import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ModalContainer from "./ModalContainer";

class SelectGalleryModal extends Component {
    state = {
        imageSelected: null,
        isProfile: false
    };
    selectImage = (image, isProfile) => {
        this.setState({ imageSelected: image, isProfile: isProfile });
    }

    confirmationHandler = () => {
        this.props.confirmationHandler(this.state.imageSelected, this.state.isProfile)
    }
    render() {
        return (
            <Dialog
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "md"}
                fullWidth
                open={this.props.open}
                onClose={this.props.closeHandler}
                className="dialog-div"
            >
                <DialogTitle className="see-more-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent
                // style={{ paddingTop: "16px" }}
                > 
                <div style={{maxWidth:"80vw",marginTop:30}}>
                <Typography variant="headline" component="h2">Act's Background Image</Typography>
                    {this.props.backgroundImage?.map((image) => (
                        <span className="gig-image-social">
                            <img style={{padding:5,backgroundColor: (!this.state.isProfile && this.state.imageSelected?.id === image.id) ? "#ff0c9c" : null}} onClick={() => this.selectImage(image, false)} className="imageStyleDetailSocial" key={image.id} src={image.url960Complete} alt={`Image ${image.id}`} />
                            { (!this.state.isProfile && this.state.imageSelected?.id === image.id)  ? 
                                <Button
                                    variant="fab"
                                    color="secondary"
                                    className="check-mark"
                                >
                                    <CheckIcon /> 
                                </Button>
                                : null }
                        </span>
                    ))}
                </div>
                <div style={{maxWidth:"80vw",marginTop:30}}>
                <Typography variant="headline" component="h2">Act's Profile Image</Typography>
                    {this.props.profileImage?.map((image) => (
                        <div className="gig-image-social">
                        <img style={{padding:5,backgroundColor:(this.state.isProfile && this.state.imageSelected?.id === image.id) ? "#ff0c9c" : null}} onClick={() => this.selectImage(image, true)} className="imageStyleDetailSocial" key={image.id} src={image.url400Complete} alt={`Image ${image.id}`} />
                        { (this.state.isProfile && this.state.imageSelected?.id === image.id)  ?  <Button
                                variant="fab"
                                color="secondary"
                                className="check-mark"
                                onClick={ () => this.handleDeleteGigImage(gigImage.id)}
                            >
                                <CheckIcon /> 
                            </Button>
                            : null 
                            }
                           
                        </div>
                        
                    ))}
                </div>
               
                  
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.closeHandler}>
                        {this.props.declineLabel ? this.props.declineLabel : "Close"}
                    </Button>
                    <Button className="btn-confirmation" color="primary" autoFocus onClick={this.confirmationHandler}>
                        {this.props.confirmationLabel ? this.props.confirmationLabel : "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default SelectGalleryModal;
