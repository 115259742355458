import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { inject, observer } from "mobx-react";
import SetListDialog from "../setList/SetListDialog";
import { Hidden, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import ConfirmationModal from "../modal/ConfirmationModal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Loading from "../loadingSpinner/Loading";

import CacheHelper from "../../helper/cache";

const toolbarStyles = theme => ({
    root: {
        backgroundColor: "#ff0c9c",
        flexGrow: 1
    },
    textCenter: {
        textAlign: "center"
    },
    highlight: {
        backgroundColor: "#333333",
        color: "#fff"
    },
    pads: {
        paddingRight: theme.spacing.unit,
        minHeight: 48
    },
    spacer: {
        paddingLeft: 20,
        flex: "0 0 76%"
    },
    spacerToolbar: {
        flex: "1 1 5%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: 0.96
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: "relative",
        top: 12
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

const textFieldStyle = {
    marginRight: "30px"
};

@inject("songListStore", "profileStore")
@observer
class NotificationToolbar extends React.Component {
    handleSongRemove = () => {
        this.props.onListRemove(this.props.selectedNotifications);
        this.props.onListReset();
    };

    render() {
        const { numSelected, classes } = this.props;

        return (
            <div>                
                <div className={classes.root}>
                    <Toolbar
                        className={classNames({
                            [classes.highlight]: numSelected > 0,
                            [classes.pads]: true
                        })}
                    >
                        <div className={classes.title}>
                            {numSelected > 0 ? (
                                <Typography variant="subheading" style={{ color: "#FFF" }}>
                                    {numSelected} selected
                                </Typography>
                            ) : (
                                <Typography variant="subheading">My Notifications</Typography>
                            )}
                        </div>
                        <div className={classes.actions}>
                            {numSelected > 0 ? (
                                <Fragment>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            aria-label="Delete"
                                            id="btnDeleteSong"
                                            style={{ paddingLeft: 10, color: "#FFF", width: 72 }}
                                            onClick={this.handleSongRemove.bind(this)}
                                        >
                                            <DeleteIcon />
                                            <Typography variant="body2" style={{ color: "#FFF" }}>
                                                Delete
                                            </Typography>
                                        </IconButton>
                                    </Tooltip>
                                </Fragment>
                            ) : (
                                <div />
                            )}
                        </div>
                    </Toolbar>
                </div>
            </div>
        );
    }
}

NotificationToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onListAdd: PropTypes.func.isRequired,
    onListRemove: PropTypes.func.isRequired,
    onListReset: PropTypes.func.isRequired
};

NotificationToolbar = withStyles(toolbarStyles)(NotificationToolbar);

export default NotificationToolbar;
