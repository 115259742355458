import React from "react";
import { inject, observer } from "mobx-react";
import "./SetListContent.css";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import ResponsiveTable from "../table/ResponsiveTable";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Logo from "../../img/hotgiggity-logo.png";
import Music from "../../img/music.svg";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        width: "100%",
        boxShadow: "none"
    },
    toolbar: {
        display: "inline-flex",
        width: "700px !important"
    },
    toolbarSmall: {
        display: "block",
        padding: "20px !important"
    },
    tableSmall: {
        marginTop: "50px !important",
        display: "block"
    },
    tableMedium: {
        display: "block"
    },
    nameSmall: {
        textAlign: "center"
    },
    avatarSmall: {
        display: "block",
        marginTop: "20px !important",
        marginLeft: "auto",
        marginRight: "auto"
    }
});

@inject("songListStore", "setListStore", "profileStore")
@observer
class SetListView extends React.Component {
    filterList = event => {
        const { selectedSongs } = this.props.setListStore;
        var updatedList = selectedSongs.filter(function(item) {
            return item.title.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        this.props.songListStore.filterSongs(updatedList);
    };

    print = () => {
        window.resizeTo(800, 815);
        window.matchMedia("print");
        window.print();
    };

    state = {
        columns: [
            { key: "number", label: "", width: "10%" },
            { key: "title", label: "Title", width: "35%" },
            { key: "artist", label: "Artist", width: "35%" },
            { key: "songKey", label: "Song Key", width: "20%" }    
        ]
    };

    renderToolbar() {
        const { classes } = this.props;
        const { profile } = this.props.profileStore;
        const { title } = this.props.setListStore;
        return (
            <div>
                <Grid item xs={12} className="text-right no-print">
                    <Grid container className="printToolbar">
                        <Grid item sm={12} className={classes.search}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="pull-right no-print"
                                style={{ marginLeft: 20 }}
                                onClick={this.print}
                            >
                                <PrintIcon /> Print
                            </Button>
                            <FormControl className="pull-right no-print">
                                <Input
                                    placeholder="Search for a Song"
                                    onChange={this.filterList}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="text-right print">
                    <Grid container className="printToolbar">
                        <Grid item sm={12} md={12} className={classes.toolbar}>
                            {profile && profile.profileImage ? (
                                <Avatar className="avatar-setlist" src={profile.profileImage.url} />
                            ) : this.props.roles ? (
                                <img src={Music} width={45} height={45} alt="avatar" />
                            ) : null}
                            {/* <Avatar className="avatar-setlist">
                                <PersonIcon />
                            </Avatar> */}

                            {title && (
                                <Typography variant="title" gutterBottom className="pull-left avatar-text">
                                    {title}
                                </Typography>
                            )}
                            <img src={Logo} className="pull-right hotgiggity-logo-setlist" alt="Hot Giggity logo" />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        const { title } = this.props.setListStore;
        const { filteredSongs } = this.props.songListStore;

        return (
            <div className="new-setlist-container">
                <Grid container spacing={24} className="no-print">
                    <Grid item xs={12} lg={3}>
                        <div className="sticky-information">
                            <Typography variant="headline" component="h3" gutterBottom>
                                {title}
                            </Typography>
                            {/* <Typography gutterBottom>
                                Use this section to create your set lists. You can create as many set lists as you like. These set lists will be able
                                to be distributed to your band members for any booked gig.
                            </Typography>
                            <Typography>
                                <b>Tip:</b>
                            </Typography>
                            <Typography>You can drag and drop songs to reorder the set list.</Typography> */}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        {this.renderToolbar()}
                        <div style={{ overflowX: "auto" }}>
                            <ResponsiveTable
                                columns={this.state.columns}
                                rows={filteredSongs.map((song, index) => {
                                    var row = {};
                                    row.number = (
                                        <div>
                                            <span style={{ whiteSpace: "nowrap" }}>{index + 1}</span>
                                        </div>
                                    );
                                    row.title = (
                                        <div>
                                            <span>{song.title}</span>
                                        </div>
                                    );
                                    row.artist = (
                                        <div>
                                            <span>{song.artist}</span>
                                        </div>
                                    );
                                    row.songKey = (
                                        <div>
                                            <span>{song.songKey}</span>
                                        </div>
                                    );
                                    return row;
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={24} className="print">
                    <Grid item xs={12}>
                        {this.renderToolbar()}
                        <div style={{ overflowX: "auto" }}>
                            <ResponsiveTable
                                columns={this.state.columns}
                                rows={filteredSongs.map((song, index) => {
                                    var row = {};
                                    row.number = (
                                        <div>
                                            <Typography variant="body2" style={{ whiteSpace: "nowrap" }} gutterBottom>
                                                {index + 1}
                                            </Typography>
                                        </div>
                                    );
                                    row.title = (
                                        <div>
                                            <Typography variant="body2" style={{ whiteSpace: "nowrap" }} gutterBottom>
                                                {song.title}
                                            </Typography>
                                        </div>
                                    );
                                    row.artist = (
                                        <div>
                                            <Typography variant="body2" style={{ whiteSpace: "nowrap" }} gutterBottom>
                                                {song.artist}
                                            </Typography>
                                        </div>
                                    );
                                    return row;
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withWidth()
)(SetListView);
