import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";
import { Grid, TextField, MenuItem } from "@material-ui/core";

import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import HubTimepicker from "../../timepicker/HubTimepicker";

import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ProfileType, BookingStatusType } from "../../../types/enum";

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore")
@observer
class BookingDateDetails extends Component {
    handleRepeatTypeChange = e => {
        this.props.bookingRequestStore.setBooking("repeatType", e.target.value);
        this.props.bookingRequestStore.setBooking("repeatCount", 1);
    };
    handleRepeatCountChange = e => {
        if (e.target.value === "0") {
            this.props.bookingRequestStore.setBooking("repeatCount", 1);
        } else {
            var value = parseInt(e.target.value, 10)
            if(value > 52){
                value = 52
            }
            this.props.bookingRequestStore.setBooking("repeatCount", value);
        }
    };
    handleDateStartChange = e => {
        this.props.bookingRequestStore.setBooking("dateStart", moment(e).startOf("day"));
        this.props.bookingRequestStore.getBookableActs();
    };
    handleTimeStartChange = e => {
        /*let {
            timeEnd
        } = this.props.bookingRequestStore.booking;

        if( moment(e, 'HH:mm').isAfter( moment(timeEnd, 'HH:mm') ) ){
            this.props.bookingRequestStore.setBooking("timeEnd", e);
        }*/
        this.props.bookingRequestStore.setBooking("timeStart", e);
        this.props.bookingRequestStore.getBookableActs();
    };

    handleTimeEndChange = e => {
        this.props.bookingRequestStore.setBooking("timeEnd", e);
        this.props.bookingRequestStore.getBookableActs();
    };

    handleLoadInStartChange = e => {
        this.props.bookingRequestStore.setBooking("loadInStart", e);
        this.props.bookingRequestStore.getBookableActs();
    };

    handleLoadInEndChange = e => {
        this.props.bookingRequestStore.setBooking("loadInEnd", e);
        this.props.bookingRequestStore.getBookableActs();
    };

    handleSoundcheckStartChange = e => {
        this.props.bookingRequestStore.setBooking("soundCheckStart", e);
        this.props.bookingRequestStore.getBookableActs();
    };

    handleSoundcheckEndChange = e => {
        this.props.bookingRequestStore.setBooking("soundCheckEnd", e);
        this.props.bookingRequestStore.getBookableActs();
    };

    shouldDisabledDate = date => {
        return false;

        // let { venueId } = this.props.bookingRequestStore.booking;
        // let days = ["", "monday", "tuesday", "wednesday", 'thursday', "friday", "saturday", "sunday"]
        // if(venueId){
        //     var agentVenue = this.props.agentVenueStore.agentVenues.filter( agentVenue => agentVenue.venueId === venueId )[0];
        //     if( agentVenue && !agentVenue[days[moment(date).isoWeekday()]] ){
        //         return true;
        //     }
        // }
        // return false;
    };

    render() {
        let { booking, error } = this.props.bookingRequestStore;
        let {
            id,
            repeatType,
            repeatCount,
            dateStart,
            timeStart,
            timeEnd,
            loadInStart,
            loadInEnd,
            soundCheckStart,
            soundCheckEnd,
            status,
            createdById,
            isAdmin
        } = booking;

        let { readOnly } = this.props;
        let currentProfile = this.props.profileStore.getCurrentProfile();
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        const isMusician = currentProfile && currentProfile?.type === ProfileType.Musician.ordinal;
        let confirmedEditable =
            status !== BookingStatusType.Cancelled.ordinal &&
            status !== BookingStatusType.Completed.ordinal &&
            moment(dateStart).endOf("day") >= moment() &&
            (!createdById || createdById === currentProfile?.id || isAdmin);

        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            id="booking-date"
                            className="booking-date-picker"
                            keyboard
                            value={dateStart}
                            fullWidth
                            margin="dense"
                            onChange={this.handleDateStartChange}
                            label="Date Start"
                            // minDate={
                            //     status !== BookingStatusType.Cancelled.ordinal &&
                            //     status !== BookingStatusType.CancelledByAct.ordinal &&
                            //     status !== BookingStatusType.CancelledByActHistory.ordinal &&
                            //     status !== BookingStatusType.Completed.ordinal
                            //         ? moment().startOf("day")
                            //         : undefined
                            // }
                            minDate={
                                !id
                                    ? moment().startOf("day")
                                    : undefined
                            }
                            format="ddd, DD-MM-YYYY"
                            keyboardIcon={<EventIcon />}
                            leftArrowIcon={<KeyboardArrowLeftIcon />}
                            rightArrowIcon={<KeyboardArrowRightIcon />}
                            disabled={readOnly}
                            style={{ marginTop: 0 }}
                            shouldDisableDate={this.shouldDisabledDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="time-start"
                        margin="none"
                        label="Time Start"
                        value={moment(timeStart, "HH:mm")}
                        onChange={this.handleTimeStartChange}
                        readOnly={readOnly}
                        error={error && error["timeStart"]}
                        helperText={error && error["timeStart"] ? error["timeStart"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="time-end"
                        margin="none"
                        label="Time End"
                        value={moment(timeEnd, "HH:mm")}
                        start={moment(timeStart, "HH:mm")}
                        onChange={this.handleTimeEndChange}
                        readOnly={readOnly}
                        error={error && error["timeEnd"]}
                        helperText={error && error["timeEnd"] ? error["timeEnd"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="loadin-start"
                        margin="none"
                        label="Load In Start"
                        value={loadInStart ? moment(loadInStart, "HH:mm") : undefined}
                        onChange={this.handleLoadInStartChange}
                        readOnly={readOnly}
                        error={error && error["loadInStart"]}
                        helperText={error && error["loadInStart"] ? error["loadInStart"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="loadin-end"
                        margin="none"
                        label="Load In End"
                        value={loadInEnd ? moment(loadInEnd, "HH:mm") : undefined}
                        onChange={this.handleLoadInEndChange}
                        readOnly={readOnly}
                        error={error && error["loadInEnd"]}
                        helperText={error && error["loadInEnd"] ? error["loadInEnd"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="time-start"
                        margin="none"
                        label="Soundcheck Start"
                        value={soundCheckStart ? moment(soundCheckStart, "HH:mm") : undefined}
                        onChange={this.handleSoundcheckStartChange}
                        readOnly={readOnly}
                        error={error && error["soundCheckStart"]}
                        helperText={error && error["soundCheckStart"] ? error["soundCheckStart"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="time-end"
                        margin="none"
                        label="Soundcheck End"
                        value={soundCheckEnd ? moment(soundCheckEnd, "HH:mm") : undefined}
                        onChange={this.handleSoundcheckEndChange}
                        readOnly={readOnly}
                        error={error && error["soundCheckEnd"]}
                        helperText={error && error["soundCheckEnd"] ? error["soundCheckEnd"] : "Venue's Local Time"}
                    />
                </Grid>
                
                {!isMusician && (id === 0 || !id) ? (
                    <Fragment>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                select
                                label="Repeat Booking"
                                value={repeatType}
                                onChange={this.handleRepeatTypeChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            >
                                <MenuItem key={0} value={0}>
                                    None
                                </MenuItem>
                                <MenuItem key={1} value={1}>
                                    Daily
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    Weekly
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                    Fortnightly
                                </MenuItem>
                                <MenuItem key={4} value={4}>
                                    Monthly
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                type="number"
                                label="Number to repeat"
                                value={repeatCount}
                                onChange={this.handleRepeatCountChange}
                                fullWidth
                                disabled={repeatType === 0}
                                InputProps={{
                                    readOnly: readOnly,
                                    inputProps: {
                                        min: 1,
                                        step: 1
                                    }
                                }}
                            />
                        </Grid>
                    </Fragment>
                ) : (
                    ""
                )}
            </Grid>
        );
    }
}

export default BookingDateDetails;
