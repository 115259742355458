import React, { Component } from "react";
import classNames from "classnames";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import PerformItem from "./PerformItem";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

class PerformList extends Component {
    state = {
        expanded: false
    };

    handleExpandPerformList = () => {
        this.setState({ expanded: true });
    };

    renderPerformItem = () => {
        let result = [];
        for (let i = 0; i < this.props.performData.length; i++) {
            result.push(
                <Grid item xs={12} key={i}>
                    {i === 2 &&
                        this.props.performData.length > 3 &&
                        !this.state.expanded && (
                            <Grid
                                container
                                className={classNames("perform-item-overlay", this.state.expanded ? "unclickable" : "clickable")}
                                onClick={this.handleExpandPerformList}
                            >
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <MoreHorizIcon />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography>View all</Typography>
                                </Grid>
                            </Grid>
                        )}
                    <PerformItem performData={this.props.performData[i]} />
                </Grid>
            );
        }
        return result;
    };

    render() {
        return (
            <Grid container spacing={8} className={classNames("perform-performlist-container", this.state.expanded ? "expanded" : "")}>
                {this.renderPerformItem()}
            </Grid>
        );
    }
}

export default PerformList;
