import React, { Fragment, Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid } from "@material-ui/core";
import PromotionCard from "./PromotionCard";

import Loading from "../loadingSpinner/Loading";

@inject("promotionStore")
@observer
class PendingPromotions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.props.promotionStore.loadPendingPromotions().then(response => {
            this.setState({
                isLoading: false
            });
        });
    }

    renderPromotionsCard = () => {
        const { pendingPromotions } = this.props.promotionStore;

        return pendingPromotions.map(promotion => (
            <Grid item xs={12} lg={6} xl={4}>
                <PromotionCard promotion={promotion} />
            </Grid>
        ));
    };

    render() {
        const { isLoading } = this.state;
        return (
            <Fragment>
                <Grid container spacing={16} style={{ position: "relative", "min-height":"80px" }}>
                    <Loading showed={isLoading} />
                    {this.renderPromotionsCard()}
                </Grid>
            </Fragment>
        );
    }
}

export default PendingPromotions;
