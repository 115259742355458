import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import "./SongListProfile.css";
import { inject, observer } from "mobx-react";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import _ from "lodash";
import "../../pages/songList/SongListView.css";

const styles = theme => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflow: "hidden"
    }
});

@inject("songListStore")
@observer
class SongListViewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: "asc",
            orderBy: "title"
        };
    }

    numberOfSplitTable = () => {
        switch (this.props.width) {
            case "xs":
            case "sm":
                return 1;
            case "md":
                return 2;
            case "lg":
            case "xl":
                return 4;
            default:
                return 1;
        }
    };

    handleRequestSort = (property, songsData, index) => {
        const tmpTable = songsData.slice();
        const tableSongs = tmpTable[index];

        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const data =
            order === "desc"
                ? tableSongs.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
                : tableSongs.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        songsData[index] = data;
        let result = [];
        songsData.forEach(songs => {
            result = _.concat(result, songs);
        });

        this.props.songListStore.filterSongs(result);

        this.setState({ order, orderBy });
    };

    handleRequestSortAll = (property, songsData, index) => {
        const { filteredSongs } = this.props.songListStore;
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const data =
            order === "desc"
                ? filteredSongs.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
                : filteredSongs.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.props.songListStore.filterSongs(data);

        this.setState({ order, orderBy });
    };

    splitDataTable = (songs, split = 4) => {
        const songCount = songs.length;

        const modulus = songCount % split;
        const divider = (songCount - modulus) / split || 1;
        let tableData = [];

        for (let index = -1; index < songCount; index += divider) {
            let tempSong = songs.slice();
            tableData.push(tempSong.splice(index + 1, divider));
        }

        let restDataSongs = tableData[tableData.length - 1].slice();
        if (restDataSongs.length) {
            tableData.forEach((table, i) => {
                let restData = restDataSongs[i] || null;
                if (restData === null) restData = { id: _.max(songs, "id"), title: "", artist: "" };
                tableData[i].push(restData);
            });
            tableData.splice(-1, 1);
        }

        return tableData;
    };

    renderTable = (songsData, index) => {
        const { classes } = this.props;
        const { order, orderBy } = this.state;
        let songs = songsData[index];
        if (!songs) return null;
        if (!songs.length) return null;
        songs = songs.filter(song => song.title !== "");
        return (
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className="row-print">
                            <TableCell className="th-songlist " variant="head" key="title" sortDirection={orderBy === "title" ? order : false}>
                                <Tooltip title="Sort" enterDelay={300}>
                                    <TableSortLabel
                                        className="head-title"
                                        active={orderBy === "title"}
                                        direction={order}
                                        onClick={this.handleRequestSortAll.bind(this, "title", songsData, index)}
                                    >
                                        <Typography variant="subheading" className="head-title" noWrap>
                                            Title
                                        </Typography>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>

                            <TableCell
                                className="th-songlist th-print"
                                variant="head"
                                key="artist"
                                sortDirection={orderBy === "artist" ? order : false}
                            >
                                <Tooltip title="Sort" enterDelay={300}>
                                    <TableSortLabel
                                        className="head-title"
                                        active={orderBy === "artist"}
                                        direction={order}
                                        onClick={this.handleRequestSortAll.bind(this, "artist", songsData, index)}
                                    >
                                        <Typography variant="subheading" className="head-title">
                                            Artist
                                        </Typography>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {songs.map(song => {
                            return (
                                <TableRow key={song.id} className="row-print">
                                    <TableCell>{_.truncate(song.title)}</TableCell>
                                    <TableCell>{_.truncate(song.artist)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter />
                </Table>
            </div>
        );
    };

    provideTable = filteredSongs => {
        let tableData = this.splitDataTable(filteredSongs, this.numberOfSplitTable());

        switch (this.props.type) {
            case "sm":
                return (
                    <Paper>
                        <Grid container style={{ padding: 16 }}>
                            <Grid item xs={12}>
                                {this.renderTable(tableData, 0)}
                            </Grid>
                        </Grid>
                    </Paper>
                );
            case "md":
                return (
                    <Grid container style={{ padding: 16 }}>
                        <Grid item xs={6}>
                            {this.renderTable(tableData, 0)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderTable(tableData, 1)}
                        </Grid>
                    </Grid>
                );
            case "lg":
                return (
                    <Grid container style={{ padding: 16 }}>
                        <Grid item sm={3} md={3}>
                            <Paper>{this.renderTable(tableData, 0)}</Paper>
                        </Grid>

                        <Grid item sm={3} md={3}>
                            <Paper>{this.renderTable(tableData, 1)}</Paper>
                        </Grid>
                        <Grid item sm={3} md={3}>
                            <Paper>{this.renderTable(tableData, 2)}</Paper>
                        </Grid>
                        <Grid item sm={3} md={3}>
                            <Paper>{this.renderTable(tableData, 3)}</Paper>
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    };

    render() {
        const { filteredSongs } = this.props.songListStore;

        return <div>{this.provideTable(filteredSongs)}</div>;
    }
}

SongListViewComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
};

export default compose(
    withStyles(styles),
    withWidth()
)(SongListViewComponent);
