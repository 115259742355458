import React, { Component, Fragment } from "react";
//  import ReactDOM from "react-dom";
import { observer, inject } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";

import {
    DialogContent,
    DialogActions,
    Button,
    //Table,
    //TableRow,
    //TableCell,
    //TableBody,
    //Typography,
    //Switch,
    TextField,
    Grid,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Collapse
} from "@material-ui/core";
import moment from "moment";
import { BookingStatusType, ProfileType, BookingType } from "../../../types/enum";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";

import "./PrivateBookingDateDetails.css";

@inject("bookingRequestStore", "profileStore")
@observer
class PrivateBookingSummaryModal extends Component {
    state = {
        actError: false,
        actErrorText: "",
        bookingReqReadMore : false,
        bookingNotesReadMore: false,
        bookingActContractReadMore: false,
        bookingContractReadMore: false,
        showReqText: false,
        showNotesText: false,
        showActText: false,
        showContractText: false
    };

    getDailyRepeatDates = () => {
        const { booking } = this.props.bookingRequestStore;
        var dates = "";
        if (booking) {
            const { dateStart, repeatCount } = booking;
            dates = `${moment(booking.dateStart).format("DD-MM-YYYY")} - ${moment(dateStart)
                .add(repeatCount - 1, "days")
                .format("DD-MM-YYYY")}`;
        }
        return dates;
    };

    getWeeklyRepeatDates = () => {
        const { booking } = this.props.bookingRequestStore;
        var dates = "";
        if (booking) {
            const { dateStart, timeStart, timeEnd, repeatCount } = booking;
            for (var i = 0; i < repeatCount; i++) {
                if (timeStart <= timeEnd) {
                    dates += moment(dateStart)
                        .add(i * 7, "days")
                        .format("DD-MM-YYYY");

                    // add commas only if it's not the last date
                    dates += i < repeatCount - 1 ? ", " : "";
                } else {
                    dates += `${moment(dateStart)
                        .add(i * 7, "days")
                        .format("DD-MM-YYYY")} - ${moment(dateStart)
                        .add(i * 7 + 1, "days")
                        .format("DD-MM-YYYY")}`;
                }
            }
        }
        return dates;
        // return <div dangerouslySetInnerHTML={{ __html: dates }} />;
    };

    getFortnightlyRepeatDates = () => {
        const { booking } = this.props.bookingRequestStore;
        var dates = "";
        if (booking) {
            const { dateStart, timeStart, timeEnd, repeatCount } = booking;
            for (var i = 0; i < repeatCount; i++) {
                if (timeStart <= timeEnd) {
                    dates += moment(dateStart)
                        .add(i * 14, "days")
                        .format("DD-MM-YYYY");
                    // add commas only if it's not the last date
                    dates += i < repeatCount - 1 ? ", " : "";
                } else {
                    dates += `${moment(dateStart)
                        .add(i * 14, "days")
                        .format("DD-MM-YYYY")} - ${moment(dateStart)
                        .add(i * 7 + 1, "days")
                        .format("DD-MM-YYYY")}`;
                }
            }
        }
        return dates;
        // return <div dangerouslySetInnerHTML={{ __html: dates }} />;
    };

    getMonthlyRepeatDates = () => {
        const { booking } = this.props.bookingRequestStore;
        var dates = "";
        if (booking) {
            const { dateStart, timeStart, timeEnd, repeatCount } = booking;

            var dayOfWeek = moment(dateStart).day();
            var weekOfMonth = 1;
            var tempDate = moment(dateStart);
            while (tempDate.get("month") === moment(dateStart).get("month")) {
                tempDate = tempDate.add(-1, "days");
                if (tempDate.day() === dayOfWeek) {
                    weekOfMonth++;
                }
            }

            for (var i = 0; i < repeatCount; i++) {
                var tempDateStart = moment(dateStart)
                    .add(i, "months")
                    .startOf("month");
                var firstSameDayOfWeekFound = false;
                while (!firstSameDayOfWeekFound) {
                    if (moment(tempDateStart).day() === dayOfWeek) {
                        firstSameDayOfWeekFound = true;
                    }
                    if (!firstSameDayOfWeekFound) {
                        tempDateStart = moment(tempDateStart).add(1, "days");
                    }
                }
                for (var j = 1; j < weekOfMonth; j++) {
                    var tempTempDate = tempDateStart.add(7, "days");
                    if (tempTempDate.get("month") === tempDateStart.get("month")) {
                        tempDateStart = tempTempDate;
                    }
                }
                if (timeStart <= timeEnd) {
                    dates += moment(tempDateStart).format("DD-MM-YYYY");
                } else {
                    dates += `${moment(tempDateStart).format("DD-MM-YYYY")} - ${moment(tempDateStart)
                        .add(1, "days")
                        .format("DD-MM-YYYY")}`;
                }

                dates += i < repeatCount - 1 ? ", " : "";
            }
        }
        return dates;
        // return <div dangerouslySetInnerHTML={{ __html: dates }} />;
    };

    handleCheckboxChange = name => event => {
        const value = event.target.checked;
        const { booking } = this.props.bookingRequestStore;

        if (name === "notifyAct" && booking && booking.actId === 0) {
            this.setState({ actError: true, actErrorText: "No act selected" });
            console.log("error act");
            return;
        }

        this.props.bookingRequestStore.setBooking(name, value);
        this.props.bookingRequestStore.setBooking("saveAsDraft", false);
    };

    handleCheckboxDraftChange = event => {
        if (event.target.checked) {
            this.props.bookingRequestStore.setBooking("notifyAct", !event.target.checked);
            this.props.bookingRequestStore.setBooking("notifyAgent", !event.target.checked);
            this.props.bookingRequestStore.setBooking("notifyClient", !event.target.checked);
            this.props.bookingRequestStore.setBooking("saveAsDraft", event.target.checked);
        }
    };

    truncateText = param => {
        if (param && param.length > 10)
            return param.substring(0,50)+'... ';
        else
            return param;
    }

    // handleNotifyActChange = e => {
    //     this.props.bookingRequestStore.setBooking("notifyAct", e.target.checked);
    // };

    render() {
        const { open, onClose, processBooking } = this.props;
        const { booking } = this.props.bookingRequestStore;
        const { saveAsDraft, notifyAct, notifyClient, notifyAgent } = booking;
        const { currentProfile } = this.props.profileStore;
        // const isAgent = currentProfile.type === ProfileType.Agent.ordinal;
        // const isStaff = currentProfile.type === ProfileType.Staff.ordinal;
        const isMusician = currentProfile?.type === ProfileType.Musician.ordinal;
        // console.log("render summary", booking, notifyAct, notifyClient, notifyAgent);

        let currencySymbol = currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$";
        const actError = booking.actId <= 0;
        const clientError = !booking.clientEmail || booking.clientEmail.length === 0;
        const canSaveAsDraft = !booking.id || booking.status === BookingStatusType.Draft.ordinal;
        const canNotifyAgent = false;
        const canNotifyClient = true;
        const canNotifyAct = !isMusician;

        const isBookingCreator = currentProfile?.id == booking?.createdById || !booking?.createdById;
        const isPrivateBooking = booking?.type == BookingType.Adhoc.ordinal;
        const isAgent = currentProfile?.type == ProfileType.Agent.ordinal;

        return (
            <ModalContainer open={open} onClose={onClose} title="Booking Summary" coloredDialogTitle={true}>
                <DialogContent>
                    <div id="alert-dialog-description">
                        <br />
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Title"
                                    value={booking.title ? booking.title : booking.tempTitle}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            {booking.agent || booking.provider ? (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Provider"
                                        value={booking.agent ? booking.agent.profileName : booking.provider}
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                            ) : (
                                ""
                            )}
                           
                    
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Name"
                                    value={booking.venue ? booking.venue.profileName : booking.venueName}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Contact"
                                    value={booking.venueContact}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Address"
                                    value={booking.venueAddress || " "}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Act"
                                    value={booking.actId > 0 ? booking.actName : "N/A"}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            {
                                booking.id ? 

                                (
                                    <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Date"
                                        value={ moment(booking.dateStart).format("DD-MM-YYYY") === moment(booking.dateEnd).format("DD-MM-YYYY") ? 
                                                moment(booking.dateStart).format("DD-MM-YYYY") : 
                                                moment(booking.dateStart).format("DD-MM-YYYY") + " - " + moment(booking.dateEnd).format("DD-MM-YYYY")
                                             }
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                               
                                ) :
                                (
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Date"
                                            value= {`${ moment(booking.dateStart).format("DD-MM-YYYY")}
                                                    ${ moment(booking.timeStart, "HH:mm").format("HH:mm") > moment(booking.timeEnd, "HH:mm").format("HH:mm") ? 
                                                    `- ${ moment(booking.dateStart).add(1, "days").format("DD-MM-YYYY")}` : ""
                                                    }`}
                                            // value={booking.dateStart === booking.dateEnd
                                            //     ? booking.dateStart
                                            //     : booking.dateStart +
                                            //     " - " +
                                            //     booking.dateEnd}
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                               
                                )
                            }
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Time"
                                    value={
                                        booking.timeStart === booking.timeEnd
                                            ? moment(booking.timeStart, "h:mma").format("h:mma")
                                            : moment(booking.timeStart, "h:mma").format("h:mma") +
                                              " - " +
                                              moment(booking.timeEnd, "h:mma").format("h:mma")
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Load In"
                                    value={
                                        booking.loadInStart && booking.loadInEnd ? 
                                            (
                                                moment(booking.loadInStart).isSame( booking.loadInEnd )
                                                    ? moment(booking.loadInStart, "h:mma").format("h:mma")
                                                    : moment(booking.loadInStart, "h:mma").format("h:mma") +
                                                    " - " +
                                                    moment(booking.loadInEnd, "h:mma").format("h:mma")
                                            ) :
                                        booking.loadInStart ? moment(booking.loadInStart, "h:mma").format("h:mma") :
                                        booking.loadInEnd ? moment(booking.loadInEnd, "h:mma").format("h:mma") :
                                        "N/A"
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Soundcheck"
                                    value={
                                        booking.soundCheckStart && booking.soundCheckEnd ? 
                                            (
                                                moment(booking.soundCheckStart).isSame( booking.soundCheckEnd )
                                                    ? moment(booking.soundCheckStart, "h:mma").format("h:mma")
                                                    : moment(booking.soundCheckStart, "h:mma").format("h:mma") +
                                                    " - " +
                                                    moment(booking.soundCheckEnd, "h:mma").format("h:mma")
                                            ) :
                                        booking.soundCheckStart ? moment(booking.soundCheckStart, "h:mma").format("h:mma") :
                                        booking.soundCheckEnd ? moment(booking.soundCheckEnd, "h:mma").format("h:mma") :
                                        "N/A"
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Number of Sets"
                                    value={booking.setNumber || " "}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Dress"
                                    value={booking.dress || " "}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Gig fee paid to Act"
                                    value={currencySymbol + `${booking.actFee ? booking.actFee : 0}`}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>

                            {currentProfile?.type === ProfileType.Agent.ordinal ? (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Agent Commision"
                                        value={currencySymbol + `${booking.agentCommissionAmount ? booking.agentCommissionAmount : 0}`}
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                            ) : (
                                ""
                            )}

                            {booking.type === BookingType.Adhoc.ordinal ? (
                                <Fragment>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Deposit / Due"
                                            value={
                                                currencySymbol +
                                                `${booking.depositAmount ? booking.depositAmount : 0} / ${moment(booking.depositDueDate).format(
                                                    "DD-MM-YYYY"
                                                )}`
                                            }
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Deposit / Due"
                                            value={
                                                currencySymbol +
                                                `${(booking.gigFee ? booking.gigFee : 0) -
                                                    (booking.depositAmount ? booking.depositAmount : 0)} / ${moment(booking.balanceDueDate).format(
                                                    "DD-MM-YYYY"
                                                )}`
                                            }
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                </Fragment>
                            ) : (
                                ""
                            )}
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="div-requirement" shrink>
                                        PA Requirements
                                    </InputLabel>
                                    <OutlinedInput
                                         id="div-requirement"
                                         className={"overflow-hidden"}
                                         fullWidth
                                         inputComponent="div"
                                         inputProps={{ dangerouslySetInnerHTML: { __html: (!this.state.showReqText ? this.truncateText(booking.requirements) : "") } }}
                                         labelWidth={125}
                                     />
                                    
                                    <Collapse in={this.state.showReqText}>
                                        <OutlinedInput
                                            id="div-requirement"
                                            className={"hide-outline"}
                                            fullWidth
                                            inputComponent="div"
                                            value={booking.requirements || " "}
                                            inputProps={{ dangerouslySetInnerHTML: { __html: booking && booking.requirements } }}
                                            labelWidth={125}
                                        />
                                    </Collapse>
                                    { booking.requirements && booking.requirements.length > 10 ? (
                                         <a className={"see-more-link"} onClick={() => this.setState({ showReqText: !this.state.showReqText })}>
                                            {
                                                !this.state.showReqText ? "Show more" : "Show less"
                                            }    
                                        </a>
                                         )
                                    : ("")}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="div-requirement" shrink>
                                        Notes
                                    </InputLabel>
                                    <OutlinedInput
                                         id="div-requirement"
                                         className={"overflow-hidden"}
                                         fullWidth
                                         inputComponent="div"
                                         inputProps={{ dangerouslySetInnerHTML: { __html: (!this.state.showNotesText ? this.truncateText(booking.notes) : "") } }}
                                         labelWidth={45}
                                     />
                                     
                                    <Collapse in={this.state.showNotesText}>
                                        <OutlinedInput
                                            id="div-requirement"
                                            className={"hide-outline"}
                                            fullWidth
                                            inputComponent="div"
                                            value={booking.notes || " "}
                                            inputProps={{ dangerouslySetInnerHTML: { __html: booking && booking.notes } }}
                                            labelWidth={45}
                                        />
                                    </Collapse>
                                    { booking.notes && booking.notes.length > 10 ? (
                                         <a className={"see-more-link"} onClick={() => this.setState({ showNotesText: !this.state.showNotesText })}>
                                            {
                                                !this.state.showNotesText ? "Show more" : "Show less"
                                            }    
                                        </a>
                                         )
                                    : ("")}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="div-requirement" shrink>
                                        Act Contract
                                    </InputLabel>
                                    <OutlinedInput
                                         id="div-requirement"
                                         className={"overflow-hidden"}
                                         fullWidth
                                         inputComponent="div"
                                         inputProps={{ dangerouslySetInnerHTML: { __html: (!this.state.showActText ? this.truncateText((booking.venueContract && !(isBookingCreator && isAgent && isPrivateBooking)) ? booking.venueContract : booking.agentContract) : "") } }}
                                         labelWidth={90}
                                     />
                                    
                                    <Collapse in={this.state.showActText}>
                                        <OutlinedInput
                                            id="div-requirement"
                                            className={"hide-outline"}
                                            fullWidth
                                            inputComponent="div"
                                            value={(booking.venueContract && !(isBookingCreator && isAgent && isPrivateBooking)) ? booking.venueContract : booking.agentContract || " "}
                                            inputProps={{ dangerouslySetInnerHTML: { __html: (booking.venueContract && !(isBookingCreator && isAgent && isPrivateBooking)) ? booking.venueContract : booking.agentContract } }}
                                            labelWidth={90}
                                        />
                                    </Collapse>
                                    { booking.venueContract && booking.venueContract.length > 10 || booking.agentContract && booking.agentContract.length > 10 ? (
                                         <a className={"see-more-link"} onClick={() => this.setState({ showActText: !this.state.showActText })}>
                                            {
                                                !this.state.showActText ? "Show more" : "Show less"
                                            }    
                                        </a>
                                         )
                                    : ("")}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="div-requirement" shrink>
                                        Client Contract
                                    </InputLabel>
                                    <OutlinedInput
                                         id="div-requirement"
                                         className={"overflow-hidden"}
                                         fullWidth
                                         inputComponent="div"
                                         inputProps={{ dangerouslySetInnerHTML: { __html: (!this.state.showContractText ? this.truncateText(booking.clientContract) : "") } }}
                                         labelWidth={110}
                                     />
                                     
                                    <Collapse in={this.state.showContractText}>
                                        <OutlinedInput
                                            id="div-requirement"
                                            className={"hide-outline"}
                                            fullWidth
                                            inputComponent="div"
                                            value={booking.clientContract || " "}
                                            inputProps={{ dangerouslySetInnerHTML: { __html: booking && booking.clientContract } }}
                                            labelWidth={110}
                                        />
                                    </Collapse>
                                    { booking.clientContract && booking.clientContract.length > 10 ? (
                                         <a className={"see-more-link"} onClick={() => this.setState({ showContractText: !this.state.showContractText })}>
                                            {
                                                !this.state.showContractText ? "Show more" : "Show less"
                                            }    
                                        </a>
                                         )
                                    : ("")}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            {canSaveAsDraft && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense"
                                                    checked={saveAsDraft}
                                                    onChange={this.handleCheckboxDraftChange}
                                                />
                                            }
                                            label="Save as Draft"
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            {canNotifyAgent && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense"
                                                    checked={notifyAgent}
                                                    onChange={this.handleCheckboxChange("notifyAgent")}
                                                />
                                            }
                                            label="Notify Agents"
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            {canNotifyAct && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox" error={actError}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense"
                                                    checked={notifyAct}
                                                    indeterminate={actError && notifyAct}
                                                    onChange={this.handleCheckboxChange("notifyAct")}
                                                />
                                            }
                                            label="Notify Act"
                                        />
                                        {notifyAct && <FormHelperText>{actError ? "Act required" : `Act: ${booking.actName}`} </FormHelperText>}
                                    </FormControl>
                                </Grid>
                            )}
                            {/* {canNotifyClient && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox" error={clientError}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense"
                                                    checked={notifyClient}
                                                    indeterminate={clientError && notifyClient}
                                                    onChange={this.handleCheckboxChange("notifyClient")}
                                                />
                                            }
                                            label={`Create invoice and notify client`}
                                        />
                                        {notifyClient && (
                                            <FormHelperText>
                                                {clientError ? "Client email required" : `Client: ${booking.clientEmail}`}{" "}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )} */}
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                    <Button onClick={() => processBooking(false)} color="primary" className="process-booking">
                        {saveAsDraft && !booking.finalized
                            ? "Save Draft"
                            : notifyAct || notifyAgent || notifyClient
                                ? "Process Booking"
                                : "Save Booking"}
                    </Button>

                    {/* {(booking.status === BookingStatusType.Accepted.ordinal && (isAgent || isStaff)) || isMusician ? (
                        <Button onClick={() => processBooking(true)} color="primary" className="process-booking finalize-booking">
                            {booking.finalized ? "Save and Send Update to Client" : "Save and Finalize Booking"}
                        </Button>
                    ) : (
                        ""
                    )} */}
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default PrivateBookingSummaryModal;
