import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";

import { Button } from "@material-ui/core";

import ResponsiveTable from "../table/ResponsiveTable";
import ActInvitationModal from "../util/ActInvitation/ActInvitationModal";
import { ProfileType } from "../../types/enum";
import ConfirmationModal from "../modal/ConfirmationModal";
import TableRowProfile from "../util/TableRowProfile";

import ActOwnerInvitationModal from "../util/ActOwnerInvitation/ActOwnerInvitationModal";
import Loading from "../loadingSpinner/Loading";

@inject("agentActStore", "templateStore", "userInvitationStore", "profileStore")
@observer
class FestivalActRoster extends Component {
    state = {
        actInvitationModalState: false,
        deleteActModalState: false,
        deletedAct: undefined,
        acceptedAct: undefined,
        acceptRequestModalState: false,
        deleteRequestModalState: false,
        actOwnerInvitationModalState: false,
        deleteInvitationModalState: false,
        request: undefined
    };

    columns = [
        { key: "actName", label: "Act Name", width: "20%" },
        { key: "actOwner", label: "Act Owner", width: "20%" },
        { key: "actContact", label: "Act Contact", width: "20%" },
        { key: "venue", label: "Venue", width: "10%" },
        { key: "status", label: "Status", width: "10%" },
        { key: "action", label: "", width: "30%" }
    ];

    rows = [];
    pendingRows = [];

    componentDidMount() {
        // Promise.all([
        //     this.props.agentActStore.getAgentsPendingRequest(),
        //     this.props.agentActStore.getAgentActRoster(),
        //     this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentAct.ordinal)
        // ]).then(() => {});
    }

    // mapInviteRequests = () => {
    //     this.pendingRows = [];

    //     this.props.userInvitationStore.invitations.forEach(data => {
    //         var row = {};

    //         row.actOwner = <TableRowProfile name={`${data.invitedFirstName} ${data.invitedLastName}`} />;

    //         row.actName = <TableRowProfile name={data.invitedAct} />;

    //         row.status = "Invited d";

    //         row.venue = data.venueName;

    //         row.action = (
    //             <Fragment>
    //                 <Button size="small" color="primary" onClick={() => this.handleOpenDeleteInvitationModal(data)}>
    //                     Remove
    //                 </Button>
    //             </Fragment>
    //         );

    //         row.style = {
    //             backgroundColor: "#EEE"
    //         };

    //         this.pendingRows.push(row);
    //     });
    //     return Promise.resolve();
    // };

    mapPendingRequest = () => {
        this.pendingRows = [];
        console.log("Count: ", this.props.agentActStore.pendingAgentActs.length);
        this.props.agentActStore.pendingAgentActs.forEach(data => {
            let rules = data && data.rules ? JSON.parse(data.rules) : undefined;
            console.log("Rules: ", rules);

            if (rules && rules.IsFestival) {
                var act = data.primaryProfile.type === ProfileType.Act.ordinal ? data.primaryProfile : data.secondaryProfile;

                var row = {};

                row.actName = <TableRowProfile profileId={act.id} name={act.fullName} />;

                row.actOwner = <TableRowProfile profileId={act.myMusician.id} name={act.myMusician.fullName} />;

                row.actContact = act.contactName + " - " + act.contactPhone;

                row.status = "Pending";
                row.venue = data.venueName;

                row.action = (
                    <Fragment>
                        <Button size="small" color="primary" onClick={() => this.handleOpenAcceptRequestModal(data)}>
                            Accept
                        </Button>
                        <Button size="small" color="primary" onClick={() => this.handleOpenDeleteRequestModal(data)}>
                            Decline
                        </Button>
                    </Fragment>
                );

                row.style = {
                    backgroundColor: "#EEE"
                };

                this.pendingRows.push(row);
            }
        });
    };

    mapAccepted = () => {
        this.rows = [];
        this.props.agentActStore.agentActs.forEach(data => {
            if (data.isFestival) {
                var act = data.act;

                var row = {};
                if (!act) {
                    return row;
                }

                row.actName = <TableRowProfile profileId={act.id} name={act.fullName} />;

                row.actOwner = <TableRowProfile profileId={act.myMusician.id} name={act.myMusician.fullName} />;

                row.actContact = act.contactName + " - " + act.contactPhone;

                row.status = "Accepted";

                row.venue = "Loading...";

                if (data.actFestival && data.actFestival.length > 0 && data.actFestival[0].venue) {
                    row.venue = data.actFestival[0].venue.profileName;
                }

                row.action = (
                    <Fragment>
                        <Button size="small" color="primary" onClick={() => this.handleOpenDeleteModal(data)}>
                            Remove
                        </Button>
                    </Fragment>
                );

                this.rows.push(row);
            }
        });
    };

    openActInvitationModal = () => {
        this.setState(
            {
                ...this.state,
                actInvitationModalState: true
            },
            () => {
                this.props.agentActStore.clearInviteData();
            }
        );
    };

    closeActInvitationModal = () => {
        this.setState({
            ...this.state,
            actInvitationModalState: false
        });
    };

    openActOwnerInvitationModal = value => {
        this.props.userInvitationStore.resetInvitedData();
        this.setState(
            {
                ...this.state,
                actOwnerInvitationModalState: true,
                actInvitationModalState: false
            },
            () => {
                this.props.userInvitationStore.setInvitedActName(value);
            }
        );
    };

    closeActOwnerInvitationModal = () => this.setState({ ...this.state, actOwnerInvitationModalState: false });

    handleDeleteAct = () => {
        this.props.agentActStore.removeAgentAct(this.state.deletedAct.id).then(() => {
            this.props.templateStore.openSnackbar(this.state.deletedAct.act.fullName + " has been removed.");
            this.handleCloseDeleteModal();
        });
    };

    handleDeleteRequest = () => {
        this.props.agentActStore.removeRequest(this.state.deletedAct.id).then(() => {
            this.props.templateStore.openSnackbar(this.state.deletedAct.secondaryProfile.fullName + " has been removed.");
            this.handleCloseDeleteModal();
        });
    };

    handleAcceptRequest = () => {
        this.props.agentActStore.acceptRequest(this.state.acceptedAct).then(() => {
            this.props.templateStore.openSnackbar(this.state.acceptedAct.secondaryProfile.fullName + " has been accepted.");
            this.handleCloseAcceptModal();
        });
    };

    handleDeleteInvitation = () => {
        this.props.userInvitationStore.deleteInvitation(this.state.deletedAct).then(() => {
            this.props.templateStore.openSnackbar(this.state.deletedAct.invitedAct + " has been removed.");
            this.handleCloseDeleteModal();
        });
    };

    handleOpenDeleteModal = agentAct => {
        this.setState({
            ...this.state,
            deleteActModalState: true,
            deletedAct: agentAct
        });
    };

    handleOpenDeleteRequestModal = agentAct => {
        this.setState({
            ...this.state,
            deleteRequestModalState: true,
            deletedAct: agentAct
        });
    };

    handleOpenDeleteInvitationModal = agentAct => {
        this.setState({
            ...this.state,
            deleteInvitationModalState: true,
            deletedAct: agentAct
        });
    };

    handleCloseDeleteModal = () => {
        this.setState({
            ...this.state,
            deleteActModalState: false,
            deletedAct: undefined,
            deleteRequestModalState: false,
            deleteInvitationModalState: false
        });
    };

    handleOpenAcceptRequestModal = agentAct => {
        this.setState({
            ...this.state,
            acceptRequestModalState: true,
            acceptedAct: agentAct,
            request: agentAct
        });
    };

    handleCloseAcceptModal = () => {
        this.setState({
            ...this.state,
            acceptActModalState: false,
            acceptedAct: undefined,
            acceptRequestModalState: false
        });
    };

    render() {
        //this.mapInviteRequests().then(() => {
        this.mapPendingRequest();
        // });
        this.mapAccepted();
        // const { festivalTitle } = this.props.profileStore.currentProfile;
        const { isLoadingPendingActs, isLoadingAcceptedActs } = this.props.agentActStore;

        return (
            <Fragment>
                {/* <Heading title={`${festivalTitle} Act Roster`}>
                    <Button id="btn-invite-act2" color="primary" className="pull-right" onClick={this.openActInvitationModal}>
                        Invite Act
                    </Button>
                </Heading> */}
                {this.pendingRows.length > 0 ? (
                    <Fragment>
                        <div style={{ position: "relative" }}>
                            <Loading showed={isLoadingPendingActs} />
                            <ResponsiveTable
                                columns={this.columns}
                                rows={this.pendingRows}
                                showToolbar={true}
                                title="Pending Acts"
                                pagination={this.pendingRows.length > 5}
                            />
                            <br />
                        </div>
                    </Fragment>
                ) : (
                    ""
                )}

                <div style={{ position: "relative" }}>
                    <Loading showed={isLoadingAcceptedActs} />
                    <ResponsiveTable columns={this.columns} rows={this.rows} />
                </div>

                <ActInvitationModal
                    open={this.state.actInvitationModalState}
                    onClose={this.closeActInvitationModal}
                    profileType="agent"
                    handleEmpty={this.openActOwnerInvitationModal}
                />
                <ActOwnerInvitationModal open={this.state.actOwnerInvitationModalState} onClose={this.closeActOwnerInvitationModal} />

                <ConfirmationModal
                    open={this.state.deleteInvitationModalState}
                    message="Are you sure to remove this act?"
                    closeHandler={this.handleCloseDeleteModal}
                    confirmationHandler={this.handleDeleteInvitation}
                    declineHandler={this.handleCloseDeleteModal}
                    confirmationLabel="Remove"
                />

                <ConfirmationModal
                    open={this.state.deleteRequestModalState}
                    message="Are you sure to remove this act?"
                    closeHandler={this.handleCloseDeleteModal}
                    confirmationHandler={this.handleDeleteRequest}
                    declineHandler={this.handleCloseDeleteModal}
                    confirmationLabel="Remove"
                />

                <ConfirmationModal
                    open={this.state.acceptRequestModalState}
                    message="Are you sure to accept this act?"
                    closeHandler={this.handleCloseAcceptModal}
                    confirmationHandler={this.handleAcceptRequest}
                    declineHandler={this.handleCloseAcceptModal}
                    confirmationLabel="Accept"
                />

                <ConfirmationModal
                    open={this.state.deleteActModalState}
                    message="Are you sure you would like to remove this act? Your current bookings with this act will be removed."
                    closeHandler={this.handleCloseDeleteModal}
                    confirmationHandler={this.handleDeleteAct}
                    declineHandler={this.handleCloseDeleteModal}
                    confirmationLabel="Remove"
                />
            </Fragment>
        );
    }
}

export default FestivalActRoster;
