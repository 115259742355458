import React, { Fragment } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import "./SetListContent.css";

import Tooltip from "@material-ui/core/Tooltip";

import ResponsiveTable from "../table/ResponsiveTable";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import Hidden from "@material-ui/core/Hidden";

@inject("songListStore", "setListStore")
@observer
class SetListContent extends React.Component {
    state = {
        openDeleteConfirm: false,
        setToDelete: null
    };

    listColumns = [
        { key: "title", label: "Title" },
        { key: "count", label: "Songs" },
        { key: "info", label: "", width: "220px" }
        // { key: "edit", label: "" },
        // { key: "delete", label: "" }
    ];

    constructor(props) {
        super(props);
        this.loadSetlists();
    }

    loadSetlists() {
        switch (this.props.type) {
            case "musician":
                this.props.setListStore.reset();
                this.props.setListStore.setType(0);
                this.props.setListStore.setProfileId(this.props.profileId);
                this.props.setListStore.loadSetlists();
                break;
            case "booking":
                break;
            default:
                break;
        }
    }

    handleClose = () => {
        this.setState({ openDeleteConfirm: false });
    };

    openConfirm = set => {
        this.setState({ openDeleteConfirm: true, setToDelete: set });
    };

    handleDeleteSet = () => {
        const { setToDelete } = this.state;
        if (!setToDelete) return false;
        this.props.setListStore.deleteSetList(setToDelete).finally(() => {
            this.setState({ openDeleteConfirm: false });
            this.loadSetlists();
        });
    };

    render() {
        const { setLists } = this.props.setListStore;

        let list = [];

        if (setLists && setLists.length > 0) {
            setLists.forEach((set, i) => {
                list.push({
                    title: set.setList.title,
                    count: set.songListCount + " Songs",
                    info: (
                        <Fragment>
                            <Tooltip title="View Songs">
                                <Link to={"/musician/setlist/" + set.setList.id + "/view"}>
                                    <Button color="primary" size="small">
                                        View
                                    </Button>
                                </Link>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <Link to={"/musician/setlist/" + set.setList.id + "/edit"}>
                                    <Button size="small" color="primary" className="btnEditSetlist">
                                        Edit
                                    </Button>
                                </Link>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button color="primary" size="small" className="btnDeleteSetlist" onClick={this.openConfirm.bind(this, set.setList)}>
                                    Delete
                                </Button>
                            </Tooltip>
                        </Fragment>
                    )
                });
            });
        }

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} lg={3}>
                    <div className="sticky-information">
                        <Typography variant="headline" component="h3" gutterBottom>
                            Set Lists
                        </Typography>
                        <Typography gutterBottom className="side-helper-text">
                            Use this section to create your set lists. You can create as many set lists as you like. These set lists will be able to
                            be distributed to your band members for any booked gig.
                        </Typography>
                        <Typography>
                            <b>Tip:</b>
                        </Typography>
                        <Typography className="side-helper-text">You can drag and drop songs to reorder the set list.</Typography>
                    </div>
                </Grid>

                <Grid item xs={12} lg={9}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} className="text-right">
                            <Hidden smDown>
                                <Link to="/musician/setlist/new">
                                    <Button variant="contained" color="primary">
                                        Add Setlist
                                    </Button>
                                </Link>
                            </Hidden>
                            <Hidden mdUp>
                                <Link to="/musician/setlist/new">
                                    <Button fullWidth variant="contained" color="primary" id="btnAddNewSetlist">
                                        Add Setlist
                                    </Button>
                                </Link>
                            </Hidden>
                        </Grid>
                        <Grid item xs={12}>
                            <ResponsiveTable columns={this.listColumns} rows={list} showToolbar={false} />
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.openDeleteConfirm}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className="dialog-div"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Delete Set List"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <p style={{display:"inline"}}>Are you sure to delete {this.state.setToDelete ? <div style={{fontWeight:"bold", display:"inline"}}>{this.state.setToDelete.title}</div> : "selected set list"}?</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                        <Button color="primary" className="btnExecuteDelete" onClick={this.handleDeleteSet}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

export default compose(
    withMobileDialog(),
    withWidth()
)(SetListContent);
