import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid } from "@material-ui/core";
import PromotionCard from "./PromotionCard";
import Loading from "../loadingSpinner/Loading";

@inject("promotionStore")
@observer
class PastPromotions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.props.promotionStore.loadCompletedPromotions().then(response => {
            this.setState({
                isLoading: false
            });
        });
    }

    renderPromotionsCard = () => {
        const { completedPromotions } = this.props.promotionStore;

        return completedPromotions.map(promotion => (
            <Grid item xs={12} lg={6} xl={4}>
                <PromotionCard promotion={promotion} />
            </Grid>
        ));
    };

    render() {
        const { isLoading } = this.state;
        return (
            <Grid container spacing={16} style={{ position: "relative", "min-height":"80px" }}>
                <Loading showed={isLoading} />
                {this.renderPromotionsCard()}
            </Grid>
        );
    }
}

export default PastPromotions;
