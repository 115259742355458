import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
// import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SelectField from "../fields/SelectField";

// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import Slide from "@material-ui/core/Slide";

// import Paper from "@material-ui/core/Paper";
// import { ProfileType, InvoiceType } from "../../types/enum";
import Loading from "../loadingSpinner/Loading";

import PageNotFound from "../../pages/profile/PageNotFound";

@inject("invoiceStore", "templateStore", "profileStore", "quickBooksStore")
@observer
class QuickBooksCustomer extends Component {
    state = {
        pdfFile: undefined,
        receiptNumber: "",
        paymentNote: "",
        invoice: {},
        notFound: false,
        isLoading: true
    };

    mapCustomers = () => {
        const { quickBooksCustomers } = this.props.quickBooksStore;

        return quickBooksCustomers.map(customer => {
            let name = customer.fullyQualifiedName;
            if (customer.companyName) {
                name = name + " - " + customer.companyName;
            }
            return {
                value: customer.id,
                label: name,
                label2: customer.companyName
            };
        });
    };

    mapServices = () => {
        const { items } = this.props.quickBooksStore;

        return items.map(item => {
            return {
                value: item.id,
                label: item.fullyQualifiedName
            };
        });
    };

    mapTaxCodes = () => {
        const { taxCodes } = this.props.quickBooksStore;

        return taxCodes.map(item => {
            return {
                value: item.id,
                label: item.name
            };
        });
    };

    handleCustomerChange = value => {
        console.log("selected cus", value);
        this.props.quickBooksStore.setSelectedCustomers(value);
    };

    handleItemChange = value => {
        this.props.quickBooksStore.setSelectedItem(value);
    };

    handleCreateCustomer = () => {
        const { invoice } = this.props.invoiceStore;
        if (invoice && invoice.payer) {
            this.props.quickBooksStore.setNewCustomerFirstName(invoice.payer.firstName);
            this.props.quickBooksStore.setNewCustomerLastName(invoice.payer.lastName);
        }
        this.props.quickBooksStore.setNewCustomerCompany(invoice.payerName);
        this.props.quickBooksStore.setNewCustomerEmail(invoice.payerEmail);
        this.props.quickBooksStore.setNewCustomerAddress(invoice.payerAddress);
        this.props.quickBooksStore.setNewCustomerCity(invoice.payerSuburb);
        this.props.quickBooksStore.setNewCustomerState(invoice.payerState);
        this.props.quickBooksStore.setNewCustomerPostCode(invoice.payerPostal);
        this.props.quickBooksStore.setIsCreatingCustomer(true);
    };

    handleCancelCreateCustomer = () => {
        this.props.quickBooksStore.setIsCreatingCustomer(false);
    };

    handleSaveNewCustomer = () => {
        this.props.quickBooksStore.saveNewCustomer();
    };

    handleTaxCodeChange = value => {
        this.props.quickBooksStore.setSelectedTaxCode(value);
    };

    handleCustomerFirstNameChange = value => {
        this.props.quickBooksStore.setNewCustomerFirstName(value.target.value);
    };

    handleCustomerLastNameChange = value => {
        this.props.quickBooksStore.setNewCustomerLastName(value.target.value);
    };

    handleCustomerEmailChange = value => {
        this.props.quickBooksStore.setNewCustomerEmail(value.target.value);
    };

    handleCustomerCompanyChange = value => {
        this.props.quickBooksStore.setNewCustomerCompany(value.target.value);
    };

    handleCustomerAddressChange = value => {
        this.props.quickBooksStore.setNewCustomerAddress(value.target.value);
    };

    handleCustomerCityChange = value => {
        this.props.quickBooksStore.setNewCustomerCity(value.target.value);
    };

    handleCustomerStateChange = value => {
        this.props.quickBooksStore.setNewCustomerState(value.target.value);
    };

    handleCustomerPostCodeChange = value => {
        this.props.quickBooksStore.setNewCustomerPostCode(value.target.value);
    };

    render() {
        // const { invoiceId, fullScreen, isLoading } = this.props.invoiceStore;
        const {
            // quickBooksCustomers,
            selectedCustomerId,
            selectedTaxCodeId,
            // loadingCustomer,
            selectedCustomer,
            selectedItemId,
            loadingItems,
            isCreatingCustomer,
            newCustomerFirstName,
            newCustomerLastName,
            newCustomerCompany,
            newCustomerEmail,
            newCustomerAddress,
            newCustomerCity,
            newCustomerState,
            newCustomerPostCode,
            error
        } = this.props.quickBooksStore;

        const { invoice } = this.props.invoiceStore;

        return this.state.notFound ? (
            <PageNotFound />
        ) : (
            <Fragment>
                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDate">
                            <Typography variant="headline" component="h3" gutterBottom>
                                QuickBook Customer
                            </Typography>

                            <Typography gutterBottom className="side-helper-text">
                                Select the customer where the invoice will be sent. If you already setup one on QuickBooks, select it from the
                                dropdown. The system will try to find a matching customer based on their email address.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={6} md={12}>
                                <SelectField
                                    id="customer"
                                    label="Customer"
                                    value={selectedCustomerId}
                                    suggestions={this.mapCustomers()}
                                    placeholder="Select Customer"
                                    onChange={this.handleCustomerChange}
                                    error={error && error["customer"]}
                                    helperText={error && error["customer"] ? error["customer"] : ""}
                                />
                            </Grid>
                            {selectedCustomer && (
                                <Fragment>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            id="no"
                                            label="Contact"
                                            margin="dense"
                                            fullWidth
                                            value={selectedCustomer.fullyQualifiedName}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField id="no" label="Company" margin="dense" fullWidth value={selectedCustomer.companyName} disabled />
                                    </Grid>
                                    {selectedCustomer.primaryEmailAddr && (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="no"
                                                label="Email"
                                                margin="dense"
                                                fullWidth
                                                value={selectedCustomer.primaryEmailAddr.address}
                                                disabled
                                            />
                                        </Grid>
                                    )}

                                    {selectedCustomer.billAddr && (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="no"
                                                label="Address"
                                                margin="dense"
                                                fullWidth
                                                value={`${selectedCustomer.billAddr.line1} ${selectedCustomer.billAddr.city} ${
                                                    selectedCustomer.billAddr.countrySubDivisionCode
                                                } ${selectedCustomer.billAddr.postalCode}`}
                                                disabled
                                            />
                                        </Grid>
                                    )}
                                </Fragment>
                            )}
                            {!selectedCustomerId &&
                                !isCreatingCustomer &&
                                invoice && (
                                    <Grid item xs={12} sm={6} md={12}>
                                        No Match Customer found. Add {invoice.payerName} as new customer?<br />
                                        <Button variant="outlined" onClick={this.handleCreateCustomer}>
                                            Create Customer
                                        </Button>
                                    </Grid>
                                )}

                            {isCreatingCustomer && (
                                <Grid item xs={12} sm={6} md={12}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <hr />
                                            <p>Creating New Customer</p>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="customerName"
                                                label="First Name"
                                                margin="dense"
                                                fullWidth
                                                value={newCustomerFirstName}
                                                onChange={this.handleCustomerFirstNameChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="customerLastName"
                                                label="Last Name"
                                                margin="dense"
                                                fullWidth
                                                value={newCustomerLastName}
                                                onChange={this.handleCustomerLastNameChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="customerCompany"
                                                label="Company"
                                                margin="dense"
                                                fullWidth
                                                value={newCustomerCompany}
                                                onChange={this.handleCustomerCompanyChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="customerEmail"
                                                label="Email"
                                                margin="dense"
                                                fullWidth
                                                value={newCustomerEmail}
                                                onChange={this.handleCustomerEmailChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="customerAddress"
                                                label="Address"
                                                margin="dense"
                                                fullWidth
                                                value={newCustomerAddress}
                                                onChange={this.handleCustomerAddressChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="customerCity"
                                                label="City"
                                                margin="dense"
                                                fullWidth
                                                value={newCustomerCity}
                                                onChange={this.handleCustomerCityChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="customerState"
                                                label="State"
                                                margin="dense"
                                                fullWidth
                                                value={newCustomerState}
                                                onChange={this.handleCustomerStateChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="customerPostCode"
                                                label="PostCode"
                                                margin="dense"
                                                fullWidth
                                                value={newCustomerPostCode}
                                                onChange={this.handleCustomerPostCodeChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Button variant="outlined" onClick={this.handleSaveNewCustomer}>
                                                Save Customer
                                            </Button>
                                            &nbsp;
                                            <Button variant="" onClick={this.handleCancelCreateCustomer}>
                                                Cancel
                                            </Button>
                                            <hr />
                                        </Grid>
                                        <hr />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={16} style={{ position: "relative" }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDate">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Products and Services
                            </Typography>

                            <Typography gutterBottom className="side-helper-text">
                                A Products and Services list contains the items your business offers. For these items to display on your invoices and
                                other sales forms, the products and services preference must be turned on. You can add items so the list includes all
                                the items you sell, edit item details as needed, and run various products/services reports.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Loading showed={loadingItems} />
                        <Grid item xs={12}>
                            <Grid container spacing={16}>
                                <React.Fragment>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <SelectField
                                            id="service"
                                            label="Product/Service type"
                                            value={selectedItemId}
                                            suggestions={this.mapServices()}
                                            placeholder="Select Product/Service"
                                            onChange={this.handleItemChange}
                                            error={error && error["item"]}
                                            helperText={error && error["item"] ? error["item"] : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <SelectField
                                            id="taxCodes"
                                            label="Product/Service type"
                                            value={selectedTaxCodeId}
                                            suggestions={this.mapTaxCodes()}
                                            placeholder="Select Tax Codes"
                                            onChange={this.handleTaxCodeChange}
                                            error={error && error["taxCodes"]}
                                            helperText={error && error["taxCodes"] ? error["taxCodes"] : ""}
                                        />
                                    </Grid>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default withMobileDialog()(QuickBooksCustomer);
