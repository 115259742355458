import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Checkbox, Tooltip, Button } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";

import VenuesTableToolbar from "./VenuesTableToolbar";

const columnData = [
    { columnTitle: "name", numeric: false, disablePadding: false, label: "Name" },
    { columnTitle: "address", numeric: false, disablePadding: false, label: "Address" },
    { columnTitle: "edit", numeric: false, disablePadding: false, label: "" }
];

@inject("venueStore", "regionStore")
@observer
class VenuesTable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            rowsPerPage: 5
        };
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleClearSelected = () => {
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleSelectAllClick = (event, checked) => {
        const { venues } = this.props.venueStore;

        if (checked) {
            this.setState({ selected: venues.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };

    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page } = this.state;
        const { venues } = this.props.venueStore;
        const { canEdit } = this.props.regionStore;
        return (
            <Paper className={classes.root}>
                <VenuesTableToolbar selected={selected} handleClearSelected={this.handleClearSelected} />
                <div className={classes.tableWrapper}>
                    <Table className="venue-table">
                        <TableHead>
                            <TableRow>
                                {canEdit ? (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            disabled={!canEdit}
                                            indeterminate={selected.length > 0 && selected.length < venues.length}
                                            checked={selected.length === venues.length}
                                            onChange={this.handleSelectAllClick}
                                        />
                                    </TableCell>
                                ) : (
                                    ""
                                )}

                                {columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.columnTitle}
                                            numeric={column.numeric}
                                            padding={column.disablePadding ? "none" : "default"}
                                            sortDirection={orderBy === column.columnTitle ? order : false}
                                        >
                                            <Tooltip title="Sort" placement={column.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                                                <TableSortLabel active={orderBy === column.columnTitle} direction={order}>
                                                    {column.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    );
                                }, this)}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {venues.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                const isSelected = this.isSelected(n.id);
                                return (
                                    <TableRow hover role="checkbox" aria-checked={isSelected} tabIndex={-1} key={n.id} selected={isSelected}>
                                        {canEdit ? (
                                            <TableCell padding="checkbox">
                                                <Checkbox disabled={!canEdit} checked={isSelected} onClick={event => this.handleClick(event, n.id)} />
                                            </TableCell>
                                        ) : (
                                            ""
                                        )}
                                        <TableCell>{n.profileName}</TableCell>
                                        <TableCell>
                                            {n.street} {n.city} {n.state}
                                        </TableCell>
                                        <TableCell>
                                            {n.canEdit ? (
                                                <Button color="primary" component={Link} to={`/venues/${n.id}/edit`}>
                                                    Edit
                                                </Button>
                                            ) : (
                                                ""
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    rowsPerPage={5}
                                    SelectProps={{ className: "transparent-select" }}
                                    page={page}
                                    count={venues.length}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
        );
    }
}

VenuesTable.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto"
    }
});

export default withStyles(styles)(VenuesTable);
