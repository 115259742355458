import { Cache } from "aws-amplify";
import { observable, action, reaction } from "mobx";
import agent from "../agent";

class CommonStore {
    @observable appName = "hubdit";
    @observable token = window.localStorage.getItem("jwt");
    @observable refreshToken = window.localStorage.getItem("refreshToken");
    @observable appLoaded = false;

    @observable tags = [];
    @observable isLoadingTags = false;

    constructor() {
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem("jwt", token);
                } else {
                    window.localStorage.removeItem("jwt");
                }
            }
        );

        reaction(
            () => this.refreshToken,
            refreshToken => {
                if (refreshToken) {
                    window.localStorage.setItem("refreshToken", refreshToken);
                } else {
                    window.localStorage.removeItem("refreshToken");
                }
            }
        );
    }

    @action
    loadTags() {
        this.isLoadingTags = true;
        return agent.Tags.getAll()
            .then(
                action(({ tags }) => {
                    this.tags = tags.map(t => t.toLowerCase());
                })
            )
            .finally(
                action(() => {
                    this.isLoadingTags = false;
                })
            );
    }

    @action
    setToken(token, refreshToken) {
        this.token = token;
        this.refreshToken = refreshToken;
    }

    @action
    setAppLoaded(value) {
        this.appLoaded = value;
    }

    @action
    resetData() {
        window.localStorage.removeItem('actsTotal')
        window.localStorage.removeItem("email-confirmation")
        window.localStorage.removeItem("registrationId");
        window.localStorage.removeItem("currentProfileType");
        window.localStorage.removeItem("startDateInvoice");
        window.localStorage.removeItem("endDateInvoice");
        window.localStorage.removeItem("organizationInvoice");
        window.localStorage.removeItem("stateInvoice");
        window.localStorage.removeItem("regionInvoice");
        window.localStorage.removeItem("venueInvoice");
        window.localStorage.removeItem("statusInvoice");
        window.localStorage.removeItem("invoiceStatusFilter");
        window.localStorage.removeItem("actInvoice");
        // window.localStorage.clear();
    }

    @action
    resetAllData() {
        window.localStorage.clear();
        Cache.clear();
    }
}

export default new CommonStore();
