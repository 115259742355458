import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import { DateTimePicker, DatePicker } from "material-ui-pickers";
import Paper from "@material-ui/core/Paper";
import { ProfileType, InvoiceType } from "../../types/enum";
import Loading from "../loadingSpinner/Loading";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";


import PdfViewer from "../pdf/PdfViewer";
import InvoiceDeleted from "../../pages/profile/invoiceDeleted";

@inject("invoiceStore", "templateStore", "profileStore")
@observer
class InvoiceDetail extends Component {
    state = {
        pdfFile: undefined,
        receiptNumber: "",
        paymentNote: "",
        invoice: {},
        notFound: false,
        isLoading: true,
        paidDate: new Date(),
        invoiceUpdatePaidDateOpen: false
    };

    componentDidMount = () => {
        const { invoiceId } = this.props;
        const currentUser = this.props.profileStore.getCurrentProfile();
        // this.props.templateStore.showLoadingScreen();
        this.setState({ isLoading: true });
        this.props.invoiceStore
            .getByIdProfileId(invoiceId,currentUser.id)
            .then(response => {
                this.props.invoiceStore.downloadInvoicePdf(invoiceId).then(responseFile => {
                    let pdfFile = `data:application/pdf;base64,${responseFile}`;
                    if(response?.status === "paid"){
                        this.setState(
                            {
                                ...this.state,
                                invoice: response,
                                isLoading: false,
                                paidDate: response.paidAt,
                                pdfFile
                            },
                            () => {
                                // this.props.templateStore.hideLoadingScreen();
                            }
                        );
                    }else{
                        this.setState(
                            {
                                ...this.state,
                                invoice: response,
                                isLoading: false,
                                pdfFile
                            },
                            () => {
                                // this.props.templateStore.hideLoadingScreen();
                            }
                        );
                    }
                   
                });
            })
            .catch(error => {
                if (error.status === 404) {
                    // this.props.templateStore.hideLoadingScreen();
                    this.setState({ notFound: true, isLoading: false });
                }else {
                    this.setState({ notFound: true, isLoading: false });
                }
            })
            .finally(() => {
                // this.setState({ isLoading: false });
            });
    };

    handleFromChange = date => {
        this.setState({ paidDate: date});
    };

    handlePayInvoiceClick = invoiceId => () => {
        this.props.invoiceStore
            .payInvoice(invoiceId, { paidDate: moment(this.state.paidDate).format('YYYY/MM/DD'), receiptNumber: this.state.receiptNumber, paymentNote: this.state.paymentNote, paidById: this.props.profileStore.currentProfile.id })
            .finally(() => {
                this.props.templateStore.openSnackbar("Invoice has been paid");
                this.setState({
                    ...this.state,
                    invoice: {
                        ...this.state.invoice,
                        paidAt: this.state.paidDate,
                        receiptNumber: this.state.receiptNumber,
                        paymentNote: this.state.paymentNote,
                        status: "paid"
                    }
                });
                this.handleCloseInvoiceDetailDialog();

                //reload invoice pdf to get paid watermark
                this.props.invoiceStore.downloadInvoicePdf(invoiceId).then(responseFile => {
                    let pdfFile = `data:application/pdf;base64,${responseFile}`;
                    this.setState({ pdfFile: pdfFile });
                });
            });
    };

    handleUpdatePaidDate = invoiceId => () => {
        this.props.invoiceStore
            .updatePaidDate(invoiceId, { paidDate: moment(this.state.paidDate).format('YYYY/MM/DD')})
            .finally(() => {
                this.props.templateStore.openSnackbar("Invoice paid date has been been updated");
                this.setState({
                    ...this.state,
                    invoice: {
                        ...this.state.invoice,
                        paidAt: this.state.paidDate
                    }
                });
                this.handleCloseInvoiceUpdatePaidDate();

            });
    };

    

    handleUnPayInvoiceClick = invoiceId => () => {
        this.props.invoiceStore
            .unPayInvoice(invoiceId, { paidById: this.props.profileStore.currentProfile.id})
            .finally(() => {
                this.props.templateStore.openSnackbar("Cancel invoice paid status");
                this.setState({
                    ...this.state,
                    invoice: {
                        ...this.state.invoice,
                        paidAt: undefined,
                        receiptNumber:"",
                        paymentNote: "",
                        paidDate: undefined,
                        status: "cancelled paid"
                    }
                });
                this.handleCloseInvoiceDetailDialog();

                //reload invoice pdf to get paid watermark
                this.props.invoiceStore.downloadInvoicePdf(invoiceId).then(responseFile => {
                    let pdfFile = `data:application/pdf;base64,${responseFile}`;
                    this.setState({ pdfFile: pdfFile });
                });
            });
    };

    handleReceiptNumberChange = e => {
        this.setState({ receiptNumber: e.target.value });
    };

    handlePaymentNoteChange = e => {
        this.setState({ paymentNote: e.target.value });
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    handleOpenInvoiceDetailDialog = () => {
        this.setState({ invoiceDetailDialogOpen: true });
    };

    handleCloseInvoiceDetailDialog = () => {
        this.setState({ invoiceDetailDialogOpen: false });
    };

    handleOpenInvoiceUpdatePaidDate = () => {
        this.setState({ invoiceUpdatePaidDateOpen: true });
    };

    handleCloseInvoiceUpdatePaidDate = () => {
        this.setState({ invoiceUpdatePaidDateOpen: false });
    };

    render() {
        const { invoiceId, fullScreen } = this.props;
        const { pdfFile, isLoading,invoice } = this.state;
        return this.state.notFound ? (
            <InvoiceDeleted />
        ) : (
            <Fragment>
                <Grid container spacing={16} style={{ position: "relative", minHeight: "200px" }}>
                    <Loading showed={isLoading} />
                    <Grid item xs={12}>
                        <Paper className="org-paper-form" style={{ borderRadius: "0px 0px 2px 2px" }}>
                            <Grid container spacing={16}>
                                {this.state.invoice.status === "paid" ? (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                id="receiptNumber"
                                                label="Receipt Number"
                                                margin="dense"
                                                fullWidth
                                                value={this.state.invoice.receiptNumber}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                id="paymentDate"
                                                label="Payment Date"
                                                margin="dense"
                                                fullWidth
                                                value={moment(this.state.invoice.paidAt).format("DD MMM YYYY")}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                id="paymentNote"
                                                label="Payment Note"
                                                margin="dense"
                                                fullWidth
                                                value={this.state.invoice.paymentNote}
                                                disabled
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="no" label="Invoice No." margin="dense" fullWidth value={this.state.invoice.no} disabled />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                id="total"
                                                label="Total"
                                                margin="dense"
                                                fullWidth
                                                value={
                                                    (this.state.invoice && this.state.invoice.payee && this.state.invoice.payee.currency === "GBP"
                                                        ? "£"
                                                        : "$") + parseFloat(this.state.invoice.total).toLocaleString()
                                                }
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                id="dueDate"
                                                label="Due Date"
                                                margin="dense"
                                                fullWidth
                                                value={moment(this.state.invoice.dueDate).format("DD MMM YYYY")}
                                                disabled
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}

                                <Grid item xs={12} md={3} sm={6} style={{ paddingTop:35 }}>
                                    {/* {(this.props.profileStore.currentProfile && (this.state.invoice.payerId === this.props.profileStore.currentProfile.id ||
                                        this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal ||
                                        (this.state.invoice.payerId === 0 &&
                                            (this.state.invoice.type === InvoiceType.AdhocDeposit.ordinal ||
                                                this.state.invoice.type === InvoiceType.AdhocBalance.ordinal) &&
                                            this.state.payeeId === this.props.profileStore.currentProfile.id)) &&
                                        this.state.invoice.status !== "paid" &&
                                        this.state.invoice.CanPay) && (
                                            <Button variant="contained" color="primary" onClick={this.handleOpenInvoiceDetailDialog}>
                                                {this.state.invoice.type === InvoiceType.AdhocDeposit.ordinal ||
                                                this.state.invoice.type === InvoiceType.AdhocBalance.ordinal
                                                    ? "Mark as Paid"
                                                    : "Pay Invoice"}
                                            </Button>
                                        )} */}
 {/* everyone could pay right now, not only the payer */}
                                        {(this.props.profileStore.currentProfile && this.state.invoice.status !== "paid" &&
                                            (this.state.invoice.CanPay === 1 || this.state.invoice.CanPay)) && (
                                            <Button variant="contained" color="primary" onClick={this.handleOpenInvoiceDetailDialog}>
                                                Mark as Paid
                                            </Button>
                                        )}

                                        {(this.props.profileStore.currentProfile && (this.state.invoice.status === "paid") &&
                                            (this.state.invoice.CanPay === 1 || this.state.invoice.CanPay)) && (
                                            <Fragment>
                                                <Button variant="contained" color="primary" onClick={this.handleUnPayInvoiceClick(invoiceId)}>
                                                    Mark as Unpaid 
                                                </Button>
                                                <Button style={{marginLeft: 5}} variant="contained" color="primary" onClick={this.handleOpenInvoiceUpdatePaidDate}>
                                                    Edit Paid Date
                                                </Button>
                                            </Fragment>
                                        )}


                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <PdfViewer file={pdfFile} invoiceId={this.props.invoiceId} receiptNumber={invoice.no} />
                    </Grid>
                </Grid>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.invoiceDetailDialogOpen}
                    onClose={this.handleCloseInvoiceDetailDialog}
                    transition={this.transition}
                    className="dialog-div"
                >
                    <DialogTitle className="see-more-dialog-title">{"Pay Invoice #" + this.state.invoice.no}</DialogTitle>
                    <DialogContent className="see-more-dialog-content">
                        <div style={{ padding: 16 }}>
                            <Grid container spacing={16}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="receiptNumber"
                                        label="Receipt Number"
                                        margin="dense"
                                        fullWidth
                                        value={this.state.receiptNumber}
                                        onChange={this.handleReceiptNumberChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="paymentNote"
                                        label="Payment Note"
                                        margin="dense"
                                        multiline
                                        rows="4"
                                        fullWidth
                                        value={this.state.paymentNote}
                                        onChange={this.handlePaymentNoteChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        value={this.state.paidDate}
                                        fullWidth
                                        margin="dense"
                                        format="ddd, DD-MM-yyyy"
                                        onChange={this.handleFromChange}
                                        keyboardIcon={<EventIcon />}
                                        leftArrowIcon={<KeyboardArrowLeftIcon />}
                                        rightArrowIcon={<KeyboardArrowRightIcon />}
                                        label="Paid Date"
                                    />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                               
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleCloseInvoiceDetailDialog}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handlePayInvoiceClick(invoiceId)}>
                            Pay
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.invoiceUpdatePaidDateOpen}
                    onClose={this.handleCloseInvoiceUpdatePaidDate}
                    transition={this.transition}
                    className="dialog-div"
                    minHeight="500px"
                    minWidth="300px"
                >
                    <DialogTitle className="see-more-dialog-title">{"Update Paid Date Invoice #" + this.state.invoice.no}</DialogTitle>
                    <DialogContent className="see-more-dialog-content">
                        <div style={{ padding: 16,paddingBottom:100,paddingTop:30 }}>
                            <Grid container spacing={16}>
                        
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        value={this.state.paidDate}
                                        fullWidth
                                        margin="dense"
                                        format="ddd, DD-MM-yyyy"
                                        onChange={this.handleFromChange}
                                        keyboardIcon={<EventIcon />}
                                        leftArrowIcon={<KeyboardArrowLeftIcon />}
                                        rightArrowIcon={<KeyboardArrowRightIcon />}
                                        label="Paid Date"
                                    />
                                    </MuiPickersUtilsProvider>
                               
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleCloseInvoiceUpdatePaidDate}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handleUpdatePaidDate(invoiceId)}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default withMobileDialog()(InvoiceDetail);
