import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import SelectField from "../../components/fields/SelectField";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
const ENABLE_MYOB = process.env.REACT_APP_ENABLE_MYOB === "true";
@inject("authStore", "templateStore", "userStore","profileStore","myobStore")
@observer
class CustomerMapping extends Component {
    state = {
        myobActive: false,
        myobAccount: "",
        editedId: "",
        updateProcess: false,
        myobUID: "",
        mappedId: "",
        newPayerId:"",
        openRemoveContactModalState: false,
        removedId : null,
        editedMYOBId: ""
    };
    customerMappingColumn = [
        { key: "customers", label: "Hot Giggity Customers" },
        { key: "myobCustomers", label: "MYOB Customers" },
        { key: "status", label: "Status" },
        { key: "action", label: "" }
    ];
    
    
    customerMappingRows = [];
    componentDidMount = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(ENABLE_MYOB && currentProfile && currentProfile.activateMYOBAccess && currentProfile.myobCompanyUri){
            Promise.all([
                this.props.myobStore.getListMYOBMappedCustomers(currentProfile.id),
                this.props.myobStore.getMYOBCustomers(currentProfile.id)
            ]).then(() => { 
      
            });
        }
       
    };
    openRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: true,
            removedId : id
        });
    };
    closeRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: true,
            removedId : null
        });
    };
    handleMappedId = (payerId,myobUID) => {
        this.props.myobStore.setEditedMYOB(payerId,myobUID)

    }
    setEditedCustomer = value =>  {
        const { editedMYOBId } = this.state
        if(this.state.editedId && !editedMYOBId){
            this.props.myobStore.cancelEditedMYOB(this.state.editedId)
        }
        if(editedMYOBId){
            this.props.myobStore.cancelUpdatedMYOB(this.state.editedId)
        }
        this.setState({
            editedId: value,
            updateProcess: false,
            editedMYOBId: ""
        });
    }

    setUpdatedCustomer = (data) =>  {
        const { editedMYOBId } = this.state
        if(this.state.editedId && !editedMYOBId){
            this.props.myobStore.cancelEditedMYOB(this.state.editedId)
        }
        if(editedMYOBId){
            this.props.myobStore.cancelUpdatedMYOB(this.state.editedId)
        }
        this.props.myobStore.setUpdatedMYOB(data.payerId)
        // this.props.myobStore.cancelEditedMYOB(existingUID)
        this.setState({
            editedId: data.payerId,
            editedMYOBId: data.myobUID,
            updateProcess: true
        });
    }

    cancelEditedCustomer = (payerId) =>{
       
        const { editedMYOBId } = this.state
        if(editedMYOBId){
            this.props.myobStore.cancelUpdatedMYOB(payerId)
        }else{
            this.props.myobStore.cancelEditedMYOB(payerId)
        }
        this.setState({
            editedId: "",
            editedMYOBId: "",
            updateProcess:false
        });
        
        
    }
    

    handleDeleteContact = () => {
        const { removedId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        this.props.myobStore.deleteMYOBMapping(removedId).then(result =>  {
            Promise.all([
                this.props.myobStore.getListMYOBMappedCustomers(currentProfile.id),
                this.props.myobStore.getMYOBCustomers(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    openRemoveContactModalState: false,
                    removedId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
        
    }

    handleOpenConfirmCreateNewCustomer = id => {
    
        this.setState({
            confirmNewCustomerModalState: true,
            newPayerId: id
        });
    };

    handleCloseConfirmNewCustomerInvitationModal = () => {
        this.setState({
            ...this.state,
            confirmNewCustomerModalState: false,
            newPayerId: undefined
        });
    };

    saveEditedCustomer = (mappedId, payerId,myobUID) =>{
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        if(this.state.updateProcess){
            this.props.myobStore.updateContactMYOB(mappedId, myobUID).then(result =>  {
                Promise.all([
                    this.props.myobStore.getListMYOBMappedCustomers(currentProfile.id),
                    this.props.myobStore.getMYOBCustomers(currentProfile.id)
                ]).then(() => { 
                    // this.props.myobStore.cancelEditedMYOB(payerId)
                    this.setState({
                        editedId: "",
                        editedMYOBId: ""
                    });
                });
              
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
               
            })
        }
        else{
            this.props.myobStore.mapExistingMYOBCustomer(currentProfile.id, payerId, myobUID).then(result =>  {
                Promise.all([
                    this.props.myobStore.getListMYOBMappedCustomers(currentProfile.id),
                    this.props.myobStore.getMYOBCustomers(currentProfile.id)
                ]).then(() => { 
                    // this.props.myobStore.cancelEditedMYOB(payerId)
                    this.setState({
                        editedId: ""
                    });
                });
              
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
               
            })
        }
       
        
    }
    handleCreateNewContact = () => {
        const { newPayerId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        this.props.myobStore.createMYOBCustomers(currentProfile.id, newPayerId).then(result =>  {
            Promise.all([
                this.props.myobStore.getListMYOBMappedCustomers(currentProfile.id),
                this.props.myobStore.getMYOBCustomers(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    confirmNewCustomerModalState: false,
                    newPayerId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
       
    }

    
    mapCustomersToRowData = () => {
        const { listMYOBMappedCustomers,myobCustomers,myobCustomersFull } = this.props.myobStore;
        
        const {editedId} = this.state
        this.customerMappingRows = [];

        listMYOBMappedCustomers.forEach(data => {

            var row = {};

            row.customers = data.profileName ? data.profileName : data.firstName + " " + data.lastName

            if(data.myobUID && !data.isEdited && !data.isUpdated){
                var existingUID = myobCustomersFull.filter(x=>x.value === data.myobUID)
                if(existingUID.length){
                    row.myobCustomers = <TextField
                    // label="MYOB Customer Name"
                    inputProps={{
                        readOnly: true
                    }}
                    value={existingUID[0].label}
                    fullWidth
                />
                }
               
            }else if(data.payerId != editedId){
                row.myobCustomers = <TextField
                // label="MYOB Customer Name"
                inputProps={{
                    readOnly: true
                }}
                value="Map MYOB Account"
                fullWidth
            />
            }else{
                row.myobCustomers =  <SelectField
                id="myobCustomers"
                value={data.myobUID}
                suggestions={myobCustomers}
                placeholder="Map MYOB Account"
                disabled={data.payerId != editedId}
                onChange={(value) => this.handleMappedId(data.payerId,value)}
            />
            }
            
           
            
           
            row.status = data.myobUID ? ((data.isEdited || data.isUpdated) ? "Confirm?" : "Mapped" ) : "Not Mapped";

            if(data.myobUID && !data.isEdited && !data.isUpdated){
                row.action = (
                    <Fragment>
                         <Button size="small" color="primary" onClick={() => this.setUpdatedCustomer(data)}>
                            Update
                        </Button>
                        <Button size="small" color="primary" onClick={() => this.openRemoveContactModal(data.mappedId)}>
                            Remove
                        </Button>
                    </Fragment>
                );
    
            }else{
                if(data.isEdited || data.isUpdated){
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.saveEditedCustomer(data.mappedId,data.payerId,data.myobUID)}>
                                Save
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.cancelEditedCustomer(data.payerId)}>
                                Cancel
                            </Button>
                        </Fragment>
                        
                    );
                   
                }else{
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.setEditedCustomer(data.payerId)}>
                                Map Existing
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.handleOpenConfirmCreateNewCustomer(data.payerId)}>
                                Create New
                            </Button>
                        </Fragment>
                        
                    );
                }
                
            }
           
            // row.style = {
            //     backgroundColor: "#FFC0CB"
            // };

            this.customerMappingRows.push(row);
        });
        return Promise.resolve();
    };

    render() {
        this.mapCustomersToRowData()
        return (
            <div>
                <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                Your customers are the contacts, venues or agents that are listed in your outgoing invoices section.
                                            </Typography>
                                            <Typography gutterBottom>
                                                Mapping your Hot Giggity customers to your MYOB customers allows the system to sync invoices to the correct contact in MYOB
                                            </Typography>
                                            <Typography gutterBottom>
                                                You will only have to do this once. New Hot Giggity customers added after the initial mapping will automatically be added to your MYOB account.
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item md={12}>
                                                        <Typography gutterBottom>Step 3. Map your Hot Giggity customers your existing MYOB customers.</Typography>

                                                        
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <ResponsiveTable
                                                            columns={this.customerMappingColumn}
                                                            rows={this.customerMappingRows}
                                                            showToolbar={true}
                                                            title="Customer Mapping"
                                                            pagination={this.customerMappingRows.length > 5}
                                                        />

                                                    </Grid>  
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>

                <ConfirmationModal
                    open={this.state.confirmNewCustomerModalState}
                    message="Are you sure you want to create a new MYOB contact for this contact?"
                    closeHandler={this.handleCloseConfirmNewCustomerInvitationModal}
                    confirmationHandler={this.handleCreateNewContact}
                    declineHandler={this.handleCloseConfirmNewCustomerInvitationModal}
                    confirmationLabel="Confirm"
                />
                 <ConfirmationModal
                    open={this.state.openRemoveContactModalState}
                    message="Are you sure you want to remove the existing connection to MYOB contact?"
                    closeHandler={this.closeRemoveContactModal}
                    confirmationHandler={this.handleDeleteContact}
                    declineHandler={this.closeRemoveContactModal}
                    confirmationLabel="Confirm"
                />
            </div>
            
        );
    }
}

export default CustomerMapping;
