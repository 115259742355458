import React, { Component, Fragment } from "react";

import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { ProfileType, BookingStatusType } from "../../../types/enum";

import SelectField from "../../fields/SelectField";

import { Grid, TextField } from "@material-ui/core";
import Countries from "../../../lookup/Country";
import moment from "moment";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { Storage } from "aws-amplify";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

@inject("bookingRequestStore", "profileStore", "templateStore", "actTypeStore")
@withRouter
@observer
class BookingActDetails extends Component {
    actSuggestions = [];

    getLineupsType = () => {
        let actTypes = [
            { name: "Solo", instrument: 1 },
            { name: "Duo", instrument: 2 },
            { name: "Trio", instrument: 3 },
            { name: "Quartet / 4 Piece", instrument: 4 },
            { name: "Quintet / 5 Piece", instrument: 5 },
            { name: "Sextet / 6 Piece", instrument: 6 }
        ];

        for (var i = 0; i < 93; i++) {
            actTypes.push({ name: i + 7 + " Piece", instrument: i + 7 });
        }
        return actTypes.map(actType => {
            return {
                value: actType.instrument,
                label: actType.name
            };
        });
    };

    componentDidMount() {
        this.props.actTypeStore.loadActTypes().then(() => {

        });
    }

    handleActChange = (value, firstLoad) => {
        const { bookableActs } = this.props.bookingRequestStore;
        this.props.bookingRequestStore.setBooking("actId", value);
        const currentProfile = this.props.profileStore.getCurrentProfile()
        const {booking} = this.props.bookingRequestStore

        if (!this.props.readOnly) {
            this.props.actChange(true)
        }
        this.setTempTitle(firstLoad);
        if (bookableActs) {
            var act = bookableActs.filter(x => x.id === value)[0];
            if (act) {
                let actTypeId = act.actTypeId;
                let lineupCount = act.lineUpCount;
                if (booking.actType !== actTypeId && act?.id == booking?.act?.id) actTypeId = booking.actType;
                if (booking.actLineup !== lineupCount && act?.id == booking?.act?.id) lineupCount = booking.actLineup;
                this.props.bookingRequestStore.setBooking("actType", actTypeId);
                this.props.bookingRequestStore.setBooking("actLineup", lineupCount);
                this.props.bookingRequestStore.setBooking("act", act);
            }else if(!value){
                this.props.bookingRequestStore.setBooking("actType", undefined);
                this.props.bookingRequestStore.setBooking("actLineup", undefined);
                this.props.bookingRequestStore.setBooking("act", null);
                this.props.bookingRequestStore.setBooking("actId", 0);
            }
        }
    };

    handleActTypeChange = value => {
        this.props.bookingRequestStore.setBooking("actType", value);
        // if (this.props.bookingRequestStore.booking.act == null) {
            this.props.bookingRequestStore.getBookableActs();
        // }
    };

    handleActLineupChange = value => {
        this.props.bookingRequestStore.setBooking("actLineup", value);
        // if (this.props.bookingRequestStore.booking.act == null) {
            this.props.bookingRequestStore.getBookableActs();
        // }
    };

    handleDownloadInsuranceDocument = () => {
        const act = this.props.bookingRequestStore.booking.act
        Storage.get(act.insuranceFileContentUrl)
            .then(result => window.open(result))
            .catch(err => console.log(err));
    }

    mapActSuggestions = () => {
        const { bookableActs } = this.props.bookingRequestStore;

        if (bookableActs) {
            return bookableActs.map(act => {
                return {
                    value: act.id,
                    label: act.actName
                };
            });
        }
        return [];
    };

    setTempTitle = (firstLoad) => {
        const { bookableActs, booking } = this.props.bookingRequestStore;
        const { actId, venue, venueName } = booking;
        var title = "";

        var act = bookableActs ? bookableActs.filter(x => x.id === actId)[0] : null;
        title = `${act ? act.actName : ""} @ ${venue ? venue.profileName : venueName}`;

        this.props.bookingRequestStore.setBooking("tempTitle", title);
        if(!firstLoad) this.props.bookingRequestStore.setBooking("title", title);
        if (!booking?.actFee) this.props.bookingRequestStore.setBooking("actFee", 0);
        this.props.bookingRequestStore.setBooking("actName", act ? act.actName : "");
        this.props.bookingRequestStore.setTempBookingTitle();
    };

    mapActTypeSuggestions = () => {
        const { actTypes } = this.props.actTypeStore;
        return actTypes.map(actType => {
            return {
                value: actType.id,
                label: actType.name
            };
        });
    };

    anyError(field) {
        return false;
    }

    errorText(field) {
        // if (!this.props.generalProfileStore.errors) return null;
        // if (!this.props.generalProfileStore.errors[field]) return null;
        // return this.props.generalProfileStore.errors[field];
    }

    getActTypeValue = (id) => {
        const { actTypes } = this.props.actTypeStore;
        let result = ""
        actTypes.forEach(x => {
            if (x.id == id) result = x.name
        })
        return result
    }

    getLineupValue = (id) => {
        let actTypes = [
            { name: "Solo", instrument: 1 },
            { name: "Duo", instrument: 2 },
            { name: "Trio", instrument: 3 },
            { name: "Quartet / 4 Piece", instrument: 4 },
            { name: "Quintet / 5 Piece", instrument: 5 },
            { name: "Sextet / 6 Piece", instrument: 6 }
        ];

        let result = ""
        actTypes.forEach(x => {
            if (x.instrument == id) result = x.name
        })
        return result
    }

    render() {
        const { error, booking, isActLoaded } = this.props.bookingRequestStore;

        const { actId, act, status, bookingRequests, createdBy, isAdmin,bookingRequest, actType, actLineup, forceAct } = booking;

        const { readOnly } = this.props;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        var isMusician = currentProfile && currentProfile?.type === ProfileType.Musician.ordinal;
        var isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal;
        var isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        let countryName = "";
        // if (!act && this.props.bookingRequestStore.actId && this.props.bookingRequestStore.actId != undefined && this.props.bookingRequestStore.actId != "undefined") {
        //     this.handleActChange(parseInt(this.props.bookingRequestStore.actId));
        // }

        let resentAct = booking.bookingRequests && booking.bookingRequests.length > 0 && booking.bookingRequests[booking.bookingRequests.length - 1].act;
        var bookingRequestName = bookingRequests?.map(x=>x.act?.actName)

        if(currentProfile?.type != ProfileType.Staff.ordinal && booking.createdById != currentProfile?.id){
            var bookingRequestSpecific = bookingRequests?.filter(x=>x.act?.actAdminId === currentProfile?.id)
            bookingRequestName = bookingRequestSpecific?.map(x=>x.act?.actName)
        }

        if (actId) {
            this.handleActChange(parseInt(actId), true)
        }
        var selectedAct = act;
        if (resentAct) {
            selectedAct = resentAct;
        }
        if (selectedAct) {
            let country = Countries.filter(x => x.code === selectedAct.countryCode);
            if (country.length > 0) {
                countryName = country[0].name;
            }
        }
        let insurancePdfLink = "";
        if (selectedAct) {
            insurancePdfLink = `${API_ROOT}/document/${selectedAct.insuranceFileGuid}/${selectedAct.insuranceFileName}`;
        }
        var canModify = booking && currentProfile && booking.createdById === currentProfile?.id
        return (
            <Grid>
            { resentAct ? 
             <TextField
                label="Act Name"
                inputProps={{
                    readOnly: true
                }}
                value={bookingRequestName.length ? bookingRequestName?.join(", "): ""}
                fullWidth
            />     :
               <Grid container spacing={8}>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    {readOnly ? 
                        <TextField
                            label="Act Type"
                            inputProps={{
                                readOnly: true
                            }}
                            value={this.getActTypeValue(actType)}
                            fullWidth
                        />    :
                        <SelectField
                            id="bookingActType"
                            label="Act Type"
                            value={actType}
                            suggestions={this.mapActTypeSuggestions()}
                            placeholder="Select Act Type"
                            onChange={this.handleActTypeChange}
                        />
                }
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    {readOnly ?
                        <TextField
                            label="Lineup"
                            inputProps={{
                                readOnly: true
                            }}
                            value={this.getLineupValue(actLineup)}
                            fullWidth
                        />    :    
                        <SelectField
                            id="bookingActLineupType"
                            label="Lineup"
                            value={actLineup}
                            suggestions={this.getLineupsType()}
                            placeholder="Select Lineup Numbers"
                            onChange={this.handleActLineupChange}
                        />
                }
                </Grid>
                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                    {
                        (
                            isAgent ||
                            isStaff ||
                            isMusician
                        ) &&
                            (
                                status === BookingStatusType.Resent.ordinal
                            ) && !bookingRequest && !forceAct ?
                            (createdBy &&
                                status === BookingStatusType.Resent.ordinal && currentProfile?.id == booking.createdById ? (
                                <TextField
                                    label="Act Name"
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    value={selectedAct ? selectedAct.actName : ""}
                                    onChange={this.handleActNameChange}
                                    fullWidth
                                />
                                // <SelectField
                                //     id="bookingAct"
                                //     label="Acts"
                                //     multi={true}
                                //     suggestions={this.mapActSuggestions()}
                                //     value={bookingRequests ? bookingRequests.filter(x => !x.deleted).map(y => y.actId) : 0}
                                //     placeholder={isActLoaded ? "Select Act" : "Hang tight... we're checking for available acts for this date and time"}
                                //     disabled={true}
                                //     emptyText="No act available on selected times"
                                // />
                            ) : (
                                <SelectField
                                    id="bookingAct"
                                    label="Act"
                                    suggestions={this.mapActSuggestions()}
                                    onChange={this.handleActChange}
                                    value={resentAct ? resentAct.id : actId}
                                    placeholder={isActLoaded ? "Select Act" : "Hang tight... we're checking for available acts for this date and time"}
                                    error={error && error["act"]}
                                    helperText={error && error["act"] ? error["act"] : ""}
                                    emptyText="No act available on selected times"
                                />
                            )
                            ) : isMusician && bookingRequest ? (
                                <TextField
                                    label="Act Name"
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    value={bookingRequest.act.actName}
                                    fullWidth
                                />
                            ) : !readOnly && (canModify || (isAdmin && (currentProfile?.type === ProfileType.Staff.ordinal || currentProfile?.type === ProfileType.Agent.ordinal)) || !booking || !booking.id) ? (
                                <SelectField
                                    id="bookingAct"
                                    label="Act"
                                    suggestions={this.mapActSuggestions()}
                                    onChange={this.handleActChange}
                                    value={resentAct ? resentAct.id : actId}
                                    placeholder={isActLoaded ? "Select Act" : "Hang tight... we're checking for available acts for this date and time"}
                                    error={error && error["act"]}
                                    helperText={error && error["act"] ? error["act"] : ""}
                                    emptyText="No act available on selected times"
                                />
                            ) : 
                            <TextField
                                    label="Act Name"
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    value={selectedAct ? selectedAct.actName : ""}
                                    onChange={this.handleActNameChange}
                                    fullWidth
                                />
                        }
                </Grid>
                {selectedAct &&
                    !isMusician && (
                        <Fragment>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.email || ""}
                                    readOnly
                                    error={this.anyError("email")}
                                    helperText={this.errorText("email")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="contactPhone"
                                    label="Phone"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.contactPhone || ""}
                                    readOnly
                                    error={this.anyError("contactPhone")}
                                    helperText={this.errorText("contactPhone")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="street"
                                    label="Street Address"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.street || ""}
                                    readOnly
                                    error={this.anyError("street")}
                                    helperText={this.errorText("street")}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="city"
                                    label="Suburb"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.city || ""}
                                    onChange={this.handleCityChange}
                                    error={this.anyError("city")}
                                    helperText={this.errorText("city")}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="postal"
                                    label="Postal/Zip Code"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.postal || ""}
                                    readOnly
                                    error={this.anyError("postal")}
                                    helperText={this.errorText("postal")}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="state"
                                    label="State"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.state || ""}
                                    readOnly
                                    error={this.anyError("state")}
                                    helperText={this.errorText("state")}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="countries"
                                    label="Country"
                                    margin="dense"
                                    fullWidth
                                    value={countryName || ""}
                                    readOnly
                                    error={this.anyError("country")}
                                    helperText={this.errorText("country")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="abn"
                                    label="ABN"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.abn || ""}
                                    readOnly
                                    error={this.anyError("abn")}
                                    helperText={this.errorText("abn")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="insurancePolicyHolder"
                                    label="Policy Holder Name"
                                    margin="dense"
                                    fullWidth
                                    readOnly
                                    value={selectedAct.insurancePolicyHolder || ""}
                                    error={this.anyError("insurancePolicyHolder")}
                                    helperText={this.errorText("insurancePolicyHolder")}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="insuranceCompany"
                                    label="Insurance Company"
                                    margin="dense"
                                    fullWidth
                                    readOnly
                                    value={selectedAct.insuranceCompany || ""}
                                    error={this.anyError("insuranceCompany")}
                                    helperText={this.errorText("insuranceCompany")}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="insurancePolicyNumber"
                                    label="Insurance Policy Number"
                                    margin="dense"
                                    fullWidth
                                    readOnly
                                    value={selectedAct.insurancePolicyNumber || ""}
                                    error={this.anyError("insurancePolicyNumber")}
                                    helperText={this.errorText("insurancePolicyNumber")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="insuranceExpiryDate"
                                    label="Insurance Expiry Date"
                                    margin="dense"
                                    fullWidth
                                    readOnly
                                    value={selectedAct.insuranceExpiryDate ? moment(selectedAct.insuranceExpiryDate).format("DD-MM-YYYY") : ""}
                                    error={this.anyError("insuranceExpiryDate")}
                                    helperText={this.errorText("insuranceExpiryDate")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                {selectedAct.insuranceFileGuid && (
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="insuranceFileName">Insurance File</InputLabel>
                                        <Input
                                            id="insuranceFileName"
                                            value={selectedAct.insuranceFileName}
                                            startAdornment={
                                                <InputAdornment
                                                    onClick={this.handleDownloadInsuranceDocument}
                                                    position="start"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <a target="_blank">
                                                        <CloudDownload />
                                                    </a>
                                                </InputAdornment>
                                            }
                                            readOnly={true}
                                        />
                                    </FormControl>
                                )}
                            </Grid>
                        </Fragment>
                    )}
            </Grid>
        } 
        </Grid>
        );
    }
}

export default BookingActDetails;
