import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import { Grid, Typography, Paper } from "@material-ui/core";
import SongListTable from "../../components/songListTable/SongListTable";

@inject("songListStore", "profileStore")
@observer
class SongList extends Component {
    render() {
        const breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Manage", link: "/" }, { name: "My Song List" }];
        const { currentProfile } = this.props.profileStore;

        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} /> <Heading title="My Song List" />
                    <Paper style={{ padding: 16 }}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} lg={3}>
                                <div className="sticky-information">
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Song List
                                    </Typography>
                                    <Typography gutterBottom className="side-helper-text">
                                        Use this section to create your master song list. If you have audio or video of you performing this song add
                                        it using the Media URL field. Select 12 songs for your Top 12 which will appear on your public facing profile.
                                    </Typography>
                                    <Typography>
                                        <b> Tip: </b>
                                    </Typography>
                                    <Typography className="side-helper-text">
                                        {" "}
                                        Be sure to spell the song title and artist names correctly!{" "}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        {currentProfile && (
                                            <SongListTable
                                                onListAdd={this.handleAddSongList}
                                                onListRemove={this.handleRemoveSong}
                                                onListSort={this.handleSortSongList}
                                                label="Song List"
                                                profileId={currentProfile.id}
                                                type="musician"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </MainContainer>
            </main>
        );
    }
}

export default SongList;
