import React, { Component, Fragment } from "react";

import { Grid } from "@material-ui/core";

import "./Heading.css";

class Heading extends Component {
    state = {
        headingDiv: undefined,
        height: 0
    };

    headingDiv = undefined;

    componentDidMount() {
        if (this.headingDiv) {
            const height = this.headingDiv.clientHeight;
            this.setState({ height });
        }
    }

    render() {
        const { title, bottomAction } = this.props;
        const action = this.props.children;
        const flowDirection = this.state.height > 67 ? "column" : "row";

        if (bottomAction) {
            return (
                <div className="widget-title" style={{ marginBottom: 16 }}>
                    <Grid container className="headingContainer">
                        <Grid item className="headingTitle" xs={12} lg={action && !bottomAction ? 4 : 12}>
                            <span className="widget-title-text">{title}</span>
                        </Grid>
                        {action && !bottomAction ? (
                            <Grid item className="headingAction" xs={12} lg={8}>
                                <div className="pull-right">{action}</div>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {bottomAction ? (
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        width: "100%",
                                        float: "right",
                                        display: "flex",
                                        alignItems: "flex-end",
                                        marginTop: "8px"
                                    }}
                                >
                                    {action}
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )}
                    </Grid>
                </div>
            );
        } else {
            return (
                <Fragment>
                    <div
                        className="heading-wrap"
                        ref={divElement => (this.headingDiv = divElement)}
                        style={{ marginBottom: 16, flexDirection: flowDirection }}
                    >
                        <div className="inside inside-left">
                            <div id="heading-title">
                                <span className="widget-title-text">{title}</span>
                            </div>
                        </div>
                        <div className="inside inside-right">
                            <div id="action-div">{action}</div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

export default Heading;
