import React, { Component } from "react";
import SearchResult from "../components/searchPage/searchResult";
import Header from "../components/Header.js";
import Sidebar from "../components/Sidebar";
import MainContainer from "../components/MainContainer";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SearchFilter from "../components/searchPage/SearchFilter";
import Breadcrumb from "../components/Breadcrumb";
import Heading from "../components/Heading";
import FilterListIcon from "@material-ui/icons/FilterList";
import Loading from "../components/loadingSpinner/Loading";
import { inject, observer } from "mobx-react";

@inject("searchStore")
@observer
class SearchPage extends Component {
    breadcrumb = [{ name: "Search" }];

    constructor(props) {
        super(props);
        this.state = {
            searchFilterShowed: true
        };
    }

    toggleSearchFilter = () => {
        this.setState({
            ...this.state,
            searchFilterShowed: !this.state.searchFilterShowed
        });
    };

    render() {
        const { isLoading, query } = this.props.searchStore;
        const { searchFilterShowed } = this.state;
        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title={`Search Result - ${query}`}>
                        <Button style={{ color: "white" }} onClick={this.toggleSearchFilter}>
                            <FilterListIcon /> &nbsp; Filter
                        </Button>
                    </Heading>
                    {searchFilterShowed ? (
                        <Grid container style={{ marginBottom: 24 }}>
                            <Grid item xs={12} lg={12}>
                                <SearchFilter />
                            </Grid>
                        </Grid>
                    ) : null}

                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={12} style={{ position: "relative", minHeight: 400 }}>
                            <Loading showed={isLoading} />
                            <SearchResult />
                        </Grid>
                    </Grid>
                </MainContainer>
            </main>
        );
    }
}

export default SearchPage;
