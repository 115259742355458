import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button, Hidden, FormControl, FormControlLabel, Checkbox, Switch } from "@material-ui/core";


import Loading from "../../components/loadingSpinner/Loading";
import GigPromotionDetails from "./formComponent/GigPromotionDetails";
import GigPromotionDateDetails from "./formComponent/GigPromotionDateDetails";
import GigPromotionActDetails from "./formComponent/GigPromotionActDetails";

import "../../pages/booking/Booking.css";
import GigPromotionSummaryModal from "./formComponent/GigPromotionSummaryModal";
import ConfirmationModal from "../modal/ConfirmationModal";


@inject("profileStore", "gigPromotionStore", "templateStore", "userStore")
@withRouter
@observer
class GigPromotionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summaryModalState: false,
            cancelModalState: false,
            confirmDoubleBookingModalState: false,
            acceptTermsCondition: false,
            errorMessage: "",
            isLoading: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { match } = this.props;
        this.props.gigPromotionStore.resetGigPromotion();
        if (match.params.id) {
            
            this.setState({
                ...this.state,
                isLoading: true
            })
            this.props.gigPromotionStore.getGigPromotionDetail(match.params.id).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            }, () => {
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            })
        }
    }

    openSummaryModal = () => this.setState({ ...this.state, summaryModalState: true });
    closeSummaryModal = () => this.setState({ ...this.state, summaryModalState: false });

    openCancelModal = () => this.setState({ ...this.state, cancelModalState: true });
    closeCancelModal = () => this.setState({ ...this.state, cancelModalState: false });

    handleOpenConfirmDoubleBookingModal = errorMessage =>
        this.setState({ ...this.state, confirmDoubleBookingModalState: true, errorMessage: errorMessage });
    handleCloseConfirmDoubleBookingModal = () => this.setState({ ...this.state, confirmDoubleBookingModalState: false });

    handleSaveGigPromotion = () => {
        this.setState({
            ...this.state,
            isLoading: true
        })
        this.props.gigPromotionStore.saveGigPromotion().then(() => {
            this.setState({
                ...this.state,
                isLoading: false
            })
            this.props.templateStore.openSnackbar("Gig Promotion Saved.");
            this.props.history.goBack();
        })
        .finally(() => {
            this.props.gigPromotionStore.resetGigPromotion();
        })
    }

    handleIsDraftChange = (e, checked) => {
        this.props.gigPromotionStore.setGigPromotion("isDraft", checked);
        this.props.gigPromotionStore.setGigPromotion("published", !checked);
    };

    handlePublishChange = (e, checked) => {
        this.props.gigPromotionStore.setGigPromotion("published", checked);
        if(checked){
            this.props.gigPromotionStore.setGigPromotion("isDraft", false);
        }
    };

    handleCancelGigPromotion = () => {
        const { gigPromotion } = this.props.gigPromotionStore;

        this.setState({
            ...this.state,
            isLoading: true
        })

        this.props.gigPromotionStore.removeGigPromotion(gigPromotion.id).then( () => {
            this.setState({
                ...this.state,
                isLoading: false
            })
            this.props.templateStore.openSnackbar("Gig Promotion Cancelled.");
            this.props.history.goBack();
        });
    }


    render() {
        const { readOnly } = this.props;
        const { gigPromotion } = this.props.gigPromotionStore;
        const { isLoading, summaryModalState, cancelModalState } = this.state;
        const { currentProfile } = this.props.profileStore;
        return (
            <div style={{ position: "relative", minHeight: "200px" }}>
                <Loading showed = { isLoading }/>
                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDate">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Date and Time Detail
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                The date is already populated from when you chose it from the booking station - Manually input the start and finish
                                time of the gig. The clock is in 24hr time. eg If the gig times are 10pm til 1am simply choose 22.00 til 1.00.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <GigPromotionDateDetails readOnly={readOnly} />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDetail">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Event / Venue Details
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                Manage this basic information - Event name, Provider/Agent, Venue Name, etc. If the event is a ticketed event, enter
                                the Ticket URL in the field allocated to create a link on the venue’s profile page to the ticketing site.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        {currentProfile && <GigPromotionDetails readOnly={readOnly} />}
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }} spacing={8}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDetail">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Act Details
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                Use this section to select the act for this booking. Only acts that are available on the booking date and time will be
                                shown.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        {!isLoading &&
                            <GigPromotionActDetails />
                        }
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: 60 }} spacing={8}>
                    <Grid item xs={12} lg={3} />
                    <Grid item xs={12} lg={9}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} lg={6}>
                                <FormControl variant="outlined" className="save-checkbox">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                className="checkbox-dense"
                                                checked={gigPromotion.isDraft}
                                                onChange={this.handleIsDraftChange}
                                            />
                                        }
                                        label="Save as Draft"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <FormControl variant="outlined" className="save-checkbox">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={gigPromotion.published}
                                                onChange={this.handlePublishChange}
                                            />
                                        }
                                        label="Publish to Hot Giggity Live"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={8}>
                    <Grid item xs={12} lg={3} />
                    <Grid item xs={12} lg={9}>
                        <Hidden smDown>
                            <div className="action pull-right">
                                <Button variant="outlined" color="primary" style={{marginRight: '8px'}} onClick = { this.openCancelModal }>
                                    Cancel Gig Promotion
                                </Button>
                                <Button variant="contained" color="primary" onClick = { this.openSummaryModal }>
                                    Save Gig Promotion
                                </Button>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={{ marginBottom: "12px" }}
                                    color="primary"
                                    onClick = { this.openSummaryModal }
                                >
                                    Save Gig Promotion
                                </Button>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginBottom: 12 }}
                                    onClick = { this.openCancelModal }
                                >
                                    Cancel Gig Promotion
                                </Button>
                                <Button variant="outlined" fullWidth onClick={() => this.props.history.goBack()}>
                                    Back
                                </Button>
                            </div>
                        </Hidden>
                    </Grid>
                </Grid>

                <GigPromotionSummaryModal 
                    open={summaryModalState}
                    onClose={this.closeSummaryModal}
                    processGigPromotion={ this.handleSaveGigPromotion }
                />

                <ConfirmationModal 
                    open = { cancelModalState }
                    title = "Gig Promotion Warning"
                    closeHandler = { this.closeCancelModal }
                    message = "Are you sure to cancel this gig promotion?"
                    confirmationLabel = "Cancel"
                    confirmationHandler = { this.handleCancelGigPromotion }
                />
            </div>
        );
    }
}

export default GigPromotionForm;
