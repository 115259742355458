import React, { Fragment } from "react";
import { inject } from "mobx-react";
import { Tooltip } from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import InboxIcon from "@material-ui/icons/Inbox";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withRouter, Redirect } from "react-router-dom";
import facebook from "../../img/facebook-box.svg";
import InstagramLogin from 'react-instagram-login';

import ConfirmationModal from "../modal/ConfirmationModal";

import jwtDecode from "jwt-decode";
import { ProfileType } from "../../types/enum";

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";
const CLIENT_ID = "3574929162757847"
const instagramMusicianRedirectUri = process.env.REACT_APP_INSTAGRAM_MUSICIAN_REDIRECT_URL
const instagramAgentRedirectUri = process.env.REACT_APP_INSTAGRAM_AGENT_REDIRECT_URL
const instagramVenueRedirectUri = process.env.REACT_APP_INSTAGRAM_VENUE_REDIRECT_URL
@inject("instagramStore", "profileStore", "templateStore", "commonStore")
class InstagramPageDialog extends React.Component {
    state = {
        open: false,
        openAdd: false,
        openConfirmModal: false,
        redirectToPaymentPage: false
    };
    

    handleFacebookShare = response => {
        if (response.status !== "unknown") {
            this.props.templateStore.showLoadingScreen();
            this.props.facebookStore.getPages(response.id, response.accessToken).then(res => {
                this.props.templateStore.hideLoadingScreen();
                this.props.facebookStore.setPages(res);
                this.setState({ open: true });
            });
        }
    };


    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleOpenModal = () => this.setState({ openConfirmModal: true });

    handleCloseModal = () => this.setState({ openConfirmModal: false });

    handleConfirmDisconnect = () => {
        const { profile } = this.props.profileStore;
        this.props.templateStore.showLoadingScreen();
        this.props.instagramStore.disconnectAccount(profile.id).then(resp => {
            this.props.templateStore.hideLoadingScreen();
            this.props.profileStore.resetInstagram();
            this.handleCloseModal();
            this.props.templateStore.openSnackbar("This profile has been disconnected to your instagram account");
        });
    };


    render() {
        const { profile, currentProfile } = this.props.profileStore;
        var returnURI = ""

        if(currentProfile?.type === ProfileType.Musician.ordinal){
            returnURI = instagramMusicianRedirectUri
        }else if (currentProfile?.type === ProfileType.Agent.ordinal){
            returnURI = instagramAgentRedirectUri
        }else if(currentProfile?.type === ProfileType.Venue.ordinal || currentProfile?.type === ProfileType.Staff.ordinal){
            returnURI = instagramVenueRedirectUri
        }
        return (
            <Fragment>
                <Tooltip title={"Share to your Facebook Page"}>
                    {profile && profile.instagramProfile ? (
                         <Button className="instagram-button-login" onClick={this.handleOpenModal}>  <span style={{color:"white", marginTop:-5}}><Icon style={{marginTop:10,marginRight:10}}>
                                <i className={"fab fa-instagram"} />
                            </Icon> {`Connected to ${profile.instagramProfile}`}</span>
                        </Button>
                       
                    )  : (
                        <a
                        href={`https://api.instagram.com/oauth/authorize
                        ?client_id=${CLIENT_ID}&redirect_uri=${returnURI}&scope=user_profile,user_media&response_type=code&state=${
                            currentProfile?.id
                        }`}
                    >
                         {/* <img src={ googleCalendarIcon }  style={{ marginTop: 25,height:40 }} alt="Get It On Google Playstore" width="300px"/>
                         */}
                        <Button className="instagram-button-login">  <span style={{color:"white", marginTop:-5}}><Icon style={{marginRight:10}}>
                                <i className={"fab fa-instagram"} />
                            </Icon> Connect Instagram to HG</span>
                        </Button>
            
                        {/* <Button variant="outlined" style={{ justifyContent: "center", alignItems: "center" }}>
                            Link Google account
                        </Button> */}
                    </a>
                    )}
                </Tooltip>


                <ConfirmationModal
                    open={this.state.openConfirmModal}
                    message="Disconnect this instagram account?"
                    closeHandler={this.handleCloseModal}
                    confirmationHandler={this.handleConfirmDisconnect}
                    declineHandler={this.handleCloseModal}
                    confirmationLabel="Disconnect Instagram"
                    maxWidth="sm"
                />
            </Fragment>
        );
    }
}

export default withMobileDialog()(withRouter(InstagramPageDialog));
