import React, { Component } from "react";
//  import ReactDOM from "react-dom";
import { observer, inject } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";

import {
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
} from "@material-ui/core";
import moment from "moment";

@inject("gigPromotionStore", "profileStore")
@observer
class GigPromotionSummaryModal extends Component {
    state = {
        actError: false,
        actErrorText: ""
    };

    render() {
        const { open, onClose, processGigPromotion } = this.props;
        const { gigPromotion } = this.props.gigPromotionStore;

        return (
            <ModalContainer open={open} onClose={onClose} title="Gig Promotion Summary" coloredDialogTitle={true}>
                <DialogContent>
                    <div id="alert-dialog-description">
                        <br />
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Title"
                                    value={gigPromotion.title ? gigPromotion.title : gigPromotion.tempTitle}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Name"
                                    value={gigPromotion.venue ? gigPromotion.venue.profileName : gigPromotion.venueName}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Contact"
                                    value={gigPromotion.venueContact}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Address"
                                    value={gigPromotion.venueAddress || " "}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Act"
                                    value={ gigPromotion.actName }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Date"
                                    value={`${moment(gigPromotion.dateStart).format("DD-MM-YYYY")}${
                                        moment(gigPromotion.timeStart, "HH:mm").format("hh:mm A") > moment(gigPromotion.timeEnd, "HH:mm").format("hh:mm A")
                                            ? `- ${moment(gigPromotion.dateStart)
                                                  .add(1, "days")
                                                  .format("DD-MM-YYYY")}`
                                            : ""
                                    }`}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Time"
                                    value={
                                        gigPromotion.timeStart === gigPromotion.timeEnd
                                            ? moment(gigPromotion.timeStart, "HH:mm").format("hh:mm A")
                                            : moment(gigPromotion.timeStart, "HH:mm").format("hh:mm A") +
                                              " - " +
                                              moment(gigPromotion.timeEnd, "HH:mm").format("hh:mm A")
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                    <Button color="primary" onClick = { processGigPromotion } className="process-gigPromotion">
                        {
                            gigPromotion.isDraft ? 
                            "Save Gig Promotion Draft" :
                            gigPromotion.published ? 
                            "Publish Gig Promotion" :
                            "Save Gig Promotion"
                        }
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default GigPromotionSummaryModal;
