import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepLabel from "@material-ui/core/StepLabel";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import General from "../../components/profile/General";
import Design from "../../components/profile/Design";
import Social from "../../components/profile/Social";
import Privacy from "../../components/profile/Privacy";
import Embed from "../../components/profile/Embed";
import Songlist from "../../components/profile/Songlist";
import Setlist from "../../components/profile/Setlist";
import Roster from "../../components/profile/Roster";
import ProductionRoster from "../../components/profile/ProductionRoster";
import TermCondition from "../../components/profile/TermCondition";

import "../stepper.css";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import { ProfileType } from "../../types/enum";

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

const SHOW_PRODUCTION_CREW = process.env.REACT_APP_SHOW_PRODUCTION_CREW === "true";

@inject("templateStore", "generalProfileStore", "profileStore", "agentActStore")
@observer
class EditAct extends Component {
    breadcrumb = [
        { name: "Dashboard", link: "/" },
        { name: "Manage", link: "/" },
        { name: "My Acts", link: this.props.agentActStore.isAgentAct === true ? "/agent/actroster/myact" : "/myact" },
        { name: "Edit" }
    ];

    state = {
        titleAct:"Create Act"
    }

    componentDidMount = () => {
        if(this.props.match.params.id) {
            this.props.profileStore.getProfile(this.props.match.params.id)
                .then(profile => {
                    if(profile?.isCompleted) this.setState({titleAct: "Edit Act " + profile?.actName})
                    else this.setState({titleAct: "Create Act " + profile?.actName})
                })
        }
        if(this.props.match.params.tab){
            if(SHOW_PRODUCTION_CREW){
                switch (this.props.match.params.tab) {
                    case "details":
                        this.props.templateStore.setEditProfileActiveStep(0);
                        break;
                    case "design":
                        this.props.templateStore.setEditProfileActiveStep(1);
                        break;
                    case "social":
                        this.props.templateStore.setEditProfileActiveStep(2);
                        break;
                    case "lineup":
                        this.props.templateStore.setEditProfileActiveStep(3);
                        break;
                    case "crew":
                        this.props.templateStore.setEditProfileActiveStep(4);
                        break;
                    case "songlist":
                        this.props.templateStore.setEditProfileActiveStep(5);
                        break;
                    case "setlist":
                        this.props.templateStore.setEditProfileActiveStep(6);
                        break;
                    case "toc":
                        this.props.templateStore.setEditProfileActiveStep(7);
                        break;
                    case "privacy":
                        this.props.templateStore.setEditProfileActiveStep(8);
                        break;
                    default:
                        this.props.templateStore.setEditProfileActiveStep(0);
                        break;
                }
            }
            else{
                switch (this.props.match.params.tab) {
                    case "details":
                        this.props.templateStore.setEditProfileActiveStep(0);
                        break;
                    case "design":
                        this.props.templateStore.setEditProfileActiveStep(1);
                        break;
                    case "social":
                        this.props.templateStore.setEditProfileActiveStep(2);
                        break;
                    case "lineup":
                        this.props.templateStore.setEditProfileActiveStep(3);
                        break;
                    case "songlist":
                        this.props.templateStore.setEditProfileActiveStep(4);
                        break;
                    case "setlist":
                        this.props.templateStore.setEditProfileActiveStep(5);
                        break;
                    case "toc":
                        this.props.templateStore.setEditProfileActiveStep(6);
                        break;
                    case "privacy":
                        this.props.templateStore.setEditProfileActiveStep(7);
                        break;
                    default:
                        this.props.templateStore.setEditProfileActiveStep(0);
                        break;
                }
            }
            
        }
        else{
            this.props.templateStore.setEditProfileActiveStep(0);
        }
    };

    componentDidUpdate = () => {
        if(SHOW_PRODUCTION_CREW){
            switch (this.props.match.params.tab) {
                case "details":
                    this.props.templateStore.setEditProfileActiveStep(0);
                    break;
                case "design":
                    this.props.templateStore.setEditProfileActiveStep(1);
                    break;
                case "social":
                    this.props.templateStore.setEditProfileActiveStep(2);
                    break;
                case "lineup":
                    this.props.templateStore.setEditProfileActiveStep(3);
                    break;
                case "crew":
                    this.props.templateStore.setEditProfileActiveStep(4);
                    break;
                case "songlist":
                    this.props.templateStore.setEditProfileActiveStep(5);
                    break;
                case "setlist":
                    this.props.templateStore.setEditProfileActiveStep(6);
                    break;
                case "toc":
                    this.props.templateStore.setEditProfileActiveStep(7);
                    break;
                case "privacy":
                    this.props.templateStore.setEditProfileActiveStep(8);
                    break;
                default:
                    this.props.templateStore.setEditProfileActiveStep(0);
                    break;
            }
        }
        else{
            switch (this.props.match.params.tab) {
                case "details":
                    this.props.templateStore.setEditProfileActiveStep(0);
                    break;
                case "design":
                    this.props.templateStore.setEditProfileActiveStep(1);
                    break;
                case "social":
                    this.props.templateStore.setEditProfileActiveStep(2);
                    break;
                case "lineup":
                    this.props.templateStore.setEditProfileActiveStep(3);
                    break;
                case "songlist":
                    this.props.templateStore.setEditProfileActiveStep(4);
                    break;
                case "setlist":
                    this.props.templateStore.setEditProfileActiveStep(5);
                    break;
                case "toc":
                    this.props.templateStore.setEditProfileActiveStep(6);
                    break;
                case "privacy":
                    this.props.templateStore.setEditProfileActiveStep(7);
                    break;
                default:
                    this.props.templateStore.setEditProfileActiveStep(0);
                    break;
            }
        }
    };

    getStepContent(stepIndex) {
        if(SHOW_PRODUCTION_CREW){
            switch (stepIndex) {
                case 0:
                    //return "Unknown stepIndex";
                    return <General type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 1:
                    return <Design type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 2:
                    return <Social type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                // case 3:
                //     return <Financial type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 3:
                    return <Roster type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 4:
                    return <ProductionRoster type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 5:
                    return <Songlist type="act" profileId={this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : null} />;
                case 6:
                    return <Setlist type="act" profileId={this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : null} />;
                case 7:
                    return <TermCondition type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 8:
                    return <Privacy type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 9:
                    return <Embed type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                default:
                    return "Unknown stepIndex";
            }
        }   
        else{
            switch (stepIndex) {
                case 0:
                    //return "Unknown stepIndex";
                    return <General type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} setTitleAct={this.setTitleName}/>;
                case 1:
                    return <Design type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 2:
                    return <Social type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                // case 3:
                //     return <Financial type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 3:
                    return <Roster type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 4:
                    return <Songlist type="act" profileId={this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : null} />;
                case 5:
                    return <Setlist type="act" profileId={this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : null} />;
                case 6:
                    return <TermCondition type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 7:
                    return <Privacy type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                case 8:
                    return <Embed type="act" profileId={this.props.match.params.id ? this.props.match.params.id : null} />;
                default:
                    return "Unknown stepIndex";
            }
        }
        
    }

    handleNext = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
    };

    handleBack = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleReset = () => {
        this.props.templateStore.setEditProfileActiveStep(0);
        this.props.history.replace(`/myact/edit/${this.props.match.params.id}/details`);
    };

    handleStepClick = step => () => {
        if(SHOW_PRODUCTION_CREW){
            switch (step) {
                case 0:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/details`);
                    break;
                case 1:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/design`);
                    break;
                case 2:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/social`);
                    break;
                case 3:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/lineup`);
                    break;
                case 4:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/crew`);
                    break;
                case 5:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/songlist`);
                    break;
                case 6:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/setlist`);
                    break;
                case 7:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/toc`);
                    break;
                case 8:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/privacy`);
                    break;
                default:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/details`);
                    break;
            }
        }
        else{
            switch (step) {
                case 0:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/details`);
                    break;
                case 1:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/design`);
                    break;
                case 2:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/social`);
                    break;
                case 3:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/lineup`);
                    break;
                case 4:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/songlist`);
                    break;
                case 5:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/setlist`);
                    break;
                case 6:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/toc`);
                    break;
                case 7:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/privacy`);
                    break;
                default:
                    this.props.history.replace(`/myact/edit/${this.props.match.params.id}/details`);
                    break;
            }
        }
        //this.props.templateStore.setEditProfileActiveStep(step);
    };

    checkPathForCreateAct(){
        if(this.props.location.pathname=="/myact/create"){
            return true;
        }
        return false;
    }

    setTitleName = (name) => {
        this.setState({titleAct:name})
    }

    render() {
        const { classes } = this.props;
        const steps = this.props.templateStore.getSteps("act");
        const { editProfileActiveStep } = this.props.templateStore;
        const { actNameTitle } = this.props.generalProfileStore;
        const { profile } = this.props.profileStore;

        if (profile?.type === ProfileType.Agent.ordinal) {
            this.props.agentActStore.setIsAgentAct(true)
        }
        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title={this.state.titleAct} />
                    <Hidden smDown>
                        <Stepper nonLinear={profile && profile.isCompleted} activeStep={editProfileActiveStep} alternativeLabel={false}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepButton onClick={this.handleStepClick(index)} disabled={this.checkPathForCreateAct()} >{label}</StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    <Hidden mdUp xsDown>
                        <Stepper nonLinear={profile && profile.isCompleted} activeStep={editProfileActiveStep} alternativeLabel={true}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepButton onClick={this.handleStepClick(index)} disabled={this.checkPathForCreateAct()}>{label}</StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    <Hidden smUp>
                        <Stepper
                            nonLinear={profile && profile.isCompleted}
                            activeStep={editProfileActiveStep}
                            alternativeLabel={false}
                            className="stepperXs"
                            connector={<span>&nbsp;</span>}
                        >
                            {steps.map((label, index) => {
                                return (
                                    <Step
                                        classes={{
                                            root: "stepper-xs-step"
                                        }}
                                        key={label}
                                    >
                                        <StepLabel
                                            classes={{
                                                iconContainer: "stepper-xs-icon"
                                            }}
                                            onClick={this.handleStepClick(index)}
                                        />
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    {editProfileActiveStep === steps.length ? (
                        <Paper className="stepperContent">
                            <Typography className={classes.instructions} component="h3">
                                All steps completed - you're profile is ready!
                            </Typography>
                            <Button onClick={this.handleReset}>Reset</Button>
                        </Paper>
                    ) : (
                        <Paper className="stepperContent">{this.getStepContent(editProfileActiveStep)}</Paper>
                    )}
                </MainContainer>
            </div>
        );
    }
}

EditAct.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(EditAct);
