import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";

import logo from "../../img/hotgiggity-logo.png";

@withRouter
class Unauthorized extends Component {
    handleBack = () => {
        this.props.history.goBack();
    };

    handleGoToMain = () => {
        this.props.history.push("/");
    };

    render() {
        return (
            <Fragment>
                <img src={logo} alt="Logo" style={{ position: "absolute", top: 16, left: 16, width: 150 }} />

                <Hidden smDown>
                    <Grid container className="error-404-container" spacing={0}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="display4" className="error-404-big">
                                401
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="display2">
                                Unauthorized
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="display1">
                                Sorry you don't have any permission to access the page
                            </Typography>
                        </Grid>
                        {this.props.history.length > 2 && (
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Button onClick={this.handleBack} variant="outlined">
                                    Go to Previous Page
                                </Button>
                            </Grid>
                        )}
                        {this.props.history.length <= 2 && (
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Button onClick={this.handleGoToMain} variant="outlined">
                                    Go to Main Page
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid container className="error-404-container" spacing={0}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="display4" className="error-404">
                                401
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="headline">
                                Unauthorized
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="title">
                                Sorry you don't have any permission to access the page
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
            </Fragment>
        );
    }
}
export default Unauthorized;
