import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import queryString from "query-string";

import { Button, TextField, Typography } from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";

import "./Login.css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import logo from "../img/hotgig-logo.png";


@inject("userStore", "authStore")
@observer
class CreateNewPassword extends Component {
    state = {
        requestSuccess: false
    };

    setSignature = password => this.props.authStore.setPassword(password);
    setEmail = email => this.props.authStore.setEmail(email);
    handleNewPasswordChange = e => this.props.authStore.setNewPassword(e.target.value);
    handleConfirmPasswordChange = e => this.props.authStore.setConfirmPassword(e.target.value);
    validateNewPassword= e => this.props.authStore.validateNewPassword(e.target.value)
    validateConfirmPassword = (e) =>  this.props.authStore.validateConfirmPasswor(e.target.value)

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    handleSubmitForm = e => {
        e.preventDefault();
        if(this.props.authStore.values.newPassword.length > 0 && this.props.authStore.values.confirmPassword.length > 0) {
            if(this.props.authStore.errors.newPassword || this.props.authStore.errors.confirmPassword) {
                return
            } else {
                this.props.authStore.createNewPassword().then(() => {
                    this.setState({ requestSuccess: true });
                    this.props.authStore.reset();
                });
            }
        } else {
            return
        }
    };

    render() {
        const { values } = this.props.authStore;

        let params = queryString.parse(this.props.location.search);
        let signature = params.signature;
        let email = params.email;
        this.setSignature(signature);
        this.setEmail(email);

        return (
            <div className="Login">
                <div className='image-background' />
                <img src={logo} alt="HotGiggity" className='header-logo'/>
                <Grid container spacing={24} className="Login-Grid">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="Form">
                            <form onSubmit={this.handleSubmitForm} className="Submit-form">
                                <img src={logo} alt="hot giggity" className="logo-crete-new-px" />

                                <Typography style={{color:"white"}} type="headline" component="h3">
                                    Create password{" "}
                                </Typography>
                                <Typography style={{color:"white"}} component="p">A few words you'll find easy to remember</Typography>

                                {!this.state.requestSuccess ? (
                                    <div>
                                        <TextField
                                            id="password"
                                            placeholder="New Password"
                                            type="password"
                                            autoComplete="current-password"
                                            margin="normal"
                                            fullWidth
                                            value={values.newPassword}
                                            onChange={this.handleNewPasswordChange}
                                            error={this.anyError("newPassword")}
                                            helperText={this.errorText("newPassword")}
                                            className="inputTextContainer"
                                            InputProps={{className: "inputTextField"}}
                                            onBlur={this.validateNewPassword}
                                        />
                                        <TextField
                                            id="confirmPassword"
                                            placeholder="Confirm Password"
                                            type="password"
                                            autoComplete="current-password"
                                            margin="normal"
                                            fullWidth
                                            value={values.confirmPassword}
                                            onChange={this.handleConfirmPasswordChange}
                                            error={this.anyError("confirmPassword")}
                                            helperText={this.errorText("confirmPassword")}
                                            onBlur={this.validateConfirmPassword}
                                            className="inputTextContainer"
                                            InputProps={{className: "inputTextField"}}
                                        />
                                    </div>
                                ) : (
                                    <span>
                                        <br />
                                        <Typography component="p" style={{color:"white"}}>We have successfully reset your password. Please sign in to continue.</Typography>
                                    </span>
                                )}

                                <div className="text-right">
                                    <LinkContainer to="/sign-in">
                                        <Button color="primary" id="signin-btn" raised={this.state.requestSuccess}>
                                            {!this.state.requestSuccess ? "Close" : "Sign In"}
                                        </Button>
                                    </LinkContainer>
                                    {!this.state.requestSuccess ? (
                                        <Button variant="contained" color="primary" id="signup-btn" type="submit">
                                            Continue
                                        </Button>
                                    ) : null}
                                </div>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default CreateNewPassword;
