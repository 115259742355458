import React, { Component, Fragment } from "react";

//component
import classNames from "classnames";

import LoadingSpinnerLess from "./Loading.css";
import { Grid, Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import "./Loading.css";

let loadingSpinnerLess = classNames.bind(LoadingSpinnerLess);
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";

class DisablePage extends Component {
    state = {
        goToPaymentPage: false
    };

    handlePaymentPage = () => {
        this.setState({
            goToPaymentPage: true
        });
    };

    render() {
        if (!ENABLE_SUBSCRIPTION) return <Fragment />;

        let loadingMaskStyle = loadingSpinnerLess({
            displayNone: !this.props.showed,
            loadingMask2: true
        });
        if (this.state.goToPaymentPage) return <Redirect to="../../billing/payment" />;
        return (
            <div className={loadingMaskStyle}>
                <div
                    style={{
                        alignItems: "center",
                        width: "inherit",
                        position: "relative",
                        top: "70%"
                    }}
                >
                    <Fragment>
                        <Grid container justify="center">
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={this.handlePaymentPage}
                                    style={{
                                        fontSize: "20px"
                                    }}
                                >
                                    Upgrade to enable invoicing
                                </Button>
                            </Grid>
                        </Grid>
                    </Fragment>
                </div>
            </div>
        );
    }
}

export default DisablePage;
