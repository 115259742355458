import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Typography, TextField, Button, Grid, Paper } from "@material-ui/core";
import UserTable from "./UserTable";
import VenuesTable from "./VenuesTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import Loading from "../../components/loadingSpinner/Loading";
import { Prompt } from "react-router-dom";

@inject("organisationStore", "organisationMemberStore", "stateStore", "regionStore", "venueStore", "templateStore")
@observer
class RegionEdit extends Component {
    state = {
        isLoading: false
    };

    constructor(props) {
        super(props);
        this.props.organisationMemberStore.reset();
        this.props.stateStore.reset();
        this.props.regionStore.reset();
        this.props.venueStore.resetVenues();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.templateStore.openRegionBlock();
        if (this.props.match.params.regionId) {
            this.setState(
                {
                    ...this.state,
                    isLoading: true
                },
                () => {
                    Promise.all([
                        this.props.organisationStore.load(this.props.match.params.id),
                        this.props.stateStore.loadState(this.props.match.params.stateId),
                        this.props.regionStore.load(this.props.match.params.regionId).then(region => {
                            if (region) {
                                this.props.organisationMemberStore.setMembers(region.members);
                                this.props.organisationMemberStore.setRegionId(region.id);
                                this.props.organisationMemberStore.setLevel(3);
                                this.props.regionStore.setRegions(region.regions);
                                this.props.venueStore.setVenues(region.venues);
                            }
                        })
                    ]).then(() => {
                        this.setState({
                            ...this.state,
                            isLoading: false
                        });
                    });
                }
            );
        }
    }

    handleNameChange = e => {
        this.props.regionStore.setName(e.target.value);
        this.props.templateStore.closeRegionBlock();
    };
    handleAddressChange = e => {
        this.props.regionStore.setAddress(e.target.value);
        this.props.templateStore.closeRegionBlock();
    };
    handleContactNumberChange = e => {
        this.props.regionStore.setContactNumber(e.target.value.replace(/\D/,''));
        this.props.templateStore.closeRegionBlock();
    };

    handleSubmitForm = e => {
        e.preventDefault();
        const { level3Label } = this.props.organisationStore;
        this.setState(
            {
                ...this.state,
                isLoading: true
            })
        // submit
        this.props.regionStore.submit().then(() => {
            this.setState(
                {
                    ...this.state,
                    isLoading: false
                })
            this.props.templateStore.openRegionBlock();
            this.props.templateStore.openSnackbar(`${level3Label} updated`);
        });
    };

    anyError(field) {
        if (!this.props.regionStore.errors) return false;
        if (!this.props.regionStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.regionStore.errors) return null;
        if (!this.props.regionStore.errors[field]) return null;
        return this.props.regionStore.errors[field];
    }

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({ open: false });
    };

    render() {
        const { name, address, contactNumber } = this.props.regionStore;
        const { level1Label, level3Label } = this.props.organisationStore;
        const { regionBlock } = this.props.templateStore;
        const breadcrumb = [
            { name: "Dashboard", link: "/staff" },
            { name: "Organisation", link: "/organisation" },
            {
                name: this.props.organisationStore.name,
                link: this.props.organisationStore.canManage ? `/organisation/${this.props.match.params.id}/edit` : undefined
            },
            {
                name: this.props.stateStore.name,
                link: this.props.stateStore.canManage
                    ? `/organisation/${this.props.match.params.id}/state/${this.props.match.params.stateId}/edit`
                    : undefined
            },
            { name: this.props.regionStore.name }
        ];

        var canManage = this.props.organisationStore.canManage || this.props.stateStore.canManage || this.props.regionStore.canManage;

        return (
            <main>
                <Prompt when={regionBlock} message={location => `You have unsaved Region data, do you still want to leave this section?`} />
                <Header userRole="agent" />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title={"Edit " + level3Label} />
                    <div style={{ position: "relative" }}>
                        <Loading showed={this.state.isLoading} />
                        <Paper>
                            <form onSubmit={this.handleSubmitForm} className="org-form">
                                <Grid container spacing={8}>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="h5" component="h4" gutterBottom>
                                                {level3Label}
                                            </Typography>
                                            <Typography gutterBottom>
                                                {level3Label} is the level 3 group in your {level1Label} hierarchy chart. Add your staff members and
                                                venues to {level3Label}.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    id="org-name"
                                                    label={`${level3Label} Name`}
                                                    fullWidth
                                                    value={name}
                                                    onChange={this.handleNameChange}
                                                    error={this.anyError("name")}
                                                    helperText={this.errorText("name")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <TextField
                                                    id="org-address"
                                                    label="Address"
                                                    fullWidth
                                                    value={address}
                                                    onChange={this.handleAddressChange}
                                                    error={this.anyError("address")}
                                                    helperText={this.errorText("address")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    id="org-contactNumber"
                                                    label="Contact Number"
                                                    fullWidth
                                                    value={contactNumber}
                                                    onChange={this.handleContactNumberChange}
                                                    error={this.anyError("contactNumber")}
                                                    helperText={this.errorText("contactNumber")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="headline" component="h4" gutterBottom>
                                                Users
                                            </Typography>
                                            <Typography gutterBottom>
                                                Use this table to add staff members and assign permissions. Any users added to this level in the{" "}
                                                {level1Label} will have access to all venues in this {level3Label}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <UserTable level={3} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="headline" component="h4" gutterBottom>
                                                Venues
                                            </Typography>
                                            <Typography gutterBottom>Use this table to add venues to this {level3Label}.</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <VenuesTable />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="action">
                                            <Button
                                                component={Link}
                                                to={`/organisation/${this.props.match.params.id}/state/${this.props.match.params.stateId}/edit`}
                                            >
                                                Back
                                            </Button>
                                            &nbsp;
                                            {canManage ? (
                                                <Button variant="contained" color="primary" type="submit" id="region-submit">
                                                    Save
                                                </Button>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </div>
                </MainContainer>
            </main>
        );
    }
}
export default RegionEdit;
