import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";

import { Grid, InputLabel, Dialog, AppBar, Toolbar, Typography, Button } from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

import RichTextEditor from "../../richTextEditor/RichTextEditor";

// import { BookingStatusType } from "../../../types/enum";

// import moment from "moment";

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore")
@observer
class BookingContract extends Component {
    state = {
        contractFullState: false
    };

    componentDidMount() {
        this.setState({venueContract: this.props.bookingRequestStore.booking.venueContract})
    }

    handleContractChange = e => {
        this.props.bookingRequestStore.setBooking("venueContract", e);
    };

    handleOpenContract = () => {
        this.setState({
            ...this.state,
            contractFullState: true
        });
    };

    handleCloseContract = () => {
        this.setState({
            ...this.state,
            contractFullState: false
        });
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    render() {
        let { venueContract } = this.props.bookingRequestStore.booking;
        const {isVenueChange, venueIsChangging} = this.props
        // const { currentProfile } = this.props.profileStore;

        // let confirmedEditable =
        //     status !== BookingStatusType.Cancelled.ordinal &&
        //     status !== BookingStatusType.Completed.ordinal &&
        //     moment(dateStart).endOf("day") >= moment() &&
        //     (!createdById || createdById === currentProfile.id);

        const readonly = this.props.readOnly;
        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={0}>
                            <Grid
                                item
                                xs={12}
                                className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <InputLabel>{this.props.isAct ? "Act " : "Venue "} Contract</InputLabel>
                                <Button color="primary" size="small" onClick={this.handleOpenContract} className="button-small fullscreen-bio">
                                    <FullscreenIcon />
                                    <Typography>Fullscreen</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <RichTextEditor
                                    id="booking-contract-rte"
                                    value={venueContract}
                                    onChange={this.handleContractChange}
                                    readOnly={readonly}
                                    resetEditor={this.state.contractFullState}
                                    isVenueChange={isVenueChange}
                                    onChangeEditor={venueIsChangging}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog fullScreen open={this.state.contractFullState} onClose={this.handleCloseContract} transition={this.transition}>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                Venue Contract
                            </Typography>
                            <Button color="inherit" onClick={this.handleCloseContract}>
                                Exit Fullscreen
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="fullscreen-rich-text">
                        <RichTextEditor value={venueContract} onChange={this.handleContractChange} readOnly={readonly} resetEditor={isVenueChange} isVenueChange={isVenueChange} onChangeEditor={venueIsChangging}/>
                    </div>
                </Dialog>
            </Fragment>
        );
    }
}

export default BookingContract;
