import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { ProfileType } from "../../../types/enum";
import Dropzone from "react-dropzone";

import { Grid, TextField, Button, InputLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import SelectField from "../../fields/SelectField";

import "./GigPromotion.css"

const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
const FILE_MAXSIZE = IS_MOBILE ? 8497152 : 2097152;
const ACCEPTED_FILE = "image/*";

@inject("gigPromotionStore", "profileStore", "agentVenueStore", "agentActStore", "venueStore", "venueActStore", "templateStore")
@withRouter
@observer
class GigPromotionDetails extends Component {
    agentSuggestions = [];
    venueSuggestions = [];

    constructor(props) {
        super(props);
        this.props.venueStore.resetVenues();
    }

    componentDidMount() {
        const { currentProfile } = this.props.profileStore;
        if (currentProfile?.type === ProfileType.Staff.ordinal) {
            this.props.templateStore.showLoadingScreen();
            Promise.all([
                this.props.venueStore.getUserBookableVenues().then(venues => {
                    if (venues.length === 0) {
                        this.props.templateStore.hideLoadingScreen();
                    }
                })
            ]).then(() => {
                this.props.templateStore.hideLoadingScreen();
            });
        }
    }

    setTempTitle = () => {
        const { gigPromotion } = this.props.gigPromotionStore;
        const { actName, venue, venueName } = gigPromotion;
        var title = "";

        title = `${actName} @ ${venue ? venue.profileName : venueName}`;

        this.props.gigPromotionStore.setGigPromotion("tempTitle", title);
    };

    handleTitleChange = e => {
        this.props.gigPromotionStore.setGigPromotion("title", e.target.value);
    };

    handleVenueContactChange = e => {
        this.props.gigPromotionStore.setGigPromotion("venueContact", e.target.value);
    };

    handleVenueAddressChange = e => {
        this.props.gigPromotionStore.setGigPromotion("venueAddress", e.target.value);
    };

    handleVenueEmailChange = e => {
        this.props.gigPromotionStore.setGigPromotion("venueEmail", e.target.value);
    };

    handlePhoneChange = e => {
        this.props.gigPromotionStore.setGigPromotion("venuePhone", e.target.value.replace(/\D/,''));
    };

    handleTicketUrlChange = e => {
        this.props.gigPromotionStore.setGigPromotion("ticketUrl", e.target.value);
    };

    handleSharePermissionChange = (e, checked) => {
        this.props.gigPromotionStore.setGigPromotion("sharePermission", checked);
        if (!checked) {
            this.props.gigPromotionStore.setGigPromotion("shareUpdate", checked);
        }
        this.setState({
            ...this.state
        });
    };

    handleShareUpdateChange = (e, checked) => {
        this.props.gigPromotionStore.setGigPromotion("shareUpdate", checked);
        this.setState({
            ...this.state
        });
    };

    handleChangeGigImage = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            let message = "";
            rejectedFiles.forEach(file => {
                message += file.name + ", ";
            });
            message = message.substr(0, message.length - 2);
            message += " couldn't be uploaded. Please check size and format.";
            this.props.templateStore.openSnackbar(message);
        }

        let image = files[0];

        if(image){
            image.url = image.preview;
            this.props.gigPromotionStore.setGigPromotion('gigImage', image);
            this.setState({...this.state});
        }
    }

    handleDeleteGigImage = (imageId) => {
        this.props.gigPromotionStore.removeGigPromotionImage(imageId).then(() => {
            this.props.gigPromotionStore.setGigPromotion('gigImage', undefined);
            this.setState({
                ...this.state
            })
        })
    }

    mapSuggestions = () => {
        const { currentProfile } = this.props.profileStore;
        if (currentProfile?.type === ProfileType.Staff.ordinal) {
            const { venues } = this.props.venueStore;
            if (venues && venues.length > 0) {
                this.venueSuggestions = venues.map(data => {
                    return {
                        value: data.id,
                        label: data.fullName
                    };
                });
            }
        }
    };

    handleVenueChange = value => {
        const { venues } = this.props.venueStore;
        let venue = venues.filter(x => x.id === value)[0];
        this.props.gigPromotionStore.setGigPromotion("venueId", value);
        if(venue){
            this.props.gigPromotionStore.setGigPromotion("venueName", venue.profileName);
            this.props.gigPromotionStore.setGigPromotion("venueContact", venue.contactName);
            this.props.gigPromotionStore.setGigPromotion("venuePhone", venue.contactPhone);
            this.props.gigPromotionStore.setGigPromotion("venueAddress", venue.venueAddress);
            this.props.gigPromotionStore.setGigPromotion("venueEmail", venue.email);  
        }
       
        this.setTempTitle();
    };

    handleSelectVenue = item => {
        this.props.gigPromotionStore.setGigPromotion("venueName", item.venueName);
        this.props.gigPromotionStore.setGigPromotion("venueContact", item.venueContact);
        this.props.gigPromotionStore.setGigPromotion("venuePhone", item.venuePhone);
        this.props.gigPromotionStore.setGigPromotion("venueAddress", item.venueAddress);
        this.props.gigPromotionStore.setGigPromotion("venueEmail", item.venueEmail);
        this.props.gigPromotionStore.setGigPromotion("venueContract", item.termCondition);
        this.props.gigPromotionStore.setGigPromotion("clientContract", item.clientContract);

        if (item.isIndependent) {
            this.props.gigPromotionStore.setGigPromotion("venueId", undefined);
            this.props.gigPromotionStore.setGigPromotion("independentVenueId", item.id);
        } else {
            this.props.gigPromotionStore.setGigPromotion("venueId", item.id);
            this.props.gigPromotionStore.setGigPromotion("independentVenueId", undefined);
        }

        this.setTempTitle();
    };

    render() {
        let { error, gigPromotion } = this.props.gigPromotionStore;
        let {
            title,
            tempTitle,
            venueContact,
            venueAddress,
            venuePhone,
            createdBy,
            ticketUrl,
            venueName,
            venueEmail,
            venueId,
            gigImage
        } = gigPromotion;

        let { readOnly } = this.props;
        const { currentProfile } = this.props.profileStore;
        this.mapSuggestions();

        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        id="event-title"
                        label="Event Title"
                        value={title !== null && title !== undefined ? title : tempTitle}
                        onChange={this.handleTitleChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                        error={error && error["title"]}
                        helperText={error && error["title"] ? error["title"] : ""}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    {currentProfile &&
                    (currentProfile.type === ProfileType.Staff.ordinal ||
                        (currentProfile.type === ProfileType.Musician.ordinal && createdBy && createdBy.type === ProfileType.Staff.ordinal)) ? (
                        <TextField
                            label="Booking Creator"
                            value={createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : ``}
                            fullWidth
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    ) : null}
                </Grid>
                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                    {readOnly ? (
                        <TextField
                            label="Venue"
                            value={venueName}
                            fullWidth
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    ) : (
                        <Fragment>
                            <SelectField
                                id="gigPromotionVenue"
                                label="Venue"
                                suggestions={this.venueSuggestions}
                                onChange={this.handleVenueChange}
                                value={venueId}
                                placeholder="Select Venue"
                                error={error && error["venue"]}
                                helperText={error && error["venue"] ? error["venue"] : ""}
                            />
                        </Fragment>
                    )}
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Venue Contact"
                        value={venueContact}
                        onChange={this.handleVenueContactChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Venue Address"
                        value={venueAddress}
                        onChange={this.handleVenueAddressChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Venue Phone"
                        value={venuePhone}
                        onChange={this.handlePhoneChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Venue Email"
                        value={venueEmail}
                        onChange={this.handleVenueEmailChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                    <TextField
                        label="Ticket Url"
                        value={ticketUrl}
                        onChange={this.handleTicketUrlChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                        placeholder="Example: https://www.eventbrite.com/"
                        error={error && error["ticketUrl"]}
                        helperText={error && error["ticketUrl"] ? error["ticketUrl"] : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Event Image</InputLabel>
                    <div className="gig-image">
                        {!gigImage && (
                            <div>
                                <Dropzone
                                    accept={ACCEPTED_FILE}
                                    maxSize={FILE_MAXSIZE}
                                    onDrop={this.handleChangeGigImage}
                                    multiple={false}
                                    className="dropzone"
                                >
                                    <p>Select file.</p>
                                </Dropzone>
                            </div>
                        )}
                        {gigImage && (
                            <div className="profile-pic-container">
                                <img src={gigImage.url} className="avatar" alt="avatar" />
                                <Button
                                    variant="fab"
                                    color="secondary"
                                    className="del-avatar"
                                    onClick={ () => this.handleDeleteGigImage(gigImage.id)}
                                >
                                    <DeleteIcon />
                                </Button>
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default GigPromotionDetails;
