import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import "./Confirmation.css";
import _ from "lodash";

import Typography from "@material-ui/core/Typography";

@inject("userStore", "authStore")
@withRouter
@observer
class Confirmation extends Component {
    state = {
        activeResend: false,
        counter: 30,
        headerInfo: "We need to be sure it's you!",
        emailConfirmation: ""
    }
    componentWillMount() {
        this.props.authStore.reset();
        console.log(this.props.location);
        console.log(this.props.location.search);

        if (this.props.location && !this.props.location.search) {
            setTimeout(() => {
                this.setState({activeResend: true})
            }, 30000);
            this.setState({counter:30})
            this.startCounter()
        }

        const emailConfirmation = localStorage.getItem("email-confirmation")
        if(emailConfirmation) {
            this.props.authStore.setEmail(emailConfirmation);
            this.setState({emailConfirmation})
        }
        
        if (this.props.location && this.props.location.search) {
            this.params = _.chain(this.props.location.search)
                .replace("?", "")
                .split("&")
                .map(_.ary(_.partial(_.split, _, "="), 1))
                .fromPairs()
                .value();

            this.props.authStore.setEmail(this.params.email);
            // this.props.authStore.setFirstname(this.params.firstName.replace(/%20/g, " "));
            // this.props.authStore.setLastname(this.params.lastName.replace(/%20/g, " "));
            this.props.authStore.setConfirmationToken(this.params.code);

            this.props.authStore.confirmationToken().then(response => {
                // this.props.history.push("/welcome");
                localStorage.setItem("email-confirmation", this.params.email?this.params.email:"")
                if(this.params.code) {
                    this.setState({counter:5})
                    this.startCounter()
                    setTimeout(() => {
                        this.props.history.push(`/sign-in`);
                    }, 5000);
                }
            });
        }
    }

    handleResendEmail = () => {
        this.props.authStore.resendVerificationCode(this.state.emailConfirmation)
        .then(() => {
            this.setState({activeResend: false, headerInfo: "You have successfully resend your verification email"})
            this.startCounter()
            setTimeout(() => {
                this.setState({activeResend: true})
            }, 30000);
            this.setState({counter:30})
        })
    }

    startCounter = () => {
        if(this.state.counter > 0) {
            setInterval(() => {
               if (this.state.counter > 0) this.setState({counter:this.state.counter - 1})
            }, 1000);
        }
    }

    render() {
        const email = this.state.emailConfirmation
        return (
            <div className="Confirmation">
                <Grid container className="main-grid" justify="center">
                    <Grid container spacing={24} justify="center">
                        <Grid item xs={12} sm={8} md={6}>
                        {this.params?.code ? 
                            <Fragment>
                                <div className="intro">
                                    <h2 id="h-confirmation">Your email has been verified</h2>
                                    <Typography component="p" className="sub-heading">
                                    You will be redirected to the Sign In page in {this.state.counter} seconds
                                    </Typography>
                                </div>
                                <div className="resend">
                                    <Typography component="p" className="sub-heading">
                                        <p className="resend-link" >
                                            Click <Link className="link" to={`/sign-in`}>HERE</Link> to Sign In if you’re not redirected automatically
                                        </p>
                                    </Typography>
                                </div>
                            </Fragment> :
                            <Fragment>
                                <div className="intro">
                                    <h2 id="h-confirmation">{this.state.headerInfo}</h2>
                                    <Typography component="p" className="sub-heading">
                                    Please check your inbox to verify your email and complete the sign up process
                                    </Typography>
                                </div>
                                <div className="resend">
                                    <Typography component="p" className="sub-heading">
                                        {this.state.activeResend ? 
                                            <p className="resend-link" >
                                                <a style={{textDecoration:'none', color:'white'}}>Didn't get your email? </a>
                                                <a className="link" onClick={this.handleResendEmail}>CLICK HERE</a>
                                                <a style={{textDecoration:'none', color:'white'}}> to resend your verification email to {email}</a>
                                            </p> : 
                                            <p>
                                                Please wait {this.state.counter} seconds for resending another email verification
                                            </p>
                                        }
                                    </Typography>
                                </div>
                            </Fragment>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Confirmation;
