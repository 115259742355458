import React, { Component } from 'react'
import { inject, observer } from "mobx-react";
import ModalContainer from '../../modal/ModalContainer';
import { DialogContent, DialogActions, Button, Grid, FormControlLabel, Checkbox, InputLabel } from '@material-ui/core';
import ResponsiveTable from '../../table/ResponsiveTable';
import { ProfileType } from '../../../types/enum';
import ReactToPrint from 'react-to-print';

import currencyHelper from '../../../helper/currency';

@inject("profileStore", "bookingRequestStore")
@observer
class PrintModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            showFee: false
        }
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps){
        if(this.props.open && !prevProps.open){
            
        }
    }

    getColumns = () => {
        const { currentProfile } = this.props.profileStore;
        const { showFee } = this.state;
        if (currentProfile?.type === ProfileType.Agent.ordinal) {
            if(showFee){
                return [
                    { key: "date", label: "Date", width: "20%", sortable: true, addComp: true  },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Gig Fee", isNumber:true, sortable: true, width: "10%"},
                    { key: "actFee", label: "Act Fee", isNumber:true, sortable: true, width: "10%"},
                    { key: "agentFee", label: "My Fee", isNumber:true, sortable: true, width: "10%"},
                ]
            }
            else{
                return [
                    { key: "date", label: "Date", isDate: true, sortable:true, width: "20%", sortable: true, addComp: true  },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "20%" },
                    { key: "venue", label: "Venue", width: "20%" }
                    // { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, type: "currency", width: "10%" },
                    // { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, type: "currency", width: "10%" },
                    // { key: "agentFee", label: "My Fee", sortable: true, isNumber: true, type: "currency", width: "10%" },
                ]
            }
            
        } 
        else if (currentProfile?.type === ProfileType.Musician.ordinal) {
            if(showFee){
                return [
                    { key: "date", label: "Date", width: "20%", sortable: true, addComp: true },
                    { key: "status", label: "Status", width: "10%" },
                    { key: "actName", label: "Act Name", width: "20%" },
                    { key: "venue", label: "Venue", width: "20%" },
                    { key: "actFee", label: "Gig Fee", isNumber:true, sortable: true, width: "10%", style: { textAlign: 'right' } },
                    { key: "myFee", label: "My Fee", isNumber:true, sortable: true, width: "10%"},
                ]
            }
            else{
                return [
                    { key: "date", label: "Date", width: "20%", sortable: true, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "20%" },
                    { key: "venue", label: "Venue", width: "20%" },
                    //{ key: "actFee", label: "Gig Fee", sortable: true, isNumber: true, type: "currency", width: "15%" },
                ]
            }
        } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
            if(showFee){
                return [
                    { key: "date", label: "Date", width: "20%", sortable: true, addComp: true },
                    { key: "status", label: "Status", width: "10%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    // { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, type: "currency", width: "10%" },
                    { key: "gigFee", label: "Gig Fee", isNumber:true,  sortable: true, width: "10%", style: { textAlign: 'right' } },
                    { key: "acknowledge", label: "Acknowledged", width: "10%", style: { textAlign: "center" } },
                ]
            }
            else{
                return [
                    { key: "date", label: "Date", width: "20%", sortable: true, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "18%" },
                    { key: "venue", label: "Venue", width: "18%" },
                    //{ key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, type: "currency", width: "10%" },
                    { key: "acknowledge", label: "Acknowledged", width: "10%", style: { textAlign: "center" } },
                ]
            }
        }else if (currentProfile?.type === ProfileType.PrivateHire.ordinal) {

            if(showFee){
                return [
                    { key: "date", label: "Date", width: "20%", sortable: true, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "18%" },
                    { key: "venue", label: "Venue", width: "18%" },
                    { key: "actFee", label: "Gig Fee", isNumber:true, sortable: true, width: "10%", style: { textAlign: 'right' } },
                    // { key: "acknowledge", label: "Acknowledged", width: "10%", style: { textAlign: "center" } }
              
            ]
            }else{
                return [
                    { key: "date", label: "Date", width: "20%", sortable: true, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "18%" },
                    { key: "venue", label: "Venue", width: "18%" },
                    //{ key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, type: "currency", width: "10%" },
                    // { key: "acknowledge", label: "Acknowledged", width: "10%", style: { textAlign: "center" } }
              
            ]
            }
           
        }

        else return []
    }

    getFooterRow = () => {
        const { showFee } = this.state;
        const { bookings } = this.props;
        const { currentProfile } = this.props.profileStore;
        if(showFee){
            let totalGigFee = 0;
            let totalActFee = 0;
            let totalAgentFee = 0;
            let totalMyFee = 0;
            bookings.forEach( booking => {
                totalGigFee += (typeof booking.gigFee.props !== 'undefined' && typeof booking.gigFee.props.class !== 'undefined') || typeof booking.gigFee === 'boolean' || booking.gigFee == 0 ? 0 : Number(booking.gigFee.replace(/[^0-9.-]+/g,""));
                totalActFee += (typeof booking.actFee.props !== 'undefined' && typeof booking.actFee.props.class !== 'undefined') || typeof booking.actFee === 'boolean'|| booking.actFee == 0 ? 0 : Number(booking.actFee.replace(/[^0-9.-]+/g,""));
                totalAgentFee += (typeof booking.agentFee.props !== 'undefined' && typeof booking.agentFee.props.class !== 'undefined') || typeof booking.agentFee === 'boolean'|| booking.agentFee == 0 ? 0 : Number(booking.agentFee.replace(/[^0-9.-]+/g,""));
                totalMyFee += (typeof booking.myFee?.props !== 'undefined' && typeof booking.myFee?.props?.class !== 'undefined') || typeof booking.myFee === 'boolean'|| booking.myFee == 0 ? 0 : Number(booking.myFee.replace(/[^0-9.-]+/g,""));
            })
            console.log({
                gigFee: `${currentProfile.currencySymbol + currencyHelper.formatMoney(totalGigFee, 2)}`,
                actFee: `${currentProfile.currencySymbol + currencyHelper.formatMoney(totalActFee, 2)}`,
                agentFee: `${currentProfile.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
                myFee: `${currentProfile.currencySymbol + currencyHelper.formatMoney(totalMyFee, 2)}`, 
                style: {
                    textAlign: 'right'
                }
            })
            return {
                gigFee: `${currentProfile.currencySymbol + currencyHelper.formatMoney(totalGigFee, 2)}`,
                actFee: `${currentProfile.currencySymbol + currencyHelper.formatMoney(totalActFee, 2)}`,
                agentFee: `${currentProfile.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
                myFee: `${currentProfile.currencySymbol + currencyHelper.formatMoney(totalMyFee, 2)}`, 
                style: {
                    textAlign: 'right'
                }
            }
        }
    }

    handleClose = () => {
        this.props.closeHandler();
    }

    handleFeeCheckboxChange = e => {
        this.setState({
            ...this.state,
            showFee: e.target.checked
        })
    }

    render(){
        const { showFee } = this.state;
        const { bookings, open } = this.props;
        return (
            <ModalContainer
                open = { open }
                title = "Print Option"
                maxWidth="lg"
                fullScreen={false}
                onClose = { this.handleClose}
            >
                <DialogContent>
                    <Grid container spacing = { 8 }>
                        <Grid item xs={12} lg={10}>
                            <InputLabel>Print Preview</InputLabel>
                            <Grid container spacing = { 8 } ref={el => (this.componentRef = el)}>
                                <Grid item xs={12}>
                                    <div className="printContainer">
                                        <ResponsiveTable 
                                            columns={ this.getColumns() }
                                            rows={ bookings }
                                            sortable
                                            hideSortableArrow
                                            footerRow = {
                                                showFee ? 
                                                    this.getFooterRow() : undefined
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <InputLabel>Options</InputLabel>
                            <Grid container spacing = { 8 }>
                                <Grid item xs={12}>
                                    <FormControlLabel 
                                        control = {
                                            <Checkbox 
                                                checked = { showFee }
                                                onChange = { this.handleFeeCheckboxChange }
                                                color="primary"
                                            />
                                        }
                                        label = "Include Fee"
                                    />
                                </Grid>    
                            </Grid>  
                        </Grid>
                    </Grid>      
                </DialogContent>
                <DialogActions>
                    <Button onClick = { this.handleClose }>
                        Close
                    </Button>
                    <ReactToPrint
                        trigger = {
                            () => (
                                <Button color="primary">
                                    Print
                                </Button>
                            )
                        }
                        content={() => this.componentRef}
                        pageStyle = {
                            "@media print { @page { margin: 0 } .printContainer{ padding-top: 16; padding-bottom:16; padding-left: 24; padding-right: 24; } a[href]:after {content: none !important; }}"
                        }
                    />
                </DialogActions>
            </ModalContainer>
        )
    }
}

export default PrintModal;