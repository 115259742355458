import { observable, action } from "mobx";
import agent from "../agent";
import profileStore from "./profileStore";
import templateStore from "./templateStore";
// import _ from "lodash";
// import { Promise } from "bluebird";

class SongListStore {
    @observable songList = [];
    @observable addedSongs = [];
    @observable deletedSongs = [];
    @observable topSongs = [];
    @observable filteredSongs = [];
    @observable profileTopSongs = [];
    @observable inProgress = false;
    @observable allowSelectTopSong = true;
    @observable profileId = undefined;
    @observable type = "musician";
    @observable importText = "";
    @observable parsedSongs = [];
    @observable ignoredSongs = [];
    @observable format = "title";
    @observable mySongList = [];

    @action
    reset() {
        this.songList = [];
        this.addedSongs = [];
        this.deletedSongs = [];
        this.topSongs = [];
        this.filteredSongs = [];
        this.profileTopSongs = [];
        this.inProgress = false;
        this.allowSelectTopSong = true;
        this.profileId = undefined;
        this.type = "musician";
        this.importText = "Girls Like U - Blackbear\nGirls Like U - Blackbear";
        this.importText = "";
        this.format = "title";
        this.parsedSongs = [];
    }

    @action
    resetImport() {
        // this.importText = "";
        this.parsedSongs = [];
        this.ignoredSongs = [];
    }

    @action
    setProfileId(profileId) {
        this.profileId = profileId;
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    setImportText(importText) {
        this.importText = importText;
    }

    @action
    setFormat(format) {
        this.format = format;
    }

    @action
    addSong(song) {
        return agent.Song.addSong(this.profileId, [song]).then(response => {
            action(this.addedSongs.replace(response));
            this.loadInitialSongs(false);
        });
    }

    @action
    filterSongs(songlist) {
        this.filteredSongs.replace(songlist);
    }

    @action
    updateSongList(songlist) {
        this.songList.replace(songlist);
    }

    @action
    undoAddSong() {
        let songs = this.addedSongs.map(a => {
            return { id: a.id, title: a.title, artist: a.artist };
        });
        agent.Song.undoAddSong(this.profileId, songs).then(response => {
            action(this.addedSongs.replace([]));
            this.loadInitialSongs(false);
        });
    }

    @action
    deleteSongs(selectedIds) {
        const s = this.songList.filter(song => {
            return selectedIds.find(songId => song && songId === song.id);
        });

        return agent.Song.deleteSong(this.profileId, s).then(response => {
            action(this.deletedSongs.replace(response));
            this.loadInitialSongs(false);
        });
    }

    @action
    undoDeleteSongs() {
        let songs = this.deletedSongs.map(a => {
            return { id: a.id, title: a.title, artist: a.artist };
        });
        return agent.Song.undoDeleteSong(this.profileId, songs).then(response => {
            action(this.deletedSongs.replace([]));
            this.loadInitialSongs(false);
        });
    }

    @action
    executeDeleteSongs() {
        let songs = this.deletedSongs.map(a => {
            return { id: a.id, title: a.title, artist: a.artist };
        });

        agent.Song.executeDeleteSong(this.profileId, songs).then(response => {
            action(this.deletedSongs.replace([]));
        });
    }

    @action
    handleSortSongList(songs) {
        this.songList.replace(songs);
    }

    @action
    handleGetSongsFromProfile(profile) {
        agent.Song.getSongs(profile.id).then(songs => {
            this.songList = songs;
        });
    }

    @action
    importFromProfile(id) {
        const currentProfile = profileStore.getCurrentProfile()
        return agent.Song.getSongs(currentProfile.id)
            .then(
                action(songs => {
                    songs.forEach(song => {
                        song.id = undefined;
                        song.profileId = this.profileId;
                    });

                    agent.Song.addSong(this.profileId, songs).then(response => {
                        action(this.addedSongs.replace(response));
                        this.loadInitialSongs(false);
                    });
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                    templateStore.hideLoadingScreen();
                })
            );
    }

    // TODO:obsolete
    @action
    loadInitialSongs(withLoadingScreen) {
       
        return agent.Song.getSongs(this.profileId)
            .then(
                action(songs => {
                    this.songList =  songs.filter(song => !song.isDelete);
                    this.topSongs = songs
                        .filter(song => {
                            return song.topProfile;
                        })
                        .map(el => {
                            return el.id;
                        });
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                    templateStore.hideLoadingScreen();
                })
            );
    }

    @action
    load(profileId) {
        return agent.Song.getSongs(profileId)
            .then(songs => {
                return songs
            })
    }

    @action
    selectTopSong(song) {
        if (this.topSongs.slice().length >= 12 && !song.topProfile) return false;
        let currentSongList = this.songList.slice();
        let filteredSongs = currentSongList.map(el => {
            return el.id === song.id ? { ...el, topProfile: !el.topProfile } : el;
        });
        action(this.songList.replace(filteredSongs));

        if (!song.topProfile) {
            this.topSongs.push(song.id);
        } else {
            this.topSongs.remove(song.id);
        }

        agent.Song.selectTopSong(this.profileId, song).then(response => {});
    }

    @action
    update(song) {
        return agent.Song.update(song);
    }

    @action
    replaceSelectedSong(song) {
        let oldSong = this.songList.find(s => s.id === song.id);
        oldSong.title = song.title;
        oldSong.artist = song.artist;
        oldSong.mediaUrl = song.mediaUrl;
        oldSong.songKey = song.songKey;
    }

    @action
    loadProfileTopSongs() {
        agent.Song.getTopSongs(this.profileId).then(
            action(topSongs => {
                this.profileTopSongs.replace(topSongs);
            })
        );
    }

    @action
    parseImportText() {
        if (!this.importText) return;
        /* eslint-disable no-useless-escape*/
        var splitLineRegex = new RegExp(`\r?\n`);
        var splitSongRegex = new RegExp(`[\–\-]`);
        /* eslint-enable no-useless-escape*/
        var lines = this.importText.split(splitLineRegex); // match new line
        lines = lines.filter(e => e !== "");
        let filteredSongs = [];
        let ignoredSongs = [];

        for (let i = 0; i < lines.length; i++) {
            //!/^[\x00-\x7F]*$/.test(lines[i]) ? ignoredSongs.push(lines[i]) : filteredSongs.push(lines[i]);
            filteredSongs.push(lines[i]);
        }

        this.parsedSongs = filteredSongs.reduce((accumulator, currentValue, currentIndex, array) => {
            let songArray = currentValue.split(" - "); // match char code 45 and char code 8211
            if (songArray.length > 1) {
                if (this.format === "title") accumulator.push({ title: songArray[0], artist: songArray[1], profileId: this.profileId });
                if (this.format === "artist") accumulator.push({ title: songArray[1], artist: songArray[0], profileId: this.profileId });
                if (this.format === "title-only") accumulator.push({ title: songArray[0], artist: null, profileId: this.profileId });
            } else {
                accumulator.push({ title: songArray[0], artist: "", profileId: this.profileId });
            }
            return accumulator;
        }, []);

        this.ignoredSongs = ignoredSongs.reduce((accumulator, currentValue, currentIndex, array) => {
            let songArray = currentValue.split(" - "); // match char code 45 and char code 8211
            if (songArray.length > 1) {
                if (this.format === "title") accumulator.push({ title: songArray[0], artist: songArray[1], profileId: this.profileId });
                if (this.format === "artist") accumulator.push({ title: songArray[1], artist: songArray[0], profileId: this.profileId });
            }
            return accumulator;
        }, []);
    }

    @action
    importSong() {
        return agent.Song.addSong(this.profileId, this.parsedSongs.slice()).then(
            action(response => {
                this.addedSongs.replace(response);
                this.loadInitialSongs(false);
                this.importText = "";
                this.parsedSongs = [];
                this.ignoredSongs = [];
            })
        );
    }
}

export default new SongListStore();
