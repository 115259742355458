import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";

import { DialogContent, DialogActions, Button, Grid, TextField, FormControl, FormControlLabel, Checkbox, FormHelperText, Collapse } from "@material-ui/core";
import moment from "moment";
import { BookingStatusType, ProfileType } from "../../../types/enum";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import SignatureCanvas from "../../sigpad/SignatureCanvas";

import "./BookingSummaryModal.css";

@inject("bookingRequestStore", "profileStore", "signatureStore", "templateStore")
@observer
class BookingSummaryModal extends Component {
    sigCanvas = {};
    state = {
        isLoading: false,
        fileContent: undefined,
        showReqText: false,
        showNotesText: false,
    };

    componentDidMount() {
        const { currentProfile } = this.props.profileStore;
        if (currentProfile) {
            this.setState({ isLoading: true });
            this.props.signatureStore
                .loadBookingSignature(this.props.bookingId)
                .then(signature => {
                    if(signature){
                        this.setState({ fileContent: signature.fileContent });
                    }
                    // this.sigCanvas.fromDataURL(fileContent);
                })
                .finally(() => {
                    this.setState({ isLoading: false });
                });
        }
    }

    onEntered = () => {
        if (this.sigCanvas && this.sigCanvas.fromDataURL && this.state) {
            this.sigCanvas.fromDataURL(this.state.fileContent);
        }
    };

    getDailyRepeatDates = () => {
        const { booking } = this.props.bookingRequestStore;
        var dates = "";
        if (booking) {
            const { dateStart, repeatCount } = booking;
            dates = `${moment(booking.dateStart).format("DD-MM-YYYY")} - ${moment(dateStart)
                .add(repeatCount - 1, "days")
                .format("DD-MM-YYYY")}`;
        }
        return dates;
    };

    getWeeklyRepeatDates = () => {
        const { booking } = this.props.bookingRequestStore;
        var dates = "";
        if (booking) {
            const { dateStart, timeStart, timeEnd, repeatCount } = booking;
            for (var i = 0; i < repeatCount; i++) {
                if (timeStart <= timeEnd) {
                    dates += moment(dateStart)
                        .add(i * 7, "days")
                        .format("DD-MM-YYYY");

                    // add commas only if it's not the last date
                    dates += i < repeatCount - 1 ? ", " : "";
                } else {
                    dates += `${moment(dateStart)
                        .add(i * 7, "days")
                        .format("DD-MM-YYYY")} - ${moment(dateStart)
                            .add(i * 7 + 1, "days")
                            .format("DD-MM-YYYY")}`;
                }
            }
        }
        return dates;
        // return <div dangerouslySetInnerHTML={{ __html: dates }} />;
    };

    getFortnightlyRepeatDates = () => {
        const { booking } = this.props.bookingRequestStore;
        var dates = "";
        if (booking) {
            const { dateStart, timeStart, timeEnd, repeatCount } = booking;
            for (var i = 0; i < repeatCount; i++) {
                if (timeStart <= timeEnd) {
                    dates += moment(dateStart)
                        .add(i * 14, "days")
                        .format("DD-MM-YYYY");
                    // add commas only if it's not the last date
                    dates += i < repeatCount - 1 ? ", " : "";
                } else {
                    dates += `${moment(dateStart)
                        .add(i * 14, "days")
                        .format("DD-MM-YYYY")} - ${moment(dateStart)
                            .add(i * 7 + 1, "days")
                            .format("DD-MM-YYYY")}`;
                }
            }
        }
        return dates;
        // return <div dangerouslySetInnerHTML={{ __html: dates }} />;
    };

    getMonthlyRepeatDates = () => {
        const { booking } = this.props.bookingRequestStore;
        var dates = "";
        if (booking) {
            const { dateStart, timeStart, timeEnd, repeatCount } = booking;

            var dayOfWeek = moment(dateStart).day();
            var weekOfMonth = 1;
            var tempDate = moment(dateStart);
            while (tempDate.get("month") === moment(dateStart).get("month")) {
                tempDate = tempDate.add(-1, "days");
                if (tempDate.day() === dayOfWeek) {
                    weekOfMonth++;
                }
            }

            for (var i = 0; i < repeatCount; i++) {
                var tempDateStart = moment(dateStart)
                    .add(i, "months")
                    .startOf("month");
                var firstSameDayOfWeekFound = false;
                while (!firstSameDayOfWeekFound) {
                    if (moment(tempDateStart).day() === dayOfWeek) {
                        firstSameDayOfWeekFound = true;
                    }
                    if (!firstSameDayOfWeekFound) {
                        tempDateStart = moment(tempDateStart).add(1, "days");
                    }
                }
                for (var j = 1; j < weekOfMonth; j++) {
                    var tempTempDate = tempDateStart.add(7, "days");
                    if (tempTempDate.get("month") === tempDateStart.get("month")) {
                        tempDateStart = tempTempDate;
                    }
                }
                if (timeStart <= timeEnd) {
                    dates += moment(tempDateStart).format("DD-MM-YYYY");
                } else {
                    dates += `${moment(tempDateStart).format("DD-MM-YYYY")} - ${moment(tempDateStart)
                        .add(1, "days")
                        .format("DD-MM-YYYY")}`;
                }

                dates += i < repeatCount - 1 ? ", " : "";
            }
        }
        return dates;
        // return <div dangerouslySetInnerHTML={{ __html: dates }} />;
    };

    handleCheckboxChange = name => event => {
        const value = event.target.checked;
        const { booking } = this.props.bookingRequestStore;

        if (name === "notifyAct" && booking && booking.actId === 0) {
            this.setState({ actError: true, actErrorText: "No act selected" });
            console.log("error act");
            return;
        }

        this.props.bookingRequestStore.setBooking(name, value);
        this.props.bookingRequestStore.setBooking("saveAsDraft", false);
    };

    handleCheckboxDraftChange = event => {
        if (event.target.checked) {
            this.props.bookingRequestStore.setBooking("notifyAct", !event.target.checked);
            this.props.bookingRequestStore.setBooking("notifyAgent", !event.target.checked);
            this.props.bookingRequestStore.setBooking("notifyClient", !event.target.checked);
            this.props.bookingRequestStore.setBooking("saveAsDraft", event.target.checked);
        } else {
            this.props.bookingRequestStore.setBooking("saveAsDraft", event.target.checked);
        }
    };

    handleCheckboxSignedChange = event => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.setBooking("signed", event.target.checked);

        const { fileContent } = this.props.signatureStore;
        if (event.target.checked) {
            this.props.bookingRequestStore.setBooking("signedById", currentProfile.id);
            this.sigCanvas.fromDataURL(fileContent);
        }
    };

    handleClearSignature = event => {
        this.sigCanvas.clear();
    };

    handleProcessBooking = () => {
        const { booking } = this.props.bookingRequestStore;
        if (booking.signed) {
            let sig = this.sigCanvas.toDataURL();
            const data = this.sigCanvas.toData();
            if (data && data.length > 0) this.props.signatureStore.setFile(sig);

            if (!this.props.signatureStore.fileContent) {
                this.props.templateStore.openSnackbar("Please draw your signature on the signature pad");
                return;
            }
            if(booking.id){
                this.props.signatureStore.submitBookingSignature().then(resp => {
                    this.props.processBooking();
                });
            }else{
                this.props.processBooking();
                
            }
           
        } else {
            this.props.processBooking();
        }
    };

    handleSaveSignature = event => {
        let sig = this.sigCanvas.toDataURL();
        const data = this.sigCanvas.toData();
        if (data && data.length > 0) this.props.signatureStore.setFile(sig);
        this.props.signatureStore.submitProfile().then(resp => {
            this.props.templateStore.openSnackbar("New signature has been saved to profile.");
        });
    };

    truncateText = param => {
        if (param.length > 10)
            return param.substring(0, 50) + '... ';
        else
            return param;
    }

    render() {
        const { open, onClose } = this.props;
        const { booking } = this.props.bookingRequestStore;
        var currentProfile = this.props.profileStore.getCurrentProfile();
        const { saveAsDraft, notifyAct, notifyClient, signed } = booking;
        const isAgent = currentProfile && currentProfile.type === ProfileType.Agent.ordinal;
        const isStaff = currentProfile && currentProfile.type === ProfileType.Staff.ordinal;
        const isMusician = currentProfile && currentProfile.type === ProfileType.Musician.ordinal;
        const isOwner = booking && booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id
        // const isMusician = currentProfile && currentProfile.type === ProfileType.Musician.ordinal;
        var fullName = " "
        if (currentProfile) {
            fullName = currentProfile.firstName + ' ' + currentProfile.lastName
        }
        
        const actError = booking.actId <= 0;
        const clientError = booking.venueId <= 0;
        let saveLabel =
            booking.id && booking.id !== 0
                ? "Update Booking"
                : saveAsDraft
                    ? "Save Booking"
                    : notifyAct || notifyClient
                        ? "Process Booking"
                        : "Save Booking";
        
        if (notifyAct || notifyClient) saveLabel = "Process Booking"
        let currencySymbol = currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$";

        return (
            <ModalContainer open={open} onClose={onClose} onEntered={this.onEntered} title="Booking Summary" coloredDialogTitle={true}>
                <DialogContent>
                    <div id="alert-dialog-description">
                        <br />
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Title"
                                    value={booking.title ? booking.title : booking.tempTitle}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Provider"
                                    value={booking.agent ? booking.agent.profileName : booking.provider}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Booking Creator"
                                    value={booking?.createdBy ? (booking?.createdBy?.firstName + " " +booking?.createdBy?.lastName) : ""}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Name"
                                    value={booking.venue ? booking.venue.profileName : booking.venueName}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Contact"
                                    value={booking.venueContact}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Venue Address"
                                    value={booking.venueAddress || " "}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Date"
                                    value={
                                        booking.repeatType === 1
                                            ? this.getDailyRepeatDates()
                                            : booking.repeatType === 2
                                                ? this.getWeeklyRepeatDates()
                                                : booking.repeatType === 3
                                                    ? this.getFortnightlyRepeatDates()
                                                    : booking.repeatType === 4
                                                        ? this.getMonthlyRepeatDates()
                                                        : `${moment(booking.dateStart).format("DD-MM-YYYY")}${moment(booking.timeStart, "HH:mm").format("HH:mm") >
                                                            moment(booking.timeEnd, "HH:mm").format("HH:mm")
                                                            ? ` - ${moment(booking.dateStart)
                                                                .add(1, "days")
                                                                .format("DD-MM-YYYY")}`
                                                            : ""
                                                        }`
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Time"
                                    value={
                                        booking.timeStart === booking.timeEnd
                                            ? moment(booking.timeStart, "h:mma").format("h:mma")
                                            : moment(booking.timeStart, "h:mma").format("h:mma") +
                                            " - " +
                                            moment(booking.timeEnd, "h:mma").format("h:mma")
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Load In"
                                    value={
                                        booking.loadInStart && booking.loadInEnd ?
                                            (
                                                moment(booking.loadInStart).isSame(booking.loadInEnd)
                                                    ? moment(booking.loadInStart, "h:mma").format("h:mma")
                                                    : moment(booking.loadInStart, "h:mma").format("h:mma") +
                                                    " - " +
                                                    moment(booking.loadInEnd, "h:mma").format("h:mma")
                                            ) :
                                            booking.loadInStart ? moment(booking.loadInStart, "h:mma").format("h:mma") :
                                                booking.loadInEnd ? moment(booking.loadInEnd, "h:mma").format("h:mma") :
                                                    "N/A"
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Soundcheck"
                                    value={
                                        booking.soundCheckStart && booking.soundCheckEnd ?
                                            (
                                                moment(booking.soundCheckStart).isSame(booking.soundCheckEnd)
                                                    ? moment(booking.soundCheckStart, "h:mma").format("h:mma")
                                                    : moment(booking.soundCheckStart, "h:mma").format("h:mma") +
                                                    " - " +
                                                    moment(booking.soundCheckEnd, "h:mma").format("h:mma")
                                            ) :
                                            booking.soundCheckStart ? moment(booking.soundCheckStart, "h:mma").format("h:mma") :
                                                booking.soundCheckEnd ? moment(booking.soundCheckEnd, "h:mma").format("h:mma") :
                                                    "N/A"
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Act"
                                    value={booking.status === BookingStatusType.Resent.ordinal && booking.bookingRequests ? booking.bookingRequests.filter(x => !x.deleted).map(y => y.act.actName).join(",") : booking.actName}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            {booking.repeatType && booking.repeatType !== 0 ? (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Repeat "
                                        value={
                                            booking.repeatType === 1
                                                ? `Daily (${booking.repeatCount})`
                                                : booking.repeatType === 2
                                                    ? `Weekly (${booking.repeatCount})`
                                                    : booking.repeatType === 3
                                                        ? `Fortnightly (${booking.repeatCount})`
                                                        : booking.repeatType === 4
                                                            ? `Monthly (${booking.repeatCount})`
                                                            : ""
                                        }
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>
                            ) : (
                                ""
                            )}
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Number of Sets"
                                    value={booking.setNumber || " "}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            {
                                isStaff ? (<Grid item xs={12} md={6}>
                                    <TextField
                                        label="Gig fee paid to Act"
                                        value={currencySymbol + (booking.gigFee || 0)}
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>) : <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Gig fee paid to Act"
                                        value={currencySymbol + (booking.actFee || 0)}
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>
                            }

                            {isAgent ? (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Agent Commision"
                                        value={currencySymbol + (booking.agentCommissionAmount || 0)}
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>
                            ) : null}
                            {/* <Grid item xs={12} md={6}>
                                <TextField
                                    label="Dress"
                                    value={booking.dress || ""}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="div-requirement" shrink>
                                        Production Requirements
                                    </InputLabel>
                                    <OutlinedInput
                                        id="div-requirement"
                                        className={"overflow-hidden"}
                                        fullWidth
                                        inputComponent="div"
                                        inputProps={{ dangerouslySetInnerHTML: { __html: (!this.state.showReqText ? this.truncateText(booking.requirements) : "") } }}
                                        labelWidth={180}
                                    />

                                    <Collapse in={this.state.showReqText}>
                                        <OutlinedInput
                                            id="div-requirement"
                                            className={"hide-outline"}
                                            fullWidth
                                            inputComponent="div"
                                            value={booking.requirements || " "}
                                            inputProps={{ dangerouslySetInnerHTML: { __html: booking && booking.requirements } }}
                                            labelWidth={180}
                                        />
                                    </Collapse>
                                    {booking.requirements.length > 10 ? (
                                        <a className={"see-more-link"} onClick={() => this.setState({ showReqText: !this.state.showReqText })}>
                                            {
                                                !this.state.showReqText ? "Show more" : "Show less"
                                            }
                                        </a>
                                    )
                                        : ("")}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="div-notes" shrink>
                                        Notes
                                    </InputLabel>
                                    <OutlinedInput
                                        id="div-requirement"
                                        className={"overflow-hidden"}
                                        fullWidth
                                        inputComponent="div"
                                        inputProps={{ dangerouslySetInnerHTML: { __html: (!this.state.showNotesText ? this.truncateText(booking.notes) : "") } }}
                                        labelWidth={45}
                                    />

                                    <Collapse in={this.state.showNotesText}>
                                        <OutlinedInput
                                            id="div-requirement"
                                            className={"hide-outline"}
                                            fullWidth
                                            inputComponent="div"
                                            value={booking.notes || " "}
                                            inputProps={{ dangerouslySetInnerHTML: { __html: booking && booking.notes } }}
                                            labelWidth={45}
                                        />
                                    </Collapse>
                                    {booking.notes.length > 10 ? (
                                        <a className={"see-more-link"} onClick={() => this.setState({ showNotesText: !this.state.showNotesText })}>
                                            {
                                                !this.state.showNotesText ? "Show more" : "Show less"
                                            }
                                        </a>
                                    )
                                        : ("")}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                               
                            </Grid>
                            <Grid item xs={12}>
                                <hr className="summary-hr" />
                            </Grid>
                            <Fragment>
                                <Grid item xs={12} className={signed ? "modal-sig" : "modal-sig hidden"}>
                                    <label className="signature-label">
                                        Signed By
                                        {booking.signedBy
                                            ? ` ${booking.signedBy.firstName} ${booking.signedBy.lastName}`
                                            : fullName}
                                    </label>
                                    <SignatureCanvas
                                        penColor="black"
                                        canvasProps={{ width: 440, height: 200, className: "sigCanvas" }}
                                        ref={ref => {
                                            this.sigCanvas = ref;
                                        }}
                                    />
                                    <div className="clearfix">&nbsp;</div>
                                    <Button className="signature-button" size="small" onClick={this.handleClearSignature}>
                                        clear
                                    </Button>
                                    <Button className="signature-button" size="small" onClick={this.handleSaveSignature}>
                                        save to my profile
                                    </Button>
                                </Grid>
                            </Fragment>

                            {!isMusician && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense"
                                                    checked={saveAsDraft}
                                                    onChange={this.handleCheckboxDraftChange}
                                                />
                                            }
                                            label="Save as Draft"
                                        />
                                    </FormControl>
                                </Grid>)}
                            {(isAgent) && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox" error={actError}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense"
                                                    checked={notifyAct}
                                                    indeterminate={actError && notifyAct}
                                                    onChange={this.handleCheckboxChange("notifyAct")}
                                                />
                                            }
                                            label={isOwner ? "Finalise and select players " : "Notify Act"}
                                        />
                                        {notifyAct && <FormHelperText>{actError ? "Act required" : booking.status === BookingStatusType.Resent.ordinal && booking.bookingRequests ? `Act: ${booking.bookingRequests.filter(x => !x.deleted).map(y => y.act.actName).join(",")}` : `Act: ${booking.actName}`} </FormHelperText>}
                                    </FormControl>
                                </Grid>)}
                                {(isStaff) && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox" error={actError}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense"
                                                    checked={notifyAct}
                                                    indeterminate={actError && notifyAct}
                                                    onChange={this.handleCheckboxChange("notifyAct")}
                                                />
                                            }
                                            label="Notify Act"
                                        />
                                        {notifyAct && <FormHelperText>{actError ? "Act required" : booking.status === BookingStatusType.Resent.ordinal && booking.bookingRequests ? `Act: ${booking.bookingRequests.filter(x => !x.deleted).map(y => y.act.actName).join(",")}` : `Act: ${booking.actName}`} </FormHelperText>}
                                    </FormControl>
                                </Grid>)}
                            {(isAgent) && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox" error={clientError}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense"
                                                    checked={notifyClient}
                                                    indeterminate={clientError && notifyClient}
                                                    onChange={this.handleCheckboxChange("notifyClient")}
                                                />
                                            }
                                            label={`Notify Venue`}
                                        />
                                        {notifyClient && (
                                            <FormHelperText>
                                                {clientError
                                                    ? "Venue required"
                                                    : `Venue: ${booking.venue ? booking.venue.profileName : booking.venueName}`}{" "}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            {isStaff && (
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" fullWidth className="save-checkbox">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox-dense checkbox-signed"
                                                    checked={signed}
                                                    onChange={this.handleCheckboxSignedChange}
                                                />
                                            }
                                            label={`Signed `}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                    <Button id="btn-confirm-processbooking" onClick={this.handleProcessBooking} color="primary" className="process-booking">
                        {saveLabel}
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default BookingSummaryModal;
