import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { computed } from "mobx";

import "./Breadcrumb.css";
import Hidden from "@material-ui/core/Hidden";

import HomeIcon from "@material-ui/icons/Home";

class Breadcrumb extends Component {
    @computed
    get name() {
        // correct; computed property will track the `user.name` property
        return this.props.links;
    }

    render() {
        const { links } = this.props;

        return (
            <Hidden xsDown>
                <Fragment>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {links.map(
                                (link, i) =>
                                    link.name && (
                                        <li className="breadcrumb-item" key={link.name}>
                                            {link.link && (
                                                <Link to={link.link}>
                                                    {i === 0 && <HomeIcon className="breadcrumb-icon" />}
                                                    {link.name}
                                                </Link>
                                            )}
                                            {!link.link && (
                                                <span>
                                                    {i === 0 && <HomeIcon className="breadcrumb-icon" />}
                                                    {link.name}
                                                </span>
                                            )}
                                        </li>
                                    )
                            )}
                        </ol>
                    </nav>
                </Fragment>
            </Hidden>
        );
    }
}

export default Breadcrumb;
