import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import ModalContainer from "./ModalContainer";
import { observer, inject } from "mobx-react";
@inject("profileStore")
class ConfirmationModalCheckbox extends Component {
    state = {
        checked : false
    }
    componentDidMount = () =>{
        var currentProfile = this.props.profileStore.getCurrentProfile()
        this.setState({
            checked : currentProfile.checkboxInvoice
        })
    }
    handleCheckBox = () => {
        var currentProfile = this.props.profileStore.getCurrentProfile()
        this.setState({
            checked : !currentProfile.checkboxInvoice
        })
            this.props.profileStore.setCheckboxInvoice(currentProfile.id).then(result => {
                this.props.profileStore.loadProfiles().finally(() => {
                
            })
        })
        
    }
    render() {
        var currentProfile = this.props.profileStore.getCurrentProfile()
        return (
            <ModalContainer
                open={this.props.open}
                title={this.props.title ? this.props.title : "Confirmation"}
                onClose={this.props.closeHandler}
                fullScreen={false}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "xs"}
            >
                <DialogContent
                // style={{ paddingTop: "16px" }}
                >
                    <DialogContentText><div dangerouslySetInnerHTML={{ __html: this.props.message }} /></DialogContentText>
                    <div style={{ display: 'flex', marginLeft: "-15px",flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
                        <Checkbox checked={this.state.checked} onChange={this.handleCheckBox} style={{ color: '#ff0c9c', alignSelf: 'center', }} />
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }} >
                            Don't show this again in the future
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.declineHandler ? this.props.declineHandler : this.props.closeHandler}>
                        {this.props.declineLabel ? this.props.declineLabel : "Close"}
                    </Button>
                    <Button className="btn-confirmation" variant="contained" color="primary"  autoFocus onClick={this.props.confirmationHandler}>
                        {this.props.confirmationLabel ? this.props.confirmationLabel : "Confirm"}
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default ConfirmationModalCheckbox;
