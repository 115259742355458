import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { inject, observer } from "mobx-react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, Button, Tooltip,Divider } from "@material-ui/core";
import ConfirmationModal from "../../../components/modal/ConfirmationModal.js"
import "../AccountIntegration.css";
const XERO_KEY = process.env.REACT_APP_XERO_CLIENT_ID;
const XERO_REDIRECT_URL = process.env.REACT_APP_XERO_REDIRECT_URI;
import XEROIcon from "../../../img/xero_logo.png";
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";
const ENABLE_XERO = process.env.REACT_APP_ENABLE_XERO === "true";
import Select from "react-select";
import { ProfileType } from "../../../types/enum.js";
import { withRouter } from "react-router-dom";

@inject("userStore", "profileStore", "templateStore","billingStore", "commonStore","xeroStore")
@withRouter
@observer
class XeroComponent extends Component {
    state = {
        selectedOption: "",
        options: [],
        showCalendar: false,
        googleCalendarActive: false,
        showUnlinkModal: false,
        showSubmitCalendar: false,
        panelXeroExpanded: false,
        panelGoogleExpanded: false,
        xeroActive:false,
        xeroTenantName: "",
        showStep3Xero: false,
        showXeroDisconnectModal: false,
        selectTenantPhase: false,
        selectedXeroTenant : "",
        selectedXeroOptions: [],
    };
    
    componentDidMount = () => {
        var options = [];
        var selectedOptions = "";
        const { XeroToken, TenantUrl } = this.props.xeroStore;
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
            // this.setState({
            //     xeroActive: currentProfile?.organisationOwner?.activateXeroAccess,
            //     XeroAccount: currentProfile?.organisationOwner?.xeroTenantName
            // });
        }
        if(currentProfile && currentProfile?.activateXeroAccess && (this.props.billingStore.billingAccess?.isProPlan)){   
            if(!currentProfile?.xeroTenantId){    
                this.props.xeroStore.getXeroTenantId(currentProfile?.id).then(result => {
                    this.setState({
                        ...this.state,
                        selectTenantPhase: true,
                        selectedXeroOptions : this.props.xeroStore.tenantList,
                        selectedXeroTenant : currentProfile?.xeroTenantId,
                        xeroActive:true,
                        xeroTenantName: currentProfile?.xeroTenantName,
                        showStep3Xero: true,
                        panelXeroExpanded: true
                    });   
                })
               
            }else{
                var options = []
                options.push({ value: currentProfile?.xeroTenantId, label: currentProfile?.xeroTenantName })
                this.setState({
                    ...this.state,
                    selectTenantPhase: false,
                    selectedXeroOptions : options,
                    selectedXeroTenant : currentProfile?.xeroTenantId,
                    xeroActive:true,

                    xeroTenantName: currentProfile?.xeroTenantName,
                    showStep3Xero: true,
                    panelXeroExpanded: false
                });
            }
            // if(XeroToken || !(currentProfile?.outGoingInvoiceXero &&
            //     currentProfile?.inGoingInvoiceXero)){
            //     this.setState({
            //         ...this.state,
            //         xeroActive:true,
            //         xeroTenantName: currentProfile?.xeroTenantName,
            //         showStep3Xero: true
            //     });
            // }else{
            //     this.setState({
            //         ...this.state,
            //         xeroActive:true,
            //         xeroTenantName: currentProfile?.xeroTenantName,
            //         showStep3Xero: true
            //     });
            // }
         
        }else{
            this.setState({
                ...this.state,
                xeroActive:false,
                xeroTenantName: "",
                showStep3Xero: false
            });
        }
        
        // this.props.userStore.getQuickBooks();
    };


    expandXERO = () => {
        const {panelXeroExpanded} =this.state
        if(this.props.billingStore.billingAccess?.isProPlan){
            this.setState({
                ...this.state,
                panelXeroExpanded: !panelXeroExpanded,
            });
        }else{
            this.props.redirectMembership();
        }
       
    }
    handleConnectXero = async () => {
        await xero_agent.auth.connect().then((result) => {
            console.log(result)
        })
    }
    handleDisconnectXero = e => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        this.props.xeroStore.disconnect(currentProfile?.id).finally(() => {
            this.props.profileStore.loadProfiles().finally(() => {
                this.props.templateStore.hideLoadingScreen()
                this.setState({
                    ...this.state,
                    showXeroDisconnectModal: false,
                    showStep3Xero: false,
                    xeroActive: false
                });
            })
         
        });
    }
    handleChangeXeroTenant= selectedOption => {
        this.setState({ selectedXeroTenant : selectedOption.value });
    };
    handleSelectTenant = () => {
        const { selectedXeroOptions, selectedXeroTenant  } = this.state
        this.props.handleLoading(true)
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
            // this.setState({
            //     xeroActive: currentProfile?.organisationOwner?.activateXeroAccess,
            //     XeroAccount: currentProfile?.organisationOwner?.xeroTenantName
            // });
        }
        var selectedOptions = selectedXeroOptions.filter(x=>x.value === selectedXeroTenant)[0]

        this.props.xeroStore
        .handleSelectTenant({ profileId:currentProfile?.id,xeroTenantId: selectedOptions.value, xeroTenantName: selectedOptions.label})
        .finally(response => {
                this.props.profileStore.loadProfiles().finally(() => {
                    this.props.handleLoading(false)
                    this.setState({
                        selectTenantPhase: false,
                        selectedXeroOptions : [selectedOptions],
                        selectedXeroTenant : selectedOptions.value
                    });
                })
        });
    }
    handleCloseXeroModal = () => this.setState({ ...this.state, showXeroDisconnectModal: false });
    handleOpenXeroModal = () => this.setState({ ...this.state, showXeroDisconnectModal: true });

    render() {
        const { currentUser } = this.props.userStore;
        const { currentProfile } = this.props.profileStore;
        const { token } = this.props.commonStore;
        const { panelXeroExpanded, xeroActive,showStep3Xero,selectTenantPhase,  selectedXeroOptions, selectedXeroTenant } = this.state;

        let hasSubscribe = false;
        // if (token && currentProfile && jwtDecode(token).scope.filter(x => x === currentProfile?.typeString).length > 0) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }
        return (
                 ((currentProfile?.type != ProfileType.Staff.ordinal) || ((currentProfile?.type === ProfileType.Staff.ordinal && (!currentProfile?.hasOrganisation || currentProfile?.allowInvoices ))))  && ENABLE_XERO &&
              (
                  <Grid container style={{marginTop: 20}}spacing={0}>
                      <Grid item md={12}>
                          <ExpansionPanel expanded={panelXeroExpanded} onChange={this.expandXERO}>
                              <ExpansionPanelSummary expandIcon={this.props.billingStore.billingAccess?.isProPlan ? 
                              <ExpandMoreIcon /> : null}>
                                      <Grid style={{display: panelXeroExpanded ? "none" :"inline"}}container spacing={8}>
                                      <Grid item md={3} xs={12}  spacing={0}>
                                              <div style={{float: "left",marginRight:"20px"}}>                                                          <img
                                                      src={XEROIcon}
                                                      alt="XERO"
                                                      width="100px"
                                                  />
                                              </div>
                                      </Grid>
                                      {/* <Grid item md={9} xs={12}  spacing={0}>
                                              <div  style={{float: "left", textAlign: "left"}}>
                                                  <h3>XERO Account</h3>
                                              </div>
                                      </Grid> */}
                                  </Grid>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                      <Fragment>
                                          <Grid container spacing={8}>
                                              <Grid item md={5} xs={12}  spacing={0}
                                                  direction="column"
                                                  alignItems="center"
                                                  justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                              <div class="row" style={{margin: "10px" }}>
                                                  <div style={{float: "left",width: "30%",height:"100%",verticalAlign:"middle"}}>                                                          <img
                                                          src={XEROIcon}
                                                          alt="Google Calendar"
                                                          width="100px"
                                                      />
                                                  </div>
                                              </div>
                                                  
                                                  <Typography gutterBottom>
                                                      Two way integration between your Hot Giggity invoices and your XERO accounting package. For detailed instruction,watch this how to video
                                                  </Typography>
                                              </Grid>
                                              { showStep3Xero ?
                                                  <Grid item md={7} xs={12}  container
                                                  spacing={0}
                                                  direction="column"
                                                  alignItems="center"
                                                  justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                      
                                                      <Grid container spacing={2}>
                                                      
                                                          <Grid item md={6}>
                                                              <Typography gutterBottom>Step 1. Connect Hot Giggity to your XERO account</Typography>
                                                              {
                                                                          xeroActive ?  <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: Successfully connected</Typography> :
                                                                          <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: XERO not connected</Typography>
                                                              }
                                                              
                                                          </Grid>
                                                          
                                                          <Grid item md={6} style={{textAlign:"center"}}>
                                                              {
                                                                  xeroActive ? 
                                                                  <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleOpenXeroModal} >
                                                                      disconnect
                                                                  </Button> :
                                                                  <Button
                                                                      variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary"
                                                                      target="_blank"
                                                                      href={`
                                                                      https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_KEY}&redirect_uri=${XERO_REDIRECT_URL}&scope=offline_access openid profile email accounting.settings accounting.transactions accounting.contacts accounting.attachments&state=123
                                                                          `}
                                                                      disabled={!hasSubscribe}
                                                                  >
                                                                      Connect XERO Account
                                                                  </Button>
                                                              }
                                                                  
  
                                                          </Grid>

                                                          <Grid item xs={12} style={{marginTop:"25px",marginBottom:"25px"}}> 
                                                              <Divider orientation="vertical" flexItem/>
                                                          </Grid>

                                                          {
                                                              !selectTenantPhase ?
                                                              (
                                                                  <Grid container spacing={2}>
                                                                      <Grid item md={6} xs={12}>
                                                                      <Typography gutterBottom>Step 2. Select Tenant Profile</Typography>
                                                                      <Select value={selectedXeroTenant} onChange={this.handleChangeXeroTenant} options={selectedXeroOptions} disabled />
                                                                      </Grid>
                                                                      <Grid item md={6} xs={12} style={{textAlign:"center"}}>
                                                                          {/* <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleSelectTenant} >
                                                                              CONNECT ACCOUNT
                                                                          </Button> */}
                                                                      </Grid>
                                                                      <Grid item xs={12} style={{marginTop:"25px",marginBottom:"25px"}}> 
                                                                          <Divider orientation="vertical" flexItem/>
                                                                      </Grid>
                                                                      <Grid item md={6}>
                                                                      <Typography gutterBottom>Step 3. Complete your XERO set up</Typography>
                                                                      <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>Note You must complete the set up process to use this feature</Typography> 
                                                                      </Grid>
                                                                      <Grid item md={6} style={{textAlign:"center"}}>
                                                                          <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={() => this.props.history.push("/XEROSetting")} >
                                                                              Manage XERO
                                                                          </Button> 
                                                                      </Grid>

                                                                      <Grid item xs={12} style={{marginTop:"25px",marginBottom:"25px"}}> 
                                                                          <Divider orientation="vertical" flexItem/>
                                                                      </Grid>
                                                                  </Grid>
                                                              ) :
                                                              (
                                                                  <Grid container spacing={2}>
                                                                      <Grid item md={6} xs={12}>
                                                                      <Typography gutterBottom>Step 2. Select Tenant Profile</Typography>
                                                                      <Select value={selectedXeroTenant} onChange={this.handleChangeXeroTenant} options={selectedXeroOptions} />
                                                                      </Grid>
                                                                      <Grid item md={6} xs={12} style={{textAlign:"center"}}>
                                                                          <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleSelectTenant} >
                                                                              CONNECT ACCOUNT
                                                                          </Button>
                                                                      </Grid>
                                                                      <Grid item xs={12} style={{marginTop:"25px",marginBottom:"25px"}}> 
                                                                          <Divider orientation="vertical" flexItem/>
                                                                      </Grid>
                                                                  </Grid>
                                                              )
                                                          
                                                          }

                                                          
                                                      </Grid>

                                                      
                                                  </Grid>
                                                          
                                                  : 
                                                  <Grid item md={7} xs={12}  container
                                                  spacing={0}
                                                  direction="column"
                                                  alignItems="center"
                                                  justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                  
                                                      <Grid container spacing={2}>
                                                  
                                                          <Grid item md={6}>
                                                              <Typography gutterBottom>Step 1. Connect Hot Giggity to your Xero account</Typography>
                                                              {
                                                                          xeroActive ?  <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: Successfully connected to (Xeroaccountname)</Typography> :
                                                                          <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: Xero not connected</Typography>
                                                              }
                                                          
                                                          </Grid>
                                                          <Grid item md={6} style={{textAlign:"center"}}>
                                                              {
                                                                  xeroActive ? 
                                                                  <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleOpenXeroModal} >
                                                                      disconnect
                                                                  </Button> :
                                                                  <Button
                                                                      variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary"
                                                                      target="_blank"
                                                                      href={` https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_KEY}&redirect_uri=${XERO_REDIRECT_URL}&scope=offline_access openid profile email accounting.settings accounting.transactions accounting.contacts accounting.attachments&state=123
                                                                          `}
                                                                      disabled={!hasSubscribe}
                                                                  >
                                                                      Connect Xero Account
                                                                  </Button>
                                                              }
                                                              
   
                                                          </Grid>
   
   
                                                          
                                                      </Grid>
   
                                                  
                                                  </Grid>
                                                  
                                                  
                                              }
                                              
                                          </Grid>
                                          <ConfirmationModal
                                            open={this.state.showXeroDisconnectModal}
                                            title="Are you sure you want to disconnect your Xero account?"
                                            message="By disconnecting your account, you will no longer be able to sync your customer invoices and supplier bills between Hot Giggity and Xero. </br>
                                            The synchronisation data in Hot Giggity will also be deleted. </br>
                                            Do you want to continue?"
                                            closeHandler={this.handleCloseXeroModal}
                                            confirmationHandler={this.handleDisconnectXero}
                                            declineHandler={this.handleCloseXeroModal}
                                            confirmationLabel="Disconnect"
                                            declineLabel="Cancel"
                                            maxWidth="sm"
                                        />
                                      </Fragment>
                              </ExpansionPanelDetails>
                          </ExpansionPanel>
                      </Grid>
                  </Grid>
              ) 
        )
    }
}

export default XeroComponent;
