import React, { Component } from "react";
import { Card, Typography, Grid } from "@material-ui/core";

import CardContent from "@material-ui/core/CardContent";

import "./InformationCard.css";

class InformationCard extends Component {
    render() {
        return (
            <Card
                classes={{
                    root: "widget information-card-widget"
                }}
            >
                <CardContent
                    classes={{
                        root: "widget-content-container"
                    }}
                >
                    <Grid container spacing={16} className="widget-grid-container" alignItems="center">
                        <Grid item xs={3} className="widget-information-item">
                            <Typography className="widget-icon">
                                <i className={this.props.mhIcon} />
                            </Typography>
                        </Grid>

                        <Grid item xs={9}>
                            <Typography variant="display1" noWrap>
                                {this.props.information}
                                <Typography variant="caption" noWrap className="caption">
                                    {this.props.informationDetail}
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export default InformationCard;
