import { observable, action,runInAction } from "mobx";
import agent from "../agent";
import moment from "moment";
import profileStore from "./profileStore";
import { Promise } from "bluebird";
import CacheHelper from '../helper/cache.js'
import { ProfileType } from "../types/enum";

class ConversationStore {
    @observable isLoading = false;
    @observable title = "";
    @observable messageChats = [];
    @observable participant = [];
    @observable firstName = "";
    @observable lastName = "";
    @observable email = "";
    @observable street = "";
    @observable city = "";
    @observable postal = "";
    @observable state = "";
    @observable phone = "";
    @observable eventType = "";
    @observable utcOffset = new Date().getTimezoneOffset();
    @observable eventDate = new Date();
    @observable subject = "Your message here <br/>regards";
    @observable body = "";
    @observable metadataBody = undefined;
    @observable profileId = undefined;
    @observable actId = undefined;
    @observable actName = "";
    @observable unreadMessage = 0;
    @observable searchedAddress = ""
    @observable messageId = 0;
    @observable
    timeStartString = "19:00"
    @observable
    timeEndString= "22:00"
        @observable
        timeStart = moment()
            .hour(19)
            .minute(0);
        @observable
        timeEnd = moment()
            .hour(22)
            .minute(0);
    @observable conversations = [];
    @observable conversation = undefined;
    @observable messages = [];
    @observable errors = "";

    @action
    reset() {
        this.errors = "";
    }
    @action
    resetModal() {
        this.searchedAddress = ""
        this.body = ""
        this.phone = ""
        this.eventType = ""
        this.eventDate = new Date();
        this.timeStart = moment()
        .hour(19)
        .minute(0);
        this.timeEnd = moment()
        .hour(22)
        .minute(0);
        this.timeStartString = "19:00"
        this.timeEndString = "22:00"
        this.errors = ""
    }
    @action
    setMessageId(id){
        this.messageId = id
    }
    @action
    addError(fieldKey, errorMessage) {
        if (!this.errors) {
            this.errors = [];
        }
        this.errors[fieldKey] = errorMessage;
    }

    @action
    setParticipant(participant) {
        this.participant = participant;
    }

    @action
    setMessageChats(messageChats) {
        this.messageChats = messageChats;
    }

    @action
    setAddress(searchedAddress) {
        this.searchedAddress = searchedAddress;
    }
    
    @action
    addMessageChats(messageChat){
        this.messageChats.push(messageChat)
    }

    @action
    setTitle(title) {
        this.title = title;
    }

    @action
    setFirstName(firstName) {
        this.firstName = firstName;
    }

    @action
    setLastName(lastName) {
        this.lastName = lastName;
    }

    @action
    setEmail(email) {
        this.email = email;
    }

    @action
    setStreet(street) {
        this.street = street;
        // this.getLongitudeLatitude();
    }

    @action
    setCity(city) {
        this.city = city;
        // this.getLongitudeLatitude();
    }

    @action
    setPostal(postal) {
        this.postal = postal;
        //  this.getLongitudeLatitude();
    }

    @action
    setState(state) {
        this.state = state;
        // this.getLongitudeLatitude();
    }

    @action
    setPhone(phone) {
        this.phone = phone;
    }

    @action
    setEventType(eventType) {
        this.eventType = eventType;
    }

    @action
    setEventDate(eventDate) {
        this.eventDate = eventDate;
    }

    @action
    setMessage(body) {
        this.body = body;
    }

    @action
    setProfileId(profileId) {
        this.profileId = profileId;
    }

    @action
    setActId(actId) {
        this.actId = actId;
    }

    @action
    setActName(actName) {
        this.actName = actName;
    }

    @action
    setTimeStart(timeStart) {
        this.timeStart = timeStart;
    }

    @action
    setTimeEnd(timeEnd) {
        this.timeEnd = timeEnd;
    }
    @action
    setTimeStartString(timeStart) {
        this.timeStartString = timeStart;
    }

    @action
    setTimeEndString(timeEnd) {
        this.timeEndString = timeEnd;
    }

    @action
    setMessages(messages){
        this.messageChats = messages
    }

    @action
    deleteMessage(id){
        return agent.Conversation.deleteMessage(id).then(
            action(response => {
                return response;
            })
        );
    }
    mapAdditionalConversationData = conversation => {
        const currentProfile = profileStore.getCurrentProfile();

        
        if (conversation) {
            let from = [];
            if (conversation.conversationParticipants) {
                conversation.conversationParticipants.forEach(conversationParticipant => {
                    let participantProfile = conversationParticipant.profile
                    if (participantProfile.type == ProfileType.Agent.ordinal && participantProfile.profileName) {
                        from.push(participantProfile.profileName)
                    } else {
                        from.push(participantProfile.firstName + " " + participantProfile.lastName);
                    }
                });
            }

            if (conversation.Metadata) {
                let metadata = JSON.parse(conversation.metadata);
                // if (metadata && metadata.fromFirstName) {
                //     from.push(metadata.fromFirstName + " " + metadata.fromLastName);
                // }
                this.firstName = metadata.fromFirstName;
                this.lastName = metadata.fromLastName;
                this.email = metadata.fromEmail;
                this.phone = metadata.fromPhone;
                this.eventDate = metadata.eventDate;
                this.eventType = metadata.eventType;
                this.timeStart = metadata.timeStart;
                this.timeEnd = metadata.timeEnd;
                this.actName = metadata.actName;
                this.actId = metadata.actId;
                this.metadataBody = metadata.body;
                this.utcOffset = new Date().getTimezoneOffset();
            }

            conversation.from = from.join(", ");
        }
    };

    @action
    leaveConversation(conversationId) {
        const currentProfile = profileStore.getCurrentProfile();

        if (!currentProfile) {
            return Promise.resolve();
        }

        this.isLoading = true;

        return agent.Conversation.leaveConversation(conversationId,currentProfile.id)
            .then(
                action(response => {
                    this.load()
                    return response;

        }))

    }

    @action
    addParticipant(conversationId,profile) {
        const currentProfile = profileStore.getCurrentProfile();

        if (!currentProfile) {
            return Promise.resolve();
        }

        const participantData = {
            conversationId : conversationId,
            createdById : currentProfile.id,
            profileId : profile.id,
            organisationId:null,
            regionId: null,
            actId : null,
            stateId : null,
            venue : null,
            venueId : null,
            venueMemberId : null
        }

        return agent.Conversation.addParticipant(currentProfile.id, participantData)
            .then(
                action(response => {
                    
                    if(this.messageId && this.messageId > 0){
                    
                    
                        const data = {
                            body: `${currentProfile.firstName} ${currentProfile.lastName} added ${profile.firstName} ${profile.lastName} to this conversation`,
                            conversationId: conversationId,
                            profileId: null,
                            from: null,
                            isFromEmail : false
                        };
    
                        return agent.Conversation.createMessage(data)
                            .then(
                                action(response => {
                                    this.loadById(response.conversationId)
                                    return response;
                                })
                        )
                    }

                    this.loadById(conversationId)

                    return response
                        

        }))

    }

    @action
    removeParticipant(conversationId,participantRemove) {
        const currentProfile = profileStore.getCurrentProfile();

        if (!currentProfile) {
            return Promise.resolve();
        }

        return agent.Conversation.removeParticipant(participantRemove.id, conversationId, currentProfile.id)
            .then(
                action(response => {

                    if(this.messageId && this.messageId > 0){

                            const data = {
                                body: `${currentProfile.firstName} ${currentProfile.lastName} removed ${participantRemove.firstName} ${participantRemove.lastName} to this conversation`,
                                conversationId: conversationId,
                                profileId: null,
                                from: null,
                                isFromEmail : false
                            };

                            return agent.Conversation.createMessage(data)
                                .then(
                                    action(response => {
                                        this.loadById(response.conversationId)
                                        return response;
                                    })
                            )
                    }

                    this.loadById(conversationId)

                    return response

        }))

    }

    @action
    load() {
        const currentProfile = profileStore.getCurrentProfile();

        if (!currentProfile) {
            return Promise.resolve();
        }

        this.isLoading = true;

        return agent.Conversation.loadByProfileId(currentProfile.id)
            .then(
                action(response => {
                    this.conversations = response;
                    this.unreadMessage = 0
                    this.conversations.forEach(conversation => {
                        if (conversation.messages && conversation.messages.length > 0) {
                            conversation.messages.forEach(message => {
                                if (message.isRead == false && message.profileId !== currentProfile.id) {
                                    this.unreadMessage = this.unreadMessage + 1
                                }
                            })
                        }
                        // this.unreadMessage = this.unreadMessage + conversation.count
                        this.mapAdditionalConversationData(conversation);
                    });

                    return this.conversations;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    loadById(conversationId) {
        const currentProfile = profileStore.getCurrentProfile();
        this.isLoading = true;
        var countUnreadMessage = 0;

        return agent.Conversation.loadById(conversationId)
            .then(
                action(async response => {
                    if(response){
                        this.title = response.subject;
                        this.conversation = response
    
                        for(var i=0;i<response.messages.length;i++){
                            var profileImageUrl = null
                            if(response.messages[i].profile && response.messages[i].profile.profileImage){
                                profileImageUrl = await CacheHelper.getImgLink(response.messages[i].profile.profileImage.url200);
                            }
                            if(response.messages[i].isFromEmail){
                                if(response.conversationParticipants.length && response.conversationParticipants[0] && response.conversationParticipants[0].profile && response.conversationParticipants[0].profile.profileImage){
                                    profileImageUrl = await CacheHelper.getImgLink(response.conversationParticipants[0].profile.profileImage.url200);
                                }
                            }
                            runInAction(() => {
                                this.messageChats[i] = response.messages[i]
                                if(response.messages[i].profile && response.messages[i].profile.profileImage){
                                    this.messageChats[i].profileImageUrl = profileImageUrl
                                }
                                if(response.messages[i].isFromEmail){
                                    this.messageChats[i].profileImageUrl = profileImageUrl
                                }
                            })
                        }

                        //check count unread message
                        let tempConversation = null;
                        for (let j = 0; j < this.conversations.length; j++) {
                            if (response.id == this.conversations[j].id) {
                                tempConversation = this.conversations[j]
                            }

                            if (tempConversation && tempConversation.messages){
                                tempConversation.messages.forEach(msg => {
                                    if(msg.isRead == false && msg.profileId !== currentProfile.id){
                                        countUnreadMessage ++
                                    }
                                })
                            }
                        }

                        //update read conversation
                        agent.Conversation.updateConversationRead(currentProfile.id,conversationId).then(action(response=>{
                            runInAction(() => {
                                this.unreadMessage = this.unreadMessage - countUnreadMessage
                            })
                        }))
    
                    }
                    return response
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    submit() {

        const currentProfile  = profileStore.getCurrentProfile();

        const owner = {
            conversationId : 0,
            createdById : currentProfile.id,
            createdBy : currentProfile,
            organisationId:null,
            profile:currentProfile,
            profileId : currentProfile.id,
            regionId: null,
            stateId : null,
            venue : null,
            venueId : null,
            venueMemberId : null
        }

        var conversationParticipantsArr = []

        for(let data of this.participant){
            const participantData = {
                conversationId : 0,
                createdById : currentProfile.id,
                createdBy : currentProfile,
                organisationId:null,
                profile:data,
                profileId : data.id,
                regionId: null,
                stateId : null,
                venue : null,
                venueId : null,
                venueMemberId : null
            }

            conversationParticipantsArr.push(participantData)
        }

        conversationParticipantsArr.push(owner)

        const data = {
            conversationParticipants: conversationParticipantsArr,
            fromFirstName: currentProfile.firstName,
            fromLastName: currentProfile.lastName,
            fromEmail: currentProfile.email,
            eventDate: moment(this.eventDate).endOf("day"),
            utcOffset: new Date().getTimezoneOffset(),
            eventType: this.eventType,
            body: this.body.toString('html').replace(/<a /g, '<a target="_blank" '),
            subject: this.title,
            createdBy: currentProfile,
            timeStart : "",
            timeEnd : "",
            actName : "",
            actId : null
        };

        return agent.Conversation.create(data).then(
            action(async response => {
                this.conversation = response
                for(var i=0;i<response.messages.length;i++){
                    var profileImageUrl = null
                    if(response.messages[i].Profile.ProfileImage){
                        profileImageUrl = await CacheHelper.getImgLink(response.messages[i].Profile.ProfileImage.Url200);
                    }

                    runInAction(() => {
                        this.messageChats[i] = response.messages[i]
                        if(response.messages[i].Profile.ProfileImage){
                            this.messageChats[i].profileImageUrl = profileImageUrl
                        }

                        
                    })

                }
                
                return response;
                
            })
        );
    }

    @action
    submitInvoice(title, message) {

        const currentProfile  = profileStore.getCurrentProfile();

        const owner = {
            conversationId : 0,
            createdById : currentProfile.id,
            createdBy : currentProfile,
            organisationId:null,
            profile:currentProfile,
            profileId : currentProfile.id,
            regionId: null,
            stateId : null,
            venue : null,
            venueId : null,
            venueMemberId : null
        }

        var conversationParticipantsArr = []

        for(let data of this.participant){
            const participantData = {
                conversationId : 0,
                createdById : currentProfile.id,
                createdBy : currentProfile,
                organisationId:null,
                profile:data,
                profileId : data.id,
                regionId: null,
                stateId : null,
                venue : null,
                venueId : null,
                venueMemberId : null
            }

            conversationParticipantsArr.push(participantData)
        }

        conversationParticipantsArr.push(owner)

        const data = {
            conversationParticipants: conversationParticipantsArr,
            fromFirstName: currentProfile.firstName,
            fromLastName: currentProfile.lastName,
            fromEmail: currentProfile.email,
            eventDate: moment(this.eventDate).endOf("day"),
            utcOffset: new Date().getTimezoneOffset(),
            eventType: this.eventType,
            body: message.toString('html').replace(/<a /g, '<a target="_blank" '),
            subject: title,
            createdBy: currentProfile,
            timeStart : "",
            timeEnd : "",
            actName : "",
            actId : null
        };

        return agent.Conversation.create(data).then(
            action(async response => {
                this.conversation = response
                for(var i=0;i<response.messages.length;i++){
                    var profileImageUrl = null
                    if(response.messages[i].Profile.ProfileImage){
                        profileImageUrl = await CacheHelper.getImgLink(response.messages[i].Profile.ProfileImage.Url200);
                    }

                    runInAction(() => {
                        this.messageChats[i] = response.messages[i]
                        if(response.messages[i].Profile.ProfileImage){
                            this.messageChats[i].profileImageUrl = profileImageUrl
                        }

                        
                    })

                }
                
                return response;
                
            })
        );
    }

    @action
    submitMessage() {
        this.isLoading = true;
        const currentProfile  = profileStore.getCurrentProfile();
        if (!currentProfile) {
            return Promise.resolve();
        }
        const data = {
            body: this.body.toString('html').replace(/<a /g, '<a target="_blank" '),
            conversationId: this.conversation.id,
            profileId: currentProfile.id,
            from: currentProfile.firstName+" "+currentProfile.lastName,
            isFromEmail : false
        };

        return agent.Conversation.createMessage(data)
            .then(
                action(response => {
                    this.loadById(response.conversationId)
                    return response;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    changeSubjectConversation() {
        this.isLoading = true;
        const currentProfile  = profileStore.getCurrentProfile();
        if (!currentProfile) {
            return Promise.resolve();
        }

        return agent.Conversation.updateSubjectConversation(this.conversation.id, this.title, currentProfile.id)
            .then(action(response => {
                this.loadById(response.conversationId)
                return response;
            }))
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    createContactActConversation(profile) {
        const currentProfile = profileStore.getCurrentProfile()
        const metadata = {
            fromFirstName: this.firstName,
            fromLastName: this.lastName,
            fromEmail: this.email,
            fromPhone: this.phone,
            eventDate: moment(this.eventDate).endOf("day"),
            utcOffset: new Date().getTimezoneOffset(),
            eventType: this.eventType,
            body: this.body,
            timeStart: moment(this.timeStartString, "HH:mm"),
            timeEnd: moment(this.timeEndString, "HH:mm"),
            address: this.searchedAddress,
            actName: this.actName ? this.actName : profile.actName,
            actId: this.actId ? this.actId : profile && profile?.type == ProfileType.Act.ordinal ? profile.id : null
        };

       function getProfile(data) {
            if (data.type == ProfileType.Act.ordinal) {
                if (data.actAdminId)  return data.actAdminId
                else return null 
            } else if (data.type == ProfileType.Venue.ordinal) {
                if (data.venueAdmin)  return data.venueAdmin.id
                else return null 
            } else {
                return data.id
            }
        }  
        const senderParticipant = {
            profileId: currentProfile ? currentProfile.id : null,
            createdById: currentProfile ? currentProfile.id : null,
            actId : null,
            organisationId: currentProfile ? currentProfile?.organisationId : null,
            regionId: currentProfile ? currentProfile?.regionId : null,
            stateId: currentProfile ? currentProfile?.stateId : null,
            venueId: currentProfile && currentProfile?.type == ProfileType.Venue.ordinal ? profile.id : null,
            venueMemberId: currentProfile ? currentProfile?.venueMemberId : null,
        };
        const conversationParticipant = {
            profileId: profile ? getProfile(profile) : this.profileId,
            createdById: currentProfile ? currentProfile.id : null,
            actId: profile && profile?.type == ProfileType.Act.ordinal ? profile.id : null,
            organisationId: profile ? profile?.organisationId : null,
            regionId: profile ? profile?.regionId : null,
            stateId: profile ? profile?.stateId : null,
            venueId: profile && profile?.type == ProfileType.Venue.ordinal ? profile.id : null,
            venueMemberId: profile ? profile?.venueMemberId : null,
        };
        const conversation = {
            createdById: currentProfile ? currentProfile.id : null,
            metadata: JSON.stringify(metadata),
            conversationParticipants: [senderParticipant, conversationParticipant]
        };

        return agent.Conversation.createContactActConversation(conversation)
            .then(
                action(response => {
                    //this.messages = response;
                    return response;
                })
            )
            .catch(
                action(err => {
                    //this.errors = err.response && err.response.body && err.response.body.errors;
                    this.errors = "Error sending message";
                    throw err;
                })
            );
        }
}

export default new ConversationStore();
