import React, { Component } from "react";

import { inject, observer } from "mobx-react";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import WidgetContainer from "../widget/WidgetContainer.js";
import ResponsiveTable from "../table/ResponsiveTable";
import moment from "moment";

import "./UpcomingBooking.css";
import { Link } from "react-router-dom";
import { BookingType } from "../../types/enum.js";

@inject("profileStore", "bookingRequestStore", "templateStore")
@observer
class UpcomingBookingAgent extends Component {
    state = {
        venue: "null",
        consultant: "null",
        columns: [
            { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, addComp: true, style: {whiteSpace: 'nowrap'} },
            { key: "venueName", label: "Venue", width: "20%" },
            { key: "actName", label: "Act", width: "20%" },
            { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
            { key: "actFee", label: "Act Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
            { key: "agentFee", label: "My Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
            { key: "consultant", label: "Booking Creator", width: "15%", align: 'left', }
        ]
    };

    handleVenueChange = event => {
        this.setState({ venue: event.target.value });
    };

    handleConsultantChange = event => {
        this.setState({ consultant: event.target.value });
    };

    mapBookingDataToRowData = () => {
        const { tableData } = this.props
        const { currentProfile } = this.props.profileStore;
        
        if (tableData) {
            return tableData.map(booking => {
                var row = {};
                let date = new Date(booking.dateStart);
                let time = booking.timeStart.length < 8 ? `0${booking.timeStart}` : booking.timeStart;
                row.date = {value:`${booking.dateStart.format("DD MMM YYYY")} ${moment(time).format('hh.mm A')}`, newFormat: <Link to={this.bookingRedirectPath(booking)} target={"_blank"}>{booking.dateStart.format("DD MMM YYYY")} {moment(time).format('hh.mm A')}</Link>};
                row.venueName = (
                    <div>
                        <span>{booking.venue && booking.venueId ? booking.venue.profileName : booking.venueName}</span>
                        <br />
                        <small>{booking.venueAddress}</small>
                    </div>
                );
                row.actName = ( <Link target={"_blank"} to={`/profile/${booking.act.id}`}>{booking.act.actName}</Link>);
                row.agentFee = ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$") + parseFloat(booking.agentCommissionAmount).toLocaleString();
                row.gigFee = ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$") + parseFloat(booking.gigFee).toLocaleString();
                row.actFee = ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$") + parseFloat(booking.actFee).toLocaleString();
                row.consultant = booking.createdBy.firstName + " " + booking.createdBy.lastName;
                
                return row;
            })
        }
        else {
            return [];
        }
    };

    bookingRedirectPath = (booking) => {
        let path = `/booking/${booking.id}/edit`
        if (booking.type === BookingType.Adhoc.ordinal) path = `/privateBooking/${booking.id}/edit`;
        return path;
    };

    render() {
        return (
            <Paper>
                <WidgetContainer
                    title="Upcoming Gigs"
                    showBadge={this.props.tableData && this.props.tableData.length > 0 ? true : false}
                    badgeValue={this.props.tableData && this.props.tableData.length}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <ResponsiveTable
                                sortable={true}
                                columns={this.state.columns}
                                rows={this.mapBookingDataToRowData()}
                            />
                        </Grid>
                    </Grid>
                </WidgetContainer>
            </Paper>
        );
    }
}

export default UpcomingBookingAgent;
