import { observable, action, runInAction} from "mobx";
import agent from "../agent";
import CacheHelper from "../helper/cache";

class BillingStore {
    @observable billingAccess;
    @observable customer;
    @observable billingPlans;
    @observable card;
    @observable subscription;
    @observable invoices;
    @observable billingInformation;
    @observable paymentAccesses;
    @observable planIdMusician = "plan_E5fDQFVsTISNZt";
    @observable planIdAgent = "plan_E5fIfwzh9z4QPJ";
    @observable planIdVenue = "plan_E5fItgGn3ijmLF";
    @observable temporaryAccess = false;

    @action
    getCustomerByUserId(userId){
        return agent.Billing.getCustomerByUserId(userId)
        .then(
            action(customer => {
                // if (customer) {
                //     this.customer = customer;
                // }

                return customer.response;
            })
        );
    }

    @action
    setTemporaryAccess(value){
        this.temporaryAccess = value;
    }
    @action
    getCardByCustomerId(customerId){
        return agent.Billing.getCardByCustomerId(customerId)
        .then(
            action(card => {
                // if (card) {
                //     this.card = card;
                // }

                return card.response;
            })
        );
    }

    @action
    getSubscriptionByCustomerId(customerId){
        return agent.Billing.getSubscriptionByCustomerId(customerId)
        .then(
            action(subscription => {
                // if (subscription) {
                //     this.subscription = subscription;
                // }

                return subscription.response;
            })
        );
    }

    @action
    getInvoicesByCustomerId(customerId){
        return agent.Billing.getInvoicesByCustomerId(customerId)
        .then(
            action(invoices => {
                // if (invoices) {
                //     this.invoices = invoices;
                // }
                return invoices.response;
            })
        );
    }

    @action
    getChargesByCustomerId(customerId){
        return agent.Billing.getChargesByCustomerId(customerId)
        .then(
            action(charges => {
                // if (invoices) {
                //     this.invoices = invoices;
                // }
                console.log( charges.response)
                return charges.response;
            })
        );
    }
    
    @action
    downloadInvoicePdf(customerId, invoiceId) {
        return agent.Billing.downloadPdf(customerId, invoiceId)
        .then(response => {
            return response;
        });
    }

    @action
    getBillingInformation(customerId) {
        return agent.Billing.getBillingInformation(customerId)
        .then(
            action(billingInformation => {
                // if (billingInformation) {
                //     this.billingInformation = billingInformation;
                // }

                return billingInformation;
            })
        );
    }

    @action
    upgradeProPlan(profileType,data) {
        return agent.Billing.upgradeProPlan(profileType,data)
        .then(
            action(response => {
                return response;
            })
        );
    }
    @action
    downgradeProPlan(profileType,data) {
        return agent.Billing.downgradeProPlan(profileType,data)
        .then(
            action(response => {
                return response;
            })
        );
    }
    @action
    cancelDowngradeProPlan(profileType) {
        return agent.Billing.cancelDowngradeProPlan(profileType)
        .then(
            action(response => {
                return response;
            })
        );
    }

    @action
    getPaymentAccesses(userId){
        return agent.Billing.getPaymentAccesses(userId)
        .then(
            action(paymentAccesses => {
                if (paymentAccesses){
                    this.paymentAccesses = paymentAccesses;
                }

                return paymentAccesses;
            })
        );
    }

    @action
    getBillingPlans(){
        return agent.Billing.getBillingPlans()
        .then(
            action(billingPlans => {
                if (billingPlans){
                    this.billingPlans = billingPlans;
                }

                return billingPlans;
            })
        );
    }

    @action
    getAccess = async (sub, type) => {
        return await agent.Billing.billingAccess(sub, type)
        .then(
            action(billingAccess => {
                if (billingAccess){
                    this.billingAccess = billingAccess;
                }

                return billingAccess;
            })
        );
        // return  {
        //     "isSubscriptionRequired": true,
        //     "isNeedToUpdatePayment": false,
        //     "plan": {
        //         "planName": "Venue Group Membership",
        //         "productPrice": 0
        //     }
        // }
    }

    loadAccess(){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        let user = CacheHelper.getUser();
        if(currentProfile.type == 1 || currentProfile.type == 2 || currentProfile.type == 3 || currentProfile.type == 9){
            if(this.billingAccess == undefined){
                let license = CacheHelper.getLicense();
                if(license == null || license == undefined){
                    let profileType = currentProfile.type;
                    if(profileType == 3 && currentProfile.isOwnOrganization) profileType = 4;
                    agent.Billing.billingAccess(user.sub, profileType)
                    .then(
                        action(billingAccess => {
                            if (billingAccess){
                                this.billingAccess = billingAccess.response;
                                CacheHelper.setLicense(billingAccess.response);
                            }
                            return billingAccess.response;
                        })
                    );
                }else {
                    this.billingAccess = license;
                }
            }
            return this.billingAccess;
        }
    }

    reloadAccess(){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        let user = CacheHelper.getUser();
        if(currentProfile.type == 1 || currentProfile.type == 2 || currentProfile.type == 3 || currentProfile.type == 9){
            let profileType = currentProfile.type;
            if(profileType == 3 && currentProfile.isOwnOrganization) profileType = 4;
            agent.Billing.billingAccess(user.sub, profileType)
                    .then(
                        action(billingAccess => {
                            if (billingAccess){
                                this.billingAccess = billingAccess.response;
                                CacheHelper.setLicense(billingAccess.response);
                            }
                            return billingAccess.response;
                        })
                    );
            return this.billingAccess;
        }
    }

    resetAccess(){
        this.billingAccess = null;
        CacheHelper.setLicense(null);
    }
    @action
    async updateCustomerEmail(data){
        await agent.Billing.updateCustomerEmail(data);
    }
    @action
    async reloadAccessSynch(){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        let user = CacheHelper.getUser();
        if(currentProfile.type == 1 || currentProfile.type == 2 || currentProfile.type == 3 || currentProfile.type == 9){
            let profileType = currentProfile.type;
            if(profileType == 3 && currentProfile.isOwnOrganization) profileType = 4;
            let billingAccess = await agent.Billing.billingAccess(user.sub, profileType)
           
            if (billingAccess){
                runInAction(() => {
                    this.billingAccess = billingAccess.response;
                })
              
                CacheHelper.setLicense(billingAccess.response);
            }
            return this.billingAccess;
        }
    }

    setAccess = async (type) => {
        let user = CacheHelper.getUser();
        if(type == 1 || type == 2 || type == 3 || type == 5){
            agent.Billing.billingAccess(user.sub, type)
            .then(
                action(billingAccess => {
                    if (billingAccess){
                        this.billingAccess = billingAccess.response;
                        CacheHelper.setLicense(billingAccess.response);
                    }
                })
            );
        }else {
            this.billingAccess = null;
            CacheHelper.setLicense(null);
        }
    }

    setAccessSynch = async (type) => {
        let user = CacheHelper.getUser();
        if(type == 1 || type == 2 || type == 3 || type == 5){
            let billingAccess = await agent.Billing.billingAccess(user.sub, type);
            if (billingAccess.response){
                this.billingAccess = billingAccess.response;
                CacheHelper.setLicense(billingAccess.response);
            }
        }else {
            this.billingAccess = null;
            CacheHelper.setLicense(null);
        }
        return Promise.resolve(this.billingAccess);
    }
    
}

export default new BillingStore();