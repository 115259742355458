import { observable, action } from "mobx";
import agent from "../agent";

class myobStore {
    @observable myobToken = "";
    @observable myobListofAccountsOutgoing = [];
    @observable myobListofAccountsIncoming = [];
    @observable OutgoingAccounts = "";
    @observable IncomingAccounts = "";

    @observable listMYOBMappedCustomers = [];
    @observable listMYOBMappedSuppliers= [];
    @observable myobSuppliers = [];
    @observable myobCustomers = [];
    @observable myobSuppliersFull = [];
    @observable myobCustomersFull = [];
    @observable invoiceMYOBId= "";
    @observable editedPayerID= "";
    @observable editedMYOBID= "";

    @observable myobFirstName= ""
    @observable myobLastName= ""
    @observable myobEmail= ""
    @observable integrateReturnResult = [];
    
    @observable companyUrl = []
    // this.myobListofAccountsOutgoing = mappedAccounts.filter(x=>x.)
    // this.myobListofAccountsIncoming = mappedAccounts
    
    @action
    resetMYOBPopUp(){
        this.invoiceMYOBId= ""
        this.myobFirstName= ""
        this.myobLastName= ""
        this.myobEmail= ""
    }
    @action
    createMapInvoiceMYOBCustomer(profileId,invoiceId){
        var data = {
            profileId: profileId,
            invoiceId: invoiceId,
            myobCustomersId: this.invoiceMYOBId
        }
        return agent.MYOB.createMappedInvoiceMYOBCustomer(data)
        .then(
            action(response => {
                return response
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }

    @action
    updateMapInvoiceMYOBCustomer(profileId,invoiceId){
        var data = {
            profileId: profileId,
            invoiceId: invoiceId,
            myobCustomersId: this.invoiceMYOBId
        }
        return agent.MYOB.updateMapInvoiceMYOBCustomer(data)
        .then(
            action(response => {
                return response
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }

    @action
    deleteMapInvoiceMYOBCustomer(profileId,invoiceId){
        var data = {
            profileId: profileId,
            invoiceId: invoiceId,
        }
        return agent.MYOB.deleteMapInvoiceMYOBCustomer(data)
        .then(
            action(response => {
                return response
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }

    @action
    createInvoiceMYOBCustomer(profileId,invoiceId){
        var data = {
            firstName: this.myobFirstName,
            lastName: this.myobLastName,
            email: this.myobEmail,
            invoiceId :invoiceId
        }
        return agent.MYOB.createInvoiceMYOBCustomer(profileId,data)
        .then(
            action(response => {
                return response
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }
    @action
    getInvoiceMYOBCustomerById(profileId,invoiceId){
        return agent.MYOB.getInvoiceMYOBCustomerById(profileId,invoiceId)
        .then(
            action(response => {
                this.myobFirstName = response.lastName
                this.myobLastName = response.firstName
                this.myobEmail = response.email
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }
    @action
    getMappedInvoiceMYOBCustomer(profileId,invoiceId){
        return agent.MYOB.getMappedInvoiceMYOBCustomer(profileId,invoiceId)
        .then(
            action(response => {
                if(response){
                    this.invoiceMYOBId = response.myobCustomersId
                    return response;
                }else{
                    return null
                }
              
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }
    @action
    getBookingByInvoiceId(invoiceId){
        return agent.Invoice.getBookingByInvoiceId(invoiceId)
        .then(
            action(response => {
                this.myobFirstName = response.clientFirstName
                this.myobLastName = response.clientLastName
                this.myobEmail = response.clientEmail
                
                return response;
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }
    @action
    setEditedSupplierMYOB(editedPayerID,editedMYOBID){
       
        this.listMYOBMappedSuppliers.forEach(item => {
            if(item.payeeId === editedPayerID) {
                item.myobUID = editedMYOBID
                item.isEdited = true
            }
           
        });
    }

    @action 
    setMyobFirstName(value){
        this.myobFirstName = value
    }
    @action 
    setMyobLastName(value){
        this.myobLastName = value
    }
    @action 
    setMyobEmail(value){
        this.myobEmail = value
    }
    @action 
    setMyobDisplayId (value){
        this.myobDisplayId = value
    }
   
    @action 
    setInvoiceMYOB(myobUID){
        this.invoiceMYOBId = myobUID
    }
    @action
    cancelEditedSupplierMYOB(editedPayerID,isUpdate){
        this.listMYOBMappedSuppliers.forEach(item => {
            if(item.payeeId === editedPayerID) {
                if(isUpdate){
                    item.isEdited = false
                    item.isUpdated = false
                }else{
                    item.myobUID = ""
                    item.isEdited = false
                    item.isUpdated = false
                }
               
            }
        });
    }
    @action
    setEditedMYOB(editedPayerID,editedMYOBID){
        this.listMYOBMappedCustomers.forEach(item => {
           
            if(item.payerId === editedPayerID) {
                item.myobUID = editedMYOBID
                item.isEdited = true
            }
        });
    }

    @action
    setUpdatedMYOB(editedPayerId){
        this.listMYOBMappedCustomers.forEach(item => {
            if(item.payerId === editedPayerId) {
                item.isUpdated = true
                var filtered = this.myobCustomersFull.filter(x=>x.value === item.myobUID)
                if(filtered.length){
                    this.myobCustomers.push(filtered[0])
                }
               
            }
        });
    }

    @action
    setUpdatedMYOBSupplier(editedPayeeId){
        this.listMYOBMappedSuppliers.forEach(item => {
            if(item.payeeId === editedPayeeId) {
                item.isUpdated = true
                var filtered = this.myobSuppliersFull.filter(x=>x.value === item.myobUID)
                if(filtered.length){
                    this.myobSuppliers.push(filtered[0])
                }
               
            }
        });
    }

    @action
    setUpdatedMYOBSupplier(editedPayeeId){
        this.listMYOBMappedSuppliers.forEach(item => {
            if(item.payeeId === editedPayeeId) {
                item.isUpdated = true
                var filtered = this.myobSuppliersFull.filter(x=>x.value === item.myobUID)
                if(filtered.length){
                    this.myobSuppliers.push(filtered[0])
                }
               
            }
        });
    }
    @action
    cancelUpdatedMYOBSupplier(editedPayeeId){
        this.listMYOBMappedSuppliers.forEach(item => {
            if(item.payerId === editedPayeeId) {
                item.isUpdated = false
                this.myobSuppliers = this.myobSuppliers.filter(x=>x.value != item.myobUID)
                
            }
        });
    }

    @action
    cancelUpdatedMYOB(editedPayerId){
        this.listMYOBMappedCustomers.forEach(item => {
            if(item.payerId === editedPayerId) {
                item.isUpdated = false
                this.myobCustomers = this.myobCustomers.filter(x=>x.value != item.myobUID)
                
            }
        });
    }
    @action
    cancelEditedMYOB(editedPayerID){
        this.listMYOBMappedCustomers.forEach(item => {
            if(item.payerId === editedPayerID) {
                item.myobUID = ""
                item.isEdited = false
            }
        });
    }
    @action
    setIncomingAccounts(value){
        this.IncomingAccounts= value;
    }
    @action
    setOutgoingAccounts(value){
        this.OutgoingAccounts= value;
    }
    @action
    setMyobCode(code){
        this.myobToken = code
    }
    @action
    connectMyob(profileId, data){
        return agent.MYOB.connectMyob(profileId,data).then(action(
            result => {
                return result;
            }
        ));
    }
    // mapExistingMYOBSupplier :(profileId, body) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/map/supplier`,body),
    // mapExistingMYOBCustomer :(profileId, body) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/map/customer`,body),
    @action
    mapExistingMYOBSupplier(profileId, relatedID, myobUID){
        var body = {
            relatedId: relatedID,
            myobUID: myobUID
        }
        return agent.MYOB.mapExistingMYOBSupplier(profileId,body).then(action(
            result => {
                return result;
            }
        ));
    }

    @action
    mapExistingMYOBCustomer(profileId, relatedId, myobUID){
        var body = {
            relatedId: relatedId,
            myobUID: myobUID
        }
        return agent.MYOB.mapExistingMYOBCustomer(profileId,body).then(action(
            result => {
                return result;
            }
        ));
    }
    
    @action
    createMYOBCustomers(profileId, customerId){
        return agent.MYOB.createMYOBCustomers(profileId,customerId).then(action(
            result => {
                return result;
            }
        ));
    }

    @action
    deleteMYOBMapping(id){
        return agent.MYOB.deleteContactMYOB(id).then(action(
            result => {
                return result;
            }
        ));
    }
    
    @action
    updateContactMYOB(id,myobID){
        var data = {
            myobUID : myobID
        }
        return agent.MYOB.updateContactMYOB(id,data).then(action(
            result => {
                return result;
            }
        ));
    }
    
    @action
    createMYOBSuppliers(profileId, supplierID){
        return agent.MYOB.createMYOBSuppliers(profileId,supplierID).then(action(
            result => {
                return result;
            }
        ));
    }

    @action
    mapAccounts(profileId){
        var data = {
            inGoingInvoiceMYOB: this.IncomingAccounts,
            outGoingInvoiceMYOB: this.OutgoingAccounts,
        }
        return agent.MYOB.mapAccounts(profileId,data).then(action(
            result => {
                return result;
            }
        ));
    }
    @action
    getMYOBListOfAccounts(profileId){
        return agent.MYOB.getListOfAccounts(profileId).then(action(
            result => {
                var mappedAccountsOutgoing = []
                var mappedAccountsIncoming = []
                result.Items.forEach(item => {
                    if((item.Classification === "Income") && item.Level > 1){
                        mappedAccountsOutgoing.push({ value: item.UID, label: item.DisplayID + " - "+item.Name})
                    }else if((item.Classification === "Expense" || item.Classification === "CostOfSales") && item.Level > 1){
                        mappedAccountsIncoming.push({ value: item.UID, label: item.DisplayID + " - "+item.Name})
                    }
                    
                });
                this.myobListofAccountsOutgoing = mappedAccountsOutgoing
                this.myobListofAccountsIncoming = mappedAccountsIncoming
                return result;
            }
        ));
        
    }

    @action
    getListMYOBMappedCustomers(profileId){
        return agent.MYOB.getListMYOBMappedCustomers(profileId).then(action(
            result => {
                this.listMYOBMappedCustomers = result
                return result;
            }
        ));
        
    }

    @action
    resetResultIntegration(){
        this.integrateReturnResult = []
    }
    @action
    getListMYOBMappedSuppliers(profileId){
        return agent.MYOB.getListMYOBMappedSuppliers(profileId).then(action(
            result => {
                this.listMYOBMappedSuppliers = result
                return result;
            }
        ));
        
    }

    @action
    getMYOBSuppliers(profileId){
        return agent.MYOB.getMYOBSuppliers(profileId).then(action(
            result => {
                var myobSuppliersFull = []
                var myobSuppliers = []
                result.Items.forEach(item => {
                    var name = item.IsIndividual ? item.FirstName + " "+item.LastName : item.CompanyName
                    if(!item.isMapped){
                        
                        myobSuppliers.push({ value: item.UID, label: name})
                    }
                    myobSuppliersFull.push({ value: item.UID, label: name})
                });
                this.myobSuppliers = myobSuppliers
                this.myobSuppliersFull = myobSuppliersFull
                return result;
            }
        ));
        
    }

    @action
    integrateIncomingInvoice(profileId, selectedIncomingInvoice){
        var body = {
            invoiceIds: selectedIncomingInvoice
        }
        return agent.MYOB.integrateIncomingInvoice(profileId, body).then(action(
            result => {
                result.failed.forEach(item => {
                    this.integrateReturnResult.push({id: item.id,invoiceNumber: item.invoiceId, type: 0, code: item.code, message:`sync failed on Invoice no ${item.invoiceId}. Reason : ${item.messages}`})
                });
                result.success.forEach(item => {
                    this.integrateReturnResult.push({id: item.invoiceId,type: 1, message:`invoice number ${item.invoiceId} successfully synced`})
                });
                return this.integrateReturnResult;
            }
        )).catch(action(err => {
           
        }))
    }

    @action
    integrateOutgoingInvoice(profileId, selectedOutgoingInvoice){
        var body = {
            invoiceIds: selectedOutgoingInvoice
        }
        return agent.MYOB.integrateOutgoingInvoice(profileId, body).then(action(
            result => {
                result.failed.forEach(item => {
                    this.integrateReturnResult.push({id: item.id,invoiceNumber: item.invoiceId, type: 0, code: item.code, message:`sync failed on Invoice no ${item.invoiceId}. Reason : ${item.messages}`})
                });
                result.success.forEach(item => {
                    this.integrateReturnResult.push({id: item.invoiceId, type: 1, message:`invoice number ${item.invoiceId} successfully synced`})
                });
                return this.integrateReturnResult;
            }
        ));
    }

    @action
    handleSelectCompany(body){
        return agent.MYOB.mapCompanyUri(body).then(action(
            result => {
               
                return result;
            }
        ));
    }
    @action
    getMYOBListCompany(profileId){
        return agent.MYOB.getCompanyUriList(profileId).then(action(
            result => {
                
                var options = []
                result.forEach(item => {
                    options.push({ value: item.Uri, label: item.Name });

                });
                this.companyUrl = options
                return options;
            }
        ));
    }

    @action
    disconnect(profileId){
        return agent.MYOB.disconnect(profileId).then(action(result => {
            return result
        }))
    }
    @action
    getMYOBCustomers(profileId){
        return agent.MYOB.getMYOBCustomers(profileId).then(action(
            result => {
                var myobCustomers = []
                var myobCustomersFull = []
                result.Items.forEach(item => {
                    var name = item.IsIndividual ? item.FirstName + " "+item.LastName : item.CompanyName
                    if(!item.isMapped){
                        
                        myobCustomers.push({ value: item.UID, label: name})
                    }
                    myobCustomersFull.push({ value: item.UID, label: name})
                });
                this.myobCustomers = myobCustomers
                this.myobCustomersFull = myobCustomersFull
                return result;
            }
        ));
        
    }

    

}

export default new myobStore();
