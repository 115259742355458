/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:6c71cdb0-d791-438d-8e91-cc5026ced705",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_KQMeQzsby",
    "aws_user_pools_web_client_id": "2g9rqgjsh5ubahc2664qpefrat",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "hotgiggity-be",
            "endpoint": "https://xebzg693ji.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "hotgiggitybackend29d007dbf85b471eb62f7ef7768ca3114623-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};

export default awsmobile;
