import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Button, TextField, Grid, Typography, MenuItem, Switch } from "@material-ui/core";
import Loading from "../loadingSpinner/Loading";
import ConfirmationModal from "../modal/ConfirmationModal";
import FestivalModal from "./festivalModal/FestivalModal";
import { Fragment } from "react";
import agent from "../../agent";
import { useLocation } from "react-router-dom"

@inject("privacyProfileStore", "templateStore", "authStore", "userStore", "profileStore", "userFestivalStore", "profileStore", "agentActStore", "billingStore")
@withRouter
@observer
class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            actConfirmationState: false,
            openFestivalModal: false,
            selectedActId: undefined
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // set type
        this.props.privacyProfileStore.setType(this.props.templateStore.getType(this.props.type));

        if (this.props.type === "act") {
            //this.props.userFestivalStore.getUserFestival();
            this.props.userFestivalStore.getUserFestivalForAct(this.props.profileId);
        }

        this.props.profileStore.getUserActList();

        this.props.privacyProfileStore
            .loadInitialData(this.props.profileId)
            .then(() => {})
            .finally(() => {
                this.setState({ isLoading: false });
                window.scrollTo(0, 0);
            });
    }

    componentWillUnmount() {
        this.props.userFestivalStore.setUserActFestivals(undefined);
    }

    handleVisibilityChange = e => this.props.privacyProfileStore.setVisibility(e.target.value);

    handleBack = () => {
        if (this.props.type === "venue") {
            const { profile } = this.props.profileStore;
            this.props.history.push(`/venues/${profile.id}/edit/contract`);
        }
        if (this.props.type === "act") {
            this.props.history.replace(`/myact/edit/${this.props.match.params.id}/toc`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    openFestivalModal = () => {
        this.setState({
            ...this.state,
            openFestivalModal: true
        });
    };

    handleSubmitForm =  async (e) => {
        e.preventDefault();
        const { currentUser } = this.props.userStore;
        const { userActFestival } = this.props.userFestivalStore;
        const actList =  this.props.profileStore.actList;
        this.setState({ isLoading: true }); 
        var isConsultantInvitation = this.props.match.params.role && this.props.match.params.role === "consultant"

        if ((this.props.match.params.new === "new" && !isConsultantInvitation) || (this.props.type === 'venue' && this.props.match.params.tab === 'privacy')) {
            let candidateCustomer = { 
                userId: currentUser.sub, 
                email: currentUser.email, 
                given_name: currentUser.given_name, 
                family_name: currentUser.family_name, 
                userType: 0
            };
    
            if(this.props.type == "musician"){
                candidateCustomer.userType = 1;
                await agent.Billing.createCustomer(candidateCustomer);
                this.props.billingStore.reloadAccess();
            }else if(this.props.type == "agent"){
                candidateCustomer.userType = 2;
                await agent.Billing.createCustomer(candidateCustomer);
                this.props.billingStore.reloadAccess();
            }else if(this.props.type == "venue"){
                //move the process on the save on general tab
                // candidateCustomer.userType = 3;
                // await agent.Billing.createCustomer(candidateCustomer);
                // this.props.billingStore.reloadAccess();
           }else if(this.props.type == "privateHire"){
                // agent.Billing.createCustomer({ userId: currentUser.sub, email: currentUser.email, given_name: currentUser.given_name, family_name: currentUser.family_name, userType: 5 });
                // this.props.billingStore.reloadAccess();
           }
        }

       
        this.props.privacyProfileStore.submit().then((profile) => {
            // const { editProfileActiveStep } = this.props.templateSoitore;
            // this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
            const { isAgentAct } = this.props.agentActStore;
             
            if (currentUser && currentUser.status === 50) {
                if (userActFestival) {
                    this.openFestivalModal();
                } else {
                    switch (this.props.type) {
                        case "agent":
                            this.props.profileStore.loadProfiles();
                            this.props.history.push("/agent");
                            break;
                        case "musician":
                            this.props.profileStore.loadProfiles();
                            this.props.history.push("/musician");
                            break;
                        case "staff":
                            this.props.profileStore.loadProfiles();
                            this.props.templateStore.openSnackbar("Profile saved");
                            this.props.history.push("/staff");
                            break;
                        case "venue":
                            this.props.history.push("/venues");
                            break;
                        case "act":
                            if (isAgentAct) {
                                this.props.history.push("/agent/actroster/myact")
                                this.props.agentActStore.setIsAgentAct(false);
                                break
                            }
                            this.props.history.push("/myact");
                            break;
                        case "production":
                            this.props.history.push("/production");
                            break;
                        case "dj":
                            this.props.history.push("/dj");
                            break;
                        case "consultant":
                            this.props.history.push("/agent");
                            break;
                        case "privateHire":
                            this.props.profileStore.loadProfiles();
                            this.props.history.push("/privateHire/gig");
                            break;
                        default:
                            this.props.history.push("/welcome");
                            break;
                    }
                }
            } else {
                if (userActFestival) {
                    this.openFestivalModal();
                } else {
                    if (this.props.type === "act") {
                        this.setState({
                            ...this.state,
                            actConfirmationState: true
                        });

                    } else if(this.props.type === "consultant"){

                        this.props.history.push("/agent");

                    } else if(this.props.type === "privateHire") {
                        this.props.history.push("/account/setting/privatehirelock");
                    }else {
                        this.props.userStore.pullUser().then(() => {
                            this.props.history.push("/welcome");
                        });
                    }
                }
            }
        });
    };

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    handleActConfirmationGotoDashboard = () => {
        this.props.userStore.completeUser().then(() => {
            this.props.history.push("/musician");
        });
    };

    handleCreateAnotherAct = () => {
        this.props.userStore.pullUser().then(() => {
            this.props.history.push("/welcome");
        });
    };

    handleCloseActConfirmationState = () => {
        this.setState({
            ...this.state,
            actConfirmationState: false
        });
    };

    handleAllowAgent = e => {
        this.props.privacyProfileStore.setAllowInvitesAgent(e.target.checked)
    }

    handleAllowAct = e => {
        this.props.privacyProfileStore.setAllowInvitesMusician(e.target.checked)
    }

    handleAllowMusicianApplyToActLineup = e => {
        this.props.privacyProfileStore.setAllowMusicianApplyToActLineup(e.target.checked)
    }

    handleIncludeInHotgiggityHire = e => {
        this.props.privacyProfileStore.setIncludeHotgiggityHire(e.target.checked)
    }

    render() {
        const { id, visibility, allowInvitesFromAgent, allowInvitesFromMusician, allowMusicianApplyToActLineup, includeInHotgiggityHire, isConsultant } = this.props.privacyProfileStore;
        const { editProfileActiveStep } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        const { isLoading, actConfirmationState, openFestivalModal } = this.state;
        return (
            <div className="Profile-Privacy">
                <ConfirmationModal
                    open={actConfirmationState}
                    title="Act Confirmation"
                    message={`Do you want to create another act?`}
                    closeHandler={this.handleCloseActConfirmationState}
                    confirmationHandler={this.handleCreateAnotherAct}
                    declineHandler={this.handleActConfirmationGotoDashboard}
                    confirmationLabel="Create Another Act"
                    declineLabel="No, Go to Dashboard"
                />

                {type === "act" ? <FestivalModal open={openFestivalModal} actId={id} /> : null}

                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Privacy
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    {type === "act"
                                        ? "Manage your act privacy. You choose who can see and find you on Hot Giggity."
                                        : "Manage your privacy. You choose who can see and find you on Hot Giggity."}
                                </Typography>
                            </div>
                        </Grid>
                        

                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="visibility"
                                        label="Visibility"
                                        margin="normal"
                                        fullWidth
                                        value={visibility || "public"}
                                        onChange={this.handleVisibilityChange}
                                        error={this.anyError("visibility")}
                                        helperText={this.errorText("visibility")}
                                        select
                                    >
                                        {/* <MenuItem value="contact">My Contact</MenuItem> */}
                                        <MenuItem value="private">Private</MenuItem>
                                        <MenuItem value="public">Searchable by Everyone</MenuItem>
                                    </TextField>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>

                        {/* {((type === "agent" && this.props.match.params.role!=="consultant") || type === "musician" || type === "act" ) && (
                            <Grid container spacing={24}>
                                
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Include profile in Hot Giggity hire
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            {type === "agent" || type === "consultant" ? "Shows or hides profile in Hot Giggity hire search result" : "Show profile in Hot Giggity hire"}
                                    </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={9}>
                                    <div>
                                        <Switch checked={includeInHotgiggityHire} onChange={this.handleIncludeInHotgiggityHire} color="primary" />{" "}
                                        Show profile in Hot Giggity hire
                                    </div>
                                </Grid>
                                    
                            </Grid>
                        )} */}
                        {/* {type === "agent" && 
                            <Fragment>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Allow apply act line up
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Shows or hides the "Apply to act Lineup" button on act search
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <div>
                                            <Switch checked={allowMusicianApplyToActLineup} onChange={this.handleAllowMusicianApplyToActLineup} color="primary" />{" "}
                                            Allow musician apply to your act lineup
                                        </div>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        } */}
                       {(type === "venue" || (type === "agent" && this.props.match.params.role!=="consultant")) && (
                            <>
                           
                                <Grid container spacing={24}>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Allow invites from Acts
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Shows or hides the "{type === "agent" ? "Apply to Act Roster" : "Apply to play"}" button on {type === "agent" ? "agent" : "act owner"} search
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <div>
                                            <Switch checked={allowInvitesFromMusician} onChange={this.handleAllowAct} color="primary" />{" "}
                                            Allow invites from Acts
                                        </div>
                                    </Grid>
                                </Grid>
                                {type !== "agent" && 
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} lg={3}>
                                            <div className="sticky-information">
                                                <Typography variant="headline" component="h3" gutterBottom>
                                                    Allow invites from Agents
                                                </Typography>
                                                <Typography gutterBottom className="side-helper-text">
                                                    Shows or hides the "Apply to Book" button on agent search
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={9}>
                                            <div>
                                                <Switch checked={allowInvitesFromAgent} onChange={this.handleAllowAgent} color="primary" />{" "}
                                                Allow invites from Agents
                                            </div>
                                        </Grid>
                                    </Grid>
                                }

                            </>
                        )}

                            <Grid item xs={12}>
                                <div className="action">
                                    <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                        Back
                                    </Button>
                                    {id && (
                                        <Button variant="contained" color="primary" type="submit">
                                            {editProfileActiveStep === steps.length - 1 || isConsultant ? "Save/Finish" : "Next"}
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                        

                    
                </form>
            </div>
        );
    }
}

export default Privacy;
