import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
// import NumberFormat from "react-number-format";
// import moment from "moment";
import { ProfileType, BookingType, BookingStatusType, LineupStatusType, RosterDividendType } from "../../types/enum";
import "./BookingDetails.css";
import { Grid, Typography, Button, Checkbox,Menu, Snackbar, IconButton, Tooltip,MenuItem } from "@material-ui/core";
import ResponsiveTable from "../table/ResponsiveTable";
import BookingStatusBadge from "./BookingStatusBadge";
import FacebookPostDialog from "../facebookPostDialog/FacebookPostDialog";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import PrintModal from "./PrintModal.js/PrintModal";
import PrintIcon from "@material-ui/icons/Print";
import facebook from "../../img/facebook-box.svg";
import ConfirmationModal from "../modal/ConfirmationModal";
import currencyHelper from '../../helper/currency';
import FacebookSharePostDialog from "../facebookSharePostDialog/FacebookSharePostDialog";
import Profile from "../../Profile";

const FACEBOOK_ENABLED = process.env.REACT_APP_FACEBOOK_ENABLED === "true";

const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("profileStore", "generalSocialPostStore", "templateStore", "facebookStore", "reportStore")
@withRouter
@observer
class GeneralSocialPost extends Component {
    state = {
        columns: [],
        acknowledgeSnackbarState: false,
        booking: undefined,
        selectedBookings: [],
        showPrintOption: false,
        sharedBookings: [],
        actionAnchor: [],
        duplicateModalState: true,
        duplicateModalState: null,
        openDuplicateSnackbar: false,
        duplicateBooking: undefined,
        page: 1
    };

    rows = [];

    setActionAnchor = (element, bookingId) => {
        var tempAnchor = [];
        tempAnchor[bookingId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    componentDidMount() {
        const {
            currentProfile
        } = this.props.profileStore;

        const { socials } = this.props.generalSocialPostStore;
        const { social } = this.props;
        this.props.generalSocialPostStore.reset();
        this.props.generalSocialPostStore.resetSocials()
            this.props.generalSocialPostStore.showLoadingState();
        if (this.props.profileType === "musician") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "subject", label: "Subject", width: "20%" },
                    { key: "scheduledDate", label: "Scheduled Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "actName", label: "Act Name", width: "20%" },
                    { key: "bookingCreator", label: "Social Post Creator", width: "15%" },
                    { key: "socialImage", label: "Social Images", width: "15%" },
                    { key: "socialPosts", label: "Status",  width: "15%", align: 'center', style: {whiteSpace: 'nowrap'}},
                
                ]
            });
        }else if(this.props.profileType === "staff" || this.props.profileType === "venue" || this.props.profileType === "agent"){
            this.setState({
                ...this.state,
                columns: [
                    { key: "subject", label: "Subject", width: "20%" },
                    { key: "scheduledDate", label: "Scheduled Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "venue", label: "Venue", width: "20%" },
                    { key: "bookingCreator", label: "Social Post Creator", width: "15%" },
                    { key: "socialImage", label: "Social Images", width: "15%" },
                    { key: "socialPosts", label: "Status",  width: "15%", align: 'center', style: {whiteSpace: 'nowrap'}},
                    
                ]
            });
        }
   
        
        this.loadGigs(1)
    }

    loadGigs(page){
        const {
            currentProfile
        } = this.props.profileStore;
        
        if(currentProfile?.type === ProfileType.Staff.ordinal){
            this.props.generalSocialPostStore.loadSocialStaff(currentProfile?.id,page).then(() => {
                this.props.generalSocialPostStore.hideLoadingState();
                this.setState({
                    page: page
                })
            })
        }else{
            this.props.generalSocialPostStore.loadSocial(currentProfile?.id,page).then(() => {
                this.props.generalSocialPostStore.hideLoadingState();
                this.setState({
                    page: page
                })
            })
        }
       
    }

    handleShowMore = e => {
        var page = this.state.page + 1;
        this.loadGigs(page)
    };



 
  

  

    renderTableCustomActions = () => {
        const { selectedBookings } = this.state;
        return (
            <Fragment>
                <Button onClick = { this.openPrintOptionModal } disabled = { !selectedBookings || selectedBookings.length === 0 } style={{ color: selectedBookings.length > 0 ? "#fff" : undefined }}>
                    <PrintIcon style={{ color: selectedBookings.length > 0 ? "#fff" : undefined }} />
                    Print
                </Button>
            </Fragment>
        )
    };

  

 

    openPrintOptionModal = () => {
        this.setState({
            ...this.state,
            showPrintOption: true
        })
    }

    closePrintOptionModal = () => {
        this.setState({
            ...this.state,
            showPrintOption: false
        })
    }



    handleOpenDuplicateBookingModal = booking => this.setState({ duplicateModalState: true, duplicateBooking: booking, actionAnchor: [] });
    handleCloseDuplicateBookingModal = () => {
        this.setState({
            ...this.state,
            duplicateModalState: false,
            duplicateBooking: null
        });
    };
    handleDupicateRequest = () => {
        const { duplicateBooking } = this.state;
        const {currentProfile} = this.props.profileStore
        this.props.generalSocialPostStore.duplicateBooking(duplicateBooking.id,currentProfile?.id ).then(response => {
            this.handleCloseDuplicateBookingModal();
           
            this.setState({ openDuplicateSnackbar: true});
            setTimeout(
                function () {
                    this.props.history.replace(`/booking/${response.id}/edit`);
                }.bind(this),
                2000
            );
            
        });
    };

    handleSnackbarEditClose = (event, reason) => {
        this.setState({ openDuplicateSnackbar: false });
    };
    
    renderSocialTip = () => {
        const { profileType } = this.props;
        let tip = "";
        switch (profileType) {
            case "musician":
                tip = "These are your upcoming gigs. Connect your act profile to your facebook page to push your gigs to Facebook"
                break;
            case "agent":
                tip = "These are your upcoming gigs. Connect your agency profile to your facebook page to push your gigs to Facebook."
                break;
            case "staff":
                tip = "These are your upcoming gigs. Connect each of your venue profiles to your Facebook page to publish your gigs to Facebook"
                break;
        
            default:
                break;
        }

        return tip
    }

    render() {
        let { listSocial,isLoading: isLoadingGigs } = this.props.generalSocialPostStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const { profileType, social } = this.props;
        const { venueId } = this.props.reportStore;
        const { showPrintOption, selectedBookings } = this.state;
        const { match } = this.props;

        if (venueId) {
            listSocial = listSocial.filter(x => x.venueId === venueId);
        }
        const bookingHighlight = match.params.id ? match.params.id : null
        var newColumns = []

        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={9}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                General Social Posts
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                Use this section to schedule your general social posts to Instagram and Facebook. 
                            </Typography>
                          
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button classes={{ root: "pull-right" }} style={{marginLeft: 20, color: 'white'}} color="primary" variant="contained" component={Link} to="/post/general/new">
                            Create Social Post
                        </Button>
                    </Grid>
                   
                    <Grid item xs={12} lg={12} className="booking-table-wrapper" ref={el => (this.componentRef = el)}>
                        <ResponsiveTable
                            showToolbar={true}
                            customActions={
                                this.renderTableCustomActions()
                            }
                            selectable={true}
                            getCheckedRows={this.getCheckedRows}
                            sortable
                            highLight={bookingHighlight}
                            columns={newColumns.length ? newColumns : this.state.columns}
                            rows={listSocial.map(booking => {
                                var row = {};
                                // row.date = date.getDate()+" "+this.props.templateStore.getMonth(date.getMonth())+" "+date.getFullYear();
                                row.id = booking.id;
                                var scheduleTime = booking.scheduleTime
                                row.subject = booking.subject
                                row.scheduledDate = {newFormat: 
                                    (
                                        <div>
                                            <span>{`${moment(scheduleTime).format("ddd, DD MMM YYYY")}`}</span>
                                            <br />
                                            <small style={{textAlign:"center"}}>{`${moment(scheduleTime).format('hh.mm A')}`}</small>
                                        </div>
                                    ), value: scheduleTime};
                               
                                // row.date = {newFormat: `${moment(booking.dateStart).utcOffset(booking.utcOffset).format("ddd, DD MMM YYYY")} ${moment(time).format('hh.mm A')}`, value: finalDate};
                             
                                row.socialImage = (
                                    <div>
                                            <div className="profile-pic-container">
                                                <img src={booking?.gigImage?.url} style={{aspectRatio:1/1, maxWidth:"100%"}} />
                                            </div>
                                    </div>
                                )
                               
                                row.bookingCreator = booking?.createdBy ? (booking?.createdBy[0]?.firstName + " " +booking?.createdBy[0]?.lastName) : ""
                                row.venue = (
                                    <div>
                                        <a target={"_blank"} href={`/profile/${ booking.profile[0]?.id}`}>{booking.profile[0]?.profileName}</a>
            
                                    </div>
                                );
                                row.styleHighlight = {
                                    border: "solid",
                                    borderColor: "#FF0C9C"
                                }
                                row.actName = (
                                    <div>
                                        <a target={"_blank"} href={`/profile/${ booking.profile[0]?.id}`}>{booking.profile[0]?.actName ? booking.profile[0]?.actName : booking.profile[0]?.firstName + " " + booking.profile[0]?.lastName} </a>
                                    </div>
                                );
                           
                               
                         
                              
                                row.socialPosts = 
                                    <Button
                                        size="small"
                                        color="primary"
                                        className="action-button booking-details"
                                        component={Link}
                                        to={`/post/general/${booking?.id}`}
                                        disabled={booking?.postInstagramId || booking?.postFacebookId}
                                    >
                                           { (booking.postInstagramId || booking.postFacebookId) ?  "Published" : "Scheduled - Edit" }
                                    </Button>
                               
                                return row;
                            })}
                        />
                    </Grid>

                    <Grid item style={{marginTop: 25}} xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                        <Grid container direction="row" justify="center" alignItems="center">
                            {this.props.generalSocialPostStore.showMoreSocial ? (
                                <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                    Show More
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

                
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.acknowledgeSnackbarState}
                    onClose={this.closeAcknowledgeSnackbar}
                    autoHideDuration={5000}
                    message={
                        <span id="message-id">{`${
                            this.state.booking ? this.props.generalSocialPostStore.getBookingTitle(this.state.booking) : "Selected gigs"
                        } has been acknowledged.`}</span>
                    }
                    action={[
                        <Button color="primary" size="small" onClick={this.handleUndoAcknowledge}>
                            UNDO
                        </Button>,
                        <IconButton onClick={this.closeAcknowledgeSnackbar} color="primary">
                            <ClearIcon />
                        </IconButton>
                    ]}
                />
                {FACEBOOK_ENABLED && <FacebookPostDialog profileType="venue" callback={this.openAcknowledgeSnackbar} />}

                {FACEBOOK_ENABLED && <FacebookSharePostDialog profileType="musician" reloadData={this.updatedSharedBookingState}/>}

                <ConfirmationModal
                    open={this.state.duplicateModalState}
                    message={`Are you sure you want to duplicate this booking - ${this.state.duplicateBooking?.title}?`}
                    closeHandler={this.handleCloseDuplicateBookingModal}
                    confirmationHandler={this.handleDupicateRequest}
                    declineHandler={this.handleCloseDuplicateBookingModal}
                    confirmationLabel="Duplicate"
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDuplicateSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarEditClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Booking {this.state.duplicateBooking?.title} duplicated</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <PrintModal 
                    open = { showPrintOption }
                    closeHandler = { this.closePrintOptionModal }
                    bookings = { selectedBookings }
                />
            </Fragment>
        );
    }
}

export default GeneralSocialPost;
