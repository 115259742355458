import React from "react";
import { inject, observer } from "mobx-react";
import { Button, Grid, Typography } from "@material-ui/core";

import { withRouter } from "react-router-dom";

import Loading from "../loadingSpinner/Loading";

import SignatureCanvas from "../sigpad/SignatureCanvas";

// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";

@inject("signatureStore", "templateStore", "authStore", "profileStore")
@withRouter
@observer
class Signature extends React.Component {
    sigCanvas = {};
    state = {
        loadingVanityUrlTest: false,
        vanityUrlAvailable: false,
        isLoading: false
    };

    // constructor(props) {
    //     super(props);
    //     // this.props.designProfileStore.reset();
    // }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ isLoading: false });

        // var ratio = Math.max(window.devicePixelRatio || 1, 1);

        // const canvas = this.sigCanvas.getCanvas();
        // canvas.width = canvas.offsetWidth * ratio;
        // canvas.height = canvas.offsetHeight * ratio;
        // canvas.getContext("2d").scale(ratio, ratio);

        // // set type
        this.props.signatureStore.setType(this.props.templateStore.getType(this.props.type));
        this.props.signatureStore
            .loadInitialData(this.props.profileId)
            .then(signature => {
                if(signature){
                this.sigCanvas.fromDataURL(signature.fileContent, { width: 500, height: 250 });
                }
                
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    handleBack = () => {
        if (this.props.type === "venue") {
            const { profile } = this.props.profileStore;
            this.props.history.push(`/venues/${profile.id}/edit/details`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        let sig = this.sigCanvas.toDataURL();
        const data = this.sigCanvas.toData();
        // we only set it if it is a new signature
        if (data && data.length > 0) this.props.signatureStore.setFile(sig);

        this.props.signatureStore.submit().then(resp => {
            const { editProfileActiveStep } = this.props.templateStore;
            this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
        });
    };

    handleClearSignature = event => {
        this.sigCanvas.clear();
    };

    render() {
        const { editProfileActiveStep } = this.props.templateStore;
        const { inProgress } = this.props.signatureStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);

        return (
            <div className="Profile-General">
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={inProgress} />
                    <Grid container spacing={24}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Signature
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Save your signature. This signature will only be use to sign the booking sheet.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <SignatureCanvas
                                                penColor="black"
                                                canvasProps={{ width: 500, height: 250, className: "sigCanvas" }}
                                                ref={ref => {
                                                    this.sigCanvas = ref;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button className="signature-button" size="small" onClick={this.handleClearSignature}>
                                                clear
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <hr style={{ marginTop: 0 }} />
                            <Grid container spacing={8}>
                                <Grid item xs={12} className="action">
                                    <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                        Back
                                    </Button>
                                    <Button
                                        id="submit-design"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={this.state.loadingVanityUrlTest}
                                    >
                                        {editProfileActiveStep === steps.length - 1 ? "Finish" : "Save/Next"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default Signature;
