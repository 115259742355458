import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import organisationMemberStore from "./organisationMemberStore";

class RegionStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable organisation = undefined;
    @observable isLoading = true;

    // Organisation fields
    @observable name = "";
    @observable address = "";
    @observable contactNumber = "";
    @observable regions = [];
    @observable organisationId = undefined;
    @observable stateId = undefined;

    @observable canManage = false;
    @observable canEdit = false;

    @action
    setRegions(regions) {
        this.regions = regions;
    }

    @action
    setName(name) {
        this.name = name;
    }

    @action
    setAddress(address) {
        this.address = address;
    }

    @action
    setContactNumber(contactNumber) {
        this.contactNumber = contactNumber;
    }

    @action
    load(id) {
        this.inProgress = true;

        return agent.Region.load(id)
            .then(
                action(region => {
                    if (region) {
                        this.id = region.id;
                        this.name = region.name;
                        this.address = region.address;
                        this.contactNumber = region.contactNumber;
                        this.stateId = region.stateId;
                        this.canManage = region.canManage;
                        this.canEdit = region.canEdit;
                    }

                    return region;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    remove(name) {
        let state = this.regions.filter(x => x.name === name);
        if (state && state.length > 0) this.regions.remove(state[0]);
    }

    @action
    reset() {
        this.id = undefined;
        this.name = "";
        this.address = "";
        this.contactNumber = "";
        this.regions = [];
        this.canManage = false;
        this.canEdit = false;
    }

    @action
    resetAddForm() {
        this.id = undefined;
        this.name = "";
        this.address = "";
        this.contactNumber = "";
    }

    @action
    create() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const existingState = this.regions.filter(x => x.name === this.name);
        if (existingState.length > 0) return false;

        const state = {
            id: this.id,
            createdById: user.id,
            name: this.name,
            address: this.address,
            contactNumber: this.contactNumber
        };
        this.regions.push(state);
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const members = organisationMemberStore.members.slice();
        // const states = stateStore.states.slice();

        const state = {
            id: this.id,
            createdById: user.id,
            name: this.name,
            address: this.address,
            contactNumber: this.contactNumber,
            stateId: this.stateId,
            members: members
        };

        return (this.id ? this.updateState(state) : this.createState(state))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    createState(data) {
        data.id = 0;
        return agent.Region.create(data).then(
            action(region => {
                this.id = region.id;
                organisationMemberStore.setMembers(region.members);
                return region;
            })
        );
    }

    @action
    updateState(data) {
        return agent.Region.update(data).then(
            action(region => {
                this.id = region.id;
                organisationMemberStore.setMembers(region.members);
                return region;
            })
        );
    }
}

export default new RegionStore();
