import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, Button, Grid, Typography, Divider, Avatar, IconButton, Snackbar } from "@material-ui/core";
import { inject, observer } from "mobx-react";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import "./ManagedActCard.css";
import ConfirmationModal from "../modal/ConfirmationModal";
import image from "../../helper/image";
import CloseIcon from "@material-ui/icons/Close";

import { ImageLazy } from "../image/ImageCool";
import blankBackground from "../../img/hero2.jpg";
import { ProfileType } from "../../types/enum";

@inject("agentMusicianStore", "rosterStore", "profileStore", "agentActStore")
@withRouter
@observer
class ManagedActCard extends Component {
    state = {
        acceptConfirmationState: false,
        declineConfirmationState: false,
        quitConfirmationState: false,
        duplicateConfirmationState: false,
        openDuplicateSnackbar: false
    };

    handleOpenAcceptRequest = () => {
        this.setState({
            ...this.state,
            acceptConfirmationState: true
        });
    };

    handleOpenQuitAct = () => {
        this.setState({
            ...this.state,
            quitConfirmationState: true
        });
    };

    handleOpenDeclineRequest = () => {
        this.setState({
            ...this.state,
            declineConfirmationState: true
        });
    };

    handleCloseConfirmation = () => {
        this.setState({
            ...this.state,
            acceptConfirmationState: false,
            declineConfirmationState: false,
            quitConfirmationState: false,
            duplicateConfirmationState: false
        });
    };

    handleOpenDuplicateRequest = () => {
        this.setState({
            ...this.state,
            duplicateConfirmationState: true
        });
    };

    handleAcceptRequest = () => {
        const { act } = this.props;
        this.props.rosterStore.acceptDeclineRequest(act.id, true).then(response => {
            this.props.profileStore.getUserActList();
            this.handleCloseConfirmation();
        });
    };

    handleDeclineRequest = () => {
        const { act } = this.props;
        this.props.rosterStore.acceptDeclineRequest(act.id, false).then(response => {
            this.props.profileStore.getUserActList();
            this.handleCloseConfirmation();
        });
    };

    handleQuitAct = () => {
        const { act } = this.props;
        this.props.rosterStore.quitRoster(act.id).then(response => {
            this.props.profileStore.getUserActList();
            this.handleCloseConfirmation();
        });
    };

    handleDupicateRequest = () => {
        const { act } = this.props;
        this.props.profileStore.duplicateProfile(act.id).then(response => {
            this.handleCloseConfirmation();
            this.setState({ openDuplicateSnackbar: true});
            setTimeout(
                function () {
                    this.props.history.replace(`/myact/edit/${response.id}`);
                }.bind(this),
                2000
            );
            
        });
    };

    handleSnackbarEditClose = (event, reason) => {
        this.setState({ openDuplicateSnackbar: false });
    };

    handleEdit = () => {
        if(this.props.agentAct) {
            this.props.agentActStore.setIsAgentAct(true);
        }
    }

    render() {
        const { act } = this.props;
        const background = image.getBackgroundImage(act);
        var subheader = this.props.agentMusicianStore.getLineUpCounts(act.lineUpCount)
        var currentProfile = this.props.profileStore.currentProfile
        if(act.vibesName){
            subheader = subheader + " / " + act.vibesName
        }
        if(act.genreName){
            subheader = subheader + " / " + act.genreName
        }
        return (
            <Fragment>
                <Card className="manage-act" raised={false} style={{ boxShadow: "0 1px 1px rgba(0, 0, 0, 0.05)" }}>
                    <div
                        className="act-background-image"
                    >
                        <ImageLazy className="act-background-image" src={background} style={{ objectFit: 'cover'}} placeholder={blankBackground} />
                        <ImageLazy className="act-avatar" style={{ borderRadius: '50%' }} aria-label={act.actName} src={image.getProfileImage(act)} />
                    </div>
                    <CardContent className="act-details">
                        <Typography variant="headline" component="h2">
                            <Link className="act-profile-link" to={"/profile/" + act.id} target={"_blank"} style={!act.isCompleted ? {fontSize:  "20px", display:"flex", alignItems:"center"} : {}}>
                                {this.props.type === "act" ? act.actName : act.profileName} {!act.isCompleted ? <div style={{fontSize: "12px", marginLeft:"5px", backgroundColor:"#B71C1C", color:"white", padding:"7px", borderRadius:"5px", cursor:"default"}}>draft</div> : null}
                            </Link>
                        </Typography>
                        {this.props.type === "act" && (
                            <Typography gutterBottom variant="caption">
                                {subheader}
                            </Typography>
                        )}
                        <Typography className="act-bio" component="div" dangerouslySetInnerHTML={{ __html: act.bio }} />
                    </CardContent>
                    <Divider />
                    {!this.props.hideActions && (
                        <CardActions className="act-action">
                            {this.props.type === "act" ? (
                                <Fragment>
                                    {act.myAct ? (
                                        <Fragment>
                                            <Button
                                                color="default"
                                                component={Link}
                                                to={`/myact/edit/${act.id}`}
                                                size="small"
                                                onClick={this.handleEdit}
                                            // target="_blank"
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                color="default"
                                                component={Link}
                                                to={`/calendar/${act.id}/act`}
                                                size="small"
                                            // target="_blank"
                                            >
                                                Calendar
                                            </Button>
                                            <Button
                                                color="default"
                                                onClick={this.handleOpenDuplicateRequest}
                                                size="small"
                                            // target="_blank"
                                            >
                                                Duplicate
                                            </Button>
                                            <Button color="default" size="small" onClick={this.props.handleRemove}>
                                                Remove
                                            </Button>
                                        </Fragment>
                                    ) : act.accepted ? currentProfile?.type === ProfileType.Musician.ordinal ? (
                                        <Fragment>
                                            <Button
                                                color="default"
                                                component={Link}
                                                to={`/calendar/${act.id}/act`}
                                                size="small"
                                            // target="_blank"
                                            >
                                                Calendar
                                            </Button>

                                            <Button color="default" size="small" onClick={this.handleOpenQuitAct}>
                                                Quit
                                            </Button>
                                        </Fragment>) :  (<Fragment>
                                            <Button
                                                color="default"
                                                component={Link}
                                                to={`/calendar/${act.id}/act`}
                                                size="small"
                                            // target="_blank"
                                            >
                                                Calendar
                                            </Button>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Button color="default" size="small" onClick={this.handleOpenDeclineRequest}>
                                                Decline
                                            </Button>
                                            <Button color="default" size="small" onClick={this.handleOpenAcceptRequest}>
                                                Accept
                                            </Button>
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : (
                                <Grid container spacing={8}>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Button color="default" size="small">
                                            View
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </CardActions>
                    )}
                </Card>

                <ConfirmationModal
                    open={this.state.acceptConfirmationState}
                    title="Accept Request"
                    message={`Are you sure you would like to accept the invite to join and be booked by ${act.actName}? `}
                    closeHandler={this.handleCloseConfirmation}
                    confirmationHandler={this.handleAcceptRequest}
                    declineHandler={this.handleCloseConfirmation}
                    confirmationLabel="Accept"
                />

                <ConfirmationModal
                    open={this.state.declineConfirmationState}
                    title="Decline Request"
                    message={`Are you sure to decline ${act.actName}?`}
                    closeHandler={this.handleCloseConfirmation}
                    confirmationHandler={this.handleDeclineRequest}
                    declineHandler={this.handleCloseConfirmation}
                    confirmationLabel="Decline"
                />

                <ConfirmationModal
                    open={this.state.quitConfirmationState}
                    message={`Are you sure you would like to quit ${act.actName}? This means you will no longer be able to be booked by them?`}
                    closeHandler={this.handleCloseConfirmation}
                    confirmationHandler={this.handleQuitAct}
                    declineHandler={this.handleCloseConfirmation}
                    confirmationLabel="Quit"
                />
                <ConfirmationModal
                    open={this.state.duplicateConfirmationState}
                    message={`Are you sure you want to duplicate this act - ${act.actName}?`}
                    closeHandler={this.handleCloseConfirmation}
                    confirmationHandler={this.handleDupicateRequest}
                    declineHandler={this.handleCloseConfirmation}
                    confirmationLabel="Duplicate"
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDuplicateSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarEditClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Act {act.actName} duplicated</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
            </Fragment>
        );
    }
}

export default ManagedActCard;
