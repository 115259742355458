import React, { Fragment, Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid } from "@material-ui/core";

import Loading from "../../../components/loadingSpinner/Loading";
import GigPromotionCard from "./GigPromotionCard";

@inject("gigPromotionStore")
@observer
class CompletedGigPromotions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.props.gigPromotionStore.getCompletedGigPromotion().then(response => {
            this.setState({
                isLoading: false
            });
        });
    }

    renderGigPromotionsCard = () => {
        const { completedGigPromotions } = this.props.gigPromotionStore;

        return completedGigPromotions.map(gigPromotion => (
            <Grid item xs={12} lg={6} xl={4}>
                <GigPromotionCard 
                    gigPromotion={gigPromotion} 
                    cancelGigPromotionHandler = { () => this.handleCancelGigPromotion(gigPromotion) }
                    hideActionsButton = { true }
                    />
            </Grid>
        ));
    };

    handleCancelGigPromotion = gigPromotion => {
        this.setState({
            isLoading: true
        })
        return this.props.gigPromotionStore.removeGigPromotion(gigPromotion.id).then( () => {
            this.setState({
                ...this.state,
                isLoading: false,
            })
        });
    }

    render() {
        const { isLoading } = this.state;
        return (
            <Fragment>
                <Grid container spacing={16} style={{ position: "relative", minHeight: 300 }}>
                    <Loading showed={isLoading} />
                    {this.renderGigPromotionsCard()}
                </Grid>
            </Fragment>
        );
    }
}

export default CompletedGigPromotions;
