import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import General from "../../components/profile/General";
import Design from "../../components/profile/Design";
import Social from "../../components/profile/Social";
import Financial from "../../components/profile/Financial";
import Privacy from "../../components/profile/Privacy";
import Embed from "../../components/profile/Embed";

import "../stepper.css";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import { ProfileType } from "../../types/enum";

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <General type="production" />;
        case 1:
            return <Design type="production" />;
        case 2:
            return <Social type="production" />;
        case 3:
            return <Financial type="production" />;
        case 4:
            return <Privacy type="production" />;
        case 5:
            return <Embed type="production" />;
        default:
            return "Uknown stepIndex";
    }
}

@inject("templateStore", "profileStore")
@observer
class EditCrew extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/crew" }, { name: "Manage", link: "/manage" }, { name: "My Profile" }];

    componentDidMount = () => {
        this.props.templateStore.setEditProfileActiveStep(0);
    };

    handleNext = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
    };

    handleBack = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleReset = () => {
        this.props.templateStore.setEditProfileActiveStep(0);
    };

    handleStepClick = step => () => {
        this.props.templateStore.setEditProfileActiveStep(step);
    };

    render() {
        const { classes, match } = this.props;
        const steps = this.props.templateStore.getSteps("musician");
        const { editProfileActiveStep } = this.props.templateStore;
        const { profile } = this.props.profileStore;

        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading
                        title={
                            profile && profile.isCompleted && profile.type === ProfileType.Production.ordinal
                                ? "Edit Production Profile"
                                : profile && match && match.params && !match.params.new
                                    ? "Edit Production Profile"
                                    : "Create Production Profile"
                        }
                    />
                    <Hidden smDown>
                        <Stepper nonLinear={profile && profile.isCompleted} activeStep={editProfileActiveStep} alternativeLabel={false}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    <Hidden mdUp xsDown>
                        <Stepper nonLinear={profile && profile.isCompleted} activeStep={editProfileActiveStep} alternativeLabel={true}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    <Hidden smUp>
                        <Stepper
                            nonLinear={profile && profile.isCompleted}
                            activeStep={editProfileActiveStep}
                            alternativeLabel={false}
                            className="stepperXs"
                        >
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepButton onClick={this.handleStepClick(index)} />
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    {editProfileActiveStep === steps.length ? (
                        <Paper className="stepperContent">
                            <Typography className={classes.instructions} component="h3">
                                All steps completed - you're profile is ready!
                            </Typography>
                            <Button onClick={this.handleReset}>Reset</Button>
                        </Paper>
                    ) : (
                        <Paper className="stepperContent">{getStepContent(editProfileActiveStep)}</Paper>
                    )}
                </MainContainer>
            </div>
        );
    }
}

EditCrew.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(EditCrew);
