import React, { Component } from "react";

class VimeoWidget extends Component {
    getEmbedId = url => {
        var vimeo_regex = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
        var match = url.match(vimeo_regex);

        return match ? match[3] : undefined;
    };

    render() {
        const frameHeight = this.props.height || "100%";
        return (
            <div>
                {this.props.url ? (
                    <div style={{ position: "relative", height: '100%', backgroundColor: "#000" }}>
                        <div style={{marginTop: 'auto', marginBottom: 'auto', textAlign: 'center'}}>
                            <iframe
                                title="Vimeo Embed Video"
                                src={`https://player.vimeo.com/video/${this.getEmbedId(this.props.url || "")}?title=0&byline=0&portrait=0`}
                                style={{ width: "100%", height: frameHeight, padding: 0, margin: 0 }}
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowFullScreen
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default VimeoWidget;
