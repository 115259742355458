import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Card, Button, TextField, Grid, Typography, Avatar, IconButton, Switch, Tooltip } from "@material-ui/core";
import { ProfileType } from "../../types/enum";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { withRouter } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import FormControl from "@material-ui/core/FormControl";

import PlayedAtTable from "./playedAt/PlayedAtTable";
import PlayedWithTable from "./playedWith/PlayedWithTable";
import TradingHourTable from "./tradingHour/TradingHourTable";
import validation from "../../helper/validation"

import DoneIcon from "@material-ui/icons/Done";

import Dropzone from "react-dropzone";
import Loading from "../loadingSpinner/Loading";

import { withStyles } from "@material-ui/core/styles";

import "./Design.css";
import { Label } from "react-bootstrap";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";

const FILE_MAXSIZE = IS_MOBILE ? 8497152 : 5497152;
const ACCEPTED_FILE = "image/*";

const styles = theme => ({
    title: {
      color: '#fff',
    }
  });

@inject("designProfileStore", "templateStore", "authStore", "profileStore")
@withRouter
@observer
class Design extends React.Component {
    state = {
        loadingVanityUrlTest: false,
        vanityUrlAvailable: false,
        isLoading: true,
        errorVideo: "",
        errorMusic: "",
        isSubmitted:false
    };

    vanityUrlChecker;

    constructor(props) {
        super(props);
        this.props.designProfileStore.reset();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        // set type
        this.setState({errorMusic: "", errorVideo:""})
        this.props.designProfileStore.setType(this.props.templateStore.getType(this.props.type));
        const {currentProfile} = this.props.profileStore
        this.props.designProfileStore
            .loadInitialData(this.props.profileId?this.props.profileId:currentProfile.id)
            .then(() => { })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    

    handleVanityUrlChange = e => {
        let vanityUrl = e.target.value;
        this.props.designProfileStore.setVanityUrl(vanityUrl);
        this.setState(
            {
                ...this.state,
                loadingVanityUrlTest: true,
                vanityUrlAvailable: false
            },
            () => {
                clearTimeout(this.vanityUrlChecker);
                if (this.props.designProfileStore.vanityUrl.length !== 0) {
                    this.vanityUrlChecker = setTimeout(() => {
                        this.props.designProfileStore.checkVanityUrl().then(response => {
                            this.setState({
                                ...this.state,
                                loadingVanityUrlTest: false,
                                vanityUrlAvailable: response
                            });

                            if (!response) {
                                this.props.templateStore.openSnackbar(this.errorText("vanityUrl"));
                                window.scrollTo(0, 0);
                            }
                        });
                    }, 2000);
                } else {
                    this.setState({
                        ...this.state,
                        loadingVanityUrlTest: false,
                        vanityUrlAvailable: false
                    });
                }
            }
        );
    };

    handleVideoUrlChange = e => {
        this.setState({errorVideo: ""})
        this.props.designProfileStore.setVideoUrl(e.target.value)
    }
    handleValidateVideoUrl = e => {
        if(this.props.designProfileStore.videoUrl.length === 0) return this.setState({errorVideo: ""})
        if(!validation.urlValidation(this.props.designProfileStore.videoUrl)) {
            this.setState({errorVideo: "Invalid URL"})
        } else{    
            this.props.designProfileStore.setVideoUrl(validation.linkify(this.props.designProfileStore.videoUrl))
            this.setState({errorVideo: ""})
        }
    }
    handleMusicUrlChange = e => {
        this.setState({errorMusic: ""})
        this.props.designProfileStore.setMusicUrl(e.target.value);
    }
    handleValidateMusicUrl = e => {
        if(this.props.designProfileStore.musicUrl.length === 0) return this.setState({errorMusic: ""})
        if(!validation.urlValidation(this.props.designProfileStore.musicUrl)) {
            this.setState({errorMusic: "Invalid URL"})
        } else{
            this.props.designProfileStore.setMusicUrl(validation.linkify(this.props.designProfileStore.musicUrl))
            this.setState({errorMusic: ""})
        }
    }
    handleSonglistChange = e => this.props.designProfileStore.setSongList(e.target.value);
    handleTestimonialContentChange = index => e => {
        this.props.designProfileStore.setTestimonialContent(index, e.target.value);
        if (e.target.value) {
            this.props.designProfileStore.setError("testimonials[" + index + "].Content", undefined);
        } else {
            this.props.designProfileStore.setError("testimonials[" + index + "].Content", "The Content field is required.");
        }
    };
    handleTestimonialNameChange = index => e => {
        this.props.designProfileStore.setTestimonialName(index, e.target.value);
        if (e.target.value) {
            this.props.designProfileStore.setError("testimonials[" + index + "].name", undefined);
        } else {
            this.props.designProfileStore.setError("testimonials[" + index + "].name", "The Name field is required.");
        }
    };

    handleChangeProfilePicture = (files, rejectedFiles) => {
       
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.props.templateStore.openSnackbar("Profile image couldn't be uploaded. Please check size and format.");
        } else {
          
            if (this.props.templateStore.getType(this.props.type) === ProfileType.Venue.ordinal){
                this.props.designProfileStore.setProfilePictureVenue(this.props.profileId, files).then(message => {
                    console.log("Message: ", message);
                    if (message === "error") {
                        this.props.templateStore.openSnackbar("Profile image couldn't be uploaded. Please check size and format.");
                    }
                });
            } else {
                this.props.designProfileStore.setProfilePicture(files).then(message => {
                    console.log("Message: ", message);
                    if (message === "error") {
                        this.props.templateStore.openSnackbar("Profile image couldn't be uploaded. Please check size and format.");
                    }
                });
            }
           
        }
    };

    handleAddBackgroundImage = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            let message = "";
            rejectedFiles.forEach(file => {
                message += file.name + ", ";
            });
            message = message.substr(0, message.length - 2);
            message += " couldn't be uploaded. Please check size and format.";
            this.props.templateStore.openSnackbar(message);
        }

        this.props.designProfileStore.setBackgroundImage(files).then(failedUploads => {
            /*if (failedUploads && failedUploads.length > 0) {
                let message = failedUploads.join(", ") + " couldn't be uploaded. Please check size and format.";
                this.props.templateStore.openSnackbar(message);
            }*/
        });
    };

    onConfirmRemoveImage = buttonIndex => {
        if (buttonIndex === 1) {
            this.props.designProfileStore.removeProfilePicture();
        }
    };

    handleDeleteProfilePicture = e => {
        if (IS_MOBILE) {
            navigator.notification.confirm(
                "Are you sure you want to remove the profile picture?", // message
                this.onConfirmRemoveImage, // callback to invoke with index of button pressed
                "Confirmation", // title
                ["Remove", "Do not remove"] // buttonLabels
            );
        } else if (window.confirm("Are you sure you want to remove the profile picture?")) {
            this.props.designProfileStore.removeProfilePicture();
        }
    };

    handleShowPlayedWithChange = e => {
        this.props.designProfileStore.setShowPlayedWith(e.target.checked);
    };

    handleShowAvatarChange = e => {
        this.props.designProfileStore.setShowAvatar(e.target.checked);
    };

    handleDeleteBackgroundPicture = image => {
        this.props.designProfileStore.removeBackgroundPicture(image);
    };

    handleBack = () => {
        if (this.props.type === "venue") {
            const { profile } = this.props.profileStore;
            this.props.history.push(`/venues/${profile.id}/edit/details`);
        }

        const { editProfileActiveStep } = this.props.templateStore;
        if (this.props.type === "act") {
            this.props.history.replace(`/myact/edit/${this.props.match.params.id}/details`);
        }
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        // submit
        const {
            backgroundImages,
            testimonials
        } = this.props.designProfileStore;
        this.setState({
            loadingVanityUrlTest: false,
            vanityUrlAvailable: false
        });
        
        this.setState({isSubmitted:true  });
        var testimonialError = false
        testimonials.forEach(testimonial => {
            if(testimonial.content.length > 1000){
                testimonialError = true
            }
        });
        if(testimonialError){
            this.setState({ isLoading: false });
            this.props.templateStore.openSnackbar("Your testimonal(s) are over the 1000 character limit. Please edit and try saving again.");
            return
        }
        if(this.state.errorMusic || this.state.errorVideo ) {
            this.setState({ isLoading: false });
            return
        }
        if((this.props.type === "act" || this.props.type === "venue") && backgroundImages.length === 0){
            window.scrollTo(0, 0)
            this.setState({ isLoading: false });
            return
        }
        this.setState({ isLoading: true  });
        this.props.designProfileStore.submit().then(resp => {
            if (this.props.type === "venue") {
                this.props.history.push(`/venues/${resp.id}/edit/financial`);
            }
            if (this.props.type === "act") {
                this.props.history.push(`/myact/edit/${resp.id}/social`);
            }
            if (this.props.type !== "act" && this.props.type !== "venue") {
                const { editProfileActiveStep } = this.props.templateStore;
                this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
            }
        });
    };

    anyError(field) {
        if (!this.props.designProfileStore.errors) return false;
        if (!this.props.designProfileStore.errors[field]) return false;
        return true;
    }

    anyErrors() {
        
        const { errors,testimonials } = this.props.designProfileStore;

        const isEmptyTestimonial = testimonials?testimonials.some(testimonial => testimonial.name == "" || testimonial.content ==""):false;
        if(isEmptyTestimonial){
            return isEmptyTestimonial
        }

        if (!errors) {
            return false;
        }
        let keys = Object.keys(errors);

        for (let i = 0; i < keys.length; i++) {
            if (errors[keys[i]]) {
                return true;
            }
        }
        return false;
    }

    handleWebSiteChange = e => this.props.designProfileStore.setWebSite(e.target.value);
    handleValidateWebSite = e => this.props.designProfileStore.validateWebSite(e.target.value);

    handleFacebookChange = e => this.props.designProfileStore.setFacebook(e.target.value);
    handleValidateFacebook = e => this.props.designProfileStore.validateFacebook(e.target.value);

    handleTwitterChange = e => this.props.designProfileStore.setTwitter(e.target.value);
    handleValidateTwitter = e => this.props.designProfileStore.validateTwitter(e.target.value);

    handleBandcampChange = e => this.props.designProfileStore.setBandcamp(e.target.value);
    handleValidateBandcamp = e => this.props.designProfileStore.validateBandcamp(e.target.value);

    handleSoundcloudChange = e => this.props.designProfileStore.setSoundcloud(e.target.value);
    handleValidateSoundcloud = e => this.props.designProfileStore.validateSoundcloud(e.target.value);

    handleMixcloudChange = e => this.props.designProfileStore.setMixcloud(e.target.value);
    handleValidateMixcloud = e => this.props.designProfileStore.validateMixcloud(e.target.value);

    handleYoutubeChange = e => this.props.designProfileStore.setYoutube(e.target.value);
    handleValidateYoutube = e => this.props.designProfileStore.validateYoutube(e.target.value);
    
    handleInstagramChange = e => this.props.designProfileStore.setInstagram(e.target.value);
    handleValidateInstagram = e => this.props.designProfileStore.validateInstagram(e.target.value);

    handleGooglePlusChange = e => this.props.designProfileStore.setGooglePlus(e.target.value);
    handleValidateGooglePlus = e => this.props.designProfileStore.validateGooglePlus(e.target.value);

    handleLinkedInChange = e => this.props.designProfileStore.setLinkedIn(e.target.value);
    handleValidateLinkedin = e => this.props.designProfileStore.validateLinkedin(e.target.value);

    handleITunesChange = e => this.props.designProfileStore.setITunes(e.target.value);
    handleValidateItunes = e => this.props.designProfileStore.validateItunes(e.target.value);

    handleSpotifyChange = e => this.props.designProfileStore.setSpotify(e.target.value);
    handleValidateSpotify = e => this.props.designProfileStore.validateSpotify(e.target.value);


    errorText(field) {
        if (!this.props.designProfileStore.errors) return null;
        if (!this.props.designProfileStore.errors[field]) return null;
        return this.props.designProfileStore.errors[field];
    }

    addTestimonial = () => {
        let testimonial = { content: "", name: "" };
        this.props.designProfileStore.addTestimonial(testimonial);
    };

    handleTestimonialDelete = index => () => {
        this.props.designProfileStore.removeTestimonial(index);
        this.props.designProfileStore.setError("testimonials[" + index + "].Content", undefined);
        this.props.designProfileStore.setError("testimonials[" + index + "].name", undefined);
    };

    renderTestimonials = () => {
        const { testimonials } = this.props.designProfileStore;
        const {classes} = this.props;

        let result = [];

        for (let i = 0; i < testimonials.length; i++) {
            result.push(
                <Grid key={i} item xs={12} md={6} lg={6} style={{ marginBottom: 10 }}>
                    <Card style={{ height: "100%" }}>
                        <CardHeader
                            classes={{ root: "edit-testimonial-card-header",  title: classes.title
                            }}
                            action={
                                <div className="edit-testimonial-action-wrapper">
                                    <Button size="small" onClick={this.handleTestimonialDelete(i)} className="edit-testimonial-card-header-action">
                                        <DeleteIcon style={{ color: "#fff" }} />
                                        <Typography variant="subheading">
                                            Delete
                                        </Typography>
                                    </Button>
                                </div>
                            }
                            title={"Testimonial #" + (i + 1)}
                        />
                        <CardContent classes={{ root: "edit-testimonial-card-content" }}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <TextField
                                        id={`testimonial-${i}`}
                                        label="Testimonial Message"
                                        margin="dense"
                                        multiline
                                        rows={5}
                                        value={testimonials[i].content}
                                        onChange={this.handleTestimonialContentChange(i)}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        error={this.anyError(`testimonials[${i}].Content`)}
                                        helperText={this.errorText(`testimonials[${i}].Content`)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Label style={testimonials[i].content.length > 1000 ? {backgroundColor:"red", fontSize:10} : {fontSize:10}}>{testimonials[i].content.length}/1000</Label>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id={`name-${i}`}
                                        label="From"
                                        margin="dense"
                                        value={testimonials[i].name}
                                        onChange={this.handleTestimonialNameChange(i)}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        error={this.anyError(`testimonials[${i}].name`)}
                                        helperText={this.errorText(`testimonials[${i}].name`)}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            );
        }

        return result;
    };

    render() {
        const {
            videoUrl,
            musicUrl,
            profileImage,
            backgroundImages,
            profileImgInProgress,
            backgroundImgInProgress,
            showPlayedWith,
            showAvatar,
            maxBackgroundImagesCount,
            vanityUrl,
            isConsultant,
            webSite,
            facebook,
            twitter,
            bandcamp,
            soundcloud,
            mixcloud,
            youtube,
            instagram,
            googlePlus,
            linkedIn,
            iTunes,
            spotify
        } = this.props.designProfileStore;
        const { editProfileActiveStep } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        const { isLoading, isSubmitted } = this.state;
        return (
            <div className="Profile-General">
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Profile Image
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    {/* Upload your profile image. Maximum size is 2MB. Full HD resolution for best fit. You have the option to hide your
                                    profile image from your public profile page. */}
                                    {type === "musician"
                                        ? "Complete your public profile with images and media to ensure you stand out from the crowd.  Your public profile will be viewed by anyone searching for a musician and is your way of standing out from the crowd."
                                        : type === "act"
                                            ? "Complete your act public profile with images and media to ensure you stand out from the crowd.  The act public profile will be viewed by anyone searching for a band, will appear in the gig guide listing and is your way of standing out from the crowd."
                                            : type === "venue"
                                                ? "Your venue public profile will be viewed by people who are searching for venues or using the gig guide app to search for entertainment. Complete your venue public profile with your logo and images to ensure you stand out from the crowd."
                                                : type === "agent" && !isConsultant
                                                    ? "Complete your agent public profile with images and media to ensure you stand out from the crowd. Your agent public profile will be viewed by anyone searching for an agent."
                                                    : type === "privateHire" ? "Upload your profile pic here. This will be shown to people you are interacting with on the platform" :
                                                     "Upload your profile image. Maximum size is 2MB. Full HD resolution for best fit. You have the option to hide your profile image from your public profile page."}
                                </Typography>
                                {/* {type !== "venue" &&
                                    type !== "staff" && (
                                        <Fragment>
                                            <Typography>
                                                <b>{!isConsultant?"Tip : ":""}</b>
                                            </Typography>
                                            <Typography className="side-helper-text">
                                                {type === "musician"
                                                    ? "You can load up to 4 background images to your profile. Don't forget to add your profile URL. This can be shared on social media!"
                                                    : type === "act"
                                                        ? "You can load up to 4 background images to your profile. Don't forget to specify your act URL."
                                                        : type === "agent"
                                                            ? "You can load up to 4 background images to your profile. Don't forget to specify your profile URL. This can be shared on social media!"
                                                            : type=== "consultant"? "": "You can upload up to 4 background images to your profile."}
                                            </Typography>
                                        </Fragment>
                                    )} */}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div className="profile-pic">
                                                {!profileImage && (
                                                    <div>
                                                        <Dropzone
                                                            accept={ACCEPTED_FILE}
                                                            maxSize={FILE_MAXSIZE}
                                                            onDrop={this.handleChangeProfilePicture.bind(this)}
                                                            multiple={false}
                                                            className="dropzone"
                                                        >
                                                            <p>Select file.</p>
                                                        </Dropzone>
                                                    </div>
                                                )}
                                                {profileImage && (
                                                    <div className="profile-pic-container">
                                                        {profileImgInProgress ? (
                                                            <Avatar alt="Avatar" className="avatar">
                                                                processing
                                                                <CircularProgress size={240} className="prof-pic-progress" thickness={1} />
                                                            </Avatar>
                                                        ) : (
                                                                <Avatar alt="Avatar" src={profileImage.url400} className="avatar" />
                                                            )}
                                                        <Button
                                                            variant="fab"
                                                            color="secondary"
                                                            className="del-avatar"
                                                            onClick={this.handleDeleteProfilePicture}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                         <Grid item xs={12} className="gridPublicToggle">
                                            {/* <InputLabel>Profile image</InputLabel> */}
                                            <div style={{ marginleft: 20 }}>
                                                <Switch
                                                    checked={showAvatar}
                                                    onChange={this.handleShowAvatarChange}
                                                    value="showAvatar"
                                                    color="primary"
                                                    id="showInProfile"
                                                    className="showInProfile"
                                                />
                                                Show in my public profile page
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {(type !== "staff" && type !== "privateHire") && (
                                <Fragment>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information padded">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Background image
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                {type === "act"
                                                    ? "You can upload 4 background images to display on your act profile page. The maximum size for each image is 2MB. For the best fit use an image 1920px X 1080px."
                                                    : "You can upload 4 background images to display on your profile page. The maximum size for each image is 2MB. For the best fit use an image 1920px X 1080px."}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <hr style={{ marginTop: 0, marginBottom: 30 }} />
                                        {type !== "staff" && (
                                                <Grid item xs={12}>
                                                    <InputLabel className="margin-bottom-10">Background images</InputLabel>
                                                    <div className="background-pic">
                                                        {(backgroundImages === undefined || (backgroundImages && backgroundImages.length === 0)) && (
                                                            <Dropzone
                                                                accept={ACCEPTED_FILE}
                                                                maxSize={FILE_MAXSIZE}
                                                                onDrop={this.handleAddBackgroundImage.bind(this)}
                                                                className="dropzone"
                                                            >
                                                                <p>
                                                                Upload your images here. Maximum size 2 MB for each file. For best fit, use an image 1920px X 1080px.
                                                                </p>
                                                            </Dropzone>
                                                        )}
                                                        {backgroundImages &&
                                                            backgroundImages.length > 0 && (
                                                                <div>
                                                                    <GridList className="gridlist">
                                                                        {backgroundImages.slice().map(image => (
                                                                            <GridListTile key={image.url}>
                                                                                <img src={image.url} alt={image.name || image.fileName} />
                                                                                <GridListTileBar
                                                                                    title={image.name || image.fileName}
                                                                                    className="tilebar"
                                                                                    actionIcon={
                                                                                        <IconButton
                                                                                            className="del"
                                                                                            onClick={this.handleDeleteBackgroundPicture.bind(
                                                                                                this,
                                                                                                image
                                                                                            )}
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    }
                                                                                />
                                                                                {backgroundImgInProgress &&
                                                                                    !image.id && (
                                                                                        <LinearProgress className="background-image-linear-progress" />
                                                                                    )}
                                                                            </GridListTile>
                                                                        ))}
                                                                        {backgroundImages.length < maxBackgroundImagesCount && (
                                                                            <GridListTile>
                                                                                <Dropzone
                                                                                    accept={ACCEPTED_FILE}
                                                                                    maxSize={FILE_MAXSIZE}
                                                                                    onDrop={this.handleAddBackgroundImage.bind(this)}
                                                                                    className="dropzone-gridlist"
                                                                                >
                                                                                    <p>
                                                                                    Upload your images here. Maximum size 2 MB for each file. For best fit, use an image 1920px X 1080px.
                                                                                    </p>
                                                                                </Dropzone>
                                                                            </GridListTile>
                                                                        )}
                                                                    </GridList>
                                                                </div>
                                                            )}
                                                    </div>
                                                </Grid>
                                                
                                            )}
                                            {this.props.type === "act" && isSubmitted && backgroundImages.length === 0 &&
                                                <Grid item xs={12} className="gridPublicToggle">
                                                    {/* <InputLabel>Profile image</InputLabel> */}
                                                    <div style={{ marginleft: 20,color: 'red' }}>
                                                        You must add at least one image of your act. Make it a good one as this will be used in the Hot Giggity Live gig guide app as well as in venue marketing.
                                                    </div>
                                                </Grid>
                                            }
                                              {this.props.type === "venue" && isSubmitted && backgroundImages.length === 0 &&
                                                <Grid item xs={12} className="gridPublicToggle">
                                                    {/* <InputLabel>Profile image</InputLabel> */}
                                                    <div style={{ marginleft: 20,color: 'red' }}>
                                                    You must add at least one image of your venue. Make it a good one as this will be used in the Hot Giggity Live gig guide app.
                                                    </div>
                                                </Grid>
                                            } 
                                    </Grid>
                                </Fragment>
                            )}
                        {type !== "staff" && type != "privateHire" &&
                            !isConsultant && (
                                <Fragment>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information padded">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Profile URL
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Your Profile URL is the address on the web that allows people to find your Hot Giggity profile.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <hr style={{ marginTop: 0 }} />
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth margin="normal">
                                                    <Tooltip title="Unique Profile URL" placement="right">
                                                        <InputLabel shrink>
                                                            Profile URL (<i className="fa fa-sm fa-question">&nbsp;</i>)
                                                        </InputLabel>
                                                    </Tooltip>
                                                    <TextField
                                                        id="vanityUrl"
                                                        margin="normal"
                                                        placeholder="Input Profile URL"
                                                        fullWidth
                                                        value={vanityUrl === "undefined" ? "" : vanityUrl}
                                                        onChange={this.handleVanityUrlChange}
                                                        error={this.anyError("vanityUrl")}
                                                        helperText={this.errorText("vanityUrl")}
                                                        onKeyDown={e => {
                                                            if (e.keyCode === 32) {
                                                                return false;
                                                            }
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{BASE_URL}</InputAdornment>,
                                                            endAdornment: this.state.loadingVanityUrlTest ? (
                                                                <InputAdornment position="start">
                                                                    <CircularProgress size={20} />
                                                                </InputAdornment>
                                                            ) : this.state.vanityUrlAvailable ? (
                                                                <InputAdornment position="start">
                                                                    <DoneIcon />
                                                                </InputAdornment>
                                                            ) : (
                                                                        ""
                                                                    )
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}

                        {type !== "staff" && type != "privateHire" &&
                                                    !isConsultant && 
                        (
                            <Fragment>
                            <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Links
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    {type === "act"
                                        ? "Let people find your act all over the web! These social icons will appear on your public profile so that people can find you on the interwebs."
                                        : "Let people find you all over the web! These social icons will appear on your public profile so that people can find you on the interwebs."}
                                </Typography>
                                <Typography>
                                    <b>Tip:</b>
                                </Typography>
                                <Typography className="side-helper-text">
                                    You need to enter a fully formed URL, example: http://www.yourwebsite.com.
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="webSite"
                                        label="Website"
                                        margin="dense"
                                        fullWidth
                                        value={webSite}
                                        onChange={this.handleWebSiteChange}
                                        onBlur={this.handleValidateWebSite}
                                        error={this.anyError("webSite")}
                                        helperText={this.errorText("webSite")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="facebook"
                                        label="Facebook"
                                        margin="dense"
                                        fullWidth
                                        value={facebook}
                                        onChange={this.handleFacebookChange}
                                        onBlur={this.handleValidateFacebook}
                                        error={this.anyError("facebook")}
                                        helperText={this.errorText("facebook")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="twitter"
                                        label="Twitter"
                                        margin="dense"
                                        fullWidth
                                        value={twitter}
                                        onChange={this.handleTwitterChange}
                                        onBlur={this.handleValidateTwitter}
                                        error={this.anyError("twitter")}
                                        helperText={this.errorText("twitter")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="bandcamp"
                                        label="Bandcamp"
                                        margin="dense"
                                        fullWidth
                                        value={bandcamp}
                                        onChange={this.handleBandcampChange}
                                        onBlur={this.handleValidateBandcamp}
                                        error={this.anyError("bandcamp")}
                                        helperText={this.errorText("bandcamp")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="soundcloud"
                                        label="Soundcloud"
                                        margin="dense"
                                        fullWidth
                                        value={soundcloud}
                                        onChange={this.handleSoundcloudChange}
                                        onBlur={this.handleValidateSoundcloud}
                                        error={this.anyError("soundcloud")}
                                        helperText={this.errorText("soundcloud")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="mixcloud"
                                        label="Mixcloud"
                                        margin="dense"
                                        fullWidth
                                        value={mixcloud}
                                        onChange={this.handleMixcloudChange}
                                        onBlur={this.handleValidateMixcloud}
                                        error={this.anyError("mixcloud")}
                                        helperText={this.errorText("mixcloud")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="youtube"
                                        label="Youtube"
                                        margin="dense"
                                        fullWidth
                                        value={youtube}
                                        onChange={this.handleYoutubeChange}
                                        onBlur={this.handleValidateYoutube}
                                        error={this.anyError("youtube")}
                                        helperText={this.errorText("youtube")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="instagram"
                                        label="Instagram"
                                        margin="dense"
                                        fullWidth
                                        value={instagram}
                                        onChange={this.handleInstagramChange}
                                        onBlur={this.handleValidateInstagram}
                                        error={this.anyError("instagram")}
                                        helperText={this.errorText("instagram")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="googlePlus"
                                        label="Tiktok"
                                        margin="dense"
                                        fullWidth
                                        value={googlePlus}
                                        onChange={this.handleGooglePlusChange}
                                        onBlur={this.handleValidateGooglePlus}
                                        error={this.anyError("googlePlus")}
                                        helperText={this.errorText("googlePlus")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="linkedIn"
                                        label="Linked In"
                                        margin="dense"
                                        fullWidth
                                        value={linkedIn}
                                        onChange={this.handleLinkedInChange}
                                        onBlur={this.handleValidateLinkedin}
                                        error={this.anyError("linkedIn")}
                                        helperText={this.errorText("linkedIn")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="iTunes"
                                        label="Apple Music"
                                        margin="dense"
                                        fullWidth
                                        value={iTunes}
                                        onChange={this.handleITunesChange}
                                        onBlur={this.handleValidateItunes}
                                        error={this.anyError("iTunes")}
                                        helperText={this.errorText("iTunes")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="spotify"
                                        label="Spotify"
                                        margin="dense"
                                        fullWidth
                                        value={spotify}
                                        onChange={this.handleSpotifyChange}
                                        onBlur={this.handleValidateSpotify}
                                        error={this.anyError("spotify")}
                                        helperText={this.errorText("spotify")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        </Fragment>
                        )
                        }
                        {type !== "staff" &&
                            !isConsultant &&
                            type !== "venue" && type != "privateHire" && (
                                <Fragment>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information padded">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Media
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                {type === "agent"
                                                    ? "This media link will showcase your agency and acts on your public profile page. Be sure to use your best Youtube or Vimeo clip. Make sure that your video has embedding enabled."
                                                    : "Add video and audio to your profile to showcase your talents.  For video, you can add a Youtube or Vimeo video. Make sure that your video has embedding enabled. For audio, you can add a single Spotify or Soundcloud link, or embed a playlist." }
                                                        
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <hr style={{ marginTop: 0, marginBottom: 30 }} />
                                        <Grid container spacing={8}>
                                            {type !== "staff" &&
                                                !isConsultant &&
                                                type !== "venue" && (
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="videoUrl"
                                                            label="YouTube or Vimeo URL"
                                                            margin="dense"
                                                            placeholder="Add YouTube or Vimeo URL"
                                                            fullWidth
                                                            value={videoUrl}
                                                            onChange={this.handleVideoUrlChange}
                                                            onBlur={this.handleValidateVideoUrl}
                                                            error={this.state.errorVideo}
                                                            helperText={this.state.errorVideo}
                                                        />
                                                    </Grid>
                                                )}

                                            {(type === "musician" || type === "act") && (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="musicUrl"
                                                        label="SoundCloud URL or SpotifyUrl"
                                                        placeholder="Add SoundCloud URL"
                                                        margin="dense"
                                                        fullWidth
                                                        value={musicUrl}
                                                        onChange={this.handleMusicUrlChange}
                                                        onBlur={this.handleValidateMusicUrl}
                                                        error={this.state.errorMusic}
                                                        helperText={this.state.errorMusic}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        {type === "venue" && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information padded">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Trading Hours
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            List your trading hours here.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <hr style={{ marginTop: 0 }} />
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <InputLabel className="margin-bottom-10">Trading Hours</InputLabel>
                                            <TradingHourTable />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        {(type === "musician" || type === "act") && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information padded">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Played At
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            Use this section to add venues that you have performed at. 
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <hr style={{ marginTop: 0, marginBottom: 30 }} />
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <InputLabel className="margin-bottom-10">Played At</InputLabel>
                                            <PlayedAtTable />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        {type === "musician" && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information padded">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Played With
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            Use this section to add musicans or bands that you have played with. You can hide this section from your
                                            public profile.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <hr style={{ marginTop: 0, marginBottom: 30 }} />
                                    <Grid container spacing={8}>
                                        <Grid item xs={12} style={{ marginTop: -10 }}>
                                            <Grid container spacing={0}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        display: "flex",
                                                        /*justifyContent: "space-between",*/ alignItems: "center"
                                                    }}
                                                >
                                                    <InputLabel>Played With</InputLabel>
                                                    <div style={{ marginleft: 20 }}>
                                                        <Switch
                                                            id="showPlayedWith"
                                                            checked={showPlayedWith}
                                                            onChange={this.handleShowPlayedWithChange}
                                                            value="showPlayedWith"
                                                            color="primary"
                                                        />
                                                        Show in profile
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: -5 }}>
                                                    <PlayedWithTable />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        {type !== "staff" &&
                            !isConsultant && type != "privateHire" && (
                                <Fragment>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information padded">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Testimonials
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                {type === "venue"
                                                    ? "Use this section to add testimonials about your venue.(1000 character limit)"
                                                    : type === "agent"
                                                        ? "Use this section to add testimonials from your venues, acts and corporate clients.(1000 character limit)"
                                                        : type === "act"
                                                            ? "Use this section to add testimonials about your act performances."
                                                            : "Use this section to add testimonials about your performances.(1000 character limit)"}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <hr style={{ marginTop: 0, marginBottom: 30 }} />
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={8}>
                                                    <Grid item xs style={{ display: "flex", alignItems: "flex-end" }}>
                                                        <InputLabel>Testimonials</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            {this.renderTestimonials()}
                                                            <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 10 }}>
                                                                <Tooltip id="tooltip-bottom" title="Add testimonial" placement="bottom">
                                                                    <Card className="add-testimonial-card" onClick={this.addTestimonial}>
                                                                        <AddIcon />
                                                                        <Typography color="primary" variant="headline" style={{ paddingTop: 3 }}>
                                                                            Add Testimonial
                                                                        </Typography>
                                                                    </Card>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        <Grid item xs={12} lg={12}>
                            <hr style={{ marginTop: 0 }} />
                            <Grid container spacing={8}>
                                <Grid item xs={12} className="action">
                                    <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                        Back
                                    </Button>
                                    <Button
                                        id="submit-design"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={this.anyErrors() || this.state.loadingVanityUrlTest}
                                    >
                                        {editProfileActiveStep === steps.length - 1 ? "Finish" : "Save/Next"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default withStyles(styles)(Design);
