import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ModalContainer from "./ModalContainer";
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../pages/GigPromotion/cropImage'
import Grid from "@material-ui/core/Grid";

class CropImageModal extends Component {
    state = {
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1 / 1,
        croppedImage: null,
        croppedAreaPixels: null
    };
    
    onCropChange = (crop) => {
        this.setState({ crop })
      }
    
    onZoomChange = (zoom) => {
        this.setState({ zoom })
      }
    
    
    showCroppedImage = async () => {
        try {
          const croppedImage = await getCroppedImg(
            this.props.image,
            // "https://hotgiggitybackend29d007dbf85b471eb62f7ef7768ca3171534-hgdev.s3.ap-southeast-2.amazonaws.com/public/avatar/200/4058/637810261836130000.jpg",
            this.state.croppedAreaPixels,
            0,
            { horizontal: false, vertical: false },
            this.props.includeAdd
          )
          this.setCroppedImage(croppedImage)
        } catch (e) {
          console.log(e)
        }
    }
    
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({
            croppedAreaPixels: croppedAreaPixels
        })
    }
    
    setCroppedImage = (image) => {
        this.props.confirmationHandler(image)
    }

    render() {
        return (
            <ModalContainer title={this.props.title} open={this.props.open} onClose={this.props.onClose}>
            <DialogContent>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12} style={{ position: "relative",  minHeight:300 }}>
                        <div className="img-container-crop">
                            <Cropper
                                image={this.props.image}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={this.state.aspect}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                // onZoomChange={this.onZoomChange}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={this.props.closeHandler}>
                    {this.props.declineLabel ? this.props.declineLabel : "Close"}
                </Button>
                <Button className="btn-confirmation" color="primary" autoFocus onClick={this.showCroppedImage}>
                    Confirm
                </Button>
            </DialogActions>
        </ModalContainer>
        );
    }
}

export default CropImageModal;
