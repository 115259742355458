
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Header from "../../components/Header";
import Loading from "../../components/loadingSpinner/Loading";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import "./SocialPostsDetail.css";
import Grid from "@material-ui/core/Grid";

import HubTimepicker3 from "../../components/timepicker/HubTimepicker3.js"
//"../../timepicker/HubTimepicker";
import Heading from "../../components/Heading";
import { DateTimePicker, DatePicker } from "material-ui-pickers";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Typography, TextField, InputLabel, Button, Select, MenuItem, Tooltip, Menu} from "@material-ui/core";
import Dropzone from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectGalleryModal from "../../components/modal/SelectGalleryModal.js"
import moment from "moment"
import CropImageModal from "../../components/modal/CropImageModal.js"
import TimePicker from 'react-time-picker';
import { Storage, Cache, Auth } from "aws-amplify";
import { ProfileType } from "../../types/enum.js";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

import GeneralSocialPostComponent from "./GeneralSocialPostComponent.js"

const FILE_MAXSIZE = 2097152;
const ACCEPTED_FILE = "image/*";
const facebookOn = "/facebook-on.png";
const facebookOff = "/facebook-off.png";
const instagramOn = "/instagram-on.png";
const instagramOff = "/instagram-off.png";
const uploadGallery = "/upload-gallery-image.png";
const uploadImage = "/upload-image.png";
const instagramImage = "/instagram-header-background.png";
const hotgiggityLive = "/hotgiggity-live-marker.png";


const defaultPreviewImage = "/default-preview-image.png";
const facebookFooterPreview = "/facebook-footer-preview.png";
const instagramFooterPreview = "/instagram-footer-preview.png";

const utcOffset = new Date().getTimezoneOffset();

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("templateStore","bookingRequestStore", "profileStore", "generalSocialPostStore", "facebookStore")
@withRouter
@observer
class GeneralSocialPost extends Component {
    state = {
        file: null,
        switchUploadImage: false,
        switchSelectGallery: false,
        openGallery: false,
        image: null,
        feedPreview: 1,
        bookingData: undefined,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1 / 1,
        croppedImage: null,
        croppedAreaPixels: null,
        originalImage: null,
        openCropImage: false,
        something: "",
        includeAdd: false,
        facebookUserName: "Hotgiggity Live",
        instagramUserName: "HotgiggityLive",
        disableFacebook: false,
        disableInstagram: false,
        postOpen: false,
        isSchedule: 1,
        actionAnchor: [],
        firstLoad: true
    };
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentWillUnmount() {
    }

    componentDidMount = () => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.showLoadingState();
    };


     
    render() {
        const breadcrumb = [{ name: "Dashboard", link: "/" },, { name: "Social Posts",link: `/socialposts`  }, { name: "General Social Post"}];
        let { isLoading: isLoadingGigs } = this.props.bookingRequestStore;
        let { socialPost,profile, eventDate, timeStartString} = this.props.generalSocialPostStore;
        let { bookingData } = this.state;
        const { currentProfile } = this.props.profileStore;
        const { match } = this.props;

        let {
            scheduleTime,
            gigImage,
            gigGalleryImage,
            isProfile,
            postContent,
            switchInstagram,
            switchFacebook,
            id,
            useProfileSetting
        } = socialPost;

        var currentDate = ` - ${moment(eventDate).format("ddd, DD MMM YYYY")}`
        var currentTime = ` - ${moment(timeStartString).format('hh.mm A')}`
        var profileName = profile? profile.profileName ? profile.profileName : profile.actName ? profile.actName : profile.firstName + " " + profile.lastName : "" 
        var profileNameString = ` - ${profileName}`
        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title={`General Social Post${profileNameString}${currentDate}${currentTime} `}>
                    </Heading>
                    <GeneralSocialPostComponent id={match.params.id} notFromBooking={true}/>
                </MainContainer>



            </div>
        );
    }
}

GeneralSocialPost.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(GeneralSocialPost);
