import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, Button, Typography, Divider, Avatar, Tooltip } from "@material-ui/core";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import "./SearchCard.css";
import { ProfileType } from "../../types/enum";
import BookingOptionModal from "./modal/BookingOptionModal";
import StaffAgentInvitationModal from "../venue/agentRoster/StaffAgentInvitationModal";
import AddMusicianToGigModal from "./modal/AddMusicianToGigModal";
import AddMusicianToActRosterModal from "./modal/AddMusicianToActRosterModal";
import ApplyToPlayModal from "./modal/ApplyToPlayModal";
import image from "../../helper/image";
import MessageModal from "../../components/profile/messageModal/MessageModal";

@withRouter
@inject("agentMusicianStore", "profileStore", "agentVenueStore", "conversationStore")
class ManagedActCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBookingOptionModal: false,
            showInvitationModal: false,
            showMusicianGigInvitationModal: false,
            showMusicianActInvitationModal: false,
            showApplyToPlayModal: false,
            showMessageModal: false,
            profileImage: image.getProfileImage(this.props.profile)
        };
    }

    showBookingOptionModal = () => {
        this.setState({
            ...this.state,
            showBookingOptionModal: true
        });
    };

    showMessageModal = () => {
        this.setState({
            showMessageModal: true
        });
    };
    hideMessageModal = () => {
        this.setState({
            showMessageModal: false
        });
    };

    hideBookingOptionModal = () => {
        this.setState({
            ...this.state,
            showBookingOptionModal: false
        });
    };

    showInvitationModal = () => {
        const { type, profile, profileName, profileImage } = this.props;

        const { currentProfile } = this.props.profileStore;
        if (type === "agent" && currentProfile?.type === ProfileType.Staff.ordinal) {
            this.props.agentVenueStore.setInvitedProfile({
                ...profile,
                fullName: profileName
            });
        }
        this.setState({
            ...this.state,
            showInvitationModal: true
        });
    };

    hideInvitaionModal = () => {
        this.setState({
            ...this.state,
            showInvitationModal: false
        });
    };

    showMusicianGigInvitationModal = () => {
        this.setState({
            ...this.state,
            showMusicianGigInvitationModal: true
        });
    };

    hideMusicianGigInvitationModal = () => {
        this.setState({
            ...this.state,
            showMusicianGigInvitationModal: false
        });
    };

    showMusicianActInvitationModal = () => {
        this.setState({
            ...this.state,
            showMusicianActInvitationModal: true
        });
    };

    handleButtonMessage = (e) => {

        this.props.conversationStore.resetModal()
        this.setState({showMessageModal: true})
    }

    hideMusicianActInvitationModal = () => {
        this.setState({
            ...this.state,
            showMusicianActInvitationModal: false
        });
    };

    
    showApplyToPlayModal = () => {
        this.setState({
            ...this.state,
            showApplyToPlayModal: true
        });
    };

    hideApplyToPlayModal = () => {
        this.setState({
            ...this.state,
            showApplyToPlayModal: false
        });
    };

    render() {
        const { showBookingOptionModal, showInvitationModal, showMusicianGigInvitationModal, showMusicianActInvitationModal, showApplyToPlayModal, profileImage } = this.state;

        const { type, profileName,  profileBio, profileUrl, profileId, profile } = this.props;
        const { currentProfile } = this.props.profileStore;
        return (
            <Card className="manage-act" raised={false} style={{ boxShadow: "0 1px 1px rgba(0, 0, 0, 0.05)" }}>
                <div
                    className="act-background-image"
                    style={{
                        backgroundImage: `url(${image.getBackgroundImage(this.props.profile)})`
                    }}
                >
                    &nbsp;
                    <Avatar className="act-avatar" aria-label={profileName} src={image.getProfileImage(this.props.profile)} />
                </div>
                <CardContent className="act-details">
                    <Typography variant="headline" component="h2">
                        <Link className="act-profile-link" to={profileUrl}>
                            {profileName}
                        </Link>
                    </Typography>
                    <Typography gutterBottom variant="caption">
                        {/*this.props.agentMusicianStore.getLineUpCounts(this.props.act.lineUpCount)*/}
                    </Typography>
                    <Typography className="act-bio" component="div" dangerouslySetInnerHTML={{ __html: profileBio }} />
                </CardContent>
                <Divider />
                <CardActions className="act-action">
                    {type === "act" && (currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal) ? (
                        <Fragment>
                            <Tooltip
                                disableHoverListener={profile.canInvite}
                                title={profile.canInvite ? "" : "Already on your act roster."}
                                placement="bottom"
                            >
                                <span>
                                    <Button size="small" color="default" disabled={!profile.canInvite} onClick={this.props.handleInvite}>
                                        Add Act
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip
                                disableHoverListener={profile.isAvailable}
                                title="Act is not available on selected date/time."
                                placement="bottom"
                            >
                                <span>
                                    <Button size="small" color="default" disabled={!profile.isAvailable} onClick={this.showBookingOptionModal}>
                                        Book Act
                                    </Button>
                                </span>
                            </Tooltip>
                            <BookingOptionModal unShowPrivate={currentProfile?.type === ProfileType.Staff.ordinal ? true : false} open={showBookingOptionModal} onClose={this.hideBookingOptionModal} actId={profileId} />
                        </Fragment>
                    ) : null}

                    {type === "act" && (currentProfile?.type === ProfileType.PrivateHire.ordinal) ? (
                        <Fragment>
                            <Tooltip
                                disableHoverListener={profile.canInvite}
                                title={profile.canInvite ? "" : "Already on your act roster."}
                                placement="bottom"
                            >
                                <span>
                                    <Button size="small" color="default" onClick={this.props.handleAddRemoveFavourite}>
                                        {this.props.isFavourite ? "Remove as Favourite" : "Add as Favourite" }
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip
                                title="Request a quote from this act"
                                placement="bottom"
                            >
                                <span>
                                    <Button variant="raised" color="primary" className="actionButton" onClick={(e) => {
                                            this.handleButtonMessage(e)
                                            }} >
                                        Request a Quote
                                    </Button>
                                </span>
                            </Tooltip>
                            <MessageModal profile={profile} open={this.state.showMessageModal} closeHandler={this.hideMessageModal} />
                        </Fragment>
                    ) : null}

                    {type === "agent" && currentProfile?.type === ProfileType.Musician.ordinal ?
                        <Tooltip
                            disableHoverListener={profile.isAvailable}
                            title="Agent not open to an invitation or already on my agents."
                            placement="bottom"
                            >
                            <span>
                                <Button size="small" color="default" disabled={!profile.allowInvitesFromMusician} onClick={this.showApplyToPlayModal}>
                                    APPLY TO ACT ROSTER
                                </Button>
                            </span>
                        </Tooltip> : null
                    }

                    {type === "musician" && currentProfile?.type === ProfileType.Musician.ordinal ? (
                        <Fragment>
                            <Button size="small" color="default" onClick={this.showMusicianActInvitationModal}>
                                Invite to Act
                            </Button>
                            <Tooltip
                                disableHoverListener={profile.isAvailable}
                                title="Musician is not available on selected date/time."
                                placement="bottom"
                            >
                                <span>
                                    <Button
                                        size="small"
                                        color="default"
                                        disabled={!profile.isAvailable}
                                        onClick={this.showMusicianGigInvitationModal}
                                    >
                                        Invite to Gig
                                    </Button>
                                </span>
                            </Tooltip>
                            <AddMusicianToGigModal
                                open={showMusicianGigInvitationModal}
                                onClose={this.hideMusicianGigInvitationModal}
                                profileName={profileName}
                                profileImage={profileImage}
                                profile={profile}
                            />
                            <AddMusicianToActRosterModal
                                open={showMusicianActInvitationModal}
                                onClose={this.hideMusicianActInvitationModal}
                                profileName={profileName}
                                profileImage={profileImage}
                                profile={profile}
                                filterActMusician={true}
                            />
                            
                        </Fragment>
                    ) : null}

                    {
                        (type === "venue" || type === "agent") && currentProfile?.type === ProfileType.Musician.ordinal && profile.allowInvitesFromMusician && (
                            
                            <ApplyToPlayModal
                                open={showApplyToPlayModal}
                                onClose={this.hideApplyToPlayModal}
                                profileName={profileName}
                                profileImage={profileImage}
                                profile={profile}
                            />
                            
                        )
                    }
                    {type === "venue" && (currentProfile?.type === ProfileType.Agent.ordinal) ? (
                        <Tooltip disableHoverListener={profile.canInvite} title="Already on your venue roster." placement="bottom">
                            <span>
                                <Button size="small" color="default" disabled={!profile.canInvite} onClick={this.props.handleInvite}>
                                    Apply To Book
                                </Button>
                            </span>
                        </Tooltip>
                    ) : null}
                    
                    {type === "venue" && currentProfile?.type === ProfileType.Musician.ordinal && profile.allowInvitesFromMusician  && (
                        // <Tooltip disableHoverListener={!profile.allowInvitesFromMusician} title="Venue not open to an invitation or already on your venue act roster." placement="bottom">
                            <span>
                                <Button size="small" color="default"   onClick={this.showApplyToPlayModal} >
                                    Apply to Play
                                </Button>
                            </span>
                        // </Tooltip>
                    )}
                    {type === "agent" && currentProfile?.type === ProfileType.Staff.ordinal ? (
                        <Fragment>
                            <Button size="small" color="default" onClick={this.showInvitationModal}>
                                Invite Agent
                            </Button>
                            <StaffAgentInvitationModal open={showInvitationModal} onClose={this.hideInvitaionModal} />
                        </Fragment>
                    ) : null}

                    {/* <Button style={{marginLeft:'auto', marginRight: 0}}>
                        Message
                    </Button> */}
                </CardActions>
                
            </Card>
        );
    }
}

export default ManagedActCard;
