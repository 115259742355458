import React, { Component } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import SummaryCard from "../../components/dashboard/SummaryCard";
import InformationCard from "../../components/dashboard/InformationCard";
import MainContainer from "../../components/MainContainer";
import GigsWidget from "../../components/widget/GigsWidget";
import UpcomingBooking from "../../components/dashboard/UpcomingBooking";

import DashboardFilter from "../../components/dashboard/DashboardFilter";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import Grid from "@material-ui/core/Grid";

class Musician extends Component {
    breadcrumb = [{ name: "Dashboard" }];

    handleFilterChange = value => {};

    render() {
        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title="Dashboard">
                        <DashboardFilter onChange={this.handleFilterChange} />
                    </Heading>
                    <Grid container direction="column" spacing={8}>
                        <Grid item>
                            <Grid container spacing={8}>
                                <Grid item md={3} xs={12} sm={12}>
                                    <SummaryCard
                                        headerContent="99"
                                        headerCaption="Total Gigs"
                                        content1="45"
                                        caption1="Upcoming"
                                        content2="54"
                                        caption2="Completed"
                                        primaryColor={true}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12} sm={12}>
                                    <SummaryCard
                                        headerContent="$39,969"
                                        headerCaption="Gig Fees"
                                        content1="$18,696"
                                        caption1="Outstanding"
                                        content2="$21,373"
                                        caption2="Received"
                                        primaryColor={false}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} sm={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={6} xs={12} sm={12}>
                                            <InformationCard mhIcon="mh-microphone" information="9" informationDetail="New Gig Alerts" />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={12}>
                                            <InformationCard mhIcon="fa fa-eye" information="89" informationDetail="Profile Views This Week" />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={12}>
                                            <InformationCard mhIcon="mh-gig" information="45" informationDetail="Upcoming Gigs" />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={12}>
                                            <InformationCard mhIcon="fa fa-envelope" information="8" informationDetail="New Messages" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={8}>
                                <Grid item md={4} xs={12} sm={12}>
                                    <GigsWidget />
                                </Grid>

                                <Grid item md={8} xs={12} sm={12}>
                                    <UpcomingBooking />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainContainer>
            </div>
        );
    }
}

export default Musician;
