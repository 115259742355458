import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";

import ResponsiveTable from "../table/ResponsiveTable";

import { IconButton, Button } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Badge from "@material-ui/core/Badge";

import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import uniqid from "uniqid";
import ConfirmationModal from "../modal/ConfirmationModal";

@inject("invoiceStore", "profileStore", "conversationStore")
@withRouter
@observer
class MessagesTable extends Component {
    columns = [];
    state = {
        leaveConversationConfirm: false,
        conversationId : null
    };

    componentDidMount() {
        this.columns = [
            
            { label: "Subject", key: "subject", sortable: true, useRawData: true, addComp: true, isString: true },
            { label: "From", key: "from", sortable: true, isString: true, addComp: true},
            { label: "Date", key: "date", sortable: true, isDate: true, addComp: true },
           
            { label: "Action", key: "action", sortable: false },
            { label: "", key: "count", sortable: false }
        ];
    }

    rows = [];

    footerRow = {};

    renderInvoiceReportCustomActions = () => (
        <Fragment>
            <IconButton>
                <i class="far fa-file-csv" />
            </IconButton>
            <IconButton>
                <i class="far fa-file-pdf" />
            </IconButton>
        </Fragment>
    );

    openLeaveConfirmation = conversationId => this.setState({ leaveConversationConfirm: true, conversationId : conversationId });
    closeLeaveConfirmation = () => this.setState({ leaveConversationConfirm: false });


    leaveGroupConversation = () =>{
        this.props.conversationStore.leaveConversation(this.state.conversationId);
        this.closeLeaveConfirmation()
    }

    getMessageUnread = (messages) => {
        const currentProfile = this.props.profileStore.getCurrentProfile()
        let total = 0;
        messages.forEach(msg => {
            if (msg.isRead == false && msg.profileId !== currentProfile.id) total++
        })
        return total
    }

    mapConversations = conversations => {
        this.rows = [];

        conversations.forEach(conversation => {
            var row = {};
            let unreadCount = this.getMessageUnread(conversation?.messages)
            row.id = conversation.id
            row.date ={newFormat: <div style={{fontWeight: unreadCount>0 ? "1000" : "", color: unreadCount>0 ? "black" : ""}}>{moment(conversation.createdAt).format("ddd, DD-MM-YYYY")}</div>, value: moment(conversation.createdAt)} 
            row.subject = {newFormat: <Link to={`/messages/${conversation.id}`} style={{fontWeight: unreadCount>0 ? "1000" : "", color: unreadCount>0 ? "#074e8a" : ""}}> {conversation.subject}</Link>, value: conversation.subject};
            row.from = {newFormat: <div style={{fontWeight: unreadCount>0 ? "1000" : "", color: unreadCount>0 ? "black" : ""}}>{conversation.from}</div>, value: conversation.from};
            row.action = (
                <Button variant="contained" color="primary" size="small" onClick={() => this.openLeaveConfirmation(conversation.id)}>
                    LEAVE
                </Button>
            )

            
            row.count =  <Badge badgeContent={unreadCount} className="sidebar-badge" children>
                            <i />
                        </Badge>

            // row.client = conversation.clientName;
            // row.invoicedAmount = conversation.invoicedAmount;
            // row.outgoingPayments = conversation.outgoingPayments;
            // row.netEarnings = conversation.netEarnings;
            // row.gstCollected = conversation.gstCollected;
            // row.gstPaidOut = conversation.gstPaidOut;
            // row.gstBalance = conversation.gstBalance;

            // row.status = conversation.status;

            // row.status = <Chip className={classNames("status-chip", conversation.status)} label={conversation.status} />;

            // row.rawData = conversation;

            this.rows.push(row);
        });
        return this.rows;
    };

    renderTableCustomActions = () => {
        const conversationId = uniqid();
        return (
            <Fragment>
                <Button style={{color:"white"}} component={Link} to={`/messages/new`} >
                    <AddIcon />
                    NEW MESSAGE
                </Button>
            </Fragment>
        )
    };

    handleClickRow = (row)=>{
        if(row){
            this.props.history.push(`/messages/${row.id}`)
        }
    }

    render() {
        const { conversations } = this.props.conversationStore;
        const row = this.mapConversations(conversations);
        // this.mapInvoiceReportToRowData();
        return (

            <Fragment>
                <ResponsiveTable
                    columns={this.columns}
                    rows={row}
                    showToolbar={true}
                    title="My Messages"
                    // footerRow={this.footerRow}
                    sortable={true}
                    getSelectedRow={this.handleClickRow}
                    customActions={
                        this.renderTableCustomActions()
                    }
                />
                <ConfirmationModal
                    open={this.state.leaveConversationConfirm}
                    title={`Are you sure that you want to leave this conversation ?`}
                    message={`By leaving this conversation You will remove all messages and not receive any updates. Continue ?`}
                    closeHandler={this.closeLeaveConfirmation}
                    confirmationHandler={this.leaveGroupConversation}
                    declineHandler={this.closeLeaveConfirmation}
                    confirmationLabel="Yes"
                />
            </Fragment>
        );
    }
}

export default MessagesTable;
