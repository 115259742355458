import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Header from "../../components/Header";
import Heading from "../../components/Heading";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import moment from "moment";

import Paper from "@material-ui/core/Paper";
import QuickBooksCustomer from "../../components/invoice/QuickBooksCustomer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { Button } from "@material-ui/core";
import QuickBooksVendor from "../../components/invoice/QuickBooksVendor";
@inject("templateStore", "invoiceStore", "profileStore", "quickBooksStore")
@withRouter
@observer
class InvoiceQuickBooks extends Component {
    state = {
        pdfFile: undefined,
        receiptNumber: "",
        paymentNote: "",
        invoice: {},
        notFound: false,
        isLoading: true,
        isInvoice: true
    };

    componentDidMount = () => {
        const { invoiceId } = this.props.match.params;
        // this.props.templateStore.showLoadingScreen();
        this.setState({ isLoading: true });
        this.props.quickBooksStore.reset();

        this.props.invoiceStore
            .getById(invoiceId)
            .then(response => {
                const { currentProfile } = this.props.profileStore;
                const { invoice } = this.props.invoiceStore;
                let isInvoice = true; // by default the type is invoice for outgoing invoice. incoming invoice will be a bill

                if (currentProfile && invoice) {
                    if (invoice.payeeId === currentProfile.id) {
                        isInvoice = true;
                    } else {
                        isInvoice = false;
                    }
                }

                if (isInvoice) {
                    this.props.quickBooksStore.getCustomers(invoiceId);
                    this.props.quickBooksStore.getServices();
                    this.props.quickBooksStore.getQuickbooksInvoice(invoiceId);
                    this.props.quickBooksStore.getTaxCodes(invoiceId);
                } else {
                    this.props.quickBooksStore.getVendors(invoiceId);
                    this.props.quickBooksStore.getExpenses();
                    this.props.quickBooksStore.getQuickbooksInvoice(invoiceId);
                }

                this.setState(
                    {
                        ...this.state,
                        invoice: response,
                        isLoading: false,
                        isInvoice: isInvoice
                    },
                    () => {
                        // this.props.templateStore.hideLoadingScreen();
                    }
                );
            })
            .catch(error => {
                if (error.status === 404) {
                    // this.props.templateStore.hideLoadingScreen();
                    this.setState({ notFound: true, isLoading: false });
                }
            })
            .finally(() => {
                // this.setState({ isLoading: false });
            });
    };

    handleSubmit = e => {
        if (this.state.isInvoice) {
            this.props.quickBooksStore.submitInvoice();
        } else {
            this.props.quickBooksStore.submitBill();
        }
    };

    render() {
        const { invoiceId } = this.props.match.params;
        const { currentProfile } = this.props.profileStore;
        const { invoice, error } = this.props.invoiceStore;
        const { quickBooksInvoice } = this.props.quickBooksStore;
        const breadcrumb = [
            { name: "Dashboard", link: "/" + this.props.templateStore.getTypeString(currentProfile.type) },
            { name: "Invoices", link: "/invoice" },
            { name: "QuickBooks Detail" }
        ];

        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="Invoice Detail" />
                    <Paper className="org-paper-form" style={{ borderRadius: "0px 0px 2px 2px", minHeight: 500 }}>
                        {this.state.isInvoice && <QuickBooksCustomer invoiceId={invoiceId} />}
                        {!this.state.isInvoice && <QuickBooksVendor invoiceId={invoiceId} />}
                        <Grid container spacing={16} style={{ position: "relative" }}>
                            <Grid item xs={12} lg={3}>
                                <div ref="eventDate">
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Line Items
                                    </Typography>

                                    <Typography gutterBottom className="side-helper-text">
                                        This is the details of the invoice line item to be synced to QuickBooks Online.
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                {invoice && (
                                    <Fragment>
                                        <hr />
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Product/Service</TableCell>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell align="right">Time</TableCell>
                                                    <TableCell align="right">Description</TableCell>
                                                    <TableCell align="right">Fee</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {invoice.invoiceDetails.map(row => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">
                                                            General services
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {moment(row.date).format("Do MMM YYYY")}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {moment(row.timeStart, "HH:mm:ss").format(
                                                                this.props.templateStore.getFormat12Hour(row.timeStart)
                                                            )}
                                                            &nbsp;-&nbsp;
                                                            {moment(row.timeEnd, "HH:mm:ss").format(
                                                                this.props.templateStore.getFormat12Hour(row.timeEnd)
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">{row.description}</TableCell>
                                                        <TableCell align="right">{row.fee}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Fragment>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Hidden mdDown>
                                <Grid item lg={3} />
                            </Hidden>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={8} className="text-right">
                                    {quickBooksInvoice &&
                                        quickBooksInvoice.updated && (
                                            <Grid item xs={12}>
                                                Last Sync: {moment(quickBooksInvoice.updated).format("DD-MM-YYYY h:mma")}{" "}
                                                {quickBooksInvoice.quickBooksInvoiceId}
                                            </Grid>
                                        )}
                                    <Grid item xs={12} className="stepperContent">
                                        <Hidden smDown>
                                            <div className="action pull-right">
                                                <Button variant="outlined" onClick={this.props.history.goBack} style={{ marginRight: 10 }}>
                                                    Back
                                                </Button>

                                                <Button
                                                    id="btn-process"
                                                    variant="contained"
                                                    disabled={error}
                                                    color="primary"
                                                    onClick={this.handleSubmit}
                                                >
                                                    Sync to QuickBooks Online
                                                </Button>
                                            </div>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <Fragment>
                                                <Button
                                                    id="btn-process"
                                                    variant="contained"
                                                    fullWidth={true}
                                                    style={{ marginBottom: "12px" }}
                                                    disabled={error}
                                                    color="primary"
                                                    onClick={this.handleSubmit}
                                                >
                                                    Sync to QuickBooks Online
                                                </Button>

                                                <Button variant="outlined" onClick={this.props.history.goBack} fullWidth={true}>
                                                    Back
                                                </Button>
                                            </Fragment>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </MainContainer>
            </Fragment>
        );
    }
}

export default InvoiceQuickBooks;
