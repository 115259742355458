import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import { Card, Button, Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import "./Billing.css"; 
import BillingInformation from '../../components/billing/BillingInformation';
import agent from "../../agent";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import ConfirmationStripeModal from "../../components/modal/ConfirmationStripeModal";
import Heading from "../../components/Heading";
import Loading from "../../components/loadingSpinner/Loading";
import moment from "moment";
const styles = theme => ({
    card: {
      maxWidth: 400,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    actions: {
      display: 'flex',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: -8,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    button: {
        margin: theme.spacing.unit,
      },
  });

@inject("userStore", "billingStore", "profileStore", "authStore")
@observer
class Billing extends Component{
    constructor(props){
        super(props);
        this.updateMenu();
    };
    state = {
        openCreditCardDialog: false,
        openConfirmationDialog: false,
        openConfirmationCancelSubscriptionDialog: false,
        planId: "",
        customer: this.props.billingStore.customer,
        card: null,
        subscription: null,
        invoices: null,
        subscriptionApp: 0,
        profileType: 0,
        currentPlanId: null,
        nextPlanId: null,
        isLoading: true,
        status: 'cancel',
        billingInfoStartDate: null,
        billingInfoStatus: null,
        billingInfoCycle: null,
        billingInfoSubsctiptionPlan: null,
        billingInfoCreditCardInformation: null,
        billingInfoIsRegistered: null,
    };

    async handleLoadBillingInformation(customer){
        const subscription = customer.subscriptions.data[0];
        let billingInfo = await this.props.billingStore.getBillingInformation(customer.id);
        if (billingInfo != null){
            let status = "";
            if (subscription != null){
                let date = moment.utc(moment.unix(subscription.current_period_end)).local().diff(moment(), "days") + 1;
                if (subscription.cancel_at_period_end){
                    status = "Cancels in " + date + " days";
                } else {
                    status = billingInfo.billingStatus;
                }
            } else {
                status = billingInfo.billingStatus;
            }
            
            this.setState({
                billingInfoStartDate: billingInfo.billingStartDate != null ? moment(billingInfo.billingStartDate).local().format("DD MMM YYYY") : null,
                billingInfoStatus: status,
                billingInfoCycle: billingInfo.billingCycle,
                billingInfoSubsctiptionPlan: billingInfo.subsctiptionPlan,
                billingInfoCreditCardInformation: billingInfo.creditCardInformation,
                billingInfoIsRegistered: billingInfo.creditCardInformation != null ? true : false,
            });
        } else {
            this.setState({
                billingInfoIsRegistered: false,
            })
        }
    }

    componentDidMount(){
        this.setState({
            customer: this.props.billingStore.customer,
        });
    }

    handleCloseConfirmationDialog = () => {
        this.setState({
            openConfirmationDialog: false,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            openCreditCardDialog: false
        })
    }

    handleSubscribeDialog = () => {
        this.setState({
            isLoading: true,
        });
        this.updateMenu();
    }

    handleOpenDialog = (planId) => {
        if (this.state.card != null){
            this.setState({
                openConfirmationDialog: true,
                nextPlanId: planId,
            });
        }else{
            this.setState({
                openCreditCardDialog: true,
                planId: planId
            });
        }
    };

    handleRefreshCard = () => {
        this.setState({
            isLoading: true,
        });
        this.updateMenu();
    };

    async subscribe(planId){
        this.setState({
            isLoading: true,
        });
        await agent.Billing.createSubscription(this.state.customer.id, planId);
        await this.props.authStore.refreshTokenManually();
        this.updateMenu();
    }

    handleOpenUpdateSubscriptionStatus = () => {
        this.setState({
            openConfirmationCancelSubscriptionDialog: true,
        });
    }

    handleCloseConfirmationUpdateSubscriptionDialog = () => {
        this.setState({
            openConfirmationCancelSubscriptionDialog: false,
        });
    }

    async handleCancelSubscription(isCancel){
        this.setState({
            isLoading: true,
        });
        let data = {
            isCancel: isCancel
        };
        await agent.Billing.updateSubscriptionInEndPeriodByCustomerId(this.state.customer.id, data);
        this.updateMenu();
    }

    async updateMenu() {
        let status = null;
        let card = null;
        let subscription = null;
        let subscriptionApp = null;
        let invoices = null;
        let customer = await this.props.billingStore.getCustomerByUserId(this.props.userStore.currentUser.id);
        if (customer.response === null){
            customer = await agent.Billing.createCustomer({
                userId: this.props.userStore.currentUser.id,
                email: this.props.userStore.currentUser.email,
            });
        }
        if (customer.response !== null){
            invoices = await this.props.billingStore.getInvoicesByCustomerId(customer.id);
        }
        if (customer.response.sources.data.length > 0){
            card = customer.sources.data[0];
        }else{
            card = await this.props.billingStore.getCardByCustomerId(customer.id);
        }
        if (customer.response.subscriptions.data.length > 0){
            subscription = customer.subscriptions.data[0];
        }else{
            subscription = await this.props.billingStore.getSubscriptionByCustomerId(customer.id);
        }
        if (subscription != null){
            status = subscription.cancel_at_period_end ? 'activate' : 'cancel';
            let planId = subscription.items.data[0].plan.id;
            subscriptionApp = planId === planMusicianMonthly || planId === planMusicianYearly ? 1 : 
                planId === planAgentMonthly || planId === planAgentYearly ? 2 : 
                planId === planVenueMonthly || planId === planVenueYearly ? 3 : 0;
        }
        this.handleLoadBillingInformation(customer);
        this.setState({
            openCreditCardDialog: false,
            openConfirmationDialog: false,
            openConfirmationCancelSubscriptionDialog: false,
            customer: customer,
            card: card,
            invoices: invoices,
            currentPlanId: subscription != null ? subscription.items.data[0].plan.id : null,
            subscriptionApp: subscriptionApp,
            subscription: subscription,
            isLoading: false,
            status: status,
        })
    }

    render(){
        const { classes } = this.props;
        const { subscription, invoices } = this.state;
        const isMusicianMonthlySubscription = this.state.currentPlanId === planMusicianMonthly;
        const isAgentMonthlySubscription = this.state.currentPlanId === planAgentMonthly;
        const isVenueMonthlySubscription = this.state.currentPlanId === planVenueMonthly;
        const isMusicianYearlySubscription = this.state.currentPlanId === planMusicianYearly;
        const isAgentYearlySubscription = this.state.currentPlanId === planAgentYearly;
        const isVenueYearlySubscription = this.state.currentPlanId === planVenueYearly;
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Heading title="Payment" />
                    <Loading showed={this.state.isLoading} />
                    { !this.state.isLoading ? (
                    <Grid container>
                        <Grid item xs={8}>
                            { this.props.profileStore.currentProfile && this.props.profileStore.currentProfile.type === 1 ? (
                                <Grid style={{ paddingRight: 20 }}>
                                    <Card>
                                        <Grid container spacing={16}>
                                            <Grid item xs key={1}>
                                                <div className="col-sm-12 pricing-wrapper" >
                                                    <img src="https://www.hotgiggity.com/assets/images/guitar-icon.png" alt="guitar icon" className="pricing-icon" />
                                                    <p className="pricing-title">MUSICIANS /<br />ENTERTAINERS</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs key={2}>
                                                <h3>HUBDIT LITE</h3>
                                                    Features:
                                                    <br/>
                                                    <ul>
                                                        <li>restricted profile page</li>
                                                        <li>calendar and booking management</li>
                                                        <li>act management</li>
                                                        <li>agent roster management</li>
                                                        <li>instant messaging</li>
                                                    </ul>
                                                    Pricing:
                                                    <br/> Free forever
                                            </Grid>
                                            <Grid item xs key={3}>
                                                <h3>HUBDIT CALIFORNIA</h3>
                                                <div>
                                                    {/* Features:
                                                    <br/>
                                                    <ul>
                                                        <li>full profile page</li>
                                                        <li>calendar and booking management</li>
                                                        <li>act management</li>
                                                        <li>agent roster management</li>
                                                        <li>instant messaging</li>
                                                        <li>automated invoicing</li>
                                                        <li>automated marketing</li>
                                                        <li>full financial reporting</li>                                            
                                                    </ul> */}
                                                    Pricing:
                                                    <br/> $19.50 per month (less than a schooner)<br/>
                                                    <Button disabled={isMusicianMonthlySubscription ? true : false} 
                                                        variant="contained" 
                                                        color={isMusicianMonthlySubscription ? "default" : "primary"} 
                                                        onClick={() => this.handleOpenDialog(planMusicianMonthly)} 
                                                        className={classes.button}>
                                                        {isMusicianMonthlySubscription ? "Subscribed" : "Subscribe"} 
                                                    </Button>
                                                    <br/> $210.60 per year (Save 10%) <br/>
                                                    <Button disabled={isMusicianYearlySubscription ? true : false} 
                                                        variant="contained" 
                                                        color={isMusicianYearlySubscription ? "default" : "primary"} 
                                                        onClick={() => this.handleOpenDialog(planMusicianYearly)} 
                                                        className={classes.button}>
                                                        {isMusicianYearlySubscription ? "Subscribed" : "Subscribe"} 
                                                    </Button>
                                                    <br/>
                                                    <br/>
                                                    { isMusicianMonthlySubscription || isMusicianYearlySubscription ? (
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary"
                                                            onClick={this.handleOpenUpdateSubscriptionStatus}
                                                            className={classes.button}
                                                            style={{marginBottom: 20}}>
                                                            {subscription.cancel_at_period_end ? "Activate Subscription" : "Cancel Subscription"} 
                                                        </Button>
                                                    ) : null}
                                                    { invoices === null ? (
                                                        <Button className={classes.button} variant="contained" color="primary" onClick={() => this.subscribe(planMusicianMonthly)} style={{marginBottom: 20}}>
                                                            {"Start Trial"} 
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ) : null}
                            { this.props.profileStore.currentProfile && this.props.profileStore.currentProfile.type === 2 ? (
                                <Grid style={{ paddingRight: 20 }}>
                                    <Card>
                                        <Grid container spacing={16}>
                                            <Grid item xs key={1}>
                                                <div className="col-sm-12 pricing-wrapper">
                                                    <img src="https://www.hotgiggity.com/assets/images/money-icon.png" alt="money icon" className="pricing-icon" />
                                                    <p className="pricing-title">AGENTS<br />MANAGERS</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs key={2}>
                                                <h3>HUBDIT VEYRON</h3>
                                                <div>
                                                    Features:
                                                    <br/>
                                                    <ul>
                                                        <li>full profile page</li>
                                                        <li>calendar and booking management</li>
                                                        <li>act roster management </li>
                                                        <li>venue roster management</li>
                                                        <li>instant messaging</li>
                                                        <li>automated invoicing</li>
                                                        <li>automated gig marketing</li>
                                                        <li>full financial reporting</li>
                                                        <li>consultant / staff management</li>
                                                    </ul>
                                                    Pricing:                                        
                                                    <br/> $78 per month <br/>    
                                                    <Button disabled={isAgentMonthlySubscription ? true : false}
                                                        variant="contained" 
                                                        color={isAgentMonthlySubscription ? "default" : "primary"} 
                                                        onClick={() => this.handleOpenDialog(planAgentMonthly)} 
                                                        className={classes.button}>
                                                        {isAgentMonthlySubscription ? "Subscribed" : "Subscribe"}
                                                    </Button>  
                                                    <br/> $842.40 per year (Save 10%)<br/>
                                                    <Button disabled={isAgentYearlySubscription ? true : false}
                                                        variant="contained" 
                                                        color={isAgentYearlySubscription ? "default" : "primary"} 
                                                        onClick={() => this.handleOpenDialog(planAgentYearly)} 
                                                        className={classes.button}>
                                                        {isAgentYearlySubscription ? "Subscribed" : "Subscribe"}
                                                    </Button>  
                                                    <br/>
                                                    <br/>
                                                    { isAgentMonthlySubscription || isAgentYearlySubscription ? (
                                                        <Button className={classes.button} variant="contained" color="primary" onClick={this.handleOpenUpdateSubscriptionStatus} style={{marginBottom: 20}}>
                                                            {subscription.cancel_at_period_end ? "Activate Subscription" : "Cancel Subscription"} 
                                                        </Button>
                                                    ) : null}
                                                    { invoices === null ? (
                                                        <Button className={classes.button} variant="contained" color="primary" onClick={() => this.subscribe(planAgentMonthly)} style={{marginBottom: 20}}>
                                                            {"Start Trial"} 
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ) : null}
                            { this.props.profileStore.currentProfile && this.props.profileStore.currentProfile.type === 3 ? (
                                <Grid style={{ paddingRight: 20 }}>
                                    <Card>
                                        <Grid container spacing={16}>
                                            <Grid item xs key={1}>
                                                <div className="col-sm-12 pricing-wrapper">
                                                    <img src="https://www.hotgiggity.com/assets/images/note-icon.png" alt="note icon" className="pricing-icon" />
                                                    <p className="pricing-title">VENUES</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs key={2}>
                                                <h3>HUBDIT CARRERA</h3>
                                                <div>
                                                    Features:
                                                    <br/>
                                                    <ul>
                                                        <li>Hierarchical organisation management including staff management</li>
                                                        <li>full profile page</li>
                                                        <li>calendar and booking management </li>
                                                        <li>act roster management </li>
                                                        <li>agent roster management</li>
                                                        <li>automated invoicing</li>
                                                        <li>automated marketing</li>
                                                        <li>full financial reporting</li>
                                                        <li>instant messaging</li>
                                                    </ul>
                                                    Pricing:
                                                    <br/> $78 per month<br/>
                                                    <Button disabled={isVenueMonthlySubscription ? true : false}
                                                        variant="contained" 
                                                        color={isVenueMonthlySubscription ? "default" : "primary"} 
                                                        onClick={() => this.handleOpenDialog(planVenueMonthly)} 
                                                        className={classes.button}>
                                                        {isVenueMonthlySubscription ? "Subscribed" : "Subscribe"}
                                                    </Button>
                                                    <br/> $842.40 per year (Save 10%)<br/>
                                                    <Button disabled={isVenueYearlySubscription ? true : false}
                                                        variant="contained" 
                                                        color={isVenueYearlySubscription ? "default" : "primary"} 
                                                        onClick={() => this.handleOpenDialog(planVenueYearly)} 
                                                        className={classes.button}>
                                                        {isVenueYearlySubscription ? "Subscribed" : "Subscribe"}
                                                    </Button>
                                                    <br/>
                                                    <br/>
                                                    { isVenueMonthlySubscription || isVenueYearlySubscription ? (
                                                        <Button className={classes.button} variant="contained" color="primary" onClick={this.handleOpenUpdateSubscriptionStatus} style={{marginBottom: 20}}>
                                                            {subscription.cancel_at_period_end ? "Activate Subscription" : "Cancel Subscription"} 
                                                        </Button>
                                                    ) : null}
                                                    { invoices === null  ? (
                                                        <Button className={classes.button} variant="contained" color="primary" onClick={() => this.subscribe(planVenueMonthly)} style={{marginBottom: 20}}>
                                                            {"Start Trial"} 
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid item xs={4}>
                            <BillingInformation
                                subscription = {this.state.subscription}
                                card = {this.state.card}
                                customer = {this.state.customer}
                                updateCard = {this.handleRefreshCard}
                                billingInfoStartDate= {this.state.billingInfoStartDate}
                                billingInfoStatus= {this.state.billingInfoStatus}
                                billingInfoCycle= {this.state.billingInfoCycle}
                                billingInfoSubsctiptionPlan= {this.state.billingInfoSubsctiptionPlan}
                                billingInfoCreditCardInformation= {this.state.billingInfoCreditCardInformation}
                                billingInfoIsRegistered= {this.state.billingInfoIsRegistered}
                            />
                        </Grid>
                    </Grid>
                    ) : null}
                    
                    <ConfirmationStripeModal 
                        customer={this.state.customer}
                        open={this.state.openCreditCardDialog}
                        onSuccess={this.handleSubscribeDialog}
                        onClose={this.handleCloseDialog}
                        fullWidth={true}
                        planId={this.state.planId}
                        isSubscribe={true}
                        title={"Add Credit Card"}
                        confirmationHandler={this.handleSubscribeDialog}
                        declineHandler={this.handleCloseDialog}
                        confirmationLabel={"Subscribe"}
                     />
                    <ConfirmationModal
                        open={this.state.openConfirmationCancelSubscriptionDialog}
                        title=""
                        message={'Are you sure to ' + this.state.status + ' subscription? '}
                        closeHandler={this.handleCloseConfirmationUpdateSubscriptionDialog}
                        confirmationHandler={() => this.handleCancelSubscription(!this.state.subscription.cancel_at_period_end)}
                        declineHandler={this.handleCloseConfirmationUpdateSubscriptionDialog}
                        confirmationLabel="Ok"
                    />
                    <ConfirmationModal
                        open={this.state.openConfirmationDialog}
                        title="Subscription"
                        message={`Are you sure to subscribe? `}
                        closeHandler={this.handleCloseConfirmationDialog}
                        confirmationHandler={() => this.subscribe(this.state.nextPlanId)}
                        declineHandler={this.handleCloseConfirmationDialog}
                        confirmationLabel="Subscribe"
                    />
                </MainContainer>
            </Fragment>
        );
    }
}

export default withStyles(styles) (Billing);
