import React, { Component } from "react";

import { Toolbar, Grid, Button, IconButton, Typography, Select, MenuItem } from "@material-ui/core";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import "./custom-toolbar.css";

class CustomCalendarToolbar extends Component {
    render() {
        const { toolbar, goNextListener, goBackListener, goCurrentListener, viewChangeListener } = this.props;

        const goToBack = () => {
            if (goBackListener) {
                goBackListener();
            } else {
                toolbar.onNavigate("PREV");
            }
        };
        const goToNext = () => {
            if (goNextListener) {
                goNextListener();
            } else {
                toolbar.onNavigate("NEXT");
            }
        };
        const goToCurrent = () => {
            if (goCurrentListener) {
                goCurrentListener();
            } else {
                toolbar.onNavigate("TODAY");
            }
        };

        let handleToolbarViewChange = event => {
            if (viewChangeListener) {
                viewChangeListener(event.target.value);
            } else {
                toolbar.onView(event.target.value);
            }
        };

        return (
            <Toolbar className="calendar-custom-toolbar">
                <Grid container alignItems="center" alignContent="center" justify="space-between">
                    <Grid item xs={12} md={2} className="custom-toolbar-today-button-container">
                        <Button className="custom-toolbar-today-button" onClick={goToCurrent}>
                            Today
                        </Button>
                    </Grid>
                    <Grid item xs={4} md={2} className="custom-toolbar-today-button-container">
                        <IconButton onClick={goToBack}>
                            {" "}
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Typography align="center" variant="subheading">
                            <strong>{toolbar.label}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} className="custom-toolbar-today-button-container">
                        <IconButton onClick={goToNext}>
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Select className="pull-right yellow-select" fullWidth value={toolbar.view} onChange={handleToolbarViewChange}>
                            <MenuItem value="month">Month</MenuItem>
                            <MenuItem value="week">Week</MenuItem>
                            <MenuItem value="day">Day</MenuItem>
                            <MenuItem value="agenda">Agenda</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </Toolbar>
        );
    }
}

export default CustomCalendarToolbar;
