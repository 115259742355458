import React, { Component } from 'react'
import { inject, observer } from "mobx-react";
import ModalContainer from '../../modal/ModalContainer';
import { DialogContent, DialogActions, Grid, TextField, Button, InputLabel, InputAdornment } from '@material-ui/core';
import SelectField from '../../fields/SelectField';
import RichTextEditor from '../../richTextEditor/RichTextEditor';

import ChipInput from 'material-ui-chip-input';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    "@global": {
        "div[class*='WAMuiChipInput-underline-']:before": {
            backgroundColor: 'transparent'
        }, 
        "div[class*='WAMuiChipInput-inkbar-']:after": {
            backgroundColor: 'transparent'
        }, 
        "div[class*='WAMuiChipInput-underline-']:hover:not([class*='WAMuiChipInput-disabled-']):before": {
            height: 0, 
            backgroundColor: 'transparent'
        }, 
        "div[class*='WAMuiChipInput-inputRoot-']": {
            margin: '0 8px 8px 0'
        }, 
        "div[class=*'WAMuiChipInput-chip-']": {
            margin: '8px 8px 8px 0'
        }
    }
});

@inject("venueMenuStore")
@observer
class NewFoodModal extends Component {
    
    onFoodNameChange = e => {
        this.props.venueMenuStore.updateFoodAttribute('name', e.target.value);
    }

    onFoodPriceChange = e => {
        this.props.venueMenuStore.updateFoodAttribute('price', e.target.value);
    }

    onFoodSectionChange = value => {
        this.props.venueMenuStore.updateFoodAttribute('section', value);
    }

    onFoodDescriptionChange = value => {
        this.props.venueMenuStore.updateFoodAttribute('description', value);
    }

    onFoodTagsChange = value => {
        this.props.venueMenuStore.updateFoodAttribute('tags', value);
    }

    mapFoodSectionSuggestions = () => {
        const { foodSections } = this.props.venueMenuStore;
        return foodSections;
    }

    handleAddNewFood = () => {
        const { food } = this.props.venueMenuStore;
        
        this.props.venueMenuStore.addToFoodList(food);

        const { menuErrors } = this.props.venueMenuStore;

        if(typeof menuErrors === 'undefined') {
            this.props.venueMenuStore.resetFoodAttribute();

            if(this.props.closeHandler){
                this.props.closeHandler();
            }
        }
    }

    render(){
        const {
            open,
            closeHandler, 
            currency
        } = this.props;

        const { food, menuErrors } = this.props.venueMenuStore;
        
        return (
            <ModalContainer open = { open } title = "Add New Food Menu Item">
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <TextField
                                id="foodName"
                                label="Food Name"
                                margin="dense"
                                fullWidth
                                autoFocus
                                value={ food.name }
                                onChange={this.onFoodNameChange}
                                error={typeof menuErrors !== 'undefined' && typeof menuErrors.foodName !== 'undefined'}
                                helperText={menuErrors && menuErrors.foodName ? menuErrors.foodName : ""}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel>Food Section</InputLabel>
                            <SelectField 
                                suggestions={this.mapFoodSectionSuggestions()}
                                onChange={this.onFoodSectionChange}
                                value={ food.section }
                                placeholder="Select Food Section"
                                error={typeof menuErrors !== 'undefined' && typeof menuErrors.foodSection !== 'undefined'}
                                helperText={menuErrors && menuErrors.foodSection ? menuErrors.foodSection : ""}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                id="foodPrice"
                                label="Price"
                                margin="dense"
                                fullWidth
                                InputProps = {
                                    {
                                        type: "number", 
                                        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
                                    }
                                }
                                value={ food.price }
                                onChange={this.onFoodPriceChange}
                                error={typeof menuErrors !== 'undefined' && typeof menuErrors.foodPrice !== 'undefined'}
                                helperText={menuErrors && menuErrors.foodPrice ? menuErrors.foodPrice : ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Food Style</InputLabel>
                            <br />
                            <ChipInput id="foodTags" fullWidth={true} onChange={(chips) => this.onFoodTagsChange(chips)} defaultValue={food.tags} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Description</InputLabel>
                            <RichTextEditor 
                                id="foodDescription"
                                value={ food.description }
                                onChange={ this.onFoodDescriptionChange }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick = { closeHandler }>
                        Close
                    </Button>
                    <Button
                        color="primary"
                        onClick = { this.handleAddNewFood }
                    >
                        { this.props.editFood ? 'Save' : 'Add' }
                    </Button>
                </DialogActions>
            </ModalContainer>
        )
    }
}

export default withStyles(styles)(NewFoodModal);