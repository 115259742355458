import React, { Component } from "react";

import { inject, observer } from "mobx-react";

import ModalContainer from "../../modal/ModalContainer";

import { Avatar, Button, Typography, IconButton } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import CircularProgress from "@material-ui/core/CircularProgress";

import EventIcon from "@material-ui/icons/Event";
import ClearIcon from "@material-ui/icons/Clear";
import blankProfile from "../../../img/hotgig-loading.png"
import image from "../../../helper/image";

@inject("venueActStore", "profileStore", "templateStore")
@observer
class VenueActModal extends Component {
    state = {
        venue: undefined,
        loading: false
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.venue) {
            this.setState(
                {
                    ...this.state,
                    loading: true
                },
                () => {
                    this.props.venueActStore.getVenueActRoster(nextProps.venue.id).then(() => {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    });
                }
            );
        }
    }

    handleDeleteAct = (id, act, agent) => {
        this.props.venueActStore.deleteVenueAct(id).then(response => {
            this.props.templateStore.openSnackbar(act + " has been deleted from " + agent  + "'s favourite act.");
        });
    };

    renderAct = () => {
        const { profile } = this.props.profileStore;

        if (this.props.venueActStore.venueActRoster.length === 0 && this.state.loading) {
            return (
                <Typography align="center">
                    <CircularProgress size={50} />
                </Typography>
            );
        }

        const { venueActRoster } = this.props.venueActStore;
        return venueActRoster.map(data => (
            <ListItem>
                <Avatar
                    alt={data.act.profileName}
                    src={data.act ? image.getProfileImage(data.act) : blankProfile}
                    style={{
                        width: 40,
                        height: 40
                    }}
                />
                <ListItemText primary={data.act.fullName} secondary={data.act.myMusician.fullName} />
                <ListItemSecondaryAction>
                    {/* <IconButton>
                        <EventIcon />
                    </IconButton> */}
                    {this.props.manage && data.createdById === profile.id ? (
                        <IconButton onClick={() => this.handleDeleteAct(data.id, data.act.fullName, data.act.myMusician.fullName)}>
                            <ClearIcon />
                        </IconButton>
                    ) : (
                        ""
                    )}
                </ListItemSecondaryAction>
            </ListItem>
        ));
    };

    render() {
        return (
            <ModalContainer
                title={(this.props.venue ? this.props.venue.fullName : "Venue") + "'s favourite acts"}
                open={this.props.open}
                onClose={this.props.onClose}
                maxWidth="sm"
            >
                <DialogContent>
                    <List>{this.renderAct()}</List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        Close
                    </Button>
                    {this.props.manage ? (
                        <Button color="primary" onClick={this.props.openAddActModal}>
                            Add Act
                        </Button>
                    ) : (
                        ""
                    )}
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default VenueActModal;
