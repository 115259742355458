import React, { Component } from "react";
import { Grid, Typography, TextField, Button, Paper, MenuItem } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import StateTable from "./StateTable";
import UserTable from "./UserTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import { ProfileType, OrganisationType } from "../../types/enum";
import { Prompt } from "react-router-dom";
import agent from "../../agent";

import Loading from "../../components/loadingSpinner/Loading";

@inject("organisationStore", "organisationMemberStore", "stateStore", "profileStore", "templateStore", "billingStore", "userStore")
@observer
class OrganisationEdit extends Component {
    state = {
        isLoading: false
    };

    constructor(props) {
        super(props);
        this.props.organisationMemberStore.reset();
        this.props.stateStore.reset();
        this.props.organisationStore.resetSelection();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.templateStore.openOrganisationBlock();
        if (this.props.match.params.id) {
            this.setState(
                {
                    ...this.state,
                    isLoading: true
                },
                () => {
                    this.props.organisationStore.load(this.props.match.params.id).then(org => {
                        this.props.organisationMemberStore.setMembers(org.members);
                        this.props.organisationMemberStore.setOrganisationId(org.id);
                        this.props.organisationMemberStore.setLevel(1);
                        this.props.stateStore.setStates(org.state);
                        
                        this.setState({
                            ...this.state,
                            isLoading: false
                        });
                    });
                }
            );
        } else {
            this.props.organisationStore.setupNew();
        }
    }

    handleNameChange = e => {
        this.props.organisationStore.setName(e.target.value);
        this.props.templateStore.closeOrganisationBlock();
    };
    handleAddressChange = e => {
        this.props.organisationStore.setAddress(e.target.value);
        this.props.templateStore.closeOrganisationBlock();
    };
    handleContactNumberChange = e => {
        this.props.organisationStore.setContactNumber(e.target.value.replace(/\D/,''));
        this.props.templateStore.closeOrganisationBlock();
    };
    handleLevel1LabelChange = e => {
        this.props.organisationStore.setLevel1Label(e.target.value);
        this.props.templateStore.closeOrganisationBlock();
    };
    handleLevel2LabelChange = e => {
        this.props.organisationStore.setLevel2Label(e.target.value);
        this.props.templateStore.closeOrganisationBlock();
    };
    handleLevel3LabelChange = e => {
        this.props.organisationStore.setLevel3Label(e.target.value);
        this.props.templateStore.closeOrganisationBlock();
    };

    handleSubmitForm = async(e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            isLoading: true
        });
        const { level1Label } = this.props.organisationStore;
        const { currentUser } = this.props.userStore;
        const { currentProfile } = this.props.profileStore;

        if (!this.props.match.params.id) {
            switch (currentProfile.type) {
                case ProfileType.Staff.ordinal:
                    this.props.organisationStore.setType(OrganisationType.Venue.ordinal);
                    break;
                case ProfileType.Agent.ordinal:
                    this.props.organisationStore.setType(OrganisationType.Agency.ordinal);
                    break;
                default:
                    break;
            }
        }
        
        if (this.props.match.path === "/organisation/new") {
            if(currentProfile.type == ProfileType.Staff.ordinal){
                let candidateCustomer = { 
                    userId: currentUser.sub, 
                    email: currentUser.email, 
                    given_name: currentUser.given_name, 
                    family_name: currentUser.family_name, 
                    userType: 4
                };
                var customer = await agent.Billing.createCustomer(candidateCustomer);
                if(customer){
                    this.props.organisationStore.submit().then((org) => {
                        Promise.all([
                            this.props.profileStore.loadProfilesAfterLogin(),
                            this.props.userStore.pullUser()
                        ]).then(() => { 
                            this.setState({
                                ...this.state,
                                isLoading: false
                            });
                          
                            
                            this.props.templateStore.openSnackbar(`${level1Label} updated`);
                            this.props.templateStore.openOrganisationBlock();
                            if (!this.props.match.params.id) {
                                this.props.history.push(`/organisation`);
                            }
                        });
                    });
                }
                
               
            }
        }else{
            this.props.organisationStore.submit().then((org) => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
              
                
                this.props.templateStore.openSnackbar(`${level1Label} updated`);
                this.props.templateStore.openOrganisationBlock();
                if (!this.props.match.params.id) {
                    this.props.history.push(`/organisation`);
                }
            });
        }

        // submit
       
    };

    anyError(field) {
        if (!this.props.organisationStore.errors) return false;
        if (!this.props.organisationStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.organisationStore.errors) return null;
        if (!this.props.organisationStore.errors[field]) return null;
        return this.props.organisationStore.errors[field];
    }

    handleClick = () => {
        this.setState({ open: true });
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({ open: false });
    };

    handleCurrencyChange = e => {
        this.props.organisationStore.setCurrency(e.target.value);
    }

    render() {
        const { name, address, contactNumber, level1Label, level2Label, level3Label, id, currency } = this.props.organisationStore;
        const { currentProfile } = this.props.profileStore;
        const { organisationBlock } = this.props.templateStore;
        const breadcrumb = [
            { name: "Dashboard", link: "/staff" },
            { name: "Organisation", link: "/organisation" },
            { name: this.props.organisationStore.name }
        ];

        var canManage = this.props.organisationStore.canManage || id === undefined;
        return (
            <main>
                <Prompt
                    when={organisationBlock}
                    message={location => `You have unsaved Organisation data, do you still want to leave this section?`}
                />
                <Header userRole="agent" />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title={this.props.match.params.id ? "Edit Organisation" : "Create Organisation"} />

                    <div style={{ position: "relative" }}>
                        <Loading showed={this.state.isLoading} />
                        <Paper>
                            <form onSubmit={this.handleSubmitForm} className="org-form">
                                <Grid container spacing={16}>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="h5" component="h4" gutterBottom>
                                                {level1Label}
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                In this section you can manage all your venues in a hierarchical fashion. You will be able to group
                                                your venues into 3 different level of grouping, each with its own set of users and permissions.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    id="org-name"
                                                    label={`${level1Label} Name`}
                                                    fullWidth
                                                    value={name}
                                                    onChange={this.handleNameChange}
                                                    error={this.anyError("name")}
                                                    helperText={this.errorText("name")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <TextField
                                                    id="org-address"
                                                    label="Address"
                                                    fullWidth
                                                    value={address}
                                                    onChange={this.handleAddressChange}
                                                    error={this.anyError("address")}
                                                    helperText={this.errorText("address")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    id="org-contactNumber"
                                                    label="Contact Number"
                                                    fullWidth
                                                    value={contactNumber}
                                                    onChange={this.handleContactNumberChange}
                                                    error={this.anyError("contactNumber")}
                                                    helperText={this.errorText("contactNumber")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="currency"
                                                    label="Currency"
                                                    select
                                                    margin="dense"
                                                    fullWidth
                                                    value={currency ?? "AUD"}
                                                    onChange={this.handleCurrencyChange}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                >
                                                    <MenuItem value="AUD">AUD</MenuItem>
                                                    <MenuItem value="USD">USD</MenuItem>
                                                    <MenuItem value="GBP">GBP</MenuItem>
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="h5" component="h4" gutterBottom>
                                                Label
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                The default labels for your organisation hierarchy are Organisation, State, and Region. You are able
                                                to change these labels to what ever best suits your organisation.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    id="org-level1Label"
                                                    label="Level 1 Label"
                                                    fullWidth
                                                    value={level1Label}
                                                    onChange={this.handleLevel1LabelChange}
                                                    error={this.anyError("level1Label")}
                                                    helperText={this.errorText("level1Label")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    id="org-level2Label"
                                                    label="Level 2 Label"
                                                    fullWidth
                                                    value={level2Label}
                                                    onChange={this.handleLevel2LabelChange}
                                                    error={this.anyError("level2Label")}
                                                    helperText={this.errorText("level2Label")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    id="org-level3Label"
                                                    label="Level 3 Label"
                                                    fullWidth
                                                    value={level3Label}
                                                    onChange={this.handleLevel3LabelChange}
                                                    error={this.anyError("level3Label")}
                                                    helperText={this.errorText("level3Label")}
                                                    InputProps={{
                                                        readOnly: !canManage
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="h5" component="h4" gutterBottom>
                                                {level1Label} Staff Members
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Use this table to add staff members and assign permissions. Any users added to this level in the{" "}
                                                {level1Label} will have access to all venues in this group
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <UserTable level={1} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="h5" component="h4" gutterBottom>
                                                {level2Label} <small>(Level 2)</small>
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Use this table to add the {level2Label}s. Once you have added the {level2Label}, be sure to hit save.
                                                You will then be able to add your regions to your state by hitting the edit button within the state
                                                you just created. This will then open up your region box.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <StateTable />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {currentProfile && (
                                        <Grid item xs={12}>
                                            <div className="action">
                                                <Button component={Link} to="/organisation">
                                                    Back
                                                </Button>
                                                &nbsp;
                                                {canManage ? (
                                                    <Button variant="contained" color="primary" type="submit" id="organisation-submit">
                                                        Save
                                                    </Button>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        </Paper>
                    </div>
                </MainContainer>
            </main>
        );
    }
}
export default OrganisationEdit;
