import React, { Fragment } from "react";
import { ContentState, Editor, EditorState, Modifier, RichUtils, convertFromHTML as convertFromHtmlDraft } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import LooksThreeIcon from "@material-ui/icons/Looks3";
import LooksFourIcon from "@material-ui/icons/Looks4";
import LooksFiveIcon from "@material-ui/icons/Looks5";
import LooksSixIcon from "@material-ui/icons/Looks6";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatCodeIcon from "@material-ui/icons/Code";
import LinkIcon from "@material-ui/icons/Link";
import addLinkPlugin from "./addons/addLinkPlugins";
import { withStyles } from "@material-ui/core/styles";


import "./RichTextEditor.css";

class RichTextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editorState: EditorState.createEmpty() };
        this.plugins = [addLinkPlugin];

        this.focus = () => this.refs.editor.focus();
        this.onChange = (newEditorState) => {
            const currentContent = this.state.editorState.getCurrentContent()
            const newContent = newEditorState.getCurrentContent();

            this.setState({ editorState: newEditorState });
            if (currentContent !== newContent) {
                return this.props.onChange(convertToHTML(newEditorState.getCurrentContent()));
            }
        };

        this.handleKeyCommand = (command) => this._handleKeyCommand(command);
        this.onTab = (e) => this._onTab(e);
        this.toggleBlockType = (type) => this._toggleBlockType(type);
        this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
        this.fixStringUnicodeFormat = (string) => string.replace(/[\u0800-\uFFFF]/g, '');                    
    }


    insertLink = () => {
        const editorState = this.state.editorState;
        const selection = editorState.getSelection();
        const link = window.prompt("Paste the link -");
        if (!link) {
          this.onChange(RichUtils.toggleLink(editorState, selection, null));
          return "handled";
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
          url: link
        });
        const newEditorState = EditorState.push(
          editorState,
          contentWithEntity,
          "create-entity"
        );
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
        return "handled";
      };

    componentDidMount() {
        this.importHTML();
    }

    componentDidUpdate(prevProps) {    
        if (this.props.onChangeEditor && this.props.value !== prevProps.value && this.props.resetEditor ) {
            // used onChangeEditor if
            // needed to update value,
            // when component already mounted 
            // used the flag on resetEditor props
            this.props.onChangeEditor(false);
            this.importHTML()
          
        } else if(this.props.onChangeEditor && this.props.isVenueChange ){
            this.props.onChangeEditor(false);
            this.importHTML()
        }else if (this.props.value !== prevProps.value && this.props.resetEditor) {
            // if the flag already handled outside richTextEditor
            // just passed the state on resetEditor props
            this.importHTML()
        } else if (this.props.value === '<p></p>' && this.props.resetEditor) {
            if (this.props.value !== prevProps.value) {                                
                this.importHTML()
            }
        }
        if(this.props.onChangeEditor){
            this.props.onChangeEditor(false)
        }
        
    }

    _handleKeyCommand(command) {
        const { editorState } = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            return true;
        }
        return false;
    }

    exportHTML = () => {
        this.setState({ convertedContent: convertToHTML(this.state.editorState.getCurrentContent()) });
    }

    updateHTML = (e) => {
        e.preventDefault();
        this.setState({ convertedContent: e.target.value });
    }

    importHTML = () => {
        const { editorState } = this.state;
        let defaultHtml = '<p></p>'
        if (this.props.sample) {
            return this.onChange(EditorState.push(editorState, convertFromHTML(this.props.sample)));
        } else if (!this.props.value) {
            return this.onChange(EditorState.push(editorState, convertFromHTML(defaultHtml)));
        }
        this.onChange(EditorState.push(editorState, convertFromHTML(this.props.value)));
    }

    _onTab(e) {
        const maxDepth = 4;
        this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    }

    _toggleBlockType(blockType) {
        console.log(`blockType`, blockType)
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    handlePastedText = (text, html) => {
        let result = null
        if(html){
            result = convertFromHtmlDraft(this.fixStringUnicodeFormat(html))
        }else{
            result = convertFromHtmlDraft(this.fixStringUnicodeFormat(text))
        }
       
        const { editorState } = this.state;
        // const selection = editorState.getSelection();
        const pastedBlocks = ContentState.createFromBlockArray(result).blockMap;
        const newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            pastedBlocks,
        );
        const newEditorState = EditorState.push(editorState, newState, "insert-fragment");
        this.onChange(newEditorState);
        return "handled";
    };

    render() {
        const { editorState } = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="RichEditor-root">
                {!this.props.hideMenu &&
                    <Fragment>
                        <BlockStyleControls
                            editorState={editorState}
                            onToggle={this.toggleBlockType}
                            onClickLink={this.insertLink}
                        />
                        <InlineStyleControls
                            editorState={editorState}
                            onToggle={this.toggleInlineStyle}
                        />
                    </Fragment>
                }
                <div className={this.props.hideMenu ? "" : className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                        onTab={this.onTab}
                        placeholder={this.props.placeholder ? this.props.placeholder : ""}
                        ref="editor"
                        spellCheck={false}
                        plugins={this.plugins}
                        readOnly={this.props.readOnly}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        formatPastedText={this.handlePastedText}
                    />
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        backgroundColor: 'red',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    Link: {
        color: 'red'
    }
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        case 'LINK': return 'RichEditor-link'
        default: return null;
    }
}

function renderIcon(label) {
    switch (label) {
        case 'H1':
            return <LooksOneIcon />
        case 'H2':
            return <LooksTwoIcon />
        case 'H3':
            return <LooksThreeIcon />
        case 'H4':
            return <LooksFourIcon />
        case 'H5':
            return <LooksFiveIcon />
        case 'H6':
            return <LooksSixIcon />
        case 'Blockquote':
            return <FormatQuoteIcon />
        case 'UL':
            return <FormatListBulletedIcon />
        case 'OL':
            return <FormatListNumberedIcon />
        case 'Code Block':
            return <FormatCodeIcon />
        case 'Bold':
            return <FormatBoldIcon />
        case 'Italic':
            return <FormatItalicIcon />
        case 'Underline':
            return <FormatUnderlinedIcon />
        // case 'Link':
        //     return <LinkIcon />

        default:
            return label
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        const { label } = this.props;

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {renderIcon(label)}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' },
    // { label: 'Link', style: 'LINK' },
];

const BlockStyleControls = (props) => {
    const { editorState, onClickLink } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                    onClickLink={onClickLink}
                />
            )}
        </div>
    );
};

var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    // { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
    var currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

const styles = theme => ({
    "@global": {
        ".RichEditor-editor .public-DraftEditorPlaceholder-root": {
            fontStyle: "italic",
            opacity: 0.5,
            height: "0px !important",
            position: "absolute !important",
        }
    }
})

export default withStyles(styles)(RichTextEditor)