import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import moment from "moment";

import { Grid, Avatar } from "@material-ui/core";

import PageNotFound from "../profile/PageNotFound";
// import classNames from "classnames";
// import goldFrame from "../../img/gold-frame.png";
import curve from "../../img/entertainment-curve.svg";
import hotGiggityLive from "../../img/Hot Giggity Live.png";
import image from "../../helper/image";

import "./Entertainment.css";

// const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

@inject("profileStore", "bookingRequestStore", "templateStore", "reportStore")
@observer
class Entertainment extends Component {
    state = {
        upcomings: [],
        profile: null,
        finishLoading: false,
        gigIndex: 0
    };

    // load profile initially
    componentDidMount = () => {
        this.loadProfile();
    };

    // check if we get an update, usually by choosing the musician profile in the user popup
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadProfile();
        }
    }

    loadProfile = () => {
        const param = this.props.match.params.id;
        let searchById = this.props.location.pathname.startsWith("/profile/");

        if (searchById) {
            this.props.reportStore.setFilterType("week");
            Promise.all([
                this.props.profileStore.getPublicProfileById(param),
                this.props.bookingRequestStore.loadEntertainmentUpcomingGigsWithoutAdhoc(param)
            ]).then((response) => { 
               
                var upcomings = response[1]
                if (upcomings.length > 3) {
                    upcomings = upcomings.slice(0, 3)
                }
                for (let i = 0; i < upcomings.length; i++) {
                    // caches images, avoiding white flash between background replacements
                    if (upcomings[i].act) {
                        new Image().src = image.getBackgroundImage(upcomings[i].act, false);
                    }
                }
                this.changeBackground(0);
                this.props.templateStore.hideLoadingScreen();
                this.setState({
                    upcomings: upcomings,
                    profile: response[0],
                    finishLoading: true
                });
            });
          
        } else {
            if (vanityUrl !== "null") {
                this.props.profileStore.getPublicProfileByVanityUrl(param).then(result => {
                    this.setState({
                        profile: result,
                        finishLoading: true
                    });

                    if (result) {
                        this.props.reportStore.setFilterType("week");
                        this.props.bookingRequestStore.loadEntertainmentUpcomingGigsWithoutAdhoc(result.id).then(upcomings => {
                            this.props.templateStore.hideLoadingScreen();
                            if (upcomings.length > 3) {
                                upcomings = upcomings.slice(0, 3)
                            }
                            for (let i = 0; i < upcomings.length; i++) {
                                // caches images, avoiding white flash between background replacements
                                if (upcomings[i].act) {
                                    new Image().src = image.getBackgroundImage(upcomings[i].act, false);
                                }
                            }
                            this.changeBackground(0);
                            this.setState({ upcomings: upcomings });
                        });
                    }
                });
            }
        }
    };

    changeBackground = index => {
        let { upcomings } = this.state;
        this.setState({ gigIndex: index });

        this.timeoutValue = setTimeout(() => {
            let newIndex = this.state.gigIndex + 1 < upcomings.length ? this.state.gigIndex + 1 : 0;
            this.changeBackground(newIndex);
        }, 5000);
    };

    manualChangeBackground = index => () => {
        clearTimeout(this.timeoutValue);
        this.changeBackground(index);
    };

    render() {
        let { upcomings } = this.state;
        let backgroundImage = image.getBackgroundImage(upcomings[this.state.gigIndex]?.act, false)

        return (
            // version 2
            <React.Fragment>
                {this.state.profile ? (
                    <div className="entertainment-page">
                        <Grid container className="entertainment-container">
                            {upcomings &&
                            upcomings[this.state.gigIndex] &&
                            upcomings[this.state.gigIndex].act &&
                            upcomings[this.state.gigIndex].act ? (
                                <Grid
                                    item
                                    xs={6}
                                    className="entertainment-left-image active"
                                    style={{
                                        backgroundImage: "url(" + backgroundImage + ")"
                                    }}
                                >
                                    <img src={curve} alt="curve" className="entertainment-curve" />
                                </Grid>
                            ) : (
                                <Grid item xs={6} className="entertainment-left-image-hero">
                                    <img src={curve} alt="curve" className="entertainment-curve" />
                                </Grid>
                            )}

                            <Grid item xs={6} className="entertainment-right-content">
                                <Grid container>
                                    <Grid item xs={12} className="entertainment-big-title">
                                        LIVE
                                    </Grid>
                                    <Grid item xs={12} className="entertainment-slide-information-day">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].dateStart &&
                                            upcomings[this.state.gigIndex].dateStart.format("dddd MMM D")
                                            .toUpperCase()}
                                    </Grid>
                                    <Grid item xs={12} className="entertainment-slide-information-name">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].act &&
                                            upcomings[this.state.gigIndex].act.actName.toUpperCase()}
                                    </Grid>
                                    <Grid item xs={12} className="entertainment-slide-information-time">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].timeStart &&
                                            upcomings[this.state.gigIndex].timeEnd &&
                                            moment(upcomings[this.state.gigIndex].timeStart, "HH:mm:ss.SSS").format("h.mmA") +
                                                " - " +
                                                moment(upcomings[this.state.gigIndex].timeEnd, "HH:mm:ss.SSS").format("h.mmA")}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <div className="background-image-control-main-container">
                            <div className="background-image-control-container">
                                {upcomings
                                    ? upcomings.map((upcoming, i) => (
                                          <div
                                              index={i}
                                              key={i}
                                              className={classNames("background-image-control", this.state.gigIndex === i ? "active" : "inactive")}
                                              onClick={this.manualChangeBackground(i)}
                                          />
                                      ))
                                    : null}
                            </div>
                        </div> */}

                        <div className="entertainment-follow-us">
                            FOLLOW US ON <img src={hotGiggityLive} alt="hotgiggity live logo" />
                        </div>

                        {this.state.profile && (
                            // <Avatar
                            //     src={image.getProfileImage(this.state.profile, true)}
                            //     alt={this.state.profile.fullName}
                            //     className="entertainment-venue-profile-image"
                            // />
                            <img src={image.getProfileImage(this.state.profile, true)} alt="curve" className="entertainment-venue-profile-image" />
                        )}
                    </div>
                ) : this.state.finishLoading ? (
                    <PageNotFound />
                ) : null}
            </React.Fragment>

            // version 1
            // <React.Fragment>
            //     {
            //         this.state.profile
            //             ?
            //                 <div className="entertainment-page">
            //                     <Grid container className="entertainment-container">
            //                         <Grid item xs={12} className="entertainment-big-title">LIVE ENTERTAINMENT</Grid>

            //                         <Grid item xs={12} className="entertainment-slides-container">
            //                             <Grid container className="entertainment-slide">
            //                                 <Grid item xs={7} className="entertainment-slide-picture-container">
            //                                     {/* <img src={goldFrame} alt="frame" className="entertainment-slide-frame-image" /> */}
            //                                     {(upcomings && upcomings[this.state.gigIndex] && upcomings[this.state.gigIndex].act && upcomings[this.state.gigIndex].act.backgroundImages && upcomings[this.state.gigIndex].act.backgroundImages.length > 0 && upcomings[this.state.gigIndex].act.backgroundImages[0].id)
            //                                         ? <div
            //                                             className="entertainment-slide-profile-image-container"
            //                                             style={{ backgroundImage: "url('" + this.props.profileStore.getBackgroundImageUrl(upcomings[this.state.gigIndex].act.backgroundImages[0].id) + "')" }}
            //                                         />
            //                                         : <div
            //                                             className="entertainment-slide-profile-image-container-hero"
            //                                         />
            //                                     }
            //                                 </Grid>

            //                                 <Grid item xs={5} className="entertainment-slide-information-container">
            //                                     <Grid container spacing={16}>
            //                                         <Grid item xs={12} className="entertainment-slide-information-day">
            //                                             { upcomings && upcomings[this.state.gigIndex] && upcomings[this.state.gigIndex].dateStart && moment(upcomings[this.state.gigIndex].dateStart).format("dddd").toUpperCase() }
            //                                         </Grid>
            //                                         <Grid item xs={12} className="entertainment-slide-information-name">
            //                                             { upcomings && upcomings[this.state.gigIndex] && upcomings[this.state.gigIndex].act && upcomings[this.state.gigIndex].act.actName.toUpperCase() }
            //                                         </Grid>
            //                                         <Grid item xs={12} className="entertainment-slide-information-time">
            //                                             { upcomings && upcomings[this.state.gigIndex] && upcomings[this.state.gigIndex].timeStart && upcomings[this.state.gigIndex].timeEnd &&
            //                                                 (moment(upcomings[this.state.gigIndex].timeStart, "HH:mm:ss.SSS").format("h.mma") + " - " + moment(upcomings[this.state.gigIndex].timeEnd, "HH:mm:ss.SSS").format("h.mma"))
            //                                             }
            //                                         </Grid>
            //                                     </Grid>
            //                                 </Grid>
            //                             </Grid>
            //                         </Grid>
            //                     </Grid>

            //                     <div className="entertainment-follow-us">
            //                         FOLLOW US ON <img src={hotgiggityLive} alt="hotgiggity live logo" />
            //                     </div>

            //                     { this.state.profile && this.state.profile.profileImage && this.state.profile.profileImage.url &&
            //                         <Avatar src={this.state.profile.profileImage.url} alt={this.state.profile.fullName} className="entertainment-venue-profile-image" />
            //                     }
            //                 </div>
            //             : (this.state.finishLoading ? <PageNotFound /> : null)
            //     }
            // </React.Fragment>
        );
    }
}
export default Entertainment;
