import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import BookingRequests from "../../components/booking/BookingRequests";
import BookingAccepted from "../../components/booking/BookingAccepted";
import BookingCompleted from "../../components/booking/BookingCompleted";
import BookingCancelled from "../../components/booking/BookingCancelled";
import ReportDateFilterGigs from "../../components/reports/ReportDateFilterGigs";
import Loading from "../../components/loadingSpinner/Loading";

import { Tabs, Tab, Badge, Grid, Hidden, TextField, MenuItem } from "@material-ui/core";
import { BookingStatusType } from "../../types/enum";
import AgentFilter from "../../components/filters/AgentFilter";
import BookingDrafts from "../../components/booking/BookingDrafts";
import BookingAll from "../../components/booking/BookingAll";

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("templateStore", "profileStore", "bookingRequestStore", "reportStore")
@withRouter
@observer
class PrivateHireGig extends Component {
    state = {
        tabValue: 0,
        isLoading: false
    };

    constructor(props) {
        super(props);
        this.props.bookingRequestStore.resetRequestsUpcomingCompleted();
        this.props.reportStore.resetFilterData();
    }

    componentWillUnmount() {
        this.props.reportStore.resetFilterData();
    }

    loadGigs = filterData => {
        const {
            currentProfile
        } = this.props.profileStore;
        // this.setState({
        //     ...this.state,
        //     isLoading: true
        // });
        this.props.bookingRequestStore.showLoadingState();

        if(filterData){
            this.props.reportStore.setVenueFilter(filterData.venue);
            this.props.reportStore.setActFilter(filterData.act);

            this.props.reportStore.setStartDate(filterData.startDate);
            this.props.reportStore.setEndDate(filterData.endDate);
        }

        if(this.state.tabValue == 1){
            Promise.all([
                this.props.bookingRequestStore.getCompletedGigs(currentProfile?.id),
            ]).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.bookingRequestStore.hideLoadingState();
            });
        }else if(this.state.tabValue == 0){
            Promise.all([
                this.props.bookingRequestStore.loadUpcomingGigs(currentProfile?.id),
            ]).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.bookingRequestStore.hideLoadingState();
            });
            
            this.props.bookingRequestStore.getCancelledGigs()
        }else if(this.state.tabValue == 2){
            Promise.all([
                this.props.bookingRequestStore.getCancelledGigs()
            ]).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.bookingRequestStore.hideLoadingState();
            });
        } else if(this.state.tabValue == 3){
            Promise.all([
                this.props.bookingRequestStore.loadUpcomingGigs(currentProfile?.id),
                this.props.bookingRequestStore.getCompletedGigs(),
                this.props.bookingRequestStore.getCancelledGigs()
            ]).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.bookingRequestStore.hideLoadingState();
            });
        }
    };

    componentDidMount = () => {
        const {
            currentProfile
        } = this.props.profileStore;

        this.props.bookingRequestStore.showLoadingState();

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.tab) {
             if (this.props.match.params.tab === "upcoming") {
                this.setState({
                    tabValue: 0
                });
            } else if (this.props.match.params.tab === "completed") {
                this.setState({
                    tabValue: 1
                });
            } else if (this.props.match.params.tab === "cancelled") {
                this.setState({
                    tabValue: 2
                });
            } else if (this.props.match.params.tab === "all") {
                this.setState({
                    tabValue: 3
                });
            }
        }

        // this.props.bookingRequestStore.loadGigsRequests(currentProfile?.id).then(() => {
        //     this.props.bookingRequestStore.hideLoadingState();
        // })
    };

    handleTabChange = (event, value) => {
        switch (value) {
            case 0:
                this.props.history.replace("/privatehire/gig/upcoming");
                break;
            case 1:
                this.props.history.replace("/privatehire/gig/completed");
                break;
            case 2:
                this.props.history.replace("/privatehire/gig/cancelled");
                break;
            case 3:
                this.props.history.replace("/privatehire/gig/all");
                break;
            default:
                this.props.history.replace("/privatehire/gig/upcoming");
                break;
        }

        this.setState({
            tabValue: value
        });
    };

    handleSelectChange = event => {
        switch (event.target.value) {
            case 0:
                this.props.history.replace("/privatehire/gig/upcoming");
                break;
            case 1:
                this.props.history.replace("/privatehire/gig/completed");
                break;
            case 2:
                this.props.history.replace("/privatehire/gig/cancelled");
                break;
            case 3:
                this.props.history.replace("/privatehire/gig/all");
                break;
            default:
                this.props.history.replace("/privatehire/gig/upcoming");
                break;
        }

        this.setState({
            tabValue: event.target.value
        });
    };

    renderTabComponent = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0:
                return <BookingAccepted profileType="PrivateHire" />;
            case 1:
                return <BookingCompleted profileType="PrivateHire" />;
            case 2:
                return <BookingCancelled profileType="PrivateHire" />;
            case 3:
                return <BookingAll profileType="PrivateHire" />;
            default:
                return "";
        }
    };

    renderRequestTabTitle = (title, list) => {
        var needAction = list.filter(data => data.status === BookingStatusType.Declined.ordinal);
        return list && needAction && needAction.length > 0 ? (
            <Badge color="primary" style={{ paddingRight: "16px" }} badgeContent={needAction.length}>
                {title}
            </Badge>
        ) : (
            title
        );
    };

    renderUpcomingTabTitle = (title, list) => {
        // var needAction = list.filter( data => data.status === BookingStatusType.Declined.ordinal || data.status === BookingStatusType.Resent.ordinal);
        return list && list.length > 0 ? (
            <Badge color="primary" style={{ paddingRight: "16px" }} badgeContent={list.length}>
                {title}
            </Badge>
        ) : (
            title
        );
    };

    renderTabTitleSmall = (title, list) => {
        let content = <span className="test">{list ? list.length : 0}</span>;
        return list && list.length > 0 ? (
            <Badge color="primary" classes={{ badge: "itembadge" }} badgeContent={content}>
                {title}
            </Badge>
        ) : (
            title
        );
    };

    render() {
        const breadcrumb = [ { name: "My Gigs" }];
        let { drafts, requests, newRequests, upcomings, completed, cancelled, isLoading: isLoadingGigs, filteredData, allGigs } = this.props.bookingRequestStore;
        const { isLoading } = this.state;        


        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="My Gigs">
                        {/* <ReportDateFilterGigs postChangeEvent={this.loadGigs} /> */}
                        <ReportDateFilterGigs postChangeEvent={this.loadGigs} />
                    </Heading>
                    <Paper style={{ position: "relative" }} className="booking-paper-form">
                        <Loading showed={isLoadingGigs} />
                        <Hidden smDown>
                            <Tabs value={this.state.tabValue} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                <Tab label={this.renderUpcomingTabTitle("Upcoming Gigs", filteredData.upcomings ? filteredData.upcomings : upcomings)} />
                                <Tab label={this.renderUpcomingTabTitle("Completed Gigs", filteredData.completed ? filteredData.completed : completed)} />
                                <Tab label={this.renderUpcomingTabTitle("Cancelled Gigs", filteredData.cancelled ? filteredData.cancelled : cancelled)} />
                                <Tab label={this.renderUpcomingTabTitle("All Gigs", filteredData.allGigs ? filteredData.allGigs : allGigs)} />
                            </Tabs>
                            <br />
                        </Hidden>
                        <Hidden mdUp>
                            <div style={{ padding: 8 }}>
                                <TextField select value={this.state.tabValue} onChange={this.handleSelectChange} textColor="primary" fullWidth>
                                    <MenuItem key={0} value={0}>
                                        {this.renderTabTitleSmall("Upcoming Gigs", upcomings)}
                                    </MenuItem>
                                    <MenuItem key={1} value={1}>
                                        {this.renderTabTitleSmall("Completed Gigs", completed)}
                                    </MenuItem>
                                    <MenuItem key={2} value={2}>
                                        {this.renderTabTitleSmall("Cancelled Gigs", cancelled)}
                                    </MenuItem>
                                    <MenuItem key={3} value={3}>
                                        {this.renderTabTitleSmall("All Gigs", allGigs)}
                                    </MenuItem>
                                </TextField>
                            </div>
                        </Hidden>
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                                <div style={{ padding: 16 }}>{this.renderTabComponent()}</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </MainContainer>
            </div>
        );
    }
}

PrivateHireGig.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(PrivateHireGig);
