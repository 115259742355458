import React, { Component, Fragment } from "react";
// import { observer, inject } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import { Paper } from "@material-ui/core";
import Loading from "../../components/loadingSpinner/Loading";
import GigPromotionForm from "../../components/gigPromotion/GigPromotionForm";

// @inject("promotionStore")
// @observer
class EditGigPromotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({
                ...this.state,
                isLoading: true
            });
            this.setState({
                ...this.state,
                isLoading: false
            });
        }
    }

    generateBreadcrumb = () => {
        var breadcrumb = [{ name: "Dashboard", link: "/staff" }, { name: "Gig Promotion", link: "/gig/promotion" }];

        breadcrumb.push({
            name: "New Gig Promotion"
        });

        return breadcrumb;
    };

    render() {
        const { isLoading } = this.state;
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.generateBreadcrumb()} />
                    <Heading title={"New Gig Promotion"} />
                    <Paper style={{ padding: 16, position: "relative" }}>
                        <Loading showed={isLoading} />
                        <GigPromotionForm />
                    </Paper>
                </MainContainer>
            </Fragment>
        );
    }
}

export default EditGigPromotion;
