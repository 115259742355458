import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button, Grid, Typography, ExpansionPanel, Paper } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ModalContainer from "../modal/ModalContainer";

import ConfirmationModal from "../modal/ConfirmationModal";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import AgentInvitationMessage from "./AgentInvitationMessage";

import ResponsiveTable from "../table/ResponsiveTable";
import Heading from "../Heading";
import TableRowProfile from "../util/TableRowProfile";
import Loading from "../../components/loadingSpinner/Loading";
import image from "../../helper/image";
import { ProfileRelationType } from "../../types/enum";
import blankProfile from "../../img/hotgig-loading.png"

@inject("agentActStore", "templateStore", "userInvitationStore", "profileStore")
@observer
class AgentTable extends Component {
    state = {
        openInvitationMessageModal: false,
        openAcceptModal: false,
        openDeclineModal: false,
        openAcceptAllModal: false,
        openDeclineAllModal: false,
        openRemoveAgentModal: false,
        openRemoveAgentActModal: false,
        request: undefined,
        from: undefined,
        to: undefined,
        invitationMessage: "",
        createdAt: undefined,
        isLoading: true
    };

    constructor(props) {
        super(props);
        this.props.agentActStore.resetData();
    }

    componentDidMount() {
        // this.props.templateStore.showLoadingScreen();
        const currentProfile = this.props.profileStore.getCurrentProfile()

        Promise.all([
            // this.props.agentActStore.getMusicianActPendingRequest(), 
            this.props.agentActStore.getMusicianAgents(),
            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentAct.ordinal, currentProfile.id)
        ]).then(() => {
            this.setState({ isLoading: false });
        });
    }

    columns = [{ key: "agent", label: "Agent" }, { key: "actName", label: "Act" }, { key: "status", label: "Status" }, { key: "action", label: "" }];

    rowData = [];

    pendingColumns = [
        { key: "agent", label: "Agent", width: "20%" },
        { key: "act", label: "Act", width: "20%" },
        { key: "status", label: "Status", width: "10%" },
        { key: "action", label: "", width: "30%" }
    ];

    pendingRows = [];

    mapPendingInvitation = () => {
        this.pendingRows = [];

        this.props.userInvitationStore.invitations.forEach(data => {
            var row = {};

            row.act = <TableRowProfile name={data.invitedAct} avatarUrl={data.actImage ? data.actImage : blankProfile} profileId={data.additionalData}/>;

            row.agent = <TableRowProfile name={`${data.invitedFirstName}`} avatarUrl={data.ownerImage ? data.ownerImage : blankProfile} profileId={data.invitedAgency}/>;

            row.status = "Pending";

            this.pendingRows.push(row);
        });
        return Promise.resolve();
    }

    mapPending = () => {
        this.props.agentActStore.pendingAgentActs.forEach(request => {
            var row = {};

            var agent = request.request[0].primaryProfile;

            var acts = "";

            request.request.forEach(data => {
                acts += data.secondaryProfile.fullName + ", ";
            });

            acts = acts.substr(0, acts.length - 2);

            row.style = {
                backgroundColor: "#EEE"
            };

            row.agent = <TableRowProfile profileId = { agent.id } name={agent.fullName} />;

            row.actName = (
                <ExpansionPanel
                    style={{
                        backgroundColor: "#EEE",
                        boxShadow: "none"
                    }}
                >
                    <ExpansionPanelSummary style={row.style} expandIcon={<ExpandMoreIcon />}>
                        {acts}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={row.style}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Act Name</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {request.request.map(data => (
                                    <TableRow>
                                        <TableCell>
                                            <TableRowProfile
                                                profileId = { data.secondaryProfile.id }
                                                name={data.secondaryProfile.fullName}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button color="primary" size="small" onClick={() => this.handleOpenInvitationMessageModal(data)}>
                                                View Message
                                            </Button>
                                            <Button color="primary" size="small" onClick={() => this.handleOpenAcceptModal(data)}>
                                                Accept
                                            </Button>
                                            <Button color="primary" size="small" onClick={() => this.handleOpenDeclineModal(data)}>
                                                Decline
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );

            row.status = "Pending";

            row.action = (
                <Fragment>
                    <Button
                        color="primary"
                        className="btn-accept-agent-invite"
                        size="small"
                        onClick={() => this.handleOpenAcceptAllModal(request.request)}
                    >
                        Accept All
                    </Button>
                    <Button color="primary" size="small" onClick={() => this.handleOpenDeclineAllModal(request.request)}>
                        Decline All
                    </Button>
                </Fragment>
            );

            this.rowData.push(row);
        });

        return Promise.resolve();
    };

    mapAccepted = () => {
        this.props.agentActStore.agentActs.forEach(agentAct => {
            var row = {};

            var agent = agentAct.data ? agentAct.data[0].agent : {};

            var acts = "";

            agentAct.data.forEach(data => {
                acts += data.act.fullName + ", ";
            });

            let agentName = agent.profileName
            let matches = agentName.match(/\((.*?)\)/);

            if (matches) {
                agentName = matches[1];
            }
            
            acts = acts.substr(0, acts.length - 2);

            row.agent = <TableRowProfile avatarUrl={image.getProfileImage(agent)} profileId = { agent.id } name={agentName} />;

            row.actName = (
                <ExpansionPanel
                    style={{
                        boxShadow: "none"
                    }}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}><div style={{width:"95%"}}>{acts}</div></ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Act Name</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agentAct.data.map(data => (
                                    <TableRow key={data.id}>
                                        <TableCell>
                                            <TableRowProfile
                                                profileId = { data.act.id }
                                                name={data.act.fullName}
                                                avatarUrl={image.getProfileImage(data.act)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button color="primary" size="small" onClick={() => this.handleOpenRemoveAgentActModal(data)}>
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );

            row.status = "Accepted";

            row.action = (
                <Fragment>
                    <Button color="primary" size="small" onClick={() => this.handleOpenRemoveAgentModal(agentAct.data)}>
                        Remove
                    </Button>
                </Fragment>
            );

            this.rowData.push(row);
        });
    };

    handleOpenInvitationMessageModal = request => {
        this.setState({
            ...this.state,
            openInvitationMessageModal: true,
            from: request.primaryProfile,
            to: request.secondaryProfile,
            invitationMessage: request.requestMessage,
            createdAt: request.createdAt,
            request: request
        });
    };

    handleCloseInvitationMessageModal = () => {
        this.setState({
            openInvitationMessageModal: false,
            invitationMessage: "",
            request: undefined
        });
    };

    handleOpenAcceptAllModal = request => {
        this.setState({
            ...this.state,
            openAcceptAllModal: true,
            request: request
        });
    };

    handleOpenDeclineAllModal = request => {
        this.setState({
            ...this.state,
            openDeclineAllModal: true,
            request: request
        });
    };

    handleOpenAcceptModal = request => {
        this.setState({
            ...this.state,
            openAcceptModal: true,
            request: request
        });
    };

    handleOpenDeclineModal = request => {
        this.setState({
            ...this.state,
            openDeclineModal: true,
            request: request
        });
    };

    handleOpenRemoveAgentModal = request => {
        this.setState({
            ...this.state,
            openRemoveAgentModal: true,
            request: request
        });
    };

    handleOpenRemoveAgentActModal = request => {
        this.setState({
            ...this.state,
            openRemoveAgentActModal: true,
            request: request
        });
    };

    handleCloseConfirmationModal = () => {
        this.setState({
            openAcceptModal: false,
            openDeclineModal: false,
            openAcceptAllModal: false,
            openDeclineAllModal: false,
            openRemoveAgentModal: false,
            openRemoveAgentActModal: false,
            request: undefined
        });
    };

    handleAcceptInvitation = () => {
        this.props.agentActStore.acceptRequest(this.state.request).then(() => {
            this.handleCloseConfirmationModal();
            this.handleCloseInvitationMessageModal();
            this.props.templateStore.openSnackbar("Agent invitation has been accepted.");
        });
    };

    handleDeclineInvitation = () => {
        this.props.agentActStore.declineRequest(this.state.request.id).then(() => {
            this.handleCloseConfirmationModal();
            this.handleCloseInvitationMessageModal();
            this.props.templateStore.openSnackbar("Agent invitation has been declined.");
        });
    };

    handleAcceptAllInvitation = () => {
        this.props.agentActStore.acceptManyRequest(this.state.request).then(() => {
            this.handleCloseConfirmationModal();
            this.handleCloseInvitationMessageModal();
            this.props.templateStore.openSnackbar("Agent invitation has been accepted.");
        });
    };

    handleDeclineAllInvitation = () => {
        this.props.agentActStore.declineManyRequest(this.state.request).then(() => {
            this.handleCloseConfirmationModal();
            this.handleCloseInvitationMessageModal();
            this.props.templateStore.openSnackbar("Agent invitation has been accepted.");
        });
    };

    handleDeleteAgent = () => {
        this.props.agentActStore.removeAgentActs(this.state.request).then(() => {
            this.props.templateStore.openSnackbar("Agent has been removed.");
            this.handleCloseConfirmationModal();
            this.handleCloseInvitationMessageModal();
        });
    };

    handleDeleteAgentAct = () => {
        this.props.agentActStore.removeAgentAct(this.state.request.id).then(() => {
            this.props.templateStore.openSnackbar(
                this.state.request.agent.profileName + " 's access to " + this.state.request.act.actName + " has been revoked."
            );
            this.handleCloseConfirmationModal();
            this.handleCloseInvitationMessageModal();
        });
    };

    render() {
        this.rowData = [];
        this.mapPending().then(this.mapAccepted());
        this.mapPendingInvitation();
        const { isLoading } = this.state;
        const {invitations} = this.props.userInvitationStore;

        let renderInvitationMessageModal = () => (
            <ModalContainer open={this.state.openInvitationMessageModal} title="Invitation Message" onClose={this.handleCloseInvitationMessageModal}>
                <DialogContent>
                    <AgentInvitationMessage
                        from={this.state.from}
                        to={this.state.to}
                        message={this.state.invitationMessage}
                        createdAt={this.state.createdAt}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.handleCloseInvitationMessageModal}>
                        Close
                    </Button>
                    <Button color="primary" onClick={this.handleDeclineInvitation}>
                        Decline
                    </Button>
                    <Button color="primary" onClick={this.handleAcceptInvitation}>
                        Accept
                    </Button>
                </DialogActions>
            </ModalContainer>
        );

        return (
            <Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Heading title="My Agent" />
                    </Grid>

                    <Grid item xs={12}>
                        <Paper elevation={0} style={{ padding: 16 }}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            My Agents
                                        </Typography>
                                        <Typography className="side-helper-text">
                                            Use this section to manage which agents will have access to your calendar and be able to book your acts.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Paper style={{ position: "relative", minHeight: "200px" }}>
                                        <Loading showed={isLoading} borderRadius="0" />
                                        {invitations.length > 0 && 
                                            <Paper>
                                                <ResponsiveTable columns={this.pendingColumns} rows={this.pendingRows} />
                                            </Paper>
                                        }
                                        <ResponsiveTable columns={this.columns} rows={this.rowData} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                {renderInvitationMessageModal()}

                <ConfirmationModal
                    open={this.state.openRemoveAgentModal}
                    message="Are you sure to remove this agent? Your current bookings with this agent will be removed."
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleDeleteAgent}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Remove"
                />

                <ConfirmationModal
                    open={this.state.openRemoveAgentActModal}
                    message="Are you sure to remove agent for this act? Your current bookings with this agent will be removed."
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleDeleteAgentAct}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Remove"
                />

                <ConfirmationModal
                    open={this.state.openAcceptAllModal}
                    message="Are you sure to accept all invitation?"
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleAcceptAllInvitation}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Accept"
                />

                <ConfirmationModal
                    open={this.state.openDeclineAllModal}
                    message="Are you sure to decline all invitation?"
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleDeclineAllInvitation}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Decline"
                />
                <ConfirmationModal
                    open={this.state.openAcceptModal}
                    message="Are you sure to accept this invitation?"
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleAcceptInvitation}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Accept"
                />
                <ConfirmationModal
                    open={this.state.openDeclineModal}
                    message="Are you sure to decline this invitation?"
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleDeclineInvitation}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Decline"
                />
            </Fragment>
        );
    }
}

export default AgentTable;
