import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Button, Checkbox, TextField, Typography,CircularProgress, InputAdornment, IconButton} from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";

import "./Login.css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import logo from "../img/hotgig-logo.png";
import imageBackground from "../img/hero3.png"
import facebook from "../img/facebook-box.svg";
import { withRouter } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import queryString from "query-string";
import FooterLogin from "./footer/FooterLogin";
import Cookies from 'universal-cookie';

import WarningModal from "../components/modal/WarningModal";
const cookies = new Cookies();
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
const EMAIL_CONFIRMATION_REQUIRED = process.env.REACT_APP_EMAIL_CONFIRMATION_REQUIRED === "true";
const DISABLE_SIGNUP = process.env.REACT_APP_DISABLE_SIGNUP === "true";
const BASE_URL = process.env.REACT_APP_BASE_URL;

@inject("userStore", "authStore", "profileStore", "facebookStore", "billingStore", "commonStore")
@withRouter
@observer
class Login extends Component {
    state = {
        source: undefined,
        checked: false,
        isLoading: false,
        showPassword:false,
        checked:false,
        isEmailInvalid: false
    };

    constructor(props) {
        super(props);
        this.props.authStore.reset();
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);

       
        const emailConfirmation = localStorage.getItem("email-confirmation")
        if(emailConfirmation) {
            this.props.authStore.setEmail(emailConfirmation);
        }
        if (params.email) {
            this.props.authStore.setEmail(params.email);
        }

        if (params.token && params.refreshToken) {
            this.props.authStore.loginWithToken(params.token, params.refreshToken).then(() => {
                if (params.redirectUrl) {
                    this.props.history.push(params.redirectUrl);
                }
            });
        }
        var rememberMe = false
        var emailLogin = cookies.get('emailLogin')
        if(emailLogin){
            this.props.authStore.setEmail(emailLogin);
            rememberMe = true
        }
        var passwordLogin = cookies.get('passwordLogin')
        if(passwordLogin){
            this.props.authStore.setPassword(passwordLogin);
            rememberMe = true
           
        }
        if (params.source) {
            this.setState({
                source: params.source,
                checked: rememberMe
            });
            this.props.authStore.setSource(params.source);
        }else{
            this.setState({ checked: rememberMe })
        }
    }

    handleCheckBox = (e) => {
        this.setState({ checked: e.target.checked })
    }

    handleTextCheckBox = () => {
        this.setState({ checked: !this.state.checked })
    }
    handleEmailChange = e => this.props.authStore.setEmail(e.target.value);
    handleValidateEmail = e => this.props.authStore.validateEmail(e.target.value);
    handlePasswordChange = e =>  this.props.authStore.setPassword(e.target.value)
    handleValidatePassword = e => this.props.authStore.validatePassword(e.target.value)

    
    openModal = () => {
        this.setState({ isEmailInvalid: true })
        
    }
    closeModal = () => {
        this.setState({ isEmailInvalid: false })
        
    }

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    openSignupLink = () => {
        window.cordova.InAppBrowser.open(`${BASE_URL}register`, "_system", "location=yes");
    };

    responseFacebook(response) {
        this.props.facebookStore.login(response.accessToken).then(() => {
            const { currentUser } = this.props.userStore;
            const { profile } = this.props.profileStore;

            if (currentUser.status !== 50 || profile === undefined) {
                this.props.history.replace("/welcome");
            } else {
                switch (profile.type) {
                    case 1:
                        this.props.history.replace("/musician");
                        break;
                    case 2:
                        this.props.history.replace("/agent");
                        break;
                    case 3:
                        this.props.history.replace("/staff");
                        break;
                    case 6:
                        this.props.history.replace("/production");
                        break;
                    case 7:
                        this.props.history.replace("/dj");
                        break;
                    default:
                        this.props.history.replace("/welcome");
                        break;
                }
            }
        });
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    handleSubmitForm = async (e) => {
        e.preventDefault();
        this.props.commonStore.resetAllData();
        this.setState({isLoading: true})
        this.props.authStore.checkEmailValid().then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                this.props.authStore.login().then(async result => {
                    this.setState({isLoading: false})
                    if(this.state.checked){
                        cookies.set('emailLogin', this.props.authStore.values.email , { path: '/' });
                        cookies.set('passwordLogin', this.props.authStore.values.password, { path: '/' });
                    }else{
                        cookies.remove('emailLogin', { path: '/' });
                        cookies.remove('passwordLogin', { path: '/' });
                    }
                    const { redirectUrl } = this.props.authStore;
                    const { currentUser } = this.props.userStore;
                    const { profile } = this.props.profileStore;
                    if (!this.props.userStore.isUserVerified()) {
                        this.props.history.push("/confirmation");
                    }
                    
                    if (currentUser.status !== 50 || profile === undefined) {
                        if (!this.props.userStore.isUserVerified() && EMAIL_CONFIRMATION_REQUIRED) {
                            this.props.history.push("/confirmation");
        
                            //complete profile and create act
                        } else if (Number.isNaN(currentUser.status)) {
                            this.gotoDashboard(profile.type)
                        } else {
                            this.props.history.push("/welcome");
                        }
                    } else if (redirectUrl) {
                        this.props.history.push(redirectUrl);
                    } else {
                        this.gotoDashboard(profile.type)
                    }
                }).finally(() => {
                    this.setState({isLoading: false})
                });
            }else{
                this.setState({isLoading: false})
                this.openModal()
            }
      
        })
    };

    handleClickShowPassword = () => {
        const {showPassword} = this.state 
     
        this.setState({showPassword: !showPassword})
    }
 
    gotoDashboard(profileType) {
        switch (profileType) {
            case 1:
                this.props.history.push("/musician");
                break;
            case 2:
                this.props.history.push("/agent");
                break;
            case 3:
                this.props.history.push("/staff");
                break;
            case 6:
                this.props.history.push("/production");
                break;
            case 7:
                this.props.history.push("/dj");
                break;
            case 8:
                this.props.history.push("/agent");
                break;
            case 9:
                this.props.history.push("/privatehire/gig");
                break;
    }
    }



    render() {
        const { values } = this.props.authStore;
        const {isLoading, showPassword} = this.state
        // const signUpLink = this.state.source ? `/register?source=${this.state.source}` : "/register";
        // const signInLink = this.state.source ? `/login?source=${this.state.source}` : "/login";

        // const signUpText = 'It’s free to sign up. No commissions ever. Just add in your details below and we will send you a hot link to log in and set up your profile.'
        const rememberMeText = () => (
            <span>
                Remember me?
                </span>
        )
        return (
            <div style={{display: "flex", flex: 1, flexDirection: 'column', zIndex: 40}}>
                <div className="Login">
                    <div className='image-background' />
                    <img src={logo} alt="HotGiggity" className='header-logo'/>
                    <Grid container direction="column" wrap='nowrap' className="Login-Grid" alignItems='center' justify='center' alignContent='center'>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid container direction='row' className="logo-login" alignItems='center' justify='center' alignContent='center' > 
                                    <h2 className="logo-login-title">Sign In to</h2>
                                    <img style={{width: 'auto'}} src={logo} alt="HotGiggity" />
                                </Grid>
                            </Grid>
                            <Paper className="Form">
                                <form onSubmit={this.handleSubmitForm} className="Submit-form">
                                    {/* Facebook Button
                                    Based on new design, commented for future use
                                    {!IS_MOBILE && (
                                        <Fragment>
                                            <FacebookLogin
                                                appId={FACEBOOK_APP_ID.toString()}
                                                callback={this.responseFacebook.bind(this)}
                                                cssClass="fb-login fb-btn"
                                                textButton="&nbsp;&nbsp; Continue with Facebook"
                                                icon={<img src={facebook} alt="fb" className="logo-fb" />}
                                            />
                                            <div className="or-container">
                                                <Typography component="p" className="or">
                                                    <span>or</span>
                                                </Typography>
                                            </div>
                                        </Fragment>
                                    )} */}
                                    {/* <p className="login-text">
                                        {signUpText}
                                    </p> */}
                                    {
                                    this.anyError("general") &&
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            color: "#f44336",
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            // paddingLeft: 5,
                                            // paddingBottom: 10,
                                            // paddingTop: 10,
                                        }}>{this.errorText("general")}</div>
                                    }
                                    <TextField
                                        id="email"
                                        // label="Email"
                                        placeholder="Email"
                                        margin="normal"
                                        className="inputTextContainer"
                                        InputProps={{className: "inputTextField"}}
                                        fullWidth
                                        type="email"
                                        value={values.email}
                                        onChange={this.handleEmailChange}
                                        onBlur={this.handleValidateEmail}
                                        error={this.anyError("email")}
                                        helperText={this.errorText("email")}
                                    />
                                    <TextField
                                        id="password"
                                        InputProps={{
                                            className: "inputTextField",
                                            endAdornment:(
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                  >
                                                    {showPassword ?  <i class="fa fa-eye whiteColor" /> :  <i class="icon-eye-blocked whiteColor"/>}
                                                  </IconButton>
                                                </InputAdornment>
                                            )
                                          }}
                                        // label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Password"
                                        className="inputTextContainer"
                                        autoComplete="current-password"
                                        margin="normal"
                                        fullWidth
                                        value={values.password}
                                        onChange={this.handlePasswordChange}
                                        onBlur={this.handleValidatePassword}
                                        error={this.anyError("password")}
                                        helperText={this.errorText("password")}
                                    />
                                     <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: -10, }}>
                                        <Checkbox checked={this.state.checked} onChange={this.handleCheckBox} style={{ color: 'white', alignSelf: 'center', }} />
                                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }}>
                                            <p onClick={this.handleTextCheckBox} className='agreement-text'>{rememberMeText()}</p>
                                        </div>
                                    </div>
                                    <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: "flex", marginTop: 10}}>
                                        { isLoading ? (
                                             <div>
                                                 <CircularProgress style={{ backgroundColor: 'transparent' }} /> 
                                             </div> 

                                            ) : (
                                                <Button className="btnSubmit" variant="contained" color="primary" id="signin-btn" type="submit">
                                                    Sign In
                                                </Button>

                                            )

                                        }
                                       
                                        <LinkContainer to="/forgotpassword">
                                            <a className="btnForget">Forgot Password?</a>
                                        </LinkContainer>
                                    </div>
                                </form>
                            </Paper>
                            {IS_MOBILE ? (
                                <Button
                                    className="btnForget"
                                    onClick={this.openSignupLink}
                                    style={{ color: "#fff", textTransform: "none", fontSize: 16 }}
                                >
                                    Sign up at hotgiggity.com
                                </Button>
                            ) : null}
                        </Grid>
                </div>
                <div style={{display: 'flex', position:'relative', width: '100vw', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
                    <FooterLogin />
                </div>
                <WarningModal
                        title={"Warning: Temporary Email Address Detected"}
                        open={this.state.isEmailInvalid}
                        message={`<p>We have detected that you are trying to sign into an account created using a temporary email address. Our platform now requires a verifiable email address for registration to ensure the security and reliability of our services.</p>

                        <p>If you are the legitimate owner of this account, please contact our support team at support@hotgiggity.com.</p>`}
                        closeHandler={this.closeModal}
                        confirmationLabel="Ok"
                />
            </div>
        );
    }
}

export default Login;
