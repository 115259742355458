import { observable, action } from "mobx";
import agent from "../agent";
import { Promise } from "bluebird";

class SetListStore {
    @observable selectedSongs = [];
    @observable setLists = [];
    @observable initialSongs = [];
    @observable filteredSongs = [];
    @observable addedList = [];
    @observable deletedList = [];
    @observable id = undefined;
    @observable bookingId = undefined;
    @observable profileId = undefined;
    @observable type = undefined;
    @observable title = "";
    @observable flagNewSetlistAct = false;
    @observable errors = undefined;
    @observable tempAddedSong = undefined;
    @observable tempDeletedSong = undefined;

    //song added save to this observable for handle undo function
    @action
    setTempAddedSong(song) {
        this.tempAddedSong = song;
    }

    //song added save to this observable for handle undo function
    @action
    setTempDeletedSong(song) {
        this.tempDeletedSong = song;
    }

    //Undo added song. delete song that has been added
    @action
    undoSetlistAction() {
        if (this.tempAddedSong) {
            let song = this.tempAddedSong;
            const updateSelected = this.selectedSongs.filter(item => {
                return item.id !== song.id;
            });
            this.deletedList.push(song.id);
            this.selectedSongs.replace(updateSelected);
        } else if (this.tempDeletedSong) {
            let song = this.tempDeletedSong;
            this.selectedSongs.push(song);
            this.addedList.push(song.id);
        }
    }

    @action
    setNewActSetlist(flag) {
        this.flagNewSetlistAct = flag;
    }

    @action
    setBookingId(bookingId) {
        this.bookingId = bookingId;
    }

    @action
    setProfileId(profileId) {
        this.profileId = profileId;
        return Promise.resolve();
    }

    @action
    setTitle(title) {
        if (this.setLists.length > 0) {
            for (let i = 0; i < this.setLists.length; i++) {
                if (this.setLists[i]["setList"]["id"] === this.id) {
                    this.setLists[i]["setList"]["title"] = title;
                }
            }
        }
        this.title = title;
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    deleteFromList(song) {
        this.deletedList.push(song.id);
    }

    @action
    deleteCurrentSetlist(song) {
        if (this.id === undefined) return Promise.resolve();

        let self = this;
        return agent.SetList.delete(this.id).then(
            action(response => {
                return this.loadSetlists();
            })
        );
    }

    @action
    loadSetlists() {
        switch (this.type) {
            case 0:
            case 1:
                return agent.SetList.getByProfileId(this.profileId).then(
                    action(sets => {
                        this.setLists = sets;
                    })
                );
            case 2:
                return agent.SetList.getByBookingId(this.bookingId).then(
                    action(sets => {
                        this.setLists = sets;
                    })
                );
            default:
                break;
        }
    }

    @action
    loadInitialSongItems() {
        return agent.Song.getSongs(this.profileId).then(
            action(songs => {
                this.initialSongs = songs;
                return this.initialSongs;
            })
        );
    }

    @action
    copyFromAct(actId) {
        return agent.SetList.copyFromAct(this.bookingId, actId).then(
            action(res => {
                return this.loadSetlists();
            })
        );
    }

    @action
    updateSelectedSongs(songs) {
        this.selectedSongs.replace(songs);
    }

    @action
    filterSongs(songs) {
        this.filteredSongs.replace(songs);
    }

    @action
    addSelectedSong(song) {
        this.selectedSongs.push(song);
        this.addedList.push(song.id);
    }

    @action
    load(setListId) {
        return agent.SetList.load(setListId).then(
            action(setlist => {
                if (setlist) {
                    this.title = setlist.title;
                    this.id = setlist.id;
                    this.type = setlist.type;
                    this.profileId = setlist.profileId;
                    this.bookingId = setlist.bookingId;
                    this.selectedSongs = setlist.songLists;
                }
            })
        );
    }

    @action
    addNewSetlist(selectedSongs = []) {
        this.errors = undefined;
        this.id = undefined;
        this.title = "New Setlist";
        this.selectedSongs = selectedSongs;
        this.addedList = [];
        this.deletedList = [];
        this.filteredSongs = [];
        this.submit()
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .then(
                action(res => {
                    return this.loadSetlists();
                })
            );
    }

    @action
    saveDraft() {
        this.errors = undefined;

        if(!this.id){
            return Promise.resolve();
        }
        let setlist = {
            id: this.id,
            title: this.title,
            profileId: this.profileId,
            bookingId: this.bookingId,
            type: this.type,

            songLists: this.selectedSongs.slice()
        };

        if (this.type === 2) {
            setlist.profileId = undefined;
        }

        return this.id
            ? this.update(setlist).catch(
                  action(err => {
                      this.errors = err.response && err.response.body && err.response.body.errors;
                      throw err;
                  })
              )
            : this.create(setlist).catch(
                  action(err => {
                      this.errors = err.response && err.response.body && err.response.body.errors;
                      throw err;
                  })
              );
    }

    @action
    submit() {
        this.errors = undefined;

        let setlist = {
            id: this.id,
            title: this.title,
            profileId: this.profileId,
            bookingId: this.bookingId,
            type: this.type,

            songLists: this.selectedSongs.slice()
        };

        if (this.type === 2) {
            setlist.profileId = undefined;
        }

        return this.id
            ? this.update(setlist).catch(
                  action(err => {
                      this.errors = err.response && err.response.body && err.response.body.errors;
                      throw err;
                  })
              )
            : this.create(setlist).catch(
                  action(err => {
                      this.errors = err.response && err.response.body && err.response.body.errors;
                      throw err;
                  })
              );
    }

    @action
    create(setlist) {
        return agent.SetList.create(setlist);
    }

    @action
    update(setlist) {
        return agent.SetList.update(setlist).then(response => {
            this.loadSetlists();
        });
    }

    @action
    addToExistingSet(set) {
        return agent.SetList.addSongs(set.id, this.selectedSongs.slice()).then(response => {});
    }

    @action
    deleteSetList(set) {
        let self = this;
        return agent.SetList.delete(set.id).then(
            action(response => {
                const filteredSetlist = self.setLists.filter(s => {
                    return s.setList.id !== response.id;
                });
                self.setLists.replace(filteredSetlist);
            })
        );
    }

    @action
    reset() {
        this.selectedSongs = [];
        this.setLists = [];
        this.initialSongs = [];
        this.filteredSongs = [];
        this.addedList = [];
        this.deletedList = [];
        this.id = undefined;
        this.bookingId = undefined;
        this.profileId = undefined;
        this.type = undefined;
        this.title = "";
        this.flagNewSetlistAct = false;
        this.errors = undefined;
    }
}

export default new SetListStore();
