import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { Button, Grid, Card, Switch, Avatar, Typography } from "@material-ui/core";

import CardHeader from "@material-ui/core/CardHeader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import image from "../../../helper/image";

@inject("agentVenueStore", "templateStore")
@observer
class EditAgentModal extends Component {
    renderDaySwitch = () => {
        const { editedAgentVenue } = this.props.agentVenueStore;

        return (
            <Grid container spacing={8}>
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(data => (
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editedAgentVenue ? editedAgentVenue[data.toLowerCase()] : false}
                                    onChange={() => this.props.agentVenueStore.toggleEditedAgentVenueBooking(data.toLowerCase())}
                                    value={data.toLowerCase()}
                                    color="primary"
                                />
                            }
                            label={data}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    handleSubmitEdit = () => {
        const { editedAgentVenue } = this.props.agentVenueStore;
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.submit([editedAgentVenue]).then(() => {
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.closeRegionBlock();
            this.props.onClose();
        });
    };

    render() {
        const { editedAgentVenue } = this.props.agentVenueStore;

        return (
            <ModalContainer open={this.props.open} onClose={this.onClose} title="Edit Agent Booking Day" maxWidth="sm">
                <DialogContent>
                    <Typography variant="caption">Select the days of the week you would like this agent to book this venue.</Typography>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            {editedAgentVenue ? (
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                alt={editedAgentVenue.agent.fullName}
                                                src={image.getProfileImage(editedAgentVenue.agent)}
                                                style={{
                                                    width: 40,
                                                    height: 40
                                                }}
                                            />
                                        }
                                        title={editedAgentVenue.agent.fullName}
                                    />
                                </Card>
                            ) : (
                                ""
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            {this.renderDaySwitch()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Close</Button>
                    <Button color="primary" onClick={this.handleSubmitEdit}>
                        Save
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default EditAgentModal;
