import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import profileStore from "./profileStore";
import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";
import organisationMemberStore from "./organisationMemberStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";

class MemberProfileStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable userId = 0;
    @observable type = 0;
    @observable members = [];
    @observable canManage = false;

    @observable visibility = "";

    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        const { id } = generalProfileStore;

        profileId = profileId ? profileId : id;

        if (currentUser) {
            this.inProgress = true;
            // templateStore.showLoadingScreen();
            if (this.type === ProfileType.Venue.ordinal && profileId) {
                return profileStore
                    .getProfile(profileId)
                    .then(
                        action(profile => {
                            if (!profile) throw new Error("Can't load profile");
                            this.visibility = profile.visibility;
                            this.id = profile.id;
                            this.canManage = profile.canManage;
                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }
                            return profile;
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            }
        } else {
            return Promise.resolve();
        }
    }

    @action
    reset() {
        this.visibility = "";
    }

    @action
    setVisibility(visibility) {
        this.visibility = visibility;
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const members = organisationMemberStore.members.slice();

        const profile = {
            id: this.id,
            userId: user.id,
            visibility: this.visibility,
            type: this.type,
            members: members
        };
        return (this.id ? this.updateProfile(profile) : this.createProfile(profile))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    createProfile(data) {
        //using general profile for create
        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.MusicianGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Staff.ordinal:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Act.ordinal:
            default:
                return agent.ActGeneralProfile.create(data).then(profile => {
                    return profile;
                });
        }
    }

    @action
    updateProfile(data) {
        switch (this.type) {
            case ProfileType.Venue.ordinal:
                return agent.VenueMemberProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            default:
                break;
        }
    }
}

export default new MemberProfileStore();
