import React, { Component, Fragment } from "react";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";

import TableCell from "@material-ui/core/TableCell";
import ReorderIcon from "@material-ui/icons/Reorder";

const style = {
    cursor: "-webkit-grab"
};

const dragSource = {
    beginDrag(props) {
        return {
            index: props.index,
            content: props.content
        };
    }
};

const dropTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    }
};

@DropTarget("row", dropTarget, connect => ({
    connectDropTarget: connect.dropTarget()
}))
@DragSource("row", dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
}))
class DraggableRow extends Component {
    render() {
        const { content, rowStyle, connectDragSource, connectDropTarget } = this.props;

        return (
            <Fragment>
                {connectDropTarget(
                    <tr style={{ ...rowStyle, width: "100% !important" }}>
                        <TableCell style={{ textAlign: "center", width: "16px", paddingLeft: 0, paddingRight: 0 }}>
                            {connectDragSource(
                                <div style={style}>
                                    <ReorderIcon />
                                </div>
                            )}
                        </TableCell>
                        {content}
                    </tr>
                )}
            </Fragment>
        );
    }
}

export default DraggableRow;
