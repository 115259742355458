import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button, TextField, Grid, Typography } from "@material-ui/core";
import FacebookPageDialog from "../../components/widget/FacebookPageDialog";
import FacebookPageDialog2 from "../../components/widget/FacebookPageDialog2";
import InstagramPageDialog from "../../components/widget/InstagramPageDialog";

import EmbedGigWidget from "../../components/widget/EmbedGigWidget";
import Loading from "../loadingSpinner/Loading";
import { withRouter } from "react-router-dom";

import EntertainmentGigWidget from "../widget/EntertainmentGigWidget";
import EntertainmentGigVenueWidget from "../widget/EntertainmentGigVenueWidget";

import GoogleCalendarPart from "../../pages/setting/component/googleCalendarPart.js"
const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
const FACEBOOK_ENABLED = process.env.REACT_APP_FACEBOOK_ENABLED === "true";
const ENV = process.env.REACT_APP_BASE_URL

@inject("socialProfileStore", "templateStore", "authStore", "profileStore", "googleCalendarStore", "instagramStore")
@withRouter
@observer
class Social extends React.Component {
    state = {
        isLoading: true,
        isLoaded: false,
        isMYOBRedirect : false,
    };

    componentDidMount() {
        const currentProfile = this.props.profileStore.getCurrentProfile();;
        const {instagramCode} = this.props.instagramStore
        window.scrollTo(0, 0);
        if(this.props.googleCalendarStore.googleCalendarCode){
            // this.setState({ isMYOBRedirect: true });
        }

        if (instagramCode) {
            var data = {
                code : instagramCode,
                profileId : currentProfile?.id
            }
            this.props.instagramStore
            .exchangeToken(data)
            .then(() => {
                this.props.socialProfileStore.setType(this.props.templateStore.getType(this.props.type));
                this.props.socialProfileStore
                    .loadInitialData(this.props.profileId)
                    .then(() => {})

            })
            .finally(() => {
                this.setState({ isLoading: false, isLoaded: true });
                window.scrollTo(0, 0);
            });
            
        }else{
            this.loadSocial();
        }
        // set type
            
    }

    
    loadSocial = () => {
        this.props.socialProfileStore.setType(this.props.templateStore.getType(this.props.type));
        this.props.socialProfileStore
            .loadInitialData(this.props.profileId)
            .then(() => {})
            .finally(() => {
                this.setState({ isLoading: false, isLoaded: true });
                window.scrollTo(0, 0);
            });
    }
    

    handleBack = () => {
        if (this.props.type === "venue") {
            const { profile } = this.props.profileStore;
            this.props.history.push(`/venues/${profile.id}/edit/financial`);
        }
        if (this.props.type === "act") {
            this.props.history.replace(`/myact/edit/${this.props.match.params.id}/design`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();

        this.setState({ isLoading: true });
       
        this.props.socialProfileStore.submit().then(resp => {
            if (this.props.type === "venue") {
                this.props.history.push(`/venues/${resp.id}/edit/member`);
            }
            if (this.props.type === "act") {
                this.props.history.push(`/myact/edit/${resp.id}/lineup`);
            }
            if (this.props.type !== "act" && this.props.type !== "venue") {
                const { editProfileActiveStep } = this.props.templateStore;
                this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
            }
        });
    };

    anyError(field) {
        if (!this.props.socialProfileStore.errors) return false;
        if (!this.props.socialProfileStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.socialProfileStore.errors) return null;
        if (!this.props.socialProfileStore.errors[field]) return null;
        return this.props.socialProfileStore.errors[field];
    }

    render() {
        const { editProfileActiveStep } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        const { isLoading,isLoaded } = this.state;

        return (
            <div className="Profile-Social">
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                      

                        <Fragment>
                            <Grid item xs={12} lg={3}>
                                <div className="sticky-information">
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Embed Your Upcoming Gigs On Your Website
                                    </Typography>
                                    {/* <Typography gutterBottom className="side-helper-text">
                                        Embed your list upcoming gig to your website.
                                    </Typography> */}
                                    <Typography>
                                        <b>Tip:</b>
                                    </Typography>
                                    <Typography className="side-helper-text">
                                        You can customise the colours of your gig guide using the 'CUSTOMISE GIG WIDGET' button.
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <hr style={{ marginTop: 0, marginBottom: 0 }} />
                                <Grid container spacing={8}>
                                    <Grid item xs={12} md={12}>
                                        <EmbedGigWidget profileId={this.props.socialProfileStore.id} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fragment>

                        {
                            type === 'venue' && (
                                <Fragment>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Display Your Upcoming Gigs on Venue Screens
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Use this URL in your digital signage solution to automatically display your upcoming gigs on screens in your venue
                                            </Typography>
                                            {/* <Typography>
                                                <b>Tip:</b>
                                            </Typography>
                                            <Typography className="side-helper-text">
                                                You can change this URL <a target={"_blank"} href={`${ENV}venues/${this.props.profileId}/edit/design#vanityUrl`}>here</a>
                                            </Typography> */}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <hr style={{ marginTop: 0, marginBottom: 0 }} />
                                        <Grid container spacing={8}>
                                            <Grid item xs={12} md={12}>
                                                <EntertainmentGigWidget profileId={this.props.socialProfileStore.id} />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <EntertainmentGigVenueWidget profileId={this.props.socialProfileStore.id} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment> 
                            )
                        }
                        {FACEBOOK_ENABLED && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Connect To Instagram
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            Schedule your upcoming gigs to your Instagram page.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <hr style={{ marginTop: 0 }} />
                                    <Grid container spacing={8}>
                                        {
                                            !IS_MOBILE ?
                                            (
                                                <Grid item xs={12} md={6}>
                                                    <FacebookPageDialog2 refresh={this.loadSocial}/>
                                                </Grid>
                                            ) :
                                            (
                                                <Grid item xs={12} md={6}>
                                                    <Typography gutterBottom className="side-helper-text">
                                                        To connect your Instagram account, please use the web interface. Sign in <a href="#" onClick={() => window.open('https://get.hotgiggity.com/sign-in', '_system')}>here</a>
                                                    </Typography>
                                                </Grid>
                                            )
                                        }
                                        
                                    </Grid>
                                </Grid>
                                
                            </Fragment>
                        )}
                        {FACEBOOK_ENABLED && (
                                <Fragment>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Connect To Facebook
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Schedule your upcoming gigs to your Facebook page.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <hr style={{ marginTop: 0 }} />
                                        <Grid container spacing={8}>
                                            {
                                                !IS_MOBILE ?
                                                (
                                                    <Grid item xs={12} md={6}>
                                                        <FacebookPageDialog refresh={this.loadSocial}/>
                                                    </Grid>
                                                ) :
                                                (
                                                    <Grid item xs={12} md={6}>
                                                        <Typography gutterBottom className="side-helper-text">
                                                        To connect your Facebook account, please use the web interface. Sign in <a href="#" onClick={() => window.open('https://get.hotgiggity.com/sign-in', '_system')}>here</a>
                                                        </Typography>
                                                    </Grid>
                                                )
                                            }
                                            
                                        </Grid>
                                    </Grid>
                                   
                                </Fragment>
                            )}

                        {isLoaded  &&
                            process.env.REACT_APP_GOOGLE_CALENDAR_ACTIVATE === "true" &&  (type === 'musician' ||  type === 'venue') && (
                                <Fragment>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Connect To Google Calendar
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Two way integration between your Hot Giggity and Google Calendars. Google Calendar events will be shown in Hot Giggity as 'Unavailable'. Hot Giggity events will be shown in your Google Calendar with full event details
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <hr style={{ marginTop: 0 }} />
                                        <Grid container spacing={8}>
                                        {
                                                !IS_MOBILE ?
                                                (
                                                    <Grid item xs={12} md={12}>
                                                        <GoogleCalendarPart profileId={this.props.profileId} type={this.props.type} />
                                                    </Grid>
                                                ) :
                                                (
                                                    <Grid item xs={12} md={6}>
                                                        <Typography gutterBottom className="side-helper-text">
                                                            To connect your Google Calendar account, please use the web interface. Sign in <a href="#" onClick={() => window.open('https://get.hotgiggity.com/sign-in', '_system')}>here</a>
                                                        </Typography>
                                                    </Grid>
                                                )
                                            }
                                            
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        <Fragment>
                            <Grid item xs={12} lg={12}>
                                <hr style={{ marginTop: 0 }} />
                                <div className="action">
                                    <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                        Back
                                    </Button>
                                    <Button variant="contained" color="primary" type="submit" id="submit-social">
                                        {editProfileActiveStep === steps.length - 1 ? "Finish" : "Save/Next"}
                                    </Button>
                                </div>
                            </Grid>
                        </Fragment>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default Social;
