import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Grid, Avatar, Button, TextField, Typography, Tooltip,IconButton  } from "@material-ui/core";
import moment from "moment";
import blankProfile from "../../../img/hotgig-loading.png";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmationModal from "../../modal/ConfirmationModal.js";
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { library } from '@fortawesome/fontawesome-svg-core'

import {faTrashCan} from '@fortawesome/free-solid-svg-icons'

library.add(faTrashCan)
@inject("lineupMessageStore", "templateStore", "profileStore")
@observer
class LineupMessage extends Component {
    state = {
        lineupMessageId: 0,
        lineUpMessageDeleteModal : false
    };
    componentDidMount() {
        this.props.templateStore.showLoadingScreen();
        this.props.lineupMessageStore.setBookingId(this.props.bookingId);
        this.props.lineupMessageStore.getBookingLineupMessages(this.props.bookingId).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    }

    handleSendMesage = e => {
        this.props.templateStore.showLoadingScreen();
        this.props.lineupMessageStore.postBookingLineupMessage(this.props.bookingId).finally(()=>{
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.openSnackbar("Message sent!");
        })
    }

    handleChangeMessage = e => {
        this.props.lineupMessageStore.setMessage(e.target.value);
    };

    handleOpenModal = (id) => {
        this.setState({ lineupMessageId: id, lineUpMessageDeleteModal : true });
    }

    handleCloseModal = () => {
        this.setState({ lineupMessageId: 0, lineUpMessageDeleteModal : false });
    }

    handleDelete = () => {
        const { messages, message } = this.props.lineupMessageStore;
        this.props.lineupMessageStore.deleteLineupId(this.state.lineupMessageId).then(() =>{
            var filteredMessages = messages.filter(x=>x.id != this.state.lineupMessageId)
            this.props.lineupMessageStore.setMessages(filteredMessages);
            this.handleCloseModal()
        })

    }
    render() {
        const { messages, message } = this.props.lineupMessageStore;
        const { currentProfile } = this.props.profileStore;
        return (
            <Grid container spacing={8} style={{ backgroundColor: "#fff" }}>
                <Grid item xs={12} lg={3}>
                    <div>
                        <Typography variant="headline" component="h3" gutterBottom>
                            Lineup Messages
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Grid container>
                        {messages.map(mess => (
                            <Grid item xs={12}>
                                <div className={mess.profileId === currentProfile.id ? "chat me" : "chat"}>
                                    <div style={{width:"100%"}}>
                                            <Avatar src={`${mess.profileImageUrl === null || typeof mess.profileImageUrl === "undefined" ? blankProfile : mess.profileImageUrl}`} className="chat-avatar" />
                                       
                                    {
                                        mess.profileId === currentProfile?.id ? 
                                        <IconButton
                                                aria-label="close"
                                                style={{    position: "absolute",
                                                    color: "#525252",
                                                    right: 0,
                                                    fontSize: 15,
                                                    marginTop: "33px"}}
                                                onClick={() => this.handleOpenModal(mess.id)}
                                                >
                                         <FontAwesomeIcon icon={['fas', 'trash-can']} />  
                                        </IconButton> : null
                                        
                                    }
                                    </div>
                                    <div className="chat-message">
                                        <Tooltip
                                            title={`Time: ${moment(mess.createdAt)
                                                .local()
                                                .format("DD-MM-YYYY HH:mm:ss")}`}
                                        >
                                            <p className="chat-name">{mess.profile.firstName}</p>
                                        </Tooltip>
                                        {mess.message}
                                       
                                    </div>
                                   
                                </div>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <TextField
                                id="body"
                                label="Send Message"
                                multiline
                                rows="4"
                                margin="normal"
                                fullWidth
                                value={message}
                                onChange={this.handleChangeMessage}
                            />
                            <Button
                                type="submit"
                                variant="raised"
                                color="primary"
                                style={{ float: "right" }}
                                onClick={() => this.handleSendMesage()}
                            >
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <ConfirmationModal
                    open={this.state.lineUpMessageDeleteModal}
                    title="Delete Lineup Message"
                    message={`Are you sure to delete this message? `}
                    closeHandler={this.handleCloseModal}
                    confirmationHandler={() => this.handleDelete()}
                    declineHandler={this.handleCloseModal}
                    confirmationLabel="Delete"
                />
            </Grid>
        );
    }
}

export default LineupMessage;
