import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import "./BookingLineup.css";
import SetListForm from "../../components/setList/SetListForm";
import { Button, Grid, Typography, AppBar, Tabs, Tab, Tooltip, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DocumentModal from "./DocumentModal/DocumentModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import blankProfile from "../../img/hotgig-loading.png";
import blankBackground from "../../img/hero2.jpg";
import { BookingType, ProfileType } from "../../types/enum";
import RichTextEditor from "../richTextEditor/RichTextEditor";
import { Prompt } from "react-router-dom";
import ConfirmationModal from "../modal/ConfirmationModal";

@inject("setListStore", "bookingRequestStore", "templateStore")
@observer
class BookingNotifyClient extends Component {
    state = {
        confirmCopyFromAct: false,
        confirmDelete: false,
        tab: 0,
        loading: false,
        success: false,
        clientMessage: "",
        sendInvoice: false,
        sendContract: false,
        openInvoice: false,
        openContract: false,
        selectedInvoice: undefined,
        isEdited: true,
        showRedirectPromote: false
    };

    componentDidMount() {
        this.props.templateStore.showLoadingScreen();
        Promise.all([
            this.props.bookingRequestStore.load(this.props.bookingId),
            this.props.bookingRequestStore.getClientEmailPreview(this.props.bookingId)
        ]).then(() => {
            this.props.templateStore.hideLoadingScreen();
            const { booking } = this.props.bookingRequestStore;
            if (booking?.notifyClient) {
                this.setState({ isEdited: false })
            }
        });
    }

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    };

    handleCreateSetlist = () => {
        this.props.setListStore.addNewSetlist();
    };

    handleDelete = () => {
        this.props.setListStore.deleteCurrentSetlist().then(() => {
            this.setState({ tab: 0, confirmDelete: false });
        });
    };

    handleSubmitForm = e => {
        e.preventDefault();
        const { sendInvoice, sendContract, clientMessage } = this.state;
        this.setState({ isEdited: false });
        // submit

        var data = {
            sendInvoice: sendInvoice,
            sendContract: sendContract,
            clientMessage: clientMessage
        };
        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore.sendClientEmail(this.props.bookingId, data).then(() => {
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.openSnackbar("Client email sent");
            this.showRedirectPromoteModal()
        });
    };

    handleCopyFromAct = actId => {
        this.props.setListStore.copyFromAct(actId);
        this.setState({
            confirmCopyFromAct: false
        });
    };

    checkAmountValue = amount => {
        return parseFloat(amount.substring(1));
    }

    handleOpenCopyFromProfile = () => this.setState({ confirmCopyFromAct: true });
    handleCloseCopyFromAct = () => this.setState({ confirmCopyFromAct: false });
    handleOpenDelete = () => this.setState({ confirmDelete: true });
    handleCloseDelete = () => this.setState({ confirmDelete: false });

    handleSetMessage = (e) => {
        this.setState({ clientMessage: e });
    }

    showRedirectPromoteModal = () => {
        this.setState({
            ...this.state,
            showRedirectPromote: true
        });
    };

    hideRedirectPromoteModal = () => {
        this.setState({
            ...this.state,
            showRedirectPromote: false
        });
    };
    


    redirectPromote = () => {
        this.setState({
            ...this.state,
            showRedirectPromote: false
        });
        this.props.redirectActionPromote()
        
    }

    render() {
        const { booking, bookingClientEmail } = this.props.bookingRequestStore;
        const { actId, isAdmin } = this.props.bookingRequestStore.booking;
        const { tab, loading, clientMessage, sendInvoice, sendContract, openInvoice, openContract, selectedInvoice } = this.state;
        const { setLists, title } = this.props.setListStore;

        let isEntertainerBooking = false
        if (booking?.createdBy?.type === ProfileType.Musician.ordinal && booking?.type === BookingType.Normal.ordinal) {
            isEntertainerBooking = true
        }

        return (
            <main style={{ backgroundColor: "#fff" }}>
                <Prompt
                    when={this.state.isEdited}
                    message={() => `You have not notified the client. Are you sure you want to leave this page?`}
                />
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={5}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Notify Client
                            </Typography>
                            <Grid container spacing={8}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>From:</b> &nbsp; {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.bookingCreatorFirstName : ""}&nbsp;
                                        no-reply@hotgiggity.com
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>To:</b> &nbsp;  {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.clientFirstName : ""}&nbsp;
                                        {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.clientLastName : ""}
                                        , {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.clientEmail : ""}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>Subject:</b> &nbsp; {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.actName : "act"} Booking {bookingClientEmail && bookingClientEmail.clientEmail && bookingClientEmail.clientEmail.notifyClient ? "Update" : "Confirmation"} -&nbsp;
                                        {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.eventTitle : ""},&nbsp;
                                        {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.eventDate : ""},&nbsp;
                                        {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.eventStartTime : ""}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>Message to client:</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={10} className="gridPrivateBookingForm">
                                    {/* <TextField
                                        id="input-client-message"
                                        value={clientMessage}
                                        onChange={this.handleSetMessage}
                                        fullWidth
                                        multiline
                                        rows={10}
                                    /> */}
                                    <RichTextEditor
                                        id="input-client-message"
                                        value={clientMessage}
                                        onChange={this.handleSetMessage}
                                        hideMenu={true}
                                    />
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={sendInvoice}
                                                onChange={e => this.setState({ sendInvoice: e.target.checked })}
                                            />
                                        }
                                        labelPlacement="end"
                                        label="Send invoice(s) to client"
                                    />
                                    {!(booking?.type === BookingType.Normal.ordinal && booking?.createdBy?.type === ProfileType.Musician.ordinal) ?
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={sendContract}
                                                    onChange={e => this.setState({ sendContract: e.target.checked })}
                                                />
                                            }
                                            labelPlacement="end"
                                            label="Send contract to client"
                                        /> : null
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={7} style={{ border: 2, borderColor: "gray", borderStyle: "solid", paddingLeft: 15, paddingBottom: 15 }}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Email Preview
                            </Typography>
                            <Grid container spacing={8}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>From:</b> &nbsp;  {bookingClientEmail &&
                                            bookingClientEmail.clientEmail &&
                                            bookingClientEmail.clientEmail.bookingCreatorFirstName ?
                                            bookingClientEmail.clientEmail.bookingCreatorFirstName : ""}&nbsp;
                                        no-reply@hotgiggity.com
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>To:</b> &nbsp; {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.clientFirstName : ""}&nbsp;
                                        {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.clientLastName : ""}
                                        , {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.clientEmail : ""}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>Subject:</b> &nbsp; {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.actName : "act"} Booking {bookingClientEmail && bookingClientEmail.clientEmail && bookingClientEmail.clientEmail.notifyClient ? "Update" : "Confirmation"} -&nbsp;
                                        {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.eventTitle : ""},&nbsp;
                                        {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.eventDate : ""},&nbsp;
                                        {bookingClientEmail &&
                                            bookingClientEmail.clientEmail ?
                                            bookingClientEmail.clientEmail.eventStartTime : ""}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <center>
                                        <table align="center" border={0} cellPadding={0} cellSpacing={0} height="100%" width="100%" id="bodyTable" style={{ borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', height: '100%', margin: 0, padding: 0, width: '100%', backgroundColor: '#262626' }}>
                                            <tbody><tr>
                                                <td align="center" valign="top" id="bodyCell" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', height: '100%', margin: 0, padding: 0, width: '100%', borderTop: 0 }}>
                                                    <table border={0} cellPadding={0} cellSpacing={0} width="100%" style={{ borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                        <tbody><tr>
                                                            <td align="center" valign="top" id="templateHeader" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: '#6b6c6d', backgroundImage: `url(${bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.backgroundImageUrl : blankBackground})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: '140px', paddingBottom: '80px' }}>
                                                                <table align="center" border={0} cellSpacing={0} cellPadding={0} width={600} style={{ width: '600px' }}>
                                                                    <tbody><tr>
                                                                        <td align="center" valign="top" width={600} style={{ width: '600px' }}>
                                                                            <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" className="templateContainer" style={{ borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', maxWidth: '600px !important' }}>
                                                                                <tbody><tr>
                                                                                    <td valign="top" className="headerContainer" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                        <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnTextBlock" style={{ minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                            <tbody className="mcnTextBlockOuter">
                                                                                                <tr>
                                                                                                    <td valign="top" className="mcnTextBlockInner" style={{ paddingTop: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                                        <table align="left" border={0} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100%' }}>
                                                                                                            <tbody><tr>
                                                                                                                <td valign="top" width={600} style={{ width: '600px' }}>
                                                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{ maxWidth: '100%', minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }} width="100%" className="mcnTextContentContainer">
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td valign="top" className="mcnTextContent" style={{ padding: '0px 18px 9px', color: '#FFFFFF', fontFamily: '"Open Sans", sans-serif', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', wordBreak: 'break-word', fontSize: '18px', lineHeight: '150%', textAlign: 'left' }}>
                                                                                                                                    <img style={{ display: 'block', margin: '0 auto 0 auto', border: '0px solid #fff', boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)', width: '100px', height: '100px', borderRadius: '100px', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundColor: '#efefef', objectFit: 'cover' }} src={bookingClientEmail && bookingClientEmail.clientEmail && bookingClientEmail.clientEmail.profileImageUrl ? bookingClientEmail.clientEmail.profileImageUrl : blankProfile} />
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            </tbody></table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody></table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                            <tr>
                                                                <td align="center" valign="top" id="templateBody" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: '#ffffff', backgroundImage: 'none', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: '40px', paddingBottom: '40px' }}>
                                                                    <table align="center" border={0} cellSpacing={0} cellPadding={0} width={600} style={{ width: '600px' }}>
                                                                        <tbody><tr>
                                                                            <td align="center" valign="top" width={600} style={{ width: '600px' }}>
                                                                                <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" className="templateContainer" style={{ borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', maxWidth: '600px !important' }}>
                                                                                    <tbody><tr>
                                                                                        <td valign="top" className="bodyContainer" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnTextBlock" style={{ minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                                <tbody className="mcnTextBlockOuter">
                                                                                                    <tr>
                                                                                                        <td valign="top" className="mcnTextBlockInner" style={{ paddingTop: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                                            <table align="left" border={0} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100%' }}>
                                                                                                                <tbody><tr>
                                                                                                                    <td valign="top" width={600} style={{ width: '600px' }}>
                                                                                                                        <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{ maxWidth: '100%', minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }} width="100%" className="mcnTextContentContainer">
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td valign="top" className="mcnTextContent" style={{ paddingTop: 0, paddingRight: '18px', paddingBottom: '9px', paddingLeft: '18px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', wordBreak: 'break-word', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%', textAlign: 'center' }}>
                                                                                                                                        {/* <div style={{ textAlign: 'left' }}><span style={{ color: '#000000' }}>Hi {bookingClientEmail &&
                                                                                                                                            bookingClientEmail.clientEmail ?
                                                                                                                                            bookingClientEmail.clientEmail.clientFirstName : "client"},</span></div> */}
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }} dangerouslySetInnerHTML={{ __html: clientMessage != "" ? clientMessage : '{Messagetoclient}' }}>
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, fontWeight: 'bold', fontFamily: '"Open Sans", sans-serif', fontSize: '18px', lineHeight: '150%', color:"black", marginBottom:"20px", marginTop:"80px"}}>Event Details:</p>
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                The details are as follows:&nbsp;
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                Date: {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.eventDate : ""}
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                Times: {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.eventStartTime : ""} - {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.eventEndTime : ""}
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                Venue: {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.venueName : "-"}
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <div style={{ height: '6px' }}>&nbsp;</div>
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                Gig Fee: {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.gigFee : ""}
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        {bookingClientEmail && bookingClientEmail.clientEmail && this.checkAmountValue(bookingClientEmail.clientEmail.depositAmount) > 0 &&
                                                                                                                                            <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                                <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                    A deposit of {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.depositAmount : ""} is due on {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.depositDueDate : ""}
                                                                                                                                                </span>
                                                                                                                                            </p>
                                                                                                                                        }

                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                The balance of {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.balanceAmount : ""} is due on {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.balanceDueDate : ""}
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        {sendInvoice && <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                {bookingClientEmail && bookingClientEmail.clientEmail && this.checkAmountValue(bookingClientEmail.clientEmail.depositAmount) > 0 ? "The invoices for the deposit and balance are attached." : "The invoices for the balance are attached."}
                                                                                                                                            </span>
                                                                                                                                        </p>}
                                                                                                                                        {sendContract && <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                The terms and conditions for the booking are attached.
                                                                                                                                            </span>
                                                                                                                                        </p>}
                                                                                                                                        <div style={{ height: '6px' }}>&nbsp;</div>
                                                                                                                                        {/* <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                If you have any questions or concerns, please contact {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.bookingCreatorFirstName : "Hotgiggity user"} on {bookingClientEmail && bookingClientEmail.clientEmail ? bookingClientEmail.clientEmail.bookingCreatorEmail : ""}.
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <div style={{ height: '6px' }}>&nbsp;</div>
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                All the best for your event.
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }}>
                                                                                                                                                The Hotgiggity team.
                                                                                                                                            </span>
                                                                                                                                        </p> */}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                </tbody></table>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody></table>
                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>
                                            </tbody></table>
                                    </center>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    {sendInvoice &&
                                        bookingClientEmail.clientInvoice.map((invoice, i) =>
                                            <FormControl style={{ marginRight: 10, marginTop: 10 }}>
                                                <Input
                                                    id="insuranceFileName"
                                                    value={invoice.fileName}
                                                    startAdornment={
                                                        <InputAdornment
                                                            onClick={e => this.setState({ openInvoice: true, selectedInvoice: invoice })}
                                                            position="start"
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            <a target="_blank">
                                                                <PictureAsPdfIcon />
                                                            </a>
                                                        </InputAdornment>
                                                    }
                                                    readOnly={true}
                                                />
                                            </FormControl>
                                        )
                                    }
                                    {
                                        sendContract &&
                                        <FormControl style={{ marginTop: 10 }}>
                                            <Input
                                                id="insuranceFileName"
                                                value="Client contract"
                                                startAdornment={
                                                    <InputAdornment
                                                        onClick={e => this.setState({ openContract: true })}
                                                        position="start"
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        <a target="_blank">
                                                            <PictureAsPdfIcon />
                                                        </a>
                                                    </InputAdornment>
                                                }
                                                readOnly={true}
                                            />
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                        </div>

                        <DocumentModal
                            open={openInvoice}
                            title={" "}
                            maxWidth="lg"
                            clientInvoice={true}
                            data={selectedInvoice}
                            closeHandler={e => this.setState({ openInvoice: false })}
                        />

                        <DocumentModal
                            open={openContract}
                            title="Client Contract"
                            maxWidth="lg"
                            clientInvoice={false}
                            data={bookingClientEmail.clientContract}
                            closeHandler={e => this.setState({ openContract: false })}
                            isEnterTainer={isEntertainerBooking}
                            dataClientEmail={bookingClientEmail}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <div className="action">
                            <Button variant="raised" color="primary" onClick={this.handleSubmitForm} disabled={bookingClientEmail?.clientEmail?.clientEmail ? false : true}>
                                Send
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <ConfirmationModal
                    open={this.state.showRedirectPromote}
                    title="Post To Socials"
                    message={`Let people know about your gig by posting to your socials`}
                    closeHandler={this.hideRedirectPromoteModal}
                    confirmationHandler={this.redirectPromote}
                    declineHandler={this.hideRedirectPromoteModal}
                    confirmationLabel="Create Post"
                    declineLabel="Not now"
                />
            </main >
        );
    }
}

export default BookingNotifyClient;
