import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Button, Grid, Typography } from "@material-ui/core";
import SongListTable from "../../components/songListTable/SongListTable";

@inject("songListStore", "templateStore", "authStore", "profileStore", "setListStore")
@withRouter
@observer
class Songlist extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setListStore.setNewActSetlist(false);
    }

    handleBack = () => {
        if (this.props.type === "act") {
            this.props.history.replace(`/myact/edit/${this.props.match.params.id}/lineup`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        if (this.props.type !== "act" && this.props.type !== "venue") {
            const { editProfileActiveStep } = this.props.templateStore;
            this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
        }
        this.props.history.push(`/myact/edit/${this.props.profileId}/setlist`);
    };

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    render() {
        const { editProfileActiveStep } = this.props.templateStore;

        return (
            <div className="Profile-Songs">
                <Grid container spacing={24}>
                    <Grid item xs={12} lg={3}>
                        <div className="sticky-information">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Song List
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                Use this section to create the master song list for your act. If you have audio or video of you performing this song,
                                add it using the media url field. Select 12 tunes for your 'Top 12' which will appear on your public facing profile.
                            </Typography>
                            <Typography>
                                <b>Tip:</b>
                            </Typography>
                            <Typography className="side-helper-text">
                                As a starting point you can copy all songs from your musician profile. Alternatively you can import the songs using
                                the import function.{" "}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <SongListTable
                                    onListAdd={this.handleAddSongList}
                                    onListRemove={this.handleRemoveSong}
                                    onListSort={this.handleSortSongList}
                                    label="Song List"
                                    type="act"
                                    profileId={this.props.profileId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <form onSubmit={this.handleSubmitForm} className="form-relative">
                                    <div className="action">
                                        <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                            Back
                                        </Button>
                                        <Button variant="contained" color="primary" type="submit">
                                            Save/Next
                                        </Button>
                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Songlist;
