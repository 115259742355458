import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";

import "./PerformItem.css";

class PerformItem extends Component {
    render() {
        return (
            <Grid container className="perform-list" onClick={this.props.onClick}>
                <Grid item xs={4} className="perform-list-calendar-info">
                    <div className="perform-list-calendar-info-container">
                        <Typography className="perform-list-calendar-month">{this.props.performData.month}</Typography>
                        <Typography className="perform-list-calendar-date">{this.props.performData.date}</Typography>
                        <Typography className="perform-list-calendar-day">{this.props.performData.day}</Typography>
                    </div>
                </Grid>
                <Grid item xs={8} className="perform-list-content">
                    <Grid container style={{ height: "100%", alignItems: "center" }}>
                        {this.props.performData.venue && (
                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={2} lg={1}>
                                        <i className="fa fa-building musician-profile-live-gigs-icon" />
                                    </Grid>
                                    <Grid item xs={10} lg={11} style={{paddingTop: 5}}>
                                        <Typography style={{width: '95%'}}  >
                                            <Link to={this.props.performData.venueUrl} target={"_blank"}>{this.props.performData.venue}</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {this.props.performData.actName && (
                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={2}  lg={1}>
                                        <i className="fa fa-user musician-profile-live-gigs-icon" />
                                    </Grid>
                                    <Grid item xs={10} lg={11} style={{paddingTop: 5}}>
                                        <Typography style={{width: '95%'}} >
                                            <Link to={this.props.performData.actUrl} target={"_blank"}>{this.props.performData.actName}</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {this.props.performData.address && (
                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={2} lg={1}>
                                        <i className="fa fa-map-marker-alt musician-profile-live-gigs-icon" />
                                    </Grid>
                                    <Grid item xs={10} lg={11} style={{paddingTop: 5}}>
                                        <Typography style={{width: '95%'}}>{this.props.performData.address}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {this.props.performData.time && (
                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={2} lg={1}>
                                        <i className="fa fa-clock musician-profile-live-gigs-icon" />
                                    </Grid>
                                    <Grid item xs={10} lg={11} style={{paddingTop: 5}}>
                                        <Typography style={{width: '95%'}}>{this.props.performData.time}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default PerformItem;
