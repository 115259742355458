import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import lookupStore from "./lookupStore"
import { LineupStatusType } from "../types/enum";
import profileStore from "./profileStore";
import bookingRequestStore from "./bookingRequestStore";

class LineupStore {
    @observable openSnackbar = false;
    @observable snackbarMessage = "";
    @observable errors = undefined;
    @observable type = 0;
    @observable bookingId = undefined;
    @observable lineups = [];
    @observable instrumentList = [];

    @observable invitedEmail = "";
    @observable invitedName = "";
    @observable actInstruments = [];
    @observable invitedInstrument = null;

    @observable newPlayerLineup = undefined;
    @observable newPlayerInstruments = undefined;
    @observable errors = undefined;

    @observable isLoadingLineup = false;
    @observable lastEditedLineup = null
    @action
    addError(fieldKey, errorMessage) {
        if (!this.errors) {
            this.errors = [];
        }
        this.errors[fieldKey] = errorMessage;
    }

    @action
    removeLineup(index) {
        this.lineups.filter(x => x.type === 0 && x.status !== LineupStatusType.Removed.ordinal)[index].status = LineupStatusType.Removed.ordinal;
    }

    @action
    setNewPlayerLineup(newPlayer) {
        this.newPlayerLineup = newPlayer;
    }

    @action
    setNewPlayerInstruments(instruments) {
        this.newPlayerInstruments = instruments;
    }

    @action
    resetNewLineupData() {
        this.newPlayerLineup = undefined;
        this.newPlayerInstruments = undefined;
    }

    @action
    addNewPlayerToLineup(id) {
        this.errors = undefined;
        if (!this.newPlayerLineup) {
            this.addError('performer', "Performer is required!");
        }
        if (!this.newPlayerInstruments) {
            this.addError('instrument', "Instrument/Skill Set(s) is required!");
        }
        if (!this.errors) {
            this.lineups.push(
                {
                    profileId: this.newPlayerLineup.id,
                    profile: this.newPlayerLineup,
                    name: lookupStore.getInstrumentsName(this.newPlayerInstruments),
                    bookingId: id ? id : this.lineups[0].bookingId,
                    fee: 0,
                    type: 0,
                    rank: this.lineups.length + 1,
                    actRosterId: undefined
                }
            )
            return Promise.resolve();
        }
        return Promise.reject();
    }

    @observable additionalPlayer = undefined;

    @action
    setAdditionalPlayer(player) {
        this.additionalPlayer = player;
    }

    @action
    setInstrumentList(newList) {
        this.instrumentList = newList;
    }

    @action
    reset() {
        this.lineups = [];
        this.openSnackbar = false;
        this.snackbarMessage = "";
        this.errors = undefined;
    }

    @action
    load(bookingId) {
        this.bookingId = bookingId;
        this.isLoadingLineup = true;
        return agent.Booking.loadLineup(bookingId).then(action(async lineups => {
            if (lineups && lineups.length > 0 && lineups !== undefined) {
                var mapped = [];
                for (var i = 0; i < lineups.length; i++) {
                    var data = lineups[i]
                    data.expanded = false;
                    await profileStore.mapAdditionalProfileDataAsync(data.profile);
                    if (data.actRoster && data.actRoster.rosters) {
                        for (var j = 0; j < data.actRoster.rosters.length; j++) {
                            await profileStore.mapAdditionalProfileDataAsync(data.actRoster.rosters[j].profile);
                        }
                    }
                    data.fee = !isNaN(data.fee) ? Number(data.fee) : 0
                    mapped.push(data)
                }
                runInAction(() => {
                    this.lineups = mapped
                })
            } else {
                this.lineups = []
            }
            if (this.additionalPlayer) {
                let lineup = this.lineups.filter(x => x.profileId === this.additionalPlayer.id);
                if (lineup.length === 0) {
                    runInAction(() => {
                        this.lineups.push({
                            profileId: this.additionalPlayer.id,
                            profile: this.additionalPlayer,
                            name: "Additional Player",
                            bookingId: this.lineups[0].bookingId,
                            fee: 0,
                            type: 0,
                            rank: this.lineups.length + 1,
                            actRosterId: undefined,
                            status: LineupStatusType.New.ordinal
                        })

                    })
                } else {
                    runInAction(() => {
                        for (let i = 0; i < this.lineups.length; i++) {
                            if (this.lineups[i].profileId == this.additionalPlayer.id && this.lineups[i].status) {
                                this.lineups[i].status = LineupStatusType.New.ordinal
                                this.lineups[i].id = undefined
                            }
                        }
                    })
                }
                this.additionalPlayer = undefined;
            }
            return this.lineups;
        }))
            .finally(
                action(() => {
                    this.isLoadingLineup = false;
                })
            );
    }

    @action
    setExpand(instrument) {
        instrument.expanded = !instrument.expanded;
    }

    @action
    setPlayer(lineup, player) {
        if (lineup.status === LineupStatusType.Accepted.ordinal) {
            lineup.acceptedProfileId = lineup.profileId;
        }

        if (lineup.acceptedProfileId && lineup.acceptedProfileId === player.profileId) {
            lineup.status = LineupStatusType.Accepted.ordinal;
        } else {
            lineup.status = LineupStatusType.New.ordinal;
        }

        lineup.profile = player.profile;
        lineup.profileId = player.profileId;
    }

    @action
    submitFeeChanged(lineup) {
        return agent.Booking.saveLineupFee(lineup).then(
            action(async response => {
                this.lastEditedLineup = null
                return response;
            }),
            err => {
                return Promise.reject("Failed to update, invoice already paid");
            }
        );
    }
    @action
    submit() {
        this.errors = undefined;
        const booking = {
            id: this.bookingId,
            lineups: this.lineups
        };
        return this.saveLineups(booking);
    }

    @action
    setManualLineupFee(fee, index) {
        this.lineups.forEach(action(
            (lineup, idx) => {
                if (idx === index) {
                    lineup.manualFee = fee;
                }
            }
        ))
    }

    @action
    doneEditLineupFeeChanges(index) {
        this.lineups.forEach(action(
            (lineup, idx) => {
                if (idx === index) {
                    var newFee = lineup.manualFee || lineup.manualFee === 0 ? lineup.manualFee : lineup.fee;
                    if(newFee != lineup.fee ){
                        lineup.feeEdited = true
                    }
                    lineup.fee = newFee
                    lineup.manualFee = undefined;
                    lineup.editMode = false;
                   
                    this.lastEditedLineup = lineup
                }
            }
        ))
    }

    @action
    toggleLineupEditMode(index) {
        this.lineups.forEach(action(
            (lineup, idx) => {
                if (idx === index) {
                    lineup.editMode = !lineup.editMode;
                }
            }
        ))
    }

    @action
    saveLineups(booking) {
        return agent.Booking.saveLineups(booking).then(
            action(async lineups => {
                if (lineups && lineups.length > 0 && lineups !== undefined) {
                    var mapped = [];
                    for (var i = 0; i < lineups.length; i++) {
                        var data = lineups[i]
                        await profileStore.mapAdditionalProfileDataAsync(data.profile);
                        mapped.push(data)
                    }
                    runInAction(() => {
                        this.lineups = mapped
                    })
                } else {
                    this.lineups = []
                }
                return bookingRequestStore.load(booking.id).then(res => {
                    return this.lineups;
                })
            }),
            err => {
                return Promise.reject(err.response.text);
            }
        );
    }
}

export default new LineupStore();
