import React, { Component, Fragment } from "react";
import ResponsiveTable from "../table/ResponsiveTable";

import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";

import { Paper, Button } from "@material-ui/core";

import { ProfileType, ProfileRelationType } from "../../types/enum";

import ConfirmationModal from "../modal/ConfirmationModal";
import ActInvitationModal from "../util/ActInvitation/ActInvitationModal";
import TableRowProfile from "../util/TableRowProfile";

import ActOwnerInvitationModal from "../util/ActOwnerInvitation/ActOwnerInvitationModal";
import image from "../../helper/image";

@inject("templateStore", "venueActStore", "userInvitationStore")
@withRouter
@observer
class ActTable extends Component {
    state = {
        invitationModalState: false,
        acceptModalState: false,
        deleteModalState: false,
        deleteSingleModalState: false,
        deleteVenueActModalState: false,
        venueId: undefined,
        request: undefined,
        actOwnerInvitationModalState: false,
        deleteInvitationModalState: false,
        cancelBookingModalState: false,
        deletedInvitation: undefined
    };

    rowData = [];

    columns = [
        { key: "actName", label: "Act" },
        { key: "actOwner", label: "Act Owner" },
        { key: "actContact", label: "Act Contact" },
        { key: "status", label: "Status" },
        { key: "action", label: "" }
    ];

    constructor(props) {
        super(props);
        this.props.venueActStore.resetList();
    }

    componentDidMount() {
        this.props.templateStore.showLoadingScreen();
        Promise.all([
            this.props.venueActStore.setActiveVenueId(this.props.venueId),
            this.props.venueActStore.getVenueActRosterPending(this.props.venueId),
            this.props.venueActStore.getVenueActRoster(this.props.venueId),
            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.VenueAct.ordinal, this.props.venueId)
        ]).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    }

    mapInvitationToRowData = () => {
        this.rowData = [];
        this.props.userInvitationStore.invitations.forEach(data => {
            var row = {};

            row.actOwner = <TableRowProfile name={`${data.invitedFirstName} ${data.invitedLastName}`} />;

            row.actName = <TableRowProfile name={data.invitedAct} />;

            row.status = "Invited";

            row.action = (
                <Fragment>
                    <Button color="primary" size="small" onClick={() => this.handleOpenDeleteInvitationModal(data)}>
                        Remove
                    </Button>
                </Fragment>
            );

            row.style = {
                backgroundColor: "#EEE"
            };

            this.rowData.push(row);
        });
        return Promise.resolve();
    };

    mapPendingToRowData = () => {
        this.props.venueActStore.pendingRequest.forEach(data => {
            if (data.act) {
                var row = {}
                var act = data.act
                row.id = data.id;
                row.actName = <TableRowProfile actId = { row.id } name={act.actName} />;
    
                row.actOwner = (
                    <TableRowProfile
                        profileId = { act.myMusician ? act.myMusician.id : undefined }
                        name={act.myMusician ? act.myMusician.fullName : ""}
                    />
                );
    
                row.actContact =
                    (act.contactName ? act.contactName : "") +
                    (act.contactName && act.contactPhone ? " - " : "") +
                    (act.contactPhone ? act.contactPhone : "");
    
                row.status = "Pending";
    
                row.action = (
                    <Fragment>
                        <Button color="primary" size="small" onClick={() => this.openDeleteSingleModal(data)}>
                            Remove
                        </Button>
                    </Fragment>
                );
    
                row.style = {
                    backgroundColor: "#EEE"
                };
    
                this.rowData.push(row);
            }
        });

        return Promise.resolve();
    };

    mapAcceptedToRowData = () => {
        this.props.venueActStore.venueActRoster.forEach(data => {
            var act = data.act;

            var row = {};
            row.id = data.id;

            row.actName = <TableRowProfile actId = { act.actId } name={act.fullName} avatarUrl={image.getProfileImage(act)} />;

            row.actOwner = (
                <TableRowProfile
                    profileId = { act.myMusician ? act.myMusician.id : undefined }
                    name={act.myMusician ? act.myMusician.fullName : ""}
                    avatarUrl={image.getProfileImage(act.myMusician)}
                />
            );

            row.actContact =
                (act.contactName ? act.contactName : "") +
                (act.contactName && act.contactPhone ? " - " : "") +
                (act.contactPhone ? act.contactPhone : "");

            row.status = "Accepted";

            row.action = (
                <Fragment>
                    <Button color="primary" size="small" onClick={() => this.openDeleteVenueActModal(data)}>
                        Remove
                    </Button>
                </Fragment>
            );

            this.rowData.push(row);
        });
    };

    //event

    openInvitationModal = () => {
        this.setState({
            ...this.state,
            invitationModalState: true
        });
    };

    closeInvitationModal = () => {
        this.setState(
            {
                ...this.state,
                invitationModalState: false
            },
            () => {
                this.props.venueActStore.clearInviteData();
            }
        );
    };

    openDeleteModal = deletedRow => {
        var deletedRequest = deletedRow.filter(data => data.status === "Pending");
        var deletedRelation = deletedRow.filter(data => data.status === "Accepted");
        this.setState({
            ...this.state,
            deleteModalState: true,
            deletedRelation: deletedRelation,
            deletedRequest: deletedRequest
        });
    };

    openDeleteSingleModal = deletedRow => {
        this.setState({
            ...this.state,
            deleteSingleModalState: true,
            deletedRequest: deletedRow
        });
    };

    openDeleteVenueActModal = deletedRow => {
        this.setState({
            ...this.state,
            deleteVenueActModalState: true,
            deletedRelation: deletedRow
        });
    };

    handleOpenDeleteInvitationModal = invitation => {
        this.setState({
            ...this.state,
            deleteInvitationModalState: true,
            deletedInvitation: invitation
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            deleteModalState: false,
            deleteSingleModalState: false,
            deleteVenueActModalState: false,
            deletedRelation: undefined,
            deletedRequest: undefined,
            deleteInvitationModalState: false,
            cancelBookingModalState: false,
            deletedInvitation: undefined
        });
    };

    handleDeleteRequest = () => {
        this.props.venueActStore.removeRequest(this.state.deletedRequest.id).then(() => {
            this.props.templateStore.openSnackbar("Act has been deleted.");
            this.closeDeleteModal();
        });
    };

    handleDeleteMany = () => {
        const { deletedRequest, deletedRelation } = this.state;
        Promise.all([this.props.venueActStore.removeManyRequest(deletedRequest), this.props.venueActStore.deleteManyVenueAct(deletedRelation)]).then(
            () => {
                this.props.templateStore.openSnackbar("Acts have been deleted.");
                this.closeDeleteModal();
            }
        );
    };

    handleDeleteVenueAct = () => {
        this.setState({ cancelBookingModalState: true });
    };

    executeCancelBooking = () => {
        this.props.venueActStore.deleteVenueAct(this.state.deletedRelation.id, true).then(() => {
            this.props.templateStore.openSnackbar("Bookings Cancelled and Act has been deleted.");
            this.closeDeleteModal();
        });
    };

    executeDeleteVenuAct = () => {
        this.props.venueActStore.deleteVenueAct(this.state.deletedRelation.id).then(() => {
            this.props.templateStore.openSnackbar("Act has been deleted.");
            this.closeDeleteModal();
        });
    };

    handleDeleteInvitation = () => {
        this.props.userInvitationStore.deleteInvitation(this.state.deletedInvitation, this.props.venueId).then(() => {
            this.props.templateStore.openSnackbar(this.state.deletedInvitation.invitedAct + " has been removed.");
            this.closeDeleteModal();
        });
    };

    openActOwnerInvitationModal = value => {
        this.props.userInvitationStore.resetInvitedData();
        this.setState(
            {
                ...this.state,
                actOwnerInvitationModalState: true,
                invitationModalState: false
            },
            () => {
                this.props.userInvitationStore.setInvitedActName(value);
            }
        );
    };

    closeActOwnerInvitationModal = () => this.setState({ ...this.state, actOwnerInvitationModalState: false });

    render() {
        this.rowData = [];
        this.mapInvitationToRowData().then(this.mapPendingToRowData().then(this.mapAcceptedToRowData()));

        return (
            <Fragment>
                <Paper>
                    <ResponsiveTable
                        selectable={true}
                        showToolbar={true}
                        title="Acts"
                        handleAdd={this.openInvitationModal}
                        handleDelete={this.openDeleteModal}
                        columns={this.columns}
                        rows={this.rowData}
                    />
                </Paper>
                <ActInvitationModal
                    open={this.state.invitationModalState}
                    onClose={this.closeInvitationModal}
                    profileType="venue"
                    handleEmpty={this.openActOwnerInvitationModal}
                />

                <ActOwnerInvitationModal open={this.state.actOwnerInvitationModalState} onClose={this.closeActOwnerInvitationModal} />

                <ConfirmationModal
                    open={this.state.deleteInvitationModalState}
                    message="Are you sure to remove this act?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDeleteInvitation}
                    declineHandler={this.closeDeleteModal}
                    confirmationLabel="Remove"
                />

                <ConfirmationModal
                    open={this.state.deleteSingleModalState}
                    title="Delete Act"
                    message="Are you sure you want to delete this act?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDeleteRequest}
                    confirmationLabel="Delete"
                />

                <ConfirmationModal
                    open={this.state.deleteModalState}
                    message="Are you sure you want to delete these acts?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDeleteMany}
                    title="Delete Acts"
                    confirmationLabel="Delete"
                />

                <ConfirmationModal
                    open={this.state.deleteVenueActModalState}
                    message="Are you sure you want to delete this act?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDeleteVenueAct}
                    title="Delete Act"
                    confirmationLabel="Delete"
                />

                <ConfirmationModal
                    open={this.state.cancelBookingModalState}
                    message="Do you want to cancell all bookings related with this act?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.executeCancelBooking}
                    declineHandler={this.executeDeleteVenuAct}
                    title="Delete Act"
                    confirmationLabel="Cancel Booking and Delete Act"
                    declineLabel="Delete Act"
                    maxWidth="sm"
                />
            </Fragment>
        );
    }
}

export default ActTable;
