import { observable, action } from "mobx";
import agent from "../agent";

class LookupStore {
    @observable venueTypes = [];
    @observable instruments = [];
    @observable genres = [];

    @action
    getGenreSuggestions(){
        return agent.Genre.getAll().then(action(
            result => {
                this.genres = result;
                return this.genres;
            }
        ));
    }

    @action
    getVenueTypesSuggestion(){
        return agent.VenueType.getAll().then(action( result => {
            this.venueTypes = result;
            return this.venueTypes;
        }))
    }

    @action
    getInstruments() {
        if (this.instruments.length === 0) {
            return agent.Instrument.getAll().then(
                action(result => {
                    this.instruments = _.sortBy(result, o => o.name).map(instrument => ({
                        value: instrument.id,
                        label: instrument.name
                    }));
                })
            );
        } else {
            return Promise.resolve(this.instruments);
        }
    }

    @action
    getInstrumentsName(instruments){
        return instruments.split(",").map( instrument => {
            return this.instruments.filter(x => x.value === parseInt(instrument, 10))[0].label;
        }).join();
    }
}

export default new LookupStore();
