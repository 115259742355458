import React from "react";
import { inject, observer } from "mobx-react";
import { Button, TextField, Grid, Paper, Typography, Checkbox, CircularProgress } from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";

import logo from "../img/hotgig-logo.png";
import facebook from "../img/facebook-box.svg";
import FacebookLogin from "react-facebook-login";
import FooterLogin from "./footer/FooterLogin";

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const EMAIL_CONFIRMATION_REQUIRED = process.env.REACT_APP_EMAIL_CONFIRMATION_REQUIRED === "true";

@inject("authStore", "facebookStore", "templateStore")
@withRouter
@observer
class Reregistration extends React.Component {
    params = undefined;

    constructor(props) {
        super(props);
        this.state = {
            defaultPasswordHelp: "The Password field need to have at least one number, one capital letter, and at least have 8 characters",
            policy: (
                <span>
                    By signing up, you agree to the <Link to="/tos">Terms of Service</Link> and <Link to="/policy">Privacy Policy</Link>
                </span>
            ),
            source: undefined,
            params: undefined,
            email: "",
            password: "",
            checked: false,
            subscribeEmail: '',
            as: undefined,
            isValid: true,
            isRegistered: false,
            isLoading: false
        };
    }

    componentDidMount() {
        this.props.authStore.reset();
        if (this.props.location && this.props.location.search) {
            this.params = _.chain(this.props.location.search)
                .replace("?", "") // a=b454&c=dhjjh&f=g6hksdfjlksd
                .split("&") // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
                .map(_.ary(_.partial(_.split, _, "="), 1)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
                .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
                .value();

            //load init data
            this.setState({isLoading: true})
            this.props.authStore.getMappingUserDataInit(this.params && this.params.invitationCode ? this.params.invitationCode : undefine).then(response => {
                this.setState({isLoading: false})
                if (response.isRegistered) {
                    this.setState({
                        ...this.state,
                        isValid: false,
                        isRegistered: true
                    });
                    return;
                }
                
                this.props.authStore.setFirstname(response.user.firstName);
                this.props.authStore.setLastname(response.user.lastName)
                this.props.authStore.setEmail(response.user.email);
                if (response.profile && response.profile.length > 0) {

                    let typeCon = ""
                    for (let data of response.profile) {
                        typeCon = typeCon + " / " + this.props.templateStore.getTypeString(data.type)
                    }

                    typeCon = typeCon.substring(2, typeCon.length)
                    this.props.authStore.setAccountType(typeCon);

                }
                
            }).catch(e => {
                this.setState({
                    ...this.state,
                    isValid: false
                });
                this.setState({isLoading: false})
            })
            if (this.params.source) {
                this.setState({
                    ...this.state,
                    source: this.params.source
                });
            }
            if (this.params.as) {
                this.setState({
                    ...this.state,
                    as: this.params.as
                });
            }
        }
    }

    responseFacebook(response) {
        this.props.facebookStore.login(response.accessToken).then(user => {
            if (user && user.status === 50) {
                this.props.history.replace("/staff/edit/new");
            } else if (this.params) {
                //auto create musician profile
                if (this.params.as) {
                    this.props.history.replace(`/welcome/${this.params.as}/${user.profileId}`);
                } else {
                    this.props.history.replace("/welcome/musician");
                }
            } else {
                this.props.history.replace("/welcome");
            }
        });
    }

    componentWillUnmount() {
        this.props.authStore.reset();
    }

    handleEmailChange = e => {
        this.props.authStore.setEmail(e.target.value);
    };
    handleFirstnameChange = e => this.props.authStore.setFirstname(e.target.value);
    handleLastnameChange = e => this.props.authStore.setLastname(e.target.value);
    handlePasswordChange = e => this.props.authStore.setPassword(e.target.value);
    handleConfirmPasswordChange = e => this.props.authStore.setConfirmPassword(e.target.value);
    handleAccountType = e => this.props.authStore.setAccountType(e.target.value);

    handleSubmitForm = async e => {
        e.preventDefault();
        const { source, as } = this.state;
        
        
        this.props.authStore.validate();
        this.setState({isLoading: true})
        this.props.authStore.reregister(this.params ? this.params.invitationCode : undefined).then(user => {
            this.setState({isLoading: false})
            localStorage.setItem("email-confirmation", this.props.authStore.values.email)
            this.props.history.push(`/confirmation`);
        })
        .finally(() => {
            this.setState({isLoading: false})
        });

        // if (user && typeof user.status!==undefined && user.status === 50) {
        //     this.props.history.push("/staff/edit/new");
        // } else if (this.params && user.isInvited) {
        //     //auto create musician profile
        //     if (this.params.as) {
        //         this.props.history.replace(`/welcome/${this.params.as}/${user.profileId}`);
        //     } else {
        //         this.props.history.replace("/welcome/musician");
        //     }
        // } else if (source) {
        //     this.props.history.replace("/welcome/musician");
        // } else {
        //     if (EMAIL_CONFIRMATION_REQUIRED) {
        //         this.props.history.push("/confirmation");
        //     } else {
        //         this.props.history.push("/welcome");
        //     }
        // }
    };

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        
        if (field === "password" && this.state.defaultPasswordHelp) {
            this.setState({ defaultPasswordHelp: "" });
        }
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field].replace(/([a-z](?=[A-Z]))/g, "$1 ");
    }

    handleCheckBox = (e) => {
        this.setState({ checked: e.target.checked })
    }

    handleTextCheckBox = () => {
        this.setState({ checked: !this.state.checked })
    }

    onChangeSubsCribe = (e) => {
        this.setState({ subscribeEmail: e.target.value })
    }

    handleSubscribe = (val) => {
        console.log('Subscribe Email:', this.state.subscribeEmail)
    }

    render() {
        const { values } = this.props.authStore;
        const params = Object(this.params); // Get Value from params
        // const signUpLink = this.state.source ? `/register?source=${this.state.source}` : "/register";
        // const signInLink = this.state.source ? `/login?source=${this.state.source}` : "/login";

        const agreementText = () => (
            <span>
                I agree to <Link className="link-tos" to="/policy">Privacy Policy</Link> and <Link className="link-tos" to="/tos">Terms {'&'} conditions</Link>
            </span>
        )

        return (
            <div style={{ display: "flex", flex: 1, flexDirection: 'column', zIndex: 40 }}>
                <div className="Login">
                    <div className='image-background' />
                    <img src={logo} alt="HotGiggity" className='header-logo' />
                    <Grid container spacing={24} className="Login-Grid">
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid container direction='row' className="logo-login" alignItems='center' justify='center' alignContent='center' >
                                <h2 className="logo-login-title"><b>Hubdit to</b></h2>
                                <img style={{ width: 'auto' }} src={logo} alt="HotGiggity" />
                            </Grid>
                        </Grid>

                        {this.state.isValid ?
                            <Paper className="Form">
                                 {this.state.isLoading ? 
                                             <form onSubmit={this.handleSubmitForm} className="Submit-form">
                                                <div style={{ alignItems: 'center', justifyContent: 'center', display: "flex", marginTop: 30 }}>
                                                    <CircularProgress /> 
                                                </div>
                                             </form> : 
                                               <form onSubmit={this.handleSubmitForm} className="Submit-form">

                                               <p className="signup-desc">
                                                   As part of the rebrand from Hubdit to Hot Giggity, we have upgraded our user authentication infrastructure and security.  This means that in order to access your account, you are required to enter a new password and authenticate your email address. 
                                               </p>
                                               <p className="signup-desc">
                                                   Please check that the following account details are correct before proceeding:
                                               </p>
           
                                               <p className="signup-text-field"> <b>Name</b> : {values.firstName} {values.lastName}</p>
                                               <p className="signup-text-field"> <b>Profile Type(s)</b> : {values.type}</p>
           
                                               {/* <p className="signup-desc">
                                                   To access your account, simply enter a password below.
                                               </p> */}
           
                                               <p className="signup-desc">
                                                   If this information is incorrect, or if you have any issues accessing your account, please contact support@hotgiggity.com or message us on Facebook.
                                               </p>
           
                                               {
                                                   this.anyError("general") &&
                                                   <div
                                                       style={{
                                                           display: 'flex',
                                                           width: '100%',
                                                           color: "#f44336",
                                                           alignItems: 'center',
                                                           justifyContent: 'center',
                                                           // paddingLeft: 5,
                                                           // paddingBottom: 10,
                                                           // paddingTop: 10,
                                                       }}>{this.errorText("general")}</div>
                                               }
                                               <TextField
                                                   id=""
                                                   type="text"
                                                   placeholder="Email"
                                                   margin="normal"
                                                   className="inputTextContainer"
                                                   InputProps={{ className: "inputTextField" }}
                                                   fullWidth
                                                   value={values.email}
                                                   onChange={this.handleEmailChange}
                                                   error={this.anyError("email")}
                                                   helperText={this.errorText("email")}
                                                   autoComplete="off"
                                               />
                                               <TextField
                                                   id=""
                                                   style={{display:"none"}}
                                               />
                                               <TextField
                                                   id="password"
                                                   placeholder="Create a Password"
                                                   type="password"
                                                   autoComplete="current-password"
                                                   margin="normal"
                                                   className="inputTextContainer"
                                                   InputProps={{ className: "inputTextField" }}
                                                   fullWidth
                                                   value={values.password}
                                                   onChange={this.handlePasswordChange}
                                                   error={this.anyError("password")}
                                                   helperText={this.errorText("password")}
                                               />
                                               <TextField
                                                   id="confirmPassword"
                                                   placeholder="Confirm Password"
                                                   type="password"
                                                   autoComplete="current-password"
                                                   margin="normal"
                                                   className="inputTextContainer"
                                                   InputProps={{ className: "inputTextField" }}
                                                   fullWidth
                                                   value={values.confirmPassword}
                                                   onChange={this.handleConfirmPasswordChange}
                                                   error={this.anyError("confirmPassword")}
                                                   helperText={this.errorText("confirmPassword")}
                                               />
           
                                               <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: -10, }}>
                                                   <Checkbox checked={this.state.checked} onChange={this.handleCheckBox} style={{ color: 'white', alignSelf: 'center', }} />
                                                   <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }} >
                                                       <p onClick={this.handleTextCheckBox} className='agreement-text' >{agreementText()}</p>
                                                   </div>
                                               </div>
                                               <div style={{ alignItems: 'center', justifyContent: 'center', display: "flex", marginTop: 30 }}>
                                                   {this.state.isLoading ? 
                                                       <CircularProgress /> :
                                                       <Button disabled={this.state.checked ? false : true} className="btnSubmit" size="large" variant="contained" color="primary" id="signup-btn" type="submit" style={{fontSize:18, padding:5}}>
                                                           Update My Password
                                                       </Button>
                                                   }
                                               </div>
                                           </form>
                                       
                                        }
                               
                            </Paper>
                            : null
                        }

                        {!this.state.isValid ?
                            <Paper className="Form">
                            <form onSubmit={this.handleSubmitForm} className="Submit-form">
                                <p className="signup-desc" style={{ textAlign: "center" }}>
                                    {this.state.isRegistered ? "You already registered." : "Sorry, we can't find your account data. Please check again."}
                                </p>
                            </form>
                            </Paper>
                            : null
                        }
                        {/* // Forgot password Component 
                        <LinkContainer to="/forgotpassword">
                            <a className="btnForget">Forgot Password?</a>
                        </LinkContainer> */}
                    </Grid>
                </div>
                <div style={{ display: 'flex', position: 'relative', width: '100vw', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    <FooterLogin subscribe={this.state.subscribeEmail} onChangeSubscribe={this.onChangeSubsCribe} onSubscribe={this.handleSubscribe} />
                </div>
            </div >
        );
    }
}

export default Reregistration;
