import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import profileStore from "./profileStore";
import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";
import { Storage } from "aws-amplify";
import imageHelper from "../helper/image";
import CacheHelper from "../helper/cache";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class DesignProfileStore {
    @observable inProgress = false;
    @observable profileImgInProgress = false;
    @observable backgroundImgInProgress = false;
    @observable playedAtInProgress = false;
    @observable playedAtSuccess = false;
    @observable playedWithInProgress = false;
    @observable playedWithSuccess = false;
    @observable 
    errors = {
        musicUrl: "",
        videoUrl: ""
    };
    @observable id = undefined;
    @observable userId = 0;
    @observable maxBackgroundImagesCount = 4;

    @observable videoUrl = "";
    @observable musicUrl = "";
    @observable songList = [];
    @observable testimonials = [];
    @observable profileImage = undefined;
    @observable backgroundImages = [];
    @observable playedAts = [];
    @observable playedWiths = [];
    @observable tradingHours = [];
    @observable showPlayedWith = false;
    @observable showAvatar = false;
    @observable type = 0; // 1 == musician

    @observable vanityUrl = "";
    @observable isConsultant = false;

    @observable webSite = "";
    @observable facebook = "";
    @observable twitter = "";
    @observable bandcamp = "";
    @observable soundcloud = "";
    @observable mixcloud = "";
    @observable youtube = "";
    @observable instagram = "";
    @observable googlePlus = "";
    @observable linkedIn = "";
    @observable iTunes = "";
    @observable spotify = "";

    
    @action
    async mapProfileToVariable(profile) {
        this.videoUrl = profile.videoUrl || "";
        this.musicUrl = profile.musicUrl || "";
        this.songList = profile.songList;
        this.testimonials = profile.testimonials || [];
        this.type = profile.type;

        this.playedAts = profile.playedAts;
        this.playedWiths = profile.playedWiths;
        this.tradingHours = (profile.tradingHours && JSON.parse(profile.tradingHours)) || [];
        this.showPlayedWith = profile.showPlayedWith;
        this.showAvatar = profile.showAvatar;
        this.vanityUrl = profile.vanityUrl || "";
        this.id = profile.id;

        this.webSite = profile.webSite || "";
        this.facebook = profile.facebook || "";
        this.twitter = profile.twitter || "";
        this.bandcamp = profile.bandCamp || "";
        this.soundcloud = profile.soundCloud || "";
        this.mixcloud = profile.mixCloud || "";
        this.youtube = profile.youtube || "";
        this.instagram = profile.instagram || "";
        this.googlePlus = profile.googlePlus || "";
        this.linkedIn = profile.linkedIn || "";
        this.iTunes = profile.itunes || "";
        this.spotify = profile.spotify || "";
        this.id = profile.id;
        this.type = profile.type;
        // if (profile.profileImage) {
        //     profile.profileImage.url = `${API_ROOT}/image/${profile.profileImage.id}`;
        // }

        // if (profile.backgroundImages && profile.backgroundImages.length > 0) {
        //     profile.backgroundImages.forEach(b => {
        //         b.url = `${API_ROOT}/image/background/${b.id}`;
        //     });
        // } else {
        //     profile.backgroundImages = undefined;
        // }

        this.profileImage = profile.profileImage;
        this.backgroundImages = profile.backgroundImages || [];
        this.isConsultant = profile.isConsultant || false;

        if(!window.location.pathname.includes("myact") && !window.location.pathname.includes("venues")){

            profileStore.setCurrentProfileImage(this.profileImage);

        }
    }

    @action
    loadInitialData(profileId) {
        this.errors = {};
        const { currentUser } = userStore;
        const { id, actNameTitle, type } = generalProfileStore;
        const {currentProfile} = profileStore
        // if(!profileId) {
        //     if(id) profileId = id
        //     else profileId = currentUserData.id
        // }
        profileId = profileId ? profileId : currentProfile ? currentProfile.id : id

        if (currentUser) {
            this.inProgress = true;
            // templateStore.showLoadingScreen();
            if ((this.type === ProfileType.Act.ordinal || this.type === ProfileType.Venue.ordinal || this.type === ProfileType.Agent.ordinal) && profileId) {
                return profileStore
                    .getProfile(profileId)
                    .then(
                        action(async profile => {
                            if (!profile) throw new Error("Can't load profile");
                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }
                            if (profile.profileImage !== null && typeof profile.profileImage.url === "undefined") {
                                profile.profileImage.url200 = await CacheHelper.getImgLink(profile.profileImage.url200);
                                profile.profileImage.url = await CacheHelper.getImgLink(profile.profileImage.urlOriginal);
                                profile.profileImage.url400 = await CacheHelper.getImgLink(profile.profileImage.url400);
                            }
                            if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                                for (var j = 0; j < profile.backgroundImages.length; j++) {
                                    if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                                        profile.backgroundImages[j].url = await CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal);
                                        profile.backgroundImages[j].url960 = await CacheHelper.getImgLink(profile.backgroundImages[j].url960);
                                    }
                                }
                            } else {
                                profile.backgroundImages = undefined;
                            }
                            await this.mapProfileToVariable(profile);
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            } else if (this.type !== ProfileType.Act.ordinal && this.type !== ProfileType.Venue.ordinal) {
                return (
                    this.loadProfile(profileId, currentUser.sub, this.type)
                        //    .loadProfile(currentUser.id, this.type)
                        .then(
                            action(async profile => {
                                if (!profile) {
                                    this.reset();
                                    throw new Error("Can't load profile");
                                }
                                if (!profileStore.isCreateVenue) {
                                    profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                                }
                                if (profile.profileImage !== null && typeof profile.profileImage.url === "undefined") {
                                    profile.profileImage.url200 =  !profile.profileImage.url200.startsWith("http") ? await CacheHelper.getImgLink(profile.profileImage.url200) : profile.profileImage.url200;
                                    profile.profileImage.url = await CacheHelper.getImgLink(profile.profileImage.urlOriginal);
                                    profile.profileImage.url400 = !profile.profileImage.url400.startsWith("http") ? await CacheHelper.getImgLink(profile.profileImage.url400) : profile.profileImage.url400;
                                }
                                if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                                    for (var j = 0; j < profile.backgroundImages.length; j++) {
                                        if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                                            profile.backgroundImages[j].url = await CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal);
                                            profile.backgroundImages[j].url960 = await CacheHelper.getImgLink(profile.backgroundImages[j].url960);
                                        }
                                    }
                                } else {
                                    profile.backgroundImages = undefined;
                                }
                                await this.mapProfileToVariable(profile);
                            })
                        )
                        .finally(
                            action(() => {
                                this.inProgress = false;
                                templateStore.hideLoadingScreen();
                            })
                        )
                );
            } else {
                templateStore.hideLoadingScreen();
                return Promise.resolve();
            }
        }
    }

    @action
    reset() {
        this.errors = {};
        this.videoUrl = "";
        this.musicUrl = "";
        this.songList = [];
        this.testimonials = [];
        this.profileImage = undefined;
        this.backgroundImages = [];
        this.playedAts = [];
        this.playedWiths = [];
        this.tradingHours = [];
        this.showPlayedWith = false;
        this.showAvatar = false;
        this.vanityUrl = "";
        this.webSite = "";
        this.facebook = "";
        this.twitter = "";
        this.bandcamp = "";
        this.soundcloud = "";
        this.mixcloud = "";
        this.youtube = "";
        this.instagram = "";
        this.googlePlus = "";
        this.linkedIn = "";
        this.iTunes = "";
        this.spotify = "";
    }

    @action
    resetErrors() {
        this.errors = {
            musicUrl: "",
            videoUrl: "",
            webSite : "",
            facebook : "",
            twitter : "",
            bandcamp : "",
            soundcloud : "",
            mixcloud : "",
            youtube : "",
            instagram : "",
            googlePlus : "",
            linkedIn : "",
            iTunes : "",
            spotify : "",
        }
    }

    @action
    setWebSite(webSite) {
        this.webSite = webSite;
    }

    @action
    validateWebSite(website){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.webSite.length === 0) return this.errors.webSite = ""
        if(!validate.urlValidation(this.webSite)) this.errors.webSite = "Invalid URL"
        else{
            this.webSite = validate.linkify(this.webSite)
            this.errors.webSite = ""
        } 
    }

    @action
    setFacebook(facebook) {
        this.facebook = facebook;
    }

    @action
    validateFacebook(facebook){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.facebook.length === 0) return this.errors.facebook = ""
        if(!validate.urlValidation(this.facebook)) this.errors.facebook = "Invalid URL"
        else{
            this.facebook = validate.linkify(this.facebook)
            this.errors.facebook = ""
        } 
    }

    @action
    setTwitter(twitter) {
        this.twitter = twitter;
    }

    @action
    validateTwitter(twitter){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.twitter.length === 0) return this.errors.twitter = ""
        if(!validate.urlValidation(this.twitter)) this.errors.twitter = "Invalid URL"
        else{
            this.twitter = validate.linkify(this.twitter)
            this.errors.twitter = ""
        } 
    }

    @action
    setBandcamp(bandcamp) {
        this.bandcamp = bandcamp;
    }

    @action
    validateBandcamp(bandcamp){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.bandcamp.length === 0) return this.errors.bandcamp = ""
        if(!validate.urlValidation(this.bandcamp)) this.errors.bandcamp = "Invalid URL"
        else{
            this.bandcamp = validate.linkify(this.bandcamp)
            this.errors.bandcamp = ""
        } 
    }

    @action
    setSoundcloud(soundcloud) {
        this.soundcloud = soundcloud;
    }

    @action
    validateSoundcloud(soundcloud){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.soundcloud.length === 0) return this.errors.soundcloud = ""
        if(!validate.urlValidation(this.soundcloud)) this.errors.soundcloud = "Invalid URL"
        else{
            this.soundcloud = validate.linkify(this.soundcloud)
            this.errors.soundcloud = ""
        } 
    }

    @action
    setMixcloud(mixcloud) {
        this.mixcloud = mixcloud;
    }

    @action
    validateMixcloud(mixcloud){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.mixcloud.length === 0) return this.errors.mixcloud = ""
        if(!validate.urlValidation(this.mixcloud)) this.errors.mixcloud = "Invalid URL"
        else{
            this.mixcloud = validate.linkify(this.mixcloud)
            this.errors.mixcloud = ""
        } 
    }

    @action
    setYoutube(youtube) {
        this.youtube = youtube;
    }

    @action
    validateYoutube(youtube){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.youtube.length === 0) return this.errors.youtube = ""
        if(!validate.urlValidation(this.youtube)) this.errors.youtube = "Invalid URL"
        else{
            this.youtube = validate.linkify(this.youtube)
            this.errors.youtube = ""
        } 
    }

    @action
    setInstagram(instagram) {
        this.instagram = instagram;
    }

    @action
    validateInstagram(instagram){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.instagram.length === 0) return this.errors.instagram = ""
        if(!validate.urlValidation(this.instagram)) this.errors.instagram = "Invalid URL"
        else{
            this.instagram = validate.linkify(this.instagram)
            this.errors.instagram = ""
        } 
    }

    @action
    setGooglePlus(googlePlus) {
        this.googlePlus = googlePlus;
    }

    @action
    validateGooglePlus(googlePlus){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.googlePlus.length === 0) return this.errors.googlePlus = ""
        if(!validate.urlValidation(this.googlePlus)) this.errors.googlePlus = "Invalid URL"
        else{
            this.googlePlus = validate.linkify(this.googlePlus)
            this.errors.googlePlus = ""
        } 
    }

    @action
    setLinkedIn(linkedIn) {
        this.linkedIn = linkedIn;
    }

    @action
    validateLinkedin(linkedIn){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.linkedIn.length === 0) return this.errors.linkedIn = ""
        if(!validate.urlValidation(this.linkedIn)) this.errors.linkedIn = "Invalid URL"
        else{
            this.linkedIn = validate.linkify(this.linkedIn)
            this.errors.linkedIn = ""
        } 
    }

    @action
    setITunes(iTunes) {
        this.iTunes = iTunes;
    }

    @action
    validateItunes(iTunes){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.iTunes.length === 0) return this.errors.iTunes = ""
        if(!validate.urlValidation(this.iTunes)) this.errors.iTunes = "Invalid URL"
        else{
            this.iTunes = validate.linkify(this.iTunes)
            this.errors.iTunes = ""
        } 
    }

    @action
    setSpotify(spotify) {
        this.spotify = spotify;
    }

    @action
    validateSpotify(spotify){
        if(this.errors === undefined || this.errors === "undefined") this.resetErrors()
        if(this.spotify.length === 0) return this.errors.spotify = ""
        if(!validate.urlValidation(this.spotify)) this.errors.spotify = "Invalid URL"
        else{
            this.spotify = validate.linkify(this.spotify)
            this.errors.spotify = ""
        } 
    }

    @action
    setVideoUrl(videoUrl) {
        this.videoUrl = videoUrl;
        this.activeValidateVideo = false
        if(this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors["videoUrl"] = "";
    }

    @action
    setMusicUrl(musicUrl) {
        this.musicUrl = musicUrl;
        if(this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors["musicUrl"] = "";
    }

    @action
    setSongList(songList) {
        this.songList.unshift(songList);
    }

    @action
    setTestimonialContent(index, content) {
        this.testimonials[index].content = content;
    }

    @action
    setTestimonialName(index, name) {
        this.testimonials[index].name = name;
    }

    @action
    addTestimonial(testimonial) {
        this.testimonials.push(testimonial);
    }

    @action
    removeTestimonial(index) {
        this.testimonials.splice(index, 1);
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    setPlayedAts(playedAts) {
        this.playedAts = playedAts;
    }

    @action
    setPlayedWiths(playedWiths) {
        this.playedWiths = playedWiths;
    }

    @action
    setTradingHours(tradingHours) {
        this.tradingHours = tradingHours;
    }

    @action
    setShowPlayedWith(showPlayedWith) {
        this.showPlayedWith = showPlayedWith;
    }

    @action
    setShowAvatar(showAvatar) {
        this.showAvatar = showAvatar;
    }

    @action
    async setProfilePictureVenue(idProfile, profileImage) {
        var avatars = [];

        profileImage.forEach(x => {
            this.profileImage = x;
            this.profileImage.url = x.preview;
        });
        //upload the files here
        this.profileImgInProgress = true;
        for (var i = 0; i < profileImage.length; i++) {
            var image = profileImage[i];
            const fileExtension = image.name.split('.').pop();
            var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
            var name = `${ticks}.${fileExtension}`;
            var result = await this.uploadOriginalProfilePicture(idProfile, image, name);
            var result200 = await this.upload200ProfilePicture(idProfile, image, name);
            var result400 = await this.upload400ProfilePicture(idProfile, image, name);
            var avatar = {
                urlOriginal: result.key,
                url200: result200.key,
                url400: result400.key,
                fileName: name,
                mime: image.type
            };
            avatars.push(avatar);
        }
        var result = await agent.Image.uploadProfilePicture(idProfile, avatars).then(
            action(async result => {
                this.profileImage.id = result[0].id;

                this.profileImage.url400 = await CacheHelper.getImgLink(result[0].url400);
                this.profileImage.url200 = await CacheHelper.getImgLink(result[0].url200);

                result[0].url200 = await CacheHelper.getImgLink(result[0].url200);
                result[0].url400 = await CacheHelper.getImgLink(result[0].url400);


            })
        )
        .catch(
            action(err => {
                this.profileImage = undefined;
                return "error";
            })
        )
            .finally(
                action(() => {
                    this.profileImgInProgress = false;
                })
            );
           
    }

    @action
    async setProfilePicture(profileImage) {
        var avatars = [];

        profileImage.forEach(x => {
            this.profileImage = x;
            this.profileImage.url = x.preview;
        });

        //upload the files here
        this.profileImgInProgress = true;
        for (var i = 0; i < profileImage.length; i++) {
            var image = profileImage[i];
            const fileExtension = image.name.split('.').pop();
            var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
            var name = `${ticks}.${fileExtension}`;
            var result = await this.uploadOriginalProfilePicture(this.id, image, name);
            var result200 = await this.upload200ProfilePicture(this.id, image, name);
            var result400 = await this.upload400ProfilePicture(this.id, image, name);
            var avatar = {
                urlOriginal: result.key,
                url200: result200.key,
                url400: result400.key,
                fileName: name,
                mime: image.type
            };
            avatars.push(avatar);
        }
        var result = await agent.Image.uploadProfilePicture(this.id, avatars).then(
            action(async result => {
                this.profileImage.id = result[0].id;

                this.profileImage.url400 = await CacheHelper.getImgLink(result[0].url400);
                this.profileImage.url200 = await CacheHelper.getImgLink(result[0].url200);

                result[0].url200 = await CacheHelper.getImgLink(result[0].url200);
                result[0].url400 = await CacheHelper.getImgLink(result[0].url400);

                if(!window.location.pathname.includes("myact") && !window.location.pathname.includes("venues")){
                    profileStore.setCurrentProfileImage(result[0]);
                }

            })
            
        )
            .finally(
                action(() => {
                    this.profileImgInProgress = false;
                })
            )
            .catch(
                action(err => {
                    console.log(err)
                    this.profileImage = undefined;
                    return "error";
                })
            );
    }

    async uploadOriginalProfilePicture(id, profileImage, name) {
        try {
            const result = await Storage.put(
                `avatar/original/${id}/${name}`,
                profileImage
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }

    async upload200ProfilePicture(id, profileImage, name) {
        try {
            // Read the image
            const imgSrc = await imageHelper.readFileAsync(profileImage);
            const img = await imageHelper.loadImgAsync(imgSrc)
            // From the image and a canvas (for the resize), 
            // generate a blob to be uploaded
            const canvas = imageHelper.createCanvas(img, 200);
            const imgBlob = await imageHelper.imgToBlobAsync(img, canvas)

            // Create a file from the blob
            const fileMain = new File([imgBlob], `${name}`, {
                type: 'image/*',
                lastModified: Date.now()
            });
            const result = await Storage.put(
                `avatar/200/${id}/${name}`,
                fileMain
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }
    async upload400ProfilePicture(id, profileImage, name) {
        try {
            // Read the image
            const imgSrc = await imageHelper.readFileAsync(profileImage);
            const img = await imageHelper.loadImgAsync(imgSrc)
            // From the image and a canvas (for the resize), 
            // generate a blob to be uploaded
            const canvas = imageHelper.createCanvas(img, 400);
            const imgBlob = await imageHelper.imgToBlobAsync(img, canvas)

            // Create a file from the blob
            const fileMain = new File([imgBlob], `${name}`, {
                type: 'image/*',
                lastModified: Date.now()
            });
            const result = await Storage.put(
                `avatar/400/${id}/${name}`,
                fileMain
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }

    @action
    setVanityUrl(value) {
        this.errors = {};
        this.vanityUrl = value;
    }

    @action
    checkVanityUrl() {
        const { id } = generalProfileStore;

        if (this.vanityUrl.length < 5) {
            this.errors = [];
            this.errors["vanityUrl"] = "Profile Url is too short. Minimal 5 characters.";
            return new Promise((resolve, reject) => {
                resolve(false);
            });
        }

        return agent.Profile.checkVanityUrl(this.vanityUrl, id).then(
            action(response => {
                if (!response) {
                    this.errors = [];
                    this.errors["vanityUrl"] = "That Profile URL is already in use. Please try another URL";
                    return false;
                } else {
                    this.errors = {};
                    return true;
                }
            })
        );
    }

    @action
    removeProfilePicture(profileImage) {
        if (this.profileImage && this.profileImage.id) {
            this.profileImgInProgress = true;

            return agent.Image.deleteProfilePicture(this.profileImage.id)
                .then(
                    action(() => {
                        this.profileImage = undefined;
                    })
                )
                .finally(
                    action(() => {
                        this.profileImgInProgress = false;
                    })
                );
        }
    }

    @action
    async setBackgroundImage(backgroundImages) {
        let totalBackgroundImagesAfterAdd = this.backgroundImages.length + backgroundImages.length;
        if (totalBackgroundImagesAfterAdd > this.maxBackgroundImagesCount) {
            let removedBackGroundImagesCount = totalBackgroundImagesAfterAdd - this.maxBackgroundImagesCount;
            backgroundImages.splice(backgroundImages.length - removedBackGroundImagesCount, removedBackGroundImagesCount);
        }

        backgroundImages.forEach(backgroundImage => {
            backgroundImage.url = backgroundImage.preview;
            this.backgroundImages.push(backgroundImage);
        });

        //upload the files here
        this.backgroundImgInProgress = true;
        var covers = [];

        for (var i = 0; i < backgroundImages.length; i++) {
            var image = backgroundImages[i];
            const fileExtension = image.name.split('.').pop();
            var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
            var name = `${ticks}.${fileExtension}`;
            var result = await this.uploadOriginalBackgroundPicture(this.id, image, name);
            var result1920 = await this.upload1920BackgroundPicture(this.id, image, name);
            var result960 = await this.upload960BackgroundPicture(this.id, image, name);
            var cover = {
                urlOriginal: result.key,
                url1920: result1920.key,
                url960: result960.key,
                fileName: name,
                mime: image.type,
                previousName: image.name
            };
            covers.push(cover);
        }
        var result = await agent.Image.uploadBackgroundPicture(this.id, covers).then(
            action (async ({ count, images }) => {
                let self = this;
                let failedUpload = [];
                // this.profileImage.url400 = await Storage.get(result[0].url400);
                let startIndex = self.backgroundImages.findIndex(x => x.name == images[0].previousName);
                for (let i = startIndex, y = 0; i < self.backgroundImages.length && y < images.length; i++, y++) {
                    if (images[y].id > 0) {
                        self.backgroundImages[i].id = images[y].id;
                        self.backgroundImages[i].url = await CacheHelper.getImgLink(images[y].urlOriginal);
                    } else {
                        self.backgroundImages.splice(i, 1);
                        failedUpload.push(images[y].fileName);
                        i--;
                    }
                }
                return failedUpload;
            })
        )
            .finally(
                action(() => {
                    this.backgroundImgInProgress = false;
                })
            )

        // return agent.Image.uploadBackgroundPicture(this.id, backgroundImages)
        //     .then(
        //         action(({ count, images }) => {
        //             let self = this;
        //             let failedUpload = [];

        //             let startIndex = self.backgroundImages.length - count;
        //             for (let i = startIndex, y = 0; i < self.backgroundImages.length; i++, y++) {
        //                 if (images[y].id > 0) {
        //                     //if background image save success
        //                     self.backgroundImages[i] = images[y];
        //                     self.backgroundImages[i].url = `${API_ROOT}/image/background/${self.backgroundImages[i].id}`;
        //                 } else {
        //                     self.backgroundImages.splice(i, 1);
        //                     failedUpload.push(images[y].fileName);
        //                     i--;
        //                 }
        //             }
        //             return failedUpload;
        //         })
        //     )
        //     .finally(
        //         action(() => {
        //             this.backgroundImgInProgress = false;
        //         })
        //     );
    }

    @action
    async setProfilePictureVenueCustom(url, idProfile) {
        var avatars = [];

        let response = await fetch(url);
        let data = await response.blob();
        const fileExtension = "jpg"
        var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
        var name = `${ticks}.${fileExtension}`;
        let metadata = {
            type: 'image/jpeg'
          };
        let image = new File([data], name, metadata);
        
        var result = await this.uploadOriginalProfilePicture(idProfile, image, name);
        var result200 = await this.upload200ProfilePicture(idProfile, image, name);
        var result400 = await this.upload400ProfilePicture(idProfile, image, name);
        var avatar = {
            urlOriginal: result.key,
            url200: result200.key,
            url400: result400.key,
            fileName: name,
            mime: image.type
        };
        avatars.push(avatar);
        var result = await agent.Image.uploadProfilePicture(idProfile, avatars).then(
            action(async result => {


            })
        )
        .catch(
            action(err => {
                return "error";
            })
        )
            .finally(
                action(() => {
                    this.profileImgInProgress = false;
                })
            );
           
    }

    @action
    async setBackgroundImageCustom(url, profileId) {
        let response = await fetch(url);
        let data = await response.blob();
        const fileExtension = "jpg"
        var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
        var name = `${ticks}.${fileExtension}`;
        let metadata = {
          type: 'image/jpeg'
        };
        let image = new File([data], name, metadata);
       
      
        var result = await this.uploadOriginalBackgroundPicture(profileId, image, name);
        var result1920 = await this.upload1920BackgroundPicture(profileId, image, name);
        var result960 = await this.upload960BackgroundPicture(profileId, image, name);
        var cover = {
            urlOriginal: result.key,
            url1920: result1920.key,
            url960: result960.key,
            fileName: name,
            mime: image.type,
            previousName: image.name
        };
        var covers = [cover]
        var result = await agent.Image.uploadBackgroundPicture(profileId, covers).then(
            action (async ({ count, images }) => {
   
            })
        )
            .finally(
                action(() => {
                    this.backgroundImgInProgress = false;
                })
            )
    }

    async uploadOriginalBackgroundPicture(id, profileImage, name) {
        try {
            const result = await Storage.put(
                `cover/original/${id}/${name}`,
                profileImage
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }

    async upload1920BackgroundPicture(id, profileImage, name) {
        try {
            // Read the image
            const imgSrc = await imageHelper.readFileAsync(profileImage);
            const img = await imageHelper.loadImgAsync(imgSrc)
            // From the image and a canvas (for the resize), 
            // generate a blob to be uploaded
            const canvas = imageHelper.createCanvas(img, 1920);
            const imgBlob = await imageHelper.imgToBlobAsync(img, canvas)

            // Create a file from the blob
            const fileMain = new File([imgBlob], `${name}`, {
                type: 'image/*',
                lastModified: Date.now()
            });
            const result = await Storage.put(
                `cover/1920/${id}/${name}`,
                fileMain
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }
    async upload960BackgroundPicture(id, profileImage, name) {
        try {
            // Read the image
            const imgSrc = await imageHelper.readFileAsync(profileImage);
            const img = await imageHelper.loadImgAsync(imgSrc)
            // From the image and a canvas (for the resize), 
            // generate a blob to be uploaded
            const canvas = imageHelper.createCanvas(img, 960);
            const imgBlob = await imageHelper.imgToBlobAsync(img, canvas)

            // Create a file from the blob
            const fileMain = new File([imgBlob], `${name}`, {
                type: 'image/*',
                lastModified: Date.now()
            });
            const result = await Storage.put(
                `cover/960/${id}/${name}`,
                fileMain
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }

    @action
    async removeBackgroundPicture(image) {
        if (image && image.id) {
            await Storage.remove(image.urlOriginal);
            return agent.Image.deleteBackgroundPicture(image.id)
                .then(
                    action(() => {
                        if (this.backgroundImages) {
                            this.backgroundImages = this.backgroundImages.filter(img => img !== image);
                            if (this.backgroundImages.length === 0) {
                                this.backgroundImages = [];
                            }
                        }
                    })
                )
                .finally(
                    action(() => {
                        this.inProgress = false;
                    })
                );
        }
        //upload the files here
        // return agent.Image.uploadProfilePicture(this.id, profileImage);
    }

    @action
    addPlayedAt = playedAts => {
        this.playedAtInProgress = true;
        return agent.PlayedAt.addPlayedAt(this.id, playedAts)
            .then(
                action(results => {
                    for (let i = 0; i < results.length; i++) {
                        this.playedAts.push(results[i]);
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.playedAtInProgress = false;
                    this.playedAtSuccess = true;
                    this.timer = setTimeout(
                        action(() => {
                            this.playedAtSuccess = false;
                        }),
                        2000
                    );
                })
            );
    };

    @action
    editPlayedAt = playedAt => {
        if (!playedAt.id) return this.addPlayedAt([playedAt]);

        this.playedAtInProgress = true;
        return agent.PlayedAt.editPlayedAt(this.id, playedAt)
            .then(
                action(result => {
                    for (let i = 0; i < this.playedAts.length; i++) {
                        if (this.playedAts[i].id === result.id) {
                            this.playedAts[i] = result;
                            break;
                        }
                    }
                    return result;
                })
            )
            .finally(
                action(() => {
                    this.playedAtInProgress = false;
                })
            );
    };

    @action
    deletePlayedAt = playedAts => {
        return agent.PlayedAt.deletePlayedAt(this.id, playedAts)
            .then(
                action(results => {
                    for (let i = 0; i < results.length; i++) {
                        for (let j = 0; j < this.playedAts.length; j++) {
                            if (results[i].id === this.playedAts[j].id) {
                                this.playedAts.splice(j, 1);
                                break;
                            }
                        }
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.playedAtInProgress = false;
                    this.playedAtSuccess = true;
                    this.timer = setTimeout(
                        action(() => {
                            this.playedAtSuccess = false;
                        }),
                        1000
                    );
                })
            );
    };

    @action
    addPlayedWith = playedWiths => {
        this.playedWithInProgress = true;
        return agent.PlayedWith.addPlayedWith(this.id, playedWiths)
            .then(
                action(results => {
                    for (let i = 0; i < results.length; i++) {
                        this.playedWiths.push(results[i]);
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.playedWithInProgress = false;
                    this.playedWithSuccess = true;
                    this.timer = setTimeout(
                        action(() => {
                            this.playedWithSuccess = false;
                        }),
                        2000
                    );
                })
            );
    };

    @action
    editPlayedWith = playedWith => {
        if (!playedWith.id) return this.addPlayedWith([playedWith]);

        this.playedWithInProgress = true;
        return agent.PlayedWith.editPlayedWith(this.id, playedWith)
            .then(
                action(result => {
                    for (let i = 0; i < this.playedWiths.length; i++) {
                        if (this.playedWiths[i].id === result.id) {
                            this.playedWiths[i] = result;
                            break;
                        }
                    }
                    return result;
                })
            )
            .finally(
                action(() => {
                    this.playedWithInProgress = false;
                })
            );
    };

    @action
    deletePlayedWith = playedWiths => {
        return agent.PlayedWith.deletePlayedWith(this.id, playedWiths)
            .then(
                action(results => {
                    for (let i = 0; i < results.length; i++) {
                        for (let j = 0; j < this.playedWiths.length; j++) {
                            if (results[i].id === this.playedWiths[j].id) {
                                this.playedWiths.splice(j, 1);
                                break;
                            }
                        }
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.playedWithInProgress = false;
                    this.playedWithSuccess = true;
                    this.timer = setTimeout(
                        action(() => {
                            this.playedWithSuccess = false;
                        }),
                        1000
                    );
                })
            );
    };

    @action
    addTradingHour = tradingHour => {
        this.tradingHours.push(tradingHour);
        return tradingHour;
    };

    @action
    submitTradingHour = tradingHour => {
        for (let i = 0; i < this.tradingHours.length; i++) {
            if (this.tradingHours[i].id === tradingHour.id) {
                this.tradingHours[i] = tradingHour;
                return;
            }
        }

        let currentTime = new Date();
        tradingHour.id = currentTime.getTime();

        return this.addTradingHour(tradingHour);
    };

    @action
    getTradingHour = tradingHourId => {
        for (let i = 0; i < this.tradingHours.length; i++) {
            if (this.tradingHours[i].id === tradingHourId) {
                return this.tradingHours[i];
            }
        }
        return undefined;
    };

    @action
    deleteTradingHour = deletedTradingHourId => {
        let deleted = undefined;
        for (let i = 0; i < this.tradingHours.length; i++) {
            if (this.tradingHours[i].id === deletedTradingHourId) {
                deleted = this.tradingHours.splice(i, 1);
                break;
            }
        }
        return deleted;
    };

    @action
    submit() {
        this.inProgress = true;
        this.errors = {};
        const user = userStore.currentUser;
        const profile = {
            id: this.id,
            userId: user.sub,
            videoUrl: this.videoUrl,
            musicUrl: this.musicUrl,
            testimonials: this.testimonials,
            songList: this.songList,
            type: this.type,
            showPlayedWith: this.showPlayedWith,
            tradingHours: JSON.stringify(this.tradingHours),
            showAvatar: this.showAvatar,
            vanityUrl: this.vanityUrl.length !== 0 ? this.vanityUrl : null,
            webSite: this.webSite,
            facebook: this.facebook,
            twitter: this.twitter,
            bandcamp: this.bandcamp,
            soundcloud: this.soundcloud,
            mixcloud: this.mixcloud,
            youtube: this.youtube,
            instagram: this.instagram,
            googlePlus: this.googlePlus,
            linkedIn: this.linkedIn,
            iTunes: this.iTunes,
            spotify: this.spotify
        };
        // if(this.vanityUrl.length > 0) profile.vanityUrl = this.vanityUrl
        // else profile.vanityUrl = null
        return (this.id ? this.updateProfile(profile) : this.createProfile(profile))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.data.errors;
                    // this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    // profileStore.loadProfiles(); //refresh profiles after editing design section in case of image change
                    this.inProgress = false;
                })
            );
    }

    @action
    setError(key, value) {
        this.errors[key] = value;
    }

    @action
    async loadProfile(profileId, userId, type) {
        //using general profile for create
        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.MusicianDesignProfile.load(profileId).then(async profile => {
                    if (profile.profileImage !== null && typeof profile.profileImage.url === "undefined") {
                        profile.profileImage.url200 = await CacheHelper.getImgLink(profile.profileImage.url200);
                        profile.profileImage.url = await CacheHelper.getImgLink(profile.profileImage.urlOriginal);
                        profile.profileImage.url400 = await CacheHelper.getImgLink(profile.profileImage.url400);
                    }
                    if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                        for (var j = 0; j < profile.backgroundImages.length; j++) {
                            if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                                profile.backgroundImages[j].url = await CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal);
                                profile.backgroundImages[j].url960 = await CacheHelper.getImgLink(profile.backgroundImages[j].url960);
                            }
                        }
                    } else {
                        profile.backgroundImages = undefined;
                    }
                    return profile;
                });
            // case ProfileType.Agent.ordinal:
            //     return agent.AgentGeneralProfile.create(data).then(profile => {
            //         return profile;
            //     });
            // case ProfileType.Staff.ordinal:
            //     return agent.VenueGeneralProfile.create(data).then(profile => {
            //         return profile;
            //     });
            // case ProfileType.Act.ordinal:
            //     return agent.ActGeneralProfile.create(data).then(profile => {
            //         return profile;
            //     });
            // case ProfileType.Venue.ordinal:
            //     return agent.VenueGeneralProfile.create(data).then(profile => {
            //         this.id = profile.id;
            //         return profile;
            //     });
            // case ProfileType.Production.ordinal:
            //     return agent.ProductionGeneralProfile.create(data).then(profile => {
            //         this.id = profile.id;
            //         return profile;
            //     });
            // case ProfileType.Dj.ordinal:
            //     return agent.DjGeneralProfile.create(data).then(profile => {
            //         this.id = profile.id;
            //         return profile;
            //     });
            default:
                return profileStore.loadProfile(userId, type, true);
        }
    }

    @action
    createProfile(data) {
        //using general profile for create
        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.MusicianGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Staff.ordinal:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Act.ordinal:
                return agent.ActGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Venue.ordinal:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Production.ordinal:
                return agent.ProductionGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Dj.ordinal:
                return agent.DjGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.PrivateHire.ordinal:
                return agent.PrivateHireGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            default:
                break;
        }
    }

    @action
    updateProfile(data) {
        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.MusicianDesignProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentDesignProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Staff.ordinal:
                return agent.StaffDesignProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Act.ordinal:
                return agent.ActDesignProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Venue.ordinal:
                return agent.VenueDesignProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Production.ordinal:
                return agent.ProductionDesignProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Dj.ordinal:
                return agent.DjDesignProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.PrivateHire.ordinal:
                return agent.PrivateHireDesignProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            default:
                break;
        }
    }
}

export default new DesignProfileStore();
