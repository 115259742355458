import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import LegacyUserTableHeader from "./LegacyUserTableHeader";
import green from "@material-ui/core/colors/green";
import { inject, observer } from "mobx-react";
import Loading from "../loadingSpinner/Loading";
import "./LegacyUserTable.css";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { Menu, MenuItem } from "@material-ui/core";

const styles = theme => ({
    rootToolbar: {
        backgroundColor: "#ff0c9c",
        flexGrow: 1
    },
    title: {
        flex: 0.96
    },
    root: {
        width: "100%",
        boxShadow: "none"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    checked: {
        color: green[500],
        "& + $bar": {
            backgroundColor: green[500]
        }
    },
    pads: {
        paddingRight: theme.spacing.unit,
        minHeight: 48
    },
    bar: {}
});

@inject("userStore", "profileStore", "authStore")
@withRouter
@observer
class LegacyUserTable extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        openDelete: false,
        openAdd: false,
        openRemove: false,
        order: "desc",
        orderBy: "createdAt",
        selected: [],
        page: 0,
        rowsPerPage: 10,
        isLoading: true,
        paginationTokenTemp: "",
    };



    componentDidMount() {
        this.props.userStore.getLegacyUser().then(x => {
            this.setState({ isLoading: false });
        });
    }

    handleChangePage = (event, page) => {
        this.setState({ isLoading: true });

        if (page > 0) {
            if (page > this.state.page) {
                this.setState({ page });
                this.props.userStore.getPrevNextLegacyUser(page).then(data => {
                    this.setState({ isLoading: false });
                });

            } else {
                this.setState({ page });
                this.props.userStore.getPrevNextLegacyUser(page).then(data => {
                    this.setState({ isLoading: false });
                });
            }

        } else {
            this.setState({ page });
            this.props.userStore.getLegacyUser().then(x => {
                this.setState({ isLoading: false });
            });
        }

    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
        this.props.userStore.setLimitPage(event.target.value)

        this.props.userStore.getLegacyUser().then(x => {
            this.setState({ isLoading: false });
        });
    };

    handleRequestSort = (event, property) => {
        const { activeUsers } = this.props.userStore;
        let data = [];
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        if (orderBy === "email") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.username < a.username ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.username < b.username ? -1 : 1));
        } else if (orderBy === "firstName") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.attributes[3].value < a.attributes[3].value ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.attributes[3].value < b.attributes[3].value ? -1 : 1));
        } else if (orderBy === "lastName") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.attributes[4].value < a.attributes[4].value ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.attributes[4].value < b.attributes[4].value ? -1 : 1));
        } else if (orderBy === "status") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.status < a.status ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.status < b.status ? -1 : 1));
        } else if (orderBy === "musician") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.musicianName < a.musicianName ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.musicianName < b.musicianName ? -1 : 1));
        } else if (orderBy === "agent") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.agentName < a.agentName ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.agentName < b.agentName ? -1 : 1));
        } else if (orderBy === "venue") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.venueName < a.venueName ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.venueName < b.venueName ? -1 : 1));
        }
        else {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                    : activeUsers.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        }

        this.props.userStore.updateTable(data);

        this.setState({ order, orderBy });
    };

    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, isLoading } = this.state;
        const { legacyUsers, totalLegacyUsers } = this.props.userStore;

        return (
            <div style={{ position: "relative" }}>
                <Loading showed={isLoading} />

                <div className={classes.rootToolbar}>
                    <Toolbar className={classes.pads}>
                        <div className={classes.title}>
                            <Typography variant="subheading">Users</Typography>
                        </div>
                    </Toolbar>
                </div>

                <div className={classes.tableWrapper}>
                    <Table className="admin-table">
                        <LegacyUserTableHeader
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={legacyUsers.length}
                        />
                        <TableBody>
                            {legacyUsers.map(data => {
                                return (
                                    <TableRow tabIndex={-1}>
                                        <TableCell>
                                            <Typography >{data.email}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.firstName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.lastName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.status}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.musicianName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.agentName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.venueName}</Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    SelectProps={{ className: "transparent-select" }}
                                    count={totalLegacyUsers}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    {/* <LinearProgress variant="determinate" value={topSongs.length * 8.5} /> */}
                </div>
            </div>
        );
    }

}

export default withStyles(styles)(LegacyUserTable);
