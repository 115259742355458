import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, Card, CardHeader, Avatar } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import moment from 'moment'
import { withRouter } from "react-router-dom";

import ModalContainer from "../../modal/ModalContainer";
import ResponsiveTable from "../../table/ResponsiveTable";
import { BookingType } from "../../../types/enum";

@withRouter
@inject("profileStore", "venueStore", "templateStore", "bookingRequestStore", "lineupStore")
@observer
class AddMusicianToGigModal extends Component {

    gigColumns = [
        { key: "date", label: "Date", width: "5%", sortable: true },
        { key: "actName", label: "Act Name", width: "40%" },
        { key: "venue", label: "Venue", width: "40%" },
        { key: "actFee", label: "Gig Fee", sortable: true, isNumber: true, type: "currency", width: "15%" },
    ];

    gigRows = [];

    constructor(props) {
        super(props);
        this.state = {
            selectedGig: undefined
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.open && !prevProps.open){
            const currentProfile = this.props.profileStore.getCurrentProfile()
            this.props.lineupStore.setAdditionalPlayer(undefined);
            this.props.bookingRequestStore.loadUpcomingGigs(currentProfile.id)
        }
    }

    getGigRows = () => {
        const { upcomings } = this.props.bookingRequestStore;
        const { selectedGig } = this.state;
        let rows = [];

        upcomings.forEach( booking => {
            var row = {};
            let date = new Date(booking.dateStart);
            row.id = booking.id;
            row.date = (
                <div>
                    <span style={{ whiteSpace: "nowrap" }}>
                        {date.getDate() +
                            " " +
                            this.props.templateStore.getMonth(date.getMonth(), true) +
                            " " +
                            date.getFullYear()}{" "}
                        {moment(booking.timeStart, "HH:mm:ss").format(
                            this.props.templateStore.getFormat12Hour(booking.timeStart)
                        )}
                    </span>
                </div>
            );
            row.venue = (
                <div>
                    <span>{booking.venue ? booking.venue.profileName : booking.venueName}</span>
                    <br />
                    <small>{booking.venueAddress}</small>
                </div>
            );
            row.actName = (
                <div>
                    <span>{booking?.act?.actName}</span>
                </div>
            );
            row.actFee = booking.actFee;
            row.time = booking.timeStart.substring(0, 5);
            row.type = booking.type;
            row.checked = booking.id === selectedGig;

            rows.push(row);
        });
        return rows;
    }

    getSelectedGig = rows => {
        this.setState({
            selectedGig: rows[0]
        })
    }

    handleAddToGig = () => {
        const { selectedGig } = this.state;
        const { profile } = this.props;
        this.props.lineupStore.setAdditionalPlayer(profile);
        if(selectedGig.type === BookingType.Normal.ordinal){
            this.props.history.push(`/musician/booking/${selectedGig.id}/lineup`);
        }
        else if(selectedGig.type === BookingType.Adhoc.ordinal){
            this.props.history.push(`/privateBooking/${selectedGig.id}/lineup`)
        }
    }

    render() {
        const { 
            profileName, profileImage, open
        } = this.props;
        const { selectedGig } = this.state;
        return (
            <ModalContainer title="Add Musician to Gig" open={ open } onClose={this.props.onClose}>
                <DialogContent>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            alt={profileName}
                                            src={ profileImage }
                                            style={{
                                                width: 40,
                                                height: 40
                                            }}
                                        />
                                    }
                                    title={ profileName }
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Gig List</InputLabel>
                            <ResponsiveTable
                                hideSelectAll
                                selectable={true}
                                single
                                rows={this.getGigRows()}
                                columns={this.gigColumns}
                                getCheckedRows={this.getSelectedGig}
                                pagination={this.getGigRows().length > 5}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Close</Button>
                    <Button disabled = { !selectedGig }id="invite-musician-to-gig-submit" color="primary" onClick = { this.handleAddToGig }>
                        Add to Gig
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default AddMusicianToGigModal;
