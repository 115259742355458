import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Checkbox, Tooltip, Button } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import StateTableToolbar from "./StateTableToolbar";
import stateStore from "../../stores/stateStore.js";

const columnData = [
    { columnTitle: "name", numeric: false, disablePadding: false, label: "Name" },
    { columnTitle: "address", numeric: false, disablePadding: false, label: "Address" },
    { columnTitle: "edit", numeric: false, disablePadding: false, label: "" }
];

@inject("designProfileStore", "stateStore", "organisationStore")
@observer
class StateTable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            rowsPerPage: 5
        };
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleClearSelected = () => {
        this.setState({ selected: [] });
    };

    invertDirection = (direction) =>{
        if (direction === "asc") {
            return "desc";
        } else {
            return "asc"
        }
    }
    
    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleSelectAllClick = (event, checked) => {
        const { states } = this.props.stateStore;

        if (checked) {
            this.setState({ selected: states.map(n => n.name) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleSort = (columnName) => {
        const { states } = this.props.stateStore;
        var newSortDir =  this.state.orderBy === columnName ? this.invertDirection(this.state.order) : "asc";
        var temp=  _.orderBy(states, columnName,newSortDir); // Use Lodash to sort array by 'name'
        stateStore.setStates(temp);
        this.setState({ ...this.state,
             orderBy: columnName,
             order:  newSortDir
             })
            
    }

    
    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page } = this.state;
        const { states } = this.props.stateStore;
        const { level1Label, level2Label, id } = this.props.organisationStore;
        var canManage = this.props.organisationStore.canManage || id === undefined;

        return (
            <Paper className={classes.root}>
                <StateTableToolbar selected={selected} handleClearSelected={this.handleClearSelected} />
                <div className={classes.tableWrapper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {canManage ? (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            disabled={!canManage}
                                            indeterminate={selected.length > 0 && selected.length < states.length}
                                            checked={selected.length === states.length}
                                            onChange={this.handleSelectAllClick}
                                        />
                                    </TableCell>
                                ) : (
                                    ""
                                )}
                                {columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.columnTitle}
                                            padding={column.disablePadding ? "none" : "default"}
                                            sortDirection={orderBy === column.columnTitle ? order : false}
                                        >
                                            <Tooltip title="Sort" placement={column.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                                                <TableSortLabel active={orderBy === column.columnTitle} direction={order}>
                                                    <div onClick={() => this.handleSort(column.columnTitle)}>{column.label}</div>
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    );
                                }, this)}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {states.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                const isSelected = this.isSelected(n.name);
                                return (
                                    <TableRow hover role="checkbox" aria-checked={isSelected} tabIndex={-1} key={n.name} selected={isSelected}>
                                        {canManage ? (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    disabled={!canManage}
                                                    checked={isSelected}
                                                    onClick={event => this.handleClick(event, n.name)}
                                                />
                                            </TableCell>
                                        ) : (
                                            ""
                                        )}
                                        <TableCell>{n.name}</TableCell>
                                        <TableCell>{n.address}</TableCell>
                                        <TableCell>
                                            {!n.id && (
                                                <Tooltip title={`Save your ${level1Label} to edit this ${level2Label}`} enterDelay={300}>
                                                    <div>
                                                        <Button
                                                            color="primary"
                                                            component={Link}
                                                            to={`/organisation/${n.organisationId}/state/${n.id}/edit`}
                                                            disabled={true}
                                                            className={"state-edit " + classes.stateEdit}
                                                        >
                                                            <Edit className={classes.editIcon} />Edit
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {n.id && (
                                                <Button
                                                    color="primary"
                                                    component={Link}
                                                    to={`/organisation/${n.organisationId}/state/${n.id}/edit`}
                                                    className={"state-edit " + classes.stateEdit}
                                                >
                                                    {canManage ? "Edit" : "View"}
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    rowsPerPage={5}
                                    page={page}
                                    count={states.length}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
        );
    }
}

StateTable.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    stateEdit: {
        justifyContent: "space-between"
    },
    editIcon: {
        fontSize: 20
    }
});

export default withStyles(styles)(StateTable);
