import React, { Component, Fragment} from "react";
import { inject, observer } from "mobx-react";

import moment from "moment";

import { Grid, Avatar } from "@material-ui/core";

import PageNotFound from "../profile/PageNotFound";
// import classNames from "classnames";
// import goldFrame from "../../img/gold-frame.png";
import curve from "../../img/entertainment-curve.svg";
import hotGiggityLive from "../../img/Hot Giggity Live.png";
import image from "../../helper/image";

import "./Entertainment.css";

// const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

@inject("profileStore", "bookingRequestStore", "templateStore", "reportStore")
@observer
class EntertainmentNextEvent extends Component {
    state = {
        upcomings: [],
        profile: null,
        finishLoading: false,
        gigIndex: 0
    };

    // load profile initially
    componentDidMount = () => {
        this.loadProfile();
    };

    // check if we get an update, usually by choosing the musician profile in the user popup
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadProfile();
        }
    }

    ordinal = (number) => {
        switch (number) {
          case 1:
          case 21:
            return number + 'st';
            break;
          case 2:
          case 22:
            return number + 'nd';
            break;
          case 3:
          case 23:
            return number + 'rd';
            break;
          default:
            return number + 'th';
        }
      }

    loadProfile = () => {
        const param = this.props.match.params.id;
        Promise.all([
            this.props.profileStore.getPublicProfileById(param),
        this.props.bookingRequestStore.loadEntertainmentUpcomingNextEvent(param)]).then((response) => { 
            var upcomings = response[1]

            for (let i = 0; i < upcomings.length; i++) {
                // caches images, avoiding white flash between background replacements
                if (upcomings[i].act) {
                    new Image().src = image.getBackgroundImage(upcomings[i].act, false);
                }
            }
            this.changeBackground(0);
            this.props.templateStore.hideLoadingScreen();
            this.setState({
                upcomings: upcomings,
                profile: response[0],
                finishLoading: true
            });
        });
    };

    changeBackground = index => {
        let { upcomings } = this.state;
        this.setState({ gigIndex: index });

        this.timeoutValue = setTimeout(() => {
            let newIndex = this.state.gigIndex + 1 < upcomings.length ? this.state.gigIndex + 1 : 0;
            this.changeBackground(newIndex);
        }, 5000);
    };

    manualChangeBackground = index => () => {
        clearTimeout(this.timeoutValue);
        this.changeBackground(index);
    };

    render() {
        let { upcomings } = this.state;
        let backgroundImage = image.getBackgroundImage(upcomings[0]?.act, false)
        return (
            <React.Fragment>
                {this.state.profile ? (
                    <div className="entertainment-page">
                        <Grid container className="entertainment-container">
                            <Grid item xs={12} className="entertainment-big-title-nextevent" >
                                <span style={{color:"white"}}>UP NEXT</span> LIVE
                            </Grid>
                            <Grid container>
                                <Grid xs={5}>
                                    <img src={backgroundImage} className="image-next-event" />
                                </Grid>
                                <Grid xs={7} style={{paddingLeft:20}}>
                                    <Grid item xs={12} className="entertainment-slide-information-name-nextevent">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].act &&
                                            upcomings[this.state.gigIndex].act.actName}
                                    </Grid>
                                    <Grid item xs={12} className="entertainment-slide-information-day-nextevent">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].dateStart &&
                                            `${upcomings[this.state.gigIndex].dateStart.format("ddd - ")
                                            }${this.ordinal(upcomings[this.state.gigIndex].dateStart.format("D"))
                                            } ${upcomings[this.state.gigIndex].dateStart.format("MMM")
                                            }`}
                                    </Grid>
                                    <Grid item xs={12} className="entertainment-slide-information-time-nextevent">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].timeStart &&
                                            upcomings[this.state.gigIndex].timeEnd &&
                                            moment(upcomings[this.state.gigIndex].timeStart, "HH:mm:ss.SSS").format("h.mmA") +
                                                " - " +
                                                moment(upcomings[this.state.gigIndex].timeEnd, "HH:mm:ss.SSS").format("h.mmA")}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                      
                        {/* <Grid container className="entertainment-container">
 
                            <Grid item xs={6} className="entertainment-left-image-hero">
                                <img src={curve} alt="curve" className="entertainment-curve" />
                            </Grid>

                            <Grid item xs={6} className="entertainment-right-content">
                                <Grid container>
                                    <Grid item xs={12} className="entertainment-big-title">
                                        LIVE
                                    </Grid>
                                    <Grid item xs={12} className="entertainment-slide-information-day">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].dateStart &&
                                            upcomings[this.state.gigIndex].dateStart.format("dddd MMM D")
                                            }
                                    </Grid>
                                    <Grid item xs={12} className="entertainment-slide-information-name">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].act &&
                                            upcomings[this.state.gigIndex].act.actName}
                                    </Grid>
                                    <Grid item xs={12} className="entertainment-slide-information-time">
                                        {upcomings &&
                                            upcomings[this.state.gigIndex] &&
                                            upcomings[this.state.gigIndex].timeStart &&
                                            upcomings[this.state.gigIndex].timeEnd &&
                                            moment(upcomings[this.state.gigIndex].timeStart, "HH:mm:ss.SSS").format("h.mmA") +
                                                " - " +
                                                moment(upcomings[this.state.gigIndex].timeEnd, "HH:mm:ss.SSS").format("h.mmA")}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}

                        <div className="entertainment-follow-us-nextevent">
                            FOLLOW US ON <img src={hotGiggityLive} alt="hotgiggity live logo" />
                        </div>

                        {this.state.profile && (
                            <img src={image.getProfileImage(this.state.profile, true)} alt="curve" className="entertainment-venue-profile-image" />
                        )}
                    </div>
                ) : this.state.finishLoading ? (
                    <PageNotFound />
                ) : null}
            </React.Fragment>

        );
    }
}
export default EntertainmentNextEvent;
