import React, { Component, Fragment } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withRouter, Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import CustomEmbed from "./CustomEmbedGigWidget";
import { EmbedType } from "../../types/enum";
const BASE_URL = process.env.REACT_APP_BASE_URL;

@withRouter
@inject("templateStore", "profileStore", "embedGigStore")
@observer
class EmbedGigWidget extends Component {
    state = {
        open: false,
        copyState: false
    };

    handleCopy = () => {
        var copyText = document.getElementById("code");
        copyText.select();
        document.execCommand("copy");
        this.setState({ copyState: true });
        this.props.templateStore.openSnackbar("Code Copied");
        setTimeout(
            function() {
                this.setState({ copyState: false });
            }.bind(this),
            2000
        );
    };

    handleClickOpen = () => this.setState({ open: true });

    handleClose = () => this.setState({ open: false });

    generateParameters = (...par) => {
        let args = par.map(ar => {
            return typeof ar === "string" ? ar.replace("#", "") : ar;
        });
        // const params =
        //     "?headerBgColor=" +
        //     args[0] +
        //     "&headerFtColor=" +
        //     args[1] +
        //     "&tableBgColor=" +
        //     args[2] +
        //     "&tableFtColor=" +
        //     args[3] +
        //     "&dateBgColor=" +
        //     args[4] +
        //     "&dateFtColor=" +
        //     args[5] +
        //     "&hideToolbar=" +
        //     args[6] + 
        //     "&hideOrderUber=" + 
        //     args[7] + 
        //     "&hideShareButton=" + 
        //     args[8];
        var params = 
            "?tableBgColor=" + 
            args[2] + 
            "&tableFtColor=" + 
            args[3] + 
            "&hideToolbar=" + 
            args[6];
        if(args[9] === EmbedType.Grid.ordinal){
            var params = 
            "?tableBgColor=" + 
            args[2] + 
            "&tableFtColor=" + 
            args[3]
        }
        if(args[10]){
            params = params +  "&tableWidgetBgColor=" + args[10]
            params = params +  "&enabledTransparent=" + args[11]
        }
        return params;
    };

    componentDidMount() {
        const { profile } = this.props.profileStore;
        if(profile){
            this.props.embedGigStore.loadEmbedCode( profile.id );
        }else if(this.props.match.params.id){
            this.props.embedGigStore.loadEmbedCode(this.props.match.params.id);
        }
        else{
            this.props.embedGigStore.loadEmbedCode(0);
        }
        
    }
    getEmbedText(embedType){
        switch (embedType) {
            case EmbedType.Large.ordinal:
                return "embed/";
            case EmbedType.Table.ordinal:
                return "embedtext/";
            case EmbedType.Grid.ordinal:
                return "embedgrid/"
            default:
                return "embedgrid/";
        }
    }

    render() {
        const { profile } = this.props.profileStore;
        const { headerBgColor, headerFtColor, tableBgColor, tableFtColor, dateBgColor, dateFtColor, hideToolbar, hideOrderUber, hideShareButton,embedType,tableWidgetBgColor,enabledTransparent } = this.props.embedGigStore;
        const parameters = this.generateParameters(headerBgColor, headerFtColor, tableBgColor, tableFtColor, dateBgColor, dateFtColor, hideToolbar, hideOrderUber, hideShareButton,embedType, tableWidgetBgColor,enabledTransparent);
        const embedText = this.getEmbedText(embedType)
        const scriptLink =  `<script src="${BASE_URL}js/iframeResizer.min.js"></script>`
        const link = embedType === 2? `${scriptLink} \n <iframe allowTransparency="true"
        onload="iFrameResize({},this)" width="100%" height="800" src="${BASE_URL + embedText + (profile ? profile.id : 0) + parameters}" frameborder="0"></iframe>` : '<iframe width="100%" height="800" src="' + BASE_URL + embedText + (profile ? profile.id : 0) + parameters + '" frameborder="0"></iframe>';
        return (
            <Fragment>
                <TextField
                    id="code"
                    label="Embed Code"
                    multiline
                    rows="5"
                    value={link}
                    margin="normal"
                    fullWidth
                />
                <CustomEmbed profile={profile} profileId={ (profile ? profile.id : 0) } />
                <Button onClick={this.handleCopy} color="primary" autoFocus style={{ float: "right" }}>
                    {this.state.copyState ? "Copied!" : "Copy"}
                </Button>
            </Fragment>
        );
    }
}

export default EmbedGigWidget;
