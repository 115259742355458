import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import { Paper } from "@material-ui/core";
import SavePromotionForm from "../../components/promotions/SavePromotionForm";
import Loading from "../../components/loadingSpinner/Loading";

@inject("promotionStore", "templateStore")
@observer
class EditPromotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        console.log(this.props);

        if (this.props.match.params.id) {
            this.setState({
                ...this.state,
                isLoading: true
            });
            this.props.templateStore.showLoadingScreen();
            this.props.promotionStore.getPromotionDetail(this.props.match.params.id).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.templateStore.hideLoadingScreen();
            });
        }
    }

    generateBreadcrumb = () => {
        const { promotion } = this.props.promotionStore;
        var breadcrumb = [{ name: "Dashboard", link: "/staff" }, { name: "Promotions", link: "/promotion" }];

        breadcrumb.push({
            name: promotion && promotion.id ? "Edit Promotion" : "New Promotion"
        });

        return breadcrumb;
    };

    render() {
        const { isLoading } = this.state;
        const { promotion } = this.props.promotionStore;
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.generateBreadcrumb()} />
                    <Heading title={promotion && promotion.id ? "Edit Promotion" : "New Promotion"} />
                    <Paper style={{ padding: 16, position: "relative" }}>
                        <Loading showed={isLoading} />
                        <SavePromotionForm />
                    </Paper>
                </MainContainer>
            </Fragment>
        );
    }
}

export default EditPromotion;
