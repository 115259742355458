import React, {Component, Fragment} from 'react';
import { Button, DialogActions, DialogContentText, DialogContent } from "@material-ui/core";
import { CardElement } from '@stripe/react-stripe-js';
import agent from "../../agent";


// @inject("userStore", "authStore")
class CheckoutForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            isError: false,
            errorMessage: "",
        };
    }
   
    handleSubmit = async (event) => {
        event.preventDefault();
        const {stripe, elements} = this.props;
    
        if (elements == null)  return;

        const card = elements.getElement(CardElement);

        if (card == null) return;

        const {token} = await stripe.createToken(card);

        if (token != null){
            let card = { tokenId: token.id };
            
            this.setState({isError: false});
            try{
                let result = await agent.Billing.addCard(this.props.customer.id, card);
                this.props.confirmationHandler();
            }catch(ex){
                this.setState({isError: true, errorMessage: "Please add a valid card."});
            }
        }
        

      };

      handleDeleteCard = async (event) => {
        event.preventDefault();
    
        try{
            let result = await agent.Billing.removeCard(this.props.customer.id,this.props.profileType);
            this.props.confirmationHandler(true);
        }catch(ex){
            console.log(ex)
            this.setState({isError: true, errorMessage: "Please add a valid card."});
        }
        

      };

    render() {
        const {stripe} = this.props;
        return (
          <form onSubmit={this.handleSubmit}>
            <Fragment>
               <DialogContent style={{paddingTop:0}}>
                   <DialogContentText>{this.props.isSubscribe ? "Please add your new card details below" : "Please input your credit card detail"}</DialogContentText>
                    { this.state.isError ? <div style={ { color:"red", fontSize: "small"}} >* {this.state.errorMessage} </div> : "" } 
                   <br/>
                   <CardElement options={{
                        style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                            color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                        },
                    }}/>
               </DialogContent>
               <DialogActions>
                    {this.props.isRemovable ? 
                        <Button color="primary" onClick={this.handleDeleteCard}>
                            Remove Card
                        </Button>
                        : ""
                    }
                   
                   <Button color="primary" onClick={this.props.declineHandler}>
                       {this.props.declineLabel ? this.props.declineLabel : "Close"}
                   </Button>
                   <Button type="submit" className="btn-confirmation" color="primary" isabled={!stripe} autoFocus>
                        {this.props.confirmationLabel ? this.props.confirmationLabel : "Confirm"}
                   </Button>
               </DialogActions>
           </Fragment>
          </form>
        );
    }
}

export default CheckoutForm;