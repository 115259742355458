import React, { Component } from "react";

import CheckoutUpgradeForm from './CheckoutUpgradeForm';
import ModalContainer from "./ModalContainer";
import { hgStripe } from "../../pages/billing/StripeConfig";
import {loadStripe} from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(hgStripe.publishableKey);

class ConfirmationStripeModal extends Component {
    render(){
        return (
            <ModalContainer
                open={this.props.open}
                title={this.props.title ? this.props.title : "Confirmation"}
                onClose={this.props.onClose}
                fullScreen={false}
                maxWidth={"sm"}
            >
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({stripe, elements}) => (
                                <CheckoutUpgradeForm 
                                    stripe={stripe} 
                                    elements={elements} 
                                    isRemovable={this.props.isRemovable}
                                    confirmationHandler={this.props.confirmationHandler}
                                    declineHandler={this.props.declineHandler}
                                    customer={this.props.customer}
                                    isSubscribe={this.props.isSubscribe}
                                    profileType={this.props.profileType}
                                    planId={this.props.planId}
                                    confirmationLabel={this.props.confirmationLabel}
                                />
                            )}
                        </ElementsConsumer>
                    </Elements>
                   
            </ModalContainer>
        )
    }
}

export default ConfirmationStripeModal;
