import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import profileStore from "./profileStore";
import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";

class PrivacyProfileStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable userId = 0;
    @observable type = 0;

    @observable visibility = "public";
    @observable allowInvitesFromMusician = false
    @observable allowInvitesFromAgent = false
    @observable allowMusicianApplyToActLineup = false
    @observable includeInHotgiggityHire = false
    @observable isConsultant = false

    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        const { id } = generalProfileStore;

        const { currentProfile } = profileStore;
       
        profileId = profileId ? profileId : id;

        if (currentUser) {
            this.inProgress = true;
            // templateStore.showLoadingScreen();
            if ((this.type === ProfileType.Act.ordinal || this.type === ProfileType.Venue.ordinal || this.type === ProfileType.Agent.ordinal || this.type === ProfileType.PrivateHire.ordinal) && profileId ) {
                return profileStore
                    .getProfile(profileId)
                    .then(
                        action(profile => {
                            if (!profile) throw new Error("Can't load profile");
                            this.visibility = profile.visibility || "public";
                            this.id = profile.id;
                            this.allowInvitesFromAgent = profile.allowInvitesFromAgent || false;
                            this.allowInvitesFromMusician = profile.allowInvitesFromMusician || false;
                            this.allowMusicianApplyToActLineup = profile.allowMusicianApplyToActLineup || false;
                            this.includeInHotgiggityHire = profile.includeInHotgiggityHire || false;
                            this.isConsultant = profile.isConsultant || false;
                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }
                            return profile;
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            } else if (this.type !== ProfileType.Act.ordinal && this.type !== ProfileType.Venue.ordinal) {
                return profileStore
                    .loadProfile(currentUser.sub, this.type, true)
                    .then(
                        action(profile => {
                            if (!profile) {
                                this.reset();
                                throw new Error("Can't load profile");
                            }
                            this.visibility = profile.visibility || "public";
                            this.id = profile.id;
                            this.allowInvitesFromAgent = profile.allowInvitesFromAgent || false;
                            this.allowInvitesFromMusician = profile.allowInvitesFromMusician || false;
                            this.allowMusicianApplyToActLineup = profile.allowMusicianApplyToActLineup || false;
                            this.includeInHotgiggityHire = profile.includeInHotgiggityHire || false;
                            this.isConsultant = profile.isConsultant || false;
                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }
                            return profile;
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            }
        } else {
            return Promise.resolve();
        }
    }

    @action
    reset() {
        this.visibility = "public";
        this.allowInvitesFromAgent = false;
        this.allowInvitesFromMusician = false;
    }

    @action
    setVisibility(visibility) {
        this.visibility = visibility;
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    setAllowInvitesAgent(allowInvitesFromAgent) {
        this.allowInvitesFromAgent = allowInvitesFromAgent
    }

    @action
    setAllowInvitesMusician(allowInvitesFromMusician) {
        this.allowInvitesFromMusician = allowInvitesFromMusician
    }

    @action
    setAllowMusicianApplyToActLineup(allowMusicianApplyToActLineup) {
        this.allowMusicianApplyToActLineup = allowMusicianApplyToActLineup
    }

    @action
    setIncludeHotgiggityHire(includeHotgiggityHire) {
        this.includeInHotgiggityHire = includeHotgiggityHire
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const profile = {
            id: this.id,
            userId: user.id,
            visibility: this.visibility,
            type: this.type,
            allowInvitesFromAgent : this.allowInvitesFromAgent,
            allowInvitesFromMusician : this.allowInvitesFromMusician,
            allowMusicianApplyToActLineup: this.allowMusicianApplyToActLineup,
            includeInHotgiggityHire : this.includeInHotgiggityHire
        };

        return (this.id ? this.updateProfile(profile) : this.createProfile(profile))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    createProfile(data) {
        //using general profile for create
        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.MusicianGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Staff.ordinal:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Production.ordinal:
                return agent.ProductionGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Dj.ordinal:
                return agent.DjGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Act.ordinal:
            default:
                return agent.ActGeneralProfile.create(data).then(profile => {
                    return profile;
                });
        }
    }

    @action
    updateProfile(data) {
        const { currentUser } = userStore;
        const { currentProfile} = profileStore;

        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.MusicianPrivacyProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentPrivacyProfile.update(data).then(profile => {

                    if(!currentProfile.isConsultant){
                        profileStore.loadProfile(currentUser.sub, this.type, true).then(
                            action(profile => {
                                profileStore.setProfileType(profile.type);
                                profileStore.setCurrentProfile(profile)
                            return profile;
                        }))

                    }else{
                        
                        profileStore.mapAdditionalProfileData(profile)
                        
                        profileStore.setCurrentProfile(profile)
                        profileStore.setProfileType(profile.type);
                        
                        return profile;
                    }
                   

                });
            case ProfileType.Staff.ordinal:
                return agent.StaffPrivacyProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Act.ordinal:
                return agent.ActPrivacyProfile.update(data).then(profile => {
                    profileStore.setSoloAct(false);
                    return profile;
                });
            case ProfileType.Venue.ordinal:
                return agent.VenuePrivacyProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            case ProfileType.Production.ordinal:
                return agent.ProductionPrivacyProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            case ProfileType.Dj.ordinal:
                return agent.DjPrivacyProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            case ProfileType.PrivateHire.ordinal:
                return agent.PrivateHirePrivacyProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            default:
                break;
        }
    }
}

export default new PrivacyProfileStore();
