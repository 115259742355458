import { observable, action } from "mobx";
import agent from "../agent";

class UserFestivalStore{
    @observable userActFestival = undefined;
    @observable errors = undefined;

    @action
    addError(fieldKey, errorMessage) {
        if (!this.errors) {
            this.errors = [];
        }
        this.errors[fieldKey] = errorMessage;
    }

    @action
    setUserActFestivals(userActFestival){
        this.userActFestival = userActFestival;
    }

    @action
    getUserFestivalForAct(actId){
        return agent.UserFestivals.getUserFestivalForAct(actId).then(
            action(
                response => {
                    this.userActFestival = response;
                }
            )
        )
    }

    @action
    registerActToFestivals(actId, festivalId, venueId){
        this.errors = undefined;
        if(!venueId){
            this.addError('venue', "Venue is required!");
        }
        if(!this.errors){
            return agent.UserFestivals.registerActToFestivals(actId, festivalId, venueId).then(response => {
                return response;
            });
        }
        else{
            return Promise.reject();
        }
    }

    @action
    registerActToAgentFestival(actId, agentId, venueId){
        this.errors = undefined;
        if(!actId){
            this.addError('act', "Act is required!");
        }
        if(!venueId){
            this.addError('venue', "Venue is required!");
        }
        if(!this.errors){
            return agent.UserFestivals.registerToAgentFestival(actId, agentId, venueId).then(response => {
                return response;
            });
        }
        else{
            return Promise.reject();
        }
    }
}

export default new UserFestivalStore();