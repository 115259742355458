import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import { createPatch } from "rfc6902";
import { BookingStatusType, ProfileType } from "../types/enum";
import profileStore from "./profileStore";
import bookingRequestStore from "./bookingRequestStore";
import moment from "moment";

class BookingHistoryStore {
    @observable bookingHistories = [];
    @observable isLoading = true;
    @observable profiles = [];

    @action
    load(bookingId, profileId) {
        this.isLoading = true;
        return agent.Booking.getHistory(bookingId, profileId)
            .then(
                action(async res => {
                    if (!res) throw new Error("Can't load profile");
                    var result = []
                    for (var i = 0; i < res.length; i++) {
                        var data = res[i];
                        await profileStore.mapAdditionalProfileDataAsync(data.createdBy);
                        result.push(data);
                    }
                    
                    if (!bookingRequestStore.booking.id) {
                        await bookingRequestStore.initializeEditBooking(bookingId, profileId);
                    }
                    runInAction(() => {
                        this.bookingHistories = result;
                        this.calculateDiff();
                        this.translateValue();
                    })

                    // this.bookingHistories = this.bookingHistories.slice().reverse();
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    calculateDiff() {
        const excludePath = ["/VenueId", "/Id"];
        const currentProfile = profileStore.getCurrentProfile();
        const {booking} = bookingRequestStore;
        const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

        if (booking?.createdById !== currentProfile?.id) {
            excludePath.push("/notifyClient")
        }
        
        for (let i = 0; i < this.bookingHistories.length; i++) {
            if (i === 0) {
                this.bookingHistories[i].diff = observable([]);
                // this.bookingHistories[i].before = [];
            } else {
                let after = createPatch(JSON.parse(this.bookingHistories[i - 1].content), JSON.parse(this.bookingHistories[i].content));
                let before = createPatch(JSON.parse(this.bookingHistories[i].content), JSON.parse(this.bookingHistories[i - 1].content));

                after.forEach(x => {
                    const oldValue = before.filter(b => b.path === x.path);
                    if (oldValue && oldValue.length > 0) {
                        x.oldValue = oldValue[0].value;
                        x.label = capitalize(this.getLabel(x.path).replace("/", ""));
                    }
                });
                // console.log({before, after});
                this.bookingHistories[i].diff = observable(after.filter(x => excludePath.indexOf(x.path) === -1));
            }
        }
        // this.bookingHistories = this.bookingHistories.slice().reverse();
    }

    @action
    translateValue() {
        for (let i = 0; i < this.bookingHistories.length; i++) {
            const bookingHistory = this.bookingHistories[i];
            if (bookingHistory && bookingHistory.diff && bookingHistory.diff.length > 0) {
                for (let j = 0; j < bookingHistory.diff.length; j++) {
                    switch (bookingHistory.diff[j].path) {
                        case "/status":
                            bookingHistory.diff[j].value = this.translateStatus(bookingHistory.diff[j].value);
                            bookingHistory.diff[j].oldValue = this.translateStatus(bookingHistory.diff[j].oldValue);
                            break;

                        case "/actId":
                            this.translateActId(bookingHistory.diff[j]);
                            break;
                        case "/cancelledById":
                        case "/signedById":
                            this.translateProfileId(bookingHistory.diff[j]);
                            break;
                        case "/requirements":
                        case "/notes":
                        case "/contract":
                        case "/clientContract":
                        case "/cancellationReason":
                            bookingHistory.diff[j].isRichText = true;
                            break;
                        case "/bookingDate":
                        case "/dateStart":
                        case "/dateEnd":
                        case "/createdAt":
                        case "/acknowledgeAt":
                        case "/extraGigFeeApprovalAt":
                        case "/depositDueDate":
                        case "/balanceDueDate":
                        case "/cancelledAt":
                        case "/signedAt":
                            this.translateDate(bookingHistory.diff[j]);
                            break;
                        case "/notifyClient":
                            bookingHistory.diff[j].value = !!Number(bookingHistory.diff[j].value);
                            bookingHistory.diff[j].oldValue = !!Number(bookingHistory.diff[j].oldValue);
                            bookingHistory.diff[j].isRichText = true;
                            break
                        default:
                            break;
                    }
                }
            }
        }
    }

    @action
    translateActId(diff) {
        if (diff.value) {
            agent.Profile.get(diff.value).then(
                action(res => {
                    if (res) {
                        diff.value = res.actName;
                    }
                })
            );
        }

        if (diff.oldValue) {
            agent.Profile.get(diff.oldValue).then(
                action(res => {
                    if (res) {
                        diff.oldValue = res.actName;
                    }
                })
            );
        }
    }

    @action
    translateProfileId(diff) {
        if (diff.value) {
            agent.Profile.get(diff.value).then(
                action(res => {
                    if (res) {
                        switch (res.type) {
                            case ProfileType.Act.ordinal:
                                diff.value = res.actName;
                                break;
                            default:
                                diff.value = res.firstName + " " + res.lastName;
                                break;
                        }
                    }
                })
            );
        }

        if (diff.oldValue) {
            agent.Profile.get(diff.oldValue).then(
                action(res => {
                    if (res) {
                        switch (res.type) {
                            case ProfileType.Act.ordinal:
                                diff.oldValue = res.actName;
                                break;
                            default:
                                diff.oldValue = res.firstName + " " + res.lastName;
                                break;
                        }
                        //diff.oldValue = res.firstName + " " + res.lastName;
                    }
                })
            );
        }
    }

    @action
    translateDate(diff) {
        if (diff.value) {
            diff.value = moment(diff.value).format("DD-MM-YYYY hh:mm:ssa");
        }

        if (diff.oldValue) {
            diff.oldValue = moment(diff.oldValue).format("DD-MM-YYYY hh:mm:ssa");
        }
    }

    translateStatus(status) {
        const currentProfile = profileStore.getCurrentProfile()
        const booking = bookingRequestStore.booking
        switch (status) {
            case BookingStatusType.New.ordinal:
                return BookingStatusType.New.name;
            case BookingStatusType.Declined.ordinal:
                return BookingStatusType.Declined.name;
            case BookingStatusType.Hold.ordinal:
                return BookingStatusType.Hold.name;
            case BookingStatusType.Accepted.ordinal:
                return BookingStatusType.Accepted.name;
            case BookingStatusType.LineupSet.ordinal:
                return "Lineup Set";
            case BookingStatusType.SetlistSet.ordinal:
                return "Setlist Set";
            case BookingStatusType.Ready.ordinal:
                return "Confirmed";
            case BookingStatusType.Completed.ordinal:
                return BookingStatusType.Completed.name;
            case BookingStatusType.Paid.ordinal:
                return BookingStatusType.Paid.name;
            case BookingStatusType.Cancelled.ordinal:
                return BookingStatusType.Cancelled.name;
            case BookingStatusType.Resent.ordinal:
                return BookingStatusType.Resent.name;
            case BookingStatusType.Draft.ordinal:
                return BookingStatusType.Draft.name;
            case BookingStatusType.Incomplete.ordinal:
                return BookingStatusType.Incomplete.name;
            case BookingStatusType.CancelledByAct.ordinal:
                return "Cancelled by Act";
            case BookingStatusType.CancelledByActHistory.ordinal:
                return "Cancelled by Act";
            case BookingStatusType.SentToAct.ordinal:
                if (booking?.createdById == currentProfile?.id) {
                    return "Sent to Act";
                } else {
                    return "New"
                }
            case BookingStatusType.PlayerNotified.ordinal:
                return "Player Notified";
            default:
                return status;
        }
    }

    getLabel(path) {
        switch (path) {
            case "/ActId":
                return "Act";
            case "/CancelledById":
                return "Cancelled By";
            default:
                var res = path.replace(/[A-Z]/g, function (x) {
                    return " " + x;
                });
                //return res;
                return res.replace("/ ", "");
        }
    }
}

export default new BookingHistoryStore();
