import { observable, action, toJS } from "mobx";
import agent from "../agent";
import { Auth } from "aws-amplify";
import notificationStore from "./notificationStore";
import CacheHelper from "../helper/cache";
import profileStore from "./profileStore";
import authStore from "./authStore";
import templateStore from "./templateStore";

class UserStore {
    @observable currentUser;
    @observable loadingUser;
    @observable updatingUser;
    @observable updatingUserErrors;
    @observable activeUsers = [];
    @observable paginationToken = [""]
    @observable totalUsers = 0;
    @observable limit = 10;
    @observable legacyUsers = [];
    @observable totalLegacyUsers = 0;

    @observable isSigningIn = false;
    @observable isSigningUp = false;

    @observable errors = undefined;

    @observable calendars = [];
    @observable quickBooksCompany = "";
    @observable hasQuickBooksAccount = false;
    @observable loadingCalendar = true;
    @observable loadingQuickBooks = true;

    @action
    setLimitPage(limit) {
        this.limit = limit
    }

    @action
    isUserVerified() {
        return this.currentUser.email_verified;
    }

    @action
    saveUser(cognitoUser) {
        const user = this.wrapUser(cognitoUser);
        this.currentUser = user;
        CacheHelper.setUser(user);
        if (user && user.sub) {
            notificationStore.subscribe(user.sub);
        }
        return user;
    }
    
    @action
    addActAsUserFavourite(actId) {
       var data = {
          favouriteProfileId: actId,
       }
 
       return agent.UserFavourite.addUserFavourite(data).then(
          action(response => {
             return response;
          }),
          err => {
             return Promise.reject()
          }
       )
    }
 
    @action
    removeActAsUserFavourite(actId) {
       return agent.UserFavourite.removeUserFavourite(actId).then(
          action(response => {
             if (this.favouriteActs) {
                this.favouriteActs = this.favouriteActs.filter(
                   x => x.favouriteProfile && x.favouriteProfile.id !== actId
                )
             }
 
             return response
          }),
          err => {
             return Promise.reject()
          }
       )
    }

    @action
    saveUserLogin(cognitoUser) {
        const user = this.wrapUser(cognitoUser);
        this.currentUser = user;
        CacheHelper.setUser(user);
        return user;
    }

    @action
    saveSubscribeLogin() {
        const user = this.currentUser
        if (user && user.sub) {
            notificationStore.subscribe(user.sub);
        }
        return user;
    }
    @action
    saveUserFromCache(user) {
        this.currentUser = user;
        return user;
    }

    wrapUser(cognitoUser) {
        const user = cognitoUser.attributes;
        user.status = parseInt(user["custom:status"]);
        return user;
    }


    @action
    pullUser() {
        this.loadingUser = true;
        const cognitoUser = Auth.currentAuthenticatedUser({ bypassCache: true }).then(
            action(result => {
                const user = this.wrapUser(result);
                this.currentUser = user;
                CacheHelper.setUser(user);
                this.loadingUser = false;
                return user;
            })
        );
        return cognitoUser;

    }

    @action
    getUserCognito() {
        return agent.Auth.getUser()
            .then(
                action(cognitoUser => {
                    var user = {
                        "custom:status": cognitoUser.Attributes.filter(data => data.Name == "custom:status")[0].Value,
                        email: cognitoUser.Attributes.filter(data => data.Name == "email")[0].Value,
                        email_verified: true,
                        given_name: cognitoUser.Attributes.filter(data => data.Name == "given_name")[0]?.Value,
                        family_name: cognitoUser.Attributes.filter(data => data.Name == "family_name")[0].Value,
                        status: parseInt(cognitoUser.Attributes.filter(data => data.Name == "custom:status")[0].Value),
                        sub: cognitoUser.Attributes.filter(data => data.Name == "sub")[0].Value
                    }
                    CacheHelper.setUser(user);
                    this.currentUser = user;

                }))
    }

    @action
    updateUser(firstName, lastName, email) {
        this.errors = undefined;
        var data = {
            givenName: firstName,
            familyName: lastName,
            email: email
        }
        return agent.Auth.save(data)
            .then(
                action(cognitoUser => {
                    var user = {
                        "custom:status": cognitoUser.Attributes.filter(data => data.Name == "custom:status")[0].Value,
                        email: cognitoUser.Attributes.filter(data => data.Name == "email")[0].Value,
                        email_verified: true,
                        given_name: cognitoUser.Attributes.filter(data => data.Name == "given_name")[0].Value,
                        family_name: cognitoUser.Attributes.filter(data => data.Name == "family_name")[0].Value,
                        status: parseInt(cognitoUser.Attributes.filter(data => data.Name == "custom:status")[0].Value),
                        sub: cognitoUser.Attributes.filter(data => data.Name == "sub")[0].Value
                    }
                    CacheHelper.setUser(user);
                    this.currentUser = user;
                })
            )
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                })
            )
            .finally(
                action(() => {
                    this.updatingUser = false;
                })
            );
    }

    @action
    async impersonateAccount(cognitoUser) {
        this.forgetUser();
        templateStore.setActiveSidebarKey("dashboard");
        notificationStore.unsubscribe();
        CacheHelper.clearImageLinkCache();
        var user = {
            "custom:status": cognitoUser.status,
            email: cognitoUser.email,
            email_verified: true,
            given_name: cognitoUser.firstName,
            family_name: cognitoUser.lastName,
            status: parseInt(cognitoUser.status),
            sub: cognitoUser.id
        }
        CacheHelper.setUser(user);
        this.currentUser = user;
        if (user && user.sub) {
            notificationStore.subscribe(user.sub);
        }

        return user;
    }

    @action
    getActiveUser() {

        return agent.Profile.loadActiveUsers(this.limit, "")
            .then(
                action(response => {
                    this.activeUsers = response.users
                    this.totalUsers = response.total
                    this.paginationToken.push(response.paginationToken)
                    return response
                })
            )
    }

    @action
    getPrevNextActiveUser(state) {
        var pageToken = this.paginationToken[this.paginationToken.length - 1]

        return agent.Profile.loadActiveUsers(this.limit, pageToken)
            .then(
                action(response => {
                    this.activeUsers = response.users
                    this.paginationToken.push(response.paginationToken)
                    this.totalUsers = response.total
                    return response
                })
            )
    }

    @action
    searchActiveUser(value) {

        return agent.Profile.searchActiveUsers(value)
            .then(
                action(response => {
                    this.activeUsers = response
                    this.totalUsers = response.length
                    return response
                })
            )
    }

    @action
    getLegacyUser() {

        return agent.Profile.loadLegacyUsers(this.limit, 0)
            .then(
                action(response => {
                    this.legacyUsers = response.users
                    this.totalLegacyUsers = response.total
                    return response
                })
            )
    }

    @action
    getPrevNextLegacyUser(state) {
        return agent.Profile.loadLegacyUsers(this.limit, state)
            .then(
                action(response => {
                    this.legacyUsers = response.users
                    this.totalLegacyUsers = response.total
                    return response
                })
            )
    }

    @action
    searchLegacyUser(value) {

        return agent.Profile.searchLegacyUsers(value, this.limit, 0)
            .then(
                action(response => {
                    this.legacyUsers = response.users
                    this.totalLegacyUsers = response.total
                    return response
                })
            )
    }

    @action
    updateTable(activeUsers) {
        this.activeUsers = activeUsers;
    }

    @action
    completeUser() {
        this.updatingUser = true;
        return agent.Auth.complete()
            .then(
                action(user => {
                    return this.pullUser().then((response) => {
                        return response;
                    });
                    // this.currentUser = user;
                })
            )
            .finally(
                action(() => {
                    this.updatingUser = false;
                })
            );
    }

    @action
    forgetUser() {
        this.currentUser = undefined;
        CacheHelper.setUser(undefined);
        this.calendars = [];
    }

    @action
    setSignIn(signIn) {
        this.isSigningIn = signIn;
    }

    @action
    setSignUp(signUp) {
        this.isSigningUp = signUp;
    }

    @action
    setGoogleCalendarId(googleCalendarId) {
        this.currentUser.googleCalendarId = googleCalendarId;
    }

    @action
    getGoogleCalendars() {
        if (this.currentUser) {
            this.loadingCalendar = true;

            return agent.Profile.getGoogleCalendars()
                .then(
                    action(result => {
                        if (result) {
                            this.calendars = result;
                            if (!this.currentUser.googleCalendarId) {
                                var primary = result.filter(x => x.primary);
                                if (primary) {
                                    this.calendarId = primary[0].id;
                                }
                            }
                        }
                    })
                )
                .finally(
                    action(() => {
                        this.loadingCalendar = false;
                    })
                );
        }
    }

    @action
    getQuickBooks() {
        if (this.currentUser) {
            this.loadingQuickBooks = true;

            return agent.Profile.getQuickBooks()
                .then(
                    action(result => {
                        if (result) {
                            this.quickBooksCompany = result.companyName;
                            this.hasQuickBooksAccount = true;
                        }
                    })
                )
                .catch(err => {
                    console.error("Error loading quickbooks", err);
                })
                .finally(
                    action(() => {
                        this.loadingQuickBooks = false;
                    })
                );
        }
    }

    @action
    unlinkGoogleAccount() {
        if (this.currentUser) {
            this.loadingCalendar = true;
            this.currentUser.googleAccessToken = undefined;
            this.currentUser.googleRefreshToken = undefined;

            return agent.Profile.unlinkGoogleCalendars()
                .then(
                    action(result => {
                        this.calendars = [];
                    })
                )
                .finally(
                    action(() => {
                        this.loadingCalendar = false;
                    })
                );
        }
    }

    @action
    unlinkQuickBooksAccount() {
        if (this.currentUser) {
            // this.loadingQuickBooks = true;
            this.quickBooksCompany = "";
            this.hasQuickBooksAccount = false;

            // return agent.Profile.unlinkGoogleCalendars()
            //     .then(
            //         action(result => {
            //             this.calendars = [];
            //         })
            //     )
            //     .finally(
            //         action(() => {
            //             this.loadingCalendar = false;
            //         })
            //     );
        }
    }

    @action
    submitIntegrations() {
        if (this.currentUser) {
            this.updatingUser = true;
            return agent.Auth.updateIntegration(toJS(this.currentUser))
                .then()
                .finally(
                    action(() => {
                        this.updatingUser = false;
                    })
                );
        }
    }

    @action
    setCurrentUserVerified() {
        if (this.currentUser) {
            this.currentUser.isVerified = true;
        }
    }
}

export default new UserStore();
