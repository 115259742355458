import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Heading from "../../components/Heading";
import Breadcrumb from "../../components/Breadcrumb";
import BookingForm from "../../components/booking/BookingForm";
import "./Booking.css";
import queryString from "query-string";
import BookingStatusBadge from "../../components/booking/BookingStatusBadge";
import { ProfileType, BookingStatusType, BookingType } from "../../types/enum";
import moment from "moment";

import { Paper, Typography } from "@material-ui/core";
@inject("profileStore", "bookingRequestStore", "templateStore")
class BookingProcess extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Booking Request" }];

    constructor(props) {
        super(props);
        this.state = {
            titleHeader: ""
        }
    }
    
    
    componentDidMount() {
        this.props.bookingRequestStore.resetBooking()
        let params = queryString.parse(this.props.location.search);
        if (!this.props.match.params.id) {
            this.props.bookingRequestStore.setBooking("dateStart", moment(params.dateStart, "DD-MM-YYYY"));
            var title = this.props.bookingRequestStore.setBookingHeading();
            this.setState({titleHeader: title})
            this.props.bookingRequestStore.setActId(params.actId);
            this.props.bookingRequestStore.setVenueId(params.venueId);
        }
    }

    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    getBookingStatus = () => {
        return "Draft";
    };

    render() {
        const { bookingHeading } = this.props.bookingRequestStore;
        let renderWidgetContainerAction = () => (
            <Typography color="primary" variant="subheading" align="right">
                {this.getParameterByName("date")}
            </Typography>
        );

        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title={this.state.titleHeader}><div style={{ display: "inline-block" }}>
                        <BookingStatusBadge cancelledBy={0} status="Draft" />
                    </div></Heading>
                    <Paper className="org-paper-form">
                        <BookingForm
                            actId={this.getParameterByName("actId")}
                            venueId={this.getParameterByName("venueId")}
                            dateStart={this.getParameterByName("dateStart")}
                            dateEnd={this.getParameterByName("dateEnd")}
                        />
                    </Paper>
                </MainContainer>
            </main>
        );
    }
}

export default BookingProcess;
