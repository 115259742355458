import React, { Component, Fragment } from "react";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import MusicianVenueTable from "../../components/musician/MusicianVenueTable";
import { Grid, Typography, Paper } from "@material-ui/core";

class Venues extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "My Venues" }];
    render() {
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} /> <Heading title="My Venue" />
                    <Paper style={{ padding: 16 }}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} lg={3}>
                                <div className="sticky-information">
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        My Venues
                                    </Typography>
                                    <Typography gutterBottom className="side-helper-text">
                                        Use this section to manage which venues have added you to their favourite act list and also have access to
                                        your calendar and be able to book your acts.
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9} style={{ position: "relative" }}>
                                <MusicianVenueTable />
                            </Grid>
                        </Grid>
                    </Paper>
                </MainContainer>
            </Fragment>
        );
    }
}

export default Venues;
