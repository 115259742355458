import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";

import General from "../../components/profile/General";
import Design from "../../components/profile/Design";
import Social from "../../components/profile/Social";
import Privacy from "../../components/profile/Privacy";
import TermCondition from "../../components/profile/TermCondition";
import Member from "../../components/profile/Member";
import VenueActRoster from "../../components/profile/VenueActRoster";
import Financial from "../../components/profile/Financial";

import "../stepper.css";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import VenueMenu from "../../components/profile/VenueMenu";

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("generalProfileStore","templateStore", "profileStore","googleCalendarStore", "instagramStore")
@withRouter
@observer
class EditVenue extends Component {
    breadcrumb = [
        { name: "Dashboard", link: "/staff" }, 
        { name: "Venues", link: "/venues" }, 
        { name: "Edit" }
    ];
    state = {
        profileId : null
    };
    componentDidMount = () => {     
        if(this.props.match.params.id === "calendar"){
            const query = new URLSearchParams(this.props.location.search);
            const profileId = query.get('state')
            this.setState({ profileId: profileId });
            const token = query.get('code')
            this.props.googleCalendarStore.setGoogleCalendarCode(token);
            this.props.templateStore.setEditProfileActiveStep(3);
        }else if(this.props.match.params.id === "instagram"){
            const query = new URLSearchParams(this.props.location.search);
            const profileId = query.get('state')
            this.setState({ profileId: profileId });
            const token = query.get('code')
            this.props.instagramStore.setInstagramCode(token);
            this.props.templateStore.setEditProfileActiveStep(3);
        }else{
            this.setState({ profileId: this.props.match.params.id  });
            switch (this.props.match.params.tab) {
                case "details":
                    this.props.templateStore.setEditProfileActiveStep(0);
                    break;
                case "design":
                    this.props.templateStore.setEditProfileActiveStep(1);
                    break;
                case "financial":
                    this.props.templateStore.setEditProfileActiveStep(2);
                    break;
                case "social":
                    this.props.templateStore.setEditProfileActiveStep(3);
                    break;
                case "member":
                    this.props.templateStore.setEditProfileActiveStep(4);
                    break;
                case "favourite":
                    this.props.templateStore.setEditProfileActiveStep(5);
                    break;
                case "menu":
                    this.props.templateStore.setEditProfileActiveStep(6);
                    break;
                case "contract":
                    this.props.templateStore.setEditProfileActiveStep(7);
                    break;
                case "privacy":
                    this.props.templateStore.setEditProfileActiveStep(8);
                    break;
                default:
                    this.props.templateStore.setEditProfileActiveStep(0);
                    break;
            }
        }
       
    };

    componentDidUpdate = () => {
        switch (this.props.match.params.tab) {
            case "details":
                this.props.templateStore.setEditProfileActiveStep(0);
                break;
            case "design":
                this.props.templateStore.setEditProfileActiveStep(1);
                break;
            case "financial":
                this.props.templateStore.setEditProfileActiveStep(2);
                break;
            case "social":
                this.props.templateStore.setEditProfileActiveStep(3);
                break;
            case "member":
                this.props.templateStore.setEditProfileActiveStep(4);
                break;
            case "favourite":
                this.props.templateStore.setEditProfileActiveStep(5);
                break;
            case "menu":
                this.props.templateStore.setEditProfileActiveStep(6);
                break;
            case "contract":
                this.props.templateStore.setEditProfileActiveStep(7);
                break;
            case "privacy":
                this.props.templateStore.setEditProfileActiveStep(8);
                break;
            default:
                this.props.templateStore.setEditProfileActiveStep(0);
                break;
        }
    };

    handleStepClick = step => () => {
        const {profileId} = this.state
        switch (step) {
            case 0:
                this.props.history.replace(`/venues/${profileId}/edit/details`);
                // this.props.templateStore.setEditProfileActiveStep(step);
                break;
            case 1:
                this.props.history.replace(`/venues/${profileId}/edit/design`);
                break;
            case 2:
                this.props.history.replace(`/venues/${profileId}/edit/financial`);
                break;
            case 3:
                this.props.history.replace(`/venues/${profileId}/edit/social`);
                break;
            case 4:
                this.props.history.replace(`/venues/${profileId}/edit/member`);
                break;
            case 5:
                this.props.history.replace(`/venues/${profileId}/edit/favourite`);
                break;
            case 6:
                this.props.history.replace(`/venues/${profileId}/edit/menu`);
                break;
            case 7:
                this.props.history.replace(`/venues/${profileId}/edit/contract`);
                break;
            case 8:
                this.props.history.replace(`/venues/${profileId}/edit/privacy`);
                break;
            default:
                this.props.history.replace(`/venues/${profileId}/edit`);
                break;
        }
    };

    getStepContent(stepIndex) {
        const {profileId} = this.state

        var paramId =profileId
        if(!profileId){
            paramId = this.props.match.params.id
        }
        switch (stepIndex) {
            case 0:
                return (
                    <General
                        type="venue"
                        profileId={profileId}
                        regionId={this.props.match.params.regionId ? this.props.match.params.regionId : null}
                    />
                );
            case 1:
                return <Design type="venue" profileId={paramId} />;
            case 2:
                return <Financial type="venue" profileId={paramId} />;
            case 3:
                return <Social type="venue" profileId={paramId} />;
            case 4:
                return <Member type="venue" profileId={paramId} />;
            case 5:
                return <VenueActRoster type="venue" profileId={paramId} />;
            case 6:
                return <VenueMenu type="venue" profileId={paramId}  />
            case 7:
                return <TermCondition type="venue" profileId={paramId}  />;
            case 8:
                return <Privacy type="venue" profileId={paramId} />;
            default:
                return "Uknown stepIndex";
        }
    }

    handleNext = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
    };

    handleBack = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleReset = () => {
        this.props.templateStore.setEditProfileActiveStep(0);
    };

    render() {
        const { classes } = this.props;
        const steps = this.props.templateStore.getSteps("venue");
        const { editProfileActiveStep } = this.props.templateStore;
        const { profile, venueNameTitle } = this.props.profileStore;
        const {profileId} = this.state
        // const { venueNameTitle } = this.props.generalProfileStore;
        // const {} = this.props.
        
        return (
           
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title={venueNameTitle} />
                    <Grid container spacing={8}>
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                            <Hidden smDown>
                                <Stepper
                                    nonLinear={profileId ? true : false}
                                    activeStep={editProfileActiveStep}
                                    alternativeLabel={false}
                                >
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={label}>
                                                <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Hidden>
                            <Hidden mdUp xsDown>
                                <Stepper nonLinear={profile && profile.isCompleted} activeStep={editProfileActiveStep} alternativeLabel={true}>
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={label}>
                                                <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Hidden>
                            <Hidden smUp>
                                <Stepper
                                    nonLinear={profile && profile.isCompleted}
                                    activeStep={editProfileActiveStep}
                                    alternativeLabel={false}
                                    className="stepperXs"
                                >
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={label}>
                                                <StepButton onClick={this.handleStepClick(index)} />
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Hidden>
                            {editProfileActiveStep === steps.length ? (
                                <Paper className="stepperContent">
                                    <Typography className={classes.instructions} component="h3">
                                        All steps completed - you're profile is ready!
                                    </Typography>
                                    <Button onClick={this.handleReset}>Reset</Button>
                                </Paper>
                            ) : (
                                <Paper className="stepperContent">{this.getStepContent(editProfileActiveStep)}</Paper>
                            )}
                        </Grid>
                    </Grid>
                </MainContainer>
            </div>
        );
    }
}

EditVenue.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(EditVenue);
