import { observable, action } from "mobx";
import agent from "../agent";
import moment from "moment";
import { actionRow } from "aws-amplify";

class EventStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable profileId = 0;

    @observable name = "";
    @observable from = new Date();
    @observable to = new Date();
    @observable allDay = true;
    @observable events = [];
    @observable venueEventsList = [];
    
    @action
    resetEvents() {
        this.events = [];
    }

    @action
    mapData(data) {
        this.id = data.id || 0;
        this.name = data.name || "";
        this.from = (data.from && moment(data.from)) || new Date();
        this.to = (data.to && moment(data.to)) || new Date();
        this.allDay = data.allDay || false;
        this.profileId = data.profileId || 0;
    }

    @action
    loadEventById(id) {
        this.inProgress = true;
        return agent.Event.get(id)
            .then(
                action(result => {
                    if (result) {
                        this.mapData(result);
                    }
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }
    
    @action
    loadEventsByProfileIdVenue(profileId) {
        this.inProgress = true;
        return agent.Event.getByProfileId(profileId)
            .then(
                action(results => {
                    if (results) {
                        this.venueEventsList = results;
                        this.venueEventsList.forEach(event => {
                            this.mapEvent(event);
                        });
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    loadEventsByProfileId(profileId) {
        this.inProgress = true;
        return agent.Event.getByProfileId(profileId)
            .then(
                action(results => {
                    if (results) {
                        this.events = results;
                        this.events.forEach(event => {
                            this.mapEvent(event);
                        });
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    resetEvent(){
        this.events =  []
    }
    @action
    loadEventsByProfileIds(profileIds) {
        var params = [];
        for(var i = 0;i< profileIds.length;i++){
            var data = profileIds[i]
            if(data){
                params.push(data)
            }
        }
        this.inProgress = true;
        return agent.Event.getByProfileIds(params)
            .then(
                action(results => {
                    if (results) {
                        this.events = results;
                        this.events.forEach(event => {
                            this.mapEvent(event);
                        });
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    reset() {
        this.name = "";
        this.from = "";
        this.to = "";
        this.allDay = true;
        this.profileId = 0;
        this.id = 0;
    }

    @action
    setId(id) {
        this.id = id;
    }

    @action
    setName(name) {
        this.name = name;
    }

    @action
    setFrom(from) {
        this.from = from;
    }

    @action
    setTo(to) {
        this.to = to;
    }

    @action
    setAllDay(allDay) {
        this.allDay = allDay;
    }

    @action
    setProfileId(profileId) {
        this.profileId = profileId;
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;

        const event = {
            id: this.id,
            profileId: this.profileId,
            name: this.name,
            from: this.allDay ? moment(this.from).add(moment().utcOffset(), "m") : moment(this.from),
            to: this.allDay ? moment(this.to).add(moment().utcOffset(), "m") : moment(this.to),
            allDay: this.allDay
        };

        moment.utc();
        return (this.id ? this.updateEvent(event) : this.createEvent(event))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    delete() {
        this.inProgress = true;
        this.errors = undefined;
        if (this.id) {
            agent.Event.delete(this.id)
                .then(
                    action(event => {
                        for (let i = 0; i < this.events.length; i++) {
                            if (this.events[i].id === this.id) {
                                this.events.splice(i, 1);
                                break;
                            }
                        }
                        return event;
                    })
                )
                .finally(
                    action(() => {
                        this.inProgress = false;
                    })
                );
        }
    }

    @action
    createEvent(data) {
        return agent.Event.create(data).then(
            action(event => {
                event.from = moment(event.from).add(moment().utcOffset(), "m")
                event.to = moment(event.to).add(moment().utcOffset(), "m")
                this.mapEvent(event);
                this.events.push(event);
                return event;
            })
        );
    }

    @action
    updateEvent(data) {
        return agent.Event.update(data).then(
            action(event => {
                event.from = moment(event.from).add(moment().utcOffset(), "m")
                event.to = moment(event.to).add(moment().utcOffset(), "m")
                this.mapEvent(event);

                for (let i = 0; i < this.events.length; i++) {
                    if (this.events[i].id === event.id) {
                        this.events[i] = event;
                        break;
                    }
                }
                return event;
            })
        );
    }

    mapEvent(event) {
        event.title = event.name === "" ? "[untitled]" : event.name;
        event.start = new Date(event.from);
        event.end = new Date(event.to);
        event.type = "availability";
        event.id = parseInt(event.id);
        
        let endHour = event.end.getHours();
        let endMinute = event.end.getMinutes();

        // add 1 minute to end time for react-big-calendar display purpose only in month view
        // real event.end time will be loaded from backend upon edit/click event
        if (endHour === 0 && endMinute === 0) {
            event.end.setMinutes(1);
        }
    }
}

export default new EventStore();
