import React, { Component, Fragment } from "react";
import { inject } from "mobx-react";
import { Grid, Badge, DialogActions, DialogContent, Button, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import group from "../../img/group.svg";
import solo from "../../img/singer.svg";
import invitation from "../../img/invitation.svg";

import "./ChooseProfile.css";
import { ProfileRelationType } from "../../types/enum";
import ModalContainer from "../modal/ModalContainer";
import ResponsiveTable from "../table/ResponsiveTable";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import TableRowProfile from "../util/TableRowProfile";
import SimpleSnackbar from "../snackBar/snackbar";
import image from "../../helper/image";

@inject("profileStore", "userStore", "userInvitationStore", "templateStore")
@withRouter
class CreateAct extends Component {
    invitations = [];

    columns = [];

    rows = [];

    constructor(props) {
        super(props);
        this.state = {
            invitationsModalState: false,
            isLoading: true,
            disabled: false
        };

        this.columns = [{ key: "invitee", label: "Invitee" }, { key: "actName", label: "Act Name" }, { key: "action", label: "" }];

        this.rows = [];
    }

    mapInvitations = () => {
        this.rows = [];

        this.invitations.forEach(invitation => {
            let row = {};

            row.invitee = (
                <TableRowProfile
                    profileId = { invitation.createdById }
                    name={invitation.createdBy.fullName}
                    avatarUrl={image.getProfileImage(invitation.createdBy)}
                />
            );
            row.actName = invitation.invitedAct;

            row.action = (
                <Fragment>
                    <Button variant="contained" size="small" color="primary" onClick={() => this.handleAccept(invitation)}>
                        Accept and Create Act
                    </Button>
                    &nbsp;
                    <Button variant="outlined" size="small" onClick={() => this.handleDecline(invitation)}>
                        Decline
                    </Button>
                </Fragment>
            );

            this.rows.push(row);
        });
    };

    componentDidMount = () => {
        this.props.profileStore.getProfileAct().then(profile => {
           if(profile){
                this.props.history.push(`/myact/edit/${profile.id}`);
           }else{
            Promise.all([
                this.props.userInvitationStore.getUserInvitationByEmailAndType(ProfileRelationType.AgentAct.ordinal).then(response => {
                    response.forEach(data => {
                        this.invitations.push(data);
                    });
                }),
                this.props.userInvitationStore.getUserInvitationByEmailAndType(ProfileRelationType.VenueAct.ordinal).then(response => {
                    response.forEach(data => {
                        this.invitations.push(data);
                    });
                })
            ]).then(
                () => {
                    this.setState(
                        {
                            ...this.state,
                            isLoading: false
                        },
                        () => {
                            this.mapInvitations();
                        }
                    );
                },
                err => {
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                }
            );
           }
        })
       
    };

    handleOpenInvitationsModal = () => {
        this.setState({ ...this.state, invitationsModalState: true });
    };

    handleCloseInvitationsModal = () => {
        this.setState({ ...this.state, invitationsModalState: false });
    };

    handleCreateAct = e => {
        e.preventDefault();
        this.props.history.replace(`/myact/create`);
    };

    handleCreateSoloAct = e => {
        e.preventDefault();
        if (!this.state.disabled) {
            this.props.profileStore.createSoloAct().then(profile => {
                this.props.history.push(`/myact/edit/${profile.id}`);
            });
        }
        this.setState({ disabled: true });
    };

    handleGoToDashboard = e => {
        e.preventDefault();
        const { currentUser } = this.props.userStore;
        const status = currentUser.status;
        this.props.userStore.completeUser().then(user => {
            switch (status) {
                case 1:
                    this.props.history.push("/musician");
                    break;
                case 11:
                    this.props.history.push("/agent");
                    break;
                case 21:
                    this.props.history.push("/staff");
                    break;
                default:
                    this.props.history.push("/account/setting");
                    break;
            }
        });
    };

    handleAccept = invitation => {
        this.setState({
            ...this.state,
            isLoading: true
        });
        this.props.userInvitationStore.acceptUserInvitation(invitation.id).then(response => {
            this.invitations = this.invitations.filter(x => x.id !== response.id);

            if (response.newProfileId) {
                this.props.templateStore.openSnackbar("Invitation has been accepted.");
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.userStore.completeUser().then(user => {
                    this.props.history.push(`/myact/edit/${response.newProfileId}`);
                });
               
            } else {
                this.mapInvitations();

                this.props.templateStore.openSnackbar("Invitation has been accepted.");

                this.setState({
                    ...this.state,
                    isLoading: false
                });
            }
        });
    };

    handleDecline = invitation => {
        this.setState({
            ...this.state,
            isLoading: true
        });
        this.props.userInvitationStore.declineUserInvitation(invitation.id).then(response => {
            this.invitations = this.invitations.filter(x => x.id !== response.id);
            this.mapInvitations();

            this.props.templateStore.openSnackbar("Invitation has been declined.");

            this.setState({
                ...this.state,
                isLoading: false
            });
        });
    };

    render() {
        const { invitationsModalState, isLoading } = this.state;
        return (
            <Fragment>
                <Grid container spacing={24} justify="center">
                    <Grid item xs={12}>
                        <Grid container justify="center">
                            {this.invitations.length === 0 && (
                            <div className="type">
                                <Button id="create-act-btn" onClick={this.handleCreateAct}>
                                    <Typography component="div" type="subheading" color="inherit">
                                        <div className="type-image">
                                            <img src={group} alt="Create Act" />
                                        </div>
                                        <span className="title">Create Act</span>
                                        <span className="subtitle">(For Duos / Bands)</span>

                                    </Typography>
                                </Button>
                            </div>
                            )}
                            {this.invitations.length === 0 && (
                            <div className="type">
                                <Button onClick={this.handleCreateSoloAct} disabled={this.state.disabled}>
                                    <Typography component="div" type="subheading" color="inherit">
                                        <div className="type-image">
                                            <img src={solo} alt="Create Solo Act" />
                                        </div>
                                        <span className="title">Create Solo Act</span>
                                        <span className="subtitle">(Copies all entertainer profile info)</span>

                                    </Typography>
                                </Button>
                            </div>
                            )}
                            {this.invitations.length > 0 && (
                                <div className="type">
                                    <Button onClick={this.handleOpenInvitationsModal}>
                                        <Typography component="div" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={invitation} alt="Act Invitations" />
                                            </div>
                                            <Badge color="secondary" badgeContent={this.invitations.length}>
                                                <span className="title">Act Invitations</span>
                                            </Badge>
                                        </Typography>
                                    </Button>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {this.invitations.length === 0  && (
                            <Grid container justify="center">
                                <Button variant="contained" color="primary" onClick={this.handleGoToDashboard}>
                                    Skip this step and take me to the dashboard.
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <LoadingSpinner showed={isLoading} />
                <SimpleSnackbar />
                <ModalContainer open={invitationsModalState} onClose={this.handleCloseInvitationsModal} title="Act Invitations">
                    <DialogContent>
                        <ResponsiveTable columns={this.columns} rows={this.rows} paginations={this.rows.length > 5} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseInvitationsModal}>Close</Button>
                    </DialogActions>
                </ModalContainer>
            </Fragment>
        );
    }
}

export default CreateAct;
