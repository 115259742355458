import React, { Component } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import TableWidget from "../widget/TableWidget.js";

import WidgetContainer from "../widget/WidgetContainer.js";

import { Grid, Select, Button, Hidden } from "@material-ui/core";

import "./UpcomingBooking.css";

class UpcomingBooking extends Component {
    state = {
        venue: "null",
        consultant: "null"
    };

    tableData = {
        title: ["Date", "Venue", "Address", "Artist", "Time", "Consultant", ""],
        data: [
            {
                id: 1,
                date: "Monday, Jan 5th",
                venue: "Jupiter's Casino",
                address: "Broadbeach, Gold Coast",
                artist: "Vertigo, 3 Piece",
                time: "6.00pm - 9.00pm",
                consultant: "Allan Discole"
            },
            {
                id: 2,
                date: "Tuesday, Jan 6th",
                venue: "Pig'n Whistle",
                address: "Broadbeach, Gold Coast",
                artist: "Vertigo, 3 Piece",
                time: "6.00pm - 9.00pm",
                consultant: "Allan Discole"
            },
            {
                id: 3,
                date: "Wednesday, Jan 57th",
                venue: "Jupiter's Casino",
                address: "Broadbeach, Gold Coast",
                artist: "Vertigo, 3 Piece",
                time: "6.00pm - 9.00pm",
                consultant: "Allan Discole"
            }
        ]
    };

    handleVenueChange = event => {
        this.setState({ venue: event.target.value });
    };

    handleConsultantChange = event => {
        this.setState({ consultant: event.target.value });
    };

    render() {
        return (
            <WidgetContainer title="Upcoming Bookings" showBadge={true} badgeValue={45}>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={2}>
                        <FormControl fullWidth>
                            <Select
                                value={this.state.venue}
                                onChange={this.handleVenueChange}
                                inputProps={{
                                    name: "venue",
                                    id: "venue"
                                }}
                            >
                                <MenuItem value="null">
                                    <em>Select Venue</em>
                                </MenuItem>
                                <MenuItem value="Jupiter's Casino">Jupiter's Casino</MenuItem>
                                <MenuItem value="Pig'n Whistle">Pig'n Whistle</MenuItem>
                                <MenuItem value="Grand Hyatt">Grand Hyatt</MenuItem>
                                <MenuItem value="Liars Bar">Liars Bar</MenuItem>
                                <MenuItem value="Avenue - Surfer's Paradise">Avenue - Surfer's Paradise</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <FormControl fullWidth>
                            <Select
                                value={this.state.consultant}
                                onChange={this.handleConsultantChange}
                                inputProps={{
                                    name: "consultant",
                                    id: "consultant"
                                }}
                            >
                                <MenuItem value="null">
                                    <em>Select Consultant</em>
                                </MenuItem>
                                <MenuItem value="Allan Discole">Allan Discole</MenuItem>
                                <MenuItem value="Barney Stinson">Barney Stinson</MenuItem>
                                <MenuItem value="Ted Mosby">Ted Mosby</MenuItem>
                                <MenuItem value="Robin Tscherbasky">Robin Tscherbasky</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Hidden mdUp>
                            <div className="border-bottom-grid">
                                <Button variant="contained" className="button-filter">
                                    Filter
                                </Button>
                            </div>
                        </Hidden>
                        <Hidden smDown>
                            <Button variant="contained" className="button-filter">
                                Filter
                            </Button>
                        </Hidden>
                    </Grid>

                    <Grid item xs={12} md={6} className="text-xs-left text-md-right">
                        <Button variant="contained" className="button-date-filter active">
                            Today
                        </Button>
                        &nbsp;
                        <Button variant="contained" className="button-date-filter">
                            This Week
                        </Button>
                        &nbsp;
                        <Button variant="contained" className="button-date-filter">
                            This Month
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <TableWidget tableData={this.tableData} />
                    </Grid>
                </Grid>
            </WidgetContainer>
        );
    }
}

export default UpcomingBooking;
