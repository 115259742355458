import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button } from "@material-ui/core";
import Heading from "../../Heading.js";

import StaffActRosterTable from "./StaffActRosterTable";
import StaffActRosterInvitationModal from "./StaffActRosterInvitationModal.js";

import Loading from "../../loadingSpinner/Loading";

import { ProfileRelationType } from "../../../types/enum";

@inject("venueActStore", "templateStore", "userInvitationStore", "profileStore")
@observer
class StaffActRosterContainer extends Component {
    state = {
        invitationModalState: false,
        isLoading: true
    };

    constructor(props) {
        super(props);
        this.props.venueActStore.resetList();
        this.props.userInvitationStore.resetInvitations();
    }

    componentDidMount() {
        this.setState(
            {
                ...this.state,
                isLoading: true
            },
            () => {
                this.props.profileStore.loadProfiles().then(() => {
                    Promise.all([
                        this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.VenueAct.ordinal),
                        this.props.venueActStore.getStaffPendingActRoster(),
                        this.props.venueActStore.getStaffActRoster()
                    ]).then(() => {
                        this.setState({
                            ...this.state,
                            isLoading: false
                        });
                    });
                })
            }
        );
    }

    openInvitationModal = () => {
        this.setState({ ...this.state, invitationModalState: true });
    };

    closeInvitationModal = () => {
        this.setState({ ...this.state, invitationModalState: false });
    };

    render() {
        var currentProfile = this.props.profileStore.getCurrentProfile();
        return (
            <Fragment>
                <Heading title="My Act Roster">
                 {
                        !(currentProfile?.viewOnly) && (currentProfile?.allowBooking)? 
                        (
                            <Button color="primary" variant="contained" onClick={this.openInvitationModal} classes={{ root: "pull-right" }}>
                                Add Act
                            </Button>
                        )
                        :
                        ""
                }
                
                </Heading>

                <div style={{ position: "relative" }}>
                    <Loading showed={this.state.isLoading} borderRadius={0} />
                    <StaffActRosterTable allowBooking={currentProfile?.allowBooking}/>
                </div>

                <StaffActRosterInvitationModal open={this.state.invitationModalState} onClose={this.closeInvitationModal} />
            </Fragment>
        );
    }
}

export default StaffActRosterContainer;
