import React, { Component } from "react";
import { TextField, MenuItem, ListItemText } from "@material-ui/core";
import moment from "moment";
import "moment-parseplus";
import Autocomplete from "react-autocomplete";

import "./HubTimepicker.css";

class HubTimepicker extends Component {
    state = {
        value: undefined,
        valueText: undefined,
        duration: "",
        open: false,
        timepickerOpen: false,
        times: []
    };

    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate", this.props.value);
        if (this.props.value !== prevProps.value) {
            const text = this.props.value ? moment(this.props.value).format("h:mma") : "";
            const duration = this.props.start ? "(" + moment(this.props.value).diff(this.props.start, "hours", true) + " hours)" : "";
            this.setState({ value: this.props.value, valueText: text, duration: duration });
            this.initializeHours();
        }
    }

    componentDidMount() {
        this.initializeHours();
        const text = this.props.value ? moment(this.props.value).format("h:mma") : "";
        const duration = this.props.start ? "(" + moment(this.props.value).diff(this.props.start, "hours", true) + " hours)" : "";
        this.setState({ value: this.props.value, valueText: text, duration: duration });
    }

    initializeHours = () => {
        // console.log("initialize hours", this.props.start);
        let times = [];
        if (this.props.start) {
            // const startHour = moment(this.props.start).hour();
            //      console.log("start time", startHour);
            for (let i = 0; i < 24; i++) {
                for (let j = 0; j <= 30; j = j + 30) {
                    times.push(
                        moment(this.props.start)
                            .add(i, "hour")
                            .minute(j)
                    );
                }
            }
        } else {
            for (let i = 0; i < 24; i++) {
                for (let j = 0; j <= 30; j = j + 30) {
                    times.push(
                        moment()
                            .hour(i)
                            .minute(j)
                    );
                }
            }
        }
        this.setState({ times: times });
    };

    setOpen = ({ open }) => {
        if (this.props.readOnly) {
            return false;
        }
        this.setState({ timepickerOpen: open });
    };

    handleToggleOpen = event => {
        if (this.props.readOnly) {
            return false;
        }
        this.setState({
            timepickerOpen: !this.state.timepickerOpen
        });
        console.log("togle open");
    };

    handleChange = (e, value) => {
        // this.setState({ open: true });
        // const timeDifference = Math.abs(e.diff(this.props.value));
        // if (timeDifference < moment.duration(1, "hour") && timeDifference >= moment.duration(1, "minute")) {
        //     this.setOpen({ open: false });
        // }
        // console.log("value", e, value);
        this.setState({
            valueText: value,
            open: true
        });

        // this.props.onChange(value);
    };

    calculateDisabledHour = () => {
        const { min } = this.props;
        let disabledHour = [];
        if (min) {
            var minTime = moment(min, "HH:mm");
            var minHour = minTime.hour();
            for (var i = 0; i < minHour; i++) {
                disabledHour.push(i);
            }
        }
        return disabledHour;
    };

    calculateDisabledMinute = () => {
        const { min, value } = this.props;
        let disabledMinutes = [];
        if (min) {
            var minTime = moment(min, "HH:mm");
            var valueTime = moment(value, "HH:mm");
            var minHour = minTime.hour();
            var minHourValue = valueTime.hour();
            if (minHour === minHourValue) {
                var minMinute = minTime.minute();
                for (var i = 0; i < minMinute; i++) {
                    disabledMinutes.push(i);
                }
            }
        }
        return disabledMinutes;
    };

    renderMenu = children => {
        const { menuStyle } = this.props;
        return (
            <div
                className="menu timepicker-menu"
                style={menuStyle ? menuStyle : { position: "absolute", zIndex: "9999", width: "100%", maxHeight: "300px", overflowY: "auto" }}
            >
                {children}
            </div>
        );
    };

    handleOnBlur = e => {
        //console.log("onbluer", e.target.value);

        // we need to do this timeout trick otherwise onblur will close the menu before we can fire onselect
        setTimeout(() => {
            this.setState({
                open: false
            });
        }, 200);

        const valueText = moment(this.props.value).format("h:mma");


        if (e.target.value !== valueText) {
            let time = moment(e.target.value);
            if (time && time.isValid()) {
                const newTime = moment(this.props.value)
                    .hour(time.hour())
                    .minute(time.minute());
                this.props.onChange(newTime);

                //  console.log("parse", time);
            } else {
                console.log("invalid hour", time);
                this.props.onChange(this.props.value);
            }
        } else {
            //  console.log("no change", e.target.value, valueText);
        }
    };

    handleOnFocus = e => {
        //console.log("onfocus", e);
        if (!this.props.readOnly) {
            this.setState({
                open: true
            });
        }
    };

    renderInput = props => {
        const { ref, ...rest } = props;

        return (
            <TextField
                {...rest}
                inputRef={ref}
                // value={this.state.valueText}
                onBlur={this.handleOnBlur}
                onFocus={this.handleOnFocus}
                InputProps={{
                    readOnly: this.props.readOnly
                }}
                // InputProps={{
                //     endAdornment: this.state.loading ? (
                //         <InputAdornment position="start">
                //             <CircularProgress size={30} />
                //         </InputAdornment>
                //     ) : (
                //         ""
                //     )
                // }}
            />
        );
    };

    renderItem = (item, isHighlighted) => {
        let text = this.props.start
            ? moment(item).format("h:mma") + " (" + moment(item).diff(this.props.start, "hours", true).toFixed(2) + " hours)"
            : moment(item).format("h:mma");

        return (
            <MenuItem
                key={item}
                selected={isHighlighted}
                component="div"
                style={{
                    zIndex: 9999,
                    backgroundColor: isHighlighted ? "#ff0c9c" : "#ffffff"
                }}
            >
                <ListItemText primary={text} />
            </MenuItem>
        );
    };

    getItemValue = item => {
        // return item;
        //  console.log("get item value");
        return moment(item).format("h:mma");
    };

    handleSelect = (value, item) => {
        this.props.onChange(item);
        console.log("value selected", value);
        this.setState({
            open: false
        });
    };
    render() {
        const { label, readOnly } = this.props;
        //console.log("render");
        return (
            <Autocomplete
                items={this.state.times}
                renderInput={this.renderInput}
                renderMenu={this.renderMenu}
                renderItem={this.renderItem}
                getItemValue={this.getItemValue}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                value={this.state.valueText}
                open={readOnly ? false : this.state.open}
                inputProps={{
                    label: label,
                    readOnly: readOnly,
                    // helperText: this.state.duration,
                    fullWidth: true,
                    id: this.props.id
                }}
                wrapperStyle={{
                    ...this.props.wrapperStyle,
                    display: "inline-block",
                    position: "relative",
                    width: "100%"
                }}
            />
            /* // <TextField
            //     label={label}
            //     value={value}
            //     margin={margin ? margin : "dense"}
            //     fullWidth
            //     error={this.props.error}
            //     helperText={this.props.helperText}
            //     // InputProps={{
            //     //     inputComponent: TimePicker,
            //     //     readOnly: readOnly,
            //     //     inputProps: {
            //     //         open: this.state.timepickerOpen,
            //     //         onOpen: this.setOpen,
            //     //         onClose: this.setOpen,
            //     //         showSecond: false,
            //     //         onChange: this.handleChange,
            //     //         popupClassName: "time-picker",
            //     //         focusOnOpen: true,
            //     //         allowEmpty: false,
            //     //         disabledHours: this.calculateDisabledHour,
            //     //         disabledMinutes: this.calculateDisabledMinute
            //     //     },
            //     //     endAdornment: (
            //     //         <InputAdornment position="start" style={{ padding: 0, backgroundColor: "transparent" }}>
            //     //             <IconButton onClick={this.handleToggleOpen}>
            //     //                 <AlarmIcon />
            //     //             </IconButton>
            //     //         </InputAdornment>
            //     //     )
            //     // }}
            // /> */
        );
    }
}

export default HubTimepicker;
