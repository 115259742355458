import React, { Component, Fragment } from "react";

import { observer, inject } from "mobx-react";

import Heading from "../../components/Heading";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
// import { ProfileType } from "../../types/enum";

import AdminTable from "../../components/admin/AdminTable";
import Breadcrumb from "../../components/Breadcrumb";
import { TextField, Grid, Typography, Paper } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Loading from "../../components/loadingSpinner/Loading";

@inject("userStore")
@observer
class Admin extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/admin" }, { name: "users" }];
    state = {
        filterText: "",
        isLoading: false
    }

    handleFilterTextChange = e => {
        this.setState({ isLoading: true });
        this.setState({ filterText: e.target.value });
        if (e.target.value.length > 2) {

            this.props.userStore.searchActiveUser(e.target.value).then(x => {
                this.setState({ isLoading: false });
            });

        } else if (e.target.value.length == 0) {
            this.props.userStore.getActiveUser().then(x => {
                this.setState({ isLoading: false });
            });
        }
    };

    componentDidMount() {
    }


    render() {
        const { isLoading } = this.state;

        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Fragment>
                        <Heading title="Active Users" >
                            <TextField
                                className="dark-datepicker"
                                placeholder="Find User"
                                onChange={this.handleFilterTextChange}
                                value={this.state.filterText}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Heading>
                        <Paper style={{ padding: 16 }}>
                            <Loading showed={isLoading} />


                            <Grid container spacing={24}>
                                <Grid item xs={12} lg={12}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <AdminTable
                                            />

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Fragment>
                </MainContainer>
            </Fragment>
        );
    }
}

export default Admin;
