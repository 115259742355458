import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { findDOMNode } from "react-dom";
import { Link, withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import { Button, AppBar, Toolbar, IconButton, Avatar, Divider, Hidden, Popover, Grid, Typography, ButtonBase } from "@material-ui/core";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControl from "@material-ui/core/FormControl";
import DialogContent from "@material-ui/core/DialogContent";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import image from "../helper/image";

// import SearchIcon from "@material-ui/icons/Search";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";
import Notification from "./notifications/Notification";
import ModalContainer from "../components/modal/ModalContainer";

import logo from "../img/hotgig-app-logo.png";
import musician from "../img/musician.svg";
import agent from "../img/agent.svg";
import venue from "../img/venue.svg";
import production from "../img/production.svg";
import dj from "../img/dj.svg";
import privatehire from "../img/privatehire.svg";

import singlevenue from "../img/venue.svg";
import groupvenue from "../img/venue-group.svg";

import "./Header.css";
import { ProfileType } from "../types/enum";
const SHOW_PRODUCTION_CREW = process.env.REACT_APP_SHOW_PRODUCTION_CREW === "true";
const SHOW_DJ = process.env.REACT_APP_SHOW_DJ === "true";

@inject("templateStore", "authStore", "profileStore", "billingStore", "userStore")
@withRouter
@observer
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            anchorOriginVertical: "bottom",
            anchorOriginHorizontal: "right",
            transformOriginVertical: "top",
            transformOriginHorizontal: "right",
            disableUnderline: true,
            openAccountSelector: false,
            secondStep: false
        };
    }

    searchField = null;

    handleClickButton = () => {
        this.props.templateStore.setHeaderPopOverAnchor(findDOMNode(this.toolbar));
        this.props.templateStore.toggleHeaderPopover();
    };

    handleProfileSelection = profile => () => {
        var type = profile.type
        this.props.profileStore.setProfileType(type);
        this.props.billingStore.setAccess(type);
        this.handleClose();
        this.props.profileStore.loadProfilesAfterLogin().then(() => {
            var to= profile.isConsultant?"/agent": profile.type === ProfileType.PrivateHire.ordinal ? "/privatehire/gig" :  "/" + profile.typeString
            this.props.history.push(to);

        }).finally(() => {
        })
    };

    handleClose = e => {
        this.props.templateStore.toggleHeaderPopover();
    };

    handleCloseAccountSelector = e => {
        this.setState({
            openAccountSelector: false
        });
    };

    handleOpenAccountSelector = e => {
        this.props.templateStore.toggleHeaderPopover();
        this.setState({
            openAccountSelector: true
        });
    };

    handleSearchChange = e => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.props.history.push(`/search?query=${this.searchField.props.value}`);
        }
    };

    handleDrawerToggle = () => {
        this.props.templateStore.toggleSidebar();
    };

    handleLogoutClick = e => {
        e.preventDefault();
        this.props.authStore.logout().then(() => {
            this.props.templateStore.toggleHeaderPopover();
            this.props.history.push("/sign-in");
        });
    };

    handleBack = (e) => {
        e.preventDefault();
        this.props.history.push('/sign-in')
    }

    renderProfiles = () => {
        const { profiles, currentProfileType } = this.props.profileStore;

        if (profiles && profiles.length > 0) {
            let result = [];
            
            for (let i = 0; i < profiles.length; i++) {
                if (profiles[i].type !== currentProfileType && profiles[i].type !== 5 && profiles[i].type !== 4 /*don't show act in profile selector*/) {
                    if (
                        profiles[i].type !== ProfileType.Production.ordinal ||
                        (profiles[i].type === ProfileType.Production.ordinal && SHOW_PRODUCTION_CREW)
                    ) {
                        result.push(<Divider key={i} />); // random key to remove warning
                        
                        result.push(
                            <ListItem
                                key={i + 100}
                                // component={Link}
                                // to={profiles[i].isConsultant?"/agent": profiles[i].type === ProfileType.PrivateHire.ordinal ? "/privatehire/gig" :  "/" + profiles[i].typeString}
                                button
                                onClick={this.handleProfileSelection(profiles[i])}
                            >
                                <Avatar src={image.getProfileImage(profiles[i])} />
                                <ListItemText primary={profiles[i].isConsultant || profiles[i].type === ProfileType.PrivateHire.ordinal?profiles[i].firstName+" "+profiles[i].lastName:profiles[i].fullName} secondary={profiles[i].labelType} />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label="Edit Profile"
                                        component={Link}
                                        to={profiles[i].isConsultant?"/agent/edit/new/consultant":"/" + profiles[i].typeString + "/edit"}
                                        onClick={this.handleProfileSelection(profiles[i].type)}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    }
                }
            }
            result.push(<Divider key={200} />);

            return result;
        }
        return null;
    };

    render() {
        const { profiles, currentProfileType, currentProfile } = this.props.profileStore;
        const { secondStep } = this.state;
        
        if(profiles==null) return "";
        let profileTypes = profiles.filter(x => x.type !== ProfileType.Act.ordinal && x.type !== ProfileType.Venue.ordinal);
        profileTypes = profileTypes.map(x => x.type); // need to map all the types

        let profile = currentProfile;
        // if (profiles && profiles.length > 0) {
        //     for (let i = 0; i < profiles.length; i++) {
        //         if (profiles[i].type === currentProfileType) {
        //             profile = profiles[i];
        //             break;
        //         }
        //     }
        // }

        // let onSearchFocus = () => {
        //     this.setState({
        //         disableUnderline: false
        //     });
        // };

        // let onSearchBlur = () => {
        //     this.setState({
        //         disableUnderline: true
        //     });
        // };

        return (
            <AppBar
                title="hotgiggity"
                ref={node => {
                    this.toolbar = node;
                }}
                style={{ background: '#262626' }}
            >
                <Toolbar className="toolbar-gutter">
                    {/* normal togler for mobile */}
                    {!this.props.hideToggler &&
                        !this.props.forceToggler &&
                        this.props.userStore.currentUser && (
                            <Hidden mdUp>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.handleDrawerToggle}
                                    style={{ marginRight: "-20px" }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>
                        )}
                    {/* force togler for public profile page */}
                    {this.props.forceToggler &&
                        this.props.userStore.currentUser && (
                            <IconButton color="inherit" aria-label="open drawer" onClick={this.handleDrawerToggle} style={{ marginRight: "-20px" }}>
                                <MenuIcon />
                            </IconButton>
                        )}
                    <img src={logo} alt="hotgiggity" style={{ marginRight: "5%", maxWidth: "190px", marginLeft: "20px" }} />
                    <div style={{ flexGrow: 1 }} />
                    {this.props.userStore.currentUser ? (
                        <React.Fragment>
                            <Notification />
                            <ButtonBase
                                onClick={this.handleClickButton}
                                ref={node => {
                                    this.button = node;
                                }}
                                id="avatar-btn"
                            >
                                {profile && currentProfileType ? (
                                    <Avatar src={image.getProfileImage(profile)} />
                                ) : currentProfileType ? (
                                    <Avatar>
                                        <PersonIcon />
                                    </Avatar>
                                ) : null}
                            </ButtonBase>
                        </React.Fragment>
                    ) : (
                        <LinkContainer to="/sign-in">
                            <Button onClick={this.handleBack} className="btnSignIn" small="true" variant="text">
                                Sign In
                            </Button>
                        </LinkContainer>
                    )}
                    {/* {currentProfileType ? (
                        <IconButton
                            id="avatar-btn"
                            ref={node => {
                                this.button = node;
                            }}
                            onClick={this.handleClickButton}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    ) : null} */}

                    <Popover
                        open={this.props.templateStore.openHeaderPopover}
                        anchorEl={this.props.templateStore.headerPopoverAnchor}
                        anchorOrigin={{
                            vertical: this.props.templateStore.headerPopoverVerticalAnchorAlign,
                            horizontal: this.props.templateStore.headerPopoverHorizontalAnchorAlign
                        }}
                        transformOrigin={{
                            vertical: this.props.templateStore.headerPopoverVerticalTransformAlign,
                            horizontal: this.props.templateStore.headerPopoverHorizontalTransformAlign
                        }}
                        onClose={this.handleClose}
                        id="header-popover"
                    >
                        <div className="profile-selector-container">
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Grid container spacing={0} className="main-profile-container">
                                        <Grid item xs={5}>
                                            {profile ? (
                                                <Avatar className="profile-selector-avatar-main" src={image.getProfileImage(profile)} />
                                            ) : (
                                                <Avatar className="profile-selector-avatar-main">
                                                    <PersonIcon style={{ width: 60, height: 60 }} />
                                                </Avatar>
                                            )}
                                        </Grid>

                                        <Grid item xs={7}>
                                            <Grid
                                                container
                                                spacing={0}
                                                style={{
                                                    height: "100%",
                                                    display: "flex",
                                                    alignContent: "space-between"
                                                }}
                                            >
                                                <Grid item xs={12}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <Typography component="div" className="bold">
                                                                {profile
                                                                    ? profile.isConsultant || profile.type == ProfileType.PrivateHire.ordinal
                                                                        ? profile.firstName + " " + profile.lastName
                                                                        : profile.fullName
                                                                    : ""}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} style={{ marginTop: -3 }}>
                                                            <Typography component="div" className="capitalize profile-selector-profile-type">
                                                                {profile && profile.labelType}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex", justifyContent: "space-between" }}
                                                            className="profile-selector-link"
                                                        >
                                                            {/* <Link onClick={this.handleClose} to={"/" + (profile && profile.typeString) + "/profile"}>View Profile</Link> – */}
                                                            {currentProfileType !== ProfileType.Staff.ordinal && currentProfileType !== ProfileType.PrivateHire.ordinal && (
                                                                <Fragment>
                                                                    <Link target={"_blank"} onClick={this.handleClose} to={"/profile/" + (profile && profile.id)}>
                                                                        View Profile
                                                                    </Link>{" "}
                                                                    –
                                                                </Fragment>
                                                            )}
                                                            <Link onClick={this.handleClose} to={currentProfile && currentProfile.isConsultant?"/agent/edit/new/consultant": currentProfile?.type == ProfileType.PrivateHire.ordinal ? "/privatehire/edit" : "/" + (profile && profile.typeString) + "/edit"}>
                                                                Edit Profile
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        variant="contained"
                                                        component={Link}
                                                        to="/account/setting"
                                                        onClick={this.handleClose}
                                                        className="button-profile-selector-main"
                                                    >
                                                        <SettingsIcon />&nbsp;
                                                        <Typography style={{color:"#fff"}}>My Account</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <List disablePadding className="profile-selector-list">
                                        {this.renderProfiles()}
                                    </List>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container className="profile-selector-footer" spacing={0}>
                                        <Grid item xs={6} className="text-xs-left">
                                            <Button
                                                size="small"
                                                color="primary"
                                                disabled={profileTypes.length >= 4}
                                                onClick={this.handleOpenAccountSelector}
                                            >
                                                Add Profile
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} className="text-xs-right">
                                            <Button size="small" color="primary" onClick={this.handleLogoutClick}>
                                                Sign Out
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Popover>
                </Toolbar>
                {/* <Hidden mdUp>
                    <Toolbar style={{ backgroundColor: "#ffffff" }}>
                        <FormControl className="searchInput" fullWidth>
                            <Input
                                placeholder="Search for an Act, Agent or Venue"
                                disableUnderline={this.state.disableUnderline}
                                onFocus={onSearchFocus}
                                onBlur={onSearchBlur}
                                onKeyUp={this.handleSearchChange}
                                onChange={e => this.props.templateStore.setMainSearchValue(e.target.value)}
                                ref={node => (this.searchField = node)}
                                value={this.props.templateStore.mainSearchValue}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Toolbar>
                </Hidden> */}

                <ModalContainer
                    open={this.state.openAccountSelector}
                    title={ secondStep ? "Select Venue Type" : "Add Profile"}
                    maxWidth="sm"
                    coloredDialogTitle = {true}
                    onClose={this.handleCloseAccountSelector}
                    fullScreen={false}
                >
                    <DialogContent className="add-profile-dialog-content">
                        { !secondStep && 
                        (  <Grid container spacing={24} justify="space-around" style={{width:"100%"}}>
                        {profileTypes.indexOf(1) < 0 && (
                            <Grid item md={2} xs={6} className="type ">
                                <Link to="/musician/edit/new">
                                    <ButtonBase focusRipple className="button-base">
                                        <Typography component="span" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={musician} alt="musician" />
                                            </div>
                                            <span>Artist/Entertainer</span>
                                        </Typography>
                                    </ButtonBase>
                                </Link>
                            </Grid>
                        )}

                    {profileTypes.indexOf(9) < 0 && (
                            <Grid item md={2} xs={6} className="type ">
                                <Link to="/privatehire/private/edit/new">
                                    <ButtonBase focusRipple className="button-base">
                                        <Typography component="span" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={privatehire} alt="musician" />
                                            </div>
                                            <span>Parties & Event</span>
                                        </Typography>
                                    </ButtonBase>
                                </Link>
                            </Grid>
                        )}

                        {profileTypes.indexOf(2) < 0 && (
                            <Grid item md={2} xs={6} className="type">
                                <Link to="/agent/edit/new">
                                    <ButtonBase focusRipple className="button-base">
                                        <Typography component="span" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={agent} alt="agent" />
                                            </div>
                                            <span>Agent</span>
                                        </Typography>
                                    </ButtonBase>
                                </Link>
                            </Grid>
                        )}

                        {profileTypes.indexOf(3) < 0 && (
                            <Grid item md={2} xs={6} className="type">
                                {/* <Link to="/staff/edit/new"> */}
                                    <ButtonBase onClick={() =>         this.setState({
                                        secondStep: true
                                    })} focusRipple className="button-base">
                                        <Typography component="span" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={venue} alt="venue" />
                                            </div>
                                            <span>Venue</span>
                                        </Typography>
                                    </ButtonBase>
                                {/* </Link> */}
                            </Grid>
                        )}

                        

                        {profileTypes.indexOf(6) < 0 &&
                            SHOW_PRODUCTION_CREW && (
                                <Grid item md={2} xs={6} className="type">
                                    <Link to="/production/edit">
                                        <ButtonBase focusRipple className="button-base">
                                            <Typography component="span" type="subheading" color="inherit">
                                                <div className="type-image">
                                                    <img src={production} alt="production" />
                                                </div>
                                                <span>Production</span>
                                            </Typography>
                                        </ButtonBase>
                                    </Link>
                                </Grid>
                            )}

                        {SHOW_DJ &&
                            profileTypes.indexOf(7) < 0 && (
                                <Grid item md={2} xs={6} className="type">
                                    <Link to="/dj/edit">
                                        <ButtonBase focusRipple className="button-base">
                                            <Typography component="span" type="subheading" color="inherit">
                                                <div className="type-image">
                                                    <img src={dj} alt="dj" />
                                                </div>
                                                <span>DJ</span>
                                            </Typography>
                                        </ButtonBase>
                                    </Link>
                                </Grid>
                            )}
                    </Grid>)}

                    { secondStep && 
                        (  <Grid container spacing={24} justify="space-around" style={{width:"100%"}}>
     

                            <Grid item md={2} xs={6} className="type">
                                <Link to="/staff/group/edit/new">
                                    <ButtonBase focusRipple className="button-base">
                                        <Typography component="span" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={groupvenue} alt="venue group" />
                                            </div>
                                            <span>Venue Group</span>
                                        </Typography>
                                    </ButtonBase>
                                </Link>
                            </Grid>
                            <Grid item md={2} xs={6} className="type">
                                <Link to="/staff/single/edit/new">
                                    <ButtonBase focusRipple className="button-base">
                                        <Typography component="span" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={singlevenue} alt="single venue" />
                                            </div>
                                            <span>Single Venue</span>
                                        </Typography>
                                    </ButtonBase>
                                </Link>
                            </Grid>


                    </Grid>)}
                    
                    </DialogContent>
                </ModalContainer>
            </AppBar>
        );
    }
}

export default Header;
