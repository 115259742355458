import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import ConfirmationModal from "../../../components/modal/ConfirmationModal.js";

import { Grid, Button, Tooltip,Divider } from "@material-ui/core";
import facebook from "../../../img/button_google.png";
import "../AccountIntegration.css";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_REDIRECT_URL = process.env.REACT_APP_GOOGLE_REDIRECT_URL;

const GOOGLE_REDIRECT_URL_VENUE = process.env.REACT_APP_GOOGLE_REDIRECT_URL_VENUE;


const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";
const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
const BASE_URL = process.env.REACT_APP_BASE_URL;
import Select from "react-select";
import { withRouter } from "react-router-dom";

@inject("userStore", "profileStore", "templateStore", "commonStore", "googleCalendarStore","myobStore", "socialProfileStore")
@withRouter
@observer
class googleCalendarPart extends Component {
    state = {
        selectedOption: "",
        options: [],
        showCalendar: false,
        googleCalendarActive: false,
        showUnlinkModal: false,
        showSubmitCalendar: false,
        panelMYOBExpanded: false,
        panelGoogleExpanded: false,
        myobActive:false,
        myobAccountName: "",
        showStep3Myob: false,
        showMyobDisconnectModal: false,
        selectCompanyPhase: false,
        selectedMYOBCompany : "",
        selectedMYOBOptions: [],
        selectedOptionName: ""
    };
    
    componentDidMount = () => {
        var options = [];
        var selectedOptions = "";
        var selectedOptionName = "";
        const { googleCalendarCode } = this.props.googleCalendarStore;
        var currentProfile = this.props.profileStore.getCurrentProfile();
        if(this.props.type === "venue"){
            currentProfile = this.props.socialProfileStore.profile
        }
       
       


        if (!currentProfile?.isConsultant){
            if(currentProfile  && currentProfile?.activateGoogleCalendar && currentProfile?.googleCalendarId){
                options.push({ value: currentProfile?.googleCalendarName, label: currentProfile?.googleCalendarName, });
                selectedOptions = currentProfile?.googleCalendarId
                selectedOptionName = currentProfile?.googleCalendarName
                this.setState({
                    ...this.state,
                    googleCalendarActive: true,
                    options: options,
                    selectedOption: selectedOptions,
                    selectedOptionName: selectedOptionName
                });
            }
            else if (googleCalendarCode) {
                this.props.templateStore.showLoadingScreen()
                this.props.googleCalendarStore
                    .exchangeTokenAndGetGoogleCalendarList({ code: googleCalendarCode, profileId: currentProfile?.id })
                    .then(response => {
                        if (response && response.length) {
                            response.forEach(item => {
                                if (item.primary) {
                                    selectedOptions = item.id;
                                    selectedOptionName = item.summary
                                }
                                options.push({ value: item.id, label: item.summary });
                            });
                            this.setState({
                                ...this.state,
                                showCalendar: true,
                                options: options,
                                selectedOption: selectedOptions,
                                selectedOptionName: selectedOptionName,
                                panelGoogleExpanded: true
                            });
                        }
                        this.props.templateStore.hideLoadingScreen()
                    }).finally(() => {
                        this.props.templateStore.hideLoadingScreen()
                    });
            }
        }else{
            if(currentProfile && currentProfile?.myAgencies.length &&  currentProfile?.myAgencies[0]?.agent?.activateGoogleCalendar){
                options.push({ value: currentProfile?.myAgencies[0]?.agent?.googleCalendarId, label: currentProfile?.myAgencies[0]?.agent?.googleCalendarId, });
                selectedOptions = currentProfile?.myAgencies[0]?.agent?.googleCalendarId
                selectedOptionName = currentProfile?.myAgencies[0]?.agent?.googleCalendarName
    
                this.setState({
                    ...this.state,
                    googleCalendarActive: true,
                    options: options,
                    selectedOption: selectedOptions,
                    selectedOptionName: selectedOptionName
                });
            }
        }
        

    };

    expandGoogle = () => {
        const {panelGoogleExpanded} =this.state
        this.setState({
            ...this.state,
            panelGoogleExpanded: !panelGoogleExpanded,
        });
    }

 
    submitGoogleCalendar = () => {
        this.props.templateStore.showLoadingScreen()
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(this.props.type === "venue"){
            currentProfile = this.props.socialProfileStore.profile
        }
        this.props.googleCalendarStore
        .selectGoogleCalendar({ googleCalendarId: this.state.selectedOption,googleCalendarName: this.state.selectedOptionName, profileId: currentProfile?.id })
        .finally(async response => {
                this.props.socialProfileStore.setType(this.props.templateStore.getType(this.props.type));
                await this.props.profileStore.loadProfiles();
                this.props.socialProfileStore
                    .loadInitialData(this.props.profileId)
                    .then(() => {})
                    .finally(() => {
                        this.props.templateStore.hideLoadingScreen()
                        currentProfile = this.props.socialProfileStore.profile;
                        if(currentProfile && currentProfile?.activateGoogleCalendar){
                            var options = [];
                            var selectedOptions = "";
                            var selectedOptionName = "";
                            options.push({ value: currentProfile?.googleCalendarName, label: currentProfile?.googleCalendarName, });
                            selectedOptions = currentProfile?.googleCalendarId
                            selectedOptionName = currentProfile?.googleCalendarName
                            this.setState({
                                ...this.state,
                                googleCalendarActive: true,
                                options: options,
                                selectedOption: selectedOptions,
                                selectedOptionName: selectedOptionName,
                                showUnlinkModal: false,
                                showSubmitCalendar: false
                            });
                        }else{
                            this.setState({
                                ...this.state,
                                googleCalendarActive: false,
                                showUnlinkModal: false,
                                selectedOption: "",
                                showSubmitCalendar: false
                            });
                        }
                    });
            
        
        });
    };

 
    handleOpenCalendarModal = () => this.setState({ ...this.state, showUnlinkModal: true });
    
    handleOpenSubmitCalendarModal = () => this.setState({ ...this.state, showSubmitCalendar: true });
    handleCloseSubmitCalendarModal = () => this.setState({ ...this.state, showSubmitCalendar: false });
    
    handleUnlinkGoogle = e => {
        let currentProfile = this.props.profileStore.getCurrentProfile();

        if(this.props.type === "venue"){
            currentProfile = this.props.socialProfileStore.profile
        }
        this.props.templateStore.showLoadingScreen()
        this.props.profileStore.disconnectGoogle(currentProfile?.id).finally(async response => {
            if(this.props.type === "venue"){
                await this.props.profileStore.loadProfiles();
                this.props.socialProfileStore.setType(this.props.templateStore.getType(this.props.type));
                this.props.socialProfileStore
                    .loadInitialData(this.props.profileId)
                    .then(() => {})
                    .finally(() => {
                        this.props.templateStore.hideLoadingScreen()
                        currentProfile = this.props.socialProfileStore.profile;
                        if(currentProfile && currentProfile?.activateGoogleCalendar){
                            this.setState({
                                ...this.state,
                                googleCalendarActive: true,
                                showUnlinkModal: false,
                                selectedOption: "",
                                selectedOptionName: ""
                            });
                        }else{
                            this.setState({
                                ...this.state,
                                googleCalendarActive: false,
                                showUnlinkModal: false,
                                selectedOption: "",
                                selectedOptionName: ""
                            });
                        }
                    });
                // this.setState({
                //     ...this.state,
                //     googleCalendarActive: false,
                //     showUnlinkModal: false,
                //     selectedOption: ""
                // });
                // this.props.templateStore.hideLoadingScreen()
            }else{
                this.props.profileStore.loadProfiles().finally(() => {
                    this.props.templateStore.hideLoadingScreen()
                    if(currentProfile && currentProfile?.activateGoogleCalendar){
                        this.setState({
                            ...this.state,
                            googleCalendarActive: true,
                            showUnlinkModal: false,
                            selectedOption: "",
                            selectedOptionName: ""
                        });
                    }else{
                        this.setState({
                            ...this.state,
                            googleCalendarActive: false,
                            showUnlinkModal: false,
                            selectedOption: "",
                            selectedOptionName: ""
                        });
                    }
                })
            }
           
         
        });
    };

    handleUnlinkQuickBooks = e => {
        this.props.userStore.unlinkQuickBooksAccount();
    };


    handleCloseUnlinkCalendarModal = () => this.setState({ ...this.state, showUnlinkModal: false });
    handleGoogleCalendarIdChange = e => {
        this.props.userStore.setGoogleCalendarId(e.target.value);
    };

    handleChangeCalendar = selectedOption => {
        this.setState({ selectedOption : selectedOption.value, selectedOptionName: selectedOption.label });
    };

 
    handleOpenGoogleLink = () => {
        const { token, refreshToken } = this.props.commonStore;
        window.cordova.InAppBrowser.open(
            `${BASE_URL}login?token=${token}&refreshToken=${refreshToken}&redirectUrl=%2Faccount%2Fsetting%2Fintegration`,
            "_system",
            "location=yes"
        );
    };
    render() {
        const { currentUser, calendars, quickBooksCompany, hasQuickBooksAccount } = this.props.userStore;
        var currentProfile = this.props.profileStore.getCurrentProfile();

        var returnURL = GOOGLE_REDIRECT_URL
        if(this.props.type === "venue"){
            currentProfile = this.props.socialProfileStore.profile
            returnURL = GOOGLE_REDIRECT_URL_VENUE
        }
       
        const { token } = this.props.commonStore;
        const { showCalendar, options, selectedOption, googleCalendarActive,selectedOptionName } = this.state;
        let hasSubscribe = false;
        // if (token && currentProfile && jwtDecode(token).scope.filter(x => x === currentProfile?.typeString).length > 0) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }
        return (
                <Fragment>
                                        <Grid container spacing={8}>
                                            <Grid item md={12} xs={12}  container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                {currentUser &&
                                                    !showCalendar &&
                                                    !currentUser.googleAccessToken && !googleCalendarActive &&
                                                    (IS_MOBILE ? (
                                                        <a onClick={this.handleOpenGoogleLink}>
                                                            <Button variant="outlined">Go to the web version to link your account</Button>
                                                        </a>
                                                    ) : (
                                                        <Grid container spacing={8}>
                                                        <Grid item md={6}>
                                                            <Typography gutterBottom>No Calendar Linked</Typography>
                                                                <Select value={selectedOption} onChange={this.handleChangeCalendar} options={options} disabled/>
                                                                <Typography gutterBottom className="bottom-helper-text">
                                                                Please note: You must select both Google scopes in order for this integration to work correctly
                                                            </Typography>
                                                            </Grid>
                                                            <Grid item md={6} style={{paddingLeft:20}}>
                                                                {
                                                                     currentProfile?.isConsultant ? 
                                                                     (
                                                                       <div>
                                                                            <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 , height: 40}} color="primary" disabled>
                                                                                LINK GOOGLE ACCOUNT
                                                                            </Button>
                                                                            <Typography gutterBottom>
                                                                                You could only sync calendar from agent
                                                                            </Typography>
                                                                       </div>
                                                                     ) :
                                                                     (   
                                                                        <a
                                                                        href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&include_granted_scopes=true&response_type=code&scope=https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly&redirect_uri=${returnURL}&access_type=offline&prompt=consent&state=${
                                                                            currentProfile?.id
                                                                        }`}
                                                                    >
                                                                         {/* <img src={ googleCalendarIcon }  style={{ marginTop: 25,height:40 }} alt="Get It On Google Playstore" width="300px"/>
                                                                         */}
                                                                        <Button variant="outlined" style={{ marginTop: 25,backgroundColor:'white', marginLeft: 10,font: "Roboto-Medium",borderColor:"rgba(0, 0, 0, 0.87)",borderRadius: 6, boxShadow: "0 2px 1px -1px",color:"rgba(0, 0, 0, 0.87)" }} color="primary">
                                                                            <img src={facebook} style={{height:30}} alt="google" className="logo-google" />&nbsp;&nbsp;Sign in with Google
                                                                        </Button>
                                                            
                                                                        {/* <Button variant="outlined" style={{ justifyContent: "center", alignItems: "center" }}>
                                                                            Link Google account
                                                                        </Button> */}
                                                                    </a>
                                                                    )
                                                                }
                                                             
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    ))}

                                                {showCalendar && !googleCalendarActive && !currentProfile?.isConsultant && (
                                                    <Grid container spacing={2}>
                                                        <Grid item md={7} xs={12} >
                                                        <Typography gutterBottom>Select the calendar to link</Typography>
                                                            <Select value={selectedOption} onChange={this.handleChangeCalendar} options={options} />
                                                            <Typography gutterBottom className="bottom-helper-text">
                                                                Please note: You must select both Google scopes in order for this integration to work correctly
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={5} xs={12}  style={{paddingLeft:20}}>
                                                            <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 , height: 40}} color="primary" onClick={this.handleOpenSubmitCalendarModal}>
                                                                LINK THIS CALENDAR
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}

                                                {showCalendar && !googleCalendarActive && currentProfile?.isConsultant && (
                                                    <Grid container spacing={2}>
                                                        <Grid item md={7} xs={12} >
                                                        <Typography gutterBottom>Select the calendar to link</Typography>
                                                            <Select value={selectedOption} onChange={this.handleChangeCalendar} options={options} />
                                                            <Typography gutterBottom className="bottom-helper-text">
                                                                Please note: You must select both Google scopes in order for this integration to work correctly
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={5} xs={12}  style={{paddingLeft:20}}>
                                                            <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 , height: 40}} color="primary" onClick={this.handleOpenSubmitCalendarModal} disabled>
                                                                LINK THIS CALENDAR
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}

                                                {currentProfile && googleCalendarActive && 
                                                    currentProfile?.googleRefreshToken && !currentProfile?.isConsultant && (
                                                        <Grid container spacing={2}>
                                                            <Grid item md={6} xs={12} >
                                                                <Typography gutterBottom>Linked Google Calendar</Typography>
                                                                <Select value={selectedOptionName} onChange={this.handleChangeCalendar} options={options} disabled />
                                                                <Typography gutterBottom className="bottom-helper-text">
                                                                Please note: The initial integration process may take a few minutes.
                                                                </Typography>
                                                                </Grid>
                                                            <Grid item md={6} xs={12} style={{paddingLeft:20}}>
                                                                <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 , height: 40}} color="primary" onClick={this.handleOpenCalendarModal} >
                                                                    UNLINK GOOGLE ACCOUNT
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                {currentProfile && googleCalendarActive && 
                                                    currentProfile?.googleRefreshToken && currentProfile?.isConsultant && (
                                                    <Grid container spacing={2}>
                                                        <Grid item md={6} xs={12} >
                                                            <Typography gutterBottom>Linked Google Calendar</Typography>
                                                            <Select value={selectedOption} onChange={this.handleChangeCalendar} options={options} disabled />
                                                            </Grid>
                                                        <Grid item md={6} xs={12} style={{paddingLeft:20}}>
                                                            <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 , height: 40}} color="primary" onClick={this.handleOpenCalendarModal} >
                                                                UNLINK GOOGLE ACCOUNT
                                                            </Button>
                                                            <Typography gutterBottom>
                                                                You could only sync calendar from agent
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                  
                                <ConfirmationModal
                open={this.state.showUnlinkModal}
                title="Are you sure you want to disconnect your Google calendar?"
                message="By unlinking your Google calendar, your Hot Giggity calendar will no longer display any of your Google calendar events. Hot Giggity events will be deleted from your Google calendar."
                closeHandler={this.handleCloseUnlinkCalendarModal}
                confirmationHandler={this.handleUnlinkGoogle}
                declineHandler={this.handleCloseUnlinkCalendarModal}
                confirmationLabel="Unlink Google Calendar"
                declineLabel="Cancel"
                maxWidth="sm"
            />
            <ConfirmationModal
                open={this.state.showSubmitCalendar}
                title={`Are you sure you want to integrate with your ${selectedOptionName} Google calendar`}
                message="By linking this Google calendar to your Hot Giggity account, events in your Google calendar will be visible in your Hot Giggity calendar as 'Unavailable'.  Venues and agents who book you will be able to see these 'Unavailable' events"
                closeHandler={this.handleCloseSubmitCalendarModal}
                confirmationHandler={this.submitGoogleCalendar}
                declineHandler={this.handleCloseSubmitCalendarModal}
                confirmationLabel="Confirm"
                declineLabel="Cancel"
                maxWidth="sm"
            />
              </Fragment>

            
        )
    }
}

export default googleCalendarPart;
