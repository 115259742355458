import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
// import moment from "moment";
import Grid from "@material-ui/core/Grid";
import SelectField from "../fields/SelectField";

// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import Slide from "@material-ui/core/Slide";

// import Paper from "@material-ui/core/Paper";
// import { ProfileType, InvoiceType } from "../../types/enum";
import Loading from "../loadingSpinner/Loading";

import PageNotFound from "../../pages/profile/PageNotFound";

@inject("invoiceStore", "templateStore", "profileStore", "quickBooksStore")
@observer
class QuickBooksVendor extends Component {
    state = {
        pdfFile: undefined,
        receiptNumber: "",
        paymentNote: "",
        invoice: {},
        notFound: false,
        isLoading: true
    };

    mapVendors = () => {
        const { vendors } = this.props.quickBooksStore;

        return vendors.map(customer => {
            return {
                value: customer.id,
                label: customer.displayName,
                label2: customer.companyName
            };
        });
    };

    mapExpenses = () => {
        const { expenses } = this.props.quickBooksStore;

        return expenses.map(item => {
            return {
                value: item.id,
                label: item.fullyQualifiedName
            };
        });
    };

    handleVendorChange = value => {
        console.log("selected vendor", value);
        this.props.quickBooksStore.setSelectedVendors(value);
    };

    handleExpenseChange = value => {
        this.props.quickBooksStore.setSelectedExpense(value);
    };

    render() {
        // const { invoiceId, fullScreen, isLoading } = this.props.invoiceStore;
        const {
            // quickBooksCustomers,
            selectedVendorId,
            // loadingCustomer,
            selectedCustomer,
            selectedExpenseId,
            loadingExpenses,
            error
        } = this.props.quickBooksStore;

        return this.state.notFound ? (
            <PageNotFound />
        ) : (
            <Fragment>
                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDate">
                            <Typography variant="headline" component="h3" gutterBottom>
                                QuickBooks Vendor
                            </Typography>

                            <Typography gutterBottom className="side-helper-text">
                                Select the vendor where the bill is coming from. If you already setup one on QuickBooks, select it from the dropdown.
                                The system will try to find a matching vendor based on their email address.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={6} md={12}>
                                <SelectField
                                    id="customer"
                                    label="Vendor"
                                    value={selectedVendorId}
                                    suggestions={this.mapVendors()}
                                    placeholder="Select Vendor"
                                    onChange={this.handleVendorChange}
                                    error={error && error["customer"]}
                                    helperText={error && error["customer"] ? error["customer"] : ""}
                                />
                            </Grid>
                            {selectedCustomer && (
                                <Fragment>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            id="no"
                                            label="Contact"
                                            margin="dense"
                                            fullWidth
                                            value={selectedCustomer.fullyQualifiedName}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField id="no" label="Company" margin="dense" fullWidth value={selectedCustomer.companyName} disabled />
                                    </Grid>
                                    {selectedCustomer.primaryEmailAddr && (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="no"
                                                label="Email"
                                                margin="dense"
                                                fullWidth
                                                value={selectedCustomer.primaryEmailAddr.address}
                                                disabled
                                            />
                                        </Grid>
                                    )}

                                    {selectedCustomer.billAddr && (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                id="no"
                                                label="Address"
                                                margin="dense"
                                                fullWidth
                                                value={`${selectedCustomer.billAddr.line1} ${selectedCustomer.billAddr.city} ${
                                                    selectedCustomer.billAddr.countrySubDivisionCode
                                                } ${selectedCustomer.billAddr.postalCode}`}
                                                disabled
                                            />
                                        </Grid>
                                    )}
                                </Fragment>
                            )}
                            {/* <Grid item xs={12} sm={6} md={12}>
                                <Button>Add new Customer</Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={16} style={{ position: "relative" }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDate">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Expense Category
                            </Typography>

                            <Typography gutterBottom className="side-helper-text">
                                A Products and Services list contains the items your business offers. For these items to display on your invoices and
                                other sales forms, the products and services preference must be turned on. You can add items so the list includes all
                                the items you sell, edit item details as needed, and run various products/services reports.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Loading showed={loadingExpenses} />
                        <Grid item xs={12}>
                            <Grid container spacing={16}>
                                <React.Fragment>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <SelectField
                                            id="service"
                                            label="Product/Service type"
                                            value={selectedExpenseId}
                                            suggestions={this.mapExpenses()}
                                            placeholder="Select Product/Service"
                                            onChange={this.handleExpenseChange}
                                            error={error && error["item"]}
                                            helperText={error && error["item"] ? error["item"] : ""}
                                        />
                                    </Grid>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default withMobileDialog()(QuickBooksVendor);
