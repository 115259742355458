import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalContainer2 from "./ModalContainer2";
import Divider from "@material-ui/core/Divider";

const htmlText = `
<p style="font-size: 1em;padding-left:15px"><strong><u>Important Notes</u></strong></p>
<ul style="list-style-type: disc;text-align: start;color: "black";font-size: 14px;">
    <li>
        <p style="font-size: 1em;">Publishing directly to Instagram requires an Instagram <a href="https://www.facebook.com/help/instagram/502981923235522?helpref=faq_content" title="https://www.facebook.com/help/instagram/502981923235522?helpref=faq_content" style="color: var(--ds-link, #0052CC);">Business</a> or <a href="https://www.facebook.com/help/instagram/2358103564437429?helpref=faq_content" title="https://www.facebook.com/help/instagram/2358103564437429?helpref=faq_content" style="color: var(--ds-link, #0052CC);">Creator</a> Account.</p>
    </li>
    <li>
        <p style="font-size: 1em;">Your Instagram Account needs to be <a href="https://help.instagram.com/570895513091465" title="https://help.instagram.com/570895513091465" style="color: var(--ds-link, #0052CC);">connected to a Facebook Page</a> and you need to be an administrator of that Facebook Page.</p>
    </li>
    <li>
        <p style="font-size: 1em;">Once you click the Connect button below, you will be redirected to Facebook. You will need to accept all permissions requested by Facebook to enable Hot Giggity to access &amp; post to your Instagram Account as necessary.</p>
    </li>
    <li>
        <p style="font-size: 1em;">Once you have accepted all permissions requested by Facebook, you will be redirected back to Hot Giggity, where you can select the Facebook Page associated with the Instagram Account you want to connect.</p>
    </li>
</ul>
`
class IntegrateInstagramForm extends Component {
    render() {
        return (
            <ModalContainer2
                open={this.props.open}
                title={"Connect to Instagram"}
                onClose={this.props.closeHandler}
                fullScreen={false}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "xs"}
            >
                <DialogContent
                className="blackText"
                >
                    <DialogContentText className="blackText"><div dangerouslySetInnerHTML={{ __html: htmlText }} /></DialogContentText>
                </DialogContent>
                <Divider 
                    style={
                        {
                            marginBottom: "16px",
                        }
                    }
                />
                <DialogActions style={{textAlign:"center",display:"flow"}}>
                    <Button className="btn-confirmation" color="primary" autoFocus onClick={this.props.confirmationHandler}>
                        {this.props.confirmationLabel ? this.props.confirmationLabel : "Confirm"}
                    </Button>
                </DialogActions>
            </ModalContainer2>

            
        );
    }
}

export default IntegrateInstagramForm;
