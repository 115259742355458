import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import moment from "moment"
import { Promise } from "bluebird";
import { Storage } from "aws-amplify";
import CacheHelper from '../helper/cache';
import profileStore from "./profileStore";
import reportStore from "./reportStore";
import staffFilterStore from "./staffFilterStore";
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class GeneralSocialPostStore{

    @observable socialPost = {
        id: undefined,
        scheduleTime : new Date(),
        gigImage: undefined,
        gigGalleryImage: undefined,
        isProfile: false,
        subject:"",
        postContent: "",
        switchInstagram: false,
        switchFacebook: false,
        gigImageData: undefined,
        postFacebookId: null,
        postInstagramId: null,
        profileId: undefined,
        createdById: undefined
    }
    @observable
    timeStartString = "19:00"
    @observable isLoading = false;
    @observable 
    listSocial = []
    @observable
    eventDate = new Date()
    @observable showMoreSocial = false
    @observable profile = undefined

    @action
    showLoadingState() {
        this.isLoading = true;
    }
    @action
    reset(){
        this.socialPost = {
            id: undefined,
            scheduleTime : new Date(),
            gigImage: undefined,
            gigGalleryImage: undefined,
            isProfile: false,
            postContent: "",
            switchInstagram: false,
            switchFacebook: false,
            gigImageData: undefined,
            postFacebookId: null,
            postInstagramId: null,
            profileId: undefined,
            createdById: undefined,
            listSocial: [],
            subject: ""
        }
        this.eventDate = new Date();
    }
    @action
    setEventDate(eventDate) {
        this.eventDate = eventDate;
    }
    @action
    hideLoadingState() {
        this.isLoading = false;
    }
    @action
    resetSocials(){
        this.showMoreSocial = false
        this.listSocial = []
    }

    @action
    setTimeStartString(timeStart) {
        this.timeStartString = timeStart;
    }

    @action
    setImageSocialPost(key, value)
    {
        this.socialPost[key] = value;
    }

    @action
    setProfile(profile) {
        this.profile = profile;
    }

    @action
    async load(socialId){
        return agent.GeneralSocialPost.get(socialId).then(
            action(async response => {
                if(response){
                    var gigImage = response?.gigImage
                    if(gigImage){
                        gigImage.url = await CacheHelper.getImgLink(gigImage.url);
                    }
                    runInAction(() => {
                        this.socialPost.id= response?.id,
                        this.socialPost.scheduleTime = response?.scheduleTime,
                        this.eventDate = moment(response?.scheduleTime),
                        this.timeStartString = response ? moment(response?.scheduleTime).format("HH:mm") : "19:00",
                        this.socialPost.gigImage = gigImage,
                        this.socialPost.postContent = response?.postContent,
                        this.socialPost.switchInstagram = response?.switchInstagram,
                        this.socialPost.switchFacebook = response?.switchFacebook,
                        this.socialPost.postFacebookId = response?.postFacebookId,
                        this.socialPost.postInstagramId = response?.postInstagramId
                        this.socialPost.profileId = response?.profileId
                        this.socialPost.createdById = response?.createdById
                        this.socialPost.subject = response?.subject
                    })
                }
            })
        )
    }
    @action
    loadSocial(profileId, page) {
        const { startDate, endDate } = reportStore;
        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;

        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        return agent.GeneralSocialPost.getByProfileId(profileId,startDateParam,endDateParam, undefined,page).then(
            action(async result => {
                for (var i = 0; i < result.data.length; i++) {
                    var item = result.data[i]
                    var gigImage = item?.gigImage
                    if(gigImage){
                        gigImage.url = await CacheHelper.getImgLink(gigImage.url);
                    }
                }
                runInAction(() => {
                    this.listSocial = this.listSocial.concat(result.data);
                    this.showMoreSocial = result.isMore
                })
               
                return this.listSocial.concat(result.data);
            })
        );
    }

    @action
    loadSocialStaff(profileId, page) {
        const { startDate, endDate,venueFilter } = staffFilterStore;
        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;

        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

      
        return agent.GeneralSocialPost.getByProfileId(profileId,startDateParam,endDateParam, venueFilter, page).then(
            action(async result => {
                for (var i = 0; i < result.data.length; i++) {
                    var item = result.data[i]
                    var gigImage = item?.gigImage
                    if(gigImage){
                        gigImage.url = await CacheHelper.getImgLink(gigImage.url);
                    }
                }
                runInAction(() => {
                    this.listSocial = this.listSocial.concat(result.data);
                    this.showMoreSocial = result.isMore
                })
                return this.listSocial;
            })
        );
    }

    @action
    setFromGalleryImage(galleryImage, isProfile)
    {
        this.socialPost.gigGalleryImage = galleryImage;
        this.socialPost.isProfile = isProfile;
        
    }

    @action
    setProfileId(id)
    {
        this.socialPost.profileId = id;
        
    }
    @action
    unSetFromGalleryImage()
    {
        this.socialPost.gigGalleryImage = undefined;
        this.socialPost.isProfile = false;
    }

  

    @action
    async cancelSosicalPost(id){
        return agent.GeneralSocialPost.cancel(id).then(response => {
            return response;
         })
    }
    @action
    async schedulePost(createdProfileId, isPostNow){
        if(this.socialPost.gigImage){
            var data = {
                id: this.socialPost.id,
                scheduleTime : `${moment(this.eventDate).endOf("day").format("YYYY-MM-DD")} ${moment(this.timeStartString, "HH:mm").format("HH:mm")}`,
                postContent: this.socialPost.postContent,
                switchInstagram: this.socialPost.switchInstagram,
                switchFacebook: this.socialPost.switchFacebook,
                createdById: createdProfileId,
                profileId: this.socialPost.profileId,
                utcOfset: new Date().getTimezoneOffset(),
                subject: this.socialPost.subject
            }
            return agent.GeneralSocialPost.create(data).then(response => {
               return response;
            })
        }else{
            return Promise.reject("image is required")
        }
      
    }

    @action
    async postToInstagram(id){
        return agent.GeneralSocialPost.postToInstagram(id).then(() => {
            
        })
      
    }

    @action
    async postToFacebook(id){

        return agent.GeneralSocialPost.postToFacebook(id).then((response) => {
            
        })
      
    }

    @action
    async setSocialPostImage(socialPostId, profileId) {
        var image = this.socialPost.gigImage;
        const fileExtension = image.fileName? image.fileName.split('.').pop() : image.name.split('.').pop() ;
        var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
        var name = `${ticks}.${fileExtension}`;
        var result = await this.uploadSocialPost(socialPostId, image, name);
        var socialPostImage = {
            url: result.key,
            filename: name,
            mime: image.type,
            socialPostOriginalId: socialPostId,
            profileId: profileId
        }
       console.log(socialPostImage)
        var uploadedImage =  await agent.GeneralSocialPost.postImage(socialPostImage)
        return uploadedImage
        
    }

    async uploadSocialPost(id, profileImage, name) {
        try {
            const result = await Storage.put(
                `generalSocialPost/${id}/${name}`,
                profileImage
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }
    
   
}

export default new GeneralSocialPostStore();