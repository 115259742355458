import React, { Component } from "react";

import Chip from "@material-ui/core/Chip";

import "./WidgetTitleBar.css";

class WidgetTitleBar extends Component {
    renderBadge = () => {
        if (this.props.showBadge) {
            return <Chip label={this.props.badgeValue} classes={{ root: "label label-danger pull-right" }} />;
        }
        return "";
    };

    render() {
        return (
            <div className="widget-title">
                <span className="widget-title-text">{this.props.title}</span>
                <span className="widget-title-badge">
                    {this.props.action}
                    {this.renderBadge()}
                </span>
            </div>
        );
    }
}

export default WidgetTitleBar;
