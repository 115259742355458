import React, { Component } from "react";
import "./Landing.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { LinkContainer } from "react-router-bootstrap";

import withTheme from "@material-ui/core/styles/withTheme";

import { withRouter } from "react-router-dom";

import { inject, observer } from "mobx-react";
import logo from "../img/hotgiggity.svg";
import LazyLoad from "react-lazyload";
import CacheHelper from "../helper/cache.js"

@inject("profileStore", "userStore", "commonStore")
@withRouter
@observer
class Landing extends Component {
    componentDidMount() {
        const {userStore} = this.props
        const { currentUser } = this.props.userStore;
        const profile = this.props.profileStore.getCurrentProfile();
        if(profile && typeof userStore.currentUser === "undefined"){
            userStore.saveUserFromCache(CacheHelper.getUser());
        }
        if ((currentUser && currentUser.status !== 50 && currentUser.status !== 40) || profile === undefined || !profile) {
            this.props.history.push("/welcome");
        } 
        // else if(currentUser && currentUser.status == 40){
        //     this.props.history.push("/account/setting/privatehirelock");
        // }
        else {
            switch (profile.type) {
                case 1:
                    this.props.history.push("/musician");
                    break;
                case 2:
                    this.props.history.push("/agent");
                    break;
                case 3:
                    this.props.history.push("/staff");
                    break;
                case 6:
                    this.props.history.push("/production");
                    break;
                case 7:
                    this.props.history.push("/dj");
                    break;
                case 9:
                    this.props.history.push("/privatehire/gig");
                    break;
                default:
                    this.props.history.push("/welcome");
                    break;
            }
        }
    }
    render() {
        return (
            <LazyLoad>
                <div className="Landing">
                    <AppBar position="static" className="landing-app-bar">
                        <Toolbar>
                            <Typography variant="title" color="inherit" className="flex">
                                <a href="/">
                                    <img src={logo} alt="HubDit" className="logo-nav" />
                                </a>
                            </Typography>
                            <LinkContainer to="/login">
                                <Button color="inherit">Sign In</Button>
                            </LinkContainer>
                        </Toolbar>
                    </AppBar>
                </div>
            </LazyLoad>
        );
    }
}

export default withTheme()(Landing);
