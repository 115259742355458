import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Grid, Avatar, Paper, Input } from "@material-ui/core";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import SongListViewComponent from "../../components/songListTable/SongListView";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";

import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";

import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Hidden from "@material-ui/core/Hidden";
import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import image from "../../helper/image";

import "./SongListView.css";
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

const styles = theme => ({
    root: {
        width: "100%",
        boxShadow: "none"
    },
    toolbar: {
        display: "inline-flex",
        padding: "50px !important"
    },
    toolbarSmall: {
        display: "block",
        padding: "20px !important"
    },
    search: {
        padding: "50px !important"
    },
    tableSmall: {
        marginTop: "50px !important",
        display: "block"
    },
    tableMedium: {
        display: "block"
    },
    nameSmall: {
        textAlign: "center"
    },
    avatarSmall: {
        display: "block",
        marginTop: "20px !important",
        marginLeft: "auto",
        marginRight: "auto"
    }
});

@inject("profileStore", "songListStore")
@withRouter
@observer
class SongListViewPage extends Component {
    state = {
        profile: undefined,
        songs: []
    };

    filterList(event) {
        var updatedList = this.state.songs.filter(function(item) {
            return item.title.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        this.props.songListStore.filterSongs(updatedList);
    }

    constructor(props) {
        super(props);
        const param = this.props.match.params.id;
        let searchById = this.props.location.pathname.startsWith("/profile/");

        if (searchById) {
            this.props.profileStore.getPublicProfileById(param).then(result => {
                this.setState({
                    profile: result
                });
            });
            this.props.songListStore.load(param).then(songs => {
                this.setState({
                    songs: songs
                });
                this.props.songListStore.filterSongs(songs);
            });
        } else {
            if(param !== "null") {            
                this.props.profileStore.getPublicProfileByVanityUrl(param).then(result => {
                    this.setState({
                        profile: result
                    });
                    this.props.songListStore.load(result.id).then(songs => {
                        this.setState({
                            songs: songs
                        });
                        this.props.songListStore.filterSongs(songs);
                    });
                });
            }
        }
    }

    renderToolbar() {
        const { classes } = this.props;
        const { profile } = this.state;

        return (
            <div>
                <Hidden only={["xs"]}>
                    <Grid container spacing={8}>
                        <Grid item sm={12} md={6} className={classes.toolbar}>
                            {profile && profile.profileImage ? (
                                <Avatar src={image.getProfileImage(profile)} />
                            ) : this.props.role ? (
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            ) : null}
                            <Typography variant="title" className="profile-name">
                                {profile && profile.fullName + "'s Song List"}
                            </Typography>
                        </Grid>

                        <Grid item sm={12} md={6} className={classes.search}>
                            <FormControl className="pull-right">
                                <Input
                                    placeholder="Search for a Song"
                                    onChange={this.filterList.bind(this)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden only={["sm", "md", "lg", "xl"]}>
                    <Grid container justify="center">
                        <Grid item xs={12} className={classes.toolbarSmall}>
                            {profile && profile.profileImage ? (
                                <Avatar src={image.getProfileImage(profile)} className={classes.avatarSmall} />
                            ) : this.props.role ? (
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} className={classes.toolbarSmall}>
                            <Typography variant="title" className={classes.nameSmall}>
                                {profile && profile.fullName + "'s Song List"}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} className={classes.search}>
                            <FormControl fullWidth>
                                <Input
                                    placeholder="Search for a Song"
                                    onChange={this.filterList.bind(this)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Header />
                <Sidebar forceMobile />
                <div className="song-view-container">
                    <Paper>
                        <Hidden only={["sm", "xs", "md"]}>
                            <Grid container justify="center" spacing={0}>
                                <Grid item sm={12} md={12} className="table-wrapper">
                                    {this.renderToolbar()}
                                    <div className={classes.tableWrapper}>
                                        <SongListViewComponent type="lg" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>

                        <Hidden only={["sm", "xs", "lg", "xl"]}>
                            <Grid container justify="center" className={classes.tableMedium} spacing={0}>
                                <Grid item sm={12} md={12} className="table-wrapper">
                                    {this.renderToolbar()}
                                    <div className={classes.tableWrapper}>
                                        <SongListViewComponent type="md" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>

                        <Hidden only={["lg", "md", "xl"]}>
                            <Grid container justify="center" className={classes.tableSmall} spacing={0}>
                                <Grid item sm={12} md={12}>
                                    {this.renderToolbar()}
                                    <div className={classes.tableWrapper}>
                                        <SongListViewComponent type="sm" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Paper>
                </div>
            </div>
        );
    }
}

SongListViewPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    withWidth()
)(SongListViewPage);
