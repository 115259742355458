import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BookingSocial from "../../components/booking/BookingSocial";
import GeneralSocialPost from "../../components/booking/GeneralSocialPost";

import Breadcrumb from "../../components/Breadcrumb";
import Header from "../../components/Header";
import Heading from "../../components/Heading";
import Loading from "../../components/loadingSpinner/Loading";
import MainContainer from "../../components/MainContainer";
import StaffFilter from "../../components/filters/StaffFilter";
import ReportDateFilterGigs from "../../components/reports/ReportDateFilterGigs";
import Sidebar from "../../components/Sidebar";
import { ProfileType } from "../../types/enum";
import "./SocialPosts.css";
import { Tabs, Tab, Grid, Hidden, TextField, MenuItem } from "@material-ui/core";


const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("templateStore", "profileStore", "bookingRequestStore", "reportStore","generalSocialPostStore")
@withRouter
@observer
class SocialPosts extends Component {
    state = {
        tag: '',
        tabValue: 0,
        
    };

    constructor(props) {
        super(props);

        this.state = {
            tag: '',

        };
        this.props.bookingRequestStore.resetRequestsUpcomingCompleted();
        this.props.reportStore.resetFilterData();
    }

    componentWillUnmount() {
        this.props.reportStore.resetFilterData();
    }

    loadGigs = () => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.resetSocials()
        this.props.generalSocialPostStore.resetSocials()
        this.props.generalSocialPostStore.reset();
        this.props.generalSocialPostStore.showLoadingState();
        this.props.bookingRequestStore.showLoadingState();
       
        if(currentProfile?.type === ProfileType.Staff.ordinal){
            this.props.bookingRequestStore.loadSocialStaff(currentProfile?.id,1).then(() => {
                this.props.bookingRequestStore.hideLoadingState();
            })
            this.props.generalSocialPostStore.loadSocialStaff(currentProfile?.id,1).then(() => {
                this.props.generalSocialPostStore.hideLoadingState();
            })
        }else{
            this.props.bookingRequestStore.loadSocial(currentProfile?.id,1).then(() => {
                this.props.bookingRequestStore.hideLoadingState();
            })
            this.props.generalSocialPostStore.loadSocial(currentProfile?.id,1).then(() => {
                this.props.generalSocialPostStore.hideLoadingState();
            })
        }
    }

    handleTabChange = (event, value) => {
        switch (value) {
            case 0:
                this.props.history.replace("/socialposts/liveentertainmentpost");
                break;
            case 1:
                this.props.history.replace("/socialposts/generalsocialpost");
                break;
            default:
                this.props.history.replace("/socialposts/liveentertainmentpost");
                break;
        }

        this.setState({
            tabValue: value
        });
    };

    componentDidMount = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.renderTag(currentProfile)
        
        if (this.props.match.params.tab === "liveentertainmentpost") {
            this.setState({
                tabValue: 0
            });
        } else if (this.props.match.params.tab === "generalsocialpost") {
            this.setState({
                tabValue: 1
            });
        }else{
            this.setState({
                tabValue: 0
            });
        }
    };

    renderTag = (profile) => {
        switch (profile.type) {
            case ProfileType.Musician.ordinal:
                this.setState({tag: "musician"})
                break;
            case ProfileType.Agent.ordinal:
                this.setState({tag: "agent"})
                break;
            case ProfileType.Staff.ordinal:
                this.setState({tag: "staff"})
                break;
        
            default:
                break;
        }
    }
    renderTabComponent = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0:
                return <BookingSocial profileType={`${this.state.tag}`} social />
            case 1:
                return <GeneralSocialPost profileType={`${this.state.tag}`} social />
            default:
                return "";
        }
    };
    handleSelectChange = event => {
        
        switch (event.target.value) {
            case 0:
                this.props.history.replace("/socialposts/liveentertainmentpost");
                break;
            case 1:
                this.props.history.replace("/socialposts/generalsocialpost");
                break;
            default:
                this.props.history.replace("/socialposts/liveentertainmentpost");
                break;
        }

        this.setState({
            tabValue: event.target.value
        });
    };

    render() {
        const breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Social Posts" }];
        let { isLoading: isLoadingGigs } = this.props.bookingRequestStore;
        let { isLoading: isLoadingGigsGeneral } = this.props.generalSocialPostStore;
        const { currentProfile } = this.props.profileStore;
        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="Social Posts">
                        {this.state.tag === "staff" ? <StaffFilter isSocialPost={true} showActFilter postChangeCreator={this.loadCreator} postChangeEvent={this.loadGigs} /> : <ReportDateFilterGigs postChangeEvent={this.loadGigs} isSocialPost={true} />
                        }
                    </Heading>
                    <Paper style={{ position: "relative" }} className="booking-paper-form">
                        <Loading showed={isLoadingGigs} />
                        <Loading showed={isLoadingGigsGeneral} />
                        <Hidden smDown>
                            <Tabs value={this.state.tabValue} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                <Tab label={"Live Entertainment Posts"}/>
                                <Tab label={"General Social Posts"} />

                            </Tabs>
                            <br />
                        </Hidden>
                        <Hidden mdUp>
                            <div style={{ padding: 8 }}>
                                <TextField select value={this.state.tabValue} onChange={this.handleSelectChange} textColor="primary" fullWidth>
                                    <MenuItem key={0} value={0}>
                                        {currentProfile?.type === ProfileType.Musician ? "My Gigs" : "Live Entertainment Posts"}
                                    </MenuItem>
                                    <MenuItem key={1} value={1}>
                                        {"General Social Posts"}
                                    </MenuItem>
                                </TextField>
                            </div>
                        </Hidden>
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                                <div style={{ padding: 16 }}>{this.renderTabComponent()}</div>
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* <Paper style={{ position: "relative" }} className="booking-paper-form">
                        <Loading showed={isLoadingGigs} />
                        <div style={{ padding: 16 }}>
                            <BookingSocial profileType={`${this.state.tag}`} social />
                        </div>
                    </Paper> */}
                </MainContainer>
            </div>
        );
    }
}

SocialPosts.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(SocialPosts);
