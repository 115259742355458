import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import "../stepper.css";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import BookingDetails from "../../components/booking/BookingDetails";
import Negotiate from "../../components/booking/Negotiate";
import BookingLineup from "../../components/booking/BookingLineup";
import BookingSetlist from "../../components/booking/BookingSetlist";
import { BookingStatusType } from "../../types/enum";
import BookingNotFound from "../profile/bookingInvalidAccess";
import BookingStatusBadge from "../../components/booking/BookingStatusBadge";
import SocialPostsDetailComponent from "../GigPromotion/SocialPostsDetailComponent"

import moment from "moment";
import History from "../../components/booking/History";

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("templateStore", "profileStore", "bookingRequestStore", "lineupStore", "facebookStore", "invoiceStore")
@withRouter
@observer
class EditMusicianBooking extends Component {
    constructor(props) {
        super(props);
        this.props.bookingRequestStore.resetRequestsUpcomingCompleted();
        this.props.lineupStore.reset();
    }
   

    componentDidMount = () => {
        const { currentProfile } = this.props.profileStore;
        const bookingId = this.props.match.params.id;
        this.props.templateStore.setEditMusicianBookingActiveStep(0);
    };

    componentDidUpdate = () => {
        const { booking } = this.props.bookingRequestStore;

        if (booking && booking.isAdmin) {
            switch (this.props.match.params.step) {
                case "details":
                    this.props.templateStore.setEditMusicianBookingActiveStep(0);
                    break;
                case "negotiate":
                    this.props.templateStore.setEditMusicianBookingActiveStep(1);
                    break;
                case "lineup":
                    this.props.templateStore.setEditMusicianBookingActiveStep(2);
                    break;
                case "promote":
                    this.props.templateStore.setEditMusicianBookingActiveStep(3);
                    break;
                case "setlist":
                    this.props.templateStore.setEditMusicianBookingActiveStep(4);
                    break;
                case "history":
                    this.props.templateStore.setEditMusicianBookingActiveStep(5);
                    break;
                case "finish":
                    this.props.templateStore.setEditMusicianBookingActiveStep(6);
                    break;
                default:
                    this.props.templateStore.setEditMusicianBookingActiveStep(0);
                    break;
            }
        } else if (booking && !booking.isAdmin) {
            switch (this.props.match.params.step) {
                case "details":
                    this.props.templateStore.setEditMusicianBookingActiveStep(0);
                    break;
                case "lineup":
                    this.props.templateStore.setEditMusicianBookingActiveStep(1);
                    break;
                case "promote":
                    this.props.templateStore.setEditMusicianBookingActiveStep(2);
                    break;
                case "setlist":
                    this.props.templateStore.setEditMusicianBookingActiveStep(3);
                    break;
                case "finish":
                    this.props.templateStore.setEditMusicianBookingActiveStep(4);
                    break;
                default:
                    this.props.templateStore.setEditMusicianBookingActiveStep(0);
                    break;
            }
        }
    };

    shouldComponentUpdate(nextProps) {
        if (this.props && nextProps && this.props.bookingRequestStore.booking !== nextProps.bookingRequestStore.booking) {
            return true;
        }

        if (
            this.props.match.params.id !== nextProps.match.params.id ||
            this.props.match.params.bookingRequestId !== nextProps.match.params.bookingRequestId
        ) {
            this.props.bookingRequestStore.load(nextProps.match.params.id, nextProps.match.params.bookingRequestId).then(() => {
                const { booking } = this.props.bookingRequestStore;

                this.props.facebookStore.setBooking(booking);
                const message =
                    "Next Gig at " +
                    booking.venueName +
                    ", " +
                    booking.venueAddress +
                    ". Book the date on " +
                    moment(new Date(booking.dateStart)).format("DD-MM-YYYY HH:mm") +
                    ". Tickets are on sale now!";
                this.props.facebookStore.setPostMessage(message);
            });
            return true;
        }

        if (this.props && nextProps && this.props.match.params.step !== nextProps.match.params.step) {
            return true;
        }

        return false;
    }
    redirectPromote = () => {
        const bookingId = this.props.match.params.id;
        this.props.history.replace(`/musician/booking/${bookingId}/promote`);
    }
    getStepContent(stepIndex, isAdmin, isNegotiate) {
        const bookingId = this.props.match.params.id;
        if (isAdmin) {
            switch (stepIndex) {
                case 0:
                    return <BookingDetails type="musician" bookingId={bookingId} />;
                case 1:
                    return <Negotiate type="musician" bookingId={bookingId} />;
                case 2:
                    return <BookingLineup redirectActionPromote={this.redirectPromote} type="musician" bookingId={bookingId} />;
                case 3:
                    return <SocialPostsDetailComponent bookingId={bookingId}/>;
                case 4:
                    return <BookingSetlist type="musician" bookingId={bookingId} />;
                case 5:
                    return <History type="musician" bookingId={bookingId} />;
                default:
                    return "Uknown stepIndex";
            }
        }

        if (!isAdmin) {
            switch (stepIndex) {
                case 0:
                    return <BookingDetails type="musician" bookingId={bookingId} />;
                case 1:
                    return <BookingLineup redirectActionPromote={this.redirectPromote}  type="musician" bookingId={bookingId} />;
                case 2:
                    return <SocialPostsDetailComponent bookingId={bookingId} />;
                case 3:
                    return <BookingSetlist type="musician" bookingId={bookingId} />;
                default:
                    return "Uknown stepIndex";
            }
        }
    }

    getSteps(isAdmin, status) {
        if(!isAdmin && status == BookingStatusType.Draft.ordinal) return ["Booking Details", "Notify Client", "History"];

        if (!isAdmin) return ["Booking Details", "Line-up", "Promote", "Setlist"];

        if (isAdmin) return ["Booking Details", "Negotiate/Message", "Line-up", "Promote",  "Setlist", "History"];
    }

    handleNext = () => {
        const { editMusicianBookingActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditMusicianBookingActiveStep(editMusicianBookingActiveStep + 1);
    };

    handleBack = () => {
        const { editMusicianBookingActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditMusicianBookingActiveStep(editMusicianBookingActiveStep - 1);
    };

    handleReset = () => {
        this.props.templateStore.setEditMusicianBookingActiveStep(0);
    };

    handleStepClick = step => () => {
        const { booking } = this.props.bookingRequestStore;
        const bookingRequestUrl = this.props.match.params.bookingRequestId ? `/${this.props.match.params.bookingRequestId}` : "";
        if (booking && booking.isAdmin) {
            switch (step) {
                case 0:
                    this.props.history.replace(`/musician/booking/${booking.id}/details${bookingRequestUrl}`);
                    break;
                case 1:
                    this.props.history.replace(`/musician/booking/${booking.id}/negotiate`);
                    break;
                case 2:
                    this.props.history.replace(`/musician/booking/${booking.id}/lineup`);
                    break;
                case 3:
                    this.props.history.replace(`/musician/booking/${booking.id}/promote`);
                    break;
                case 4:
                    this.props.history.replace(`/musician/booking/${booking.id}/setlist`);
                    break;
                case 5:
                    this.props.history.replace(`/musician/booking/${booking.id}/history`);
                    break;
                case 6:
                    this.props.history.replace(`/musician/booking/${booking.id}/finish`);
                    break;
                default:
                    this.props.history.replace(`/musician/booking/${booking.id}/details`);
                    break;
            }
        } else if (booking && !booking.isAdmin) {
            switch (step) {
                case 0:
                    this.props.history.replace(`/musician/booking/${booking.id}/details`);
                    break;
                case 1:
                    this.props.history.replace(`/musician/booking/${booking.id}/lineup`);
                    break;
                case 2:
                    this.props.history.replace(`/musician/booking/${booking.id}/promote`);
                    break;
                case 3:
                    this.props.history.replace(`/musician/booking/${booking.id}/setlist`);
                    break;
                case 4:
                    this.props.history.replace(`/musician/booking/${booking.id}/finish`);
                    break;
                default:
                    this.props.history.replace(`/musician/booking/${booking.id}/details`);
                    break;
            }
        }
    };

    handleRedirectToInvoice = () => {
        const { booking } = this.props.bookingRequestStore
        this.props.history.replace(`/invoice/${booking.invoiceId}/detail`);
    }
    render() {
        const { classes } = this.props;
        const { editMusicianBookingActiveStep } = this.props.templateStore;
        const { currentProfile } = this.props.profileStore;
        const { booking } = this.props.bookingRequestStore
        let { title, isAdmin, status, dateStart, venueName, venue, myLineup, cancelledBy, bookingRequests } = this.props.bookingRequestStore.booking;
        const steps = this.getSteps(isAdmin, status);
        const breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Booking", link: "/musician/gig" }, { name: title }];

        var statusBadge = "New";
        let resentBooking = cancelledBy && currentProfile && cancelledBy.userId === currentProfile.userId && bookingRequests?.length > 0 && bookingRequests[0].act.userId !== currentProfile.userId ? true : false;
   
        if (status === BookingStatusType.Resent.ordinal) {
            statusBadge = BOOKING_STATUS[BookingStatusType.New.ordinal];
        } else {
            statusBadge = BOOKING_STATUS[status];
        }

        return (
            <div>
                <Header />
                <Sidebar />
                    {booking && booking.id && (!isAdmin && !myLineup) || resentBooking ? (
                        <MainContainer>
                            <BookingNotFound />
                        </MainContainer>
                    ) : (
                        <MainContainer>
                        <Breadcrumb links={breadcrumb} />
                        <Heading
                            title={
                                currentProfile
                                    ? status === BookingStatusType.New.ordinal
                                        ? `Booking Request - ${venue ? venue.profileName : venueName} / ${moment(dateStart).format("DD-MM-YYYY")}`
                                        : `Edit Booking - ${venue ? venue.profileName : venueName} / ${moment(dateStart).format("DD-MM-YYYY")}`
                                    : "Create Musician Profile"
                            }
                        >
                           
                          

                            <div style={{ display: "inline-block" }}>
                               
                               <Button className="viewInvoiceButton" style={{display: booking.invoiceId ? "inline" : "none"}} variant="contained" color="primary" onClick={this.handleRedirectToInvoice}>
                                        View Invoice
                                    </Button>
                                <BookingStatusBadge status={statusBadge} cancelledBy={cancelledBy ? cancelledBy.type : 0} />
                            </div>

                        </Heading>
                        <Hidden smDown>
                            <Stepper
                                nonLinear={status && status !== BookingStatusType.New.ordinal && status !== BookingStatusType.Resent.ordinal}
                                activeStep={editMusicianBookingActiveStep}
                                alternativeLabel={false}
                            >
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Hidden>
                        <Hidden mdUp xsDown>
                            <Stepper
                                nonLinear={status && status !== BookingStatusType.New.ordinal && status !== BookingStatusType.Resent.ordinal}
                                activeStep={editMusicianBookingActiveStep}
                                alternativeLabel={true}
                            >
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Hidden>
                        <Hidden smUp>
                            <Stepper
                                nonLinear={status && status !== BookingStatusType.New.ordinal}
                                activeStep={editMusicianBookingActiveStep}
                                alternativeLabel={false}
                                className="stepperXs"
                            >
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepButton onClick={this.handleStepClick(index)} />
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Hidden>
                        {editMusicianBookingActiveStep === steps.length ? (
                            <Paper className="stepperContent">
                                <Typography className={classes.instructions} component="h3">
                                    All steps completed - you're profile is ready!
                                </Typography>
                                <Button onClick={this.handleReset}>Reset</Button>
                            </Paper>
                        ) : (
                            <Paper className="stepperContent">{this.getStepContent(editMusicianBookingActiveStep, isAdmin)}</Paper>
                        )}
                        </MainContainer>
                    )}      
                   
            </div>
        );
    }
}

EditMusicianBooking.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(EditMusicianBooking);
