import React, { Component } from "react";

import { observer, inject } from "mobx-react";

import { Snackbar, IconButton } from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";

@inject("templateStore")
@observer
class SimpleSnackbar extends Component {
    render() {
        return (
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={this.props.templateStore.snackbarState}
                onClose={() => this.props.templateStore.closeSnackbar()}
                autoHideDuration={5000}
                message={<span id="message-id">{this.props.templateStore.snackbarMessage}</span>}
                action={
                    <IconButton onClick={() => this.props.templateStore.closeSnackbar()} color="primary">
                        <ClearIcon />
                    </IconButton>
                }
            />
        );
    }
}

export default SimpleSnackbar;
