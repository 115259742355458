import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Button, Grid, Typography, AppBar, Tabs, Tab, Tooltip } from "@material-ui/core";
import SetListForm from "../../components/setList/SetListForm";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ConfirmationModal from "../modal/ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";

import DeleteIcon from "@material-ui/icons/Delete";

@inject("privacyProfileStore", "templateStore", "authStore", "setListStore", "profileStore")
@withRouter
@observer
class Setlist extends React.Component {
    state = {
        tab: 0,
        confirmDelete: false,
        loading: false,
        success: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initialActSetlist();
        this.props.setListStore.reset();
        this.props.setListStore.setType(1);
        this.props.setListStore.setProfileId(this.props.profileId);
        this.props.setListStore.loadSetlists();
    }

    initialActSetlist = () => {
        if (this.props.setListStore.flagNewSetlistAct) {
            let songItems = this.props.setListStore.selectedSongs.slice();
            this.props.setListStore.addNewSetlist(songItems);
            this.setState({ tab: this.props.setListStore.setLists.length });
        } else {
            this.setState({ tab: 0 });
        }
        this.props.setListStore.setNewActSetlist(false);
    };

    handleBack = () => {
        if (this.props.type === "act") {
            this.props.history.replace(`/myact/edit/${this.props.match.params.id}/songlist`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.props.setListStore.submit().then(() => {
            this.handleNextStep();
        });
    };

    handleNextStep = () => {
        if (this.props.type !== "act" && this.props.type !== "venue") {
            const { editProfileActiveStep } = this.props.templateStore;
            this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
        }
        this.props.history.push(`/myact/edit/${this.props.profileId}/toc`);
    };

    handleCreateSetlist = () => {
        this.props.setListStore.addNewSetlist();
        this.setState({ tab: this.props.setListStore.setLists.length });
    };

    handleDelete = () => {
        this.props.setListStore.deleteCurrentSetlist().then(() => {
            this.setState({ tab: 0, confirmDelete: false });
        });
    };

    handleOpenDelete = () => this.setState({ confirmDelete: true });
    handleCloseDelete = () => this.setState({ confirmDelete: false });
    handleTabChange = (event, value) => this.setState({ tab: value });

    render() {
        const { setLists, title, id } = this.props.setListStore;
        const { tab, loading } = this.state;
        const { profile } = this.props.profileStore;
        const { editProfileActiveStep } = this.props.templateStore;
        const msgDelete = <p style={{display:"inline"}}>Are you sure to delete <div style={{fontWeight:"bold", display:"inline"}}>{title}</div>?</p>

        return (
            <div className="Profile-Privacy">
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Grid container spacing={16}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Set List
                                </Typography>
                                <Typography gutterBottom>
                                    Use section to create set lists for your act. These set lists can then be selected for your gigs.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item sm={12} md={12} lg={12} style={{ textAlign: "right" }}>
                                    <Tooltip title="Delete the current setlist">
                                        <Button variant="flat" color="default" aria-label="import" onClick={this.handleOpenDelete}>
                                            {this.state.success ? <CheckIcon /> : <DeleteIcon />} Delete
                                        </Button>
                                    </Tooltip>
                                    <Button
                                        variant="flat"
                                        color="primary"
                                        aria-label="add"
                                        disabled={loading || profile === undefined}
                                        onClick={this.handleCreateSetlist}
                                    >
                                        {this.state.success ? <CheckIcon /> : <AddIcon />} Create Setlist
                                    </Button>
                                    {loading && <CircularProgress size={53} />}
                                </Grid>
                            </Grid>
                            <hr />
                            {(setLists[0] !== undefined) ?
                                <AppBar position="static">
                                    <Tabs value={tab} onChange={this.handleTabChange} scrollable scrollButtons="auto">
                                        {setLists.map(set => {
                                            return <Tab label={set.setList.title} key={set.setList.id} />;
                                        })}
                                    </Tabs>
                                </AppBar> : null
                            }
                            {setLists.map((set, index) => {
                                return (
                                    <div key={index}>
                                        {tab === index && (
                                            <div style={{ padding: 16 }}>
                                                <SetListForm type="act" id={set.setList.id} profileId={this.props.profileId} />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            {(!setLists || setLists.length === 0) && (
                                <div style={{ margin: 24, textAlign: "center" }}>
                                    <h4>You don't have any setlist yet. Click create setlist to create one or import from your musician profile.</h4>
                                </div>
                            )}

                            <ConfirmationModal
                                open={this.state.confirmDelete}
                                title={`Delete Set List`}
                                message={msgDelete}
                                closeHandler={this.handleCloseDelete}
                                confirmationHandler={this.handleDelete}
                                declineHandler={this.handleCloseDelete}
                                confirmationLabel="Delete"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <div className="action">
                                <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                    Back
                                </Button>
                                <Button variant="contained" color="primary" onClick={id ? this.handleSubmitForm : this.handleNextStep}>
                                    Save/Next
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default Setlist;
