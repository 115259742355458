import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import SelectField from "../../components/fields/SelectField";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
const ENABLE_XERO = process.env.REACT_APP_ENABLE_XERO === "true";
@inject("authStore", "templateStore", "userStore","profileStore","xeroStore")
@observer
class ContactMapping extends Component {
    state = {
        xeroActive: false,
        xeroAccount: "",
        editedId: "",
        updateProcess: false,
        xeroId: "",
        mappedId: "",
        newcontactId:"",
        openRemoveContactModalState: false,
        removedId : null,
        editedXeroId: "",
        confirmNewContactModalState: false
    };
    contactMappingColumn = [
        { key: "Contacts", label: "Hot Giggity Contacts",width:"25%" },
        { key: "xeroContacts", label: "Xero Contacts",width:"30%"  },
        { key: "status", label: "Status",width:"15%"  },
        { key: "action", label: "",width:"30%"  }
    ];
    
    
    contactMappingRows = [];
    componentDidMount = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(ENABLE_XERO && currentProfile && currentProfile.activateXeroAccess && currentProfile.xeroTenantId){
            Promise.all([
                this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                this.props.xeroStore.getXeroContacts(currentProfile.id)
            ]).then(() => { 
      
            });
        }
       
    };
    openRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: true,
            removedId : id
        });
    };
    closeRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: false,
            removedId : null
        });
    };
    handleMappedId = (contactId,xeroId) => {
        this.props.xeroStore.setEditedXero(contactId,xeroId)

    }
    setEditedContact = value =>  {
        const { editedXeroId } = this.state
        if(this.state.editedId && !editedXeroId){
            this.props.xeroStore.cancelEditedXero(this.state.editedId)
        }
        if(editedXeroId){
            this.props.xeroStore.cancelUpdatedXero(this.state.editedId)
        }
        this.setState({
            editedId: value,
            updateProcess: false,
            editedXeroId: ""
        });
    }

    setUpdatedContact = (data) =>  {
        const { editedXeroId } = this.state
        if(this.state.editedId && !editedXeroId){
            this.props.xeroStore.cancelEditedXero(this.state.editedId)
        }
        if(editedXeroId){
            this.props.xeroStore.cancelUpdatedXero(this.state.editedId)
        }
        this.props.xeroStore.setUpdatedXero(data.contactId)
        // this.props.xeroStore.cancelEditedXero(existingUID)
        this.setState({
            editedId: data.contactId,
            editedXeroId: data.xeroId,
            updateProcess: true
        });
    }

    cancelEditedContact = (contactId) =>{
       
        const { editedXeroId } = this.state
        if(editedXeroId){
            this.props.xeroStore.cancelUpdatedXero(contactId)
        }else{
            this.props.xeroStore.cancelEditedXero(contactId)
        }
        this.setState({
            editedId: "",
            editedXeroId: "",
            updateProcess:false
        });
        
        
    }
    

    handleDeleteContact = () => {
        const { removedId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        this.props.xeroStore.deleteXeroMapping(removedId).then(result =>  {
            Promise.all([
                this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                // this.props.xeroStore.getXeroContacts(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    openRemoveContactModalState: false,
                    removedId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
        
    }

    handleOpenConfirmCreateNewContact = id => {
        this.setState({
            confirmNewContactModalState: true,
            newcontactId: id,
            editedId: "",
            editedXeroId: ""
        });
    };

    handleCloseConfirmNewContactInvitationModal = () => {
        this.setState({
            ...this.state,
            confirmNewContactModalState: false,
            newcontactId: undefined
        });
    };

    saveEditedContact = (mappedId, contactId,xeroId) =>{
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        if(this.state.updateProcess){
            this.props.xeroStore.updateContactXero(mappedId, xeroId).then(result =>  {
                Promise.all([
                    this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                    // this.props.xeroStore.getXeroContacts(currentProfile.id)
                ]).then(() => { 
                    // this.props.xeroStore.cancelEditedXero(contactId)
                    this.setState({
                        editedId: "",
                        editedXeroId: ""
                    });
                });
              
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
               
            })
        }
        else{
            this.props.xeroStore.mapExistingXeroContact(currentProfile.id, contactId, xeroId).then(result =>  {
                Promise.all([
                    this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                    // this.props.xeroStore.getXeroContacts(currentProfile.id)
                ]).then(() => { 
                    // this.props.xeroStore.cancelEditedXero(contactId)
                    this.setState({
                        editedId: ""
                    });
                });
              
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
               
            })
        }
       
        
    }
    handleCreateNewContact = () => {
        const { newcontactId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        this.props.xeroStore.createXeroContacts(currentProfile.id, newcontactId).then(result =>  {
            Promise.all([
                this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                this.props.xeroStore.getXeroContacts(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    confirmNewContactModalState: false,
                    newcontactId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
       
    }

    mapContactsToRowData = () => {
        const { listXeroMappedContacts,xeroContacts,xeroContactsFull } = this.props.xeroStore;
        
        const {editedId} = this.state
        this.contactMappingRows = [];

        listXeroMappedContacts.forEach(data => {

            var row = {};

            row.Contacts = data.profileName ? data.profileName : data.firstName + " " + data.lastName

            if(data.xeroId && !data.isEdited && !data.isUpdated){
                var existingUID = xeroContactsFull.filter(x=>x.value === data.xeroId)
                if(existingUID.length){
                    row.xeroContacts = <TextField
                    // label="Xero Contact Name"
                    inputProps={{
                        readOnly: true
                    }}
                    value={existingUID[0].label}
                    fullWidth
                />
                }
               
            }else if(data.contactId != editedId){
                row.xeroContacts = <TextField
                // label="Xero Contact Name"
                inputProps={{
                    readOnly: true
                }}
                value="Map Xero Account"
                fullWidth
            />
            }else{
                row.xeroContacts =  <SelectField
                id="xeroContacts"
                value={data.xeroId}
                suggestions={xeroContacts}
                placeholder="Map Xero Account"
                disabled={data.contactId != editedId}
                onChange={(value) => this.handleMappedId(data.contactId,value)}
            />
            }
            
           
            
           
            row.status = data.xeroId ? ((data.isEdited || data.isUpdated) ? "Confirm?" : "Mapped" ) : "Not Mapped";

            if(data.xeroId && !data.isEdited && !data.isUpdated){
                row.action = (
                    <Fragment>
                         <Button size="small" color="primary" onClick={() => this.setUpdatedContact(data)}>
                            Update
                        </Button>
                        <Button size="small" color="primary" onClick={() => this.openRemoveContactModal(data.mappedId)}>
                            Remove
                        </Button>
                    </Fragment>
                );
    
            }else{
                if(data.isEdited || data.isUpdated){
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.saveEditedContact(data.mappedId,data.contactId,data.xeroId)}>
                                Save
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.cancelEditedContact(data.contactId)}>
                                Cancel
                            </Button>
                        </Fragment>
                        
                    );
                   
                }else{
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.setEditedContact(data.contactId)}>
                                Map Existing
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.handleOpenConfirmCreateNewContact(data.contactId)}>
                                Create New
                            </Button>
                        </Fragment>
                        
                    );
                }
                
            }
           
            // row.style = {
            //     backgroundColor: "#FFC0CB"
            // };

            this.contactMappingRows.push(row);
        });
        return Promise.resolve();
    };

    render() {
        this.mapContactsToRowData()
        return (
            <div>
                <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                Your Contacts are the contacts, venues or agents that are listed in your outgoing invoices section.
                                            </Typography>
                                            <Typography gutterBottom>
                                                Mapping your Hot Giggity Contacts to your Xero Contacts allows the system to sync invoices to the correct contact in Xero
                                            </Typography>
                                            <Typography gutterBottom>
                                                You will only have to do this once. New Hot Giggity Contacts added after the initial mapping will automatically be added to your Xero account.
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item md={12}>
                                                        <Typography gutterBottom>Step 3. Map your Hot Giggity Contacts your existing Xero Contacts.</Typography>

                                                        
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <ResponsiveTable
                                                            columns={this.contactMappingColumn}
                                                            rows={this.contactMappingRows}
                                                            showToolbar={true}
                                                            title="Contact Mapping"
                                                            pagination={this.contactMappingRows.length > 5}
                                                        />

                                                    </Grid>  
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>

                <ConfirmationModal
                    open={this.state.confirmNewContactModalState}
                    message="Are you sure you want to create a new Xero contact for this contact?"
                    closeHandler={this.handleCloseConfirmNewContactInvitationModal}
                    confirmationHandler={this.handleCreateNewContact}
                    declineHandler={this.handleCloseConfirmNewContactInvitationModal}
                    confirmationLabel="Confirm"
                />
                 <ConfirmationModal
                    open={this.state.openRemoveContactModalState}
                    message="Are you sure you want to remove the existing connection to Xero contact?"
                    closeHandler={this.closeRemoveContactModal}
                    confirmationHandler={this.handleDeleteContact}
                    declineHandler={this.closeRemoveContactModal}
                    confirmationLabel="Confirm"
                />
            </div>
            
        );
    }
}

export default ContactMapping;
