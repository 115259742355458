import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { LinkContainer } from "react-router-bootstrap";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import WidgetFormContainer from "../../components/widget/WidgetFormContainer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { Grid, Typography, Button, TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

@inject("authStore", "profileStore", "templateStore")
@observer
class ChangePassword extends Component {
    state = {
        password: "",
        newPassword: "",
        confirmPassword: "",
        showPassword: false
    };

    componentDidMount = () => {
        this.props.authStore.reset();
    };

    handlePasswordChange = e => this.props.authStore.setPassword(e.target.value);
    handleNewPasswordChange = e => this.props.authStore.setNewPassword(e.target.value);
    handleConfirmPasswordChange = e => this.props.authStore.setConfirmPassword(e.target.value);

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = name => () => {
        this.setState({ [name]: !this.state[name] });
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.props.authStore.changePassword().then(() => this.props.history.push("/account/setting"));
    };

    render() {
        const { values } = this.props.authStore;
        const { currentProfileType } = this.props.profileStore;

        return (
            <div>
                <Header role={this.props.templateStore.getTypeString(currentProfileType || 1)} />
                <Sidebar />
                <MainContainer>
                    <WidgetFormContainer title="Manage Your Account" showBadge={false}>
                        <form onSubmit={this.handleSubmitForm} className="form-relative">
                            <Grid container spacing={8}>
                                <Grid item xs={12} lg={4}>
                                    <Typography type="headline" component="h3" gutterBottom>
                                        Change Your Password
                                    </Typography>
                                    <Typography>
                                        Tip: Your password must contain at least 8 character with at least 1 uppercase letter, 1 lowercase letter, 1
                                        number
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} lg={8}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="password"
                                                label="Current Password"
                                                value={values.password}
                                                onChange={this.handlePasswordChange}
                                                fullWidth
                                                type={this.state.showPassword ? "text" : "password"}
                                                margin="normal"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={this.handleClickShowPassword("showPassword")}
                                                                onMouseDown={this.handleMouseDownPassword}
                                                            >
                                                                {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={this.anyError("password")}
                                                helperText={this.errorText("password")}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={8}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        id="newPassword"
                                                        label="New Password"
                                                        value={values.newPassword}
                                                        onChange={this.handleNewPasswordChange}
                                                        fullWidth
                                                        type={this.state.showNewPassword ? "text" : "password"}
                                                        margin="normal"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={this.handleClickShowPassword("showNewPassword")}
                                                                        onMouseDown={this.handleMouseDownPassword}
                                                                    >
                                                                        {this.state.showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        error={this.anyError("newPassword")}
                                                        helperText={this.errorText("newPassword")}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        id="confirmPassword"
                                                        label="Confirm Password"
                                                        value={values.confirmPassword}
                                                        onChange={this.handleConfirmPasswordChange}
                                                        fullWidth
                                                        type={this.state.showConfirmPassword ? "text" : "password"}
                                                        margin="normal"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={this.handleClickShowPassword("showConfirmPassword")}
                                                                        onMouseDown={this.handleMouseDownPassword}
                                                                    >
                                                                        {this.state.showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        error={this.anyError("confirmPassword")}
                                                        helperText={this.errorText("confirmPassword")}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} className="text-xs-right">
                                                    <Button variant="contained" color="primary" type="submit">
                                                        Change Password
                                                    </Button>

                                                    <LinkContainer to="/account/setting" style={{ marginLeft: 10 }}>
                                                        <Button raised>Close</Button>
                                                    </LinkContainer>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </WidgetFormContainer>
                </MainContainer>
            </div>
        );
    }
}

export default ChangePassword;
