import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import moment from "moment"
import { Promise } from "bluebird";
import { Storage } from "aws-amplify";
import CacheHelper from '../helper/cache';

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class SocialPromotionStore{

    @observable socialPost = {
        id: undefined,
        scheduleTime : new Date(),
        gigImage: undefined,
        gigGalleryImage: undefined,
        isProfile: false,
        isProfile: false,
        useProfileSetting: true,
        postContent: "",
        switchInstagram: false,
        switchFacebook: false,
        gigImageData: undefined,
        postFacebookId: null,
        postInstagramId: null,
        useProfileSetting: true
    }
    @observable
    timeStartString = "19:00"

    @observable
    showProfileSetting = false

    @observable
    eventDate = new Date()

    @action
    reset(){
        this.socialPost = {
            id: undefined,
            scheduleTime : new Date(),
            gigImage: undefined,
            gigGalleryImage: undefined,
            isProfile: false,
            isProfile: false,
            useProfileSetting: true,
            postContent: "",
            switchInstagram: false,
            switchFacebook: false,
            gigImageData: undefined,
            postFacebookId: null,
            postInstagramId: null,
            useProfileSetting: false
        }
        this.showProfileSetting = false;
        this.eventDate = new Date();
    }
    @action
    setEventDate(eventDate) {
        this.eventDate = eventDate;
    }

    @action
    setTimeStartString(timeStart) {
        this.timeStartString = timeStart;
    }

    @action
    setImageSocialPost(key, value)
    {
        this.socialPost[key] = value;
    }

    @action
    setProfileSetting(value){
        this.showProfileSetting = value
    }
    @action
    setFromGalleryImage(galleryImage, isProfile)
    {
        this.socialPost.gigGalleryImage = galleryImage;
        this.socialPost.isProfile = isProfile;
        
    }
    @action
    unSetFromGalleryImage()
    {
        this.socialPost.gigGalleryImage = undefined;
        this.socialPost.isProfile = false;
    }

    @action
    async load(bookingId,profileId){
        return agent.SocialPost.get(bookingId, profileId).then(
            action(async response => {
                if(response){
                    var gigImage = response?.gigImage
                    if(gigImage){
                        gigImage.url = await CacheHelper.getImgLink(gigImage.url);
                    }
                    runInAction(() => {
                        this.socialPost.id= response?.id,
                        this.socialPost.scheduleTime = response?.scheduleTime,
                        this.eventDate = moment(response?.scheduleTime),
                        this.timeStartString = response ? moment(response?.scheduleTime).format("HH:mm") : "19:00",
                        this.socialPost.gigImage = gigImage,
                        this.socialPost.postContent = response?.postContent,
                        this.socialPost.switchInstagram = response?.switchInstagram,
                        this.socialPost.switchFacebook = response?.switchFacebook,
                        this.socialPost.postFacebookId = response?.postFacebookId,
                        this.socialPost.postInstagramId = response?.postInstagramId
                        this.socialPost.useProfileSetting = response?.useProfileSetting === 1
                    })
                }
            })
        )
    }

    @action
    async cancelSosicalPost(id){
        return agent.SocialPost.cancel(id).then(response => {
            return response;
         })
    }
    @action
    async schedulePost(bookingId,profileId, isPostNow){
        if(this.socialPost.gigImage){
            var data = {
                scheduleTime : `${moment(this.eventDate).endOf("day").format("YYYY-MM-DD")} ${moment(this.timeStartString, "HH:mm").format("HH:mm")}`,
                postContent: this.socialPost.postContent,
                switchInstagram: this.socialPost.switchInstagram,
                switchFacebook: this.socialPost.switchFacebook,
                bookingId: bookingId,
                profileId: profileId,
                utcOfset: new Date().getTimezoneOffset(),
                useProfileSetting: this.socialPost.useProfileSetting
            }
            return agent.SocialPost.create(data).then(response => {
               return response;
            })
        }else{
            return Promise.reject("image is required")
        }
      
    }

    @action
    async postToInstagram(id){
        return agent.SocialPost.postToInstagram(id).then(() => {
            
        })
      
    }

    @action
    async postToFacebook(id){

        return agent.SocialPost.postToFacebook(id).then((response) => {
            
        })
      
    }

    @action
    async setSocialPostImage(socialPostId, profileId) {
        var image = this.socialPost.gigImage;
        const fileExtension = image.fileName? image.fileName.split('.').pop() : image.name.split('.').pop() ;
        var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
        var name = `${ticks}.${fileExtension}`;
        var result = await this.uploadSocialPost(socialPostId, image, name);
        var socialPostImage = {
            url: result.key,
            filename: name,
            mime: image.type,
            socialPostId: socialPostId,
            profileId: profileId
        }
       
        var uploadedImage =  await agent.SocialPost.postImage(socialPostImage)
        return uploadedImage
        
    }

    async uploadSocialPost(id, profileImage, name) {
        try {
            const result = await Storage.put(
                `socialPost/${id}/${name}`,
                profileImage
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }
    
   
}

export default new SocialPromotionStore();