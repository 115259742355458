import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import SelectField from "../../components/fields/SelectField";
import ConfirmationModal from "../../components/modal/ConfirmationModal";

import { withRouter } from "react-router-dom";
@inject("authStore", "templateStore", "userStore","profileStore","myobStore")
@withRouter
@observer
class SyncMYOB extends Component {
   
    componentDidMount = () => {
   
    };

    redirectToMYOB = () => {
        this.props.history.push('/invoice')
    }
    render() {
        return (
            <div>
                <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                To ensure accuracy and data integrity, synchronising your Hot Giggity invoices into MYOB is a manual process.
                                            </Typography>
                                            <Typography gutterBottom>
                                                We recommend only doing this once per month on the 1st day of the month for example
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item md={12}>
                                                        <Typography gutterBottom>Step 5. Synchronise your Hot Giggity invoices with MYOB. </Typography>
                                                        <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>Synchronising your Hot Giggity invoices to your accounting package is done via the invoices tab.</Typography>
                                                
                                                    </Grid>
                                                    <Grid item md={3} xs={12}>
                                                        <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>Would you like to go there now?</Typography>  
                                                    </Grid>
                                                    <Grid item md={9} xs={12} direction="column"
                                                        alignItems="left"
                                                        justify="left">
                                                        <Button variant="contained" color="primary" style={{textAlign: "left"}} onClick={this.redirectToMYOB}>
                                                            Syncronise Now  	&nbsp; <i class="fa fa-sm fa-plus"></i>
                                                        </Button>
                                                    </Grid>
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>
            </div>
            
        );
    }
}

export default SyncMYOB;
