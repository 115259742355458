import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withRouter } from "react-router-dom";

import { Grid, TextField, Typography, Avatar, Card, CardHeader, CardContent, FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import Loading from "../loadingSpinner/Loading";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
import blankProfile from "../../img/hotgig-loading.png";

@inject("profileStore", "bookingHistoryStore", "templateStore")
@withRouter
@observer
class History extends Component {
    state = {
        declineBookingModalState: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if (this.props.bookingId) {
            this.props.bookingHistoryStore.load(this.props.bookingId, currentProfile?.id);
        }
    }

    render() {
        //const { match } = this.props;
        let { bookingHistories, isLoading } = this.props.bookingHistoryStore;
        //const { profile } = this.props.profileStore;
        bookingHistories = bookingHistories.slice().reverse();

        return (
            <Grid container spacing={16} style={{ position: "relative", minHeight: "200px" }}>
                <Loading showed={isLoading} />
                <Grid item xs={12} lg={3}>
                    <div>
                        <Typography variant="headline" component="h3" gutterBottom>
                            History
                        </Typography>
                        <Typography gutterBottom>History of changes made to this booking/gig.</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography variant="headline" component="h3" gutterBottom>
                                History
                            </Typography>
                        </Grid>
                        {bookingHistories.map((history, index) => {
                            if (index === bookingHistories.length - 1) {
                                return (
                                    <Grid item xs={12} key={history.id}>
                                        <Card>
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        alt={history.createdBy.fullName}
                                                        src={history.createdBy.profileImage &&
                                                            history.createdBy.profileImage.url
                                                                ? history.createdBy.profileImage.url
                                                                : blankProfile
                                                        }
                                                        style={{
                                                            width: 40,
                                                            height: 40
                                                        }}
                                                    />
                                                }
                                                title={`${history.createdBy.firstName} ${history.createdBy.lastName}`}
                                                subheader={`${moment(history.createdAt)
                                                    .local()
                                                    .format("dddd, MMMM DD, YYYY h:mm:ss a")}`}
                                            />
                                            <CardContent> Booking Created</CardContent>
                                        </Card>
                                    </Grid>
                                );
                            }
                            if (history.diff.length > 0){
                                return (
                                
                                    <Grid item xs={12} key={history.id}>
                                        <Card>
                                        <CardHeader
                                                    avatar={
                                                        <Avatar
                                                            alt={history.createdBy.fullName}
                                                            src={ history.createdBy.profileImage &&
                                                                history.createdBy.profileImage.url
                                                                    ? history.createdBy.profileImage.url
                                                                    : blankProfile
                                                            }
                                                            style={{
                                                                width: 40,
                                                                height: 40
                                                            }}
                                                        />
                                                    }
                                                    title={`${history.createdBy.firstName} ${history.createdBy.lastName}`}
                                                    subheader={`${moment(history.createdAt)
                                                        .local()
                                                        .format("dddd, MMMM DD, YYYY h:mm:ss a")}`}
                                                />
                                            <CardContent>
                                                <Grid container spacing={8}>
                                                    {history.diff.map((diff, index) => {
                                                        if (diff.isRichText) {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <Grid item lg={6}>
                                                                        <FormControl variant="outlined" fullWidth>
                                                                            <InputLabel shrink>{diff.label + " Before"}</InputLabel>
                                                                            <OutlinedInput
                                                                                fullWidth
                                                                                inputComponent="div"
                                                                                value={isNaN(Number(diff.oldValue)) ? diff.oldValue : Number(diff.oldValue).toFixed()}
                                                                                inputProps={{
                                                                                    dangerouslySetInnerHTML: { __html: diff && diff.oldValue }
                                                                                }}
                                                                                labelWidth={diff.label.length * 8 + 40}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item lg={6}>
                                                                        <FormControl variant="outlined" fullWidth>
                                                                            <InputLabel shrink>{diff.label + " After"}</InputLabel>
                                                                            <OutlinedInput
                                                                                fullWidth
                                                                                inputComponent="div"
                                                                                value={isNaN(Number(diff.value)) ? diff.value : Number(diff.value).toFixed()}
                                                                                inputProps={{
                                                                                    dangerouslySetInnerHTML: { __html: diff && diff.value }
                                                                                }}
                                                                                labelWidth={diff.label.length * 8 + 40}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Fragment>
                                                            );
                                                        }
                                                        return (
                                                            <Fragment key={index}>
                                                                <Grid item lg={6}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label={diff.label + " Before"}
                                                                        InputProps={{
                                                                            readOnly: true
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        value={isNaN(Number(diff.oldValue)) ? diff.oldValue : Number(diff.oldValue).toFixed() == 0 ? "" : Number(diff.oldValue).toFixed()}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={6}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label={diff.label + " After"}
                                                                        InputProps={{
                                                                            readOnly: true
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        value={isNaN(Number(diff.value)) ? diff.value : Number(diff.value).toFixed() == 0 ? "" : Number(diff.value).toFixed()}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Fragment>
                                                        );
                                                    })}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            }
                          
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default History;
