import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import moment from "moment"
import { Promise } from "bluebird";
import { Storage } from "aws-amplify";
import CacheHelper from '../helper/cache';

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class googleCalendarStore{

    @observable googleCalendarCode = "";
    @observable googleCalendarEvent = [];

    @observable googleCalendarEventVenue = [];
    @action
    resetGoogleCalendarEvent(){
        this.googleCalendarEvent = []
        this.googleCalendarEventVenue = []
    }
    @action
    setGoogleCalendarCode(code){
        this.googleCalendarCode = code;
    }

    @action
    exchangeTokenAndGetGoogleCalendarList(data){
        return agent.GoogleCalendar.exchangeTokenAndGetGoogleCalendarList(data).then(action(
            response => {
                
                return response
                
            }
        ))
    }
    @action
    selectGoogleCalendar(data){
        return agent.GoogleCalendar.selectCalendar(data).then(action(
            response => {
                
                return response
                
            }
        ))
    }

    @action
    loadCalendarProfile(profileId, startDate, endDate) {
        this.inProgress = true;
        var utcOffset = new Date().getTimezoneOffset();
        return agent.GoogleCalendar.getProfileGoogleCalendar(profileId, startDate, endDate,utcOffset)
            .then(
                action(results => {
                    if (results) {
                        this.googleCalendarEvent = results.filter(x=>x.status != "cancelled");
                        this.googleCalendarEvent.forEach(event => {
                            this.mapEvent(event);
                        });
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    loadCalendarProfileVenue(profileId, startDate, endDate) {
        this.inProgress = true;
        var utcOffset = new Date().getTimezoneOffset();
        return agent.GoogleCalendar.getProfileGoogleCalendar(profileId, startDate, endDate,utcOffset)
            .then(
                action(results => {
                    if (results) {
                        this.googleCalendarEventVenue = results.filter(x=>x.status != "cancelled");
                        this.googleCalendarEventVenue.forEach(event => {
                            this.mapEvent(event);
                        });
                    }
                    return results;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    mapEvent(event) {
        event.title = "Unavailable"
        var startDate = event.start && event.start.date ? event.start.date : event.start.dateTime
        var endDate = event.end && event.end.date ? event.end.date : event.end.dateTime
       
      
        
        event.type = "google-calendar";
        event.id = parseInt(event.id);

        if(event.start && event.start.date){
                        var utcOffset = new Date().getTimezoneOffset();
            var timeString = new Date(startDate);
            timeString.setTime(timeString.getTime() + (utcOffset*60*1000));
            event.start = timeString
        }else{
            event.start = new Date(startDate);
        }
        if(event.end && event.end.date){
            var timeString = new Date(endDate);

            var utcOffset = new Date().getTimezoneOffset();
            timeString.setTime(timeString.getTime() + (utcOffset*60*1000-3));
            event.end = timeString
        }else{
            event.end = new Date(endDate);
        }

        let endHour = event.end.getHours();
        let endMinute = event.end.getMinutes();

        // add 1 minute to end time for react-big-calendar display purpose only in month view
        // real event.end time will be loaded from backend upon edit/click event
        if (endHour === 0 && endMinute === 0) {
            event.end.setMinutes(1);
        }
    }
}

export default new googleCalendarStore();