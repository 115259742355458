import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import ModalContainer from "../modal/ModalContainer";
import { Button, Grid, Avatar, Typography, TextField } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
import ValidationHelper from "../../helper/validation.js";

@inject("authStore")
@observer
class BookingChangeActModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newEmail: undefined,
            isEmailValid: false
        };
    }

    handleChangeNewEmail = (email) => {
        if(ValidationHelper.emailValidation(email)){
            this.setState({ isEmailValid: true, newEmail: email });
        }
        else{
            this.setState({ isEmailValid: false, newEmail: email });
        }
    }

    handleSaveNewEmail = () => {
        this.props.handleSendRequest(this.state.newEmail);
        this.setState(
            {
                newEmail: undefined,
            });
    }

    handleClose = () => {
        this.setState(
            {
                newEmail: undefined,
            },
            () => {
                this.props.onClose();
            }
        );
    };

    render() {
        const { newEmail, isEmailValid } = this.state;
        return (
            <ModalContainer
                title="Change User Email"
                open={this.props.open}
                onClose={this.handleClose}
                maxWidth="md"
                fullScreen={false}
                isLoading={this.state.isLoading}
            >
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                id="input-new-email"
                                label="Insert New User Email"
                                value={newEmail}
                                onChange={e => this.handleChangeNewEmail(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>CLOSE</Button>
                    <Button
                        id="confirm-send-request"
                        color="primary"
                        onClick={this.handleSaveNewEmail}
                        disabled={!isEmailValid}
                    >
                        SAVE
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default BookingChangeActModal;
