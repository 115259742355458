import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { inject, observer } from "mobx-react";
import SetListDialog from "../setList/SetListDialog";
import { Hidden, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import ConfirmationModal from "../modal/ConfirmationModal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ValidationHelper from '../../helper/validation'

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Loading from "../loadingSpinner/Loading";

import CacheHelper from "../../helper/cache";
import validation from "../../helper/validation";

const toolbarStyles = theme => ({
    root: {
        backgroundColor: "#ff0c9c",
        flexGrow: 1
    },
    textCenter: {
        textAlign: "center"
    },
    highlight: {
        backgroundColor: "#333333",
        color: "#fff"
    },
    pads: {
        paddingRight: theme.spacing.unit,
        minHeight: 48
    },
    spacer: {
        paddingLeft: 20,
        flex: "0 0 76%"
    },
    spacerToolbar: {
        flex: "1 1 5%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: 0.96
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: "relative",
        top: 12
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

const textFieldStyle = {
    marginRight: "30px"
};

@inject("songListStore", "profileStore")
@observer
class SongListToolbar extends React.Component {
    state = {
        title: "",
        artist: "",
        mediaUrl: "",
        songKey: "",
        success: false,
        mediaError: '',
        titleError: '',
        confirmCopyFromProfile: false,
        form: undefined,
        openImport: false,
        isLoading: false,
        mySongList : []
    };

    componentDidMount() {
        const currentUser = this.props.profileStore.getCurrentProfile()
        this.props.songListStore.load(currentUser.id)
        .then(res => {
            this.setState({mySongList : res})
        })
    }

    handleTitleChange = e => {
        let title = e.target.value
        this.setState({ title });
        this.setState({titleError: ""})
    };

    validateTitle = e => {
        if(this.state.title.length === 0) return this.setState({titleError: ""})
        if(this.state.title.length === 0)  this.setState({titleError: "This field is required"}) 
        else this.setState({titleError: ""})
    }

    handleArtistChange = e => {
        this.setState({ artist: e.target.value });
    };

    handleKeyChange = e => {
        this.setState({ songKey: e.target.value });
    };

    handleMediaChange = e => {
        const url = e.target.value;
        this.setState({  mediaError: "" });
        this.setState({ mediaUrl: url});
    };

    validateMediaUrl = e => {
        if(this.state.mediaUrl.length === 0) return this.setState({  mediaError: "" });
        if(!validation.urlValidation(this.state.mediaUrl)) {
            this.setState({  mediaError: "Invalid URL" });
        } else {
            this.setState({  mediaError: "" });
            this.setState({ mediaUrl: validation.linkify(this.state.mediaUrl)});
        }
    }

    handleAddSongList = () => {
        let { title, mediaError } = this.state;
        if(title === "") return  this.setState({titleError: "This field is required"}) 
        if (title === "" || mediaError) return false;
        this.updateStateSuccess();
    };

    handleCopyFromProfile = profileId => {
        this.props.songListStore.importFromProfile(profileId);
        this.setState({
            confirmCopyFromProfile: false
        });
    };

    removeFormFocus = () => this.setState({ formFocus: false });

    updateStateSuccess = () => {
        let { title, artist, mediaUrl, songKey } = this.state;
        this.props.onListAdd(title, artist, mediaUrl, songKey).then(() => {
            this.resetData();
            this.setState({
                success: false
            });
        });
    };

    handleSongRemove = () => {
        this.props.onListRemove(this.props.selectedSongs);
        this.props.onListReset();
    };

    resetData = () => {
        this.setState({ title: "", artist: "", mediaUrl: "", songKey: "" });
        this.state.form.focus();
    };

    handleKeyPres = e => {
        if (e.key === "Enter") {
            this.handleAddSongList();
        }
    };
    handleImportTextChange = e => this.props.songListStore.setImportText(e.target.value);
    handleFormatChange = e => this.props.songListStore.setFormat(e.target.value);
    handleParse = e => this.props.songListStore.parseImportText();
    handleImport = e => {
        this.setState({ isLoading: true });
        this.props.songListStore.importSong().then(() => {
            this.setState({ openImport: false, isLoading: false });
        });
    };
    handleOpenCopyFromProfile = () => this.setState({ confirmCopyFromProfile: true });
    handleCloseCopyFromProfile = () => this.setState({ confirmCopyFromProfile: false });
    handleOpenImport = () => {
        this.props.songListStore.resetImport();
        this.setState({ openImport: true });
    };
    handleCloseImport = () => this.setState({ openImport: false });

    render() {
        const { type, importText, parsedSongs, ignoredSongs, format, load } = this.props.songListStore;
        const { numSelected, classes } = this.props;
        const { success, isLoading } = this.state;
        const { profile } = this.props.profileStore;
        const currentProfileId = this.props.songListStore.profileId; 
        const buttonClassname = classNames({
            [classes.buttonSuccess]: success
        });
        const parsed = parsedSongs && parsedSongs.length > 0;
        const ignored = ignoredSongs && ignoredSongs.length > 0;

        return (
            <div>
                <Grid container spacing={8}>
                    <Grid item sm={12} md={12} lg={6} style={{ width: "100%" }}>
                        <TextField
                            style={textFieldStyle}
                            id="songtitle"
                            label="Song Title"
                            value={this.state.title}
                            error={this.state.titleError}
                            helperText={this.state.titleError}  
                            onChange={this.handleTitleChange}
                            className={classes.textField}
                            margin="dense"
                            inputRef={el => {
                                this.setState({ form: el });
                            }}
                            onBlur={this.validateTitle}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={6} style={{ width: "100%" }}>
                        <TextField
                            style={textFieldStyle}
                            id="songartist"
                            label="Artist"
                            value={this.state.artist}
                            onChange={this.handleArtistChange.bind(this)}
                            className={classes.textField}
                            margin="dense"
                            onKeyPress={this.handleKeyPres.bind(this)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={6} style={{ width: "100%" }}>
                        <TextField
                            id="songmedia"
                            placeholder="Media URL"
                            label="Media URL"
                            error={this.state.mediaError}
                            helperText={this.state.mediaError}  
                            value={this.state.mediaUrl}
                            onChange={this.handleMediaChange}
                            onBlur={this.validateMediaUrl}
                            className={classes.textField}
                            margin="dense"
                            onKeyPress={this.handleKeyPres.bind(this)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={6} style={{ width: "100%" }}>
                        <TextField
                            id="songkey"
                            placeholder="Song Key"
                            label="Song Key"
                            value={this.state.songKey}
                            onChange={this.handleKeyChange.bind(this)}
                            className={classes.textField}
                            margin="dense"
                            onKeyPress={this.handleKeyPres.bind(this)}
                            fullWidth
                        />
                    </Grid>

                    <Hidden smDown>
                        <Grid item sm={12} md={12} lg={12} style={{ textAlign: "right" }}>
                            {type === "act" && this.state.mySongList.length > 0 && (
                                <Tooltip title="Click to copy all the songs from your musician profile">
                                    <Button variant="flat" color="default" aria-label="import" onClick={this.handleOpenCopyFromProfile}>
                                        {this.state.success ? <CheckIcon /> : <FileCopyIcon />} Copy From My Profile
                                    </Button>
                                </Tooltip>
                            )}
                            <ConfirmationModal
                                open={this.state.confirmCopyFromProfile}
                                title="Copy song list from your musician profile?"
                                message="We will create a copy of all the songs in your musician profile song list."
                                maxWidth="sm"
                                closeHandler={this.handleCloseCopyFromProfile}
                                confirmationHandler={this.handleCopyFromProfile.bind(this, currentProfileId)}
                                declineHandler={this.handleCloseCopyFromProfile}
                                confirmationLabel="Copy"
                            />
                            {profile && (
                                <Button variant="flat" color="default" aria-label="import" onClick={this.handleOpenImport} id="importDialogBtn">
                                    {this.state.success ? <CheckIcon /> : <ImportExportIcon />} Import Songs
                                </Button>
                            )}
                            <Button
                                variant="flat"
                                color="primary"
                                aria-label="add"
                                className={buttonClassname}
                                disabled={profile === undefined}
                                onClick={this.handleAddSongList}
                            >
                                {this.state.success ? <CheckIcon /> : <AddIcon />} Add Song
                            </Button>
                        </Grid>
                    </Hidden>

                    <Hidden mdUp>
                        <Grid item xs={12} className={classes.textCenter}>
                            {type === "act" && (
                                <Tooltip title="Click to copy all the songs from your musician profile">
                                    <Button variant="flat" color="default" aria-label="import" onClick={this.handleOpenCopyFromProfile}>
                                        {this.state.success ? <CheckIcon /> : <FileCopyIcon />} Copy From My Profile
                                    </Button>
                                </Tooltip>
                            )}
                            <ConfirmationModal
                                open={this.state.confirmCopyFromProfile}
                                title="Copy song list from your musician profile?"
                                message="We will create a copy of all the songs in your musician profile song list."
                                maxWidth="sm"
                                closeHandler={this.handleCloseCopyFromProfile}
                                confirmationHandler={this.handleCopyFromProfile.bind(this, currentProfileId)}
                                declineHandler={this.handleCloseCopyFromProfile}
                                confirmationLabel="Copy"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.textCenter}>
                            {profile && (
                                <Button variant="flat" color="default" aria-label="import" onClick={this.handleOpenImport} id="importDialogBtn">
                                    {this.state.success ? <CheckIcon /> : <ImportExportIcon />} Import Songs
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12} className={classes.textCenter}>
                            <Button
                                variant="flat"
                                color="primary"
                                aria-label="add"
                                className={buttonClassname}
                                disabled={profile === undefined}
                                onClick={this.handleAddSongList}
                            >
                                {this.state.success ? <CheckIcon /> : <AddIcon />} Add Song
                            </Button>
                        </Grid>
                    </Hidden>
                </Grid>

                <hr />
                <div className={classes.root}>
                    <Toolbar
                        className={classNames({
                            [classes.highlight]: numSelected > 0,
                            [classes.pads]: true
                        })}
                    >
                        <div className={classes.title}>
                            {numSelected > 0 ? (
                                <Typography variant="subheading" style={{ color: "#FFF" }}>
                                    {numSelected} selected
                                </Typography>
                            ) : (
                                <Typography variant="subheading">Song List</Typography>
                            )}
                        </div>
                        <div className={classes.actions}>
                            {numSelected > 0 ? (
                                <Fragment>
                                    {currentProfileId && <SetListDialog type={this.props.type} profileId={currentProfileId} />}

                                    <Tooltip title="Delete">
                                        <IconButton
                                            aria-label="Delete"
                                            id="btnDeleteSong"
                                            style={{ paddingLeft: 10, color: "#FFF", width: 72 }}
                                            onClick={this.handleSongRemove.bind(this)}
                                        >
                                            <DeleteIcon />
                                            <Typography variant="body2" style={{ color: "#FFF" }}>
                                                Delete
                                            </Typography>
                                        </IconButton>
                                    </Tooltip>
                                </Fragment>
                            ) : (
                                <div />
                            )}
                        </div>
                    </Toolbar>
                </div>

                <Dialog
                    open={this.state.openImport}
                    onClose={this.handleCloseImport}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    className="dialog-div"
                >
                    <div style={{ position: "relative" }}>
                        <Loading showed={isLoading} />
                        <DialogTitle id="form-dialog-title">Import Songs</DialogTitle>
                        <DialogContent>
                            {!parsed &&
                                !ignored && (
                                    <Fragment>
                                        <DialogContentText>
                                            To import you songs, enter them in the text box below. Each song should be in the format [Song Title] -
                                            [Song Artist], one song per line.
                                        </DialogContentText>
                                        <RadioGroup aria-label="format" name="format" value={format} onChange={this.handleFormatChange}>
                                            <FormControlLabel value="title" control={<Radio />} label="[Title] - [Artist]" />
                                            <FormControlLabel value="artist" control={<Radio />} label="[Artist] - [Title]" />
                                            <FormControlLabel value="only-title" control={<Radio />} label="[Title]" />
                                        </RadioGroup>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            label="Enter Songs"
                                            type="text"
                                            fullWidth
                                            multiline
                                            rows={10}
                                            value={importText}
                                            onChange={this.handleImportTextChange}
                                            id="importText"
                                        />
                                    </Fragment>
                                )}
                            {(parsed || ignored) && (
                                <Fragment>
                                    <DialogContentText>Click save to add the songs in the below to your songlist</DialogContentText>
                                    <Table className="import-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Title</TableCell>
                                                <TableCell>Artist</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {parsedSongs.map((n, index) => {
                                                return (
                                                    <TableRow key={`${n.title}-${n.artist}`}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{n.title}</TableCell>
                                                        <TableCell>{n.artist}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    {ignored && (
                                        <Fragment>
                                            <Typography variant="body2" gutterBottom style={{ marginTop: 20, color: "red" }}>
                                                Ignored Songs (Title or Artist contains forbidden character)
                                            </Typography>
                                            <Table className="import-table">
                                                <TableBody>
                                                    {ignoredSongs.map((n, index) => {
                                                        return (
                                                            <TableRow key={`${n.title}-${n.artist}`}>
                                                                <TableCell>
                                                                    <Typography variant="body1" style={{ color: "red" }} gutterBottom>
                                                                        {index + 1}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body1" style={{ color: "red" }} gutterBottom>
                                                                        {n.title}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body1" style={{ color: "red" }} gutterBottom>
                                                                        {n.artist}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseImport} color="primary">
                                Close
                            </Button>
                            {!parsed && (
                                <Button onClick={this.handleParse} color="primary" id="btnParse">
                                    Import
                                </Button>
                            )}
                            {parsed && (
                                <Button onClick={this.handleImport} color="primary" id="btnImport">
                                    Save
                                </Button>
                            )}
                            {ignored && (
                                <Button onClick={this.handleOpenImport} color="primary" id="btnFix">
                                    Fix
                                </Button>
                            )}
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        );
    }
}

SongListToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onListAdd: PropTypes.func.isRequired,
    onListRemove: PropTypes.func.isRequired,
    onListReset: PropTypes.func.isRequired
};

SongListToolbar = withStyles(toolbarStyles)(SongListToolbar);

export default SongListToolbar;
