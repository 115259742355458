import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Select, MenuItem, Grid } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import "./DashboardFilter.css";

const dateFormat = "DD-MM-YYYY";
const monthFormat = "MMM YYYY";
@inject("profileStore", "staffFilterStore")
@observer
class DashboardFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardFilter: "monthly",
            organisationFilter: 0,
            stateFilter: 0,
            regionFilter: 0,
            venueFilter: 0,
            consultantFilter:
                this.props.profileStore && this.props.profileStore.currentProfile && this.props.profileStore.currentProfile.isConsultant
                    ? this.props.profileStore.currentProfile.id
                    : 0,
            showDateRangePicker: false,
            startDate: moment().startOf("month"),
            endDate: moment().endOf("month"),
            level1Label: "Organisation",
            level2Label: "State",
            level3Label: "Region"
        };
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.organisations !== this.props.organisations ||
            prevProps.states !== this.props.states ||
            prevProps.regions !== this.props.regions ||
            prevProps.venues !== this.props.venues ||
            prevProps.consultants !== this.props.consultants
        ) {
            this.setState({
                ...this.state,
                organisationFilter:
                    this.props.organisations && this.props.organisations.length === 1
                        ? this.props.organisations[0].id
                        : this.state.organisationFilter,
                stateFilter: this.props.states && this.props.states.length === 1 ? this.props.states[0].id : this.state.stateFilter,
                regionFilter: this.props.regions && this.props.regions.length === 1 ? this.props.regions[0].id : this.state.regionFilter,
                venueFilter: this.props.venues && this.props.venues.length === 1 ? this.props.venues[0].id : this.state.venueFilter,
                consultantFilter: this.props.consultants && this.props.consultants.length === 1 ? this.props.consultants[0].id : this.state.consultantFilter,
                level1Label: this.props.level1Label,
                level2Label: this.props.level2Label,
                level3Label: this.props.level3Label
            });
        }
    }

    onChange = e => {
        var value = e.target.value;
        this.props.staffFilterStore.setFilterType(e.target.value);
        this.setState(
            {
                ...this.state,
                dashboardFilter: value,
                showDateRangePicker: false
            },
            () => {
                if (value === "custom") {
                    this.setState({
                        ...this.state,
                        showDateRangePicker: true
                    });
                }
                this.props.onChange(e.target.value);
            }
        );
    };

    onChangeStartDate = value => {
        this.setState({
            ...this.state,
            startDate: value
        });
        this.props.onCustomStartDateChange(value);
    };

    onOrganisationChange = e => {
        const { organisations } = this.props;
        let organisation = organisations.find(x => x.id === e.target.value);
        this.setState(
            {
                organisationFilter: e.target.value,
                stateFilter: 0,
                regionFilter: 0,
                venueFilter: 0,
                level1Label: organisation ? organisation.level1Label : "Organisation",
                level2Label: organisation ? organisation.level2Label : "State",
                level3Label: organisation ? organisation.level3Label : "Region"
            },
            () => {
                this.props.onOrganisationChange(e.target.value);
            }
        );
    };

    onStateChange = e => {
        this.setState(
            {
                stateFilter: e.target.value,
                regionFilter: 0,
                venueFilter: 0
            },
            () => {
                this.props.onStateChange(e.target.value);
            }
        );
    };

    onRegionChange = e => {
        this.setState(
            {
                regionFilter: e.target.value,
                venueFilter: 0
            },
            () => {
                this.props.onRegionChange(e.target.value);
            }
        );
    };

    onVenueChange = e => {
        const selectedVenues = this.props.venues.find(x => x.id == e.target.value);
        const { regionId, stateId, organisationId } = selectedVenues || {};
        this.setState(
            {
                venueFilter: e.target.value,
                regionFilter: regionId ? regionId : 0,
                stateFilter: stateId ? stateId : 0,
                organisationFilter: organisationId ? organisationId : 0
            },
            () => {
                this.props.onVenueChange(e.target.value);
            }
        );
    };

    onConsultantChange = e => {
        this.setState({ consultantFilter: e.target.value }, () => {
            this.props.onConsultantChange(e.target.value);
        });
    };

    onChangeEndDate = value => {
        this.setState({
            ...this.state,
            endDate: value
        });
        this.props.onCustomEndDateChange(value);
    };

    onChangeMonth = value => {
        const startDate = moment(value).date(1);
        this.props.staffFilterStore.setStartDate(startDate);

        const endDate = moment(value).date(moment(value).daysInMonth());
        this.props.staffFilterStore.setEndDate(endDate);

        this.setState({
            ...this.state,
            startDate: startDate,
            endDate: endDate
        });

        this.props.onCustomMonthChange(startDate, endDate);
    };

    render() {
        const { level1Label, level2Label, level3Label, dashboardFilter } = this.state;
        return (
            <Grid container spacing={8}>
                {this.props.organisations &&
                    this.props.organisations.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="Organisation"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={this.state.organisationFilter}
                                onChange={this.onOrganisationChange}
                                inputProps={{
                                    color: "primary"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All {`${level1Label}s` || "Organisations"}</MenuItem>
                                {this.props.organisations.map(organisation => (
                                    <MenuItem key={organisation.id} value={organisation.id}>
                                        {organisation.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                {this.props.states &&
                    this.props.states.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="State"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={this.state.stateFilter}
                                onChange={this.onStateChange}
                                inputProps={{
                                    color: "primary"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All {`${level2Label}s` || "States"}</MenuItem>
                                {this.props.states.map(state => (
                                    <MenuItem key={state.id} value={state.id}>
                                        {state.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                {this.props.regions &&
                    this.props.regions.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="Region"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={this.state.regionFilter}
                                onChange={this.onRegionChange}
                                inputProps={{
                                    color: "primary"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All {`${level3Label}s` || "Regions"}</MenuItem>
                                {this.props.regions.map(region => (
                                    <MenuItem key={region.id} value={region.id}>
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                {this.props.venues &&

                    <Grid item className="filterHalf">
                        <Select
                            label="Venue"
                            className="pull-right dark-select slim-input"
                            fullWidth
                            value={this.state.venueFilter}
                            onChange={this.onVenueChange}
                            inputProps={{
                                color: "primary"
                            }}
                            classes={{
                                root: "text-white",
                                icon: "text-white"
                            }}
                    >
                        {
                            this.props.venues.length > 0 ? (
                                <MenuItem value={0}>All Venues</MenuItem>

                            ) : (
                                <MenuItem value={0}>No Venues</MenuItem>
                            )
                        }
                        {this.props.venues.length && (
                            this.props.venues.map(venue => (
                                <MenuItem key={venue.id} value={venue.id}>
                                    {venue.name}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                    </Grid>
                }
                {this.props.consultants &&
                    this.props.consultants.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="Consultant"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={this.state.consultantFilter}
                                onChange={this.onConsultantChange}
                                inputProps={{
                                    color: "primary"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All Consultants</MenuItem>
                                {this.props.consultants.map(consultant => (
                                    <MenuItem key={consultant.id} value={consultant.id}>
                                        {consultant.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                <Grid item className="filterFull">
                    <Select
                        label="Filter"
                        className="dark-select slim-input"
                        fullWidth
                        value={this.state.dashboardFilter}
                        onChange={this.onChange}
                        inputProps={{
                            color: "primary"
                        }}
                        classes={{
                            root: "text-white",
                            icon: "text-white"
                        }}
                    >
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="week">This Week</MenuItem>
                        {/* <MenuItem value="month">This Month</MenuItem>
                        <MenuItem value="nextMonth">Next Month</MenuItem> */}
                        <MenuItem value="monthly">Month</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                </Grid>
                {dashboardFilter === "custom" && (
                    <Grid item className="filterHalf">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                emptyLabel="From"
                                margin="none"
                                className="dark-datepicker slim-input"
                                value={this.state.startDate}
                                onChange={this.onChangeStartDate}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                format={dateFormat}
                                InputProps={{
                                    classes: {
                                        root: "text-white"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {dashboardFilter === "custom" && (
                    <Grid item className="filterHalf">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                emptyLabel="to"
                                margin="none"
                                className="dark-datepicker slim-input"
                                value={this.state.endDate}
                                onChange={this.onChangeEndDate}
                                format={dateFormat}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                InputProps={{
                                    classes: {
                                        root: "text-white"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {dashboardFilter === "monthly" && (
                    <Grid item className="filterFull">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                views={["year", "month"]}
                                emptyLabel="..."
                                margin="none"
                                className="dark-datepicker slim-month-input"
                                value={this.state.startDate}
                                onChange={this.onChangeMonth}
                                format={monthFormat}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                fullWidth
                                autoOk
                                InputProps={{
                                    classes: {
                                        root: "text-white pull-right"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default DashboardFilter;
