import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { Typography, Button, Tooltip, Avatar, Divider } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";

import "./WidgetSmallTextLink.css";

class WidgetSmallTextLink extends Component {
    maxDisplayItem = 7;

    state = {
        open: false
    };

    materialColor = [
        "#4CAF50",
        "#00BCD4",
        "#3F51B5",
        "#795548",
        "#673AB7",
        "#F44336",
        "#9C27B0",
        "#E91E63",
        "#8BC34A",
        "#FF9800",
        "#2196F3",
        "#03A9F4",
        "#FF5722",
        "#009688",
        "#CDDC39",
        "#FFC107"
    ];

    getInitial = text => {
        if (text) {
            let split = text.split(" ");
            let result = "";

            if (split.length >= 2) {
                result = split[0].split("")[0] + split[1].split("")[0];
            } else {
                split = text.split("");
                if (split.length >= 2) {
                    result = split[0] + split[1];
                } else if (split.length > 0) {
                    result = split[0];
                }
            }
            return result;
        }
    };

    getRandomNumberBetween = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    renderData = isRenderAll => {
        let result = [];
        for (let i = 0; i < this.props.data.datas.length; i++) {
            if (i > 0) {
                result.push(", ");
            }

            if (i < this.maxDisplayItem || isRenderAll) {
                if(this.props.data.datas[i].link == "#") {
                    result.push (
                        <span key={i}>{this.props.data.datas[i].text}</span>
                    )
                } else {
                    result.push(
                        <LinkContainer key={i} to={this.props.data.datas[i].link}>
                            <a className="no-hover">{this.props.data.datas[i].text}</a>
                        </LinkContainer>
                    );
                }
            } else {
                result.push("... ");

                result.push(
                    <Tooltip key={i} title="Click to see more" placement="bottom">
                        <LinkContainer to={this.props.data.datas[i].link}>
                            <a onClick={this.handleOpen}>(and {this.props.data.datas.length - i} more)</a>
                        </LinkContainer>
                    </Tooltip>
                );
                break;
            }
        }
        return result;
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    renderList = () => {
        let result = [];
        for (let i = 0; i < this.props.data.datas.length; i++) {
            if (i > 0) {
                result.push(<Divider key={i + 10000} />);
            }

            result.push(
                <ListItem key={i}>
                    <Avatar style={{ backgroundColor: this.materialColor[i % this.materialColor.length], fontSize: 15, textTransform: "uppercase" }}>
                        {this.getInitial(this.props.data.datas[i].text)}
                    </Avatar>
                    <ListItemText
                        primary={this.props.data.datas[i].text}
                        secondary={this.props.data.datas[i].secondaryText ? this.props.data.datas[i].secondaryText : null}
                    />
                    {/* <ListItemSecondaryAction>
                        <Button color="primary" variant="contained" className="see-more-dialog-button">
                            <OpenInNew className="see-more-dialog-button-icon"/>
                            View
                        </Button>
                    </ListItemSecondaryAction> */}
                </ListItem>
            );
        }
        return result;
    };

    render() {
        const { fullScreen } = this.props;

        return (
            <div className="widget-small-text-link-container">
                <div className="widget-small-text-link-title">
                    <Typography component="h2">{this.props.data.title}</Typography>
                </div>
                <div className="widget-small-text-link-content">
                    <div className="test">
                        <Typography component="p">{this.renderData(false)}</Typography>
                    </div>
                </div>

                <Dialog fullScreen={fullScreen} open={this.state.open} onClose={this.handleClose} transition={this.transition}>
                    <DialogTitle className="see-more-dialog-title">{this.props.data.title}</DialogTitle>
                    <DialogContent className="see-more-dialog-content">
                        <List>{this.renderList()}</List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withMobileDialog()(WidgetSmallTextLink);
