import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Grid, Button, IconButton, Snackbar } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ManagedActCard from "./ManagedActCard";
// import ModalContainer from "../modal/ModalContainer";
import ClearIcon from "@material-ui/icons/Clear";
import Loading from "../loadingSpinner/Loading";
import SearchCard from "../searchPage/SearchCard";

import "./ActList.css";
import { ProfileType } from "../../types/enum";

@inject("profileStore", "searchStore", "userStore")
@observer
class ActList extends Component {
    state = {
        expanded: false,
        openDeleteConfirmationModal: false,
        deletedActId: undefined,
        deletedActName: "",
        openSnackbar: false,
        snackbarMessage: ""
    };

    componentDidMount() {
        const profile = this.props.profileStore.getCurrentProfile()
        if (this.props.agentAct) {
            return this.props.profileStore.getAgentActList();
        }
        if(profile.type === ProfileType.PrivateHire.ordinal){
            this.props.profileStore.getPrivateHireFavouriteActList();
        }else{
            this.props.profileStore.getUserActList();
        }
        
    }

    handleExpandClick = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    };

    handleOpenDeleteConfirmation = id => {
        const act = this.props.profileStore.actList.slice().find(x => x.id === id);
        this.setState({
            deletedActName: act ? act.actName : "",
            openDeleteConfirmationModal: true,
            deletedActId: id
        });
    };

    handleCancelDelete = () => {
        this.setState({
            openDeleteConfirmationModal: false,
            deletedActId: undefined
        });
    };

    handleRemove = () => {
        this.props.profileStore.deleteProfile(this.state.deletedActId).then(response => {
            this.handleCancelDelete();
            this.setState({
                snackbarMessage: `${this.state.deletedActName} has been deleted.`,
                openSnackbar: true
            });
        });
    };

    getProfileUrl = profile => {
        return profile.vanityUrl && profile.vanityUrl.length > 3 ? `/${profile.vanityUrl}` : `/profile/${profile.id}`;
    };

    handleAddRemoveFavourite = (act) => {
        if(act.isFavourite){
            this.props.userStore.removeActAsUserFavourite(act.id).then(result => {
                this.props.profileStore.setFavouriteOnSearch(act.id, !act.isFavourite)
          
            });
        }else{
            this.props.userStore.addActAsUserFavourite(act.id).then(result => {
                this.props.profileStore.setFavouriteOnSearch(act.id, !act.isFavourite)
            });
        }
       
    };

    closeSnackbarContainer = () => {
        this.setState({
            snackbarMessage: "",
            openSnackbar: false
        });
    };

    render() {
        const profile = this.props.profileStore.getCurrentProfile()
        let renderAct = act => {
            return (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={act.id}>
                    {
                        profile?.type === ProfileType.PrivateHire.ordinal ? 
                        ( 
                            <SearchCard
                                profileId={act.id}
                                profileName={act.actName}
                                profileBio={act.bio}
                                profile={act}
                                type="act"
                                userRole={this.props.profileStore.profile?.type}
                                profileUrl={this.getProfileUrl(act)}
                                isFavourite={act.isFavourite}
                                handleAddRemoveFavourite={() => this.handleAddRemoveFavourite(act)}
                            />
                        ) : 
                        (
                            <ManagedActCard act={act} handleRemove={() => this.handleOpenDeleteConfirmation(act.id)} type="act" profile={profile} agentAct={this.props.agentAct} />
                        )
                    }
                    
                </Grid>
            );
        };

        const { fullScreen } = false;
        const { isLoadingProfile } = this.props.profileStore;
        const { actList } = this.props.profileStore

        return (
            <Grid container direction="column" style={{ position: "relative", minHeight: "200px" }}>
                {actList.length < 1 && <Loading showed={isLoadingProfile} />}
                <Grid item xs={12}>
                    <Grid container spacing={16}>
                        {actList.map(act => renderAct(act))}
                    </Grid>
                </Grid>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.openDeleteConfirmationModal}
                    onClose={this.handleCancelDelete}
                    aria-labelledby="responsive-dialog-title"
                    className="dialog-div"
                >
                    <DialogTitle id="responsive-dialog-title">Delete Act</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure to delete <strong>{this.state.deletedActName}</strong>?
                            <br />Rosters and bookings with <strong>{this.state.deletedActName}</strong> will be cancelled.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancelDelete} color="primary">
                            Close
                        </Button>
                        <Button onClick={this.handleRemove} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.openSnackbar}
                    onClose={() => this.closeSnackbarContainer()}
                    autoHideDuration={6000}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">{this.state.snackbarMessage}</span>}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={() => this.closeSnackbarContainer()}>
                            <ClearIcon />
                        </IconButton>
                    ]}
                />
            </Grid>
        );
    }
}

export default ActList;
