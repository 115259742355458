import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Grid, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { ProfileType } from "../../types/enum";

import musician from "../../img/musician.svg";
import agent from "../../img/agent.svg";
import venue from "../../img/venue.svg";
import privatehire from "../../img/privatehire.svg";
import production from "../../img/production.svg";
import dj from "../../img/dj.svg";

import "./ChooseProfile.css";

const SHOW_PRODUCTION_CREW = process.env.REACT_APP_SHOW_PRODUCTION_CREW === "true";
const SHOW_DJ = process.env.REACT_APP_SHOW_DJ === "true";

@inject("profileStore")
@withRouter
@observer
class ChooseProfile extends Component {
    handleRedirect = (route, profileType) => {
        this.props.profileStore.setProfileType(profileType);
        this.props.history.push(route);
    };
    componentDidMount() {
        var profile = this.props.profileStore.getCurrentProfile();
        if(profile){
            if(profile.type === ProfileType.Musician.ordinal){
                this.handleRedirect("/musician/edit/new", ProfileType.Musician.ordinal)
            }
            if(profile.type === ProfileType.Agent.ordinal){
                this.handleRedirect("/agent/edit/new", ProfileType.Agent.ordinal)
            }
            if(profile.type === ProfileType.Staff.ordinal){
                this.handleRedirect("/staff/edit/new", ProfileType.Staff.ordinal)
            }
           
        }
    }

    render() {
        const type = this.props.type;

        return (
            <Grid container spacing={24} justify="center">
                <Grid item xs={12}>
                    <Grid container justify="center">
                        {type !== "venue" && (
                            <div className="type">
                                <Button
                                    id="create-musician-profile"
                                    onClick={() => this.handleRedirect("/musician/edit/new", ProfileType.Musician.ordinal)}
                                >
                                    <Typography component="div" type="subheading" color="inherit">
                                        <div className="type-image">
                                            <img src={musician} alt="musician" />
                                        </div>
                                        <span className="title">Artist/Entertainer</span>
                                    </Typography>
                                </Button>
                            </div>
                        )}

                        {type !== "musician" &&
                            type !== "venue" && (
                                <div className="type">
                                    <Button
                                        id="create-agent-profile"
                                        onClick={() => this.handleRedirect("/agent/edit/new", ProfileType.Agent.ordinal)}
                                    >
                                        <Typography component="div" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={agent} alt="agent" />
                                            </div>
                                            <span className="title">Agent</span>
                                        </Typography>
                                    </Button>
                                </div>
                            )}

                        {type !== "musician" && (
                            <div className="type">
                                <Button id="create-staff-profile" onClick={() => this.handleRedirect("/staff/edit/new", ProfileType.Staff.ordinal)}>
                                    <Typography component="div" type="subheading" color="inherit">
                                        <div className="type-image">
                                            <img src={venue} alt="venue" />
                                        </div>
                                        <span className="title">Venue</span>
                                        <span className="subtitle">Choose this profile if you are venue manager, operator or staff.</span>
                                    </Typography>
                                </Button>
                            </div>
                        )}

                        {type !== "musician" && type !== "venue" && (
                            <div className="type">
                                <Button id="create-private-hire" onClick={() => this.handleRedirect("/privatehire/edit/new", ProfileType.PrivateHire.ordinal)}>
                                    <Typography component="div" type="subheading" color="inherit">
                                        <div className="type-image">
                                            <img src={privatehire} alt="venue" />
                                        </div>
                                        <span className="title">Parties & Events</span>
                                        <span className="subtitle">Use this profile if you are booking entertainment for private events.</span>
                                    </Typography>
                                </Button>
                            </div>
                        )}

                        {type !== "venue" &&
                            SHOW_PRODUCTION_CREW && (
                                <div className="type">
                                    <Button
                                        id="create-production-profile"
                                        onClick={() => this.handleRedirect("/production/edit/new", ProfileType.Production.ordinal)}
                                    >
                                        <Typography component="div" type="subheading" color="inherit" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                            <div className="type-image">
                                                <img src={production} alt="production" />
                                            </div>
                                            <span className="title">Production</span>
                                        </Typography>
                                    </Button>
                                </div>
                            )}

                        {type !== "venue" &&
                            SHOW_DJ && (
                                <div className="type">
                                    <Button id="create-dj-profile" onClick={() => this.handleRedirect("/dj/edit/new", ProfileType.Dj.ordinal)}>
                                        <Typography component="div" type="subheading" color="inherit" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                            <div className="type-image">
                                                <img src={dj} alt="dj" />
                                            </div>
                                            <span className="title">DJ</span>
                                        </Typography>
                                    </Button>
                                </div>
                            )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default ChooseProfile;
