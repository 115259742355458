import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";

// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import agent from "../../agent";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import "./ManageXero.css";

import { Grid, Typography, Button, TextField, IconButton, Paper, Tabs, Tab, Hidden, MenuItem } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import  Loading from "../../components/loadingSpinner/Loading";

import ConnectedAccount from "../xero/ConnectedAccount";
import InvoiceMapping from "../xero/InvoiceMapping";
import ContactMapping from "../xero/ContactMapping";
import SyncXero from "../xero/SyncXero";
import XeroLog from "../xero/XeroLog";


import { ProfileType } from "../../types/enum";

@inject("profileStore", "templateStore", "userStore", "authStore", "billingStore","googleCalendarStore","xeroStore")
@withRouter
@observer
class ManageXero extends Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        passwordEditState: false,
        isDirty: false,
        password: "",
        newPassword: "",
        confirmPassword: "",
        showPassword: false,
        isPrivateHireLock: false,
        tab: 0,
        tabDisabled: false,
        isLoading: false,
        isRedirected: false
    };

    handleLoading = (value) => {
        this.setState({ isLoading: value });
    };
    componentDidMount() {

        this.props.userStore.getUserCognito()
        this.props.googleCalendarStore.setGoogleCalendarCode("");
        const { currentUser } = this.props.userStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.setState({
            firstName: currentUser.given_name,
            lastName: currentUser.family_name,
            email: currentUser.email,
            firstNameEditState: false,
            lastNameEditState: false,
            emailEditState: false,
            passwordEditState: false,
            isDirty: false,
            password: "",
            newPassword: "",
            confirmPassword: "",
            showPassword: false
        });

        // if (this.props && this.props.match && this.props.match.params && this.props.match.params.tab) {
        //     if (this.props.match.params.tab === "integration") {
        //         this.setState({
        //             tab: 1
        //         });
        //     }
       
        //     if (this.props.match.params.tab === "lock") {
        //         this.setState({ tab: 3, tabDisabled: true });
        //     }
        //     if(this.props.match.params.tab === "privatehirelock"){
        //         this.setState({ tab: 3, tabDisabled: true,isPrivateHireLock: true });
        //     }
        //     if(this.props.match.params.tab === "contactadministrator"){
        //         this.setState({ isRedirected : true, tabDisabled: true});
        //     }
        //     if(this.props.match.params.tab === "Xero"){
        //         const query = new URLSearchParams(this.props.location.search);
        //         const token = query.get('code')
               
        //         if(token){
        //             var codeXero = decodeURIComponent(token)
        //             this.props.xeroStore.setXeroCode(token);
        //             const body = {
        //                 code: codeXero
        //             };
        //             this.setState({
        //                 isLoading: true,
                        
        //             });
        //             this.props.xeroStore.connectXero(currentProfile.id, body).then(result => {
        //                 this.props.profileStore.loadProfiles().finally(() => {
        //                     this.setState({
        //                         isLoading: true,
        //                         tab: 5
        //                     });
        //                 })
        //             })
        //             // return agent.Xero.authorizeXero(body).then(result => {
        //             //     console.log("diceritakan", result)
        //             // });
        //         }else{
        //             this.setState({ tab: 5});
        //         }
              
        //     }
        //     if(this.props.match.params.tab === "googlecalendar"){
        //         const query = new URLSearchParams(this.props.location.search);
        //         const token = query.get('code')
        //         this.props.googleCalendarStore.setGoogleCalendarCode(token);
        //         this.setState({ tab: 5});
        //     }
        // }
        this.props.authStore.setPassword("");
        this.props.authStore.setNewPassword("");
        this.props.authStore.setConfirmPassword("");
    };

    handleChangeTab = (event, value) => {
        this.setState({ tab: value });
    };

    redirectToInvoiceMapping = () => {
        this.setState({
            tab: 1
        });
    }
    handleSelectedTab = (event, value) => {
        this.setState({ tab: value });
    };
    handleSelectChange = event => {
        this.setState({ tab:  event.target.value });
       
    }
    
    render() {
        const { currentProfileType } = this.props.profileStore;
        const { billingAccess } = this.props.billingStore; 
        const profiles = this.props.profileStore.getCurrentProfile();
        var breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Xero Settings" }];
        if(profiles?.type === ProfileType.PrivateHire.ordinal){
            breadcrumb = [{ name: "Xero Settings" }];
        }
        
        return (
            <div>
                <Header role={this.props.templateStore.getTypeString(currentProfileType || 1)} />
                <Loading showed={this.state.isLoading}/>
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="Xero Settings" />
                    
                    <Paper style={{ position: "relative" }}>
                    <div style={{padding:20}}>
                    <Grid container spacing={8}>
                            {
                                !this.state.isRedirected &&
                                <Grid item xs={12} lg={12}>
                                    <Typography variant="headline" component="h3">
                                        Xero Settings
                                    </Typography>
                                    <Typography gutterBottom>
                                        Use this section to manage your Xero settings
                                    </Typography>
                                    {/* <Typography>
                                        <b>Tip:</b>
                                    </Typography>
                                    <Typography>You can reset your password by clicking 'Edit'.</Typography> */}
                                </Grid>
                            }
       


                        {
                            process.env.REACT_APP_DISABLE_BILLING_MOBILE !== "true" &&
                                <Grid item xs={12} lg={12}>
                                    <Hidden smDown>
                                        <Tabs value={this.state.tab} onChange={this.handleChangeTab}  indicatorColor="primary" textColor="primary">
                                                <Tab label="Connected Account"/>
                                                <Tab label="Invoice Mapping"/>
                                                <Tab label="Contact Mapping"/>
                                                <Tab label="Sync"/>
                                                <Tab label="Log"/>
                                        </Tabs>
                                       
                                        <br />
                                    </Hidden>
                                    <Hidden mdUp>
                                        <div style={{ padding: 8 }}>
                                        <TextField select value={this.state.tab} onChange={this.handleSelectChange} textColor="primary" fullWidth>
                                                <MenuItem key={0} value={0}>
                                                    Connected Account
                                                </MenuItem>
                                                <MenuItem key={1} value={1}>
                                                    Invoice Mapping
                                                </MenuItem>
                                                <MenuItem key={2} value={2}>
                                                    Contact Mapping
                                                </MenuItem>
                                                <MenuItem key={3} value={3}>
                                                    Sync
                                                </MenuItem>
                                                <MenuItem key={3} value={3}>
                                                    Log
                                                </MenuItem>
                                                {/* <MenuItem key={5} value={5}>
                                                    Error Log
                                                </MenuItem> */}
                                            </TextField>
                                           
                                        </div>
                                    </Hidden>
                                    <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                                {this.state.tab === 0 && (
                                                <ConnectedAccount />
                                                )}
                                                {this.state.tab === 1 && (
                                                <InvoiceMapping />
                                                )}
                                                {this.state.tab === 2 && (
                                                <ContactMapping />
                                                )}
                                                {this.state.tab === 3 && (
                                                    <SyncXero />
                                                )}
                                                {this.state.tab === 4 && (
                                                    <XeroLog redirectToInvoiceMapping={this.redirectToInvoiceMapping}/>
                                                )}
                                                
                                            </Grid>
                                       
                                    </Grid>
                                </Grid>
                         }
                        </Grid>
                    </div>
                       
                    </Paper>
                </MainContainer>
            </div>
        );
    }
}

export default ManageXero;
