import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import {
    Toolbar,
    Typography,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    TextField,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    InputLabel,
    Card,
    Avatar,
    IconButton 
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ProfileSearchBox from "../../components/fields/ProfileSearchBox";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import CardHeader from "@material-ui/core/CardHeader";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";

import green from "@material-ui/core/colors/green";
import Slide from "@material-ui/core/Slide";
// import UserSearchBox from "../../components/fields/UserSearchBox";

@inject("organisationMemberStore", "profileStore", "organisationStore", "stateStore", "regionStore", "templateStore", "memberProfileStore")
@observer
class UserTableToolbar extends React.Component {
    state = {
        openAdd: false,
        openDelete: false,
        openDialog: false,
        success: false,
        inviteNewUser: false
    };

    lastData = [];

    handleNameChange = e => this.props.organisationMemberStore.setName(e.target.value);
    handleEmailChange = e => this.props.organisationMemberStore.setEmail(e.target.value);
    handleTitleChange = e => this.props.organisationMemberStore.setTitle(e.target.value);
    handleEditChange = e => this.props.organisationMemberStore.setEdit(e.target.checked);
    handleFinancialChange = e => this.props.organisationMemberStore.setFinancial(e.target.checked);
    handleBookingChange = e => this.props.organisationMemberStore.setBooking(e.target.checked);
    handleManageChange = e => this.props.organisationMemberStore.setManage(e.target.checked);
    handlePromoteChange = e => this.props.organisationMemberStore.setPromote(e.target.checked);
    handleViewOnlyChange = e => this.props.organisationMemberStore.setViewOnly(e.target.checked);
    handleLastNameChange = e => this.props.organisationMemberStore.setLastName(e.target.value);
    
    handleSubmitClick = async () => {
        await this.props.organisationMemberStore.submit().then(() => {
            const { errors } = this.props.organisationMemberStore;

            if(typeof errors === 'undefined') {
                this.handleCloseDialog();
                this.props.templateStore.closeOrganisationBlock();
                this.props.templateStore.closeStateBlock();
                this.props.templateStore.closeRegionBlock();
                this.props.templateStore.closeMemberBlock();
                this.props.organisationMemberStore.resetErrors();
            }
        }).catch((err) => {
            this.props.templateStore.openSnackbar(err);
        })
       
    };

    handleDeleteClick = () => {
        this.props.selected.map(om => {
            return this.props.organisationMemberStore.removeOrganisationMember(om);
        });
        this.props.handleClearSelected();
        this.props.templateStore.closeOrganisationBlock();
        this.props.templateStore.closeStateBlock();
        this.props.templateStore.closeRegionBlock();
        this.props.templateStore.closeMemberBlock();
        this.setState({ openDelete: true });

        return true;
    };

    handleSnackbarAddOpen = () => {
        this.setState({ openAdd: true });
    };

    handleSnackbarAddClose = (event, reason) => {
        this.setState({ openAdd: false });
        if (reason === "undo") {
            //execute undo add
            //  this.props.designProfileStore.deletePlayedAt(this.lastData);
        }
    };

    handleSnackbarDeleteOpen = () => {
        this.setState({ openDelete: true });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        this.setState({ openDelete: false });
        if (reason === "undo") {
            //   this.props.designProfileStore.addPlayedAt(this.lastData);
        }
    };

    handleUndoAddPlayedAt = event => {
        this.handleSnackbarAddClose(event, "undo");
    };

    handleUndoRemovePlayedAt = event => {
        this.handleSnackbarDeleteClose(event, "undo");
    };

    handleOpenDialog = () => {
        this.props.organisationMemberStore.resetAddForm();
        this.setState({ openDialog: true });
    };

    handleCloseDialog = () => {
        this.props.organisationMemberStore.resetErrors();
        this.props.organisationMemberStore.setInvitedProfile(undefined)
        this.setState({ openDialog: false, inviteNewUser: false });
    };

    handleEmpty = value => {
        this.props.organisationMemberStore.resetAddForm();
        this.setState(
            {
                ...this.state,
                inviteNewUser: true
            },
            () => {
                var fields = value.split(' ');
                if (fields.length > 1){
                    this.props.organisationMemberStore.setLastName(fields[fields.length-1]);
                    let removeLastIndex = fields.pop()
                    this.props.organisationMemberStore.setName(fields.toString());
                } else {
                    this.props.organisationMemberStore.setName(value);
                }
            }
        );
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    render() 
       {
        const { inviteNewUser } = this.state;
        const { classes, selected } = this.props;
        const { playedAtSuccess } = true;
        const { members } = this.props.organisationMemberStore;
        const fullScreen = false;
        const { title, edit, financial, booking, name, lastName, email, manage, promote, viewOnly, errors } = this.props.organisationMemberStore;
        let numSelected = selected.length;

        var canManage = false;
        let editLabel = "";

        if (this.props.level === 1) {
            canManage = this.props.organisationStore.canManage || this.props.organisationStore.id === undefined;
            editLabel = `Edit ${this.props.organisationStore.level1Label}`;
        } else if (this.props.level === 2) {
            canManage = this.props.stateStore.canManage;
            editLabel = `Edit ${this.props.organisationStore.level2Label}`;
        } else if (this.props.level === 3) {
            canManage = this.props.regionStore.canManage;
            editLabel = `Edit ${this.props.organisationStore.level3Label}`;
        } else if (this.props.level === 4) {
            canManage = this.props.memberProfileStore.canManage;
            editLabel = "Manage Venue Staff";
        }

       

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0
                })}
            >
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography variant="subtitle1" style={{ color: "#FFF" }}>
                            {numSelected} selected
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1">Staff Members</Typography>
                    )}
                </div>
                <div className={classes.spacer} />
                {canManage ? (
                    <div className={classes.actions}>
                        {numSelected > 0 ? (
                            <Tooltip title="Delete">
                                <Button aria-label="Delete" style={{ color: "#FFF" }} onClick={this.handleDeleteClick}>
                                    <DeleteIcon />
                                    <span className={classes.textSideIcon}>Delete </span>
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Add" >
                                <Button
                                    id="add-user"
                                    aria-label="Add"
                                    onClick={this.handleOpenDialog}
                                    style={playedAtSuccess ? { color: green[800] } : null}
                                >
                                    {playedAtSuccess ? (
                                        <CheckIcon />
                                    ) : (
                                        <React.Fragment>
                                            <AddIcon style={{color:"white"}}/>
                                            <span className={classes.textSideIcon} style={{color:"white"}}>Add</span>
                                        </React.Fragment>
                                    )}
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                ) : (
                    ""
                )}

                {canManage ? (
                    <Dialog fullScreen={fullScreen} open={this.state.openDialog} onClose={this.handleCloseDialog} transition={this.transition}>
                        <DialogTitle className="see-more-dialog-title">Add Member</DialogTitle>
                        <DialogContent className="see-more-dialog-content">
                            <div style={{ padding: 16 }}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12}>
                                        <DialogContentText className={classes.blackTextColor}>
                                            Add staff member to your team by their email address/existing user.
                                        </DialogContentText>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                    {this.props.organisationMemberStore.invitedProfile && !inviteNewUser ? (
                                        <Fragment>
                                            <InputLabel>New Staff</InputLabel>
                                            <Card>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar
                                                            alt={this.props.organisationMemberStore.invitedProfile.fullName}
                                                            src={
                                                                this.props.organisationMemberStore.invitedProfile.profileImage
                                                                    ? this.props.organisationMemberStore.invitedProfile.profileImage.url
                                                                    : ""
                                                            }
                                                            style={{
                                                                width: 40,
                                                                height: 40
                                                            }}
                                                        />
                                                    }
                                                    action={
                                                        <IconButton onClick={() => this.props.organisationMemberStore.setInvitedProfile(undefined)}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    }
                                                    title={this.props.organisationMemberStore.invitedProfile.fullName}
                                                />
                                            </Card>
                                        </Fragment>
                                    ) : !inviteNewUser ? <Fragment>
                                            <InputLabel>Staff   </InputLabel>
                                            <ProfileSearchBox
                                                placeholder="Search your existing staff roster"
                                                handleSearch={value => this.props.profileStore.searchMemberVenueProfile(value,members)}
                                                handleChange={item => this.props.organisationMemberStore.setInvitedProfile(item)}
                                                handleEmpty={this.handleEmpty}
                                                menuStyle={{
                                                    position: "absolute",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    width: "100%"
                                                }}
                                            />
                                        </Fragment> : null
                                    }
                                    {/* <Grid item xs={12} md={8}>
                                            <UserSearchBox
                                                placeholder=""
                                                handleSearch={value => this.props.profileStore.searchVenueProfile(value)}
                                                handleChange={item => {
                                                    this.props.organisationMemberStore.setProfile(item);
                                                }}
                                                handleEmpty={() => console.log("empty")}
                                            />
                                        </Grid> */}
                                        </Grid>
                                     {inviteNewUser ? <Grid item xs={12} md={6}>
                                        <TextField
                                            id="staff-name"
                                            autoFocus={true}
                                            placeholder="Staff name"
                                            label="First Name"
                                            margin="none"
                                            fullWidth
                                            value={name}
                                            onChange={this.handleNameChange}
                                            error={typeof errors !== 'undefined' && typeof errors.staffName !== 'undefined'}
                                            helperText={errors && errors.staffName ? errors.staffName : ""}
                                        />
                                   </Grid>
                                   : null }
                                   {inviteNewUser ? <Grid item xs={12} md={6}>
                                        <TextField
                                            id="last-name"
                                            autoFocus={true}
                                            placeholder="Last Name"
                                            label="Last Name"
                                            margin="none"
                                            fullWidth
                                            value={lastName}
                                            onChange={this.handleLastNameChange}
                                            error={typeof errors !== 'undefined' && typeof errors.lastName !== 'undefined'}
                                            helperText={errors && errors.lastName ? errors.lastName : ""}
                                        />
                                    </Grid> : null }
                                    {inviteNewUser ?  <Grid item xs={12} md={6}>
                                        <TextField
                                            id="staff-email"
                                            placeholder="Email address"
                                            label="Email"
                                            margin="none"
                                            fullWidth
                                            value={email}
                                            onChange={this.handleEmailChange}
                                            error={typeof errors !== 'undefined' && typeof errors.staffEmail !== 'undefined'}
                                            helperText={errors && errors.staffEmail ? errors.staffEmail : ""}
                                        />
                                    </Grid>: null
                                    }

                                    {inviteNewUser ? <Grid item xs={12} md={6}>
                                        <TextField
                                            id="staff-title"
                                            placeholder="i.e. Manager, Staff, Admin"
                                            label="Title"
                                            margin="none"
                                            fullWidth
                                            value={title}
                                            onChange={this.handleTitleChange}
                                            error={typeof errors !== 'undefined' && typeof errors.title !== 'undefined'}
                                            helperText={errors && errors.title ? errors.title : ""}
                                        />
                                    </Grid>: null
                                    }

                                    {!inviteNewUser ? <Grid item xs={12} md={12}>
                                        <TextField
                                            id="staff-title"
                                            placeholder="i.e. Manager, Staff, Admin"
                                            label="Title"
                                            margin="none"
                                            fullWidth
                                            value={title}
                                            onChange={this.handleTitleChange}
                                            error={typeof errors !== 'undefined' && typeof errors.title !== 'undefined'}
                                            helperText={errors && errors.title ? errors.title : ""}
                                        />
                                    </Grid>: null
                                    }

                                    <Grid item xs={12} md={12}>
                                        <DialogContentText className={classes.blackTextColor}>Permissions:</DialogContentText>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={<Checkbox value="edit" checked={edit} onChange={this.handleEditChange} id="permission-edit" />}
                                            label={<div>Edit Venues Profile</div>}
                                        />
                                        <br />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="financial"
                                                    checked={financial}
                                                    onChange={this.handleFinancialChange}
                                                    id="permission-financial"
                                                />
                                            }
                                            label={<div>Pay Invoices</div>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="booking"
                                                    checked={booking}
                                                    onChange={this.handleBookingChange}
                                                    id="permission-booking"
                                                />
                                            }
                                            label={<div>Manage Bookings</div>}
                                        />
                                        <br />
                                        <FormControlLabel
                                            control={
                                                <Checkbox value="manage" checked={manage} onChange={this.handleManageChange} id="permission-manage" />
                                            }
                                            label={<div>{editLabel}</div>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="promote"
                                                    checked={promote}
                                                    onChange={this.handlePromoteChange}
                                                    id="permission-promote"
                                                />
                                            }
                                            label={<div>Manage Promotions</div>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="viewOnly"
                                                    checked={viewOnly}
                                                    onChange={this.handleViewOnlyChange}
                                                    id="permission-viewOnly"
                                                />
                                            }
                                            label={<div>View Only (No Financial)</div>}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="primary">
                                Close
                            </Button>
                            <Button onClick={this.handleSubmitClick} color="primary" id="staff-submit">
                                Add Member
                            </Button>
                        </DialogActions>
                    </Dialog>
                ) : (
                    ""
                )}
            </Toolbar>
        );
    }
}

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        backgroundColor: "#ff0c9c"
    },
    highlight: {
        backgroundColor: "#333333"
    },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
    blackTextColor: {
        color: "#000"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: 2,
        right: 2,
        zIndex: 1
    },
    textSideIcon: {
        paddingTop: 3
    }
});

export default withMobileDialog()(withStyles(toolbarStyles)(UserTableToolbar));
