import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { Grid, Button, TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

@inject("authStore", "profileStore", "templateStore", "userStore")
@withRouter
@observer
class AccountPassword extends Component {
    state = {
        email: "",
        password: "",
        isDirty: false,
    };

    componentDidMount = () => {
        this.props.authStore.reset();
    };

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    handleCancel = () => {
        this.props.authStore.setPassword("");
        this.props.authStore.setNewPassword("");
        this.props.authStore.setConfirmPassword("");
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.props.templateStore.showLoadingScreen();
        if (
            this.props.authStore.values.password !== "" &&
            this.props.authStore.values.confirmPassword !== "" &&
            this.props.authStore.values.newPassword !== ""
        ) {
            this.props.authStore.changePassword().then(response => {
                this.handleCancel();
                this.props.templateStore.hideLoadingScreen();
                this.props.templateStore.openSnackbar("Account has been updated.");
            });
        } else {
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.openSnackbar("Account update is failed, check your credentials.");
        }
    };

    handlePasswordChange = e => {
        this.props.authStore.setPassword(e.target.value);
        this.validateAllField();
    };

    handleNewPasswordChange = e => {
        this.props.authStore.setNewPassword(e.target.value);
        this.validateAllField();
    };

    handleConfirmPasswordChange = e => {
        this.props.authStore.setConfirmPassword(e.target.value);
        this.validateAllField();
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = name => () => {
        this.setState({ [name]: !this.state[name] });
    };
    validateNewPassword = e => {
        this.props.authStore.validateNewPassword(e.target.value)
        this.validateAllField();
    }

    validateConfirmPassword = (e) => {
        this.props.authStore.validateConfirmPasswor(e.target.value)
        this.validateAllField();
    }

    validateAllField = () => {
        const { values } = this.props.authStore;

        if (this.anyError("confirmPassword") || this.anyError("newPassword") ||
            values.password.length == 0 || values.newPassword.length == 0 || values.confirmPassword.length == 0) {
            this.setState({ isDirty: false });
        }
        else {
            this.setState({ isDirty: true });
        }
    }

    render() {
        const { values } = this.props.authStore;
        const { currentUser } = this.props.userStore;

        return (
            <div>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            label="Current Password"
                            value={values.password}
                            onChange={this.handlePasswordChange}
                            fullWidth
                            type={this.state.showPassword ? "text" : "password"}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={this.handleClickShowPassword("showPassword")}
                                            onMouseDown={this.handleMouseDownPassword}
                                        >
                                            {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={this.anyError("password")}
                            helperText={this.errorText("password")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="newPassword"
                            label="New Password"
                            value={values.newPassword}
                            onChange={this.handleNewPasswordChange}
                            fullWidth
                            onBlur={this.validateNewPassword}
                            type={this.state.showNewPassword ? "text" : "password"}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={this.handleClickShowPassword("showNewPassword")}
                                            onMouseDown={this.handleMouseDownPassword}
                                        >
                                            {this.state.showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={this.anyError("newPassword")}
                            helperText={this.errorText("newPassword")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="confirmPassword"
                            label="Confirm Password"
                            value={values.confirmPassword}
                            onChange={this.handleConfirmPasswordChange}
                            fullWidth
                            onBlur={this.validateConfirmPassword}
                            type={this.state.showConfirmPassword ? "text" : "password"}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={this.handleClickShowPassword("showConfirmPassword")}
                                            onMouseDown={this.handleMouseDownPassword}
                                        >
                                            {this.state.showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={this.anyError("confirmPassword")}
                            helperText={this.errorText("confirmPassword")}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} className="text-xs-right">
                                <Button onClick={this.handleCancel} disabled={!this.state.isDirty}>Close</Button>
                                <Button
                                    variant="contained"
                                    onClick={this.handleSubmitForm}
                                    style={{ marginLeft: 10 }}
                                    color="primary"
                                    disabled={!this.state.isDirty}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default AccountPassword;
