import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import "./ClientInvoice.css";
import blankProfile from "../../../img/hotgig-loading.png";

class ClientInvoice extends Component {
    render() {
        const data = this.props.data;
        return (
            <Grid>
                <table>
                    <tbody><tr>
                        <td className="header-left-col">
                            <table>
                                <tbody><tr>
                                    <td className="logo-container">
                                        <img className="avatar" src={data.profileImage ? data.profileImage : blankProfile} />
                                    </td>
                                    <td>
                                        <span className="invoice-creator" style={{paddingLeft: 20}}>
                                            {data.invoiceCreator}
                                        </span>
                                    </td>
                                </tr>
                                </tbody></table>
                        </td>
                        <td className="header-right-col tax-invoice">
                            Tax Invoice
              </td>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">
                                {data.addressLine1}
                            </td>
                            <td className="header-right-col">
                                Date: {data.createdDate}
                            </td>
                        </tr>
                        <tr>
                            <td className="header-left-col">
                                {data.addressLine2}
                            </td>
                            <td className="header-right-col">
                                Invoice#: HG-{data.invoiceNumber}
              </td>
                        </tr>
                        <tr>
                            <td className="header-left-col">
                                {data.phoneNumber}
                            </td>
                        </tr>
                        <tr>
                            <td className="header-left-col">
                                <a href="">{data.emailAddress}</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="header-left-col">
                                ABN: {data.abnNumber}
                            </td>
                        </tr>
                        <tr>
                            <td className="header-left-col">
                                &nbsp;
              </td>
                            <td className="header-right-col">
                                To: {data.recipientName}
                            </td>
                        </tr>
                        <tr>
                            <td className="header-left-col">
                                &nbsp;
              </td>
                            <td className="header-right-col">
                                <a href="">{data.recipientEmail}</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="header-left-col">
                                &nbsp;
              </td>
                            <td className="header-right-col">
                                {data.recipientAddressLine1}
                            </td>
                        </tr><tr>
                            <td className="header-left-col">
                                &nbsp;
              </td>
                            <td className="header-right-col">
                                {data.recipientSuburb}
                            </td>
                        </tr><tr>
                            <td className="header-left-col">
                                &nbsp;
              </td>
                            <td className="header-right-col">
                                {data.recipientAddressLine2}
                            </td>
                        </tr><tr>
                            <td className="header-left-col">
                                &nbsp;
              </td>
                            <td className="header-right-col">
                                {data.recipientPhoneNumber}
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
                <table><tbody>
                    <tr>
                        <td className="invoice-row-fee">
                            {data.purchaseOrderCode ? `Purchase Order` : ""}
                        </td>
                        <td className="invoice-row-fee">
                            &nbsp;
              </td>
                        <td className="invoice-row-fee">
                            &nbsp;
              </td>
                        <td className="invoice-row-fee">
                            Payment Terms
              </td>
                        <td className="invoice-row-fee">
                            Due Date
              </td>
                    </tr>
                    <tr>
                        {data.purchaseOrderCode ?
                            <td className="bordered-cell invoice-summary-cell-left">{data.purchaseOrderCode}</td> :
                            <td className="invoice-row-fee">&nbsp;</td>
                        }
                        <td className="invoice-row-fee">&nbsp;</td>
                        <td className="invoice-row-fee">&nbsp;</td>
                        <td className="bordered-cell invoice-summary-cell">
                            {data.paymentTerms}
                        </td>
                        <td className="bordered-cell invoice-row invoice-summary-cell">
                            {data.paymentDueDate}
                        </td>
                    </tr>
                </tbody>
                </table>
                <br />
                <br />
                <table className="invoice-table">
                    <tbody>
                        {data.invoiceLines.map(invoice => {
                            return <tr className="bordered-cell invoice-row">
                                <td className="bordered-cell invoice-row">
                                    {invoice.date}
                                </td>
                                <td className="bordered-cell invoice-row">
                                    {invoice.timeStart}-{invoice.timeEnd}
                                </td>
                                <td className="bordered-cell invoice-row">
                                    {invoice.description}
                                </td>
                                <td className="bordered-cell invoice-row invoice-row-fee-detail">
                                    {invoice.fee}
                                </td>
                            </tr>
                        })}
                        <tr>
                            <td>
                                &nbsp;
              </td>
                            <td>
                                &nbsp;
              </td>
                            <td className="invoice-summary-cell invoice-row">
                                Total
              </td>
                            <td className="bordered-cell invoice-row-fee-detail invoice-row">
                                {data.invoiceSubTotal}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                &nbsp;
              </td>
                            <td>
                                &nbsp;
              </td>
                            <td className="invoice-summary-cell invoice-row">
                                {data.taxLabel}
                            </td>
                            <td className="bordered-cell invoice-row-fee-detail invoice-row">
                                {data.invoiceTax}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                &nbsp;
              </td>
                            <td>
                                &nbsp;
              </td>
                            <td className="invoice-summary-cell invoice-row">
                                Invoice Total
              </td>
                            <td className="bordered-cell invoice-row-fee-detail invoice-row">
                                {data.invoiceTotal}
                            </td>
                        </tr>
                    </tbody></table >
                <table>
                    <tbody><tr>
                        <td className="payment-to">
                            Please make payments to:
              </td>
                    </tr>
                        <tr>
                            <td className="payment-to">
                                Bank Account Name: {data.paymentReceiverAccountName}
                            </td>
                        </tr>
                        <tr>
                            <td className="payment-to">
                                BSB: {data.paymentReceiverBSB}
                            </td>
                        </tr>
                        <tr>
                            <td className="payment-to">
                                Account Number: {data.paymentReceiverAccountNumber}
                            </td>
                        </tr>
              
                    </tbody></table>
            </Grid >
        );
    }
}

export default ClientInvoice;
