import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { IconButton, Menu, MenuItem, Avatar, Button } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import MoreVertIcon from "@material-ui/icons/MoreVert";

@inject("templateStore", "notificationStore", "profileStore")
@withRouter
@observer
class NotificationLineupReminder extends Component {
    state = {
        anchorMenu: null,
        buttonClicked: false
    };

    handleCloseNotificationPopover = () => {
        this.props.templateStore.setNotificationPopoverAnchor(null);
    };

    handleClickMenu = event => {
        event.preventDefault();
        this.setState({ anchorMenu: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorMenu: null });
    };

    handleRead = () => {
        const { notification } = this.props;
        this.handleCloseMenu();
        this.handleCloseNotificationPopover();
        this.props.notificationStore.confirm(notification);
    };

    handleDelete = () => {
        const { notification } = this.props;
        this.handleCloseMenu();
        this.props.notificationStore.delete(notification);
    };

    handleClick = () => {
        const { notification } = this.props;
        if (!this.state.buttonClicked && notification.type == NotificationType.BookingReminder.ordinal || notification.type == NotificationType.LineupReminder.ordinal) {
            this.setState({buttonClicked: true})
            return null
        }

        // let forwardUrl = GetNotificationLink(notification);

        // if (notification && notification.toProfile) {
        //     var newProfileType = notification.toProfile.type;
        //     if (newProfileType === ProfileType.Act.ordinal) {
        //         newProfileType = ProfileType.Musician.ordinal;
        //     } else if (newProfileType === ProfileType.Venue.ordinal) {
        //         newProfileType = ProfileType.Staff.ordinal;
        //     }

        //     this.props.profileStore.setProfileType(newProfileType);
        // }
        // if (this.props.history.location.pathname === forwardUrl) {
        //     //window.location.reload();
        // } else {
        //     this.props.history.push(forwardUrl);
        // }

        this.props.notificationStore.gotoPage(notification);

        this.handleRead();
    };

    handleConfirm = async event => {
        this.setState({buttonClicked: true})
        await this.props.notificationStore.confirm(this.props.notification, true)
            this.props.notificationStore.gotoPage(this.props.notification);
            this.handleRead();
    };

    handleReminder = () => {
        this.props.notificationStore.reminder(this.props.notification);
    };

    render() {
        const { anchorMenu } = this.state;
        const { notification } = this.props;
        let confirm = false;
        let remind = false;
        if (notification.message && typeof(notification.message) == 'string') {
            const parsedData = JSON.parse(notification.message)
            confirm = parsedData.Confirmed;
            remind = parsedData.Reminder;
        } 

        return (
            <div className={notification.isRead ? "read" : "unread"}>
                <ListItem button>
                    <ListItemAvatar onClick={this.handleClick}>
                        <Avatar src={notification.profileImageUrl} />
                    </ListItemAvatar>
                    <ListItemText
                        onClick={this.handleClick}
                        primary={<div dangerouslySetInnerHTML={{ __html: notification.generatedMessage }} />}
                        secondary={
                            <div>
                                <Button
                                    disabled={confirm}
                                    onClick={() => this.handleConfirm()}
                                    className="btn-notification"
                                    variant="outlined"
                                    size="small"
                                >
                                    {confirm ? "Confirmed" : "Confirm"}
                                </Button>
                                &nbsp;
                                {/* <Button
                                    disabled={remind}
                                    onClick={this.handleReminder}
                                    className="btn-notification"
                                    variant="outlined"
                                    size="small"
                                >
                                    {remind ? "Reminder Set" : "Set Reminder"}
                                </Button> */}
                            </div>
                        }
                        disableTypography={false}
                    />
                    <ListItemSecondaryAction>
                        <IconButton aria-label="Action" onClick={this.handleClickMenu}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu id="notif-menu" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={this.handleCloseMenu}>
                            <MenuItem onClick={this.handleRead}>Confirm and Mark as read</MenuItem>
                            <MenuItem onClick={this.handleDelete}>Delete</MenuItem>
                        </Menu>
                    </ListItemSecondaryAction>
                </ListItem>
            </div>
        );
    }
}

export default NotificationLineupReminder;
