import React, { Component,Fragment } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import AgentVenueTable from "../../components/agent/venueRoster/AgentVenueTable";
import Breadcrumb from "../../components/Breadcrumb";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import { Avatar, Button, Typography, IconButton, Grid ,Menu, MenuItem} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import image from "../../helper/image";
import TableRowProfile from "../../components/util/TableRowProfile";
import Heading from "../../components/Heading";
import ClearIcon from "@material-ui/icons/Clear";
import AgentActInvitationModal from "../../components/agent/venueRoster/AgentActInvitationModal";

@inject("venueActStore","templateStore","profileStore")
@withRouter
@observer
class VenueActFavourites extends Component {
    state ={
        actInvitationModalState: false,
        venueActModalState: false,
        venue: null,
        actionAnchor: []
    }
    breadcrumb = [{ name: "Dashboard", link: "/agent" }, { name: "My Venue Roster", link: "/agent/venues" },, { name: `${this.props.match.params.venueName} Favourite Act` }];
    rowData = [];
    columns = [
        { key: "actName", label: "Act Name",width: "15%" , sortable: true, isString: true,addComp: true},
        { key: "actOwner", label: "Act Owner", width: "15%",sortable: true, isString: true, addComp: true  },
        { key: "action", label: "", width: "10%" }
    ];
   
    componentDidMount() {
     
        this.loadAct()
    }
    loadAct = () => {
        this.rowData = [];
        this.props.venueActStore.resetPage()
        if(this.props.match.params.id){
            this.props.templateStore.showLoadingScreen();
            Promise.all([this.props.profileStore.getProfileByIdSimple(this.props.match.params.id),
            this.props.venueActStore.getVenueActRosterWithPagination(this.props.match.params.id,10,1)
            ])
           .then((result) => {
                this.props.templateStore.hideLoadingScreen();
                this.setState({
                    ...this.state,
                    venue: result[0]
                });
            });
        }
    }

    handleShowMore = e => {
        var page = this.props.venueActStore.agentActFavouritePage + 1;
        this.props.templateStore.showLoadingScreen();
        this.loadMoreAgentActFavourite(page)
    };

    loadMoreAgentActFavourite = (page) => {
        const currentProfile = this.props.profileStore.getCurrentProfile()
        this.props.venueActStore.getVenueActRosterWithPagination(this.props.match.params.id, 10,page).then(() =>{
            this.props.templateStore.hideLoadingScreen();
            this.props.venueActStore.setAgentActFavouritePage(page)
        })
       
    }
    handleDeleteAct = (id, act, agent) => {
        this.props.venueActStore.deleteVenueAct(id).then(response => {
            this.props.templateStore.openSnackbar(act + " has been deleted from " + agent  + "'s favourite act.");
        });
    };

    setActionAnchor = (element, id) => {
        var tempAnchor = [];
        tempAnchor[id] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = id => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    handleButtonMessage = (act) => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        if (currentProfile) {
            this.props.history.push({
                pathname: '/messages/new',
                state: { profile: act.actAdmin }
            })
        }
    }

    createBooking = (actId) => {
        let redirectUrl = `/booking/new?actId=${actId}&venueId=${this.props.match.params.id}&dateStart=${moment(new Date()).format("DD-MM-YYYY")}`
                                                
        this.props.history.push(redirectUrl)
    }
    mapApprovedToRowData = () => {
        this.rowData = [];
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.venueActStore.venueActRoster.forEach(data => {
            var row = {};

            var act = data.act;
            

            row.id = "a" + data.id;

            row.data = data;

            row.actName =  {newFormat: 
                (
                    <TableRowProfile profileId={act.id} name={act.actName} avatarUrl={image.getProfileImage(act)} />

                ), value: act.actName};
            row.actOwner = {newFormat: 
            (
                <TableRowProfile profileId={act.myMusician?.id} name={act.myMusician?.fullName} avatarUrl={image.getProfileImage(act.myMusician)} />

            ), value: act.myMusician?.fullName};

            row.action = (
                <Fragment>
                <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, data.id)}>
                <i class="fa-solid fa-ellipsis-vertical fa-xl"></i>
                               </Button>
                               {data.id && data.createdById === currentProfile.id ? (
                                   <Menu
                                       open={this.state.actionAnchor[data.id] != null}
                                       onClose={() => this.unsetActionAchnor(data.id)}
                                       anchorEl={this.state.actionAnchor[data.id]}
                                   >
                                       <MenuItem 
                                        button
                                        className="booking-details"
                                        onClick={() => this.createBooking(act?.id)}
                                       >Create Booking</MenuItem>
                                      
                                        <MenuItem onClick={() => this.handleButtonMessage(act)}>Send Message</MenuItem>
                                        
                                        <MenuItem onClick={() => this.handleDeleteAct(data.id, data.act.fullName, data.act.myMusician.fullName)}>Remove</MenuItem>
        
                                  </Menu>
                               ) : (
                                <Menu
                                open={this.state.actionAnchor[data.id] != null}
                                onClose={() => this.unsetActionAchnor(data.id)}
                                anchorEl={this.state.actionAnchor[data.id]}
                            >
                                <MenuItem 
                                 button
                                 className="booking-details"
                                 onClick={() => this.createBooking(act?.id)}
                                >Create Booking</MenuItem>
                               
                                 <MenuItem onClick={() => this.handleButtonMessage(act)}>Send Message</MenuItem>
                               
                           </Menu>
                               )
        }
           </Fragment>
            )

            // {data.createdById === currentProfile.id ? (
            //     row.action =  <IconButton onClick={() => this.handleDeleteAct(data.id, data.act.fullName, data.act.myMusician.fullName)}>
            //         <ClearIcon />
            //     </IconButton>
            // ) : (
            //     ""
            // )}
            this.rowData.push(row);
        });
    };

    openAddActModal = () => {
        this.setState(
            {
                ...this.state,
                venueActModalState: false,
                actInvitationModalState: true
            },
            () => {
                this.props.venueActStore.clearInviteData();
            }
        );
       
       
    };

    closeAddActModal = async () => {
       
        await this.loadAct()
            this.setState({
                ...this.state,
                actInvitationModalState: false,
                venueActModalState: true
            });
       
    };
    
    render() {
        this.mapApprovedToRowData();
        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title={`${this.props.match.params.venueName} Favorite Acts`}>
                        <Button color="primary" variant='contained' onClick={this.openAddActModal} className="pull-right">
                            Add Act
                        </Button>
                    </Heading>
                    <div style={{backgroundColor:"white", paddingBottom:15}}>
                    <ResponsiveTable columns={this.columns} rows={this.rowData} />
                    <Grid item style={{marginTop: 25, marginBottom:15}} xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                        <Grid container direction="row" justify="center" alignItems="center">
                            {this.props.venueActStore.isMoreFavouriteAct ? (
                                <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                    Show More
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                    </div>

                    <AgentActInvitationModal
                        open={this.state.actInvitationModalState}
                        onClose={this.closeAddActModal}
                        venue={this.state.venue ? this.state.venue : undefined}
                    />
                </MainContainer>
            </div>
        );
    }
}

export default VenueActFavourites;
