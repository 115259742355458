import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import SelectField from "../../components/fields/SelectField";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import Switch from "@material-ui/core/Switch";
import  Loading from "../../components/loadingSpinner/Loading";
import { withRouter } from "react-router-dom";
import moment from "moment"
import { XeroErrorType } from "../../types/enum";
import XeroMappedContactModal from "../../components/invoice/Modal/XeroMappedContactModal"
import XeroCustomerModal from "../../components/invoice/Modal/XeroCustomerModal"
const headerMarkAsApproveMessage = "Mark As Approved Confirmation"
const headerTurnOffMarkAsApproveMessage = "Are you sure you want to disable ‘Mark as Approved’ on invoice sync?"
import CheckCircle from "@material-ui/icons/CheckCircle";

const markAsApproveMessage = "Enabling this feature will mark invoices as 'Approved / Awaiting Payment” in XERO.  Do you want to continue?"
const turnOffMarkAsApproveMessage = "Turning off ‘Mark as Approved’ on invoice sync will mean that future synced invoices will show as ‘Awaiting Approval’.   Are you sure you want to continue"

@inject("profileStore", "xeroLogStore")
@withRouter
@observer
class XeroLog extends Component {
    state = {
        openSyncPDFModal: false,
        isLoading:false,
        openXeroSettingApprovedToggle: false,
        selectedPayerId: 0,
        openCustomerMappedXeroModal: false,
        relatedInvoiceId: 0,
        type: 0,
        logId: null,
        openCustomerXeroModal: false
    };
    columns = [
        { key: "date", label: "Date", sortable: true,isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
        { key: "description", label: "Description" },
        { key: "type", label: "type" },
        { key: "action", label: "" }
    ]
    errorRows = [];
    componentDidMount = () => {
        var currentProfile  = this.props.profileStore.getCurrentProfile();
        this.props.xeroLogStore.getXeroLogs(currentProfile.id)
    };

    quickFix = (data) => {
        if(data.errorType === XeroErrorType.ContactNotFoundInvoice.ordinal){
            this.showCustomerMappedXeroModal(data.contactId, data.invoiceId,1,data.id)
        }else if(data.errorType === XeroErrorType.ContactNotFoundBill.ordinal){
            this.showCustomerMappedXeroModal(data.contactId, data.invoiceId, 0,data.id)
        }else if(data.errorType === XeroErrorType.CustomerPrivateNotFound.ordinal){
            this.showCustomerXeroModal(data.invoiceId,data.id)
        }else if(data.errorType === XeroErrorType.InvoiceAccountCodeNotMapped.ordinal){
            this.props.xeroLogStore.markAsResolved(data.id)
            this.props.redirectToInvoiceMapping()
    
        }
    }
    getLogsDescription = (type) => {
        var description = ""
        switch (type) {
            case "General":
                description = "Contact Administration"
                break;
            case "ContactNotFoundBill":
                description = "Billing Contact Not Found"
                break;
            case "ContactNotFoundInvoice":
                description = "Contact Not Found Invoice"
                break;
            case "CustomerNotFound":
                description = "CustomerNotFound"
                break;
            case "SupplierNotFound":
                description = "SupplierNotFound"
                break;
            case "CustomerPrivateNotFound":
                description = "CustomerPrivateNotFound"
                break;
            case "InvoiceNotFound":
                description = "InvoiceNotFound"
                break;
            case "UploadAttachmentFailed":
                description = "UploadAttachmentFailed"
                break;
            case "InvoiceNotFound":
                description = "Contact Administration"
                break;
            default:
                description = "Contact Administration"
                break;
        }
        
        return description
}
    mapErrorLogs = () => {
        this.errorRows = [];

        this.props.xeroLogStore.xeroErrorLogs.forEach(data => {
            var row = {};

                                var date = moment(data.createdDate)
                                var formattedDate = date.format("ddd, DD MMM YYYY hh.mm A")
            row.date =  row.date = {newFormat: 
                (
                    <div>
                        <span>{ formattedDate }</span>
                    </div>
                ), value: date};

            row.description = data.description
            row.type = data.errorType
            
            if(data.errorType === XeroErrorType.ContactNotFoundBill.ordinal || data.errorType === XeroErrorType.ContactNotFoundInvoice.ordinal || data.errorType === XeroErrorType.CustomerNotFound.ordinal || data.errorType === XeroErrorType.SupplierNotFound.ordinal || data.errorType === XeroErrorType.CustomerPrivateNotFound.ordinal || data.errorType === XeroErrorType.InvoiceAccountCodeNotMapped.ordinal){
                if(data.isResolved){
                    row.action = 
                    (
                        <div style={{textAlign:"center", width:"100%"}}><CheckCircle style={{ texAlign:"center",color: "green"}} /></div>
                    );
                }else{
                    row.action = 
                    (
                             <Button style={{textAlign:"center", width:"100%"}}size="small" color="primary" onClick={() => this.quickFix(data)}>
                                Quick Fix
                            </Button>
                    );
                }
              
            }else{
                row.action = 
                    (<div></div>)
            }
           
            
            row.style = {
                backgroundColor: "#EEE"
            };

            this.errorRows.push(row);
        });
        return Promise.resolve();
    };

    showCustomerMappedXeroModal = (payerId,invoiceId,type,logId) => {
        this.setState({
            ...this.state,
            openCustomerMappedXeroModal: true,
            selectedPayerId: payerId,
            relatedInvoiceId: invoiceId,
            type: type,
            logId:logId
        });
    };

    hideCustomerMappedXeroModal = () => {
        this.setState({
            ...this.state,
            openCustomerMappedXeroModal: false,
            relatedInvoiceId: null
        });
    };

    showCustomerXeroModal = (invoiceId,logId) => {
        this.setState({
            ...this.state,
            openCustomerXeroModal: true,
            relatedInvoiceId : invoiceId,
            logId:logId
        });
    };

    hideCustomerXeroModal = () => {
        this.setState({
            ...this.state,
            openCustomerXeroModal: false,
            relatedInvoiceId : null
        });
    };
    render() {
        const {openCustomerMappedXeroModal, selectedPayerId,relatedInvoiceId, type, openCustomerXeroModal, logId} = this.state
        this.mapErrorLogs()
        return (
            <div>
                <Loading showed={this.state.isLoading}/>
                <XeroMappedContactModal type={type}  selectedPayerId={selectedPayerId}  logId={logId} relatedInvoiceId={relatedInvoiceId} open={openCustomerMappedXeroModal} closeHandler={this.hideCustomerMappedXeroModal} />
                <XeroCustomerModal open={openCustomerXeroModal} logId={logId}  relatedInvoiceId={relatedInvoiceId} closeHandler={this.hideCustomerXeroModal} />
                <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                This is a list of the errors from the last sync with XERO. 
                                            </Typography>
                                            {/* <Typography gutterBottom>
                                                Lorem ipsum
                                            </Typography> */}
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item md={12}>
                                                        {this.errorRows.length > 0 ? (
                                                            <ResponsiveTable
                                                                columns={this.columns}
                                                                rows={this.errorRows}
                                                                showToolbar={true}
                                                                title="Error logs"
                                                                pagination={this.errorRows.length > 5}
                                                            />) : null
                                                        }
                                                    </Grid>
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>
            </div>
            
        );
    }
}

export default XeroLog;
