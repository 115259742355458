import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import { Grid, Typography, Paper } from "@material-ui/core";
import NotificationTable from "../../components/notificationTable/NotificationTable";
import { ProfileType } from "../../types/enum";

@inject("songListStore", "profileStore")
@observer
class Notification extends Component {
    render() {
        var breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Notifications", link: "/notifications" }];
        const { currentProfile } = this.props.profileStore;
        if(currentProfile?.type === ProfileType.PrivateHire.ordinal){
            breadcrumb = [{ name: "Notifications", link: "/notifications" }];
        }
        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} /> <Heading title="Notifications" />
                    <Paper style={{ padding: 16 }}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} lg={12}>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        {currentProfile && (
                                            <NotificationTable
                                                onListAdd={this.handleAddSongList}
                                                onListRemove={this.handleRemoveSong}
                                                onListSort={this.handleSortSongList}
                                                label="Song List"
                                                profileId={currentProfile.id}
                                                type="musician"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </MainContainer>
            </main>
        );
    }
}

export default Notification;
