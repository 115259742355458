import React, { Component } from 'react'
import { inject, observer } from "mobx-react";
import ModalContainer from '../../modal/ModalContainer';
import { DialogContent, DialogActions, Grid, TextField, Button, InputLabel, InputAdornment } from '@material-ui/core';
import SelectField from '../../fields/SelectField';
import RichTextEditor from '../../richTextEditor/RichTextEditor';

import ChipInput from 'material-ui-chip-input';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    "@global": {
        "div[class*='WAMuiChipInput-underline-']:before": {
            backgroundColor: 'transparent'
        }, 
        "div[class*='WAMuiChipInput-inkbar-']:after": {
            backgroundColor: 'transparent'
        }, 
        "div[class*='WAMuiChipInput-underline-']:hover:not([class*='WAMuiChipInput-disabled-']):before": {
            height: 0, 
            backgroundColor: 'transparent'
        }, 
        "div[class*='WAMuiChipInput-inputRoot-']": {
            margin: '0 8px 8px 0'
        }, 
        "div[class=*'WAMuiChipInput-chip-']": {
            margin: '8px 8px 8px 0'
        }
    }
});

@inject("venueMenuStore")
@observer
class NewDrinkModal extends Component {
    
    onDrinkNameChange = e => {
        this.props.venueMenuStore.updateDrinkAttribute('name', e.target.value);
    }

    onDrinkPriceChange = e => {
        this.props.venueMenuStore.updateDrinkAttribute('price', e.target.value);
    }

    onDrinkSectionChange = value => {
        this.props.venueMenuStore.updateDrinkAttribute('section', value);
    }

    onDrinkDescriptionChange = value => {
        this.props.venueMenuStore.updateDrinkAttribute('description', value);
    }

    onDrinkTagsChange = value => {
        this.props.venueMenuStore.updateDrinkAttribute('tags', value);
    }

    mapDrinkSectionSuggestions = () => {
        const { drinkSections } = this.props.venueMenuStore;
        return drinkSections;
    }

    handleAddNewDrink = () => {
        const { drink } = this.props.venueMenuStore;
        
        this.props.venueMenuStore.addToDrinkList(drink);

        const { menuErrors } = this.props.venueMenuStore;

        if(typeof menuErrors === 'undefined') {
            this.props.venueMenuStore.resetDrinkAttribute();

            if(this.props.closeHandler){
                this.props.closeHandler();
            }
        }
    }

    render(){
        const {
            open,
            closeHandler, 
            currency
        } = this.props;

        const { drink, menuErrors } = this.props.venueMenuStore;

        return (
            <ModalContainer open = { open } title = "Add New Drink Menu Item">
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <TextField
                                id="drinkName"
                                label="Drink Name"
                                margin="dense"
                                fullWidth
                                autoFocus
                                value={ drink.name }
                                onChange={this.onDrinkNameChange}
                                error={typeof menuErrors !== 'undefined' && typeof menuErrors.drinkName !== 'undefined'}
                                helperText={menuErrors && menuErrors.drinkName ? menuErrors.drinkName : ""}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel>Drink Section</InputLabel>
                            <SelectField 
                                suggestions={this.mapDrinkSectionSuggestions()}
                                onChange={this.onDrinkSectionChange}
                                value={ drink.section }
                                placeholder="Select Drink Section"
                                error={typeof menuErrors !== 'undefined' && typeof menuErrors.drinkSection !== 'undefined'}
                                helperText={menuErrors && menuErrors.drinkSection ? menuErrors.drinkSection : ""}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                id="drinkPrice"
                                label="Price"
                                margin="dense"
                                fullWidth
                                InputProps = {
                                    {
                                        type: "number", 
                                        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
                                    }
                                }
                                value={ drink.price }
                                onChange={this.onDrinkPriceChange}
                                error={typeof menuErrors !== 'undefined' && typeof menuErrors.drinkPrice !== 'undefined'}
                                helperText={menuErrors && menuErrors.drinkPrice ? menuErrors.drinkPrice : ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Drink Style</InputLabel>
                            <br />
                            <ChipInput id="drinkTags" fullWidth={true} onChange={(chips) => this.onDrinkTagsChange(chips)} defaultValue={drink.tags} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Description</InputLabel>
                            <RichTextEditor 
                                id="drinkDescription"
                                value={ drink.description }
                                onChange={ this.onDrinkDescriptionChange }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick = { closeHandler }>
                        Close
                    </Button>
                    <Button
                        color="primary"
                        onClick = { this.handleAddNewDrink }
                    >
                        {this.props.editDrink ? 'Save' : 'Add'}
                    </Button>
                </DialogActions>
            </ModalContainer>
        )
    }
}

export default withStyles(styles)(NewDrinkModal);