import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button, Grid, Typography } from "@material-ui/core";
// import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Loading from "../loadingSpinner/Loading";
import { withRouter } from "react-router-dom";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Dropzone from "react-dropzone";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import CloudDownload from "@material-ui/icons/CloudDownload";
import DeleteForever from "@material-ui/icons/DeleteForever";
import ConfirmationModal from "../modal/ConfirmationModal";

import { Storage } from "aws-amplify";

// const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

const FILE_MAXSIZE = 8497152;
const ACCEPTED_FILE = "application/pdf";

@inject("insuranceProfileStore", "templateStore", "authStore", "profileStore")
@withRouter
@observer
class Insurance extends React.Component {
    state = {
        confirmDeleteInsuranceFileState: false,
        isLoading: true,
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        // set type
        this.props.insuranceProfileStore.setType(this.props.templateStore.getType(this.props.type));
        this.props.insuranceProfileStore
            .loadInitialData(this.props.profileId)
            .then(() => { })
            .finally(() => {
                this.setState({ isLoading: false });
                window.scrollTo(0, 0);
            });
    }

    handleInsurancePolicyHolderChange = e => this.props.insuranceProfileStore.setInsurancePolicyHolder(e.target.value);
    handleInsuranceCompanyChange = e => this.props.insuranceProfileStore.setInsuranceCompany(e.target.value);
    handleInsurancePolicyNameChange = e => this.props.insuranceProfileStore.setInsurancePolicyName(e.target.value);
    handleInsurancePolicyNumberChange = e => this.props.insuranceProfileStore.setInsurancePolicyNumber(e.target.value);
    handleInsuranceExpiryDateChange = e => this.props.insuranceProfileStore.setInsuranceExpiryDate(moment(e));
    handlePolicyDocumentChange = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.props.templateStore.openSnackbar("Profile image couldn't be uploaded. Please check size and format.");
        } else {
            this.props.insuranceProfileStore.setPolicyDocument(files).then(message => {
                if (message === "error") {
                    this.props.templateStore.openSnackbar("Insurance document couldn't be uploaded. Please check size and format.");
                }
            });
        }
    };

    handleDownloadInsuranceDocument = () => {
        Storage.get(this.props.insuranceProfileStore.insuranceFileContentUrl)
            .then(result => window.open(result))
            .catch(err => console.log(err));
    }

    handleDeleteInsuranceDocument = e => {
        this.props.insuranceProfileStore
            .deleteInsuranceDocument()
            .then()
            .finally(() => {
                this.setState({
                    confirmDeleteInsuranceFileState: false
                });
            });
    };

    handleOpenConfirmation = () => {
        this.setState({
            confirmDeleteInsuranceFileState: true
        });
    };

    handleCloseConfirmation = () => {
        this.setState({
            confirmDeleteInsuranceFileState: false
        });
    };

    handleBack = () => {
        if (this.props.type === "venue") {
            const { profile } = this.props.profileStore;
            this.props.history.push(`/venues/${profile.id}/edit/favourite`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.setState({ isLoading: true });
        this.props.insuranceProfileStore.submit().then(resp => {
            if (this.props.type === "venue") {
                this.props.history.push(`/venues/${resp.id}/edit/contract`);
            }
            const { editProfileActiveStep } = this.props.templateStore;
            this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
        });
    };

    anyError(field) {
        if (!this.props.insuranceProfileStore.errors) return false;
        if (!this.props.insuranceProfileStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.insuranceProfileStore.errors) return null;
        if (!this.props.insuranceProfileStore.errors[field]) return null;
        return this.props.insuranceProfileStore.errors[field];
    }

    render() {
        const {
            insurancePolicyHolder,
            insuranceCompany,
            // insurancePolicyName,
            insurancePolicyNumber,
            insuranceExpiryDate,
            insuranceFileGuid,
            insuranceFileName
        } = this.props.insuranceProfileStore;
        const { editProfileActiveStep } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        const { isLoading } = this.state;

        return (
            <div className="Profile-Financial">
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Insurance
                                </Typography>
                                <Fragment>
                                    <Typography gutterBottom className="side-helper-text">
                                    Enter your public liability insurance information here. Some venues require you to have Public Liability Insurance in order to perform in their venue.
                                    </Typography>
                                </Fragment>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="insurancePolicyHolder"
                                        label="Policy Holder Name"
                                        margin="dense"
                                        fullWidth
                                        value={insurancePolicyHolder}
                                        onChange={this.handleInsurancePolicyHolderChange}
                                        error={this.anyError("insurancePolicyHolder")}
                                        helperText={this.errorText("insurancePolicyHolder")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="insuranceCompany"
                                        label="Insurance Company"
                                        margin="dense"
                                        fullWidth
                                        value={insuranceCompany}
                                        onChange={this.handleInsuranceCompanyChange}
                                        error={this.anyError("insuranceCompany")}
                                        helperText={this.errorText("insuranceCompany")}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="insurancePolicyNumber"
                                        label="Policy Number"
                                        margin="dense"
                                        fullWidth
                                        value={insurancePolicyNumber}
                                        onChange={this.handleInsurancePolicyNumberChange}
                                        error={this.anyError("insurancePolicyNumber")}
                                        helperText={this.errorText("insurancePolicyNumber")}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            id="booking-date"
                                            className="booking-date-picker"
                                            keyboard
                                            value={insuranceExpiryDate || null}
                                            fullWidth
                                            margin="dense"
                                            onChange={this.handleInsuranceExpiryDateChange}
                                            label="Expiry Date"
                                            // minDate={moment().startOf("day")}
                                            format="DD-MM-YYYY"
                                            keyboardIcon={<EventIcon />}
                                            leftArrowIcon={<KeyboardArrowLeftIcon />}
                                            rightArrowIcon={<KeyboardArrowRightIcon />}
                                            // style={{ marginTop: 0 }}
                                            shouldDisableDate={this.shouldDisabledDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    {insuranceFileGuid && (
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="insuranceFileName">Certificate of Currency</InputLabel>
                                            <Input
                                                id="insuranceFileName"
                                                value={insuranceFileName}
                                                startAdornment={
                                                    <InputAdornment
                                                        onClick={this.handleDownloadInsuranceDocument}
                                                        position="start"
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        <a target="_blank">
                                                            <CloudDownload />
                                                        </a>
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                    <InputAdornment
                                                        onClick={this.handleOpenConfirmation}
                                                        position="end"
                                                        style={{
                                                            cursor: "pointer",
                                                            marginRight: 0,
                                                            borderRadius: "0 4px 4px 0",
                                                            padding: "21px 10px 21px 10px",
                                                            backgroundColor: "#ccc"
                                                        }}
                                                    >
                                                        <DeleteForever />
                                                    </InputAdornment>
                                                }
                                                readOnly={true}
                                            />
                                        </FormControl>
                                    )}
                                    {!insuranceFileGuid && (
                                        <Dropzone
                                            accept={ACCEPTED_FILE}
                                            maxSize={FILE_MAXSIZE}
                                            onDrop={this.handlePolicyDocumentChange.bind(this)}
                                            className="dropzone insurance-doc"
                                        >
                                            <p>Upload your policy document here. Maximum size 8MB.</p>
                                        </Dropzone>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="action">
                                <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                    Back
                                </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    {editProfileActiveStep === steps.length - 1 ? "Finish" : "Save/Next"}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
                <ConfirmationModal
                    open={this.state.confirmDeleteInsuranceFileState}
                    title="Delete Certificate of Currency"
                    // message={`Are you sure to delete ${insuranceFileName} file?`}
                    message={`Are you sure you want to delete your insurance certificate of currency?`}
                    closeHandler={this.handleCloseConfirmation}
                    confirmationHandler={this.handleDeleteInsuranceDocument}
                    declineHandler={this.handleCloseConfirmation}
                    confirmationLabel="Delete"
                    declineLabel="Don't Delete"
                />
            </div>
        );
    }
}

export default Insurance;
