import React, { Component } from "react";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Booking.css";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import BookingCalendars from "../../components/booking/bookingCalendars";

@withRouter
@inject("generalProfileStore", "templateStore", "authStore", "bookingRequestStore")
class Booking extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Booking Station" }];
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };
    render() {
        return (
            <main>
                <Header userRole="agent" />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <BookingCalendars 
                        actId={this.getParameterByName("actId")}
                        venueId={this.getParameterByName("venueId")}
                    />
                </MainContainer>
            </main>
        );
    }
}
export default Booking;
