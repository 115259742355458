import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Grid, Typography, Hidden } from "@material-ui/core";

import EventDialog from "../../components/adhocBooking/EventDialog";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Breadcrumb from "../../components/Breadcrumb";
import MainContainer from "../../components/MainContainer";
import WidgetContainer from "../../components/widget/WidgetContainer";

import CustomCalendarToolbar from "../../components/calendar/custom-toolbar";
import CustomEvent from "../../components/calendar/CustomEvent";
import CustomAgendaEvent from "../../components/calendar/CustomAgendaEvent";

import BookingDialog from "../../components/booking/bookingDialog";
import ActCalendarBookingOptionModal from "../../components/adhocBooking/ActCalendarBookingOptionModal";

// import Slide from '@material-ui/core/Slide';
import { Calendar, momentLocalizer  } from 'react-big-calendar' 
import moment from "moment";

import "./Calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ProfileType } from "../../types/enum";
const formatDate = 'YYYY-MM-DD';
moment.locale("ko", {
    week: {
        dow: 1,
        doy: 1
    }
});

const localizer = momentLocalizer(moment)
// let allViews = Object.keys(Calendar.Views).map(k => Calendar.Views[k]);

@inject("eventStore", "bookingRequestStore", "templateStore", "profileStore")
@withRouter
@observer
class CalendarComponent extends Component {
    state = {
        openDialog: false,
        isEdit: false,
        breadcrumb: [],
        openBookingDialog: false,
        openOptionModal: false,
        slotInformation: undefined,
        name: "",
        type: ProfileType.Act.ordinal,
        selectedVenue: undefined,
        selectedAct: undefined,
        startDate: moment().startOf('month').format(formatDate),
        endDate: moment().endOf('month').format(formatDate)
    };

    componentDidMount = () => {
        const {startDate, endDate} = this.state
        let profileId = this.props.match.params.id;
        let profileType = this.props.match.params.profileType;
        this.props.eventStore.setProfileId(profileId);

        this.props.templateStore.showLoadingScreen();
        Promise.all([
            this.props.eventStore.loadEventsByProfileId(profileId),
            this.props.bookingRequestStore.loadMyBookingPagination(profileId, profileType, startDate, endDate )
        ]).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });

        this.props.profileStore.getPublicProfileById(profileId).then(result => {
            this.setState({ type: result.type });
            if(result.type === ProfileType.Venue.ordinal) {
                this.setState({ name: result.profileName });
            } else {
                this.setState({ name: result.actName });
            }
        })
        if (profileType === "act") {
            let breadcrumb = [
                { name: "Dashboard", link: "/" },
                { name: "Manage", link: "/myact" },
                { name: "My Acts", link: "/myact" },
                { name: "Calendar" }
            ];
            this.setState({ breadcrumb: breadcrumb, selectedAct: profileId });
        } else if (profileType === "venue" || profileType === "staff") {
            let breadcrumb = [{ name: "Dashboard", link: "/staff" }, { name: "Venues", link: "/venues" }, { name: "Calendar" }];
            this.setState({ breadcrumb: breadcrumb });
        }
    };

    handleNavigate = (newDate, view, action) => {
        this.props.templateStore.showLoadingScreen();
       
        const typeView = view !== 'agenda' ? view  : 'month';
        const dateMoment = moment(newDate);
        const startDate = dateMoment.startOf(typeView).format(formatDate);
        const endDate   = dateMoment.endOf(typeView).format(formatDate);
        this.setState({
            ...this.state,
            currentDate: newDate,
            startDate: dateMoment.startOf(typeView).format(formatDate),
            endDate: dateMoment.endOf(typeView).format(formatDate),
            isLoading: true
        });
        
        // get agenda data
        this.loadData(startDate,endDate);
    }

    loadData = (startDate,endDate) => {
        let profileId = this.props.match.params.id;
        let profileType = this.props.match.params.profileType;
        Promise.all([
            this.props.eventStore.loadEventsByProfileId(profileId),
            this.props.bookingRequestStore.loadMyBookingPagination(profileId, profileType, startDate, endDate )
        ]).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    }

    handleOpenDialog = () => {
        this.setState({ openDialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false, openOptionModal: false });
    };

    handleClickEvent = event => {
        if (event.type === "booking") {
            this.props.bookingRequestStore.getBookingDetail(event.id).finally(() => {
                this.setState({ openBookingDialog: true });
            });
        } else if (event.type === "availability") {
            this.props.eventStore.reset();
            this.props.eventStore.loadEventById(event.id);
            this.setState({ openDialog: true, isEdit: true });
        }
    };

    handleClickSlot = slotInformation => {
        this.setState({ openOptionModal: true, isEdit: false, slotInformation });
    };

    handleClickVenueBooking = () => {
        const { slotInformation, selectedAct, selectedVenue } = this.state;
        if (slotInformation && this.props.profileStore.currentProfile.type === ProfileType.Musician.ordinal ||
            this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal ||
            this.props.profileStore.currentProfile.type === ProfileType.Agent.ordinal) {
            this.props.history.push(`/booking/new?actId=${selectedAct}&venueId=${selectedVenue}&dateStart=${moment(slotInformation.start).format("DD-MM-YYYY")}`);
        } else {
            this.props.history.push(`/privateBooking`);
        }
    };

    handleClickPrivateEvent = () => {
        const { slotInformation } = this.state;
        this.props.eventStore.reset();

        let start = slotInformation.start;
        let end = slotInformation.end;
        if (start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 0 && end.getMinutes() === 0) {
            this.props.eventStore.setAllDay(true);
        } else {
            this.props.eventStore.setAllDay(false);
        }

        this.props.eventStore.setFrom(slotInformation.start);
        this.props.eventStore.setTo(slotInformation.end);
        this.props.eventStore.setProfileId(this.props.match.params.id);
        this.setState({ openDialog: true, isEdit: false, slotInformation });
    };

    handleCloseBookingDialog = () => {
        this.setState({ openBookingDialog: false });
    };

    renderLegends = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        let legendArrays = [
            // { label: "New", color: "#1565c0" },
            // { label: "Negotiate", color: "#ff0c9c" },
            // { label: "Accepted", color: "#43a047" },
            // { label: "Unavailable", color: "#696969" }
            //{ label: "Completed", color: "#bdbdbd" }

            { label: "Draft", color: "#64b5f6" },
            { label: "New", color: "#1565c0" },
            { label: "Negotiate", color: "#ff0c9c" },
            { label: "In Progress", color: "#1b5e20" },
            { label: "Accepted", color: "#43a047" },
            { label: "Cancelled", color: "#000000" },
            { label: "Unavailable", color: "#696969" }
        ];

        if (
            currentProfile.type === ProfileType.Agent.ordinal ||
            currentProfile.type === ProfileType.Staff.ordinal ||
            currentProfile.type === ProfileType.Venue.ordinal
        ) {
            legendArrays.push({ label: "Declined", color: "#b71c1c" });
            legendArrays.push({ label: "Resent", color: "#b19cd9" });
        }

        if (
            currentProfile.type === ProfileType.Musician.ordinal || 
            currentProfile.type === ProfileType.Staff.ordinal
        ) {
            legendArrays.push({ label: "Completed", color: "#bdbdbd" })
        }

        return legendArrays.map(x => (
            <Grid key={x.label} item xs={3} lg={1}>
                <Typography variant="caption" color="inherit">
                    <span style={{ backgroundColor: x.color, width: "15px", height: "15px", display: "inline-block", borderRadius: "10px" }}>&nbsp;</span>  {x.label}
                </Typography>
            </Grid>
        ));
    };

    handleClickPrivateBooking = () => {
        const { slotInformation, selectedAct } = this.state;
       
        if (slotInformation) {
            this.props.history.push(
                `/privateBooking?date=${moment(slotInformation.start).format("DD-MM-YYYY")}&act=${selectedAct ? selectedAct : ""}`
            );
        } else {
            this.props.history.push(`/privateBooking`);
        }
    };

    render() {
        let components = {
            toolbar: toolbar => <CustomCalendarToolbar toolbar={toolbar} />,
            event: event => <CustomEvent data={event} />,
            agenda: {
                event: event => <CustomAgendaEvent data={event} clickHandler={() => this.handleClickEvent(event.event)} />
            }
        };

        const profileType = this.props.match.params.profileType;
        const { myBooking, myActBooking } = this.props.bookingRequestStore;
        const { events } = this.props.eventStore;
        const { slotInformation, name, type } = this.state;

        let combinedEvents = this.props.bookingRequestStore.generateBookings(profileType === "act" ? myActBooking : myBooking);
        for (let i = 0; i < events.length; i++) {
            combinedEvents.push(events[i]);
        }

        return (
            <main>
                <Header userRole="agent" />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.state.breadcrumb} />
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <WidgetContainer title={name ? name + " - Calendar" : "-" + " Calendar"} padding={24}>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            {/* <Typography variant="headline" component="h3" gutterBottom>
                                                Calendar
                                            </Typography> */}
                                            <Typography gutterBottom>
                                                This is your {type === ProfileType.Act.ordinal ? "act" : "venue"} calendar. Here you can view your agenda and manage your availability here.
                                            </Typography>
                                            <Typography>
                                                <strong>Tip:</strong>
                                            </Typography>
                                            <Typography>
                                                Click on the date you want to manage your availability. You can switch to monthly, weekly, daily, or
                                                agenda view using the top-right dropdown.
                                            </Typography>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <Hidden mdUp>
                                                    <Grid container alignItems="right">
                                                        {this.renderLegends()}
                                                    </Grid>
                                                </Hidden>
                                                <Hidden smDown>
                                                    <Grid container justify="flex-end" alignItems="right">
                                                        {this.renderLegends()}
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Calendar
                                                    localizer={localizer} 
                                                    id="big-calendar"
                                                    events={combinedEvents && combinedEvents.length > 0 ? combinedEvents : []}
                                                    // views={allViews}
                                                    // longPressThreshold={250}
                                                    step={30}
                                                    defaultDate={new Date()}
                                                    className="my-calendar"
                                                    components={components}
                                                    onSelectEvent={this.handleClickEvent}
                                                    onSelectSlot={this.handleClickSlot}
                                                    onNavigate={this.handleNavigate}
                                                    showMultiDayTimes={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </WidgetContainer>
                        </Grid>
                    </Grid>
                    <EventDialog openDialog={this.state.openDialog} handleCloseDialog={this.handleCloseDialog} isEdit={this.state.isEdit} />
                    <BookingDialog open={this.state.openBookingDialog} handleClose={this.handleCloseBookingDialog} hidepanel={true} />
                    <ActCalendarBookingOptionModal
                        open={this.state.openOptionModal}
                        onClose={this.handleCloseDialog}
                        privateEventHandler={this.handleClickPrivateEvent}
                        privateBookingHandler={this.handleClickPrivateBooking}
                        hidePrivateBooking={(slotInformation && moment(slotInformation.start).endOf("day") < moment().startOf("day")) || !slotInformation}
                        type={profileType}
                        handleClickVenueBooking={this.handleClickVenueBooking}
                    />
                </MainContainer>
            </main>
        );
    }
}
export default CalendarComponent;
