import { observable, action } from "mobx";
import agent from "../agent";

class ActTypeStore {
    @observable inProgress = false;
    @observable errors = undefined;

    @observable id = undefined;
    @observable name = "";
    @observable actTypes = [];

    @action
    setActTypes(actTypes) {
        this.actTypes = actTypes;
    }

    @action
    setId(id) {
        this.id = id;
    }

    @action
    setName(name) {
        this.name = name;
    }

    @action
    loadActTypes() {
        this.inProgress = true;

        return agent.ActType.getAll()
            .then(
                action(actTypes => {
                    if (actTypes) {
                        this.actTypes = actTypes;
                    }

                    return actTypes;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }
}

export default new ActTypeStore();
