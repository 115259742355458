import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../img/hotgiggity-logo.png";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import moment from "moment";
import Grid from '@material-ui/core/Grid';

import "./EmbedGigText.css";
import { ProfileType } from "../../types/enum";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const maxText = 500;
const blankBackground = "/hero2.jpg";

@inject("profileStore", "bookingRequestStore", "reportStore")
@withRouter
@observer
class EmbedText extends Component {
    state = {
        profile: null,
        finishLoading: false, 
        performData: null
    };

    constructor(props) {
        super(props);
        this.props.reportStore.clearDateRangeFilter();
    }

    componentDidMount() {
        this.loadProfile();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadProfile();
        }
    }

    loadProfile = () => {
        const param = this.props.match.params.id;

        this.props.reportStore.clearDateRangeFilter();
        // this.props.reportStore.setFilterType("week");
        this.props.profileStore.getPublicProfileById(param).then(result => {

            this.setState({
                ...this.state, 
                profile: result
            })
            
            this.props.bookingRequestStore.loadEntertainmentUpcomingGigsWithoutAdhocGigGuideEmbed(result.id).then((upcomings) => {
                this.setState({
                    upcomings: upcomings, 
                    finishLoading: true,
                    profile: result, 
                    performData: this.processUpcomingGigs(upcomings)
                });
            });
        });
    };

    processUpcomingGigs = upcomingGigs => {
        let result = [];
        if (upcomingGigs && upcomingGigs.length > 0)
        {
            upcomingGigs = upcomingGigs.sort((a,b) => (a.dateStart > b.dateStart) ? 1 : ((b.dateStart > a.dateStart) ? -1 : 0))
            for (let i = 0; i < upcomingGigs.length; i++) {
                let dateStart = moment(upcomingGigs[i].dateStart);

                let data = {
                    eventId: upcomingGigs[i].id, 
                    year: dateStart.format("YYYY"), 
                    month: dateStart.format("MMMM"),
                    date: dateStart.format("D"),
                    day: dateStart.format("dddd"),
                    actId: upcomingGigs[i].act?.id,
                    venueId: upcomingGigs[i].venue?.id,
                    time:
                        moment(upcomingGigs[i].timeStart, "HH:mm:ss.SSS").format("h.mma") +
                        " - " +
                        moment(upcomingGigs[i].timeEnd, "HH:mm:ss.SSS").format("h.mma"),
                    title: upcomingGigs[i].title ? upcomingGigs[i].title : upcomingGigs[i].act.actName + '@' + upcomingGigs[i].venueName, 
                    venueName: upcomingGigs[i].venueName,
                    venueAddress: upcomingGigs[i].venueAddress,
                    venueType: upcomingGigs[i].venue && upcomingGigs[i].venue.profileVenueTypes && upcomingGigs[i].venue.profileVenueTypes.map(profileVenueType => profileVenueType.venueType.name).join(', '), 
                    venueCover: upcomingGigs[i].venue && upcomingGigs[i].venue.backgroundImagesNew ? upcomingGigs[i].venue.backgroundImagesNew.url960 : BASE_URL + "hero2.jpg", 
                    venueCoverAlt: upcomingGigs[i].venue && upcomingGigs[i].venue.backgroundImages && upcomingGigs[i].venue.backgroundImages.length > 0 ? upcomingGigs[i].venue.backgroundImages[0].fileName : "Venue Cover", 
                    venueLatitude: upcomingGigs[i].venue && upcomingGigs[i].venue.latitude ? upcomingGigs[i].venue.latitude : 0, 
                    venueLongitude: upcomingGigs[i].venue && upcomingGigs[i].venue.longitude ? upcomingGigs[i].venue.longitude : 0, 
                    venueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio ? upcomingGigs[i].venue.bio : '', 
                    shortVenueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio ? this.truncateText(upcomingGigs[i].venue.bio) : '', 
                    expandableVenueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio && upcomingGigs[i].venue.bio.length > maxText ? true : false, 
                    expandVenueBio: false, 
                    actName: upcomingGigs[i].act.actName,
                    actGenres: upcomingGigs[i].act && upcomingGigs[i].act.profileGenres && upcomingGigs[i].act.profileGenres.map(profileGenre => profileGenre.genre.name).join(', '), 
                    actCover: upcomingGigs[i].act && upcomingGigs[i].act.backgroundImagesNew ? upcomingGigs[i].act.backgroundImagesNew.url960 : BASE_URL + "hero2.jpg", 
                    actCoverAlt: upcomingGigs[i].act && upcomingGigs[i].act.backgroundImages && upcomingGigs[i].act.backgroundImages.length > 0 ? upcomingGigs[i].act.backgroundImages[0].fileName : "Act Cover", 
                    actBio: upcomingGigs[i].act && upcomingGigs[i].act.bio ? upcomingGigs[i].act.bio : '',
                    shortActBio: upcomingGigs[i].act && upcomingGigs[i].act.bio ? this.truncateText(upcomingGigs[i].act.bio) : '', 
                    expandableActBio: upcomingGigs[i].act && upcomingGigs[i].act.bio && upcomingGigs[i].act.bio.length > maxText ? true : false, 
                    expandActBio: false
                };
                    
                result.push(data);
                
            }

        }

        return result
    };

    truncateText = text => {
        if(text.length > maxText) {
            return text.substring(0, maxText) + '...';
        }
        else {
            return text;
        }
    }

    manipulatePerformData = (index, type) => {
        const { performData } = this.state;
        let tempData = performData;

        if(type === 'act') {
            tempData[index].expandActBio = !tempData[index].expandActBio;
        }
        else {
            tempData[index].expandVenueBio = !tempData[index].expandVenueBio;
        }

        this.setState({
            ...this.state, 
            performData: tempData
        })
    }

    render() {
        const { profile, performData } = this.state;
        const queryString = this.props.location.search;
        const params = new URLSearchParams(queryString);
        // const headerBgColor = params.get("headerBgColor") ? "#" + params.get("headerBgColor") : "#262626";
        // const headerFtColor = params.get("headerFtColor") ? "#" + params.get("headerFtColor") : "#fff";
        // const dateBgColor = params.get("dateBgColor") ? "#" + params.get("dateBgColor") : "#ff0c9c";
        // const dateFtColor = params.get("dateFtColor") ? "#" + params.get("dateFtColor") : "#000000";
        const tableBgColor = params.get("tableBgColor") ? "#" + params.get("tableBgColor") : "#ffff";
        const tableFtColor = params.get("tableFtColor") ? "#" + params.get("tableFtColor") : "#000000";
        const hideToolbar = params.get("hideToolbar") || false;
        // const hideOrderUber = params.get("hideOrderUber") || false;
        // const hideShareButton = params.get("hideShareButton") || false;
        const toolbarState = JSON.parse(hideToolbar);
        let user = "";

        if (profile) {
            user =
                profile.typeString === "act"
                    ? profile.actName
                    : profile.typeString === "agent"
                        ? profile.agencyName || profile.profileName
                        : profile.typeString === "venue"
                            ? profile.profileName
                            : profile.firstName + " " + profile.lastName;
        }

        return (
            <Fragment>
                <Paper>
                    {!toolbarState && (


                        <Fragment>
                            <Hidden smUp>
                            <Toolbar className="header-embed">
                                    <Grid item xs={6}>

                                    <Typography variant="headline">{user + "'s Upcoming Gigs"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>

                                    <div style={{ textAlign: "right", float:"right" }}>
                                        <Typography variant="caption" style={{ float: "left", paddingTop: 14, paddingRight: 5 }}>
                                            Powered By
                                            <img style={{marginLeft:5}} src={Logo} alt="hotgiggity logo" width="30%" />
                                        </Typography>
                                    </div>
                                    </Grid>
                                </Toolbar>
                            </Hidden>
                            <Hidden xsDown>
                                <Toolbar className="header-embed">
                                    <Grid item xs={6}>

                                    <Typography variant="headline">{user + "'s Upcoming Gigs"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>

                                    <div style={{ textAlign: "right", float:"right" }}>
                                        <Typography variant="caption" style={{ float: "left", paddingTop: 14, paddingRight: 5 }}>
                                            Powered By
                                            <img style={{marginLeft:5}} src={Logo} alt="hotgiggity logo" width="30%" />
                                        </Typography>
                                    </div>
                                    </Grid>
                                </Toolbar>
                            </Hidden>
                        </Fragment>

                    )}
                    <div style={{padding:"24px"}} className={!toolbarState ? "gig-table-container" : ""}>
                        <Table className="preview-table">
                            {/* <TableHead>
                                <TableRow style={{ backgroundColor: headerBgColor }}>
                                    <TableCell className="gig-column">
                                        <Typography align="center" style={{ color: headerFtColor }} variant="subheading">
                                            Cover
                                        </Typography>
                                    </TableCell>
                                    {profile &&
                                        profile.typeString !== "act" && (
                                            <TableCell className="gig-column">
                                                <Typography align="center" style={{ color: headerFtColor }} variant="subheading">
                                                    Act
                                                </Typography>
                                            </TableCell>
                                        )}
                                    {profile &&
                                        profile.typeString !== "venue" && (
                                            <TableCell className="gig-column">
                                                <Typography align="center" style={{ color: headerFtColor }} variant="subheading">
                                                    Venue
                                                </Typography>
                                            </TableCell>
                                        )}
                                </TableRow>
                            </TableHead> */}
                            <TableHead>
                                <TableRow >
                                    <TableCell className="gig-column" style={{ width: '33.33%', backgroundColor: tableBgColor,textAlign:'left', fontSize:"1.25rem", fontWeight:700, color: "black",border:0 }}>Date</TableCell>
                                    <TableCell className="gig-column" style={{ width: '33.33%', backgroundColor: tableBgColor,textAlign:'left', fontSize:"1.25rem", fontWeight:700, color: "black",border:0  }}>Time</TableCell>
                                    <TableCell className="gig-column" style={{ width: '33.33%', backgroundColor: tableBgColor,textAlign:'left', fontSize:"1.25rem", fontWeight:700, color: "black",border:0  }}>{profile?.type === ProfileType.Musician.ordinal || profile?.type === ProfileType.Act.ordinal ? "Venue" : "Act"}</TableCell>
                                </TableRow>
                            </TableHead>
                            {/* <TableRow key={index} style={{ verticalAlign: 'top' }}>
                                <TableCell style={{ backgroundColor: tableBgColor,width:'33.33%', padding: 5 }} className="td-custom">
                                    <Typography variant="p" style={{ color: tableFtColor, fontSize: 10 }}>
                                        DD / Date
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ backgroundColor: tableBgColor,width:'33.33%', padding: 5 }} className="td-custom">
                                    <Typography variant="p" style={{ color: tableFtColor, fontSize: 10,textAlign:'center' }}>
                                        08:00pm-11:00pm
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ backgroundColor: tableBgColor,width:'33.33%', padding: 5 }} className="td-custom">
                                    <Typography variant="p" style={{ color: tableFtColor, fontSize: 10,textAlign:'center' }}>
                                        Awesome Act
                                    </Typography>
                                        
                                </TableCell>
                            </TableRow> */}
                            <TableBody>
                                {performData && performData.map((data, index) => {
                                      return (
                                        <TableRow key={index} style={{ verticalAlign: 'top' }}>
                                            <TableCell className="gig-column" style={{ width: '33.33%', backgroundColor: tableBgColor,textAlign:'left', fontSize:"1rem",border:0}}>
                                                <Typography variant="body1" style={{ color: tableFtColor}}>
                                                    {`${data.day}, ${data.date} ${data.month} ${data.year}`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="gig-column" style={{ width: '33.33%',backgroundColor: tableBgColor,textAlign:'left', fontSize:"1rem",border:0}}>
                                                <Typography variant="body1" style={{ color: tableFtColor}}>
                                                    {`${data.time}`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="gig-column" style={{ width: '33.33%',backgroundColor: tableBgColor,textAlign:'left', fontSize:"1rem",border:0 }}>
                                                <Typography variant="body1" style={{ color: tableFtColor}}>
                                                    <Typography variant="h5" gutterBottom style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/profile/${profile?.type === ProfileType.Musician.ordinal || profile?.type === ProfileType.Act.ordinal ?  data.venueId : data.actId}`)}>
                                                        {profile?.type === ProfileType.Musician.ordinal || profile?.type === ProfileType.Act.ordinal ? data.venueName : data.actName}
                                                    </Typography>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
                {toolbarState && (
                    <div className="embed-footer">
                        <span style={{ right: "-60px", position: "inherit", width: "10%", marginRight: 98 }}>
                            <Typography variant="caption" style={{ float: "left", paddingTop: 7, marginLeft: 45, paddingRight: 5 }}>
                                Powered By
                            </Typography>
                            <img alt="hotgiggity logo" src={Logo} width="40%" style={{ float: "right", marginLeft:5 }} />
                        </span>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default compose(withWidth())(EmbedText);
