import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Avatar, Card, IconButton, Badge, Tooltip, Button } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

import ReorderIcon from "@material-ui/icons/Reorder";
import DeleteIcon from "@material-ui/icons/Delete";
import "./rosterBigCard.css";

import blankProfile from "../../img/hotgig-loading.png";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
const blankBackground = "/hero2.jpg";
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';

// import classnames from 'classnames';

class BigRosterCard extends Component {
    render() {
        const backgroundImage = this.props.backgroundImage ? this.props.backgroundImage.url960 : blankBackground;
        const profileImage = this.props.profileImage ? this.props.profileImage.url200 : blankProfile;

        return (
            <Tooltip id="tooltip-top-end" title="Hold to reorder" placement="bottom">
                <Card classes={{ root: "roster-card-container" }}>
                    <div className="act-background-image" style={{ backgroundImage: "url(" + backgroundImage + ")" }}>
                        <Badge badgeContent={this.props.index} color="primary" className="roster-act-badge">
                            &nbsp;
                        </Badge>
                        <IconButton color="primary" className="roster-act-reorder">
                            <ReorderIcon />
                        </IconButton>
                        <Avatar className="act-avatar" src={profileImage} />
                    </div>
                    <CardContent className="roster-act-details">
                        <Grid container>
                            <Grid item xs={12} style={{ marginTop: 16, textAlign: "center", height: 32, overflow: "hidden" }}>
                                <Typography variant="headline" component="h2">
                                    {this.props.profileId ? (
                                        <Link className="act-profile-link" target={"_blank"} to={`/profile/${this.props.profileId}`}>
                                            {this.props.name}
                                        </Link>
                                    ) : (
                                        this.props.name
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 16, textAlign: "center" }}>
                                <Typography component="h5">{this.props.status}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 16, textAlign: "center" }}>
                                <Button variant="contained" size="small" color="primary" onClick={this.props.removeHandle}>
                                    <DeleteIcon />
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Tooltip>
        );
    }
}

export default BigRosterCard;
