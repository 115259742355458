import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import ModalContainer from "./ModalContainer";

class ConfirmationModalAccountPackage extends Component {
    render() {
        return (
            <ModalContainer
                open={this.props.open}
                title={this.props.title ? this.props.title : "Confirmation"}
                onClose={this.props.closeHandler}
                fullScreen={false}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "xs"}
            >
                <DialogContent
                // style={{ paddingTop: "16px" }}
                >
                    <DialogContentText><div dangerouslySetInnerHTML={{ __html: this.props.message }} /></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.declineHandler ? this.props.declineHandler : this.props.closeHandler}>
                        {this.props.declineLabel ? this.props.declineLabel : "Close"}
                    </Button>
                    <Button className="btn-confirmation" variant="contained" color="primary" autoFocus onClick={this.props.confirmationHandler}>
                        {this.props.confirmationLabel ? this.props.confirmationLabel : "Confirm"}
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default ConfirmationModalAccountPackage;
