import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import profileStore from "./profileStore";
import templateStore from "./templateStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";

class EmbedProfileStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable userId = 0;
    @observable type = 0;

    @observable embed = "";

    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        if (currentUser) {
            this.inProgress = true;
            templateStore.showLoadingScreen();
            if ((this.type === ProfileType.Act.ordinal || this.type === ProfileType.Venue.ordinal) && profileId) {
                return profileStore
                    .getProfile(profileId)
                    .then(
                        action(profile => {
                            if (!profile) throw new Error("Can't load profile");
                            this.embed = profile.embed;
                            this.id = profile.id;
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            }
            return profileStore
                .loadProfile(currentUser.sub, this.type)
                .then(
                    action(profile => {
                        if (!profile) {
                            this.reset();
                            throw new Error("Can't load profile");
                        }
                        this.embed = profile.embed;
                        this.id = profile.id;
                    })
                )
                .finally(
                    action(() => {
                        this.inProgress = false;
                        templateStore.hideLoadingScreen();
                    })
                );
        } else {
            return Promise.resolve();
        }
    }

    @action
    reset() {
        this.embed = "";
    }

    @action
    setEmbed(embed) {
        this.embed = embed;
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const profile = {
            id: this.id,
            userId: user.id,
            embed: this.embed,
            type: this.type
        };
        return (this.id ? this.updateProfile(profile) : this.createProfile(profile))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    createProfile(data) {
        //using general profile for create
        switch (this.type) {
            case 1:
                return agent.MusicianGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 2:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 3:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 6:
                return agent.ProductionEmbedProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 7:
                return agent.DjEmbedProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 4:
            default:
                return agent.ActGeneralProfile.create(data).then(profile => {
                    return profile;
                });
        }
    }

    @action
    updateProfile(data) {
        switch (this.type) {
            case 1:
                return agent.MusicianEmbedProfile.update(data).then(profile => {
                    return profile;
                });
            case 2:
                return agent.AgentEmbedProfile.update(data).then(profile => {
                    return profile;
                });
            case 3:
                return agent.VenueEmbedProfile.update(data).then(profile => {
                    return profile;
                });
            case 6:
                return agent.ProductionEmbedProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 7:
                return agent.DjEmbedProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 4:
            default:
                return agent.ActEmbedProfile.update(data).then(profile => {
                    return profile;
                });
        }
    }
}

export default new EmbedProfileStore();
