import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { BookingStatusType, ProfileType } from "../../../types/enum";

import { Grid, TextField } from "@material-ui/core";

import moment from "moment";

@inject("bookingRequestStore", "profileStore")
@observer
class BookingDressInput extends Component {
    handleDressChange = e => {
        this.props.bookingRequestStore.setBooking("dress", e.target.value);
    };

    render() {
        const { currentProfile } = this.props.profileStore;
        let { booking } = this.props.bookingRequestStore;
        let { dress, status, createdById, dateStart } = booking;
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;

        let confirmedEditable =
            status !== BookingStatusType.Cancelled.ordinal &&
            status !== BookingStatusType.Completed.ordinal &&
            moment(dateStart).endOf("day") >= moment() &&
            (!createdById || createdById === currentProfile?.id || isStaff);

        return (
            <Grid item xs={12} lg={6}>
                <TextField
                    label="Dress"
                    value={dress}
                    onChange={this.handleDressChange}
                    fullWidth
                    InputProps={{
                        readOnly: !confirmedEditable || this.props.readOnly
                    }}
                />
            </Grid>
        );
    }
}

export default BookingDressInput;
