import React, { Component, Fragment} from "react";
import { inject, observer } from "mobx-react";

import moment from "moment";

import { Grid, Avatar } from "@material-ui/core";

import PageNotFound from "../profile/PageNotFound";
// import classNames from "classnames";
// import goldFrame from "../../img/gold-frame.png";
import curve from "../../img/entertainment-curve.svg";
import hotGiggityLive from "../../img/Hot Giggity Live.png";
import image from "../../helper/image";

import "./Entertainment.css";

// const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

@inject("profileStore", "bookingRequestStore", "templateStore", "reportStore")
@observer
class EntertainmentNext4Event extends Component {
    state = {
        upcomings: [],
        profile: null,
        finishLoading: false,
        gigIndex: 0
    };

    // load profile initially
    componentDidMount = () => {
        this.loadProfile();
    };

    // check if we get an update, usually by choosing the musician profile in the user popup
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadProfile();
        }
    }

    ordinal = (number) => {
        switch (number) {
          case 1:
          case 21:
            return number + 'st';
            break;
          case 2:
          case 22:
            return number + 'nd';
            break;
          case 3:
          case 23:
            return number + 'rd';
            break;
          default:
            return number + 'th';
        }
      }

    loadProfile = () => {
        const param = this.props.match.params.id;
        Promise.all([
            this.props.profileStore.getPublicProfileById(param),
            this.props.bookingRequestStore.loadEntertainmentUpcomingNext4Event(param)]).then((response) => { 
            var upcomings = response[1]
            for (let i = 0; i < upcomings.length; i++) {
                // caches images, avoiding white flash between background replacements
                if (upcomings[i].act) {
                    new Image().src = image.getBackgroundImage(upcomings[i].act, false);
                }
            }
            this.props.templateStore.hideLoadingScreen();
            this.setState({
                upcomings: upcomings,
                profile: response[0],
                finishLoading: true
            });
        });
    };



    render() {
        let { upcomings } = this.state;
        let backgroundFirstImage = image.getBackgroundImage(upcomings[0]?.act, false)
        let backgroundSecondImage = image.getBackgroundImage(upcomings[1]?.act, false)
        let backgroundThirdImage = image.getBackgroundImage(upcomings[2]?.act, false)
        let backgroundFourthImage = image.getBackgroundImage(upcomings[3]?.act, false)
        return (
            <React.Fragment>
                {this.state.profile ? (
                    <div className="entertainment-page">
                        <Grid container className="entertainment-container">
                            <Grid item xs={12} className="entertainment-big-title-nextevent" >
                                <span style={{color:"white"}}>UP NEXT</span> LIVE
                            </Grid>
                            <Grid container style={{marginLeft:"20vh",marginRight:"20vh",marginTop:"-10vh"}}>
                                <Grid item md={6}xs={6}>
                                    <Grid container>
                                        <Grid xs={5}>
                                            <img src={backgroundFirstImage} className="image-next-4event" />
                                        </Grid>
                                        <Grid xs={7} style={{paddingLeft:20}}>
                                            <Grid item xs={12} className="entertainment-slide-information-name-next4event">
                                                {upcomings &&
                                                    upcomings[0] &&
                                                    upcomings[0].act &&
                                                    upcomings[0].act.actName}
                                            </Grid>
                                            <Grid item xs={12} className="entertainment-slide-information-day-next4event">
                                                {upcomings &&
                                                    upcomings[0] &&
                                                    upcomings[0].dateStart &&
                                                    `${upcomings[0].dateStart.format("ddd - ")
                                                    }${this.ordinal(upcomings[0].dateStart.format("D"))
                                                    } ${upcomings[0].dateStart.format("MMM")
                                                    }`}
                                            </Grid>
                                            <Grid item xs={12} className="entertainment-slide-information-time-next4event">
                                                {upcomings &&
                                                    upcomings[0] &&
                                                    upcomings[0].timeStart &&
                                                    upcomings[0].timeEnd &&
                                                    moment(upcomings[0].timeStart, "HH:mm:ss.SSS").format("h.mmA") +
                                                        " - " +
                                                        moment(upcomings[0].timeEnd, "HH:mm:ss.SSS").format("h.mmA")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid xs={5}>
                                            <img src={backgroundSecondImage} className="image-next-4event" />
                                        </Grid>
                                        <Grid xs={7} style={{paddingLeft:20}}>
                                            <Grid item xs={12} className="entertainment-slide-information-name-next4event">
                                                {upcomings &&
                                                    upcomings[1] &&
                                                    upcomings[1].act &&
                                                    upcomings[1].act.actName}
                                            </Grid>
                                            <Grid item xs={12} className="entertainment-slide-information-day-next4event">
                                                {upcomings &&
                                                    upcomings[1] &&
                                                    upcomings[1].dateStart &&
                                                    `${upcomings[1].dateStart.format("ddd - ")
                                                    }${this.ordinal(upcomings[1].dateStart.format("D"))
                                                    } ${upcomings[1].dateStart.format("MMM")
                                                    }`}
                                            </Grid>
                                            <Grid item xs={12} className="entertainment-slide-information-time-next4event">
                                                {upcomings &&
                                                    upcomings[1] &&
                                                    upcomings[1].timeStart &&
                                                    upcomings[1].timeEnd &&
                                                    moment(upcomings[1].timeStart, "HH:mm:ss.SSS").format("h.mmA") +
                                                        " - " +
                                                        moment(upcomings[1].timeEnd, "HH:mm:ss.SSS").format("h.mmA")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"-10vh"}}>
                                    <Grid container>
                                        <Grid xs={5}>
                                            <img src={backgroundThirdImage} className="image-next-4event" />
                                        </Grid>
                                        <Grid xs={7} style={{paddingLeft:20}}>
                                            <Grid item xs={12} className="entertainment-slide-information-name-next4event">
                                                {upcomings &&
                                                    upcomings[2] &&
                                                    upcomings[2].act &&
                                                    upcomings[2].act.actName}
                                            </Grid>
                                            <Grid item xs={12} className="entertainment-slide-information-day-next4event">
                                                {upcomings &&
                                                    upcomings[2] &&
                                                    upcomings[2].dateStart &&
                                                    `${upcomings[2].dateStart.format("ddd - ")
                                                    }${this.ordinal(upcomings[2].dateStart.format("D"))
                                                    } ${upcomings[2].dateStart.format("MMM")
                                                    }`}
                                            </Grid>
                                            <Grid item xs={12} className="entertainment-slide-information-time-next4event">
                                                {upcomings &&
                                                    upcomings[2] &&
                                                    upcomings[2].timeStart &&
                                                    upcomings[2].timeEnd &&
                                                    moment(upcomings[2].timeStart, "HH:mm:ss.SSS").format("h.mmA") +
                                                        " - " +
                                                        moment(upcomings[2].timeEnd, "HH:mm:ss.SSS").format("h.mmA")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"-10vh"}}>
                                    <Grid container>
                                        <Grid xs={5}>
                                            <img src={backgroundFourthImage} className="image-next-4event" />
                                        </Grid>
                                        <Grid xs={7} style={{paddingLeft:20}}>
                                            <Grid item xs={12} className="entertainment-slide-information-name-next4event">
                                                {upcomings &&
                                                    upcomings[3] &&
                                                    upcomings[3].act &&
                                                    upcomings[3].act.actName}
                                            </Grid>
                                            <Grid item xs={12} className="entertainment-slide-information-day-next4event">
                                                {upcomings &&
                                                    upcomings[3] &&
                                                    upcomings[3].dateStart &&
                                                    `${upcomings[3].dateStart.format("ddd - ")
                                                    }${this.ordinal(upcomings[3].dateStart.format("D"))
                                                    } ${upcomings[3].dateStart.format("MMM")
                                                    }`}
                                            </Grid>
                                            <Grid item xs={12} className="entertainment-slide-information-time-next4event">
                                                {upcomings &&
                                                    upcomings[3] &&
                                                    upcomings[3].timeStart &&
                                                    upcomings[3].timeEnd &&
                                                    moment(upcomings[3].timeStart, "HH:mm:ss.SSS").format("h.mmA") +
                                                        " - " +
                                                        moment(upcomings[3].timeEnd, "HH:mm:ss.SSS").format("h.mmA")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                           
                        </Grid>
                      

                        <div className="entertainment-follow-us-nextevent">
                            FOLLOW US ON <img src={hotGiggityLive} alt="hotgiggity live logo" />
                        </div>

                        {this.state.profile && (
                            <img src={image.getProfileImage(this.state.profile, true)} alt="curve" className="entertainment-venue-profile-image-nextevent" />
                        )}
                    </div>
                ) : this.state.finishLoading ? (
                    <PageNotFound />
                ) : null}
            </React.Fragment>

        );
    }
}
export default EntertainmentNext4Event;
