import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";

import {
    Toolbar,
    Typography,
    Tooltip,
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    DialogContentText,
    DialogActions
} from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";

import green from "@material-ui/core/colors/green";
import Slide from "@material-ui/core/Slide";

@inject("designProfileStore", "stateStore", "organisationStore", "templateStore")
@observer
class StateTableToolbar extends React.Component {
    state = {
        newName: "",
        newSuburb: "",
        newState: "",
        openAdd: false,
        openDelete: false,
        openDialog: false,
        success: false
    };

    lastData = [];

    handleNameChange = e => this.props.stateStore.setName(e.target.value);
    handleAddressChange = e => this.props.stateStore.setAddress(e.target.value);
    handleContactNumberChange = e => this.props.stateStore.setContactNumber(e.target.value.replace(/\D/,''));

    handleSubmitClick = () => {
        this.props.stateStore.create();
        this.handleCloseDialog();
        this.props.templateStore.closeOrganisationBlock();
    };

    handleDeleteClick = () => {
        this.props.selected.map(om => {
            return this.props.stateStore.removeState(om);
        });
        this.props.handleClearSelected();
        this.setState({ openDelete: true });

        return true;
    };

    handleSnackbarAddOpen = () => {
        this.setState({ openAdd: true });
    };

    handleSnackbarAddClose = (event, reason) => {
        this.setState({ openAdd: false });
        if (reason === "undo") {
            //execute undo add
            this.props.designProfileStore.deletePlayedAt(this.lastData);
        }
    };

    handleSnackbarDeleteOpen = () => {
        this.setState({ openDelete: true });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        this.setState({ openDelete: false });
        if (reason === "undo") {
            this.props.designProfileStore.addPlayedAt(this.lastData);
        }
    };

    handleOpenDialog = () => {
        this.props.stateStore.resetAddForm();
        this.setState({ openDialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    render() {
        const { classes, selected } = this.props;
        const { playedAtSuccess } = this.props.designProfileStore;
        const fullScreen = false;
        const { name, address, contactNumber } = this.props.stateStore;
        const { level1Label, level2Label, canManage } = this.props.organisationStore;
        let numSelected = selected.length;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0
                })}
            >
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography variant="subtitle1" style={{ color: "#FFF" }}>
                            {numSelected} selected
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1">{level2Label}</Typography>
                    )}
                </div>
                <div className={classes.spacer} />
                {canManage ? (
                    <div className={classes.actions}>
                        {numSelected > 0 ? (
                            <Tooltip title="Delete">
                                <Button aria-label="Delete" style={{ color: "#FFF" }} onClick={this.handleDeleteClick}>
                                    <DeleteIcon />
                                    <span className={classes.textSideIcon}>Delete </span>
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Add">
                                <Button
                                    id="add-state"
                                    aria-label="Add"
                                    onClick={this.handleOpenDialog}
                                    style={playedAtSuccess ? { color: green[800] } : null}
                                >
                                    {playedAtSuccess ? (
                                        <CheckIcon />
                                    ) : (
                                        <React.Fragment>
                                            <AddIcon style={{color:"white"}} />
                                            <span className={classes.textSideIcon} style={{color:"white"}}>Add</span>
                                        </React.Fragment>
                                    )}
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                ) : (
                    ""
                )}

                {canManage ? (
                    <Dialog fullScreen={fullScreen} open={this.state.openDialog} onClose={this.handleCloseDialog} transition={this.transition}>
                        <DialogTitle className="see-more-dialog-title">Add {level2Label}</DialogTitle>
                        <DialogContent className="see-more-dialog-content">
                            <div style={{ padding: 16 }}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12}>
                                        <DialogContentText className={classes.blackTextColor}>
                                            Add new {level2Label} to your {level1Label}
                                        </DialogContentText>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            id="state-name"
                                            autoFocus={true}
                                            placeholder={level2Label + " name"}
                                            label="Name"
                                            margin="none"
                                            fullWidth
                                            value={name}
                                            onChange={this.handleNameChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="state-contactNumber"
                                            placeholder="Phone or mobile number"
                                            label="Contact Number"
                                            margin="none"
                                            fullWidth
                                            value={contactNumber}
                                            onChange={this.handleContactNumberChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="state-address"
                                            placeholder="Address"
                                            label="Address"
                                            margin="none"
                                            fullWidth
                                            value={address}
                                            onChange={this.handleAddressChange}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="primary">
                                Close
                            </Button>
                            <Button onClick={this.handleSubmitClick} color="primary" id="state-submit">
                                Add {level2Label}
                            </Button>
                        </DialogActions>
                    </Dialog>
                ) : (
                    ""
                )}
            </Toolbar>
        );
    }
}

StateTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        backgroundColor: "#ff0c9c"
    },
    highlight: {
        backgroundColor: "#333333"
    },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
    blackTextColor: {
        color: "#000"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: 2,
        right: 2,
        zIndex: 1
    },
    textSideIcon: {
        paddingTop: 3
    }
});

export default withMobileDialog()(withStyles(toolbarStyles)(StateTableToolbar));
