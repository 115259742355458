import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button, Grid, Typography, Switch } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Loading from "../loadingSpinner/Loading";
import { withRouter } from "react-router-dom";
import { ProfileType } from "../../types/enum";
import HubTimepicker2 from "../timepicker/HubTimepicker2";
import moment from "moment";
import ConfirmationModal from "../modal/ConfirmationModal";


import SelectField from "../fields/SelectField";
import Countries from "../../lookup/Country";


const venueIncomingInvoice = "Use this section to manage your artist and agent incoming invoices batching. If your legal entity differs from your venue name, you can add that here.  All incoming invoices will be addressed to this legal entity."
const agentIncomingInvoice = "Use this section to manage your artist's incoming invoices batching. If your legal entity for incoming invoices is different from your agency name, you can set that here. This entity will be applied to all invoices coming from your artists."
const musicianIncomingInvoice = "Use this section to manage your player’s incoming invoices batching. If your legal entity for incoming invoices is different from your profile name, you can set that here. This entity will be applied to all invoices coming from your players."

const agencyOutgoingInvoice = "Use this section to manage your outgoing invoices to venues and clients. If your outgoing invoicing entity is different to your agency name, you can set that here.  This entity will be applied  to your outgoing invoices."
const musicianOutgoingInvoice = "Use this section to manage your outgoing invoices to venues and clients. If your outgoing invoicing entity is different to your agency name, you can set that here.  This entity will be applied  to your outgoing invoices."
@inject("financialProfileStore", "templateStore", "authStore", "profileStore", "organisationStore", "financialProfileStore")
@withRouter
@observer
class Financial extends React.Component {
    state = {
        isLoading: true,
        taxOptions: [],
        dialogAutoSendPopUp: false,
        dialogAutoSendOffPopUp: false
    };

    initMap(){
        const gmapScriptEl = document.createElement(`script`)
        gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB7f0cc3w1EyMBqq0kn_fIvf6XPKzi8VWE&libraries=places&callback=initMap`
        document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
        window.scrollTo(0, 0);
    }
    
    componentDidMount() {
        this.initMap()
        // set type
        this.props.financialProfileStore.setType(this.props.templateStore.getType(this.props.type));
        this.props.financialProfileStore
            .loadInitialData(this.props.profileId)
            .then(() => {
                if (this.props.financialProfileStore.currency === '') {
                    if (!this.props.organisationStore.id) {
                        this.props.organisationStore.loadOrganisations().then(res => {
                            if (res.length > 1) {
                                this.props.financialProfileStore.setCurrency(res[0].Currency)
                            } else {
                                this.props.financialProfileStore.setCurrency("AUD")
                            }
                        }) 
                    } else {
                        this.props.financialProfileStore.setCurrency(this.props.organisationStore.currency)
                    }
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
                window.scrollTo(0, 0);
            });
    }

    handleBankNameChange = e => this.props.financialProfileStore.setBankName(e.target.value);
    handleBsbNumberChange = e => this.props.financialProfileStore.setBsbNumber(e.target.value)
    handleAccountNumberChange = e => {
        this.props.financialProfileStore.setAccountNumber(e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.'))
    }
    handleAccountDescriptionChange = e => this.props.financialProfileStore.setAccountDescription(e.target.value);
    // handleAbnChange = e => {
    //     this.props.financialProfileStore.setAbn(e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.'))
    // }

    handleAbn1Change = e => {
        this.props.financialProfileStore.setAbn1(e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.'))
    }

    handleAbn2Change = e => {
        this.props.financialProfileStore.setAbn2(e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.'))
    }

    handleAbn3Change = e => {
        this.props.financialProfileStore.setAbn3(e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.'))
    }

    handleAbn4Change = e => {
        this.props.financialProfileStore.setAbn4(e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.'))
    }
    handleAbnChange = e => {
        this.props.financialProfileStore.setAbn(e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.'))
    }
    
    handleCurrencyChange = e => {
        this.props.financialProfileStore.setCurrency(e.target.value);
    };

    handleToggleIncomingAddress  = () => {
        this.props.financialProfileStore.setIncomingToggleAddress();
    };

    handleToggleOutgoingAddress = () => {
        this.props.financialProfileStore.setOutgoingToggleAddress();
    };

    handleAutoSendTimeChange = e => {
        this.props.financialProfileStore.setAutoSendTime(e);
    };
    handleAutoSendDay = e => {
        this.props.financialProfileStore.setAutoSendDay(e.target.value);
    };
    handleAutoSendChange = e => {
        this.props.financialProfileStore.setAutoSend();
        this.setState({ dialogAutoSendPopUp: false, dialogAutoSendOffPopUp: false });
    };

    
    handleStreetChangeIncoming = e => this.props.financialProfileStore.setStreetIncoming(e.target.value);
    handleCityChangeIncoming = e => this.props.financialProfileStore.setCityIncoming(e.target.value);
    handlePostalChangeIncoming = e => this.props.financialProfileStore.setPostalIncoming(e.target.value);
    handleStateChangeIncoming = e => this.props.financialProfileStore.setStateIncoming(e.target.value);
    handleCountryChangeIncoming = value => this.props.financialProfileStore.setCountryCodeIncoming(value);
    handleChangeLongitudeLatitudeIncoming = value => {
        this.props.financialProfileStore.setLongitudeLatitudeIncoming(value);
    };
    handleChangeSearchAddressIncoming = value => {
        this.props.financialProfileStore.setSearchedAddressIncoming(value);
    };

    handleStreetChangeOutgoing = e => this.props.financialProfileStore.setStreetOutgoing(e.target.value);
    handleCityChangeOutgoing = e => this.props.financialProfileStore.setCityOutgoing(e.target.value);
    handlePostalChangeOutgoing = e => this.props.financialProfileStore.setPostalOutgoing(e.target.value);
    handleStateChangeOutgoing = e => this.props.financialProfileStore.setStateOutgoing(e.target.value);
    handleCountryChangeOutgoing = value => this.props.financialProfileStore.setCountryCodeOutgoing(value);
    handleChangeLongitudeLatitudeOutgoing = value => {
        this.props.financialProfileStore.setLongitudeLatitudeOutgoing(value);
    };
    handleChangeSearchAddressOutgoing = value => {
        this.props.financialProfileStore.setSearchedAddressOutgoing(value);
    };

    openDialogAutoSendPopUp = () => {
        const {autoSend} = this.props.financialProfileStore
        if(autoSend){
            this.setState({ dialogAutoSendOffPopUp: true });
        }else{
            this.handleAutoSendChange()
        }
        
        
    }

    closeDialogAutoSendPopUp = () => {
        this.setState({ dialogAutoSendPopUp: false });
    }

    closeDialogAutoSendOffPopUp = () => {
        this.setState({ dialogAutoSendOffPopUp: false });
    }

    handleTaxChange = e => {
        this.props.financialProfileStore.setTax(e.target.value);

        switch (e.target.value) {
            case "GST":
                this.props.financialProfileStore.setTaxValue(10);
                break;
            case "GSTNZ":
                this.props.financialProfileStore.setTaxValue(15);
                break;
            case "VAT":
                this.props.financialProfileStore.setTaxValue(20);
                break;
            case "NOT":
                this.props.financialProfileStore.setTaxValue(0);
                break;
            default:
                break;
        }
    };
    getBsbLabel = () => {
        const {currency} = this.props.financialProfileStore;
        var label = "BSB"

        if(currency === "GBP"){
            label = "Sort Code"
        }else if(currency === "NZD"){
            label = "Bank Code"
        }else if(currency === "USD"){
            label = "Routing Number"}
        return label
    }
    getABNLabel = () => {
        const {currency} = this.props.financialProfileStore;
        var label = "ABN"

        if(currency === "GBP"){
            label = "VRN"
        }else if(currency === "NZD"){
            label = "NZBN"
        }else if(currency === "USD"){
            label = "EIN"}
        return label
    }
    getTaxOptions = () => {
        const {currency} = this.props.financialProfileStore;
        var taxOptions = []
        if(currency === "AUD"){
            taxOptions = [{value:"GST",label:"GST (10%)"}, {value:"NOT",label:"Not Applicable (0%)"}]
            
        }else if(currency === "NZD"){
            taxOptions = [{value:"GSTNZ",label:"GST-NZ (15%)"}, {value:"NOT",label:"Not Applicable (0%)"}]
        }else if(currency === "GBP"){
            taxOptions = [{value:"VAT",label:"VAT (20%)"}, {value:"NOT",label:"Not Applicable (0%)"}]

        }else if(currency === "USD"){
            taxOptions = [{value:"CUSTOM",label:"Custom Tax Percentage"}, {value:"NOT",label:"Not Applicable (0%)"}]

        }else{
            taxOptions = [{value:"GST",label:"GST (10%)"},{value:"GSTNZ",label:"GST-NZ (15%)"}, {value:"VAT",label:"VAT (20%)"},{value:"CUSTOM",label:"Custom Tax Percentage"}, {value:"NOT",label:"Not Applicable (0%)"}]
        }
        return taxOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
                {item.label}
            </MenuItem>
        ));
        
    }
    handleTaxValueChange = e => this.props.financialProfileStore.setTaxValue(e.target.value);
    handlePaymentTermsChange = e => this.props.financialProfileStore.setPaymentTerms(e.target.value);
    handleOutgoingPaymentTermsChange = e => this.props.financialProfileStore.setOutgoingPaymentTerms(e.target.value);
    handlePaymentOptionsChange = e => this.props.financialProfileStore.setPaymentOptions(e.target.value);
    handlePushNotificationChange = e => this.props.financialProfileStore.setPushNotification(e.target.checked);
    handleEmailNotificationChange = e => this.props.financialProfileStore.setEmailNotification(e.target.checked);

    setIncomingInvoiceAddress = e => this.props.financialProfileStore.setIncomingInvoiceAddress(e.target.value);
    setIncomingInvoiceEmail = e => this.props.financialProfileStore.setIncomingInvoiceEmail(e.target.value);
    setIncomingEntityName = e => this.props.financialProfileStore.setIncomingEntityName(e.target.value);
    setOutgoingEntityName = e => this.props.financialProfileStore.setOutgoingEntityName(e.target.value);
    setOutgoingInvoiceEmail = e => this.props.financialProfileStore.setOutgoingInvoiceEmail(e.target.value);
    setOutgoingInvoiceAddress = e => this.props.financialProfileStore.setOutgoingInvoiceAddress(e.target.value);

    handleNextInvoiceNumberChange = e => this.props.financialProfileStore.setNextInvoiceNumber(e.target.value);
    handleMonthlyBudgetChange = e => this.props.financialProfileStore.setMonthlyBudget(e.target.value);
    handleCCInvoiceEmailChange = e => this.props.financialProfileStore.setcCInvoiceEmailAddress(e.target.value);
    validateCCInvoiceEmail = e => this.props.financialProfileStore.validateCCInvoiceEmail(e.target.value);

    // handleInsuranceNameChange = e => this.props.financialProfileStore.setInsuranceName(e.target.value);
    // handleInsuranceCompanyChange = e => this.props.financialProfileStore.setInsuranceCompany(e.target.value);
    // handlePolicyNumberChange = e => this.props.financialProfileStore.setPolicyNumber(e.target.value);
    // handleDateStartChange= e => this.props.financialProfileStore.setDateStart(e.target.value);

    handlemonthlyBudgetTypeChange = e => {
        this.props.financialProfileStore.setMonthlyBudgetType(e.target.checked);
    };

    handleEachMonthChange = e => {
        this.props.financialProfileStore.setEachMonthlyBudgetType(e.target.id, e.target.value);
    };


    handleBack = () => {
        if (this.props.type === "venue") {
            const { profile } = this.props.profileStore;
            this.props.history.push(`/venues/${profile.id}/edit/design`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        if(this.props.financialProfileStore.errors !== undefined) return
        this.setState({ isLoading: true });
        const { profile } = this.props.profileStore;
        this.props.financialProfileStore.submit().then(async resp => {
            if (this.props.type === "venue") {
                this.props.history.push(`/venues/${resp.id}/edit/social`);
            }
            await this.props.profileStore.loadProfiles().then(() => {
                const { editProfileActiveStep } = this.props.templateStore;
                this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
            })
           
        }).catch(err => {
            this.setState({ isLoading: false });
            this.props.templateStore.openSnackbar(err);
        });
    };

    anyError(field) {
        if (!this.props.financialProfileStore.errors) return false;
        if (!this.props.financialProfileStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.financialProfileStore.errors) return null;
        if (!this.props.financialProfileStore.errors[field]) return null;
        return this.props.financialProfileStore.errors[field];
    }

   

    render() {
        const {
            bankName,
            bsbNumber,
            accountNumber,
            accountDescription,
            abn,
            abn1,
            abn2,
            abn3,
            abn4,
            currency,
            tax,
            taxValue,
            monthlyBudget,
            paymentTerms,
            outgoingPaymentTerms,
            monthlyBudgetType,
            paymentOptions,
            nextInvoiceNumber,
            cCInvoiceEmailAddress,
            januaryBudget,
            februaryBudget,
            marchBudget,
            aprilBudget,
            mayBudget,
            juneBudget,
            julyBudget,
            augustBudget,
            septemberBudget,
            octoberBudget,
            novemberBudget,
            decemberBudget,
            autoSend, 
            autoSendDay, 
            autoSendTime,
            incomingToggleAddress,
            outgoingToggleAddress,
            searchedAddressIncoming, 
            incomingInvoiceEmail, 
            incomingEntityName, 
            outgoingEntityName,
            outgoingInvoiceEmail,
            searchedAddressOutgoing,
            streetIncoming,
            cityIncoming,
            postalIncoming,
            stateIncoming,
            countryCodeIncoming,
            longitudeIncoming,
            latitudeIncoming,
            streetOutgoing,
            cityOutgoing,
            postalOutgoing,
            stateOutgoing,
            countryCodeOutgoing,
            longitudeOutgoing,
            latitudeOutgoing
            // insuranceName,
            // insuranceCompany,
            // policyNumber,
            // insuranceExpiryDate
        } = this.props.financialProfileStore;
        const { editProfileActiveStep } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        const { isLoading } = this.state;
        const { currentProfile, profile } = this.props.profileStore;
        const isStaff = currentProfile && currentProfile.type === ProfileType.Staff.ordinal;
        const isMusician = currentProfile && currentProfile.type === ProfileType.Musician.ordinal;
        const isAgent = currentProfile && currentProfile.type === ProfileType.Agent.ordinal;
        
        let countriesSuggestion = () => {
            return Countries.map(country => {
                return {
                    value: country.code,
                    label: country.name,
                    divider: country.divider
                };
            });
        };
        return (
            <div className="Profile-Financial">
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Financial
                                </Typography>
                                {type === "venue" ? (
                                    <Fragment>
                                        <Typography gutterBottom className="side-helper-text">
                                            In this section you are able to choose how your invoices coming from musicians, acts and agents will be
                                            batched. For example, if you choose 14 day batching, all the gigs that have been booked and performed
                                            within the 14 day period will arrive on the same day at the end of the batching cycle.
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            If the same act has performed more than once within that batching period, their gigs will appear in line
                                            format with all details on just 1 invoice. Hit the drop down and choose your batching preference.
                                        </Typography>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Typography gutterBottom className="side-helper-text">
                                            This information will be used to create invoices within Hot Giggity. We will never sell, rent, or share your
                                            personal information with any third parties.
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            If you are migrating from another invoicing program, set the 'Next invoice number' to follow on from the
                                            last invoice in your previous program.
                                        </Typography>
                                    </Fragment>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="currency"
                                        label="Currency"
                                        select
                                        margin="dense"
                                        fullWidth
                                        value={currency}
                                        onChange={this.handleCurrencyChange}
                                        error={this.anyError("currency")}
                                        helperText={this.errorText("currency")}
                                    >
                                        <MenuItem value="AUD">AUD</MenuItem>
                                        <MenuItem value="USD">USD</MenuItem>
                                        <MenuItem value="GBP">GBP</MenuItem>
                                        <MenuItem value="NZD">NZD</MenuItem>
                                    </TextField>
                                </Grid>
                              
                            <Grid item xs={12} md={6}>
                            {type === "venue" && (
                                        <TextField
                                            id="cCInvoiceEmailAddress"
                                            label="Accounts Email Address"
                                            margin="dense"
                                            fullWidth
                                            value={cCInvoiceEmailAddress}
                                            onChange={this.handleCCInvoiceEmailChange}
                                            onBlur={this.validateCCInvoiceEmail}
                                            error={this.anyError("cCInvoiceEmailAddress")}
                                            helperText={this.errorText("cCInvoiceEmailAddress")}
                                        />)}
                               
                                    {type !== 'venue' && 
                                        <TextField
                                            id="tax"
                                            label="Tax"
                                            select
                                            margin="dense"
                                            fullWidth
                                            value={tax}
                                            onChange={this.handleTaxChange}
                                            error={this.anyError("tax")}
                                            helperText={this.errorText("tax")}
                                        >
                                            {this.getTaxOptions()}
                                            {/* <MenuItem value="GST">GST (10%)</MenuItem>
                                            <MenuItem value="GSTNZ">GST-NZ (15%)</MenuItem>
                                            <MenuItem value="VAT">VAT (20%)</MenuItem>
                                            <MenuItem value="NOT">Not Applicable (0%)</MenuItem>
                                            <MenuItem value="CUSTOM">Custom Tax Percentage</MenuItem> */}
                                        </TextField>
                                }
                            
                            </Grid>
                            <Grid container spacing={8}>
                            </Grid>
                                   {type !== 'venue' && 
                                    tax === "CUSTOM" && (
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="taxValue"
                                                label="Tax Value (%)"
                                                margin="dense"
                                                fullWidth
                                                value={Number(taxValue)}
                                                onChange={this.handleTaxValueChange}
                                                error={this.anyError("taxValue")}
                                                helperText={this.errorText("taxValue")}
                                                type="number"
                                            />
                                        </Grid>
                                    )
                                }
                            
                             {  currency === "AUD" && type !== 'venue' ?
                                <Fragment>
                                    <Grid item xs={3} md={1}>
                                        <TextField
                                            id="abn"
                                            label={this.getABNLabel()}
                                            margin="dense"
                                            fullWidth
                                            value={abn1}
                                            onChange={this.handleAbn1Change}
                                            error={this.anyError("abn")}
                                            helperText={this.errorText("abn")}
                                            type="text"
                                            inputProps={{ maxLength: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={1}>
                                        <TextField
                                            id="abn"
                                            label=" "
                                            margin="dense"
                                            fullWidth
                                            value={abn2}
                                            onChange={this.handleAbn2Change}
                                            error={this.anyError("abn")}
                                            helperText={this.errorText("abn")}
                                            type="text"
                                            inputProps={{ maxLength: 3 }}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={1}>
                                        <TextField
                                            id="abn"
                                            label=" "
                                            margin="dense"
                                            fullWidth
                                            value={abn3}
                                            onChange={this.handleAbn3Change}
                                            error={this.anyError("abn")}
                                            helperText={this.errorText("abn")}
                                            type="text"
                                            inputProps={{ maxLength: 3 }}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={1}>
                                        <TextField
                                            id="abn"
                                            label=" "
                                            margin="dense"
                                            fullWidth
                                            value={abn4}
                                            onChange={this.handleAbn4Change}
                                            error={this.anyError("abn")}
                                            helperText={this.errorText("abn")}
                                            type="text"
                                            inputProps={{ maxLength: 3 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        
                                    </Grid>
                                </Fragment> :
                                null
                            }
                             {  currency != "AUD" && type !== 'venue' ?
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="abn"
                                        label={this.getABNLabel()}
                                        margin="dense"
                                        fullWidth
                                        value={abn}
                                        onChange={this.handleAbnChange}
                                        error={this.anyError("abn")}
                                        helperText={this.errorText("abn")}
                                        type="text"
                                    />
                                </Grid> : null
                                
                            }
                            </Grid>
                            <Grid container spacing={8}>
                                {type !== 'venue' && 
                                <Fragment>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="bankName"
                                            label="Bank Account Name"
                                            margin="dense"
                                            fullWidth
                                            value={bankName}
                                            onChange={this.handleBankNameChange}
                                            error={this.anyError("bankName")}
                                            helperText={this.errorText("bankName")}
                                        />
                                    </Grid>
    
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="bsbNumber"
                                            label={this.getBsbLabel()}
                                            margin="dense"
                                            fullWidth
                                            value={bsbNumber}
                                            onChange={this.handleBsbNumberChange}
                                            error={this.anyError("bsbNumber")}
                                            helperText={this.errorText("bsbNumber")}
                                            type="text"
                                        />
                                    </Grid>
    
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="accountNumber"
                                            label="Account Number"
                                            margin="dense"
                                            fullWidth
                                            value={accountNumber}
                                            onChange={this.handleAccountNumberChange}
                                            error={this.anyError("accountNumber")}
                                            helperText={this.errorText("accountNumber")}
                                            type="text" 
                                        />
                                    </Grid>
    
                                    {/* <Grid item xs={12}>
                                        <TextField
                                            id="accountDescription"
                                            label="Description"
                                            margin="dense"
                                            fullWidth
                                            value={accountDescription}
                                            onChange={this.handleAccountDescriptionChange}
                                            error={this.anyError("accountDescription")}
                                            helperText={this.errorText("accountDescription")}
                                        />
                                    </Grid> */}
                                   
                                    
                                </Fragment>
                                }

             

                               
                               { type === 'venue' &&
                                    <Fragment>
                                        <Grid item xs={12} md={12}>
                                            <Grid item xs={12} md={5}>
                                                <Switch checked={autoSend} onChange={this.openDialogAutoSendPopUp} value="hideToolbar" /> Automatically send to accounts
                                            </Grid>
                                        </Grid>
                                        {
                                            autoSend ?
                                            <Fragment>
                                                <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="autoSendDay"
                                                    label="Auto Send Day"
                                                    select
                                                    margin="dense"
                                                    fullWidth
                                                    value={autoSendDay}
                                                    onChange={this.handleAutoSendDay}
                                                    error={this.anyError("autoSendDay")}
                                                    helperText={this.errorText("autoSendDay") || "Last week's invoices (Mon-Sun) will be sent to accounts on this day."}
                                                >
                                                    <MenuItem value="Monday">Monday</MenuItem>
                                                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                    <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                    <MenuItem value="Thursday">Thursday</MenuItem>
                                                    <MenuItem value="Friday">Friday</MenuItem>
                                                    <MenuItem value="Saturday">Saturday</MenuItem>
                                                    <MenuItem value="Sunday">Sunday</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <div style={{paddingTop:8}}>
                                                <HubTimepicker2
                                                    margin="dense"
                                                    label="Auto Send Time"
                                                    value={moment(autoSendTime, "HH:mm")}
                                                    onChange={this.handleAutoSendTimeChange}
                                                    error={this.errorText["autoSendTime"]}
                                                    helperText={this.errorText["autoSendTime"]}
                                                />
                                                </div>
                                            </Grid>
                                            </Fragment> : null
                                        }
                                    </Fragment>
                               }
                               
                                
                              

                              

                               
                                
                                
                               

                                {isStaff && !monthlyBudgetType && type === 'venue' && (
                                    <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="monthlyBudget"
                                            label="Monthly Budget"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={monthlyBudget}
                                            onChange={this.handleMonthlyBudgetChange}
                                            error={this.anyError("monthlyBudget")}
                                            helperText={this.errorText("monthlyBudget")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div style={{ paddingTop: 20 }} >
                                            <Switch
                                                checked={monthlyBudgetType}
                                                onChange={this.handlemonthlyBudgetTypeChange}
                                                value="monthlyBudgetType"
                                                color="primary"
                                                id="BudgetType"
                                                className="BudgetType"
                                            />
                                            Set Your Budget For Each Month
                                        </div>
                                    </Grid>
                                    </Grid>
                                    
                                )}

                                
                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} md={12}>
                                        <div>
                                            <Switch
                                                checked={monthlyBudgetType}
                                                onChange={this.handlemonthlyBudgetTypeChange}
                                                value="monthlyBudgetType"
                                                color="primary"
                                                id="BudgetType"
                                                className="BudgetType"
                                            />
                                            Set Your Budget For Each Month
                                        </div>
                                    </Grid>
                                )}
                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="January"
                                            label="January"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={januaryBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("januaryBudget")}
                                            helperText={this.errorText("januaryBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="February"
                                            label="February"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={februaryBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("februaryBudget")}
                                            helperText={this.errorText("februaryBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="March"
                                            label="March"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={marchBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("marchBudget")}
                                            helperText={this.errorText("marchBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="April"
                                            label="April"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={aprilBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("aprilBudget")}
                                            helperText={this.errorText("aprilBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="May"
                                            label="May"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={mayBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("mayBudget")}
                                            helperText={this.errorText("mayBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="June"
                                            label="June"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={juneBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("juneBudget")}
                                            helperText={this.errorText("juneBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="July"
                                            label="July"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={julyBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("julyBudget")}
                                            helperText={this.errorText("julyBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="August"
                                            label="August"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={augustBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("augustBudget")}
                                            helperText={this.errorText("augustBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="September"
                                            label="September"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={septemberBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("septemberBudget")}
                                            helperText={this.errorText("septemberBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="October"
                                            label="October"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={octoberBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("octoberBudget")}
                                            helperText={this.errorText("octoberBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="November"
                                            label="November"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={novemberBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("novemberBudget")}
                                            helperText={this.errorText("novemberBudget")}
                                        />
                                    </Grid>
                                )}

                                {isStaff && monthlyBudgetType && (
                                    <Grid item xs={12} lg={2} md={4}>
                                        <TextField
                                            id="December"
                                            label="December"
                                            margin="dense"
                                            fullWidth
                                            type="number"
                                            value={decemberBudget}
                                            onChange={this.handleEachMonthChange}
                                            error={this.anyError("decemberBudget")}
                                            helperText={this.errorText("decemberBudget")}
                                        />
                                    </Grid>
                                )}

                                
                               
                            </Grid>
                        </Grid>

                        {
                            type != "venue" &&
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Outgoing Invoices
                                        </Typography>
                                            <Fragment>
                                                <Typography gutterBottom className="side-helper-text">
                                                    {type === "musician" ? musicianOutgoingInvoice : agencyOutgoingInvoice}
                                                </Typography>
                                            </Fragment>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                {type !== "venue" && (
                                    <Grid container spacing={8}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="outgoingPaymentTerms"
                                            label="Outgoing Invoices Payment Term"
                                            select
                                            margin="dense"
                                            fullWidth
                                            value={outgoingPaymentTerms}
                                            onChange={this.handleOutgoingPaymentTermsChange}
                                            error={this.anyError("outgoingPaymentTerms")}
                                            helperText={this.errorText("outgoingPaymentTerms") || "Outgoing invoices due date"}
                                        >
                                             <MenuItem value="0">Due on receipt</MenuItem>
                                             <MenuItem value="7">7 days</MenuItem>
                                             <MenuItem value="14">14 days</MenuItem>
                                             <MenuItem value="30">30 days</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                            <TextField
                                                id="paymentOptions"
                                                label="Payment Options"
                                                select
                                                margin="dense"
                                                fullWidth
                                                value={paymentOptions}
                                                onChange={this.handlePaymentOptionsChange}
                                                error={this.anyError("paymentOptions")}
                                                helperText={this.errorText("paymentOptions")}
                                            >
                                                <MenuItem value="Direct Deposit">Direct Deposit</MenuItem>
                                            </TextField>
                                        </Grid>
        
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="nextInvoiceNumber"
                                                label="Next Invoice Number"
                                                margin="dense"
                                                fullWidth
                                                value={nextInvoiceNumber}
                                                onChange={this.handleNextInvoiceNumberChange}
                                                error={this.anyError("nextInvoiceNumber")}
                                                helperText={this.errorText("nextInvoiceNumber")}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                { (isAgent || isMusician) ?
                                    <Grid item xs={12} md={12}>
                                        <Grid item xs={12} md={5}>
                                            <Switch checked={outgoingToggleAddress} onChange={this.handleToggleOutgoingAddress} value="hideToolbar" /> Set legal entity for outgoing invoices
                                        </Grid>
                                    </Grid> : null
                                }

                                {
                                    outgoingToggleAddress ? 
                                    <Grid xs={12} md={12}>
                                         <Grid container spacing={8}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="legalEntityNameOutgoing"
                                                label="Legal Entity Name"
                                                margin="dense"
                                                fullWidth
                                                value={outgoingEntityName}
                                                onChange={this.setOutgoingEntityName}
                                                error={this.anyError("legalEntityNameOutgoing")}
                                                helperText={this.errorText("legalEntityNameOutgoing")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="emailOutgoingEmail"
                                                label="Email"
                                                margin="dense"
                                                fullWidth
                                                value={outgoingInvoiceEmail}
                                                onChange={this.setOutgoingInvoiceEmail}
                                                error={this.anyError("emailOutgoingEmail")}
                                                helperText={this.errorText("emailOutgoingEmail")}
                                            />
                                        </Grid>
                                        </Grid>
                                        <hr style={{ marginTop: 0 }} />
                                        <Grid container spacing={8}>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    id="streetOutgoing"
                                                    label="Street Address"
                                                    margin="dense"
                                                    fullWidth
                                                    value={streetOutgoing}
                                                    onChange={this.handleStreetChangeOutgoing}
                                                    error={this.anyError("streetOutgoing")}
                                                    helperText={this.errorText("streetOutgoing")}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    id="cityOutgoing"
                                                    label="Suburb"
                                                    margin="dense"
                                                    fullWidth
                                                    value={cityOutgoing}
                                                    onChange={this.handleCityChangeOutgoing}
                                                    error={this.anyError("cityOutgoing")}
                                                    helperText={this.errorText("cityOutgoing")}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    id="stateOutgoing"
                                                    label="State"
                                                    margin="dense"
                                                    fullWidth
                                                    value={stateOutgoing}
                                                    onChange={this.handleStateChangeOutgoing}
                                                    error={this.anyError("stateOutgoing")}
                                                    helperText={this.errorText("stateOutgoing")}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    id="postalOutgoing"
                                                    label="Postal/Zip Code"
                                                    margin="dense"
                                                    fullWidth
                                                    value={postalOutgoing}
                                                    onChange={this.handlePostalChangeOutgoing}
                                                    error={this.anyError("postalOutgoing")}
                                                    helperText={this.errorText("postalOutgoing")}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={4} className="select-field-country">
                                                <SelectField
                                                    id="countriesOutgoing"
                                                    multi={false}
                                                    suggestions={countriesSuggestion()}
                                                    onChange={this.handleCountryChangeOutgoing}
                                                    value={countryCodeOutgoing}
                                                    placeholder=" "
                                                    label="Country"
                                                    margin="dense"
                                                    error={this.anyError("countriesOutgoing")}
                                                    helperText={this.errorText("countriesOutgoing")}
                                                />
                                            </Grid>

                                           
                                            </Grid>
                                        
                                    </Grid>
                                     : null
                                }
                                </Grid>
                            </Fragment>
                        }
                     

                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Incoming Invoices
                                </Typography>
                                <Fragment>
                                    <Typography gutterBottom className="side-helper-text">
                                        {type === "venue" ? venueIncomingInvoice : type === "musician" ? musicianIncomingInvoice : agentIncomingInvoice}
                                    </Typography>
                                </Fragment>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            {type !== "venue" && (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="paymentTerms"
                                        label="Incoming Invoices Batching"
                                        select
                                        margin="dense"
                                        fullWidth
                                        value={paymentTerms}
                                        onChange={this.handlePaymentTermsChange}
                                        error={this.anyError("paymentTerms")}
                                        helperText={this.errorText("paymentTerms") || "Incoming Invoices will be batched by this option"}
                                    >
                                        <MenuItem value="0">One invoice per gig</MenuItem>
                                        <MenuItem value="7">Weekly</MenuItem>
                                        <MenuItem value="14">Fortnightly</MenuItem>
                                        <MenuItem value="30">Monthly</MenuItem>
                                    </TextField>
                                </Grid>)
                                }
                                {type === "venue" && (
                                <Grid container spacing={8}> 
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="paymentTerms"
                                        label="Incoming Invoices Batching"
                                        select
                                        margin="dense"
                                        fullWidth
                                        value={paymentTerms}
                                        onChange={this.handlePaymentTermsChange}
                                        error={this.anyError("paymentTerms")}
                                        helperText={this.errorText("paymentTerms") || "Incoming Invoices will be batched by this option"}
                                    >
                                        <MenuItem value="0">One invoice per gig</MenuItem>
                                        <MenuItem value="7">Weekly</MenuItem>
                                        <MenuItem value="14">Fortnightly</MenuItem>
                                        <MenuItem value="30">Monthly</MenuItem>
                                    </TextField>
                                    </Grid>
                                </Grid>
                                )}
                                
                                <Grid item xs={12} md={12}>
                                    <Grid item xs={12} md={5}>
                                        <Switch checked={incomingToggleAddress} onChange={this.handleToggleIncomingAddress} value="hideToolbar" /> Set legal entity for incoming invoices
                                    </Grid>
                                </Grid>

                                {
                                    incomingToggleAddress ? 
                                    <Grid xs={12} md={12}>
                                        <Grid container spacing={8}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="legalEntityNameIncoming"
                                                label="Legal Entity Name"
                                                margin="dense"
                                                fullWidth
                                                value={incomingEntityName}
                                                onChange={this.setIncomingEntityName}
                                                error={this.anyError("legalEntityNameIncoming")}
                                                helperText={this.errorText("legalEntityNameIncoming")}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="emailInvoicesOutgoing"
                                                label="Email"
                                                margin="dense"
                                                fullWidth
                                                value={incomingInvoiceEmail}
                                                onChange={this.setIncomingInvoiceEmail}
                                                error={this.anyError("street")}
                                                helperText={this.errorText("street")}
                                            />
                                        </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                        <hr style={{ marginTop: 0 }} />
                                        <Grid container spacing={8}>
                                            <Grid item xs={12} lg={8}>
                                                <TextField
                                                    id="streetIncoming"
                                                    label="Street Address"
                                                    margin="dense"
                                                    fullWidth
                                                    value={streetIncoming}
                                                    onChange={this.handleStreetChangeIncoming}
                                                    error={this.anyError("streetIncoming")}
                                                    helperText={this.errorText("streetIncoming")}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    id="cityIncoming"
                                                    label="Suburb"
                                                    margin="dense"
                                                    fullWidth
                                                    value={cityIncoming}
                                                    onChange={this.handleCityChangeIncoming}
                                                    error={this.anyError("cityIncoming")}
                                                    helperText={this.errorText("cityIncoming")}
                                                />
                                            </Grid>

                                           

                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    id="stateIncoming"
                                                    label="State"
                                                    margin="dense"
                                                    fullWidth
                                                    value={stateIncoming}
                                                    onChange={this.handleStateChangeIncoming}
                                                    error={this.anyError("stateIncoming")}
                                                    helperText={this.errorText("stateIncoming")}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    id="postalIncoming"
                                                    label="Postal/Zip Code"
                                                    margin="dense"
                                                    fullWidth
                                                    value={postalIncoming}
                                                    onChange={this.handlePostalChangeIncoming}
                                                    error={this.anyError("postalIncoming")}
                                                    helperText={this.errorText("postalIncoming")}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={4} className="select-field-country">
                                                <SelectField
                                                    id="countriesIncoming"
                                                    multi={false}
                                                    suggestions={countriesSuggestion()}
                                                    onChange={this.handleCountryChangeIncoming}
                                                    value={countryCodeIncoming}
                                                    placeholder=" "
                                                    label="Country"
                                                    margin="dense"
                                                    error={this.anyError("countriesIncoming")}
                                                    helperText={this.errorText("countriesIncoming")}
                                                />
                                            </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                     : null
                                }
                        </Grid>

                        {/* {type !== 'venue' && 
                        <Fragment>
                            <Grid item xs={12} lg={3}>
                                <div className="sticky-information">
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Insurance
                                    </Typography>
                                    <Fragment>
                                        <Typography gutterBottom className="side-helper-text">
                                            Enter your insurance information here. Some venues require this information for the booking worksheet.
                                        </Typography>
                                    </Fragment>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="insuranceName"
                                            label="Policy Holder Name"
                                            margin="dense"
                                            fullWidth
                                            value={insuranceName}
                                            onChange={this.handleInsuranceNameChange}
                                            error={this.anyError("insuranceName")}
                                            helperText={this.errorText("insuranceName")}
                                        />
                                    </Grid>
    
                                    <Grid item xs={12} md={6}>
                                    <TextField
                                            id="insuranceCompany"
                                            label="Insurance Company"
                                            margin="dense"
                                            fullWidth
                                            value={insuranceCompany}
                                            onChange={this.handleInsuranceCompanyChange}
                                            error={this.anyError("insuranceCompany")}
                                            helperText={this.errorText("insuranceCompany")}
                                        />
                                    </Grid>
    
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="policyNumber"
                                            label="Policy Number"
                                            margin="dense"
                                            fullWidth
                                            value={policyNumber}
                                            onChange={this.handlePolicyNumberChange}
                                            error={this.anyError("policyNumber")}
                                            helperText={this.errorText("policyNumber")}
                                        />
                                    </Grid>
    
                                    <Grid item xs={12} lg={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker
                                                id="booking-date"
                                                className="booking-date-picker"
                                                keyboard
                                                value={insuranceExpiryDate}
                                                fullWidth
                                                margin="dense"
                                                onChange={this.handleDateStartChange}
                                                label="Expiry Date"
                                                minDate={moment().startOf("day")}
                                                format="DD-MM-YYYY"
                                                keyboardIcon={<EventIcon />}
                                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                                style={{ marginTop: 0 }}
                                                shouldDisableDate={this.shouldDisabledDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fragment>
                        } */}
                        <Grid item xs={12}>
                            <div className="action">
                                <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                    Back
                                </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    {editProfileActiveStep === steps.length - 1 ? "Finish" : "Save/Next"}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
                <ConfirmationModal
                    open={this.state.dialogAutoSendPopUp}
                    message="Turning on this feature will automatically send your invoices to accounts at midnight, on the Monday after the end of the incoming invoices batching period. If you select One Invoice Per Gig, the batch will be sent on Monday after the week end. Your current incoming invoices batching is set to {incoming invoices batching period}. Do you want to continue?"
                    title="Activate auto ‘Send To Accounts’"
                    closeHandler={this.closeDialogAutoSendPopUp}
                    confirmationHandler={this.handleAutoSendChange}
                    declineHandler={this.closeDialogAutoSendPopUp}
                    confirmationLabel="Confirm"
                    maxWidth="md"
                />
                  <ConfirmationModal
                    open={this.state.dialogAutoSendOffPopUp}
                    message="Turning off this feature will mean artist invoices are no longer automatically sent to accounts. Do you want to continue?"
                    title="Deactivate auto ‘Send To Accounts’"
                    closeHandler={this.closeDialogAutoSendOffPopUp}
                    confirmationHandler={this.handleAutoSendChange}
                    declineHandler={this.closeDialogAutoSendOffPopUp}
                    confirmationLabel="Confirm"
                    maxWidth="md"
                />

            </div>
        );
    }
}

export default Financial;
