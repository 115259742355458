import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { TextField, Grid, FormControlLabel, Switch } from "@material-ui/core";
import ValidationHelper from "../../../helper/validation.js";
const CONTACT_FEATURE = process.env.REACT_APP_CONTACT_FEATURE === "true";

@inject("bookingRequestStore")
@observer
class BookingClientDetails extends Component {
    checkClientEmail = email => {
        if (!ValidationHelper.emailValidation(email)) {
            this.props.bookingRequestStore.addError("clientEmail", "Invalid email");
        }
        else {
            this.props.bookingRequestStore.removeError("clientEmail");
        }
    }
    render() {
        let { error, booking } = this.props.bookingRequestStore;
        const { clientFirstName, clientLastName, clientAddress, clientPhone, clientEmail, addToContact, clientCompanyName, contactId } = booking;
        const { readOnly } = this.props;
        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                    <TextField
                        id="input-client-name"
                        label="Company / Client Name"
                        value={clientCompanyName}
                        onChange={e => this.props.bookingRequestStore.setBooking("clientCompanyName", e.target.value)}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        id="input-client-firstname"
                        label="Contact First Name"
                        value={clientFirstName}
                        onChange={e => this.props.bookingRequestStore.setBooking("clientFirstName", e.target.value)}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                        error={error && error["clientFirstName"]}
                        helperText={error && error["clientFirstName"] ? error["clientFirstName"] : ""}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        id="input-client-lastname"
                        label="Contact Last Name"
                        value={clientLastName}
                        onChange={e => this.props.bookingRequestStore.setBooking("clientLastName", e.target.value)}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                        error={error && error["clientLastName"]}
                        helperText={error && error["clientLastName"] ? error["clientLastName"] : ""}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        id="input-client-address"
                        label="Contact Address"
                        value={clientAddress}
                        onChange={e => this.props.bookingRequestStore.setBooking("clientAddress", e.target.value)}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                        error={error && error["clientAddress"]}
                        helperText={error && error["clientAddress"] ? error["clientAddress"] : ""}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        id="input-client-phone"
                        label="Contact Phone"
                        value={clientPhone}
                        onChange={e => this.props.bookingRequestStore.setBooking("clientPhone", e.target.value.replace(/\D/,''))}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                        error={error && error["clientPhone"]}
                        helperText={error && error["clientPhone"] ? error["clientPhone"] : ""}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        id="input-client-email"
                        label="Contact Email"
                        value={clientEmail}
                        type="email"
                        onChange={e => this.props.bookingRequestStore.setBooking("clientEmail", e.target.value)}
                        fullWidth
                        onBlur={e => this.checkClientEmail(e.target.value)}
                        InputProps={{
                            readOnly: readOnly
                        }}
                        error={error && error["clientEmail"]}
                        helperText={error && error["clientEmail"] ? error["clientEmail"] : ""}
                    />
                </Grid>
                {CONTACT_FEATURE && (
                    <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                        {!contactId || contactId === 0 ? (
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={addToContact}
                                        onChange={(e, checked) => this.props.bookingRequestStore.setBooking("addToContact", checked)}
                                    />
                                }
                                label="Add to Contact"
                                style={{
                                    marginTop: "12px"
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default BookingClientDetails;
