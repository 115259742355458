import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Header from "../../components/Header";
import Heading from "../../components/Heading";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import InvoiceDetail from "../../components/invoice/InvoiceDetail";
import { ProfileType } from "../../types/enum";

@inject("templateStore", "profileStore")
@withRouter
@observer
class InvoicePdf extends Component {
    render() {
        const { invoiceId } = this.props.match.params;
        const { currentProfile } = this.props.profileStore;
        var breadcrumb = [];
        if(currentProfile?.type === ProfileType.PrivateHire.ordinal){
            breadcrumb = [,
                { name: "Invoices", link: "/invoice" },
                { name: "Detail" }
            ];
        }else if(currentProfile){
            breadcrumb = [
                { name: "Dashboard", link: "/" + this.props.templateStore.getTypeString(currentProfile.type) },
                { name: "Invoices", link: "/invoice" },
                { name: "Detail" }
            ];
        }else {
            breadcrumb = [
                { name: "Dashboard", link: "/" + this.props.templateStore.getTypeString(1) },
                { name: "Invoices", link: "/invoice" },
                { name: "Detail" }
            ];
        }

        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="Invoice Detail" />

                    <InvoiceDetail invoiceId={invoiceId} />
                </MainContainer>
            </Fragment>
        );
    }
}

export default InvoicePdf;
