import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from "@material-ui/icons/Print";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/CloseRounded";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import ModalContainer from "../../components/modal/ModalContainer";
import { DialogContent, DialogActions, Menu, MenuItem } from "@material-ui/core";
import PdfViewer from "../../components/pdf/PdfViewer";
import POModal from "../../components/invoice/Modal/POModal";
import MYOBMappedSupplierModal from "../../components/invoice/Modal/MYOBMappedSupplierModal";
import currencyHelper from '../../helper/currency';
import XeroCustomerModal from "../../components/invoice/Modal/XeroCustomerModal";
import XeroMappedContactModal from "../../components/invoice/Modal/XeroMappedContactModal";
import ConfirmationModal from "../../components/modal/ConfirmationModal";

import { ProfileType } from "../../types/enum";
import List from '@material-ui/core//List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
const ENABLE_QUICKBOOKS = process.env.REACT_APP_ENABLE_QUICKBOOKS === "true";
const ENABLE_MYOB = process.env.REACT_APP_ENABLE_MYOB === "true";
const ENABLE_XERO = process.env.REACT_APP_ENABLE_XERO === "true";
@inject("profileStore", "invoiceStore", "templateStore", "userStore","myobStore", "billingStore","xeroStore")
@withRouter
@observer
class IncomingInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceDetailDialogOpen: false,
            selectedInvoice: { invoiceDetails: [] },
            batchPrintModalState: false,
            pdfFile: undefined,
            openPOModal: false,
            openCustomerMYOBModal: false,
            selectedPayeeId: "",
            actionAnchor: [],
            openSupplierMappedMYOBModal: false,
            openCustomerMappedXeroModal: false,
            openCustomerXeroModal: false,
            customerRow: [],
            activateMYOBAccess: false,
            activateXeroAccess: false,
            unsyncInvoiceModal: false,
            invoice: null,
            unsyncInvoiceXeroModal: false
        };
    }

    setActionAnchor = (element, bookingId) => {
        var tempAnchor = [];
        tempAnchor[bookingId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    componentDidMount() {
        this.props.myobStore.resetResultIntegration()
        this.props.xeroStore.resetResultIntegration()
        let currentProfile = this.props.profileStore.getCurrentProfile()
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
            var activeMYOB = false
            var activeXero = false
            if(currentProfile?.activateMYOBAccess && currentProfile?.myobCompanyUri){
                activeMYOB = true 
            }
            if(currentProfile?.activateXeroAccess && currentProfile?.xeroTenantId){
                activeXero = true
            }
            this.setState({
                activateMYOBAccess : activeMYOB,
                activateXeroAccess : activeXero
            });
        }else{
            var activeMYOB = false
            var activeXero = false
            if(currentProfile?.organisationOwner?.activateMYOBAccess && currentProfile?.organisationOwner?.myobCompanyUri){
                activeMYOB = true
            }
            if(currentProfile?.organisationOwner?.activateXeroAccess && currentProfile?.organisationOwner?.xeroTenantId){
                activeXero = true
            }
            this.setState({
                activateMYOBAccess : activeMYOB,
                activateXeroAccess : activeXero
            });
        }
       
        // this.props.userStore.getQuickBooks();
    }
    loadIncomingInvoice = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        // return this.props.invoiceStore.generate(currentProfile?.id).then(() => {
        //     return this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfile?.id);
        // });
        this.props.invoiceStore.loadByPayerIdAndFilter(currentProfile?.id);
    };

    showCustomerXeroModal = (invoice) => {
        this.setState({
            ...this.state,
            openCustomerXeroModal: true,
            customerRow : [invoice]
        });
    };

    hideCustomerXeroModal = () => {
        this.setState({
            ...this.state,
            openCustomerXeroModal: false,
            customerRow : []
        });
    };
    
    showCustomerMappedXeroModal = (invoice) => {
        this.setState({
            ...this.state,
            openCustomerMappedXeroModal: true,
            selectedPayeeId: invoice.payeeId,
            customerRow : [invoice]
        });
    };

    hideCustomerMappedXeroModal = () => {
        this.setState({
            ...this.state,
            openCustomerMappedXeroModal: false,
            customerRow : []
        });
    };

    showCustomerMappedMYOBModal = (invoice) => {
        this.setState({
            ...this.state,
            openSupplierMappedMYOBModal: true,
            selectedPayeeId: invoice.payeeId,
            customerRow : [invoice]
        });
    };

    hideCustomerMappedMYOBModal = () => {
        this.setState({
            ...this.state,
            openSupplierMappedMYOBModal: false,
            customerRow : []
        });
    };
    solveCustomerXeroNotFound = invoiceId =>{
        let { incomingInvoices } = this.props.invoiceStore;
        let invoices = incomingInvoices.filter(x=>x.id === invoiceId)
        this.showCustomerMappedXeroModal(invoices[0])
    }
    solveCustomerNotFound = invoiceId =>{
        let { incomingInvoices } = this.props.invoiceStore;
        let invoices = incomingInvoices.filter(x=>x.id === invoiceId)
        this.showCustomerMappedMYOBModal(invoices[0])
    }
    onGenerateClick = () => {
        let currentProfileId = this.props.profileStore.currentProfile?.id;

        this.props.invoiceStore.generate(currentProfileId).then(response => {
            this.props.invoiceStore.loadByPayerId(currentProfileId);
        });
    };

    handleViewInvoiceDetailClick = invoice => () => {
        this.setState({
            selectedInvoice: invoice,
            invoiceDetailDialogOpen: true
        });
    };

    handlePayInvoiceClick = invoiceId => () => {
        this.props.invoiceStore.payInvoice(invoiceId).finally(() => {
            this.props.invoiceStore.loadByPayerId(this.props.profileStore.currentProfile?.id);
            this.props.templateStore.openSnackbar("Invoice has been paid");
            this.handleCloseInvoiceDetailDialog();
        });
    };

    handleCloseInvoiceDetailDialog = () => {
        this.setState({
            selectedInvoice: { invoiceDetails: [] },
            invoiceDetailDialogOpen: false
        });
    };

    getCheckedRows = rows => {
        // var ids = [];
        // rows.forEach(row => {
        //     if (row && row.id) {
        //         ids.push(row);
        //     }
        // });
        this.props.invoiceStore.setSelectedIncomingInvoice(rows);
    };

    handleBatchSync = () => {
        const { selectedIncomingInvoice } = this.props.invoiceStore;
        let currentProfile = this.props.profileStore.getCurrentProfile();
        let currentProfileId = currentProfile?.id;
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfileId = currentProfile?.organisationOwner.id
        }
        this.props.myobStore.resetResultIntegration()
        if (selectedIncomingInvoice && selectedIncomingInvoice.length < 11) {
            this.props.templateStore.showLoadingScreen();
            this.props.myobStore.integrateIncomingInvoice(currentProfileId, selectedIncomingInvoice.map(x => x.id)).then(result => {
                this.props.invoiceStore.loadByPayerIdAndFilter(currentProfileId).finally(() => {
                    this.props.templateStore.hideLoadingScreen();
                })
            });
        }else{
            this.props.templateStore.openSnackbar("Integration only support 10 invoice at a time");
        }
    }
    handleConfirmMarkAsUnsync = () => {
        this.props.invoiceStore.unsyncSendToAccountIncoming(this.state.invoice.id).then(responseFile => {
            this.handleCloseUnsyncInvoiceModal()
        })
    }
    handleConfirmMarkAsUnsyncXero = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.invoiceStore.unsyncXeroInvoiceIncoming(currentProfile.id, this.state.invoice.id).then(response => {
            this.handleCloseUnsyncInvoiceXeroModal()
        }).catch((err) => {
            this.handleCloseUnsyncInvoiceXeroModal()
            this.props.templateStore.openSnackbar("This invoice has already been paid and cannot be unsynced. You will need to manually edit the invoice in XERO.");
        });
    }
    handleBatchSyncXero = () => {
        const { selectedIncomingInvoice } = this.props.invoiceStore;
        let currentProfile = this.props.profileStore.getCurrentProfile();
        let currentProfileId = currentProfile?.id;
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfileId = currentProfile?.organisationOwner.id
        }
        this.props.xeroStore.resetResultIntegration()
        if (selectedIncomingInvoice && selectedIncomingInvoice.length < 11) {
            this.props.templateStore.showLoadingScreen();
            this.props.xeroStore.integrateIncomingInvoice(currentProfileId, selectedIncomingInvoice.map(x => x.id)).then(result => {
                this.props.invoiceStore.loadByPayerIdAndFilter(currentProfileId).finally(() => {
                    this.props.templateStore.hideLoadingScreen();
                })
            });
        }else{
            this.props.templateStore.openSnackbar("Integration only support 10 invoice at a time");
        }
    }

    handleBatchPrint = () => {
        const { selectedIncomingInvoice } = this.props.invoiceStore;
        if (selectedIncomingInvoice) {
            this.props.templateStore.showLoadingScreen();
            this.props.invoiceStore.downloadBatchInvoicePdf(selectedIncomingInvoice.map(x => x.id)).then(responseFile => {
                let pdfFile = `data:application/pdf;base64,${responseFile}`;
                this.setState(
                    {
                        ...this.state,
                        batchPrintModalState: true,
                        pdfFile: pdfFile
                    },
                    () => {
                        this.props.templateStore.hideLoadingScreen();
                    }
                );
            });
        }
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            batchPrintModalState: false
        });
    };

    getColumns = (hasQuickBooksAccount, isStaff, hasOrganisation) => {
        var isMYOBActive = ENABLE_MYOB && this.props.billingStore.billingAccess?.isProPlan && this.state.activateMYOBAccess
        var isXeroActive = ENABLE_XERO && this.props.billingStore.billingAccess?.isProPlan && this.state.activateXeroAccess
        var needExtraColumn= isXeroActive || isMYOBActive
        var actionWitdh = needExtraColumn ? "5%" : "10%"
        if (isStaff){
            const columns = [
                { key: "date", label: "Invoice Date", sortable: true, isDate: true,width: "10%",align:"center"  },
                { key: "dueDate", label: "Due Date", sortable: true, isDate: true,width: "10%",align:"center" },
                { key: "no", label: "Invoice No.",width: "4%",align:"center"  },
                { key: "status", label: "Status",width: "5%"  },
                { key: "subTotal", label: "Gig Fee", sortable: true, isNumber: true,width:"9%",align:"center"  },
                { key: "tax", label: "Tax", sortable: true, isNumber: true,width:"7%"  },
                { key: "total", label: "Total", sortable: true, isNumber: true,width:"9%"  },
                { key: "payeeName", label: "Contact Name", sortable: true,width: "13%"  },
                ...(isStaff ? [{ key: "payerName", label: "Venue",width: "13%" }] : []),
                ...(isStaff ? [{ key: "synchedSendAccount", label: "Sent To Accounts",width: "5%"  }] : []),
                // ...(isStaff ? [{ key: "purchaseOrderCode", label: "PO",width: "5%"  }] : []),
                // ...(isStaff ? [{ key: "synchedSendAccount2", label: "synchedSendAccount2",width: "5%"  }] : []),
                ...(isMYOBActive ? [ { key: "syncMYOB", label: "Synced to MYOB",width: "5%" ,align:"center" }] : []),
                ...(isXeroActive ? [ { key: "syncXero", label: "Synced to Xero",width: "5%",align:"center"  }] : []),
                ...(ENABLE_QUICKBOOKS && hasQuickBooksAccount ? [{ key: "quickBooks", label: "QuickBooks" }] : []),
                { key: "action", label: "",width:actionWitdh }
            ];
            return columns;
        }else{
            const columns = [
                { key: "date", label: "Invoice Date", sortable: true, isDate: true },
                { key: "dueDate", label: "Due Date", sortable: true, isDate: true },
                { key: "no", label: "Invoice No." },
                { key: "status", label: "Status" },
                { key: "subTotal", label: "Gig Fee", sortable: true, isNumber: true },
                { key: "tax", label: "Tax", sortable: true, isNumber: true },
                { key: "total", label: "Total", sortable: true, isNumber: true },
                { key: "payeeName", label: "Contact Name", sortable: true },
                ...(isStaff ? [{ key: "purchaseOrderCode", label: "Purchase Order" }] : []),
                ...(ENABLE_MYOB && this.props.billingStore.billingAccess?.isProPlan && this.state.activateMYOBAccess ? [ { key: "syncMYOB", label: "Synched with MYOB" }] : []),
                ...(ENABLE_XERO && this.props.billingStore.billingAccess?.isProPlan && this.state.activateXeroAccess ? [ { key: "syncXero", label: "Synched with Xero" }] : []),
                ...(ENABLE_QUICKBOOKS && hasQuickBooksAccount ? [{ key: "quickBooks", label: "QuickBooks" }] : []),
                { key: "action", label: "" }
            ];
            return columns;
        }
       
       
    };

    renderTableCustomActions = () => {
        const { selectedIncomingInvoice } = this.props.invoiceStore;
        const { currentProfile } = this.props.profileStore;
        return (
            <Fragment>
                {currentProfile?.type === ProfileType.Staff.ordinal && (
                    <Button disabled={selectedIncomingInvoice && selectedIncomingInvoice[0] == undefined || !selectedIncomingInvoice ? true : false} onClick={this.showPurchaseOrderModal}>
                        <span style={{ paddingTop: 3, color: selectedIncomingInvoice && selectedIncomingInvoice[0] !== undefined ? "#fff" : undefined }}>Send to Accounts</span>
                    </Button>
                )}
                 <Fragment>
                   { ENABLE_MYOB && ((this.props.billingStore.billingAccess?.isProPlan && this.state.activateMYOBAccess && ((currentProfile?.type != ProfileType.Staff.ordinal || !currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization) || (!currentProfile?.isOwnOrganization && currentProfile?.allowInvoices)))) ?(
                    
                       <Button disabled={selectedIncomingInvoice && selectedIncomingInvoice[0] == undefined || !selectedIncomingInvoice ? true : false} onClick={this.handleBatchSync}>
                            <RefreshIcon style={{ color: selectedIncomingInvoice && selectedIncomingInvoice[0] !== undefined ? "#fff" : undefined }} />
                            <span style={{ paddingTop: 3, color: selectedIncomingInvoice && selectedIncomingInvoice[0] !== undefined ? "#fff" : undefined }}>SYNC WITH MYOB</span>
                        </Button>
                      
                  
                ) : null}
                  {   ENABLE_XERO && (this.props.billingStore.billingAccess?.isProPlan && this.state.activateXeroAccess && ((currentProfile?.type != ProfileType.Staff.ordinal || !currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization) || (!currentProfile?.isOwnOrganization && currentProfile?.allowInvoices))) ?(
                    
                    <Button disabled={selectedIncomingInvoice && selectedIncomingInvoice[0] == undefined || !selectedIncomingInvoice ? true : false} onClick={this.handleBatchSyncXero}>
                         <RefreshIcon style={{ color: selectedIncomingInvoice && selectedIncomingInvoice[0] !== undefined ? "#fff" : undefined }} />
                         <span style={{ paddingTop: 3, color: selectedIncomingInvoice && selectedIncomingInvoice[0] !== undefined ? "#fff" : undefined }}>SYNC WITH XERO</span>
                     </Button>
                   
               
             ) : null}
                      <Button disabled={selectedIncomingInvoice && selectedIncomingInvoice[0] == undefined || !selectedIncomingInvoice ? true : false} onClick={this.handleBatchPrint}>
                            <PrintIcon style={{ color: selectedIncomingInvoice && selectedIncomingInvoice[0] !== undefined ? "#fff" : undefined }} />
                            <span style={{ paddingTop: 3, color: selectedIncomingInvoice && selectedIncomingInvoice[0] !== undefined ? "#fff" : undefined }}>Print</span>
                        </Button>
                 </Fragment>
            </Fragment>
        );
    };

    
    handleOpenUnsyncInvoiceXeroModal = invoice => this.setState({ unsyncInvoiceXeroModal: true, invoice: invoice, actionAnchor: [] });
    handleCloseUnsyncInvoiceXeroModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceXeroModal: false,
            invoice: null
        });
    };

    handleOpenUnsyncInvoiceModal = invoice => this.setState({ unsyncInvoiceModal: true, invoice: invoice, actionAnchor: [] });
    handleCloseUnsyncInvoiceModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceModal: false,
            invoice: null
        });
    };

    showPurchaseOrderModal = () => {
        this.setState({
            ...this.state,
            openPOModal: true
        });
    };

    showCustomerMYOBModal = () => {
        this.setState({
            ...this.state,
            openCustomerMYOBModal: true
        });
    };

    hidePurchaseOrderModal = () => {
        this.setState({
            ...this.state,
            openPOModal: false
        });
    };
    hideCustomerMYOBModal = () => {
        this.setState({
            ...this.state,
            openCustomerMYOBModal: false
        });
    };
    
    renderFooterRow = invoices => {
        const { currentProfile } = this.props.profileStore;
        let summary = invoices.reduce(
            (a, b) => {
                return { total: parseFloat(a.total) + parseFloat(b.total), subTotal: parseFloat(a.subTotal) + parseFloat(b.subTotal), tax: parseFloat(a.tax) + parseFloat(b.tax) };
            },
            { total: 0, subTotal: 0, tax: 0 }
        );
        let currencySymbol = "$"
        if((currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.type === ProfileType.Agent.ordinal) && currentProfile?.currency){
            currencySymbol = currentProfile?.currency === "GBP" ? "£" : "$"
        }else if(currentProfile?.type === ProfileType.Staff.ordinal ){
            if(currentProfile.hasOrganisation){
                currencySymbol = currentProfile.organisation[0].currency === "GBP" ? "£" : "$"
            }else{
                currencySymbol = currentProfile.venue.currency === "GBP" ? "£" : "$"
            }
        }
        // if(invoices.length){
        //     var invoice = invoices[0]
        //     currencySymbol = invoice && invoice.payee && invoice.payee.currency === "GBP" ? "£" : "$";
        
        // }
        return {
            date: `Total of ${invoices.length} invoices`,
            total: currencySymbol +  currencyHelper.formatMoney(parseFloat(summary.total), 2),
            subTotal: currencySymbol + currencyHelper.formatMoney(parseFloat(summary.subTotal), 2),
            tax: currencySymbol + currencyHelper.formatMoney(parseFloat(summary.tax), 2)
        };
    };

    render() {
        const { incomingInvoices, selectedIncomingInvoice } = this.props.invoiceStore;
        const { currentProfile } = this.props.profileStore;
        const { pdfFile, batchPrintModalState, openPOModal,openSupplierMappedMYOBModal,selectedPayeeId,customerRow,openCustomerXeroModal,openCustomerMappedXeroModal} = this.state;
        const { hasQuickBooksAccount } = this.props.userStore;
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        const columns = this.getColumns(hasQuickBooksAccount, isStaff, currentProfile?.hasOrganisation);
        const invoices = this.props.invoiceStore.filterIncomingInvoiceBaseOnFilter(incomingInvoices);
        var invoiceNumberArray = [];
        if(selectedIncomingInvoice && selectedIncomingInvoice.length > 0){
            selectedIncomingInvoice.forEach(column => {
                var selectedInvoice = invoices.filter(x=>x.id === column.id)[0]
                if(selectedInvoice && selectedInvoice.no){
                    invoiceNumberArray.push("HG " + selectedInvoice.no)
                }
               
            });
        }
        return (
            <Fragment>
                 <POModal open={openPOModal} invoices={selectedIncomingInvoice} closeHandler={this.hidePurchaseOrderModal} />
                {
                     ENABLE_MYOB && (this.props.billingStore.billingAccess?.isProPlan && this.state.activateMYOBAccess && (currentProfile?.allowInvoices || currentProfile.type != ProfileType.Staff.ordinal )) ?
                    <Fragment>
                        <MYOBMappedSupplierModal type={0}  selectedPayeeId={selectedPayeeId}  open={openSupplierMappedMYOBModal} invoices={customerRow} closeHandler={this.hideCustomerMappedMYOBModal} />
                    </Fragment> : null
                    }
                
                {
                     ENABLE_XERO && (this.props.billingStore.billingAccess?.isProPlan && this.state.activateXeroAccess && (currentProfile?.allowInvoices || currentProfile.type != ProfileType.Staff.ordinal )) ?
                    <Fragment>
                        <XeroCustomerModal open={openCustomerXeroModal} invoices={customerRow} closeHandler={this.hideCustomerXeroModal} />
                        <XeroMappedContactModal type={0}  selectedPayerId={selectedPayeeId}  open={openCustomerMappedXeroModal} invoices={customerRow} closeHandler={this.hideCustomerMappedXeroModal} />
                    </Fragment> : null
                }
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Incoming Invoices
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                These are the incoming invoices from the entertainers you have booked.  Please mark the invoices as paid after you have made payment.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={16}> 
                            <Grid item xs={12}>
                                <Paper>
                                    {
                                        this.props.myobStore.integrateReturnResult.length ? 
                                        ( 
                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                {this.props.myobStore.integrateReturnResult.map((value) => (
                                                    <ListItem
                                                    key={value.id}
                                                    disableGutters
                                                    >
                                                    <CloseIcon style={{ texAlign:"center",display:value.type == 0 ? "absolute" : "none",color: "red"}}  /> 
                                                    <CheckCircle style={{ texAlign:"center",display:value.type == 1 ? "absolute" : "none",color: "green"}}  /> 
                                                    <ListItemText primary={`${value.message}`} />
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        style={{margin:20,display:value.type == 0 && (value.code === 1 || value.code === 4)  ? "absolute" : "none"}}
                                                        onClick={() => this.solveCustomerNotFound(value.id)}
                                                    >
                                                        Quick Fix
                                                    </Button>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : null
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    {
                                        this.props.xeroStore.integrateReturnResult.length ? 
                                        ( 
                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                {this.props.xeroStore.integrateReturnResult.map((value) => (
                                                    <ListItem
                                                    key={value.id}
                                                    disableGutters
                                                    >
                                                    <CloseIcon style={{ texAlign:"center",display:value.type == 0 ? "absolute" : "none",color: "red"}}  /> 
                                                    <CheckCircle style={{ texAlign:"center",display:value.type == 1 ? "absolute" : "none",color: "green"}}  /> 
                                                    <ListItemText primary={`${value.message}`} />
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        style={{margin:20,display:value.type == 0 && (value.code === 1 || value.code === 4)  ? "absolute" : "none"}}
                                                        onClick={() => this.solveCustomerXeroNotFound(value.id)}
                                                    >
                                                        Quick Fix
                                                    </Button>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : null
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div style={{ overflowX: "auto" }}>
                                        <ResponsiveTable
                                            showToolbar={true}
                                            customActions={this.renderTableCustomActions()}
                                            selectable={true}
                                            getCheckedRows={this.getCheckedRows}
                                            columns={columns}
                                            rows={invoices.map(invoice => {
                                                var row = {};
                                                row.id = invoice.id;
                                                row.date = moment.utc(invoice.date).format("DD MMM YYYY");
                                                row.dueDate = moment.utc(invoice.dueDate).format("DD MMM YYYY");
                                                row.no = <div style={{textAlign:"center"}}>{invoice.no}</div>;
                                                row.status =
                                                    invoice.status === "overdue" ? (
                                                        <Tooltip
                                                            title={
                                                                "This invoice has been " +
                                                                moment(new Date()).diff(moment(invoice.dueDate), "days") +
                                                                " day(s) past due date"
                                                            }
                                                            placement="bottom"
                                                        >
                                                            <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                                        </Tooltip>
                                                    ) : (
                                                        <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                                    );

                                                const currencySymbol = invoice && invoice.payee && invoice.payee.currency === "GBP" ? "£" : "$";
                                                row.total =  currencySymbol + currencyHelper.formatMoney(invoice.total, 2)
                                                row.subTotal =  currencySymbol + currencyHelper.formatMoney(invoice.subTotal, 2)
                                                row.tax = currencySymbol + currencyHelper.formatMoney(invoice.tax, 2)
                                                var payeeName = invoice.payeeName
                                                var payerName = invoice.payerName
                                                if(invoice.payee){
                                                    payeeName = invoice.payee.profileName ? invoice.payee.profileName : invoice.payee.firstName + " " + invoice.payee.lastName
                                                }
                                                if(invoice.payer){
                                                    payerName = invoice.payer.profileName ? invoice.payer.profileName : invoice.payer.firstName + " " + invoice.payer.lastName
                                                }
                                                row.payeeName = payeeName;
                                                row.payeePhone = invoice.payeePhone;
                                                row.synchedSendAccount =  invoice.synchedSendAccount || invoice.purchaseOrderCode ? <div style={{textAlign:"center", width:"100%"}}><CheckCircle style={{ texAlign:"center",color: "green"}} /></div> : null;

                                                // row.purchaseOrderCode =  invoice.purchaseOrderCode 
                                                // row.synchedSendAccount2 =  invoice.synchedSendAccount
                                                row.payerName = payerName
                                                
                                                row.syncMYOB = invoice.isMYOBSyncronise ?  <div style={{textAlign:"center", width:"100%"}}><CheckCircle style={{ texAlign:"center",color: "green"}} /></div> : null;
                                                row.syncXero = invoice.isXeroSyncronise ? <div style={{textAlign:"center", width:"100%"}}><CheckCircle style={{ texAlign:"center",color: "green"}} /></div>  : null;
                                                row.quickBooks = (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        className="btn-sync-quickbooks"
                                                        component={Link}
                                                        to={`/invoice/${invoice.id}/quickbooks`}
                                                    >
                                                        Sync
                                                    </Button>
                                                );
                                                // row.action = (
                                                //     <Button
                                                //         size="small"
                                                //         color="primary"
                                                //         className="btn-invoice-detail"
                                                //         component={Link}
                                                //         to={`/invoice/${invoice.id}/detail`}
                                                //     >
                                                //         Detail
                                                //     </Button>
                                                // );

                                                row.action =  
                                                (  <Fragment>
                                                        <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, invoice.id)}>
                                                            Actions
                                                        </Button>
                                                        {invoice.id && (
                                                            <Menu
                                                                open={this.state.actionAnchor[invoice.id] != null}
                                                                onClose={() => this.unsetActionAchnor(invoice.id)}
                                                                anchorEl={this.state.actionAnchor[invoice.id]}
                                                            >
                                                                <MenuItem
                                                                    button
                                                                    className="btn-invoice-detail"
                                                                    onClick={() => {
                                                                        var redirectUrl = `/invoice/${invoice.id}/detail`
                                                                        this.props.history.push(redirectUrl);
                                                                    }}
                                                                >
                                                                    View
                                                                </MenuItem>
                                                                {invoice.synchedSendAccount === 1? <MenuItem onClick={() => this.handleOpenUnsyncInvoiceModal(invoice)}>Unsync Invoice </MenuItem> : null}
                                                                {invoice.isXeroSyncronise? <MenuItem onClick={() => this.handleOpenUnsyncInvoiceXeroModal(invoice)}>Unsync Xero Invoice </MenuItem> : null}
                                                                
                                                                
                                                            </Menu>
                                                        )}
                                                    </Fragment>
                                                ) 

                                                return row;
                                            })}
                                            sortable={true}
                                            footerRow={this.renderFooterRow(invoices)}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <ConfirmationModal
                    open={this.state.unsyncInvoiceModal}
                    title={"Unsync Confirmation"}
                    message={`This action will not affected the invoice that already sent to accounts. It will only mark this hotgiggity invoice to unsync. Are you sure you want to continue??`}
                    closeHandler={this.handleCloseUnsyncInvoiceModal}
                    confirmationHandler={this.handleConfirmMarkAsUnsync}
                    declineHandler={this.handleCloseUnsyncInvoiceModal}
                    confirmationLabel="Confirm"
                />

                <ConfirmationModal
                    open={this.state.unsyncInvoiceXeroModal}
                    title={"Unsync Confirmation"}
                    message={`This action will void the invoice in XERO. You will need to resync the invoice again. Are you sure you want to continue?`}
                    closeHandler={this.handleCloseUnsyncInvoiceXeroModal}
                    confirmationHandler={this.handleConfirmMarkAsUnsyncXero}
                    declineHandler={this.handleCloseUnsyncInvoiceXeroModal}
                    confirmationLabel="Confirm"
                />

                <ModalContainer open={batchPrintModalState} onClose={this.handleClose} title="Batch Invoice Pdf" coloredDialogTitle={true}>
                    <DialogContent>
                        <PdfViewer file={pdfFile} batch={true} invoiceIds={selectedIncomingInvoice} receiptNumber={invoiceNumberArray} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>Close</Button>
                    </DialogActions>
                </ModalContainer>
            </Fragment>
        );
    }
}
export default IncomingInvoice;
