import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import profileStore from "./profileStore";
import CacheHelper from "../helper/cache.js";
import image from "../helper/image";
import blankProfile from "../img/hotgig-loading.png";

class LineupMessageStore {
    @observable messages = [];

    @observable bookingId = undefined;
    @observable message = "";

    @action
    setBookingId(bookingId) {
        this.bookingId = bookingId;
    }

    @action
    addMessage(message) {
        this.messages.push(message);
    }

    @action
    setMessages(messages) {
        this.messages = messages;
    }

    @action
    setMessage(message) {
        this.message = message;
    }

    @action
    getBookingLineupMessages(bookingId) {
        return agent.LineupMessage.getBookingLineupMessage(bookingId).then(
            action(async response => {
                for (var i = 0; i < response.length; i++) {
                    if(response[i].profileImageUrl!==null)
                        response[i].profileImageUrl = await CacheHelper.getImgLink(response[i].profileImageUrl)
                }
                runInAction(() => {
                    this.messages = response;
                })

                return this.messages;
            })
        );
    }

    @action
    postBookingLineupMessage(bookingId) {
        const { profile } = profileStore;

        let data = {
            bookingId: bookingId ? bookingId : this.bookingId,
            message: this.message,
            profileId: profile.id,
            profileImageUrl: image.getProfileImage(profile) == blankProfile ? null: image.getProfileImage(profile)
        };
        return agent.LineupMessage.postBookingLineupMessage(data).then(
            action(response => {
                this.message = "";
                return response;
            })
        );
    }

    @action
    deleteLineupId(lineupId) {
     
        return agent.LineupMessage.deleteLineupMessage(lineupId).then(
            action(response => {
                this.message = "";
                return response;
            })
        );
    }
}

export default new LineupMessageStore();
