import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { Grid } from "@material-ui/core";

import MomentUtils from "@date-io/moment";

import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";

import moment from "moment";

import HubTimepicker from "../../timepicker/HubTimepicker";

import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "./GigPromotionDateDetails.css";

@inject("gigPromotionStore", "profileStore", "agentVenueStore", "agentActStore")
@observer
class GigPromotionDateDetails extends Component {
    handleRepeatTypeChange = e => {
        this.props.gigPromotionStore.setGigPromotion("repeatType", e.target.value);
        this.props.gigPromotionStore.setGigPromotion("repeatCount", 1);
    };
    handleRepeatCountChange = e => {
        if (e.target.value === "0") {
            this.props.gigPromotionStore.setGigPromotion("repeatCount", 1);
        } else {
            this.props.gigPromotionStore.setGigPromotion("repeatCount", parseInt(e.target.value, 10));
        }
    };
    handleDateStartChange = e => {
        this.props.gigPromotionStore.setGigPromotion("dateStart", moment(e).startOf("day"));
    };
    handleTimeStartChange = e => {
        this.props.gigPromotionStore.setGigPromotion("timeStart", e);
    };
    handleTimeEndChange = e => {
        this.props.gigPromotionStore.setGigPromotion("timeEnd", e);
    };

    render() {
        let { gigPromotion, error } = this.props.gigPromotionStore;
        let { dateStart, timeStart, timeEnd } = gigPromotion;

        let { readOnly } = this.props;

        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            id="booking-date"
                            className="booking-date-picker"
                            keyboard
                            value={dateStart}
                            fullWidth
                            margin="dense"
                            onChange={this.handleDateStartChange}
                            label="Date Start"
                            minDate={ 
                                moment().startOf('day')
                            }
                            format="DD-MM-YYYY"
                            keyboardIcon={<EventIcon />}
                            leftArrowIcon={<KeyboardArrowLeftIcon />}
                            rightArrowIcon={<KeyboardArrowRightIcon />}
                            disabled={readOnly}
                            style={{ marginTop: 0 }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        margin="none"
                        label="Time Start"
                        value={moment(timeStart, "HH:mm")}
                        onChange={this.handleTimeStartChange}
                        error={error && error["timeStart"]}
                        helperText={error && error["timeStart"] ? error["timeStart"] : "Venue's Local Time"}
                        menuStyle = {
                            { position: "relative", zIndex: "9999", width: "100%", maxHeight: "300px", overflowY: "auto" }
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        margin="none"
                        label="Time End"
                        value={moment(timeEnd, "HH:mm")}
                        start={moment(timeStart, "HH:mm")}
                        onChange={this.handleTimeEndChange}
                        error={error && error["timeEnd"]}
                        helperText={error && error["timeEnd"] ? error["timeEnd"] : "Venue's Local Time"}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default GigPromotionDateDetails;
