import React, { Component } from "react";
import ComingSoon from "../ComingSoon";

class HubTrade extends Component {
    render() {
        return <ComingSoon title="Hubtrade" />;
    }
}

export default HubTrade;
