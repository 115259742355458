import { observable, action, runInAction } from "mobx";
import ValidationHelper from '../helper/validation';
// import agent from "../agent";
import userStore from "./userStore";
import agent from "../agent";
import authStore from "./authStore"
class OrganisationMemberStore {
    @observable isLoadingMembers = false;
    @observable submitInProgress = false;
    @observable members = [];
    @observable profile = undefined;
    @observable id = undefined;
    @observable name = "";
    @observable lastName = "";
    @observable email = "";
    @observable title = "";
    @observable edit = false;
    @observable financial = false;
    @observable booking = false;
    @observable manage = false;
    @observable promote = false;
    @observable viewOnly = false;
    @observable organisationId = undefined;
    @observable stateId = undefined;
    @observable regionId = undefined;
    @observable venueId = undefined;
    @observable level = undefined;
    @observable errors = undefined;
    @observable invitedProfile = undefined;
    @observable invitedId = undefined;

    @action
    loadMembers(level, orgId, stateId, regionId) {
        // this.isLoadingMembers = true;
        // this.members = [];
        // this.organisationId = orgId;
        // console.log("loading members");
        // switch (level) {
        //     case 1:
        //         return agent.OrganisationMember.loadOrg(orgId)
        //             .then(
        //                 action(members => {
        //                     this.members = members;
        //                 })
        //             )
        //             .finally(
        //                 action(() => {
        //                     this.isLoadingMembers = false;
        //                 })
        //             );
        //     default:
        //         return agent.OrganisationMember.loadOrg(orgId);
        // }
    }

    @action
    setMembers(members) {
        if(members){
            const tempMember = []
            members.map( member => {
                if (member.name){
                    
                    var fields = member.name.split(' ');
                    if (fields.length > 1){
                        member.lastName = fields[fields.length-1];
                        let removeLastIndex = fields.pop()
                        member.name = fields.toString();
                    }
                    tempMember.push(member)
                    return member;
                } else {
                    tempMember.push(member)
                }
           
            })
            this.members = members;
        }
        else {
            this.members = members;
        }
    }

    @action
    setInvitedProfile(profile){
        if (profile) {
            this.invitedId = profile.id;
            this.setName(profile.firstName);
            this.setLastName(profile.lastName);
            this.setEmail(profile.email);

        }
        this.invitedProfile = profile;
    
    }

    @action
    setOrganisationId(organisationId) {
        this.organisationId = organisationId;
    }

    @action
    setStateId(stateId) {
        this.stateId = stateId;
    }

    @action
    setRegionId(regionId) {
        this.regionId = regionId;
    }

    @action
    setVenueId(venueId) {
        this.venueId = venueId;
    }

    @action
    setLevel(level) {
        this.level = level;
    }

    @action
    setProfile(profile) {
        this.profile = profile;
    }

    @action
    setName(name) {
        this.name = name;
    }
    @action
    setLastName(name) {
        this.lastName = name;
    }
    
    @action
    setEmail(email) {
        this.email = email;
    }

    @action
    setTitle(title) {
        this.title = title;
    }

    @action
    setEdit(edit) {
        this.edit = edit;
    }

    @action
    setFinancial(financial) {
        this.financial = financial;
        if(financial) {
            this.setViewOnly(false);
        }
    }

    @action
    setBooking(booking) {
        this.booking = booking;
        if(booking) {
            this.setViewOnly(false);
        }
    }

    @action
    setManage(manage) {
        this.manage = manage;
    }

    @action
    setPromote(promote) {
        this.promote = promote;
        if(promote) {
            this.setViewOnly(false);
        }
    }

    @action
    setViewOnly(viewOnly) {
        this.viewOnly = viewOnly;
        if(viewOnly) {
            this.setFinancial(false);
            this.setBooking(false);
            this.setPromote(false);
        }
    }

    @action
    reset() {
        this.id = undefined;
        this.name = "";
        this.lastName = "";
        this.email = "";
        this.title = "";
        this.edit = false;
        this.financial = false;
        this.booking = false;
        this.manage = false;
        this.promote = false;
        this.viewOnly = false;
        this.members = [];
        this.organisationId = undefined;
        this.stateId = undefined;
        this.regionId = undefined;
        this.venueId = undefined;
        this.invitedProfile = undefined;
    }

    @action
    resetAddForm() {
        this.id = undefined;
        this.name = "";
        this.lastName = "";
        this.email = "";
        this.title = "";
        this.edit = false;
        this.financial = false;
        this.booking = false;
        this.manage = false;
        this.promote = false;
        this.viewOnly = false;
    }

    @action
    setMemberPermission(selectedMember, type, value) {
        this.members = this.members.map( member => {
            if(member.id && selectedMember.id){
                if(member.id === selectedMember.id){
                    switch (type) {
                        case "edit":
                            member.edit = value;
                            break;
                        case "booking":
                            member.booking = value;
                            member.viewOnly = false;
                            break;
                        case "financial":
                            member.financial = value;
                            member.viewOnly = false;
                            break;
                        case "manage":
                            member.manage = value;
                            break;
                        case "promote":
                            member.promote = value;
                            member.viewOnly = false;
                            break;
                        case "viewOnly":
                            member.viewOnly = value;
                            member.booking = false;
                            member.financial = false;
                            member.promote = false;
                            break;
                        default:
                            break;
                    }
                }
            } else {
                if(member.email === selectedMember.email){
                    switch (type) {
                        case "edit":
                            member.edit = value;
                            break;
                        case "booking":
                            member.booking = value;
                            member.viewOnly = false;
                            break;
                        case "financial":
                            member.financial = value;
                            member.viewOnly = false;
                            break;
                        case "manage":
                            member.manage = value;
                            break;
                        case "promote":
                            member.promote = value;
                            member.viewOnly = false;
                            break;
                        case "viewOnly":
                            member.viewOnly = value;
                            member.booking = false;
                            member.financial = false;
                            member.promote = false;
                            break;
                        default:
                            break;
                    }
                }
            }
           
            return member;
        })
    }

    @action
    removeOrganisationMember(email) {
        let om = this.members.filter(x => x.email === email);
        if (om && om.length > 0) this.members.remove(om[0]);
    }

    @action
    async submit() {
        this.submitInProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        
        return authStore.checkEmailValidWithEmail(this.email).then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                await this.validateAddFoodFields();

            
                if(this.errors) return;

                const member = {
                    id: this.id,
                    createdById: user.id,
                    edit: this.edit,
                    financial: this.financial,
                    booking: this.booking,
                    manage: this.manage,
                    promote: this.promote,
                    viewOnly: this.viewOnly, 
                    title: this.title,
                    name: this.name,
                    lastName: this.lastName,
                    email: this.email,
                    organisationId: this.organisationId,
                    stateId: this.stateId,
                    regionId: this.regionId,
                    venueId: this.venueId,
                    invitedId: this.invitedId
                };
                runInAction(()=>{
                    this.members.push(member);
                })
            
                return true;
            }else{
                runInAction(async () => {
                    this.errors = [];
                    this.errors["staffEmail"] = "We have detected that you are trying to add a user using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.  Please use a permanent email address to invite this user.";
                
                })
                return Promise.reject("Invalid Email!");
            }
        })
    }

    @action
    validateAddFoodFields = async () => {
        let findError = false;
        this.errors = {
            staffName: undefined, 
            staffEmail: undefined,
            lastName: undefined,
            title: undefined
        };

        if (!this.title || this.title.length === 0) {
            this.errors.title = "Title is required!";
            findError = true;
        } else {
            this.errors.title = undefined;
        }

        if (!this.invitedProfile){
            if (!this.name || this.name.length === 0) {
                this.errors.staffName = "First name is required!";
                findError = true;
            } else {
                this.errors.staffName = undefined;
            }
    
            if (!this.lastName || this.lastName.length === 0) {
                this.errors.lastName = "Last Name is required!";
                findError = true;
            } else {
                this.errors.lastName = undefined;
            }
            
            const existingUser = this.members.find(x => x.email === this.email);

            if (!this.email || this.email.length === 0) {
                this.errors.staffEmail = "Staff email is required!";
                findError = true;
            } else if (existingUser) {
                this.errors.staffEmail = "Staff email already exist!";
                findError = true;
            } else if (!ValidationHelper.emailValidation(this.email)) {
                this.errors.staffEmail = "Please use valid email address";
                findError = true;
            }else {
                this.errors.staffEmail = undefined;
            }
            var errorChecking = await this.checkMemberValid(this.email,findError);
    
            runInAction(() => {
                if (!errorChecking){
                    this.errors.staffEmail = "you can't add this user at the moment please contact support@hotgiggity.com";
                }
                if (!findError && errorChecking)  this.errors = undefined;
            })
        } else {
            if (!findError)  this.errors = undefined;
        }
        
      
        
    };

    @action
    resetErrors() {
        this.errors = undefined;
    }

    @action
    checkMemberValid(email, findError) {
        return agent.OrganisationMember.checkEmailMember(email)
        .then(
            action(result => {
                return result
            })
        )
    }
}

export default new OrganisationMemberStore();
