import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import profileStore from "./profileStore";
import agentVenueStore from "./agentVenueStore";
import venueActStore from "./venueActStore";

import { ProfileType, ProfileRelationType } from "../types/enum";
import userStore from "./userStore";
import authStore from "./authStore"
import designProfileStore from "./designProfileStore";
import templateStore from "./templateStore";
import CacheHelper from "../helper/cache.js"

class UserInvitationStore {
    @observable invitedUserFirstName;
    @observable invitedUserLastName;
    @observable invitedUserEmail;

    @observable invitedActName;
    @observable invitedVenueName;

    @observable actLineup = 1;
    @observable actType = 2;

    @observable invitationMessage;

    @observable errors = undefined;
    @observable isLoadingUserInvitations = false;

    @observable invitations = [];
    @observable searchedAddress = "";
    @observable existingVenue = null;
    @action
    resetInvitedData() {
        this.invitedUserFirstName = undefined;
        this.invitedUserLastName = undefined;
        this.invitedUserEmail = undefined;

        this.invitedActName = undefined;
        this.invitedVenueName = undefined;

        this.invitationMessage = undefined;

        this.errors = undefined;
    }

    @action
    resetInvitations() {
        this.invitations = [];
    }

    @action
    setInvitedUserFirstName(value) {
        this.invitedUserFirstName = value;    
    }

    @action
    setSearchedAddress(value) {
        this.searchedAddress = value
    }
    @action
    setInvitedUserLastName(value) {
        this.invitedUserLastName = value;
    }

    @action
    setInvitedUserEmail(value) {
        this.invitedUserEmail = value;
    }

    

    @action
    setInvitedActName(value) {
        this.invitedActName = value;
    }

    @action
    setActType(value) {
        this.actType = value;
    }

    @action
    setActLineup(value) {
        this.actLineup = value;
    }

    @action
    setInvitedVenueName(value) {
        this.invitedVenueName = value;
    }

    @action
    setInvitationMessage(value) {
        this.invitationMessage = value;
    }

    @action
    validateInvitedActName(value) {
        let currentErrorState = {...this.errors};

        if(!value) {
            currentErrorState["actName"] = "This field is required!";
        } else {
            delete currentErrorState["actName"]
        }

        this.errors = currentErrorState;
    }

    @action
    validateInvitedUserFirstName(value) {
        const nameRegex = /^[a-z ,.'-]+$/i

        let currentErrorState = {...this.errors};

        if (!this.invitedUserFirstName.length === 0) {
            currentErrorState["firstName"] = 'This field is required'
        } else if (!nameRegex.test(value)) {
            currentErrorState["firstName"] = 'Invalid first name';
        } else {
            delete currentErrorState["firstName"]
        }
        this.errors = currentErrorState;
    }

    @action
    validateInvitedUserLastName(value) {
        const nameRegex = /^[a-z ,.'-]+$/i

        let currentErrorState = {...this.errors};

        if (!this.invitedUserLastName.length === 0) {
            currentErrorState["lastName"] = 'This field is required'
        } else if (!nameRegex.test(value)) {
            currentErrorState["lastName"] = 'Invalid last name';
        } else {
            delete currentErrorState["lastName"]
        }
        this.errors = currentErrorState;
    }

    @action
    validateInvitedUserEmail(value) {
        /* eslint-disable no-useless-escape*/
        var email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        /* eslint-enable no-useless-escape*/

        let currentErrorState = {...this.errors};
        if (!email_regex.test(value)) {
            currentErrorState["email"] = "Invalid Email";
        } else {
            currentErrorState = undefined;
        }

       
        
        this.errors = currentErrorState;
        
    }

    validateActOwnerFields = () => {
        if (!this.invitedActName || this.invitedActName.length === 0) {
            if (!this.errors) {
                this.errors = [];
            }
            this.errors["actName"] = "This field is required!";
        }

        if (!this.invitedUserEmail || this.invitedUserEmail.length === 0) {
            if (!this.errors) {
                this.errors = [];
            }
            this.errors["email"] = "Act owner email is required!";
        }
    };

    validateStaffAgentFields = () => {
        if (!this.invitedUserEmail || this.invitedUserEmail.length === 0) {
            if (!this.errors) {
                this.errors = [];
            }
            this.errors["email"] = "Agent's email is required!";
        }
    };

    validateVenueOwnerFields = () => {
        if (!this.invitedVenueName || this.invitedVenueName.length === 0) {
            if (!this.errors) {
                this.errors = [];
            }
            this.errors["venueName"] = "Venue name is required!";
        }

        if (!this.invitedUserEmail || this.invitedUserEmail.length === 0) {
            if (!this.errors) {
                this.errors = [];
            }
            this.errors["email"] = "Venue owner email is required!";
        }
    };

    @action
    getMyUserInvitation(type, profileId) {
        this.invitations = [];
        this.isLoadingUserInvitations = true;
        const currentProfile = profileStore.getCurrentProfile()
        
        if (!profileId) {
            if(currentProfile!=undefined){
                profileId = currentProfile.id;
            }
        }

        return agent.UserInvitation.getMyInvitation(profileId, type).then(
            action(response => {
                this.invitations = []
                response.map(async data => {
                    if(data.actImage) {
                        data.actImage = await CacheHelper.getImgLink(data.actImage.url200)
                    }
                    if(data.ownerImage) {
                        data.ownerImage = await CacheHelper.getImgLink(data.ownerImage.url200)
                    }
                    if (data.venue) {
                        await profileStore.mapAdditionalProfileDataAsync(data.venue);
                    }
                    runInAction(() => {
                        this.invitations.push(data)
                    })
                    
                });
                return this.invitations;
            })
        ).finally(
            action(() => {
                this.isLoadingUserInvitations = false;
            })
        );
    }

    @action
    async getUserInvitationByEmailAndType(type) {
        const { currentUser } = userStore;
        if (currentUser) {
            let result = []
            const response = await agent.UserInvitation.getUserInvitationByUserEmailAndType(currentUser.email, type);
            for (const data of response) {
                await this.mapAdditionalProfileData(data.createdBy)
                result.push(data);
            }
            return result
        } else {
            return Promise.reject("No User Found");
        }
    }

    @action
    acceptUserInvitation(invitationId) {
        return agent.UserInvitation.accept(invitationId).then(response => {
            return response;
        });
    }

    @action
    declineUserInvitation(invitationId) {
        return agent.UserInvitation.decline(invitationId).then(response => {
            return response;
        });
    }

    @action
    async getInvitationByAgentIdPlaceId(agentId,placeId) {
        return agent.UserInvitation.getByAgentIdPlaceId(agentId,placeId).then(async response => {
            if(response.venue){
                await profileStore.mapProfiles([response.venue])
                console.log(response.venue)
            }
            return response;
        });
    }
    @action
    async createVenueExternalInvitation(data,urls) {
        const { invitationMessage, dayToBookVenueInvitation } = agentVenueStore;
        if(data.email && data.venueWebsite){
            var websiteClean = data.venueWebsite.replace('//','.')
            websiteClean = websiteClean.replace('/','.')
            websiteClean = websiteClean.replace(':','.')
            var webiste = websiteClean.split('.');
            var emailArr = data.email.split('@');
            var domainArr = emailArr[1].split(".")
            var domain = domainArr[0]
            var isMatch = false

            webiste.forEach(item => {
                if(item === domain){
                    isMatch = true
                }
            });
            if(!isMatch){
                let currentErrorState = {...this.errors};

                // currentErrorState["email"] = "email address must the same domain as the venue website.";
                currentErrorState["emailInvalid"] = true;
                this.errors = currentErrorState;
                return Promise.reject("email address must the same domain as the venue website.");
            }else{
                currentErrorState["emailInvalid"] = false;
                this.errors = currentErrorState;
            }

           

        }
        data.additionalData= JSON.stringify(dayToBookVenueInvitation)
        return agent.VenueExternalInvitation.create(data).then(async (res) => {
            if(res && res?.isSuccess){
                urls.forEach(async url => {
                    await designProfileStore.setBackgroundImageCustom(url, res?.data?.id)
                })
                if(urls && urls.length){
                    await designProfileStore.setProfilePictureVenueCustom(urls[0], res?.data?.id)
                }
            }else{
                if(res?.data){
                    var profile = res.data
                    var profiles = [profile]
                    await profileStore.mapProfiles(profiles);
                    agentVenueStore.setInvitedProfile(profiles[0])
                    // runInAction(() => {
                        
                       
                    // })
                   
                }
                return Promise.reject(res? res?.message : "general error");
            }
          
        }).catch(err => {
            return Promise.reject(err);
        })

       
    }

    @action
    async inviteActOwner() {
       
        this.validateActOwnerFields();
        const { currentProfile, profile } = profileStore;
        if (this.errors && !(this.errors 
            && Object.keys(this.errors).length === 0
            && Object.getPrototypeOf(this.errors) === Object.prototype)) {
            
                return Promise.reject("Required fields are empty!");
        }
        var relation = 0;

        await authStore.checkEmailValidWithEmail(this.invitedUserEmail).then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                if (currentProfile.type === ProfileType.Agent.ordinal) {
                    relation = ProfileRelationType.AgentAct.ordinal;
                } else if (currentProfile.type === ProfileType.Staff.ordinal || profile === ProfileType.Venue.ordinal) {
                    relation = ProfileRelationType.VenueAct.ordinal;
                }
        
                let invitationData = {
                    invitedFirstName: this.invitedUserFirstName,
                    invitedLastName: this.invitedUserLastName,
                    invitedEmail: this.invitedUserEmail,
                    invitedAs: "act",
                    invitedAct: this.invitedActName,
                    invitationMessage: this.invitationMessage,
                    createdById: profile.id,
                    relation: relation,
                    actType: this.actType,
                    actLineup: this.actLineup
                };
        
                return agent.UserInvitation.create(invitationData).then(
                    action(async response => {
                        runInAction(async () => {
                            if(Array.isArray(response)) {
                                for(let i = 0; i < response.length; i++) {
                                    await this.invitations.push(response[i])
                                }
                            } else {
                                this.invitations.push(response)
                            }
                        })
                        return this.invitations;
                    }),
                    action(err => {
                        this.errors = [];
                        this.errors["email"] = err.response.data.errors;
                        return Promise.reject(err.response.data.errors);
                    })
                );
            }else{
                runInAction(async () => {
                    this.errors = [];
                    this.errors["email"] = "We have detected that you are trying to add a user using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.  Please use a permanent email address to invite this user.";
                
                })
                return Promise.reject("Invalid Email!");
            }
        })

   
    }

    @action
    async inviteActOwnerByStaff() {
        this.validateActOwnerFields();
        const { currentProfile } = profileStore;

        const { invitedActsVenues, invitationMessage } = venueActStore;
       
        if (this.errors && !(this.errors 
        && Object.keys(this.errors).length === 0
        && Object.getPrototypeOf(this.errors) === Object.prototype)) {
        
            
        }

        await authStore.checkEmailValidWithEmail(this.invitedUserEmail).then(action(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                var invitedVenuesIds = "";

                invitedActsVenues.forEach(venue => {
                    invitedVenuesIds += venue.id + ", ";
                });
        
                invitedVenuesIds = invitedVenuesIds.substr(0, invitedVenuesIds.length - 2);
        
                let invitationData = {
                    invitedFirstName: this.invitedUserFirstName,
                    invitedLastName: this.invitedUserLastName,
                    invitedEmail: this.invitedUserEmail,
                    invitedAs: "act",
                    invitedAct: this.invitedActName,
                    invitedVenue: invitedVenuesIds,
                    invitationMessage: invitationMessage,
                    createdById: currentProfile.id,
                    relation: ProfileRelationType.VenueAct.ordinal,
                    actType: this.actType,
                    actLineup: this.actLineup
                };
        
                return agent.UserInvitation.create(invitationData).then(
                    action(response => {
                        return this.getMyUserInvitation(ProfileRelationType.VenueAct.ordinal);
                    }),
                    action(err => {
                        this.errors = [];
                        this.errors["email"] = Object.values(err.response.data.errors);
                        return Promise.reject(Object.values(err.response.data.errors));
                    })
                );
            }else{
                this.errors = [];
                this.errors["email"] = "We have detected that you are trying to add a user using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.  Please use a permanent email address to invite this user.";
                return Promise.reject("Invalid Email!");
            }
        }))

      
    }

    @action
    async inviteVenueAgent(venueId, manage, manageSocialPost) {
        this.validateStaffAgentFields();

        const { profile } = profileStore;

        const { invitationMessage, dayToBook } = agentVenueStore;

        if (this.errors && !(this.errors 
            && Object.keys(this.errors).length === 0
            && Object.getPrototypeOf(this.errors) === Object.prototype)) {
            
                return Promise.reject("Required fields are empty!");
            }

        let invitationData = {
            invitedFirstName: this.invitedUserFirstName,
            invitedLastName: this.invitedUserLastName,
            invitedEmail: this.invitedUserEmail,
            invitedAs: "agent",
            invitationMessage: invitationMessage,
            invitedVenue: venueId + "",
            createdById: profile.id,
            relation: ProfileRelationType.AgentVenue.ordinal,
            additionalData: JSON.stringify(dayToBook),
            manage: manage, 
            manageSocialPost: manageSocialPost
        };
        await authStore.checkEmailValidWithEmail(this.invitedUserEmail).then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                return agent.UserInvitation.create(invitationData).then(
                    action(response => {
                        return this.getMyUserInvitation(ProfileRelationType.AgentVenue.ordinal, venueId);
                    }),
                    action(err => {
                        this.errors = [];
                        this.errors["email"] = err.response.data.errors
                        return Promise.reject(err.response.data.errors);
                    })
                )
            }else{
                runInAction(async () => {
                    this.errors = [];
                    this.errors["email"] = "We have detected that you are trying to add a user using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.  Please use a permanent email address to invite this user.";
                })
                return Promise.reject("Invalid Email!");
            }
        })
    }

    @action
    async inviteStaffAgent(manage, manageSocialPost) {
        this.validateStaffAgentFields();

        const { currentProfile } = profileStore;

        const { invitationMessage, dayToBook, invitedVenues } = agentVenueStore;

        if (this.errors && !(this.errors 
            && Object.keys(this.errors).length === 0
            && Object.getPrototypeOf(this.errors) === Object.prototype)) {
            
                return Promise.reject("Required fields are empty!");
            }

        await authStore.checkEmailValidWithEmail(this.invitedUserEmail).then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                var invitedVenuesIds = "";

                invitedVenues.forEach(venue => {
                    invitedVenuesIds += venue.id + ", ";
                });

                invitedVenuesIds = invitedVenuesIds.substr(0, invitedVenuesIds.length - 2);

                let invitationData = {
                    invitedFirstName: this.invitedUserFirstName,
                    invitedLastName: this.invitedUserLastName,
                    invitedEmail: this.invitedUserEmail,
                    invitedAs: "agent",
                    invitationMessage: invitationMessage,
                    invitedVenue: invitedVenuesIds,
                    createdById: currentProfile.id,
                    relation: ProfileRelationType.AgentVenue.ordinal,
                    additionalData: JSON.stringify(dayToBook),
                    manage: manage, 
                    manageSocialPost: manageSocialPost
                };

                return agent.UserInvitation.create(invitationData).then(
                    action(response => {
                        return this.getMyUserInvitation(ProfileRelationType.AgentVenue.ordinal);
                    }),
                    action(err => {
                        this.errors = [];
                        this.errors["email"] = err.response.data.errors;
                        return Promise.reject(err.response.data.errors);
                    })
                );
            }else{
                runInAction(() => {
                    this.errors = [];
                    this.errors["email"] = "We have detected that you are trying to add a user using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.  Please use a permanent email address to invite this user.";
                })
             
                return Promise.reject("Invalid Email!");
            }
        })
        
    }

    @action
    async inviteVenueOwnerByAgent() {
        await authStore.checkEmailValidWithEmail(this.invitedUserEmail).then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                this.validateVenueOwnerFields();
                const { currentProfile } = profileStore;

                const { invitationMessage, dayToBook } = agentVenueStore;
                if (this.errors && !(this.errors 
                    && Object.keys(this.errors).length === 0
                    && Object.getPrototypeOf(this.errors) === Object.prototype)) {
                    
                        return Promise.reject("Required fields are empty!");
                    }

                let invitationData = {
                    invitedFirstName: this.invitedUserFirstName,
                    invitedLastName: this.invitedUserLastName,
                    invitedEmail: this.invitedUserEmail,
                    invitedAs: "venue",
                    invitedVenue: this.invitedVenueName,
                    invitationMessage: invitationMessage,
                    createdById: currentProfile.id,
                    relation: ProfileRelationType.AgentVenue.ordinal,
                    additionalData: JSON.stringify(dayToBook)
                };

                return agent.UserInvitation.create(invitationData).then(
                    action(response => {
                        this.invitations.push(response);
                        return this.invitations;
                    }),
                    action(err => {
                        this.errors = [];
                        this.errors["email"] = err.response.data.errors;
                        return Promise.reject(err.response.data.errors);
                    })
                );
            }
            else{
                runInAction(async () => {
                    this.errors = [];
                    this.errors["email"] = "We have detected that you are trying to add a user using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.  Please use a permanent email address to invite this user.";
                
                })
                return Promise.reject("Invalid Email!");
            }
        })
    }

    @action
    resendInvitation(invitation) {
        const { currentProfile } = profileStore;
        if(invitation?.id){
            return agent.UserInvitation.resendLink(invitation?.id,currentProfile?.id)
        }
    }

    @action
    deleteInvitation(invitation, profileId) {

        const { currentProfile } = profileStore;

        var invitationModelDelete = {
            id : invitation.id,
            invitedVenue : invitation.invitedVenue
        }

        if(invitation.relation == ProfileRelationType.AgentAct.ordinal || invitation.relation == ProfileRelationType.VenueAct.ordinal ) {
            invitationModelDelete.Relation = invitation.relation;
        }

        return agent.UserInvitation.delete(invitationModelDelete).then(
            action(response => {
                if (invitation.relation === ProfileRelationType.AgentAct.ordinal) {
                    return this.getMyUserInvitation(ProfileRelationType.AgentAct.ordinal)
                } else if (invitation.relation === ProfileRelationType.AgentVenue.ordinal) {
                    return this.getMyUserInvitation(ProfileRelationType.AgentVenue.ordinal)
                } else if (invitation.relation === ProfileRelationType.VenueAct.ordinal) {
                    return this.getMyUserInvitation(ProfileRelationType.VenueAct.ordinal, profileId)
                }
                this.getMyUserInvitation(ProfileRelationType.AgentConsultant.ordinal)
            })
        );
    }

    @action
    deleteInvitations(invitations) {
        return agent.UserInvitation.deleteMany(invitations).then(
            action(response => {
                response.forEach(data => {
                    this.invitations = this.invitations.filter(x => x.id !== data.id);
                });
                return this.invitations;
            })
        );
    }

    @action
    async mapAdditionalProfileData(profile) {
        await profileStore.mapAdditionalProfileImageAndBackgroundImages(profile);
        profileStore.mapAdditionalProfileNames(profile);
    }
}

export default new UserInvitationStore();
