import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link, withRouter } from "react-router-dom";

import Music from "./../../img/music.svg";
import SongListMedia from "./SongListMedia";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import "./SongListProfile.css";

@inject("songListStore")
@withRouter
@observer
class SongListProfile extends Component {
    materialColor = [
        "#4CAF50",
        "#00BCD4",
        "#3F51B5",
        "#795548",
        "#673AB7",
        "#F44336",
        "#9C27B0",
        "#E91E63",
        "#8BC34A",
        "#FF9800",
        "#2196F3",
        "#03A9F4",
        "#FF5722",
        "#009688",
        "#CDDC39",
        "#FFC107"
    ];

    render() {
        let topSongs = this.props.songs.filter(el => {
            return el.id && el.topProfile === true;
        });
        let lowSongs = this.props.songs.filter(el => {
            return el.id && el.topProfile === false;
        });

        if (topSongs.length < 12) {
            let index = 0;
            while (topSongs.length < 12 && lowSongs.length !== 0) {
                topSongs.push(lowSongs[index]);
                index += 1;
            }
        }
        return (
            <div>
                <Grid container spacing={16}>
                    {topSongs.filter(x => x && x.id).map(song => {
                        return (
                            <Grid item xs={12} md={4} key={song.id}>
                                <Paper className="song">
                                    <Grid container justify="center" alignItems="center">
                                        <Grid item xs={3} className="text-center">
                                            <img src={Music} alt="music logo" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">{song.title}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {song.artist}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} className="text-center">
                                            <SongListMedia media={song.mediaUrl} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        );
                    })}

                    {this.props.songs.length > 12 ? (
                        <Grid item sm={12} className="text-center">
                            {/* <a href="/musician/songs" target="_blank">
                                View More
                            </a> */}
                            <Button component={Link} to={this.props.location.pathname + "/songs"}>
                                View More
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>
            </div>
        );
    }
}

export default withMobileDialog()(SongListProfile);
