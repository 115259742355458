import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../img/hotgiggity-logo.png";
import Switch from "@material-ui/core/Switch";
import poweredByLogo from "../../img/powered_by_hg_logo.png";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import reactCSS from "reactcss";
import { Select, MenuItem } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import CardActionArea from '@material-ui/core/CardActionArea';
import { IconButton } from "@material-ui/core";
import "./CustomEmbedGigWidget.css";
import CloseIcon from "@material-ui/icons/Close";
import { SketchPicker } from "react-color";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
const BASE_URL = process.env.REACT_APP_BASE_URL;
import "./CustomEmbedGigWidget.css";
const blankBackground = "/hero2.jpg";


import profile1 from "../../img/ned.png";
import profile2 from "../../img/liam.png";
import profile3 from "../../img/emily.png";
import profile4 from "../../img/cooper.png";

import profileVenue1 from "../../img/easytime.jpg";
import profileVenue2 from "../../img/gardenkitchenbar.jpg";
import profileVenue3 from "../../img/hotelrichland.jpg";
import profileVenue4 from "../../img/miltoncommon.jpg";

// import profile1 from "../../img/ned.png";
// import profile2 from "../../img/liam.png";
// import profile3 from "../../img/emily.png";
// import profile4 from "../../img/cooper.png";

import popUpIcon from "../../img/popup-open-icon.png";
import { EmbedType, ProfileType } from "../../types/enum";

const bookingGenresString = [
    "Chillout", "Reggae", "Rock", "Pop", "Accoustic", "Happy Hardcore", "PsyTrance", "Country"
]

var temporaryData = []
const profileAct = [
    {
        name : "Ned Walker",
        photo:  profile1,
        date: 4,
        bio: "Ned has been a professional artist and entertainer for over 25 years across three continents. Ned has supported artists and bands such as Blondie, Spider Bait, The Living End, Eskimo Joe, Grinspoon and Sneaky Sound System. In his gigs, Ned plays a mix of guitar and piano and covers tunes from all decades and genres, from easy listening to crowd-pleasing bangers!"
    },
    {
        name : "Liam Byant",
        photo:  profile2,
        bio: "Liam Bryant is a writer of endearing, emotive and eminent songs and loves to perform with his ever faithful acoustic stompbox named Stompy and a foot tambourine named Tamby.",
        date: 5
    },
    {
        name : "Emily Hatton",
        photo:  profile3,
        date: 6,
        bio: "Oh hey. My names Emily, but you can call me Em. My biggest obsession is songs; writing them, and singing them on stage whilst strumming my Martin guitar. It's my dream to perform songs I've written on a stadium stage, while the crowd holds up thousands of little lights, and sings those lyrics with me.",
    },
    {
        name : "Cooper Flick",
        photo:  profile4,
        date: 7,
        bio: "Out of Brisbane's beautiful Moreton Bay comes the multi-talented Cooper Flick - a 20-year-old self-accompanied singer-songwriter with a deep affinity for rock, country and indie music."
    },
    {
        name : "Ned Walker",
        photo:  profile1,
        date: 11,
        bio: "Ned has been a professional artist and entertainer for over 25 years across three continents. Ned has supported artists and bands such as Blondie, Spider Bait, The Living End, Eskimo Joe, Grinspoon and Sneaky Sound System. In his gigs, Ned plays a mix of guitar and piano and covers tunes from all decades and genres, from easy listening to crowd-pleasing bangers!"
    },
    {
        name : "Liam Byant",
        photo:  profile2,
        bio: "Liam Bryant is a writer of endearing, emotive and eminent songs and loves to perform with his ever faithful acoustic stompbox named Stompy and a foot tambourine named Tamby.",
        date: 12
    },
    {
        name : "Emily Hatton",
        photo:  profile3,
        bio: "Oh hey. My names Emily, but you can call me Em. My biggest obsession is songs; writing them, and singing them on stage whilst strumming my Martin guitar. It's my dream to perform songs I've written on a stadium stage, while the crowd holds up thousands of little lights, and sings those lyrics with me.",
        date: 13
    },
    {
        name : "Cooper Flick",
        photo:  profile4,
        date: 14,
        bio: "Out of Brisbane's beautiful Moreton Bay comes the multi-talented Cooper Flick - a 20-year-old self-accompanied singer-songwriter with a deep affinity for rock, country and indie music."
    },
    {
        name : "Ned Walker",
        photo:  profile1,
        date: 18,
        bio: "Ned has been a professional artist and entertainer for over 25 years across three continents. Ned has supported artists and bands such as Blondie, Spider Bait, The Living End, Eskimo Joe, Grinspoon and Sneaky Sound System. In his gigs, Ned plays a mix of guitar and piano and covers tunes from all decades and genres, from easy listening to crowd-pleasing bangers!"
    },
    {
        name : "Liam Byant",
        photo:  profile2,
        bio: "Liam Bryant is a writer of endearing, emotive and eminent songs and loves to perform with his ever faithful acoustic stompbox named Stompy and a foot tambourine named Tamby.",
        date: 19
    },
    {
        name : "Emily Hatton",
        photo:  profile3,
        date: 20,
        bio: "Oh hey. My names Emily, but you can call me Em. My biggest obsession is songs; writing them, and singing them on stage whilst strumming my Martin guitar. It's my dream to perform songs I've written on a stadium stage, while the crowd holds up thousands of little lights, and sings those lyrics with me.",
    },
    {
        name : "Cooper Flick",
        photo:  profile4,
        date: 21,
        bio: "Out of Brisbane's beautiful Moreton Bay comes the multi-talented Cooper Flick - a 20-year-old self-accompanied singer-songwriter with a deep affinity for rock, country and indie music."
    }


]

const profileVenue = [
    {
        name : "Easy Time",
        photo:  profileVenue1,
        date: 4,
        bio: `Fresh beers, a warm welcome and top tunes. Easy Does It.

        Easy Times Brewing Co is a small independent brewery at The Gabba where you can kick back and relax with some freshly made, handcrafted local beers brewed under the Queensland sun.`
    },
    {
        name : "Garden Kitchen Bar",
        photo:  profileVenue2,
        date: 5,
        bio: `Garden Beats...

        Set the scene for a smokin night at Garden Kitchen & Bar, with a Dj every Friday night and Vinyl On The Deck every Saturday from 7pm. Because lets face it, drinks taste better with a little old school funk on the side.
        
         
        
        Looking to wind down the weekend in style? Join us every Sunday for live music on the deck.`
     
    },
    {
        name : "Richies Game On",
        photo:  profileVenue3,
        date: 6,
        bio: `In 2006, the Star Group became the proud owners of the Richlands Tavern. In 2009, a multi-million dollar renovation took place which transformed the hotel’s dining area’s, bars and gaming rooms. With the inclusion of an all-you-can-eat buffet and a rebrand to what we all now know as Hotel Richlands, this venue is a favourite venue in the local community.

        Bring the family down to Hotel Richlands – a great place to have fun!`},
    {
        name : "Milton Common",
        photo:  profileVenue4,
        date: 7,
        bio: `MILTON COMMON IS WHERE ALL THINGS BEER, FOOD, AND COMMUNITY COME TOGETHER.

        Ice-cold libations and the best craft beer around? We've got it. Delicious food? We've got that too! A space for the crew or the whole family to enjoy?
        
        You'll find it here at Milton Common.`},
        {
            name : "Easy Time",
            photo:  profileVenue1,
            date: 11,
            bio: `Fresh beers, a warm welcome and top tunes. Easy Does It.
    
            Easy Times Brewing Co is a small independent brewery at The Gabba where you can kick back and relax with some freshly made, handcrafted local beers brewed under the Queensland sun.`
        },
        {
            name : "Garden Kitchen Bar",
            photo:  profileVenue2,
            date: 12,
            bio: `Garden Beats...
    
            Set the scene for a smokin night at Garden Kitchen & Bar, with a Dj every Friday night and Vinyl On The Deck every Saturday from 7pm. Because lets face it, drinks taste better with a little old school funk on the side.
            
             
            
            Looking to wind down the weekend in style? Join us every Sunday for live music on the deck.`
         
        },
        {
            name : "Richies Game On",
            photo:  profileVenue3,
            date: 13,
            bio: `In 2006, the Star Group became the proud owners of the Richlands Tavern. In 2009, a multi-million dollar renovation took place which transformed the hotel’s dining area’s, bars and gaming rooms. With the inclusion of an all-you-can-eat buffet and a rebrand to what we all now know as Hotel Richlands, this venue is a favourite venue in the local community.
    
            Bring the family down to Hotel Richlands – a great place to have fun!`},
        {
            name : "Milton Common",
            photo:  profileVenue4,
            date: 14,
            bio: `MILTON COMMON IS WHERE ALL THINGS BEER, FOOD, AND COMMUNITY COME TOGETHER.
    
            Ice-cold libations and the best craft beer around? We've got it. Delicious food? We've got that too! A space for the crew or the whole family to enjoy?
            
            You'll find it here at Milton Common.`},
    {
        name : "Easy Time",
        photo:  profileVenue1,
        date: 18,
        bio: `Fresh beers, a warm welcome and top tunes. Easy Does It.

        Easy Times Brewing Co is a small independent brewery at The Gabba where you can kick back and relax with some freshly made, handcrafted local beers brewed under the Queensland sun.`
    },
    {
        name : "Garden Kitchen Bar",
        photo:  profileVenue2,
        date: 19,
        bio: `Garden Beats...

        Set the scene for a smokin night at Garden Kitchen & Bar, with a Dj every Friday night and Vinyl On The Deck every Saturday from 7pm. Because lets face it, drinks taste better with a little old school funk on the side.
        
         
        
        Looking to wind down the weekend in style? Join us every Sunday for live music on the deck.`
     
    },
    {
        name : "Richies Game On",
        photo:  profileVenue3,
        date: 20,
        bio: `In 2006, the Star Group became the proud owners of the Richlands Tavern. In 2009, a multi-million dollar renovation took place which transformed the hotel’s dining area’s, bars and gaming rooms. With the inclusion of an all-you-can-eat buffet and a rebrand to what we all now know as Hotel Richlands, this venue is a favourite venue in the local community.

        Bring the family down to Hotel Richlands – a great place to have fun!`},
    {
        name : "Milton Common",
        photo:  profileVenue4,
        date: 21,
        bio: `MILTON COMMON IS WHERE ALL THINGS BEER, FOOD, AND COMMUNITY COME TOGETHER.

        Ice-cold libations and the best craft beer around? We've got it. Delicious food? We've got that too! A space for the crew or the whole family to enjoy?
        
        You'll find it here at Milton Common.`},
   


]
@inject("embedGigStore","profileStore")
@observer
class CustomEmbedGigWidget extends Component {
    state = {
        open: false,
        background: "#fff",
        dialogHeaderBgColor: false,
        dialogHeaderFtColor: false,
        dialogDateBgColor: false,
        dialogDateFtColor: false,
        dialogTableBgColor: false,
        dialogTableFtColor: false,
        dialogWidgetBgColor: false, 
        tab: 0,
        detailsOn: false,
        indexOn: 0
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    // === header section ===
    handleHeaderBgColorChangeComplete = color => this.props.embedGigStore.setHeaderBgColor(color.hex);
    handleHeaderFontColorChangeComplete = color => this.props.embedGigStore.setHeaderFtColor(color.hex);
    handleHeaderBgColorPickerClick = () => this.setState({ dialogHeaderBgColor: !this.state.dialogHeaderBgColor });
    handleHeaderBgColorPickerClose = () => this.setState({ dialogHeaderBgColor: false });
    handleHeaderFtColorPickerClick = () => this.setState({ dialogHeaderFtColor: !this.state.dialogHeaderFtColor });
    handleHeaderFtColorPickerClose = () => this.setState({ dialogHeaderFtColor: false });
    // =======================

    // === date section ===
    handleDateBgColorChangeComplete = color => this.props.embedGigStore.setDateBgColor(color.hex);
    handleDateFontColorChangeComplete = color => this.props.embedGigStore.setDateFtColor(color.hex);
    handleDateBgColorPickerClick = () => this.setState({ dialogDateBgColor: !this.state.dialogDateBgColor });
    handleDateBgColorPickerClose = () => this.setState({ dialogDateBgColor: false });
    handleDateFtColorPickerClick = () => this.setState({ dialogDateFtColor: !this.state.dialogDateFtColor });
    handleDateFtColorPickerClose = () => this.setState({ dialogDateFtColor: false });
    // =======================

    // === table section ===
    handleTableBgColorChangeComplete = color => this.props.embedGigStore.setTableBgColor(color.hex);
    handleTableFontColorChangeComplete = color => this.props.embedGigStore.setTableFtColor(color.hex);
    handleTableBgColorPickerClick = () => this.setState({ dialogTableBgColor: !this.state.dialogTableBgColor });

    handleTableWidgetBgColorPickerClick = () => this.setState({ dialogWidgetBgColor: !this.state.dialogTableBgColor });
    handleTableWidgetBgColorPickerClose = () => this.setState({ dialogWidgetBgColor: false });

    handleTableWidgetBgColorPickerComplete = color => this.props.embedGigStore.setTableWidgetBgColor(color.hex);

    handleTableBgColorPickerClose = () => this.setState({ dialogTableBgColor: false });
    handleTableFtColorPickerClick = () => this.setState({ dialogTableFtColor: !this.state.dialogTableFtColor });
    handleTableFtColorPickerClose = () => this.setState({ dialogTableFtColor: false });
    // =======================

    handleNumberEventsChange = e => this.props.embedGigStore.setNumberEvent(e.target.value);
    // === toolbar ===
    toggleHideToolbar = () => {
        const hide = this.props.embedGigStore.hideToolbar;
        this.props.embedGigStore.setHideToolbar(!hide);
    };
    toggleEnabledTransparent = () => {
        const hide = this.props.embedGigStore.enabledTransparent;
        this.props.embedGigStore.setEnabledTransparent(!hide);
        this.props.embedGigStore.setTableBgColor("#fff");
    };

    
    setEmbedType = (e) => {
        this.props.embedGigStore.setEmbedType(e.target.value);
    };
    
    // =======================

    // === order uber ===
    toggleHideOrderUber = () => {
        const hide = this.props.embedGigStore.hideOrderUber;
        this.props.embedGigStore.setHideOrderUber(!hide);
    };
    // =======================

    // === share button ===
    toggleHideShareButton = () => {
        const hide = this.props.embedGigStore.hideShareButton;
        this.props.embedGigStore.setHideShareButton(!hide);
    };
    // =======================

    handleSaveEmbedCode = () => {
        const profileId = this.props.profileId;
        this.props.embedGigStore.createEmbedCode(profileId).then(() => {
            this.handleClose();
        });
    };
    handleChangeTab = (event, value) => {
        this.setState({ tab: value });
    };

   

    renderRowItem = (index, tableBgColor, tableFtColor, dateBgColor, dateFtColor) => {
        return (
            <TableRow key={index} style={{ verticalAlign: 'top' }}>
                <TableCell style={{ padding: 0, width: '40%', backgroundColor: tableBgColor }} className="td-custom">
                    <Card>
                        <CardMedia
                            title="This is cover title"
                            component="img"
                            image={BASE_URL + "hero2.jpg"}
                        />
                    </Card>
                </TableCell>
                <TableCell style={{ backgroundColor: tableBgColor, padding: 5 }} className="td-custom">
                    <div style={{ padding: 0 }}>
                        <Typography variant="p" gutterBottom style={{ color: tableFtColor, display: 'inline', borderBottom: 'solid 1px ' + tableFtColor, fontSize: 10, cursor: 'pointer' }}>
                            {this.props.profile?.type === ProfileType.Musician.ordinal || this.props.profile?.type === ProfileType.Act.ordinal ? "Awesome Venue" : "Awesome Act"}
                        </Typography>
                        <Typography component="p" gutterBottom style={{ fontStyle: 'italic', color: tableFtColor, fontSize: 8 }}>
                            Pop, Acoustic, Rock
                        </Typography>
                        <Typography variant="p" style={{ color: tableFtColor, fontSize: 9 }}>
                            Saturday, 17 August 2019 10.27am - 10.27pm
                        </Typography>
                        <Typography variant="p" gutterBottom style={{ color: tableFtColor, fontSize: 9 }}>
                            Green Valley Venue <a target="_blank" href={`https://maps.google.com/?q=-27.470125,153.021072`}>(Get Directions)</a>
                        </Typography>
                        <Typography component="p" style={{ color: tableFtColor, fontSize: 8 }}>
                        Hot Giggity is the only platform for the live entertainment industry where all stakeholders are always on the same page.
                        </Typography>
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    renderRowItemText = (index, tableBgColor, tableFtColor, dateBgColor, dateFtColor) => {
        return (
            <TableRow key={index} style={{ verticalAlign: 'top' }}>
                <TableCell style={{ backgroundColor: tableBgColor,width:'33.33%', padding: 5,textAlign:'left' }} className="td-custom">
                    <Typography variant="p" style={{ color: tableFtColor, fontSize: 10,textAlign:'left'  }}>
                        DD / Date
                    </Typography>
                </TableCell>
                <TableCell style={{ backgroundColor: tableBgColor,width:'33.33%', padding: 5,textAlign:'left' }} className="td-custom">
                    <Typography variant="p" style={{ color: tableFtColor, fontSize: 10,textAlign:'left' }}>
                        08:00pm-11:00pm
                    </Typography>
                </TableCell>
                <TableCell style={{ backgroundColor: tableBgColor,width:'33.33%', padding: 5,textAlign:'left' }} className="td-custom">
                    <Typography variant="p" style={{ color: tableFtColor, fontSize: 10,textAlign:'left' }}>
                        {this.props.profile?.type === ProfileType.Musician.ordinal || this.props.profile?.type === ProfileType.Act.ordinal ? "Awesome Venue" : "Awesome Act"}
                    </Typography>
                        
                </TableCell>
            </TableRow>
        );
    };

    goToDetails = (index) =>{
        this.setState({
            detailsOn : true,
            indexOn: index
        })
    }
    handleCloseDetails = () =>{
        this.setState({
            detailsOn : false,
            indexOn: 0
        })
    }
    renderPreview = (headerBgColor, headerFtColor, tableBgColor, tableFtColor, dateBgColor, dateFtColor, hideToolbar, hideOrderUber, hideShareButton, numberEvent) => {
        var arrayThis = []
        if(numberEvent){
            if (numberEvent === 0){
                numberEvent = 1
            }else if(numberEvent > 5){
                numberEvent = 5
            }
           
        }else{
            numberEvent = 5
        }
        for(var i = 0; i<numberEvent;i++){
            arrayThis.push(i)
        }
        return (
            <Fragment>
                {!hideToolbar && (
                    <Toolbar style={{ width: "100%", display:"flex", justifyContent:"space-between", padding:"0px" }}>
                        <Typography variant="body1">John Doe's Upcoming Gigs</Typography>
                        <span style={{display:"flex", flexDirection:"column", alignItems:"start", justifyContent:"flex-end", width:"10rem"}}>
                            <Typography variant="caption" className="td-custom">
                                Powered By
                            </Typography>
                            <img alt="hotgiggity logo" src={Logo} width="50%" />
                        </span>
                    </Toolbar>
                )}
                <Table className="preview-table" fixedHeader>
   
                    <TableBody>
                        {arrayThis.map(num => {
                            return this.renderRowItem(num, tableBgColor, tableFtColor, dateBgColor, dateFtColor);
                        })}
                    </TableBody>
                </Table>
                {hideToolbar && (
                    <Toolbar style={{ width: "100%", display:"flex", justifyContent:"flex-end" }}>
                        <span style={{display:"flex", flexDirection:"column", alignItems:"start", justifyContent:"flex-end", width:"10rem"}}>
                            <Typography variant="caption" className="td-custom">
                                Powered By
                            </Typography>
                            <img src={Logo} alt="hotgiggity logo" width="50%" />
                        </span>
                    </Toolbar>
                )}
            </Fragment>
        );
    };

    renderPreviewText = (headerBgColor, headerFtColor, tableBgColor, tableFtColor, dateBgColor, dateFtColor, hideToolbar, hideOrderUber, hideShareButton, numberEvent) => {
        var arrayThis = []
        if(numberEvent){
            if (numberEvent === 0){
                numberEvent = 1
            }else if(numberEvent > 5){
                numberEvent = 5
            }
           
        }else{
            numberEvent = 5
        }
        for(var i = 0; i<numberEvent;i++){
            arrayThis.push(i)
        }
        return (
            <Fragment>
                {!hideToolbar && (
                    <Toolbar style={{ width: "100%", display:"flex", justifyContent:"space-between", padding:"0px" }}>
                        <Typography variant="body1">John Doe's Upcoming Gigs</Typography>
                        <span style={{display:"flex", flexDirection:"column", alignItems:"start", justifyContent:"flex-end", width:"10rem"}}>
                            <Typography variant="caption" className="td-custom">
                                Powered By
                            </Typography>
                            <img alt="hotgiggity logo" src={Logo} width="50%" />
                        </span>
                    </Toolbar>
                )}
                <Table className="preview-table" fixedHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{width:"33.33%", textAlign:"left", fontWeight:700,paddingLeft:4}}>Date</TableCell>
                            <TableCell align="left" style={{width:"33.33%", textAlign:"left", fontWeight:700,paddingLeft:4}}>Time</TableCell>
                            <TableCell align="left" style={{width:"33.33%", textAlign:"left", fontWeight:700,paddingLeft:4}}>{this.props.profile?.type === ProfileType.Musician.ordinal || this.props.profile?.type === ProfileType.Act.ordinal ? "Venue" : "Act"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arrayThis.map(num => {
                            return this.renderRowItemText(num, tableBgColor, tableFtColor, dateBgColor, dateFtColor);
                        })}
                    </TableBody>
                </Table>
                {hideToolbar && (
                    <Toolbar style={{ width: "100%", display:"flex", justifyContent:"flex-end" }}>
                        <span style={{display:"flex", flexDirection:"column", alignItems:"start", justifyContent:"flex-end", width:"10rem"}}>
                            <Typography variant="caption" className="td-custom">
                                Powered By
                            </Typography>
                            <img src={Logo} alt="hotgiggity logo" width="50%" />
                        </span>
                    </Toolbar>
                )}
            </Fragment>
        );
    };

    renderPreviewGrid = (headerBgColor, headerFtColor, tableBgColor, tableFtColor, dateBgColor, dateFtColor, hideToolbar, hideOrderUber, hideShareButton, numberEvent, tableWidgetBgColor) => {
        var arrayThis = []
        if(numberEvent){
            if (numberEvent === 0){
                numberEvent = 1
            }else if(numberEvent > 5){
                numberEvent = 5
            }
           
        }else{
            numberEvent = 5
        }
        for(var i = 0; i<numberEvent;i++){
            arrayThis.push(i)
        }
        return (
            <Fragment>
                {
                    !this.state.detailsOn ? 
    
                            <Grid style={{backgroundColor: tableBgColor}} container justifyContent="space-between" m={1} spacing={1}>
                            {temporaryData && temporaryData.map((data, index) => {
                            return (
                            <Grid item xs={12} md={3}  className="gridLayoutMini">
                                <div className="gridItemMini">
                                    <Card className="imageItemMini" onClick={() => this.goToDetails(index)}>
                                        <CardActionArea>
                                            <img className="imageStyleMini" src={data.photo ? data.photo : blankBackground} alt={data.name}></img>
                                            <div style={{backgroundColor: tableWidgetBgColor}} className="topcornerMini">
                                                <div style={{fontSize:"9px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor,}}>{data.date}</div>
                                                <div style={{fontSize:"6px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor,}}>May</div>
                                                <div style={{fontSize:"5px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor,}}>5pm</div>
    
                                            </div>
                                            <div className="bottomTextMini">
                                                <span style={{fontSize:"12px", fontFamily:"Raleway", fontWeight:"bold", lineHeight:"60%"}}>{data.name}</span>
                                                
                                            </div>
                                                <img className="bottomRightCornerMini" src={popUpIcon}
                                                alt="popup" width={10} height={10}></img>
                                            <div className="bottomSectionMini"></div>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </Grid>
                            )})}
                    </Grid> : 
                    <Grid  container justifyContent="space-between" style={{border:"solid",}} m={2} spacing={2}>
                        <Grid style={{textAlign:"right"}} item xs={12} md={12}>
                            <IconButton
                                    aria-label="close"
                                    onClick={this.handleCloseDetails}
                                style={{
                                    right:8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                                    >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid style={{marginTop:-40}} item xs={12} md={6}  className="gridLayoutMini">
                            <Card className="imageItemDetailMini">
                                <CardActionArea>
                                    <img className="imageStyleDetailMini" src={temporaryData[this.state.indexOn]?.photo ? temporaryData[this.state.indexOn].photo : blankBackground} alt={temporaryData[this.state.indexOn]?.name}></img>
                                    <div className="topcornerMini" style={{backgroundColor: tableWidgetBgColor,padding:10}}>
                                        <div style={{fontSize:"12px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor }}>{temporaryData[this.state.indexOn]?.date}</div>
                                        <div style={{fontSize:"10px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor }}>May</div>
                                        <div style={{fontSize:"8px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor }}>5pm</div>
                                    </div>
                                
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid  style={{marginTop:-40}} item xs={12} md={6} className="gridInfoMini">
                        <div style={{fontSize:"10px", fontFamily:"Raleway", fontWeight:"bold",  textDecoration: "underline"}}>{temporaryData[this.state.indexOn]?.name}</div>
                        <div className="genreContainerMini" style={{marginLeft:-5,marginBottom:5}}>
                            {
                                bookingGenresString && bookingGenresString.length ? bookingGenresString.map((data, index) => {
                                    return <span className="genreWrapperMini" style={{backgroundColor: tableWidgetBgColor, color: tableFtColor }}>
                                        {data}
                                    </span>
                                }) : null
                            }
                        </div>
                        <Typography component="div" style={{width:"100%",fontSize:"7px", fontFamily:"Open Sans"}} dangerouslySetInnerHTML={{ 
                                __html: temporaryData[this.state.indexOn]?.bio ? temporaryData[this.state.indexOn]?.bio  : "Lorem Ipsum"
                            }} 
                        />
                        <Grid style={{textAlign:"center",marginTop:10}} container justifyContent="space-between" alignContent="center" m={1} spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Button style={{backgroundColor: tableWidgetBgColor, color: tableFtColor, float:"left" }} className="buttonStyleMini">Book A Table</Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button style={{backgroundColor: tableWidgetBgColor, color: tableFtColor, float:"left" }} className="buttonStyleMini">Buy Tickets</Button>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                }
              
                    <Toolbar style={{ width: "100%", display:"flex", justifyContent:"center" }}>
                        <span style={{display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "center"}}>
                            <img src={poweredByLogo} alt="hotgiggity logo" width="30%" />
                        </span>
                    </Toolbar>
            </Fragment>
        );
    };

    render() {
        const { headerBgColor, headerFtColor, tableBgColor, tableWidgetBgColor,tableFtColor, dateBgColor, dateFtColor, hideToolbar, hideOrderUber, hideShareButton,numberEvent, embedType,enabledTransparent } = this.props.embedGigStore;

        if(this.props.profileStore.currentProfile?.type === ProfileType.Musician.ordinal || this.props.profileStore.currentProfile?.type === ProfileType.Act.ordinal) {
            temporaryData = profileVenue
        }else{
            temporaryData = profileAct
        }
        const styles = reactCSS({
            default: {
                headerBgColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: headerBgColor
                },
                headerFtColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: headerFtColor
                },
                dateBgColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: dateBgColor
                },
                dateFtColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: dateFtColor
                },
                tableBgColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: tableBgColor
                },
                tableWidgetBgColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: tableWidgetBgColor
                },
                tableFtColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: tableFtColor
                },
                swatch: {
                    padding: "5px",
                    background: "#fff",
                    borderRadius: "1px",
                    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                    display: "inline-block",
                    cursor: "pointer"
                },
                popover: {
                    position: "absolute",
                    zIndex: "2"
                },
                cover: {
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px"
                }
            }
        });

        return (
            <Fragment>
                <Button onClick={this.handleClickOpen} color="primary" autoFocus style={{ float: "right" }}>
                    CUSTOMISE GIG WIDGET
                </Button>

                <Dialog open={this.state.open} onClose={this.handleClose} fullWidth={true} maxWidth="md">
                    <DialogTitle style={{ backgroundColor: "#262626" }}>
                        <Typography style={{ color: "#fff" }}> Custom Embed Page</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={8} style={{ paddingTop: 50 }}>
                                    {/* <Grid item xs={6}>
                                        Header Background Color
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <div style={styles.swatch} onClick={this.handleHeaderBgColorPickerClick}>
                                                <div style={styles.headerBgColor} />
                                            </div>
                                            {this.state.dialogHeaderBgColor ? (
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={this.handleHeaderBgColorPickerClose} />
                                                    <SketchPicker color={headerBgColor} onChange={this.handleHeaderBgColorChangeComplete} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        Header Font Color
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <div style={styles.swatch} onClick={this.handleHeaderFtColorPickerClick}>
                                                <div style={styles.headerFtColor} />
                                            </div>
                                            {this.state.dialogHeaderFtColor ? (
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={this.handleHeaderFtColorPickerClose} />
                                                    <SketchPicker color={headerFtColor} onChange={this.handleHeaderFontColorChangeComplete} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        Date Background Color
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <div style={styles.swatch} onClick={this.handleDateBgColorPickerClick}>
                                                <div style={styles.dateBgColor} />
                                            </div>
                                            {this.state.dialogDateBgColor ? (
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={this.handleDateBgColorPickerClose} />
                                                    <SketchPicker color={dateBgColor} onChange={this.handleDateBgColorChangeComplete} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        Date Font Color
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <div style={styles.swatch} onClick={this.handleDateFtColorPickerClick}>
                                                <div style={styles.dateFtColor} />
                                            </div>
                                            {this.state.dialogDateFtColor ? (
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={this.handleDateFtColorPickerClose} />
                                                    <SketchPicker color={dateFtColor} onChange={this.handleDateFontColorChangeComplete} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </Grid> */}
                                    <Grid item xs={7}>
                                        Select Your Widget Type
                                    </Grid>
                                    <Grid item xs={5}>
                                    <Select
                                        label="embedType"
                                        value={embedType}
                                        onChange={this.setEmbedType}
                                    >   
                                        <MenuItem value={2}>Grid Layout</MenuItem>
                                        <MenuItem value={0}>{this.props.profile?.type === ProfileType.Musician.ordinal  || this.props.profile?.type === ProfileType.Act.ordinal ? "Large Venue Image" : "Large Artist Image"}</MenuItem>
                                        <MenuItem value={1}>Table Layout</MenuItem>
                                        
                                    </Select>
                                    </Grid>
                                     
                                    {
                                        this.props.embedGigStore.embedType === EmbedType.Grid.ordinal ?
                                        <Grid item xs={7}>
                                            Make widget background transparent
                                        </Grid> : null
                                    }
                                     {
                                        this.props.embedGigStore.embedType === EmbedType.Grid.ordinal ?                         
                                        <Grid style={{marginLeft:-15,marginTop:-10}} item xs={5}>
                                            <Switch   checked={enabledTransparent} onChange={this.toggleEnabledTransparent} value="toggleEnabledTransparent" />
                                        </Grid> : null
                                    }
                                  
                                    {
                                        this.props.embedGigStore.embedType != EmbedType.Grid.ordinal || !enabledTransparent ?
                                        <Grid item xs={7}>
                                            Content Background Color
                                        </Grid> : null
                                    }
                                    {
                                        this.props.embedGigStore.embedType != EmbedType.Grid.ordinal || !enabledTransparent ?
                                        <Grid item xs={5}>
                                            <div>
                                                <div style={styles.swatch} onClick={this.handleTableBgColorPickerClick}>
                                                    <div style={styles.tableBgColor} />
                                                </div>
                                                {this.state.dialogTableBgColor ? (
                                                    <div style={styles.popover}>
                                                        <div style={styles.cover} onClick={this.handleTableBgColorPickerClose} />
                                                        <SketchPicker color={tableBgColor} onChange={this.handleTableBgColorChangeComplete} />
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Grid> : null
                                    }
                                  
                                    {
                                        this.props.embedGigStore.embedType === EmbedType.Grid.ordinal ? 
                                        (
                                            <Grid item xs={7}>
                                                Set your widget featured colour
                                            </Grid>
                                        )
                                        : null
                                    }

{
                                        this.props.embedGigStore.embedType === EmbedType.Grid.ordinal ? 
                                        (
                                            <Grid item xs={5}>
                                                <div>
                                                    <div style={styles.swatch} onClick={this.handleTableWidgetBgColorPickerClick}>
                                                        <div style={styles.tableWidgetBgColor} />
                                                    </div>
                                                    {this.state.dialogWidgetBgColor ? (
                                                        <div style={styles.popover}>
                                                            <div style={styles.cover} onClick={this.handleTableWidgetBgColorPickerClose} />
                                                            <SketchPicker color={tableWidgetBgColor} onChange={this.handleTableWidgetBgColorPickerComplete} />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Grid>
                                        )
                                        : null
                                    }
                                    
                                    <Grid item xs={7}>
                                        Content Font Color
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div>
                                            <div style={styles.swatch} onClick={this.handleTableFtColorPickerClick}>
                                                <div style={styles.tableFtColor} />
                                            </div>
                                            {this.state.dialogTableFtColor ? (
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={this.handleTableFtColorPickerClose} />
                                                    <SketchPicker color={tableFtColor} onChange={this.handleTableFontColorChangeComplete} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </Grid>
                                   
                                    {
                                        this.props.embedGigStore.embedType != EmbedType.Grid.ordinal ? 
                                        <Grid item xs={7}>
                                            Hide Title Bar
                                        </Grid> : null
                                    }
                                     {
                                        this.props.embedGigStore.embedType != EmbedType.Grid.ordinal ? 
                                        <Grid style={{marginLeft:-15,marginTop:-10}} item xs={5}>
                                            <Switch checked={hideToolbar} onChange={this.toggleHideToolbar} value="hideToolbar" />
                                        </Grid> : null
                                    }
                                   
                                    
                                    <Grid item xs={7}>
                                        Select the number of events to show:
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            id="eventsToShow"
                                            type="text"
                                            style={{width:50}}
                                            value={numberEvent}
                                            onChange={this.handleNumberEventsChange}
                                        />
                                    </Grid>
                                    <Grid style={{marginTop:20}} item xs={12}></Grid>
                                    {
                                        this.props.embedGigStore.embedType === EmbedType.Grid.ordinal ? 
                                        <Grid item xs={12}>
                                            <span style={{fontSize:11}}>Tip: Click the embed preview to see the pop-up. If you have added a Book a Table in your venue, or a Ticket URL to the booking, these links will be shown.
                                            </span>
                                        </Grid> :
                                        null
                                    }
                                 
                                    {/* <Grid item xs={6}>
                                        Use text-only table layout
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Switch checked={embedType} onChange={this.toggleEmbedType} value="embedType" />
                                    </Grid> */}
                                    {/* <Grid item xs={6}>
                                        Hide Order Uber
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Switch checked={hideOrderUber} onChange={this.toggleHideOrderUber} value="hideOrderUber" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        Hide Share Button
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Switch checked={hideShareButton} onChange={this.toggleHideShareButton} value="hideShareButton" />
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            { this.props.embedGigStore.embedType  === 1? 
                            (
                                <Grid item xs={12} md={6}>
                                    <div style={{ paddingTop: 30 }}>
                                        {this.renderPreviewText(
                                            headerBgColor,
                                            headerFtColor,
                                            tableBgColor,
                                            tableFtColor,
                                            dateBgColor,
                                            dateFtColor,
                                            hideToolbar, 
                                            hideOrderUber, 
                                            hideShareButton,
                                            numberEvent
                                        )}
                                    </div>
                                </Grid>

                            ) : null
                            
                            }
                             { this.props.embedGigStore.embedType  === 0? 
                            (
                                <Grid item xs={12} md={6}>
                                    <div style={{ paddingTop: 30 }}>
                                        {this.renderPreview(
                                            headerBgColor,
                                            headerFtColor,
                                            tableBgColor,
                                            tableFtColor,
                                            dateBgColor,
                                            dateFtColor,
                                            hideToolbar, 
                                            hideOrderUber, 
                                            hideShareButton,
                                            numberEvent
                                        )}
                                    </div>
                                </Grid>

                            ) : 
                               null
                            
                            }

                            { this.props.embedGigStore.embedType  === 2? 
                            (
                                <Grid item xs={12} md={6}>
                                    <div style={{ paddingTop: 30 }}>
                                        {this.renderPreviewGrid(
                                            headerBgColor,
                                            headerFtColor,
                                            tableBgColor,
                                            tableFtColor,
                                            dateBgColor,
                                            dateFtColor,
                                            hideToolbar, 
                                            hideOrderUber, 
                                            hideShareButton,
                                            numberEvent,
                                            tableWidgetBgColor
                                        )}
                                    </div>
                                </Grid>

                            ) : null
                            
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                        <Button onClick={this.handleSaveEmbedCode} color="primary" autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default CustomEmbedGigWidget;
