import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import { Paper, Checkbox, Tooltip, IconButton, Snackbar, Button, TextField, Grid, Toolbar, Typography, CircularProgress } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import green from "@material-ui/core/colors/green";
import Slide from "@material-ui/core/Slide";

import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { del } from "superagent";

const columnData = [
    { columnTitle: "name", numeric: false, disablePadding: true, label: "Name" },
    { columnTitle: "notes", numeric: false, disablePadding: true, label: "Notes" }
];

@inject("designProfileStore")
@observer
class PlayedWithTable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            rowsPerPage: 5,
            openDelete: false,
            openDialog: false,
            openAdd: false,
            openEdit: false,
            editedId: 0,
            inputName: "",
            inputNotes: "",
            isEdit: false
        };
    }

    lastData = [];

    handleRequestSort = (event, property) => {
        const orderBy = property;
        const { playedWiths } = this.props.designProfileStore;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        let data =
            order === "desc"
                ? playedWiths.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                : playedWiths.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.props.designProfileStore.setPlayedWiths(data);

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        const { playedWiths } = this.props.designProfileStore;

        if (checked) {
            this.setState({ selected: playedWiths.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleClearSelected = () => {
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    createSortHandler = property => event => {
        this.handleRequestSort(event, property);
    };

    handleDeleteClick = playedWith => () => {
        const { 
            id,
            name,
            notes,
            profileId
        } = playedWith;

        let deletedPlayedWith = [{ id: id }];
        this.props.designProfileStore.deletePlayedWith(deletedPlayedWith).then(results => {
            this.lastData = [{
                id,
                name,
                notes,
                profileId
            }];
            this.handleClearSelected();
            this.setState({ openDelete: true });
        }); 
    };
    handleDeleteBulkClick = () => {
        var selected=this.state.selected;
        let deletedPlayedWith = [];
        for(var i=0; i<selected.length;i++){
            deletedPlayedWith.push({id:selected[i]});
        }
        
        this.props.designProfileStore.deletePlayedWith(deletedPlayedWith).then(results => {
            this.lastData = results;
            this.handleClearSelected();
            this.setState({ openDelete: true });
        });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        this.setState({ openDelete: false });
        if (reason === "undo") {
            this.props.designProfileStore.addPlayedWith(this.lastData);
        }
    };

    handleUndoRemovePlayedWith = event => {
        this.handleSnackbarDeleteClose(event, "undo");
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    handleOpenAddDialog = () => {
        this.setState({ openDialog: true, isEdit: false });
        this.resetDialogForm();
    };

    handleOpenDialog = playedWith => () => {
        this.setState({
            openDialog: true,
            editedId: playedWith.id,
            inputName: playedWith.name,
            inputNotes: playedWith.notes,
            isEdit: true
        });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    };

    handleNameChange = e => {
        this.setState({ inputName: e.target.value });
    };

    handleNotesChange = e => {
        this.setState({ inputNotes: e.target.value });
    };

    resetDialogForm = () => {
        this.setState({
            editedId: 0,
            inputName: "",
            inputNotes: "",
            isEdit: false
        });
    };

    handleSubmitClick = () => {
        this.props.designProfileStore
            .editPlayedWith({
                id: this.state.editedId,
                name: this.state.inputName,
                notes: this.state.inputNotes
            })
            .then(results => {
                if (this.state.editedId) {
                    this.handleSnackbarEditOpen();
                } else {
                    this.lastData = results;
                    this.handleSnackbarAddOpen();
                }
            })
            .finally(() => {
                this.resetDialogForm();
            });

        this.handleCloseDialog();
    };

    handleSnackbarAddOpen = () => {
        this.setState({ openAdd: true });
    };

    handleSnackbarAddClose = (event, reason) => {
        this.setState({ openAdd: false });
        if (reason === "undo") {
            //execute undo add
            this.props.designProfileStore.deletePlayedWith(this.lastData);
        }
    };

    handleSnackbarEditOpen = () => {
        this.setState({ openEdit: true });
    };

    handleSnackbarEditClose = (event, reason) => {
        this.setState({ openEdit: false });
    };

    handleUndoAddPlayedWith = event => {
        this.handleSnackbarAddClose(event, "undo");
    };

    render() {
        const { classes, fullScreen } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, inputName, inputNotes } = this.state;
        const { playedWiths, playedWithInProgress, playedWithSuccess } = this.props.designProfileStore;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, playedWiths.length - page * rowsPerPage);

        let numSelected = selected.length;

        return (
            <Paper className={classes.root}>
                <Toolbar
                    className={classNames(classes.toolbarRoot, {
                        [classes.highlight]: numSelected > 0
                    })}
                >
                    <div className={classes.title}>
                        {numSelected > 0 ? (
                            <Typography variant="subheading" style={{ color: "#FFF" }}>
                                {numSelected} selected
                            </Typography>
                        ) : (
                            <Typography variant="subheading">Musicians - Bands - Artists</Typography>
                        )}
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.actions}>
                        {numSelected > 0 ? (
                            <Tooltip title="Delete">
                                <Button id="delete-played-with" aria-label="Delete" style={{ color: "#FFF" }} onClick={this.handleDeleteBulkClick}>
                                    <DeleteIcon /> Delete
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Add">
                                <Button
                                    id="add-played-with"
                                    aria-label="Add"
                                    onClick={this.handleOpenAddDialog}
                                    style={playedWithSuccess ? { color: green[800] } : null}
                                >
                                    {playedWithSuccess ? (
                                        <Fragment>
                                            <CheckIcon style={{ color: "#FFF" }} /> <span style={{ color: "#FFF" }}>Add</span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <AddIcon style={{ color: "#FFF" }} /> <span style={{ color: "#FFF" }}>Add</span>
                                        </Fragment>
                                    )}
                                </Button>
                            </Tooltip>
                        )}
                    </div>

                    {playedWithInProgress && <CircularProgress size={60} className={classes.buttonProgress} />}
                </Toolbar>

                <div className={classes.tableWrapper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < playedWiths.length}
                                        checked={selected.length === playedWiths.length}
                                        onChange={this.handleSelectAllClick}
                                    />
                                </TableCell>
                                {columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.columnTitle}
                                            numeric={column.numeric}
                                            padding={column.disablePadding ? "none" : "default"}
                                            sortDirection={orderBy === column.columnTitle ? order : false}
                                        >
                                            <Tooltip title="Sort" placement={column.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                                                <TableSortLabel
                                                    active={orderBy === column.columnTitle}
                                                    direction={order}
                                                    onClick={this.createSortHandler(column.columnTitle)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    );
                                }, this)}
                                <TableCell padding="checkbox" numeric={true} />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {playedWiths.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                const isSelected = this.isSelected(n.id);
                                return (
                                    <TableRow hover aria-checked={isSelected} tabIndex={-1} key={n.id} selected={isSelected}>
                                        <TableCell padding="checkbox">
                                            <Checkbox checked={isSelected} onClick={event => this.handleClick(event, n.id)} />
                                        </TableCell>
                                        <TableCell padding="none">{n.name}</TableCell>
                                        <TableCell padding="none">{n.notes}</TableCell>
                                        <TableCell numeric={true}>
                                            <Tooltip title="Edit">
                                                <Button color="primary" size="small" onClick={this.handleOpenDialog(n)}>
                                                    Edit
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <Button color="primary" size="small" onClick={this.handleDeleteClick(n)}>
                                                    Delete
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    SelectProps={{ className: "transparent-select" }}
                                    count={playedWiths.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                    TransitionComponent={this.transition}
                    className="dialog-div"
                >
                    <DialogTitle className="see-more-dialog-title">Musician - Band - Artist</DialogTitle>
                    <DialogContent className="see-more-dialog-content">
                        <div style={{ padding: 16 }}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <DialogContentText className={classes.blackTextColor}>
                                        Enter the musician, band or artist information. This will be displayed on your profile.
                                    </DialogContentText>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        id="musician-name"
                                        placeholder="Name"
                                        label="Name"
                                        margin="none"
                                        fullWidth
                                        value={inputName}
                                        onChange={this.handleNameChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="musician-notes"
                                        placeholder="Notes"
                                        label="Notes"
                                        margin="none"
                                        fullWidth
                                        value={inputNotes}
                                        onChange={this.handleNotesChange}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Close
                        </Button>
                        <Button
                            id="submit-musician"
                            onClick={this.handleSubmitClick}
                            color="primary"
                            disabled={inputName && inputNotes ? false : true}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openAdd}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarAddClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Musician Added</span>}
                    action={[
                        <Button key="undo" color="primary" size="small" onClick={this.handleUndoAddPlayedWith}>
                            UNDO
                        </Button>,
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarAddClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openEdit}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarEditClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Musician Edited</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDelete}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarDeleteClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Musician Deleted</span>}
                    action={[
                        <Button key="undo" color="primary" size="small" onClick={this.handleUndoRemovePlayedWith}>
                            UNDO
                        </Button>,
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarDeleteClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </Paper>
        );
    }
}

PlayedWithTable.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    toolbarRoot: {
        paddingRight: theme.spacing.unit,
        backgroundColor: "#ff0c9c"
    },
    highlight: {
        backgroundColor: "#333333"
    },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
    blackTextColor: {
        color: "#000"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: 2,
        right: 2,
        zIndex: 1
    }
});

export default withMobileDialog()(withStyles(styles)(PlayedWithTable));
