import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faTiktok,faGoogle } from '@fortawesome/free-brands-svg-icons'
import "./SocialButton.css";

const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
library.add(faCheckSquare, faCoffee,faTiktok,faGoogle)
class SocialButton extends Component {
    getUrl = () => {
        if (this.props.urlData) {
            switch (this.props.variant) {
                case "webSite":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fa fa-globe"
                    };
                case "facebook":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-facebook-f"
                    };
                case "twitter":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-twitter"
                    };
                case "bandcamp":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-bandcamp"
                    };
                case "soundcloud":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-soundcloud"
                    };
                case "mixcloud":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-mixcloud"
                    };
                case "youtube":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-youtube"
                    };
                case "instagram":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-instagram"
                    };
                case "googlePlus":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "cib-tiktok"
                    };
                case "linkedIn":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-linkedin-in"
                    };
                case "iTunes":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-apple"
                    };
                case "spotify":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-spotify"
                    };
                case "email":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fa fa-envelope"
                    };
                case "whasapp":
                    return {
                        url: this.props.urlData,
                        buttonClass: "button-" + this.props.variant,
                        iconClass: "fab fa-whatsapp"
                    };
                default:
                    return null;
            }
        } else {
            return null;
        }
    };
    render() {
        const variant = this.props.variant;
        let buttonData = this.getUrl();
        return (
            <span>
                {buttonData ? (
                    IS_MOBILE ? (
                        <Button
                            variant="contained"
                            className={"button-social " + buttonData.buttonClass}
                            onClick={() => window.open(buttonData.url, "_system")}
                        >
                            <Icon classes={{ root: "icon-button-social" }}>
                                <i className={buttonData.iconClass} />
                            </Icon>
                        </Button>
                    ) : (
                        <a href={buttonData.url} className="button-social-link">
                            <Button variant="contained" className={"button-social " + buttonData.buttonClass}>
                                {
                                     variant === "googlePlus" ? 
                                     <FontAwesomeIcon icon={['fab', 'tiktok']} />  : 
                                     <Icon classes={{ root: "icon-button-social" }}>
                                        <i className={buttonData.iconClass} />
                                    </Icon>
                                }
                               
                            </Button>
                        </a>
                    )
                ) : null}
            </span>
        );
    }
}

export default SocialButton;
