import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { DialogContent, DialogActions, Button, Grid, TextField, InputLabel } from "@material-ui/core";

import ModalContainer from "../../modal/ModalContainer";

import SelectField2 from "../../fields/SelectField2";

import RichTextEditor from "../../richTextEditor/RichTextEditor";

@inject("userInvitationStore", "templateStore", "actTypeStore","authStore")
@observer
class ActOwnerInvitationModal extends Component {
    componentDidMount(){
        this.props.actTypeStore.loadActTypes()
    }
    handleInviteActOwner = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.userInvitationStore.inviteActOwner().then(
            response => {
                this.props.templateStore.hideLoadingScreen();
                this.props.templateStore.openSnackbar("Act owner has been invited.");
                this.props.onClose();
            },
            err => {
                this.props.templateStore.hideLoadingScreen();
                this.props.templateStore.openSnackbar(this.handleErrorMessage(err));
            }
        );
    };

    handleValidate(value, type) {        
        switch (type) {
            case "firstName":
                this.props.userInvitationStore.validateInvitedUserFirstName(value);
                break;
            case "lastName":
                this.props.userInvitationStore.validateInvitedUserLastName(value);
                break;
            case "email":
                this.props.userInvitationStore.validateInvitedUserEmail(value);
                break;
            case "actName":
                this.props.userInvitationStore.validateInvitedActName(value);
                break;
        
            default:
                break;
        }
    }

    mapActTypeSuggestions = () => {
        const { actTypes } = this.props.actTypeStore;
        return actTypes.map(actType => {
            return {
                value: actType.id,
                label: actType.name
            };
        });
    };

    handleActTypeChange = value => {
        this.props.userInvitationStore.setActType(value);
    };

    handleActLineupChange = value => {
        this.props.userInvitationStore.setActLineup(value);
    };
    getLineupsType = () => {
        let actTypes = [
            { name: "Solo", instrument: 1 },
            { name: "Duo", instrument: 2 },
            { name: "Trio", instrument: 3 },
            { name: "Quartet / 4 Piece", instrument: 4 },
            { name: "Quintet / 5 Piece", instrument: 5 },
            { name: "Sextet / 6 Piece", instrument: 6 }
        ];

        for (var i = 0; i < 93; i++) {
            actTypes.push({ name: i + 7 + " Piece", instrument: i + 7 });
        }
        return actTypes.map(actType => {
            return {
                value: actType.instrument,
                label: actType.name
            };
        });
    };
    handleErrorMessage = (err) => {
        try {
            return Object.values(JSON.parse(err))   
        } catch (error) {
            return err
        }
    }

    render() {
        const {
            invitedUserFirstName,
            invitedUserLastName,
            invitedUserEmail,
            invitedActName,
            errors,
            actLineup,
            actType,
            invitationMessage
        } = this.props.userInvitationStore;
        const anyError = errors?.firstName || errors?.lastName || errors?.actName || errors?.email;
        const isError = anyError || !invitedUserFirstName || !invitedUserLastName || !invitedActName;

        return (
            <ModalContainer open={this.props.open} onClose={this.props.onClose} title="Invite Act Owner">
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={12}>
                            <Grid container direction="column" spacing={8}>
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        label="Act Name"
                                        value={invitedActName}
                                        fullWidth
                                        onBlur={e => this.handleValidate(e.target.value, "actName")}
                                        onChange={e => this.props.userInvitationStore.setInvitedActName(e.target.value)}
                                        error={errors && errors["actName"] ? true : false}
                                        helperText={errors && errors["actName"] ? this.handleErrorMessage(errors["actName"]) : ""}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                                    <SelectField2
                                        id="bookingActType"
                                        label="Act Type"
                                        value={actType}
                                        suggestions={this.mapActTypeSuggestions()}
                                        placeholder="Select Act Type"
                                        onChange={this.handleActTypeChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                                    <SelectField2
                                        id="bookingActLineupType"
                                        label="Lineup"
                                        value={actLineup}
                                        suggestions={this.getLineupsType()}
                                        placeholder="Select Lineup Numbers"
                                        onChange={this.handleActLineupChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Act Owner First Name"
                                        value={invitedUserFirstName}
                                        fullWidth
                                        onBlur={e => this.handleValidate(e.target.value, "firstName")}
                                        onChange={e => this.props.userInvitationStore.setInvitedUserFirstName(e.target.value)}
                                        error={errors && errors["firstName"] ? true : false}
                                        helperText={errors && errors["firstName"] ? this.handleErrorMessage(errors["firstName"]) : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Act Owner Last Name"
                                        value={invitedUserLastName}
                                        fullWidth
                                        onBlur={e => this.handleValidate(e.target.value, "lastName")}
                                        onChange={e => this.props.userInvitationStore.setInvitedUserLastName(e.target.value)}
                                        error={errors && errors["lastName"] ? true : false}
                                        helperText={errors && errors["lastName"] ? this.handleErrorMessage(errors["lastName"]) : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Act Owner Email"
                                        value={invitedUserEmail}
                                        fullWidth
                                        onBlur={e => this.handleValidate(e.target.value, "email")}
                                        onChange={e => this.props.userInvitationStore.setInvitedUserEmail(e.target.value)}
                                        error={errors && errors["email"] ? true : false}
                                        helperText={errors && errors["email"] ? this.handleErrorMessage(errors["email"]) : ""}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} lg={6}>
                            <InputLabel>Invitation Message</InputLabel>
                            <RichTextEditor
                                label="Invitation Message"
                                value={invitationMessage}
                                onChange={value => this.props.userInvitationStore.setInvitationMessage(value)}
                            />
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Close</Button>
                    <Button color="primary" disabled={isError} onClick={this.handleInviteActOwner}>
                        Invite
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default ActOwnerInvitationModal;
