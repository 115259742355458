import React from "react";

class SongListMedia extends React.Component {
    getUrlType = () => {
        const url = this.props.media;
        if (url) {
            const domain = url
                .replace("http://", "")
                .replace("https://", "")
                .split(/[/?#]/)[0];
            if (domain.indexOf("spotify.com") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-spotify fa-2x" style={{ color: "green", cursor:"pointer" }} />
                    </a>
                );
            } else if (url.indexOf("soundcloud.com") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-soundcloud fa-2x" style={{ color: "orange", cursor:"pointer" }} />
                    </a>
                );
            } else if (url.indexOf("youtube.com") >= 0 || url.indexOf("youtu.be") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-youtube fa-2x" style={{ color: "#cc181e", cursor:"pointer" }} />
                    </a>
                );
            } else if (url.indexOf("facebook.com") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-facebook fa-2x" style={{ color: "#3b5998", cursor:"pointer" }} />
                    </a>
                );
            } else if (url.indexOf("twitter.com") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-twitter fa-2x" style={{ color: "#1DA1F2", cursor:"pointer" }} />
                    </a>
                );
            } else if (url.indexOf("itunes.apple.com") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-itunes fa-2x" style={{ color: "black", cursor:"pointer" }} />
                    </a>
                );
            } else if (url.indexOf("bandcamp.com") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-bandcamp fa-2x" style={{ color: "black", cursor:"pointer" }} />
                    </a>
                );
            } else if (url.indexOf("mixcloud.com") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-mixcloud fa-2x" style={{ color: "black", cursor:"pointer" }} />
                    </a>
                );
            } else if (url.indexOf("instagram.com") >= 0) {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fab fa-lg fa-instagram fa-2x" style={{ color: "#e4405f", cursor:"pointer" }} />
                    </a>
                );
            } else {
                return (
                    <a onClick={() => window.open(url)}>
                        <i className="fas fa-lg fa-globe fa-2x" style={{ color: "black", cursor:"pointer" }} />
                    </a>
                );
            }
        }
    };

    render() {
        return <div style={{ marginLeft: this.props.left }}>{this.getUrlType()}</div>;
    }
}

export default SongListMedia;
