import { observable, action, reaction, runInAction } from "mobx";
import agent from "../agent";
import negotiateStore from "./negotiateStore";
import templateStore from "./templateStore";
import profileStore from "./profileStore";
import conversationStore from "./conversationStore";
import { LineupStatusType, BookingStatusType, ProfileType, NotificationType } from "../types/enum";
import lineupMessageStore from "./lineupMessageStore";
import { GetNotificationLink } from "../components/notifications/helper/NotificationLink";
import CacheHelper from "../helper/cache";
import blankProfile from "../img/hotgig-loading.png";

var WebSocketClient = require('websocket').w3cwebsocket;

const API_NOTIFICATION_ROOT = process.env.REACT_APP_SOCKET_SERVICE_URL;
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

class NotificationStore {
    @observable notifications = [];
    @observable notificationsUpdated = [];
    @observable unread = 0;
    socket = undefined;
    routingStore = undefined;
    location = undefined;
    userId = undefined;
    history = undefined;
    iosPush = undefined;

    recalculate = reaction(
        () => this.unread,
        unread => {
            if (this.iosPush) {
                this.iosPush.setApplicationIconBadgeNumber(() => { }, () => { }, unread);
            }
        }
    );

    @action
    setRoutingStore(routingStore) {
        this.routingStore = routingStore;
    }

    @action
    setHistory(history) {
        this.history = history;
    }

    @action
    update(notifications) {
        this.notifications = notifications;
    }

    @action
    updateNotificationUpdated(notifications) {
        this.notificationsUpdated = notifications;
    }

    @action
    reset() {
        this.notifications = [];
        this.notificationsUpdated = [];
        this.unread = 0;
    }

    @action
    subscribe(userId) {
        this.userId = userId;
        var client = new WebSocketClient(API_NOTIFICATION_ROOT);
        const currentProfile = profileStore.getCurrentProfile()
    
        client.onopen = function () {
            if (client.readyState === client.OPEN) {
                var message = JSON.stringify({
                    action: "userSubscribe",
                    data: userId
                });
                client.send(message);
                // setTimeout(sendNumber, 1000);
            }
        };

        client.onclose = function () {
            console.log('echo-protocol Client Closed');
        };

        client.onmessage = (e) => {
            const { event, data } = JSON.parse(e.data);
            if (event === 'SendNotification') {
                CacheHelper.getImgLink(data.fromProfileImageUrl).then(
                    action(imageUrl => {
                        if (typeof data.fromProfileImageUrl !== "undefined") {
                            data.profileImageUrl = imageUrl;
                        }
                        if (!data.isRead) {
                            this.unread = this.unread + 1;
                        }
                        this.notifications.unshift(data);
                        this.notificationsUpdated.unshift(data);
                    }));
            }
            else if (event === "SendLineupMessage") {
                if (data.profileImageUrl !== null) {
                    CacheHelper.getImgLink(data.profileImageUrl).then(
                        action(imageUrl => {
                            if (data.profileImageUrl !== null) {
                                data.profileImageUrl = imageUrl;
                            }
                            lineupMessageStore.addMessage(data);
                        }));
                }
                else {
                    lineupMessageStore.addMessage(data);
                }
            }
            else if (event === "SendConversationMessage") {
                if (data.profileImageUrl) {
                    CacheHelper.getImgLink(data.profileImageUrl).then(
                        action(imageUrl => {
                            var messageChat = {
                                body: data.message.body,
                                profile: data.fromProfile,
                                profileId: data.fromProfileId,
                                profileImageUrl: imageUrl,
                                createdAt: data.createdAt,
                                conversationId: data?.message?.conversationId
                            }

                            messageChat.profile.firstName = data.fromProfile.firstName
                            messageChat.profile.lastName = data.fromProfile.lastName
                            if (currentProfile?.id == data.toProfileId && messageChat.conversationId == conversationStore.messageId) {
                                conversationStore.addMessageChats(messageChat)
                            }
                        })
                    );
                }
                if (data.id && !conversationStore.messageChats.find(x => x.id == data.id)){
                    conversationStore.load()
                }
            }
            else if (event === "SendNegotiateMessage") {
                if (data.profileImageUrl !== null) {
                    CacheHelper.getImgLink(data.profileImageUrl).then(
                        action(imageUrl => {
                            if (data.profileImageUrl !== null) {
                                data.profileImageUrl = imageUrl;
                            }
                            negotiateStore.addMessage(data);
                        }));
                }
                else {
                    negotiateStore.addMessage(data);
                }
            }
            // this.socket.on(
            //     "SendNegotiateMessage",
            //     action(negotiateMessage => {
            //         negotiateStore.addMessage(negotiateMessage);
            //     })
            // );
        };

        // client.on('connect', function (connection) {
        //     console.log('WebSocket Client Connected');
        //     connection.on('error', function (error) {
        //         console.log("Connection Error: " + error.toString());
        //     });
        //     connection.on('close', function () {
        //         console.log('echo-protocol Connection Closed');
        //     });
        //     connection.on('message', function (message) {
        //         if (message.type === 'utf8') {
        //             console.log("Received: '" + message.utf8Data + "'");
        //         }
        //     });

        //     connection.on('SendNotification', function (message) {
        //         console.log(notification);
        //         CacheHelper.getImgLink(notification.fromProfileImageUrl).then(
        //             action(imageUrl => {
        //                 notification.profileImageUrl = imageUrl;
        //                 if (!notification.isRead) {
        //                     this.unread = this.unread + 1;
        //                 }

        //                 this.notifications.unshift(notification);
        //             }));
        //     });

        //     function sendNumber() {
        //         if (connection.connected) {
        //             var number = Math.round(Math.random() * 0xFFFFFF);
        //             connection.sendUTF(number.toString());
        //             setTimeout(sendNumber, 1000);
        //         }
        //     }
        //     sendNumber();
        // });

        // client.connect(API_NOTIFICATION_ROOT, 'echo-protocol');

        // this.socket = io(API_NOTIFICATION_ROOT, {
        //     path: "/hubs/",
        //     autoConnect: true,
        //     withCredentials: true,
        //     extraHeaders: {
        //         "userSub": userId
        //     },
        //     secure: true,
        //     reconnect: true,
        //     rejectUnauthorized: false
        // });
        // console.log("socket ", API_NOTIFICATION_ROOT);
        // this.socket.on("connect", () => {
        //     console.log("connect socket ");
        //     console.log(this.socket.id);

        // });

        // this.socket.on(
        //     "SendNotification",
        //     notification => {
        //         console.log(notification);
        //         CacheHelper.getImgLink(notification.fromProfileImageUrl).then(
        //             action(imageUrl => {
        //                 notification.profileImageUrl = imageUrl;
        //                 if (!notification.isRead) {
        //                     this.unread = this.unread + 1;
        //                 }

        //                 this.notifications.unshift(notification);
        //             }));

        //     }
        // );

        // this.socket.on(
        //     "DeleteNotification",
        //     action(notification => {
        //         this.notifications = this.notifications.filter(x => x.id !== notification.id);
        //         this.unread -= 1;
        //     })
        // );

        // this.socket.on(
        //     "SendNegotiateMessage",
        //     action(negotiateMessage => {
        //         negotiateStore.addMessage(negotiateMessage);
        //     })
        // );

        // this.socket.on(
        //     "SendLineupMessage",
        //     action(lineupMessage => {
        //         lineupMessageStore.addMessage(lineupMessage);
        //     })
        // );

        if (window.cordova) {
            this.setupPush();
            this.loadNotifications(userId);
        }else{
            if(window.location.pathname != "/notifications"){
                this.loadNotifications(userId);
            }
            
        }
        // load notification from db
        
        //load messages from db
        conversationStore.load()
    }

    setupPush() {
        // const push = window.PushNotification.init({
        //     android: {},
        //     browser: {
        //         pushServiceURL: "http://push.api.phonegap.com/v1/push"
        //     },
        //     ios: {
        //         alert: "true",
        //         badge: true,
        //         sound: "false"
        //     },
        //     windows: {}
        // });

        const push = window.PushNotification.init({
            android: {
                senderID: "XXXXXXXX"
            },
            browser: {},
            ios: {
                sound: true,
                vibration: true,
                badge: true,
                categories: {
                    reminder1: {
                        yes: {
                            callback: "accept",
                            title: "Confirm",
                            foreground: true,
                            destructive: false
                        },
                        no: {
                            callback: "reject",
                            title: "Set Reminder",
                            foreground: true,
                            destructive: false
                        }
                    }
                }
            },
            windows: {}
        });

        const userId = this.userId;
        const history = this.history;
        const gotoPage = this.gotoPage;
        //const setHistory = this.setHistory;

        push.on("registration", function (data, store) {
            var oldRegId = localStorage.getItem("registrationId");
            if (oldRegId !== data.registrationId) {
                // Save new registration ID
                localStorage.setItem("registrationId", data.registrationId);
                // Post registrationId to your app server as the value has changed
            }

            if (ENVIRONMENT !== "Dev") {
                push.unsubscribe(`Dev-userId-${userId}`, () => { }, e => { });
            }
            if (ENVIRONMENT !== "Demo") {
                push.unsubscribe(`Demo-userId-${userId}`, () => { }, e => { });
            }
            if (ENVIRONMENT !== "Test") {
                push.unsubscribe(`Test-userId-${userId}`, () => { }, e => { });
            }
            if (ENVIRONMENT !== "Production") {
                push.unsubscribe(`Production-userId-${userId}`, () => { }, e => { });
            }

            const userTopic = `${ENVIRONMENT}-userId-${userId}`;

            push.subscribe(
                userTopic,
                () => {
                    //console.log("success subscribe to user topic");
                },
                e => {
                    //console.log("error subscribe to user topic:", e);
                }
            );
        });

        push.on("error", function (e) {
            //console.log("push error = " + e.message);
        });

        push.on("notification", function (data) {
            const isForeground = data.additionalData.foreground;
            let notificationId = Number.parseInt(data.additionalData.id, 10);
            if (Number.isNaN(notificationId)) {
                notificationId = 0;
            }

            agent.Notification.load().then(notifications => {
                const filteredNotification = notifications.filter(x => x.id === notificationId);
                if (filteredNotification && filteredNotification.length > 0) {
                    const notification = filteredNotification[0];
                    if (isForeground) {
                        if (window.navigator.notification) {
                            window.navigator.notification.beep(2);
                        }
                        // templateStore.openSnackbar(data.message);
                    } else {
                        const url = gotoPage(notification, true);
                        history.push(url);
                        // templateStore.openSnackbar("routing pushed");
                    }
                }
            });
        });

        push.on("accept", data => {
            // do something with the notification data

            push.finish(
                () => {
                    //console.log("accept callback finished");
                },
                () => {
                    //console.log("accept callback failed");
                },
                data.additionalData.notId
            );
        });

        push.on("reject", data => {
            // do something with the notification data

            push.finish(
                () => {
                    //console.log("reject callback finished");
                },
                () => {
                    //console.log("reject callback failed");
                },
                data.additionalData.notId
            );
        });

        push.on("maybe", data => {
            // do something with the notification data

            push.finish(
                () => {
                    //console.log("accept callback finished");
                },
                () => {
                    //console.log("accept callback failed");
                },
                data.additionalData.notId
            );
        });

        this.iosPush = push;
    }

    @action
    incomingNotification(data) {
        const isForeground = data.additionalData.foreground;
        if (isForeground) {
            templateStore.openSnackbar(data.message);
        } else {
            this.routingStore.push("/musician/booking/1/details");
        }
    }

    @action
    unsubscribe() {
        if (this.socket) {
            this.socket.disconnect();
        }

        if (this.iosPush) {
            const userTopic = `${ENVIRONMENT}-userId-${this.userId}`;
            this.iosPush.unsubscribe(
                userTopic,
                () => {
                    // console.log("success unsubscribe");
                },
                e => {
                    // console.log("error:", e);
                }
            );
            this.iosPush.unregister(
                () => {
                    // console.log("success unregister");
                },
                () => {
                    // console.log("error");
                }
            );
        }
    }

    @action
    loadNotifications(userId) {
       var result = []
        return agent.Notification.load().then(
            action(async notifications => {
                await Promise.all(notifications.map(async notification => {
                    let profileImageUrl = null;
                    if (typeof notification.fromProfileImageUrl !== "undefined") {
                        profileImageUrl = await CacheHelper.getImgLink(notification.fromProfileImageUrl)
                    }

                    runInAction(() => {
                        notification.profileImageUrl = profileImageUrl ? profileImageUrl : blankProfile;
                        result.push(notification)

                        this.unread = notifications.filter(x => x.isRead === false).length;
                        const unreadBadge = this.unread;
                        if (this.iosPush) {
                            this.iosPush.setApplicationIconBadgeNumber(() => { }, () => { }, unreadBadge);
                        }
                    })
                }))
                runInAction(() => {
                    this.notifications = _.orderBy(result, "createdAt",'desc');
                    this.notificationsUpdated = this.notifications.slice(0);
                })
                
                return _.orderBy(notifications, "createdAt",'desc');
            })
        );
    }

    @action
    gotoPage(notification, skipRedirect) {
        let forwardUrl = GetNotificationLink(notification);
        const currentProfile = profileStore.getCurrentProfile()
        if (notification && notification.toProfile) {
            var newProfileType = notification.toProfile.type;
            if (newProfileType === ProfileType.Act.ordinal) {
                if (notification?.toProfile?.actAdminId) {
                    var profile = profileStore.getCurrentProfileById(notification?.toProfile?.actAdminId);
                    if (profile?.type  && profile?.type !== currentProfile?.type) {
                        newProfileType = profile.type;
                    }
                    else {
                        newProfileType = currentProfile.type;
                    }
                }
                else {
                    newProfileType = ProfileType.Musician.ordinal;
                }
            }
            else if (newProfileType === ProfileType.Venue.ordinal) {
                newProfileType = ProfileType.Staff.ordinal;
            }
            profileStore.setProfileType(newProfileType);
        }
        if (!skipRedirect) {

            if (forwardUrl === "/agent/edit/new/consultant/create") {

                this.history.push(forwardUrl);

            } else if (forwardUrl == "/agent") {
                this.history.push(forwardUrl);
                window.location.reload();
            } else {
                if (this.history.location.pathname === forwardUrl) {
                    window.location.reload();
                } else {
                    this.history.push(forwardUrl);
                }
            }
        }
        return forwardUrl;
    }

    @action
    async read(notification) {
        if (!notification.isRead) {
            notification.isRead = true;
            this.unread -= 1;
            await agent.Notification.read(notification.id);
        }
    }

    @action
    markAllAsRead() {
        this.notifications = []
        return agent.Notification.readAll()
        .then(action(res => {
            this.loadNotifications()
            return res
        }))
    }

    @action
    delete(notification) {
        var notificationIds = [];
        notificationIds.push(notification.id);
        agent.Notification.delete(notificationIds);
        this.notifications.remove(notification);
        this.notificationsUpdated.remove(notification);
        if (!notification.isRead) {
            this.unread -= 1;
        }
    }

    @action
    async deleteList(notificationIds) {
        for (var i = 0; i < notificationIds.length; i++) {
            var notification = this.notifications.filter(x => x.id === notificationIds[i])[0];
            var notificationsUpdated = this.notificationsUpdated.filter(x => x.id === notificationIds[i])[0];
            this.notifications.remove(notification);
            this.notificationsUpdated.remove(notificationsUpdated);
            if (notification && !notification.isRead) {
                this.unread -= 1;
            }
        }
        agent.Notification.delete(notificationIds);
    }

    @action
    async deleteAll() {
        return agent.Notification.deleteAll()
        .then(action(res => {
            this.notifications = []
            this.notificationsUpdated = []
            return res
        }))
    }

    @action
    async confirm  (notification, isLineup) {
        if (!notification.isRead) {
            notification.isRead = true;
            this.unread -= 1;
        }

        var jsonData = JSON.parse(notification.message);
        if (!jsonData.Confirmed) {
            jsonData.Confirmed = true;
            notification.message = JSON.stringify(jsonData);
            const lineup = { id: jsonData?.LineupId, status: LineupStatusType.Confirmed.ordinal };
            const booking = { id: notification.bookingId, status: BookingStatusType.Ready.ordinal };
            return (isLineup ? agent.Lineup.updateStatus(lineup) :  agent.Booking.updateStatus(notification?.toProfile?.id, booking))
            .then(async () => {
                // booking.status = BookingStatusType.Ready.ordinal
                // return agent.Lineup.updateStatus(lineup).then(async () => {
                // await agent.Booking.updateStatus(notification?.toProfile?.id, booking);

                await agent.Notification.update(notification);
            })
        } else {
            return
        }
    }

    @action
    reminder(notification) {
        if (!notification.isRead) {
            notification.isRead = true;
            this.unread -= 1;
        }

        // we schedule the reminder here
        var jsonData = JSON.parse(notification.message);
        if (!jsonData.Reminder) {
            jsonData.Confirmed = true;
            jsonData.Reminder = true;
            notification.message = JSON.stringify(jsonData);
            const lineup = { id: jsonData.LineupId, status: LineupStatusType.Confirmed.ordinal };
            agent.Lineup.setReminder(lineup);
        }

        return agent.Notification.update(notification);
    }

    @action
    confirmBooking(notification) {
        if (!notification.isRead) {
            notification.isRead = true;
            this.unread -= 1;
        }

        var jsonData = JSON.parse(notification.message);
        if (!jsonData.Confirmed) {
            jsonData.Confirmed = true;
            notification.message = JSON.stringify(jsonData);
            const booking = { id: notification.bookingId, status: BookingStatusType.Ready.ordinal };
            agent.Booking.updateStatus(notification.bookingId, booking);
            return agent.Notification.update(notification);
        }
    }

    @action
    reminderBooking(notification) {
        if (!notification.isRead) {
            notification.isRead = true;
            this.unread -= 1;
        }

        // we schedule the reminder here
        var jsonData = JSON.parse(notification.message);
        // jsonData.Reminder = false;
        if (!jsonData.Reminder) {
            jsonData.Confirmed = true;
            jsonData.Reminder = true;
            notification.message = JSON.stringify(jsonData);
            const booking = { id: notification.bookingId, status: BookingStatusType.Ready.ordinal };
            agent.Booking.setReminder(notification.bookingId, booking);
            return agent.Notification.update(notification);
        }
    }
}

export default new NotificationStore();
