import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField } from "@material-ui/core";

@inject("authStore", "templateStore", "userStore")
@observer
class AccountDetails extends Component {
    state = {
        firstName: "",
        lastName: "",
        isDirty: false,
    };

    componentDidMount = () => {
        this.props.authStore.reset();

        this.props.userStore.getUserCognito()

        const { currentUser } = this.props.userStore;

        this.setState({
            firstName: currentUser.given_name,
            lastName: currentUser.family_name,
            email: currentUser.email,
        });
    };
    
    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }
    handleChange = name => event => {

        if (event.target.value.length == 0) {
            this.setState({ isDirty: false });
        } else {
            this.setState({ isDirty: true });
        }
        this.setState({
            [name]: event.target.value
        });
    };

    handleCancel = () => {
        const { currentUser } = this.props.userStore;
        console.log(currentUser)

        this.setState({
            firstName: currentUser.given_name,
            lastName: currentUser.family_name,
            email: currentUser.email,
        });
    };

    handleSubmitForm = e => {
        e.preventDefault();

        this.props.templateStore.showLoadingScreen();
        this.props.userStore.updateUser(this.state.firstName, this.state.lastName, this.state.email).then(() => {
            const { currentUser } = this.props.userStore;

            this.setState({
                firstName: currentUser.given_name,
                lastName: currentUser.family_name,
                email: currentUser.email,
                isDirty: false
            });
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.openSnackbar("Account has been updated.");
        });
    };

    render() {
        return (
            <div>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <TextField
                            id="firstName"
                            label="First name"
                            value={this.state.firstName}
                            onChange={this.handleChange("firstName")}
                            fullWidth
                            classes={{ root: "text-field-readonly" }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="lastName"
                            label="Last name"
                            value={this.state.lastName}
                            onChange={this.handleChange("lastName")}
                            fullWidth
                            classes={{ root: "text-field-readonly" }}
                            margin="normal"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="email"
                            label="Email"
                            value={this.state.email}
                            fullWidth
                            disabled={true}
                            classes={{ root: "text-field-readonly" }}
                            margin="normal"
                            error={this.anyError("email")}
                            helperText={this.errorText("email")}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} className="text-xs-right">
                                <Button onClick={this.handleCancel} disabled={!this.state.isDirty}>Close</Button>
                                <Button
                                    variant="contained"
                                    onClick={this.handleSubmitForm}
                                    style={{ marginLeft: 10 }}
                                    color="primary"
                                    disabled={!this.state.isDirty}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default AccountDetails;
