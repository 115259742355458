import { observable, action } from "mobx";
import agent from "../agent";
import { EmbedType } from "../types/enum";

class EmbedGigStore {
    @observable headerBgColor = undefined;
    @observable headerFtColor = undefined;
    @observable dateBgColor = undefined;
    @observable dateFtColor = undefined;
    @observable tableBgColor = undefined;
    @observable tableWidgetBgColor = undefined;
    @observable tableFtColor = undefined;
    @observable hideToolbar = undefined;
    @observable hideOrderUber = undefined;
    @observable hideShareButton = undefined;
    @observable enabledTransparent = undefined;
    @observable numberEvent = 5;
    @observable embedType = 2;
    @action
    setHeaderBgColor(color) {
        this.headerBgColor = color;
    }

    @action
    setNumberEvent(value){
        this.numberEvent = value
    }

    @action
    setEmbedType(state) {
        this.embedType = state;
    }

    @action
    setHeaderFtColor(color) {
        this.headerFtColor = color;
    }

    @action
    setDateBgColor(color) {
        this.dateBgColor = color;
    }

    @action
    setDateFtColor(color) {
        this.dateFtColor = color;
    }

    @action
    setTableBgColor(color) {
        this.tableBgColor = color;
    }

    @action
    setTableWidgetBgColor(color) {
        this.tableWidgetBgColor = color;
    }
    
    @action
    setTableFtColor(color) {
        this.tableFtColor = color;
    }

    @action
    setHideToolbar(state) {
        this.hideToolbar = state;
    }

    @action
    setEnabledTransparent(state) {
        this.enabledTransparent = state;
    }

    
    @action
    setHideOrderUber(state) {
        this.hideOrderUber = state;
    }

    @action
    setHideShareButton(state) {
        this.hideShareButton = state;
    }

    @action
    loadEmbedCode(profileId) {
        let self = this;
        return agent.EmbedGig.getByProfileId(profileId).then(
            action(embed => {
                if (embed) {
                    self.headerBgColor = embed.headerBgColor;
                    self.headerFtColor = embed.headerFtColor;
                    self.dateBgColor = embed.dateBgColor;
                    self.dateFtColor = embed.dateFtColor;
                    self.tableBgColor = embed.tableBgColor;
                    self.tableWidgetBgColor = embed.tableWidgetBgColor ? embed.tableWidgetBgColor : "#ff0c9c";
                    self.tableFtColor = embed.tableFtColor;
                    self.hideToolbar = embed.hideToolbar;
                    self.hideOrderUber = embed.hideOrderUber;
                    self.hideShareButton = embed.hideShareButton;
                    self.numberEvent = embed.numberEvent;
                    self.embedType = embed.embedType;
                    self.enabledTransparent = embed.enabledTransparent;
                } else {
                    self.headerBgColor = "#262626";
                    self.headerFtColor = "#fff";
                    self.dateBgColor = "#ff0c9c";
                    self.dateFtColor = "#000000";
                    self.tableBgColor = "#ffff";
                    self.tableWidgetBgColor = "#ff0c9c";
                    self.tableFtColor = "#000000";
                    self.hideToolbar = false;
                    self.hideOrderUber = false;
                    self.hideShareButton = false;
                    self.numberEvent = 5;
                    self.embedType = 2;
                    self.enabledTransparent = false
                }
            })
        );
    }

    @action
    createEmbedCode(profileId) {
        let data = {
            headerBgColor: this.headerBgColor,
            headerFtColor: this.headerFtColor,
            dateBgColor: this.dateBgColor,
            dateFtColor: this.dateFtColor,
            tableBgColor: !this.embedType === EmbedType.Grid.ordinal || !this.enabledTransparent ?  this.tableBgColor : "#fff",
            tableFtColor: this.tableFtColor,
            hideToolbar: this.hideToolbar,
            hideOrderUber: this.hideOrderUber, 
            hideShareButton: this.hideShareButton, 
            numberEvent: this.numberEvent,
            embedType: this.embedType,
            tableWidgetBgColor: this.tableWidgetBgColor,
            enabledTransparent: this.enabledTransparent,
            profileId
        };

        return agent.EmbedGig.createEmbedCode(data);
    }
}

export default new EmbedGigStore();
