import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

@inject("songListStore")
@observer
class SongListTableHeader extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
        const { topSongs } = this.props.songListStore;

        return (
            <TableHead>
                <TableRow>
                    <TableCell className="th-songlist nopad" padding="checkbox" style={{ width: "6%" }}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount && numSelected !== 0}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>

                    <TableCell className="th-songlist" key="title" padding="default" sortDirection={orderBy === "title" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "title"} direction={order} onClick={this.createSortHandler("title")}>
                                <Typography variant="subheading" className="head-title">
                                    Song Title
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="artist" padding="default" sortDirection={orderBy === "artist" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "artist"} direction={order} onClick={this.createSortHandler("artist")}>
                                <Typography variant="subheading" className="head-title">
                                    Artist
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="topsongs" style={{ width: "16%" }}>
                        <Tooltip title="Top songs are prioritised for display in the public profile page">
                            <Typography variant="subheading" className="head-title">
                                Top Songs ({topSongs.length}/12)
                            </Typography>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="mediaUrl" style={{ width: "10%" }}>
                        <Typography variant="subheading" className="head-title">
                            Media
                        </Typography>
                    </TableCell>

                    <TableCell className="th-songlist" key="songKey" style={{ width: "10%" }}>
                        <Typography variant="subheading" className="head-title">
                            Song Key
                        </Typography>
                    </TableCell>

                    <TableCell className="th-songlist" key="edit" style={{ width: "14%" }} />
                </TableRow>
            </TableHead>
        );
    }
}

SongListTableHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

export default SongListTableHeader;
