import React, { Component } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

const grid = 16;

const getHorizontalItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 4,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? "#ff0c9c" : undefined,

    // styles we need to apply on draggables
    ...draggableStyle
});

const getVerticalItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 4,
    margin: `0`,

    // change background colour if dragging
    background: isDragging ? "#ff0c9c" : undefined,

    // styles we need to apply on draggables
    ...draggableStyle
});

const getHorizontalListStyle = isDraggingOver => ({
    background: isDraggingOver
        ? "repeating-linear-gradient(45deg,rgba(242,155,18,0.20),rgba(242,155,18,0.20) 20px,rgba(242,155,18,0.30) 20px,rgba(242,155,18,0.30) 40px)"
        : "white",
    display: "flex",
    padding: grid,
    overflow: "auto"
});

const getVerticalListStyle = isDraggingOver => ({
    background: isDraggingOver
        ? "repeating-linear-gradient(45deg,rgba(242,155,18,0.20),rgba(242,155,18,0.20) 20px,rgba(242,155,18,0.30) 20px,rgba(242,155,18,0.30) 40px)"
        : "white",
    width: "100%"
});

class DragDrop extends Component {
    render() {
        const items = this.props.items || [];
        return (
            <Droppable droppableId={this.props.droppableId} type={this.props.type} direction={this.props.horizontal ? "horizontal" : undefined}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={
                            this.props.horizontal ? getHorizontalListStyle(snapshot.isDraggingOver) : getVerticalListStyle(snapshot.isDraggingOver)
                        }
                        {...provided.droppableProps}
                    >
                        {items.map((item, index) => (
                            <Draggable key={index + 1} type={this.props.type} draggableId={this.props.droppableId + "@" + (index + 1)} index={index}>
                                {(provided, snapshot) => (
                                    <div className={this.props.classes && this.props.classes.wrapper ? this.props.classes.wrapper : ""}>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={
                                                this.props.horizontal
                                                    ? getHorizontalItemStyle(snapshot.isDragging, provided.draggableProps.style)
                                                    : getVerticalItemStyle(snapshot.isDragging, provided.draggableProps.style)
                                            }
                                        >
                                            {this.props.renderContent(item, index + 1)}
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }
}

export default DragDrop;
