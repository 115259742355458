import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import {
    Paper,
    Checkbox,
    Tooltip,
    IconButton,
    Snackbar,
    Button,
    TextField,
    Grid,
    Toolbar,
    Typography,
    CircularProgress,
    MenuItem,
    Card,
    ListItemText,
    InputAdornment
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import green from "@material-ui/core/colors/green";
import Slide from "@material-ui/core/Slide";

import Countries from "../../../lookup/Country";
import SelectField from "../../fields/SelectField";
import "./PlayedAtTable.css";

import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

// import AutoComplete from "../../fields/AutoComplete";

const columnData = [
    { columnTitle: "name", numeric: false, disablePadding: true, label: "Name" },
    { columnTitle: "suburb", numeric: false, disablePadding: true, label: "Suburb" },
    { columnTitle: "state", numeric: false, disablePadding: true, label: "State" },
    { columnTitle: "country", numeric: false, disablePadding: true, label: "Country" }
];

@inject("designProfileStore")
@observer
class PlayedAtTable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            rowsPerPage: 5,
            openDelete: false,
            openDialog: false,
            openAdd: false,
            openEdit: false,
            editedId: 0,
            inputName: "",
            inputSuburb: "",
            inputState: "",
            inputCountry: "",
            suggestions: [],
            open: false,
            value: "",
            loading: false,
            isEdit: false
        };
    }

    lastData = [];

    handleRequestSort = (event, property) => {
        const orderBy = property;
        const { playedAts } = this.props.designProfileStore;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        let data =
            order === "desc"
                ? playedAts.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                : playedAts.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.props.designProfileStore.setPlayedAts(data);

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        const { playedAts } = this.props.designProfileStore;

        if (checked) {
            this.setState({ selected: playedAts.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleClearSelected = () => {
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    createSortHandler = property => event => {
        this.handleRequestSort(event, property);
    };

    handleDeleteClick = playedAt => () => {
        let deletedPlayedAt = [{ id: playedAt.id }];

        const {
            country,
            id,
            name,
            profileId,
            state,
            suburb
        } = playedAt;

        this.props.designProfileStore.deletePlayedAt(deletedPlayedAt).then(results => {
            this.lastData = [
               {
                country,
                id,
                name,
                profileId,
                state,
                suburb
               }
            ];
            
            this.handleClearSelected();
            this.setState({ openDelete: true });
        }); 
    };

    handleDeleteBulkClick = () => {
        var selected=this.state.selected;
        let deletedPlayedAt = [];
        for(var i=0; i<selected.length;i++){
            deletedPlayedAt.push({id:selected[i]});
        }

        this.props.designProfileStore.deletePlayedAt(deletedPlayedAt).then(results => {
            this.lastData = results;
            this.handleClearSelected();
            this.setState({ openDelete: true });
        });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        this.setState({ openDelete: false });
        if (reason === "undo") {
            this.props.designProfileStore.addPlayedAt(this.lastData);
        }
    };

    handleUndoRemovePlayedAt = event => {
        this.handleSnackbarDeleteClose(event, "undo");
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    handleOpenAddDialog = () => {
        this.setState({ openDialog: true, isEdit: false });
        this.resetDialogForm();
    };

    handleOpenDialog = playedAt => () => {
        this.setState({
            openDialog: true,
            editedId: playedAt.id,
            inputName: playedAt.name,
            inputSuburb: playedAt.suburb,
            inputState: playedAt.state,
            inputCountry: playedAt.country,
            isEdit: true
        });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    };

    handleNameChange = e => {
        this.setState({ inputName: e.target.value });
    };

    handleSuburbChange = e => {
        this.setState({ inputSuburb: e.target.value });
    };

    handleStateChange = e => {
        this.setState({ inputState: e.target.value });
    };

    handleCountryChange = e => {
        this.setState({ inputCountry: e });
    };

    resetDialogForm = () => {
        this.setState({
            editedId: 0,
            inputName: "",
            inputSuburb: "",
            inputState: "",
            inputCountry: "",
            isEdit: false
        });
    };

    handleSubmitClick = () => {
        this.props.designProfileStore
            .editPlayedAt({
                id: this.state.editedId,
                name: this.state.inputName,
                suburb: this.state.inputSuburb,
                state: this.state.inputState,
                country: this.state.inputCountry
            })
            .then(results => {
                if (this.state.editedId) {
                    this.handleSnackbarEditOpen();
                } else {
                    this.lastData = results;
                    this.handleSnackbarAddOpen();
                }
            })
            .finally(() => {
                this.resetDialogForm();
            });

        this.handleCloseDialog();
    };

    handleSnackbarAddOpen = () => {
        this.setState({ openAdd: true });
    };

    handleSnackbarAddClose = (event, reason) => {
        this.setState({ openAdd: false });
        if (reason === "undo") {
            //execute undo add
            this.props.designProfileStore.deletePlayedAt(this.lastData);
        }
    };

    handleSnackbarEditOpen = () => {
        this.setState({ openEdit: true });
    };

    handleSnackbarEditClose = (event, reason) => {
        this.setState({ openEdit: false });
    };

    handleUndoAddPlayedAt = event => {
        this.handleSnackbarAddClose(event, "undo");
    };

    getItemValue = item => {
        return item.name;
    };

    handleSelect = (value, item) => {
        this.setState({
            value: "",
            open: false
        });
        this.setState({ inputCountry: item.name });
    };

    handleSearch = value => {
        this.setState(
            {
                loading: true,
                suggestions: []
            },
            () => {
                let filterItems = query => {
                    return Countries.filter(function(country) {
                        return country.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                };

                let result = filterItems(value);

                this.setState({
                    loading: false,
                    suggestions: result
                });
            }
        );
    };

    handleChange = (event, value) => {
        this.setState({ inputCountry: value, open: false });

        if (value.length > 1) {
            clearTimeout(this.requestTimer);
            this.requestTimer = setTimeout(this.handleSearch(value), 5000);
            this.setState({ inputCountry: value, open: true });
        }
    };

    renderMenu = (children, value) => {
        if (children && value.length > 3) {
            if (!this.state.loading && this.handleEmptySuggestion !== undefined) {
                return (
                    <Card className="menu" style={{ position: "absolute", backgroundColor: "#FFFFFF", width: "100%", zIndex: "9999" }}>
                        <MenuItem button>
                            <ListItemText primary="No Country Found" />
                        </MenuItem>
                    </Card>
                );
            }
        }
        return (
            <div className="menu" style={{ position: "absolute", zIndex: "9999", width: "100%" }}>
                {children}
            </div>
        );
    };

    renderInput = props => {
        const { ref, ...rest } = props;
        return (
            <TextField
                {...rest}
                fullWidth
                inputRef={ref}
                margin="dense"
                InputProps={{
                    endAdornment: this.state.loading ? (
                        <InputAdornment position="start">
                            {" "}
                            <CircularProgress size={30} />
                        </InputAdornment>
                    ) : (
                        ""
                    )
                }}
            />
        );
    };

    renderItem = (item, isHighlighted) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                style={{
                    zIndex: 99999,
                    backgroundColor: isHighlighted ? "#ff0c9c" : "#ffffff"
                }}
            >
                <ListItemText primary={item.name} />
            </MenuItem>
        );
    };

    render() {
        const { classes, fullScreen } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, inputName, inputState, inputSuburb, inputCountry } = this.state;
        const { playedAts, playedAtInProgress, playedAtSuccess } = this.props.designProfileStore;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, playedAts.length - page * rowsPerPage);

        let numSelected = selected.length;

        let countriesSuggestion = () => {
            return Countries.map(country => {
                return {
                    value: country.name,
                    label: country.name,
                    divider: country.divider
                };
            });
        };

        return (
            <Paper className={classes.root}>
                <Toolbar
                    className={classNames(classes.toolbarRoot, {
                        [classes.highlight]: numSelected > 0
                    })}
                >
                    <div className={classes.title}>
                        {numSelected > 0 ? (
                            <Typography variant="subheading" style={{ color: "#FFF" }}>
                                {numSelected} selected
                            </Typography>
                        ) : (
                            <Typography variant="subheading">Venues</Typography>
                        )}
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.actions}>
                        {numSelected > 0 ? (
                            <Tooltip title="Delete">
                                <Button id="delete-played-at" aria-label="Delete" style={{ color: "#FFF" }} onClick={this.handleDeleteBulkClick}>
                                    <DeleteIcon /> Delete
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Add">
                                <Button
                                    id="add-played-at"
                                    aria-label="Add"
                                    onClick={this.handleOpenAddDialog}
                                    style={playedAtSuccess ? { color: green[800] } : null}
                                >
                                    {playedAtSuccess ? (
                                        <Fragment>
                                            <CheckIcon style={{ color: "#FFF" }} /> <span style={{ color: "#FFF" }}>Add</span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <AddIcon style={{ color: "#FFF" }} /> <span style={{ color: "#FFF" }}>Add</span>
                                        </Fragment>
                                    )}
                                </Button>
                            </Tooltip>
                        )}
                    </div>

                    {playedAtInProgress && <CircularProgress size={60} className={classes.buttonProgress} />}
                </Toolbar>

                <div className={classes.tableWrapper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < playedAts.length}
                                        checked={selected.length === playedAts.length}
                                        onChange={this.handleSelectAllClick}
                                    />
                                </TableCell>
                                {columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.columnTitle}
                                            numeric={column.numeric}
                                            padding={column.disablePadding ? "none" : "default"}
                                            sortDirection={orderBy === column.columnTitle ? order : false}
                                        >
                                            <Tooltip title="Sort" placement={column.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                                                <TableSortLabel
                                                    active={orderBy === column.columnTitle}
                                                    direction={order}
                                                    onClick={this.createSortHandler(column.columnTitle)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    );
                                }, this)}
                                <TableCell padding="checkbox" numeric={true} />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {playedAts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                const isSelected = this.isSelected(n.id);
                                return (
                                    <TableRow hover aria-checked={isSelected} tabIndex={-1} key={n.id} selected={isSelected}>
                                        <TableCell padding="checkbox">
                                            <Checkbox checked={isSelected} onClick={event => this.handleClick(event, n.id)} />
                                        </TableCell>
                                        <TableCell padding="none">{n.name}</TableCell>
                                        <TableCell padding="none">{n.suburb}</TableCell>
                                        <TableCell padding="none">{n.state}</TableCell>
                                        <TableCell padding="none">{n.country}</TableCell>
                                        <TableCell numeric={true}>
                                            <Tooltip title="Edit">
                                                <Button color="primary" size="small" onClick={this.handleOpenDialog(n)}>
                                                    Edit
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <Button color="primary" size="small" onClick={this.handleDeleteClick(n)}>
                                                    Delete
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    SelectProps={{ className: "transparent-select" }}
                                    count={playedAts.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                    TransitionComponent={this.transition}
                    className="dialog-div played-at-dialog"
                >
                    <DialogTitle className="see-more-dialog-title">{this.state.isEdit ? "Edit Venue" : "Add Venue"}</DialogTitle>
                    <DialogContent className="see-more-dialog-content" style={{ overflow: "unset" }}>
                        <div style={{ padding: 16 }}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <DialogContentText className={classes.blackTextColor}>
                                        Enter the venue information. This will be displayed on your profile.
                                    </DialogContentText>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        autoFocus
                                        id="venue-name"
                                        placeholder="Venue Name"
                                        label="Venue Name"
                                        margin="none"
                                        fullWidth
                                        value={inputName}
                                        onChange={this.handleNameChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="venue-suburb"
                                        placeholder="Suburb"
                                        label="Suburb"
                                        margin="none"
                                        fullWidth
                                        value={inputSuburb}
                                        onChange={this.handleSuburbChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="venue-state"
                                        placeholder="State"
                                        label="State"
                                        margin="none"
                                        fullWidth
                                        value={inputState}
                                        onChange={this.handleStateChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SelectField
                                        id="venue-country"
                                        multi={false}
                                        suggestions={countriesSuggestion()}
                                        onChange={this.handleCountryChange}
                                        value={inputCountry}
                                        placeholder=" "
                                        label="Country"
                                        margin="dense"
                                        className="countries"
                                    />
                                    {/* <TextField
                                        id="country"
                                        placeholder="Country"
                                        label="Country"
                                        margin="none"
                                        fullWidth
                                        select
                                        value={inputCountry}
                                        onChange={this.handleCountryChange}
                                        className="venue-country"
                                    >
                                        {Countries.map((country, i) => {
                                            return (
                                                <MenuItem key={i} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField> */}
                                    {/* <AutoComplete
                                        items={this.state.suggestions}
                                        getItemValue={this.getItemValue}
                                        open={this.state.open}
                                        value={this.state.inputCountry}

                                        onChange={this.handleChange}
                                        onSelect={this.handleSelect}

                                        renderInput={this.renderInput}
                                        renderMenu={this.renderMenu}
                                        renderItem={this.renderItem}
                                        placeholder="Country"
                                    /> */}
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Close
                        </Button>
                        <Button
                            id="submit-venue"
                            onClick={this.handleSubmitClick}
                            color="primary"
                            disabled={inputName && inputSuburb && inputState && inputCountry ? false : true}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openAdd}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarAddClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Venue Added</span>}
                    action={[
                        <Button key="undo" color="primary" size="small" onClick={this.handleUndoAddPlayedAt}>
                            UNDO
                        </Button>,
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarAddClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openEdit}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarEditClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Venue Edited</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDelete}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarDeleteClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Venue Deleted</span>}
                    action={[
                        <Button key="undo" color="primary" size="small" onClick={this.handleUndoRemovePlayedAt}>
                            UNDO
                        </Button>,
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarDeleteClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </Paper>
        );
    }
}

PlayedAtTable.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    toolbarRoot: {
        paddingRight: theme.spacing.unit,
        backgroundColor: "#ff0c9c"
    },
    highlight: {
        backgroundColor: "#333333"
    },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
    blackTextColor: {
        color: "#000"
    },
    whiteTextColor:{
        color:"#ffffff"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: 2,
        right: 2,
        zIndex: 1
    }
});

export default withMobileDialog()(withStyles(styles)(PlayedAtTable));
