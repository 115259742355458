import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import generalProfileStore from "./generalProfileStore";
import { Promise } from "bluebird";
import uniqid from "uniqid";
import { VenueMenuType } from "../types/enum";
import profileStore from "./profileStore";
import validate from "../helper/validation.js"

class VenueMenuStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable userId = 0;
    @observable type = 0; // 1 == musician

    @observable foodList = [];
    @observable drinkList = [];
    @observable bookTableUrl = "";

    // Region Menu Validation
    @observable menuErrors = undefined;

    @observable
    foodSections = [
        {
            value: 7,
            label: 'Burgers'
        }, 
        {
            value: 3,
            label: 'Dessert'
        },
        {
            value: 1,
            label: "Entree"
        },
        {
            value: 9,
            label: 'Kids'
        }, 
        {
            value: 2,
            label: "Main"
        },
        {
            value: 10,
            label: 'Pizza & Pasta'
        }, 
        {
            value: 8,
            label: 'Salads'
        }, 
        {
            value: 6,
            label: 'Seafood'
        }, 
        {
            value: 11,
            label: 'Seniors'
        }, 
        {
            value: 12,
            label: 'Sides'
        },
        {
            value: 5,
            label: 'Steaks'
        },   
        {
            value: 4,
            label: 'Tapas & Share'
        },  
    ];

    @observable
    drinkSections = [
        {
            value: 101,
            label: "Beers"
        },
        {
            value: 104,
            label: "Cocktails"
        },
        {
            value: 102,
            label: "Coffees"
        },
        {
            value: 105,
            label: "Red Wines"
        },
        {
            value: 103,
            label: "Softies"
        },
        {
            value: 107,
            label: "Sparkling Wines"
        },
        {
            value: 106,
            label: "White Wines"
        }
    ];

    @observable
    food = {
        id: undefined,
        type: 0,
        name: undefined,
        section: undefined,
        price: undefined,
        description: "",
        tags: ""
    };

    @observable
    drink = {
        id: undefined,
        type: 1,
        name: undefined,
        section: undefined,
        price: undefined,
        description: "",
        tags: ""
    };

    @action
    setType(type) {
        this.type = type;
    }

    @action
    setBookTableUrl(bookTableUrl) {
        this.errors = undefined;
        this.bookTableUrl = bookTableUrl;
    }

    @action
    validateBookTableUrl() {
        if(this.bookTableUrl.length === 0) return this.errors = undefined
        if(validate.validateWhiteSpace(this.bookTableUrl)) {
            this.errors = undefined
        } else {
            this.errors = {bookTableUrl: "Invalid URL"}
        }
    }

    @action
    reset() {
        this.foodList = [];
        this.drinkList = [];
        this.bookTableUrl = "";
    }

    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        const { id } = generalProfileStore;

        profileId = profileId ? profileId : id;
        if(profileId) {
            profileStore.getProfile(profileId)
            .then(profile => {
                if(profileStore.venueNameTitle !== "Create venue") profileStore.setVenueNameTitle("Edit Venue - " + profile.profileName)
            })
        }
        this.id = profileId;
        if (currentUser) {
            this.inProgress = true;

            return agent.VenueMenuProfile.get(profileId)
                .then(
                    action(response => {
                        this.id = profileId;
                        this.bookTableUrl = response.bookTableUrl;
                        if (response) return new Promise.resolve(this.splitMenusIntoFoodAndDrink(response.menus));
                        return Promise.resolve();
                    })
                )
                .finally(
                    action(() => {
                        this.inProgress = false;
                    })
                );
        } else {
            this.inProgress = true;

            this.inProgress = false;
            return new Promise.resolve(true);
        }
    }

    @action
    splitMenusIntoFoodAndDrink(menus) {
        return menus.forEach(menu => {
            if (menu.type === VenueMenuType.Food.ordinal) {
                const food = {
                    id: menu.id,
                    type: 0,
                    name: menu.name,
                    section: menu.section,
                    price: parseFloat(menu.price).toFixed(2),
                    description: menu.description,
                    tags: menu.tags !== "" && menu.tags ? menu.tags.split(",") : ""
                };

                this.addToFoodList(food);
            } else if (menu.type === VenueMenuType.Drink.ordinal) {
                const drink = {
                    id: menu.id,
                    type: 1,
                    name: menu.name,
                    section: menu.section,
                    price: parseFloat(menu.price).toFixed(2),
                    description: menu.description,
                    tags: menu.tags !== "" && menu.tags ? menu.tags.split(",") : ""
                };
                this.addToDrinkList(drink);
            }
        });
    }

    @action
    addToFoodList(newFood) {
        this.validateAddFoodFields(newFood);
        if (this.menuErrors) return;

        for (let i = 0; i < this.foodList.length; i++) {
            if (this.foodList[i].id === newFood.id) {
                this.foodList[i] = newFood;
                return;
            }
        }

        newFood.id = newFood.id ? newFood.id : uniqid();

        this.foodList.push(newFood);
    }

    @action
    addToDrinkList(newDrink) {
        this.validateAddDrinkFields(newDrink);
        if (this.menuErrors) return;

        for (let i = 0; i < this.drinkList.length; i++) {
            if (this.drinkList[i].id === newDrink.id) {
                this.drinkList[i] = newDrink;
                return;
            }
        }

        newDrink.id = newDrink.id ? newDrink.id : uniqid();

        this.drinkList.push(newDrink);
    }

    @action
    updateFoodAttribute(key, value) {
        this.food[key] = value;
    }

    @action
    updateDrinkAttribute(key, value) {
        this.drink[key] = value;
    }

    @action
    setFoodValue(food) {
        this.food = food ? { ...food } : undefined;
    }

    @action
    setDrinkValue(drink) {
        this.drink = drink ? { ...drink } : undefined;
    }

    @action
    resetFoodAttribute() {
        this.food = {
            id: undefined,
            type: 0,
            name: undefined,
            section: undefined,
            price: undefined,
            description: "",
            tags: ""
        };
        this.menuErrors = undefined;
    }

    @action
    resetDrinkAttribute() {
        this.drink = {
            id: undefined,
            type: 1,
            name: undefined,
            section: undefined,
            price: undefined,
            description: "",
            tags: ""
        };
        this.menuErrors = undefined;
    }

    @action
    getFoodSectionLabel(value) {
        let section = this.foodSections.find(x => x.value === value);
        if (section) {
            return section.label;
        }
        return "-";
    }

    @action
    getDrinkSectionLabel(value) {
        let section = this.drinkSections.find(x => x.value === value);
        if (section) {
            return section.label;
        }
        return "-";
    }

    @action
    deleteFoodMenu(deletedFoodMenuId) {
        let deleted = undefined;
        for (let i = 0; i < this.foodList.length; i++) {
            if (this.foodList[i].id === deletedFoodMenuId) {
                deleted = this.foodList.splice(i, 1);
                break;
            }
        }
        return deleted;
    }

    @action
    deleteDrinkMenu(deletedDrinkMenuId) {
        let deleted = undefined;
        for (let i = 0; i < this.drinkList.length; i++) {
            if (this.drinkList[i].id === deletedDrinkMenuId) {
                deleted = this.drinkList.splice(i, 1);
                break;
            }
        }
        return deleted;
    }

    @action
    updateFoodList(food) {
        this.foodList[food.id] = food;
        return this.foodList;
    }

    @action
    updateDrinkList(drink) {
        this.drinkList[drink.id] = drink;
        return this.drinkList;
    }

    @action
    createMenu(profileId, data) {
        return new Promise.resolve(agent.VenueMenuProfile.create(data)).then(response => {
            return response;
        });
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        this.userId = user.id;
        let menuList = [],
            menus = [];

        this.foodList.map(food => menuList.push(food));
        this.drinkList.map(drink => menuList.push(drink));

        menuList.forEach((menu, i) => {
            menus[i] = {};
            menus[i].id = Number.isInteger(menu.id) ? menu.id : undefined;
            menus[i].profileId = menu.profileId ? menu.profileId : this.id;
            menus[i].type = menu.type;
            menus[i].name = menu.name;
            menus[i].section = menu.section;
            menus[i].price = parseFloat(menu.price);
            menus[i].description = menu.description;
            menus[i].tags =
                typeof menu.tags !== "undefined" && menu.tags.length > 0
                    ? menu.tags
                          .map(tag => {
                              return tag;
                          })
                          .join(",")
                    : menu.tags;
        });

        const data = {
            id: this.id,
            bookTableUrl: this.bookTableUrl,
            menus: menus
        };
        return this.createMenu(this.id, data)
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    // Region Validation
    validateAddFoodFields = food => {
        let findError = false;
        this.menuErrors = {
            foodName: undefined,
            foodSection: undefined,
            foodPrice: undefined
        };

        if (!food.name || food.name.length === 0) {
            this.menuErrors.foodName = "Food name is required!";
            findError = true;
        } else {
            this.menuErrors.foodName = undefined;
        }

        if (!food.section || food.section.length === 0) {
            this.menuErrors.foodSection = "Food section is required!";
            findError = true;
        } else {
            this.menuErrors.foodSection = undefined;
        }

        if (!food.price || food.price.length === 0) {
            this.menuErrors.foodPrice = "Food price is required!";
            findError = true;
        } else {
            this.menuErrors.foodPrice = undefined;
        }

        if (!findError) this.menuErrors = undefined;
    };

    validateAddDrinkFields = drink => {
        let findError = false;
        this.menuErrors = {
            drinkName: undefined,
            drinkSection: undefined,
            drinkPrice: undefined
        };

        if (!drink.name || drink.name.length === 0) {
            this.menuErrors.drinkName = "Drink name is required!";
            findError = true;
        } else {
            this.menuErrors.drinkName = undefined;
        }

        if (!drink.section || drink.section.length === 0) {
            this.menuErrors.drinkSection = "Drink section is required!";
            findError = true;
        } else {
            this.menuErrors.drinkSection = undefined;
        }

        if (!drink.price || drink.price.length === 0) {
            this.menuErrors.drinkPrice = "Drink price is required!";
            findError = true;
        } else {
            this.menuErrors.drinkPrice = undefined;
        }

        if (!findError) this.menuErrors = undefined;
    };

    // Region Copy Venue Menu
    @action
    getMenuListFromSelectedVenue(venueId) {
        this.inProgress = true;
        return (agent.VenueMenuProfile.get(venueId).then(menus => {
            this.inProgress = false;
            return Promise.resolve(menus);
        }));
    }

    @action
    copySelectedMenu(selectedMenus, type) {
        if (type === VenueMenuType.Food.ordinal) {
            selectedMenus.forEach(menu => {
                const newFood = {
                    name: menu.name,
                    type: 0,
                    price: menu.price,
                    section: this.foodSections.find(x => x.label === menu.section).value,
                    description: menu.description,
                    tags: menu.tags !== "" ? menu.tags : ""
                };
                this.addToFoodList(newFood);
            });
        } else {
            selectedMenus.forEach(menu => {
                const newDrink = {
                    name: menu.name,
                    type: 1,
                    price: menu.price,
                    section: this.drinkSections.find(x => x.label === menu.section).value,
                    description: menu.description,
                    tags: menu.tags !== "" ? menu.tags : ""
                };
                this.addToDrinkList(newDrink);
            });
        }
    }
}

export default new VenueMenuStore();
