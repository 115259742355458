import React, { Component } from 'react'
import { inject, observer } from "mobx-react";
import ModalContainer from '../../modal/ModalContainer';
import { DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
// import SelectField from '../../fields/SelectField';
import { withRouter } from "react-router-dom";
import WidgetContainer from '../../widget/WidgetContainer';
import SelectField from '../../fields/SelectField';

@inject("userFestivalStore", "templateStore", "agentVenueStore", "userStore")
@withRouter
@observer
class FestivalModal extends Component{

    constructor(props){
        super(props);
        this.state = {
            festivals: undefined,
            isLoading: false,
            venueId: undefined,
            shortedDescription: "",
            hideDescription: false
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.open && !prevProps.open){
            const { userActFestival } = this.props.userFestivalStore; 
            if(userActFestival)
            {
                this.setState({
                    ...this.state,
                    isLoading:true,
                    hideDescription: userActFestival.agent.festivalDescription.length > 1500
                })
                this.props.agentVenueStore.getAgentVenue(userActFestival.agentId).then(() => {
                    this.setState({
                        ...this.state,
                        isLoading:false
                    })
                });
            }
        }
    }

    handleSkip = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.userStore.completeUser().then( () => {
            this.props.templateStore.hideLoadingScreen();
            this.props.history.push("/myact");
        })
    }

    handleRegister = () => {
        const { actId } = this.props;
        const { venueId } = this.state;
        const { userActFestival } = this.props.userFestivalStore;
        if(actId && venueId){
            this.props.templateStore.showLoadingScreen();
            Promise.all([
                this.props.userFestivalStore.registerActToFestivals(actId, userActFestival.id, venueId),
                this.props.userStore.completeUser()
            ]).then( () => {
                this.props.templateStore.openSnackbar("Registration Success!");
                this.props.templateStore.hideLoadingScreen();
                this.props.history.push("/myact");
            }, () => {
                this.props.templateStore.openSnackbar("Registration Failed!");
            })
        }
    }

    handleVenueChange = value => {
        this.setState({
            ...this.state,
            venueId: value
        })
    }

    mapVenueSuggestions = () => {
        const { agentVenues } = this.props.agentVenueStore;
        return agentVenues.map(data => {
            return {
                value: data.venueId,
                label: data.venue.fullName
            };
        });
    }

    render(){
        const { open } = this.props;
        const { venueId,hideDescription } = this.state;
        const { userActFestival, errors } = this.props.userFestivalStore;
        return (
            <ModalContainer  open={open} title="Festival Registration">
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={6}>
                            {
                                userActFestival ? (
                                    <WidgetContainer title = { userActFestival.agent.festivalTitle ? userActFestival.agent.festivalTitle : userActFestival.agent.festivalCode }>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: userActFestival.agent.festivalDescription }}
                                            style = {
                                                {
                                                    height: hideDescription ? 250 : undefined
                                                }
                                            }
                                         />
                                    </WidgetContainer>
                                ) : null
                            }
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <SelectField
                                id="bookingVenue"
                                label="Venue"
                                suggestions={ this.mapVenueSuggestions() }
                                onChange={this.handleVenueChange}
                                value={ venueId }
                                placeholder="Select Venue"
                                error={errors && errors["venue"]}
                                helperText={errors && errors["venue"] ? errors["venue"] : ""}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick = { this.handleSkip }>
                        Skip
                    </Button>
                    <Button color="primary" onClick = { this.handleRegister }>
                        Register
                    </Button>
                </DialogActions>
            </ModalContainer>
        )
    }
}

export default FestivalModal;