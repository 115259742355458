import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Grid, TextField, FormControl, FormControlLabel, Checkbox, InputLabel, Typography, Button, Switch /*MenuItem*/ } from "@material-ui/core";

import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";

import MomentUtils from "@date-io/moment";

import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RichTextEditor from "../richTextEditor/RichTextEditor";

import Dropzone from "react-dropzone";

import "./SavePromotionForm.css";
import SelectField from "../fields/SelectField";

import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationModal from "../modal/ConfirmationModal";
import moment from "moment";

const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";

const FILE_MAXSIZE = IS_MOBILE ? 8497152 : 2097152;
const ACCEPTED_FILE = "image/*";

@withRouter
@inject("venueStore", "promotionStore", "templateStore")
@observer
class SavePromotionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venueSuggestion: [],
            addAllChecked: false,
            addStateChecked: false,
            addRegionChecked: false,
            selectedStates: "",
            selectedRegions: "",
            level2Label: "",
            level3Label: "",
            showDeletePromotionConfirmationModal: false,
            publishToFacebook: false,
            publishToFacebookDateOption: 0,
            publishToFacebookDate: moment(),
            isFollowAndRedeem: false,
        };
    }

    componentDidMount() {
        this.props.venueStore.getUserVenuesBasedOnPermission(null, null, null, null, true).then(response => {
            var firstVenue = response[0];
            this.setState({
                ...this.state,
                venueSuggestion: response,
                level2Label: firstVenue && firstVenue.organisation ? firstVenue.organisation.level2Label : "State",
                level3Label: firstVenue && firstVenue.organisation ? firstVenue.organisation.level3Label : "Region"
            });
        });
    }

    componentWillUnmount() {
        this.props.promotionStore.resetPromotionData();
    }

    handlePromotionImageChange = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.props.templateStore.openSnackbar("Profile image couldn't be uploaded. Please check size and format.");
        } else {
            if (files) {
                this.props.promotionStore.setPromotionValue("image", files);
            }
        }
    };

    handleDeletePromotionImage = () => {
        this.props.promotionStore.setPromotionValue("image", undefined);
        this.props.promotionStore.setPromotionValue("imageUrl", undefined);
    };

    handlePromotionTitleChange = e => {
        this.props.promotionStore.setPromotionValue("title", e.target.value);
    };

    handlePromotionDateStartChange = value => {
        const { promotion } = this.props.promotionStore;

        this.props.promotionStore.setPromotionValue("dateStart", value);

        if (moment(promotion.dateStart).endOf("day") > moment(promotion.dateEnd).endOf("day")) {
            this.props.promotionStore.setPromotionValue("dateEnd", value);
        }
    };

    handlePromotionDateEndChange = value => {
        this.props.promotionStore.setPromotionValue("dateEnd", value);
    };

    handleVenueChange = value => {
        const { venueSuggestion } = this.state;
        var arrValue = value.split(",");
        if (arrValue.length !== venueSuggestion.length) {
            this.setState({
                ...this.state,
                addAllChecked: false
            });
        }
        this.props.promotionStore.setPromotionValue("venues", value);
    };

    handleCheckFollowAndReedem = (e, checked) => {
        this.setState(
            {
                ...this.state,
                isFollowAndRedeem: checked
            },
        );
        this.props.promotionStore.setPromotionValue("isFollowAndRedeem", checked);
    };

    handleCheckAddAllVenue = (e, checked) => {
        this.setState(
            {
                ...this.state,
                addAllChecked: checked
            },
            () => {
                if (checked) {
                    const { venueSuggestion } = this.state;
                    var allVenueId = venueSuggestion.map(venue => venue.id).join();
                    this.props.promotionStore.setPromotionValue("venues", allVenueId);
                    this.setState({
                        ...this.state,
                        addStateChecked: false,
                        addRegionChecked: false
                    });
                }
            }
        );
    };

    handleCheckAddStateVenue = (e, checked) => {
        this.setState({
            ...this.state,
            addStateChecked: checked
        });
    };

    handleCheckAddRegionVenue = (e, checked) => {
        this.setState({
            ...this.state,
            addRegionChecked: checked
        });
    };

    handleStateChange = value => {
        const { venueSuggestion } = this.state;
        const { promotion } = this.props.promotionStore;

        var stateIds = value.split(",");
        var venueIds = promotion.venues?.length > 0 ? promotion.venues.split(",") : [];

        var statesVenues = [];

        stateIds.forEach(stateId => {
            var stateVenues = venueSuggestion.filter(x => x.stateId === parseInt(stateId, 10)).map(venue => {
                return venue.id;
            });

            stateVenues.forEach(stateVenueId => {
                if (statesVenues.find(x => x === stateVenueId) === undefined) {
                    statesVenues.push(stateVenueId);
                }
            });
        });

        statesVenues.forEach(venueId => {
            if (venueIds.find(x => parseInt(x, 10) === venueId) === undefined) {
                venueIds.push(venueId);
            }
        });

        this.props.promotionStore.setPromotionValue("venues", venueIds.join());

        this.setState({
            ...this.state,
            selectedStates: value
        });
    };

    handleRegionChange = value => {
        const { venueSuggestion } = this.state;
        const { promotion } = this.props.promotionStore;

        var regionIds = value.split(",");
        var venueIds = promotion.venues?.length > 0 ? promotion.venues.split(",") : [];

        var regionsVenues = [];

        regionIds.forEach(regionId => {
            var regionVenues = venueSuggestion.filter(x => x.regionId === parseInt(regionId, 10)).map(venue => {
                return venue.id;
            });

            regionVenues.forEach(regionVenueId => {
                if (regionsVenues.find(x => x === regionVenueId) === undefined) {
                    regionsVenues.push(regionVenueId);
                }
            });
        });

        regionsVenues.forEach(venueId => {
            if (venueIds.find(x => parseInt(x, 10) === venueId) === undefined) {
                venueIds.push(venueId);
            }
        });

        this.props.promotionStore.setPromotionValue("venues", venueIds.join());

        this.setState({
            ...this.state,
            selectedRegions: value
        });
    };

    handleRedeemLimitChange = e => {
        this.props.promotionStore.setPromotionValue("redeemLimit", parseInt(e.target.value, 10));
    };

    handleDescriptionChange = value => {
        this.props.promotionStore.setPromotionValue("description", value);
    };

    handleIsDraftChange = (e, checked) => {
        this.props.promotionStore.setPromotionValue("isDraft", checked);
        this.props.promotionStore.setPromotionValue("published", !checked);
    };

    handlePublishChange = (e, checked) => {
        this.props.promotionStore.setPromotionValue("published", checked);
    };

    handlePublishToFacebookChange = (e, checked) => {
        this.setState({
            ...this.state,
            publishToFacebook: checked
        });
    };

    handlePublishToFacebookDateOptionChange = e => {
        this.setState({
            ...this.state,
            publishToFacebookDateOption: e.target.value
        });
    };

    handlePublishToFacebookDateChange = value => {
        this.setState({
            ...this.state,
            publishToFacebookDate: value
        });
    };

    handleCancelSavePromotion = () => {
        this.props.history.goBack();
    };

    handleSavePromotion = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.promotionStore.savePromotion().then(response => {
            if (response.error) {
                this.props.templateStore.openSnackbar("Incomplete fields");
            } else if (response.published) {
                this.props.history.push("/promotion/published");
            } else {
                this.props.history.push("/promotion/draft");
            }
        })
        .finally(() => {
            this.props.templateStore.hideLoadingScreen();
        })
    };

    handleShowDeletePromotionConfirmationModal = () => {
        this.setState({
            ...this.state,
            showDeletePromotionConfirmationModal: true
        });
    };

    handleHideDeletePromotionConfirmationModal = () => {
        this.setState({
            ...this.state,
            showDeletePromotionConfirmationModal: false
        });
    };

    handleRemovePromotion = () => {
        const { promotion } = this.props.promotionStore;
        this.props.templateStore.showLoadingScreen();
        this.props.promotionStore.removePromotion(promotion.id).then(
            response => {
                this.props.templateStore.openSnackbar(`Promotion ${response.title} has been removed.`);
                this.props.templateStore.hideLoadingScreen();
                this.handleHideDeletePromotionConfirmationModal();
                this.props.history.goBack();
            },
            () => {
                this.props.templateStore.openSnackbar(`Promotion ${promotion.title} can not be removed.`);
                this.props.templateStore.hideLoadingScreen();
                this.handleHideDeletePromotionConfirmationModal();
            }
        );
    };

    mapVenueSuggestion = () => {
        const { venueSuggestion } = this.state;
        return venueSuggestion.map(venue => {
            return {
                value: venue.id,
                label: venue.profileName
            };
        });
    };

    mapStateSuggestion = () => {
        const { venueSuggestion } = this.state;
        var states = [];
        venueSuggestion.forEach(venue => {
            if (states.find(x => x.value === venue.stateId) === undefined) {
                if (venue.stateEntity) {
                    states.push({
                        value: venue.stateId,
                        label: venue.stateEntity?.name
                    });
                }
            }
        });

        return states;
    };

    mapRegionSuggestion = () => {
        const { venueSuggestion } = this.state;
        var regions = [];
        venueSuggestion.forEach(venue => {
            if (regions.find(x => x.value === venue.regionId) === undefined) {
                if (venue.region) {
                    regions.push({
                        value: venue.regionId,
                        label: venue.region?.name
                    });
                }
            }
        });

        return regions;
    };

    render() {
        const {
            addAllChecked,
            addStateChecked,
            addRegionChecked,
            selectedStates,
            selectedRegions,
            level2Label,
            level3Label,
            showDeletePromotionConfirmationModal
            //publishToFacebook,
            //publishToFacebookDateOption,
            //publishToFacebookDate
        } = this.state;
        const { promotion, promotionImage, error } = this.props.promotionStore;
        const { loadingScreenState } = this.props.templateStore;
        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Promotion Image
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Upload an image for your promotion
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <InputLabel className="margin-bottom-10">Promotion Image</InputLabel>
                                        {promotionImage || promotion.imageUrl ? (
                                            <div className="picture-container">
                                                <img
                                                    src={promotion.imageUrl || promotionImage[0].preview}
                                                    alt="promotion_image"
                                                    style={{ maxWidth: "500px", maxHeight: "500px" }}
                                                />
                                                <Button
                                                    variant="fab"
                                                    color="secondary"
                                                    className="del-picture"
                                                    onClick={this.handleDeletePromotionImage}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </div>
                                        ) : (
                                            <Dropzone
                                                accept={ACCEPTED_FILE}
                                                maxSize={FILE_MAXSIZE}
                                                onDrop={this.handlePromotionImageChange}
                                                multiple={false}
                                                className="dropzone"
                                            >
                                                <p>Select file.</p>
                                            </Dropzone>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Promotion Detail
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Use this section to input the description, select start and end dates and specify which venues this promotion will
                                    apply to.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Promotion Title"
                                            fullWidth
                                            value={promotion.title}
                                            onChange={this.handlePromotionTitleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker
                                                id="booking-date"
                                                className="booking-date-picker"
                                                keyboard
                                                minDate={moment()}
                                                value={promotion.dateStart}
                                                onChange={this.handlePromotionDateStartChange}
                                                fullWidth
                                                margin="dense"
                                                label="Date Start"
                                                format="DD-MM-YYYY"
                                                keyboardIcon={<EventIcon />}
                                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker
                                                id="booking-date"
                                                className="booking-date-picker"
                                                keyboard
                                                minDate={promotion.dateStart || moment()}
                                                value={promotion.dateEnd}
                                                onChange={this.handlePromotionDateEndChange}
                                                fullWidth
                                                margin="dense"
                                                label="Date End"
                                                format="DD-MM-YYYY"
                                                keyboardIcon={<EventIcon />}
                                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <FormControl variant="outlined" className="save-checkbox">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        className="checkbox-dense"
                                                        checked={addAllChecked}
                                                        onChange={this.handleCheckAddAllVenue}
                                                    />
                                                }
                                                label="Apply to All Venues"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <FormControl variant="outlined" className="save-checkbox">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        className="checkbox-dense"
                                                        checked={addStateChecked}
                                                        onChange={this.handleCheckAddStateVenue}
                                                        disabled={addAllChecked}
                                                    />
                                                }
                                                label={`Add venues on selected ${level2Label}s`}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <FormControl variant="outlined" className="save-checkbox">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        className="checkbox-dense"
                                                        checked={addRegionChecked}
                                                        onChange={this.handleCheckAddRegionVenue}
                                                        disabled={addAllChecked}
                                                    />
                                                }
                                                label={`Add venues on selected ${level3Label}s`}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {addStateChecked && !addAllChecked ? (
                                        <Grid item xs={12}>
                                            <SelectField
                                                id="bookingActType"
                                                label={level2Label}
                                                value={selectedStates}
                                                suggestions={this.mapStateSuggestion()}
                                                placeholder={`Select ${level2Label}s`}
                                                multi
                                                onChange={this.handleStateChange}
                                            />
                                        </Grid>
                                    ) : null}
                                    {addRegionChecked && !addAllChecked ? (
                                        <Grid item xs={12}>
                                            <SelectField
                                                id="bookingActType"
                                                label={level3Label}
                                                value={selectedRegions}
                                                suggestions={this.mapRegionSuggestion()}
                                                placeholder={`Select ${level3Label}s`}
                                                multi
                                                onChange={this.handleRegionChange}
                                            />
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={12}>
                                        <SelectField
                                            id="bookingActType"
                                            label="Applied Venues"
                                            value={promotion.venues}
                                            suggestions={this.mapVenueSuggestion()}
                                            placeholder="Select venue which has promotion applied."
                                            multi
                                            error={error && error["venue"]}
                                            helperText={error && error["venue"] ? error["venue"] : ""}
                                            onChange={this.handleVenueChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Promotion Redeem Detail
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Set the total number of redemptions available for this promotion. If selecting multiple venues, this total will be
                                    the total number of redemptions available across the group.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Redeem Limit"
                                            placeholder="Put at least one, if you put 0 then there is no redeem limit."
                                            type="number"
                                            fullWidth
                                            value={promotion.redeemLimit}
                                            onChange={this.handleRedeemLimitChange}
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    step: 1
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Follow and Redeem
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    If selected, user need to follow venue to redeem ticket
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormControl variant="outlined" className="save-checkbox">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                className="checkbox-dense"
                                                checked={promotion.isFollowAndRedeem}
                                                onChange={this.handleCheckFollowAndReedem}
                                            />
                                        }
                                        label="Follow and Redeem"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={3}>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Promotion Description Detail
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Use the rich text editor to give the details about your promotion.
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Your promotion will not be published to the gig guide app until you click the ‘Publish to Hot Giggity Live’ button.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <InputLabel>Description</InputLabel>
                                        {
                                            !loadingScreenState && <RichTextEditor value={promotion.description} onChange={this.handleDescriptionChange} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={3}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} lg={6}>
                                        <FormControl variant="outlined" className="save-checkbox">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        className="checkbox-dense"
                                                        checked={promotion.isDraft}
                                                        onChange={this.handleIsDraftChange}
                                                    />
                                                }
                                                label="Save as Draft"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <FormControl variant="outlined" className="save-checkbox">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        color="primary"
                                                        disabled={promotion.isDraft}
                                                        checked={promotion.published}
                                                        onChange={this.handlePublishChange}
                                                    />
                                                }
                                                label="Publish to Hot Giggity Live"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="pull-right">
                            <Button style={{ marginRight: "8px" }} onClick={this.handleCancelSavePromotion}>
                                Cancel
                            </Button>
                            {promotion.id ? (
                                <Button
                                    disabled={!promotion.canRemove}
                                    style={{ marginRight: "8px" }}
                                    variant="flat"
                                    color="primary"
                                    onClick={this.handleShowDeletePromotionConfirmationModal}
                                >
                                    Cancel Promotion
                                </Button>
                            ) : null}
                            <Button color="primary" variant="contained" onClick={this.handleSavePromotion}>
                                {promotion.published ? "Publish" : "Save"} Promotion
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <ConfirmationModal
                    open={showDeletePromotionConfirmationModal}
                    title="Remove Promotions"
                    message={`Are you sure to remove ${promotion.title}?`}
                    closeHandler={this.handleHideDeletePromotionConfirmationModal}
                    confirmationHandler={this.handleRemovePromotion}
                    declineHandler={this.handleHideDeletePromotionConfirmationModal}
                    confirmationLabel="Remove"
                />
            </Fragment>
        );
    }
}

export default SavePromotionForm;
