import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import "./Modal.css";
import Loading from "../loadingSpinner/Loading";

class ModalContainer2 extends Component {
    render() {
        const { fullScreen } = this.props;
        return (
            <Dialog
                disableEnforceFocus={true}
                open={this.props.open}
                onClose={this.props.onClose}
                onEntered={this.props.onEntered}
                fullScreen={fullScreen}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "md"}
                fullWidth
                disableRestoreFocus
                className="dialog-div"
                disableBackdropClick={this.props.disableBackdropClick}
                disableEscapeKeyDown={this.props.disableEscapeKeyDown}
            >
                <DialogTitle
                    disableTypography
                    className="dialogTitle"
                >
                    <h2 style={{fontWeight:"bold",textAlign:"center", width:"100%"}}>{this.props.title || ""}</h2>
                    <IconButton onClick={this.props.onClose}>
                        <CloseIcon style={{color:"white"}}/>
                    </IconButton>
                </DialogTitle>
                <Divider 
                    style={
                        {
                            marginBottom: "16px",
                        }
                    }
                />
                <div style={{ position: "relative" }}>
                    <Loading showed={this.props.isLoading} />
                    {this.props.children}
                </div>
            </Dialog>
        );
    }
}
export default withMobileDialog()(ModalContainer2);
