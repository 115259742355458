import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, TextField, Button, Switch } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { MuiPickersUtilsProvider } from "material-ui-pickers";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";

import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import DateRange from "@material-ui/icons/DateRange";
import AccessTime from "@material-ui/icons/AccessTime";

import { DateTimePicker, DatePicker } from "material-ui-pickers";
import Slide from "@material-ui/core/Slide";

@inject("eventStore")
@observer
class EventDialog extends Component {
    handleNameChange = e => {
        this.props.eventStore.setName(e.target.value);
    };

    handleFromChange = date => {
        this.props.eventStore.setFrom(date);

        const { from, to } = this.props.eventStore;
        if (from > to) {
            this.props.eventStore.setTo(date);
        }
    };

    handleToChange = date => {
        this.props.eventStore.setTo(date);

        const { from, to } = this.props.eventStore;
        if (to < from) {
            this.props.eventStore.setTo(from);
        }
    };

    handleAllDayChange = e => {
        this.props.eventStore.setAllDay(e.target.checked);
    };

    handleSubmitClick = e => {
        e.preventDefault();
        this.props.eventStore.submit().then(() => {
            this.props.handleCloseDialog();
        });
    };

    handleDeleteClick = e => {
        this.props.eventStore.delete();
        this.props.handleCloseDialog();
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    render() {
        const { name, from, to, allDay, inProgress } = this.props.eventStore;
        const { fullScreen } = this.props;

        return (
            <Dialog fullScreen={fullScreen} open={this.props.openDialog} onClose={this.props.handleCloseDialog} transition={this.transition}>
                <DialogTitle className="see-more-dialog-title">{this.props.isEdit ? "Edit Event" : "Create Event"}</DialogTitle>
                <DialogContent className="see-more-dialog-content">
                    <div style={{ padding: 16 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid container className={classNames(!fullScreen ? "event-popup-size" : "")}>
                                {inProgress ? (
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <CircularProgress style={{ margin: "auto" }} size={50} />
                                    </Grid>
                                ) : (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="name"
                                                label="Event Name"
                                                margin="dense"
                                                fullWidth
                                                autoFocus
                                                value={name}
                                                onChange={this.handleNameChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div style={{ marginLeft: -14 }}>
                                                <Switch checked={allDay} onChange={this.handleAllDayChange} value="allDay" color="primary" />
                                                All Day
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {allDay ? (
                                                <DatePicker
                                                    value={from}
                                                    fullWidth
                                                    margin="dense"
                                                    format="ddd, DD-MM-yyyy hh:mm A"
                                                    onChange={this.handleFromChange}
                                                    keyboardIcon={<EventIcon />}
                                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                                    label="From"
                                                />
                                            ) : (
                                                <DateTimePicker
                                                    value={from}
                                                    fullWidth
                                                    margin="dense"
                                                    format="ddd, DD-MM-yyyy hh:mm A"
                                                    onChange={this.handleFromChange}
                                                    keyboardIcon={<EventIcon />}
                                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                                    timeIcon={<AccessTime />}
                                                    dateRangeIcon={<DateRange />}
                                                    label="From"
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            {allDay ? (
                                                <DatePicker
                                                    value={to}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={this.handleToChange}
                                                    label="To"
                                                    format="ddd, DD-MM-yyyy hh:mm A"
                                                    minDate={from}
                                                    minDateMessage="Date should not be before From Date"
                                                    keyboardIcon={<EventIcon />}
                                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                                />
                                            ) : (
                                                <DateTimePicker
                                                    value={to}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={this.handleToChange}
                                                    label="To"
                                                    format="ddd, DD-MM-yyyy hh:mm A"
                                                    minDate={from}
                                                    minDateMessage="Date should not be before From Date"
                                                    keyboardIcon={<EventIcon />}
                                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                                    timeIcon={<AccessTime />}
                                                    dateRangeIcon={<DateRange />}
                                                />
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleCloseDialog}>Close</Button>
                    {this.props.isEdit && (
                        <Button onClick={this.handleDeleteClick} color="primary">
                            Delete
                        </Button>
                    )}
                    <Button onClick={this.handleSubmitClick} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
export default withMobileDialog()(EventDialog);
