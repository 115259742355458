import { observable, action } from "mobx";
import { ProfileType } from "../types/enum";
import moment from "moment";

const SHOW_PRODUCTION_CREW = process.env.REACT_APP_SHOW_PRODUCTION_CREW === "true";

class TemplateStore {
    //sidebar state

    @observable history = undefined;
 
    @action
    setHistory(history) {
        this.history = history;
    }
    
    @observable openSubMenu = false;

    @action
    setOpenSubMenu(state) {
        this.openSubMenu = state;
    }
    
    @action
    replaceLink(address) {
        if (this.history) {
            this.history.replace(address);
        }
    }

    @action
    redirectTo(address) {
        if (this.history) {
            this.history.push(address);
        }
    }

    @observable activeSidebarKey = "dashboard";

    @action
    setActiveSidebarKey(key) {
        this.activeSidebarKey = key;
    }

    @action
    getActiveSideBarKey() {
        return this.activeSidebarKey;
    }
    //Header Popover
    @observable openHeaderPopover = false;
    @observable headerPopoverAnchor = null;
    @observable headerPopoverVerticalAnchorAlign = "bottom";
    @observable headerPopoverHorizontalAnchorAlign = "right";
    @observable headerPopoverVerticalTransformAlign = "top";
    @observable headerPopoverHorizontalTransformAlign = "right";
    @observable editProfileActiveStep = 0;
    @observable editMusicianBookingActiveStep = 0;
    @observable editBookingStep = 0;
    @observable welcomeStep = 0;

    @observable lineUpBlock = false;
    @observable organisationBlock = false;
    @observable stateBlock = false;
    @observable regionBlock = false;
    @observable memberBlock = false;

    @observable snackbarState = false;
    @observable snackbarMessage = "";

    @observable mainSearchValue = "";
    @observable permanent = false;
    //snackbar function

    @action
    openSnackbar(message) {
        this.snackbarState = true;
        this.snackbarMessage = message;
    }
    
    @action
    closeSnackbar() {
        this.snackbarState = false;
        this.snackbarMessage = "";
    }

    @action
    setMainSearchValue(query) {
        this.mainSearchValue = query;
    }

    @observable loadingScreenState = false;

    @action
    toggleHeaderPopover() {
        this.openHeaderPopover = !this.openHeaderPopover;
    }

    @action
    setHeaderPopOverAnchor(element) {
        this.headerPopoverAnchor = element;
    }

    @action
    showLoadingScreen() {
        this.loadingScreenState = true;
        //this.autoHideLoadingScreen();
    }

    autoHideLoadingScreen() {
        setTimeout(
            action(() => {
                this.loadingScreenState = false;
            }),
            3000
        );
    }

    @action
    hideLoadingScreen() {
        this.loadingScreenState = false;
    }

    //Sidebar Popover
    @observable openSidebarPopover = false;
    @observable sidebarPopoverAnchor = null;
    @observable sidebarPopoverVerticalAnchorAlign = "bottom";
    @observable sidebarPopoverHorizontalAnchorAlign = "right";
    @observable sidebarPopoverVerticalTransformAlign = "top";
    @observable sidebarPopoverHorizontalTransformAlign = "right";

    @action
    toggleSidebarPopover() {
        this.openSidebarPopover = !this.openSidebarPopover;
    }

    @action
    setSidebarPopOverAnchor(element) {
        this.sidebarPopoverAnchor = element;
    }

    //Sidebar
    @observable showSidebarToggleButton = false;
    @action
    toggleSidebar() {
        this.showSidebarToggleButton = !this.showSidebarToggleButton;
    }

    @action
    setShowSidebar = value => {
        this.showSidebarToggleButton = value;
    };

    //Musician edit profile
    @action
    setEditProfileActiveStep(step) {
        this.editProfileActiveStep = step;
    }

    // Booking Detail lineup toggle block
    @action
    openLineupBlock() {
        this.lineUpBlock = false;
    }
    @action
    closeLineupBlock() {
        this.lineUpBlock = true;
    }

    // Edit organisation toggle block
    @action
    openOrganisationBlock() {
        this.organisationBlock = false;
    }
    @action
    closeOrganisationBlock() {
        this.organisationBlock = true;
    }

    // Edit state toggle block
    @action
    openStateBlock() {
        this.stateBlock = false;
    }
    @action
    closeStateBlock() {
        this.stateBlock = true;
    }

    // Edit region toggle block
    @action
    openRegionBlock() {
        this.regionBlock = false;
    }
    @action
    closeRegionBlock() {
        this.regionBlock = true;
    }

    // Edit member toggle block
    @action
    openMemberBlock() {
        this.memberBlock = false;
    }
    @action
    closeMemberBlock() {
        this.memberBlock = true;
    }

    @action
    setEditMusicianBookingActiveStep(step) {
        this.editMusicianBookingActiveStep = step;
    }

    @action
    setEditBookingStep(step) {
        this.editBookingStep = step;
    }

    @action
    setWelcomeStep(step) {
        this.welcomeStep = step;
    }

    // Notification Popover
    @observable notificationPopoverAnchor = null;

    @action
    setNotificationPopoverAnchor(element) {
        this.notificationPopoverAnchor = element;
    }

    getSteps(type) {
        switch (type) {
            case "agent":
                return ["General", "Profile Design", "Integrations", "Financial", "Insurance", "Terms and Conditions", "Privacy"];
            case "consultant":
                return ["General", "Profile Design", "Privacy"];
            case "privateHire":
                    return ["General", "Profile Design", "Privacy"];
            case "musician":
                return ["General", "Profile Design", "Integrations", "Financial", "Insurance", "Privacy"];
            case "production":
                return ["General", "Profile Design", "Integrations", "Financial", "Privacy"];
            case "dj":
                return ["General", "Profile Design", "Integrations", "Financial", "Privacy"];
            case "staff":
                return ["General", "Profile Design", "Signature", "Privacy"];
            case "venue":
                return ["General", "Profile Design", "Financial", "Integrations", "Members", "Favourite Acts", "Menu", "Worksheet Templates", "Privacy"];
            case "act":
                if(SHOW_PRODUCTION_CREW){
                    return [
                        "General",
                        "Profile Design",
                        "Integrations",
                        "Lineup",
                        "Production Roster",
                        "Song List",
                        "Set Lists",
                        "Terms And Conditions",
                        "Privacy"
                    ];
                }
                else{
                    return [
                        "General",
                        "Profile Design",
                        "Integrations",
                        "Lineup",
                        "Song List",
                        "Set Lists",
                        "Terms And Conditions",
                        "Privacy"
                    ];
                }                
            case "booking":
                return ["Booking Details", "Negotiate/Message", "TBD"];
            default:
                return ["General", "Profile Design", "Integrations", "Financial", "Privacy"];
        }
    }

    getType(typeString) {
        switch (typeString) {
            case "musician":
                return ProfileType.Musician.ordinal;
            case "agent":
                return ProfileType.Agent.ordinal;
            case "consultant":
                return ProfileType.Consultant.ordinal;
            case "staff":
                return ProfileType.Staff.ordinal;
            case "act":
                return ProfileType.Act.ordinal;
            case "venue":
                return ProfileType.Venue.ordinal;
            case "production":
                return ProfileType.Production.ordinal;
            case "dj":
                return ProfileType.Dj.ordinal;
            case "admin":
                return ProfileType.Admin.ordinal;
            case "privateHire":
                return ProfileType.PrivateHire.ordinal;
            default:
                return 0;
        }
    }

    getLabelType(typeString) {
        switch (typeString) {
            case ProfileType.Musician.ordinal:
                return "Artist/Entertainer";
            case ProfileType.Agent.ordinal:
                return "Agent";
            case ProfileType.Staff.ordinal:
                return "Staff";
            case ProfileType.Act.ordinal:
                return "Act";
            case ProfileType.Venue.ordinal:
                return "Venue";
            case ProfileType.Production.ordinal:
                return "Production";
            case ProfileType.Dj.ordinal:
                return "Dj";
            case ProfileType.Admin.ordinal:
                return "Admin";
            case ProfileType.PrivateHire.ordinal:
                return "Parties & Events";
            default:
                return null;
        }
    }

    getTypeString(typeId) {
        switch (typeId) {
            case ProfileType.Musician.ordinal:
                return "musician";
            case ProfileType.Agent.ordinal:
                return "agent";
            case ProfileType.Staff.ordinal:
                return "staff";
            case ProfileType.Act.ordinal:
                return "act";
            case ProfileType.Venue.ordinal:
                return "venue";
            case ProfileType.Production.ordinal:
                return "production";
            case ProfileType.Dj.ordinal:
                return "dj";
            case ProfileType.Admin.ordinal:
                return "admin";
                
            default:
                return null;
        }
    }

    getFullName = profile => {
        if (
            profile &&
            (profile.type === ProfileType.Musician.ordinal ||
                profile.type === ProfileType.Staff.ordinal ||
                profile.type === ProfileType.Production.ordinal ||
                profile.type === ProfileType.Dj.ordinal || 
                profile.type === ProfileType.Admin.ordinal)
        ) {
            return profile && profile.firstName + (profile.lastName ? " " + profile.lastName : "");
        } else if (
            profile &&
            (profile.type === ProfileType.Staff.ordinal || profile.type === ProfileType.Venue.ordinal || profile.type === ProfileType.Agent.ordinal)
        ) {
            if(profile && profile.profileName) {
                return profile.profileName
            } else if(profile && profile.firstName){
                return profile.firstName + " " + profile.lastName
            } else {
                return ""
            }
        } else if (profile && profile.type === ProfileType.Act.ordinal) {
            return profile && profile.actName;
        } else {
            return null;
        }
    };

    getMonth = (monthIndex, isShort) => {
        let monthLong = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let monthShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (isShort) {
            return monthShort[monthIndex];
        } else {
            return monthLong[monthIndex];
        }
    };

    getFormat12Hour(hour) {
        return moment(hour, "HH:mm").format("mm") === "00" ? "ha" : "h.mma";
    }
}

export default new TemplateStore();
