import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Testimonial from "./Testimonial";

class Testimonials extends Component {
    renderTestimonial = () => {
        let result = [];
        for (let i = 0; this.props.testimonials && i < this.props.testimonials.length; i++) {
            result.push(
                <Grid key={i} item xs={12} sm={6} lg={3}>
                    <Testimonial testimonial={this.props.testimonials[i]} even={i % 2 === 0} />
                </Grid>
            );
        }
        return result;
    };

    render() {
        return (
            <Grid container spacing={16}>
                {this.renderTestimonial()}
            </Grid>
        );
    }
}

export default Testimonials;
