import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import profileStore from "./profileStore";
// import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";

class SignatureProfileStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable bookingSignatureId = undefined;
    @observable profileId = undefined;
    @observable bookingId = undefined;
    @observable userId = 0;
    @observable type = 0;
    @observable fileContent = undefined;
    @observable newSignature = false;

    @observable visibility = "public";

    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        const { id } = generalProfileStore;
        const { currentProfile } = profileStore;
        profileId = profileId ? profileId : id;
        this.profileId = currentProfile?.id;
        this.id = undefined;

        if (currentUser) {
            this.inProgress = true;

            return agent.Signature.loadByProfileId(this.profileId)
                .then(
                    action(signature => {
                        if (!signature) {
                            this.id = undefined;
                        } else {
                            this.id = signature.id;
                            this.fileContent = signature.fileContent;
                            this.newSignature = false;
                        }
                        return signature;
                    })
                )
                .catch(
                    action(() => {
                       console.log("signature not found")
                    })
                )
                .finally(
                    action(() => {
                        this.inProgress = false;
                    })
                );
        } else {
            return Promise.resolve();
        }
    }
    @action
    setBookingId(bookingId){
        this.bookingId = parseInt(bookingId,10)
    }
    @action
    loadBookingSignature(bookingId) {
        this.bookingId = parseInt(bookingId, 10);
        const { currentProfile } = profileStore;
        this.profileId = currentProfile?.id;
        this.id = undefined;
        this.bookingSignatureId = undefined;
        const { currentUser } = userStore;

        if (currentUser) {
            this.inProgress = true;

            return agent.Signature.loadByBookingId(this.bookingId, this.profileId)
                .then(
                    action(signature => {
                        if (!signature) {
                            this.id = undefined;
                        } else {
                            // if this signature is coming from the booking, use the id as booking signature id
                            if (signature.bookingId) {
                                this.bookingSignatureId = signature.id;
                            }

                            this.id = signature.id;
                            this.fileContent = signature.fileContent;
                            this.newSignature = false;
                        }
                        return signature;
                    })
                )
                .finally(
                    action(() => {
                        this.inProgress = false;
                    })
                );
        } else {
            return Promise.resolve();
        }
    }

    @action
    reset() {
        this.visibility = "public";
    }

    @action
    setVisibility(visibility) {
        this.visibility = visibility;
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    setFile(file) {
        this.newSignature = true;
        this.fileContent = file;
    }

    @action
    submitProfile() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const { currentProfile } = profileStore;
        const signature = {
            id: this.id,
            profileId: currentProfile?.id,
            createdById: user.id,
            fileContent: this.fileContent
        };
        if (!this.newSignature) {
            this.inProgress = false;
            return Promise.resolve();
        }

        return agent.Signature.updateSignatureProfile(currentProfile?.id, signature).then(profile => {
            return profile;
        }).catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }
    
    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const { currentProfile } = profileStore;
        const signature = {
            id: this.id,
            profileId: currentProfile?.id,
            createdById: user.id,
            fileContent: this.fileContent
        };
        if (!this.newSignature) {
            this.inProgress = false;
            return Promise.resolve();
        }

        return (signature.id ? this.updateSignature(signature) : this.createSignature(signature))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    submitBookingSignature() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const signature = {
            id: this.bookingSignatureId,
            bookingId: this.bookingId,
            createdById: user.id,
            fileContent: this.fileContent
        };
        return (signature.id ? this.updateSignature(signature) : this.createSignature(signature))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    createSignature(data) {
        //using general profile for create
        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.Signature.create(this.profileId, this.fileContent).then(profile => {
                    return profile;
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Staff.ordinal:
                return agent.Signature.create(this.profileId, data).then(profile => {
                    return profile;
                });
            case ProfileType.Production.ordinal:
                return agent.ProductionGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Dj.ordinal:
                return agent.DjGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Act.ordinal:
            default:
                return agent.Signature.create(this.profileId, data).then(profile => {
                    return profile;
                });
        }
    }

    @action
    updateSignature(data) {
        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.MusicianPrivacyProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentPrivacyProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Staff.ordinal:
                return agent.Signature.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Act.ordinal:
                return agent.ActPrivacyProfile.update(data).then(profile => {
                    profileStore.setSoloAct(false);
                    return profile;
                });
            case ProfileType.Venue.ordinal:
                return agent.VenuePrivacyProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            case ProfileType.Production.ordinal:
                return agent.ProductionPrivacyProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            case ProfileType.Dj.ordinal:
                return agent.DjPrivacyProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            default:
                return agent.Signature.update(data).then(profile => {
                    return profile;
                });
        }
    }
}

export default new SignatureProfileStore();
