import React, { Component, Fragment } from "react";
import moment from "moment";

import { inject, observer } from "mobx-react";

import {
    Grid,
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    InputAdornment,
    Input,
    FormHelperText,
    Switch,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    Popover
} from "@material-ui/core";

import NumberFormat from "react-number-format";
import { ProfileType, BookingStatusType, BookingType } from "../../../types/enum";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";
const styles = theme => ({
    margin: {
        margin: theme.spacing.unit
    }
});
@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore", "templateStore", "commonStore")
@observer
class BookingFeeDetails extends Component {
    state = {
        approvalDialogOpen: false,
        checked: false,
        anchorEl: false,
    };

    handleExtraGigFeeChange = e => {
        let extraGigFee = e.target.value < 0 ? 0 : e.target.value;
        this.props.bookingRequestStore.setBooking("extraGigFee", extraGigFee);
    };

    handleRequestApprovalClick = () => {
        let extraGigFee = this.props.bookingRequestStore.booking.extraGigFee;

        if (extraGigFee === 0 || extraGigFee === "0") {
            this.props.templateStore.openSnackbar("Extra set fee must be more than 0");
            return;
        } else if (!extraGigFee) {
            this.props.templateStore.openSnackbar("Please fill the extra set fee amount");
            return;
        }

        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore
            .requestExtraGigFee(this.props.bookingRequestStore.booking.id, extraGigFee)
            .then(response => {
                this.props.templateStore.openSnackbar("Request approval for extra set fee has been sent");
                this.props.bookingRequestStore.setBooking("isApprovalSent", true);
            })
            .finally(e => {
                this.props.templateStore.hideLoadingScreen();
            });
    };

    handleRequestApprovedClick = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore
            .approveExtraGigFee(this.props.bookingRequestStore.booking.id, this.props.profileStore.currentProfile?.id)
            .then(response => {
                this.props.templateStore.openSnackbar("Extra set fee has been approved");
                this.props.bookingRequestStore.setBooking("extraGigFeeApprovalById", this.props.profileStore.currentProfile?.id);
                this.props.bookingRequestStore.setBooking("extraGigFeeApprovalAt", new Date());
                this.props.bookingRequestStore.setBooking("isApproved", true);
            })
            .finally(e => {
                this.props.templateStore.hideLoadingScreen();
                this.closeApprovalDialog();
            });
    };

    handleRequestDeclinedClick = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore
            .declineExtraGigFee(this.props.bookingRequestStore.booking.id, this.props.profileStore.currentProfile?.id)
            .then(response => {
                this.props.templateStore.openSnackbar("Extra set fee has been declined");
                this.props.bookingRequestStore.setBooking("extraGigFeeApprovalById", this.props.profileStore.currentProfile?.id);
                this.props.bookingRequestStore.setBooking("extraGigFeeApprovalAt", new Date());
                this.props.bookingRequestStore.setBooking("isApproved", false);
            })
            .finally(e => {
                this.props.templateStore.hideLoadingScreen();
                this.closeApprovalDialog();
            });
    };

    openApprovalDialog = () => {
        this.setState({
            approvalDialogOpen: true,
            checked: false
        });
    };

    closeApprovalDialog = () => {
        this.setState({ approvalDialogOpen: false });
    };

    handleCheckedChange = e => {
        this.setState({ checked: e.target.checked });
    };

    handleGigFeeChange = e => {
        const { agentCommissionType, agentCommissionFee } = this.props.bookingRequestStore.booking;
        let actGigFee = 0;
        let agentCommission = 0;
        let value = e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.')
        value = !value ? '' : value

        if (agentCommissionType === 0) {
            actGigFee = parseFloat(value) - parseFloat(agentCommissionFee);
            agentCommission = agentCommissionFee;
        } else if (agentCommissionType === 1) {
            agentCommission = (parseFloat(agentCommissionFee) / 100) * parseFloat(value);
            actGigFee = parseFloat(value) - agentCommission;
        }

        this.props.bookingRequestStore.setBooking("gigFee", value);
        this.props.bookingRequestStore.setBooking("actFee", actGigFee);
        this.props.bookingRequestStore.setBooking("agentCommissionAmount", agentCommission);
    };

    handleActFeeChange = e => {
        this.props.bookingRequestStore.setBooking("actFee", e.target.value);
    };

    handleGigFeeChange = e => {
        let value = e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.')
        value = !value ? '' : value
        const { agentCommissionType, agentCommissionFee, agentCommissionAmount, gigFee } = this.props.bookingRequestStore.booking;
        this.props.bookingRequestStore.setBooking("gigFee", value);
        var actFee = 0;
        if (agentCommissionType === 0) {
            actFee = value - (agentCommissionFee ? agentCommissionFee : 0);
        } else if (agentCommissionType === 1 && value !== 0) {
            if (parseInt(agentCommissionFee, 10) > 100 || parseInt(agentCommissionFee, 10) < 0) return false;
            let diffFee = (value / 100) * agentCommissionFee;
            this.props.bookingRequestStore.setBooking("agentCommissionAmount", parseFloat(diffFee).toFixed(2));
            actFee = parseFloat(value - diffFee).toFixed(2);

        }
        this.props.bookingRequestStore.setBooking("actFee", actFee);
    };

    handleGigFeeStaffChange = e => {
        this.props.bookingRequestStore.setBooking("gigFee", e.target.value);
        this.props.bookingRequestStore.setBooking("actFee", e.target.value);
    };

    handleAgentCommissionAmountChange = e => {
        this.props.bookingRequestStore.setBooking("agentCommissionAmount", e.target.value);
    };

    handleCommissionTypeChange = e => {
        this.props.bookingRequestStore.setBooking("agentCommissionType", e.target.value);
        this.props.bookingRequestStore.setBooking("agentCommissionFee", 0);
        this.props.bookingRequestStore.setBooking("actFee", 0);
        this.props.bookingRequestStore.setBooking("agentCommissionAmount", 0);
    };

    handleAgentCommissionChange = e => {
        const { gigFee, agentCommissionType } = this.props.bookingRequestStore.booking;
        let value = e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.')
        value = !value ? '' : value
        let result = 0.0;
        if (agentCommissionType === 0) {
            if (parseInt(value, 10) > parseInt(gigFee, 10)) return false;
            result = (gigFee || 0) - e.target.value;
            this.props.bookingRequestStore.setBooking("agentCommissionAmount", value);
        } else if (agentCommissionType === 1 && gigFee !== 0) {
            if (parseInt(value, 10) > 100 || parseInt(value, 10) < 0) return false;
            let diffFee = (gigFee / 100) * value;
            this.props.bookingRequestStore.setBooking("agentCommissionAmount", parseFloat(diffFee).toFixed(2));
            result = parseFloat(gigFee - diffFee).toFixed(2);
        }
        this.props.bookingRequestStore.setBooking("agentCommissionFee", value);
        this.props.bookingRequestStore.setBooking("actFee", result);
    };

    handleImmediateInvoiceChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("immediateInvoice", checked);
        this.setState({
            ...this.state
        });
    };
    handleGigFeeEntertainerChange = e => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.setBooking("gigFee", e.target.value);
        if (currentProfile?.type === ProfileType.Musician.ordinal) {
            this.props.bookingRequestStore.setBooking("actFee", e.target.value);
        }
    };

    checkBookingStatus = status => {
        return (
            status === BookingStatusType.New.ordinal ||
            status === BookingStatusType.Declined.ordinal ||
            status === BookingStatusType.Hold.ordinal ||
            status === BookingStatusType.Cancelled.ordinal ||
            status === BookingStatusType.Resent.ordinal
        );
    };

    handleBalanceDueDateChange = e => {
        this.props.bookingRequestStore.setBooking("balanceDueDate", moment(e).startOf("day"));
    };

    handlePopoverOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: false });
    };

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const { classes } = this.props;
        let { booking, error } = this.props.bookingRequestStore;
        let {
            isApproved,
            extraGigFeeApprovalById,
            isApprovalSent,
            gigFee,
            agentCommissionFee,
            actFee,
            agentCommissionType,
            agentCommissionAmount,
            status,
            immediateInvoice,
            isAdmin,
            createdById,
            dateStart,
            extraGigFee,
            balanceDueDate,
            createInvoice,
            createdBy
        } = booking;

        let { readOnly } = this.props;
        const { currentProfile } = this.props.profileStore;
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        let currencySymbol = currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$";

        const { token } = this.props.commonStore;
        let hasSubscribe = false;
        if (token && currentProfile) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }
        //for checking if profile is in lineup booking
        let onLineup = false;
        if (booking.lineups && booking?.lineups?.length && booking.lineups[0] !== undefined) {
            if (booking?.lineups?.find(x => x.profileId == currentProfile?.id)) {
                onLineup = true
            }
        }

        let lineupFee = 0;
        if (onLineup) {
            booking?.lineups?.forEach(x => {
                if (x.profileId == currentProfile?.id) {
                    lineupFee = Number(x.fee).toFixed(2)
                }
            })
        }

        let isPlayerLineupVenueBooking = false;
        if (onLineup && booking?.type == BookingType.Normal.ordinal && booking?.createdBy?.type !== ProfileType.Musician.ordinal) {
            isPlayerLineupVenueBooking = true;
        }

        let isActOwer = false
        if(booking.bookingRequests && booking.bookingRequests[0] !== undefined) {
            if(booking.bookingRequests[booking.bookingRequests.length - 1]?.act?.actAdminId == currentProfile?.id) isActOwer = true
        } else {
            if (booking?.act?.actAdminId == currentProfile?.id) isActOwer = true
        }

        let NumberFormatCustom = props => {
            const { inputRef, onChange, ...other } = props;

            return (
                <NumberFormat
                    {...other}
                    ref={inputRef}
                    onValueChange={values => {
                        onChange({
                            target: {
                                value: values.value
                            }
                        });
                    }}
                    thousandSeparator
                    prefix={currencySymbol}
                />
            );
        };

        let modGigFee = parseInt(agentCommissionFee, 10) > parseInt(gigFee, 10);

        let confirmedEditable =
            status !== BookingStatusType.Cancelled.ordinal &&
            status !== BookingStatusType.Completed.ordinal &&
            moment(dateStart).endOf("day") >= moment() &&
            (!createdById || createdById === currentProfile?.id || isStaff);

        return currentProfile && (currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Musician.ordinal) ? (
            <Grid container spacing={8}>
                {currentProfile?.type === ProfileType.Musician.ordinal ? (
                    booking.type === BookingType.Adhoc.ordinal ? (
                        <React.Fragment>
                            <Grid item xs={12} lg={6} className="gridBookingForm">
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="gigFee">{booking.isAdmin ? "Gig Fee Paid to Act" : "Gig Fee"}</InputLabel>
                                    <Input
                                        id="gigFee"
                                        type="number"
                                        value={actFee}
                                        onChange={this.handleActFeeChange}
                                        startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                        readOnly={readOnly}
                                    />
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} lg={6} className="gridBookingForm">
                            <FormControl fullWidth>
                                <MuiPickersUtilsProvider utils={MomentUtils} fullWidth>
                                    <DatePicker
                                        className="booking-date-picker"
                                        keyboard
                                        value={moment(balanceDueDate)}
                                        fullWidth
                                        margin="dense"
                                        onChange={this.handleBalanceDueDateChange}
                                        label="Due Date"
                                        minDate={booking.id ? undefined : moment().startOf("days")}
                                        maxDate={moment(dateStart).endOf("days")}
                                        format="DD-MM-YYYY"
                                        keyboardIcon={<EventIcon />}
                                        leftArrowIcon={<KeyboardArrowLeftIcon />}
                                        rightArrowIcon={<KeyboardArrowRightIcon />}
                                        style={{ marginTop: 0 }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid> */}
                            <Grid item xs={12} className="gridBookingForm">
                                <FormControlLabel
                                    control={
                                        immediateInvoice ? (
                                            <Switch
                                                color="primary"
                                                checked={true}
                                                onChange={this.handleImmediateInvoiceChange}
                                                disabled={!confirmedEditable}
                                            />
                                        ) : (
                                            <Switch
                                                color="primary"
                                                checked={false}
                                                onChange={this.handleImmediateInvoiceChange}
                                                disabled={!confirmedEditable}
                                            />
                                        )
                                    }
                                    label="Immediate Invoice"
                                />
                            </Grid>
                        </React.Fragment>) :
                        (
                            <React.Fragment>
                                <Grid item xs={12} lg={6} className="gridBookingForm">
                                    <FormControl fullWidth>
                                                                        {/* on act owner private booking*/}                                                                      {/* on player lineup venue booking*/}                                                          {/* on player lineup and act owner venue booking*/}                                                                                                                                           {/* on booking creator private booking*/}                                                          {/* on player lineup and act owner venue booking*/}
                                        <InputLabel htmlFor="gigFee">{(booking?.act?.actAdminId == currentProfile?.id && booking.type == BookingType.Adhoc.ordinal) ? "Gig Fee" : isPlayerLineupVenueBooking && booking?.act?.actAdminId !== currentProfile?.id? "Your Fee" : ((onLineup || isActOwer) && booking?.type == BookingType.Normal.ordinal && booking?.createdBy?.type !== ProfileType.Musician.ordinal) ? "Gig Fee Paid to Act" : "Gig Fee to Client"} </InputLabel>
                                        <Input
                                            id="gigFee"
                                            type="number"
                                            value={isActOwer && booking?.createdById !== currentProfile?.id ? actFee : isPlayerLineupVenueBooking ? lineupFee :  gigFee}
                                            onChange={this.handleGigFeeEntertainerChange}
                                            startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                            readOnly={readOnly}
                                        />
                                    </FormControl>
                                </Grid>
                                {(!booking.id || currentProfile?.id === booking.createdById) &&
                                    <Grid item xs={12} lg={6} className="gridBookingForm">
                                        <FormControl fullWidth>
                                            <MuiPickersUtilsProvider utils={MomentUtils} fullWidth>
                                                <DatePicker
                                                    className="booking-date-picker"
                                                    keyboard
                                                    value={moment(balanceDueDate)}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={this.handleBalanceDueDateChange}
                                                    label="Due Date"
                                                    minDate={booking.id ? undefined : moment().startOf("days")}
                                                    maxDate={moment(dateStart).endOf("days")}
                                                    format="DD-MM-YYYY"
                                                    keyboardIcon={<EventIcon />}
                                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                                    style={{ marginTop: 0 }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Grid>}
                                <br />
                                <Grid container spacing={2} className="gridBookingForm">
                                    <Grid item xs={12} lg={6} className="gridBookingForm">
                                        <FormControlLabel
                                            onMouseOver={this.handlePopoverOpen}
                                            control={
                                                <Switch
                                                    color="primary"
                                                    onChange={(e, checked) => this.props.bookingRequestStore.setBooking("createInvoice", checked)}
                                                    checked={createInvoice && hasSubscribe}
                                                    disabled={!confirmedEditable || !hasSubscribe}
                                                />
                                            }
                                            label="Create Invoices"
                                        />
                                    </Grid>
                                    <Popover
                                        id="mouse-over-popover"
                                        open={open && !hasSubscribe}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left"
                                        }}
                                        onClose={this.handlePopoverClose}
                                        disableRestoreFocus
                                    >
                                        <div onMouseLeave={this.handlePopoverClose}>
                                            <Typography className={classes.margin}>Upgrade to enable automated invoicing</Typography>
                                            <Grid container xs={12} justify="center" className={classes.margin}>
                                                <Grid item>
                                                    <Button align="center" variant="contained" color="primary" onClick={this.handleGoToPaymentpage}>
                                                        Upgrade
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Popover>
                                    <Grid item xs={12} lg={6} className="gridBookingForm">
                                        <FormControlLabel
                                            control={
                                                immediateInvoice ? (
                                                    <Switch
                                                        color="primary"
                                                        checked={true}
                                                        onChange={this.handleImmediateInvoiceChange}
                                                        disabled={!confirmedEditable}
                                                    />
                                                ) : (
                                                    <Switch
                                                        color="primary"
                                                        checked={false}
                                                        onChange={this.handleImmediateInvoiceChange}
                                                        disabled={!confirmedEditable}
                                                    />
                                                )
                                            }
                                            label="Immediate Invoice"
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )
                ) :
                    // currentProfile?.type === ProfileType.Musician.ordinal && !isAdmin ? (
                    //     <React.Fragment>
                    //         <Grid item xs={12} lg={6} className="gridBookingForm">
                    //             <FormControl fullWidth>
                    //                 <InputLabel htmlFor="playerFee">Gig Fee Paid To Player</InputLabel>
                    //                 <Input
                    //                     id="playerFee"
                    //                     type="number"
                    //                     value={actFee}
                    //                     onChange={this.handleActFeeChange}
                    //                     startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                    //                     readOnly
                    //                 />
                    //             </FormControl>
                    //         </Grid>
                    //         <Grid item xs={12} lg={6} className="gridBookingForm">
                    //             <FormControl fullWidth error={error && error["actFee"]}>
                    //                 <InputLabel htmlFor="actFee">Extra Set Fee</InputLabel>
                    //                 <Input
                    //                     id="actFeeAmount"
                    //                     type="number"
                    //                     value={extraGigFee}
                    //                     onChange={this.handleExtraGigFeeChange}
                    //                     startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                    //                     disabled
                    //                 />
                    //                 {error && error["extraGigFee"] ? <FormHelperText>{error["extraGigFee"]}</FormHelperText> : ""}
                    //             </FormControl>
                    //         </Grid>
                    //         <Grid item xs={12} className="gridBookingForm">
                    //             <FormControlLabel
                    //                 control={
                    //                     immediateInvoice ? (
                    //                         <Switch
                    //                             color="primary"
                    //                             checked={true}
                    //                             onChange={this.handleImmediateInvoiceChange}
                    //                             disabled={!confirmedEditable}
                    //                         />
                    //                     ) : (
                    //                         <Switch
                    //                             color="primary"
                    //                             checked={false}
                    //                             onChange={this.handleImmediateInvoiceChange}
                    //                             disabled={!confirmedEditable}
                    //                         />
                    //                     )
                    //                 }
                    //                 label="Immediate Invoice"
                    //             />
                    //         </Grid>
                    //     </React.Fragment>
                    // ) :
                    (
                        <Fragment>
                            {/* show only when booking venue shown on act owner booking who not created the booking */}
                            {booking?.act?.actAdminId == currentProfile?.id && booking?.type == BookingType.Normal.ordinal && booking?.createdBy?.type == ProfileType.Agent.ordinal && currentProfile?.id !== booking?.createdBy?.id ?
                                <Fragment>
                                    <Grid item xs={12} lg={6} className="gridBookingForm">
                                        <FormControl fullWidth error={error && error["actFee"]}>
                                            <InputLabel htmlFor="actFee">Gig Fee Paid To Act</InputLabel>
                                            <Input
                                                id="actFeeAmount"
                                                type="number"
                                                value={actFee}
                                                onChange={this.handleActFeeChange}
                                                startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                                readOnly
                                            />
                                            {error && error["actFee"] ? <FormHelperText>{error["actFee"]}</FormHelperText> : ""}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} className="gridBookingForm">
                                        <FormControlLabel
                                            control={
                                                immediateInvoice ? (
                                                    <Switch
                                                        color="primary"
                                                        checked={true}
                                                        onChange={this.handleImmediateInvoiceChange}
                                                        disabled={!confirmedEditable}
                                                    />
                                                ) : (
                                                    <Switch
                                                        color="primary"
                                                        checked={false}
                                                        onChange={this.handleImmediateInvoiceChange}
                                                        disabled={!confirmedEditable}
                                                    />
                                                )
                                            }
                                            label="Immediate Invoice"
                                        />
                                    </Grid>
                                </Fragment> :
                                <Fragment>
                                    {
                                        !(booking.createdById && booking.act?.actAdminId === currentProfile?.id && booking.createdById !== currentProfile?.id) && (
                                            <>
                                                <Grid item xs={12} lg={6} className="gridBookingForm">
                                                    <FormControl fullWidth error={error && error["gigFee"]}>
                                                        <InputLabel htmlFor="venueFee">Gig Fee to Venue</InputLabel>
                                                        <Input
                                                            type="string"
                                                            id="venueFee"
                                                            value={gigFee}
                                                            onChange={this.handleGigFeeChange.bind(this)}
                                                            startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                                            readOnly={readOnly}
                                                        />
                                                        {error && error["gigFee"] ? <FormHelperText>{error["gigFee"]}</FormHelperText> : ""}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={6} className="gridBookingForm">
                                                    <FormControl fullWidth error={error && error["agentCommissionFee"]}>
                                                        <InputLabel htmlFor="agentFee">Agent Commission</InputLabel>
                                                        <Input
                                                            id="agentFee"
                                                            error={
                                                                (agentCommissionType === 0 && modGigFee) ||
                                                                (agentCommissionType === 1 && (agentCommissionFee < 0 || agentCommissionFee > 100))
                                                            }
                                                            value={agentCommissionFee}
                                                            onChange={this.handleAgentCommissionChange}
                                                            type="string"
                                                            readOnly={readOnly}
                                                            inputprops={{
                                                                inputComponent: NumberFormatCustom
                                                            }}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <TextField
                                                                        select
                                                                        value={agentCommissionType}
                                                                        onChange={this.handleCommissionTypeChange}
                                                                        disabled={readOnly}
                                                                        SelectProps={{
                                                                            className: "select-adornment",
                                                                            inputProps: { className: "select-adornment-input" }
                                                                        }}
                                                                    >
                                                                        <MenuItem key={1} value={0}>
                                                                            {currencySymbol}
                                                                        </MenuItem>
                                                                        <MenuItem key={2} value={1}>
                                                                            %
                                                                        </MenuItem>
                                                                    </TextField>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                        {error && error["agentCommissionFee"] ? <FormHelperText>{error["agentCommissionFee"]}</FormHelperText> : ""}
                                                    </FormControl>
                                                    <FormHelperText id="name-error-text">Percentage 0 to 100% and commission is not more than Gig Fee</FormHelperText>
                                                </Grid>
                                            </>
                                        )
                                    }
                                    <Grid item xs={12} lg={6} className="gridBookingForm">
                                        <FormControl fullWidth error={error && error["actFee"]}>
                                            <InputLabel htmlFor="actFee">Gig Fee Paid To Act</InputLabel>
                                            <Input
                                                id="actFeeAmount"
                                                type="number"
                                                value={actFee}
                                                onChange={this.handleActFeeChange}
                                                startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                                readOnly
                                            />
                                            {error && error["actFee"] ? <FormHelperText>{error["actFee"]}</FormHelperText> : ""}
                                        </FormControl>
                                    </Grid>

                                    {/* {!currentProfile?.isConsultant && 
                                    <Grid item xs={12} lg={3} className="gridBookingForm">
                                        <FormControl fullWidth error={error && error["actFee"]}>
                                            <InputLabel htmlFor="extraGigFee">Extra Set Fee</InputLabel>
                                            <Input
                                                id="extraGigFee"
                                                type="number"
                                                value={extraGigFee}
                                                disabled
                                                startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                                endAdornment={
                                                    extraGigFee > 0 && (
                                                        <InputAdornment position="end">
                                                            <Button
                                                                id="btn-respond-extra-fee"
                                                                fullWidth
                                                                className={
                                                                    extraGigFeeApprovalById
                                                                        ? "extra-gig-fee-inline-button-disabled"
                                                                        : "extra-gig-fee-inline-button"
                                                                }
                                                                onClick={this.openApprovalDialog}
                                                                disabled={extraGigFeeApprovalById}
                                                            >
                                                                {extraGigFeeApprovalById ? (isApproved ? "Approved" : "Declined") : "Respond"}
                                                            </Button>
                                                        </InputAdornment>
                                                    )
                                                }
                                            />
                                            {error && error["extraGigFee"] ? (
                                                <FormHelperText>{error["extraGigFee"]}</FormHelperText>
                                            ) : (
                                                <FormHelperText>Extra set fee will be included in the invoice when you have approved it</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                } */}
                                    {
                                        !(booking.act?.actAdminId === currentProfile?.id && booking.createdById !== currentProfile?.id) && (
                                            <Grid item xs={12} lg={6} className="gridBookingForm">
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="adornment-amount">Commission paid to Agent</InputLabel>
                                                    <Input
                                                        id="agentFeeAmount"
                                                        type="number"
                                                        value={agentCommissionAmount}
                                                        startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                                        readOnly
                                                    />
                                                </FormControl>
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs={12} className="gridBookingForm">
                                        <FormControlLabel
                                            control={
                                                immediateInvoice ? (
                                                    <Switch
                                                        color="primary"
                                                        checked={true}
                                                        onChange={this.handleImmediateInvoiceChange}
                                                        disabled={!confirmedEditable}
                                                    />
                                                ) : (
                                                    <Switch
                                                        color="primary"
                                                        checked={false}
                                                        onChange={this.handleImmediateInvoiceChange}
                                                        disabled={!confirmedEditable}
                                                    />
                                                )
                                            }
                                            label="Immediate Invoice"
                                        />
                                    </Grid>

                                    {/* <Dialog
                                    open={this.state.approvalDialogOpen}
                                    onClose={this.closeApprovalDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">Approve Extra Set Fee?</DialogTitle>
                                    <DialogContent>
                                        <div>
                                            <Grid container spacing={16}>
                                                <Grid item xs={12}>
                                                    <DialogContentText id="alert-dialog-description">
                                                        {"Extra set fee will be included in the invoice when you have approved it. Are you sure to approve extra " +
                                                            currencySymbol +
                                                            extraGigFee +
                                                            " gig fee?"}
                                                    </DialogContentText>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="approval_by_name"
                                                        label="Approval By"
                                                        margin="dense"
                                                        fullWidth
                                                        value={
                                                            this.props.profileStore.currentProfile?.firstName +
                                                            " " +
                                                            this.props.profileStore.currentProfile?.lastName
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="approval_date"
                                                        label="Approval Date"
                                                        margin="dense"
                                                        fullWidth
                                                        value={moment(new Date()).format("D MMM YYYY")}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={this.state.checked} onChange={this.handleCheckedChange} value="checked" />
                                                            }
                                                            label="I have read the statement above and willing to be responsible with the result"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.closeApprovalDialog} color="secondary">
                                            Close
                                        </Button>
                                        <Button onClick={this.handleRequestDeclinedClick} color="primary" disabled={!this.state.checked}>
                                            Decline
                                        </Button>
                                        <Button onClick={this.handleRequestApprovedClick} color="primary" autoFocus disabled={!this.state.checked}>
                                            {"Approve " + currencySymbol + extraGigFee + " Extra Set Fee"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                             */}

                                </Fragment>

                            }
                        </Fragment>
                    )}
            </Grid>
        ) : currentProfile && currentProfile?.type === ProfileType.Staff.ordinal ? (
            <Grid container spacing={8}>
               
                <Fragment>
                    {
                        createdById && createdBy && createdBy?.type === ProfileType.Agent.ordinal?
                        <Fragment>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <FormControl fullWidth error={error && error["gigFee"]}>
                                <InputLabel htmlFor="venueFee">Gig Fee</InputLabel>
                                <Input
                                    type="number"
                                    id="venueFee"
                                    value={gigFee}
                                    onChange={this.handleGigFeeStaffChange.bind(this)}
                                    startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                    readOnly={readOnly}
                                />
                                {error && error["gigFee"] ? <FormHelperText>{error["gigFee"]}</FormHelperText> : ""}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <FormControl fullWidth>
                                <InputLabel htmlFor="adornment-amount">Commission paid to Agent</InputLabel>
                                <Input
                                    id="agentFeeAmount"
                                    type="number"
                                    value={agentCommissionAmount}
                                    startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                    readOnly
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <FormControl fullWidth error={error && error["actFee"]}>
                                <InputLabel htmlFor="actFee">Gig Fee Paid To Act</InputLabel>
                                <Input
                                    id="actFeeAmount"
                                    type="number"
                                    value={actFee}
                                    onChange={this.handleActFeeChange}
                                    startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                    readOnly
                                />
                                {error && error["actFee"] ? <FormHelperText>{error["actFee"]}</FormHelperText> : ""}
                            </FormControl>
                        </Grid>
                        </Fragment> : 
                           <Fragment>
                           <Grid item xs={12} lg={6} className="gridBookingForm">
                               <FormControl fullWidth error={error && error["gigFee"]}>
                                   <InputLabel htmlFor="venueFee">Gig Fee</InputLabel>
                                   <Input
                                       type="number"
                                       id="venueFee"
                                       value={gigFee}
                                       onChange={this.handleGigFeeStaffChange.bind(this)}
                                       startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                       readOnly={readOnly}
                                   />
                                   {error && error["gigFee"] ? <FormHelperText>{error["gigFee"]}</FormHelperText> : ""}
                               </FormControl>
                           </Grid>
                           </Fragment>
                    }
                 
                </Fragment>
               
                <Grid item xs={12} lg={6} className="gridBookingForm">
                    {/* <FormControl fullWidth error={error && error["actFee"]}>
                        <InputLabel htmlFor="extraGigFee">Extra Set Fee</InputLabel>
                        <Input
                            id="extraGigFee"
                            type="number"
                            value={extraGigFee}
                            disabled
                            startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                            endAdornment={
                                extraGigFee > 0 && (
                                    <InputAdornment position="end">
                                        <Button
                                            id="btn-respond-2"
                                            fullWidth
                                            className={
                                                extraGigFeeApprovalById ? "extra-gig-fee-inline-button-disabled" : "extra-gig-fee-inline-button"
                                            }
                                            onClick={this.openApprovalDialog}
                                            disabled={extraGigFeeApprovalById}
                                        >
                                            {extraGigFeeApprovalById ? (isApproved ? "Approved" : "Declined") : "Respond"}
                                        </Button>
                                    </InputAdornment>
                                )
                            }
                        />
                        {error && error["extraGigFee"] ? (
                            <FormHelperText>{error["extraGigFee"]}</FormHelperText>
                        ) : (
                            <FormHelperText>Extra set fee will be included in the invoice when you have approved it</FormHelperText>
                        )}
                    </FormControl>
                 */}
                </Grid>
                <Grid item xs={12} className="gridBookingForm">
                    <FormControlLabel
                        control={
                            immediateInvoice ? (
                                <Switch color="primary" checked={true} onChange={this.handleImmediateInvoiceChange} disabled={readOnly || !confirmedEditable} />
                            ) : (
                                <Switch color="primary" checked={false} onChange={this.handleImmediateInvoiceChange} disabled={readOnly || !confirmedEditable} />
                            )
                        }
                        label="Immediate Invoice"
                    />
                </Grid>

                {/* <Dialog
                    open={this.state.approvalDialogOpen}
                    onClose={this.closeApprovalDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Approve Extra Set Fee?</DialogTitle>
                    <DialogContent>
                        <div>
                            <Grid container spacing={16}>
                                <Grid item xs={12}>
                                    <DialogContentText id="alert-dialog-description">
                                        {"Extra set fee will be included in the invoice when you have approved it. Are you sure to approve extra " +
                                            currencySymbol +
                                            extraGigFee +
                                            " gig fee?"}
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="approval_by_name"
                                        label="Approval By"
                                        margin="dense"
                                        fullWidth
                                        value={
                                            this.props.profileStore.currentProfile?.firstName + " " + this.props.profileStore.currentProfile?.lastName
                                        }
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="approval_date"
                                        label="Approval Date"
                                        margin="dense"
                                        fullWidth
                                        value={moment(new Date()).format("D MMM YYYY")}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.checked} onChange={this.handleCheckedChange} value="checked" />}
                                            label="I have read the statement above and willing to be responsible with the result"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeApprovalDialog} color="secondary">
                            Close
                        </Button>
                        <Button onClick={this.handleRequestDeclinedClick} color="primary" disabled={!this.state.checked}>
                            Decline
                        </Button>
                        <Button onClick={this.handleRequestApprovedClick} color="primary" autoFocus disabled={!this.state.checked}>
                            {"Approve " + currencySymbol + extraGigFee + " Extra Set Fee"}
                        </Button>
                    </DialogActions>
                </Dialog>
             */}
            </Grid>
        ) : (
            ""
        );
    }
}
BookingFeeDetails.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(BookingFeeDetails);
