import React, { Component,Fragment } from "react";
import { inject, observer } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";
import { DialogContent, DialogActions, Button, TextField, Grid,Hidden,MenuItem } from "@material-ui/core";
import ResponsiveTable from "../../table/ResponsiveTable";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import SelectField from "../../fields/SelectField";
import moment from "moment"
import { Link } from "react-router-dom";
import classNames from "classnames";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ConfirmationModal from "../../modal/ConfirmationModal";

const ENABLE_QUICKBOOKS = process.env.REACT_APP_ENABLE_QUICKBOOKS === "true";

@inject("profileStore", "invoiceStore", "userStore", "myobStore","templateStore")
@observer
class MYOBMappedSupplierModal extends Component {
    state = {
        myobActive: false,
        myobAccount: "",
        editedId: "",
        updateProcess: false,
        myobUID: "",
        mappedId: "",
        newpayeeId:"",
        openRemoveContactModalState: false,
        removedId : null,
        editedMYOBId: ""
    };
    supplierMappingColumn = [
        { key: "suppliers", label: "Hot Giggity Suppliers" },
        { key: "myobSuppliers", label: "MYOB Suppliers" },
        { key: "status", label: "Status" },
        { key: "action", label: "" }
    ];
    
    
    supplierMappingRows = [];
    componentDidMount = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(currentProfile?.organisationOwner && currentProfile?.organisationOwner.id){
            currentProfile = currentProfile?.organisationOwner;
        }
        Promise.all([
            this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
            this.props.myobStore.getMYOBSuppliers(currentProfile.id)
        ]).then(() => { 
  
        });
    };
    handleMappedId = (payeeId,myobUID) => {
        this.props.myobStore.setEditedSupplierMYOB(payeeId,myobUID)
        this.setState({
            editedId: payeeId
        });
    }
    setEditedSupplier = value =>  {
        const { editedMYOBId } = this.state
        if(this.state.editedId && !editedMYOBId){
            this.props.myobStore.cancelEditedSupplierMYOB(this.state.editedId)
        }
        if(editedMYOBId){
            this.props.myobStore.cancelUpdatedMYOBSupplier(this.state.editedId)
        }
        this.setState({
            editedId: value,
            updateProcess: false,
            editedMYOBId: ""
        });
    }

    setUpdatedSupplier = (data) =>  {
        const { editedMYOBId } = this.state
        if(this.state.editedId && !editedMYOBId){
            this.props.myobStore.cancelEditedSupplierMYOB(this.state.editedId)
        }
        if(editedMYOBId){
            this.props.myobStore.cancelUpdatedMYOBSupplier(this.state.editedId)
        }
        this.props.myobStore.setUpdatedMYOBSupplier(data.payeeId)
        // this.props.myobStore.cancelEditedMYOB(existingUID)
        this.setState({
            editedId: data.payeeId,
            editedMYOBId: data.myobUID,
            updateProcess: true
        });
    }

    handleOpenConfirmCreateNewSupplier = id => {
        this.setState({
            confirmNewSupplierModalState: true,
            newpayeeId: id
        });
    };

    openRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: true,
            removedId : id
        });
    };
    closeRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: true,
            removedId : null
        });
    };
    
    handleClose = () =>{
        this.props.closeHandler();
    }

    handleCloseConfirmNewSupplierInvitationModal = () => {
        this.setState({
            ...this.state,
            confirmNewSupplierModalState: false,
            newpayeeId: undefined
        });
    };

    saveEditedSupplier = (mappedId,payeeId,myobUID) =>{
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(currentProfile?.organisationOwner && currentProfile?.organisationOwner.id){
            currentProfile = currentProfile?.organisationOwner;
        }
        this.props.templateStore.showLoadingScreen()
        if(this.state.updateProcess){
            this.props.myobStore.updateContactMYOB(mappedId, myobUID).then(result =>  {
                Promise.all([
                    this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
                    this.props.myobStore.getMYOBSuppliers(currentProfile.id)
                ]).then(() => { 
                    // this.props.myobStore.cancelEditedMYOB(payerId)
                    this.setState({
                        editedId: "",
                        editedMYOBId: ""
                    });
                });
              
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
               
            })
        }else{
            this.props.myobStore.mapExistingMYOBSupplier(currentProfile.id, payeeId, myobUID).then(result =>  {
                Promise.all([
                    this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
                    this.props.myobStore.getMYOBSuppliers(currentProfile.id)
                ]).then(() => { 
                    this.setState({
                        editedId: ""
                    });
                });
            
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
            
            })
        }
    }

    handleDeleteContact = () => {
        const { removedId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(currentProfile?.organisationOwner && currentProfile?.organisationOwner.id){
            currentProfile = currentProfile?.organisationOwner;
        }
        this.props.templateStore.showLoadingScreen()
        this.props.myobStore.deleteMYOBMapping(removedId).then(result =>  {
            Promise.all([
                this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
                this.props.myobStore.getMYOBSuppliers(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    openRemoveContactModalState: false,
                    removedId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
        
    }

    handleBatchSync = () => {
        const { invoices, type} = this.props
        let currentProfileId = this.props.profileStore.currentProfile?.id;
        if (type === 0) {
            this.props.templateStore.showLoadingScreen();
            this.props.myobStore.resetResultIntegration();
            this.props.myobStore.integrateIncomingInvoice(currentProfileId, [invoices[0].id]).then(result => {
                if(result.length && result[0].type === 1){
                    this.props.invoiceStore.loadByPayerIdAndFilter(currentProfileId).finally(() => {

                        this.handleClose()
                    })
                }else{
                    this.props.templateStore.openSnackbar("Please define related supplier before resync invoice");
                }
            }).finally(() => {
                this.props.templateStore.hideLoadingScreen();
            });
        }
        if (type === 1) {
            this.props.templateStore.showLoadingScreen();
            this.props.myobStore.resetResultIntegration();
            this.props.myobStore.integrateOutgoingInvoice(currentProfileId, [invoices[0].id]).then(result => {
                if(result.length && result[0].type === 1){
                    this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfileId).finally(() => {
                        this.handleClose()
                    })
                }else{
                    this.props.templateStore.openSnackbar("Please define related customer before resync invoice");
                }
            }).finally(() => {
                this.props.templateStore.hideLoadingScreen();
            });
        }
    }

    getColumns = () => {
        const { hasQuickBooksAccount } = this.props.userStore;
        const columns = [
            { key: "date", label: "Invoice Date", sortable: true, isDate: true },
            { key: "dueDate", label: "Due Date", sortable: true, isDate: true },
            { key: "no", label: "Invoice No." },
            { key: "status", label: "Status" },
            { key: "total", label: "Total", sortable: true },
            { key: "payeeName", label: "Contact Name", sortable: true },
            { key: "payeePhone", label: "Contact Phone" },
            ...(ENABLE_QUICKBOOKS && hasQuickBooksAccount ? [{ key: "quickBooks", label: "QuickBooks" }] : []),
            { key: "action", label: "" }
        ];
        return columns;
    };
    renderFooterRow = invoices => {
        // if (invoices) console.log("match", invoices.slice());
        const { incomingInvoices } = this.props.invoiceStore;
        let total = 0;
        if (incomingInvoices && invoices) {
            const match = incomingInvoices.filter(incoming => {
                return invoices.some(x => x.id === incoming.id);
            });

            if (match) {
                total = match.reduce((a, b) => {
                    return a + b.total;
                }, 0);
            }
        }

        return {
            total: "$" + parseFloat(total).toLocaleString()
        };
    };
    handleCreateNewContact = () => {
        const { newpayeeId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(currentProfile?.organisationOwner && currentProfile?.organisationOwner.id){
            currentProfile = currentProfile?.organisationOwner;
        }
        this.props.templateStore.showLoadingScreen()
        this.props.myobStore.createMYOBSuppliers(currentProfile.id, newpayeeId).then(result =>  {
            Promise.all([
                this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
                this.props.myobStore.getMYOBSuppliers(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    confirmNewSupplierModalState: false,
                    newpayeeId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
       
    }
    cancelEditedSupplier = (payeeId) =>{
        this.props.myobStore.cancelEditedSupplierMYOB(payeeId)
        this.setState({
            editedId: ""
        });
    }
    
    mapSuppliersToRowData = () => {
        const { listMYOBMappedSuppliers,myobSuppliers,myobSuppliersFull } = this.props.myobStore;
        
        const {editedId} = this.state
        this.supplierMappingRows = [];
        var selectedlistMYOBMappedSuppliers = listMYOBMappedSuppliers.filter(x=> x.payeeId === this.props.selectedPayeeId)
        selectedlistMYOBMappedSuppliers.forEach(data => {
            var row = {};

            row.suppliers = data.profileName ? data.profileName : data.firstName + " " + data.lastName

            if(data.myobUID && !data.isEdited && !data.isUpdated){
                var existingUID = myobSuppliersFull.filter(x=>x.value === data.myobUID)
                if(existingUID.length){
                    row.myobSuppliers = <TextField
                    // label="MYOB Supplier Name"
                    inputProps={{
                        readOnly: true
                    }}
                    value={existingUID[0].label}
                    fullWidth
                />
                }
               
            }else if(data.payeeId != editedId){
                row.myobSuppliers = <TextField
                // label="MYOB Supplier Name"
                inputProps={{
                    readOnly: true
                }}
                value="Map MYOB Account"
                fullWidth
            />
            }else{
                row.myobSuppliers =  <SelectField
                id="myobSuppliers"
                value={data.myobUID}
                suggestions={myobSuppliers}
                placeholder="Map MYOB Account"
                disabled={data.payeeId != editedId}
                onChange={(value) => this.handleMappedId(data.payeeId,value)}
            />
            }
            
           
            
           
            row.status = data.myobUID ? "Mapped" : "Not Mapped";

            if(data.myobUID && !data.isEdited && !data.isUpdated){
                row.action = (
                    <Fragment>
                         <Button size="small" color="primary" onClick={() => this.setUpdatedSupplier(data)}>
                            Update
                        </Button>
                        <Button size="small" color="primary" onClick={() => this.openRemoveContactModal(data.mappedId)}>
                            Remove
                        </Button>
                    </Fragment>
                );
    
            }else{
                if(data.isEdited || data.isUpdated){
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.saveEditedSupplier(data.mappedId,data.payeeId,data.myobUID)}>
                                Save
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.cancelEditedSupplier(data.payeeId)}>
                                Cancel
                            </Button>
                        </Fragment>
                        
                    );
                   
                }else{
                    row.action = (
                        <Fragment>
                             <Button size="small" color="primary" onClick={() => this.setEditedSupplier(data.payeeId)}>
                                Assign
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.handleOpenConfirmCreateNewSupplier(data.payeeId)}>
                                Create New
                            </Button>
                        </Fragment>
                        
                    );
                }
                
            }
           
            // row.style = {
            //     backgroundColor: "#FFC0CB"
            // };

            this.supplierMappingRows.push(row);
        });
        var blankRow = {};
        this.supplierMappingRows.push(blankRow);
        return Promise.resolve();
    };
    render() {
        const { isLoading} = this.state;
        const { invoices, open } = this.props;
        
        this.mapSuppliersToRowData()
        return (
            <ModalContainer
                open={open}
                title="Map Invoice with MYOB Supplier"
                maxWidth="md"
                fullScreen={false}
                onClose={this.props.closeHandler}
                isLoading={isLoading}
            >
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <ResponsiveTable columns={this.getColumns()}
                              rows={invoices.map(invoice => {
                                var row = {};
                                row.id = invoice.id;
                                row.date = moment.utc(invoice.date).format("DD MMM YYYY");
                                row.dueDate = moment.utc(invoice.dueDate).format("DD MMM YYYY");
                                row.no = invoice.no;

                                row.status =
                                    invoice.status === "pending" ? (
                                        invoice.immediateInvoice ? (
                                            <Tooltip
                                                title={
                                                    "This invoice is waiting for gig date at " +
                                                    moment(invoice.date).format("DD MMM YYYY")
                                                }
                                                placement="bottom"
                                            >
                                                <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={
                                                    "This invoice is waiting for payer's billing cycle at " +
                                                    moment(invoice.date).format("DD MMM YYYY")
                                                }
                                                placement="bottom"
                                            >
                                                <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                            </Tooltip>
                                        )
                                    ) : invoice.status === "overdue" ? (
                                        <Tooltip
                                            title={
                                                "This invoice is " +
                                                moment(new Date()).diff(moment(invoice.dueDate), "days") +
                                                " day(s) past due date"
                                            }
                                            placement="bottom"
                                        >
                                            <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                        </Tooltip>
                                    ) : (
                                        <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                    );
                                const currencySymbol = invoice && invoice.payee && invoice.payee.currency === "GBP" ? "£" : "$";
                                row.total = currencySymbol + parseFloat(invoice.total).toLocaleString();
                                row.subTotal = currencySymbol + parseFloat(invoice.subTotal).toLocaleString();
                                row.tax = currencySymbol + parseFloat(invoice.tax).toLocaleString();
                                row.payerName = invoice.payerName;
                                row.payerPhone = invoice.payerPhone;
                                row.syncMYOB = invoice.isMYOBSyncronise ? <CheckCircle style={{ texAlign:"center",color: "green"}} />  : null;
                                row.quickBooks = (
                                    <Button
                                        size="small"
                                        color="primary"
                                        className="btn-sync-quickbooks"
                                        component={Link}
                                        to={`/invoice/${invoice.id}/quickbooks`}
                                    >
                                        Sync
                                    </Button>
                                );
                                row.action = (
                                    <div>
                                        <Button
                                            size="small"
                                            color="primary"
                                            className="btn-invoice-detail"
                                            component={Link}
                                            to={`/invoice/${invoice.id}/detail`}
                                        >
                                            Detail
                                        </Button>
                                    </div>
                                );
                                

                                return row;
                            })}
                             footerRow={this.renderFooterRow(invoices)} />
                        </Grid>
                        <Grid item md={12} style={{ marginTop: 25,marginBotton: 300 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={12} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle"}}>
                                                
                                                <Grid container spacing={2}>
                                                    <Grid item md={12}>
                                                        <ResponsiveTable
                                                            columns={this.supplierMappingColumn}
                                                            rows={this.supplierMappingRows}
                                                            showToolbar={true}
                                                            title="Supplier Mapping"
                                                            pagination={this.supplierMappingRows.length > 5}
                                                            addSpace={true}
                                                        />

                                                    </Grid>  
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>
                <ConfirmationModal
                    open={this.state.confirmNewSupplierModalState}
                    message="Are you sure you want to create a new MYOB contact for this contact?"
                    closeHandler={this.handleCloseConfirmNewSupplierInvitationModal}
                    confirmationHandler={this.handleCreateNewContact}
                    declineHandler={this.handleCloseConfirmNewSupplierInvitationModal}
                    confirmationLabel="Confirm"
                />

                <ConfirmationModal
                    open={this.state.openRemoveContactModalState}
                    message="Are you sure you want to remove the existing connection to MYOB contact?"
                    closeHandler={this.closeRemoveContactModal}
                    confirmationHandler={this.handleDeleteContact}
                    declineHandler={this.closeRemoveContactModal}
                    confirmationLabel="Confirm"
                />
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button color="primary" onClick={this.handleBatchSync}>Sync Invoice</Button>
                    <Button onClick={this.handleClose}>Close</Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default MYOBMappedSupplierModal;
