import React, { Component } from "react";

import WidgetContainer from "./WidgetContainer.js";

class WidgetFormContainer extends Component {
    render() {
        return (
            <WidgetContainer
                className="widget-container"
                title={this.props.title}
                showBadge={this.props.showBadge}
                badgeValue={this.props.badgeValue}
            >
                {this.props.children}
            </WidgetContainer>
        );
    }
}

export default WidgetFormContainer;
