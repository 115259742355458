import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import ModalContainer from "./ModalContainer";

class InfoModal extends Component {
    render() {
        return (
            <ModalContainer
                open={this.props.open}
                title={this.props.title ? this.props.title : "Subscription Information"}
                onClose={this.props.closeHandler}
                fullScreen={false}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "xs"}
            >
                <DialogContent>
                    <DialogContentText>{this.props.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.declineHandler ? this.props.declineHandler : this.props.closeHandler}>
                        {this.props.declineLabel ? this.props.declineLabel : "Close"}
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default InfoModal;
