import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import SelectField from "../../components/fields/SelectField";
import ConfirmationModalAccountPackage from "../../components/modal/ConfirmationModalAccountPackage";
import Switch from "@material-ui/core/Switch";
import  Loading from "../../components/loadingSpinner/Loading";
import { withRouter } from "react-router-dom";

const headerMarkAsApproveMessage = "Mark As Approved Confirmation"
const headerTurnOffMarkAsApproveMessage = "Are you sure you want to disable ‘Mark as Approved’ on invoice sync?"


const markAsApproveMessage = "Enabling this feature will mark invoices as 'Approved / Awaiting Payment” in XERO.  Do you want to continue?"
const turnOffMarkAsApproveMessage = "Turning off ‘Mark as Approved’ on invoice sync will mean that future synced invoices will show as ‘Awaiting Approval’.   Are you sure you want to continue"
@inject("authStore", "templateStore", "userStore","profileStore","xeroStore")
@withRouter
@observer
class SyncXero extends Component {
    state = {
        openSyncPDFModal: false,
        isLoading:false,
        openXeroSettingApprovedToggle: false,
        openMarkAsSent: false
    };
    componentDidMount = () => {
        var currentProfile  = this.props.profileStore.getCurrentProfile();
        this.props.profileStore.setIncludePDF(currentProfile?.xeroIncludePDF)
        this.props.profileStore.setXeroSettingApproved(currentProfile?.xeroSettingApproved)
        this.props.profileStore.setMarkInvoiceAsSent(currentProfile?.xeroMarkAsSent)

    };
    closeSyncPDFModal = () => {
        this.setState({
            openSyncPDFModal: false
        });
    };
    openSyncPDFModal = () => {
        this.setState({
            openSyncPDFModal: true
        });
    };
    

    closeMarkAsSent = () => {
        this.setState({
            openMarkAsSent: false
        });
    };
    openMarkAsSent = () => {
        this.setState({
            openMarkAsSent: true
        });
    };

    closeXeroSettingApprovedToggle = () => {
        this.setState({
            openXeroSettingApprovedToggle: false
        });
    };
    openXeroSettingApprovedToggle = () => {
        this.setState({
            openXeroSettingApprovedToggle: true
        });
    };
    
    handleSyncPDF = () => {
        this.setState({ isLoading: true });
        var currentProfile  = this.props.profileStore.getCurrentProfile();
        this.props.profileStore.toggleXeroSyncPDF(currentProfile.id).then(result =>  {
            this.props.profileStore.loadProfiles().finally(() => {
                this.setState({ isLoading: false });
                this.props.templateStore.openSnackbar(`Sync PDF invoice ${result.xeroIncludePDF ? "on" : "off"}`);
                this.setState({
                    openSyncPDFModal: false
                });
            })
           
        })
        
       
    }

    
    handleTogleInvoiceMarkAsSent= () => {
        this.setState({ isLoading: true });
        var currentProfile  = this.props.profileStore.getCurrentProfile();
        this.props.profileStore.toggleXeroInvoiceSent(currentProfile.id).then(result =>  {
            this.props.profileStore.loadProfiles().finally(() => {
                this.setState({ isLoading: false });
                this.props.profileStore.setMarkInvoiceAsSent(result.xeroMarkAsSent)
                this.props.templateStore.openSnackbar(`Sync invoice mark as sent ${result.xeroMarkAsSent ? "on" : "off"}`);
                this.setState({
                    openMarkAsSent: false
                });
            })
           
        })
        
       
    }

    handleToggleXeroSettingApproved= () => {
        this.setState({ isLoading: true });
        var currentProfile  = this.props.profileStore.getCurrentProfile();
        this.props.profileStore.toggleXeroSettingApproved(currentProfile.id).then(result =>  {
            this.props.profileStore.loadProfiles().finally(() => {
                this.setState({ isLoading: false });
                this.props.profileStore.setXeroSettingApproved(result.xeroSettingApproved)
                this.props.templateStore.openSnackbar(`Sync Invoice Approved ${result.xeroSettingApproved ? "on" : "off"}`);
                this.setState({
                    openXeroSettingApprovedToggle: false
                });
            })
           
        })
        
       
    }

    handleToggleXeroSettingApproved= () => {
        this.setState({ isLoading: true });
        var currentProfile  = this.props.profileStore.getCurrentProfile();
        this.props.profileStore.toggleXeroSettingApproved(currentProfile.id).then(result =>  {
            this.props.profileStore.loadProfiles().finally(() => {
                this.setState({ isLoading: false });
                this.props.profileStore.setXeroSettingApproved(result.xeroSettingApproved)
                this.props.templateStore.openSnackbar(`Sync Invoice Approved ${result.xeroSettingApproved ? "on" : "off"}`);
                this.setState({
                    openXeroSettingApprovedToggle: false
                });
            })
           
        })
        
       
    }
    
    redirectToXero = () => {
        this.props.history.push('/invoice')
    }
    toggleHideToolbar = () => {
        // const hide = this.props.embedGigStore.hideToolbar;
        // this.props.embedGigStore.setHideToolbar(!hide);
    };
    render() {
        const { xeroIncludePDF, xeroSettingApproved, markInvoiceAsSent } = this.props.profileStore
        return (
            <div>
                <Loading showed={this.state.isLoading}/>
                <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                To ensure accuracy and data integrity, synchronising your Hot Giggity invoices into Xero is a manual process.
                                            </Typography>
                                            <Typography gutterBottom>
                                                We recommend only doing this once per month on the 1st day of the month for example
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item md={12}>
                                                        <Typography gutterBottom>Step 5. Synchronise your Hot Giggity invoices with Xero. </Typography>
                                                        <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>Synchronising your Hot Giggity invoices to your accounting package is done via the invoices tab.</Typography>
                                                
                                                    </Grid>
                                                    <Grid item md={12} xs={12}>
                                                    <p style={{fontSize:14, fontWeight:900}}> <Switch checked={xeroIncludePDF} onChange={this.openSyncPDFModal} value="hideToolbar" />  Sync Invoice PDF</p>
                                                    </Grid>
                                                    <Grid item md={12} xs={12}>
                                                    <p style={{fontSize:14, fontWeight:900}}> <Switch checked={xeroSettingApproved} onChange={this.openXeroSettingApprovedToggle} value="hideToolbar2" />  Sync invoices as approved</p>
                                                    </Grid>
                                                    <Grid item md={12} xs={12}>
                                                    <p style={{fontSize:14, fontWeight:900}}> <Switch checked={markInvoiceAsSent} onChange={this.openMarkAsSent} value="hideToolbar2" />  Mark Outgoing Invoices as Sent</p>
                                                    </Grid>
                                                    
                                                    <Grid item md={3} xs={12}>
                                                        <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>Would you like to go there now?</Typography>  
                                                    </Grid>
                                                   
                                                    <Grid item md={9} xs={12} direction="column"
                                                        alignItems="left"
                                                        justify="left">
                                                        <Button variant="contained" color="primary" style={{textAlign: "left"}} onClick={this.redirectToXero}>
                                                            Syncronise Now  	&nbsp; <i class="fa fa-sm fa-plus"></i>
                                                        </Button>
                                                    </Grid>
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>
                <ConfirmationModalAccountPackage
                    open={this.state.openSyncPDFModal}
                    message={xeroIncludePDF ? "Turning off this feature means that the invoice PDF will not longer be sent to your XERO account.  Do you want to continue?" : "Enabling this feature will upload the PDF for each Hot Giggity invoice into XERO.  Do you want to continue?"}
                    closeHandler={this.closeSyncPDFModal}
                    confirmationHandler={this.handleSyncPDF}
                    declineHandler={this.closeSyncPDFModal}
                    confirmationLabel={xeroIncludePDF ? "Turn off PDF Sync" : "Sync Invoice PDF Confirmation"}
                    title={xeroIncludePDF ? "Are you sure you want to turn off PDF sync?" : "Confirmation"}
                />

                 <ConfirmationModalAccountPackage
                    open={this.state.openXeroSettingApprovedToggle}
                    title={xeroSettingApproved ? headerTurnOffMarkAsApproveMessage : headerMarkAsApproveMessage}
                    message={xeroSettingApproved ? turnOffMarkAsApproveMessage : markAsApproveMessage}
                    closeHandler={this.closeXeroSettingApprovedToggle}
                    confirmationHandler={this.handleToggleXeroSettingApproved}
                    declineHandler={this.closeXeroSettingApprovedToggle}
                    confirmationLabel={xeroSettingApproved ? "Confirm" : "Mark Invoice as Approved"}
                />

                <ConfirmationModalAccountPackage
                    open={this.state.openMarkAsSent}
                    title={markInvoiceAsSent ? `Are you sure you want to turn off 'Mark as Sent'?` : "Mark Outgoing Invoices as Sent"}
                    message={markInvoiceAsSent ? `Turning off this feature means that the invoice will not longer be marked as 'Sent' in your XERO account. Do you want to continue?` : `Enabling this feature will mark invoices to clients as 'Sent' in XERO. Do you want to continue?`}
                    closeHandler={this.closeMarkAsSent}
                    confirmationHandler={this.handleTogleInvoiceMarkAsSent}
                    declineHandler={this.closeMarkAsSent}
                    confirmationLabel={markInvoiceAsSent ? `Turn off 'Mark as Sent'` : "Mark Invoice as Sent"}
                />
            </div>
            
        );
    }
}

export default SyncXero;
