import React, { Component } from 'react'
import { inject, observer } from "mobx-react";
import ModalContainer from '../../modal/ModalContainer';
import { DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
// import SelectField from '../../fields/SelectField';
import { withRouter } from "react-router-dom";
import WidgetContainer from '../../widget/WidgetContainer';
import SelectField from '../../fields/SelectField';
import { ProfileType } from '../../../types/enum';

@inject("userFestivalStore", "templateStore", "agentVenueStore", "userStore", "profileStore")
@withRouter
@observer
class FestivalRegistrationModal extends Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            actId: undefined,
            venueId: undefined,
            shortedDescription: "",
            hideDescription: false
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.open && !prevProps.open){
            const { agent } = this.props;
            const { profiles } = this.props.profileStore;
            let musicianProfile = undefined;
            if(profiles){
                musicianProfile = profiles.filter(x => x.type === ProfileType.Musician.ordinal)[0];
            }
            
            if(agent && agent.festivalCode){
                this.setState({
                    ...this.state,
                    isLoading:true,
                    hideDescription: agent.festivalDescription.length > 1500
                })
                Promise.all([
                    this.props.agentVenueStore.getAgentVenue(agent.id),
                    musicianProfile ? this.props.profileStore.getUserActList(musicianProfile.id) : undefined
                ]).then(() => {
                    this.setState({
                        ...this.state,
                        isLoading:false
                    })
                });
            }
        }
    }

    handleRegister = () => {
        const { actId, venueId } = this.state;
        const { agent } = this.props;
        if(agent){
            this.props.templateStore.showLoadingScreen();
            this.props.userFestivalStore.registerActToAgentFestival(actId, agent.id, venueId).then( () => {
                this.props.templateStore.openSnackbar("Registration Success!");
                this.props.templateStore.hideLoadingScreen();
                this.props.closeHandler();
            }, () => {
                this.props.templateStore.openSnackbar("Registration Failed!");
                this.props.templateStore.hideLoadingScreen();
            });
        }
    }

    handleVenueChange = value => {
        this.setState({
            ...this.state,
            venueId: value
        })
    }

    mapVenueSuggestions = () => {
        const { agentVenues } = this.props.agentVenueStore;
        return agentVenues.map(data => {
            return {
                value: data.venueId,
                label: data.venue.fullName
            };
        });
    }

    handleActChange = value => {
        this.setState({
            ...this.state,
            actId: value
        })
    }

    mapActSuggestions = () => {
        const { currentUser } = this.props.userStore;
        if(currentUser){
            const { actList } = this.props.profileStore;
            return actList.filter(x => x.userId === currentUser.id).map(data => {
                return {
                    value: data.id,
                    label: data.actName
                };
            });
        }
        else{
            return []
        }
    }

    render(){
        const { open, agent } = this.props;
        const { actId, venueId, hideDescription } = this.state;
        const { errors } = this.props.userFestivalStore;
        return (
            <ModalContainer  open={open} title="Festival Registration">
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={6}>
                            {
                                agent ? (
                                    <WidgetContainer title = { agent.festivalTitle ? agent.festivalTitle : agent.festivalCode }>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: agent.festivalDescription }}
                                            style = {
                                                {
                                                    height: hideDescription ? 250 : undefined
                                                }
                                            }
                                         />
                                    </WidgetContainer>
                                ) : null
                            }
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={8}>
                                <Grid item xs = { 12 }>
                                    <SelectField
                                        id="festivalAct"
                                        label="Act"
                                        suggestions={ this.mapActSuggestions() }
                                        onChange={this.handleActChange}
                                        value={ actId }
                                        placeholder="Select Act"
                                        error={errors && errors["act"]}
                                        helperText={errors && errors["act"] ? errors["act"] : ""}
                                    />
                                </Grid>
                                <Grid item xs = { 12 }>
                                    <SelectField
                                        id="festivalVenue"
                                        label="Venue"
                                        suggestions={ this.mapVenueSuggestions() }
                                        onChange={this.handleVenueChange}
                                        value={ venueId }
                                        placeholder="Select Venue"
                                        error={errors && errors["venue"]}
                                        helperText={errors && errors["venue"] ? errors["venue"] : ""}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick = { this.props.closeHandler } >
                        Close
                    </Button>
                    <Button color="primary" onClick = { this.handleRegister }>
                        Register
                    </Button>
                </DialogActions>
            </ModalContainer>
        )
    }
}

export default FestivalRegistrationModal;