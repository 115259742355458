import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Heading from "../../components/Heading";
import Breadcrumb from "../../components/Breadcrumb";
import BookingForm from "../../components/booking/BookingForm";
import "./Booking.css";
import History from "../../components/booking/History";

import Negotiate from "../../components/booking/Negotiate";
import BookingLineup from "../../components/booking/BookingLineup";
import { Paper, Hidden } from "@material-ui/core";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";

import BookingStatusBadge from "../../components/booking/BookingStatusBadge";

import { BookingStatusType, ProfileType } from "../../types/enum";
import moment from "moment";

import BookingNotifyClient from "../../components/booking/BookingNotifyClient";
import BookingSetlist from "../../components/booking/BookingSetlist";
import BookingNotFound from "../profile/bookingInvalidAccess";
import SocialPostsDetailComponent from "../GigPromotion/SocialPostsDetailComponent"
const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("templateStore", "bookingRequestStore", "profileStore")
@observer
class BookingEdit extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Edit Booking Request" }];

    componentDidMount() {
        this.props.bookingRequestStore.setBookingHeading();
        this.props.templateStore.setEditBookingStep(0);
    }

    shouldComponentUpdate(nextProps) {
        if (this.props && nextProps && this.props.bookingRequestStore.booking !== nextProps.bookingRequestStore.booking) {
            return true;
        }

        if (this.props && nextProps && this.props.match.params.id !== nextProps.match.params.id) {
            this.props.bookingRequestStore.initializeEditBooking(nextProps.match.params.id);
            return true;
        }

        if (this.props && nextProps && this.props.match.params.step !== nextProps.match.params.step) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        const { booking } = this.props.bookingRequestStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.bookingRequestStore.setBookingHeading();
        if (this.props.match && this.props.match.params && this.props.match.params.step) {
            if(booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id){
                switch (this.props.match.params.step) {
                    case "edit":
                        this.props.templateStore.setEditBookingStep(0);
                        break;
                    case "lineup":
                        this.props.templateStore.setEditBookingStep(1);
                        break;
                    case "promote":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                    case "setlist":
                        this.props.templateStore.setEditBookingStep(3);
                        break;
                    case "history":
                        this.props.templateStore.setEditBookingStep(4);
                        break;
                    default:
                        this.props.templateStore.setEditBookingStep(0);
                        break;
                }
            } else if (booking.createdById === currentProfile?.id && currentProfile?.type !== ProfileType.Musician.ordinal) {
                switch (this.props.match.params.step) {
                    case "edit":
                        this.props.templateStore.setEditBookingStep(0);
                        break;
                    case "negotiate":
                        this.props.templateStore.setEditBookingStep(1);
                        break;
                    case "promote":
                        this.props.templateStore.setEditBookingStep(2);
                        break;
                    case "history":
                        this.props.templateStore.setEditBookingStep(3);
                        break;
                    default:
                        this.props.templateStore.setEditBookingStep(0);
                        break;
                }
            } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
                switch (this.props.match.params.step) {
                    case "edit":
                        this.props.templateStore.setEditBookingStep(0);
                        break;
                    case "history":
                        this.props.templateStore.setEditBookingStep(1);
                        break;
                    default:
                        this.props.templateStore.setEditBookingStep(0);
                        break;
                }
            }else{
                switch (this.props.match.params.step) {
                    case "edit":
                        this.props.templateStore.setEditBookingStep(0);
                        break;
                    case "notifyclient":
                        this.props.templateStore.setEditBookingStep(1);
                        break;
                    case "history":
                        this.props.templateStore.setEditBookingStep(2);
                        break;
                    default:
                        this.props.templateStore.setEditBookingStep(0);
                        break;
                }
            }
           
        } else {
            this.props.templateStore.setEditBookingStep(0);
        }
    }

    getSteps() {
        const { booking } = this.props.bookingRequestStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        if (booking.createdById === currentProfile?.id && currentProfile?.type === ProfileType.Musician.ordinal) {
            return ["Booking Details", "Notify Client", "Promote", "History"];
        } else if (currentProfile?.type === ProfileType.Agent.ordinal && booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id) {
            return ["Booking Details", "Line-up","Promote", "Setlist", "History"];
        }
        else if (booking.createdById === currentProfile?.id) {
            return ["Booking Details", "Negotiate/Message", "Promote", "History"];
        } else {
            return ["Booking Details", "History"];
        }
    }

    redirectPromote = () => {
        const bookingId = this.props.match.params.id;
        this.props.history.replace(`/musician/booking/${bookingId}/promote`);
    }

    getStepContent(stepIndex) {
        const { booking } = this.props.bookingRequestStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if (booking.createdById === currentProfile?.id && currentProfile?.type === ProfileType.Musician.ordinal) {
            switch (stepIndex) {
                case 0:
                    return (
                        <BookingForm
                            readOnly={
                                booking.status === BookingStatusType.Cancelled.ordinal ||
                                booking.status === BookingStatusType.Completed.ordinal ||
                                booking.status === BookingStatusType.Declined.ordinal ||
                                booking.status === BookingStatusType.CancelledByAct.ordinal ||
                                booking.dateStart < moment()
                            }
                            isEdit={true}
                            bookingId={this.props.match.params.id}
                            processLabel="Update Booking"
                        />
                    );
                case 1:
                    return <SocialPostsDetailComponent bookingId={this.props.match.params.id}/>
                case 2:
                    return <BookingNotifyClient type="musician" redirectActionPromote={this.redirectPromote} bookingId={this.props.match.params.id} />;
                case 3:
                    return <History type="musician" bookingId={this.props.match.params.id} />;
                default:
                    return "On development";
            }
        }  else if (booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id) {
            switch (stepIndex) {
                case 0:
                    return (
                        <BookingForm
                            readOnly={
                                booking.status === BookingStatusType.Cancelled.ordinal ||
                                booking.status === BookingStatusType.Completed.ordinal ||
                                booking.status === BookingStatusType.Declined.ordinal ||
                                booking.status === BookingStatusType.CancelledByAct.ordinal ||
                                booking.dateStart < moment()
                            }
                            isEdit={true}
                            bookingId={this.props.match.params.id}
                            processLabel="Update Booking"
                        />
                    );
                case 1:
                    return <BookingLineup type="musician" redirectActionPromote={this.redirectPromote}  bookingId={this.props.match.params.id} />;
                case 2:
                    return <SocialPostsDetailComponent bookingId={this.props.match.params.id}/>
                case 3:
                    return <BookingSetlist type="musician" bookingId={this.props.match.params.id} />;
                case 4:
                    return <History type="musician" bookingId={this.props.match.params.id} />;
                default:
                    return "On development";
            }
        } 
        else if (booking.createdById === currentProfile?.id) {
            switch (stepIndex) {
                case 0:
                    return (
                        <BookingForm
                            readOnly={
                                booking.status === BookingStatusType.Cancelled.ordinal ||
                                booking.status === BookingStatusType.Completed.ordinal ||
                                booking.status === BookingStatusType.Declined.ordinal ||
                                booking.status === BookingStatusType.CancelledByAct.ordinal ||
                                booking.dateStart < moment()
                            }
                            isEdit={true}
                            bookingId={this.props.match.params.id}
                            processLabel={booking.status === BookingStatusType.Draft.ordinal ? "Process Booking" : "Update Booking"}
                        />
                    );
                case 1:
                    return <Negotiate type="musician" bookingId={this.props.match.params.id} />;
                case 2:
                    return <SocialPostsDetailComponent bookingId={this.props.match.params.id}/>
                case 3:
                    return <History type="musician" bookingId={this.props.match.params.id} />;
                default:
                    return "On development";
            }
        } else {
            switch (stepIndex) {
                case 0:
                    return (
                        <BookingForm
                            readOnly={
                                booking.status === BookingStatusType.Cancelled.ordinal ||
                                booking.status === BookingStatusType.Completed.ordinal ||
                                booking.status === BookingStatusType.Declined.ordinal ||
                                booking.status === BookingStatusType.CancelledByAct.ordinal ||
                                booking.dateStart < moment()
                            }
                            isEdit={true}
                            bookingId={this.props.match.params.id}
                            processLabel="Update Booking"
                        />
                    );
                case 1:
                    return <History type="musician" bookingId={this.props.match.params.id} />;
                default:
                    return "On development";
            }
        }
    }

    handleStepClick = step => () => {
        const { booking } = this.props.bookingRequestStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if (booking.createdById === currentProfile?.id && currentProfile?.type === ProfileType.Musician.ordinal) {
            switch (step) {
                case 0:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
                case 1:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/promote`);
                    break;
                case 2:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/notifyclient`);
                    break;
                case 3:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/history`);
                    break;
                default:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
            }
        }  else if (booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id && currentProfile?.type === ProfileType.Agent.ordinal) {
            switch (step) {
                case 0:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
                case 1:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/lineup`);
                    break;
                case 2:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/promote`);
                    break;
                case 3:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/setlist`);
                    break;
                case 4:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/history`);
                    break;
                default:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
            }
        }else if (booking.createdById === currentProfile?.id && currentProfile?.type === ProfileType.Staff.ordinal) {
            switch (step) {
                case 0:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
                case 1:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/negotiate`);
                    break;
                case 2:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/promote`);
                    break;
                case 3:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/history`);
                    break;
                default:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
            }
        } else if (booking.createdById === currentProfile?.id) {
            switch (step) {
                case 0:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
                case 1:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/negotiate`);
                    break;
                case 2:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/promote`);
                    break;
                case 3:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/history`);
                    break;
                default:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
            }
        } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
            switch (step) {
                case 0:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
                case 1:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/history`);
                    break;
                default:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
            }
        } else {
            switch (step) {
                case 0:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
                case 1:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/negotiate`);
                    break;
                case 2:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/notifyclient`);
                    break;
                case 3:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/history`);
                    break;
                default:
                    this.props.history.replace(`/booking/${this.props.match.params.id}/edit`);
                    break;
            }
        }
        this.props.templateStore.setEditBookingStep(step);
    };

    getBookingStatus = () => {
        const { booking } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        const isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal;
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        const isStaffOrAgent = isStaff || isAgent;

        if (moment(booking.dateStart).endOf("day") < moment().startOf("day")) {
            return BOOKING_STATUS[booking ? booking.status : 0];
        } else if (booking.status === BookingStatusType.New.ordinal && !booking.saveAsDraft && isStaffOrAgent) {
            return "Sent";
        } else {
            return BOOKING_STATUS[booking ? booking.status : 0];
        }
    };

    render() {
        const steps = this.getSteps();
        const { booking, bookingHeading } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        const { editBookingStep } = this.props.templateStore;
        let { title, isAdmin, status, dateStart, venueName, venue, myLineup, cancelledBy, bookingRequests } = this.props.bookingRequestStore.booking;
        let resentBooking = cancelledBy && currentProfile && cancelledBy.userId === currentProfile.userId && bookingRequests?.length > 0 && bookingRequests[0].act.userId !== currentProfile.userId ? true : false;

        return (
            <main>
                <Header />
                <Sidebar />
                {booking && booking.id && (!isAdmin && !myLineup) || resentBooking ? (
                        <MainContainer>
                            <BookingNotFound />
                        </MainContainer>
                        ) : (
                        <MainContainer>
                            <Breadcrumb links={this.breadcrumb} />
                            <Heading title={bookingHeading}>
                                <div style={{ display: "inline-block" }}>
                                    <BookingStatusBadge cancelledBy={booking.cancelledBy ? booking.cancelledBy.type : 0} status={this.getBookingStatus()} />
                                </div>
                            </Heading>
                            <Paper className="booking-paper-form">
                                <Hidden smDown>
                                    <Stepper
                                        activeStep={editBookingStep}
                                        alternativeLabel={false}
                                        nonLinear={
                                            booking.status !== BookingStatusType.New.ordinal &&
                                            // booking.status !== BookingStatusType.Cancelled.ordinal &&
                                            booking.status !== BookingStatusType.Declined.ordinal
                                        }
                                    >
                                        {steps.map((label, index) => {
                                            return (
                                                <Step key={label} id="stepper-booking" disabled={false}>
                                                    <StepButton className="step-btn" onClick={this.handleStepClick(index)}>
                                                        {label}
                                                    </StepButton>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Hidden>
                                <Hidden mdUp xsDown>
                                    <Stepper
                                        activeStep={editBookingStep}
                                        alternativeLabel={true}
                                        nonLinear={
                                            booking.status !== BookingStatusType.New.ordinal &&
                                            booking.status !== BookingStatusType.Cancelled.ordinal &&
                                            booking.status !== BookingStatusType.Declined.ordinal
                                        }
                                    >
                                        {steps.map((label, index) => {
                                            return (
                                                <Step key={label}>
                                                    <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Hidden>
                                <Hidden smUp>
                                    <Stepper
                                        activeStep={editBookingStep}
                                        alternativeLabel={false}
                                        className="stepperXs"
                                        nonLinear={
                                            booking.status !== BookingStatusType.New.ordinal &&
                                            booking.status !== BookingStatusType.Cancelled.ordinal &&
                                            booking.status !== BookingStatusType.Declined.ordinal
                                        }
                                    >
                                        {steps.map((label, index) => {
                                            return (
                                                <Step key={label}>
                                                    <StepButton onClick={this.handleStepClick(index)} />
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Hidden>

                                <Paper className="stepperContent">{this.getStepContent(editBookingStep)}</Paper>
                            </Paper>
                        </MainContainer>
                    )
                }
                
            </main>
        );
    }
}

export default BookingEdit;
