import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
const XERO_KEY = process.env.REACT_APP_XERO_KEY;
const XERO_REDIRECT_URL = process.env.REACT_APP_XERO_REDIRECT_URL;
import ConfirmationModal from "../../components/modal/ConfirmationModal.js";

import { withRouter } from "react-router-dom";

@inject("authStore","xeroStore", "templateStore", "userStore","profileStore")
@withRouter
@observer
class ConnectedAccount extends Component {
    state = {
        xeroActive: false,
        xeroAccount: "",
        showXeroDisconnectModal: false
    };

    componentDidMount = () => {
        this.props.authStore.reset();

        this.props.userStore.getUserCognito()

        let currentProfile = this.props.profileStore.getCurrentProfile();

        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
            this.setState({
                xeroActive: currentProfile?.activateXeroAccess,
                xeroAccount: currentProfile?.xeroTenantName
            });
        }else{
            this.setState({
                xeroActive: currentProfile?.organisationOwner?.activateXeroAccess,
                xeroAccount: currentProfile?.organisationOwner?.xeroTenantName
            });
        }
        
    };
    handleCloseXeroModal = () => this.setState({ ...this.state, showXeroDisconnectModal: false });
    handleOpenXeroModal = () => this.setState({ ...this.state, showXeroDisconnectModal: true });

    handleDisconnectXero = e => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        this.props.xeroStore.disconnect(currentProfile.id).finally(() => {
            this.props.profileStore.loadProfiles().finally(() => {
                this.props.templateStore.hideLoadingScreen()
                this.props.history.push("/account/setting/xero")
            })
         
        });
    }

    render() {
        const {xeroActive,xeroAccount} = this.state
        const { currentUser } = this.props.userStore;
        var stringText = `By disconnecting your account, you will no longer be able to sync your customer invoices and supplier bills between Hot Giggity and xero. </br>
       The synchronisation data in Hot Giggity will also be deleted.</br>
       Do you want to continue?`
        return (
            <div>
                <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                Connect your Hotgiggity Account to your XERO account
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                      
                                                      <Grid item md={6}>
                                                          <Typography gutterBottom>Step 1. Connect Hot Giggity to your XERO account</Typography>
                                                          {
                                                                      xeroActive ?  <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: Successfully connected</Typography> :
                                                                      <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: XERO not connected</Typography>
                                                          }
                                                          
                                                      </Grid>
                                                      
                                                      <Grid item md={6} style={{textAlign:"center"}}>
                                                          {
                                                              xeroActive ? 
                                                              <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleOpenXeroModal} >
                                                                  disconnect
                                                              </Button> :
                                                              <Button
                                                                  variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary"
                                                                  target="_blank"
                                                                  href={`
                                                                  https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_KEY}&redirect_uri=${XERO_REDIRECT_URL}&scope=offline_access openid profile email accounting.settings accounting.transactions accounting.contacts accounting.attachments&state=123
                                                                  offline_access openid profile email accounting.transactions accounting.contacts accounting.settings accounting.attachments
                                                                      `}
                                                              >
                                                                  Connect XERO Account
                                                              </Button>
                                                          }
                                                              

                                                      </Grid>

                                                  </Grid> 
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>
                <ConfirmationModal
                    open={this.state.showXeroDisconnectModal}
                    title="Are you sure you want to disconnect your xero account?"
                    message={stringText}
                    closeHandler={this.handleCloseXeroModal}
                    confirmationHandler={this.handleDisconnectXero}
                    declineHandler={this.handleCloseXeroModal}
                    confirmationLabel="Disconnect"
                    declineLabel="Cancel"
                    maxWidth="sm"
                />
            </div>
        );
    }
}

export default ConnectedAccount;
