import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import NotificationTableHeader from "./NotificationTableHeader";
import NotificationToolbar from "./NotificationToolbar";
import green from "@material-ui/core/colors/green";
import { inject, observer } from "mobx-react";
import Loading from "../loadingSpinner/Loading";
import "./NotificationTable.css";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        width: "100%",
        boxShadow: "none"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    checked: {
        color: green[500],
        "& + $bar": {
            backgroundColor: green[500]
        }
    },
    bar: {}
});

@inject("songListStore", "setListStore", "notificationStore")
@observer
class NotificationTable extends Component {
    state = {
        openDelete: false,
        openAdd: false,
        openRemove: false,
        order: "desc",
        orderBy: "createdAt",
        selected: [],
        page: 0,
        rowsPerPage: 10,
        isLoading: false,
        notificationView: []
    };

    componentDidMount() {
        let { notifications, notificationsUpdated } = this.props.notificationStore;
        if(notifications.length){
            
            // var notif = notifications.slice(0)
            // console.log(notif)
            // this.setState({ notificationView : notif});
        }
       else{
        // this.setState({ isLoading: true });
         this.props.notificationStore.loadNotifications().then(x => {
            // var notificationsFresh = this.props.notificationStore.notifications;
            // var notif = notificationsFresh.slice(0)
            // console.log(notif)
            // this.setState({ isLoading: false,notificationView : notif});
            
        });
       }
       
    }


    handleSelectAllClick = (event, checked) => {
        let { notifications, notificationsUpdated } = this.props.notificationStore;
        if (checked) {
            this.setState({ selected: notificationsUpdated.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };
    handleRequestSort = (event, property) => {
        let { notifications, notificationsUpdated } = this.props.notificationStore;
        let data = [];
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        if (orderBy === "from") {
            data =
                order === "desc"
                    ? this.props.notificationStore.updateNotificationUpdated(notificationsUpdated.sort((a, b) => (b.fromProfile.firstName+" "+b.fromProfile.lastName < a.fromProfile.firstName+" "+a.fromProfile.lastName ? -1 : 1)))
                    : this.props.notificationStore.updateNotificationUpdated(notificationsUpdated.sort((a, b) => (a.fromProfile.firstName+" "+a.fromProfile.lastName < b.fromProfile.firstName+" "+b.fromProfile.lastName ? -1 : 1)));
        }
        else {
            data =
                order === "desc"
                    ? this.props.notificationStore.updateNotificationUpdated(notificationsUpdated.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)))
                    : this.props.notificationStore.updateNotificationUpdated(notificationsUpdated.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1)));
        }


        // this.props.notificationStore.update(data);

        this.setState({ order, orderBy });
    };
    handleClick = (event, id) => {
        let { songList } = this.props.songListStore;
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        const songToSetList = songList.filter(item => {
            return newSelected.indexOf(item.id) !== -1;
        });

        this.props.setListStore.updateSelectedSongs(songToSetList);
        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    onListReset = () => {
        this.setState({ selected: [] });
    };

    handleRemoveSong = notificationIds => {
        // let songs = this.props.songListStore.songList;
        this.setState({ isLoading: true });
      
        this.props.notificationStore.deleteList(notificationIds).then(() => {
            // var notificationsFresh = this.props.notificationStore.notifications;
            // var notif = notificationsFresh.slice(0)
            this.setState({ isLoading: false});
        });
        this.setState({ openDelete: true });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        // this.props.songListStore.executeDeleteSongs();
        this.setState({ openDelete: false });
    };

    handleClickOpen = async (notification) => {
        await this.props.notificationStore.read(notification);
        setTimeout(this.props.notificationStore.gotoPage(notification), 3000);
    };

    getFromName = (notification) => {
        return notification.fromProfile.firstName ? notification.fromProfile.firstName + " " + notification.fromProfile.lastName : notification.fromProfile.profileName ? notification.fromProfile.profileName : notification.fromProfile.actName
    }

    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, isLoading, notificationView } = this.state;
        const { notifications, notificationsUpdated } = this.props.notificationStore;
        

        return (
            <div style={{ position: "relative" }}>
                <Loading showed={isLoading} />
                <NotificationToolbar
                    numSelected={this.state.selected.length}
                    onListRemove={this.handleRemoveSong}
                    onListReset={this.onListReset}
                    selectedNotifications={this.state.selected}
                    type={this.props.type}
                />

                <div className={classes.tableWrapper}>
                    <Table className="song-table">
                        <NotificationTableHeader
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={notificationsUpdated.length}
                        />
                        <TableBody>
                            {notificationsUpdated.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                const isSelected = this.isSelected(n.id);
                                return (
                                    <TableRow hover role="checkbox" aria-checked={isSelected} tabIndex={-1} key={n.id} selected={isSelected}>
                                        <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id)} className="nopad">
                                            <Checkbox className="songCheckbox" checked={isSelected} />
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{fontWeight: n.isRead ? "" : "bold"}}>{moment(n.createdAt).format('ddd, DD-MM-YYYY hh:mm a')}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Link style={{fontWeight: n.isRead ? "" : "bold"}} target={"_blank"} to={`/profile/${n?.fromProfile?.id}`}>{n.fromProfile?this.getFromName(n):""}</Link>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{fontWeight: n.isRead ? "" : "bold"}}><div dangerouslySetInnerHTML={{__html: n.generatedMessage}}></div></Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit">
                                                <Button style={{fontWeight: n.isRead ? "" : "bold"}} size="small" onClick={() => this.handleClickOpen(n)}>
                                                    Open
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    SelectProps={{ className: "transparent-select" }}
                                    count={notificationsUpdated.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    {/* <LinearProgress variant="determinate" value={topSongs.length * 8.5} /> */}
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDelete}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarDeleteClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Notification Deleted</span>}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarDeleteClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}

NotificationTable.propTypes = {
    classes: PropTypes.object.isRequired
    // onListAdd: PropTypes.func.isRequired,
    // onListRemove: PropTypes.func.isRequired,
    // onListSort: PropTypes.func.isRequired,
    // profileId: PropTypes.number.isRequired
};

export default withStyles(styles)(NotificationTable);
