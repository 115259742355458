import React, { Component } from "react";
import { Grid, Navbar, Jumbotron, Button } from "react-bootstrap";
import logo from "./logo.svg";
import "./App.css";
import profileimg from "./img/profile-img.jpg";
import Header from "./components/Header.js";

class Profile extends Component {
    render() {
        return (
            <div className="Profile">
                <Header />
                <section id="intro" class="musician">
                    <div class="intro-inner">
                        <div class="row">
                            <div class="user wrap-t-b col-xl-3">
                                <div class="user-inner text-center">
                                    <span class="block m-auto thumb thumb-lg avatar">
                                        <img class="img-circle" src={profileimg} alt="profile" />
                                    </span>
                                    <span class="user-name h2">Amanda King</span>
                                    <span class="user-title">Singer, Soloist</span>
                                    <span class="user-location block wrap-t-b">
                                        <span>
                                            <i class="fa fa-map-marker" />
                                        </span>Brisbane, Australia
                                    </span>
                                    <div class="contact-user">
                                        <a class="btn btn--primary">Message</a> &nbsp;
                                        <a class="btn btn--primary">Booking</a>
                                    </div>
                                    <div class="artist-social">
                                        <ul>
                                            <li>
                                                <a class="facebook" href="#anchor">
                                                    <i class="fa fa-facebook" />
                                                </a>
                                            </li>&nbsp;
                                            <li>
                                                <a class="twitter" href="#anchor">
                                                    <i class="fa fa-twitter" />
                                                </a>
                                            </li>&nbsp;
                                            <li>
                                                <a class="googleplus" href="#anchor">
                                                    <i class="fa fa-google-plus" />
                                                </a>
                                            </li>&nbsp;
                                            <li>
                                                <a class="linkedin" href="#anchor">
                                                    <i class="fa fa-linkedin" />
                                                </a>
                                            </li>&nbsp;
                                            <li>
                                                <a class="soundcloud" href="#anchor">
                                                    <i class="fa fa-soundcloud" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <main id="page" class="musician">
                    <aside id="app-sidebar" class="musician widgets">
                        <div class="sidebar-inner">
                            <div class="booking-calendar widget wrap-lg">
                                <header class="sidebar widget-header">
                                    <h4 class="widget-title pull-left m-t-sm">See Amanda Performs</h4>
                                    <div class="select-view pull-right">
                                        <a class="calendarview btn btn--secondary">
                                            <i class="fa fa-calendar" />
                                        </a>
                                        <a class="listview btn btn--secondary active">
                                            <i class="fa fa-list" />
                                        </a>
                                    </div>
                                </header>
                                <hr />
                                <div class="artist-gigs-listview">
                                    <ul class="list-group">
                                        <li class="gig-item list-group-item">
                                            <div class="row">
                                                <div class="col-xs-4">
                                                    <span class="gig-cal">
                                                        <span class="gig-month">September</span>
                                                        <span class="gig-date">4</span>
                                                        <span class="gig-day">Friday</span>
                                                    </span>
                                                </div>
                                                <div class="col-xs-8">
                                                    <span class="gig-details">
                                                        <span class="gig-venue">
                                                            <span class="venue-icon">
                                                                <i class="fa fa-building" />
                                                            </span>
                                                            <span class="venue-name">The Foundry</span>
                                                        </span>
                                                        <span class="gig-address">
                                                            <span class="address-icon">
                                                                <i class="fa fa-map-marker" />
                                                            </span>
                                                            <span class="venue-address">228 Wickham Street, Fortitude Valley, Queensland 4006</span>
                                                        </span>
                                                        <span class="gig-time">
                                                            <span class="time-icon">
                                                                <i class="fa fa-clock-o" />
                                                            </span>
                                                            <span class="time-slot">9pm - 11pm</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="gig-item list-group-item">
                                            <div class="row">
                                                <div class="col-xs-4">
                                                    <span class="gig-cal">
                                                        <span class="gig-month">September</span>
                                                        <span class="gig-date">5</span>
                                                        <span class="gig-day">Saturday</span>
                                                    </span>
                                                </div>
                                                <div class="col-xs-8">
                                                    <span class="gig-details">
                                                        <span class="gig-venue">
                                                            <span class="venue-icon">
                                                                <i class="fa fa-building" />
                                                            </span>
                                                            <span class="venue-name">The Foundry</span>
                                                        </span>
                                                        <span class="gig-address">
                                                            <span class="address-icon">
                                                                <i class="fa fa-map-marker" />
                                                            </span>
                                                            <span class="venue-address">228 Wickham Street, Fortitude Valley, Queensland 4006</span>
                                                        </span>
                                                        <span class="gig-time">
                                                            <span class="time-icon">
                                                                <i class="fa fa-clock-o" />
                                                            </span>
                                                            <span class="time-slot">9pm - 11pm</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="artist-gigs-calendarview">
                                    <div class="calendar-header">
                                        <span class="glyphicon glyphicon-chevron-left pull-left" />
                                        <span class="glyphicon glyphicon-chevron-right pull-right" />
                                        <p>September 2015</p>
                                    </div>
                                    <div class="calendar-body">
                                        <div class="row weekdays">
                                            <div class="col-md-12">
                                                <div class="weekdays-inner">
                                                    <div class="col-xs-1">
                                                        <p>Mo</p>
                                                    </div>
                                                    <div class="col-xs-1">
                                                        <p>Tu</p>
                                                    </div>
                                                    <div class="col-xs-1">
                                                        <p>We</p>
                                                    </div>
                                                    <div class="col-xs-1">
                                                        <p>Th</p>
                                                    </div>
                                                    <div class="col-xs-1">
                                                        <p>Fr</p>
                                                    </div>
                                                    <div class="col-xs-1">
                                                        <p>Sa</p>
                                                    </div>
                                                    <div class="col-xs-1">
                                                        <p>Su</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dates">
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner inactive">
                                                        <span>31</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>1</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>2</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>3</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>4</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner" data-toggle="modal" data-target="#book_20150905">
                                                        <span>5</span>
                                                        <span class="availability available">
                                                            <i class="fa fa-circle" />
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>6</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dates">
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>7</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>8</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>9</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>10</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>11</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>12</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>13</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dates">
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>14</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>15</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>16</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>17</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>18</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>19</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>20</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dates">
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>21</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>22</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>23</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>24</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>25</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>26</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>27</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dates">
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>28</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>29</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner">
                                                        <span>30</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner inactive">
                                                        <span>1</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner inactive">
                                                        <span>2</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner inactive">
                                                        <span>3</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xs-1">
                                                <div class="date">
                                                    <a href="#anchor" class="date-inner inactive">
                                                        <span>4</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="line" />
                                        <div class="current-date" />
                                    </div>
                                </div>
                            </div>
                            <div class="artist-bio widget wrap-lg">
                                <header class="sidebar widget-header">
                                    <h4 class="widget-title">Amanda's Bio</h4>
                                </header>
                                <hr />
                                <div class="widget-content">
                                    <p>Amanda is one of the Gold Coast's most popular soloists!</p>
                                    <p>
                                        Hailing from the East Coast of Australia, Amanda has been performing live and in studios since the age of 15.
                                        Throughout her career Amanda has worked with the cream of the Australian music scene and is equally at home
                                        performing to an audience of 10 or 10,000. With her soulful vocal, passion for her craft, infectious
                                        personality and ability to engage, Amanda is sure to have your audience transfixed and wanting more.
                                    </p>
                                    <p>To find out more about Amanda, visit her website.</p>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <section id="app-content" class="artist-profile">
                        <div class="widgets artist-stats row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="widget total-performance gig-alerts panel">
                                            <div class="widget-inner bg-orange text-center wrap-t-b">
                                                <span class="h1">
                                                    99
                                                    <small class="block">Total Performances</small>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="widget played-at gig-fees panel">
                                            <div class="widget-inner bg-black text-center wrap-t-b">
                                                <span class="h3 widget-title">Played At</span>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 p-lr-n">
                                                    <div class="widget-inner wrap text-center">
                                                        <a href="#anchor">Jupiter's Ballroom</a>, <a href="#anchor">Liars Bar</a>,{" "}
                                                        <a href="#anchor">Surfer's Paradise</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="widget played-with gig-alerts panel">
                                            <div class="widget-inner bg-black text-center wrap-t-b">
                                                <span class="h3 widget-title">Played With</span>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 p-lr-n">
                                                    <div class="widget-inner wrap text-center">
                                                        <a href="#anchor">Ned Walker</a>, <a href="#anchor">Todd Byrnes</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="widget skillset gig-fees panel">
                                            <div class="widget-inner bg-black text-center wrap-t-b">
                                                <span class="h3 widget-title">Skill Set</span>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 p-lr-n">
                                                    <div class="widget-inner wrap text-center">
                                                        <a href="#anchor">Solo Vocal</a>, <a href="#anchor">Acoustic Guitar</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="widgets row">
                            <div class="artist-video widget col-md-6">
                                <header class="widget-header artist-video">
                                    <h4 class="widget-title">Amanda's Video</h4>
                                </header>
                                <div class="widget-content artist-video">
                                    <iframe
                                        title="artist-video"
                                        width="100%"
                                        height="320"
                                        src="https://www.youtube.com/embed/613jJALd7c8"
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                            </div>
                            <div class="artist-music widget col-md-6">
                                <header class="widget-header artist-sounds">
                                    <h4 class="widget-title">Amanda's Sounds</h4>
                                </header>

                                <div class="widget-content artist-sounds">
                                    <iframe
                                        title="artist-sound"
                                        width="100%"
                                        height="320"
                                        scrolling="no"
                                        frameBorder="no"
                                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/204911772&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="widgets row">
                            <div class="artist-testimonial widget col-md-12">
                                <header class="widget-header">
                                    <h4 class="widget-title">Testimonials</h4>
                                </header>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        );
    }
}

export default Profile;
