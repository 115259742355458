import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
// import NumberFormat from "react-number-format";
// import moment from "moment";
import { ProfileType, BookingType, BookingStatusType, RosterDividendType, LineupStatusType} from "../../types/enum";
import "./BookingDetails.css";
import { Grid, Typography, Button, Checkbox, Snackbar, IconButton, Tooltip } from "@material-ui/core";
import ResponsiveTable from "../table/ResponsiveTable";
import BookingStatusBadge from "./BookingStatusBadge";
import FacebookPostDialog from "../facebookPostDialog/FacebookPostDialog";
import moment from "moment";

import ClearIcon from "@material-ui/icons/Clear";
import PrintModal from "./PrintModal.js/PrintModal";
import PrintIcon from "@material-ui/icons/Print";
import facebook from "../../img/facebook-box.svg";

import currencyHelper from '../../helper/currency';
import FacebookSharePostDialog from "../facebookSharePostDialog/FacebookSharePostDialog";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

const FACEBOOK_ENABLED = process.env.REACT_APP_FACEBOOK_ENABLED === "true";

const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("profileStore", "bookingRequestStore", "templateStore", "facebookStore", "reportStore")
@withRouter
@observer
class BookingAll extends Component {
    state = {
        columns: [],
        acknowledgeSnackbarState: false,
        booking: undefined,
        selectedBookings: [],
        showPrintOption: false,
        allGigs: [],
        sharedBookings: [],
        isLoading: false,
        page: 1,
    };

    rows = [];

    componentDidMount() {
        const {
            currentProfile
        } = this.props.profileStore;
        this.props.bookingRequestStore.resetAllGigs()
        const { upcomings, requests, cancelled, completed, drafts,allGigsBooking } = this.props.bookingRequestStore;
        const { social } = this.props;

        this.props.bookingRequestStore.showLoadingState();
        
        if (this.props.profileType === "agent") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },

                    !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
                    !social && { key: "actFee", label: "Act Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
                    !social && { key: "agentFee", label: "My Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
                    
                    // { key: "interested", label: "Interested", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    // { key: "going", label: "Going", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    // { key: "", label: "", width: "10%" },
                    // { key: "socialPosts", label: "Social Posts",  width: "15%", align: 'center', style: {whiteSpace: 'nowrap'}},
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "5%" }
                ],
                isLoading: true            
            });
        } else if (this.props.profileType === "musician") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    !social && { key: "gigFee", label: "Venue/Client Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
                    !social &&   { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    !social && { key: "myFee", label: "My Fee", width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
                    // { key: "socialPosts", label: "Social Posts", width: "15%", align: 'center', style: {whiteSpace: 'nowrap' }},
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "15%" }
                ],
                isLoading: true
            });
        } else if (this.props.profileType === "staff") {
            if(currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.viewOnly){
                this.setState({
                    ...this.state,
                    columns: [
                        { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                        { key: "status", label: "Status", width: "15%" },
                        { key: "actName", label: "Act Name", width: "15%" },
                        { key: "venue", label: "Venue", width: "15%" },
                        !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } }, 
                        !social && { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                        !social && { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                        
                        // { key: "interested", label: "Interested", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                        // { key: "going", label: "Going", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                        // { key: "acknowledge", label: "Acknowledged", width: "10%", style: { textAlign: "center" } },
                        // { key: "socialPosts", label: "Social Posts",  width: "15%", align: 'center', style: {whiteSpace: 'nowrap'}},
                        { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                        { key: "action", label: "", width: "5%" }
                    ]
                });
            }else{
                this.setState({
                    ...this.state,
                    columns: [
                        { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                        { key: "status", label: "Status", width: "15%" },
                        { key: "actName", label: "Act Name", width: "15%" },
                        { key: "venue", label: "Venue", width: "15%" },
                        !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } }, 
                        !social &&   { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                        // { key: "interested", label: "Interested", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                        // { key: "going", label: "Going", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                        // { key: "acknowledge", label: "Acknowledged", width: "10%", style: { textAlign: "center" } },
                        { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                        { key: "action", label: "", width: "5%" }
                    ]
                });
            }
        }else if (this.props.profileType === "PrivateHire") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "20%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "20%" },
                    { key: "actName", label: "Act Name", width: "20%" },
                    { key: "venue", label: "Venue", width: "25%" },
                    !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } }, 
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "5%" }
                ],
                isLoading: true
            });
        }     
        this.loadGigs(1)
       
       
    }

    loadGigs = (page) => {
        const {
            currentProfile
        } = this.props.profileStore;
        if(this.props.profileType === "PrivateHire"){
            Promise.all([
                this.props.bookingRequestStore.loadAllGigs(currentProfile?.id)
            ]).then(() => {            
                // allGigs = allGigsBooking
    
                this.props.bookingRequestStore.hideLoadingState();            
    
                // this.setState({ isLoading: false });
                window.scrollTo(0, 0);
            })
            .finally(() => {
                this.showLoadingLocal(false)
            });
        }else{
            Promise.all([
                this.props.bookingRequestStore.loadAllGigs(currentProfile?.id,page)
            ]).then((result) => {            
                // allGigs = allGigsBooking
               
                this.props.bookingRequestStore.hideLoadingState();            
    
                // this.setState({ isLoading: false });
                // window.scrollTo(0, 0);
            })
            .finally(() => {
                this.showLoadingLocal(false)
                this.props.bookingRequestStore.setAllPage(page)
            });
        }
    }

    
    showLoadingLocal = (value) => {
        let currentState = { ...this.state };
        currentState.isLoading = value;
        this.setState(currentState);
    }

    openAcknowledgeSnackbar = booking => {
        this.setState({
            ...this.state,
            acknowledgeSnackbarState: true,
            booking: booking
        });
    };

    closeAcknowledgeSnackbar = () => {
        this.setState({
            ...this.state,
            acknowledgeSnackbarState: false,
            booking: undefined
        });
    };

    handleShowMore = e => {
        var page = this.props.bookingRequestStore.allPage + 1;
        this.showLoadingLocal(true)
        this.loadGigs(page)
    };
    updatedSharedBookingState = () => {
        const { booking } = this.props.facebookStore;
        const { sharedBookings } = this.state;
        let postedIds = sharedBookings;
        if (booking) {
            postedIds.push(booking.id);
        }
        this.setState({
            ...this.state,
            sharedBookings: postedIds,
        })
    }

    handleAcknowledgeBooking = booking => {
        let message = `Catch ${booking.actName} playing here on ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")} from ${moment(
            booking.timeStart,
            "HH:mm:ss"
        ).format("hh:mm A")} to ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}. `;
        if (booking.ticketUrl !== "") {
            message = message + `Tickets on sale ${booking.ticketUrl}.`;
        }
        this.props.facebookStore.setPostMessage(message);
        this.props.facebookStore.setBooking(booking);
        if (booking.venue.facebookPageId) {
            this.props.facebookStore.openFacebookPostDialog(booking.venue);
        } else {
            this.props.bookingRequestStore.venueStaffAcknowledgeGigs(booking.id).then(response => {
                this.openAcknowledgeSnackbar(response);
            });
        }
    };

    handleSharePost = async (booking, profile) => {
        const { currentProfile } = this.props.profileStore;
        let formattedVenueName = booking.venueName ? "#" + booking.venueName?.replace(/ /g, "") : booking.venue ? "#" + booking.venue?.profileName?.replace(/ /g, "") : "";
        let formattedActName = booking.actName ? "#" + booking.actName.replace(/ /g, "") : "";
        let formattedActName2 = booking.actName ? booking.actName : booking.act ? booking.act?.actName : ""
        let bookTableUrl = booking.venue && booking.venue.bookTableUrl ? booking.venue.bookTableUrl  : ""
        let message = `Hi Folks, Catch ${formattedActName2} playing here on ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")}  from ${moment(
            booking.timeStart,
            "HH:mm:ss" 
        ).format("hh:mm A")} to  ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}.  Come and enjoy some great live entertainment - get in early and book a table! \n \n ${bookTableUrl} \n \n ${formattedActName} ${formattedVenueName} #livegigs #livegigs #livemusic #liveentertainment #hotgiggity`
        if(currentProfile.type === ProfileType.Musician.ordinal){
            message = `Hi Folks!  I am playing a gig at ${formattedVenueName} on the ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")}  from ${moment(
                booking.timeStart,
                "HH:mm:ss" 
            ).format("hh:mm A")} to  ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}.  See you there!! \n \n #livegigs #livemusic #liveentertainment #hotgiggity `
        }
        
        if (booking.ticketUrl !== "") {
            message = message + `Tickets on sale ${booking.ticketUrl}.`;
        }
        if(booking.venue){
            booking.venue.backgroundImages = await this.props.profileStore.getBackgroundImage(booking.venue.id);
        }
        if(booking.act){
            booking.act.backgroundImages = await this.props.profileStore.getBackgroundImage(booking.act.id);
        }
        this.props.facebookStore.setPostMessage(message);
        this.props.facebookStore.setBooking(booking);
        if (profile.type === ProfileType.Musician.ordinal) {
            if (booking?.act?.facebookPageId) {
                this.props.facebookStore.openFacebookSharePostDialog(booking.act);
            } else {
                this.props.facebookStore.openFacebookSharePostDialog(currentProfile);
            }
        } else if (profile.type === ProfileType.Staff.ordinal) {
            this.props.facebookStore.openFacebookSharePostDialog(booking.venue);
              // this.props.bookingRequestStore.venueStaffAcknowledgeGigs(booking.id).then(response => {
            //     this.openAcknowledgeSnackbar(response);
        } else if (profile.type === ProfileType.Agent.ordinal) {
            this.props.facebookStore.openFacebookSharePostDialog(booking.agent);
        }
    };

    handleUndoAcknowledge = () => {
        if (this.state.booking) {
            this.props.bookingRequestStore.venueStaffUnacknowledgeGigs(this.state.booking.id).then(response => {
                this.closeAcknowledgeSnackbar();
                this.props.templateStore.openSnackbar(`Changes to ${response.title} has been reverted.`);
            });
        }
    };

    getBookingStatus = booking => {
        const { currentProfile } = this.props.profileStore
        if (booking.isCompletedGig) {
            return BOOKING_STATUS[BookingStatusType.Completed.ordinal]
        }
        if (booking.status === BookingStatusType.New.ordinal && booking.saveAsDraft) {
            return "Draft";
        }
        if (booking.createdById !== currentProfile?.id && (booking.status === BookingStatusType.AllPlayerConfirmed.ordinal || booking.status === BookingStatusType.PlayerConfirmedAndClientNotified.ordinal)) {
            return "Accepted"
        }
    
        return BOOKING_STATUS[booking.status];
        
    };

    renderTableCustomActions = () => {
        const { selectedBookings } = this.state;
        return (
            <Fragment>
                <Button onClick = { this.openPrintOptionModal } disabled = { !selectedBookings || selectedBookings.length === 0 } style={{ color: selectedBookings.length > 0 ? "#fff" : undefined }}>
                    <PrintIcon style={{ color: selectedBookings.length > 0 ? "#fff" : undefined }} />
                    Print
                </Button>
            </Fragment>
        )
    };

    renderFooterRow = () => {
        let { allGigs } = this.props.bookingRequestStore;
        const { profileType } = this.props;
        const { venueId } = this.props.reportStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if (venueId) {
            allGigs = allGigs.filter(x => x.venueId === venueId)
        }
        var totalFee = 0;
        var totalActFee = 0;
        var totalAgentFee = 0;
        var totalMyFee = 0

        allGigs.forEach(booking => {
            let linuep = booking.lineups && booking.lineups?.find(x => x.profileId === currentProfile?.id);
            var musicianFee = parseFloat(booking.gigFee) - totalFee
            const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

            let adminFeeRoster = 0
            let totalRosterFeeAdmin = 0
            booking?.act?.actRoster?.forEach(actRoster => {
                let feeCount = 0
                if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                    feeCount = actRoster.dividendAmount /100 * booking.actFee;
                }else {
                    feeCount = actRoster.dividendAmount;
                }
                totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
            })
            adminFeeRoster = booking.actFee - totalRosterFeeAdmin
            if(currentProfile?.type === ProfileType.Musician.ordinal && !booking?.lineups?.length){
                let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
               
                if(actRoster?.length){
                    if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                        musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                    }else {
                        musicianFee = actRoster[0].dividendAmount;
                    }
                    if(this.props.profileType === 'musician'){
                        if(booking?.act?.actAdminId === currentProfile?.id){
                            musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                        }
                    }
                }else{
                    musicianFee = booking.actFee
                    booking?.act?.actRoster?.forEach(item => {
                        var rosterFee = 0
                        if(item.dividendType === RosterDividendType.Percentage.ordinal){
                            rosterFee = item.dividendAmount /100 * booking.actFee;
                        }else {
                            rosterFee = item.dividendAmount;
                        }
                        musicianFee = musicianFee - rosterFee
                    });
                }
            }
             
            var totalRosterFee = 0

            booking?.lineups?.forEach(lineUp => {
                totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
            })
            var adminFee = booking.actFee - totalRosterFee
            var calculateFee = linuep?.fee ?  linuep?.fee : 0
            if(this.props.profileType === 'musician'){
                if(booking?.act?.actAdminId === currentProfile?.id){
                    calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                }
            }
            
            let myFee = linuep?.fee ? calculateFee: isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;
          
            let actOwner = booking?.act?.userId === currentProfile?.userId;            

            totalFee += (booking.viewOnly) ? 0 : (profileType === "agent" || profileType === "staff") && booking.isApproved ? Number(booking.gigFee) + Number(booking.extraGigFee) : Number(booking.gigFee);
            totalActFee += this.props.profileType === "musician" && !actOwner ? 0 : Number(booking.actFee);
            totalAgentFee += Number(booking.agentCommissionAmount);
            totalMyFee += Number(myFee);
        });

        return {
            gigFee: ( (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalFee, 2)}`,
            actFee: ( (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalActFee, 2)}`,
            agentCommissionAmount: ((currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
            agentFee: ((currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
            myFee: ((currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalMyFee, 2)}`,
            style: {
                textAlign: 'left'
            }
        }
    }

    getCheckedRows = rows => {
        this.setState({
            ...this.state,
            selectedBookings: rows
        })
    };

    openPrintOptionModal = () => {
        this.setState({
            ...this.state,
            showPrintOption: true
        })
    }

    closePrintOptionModal = () => {
        this.setState({
            ...this.state,
            showPrintOption: false
        })
    }

    isConnectedToFb = (booking) => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        let status;

        if (this.props.profileType === 'musician') {
            status = currentProfile && currentProfile.facebookPageId || booking?.act?.facebookPageId ? true : false;
        } else if (this.props.profileType === 'staff') {
            status = booking.venue.facebookPageId ? true : false;
        } else if (this.props.profileType === 'agent') {
            status = currentProfile && currentProfile.facebookPageId ? true : false
        }

        return status
    };

    render() {
        let { allGigs, isLoading } = this.props.bookingRequestStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const { profileType, social } = this.props;
        const { venueId } = this.props.reportStore;
        const { showPrintOption, selectedBookings } = this.state;
        if (venueId) {
            allGigs = allGigs.filter(x => x.venueId === venueId);
            this.props.bookingRequestStore.setBookingsCount("allGigs", allGigs);
            
        }

        allGigs = _.uniqBy(allGigs, "id");
        allGigs = _.sortBy(allGigs, "dateStart")

        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>                        
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                All Gigs
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                {profileType === "musician" || profileType === "dj" || profileType === "production"
                                    ? "This is the list all of your gigs"
                                    : "This is the list of all gigs"}
                            </Typography>
                            {/* <Typography>
                                <b>Tip:</b>
                                {profileType === "musician"
                                    ? " Click 'View Details' to edit the player lineup or update your set lists."
                                    : " Click 'View Details' to see booking details."}
                            </Typography> */}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12} className="booking-table-wrapper" ref={el => (this.componentRef = el)}>
                        <LoadingSpinner showed={!isLoading && this.state.isLoading} />
                        <ResponsiveTable
                            // showToolbar={true}
                            // customActions={
                            //     this.renderTableCustomActions()
                            // }
                            // selectable={true}
                            // getCheckedRows={this.getCheckedRows}
                            sortable
                            columns={this.state.columns}
                            rows={allGigs.map(booking => {
                                var row = {};
                                let date = new Date(booking.dateStart);
                                // row.date = date.getDate()+" "+this.props.templateStore.getMonth(date.getMonth())+" "+date.getFullYear();
                                row.id = booking.id;
                                var formattedDate = moment(booking.dateStart).format("YYYY-MM-DD")
                                
                                
                                let time = booking.timeStart.length < 8 ? `0${booking.timeStart}` : booking.timeStart;
                                let endTime = booking.timeEnd.length < 8 ? `0${booking.timeEnd}` : booking.timeEnd;
                                var finalDate= moment(formattedDate + ' ' + time)
                                row.date = {newFormat: 
                                    (
                                        <div>
                                            <span>{`${booking.dateStart.format("ddd, DD MMM YYYY")}`}</span>
                                            <br />
                                            <small>{`${moment(time).format('hh.mm A')} - ${moment(endTime).format('hh.mm A')}`}</small>
                                        </div>
                                    ), value: finalDate};
                                row.venue = (
                                    <div>
                                        <span>{booking.venue ? booking.venue.profileName : booking.venueName}</span>
                                        <br />
                                        <small>{booking.venueAddress}</small>
                                    </div>
                                );
                                // row.address = booking.venueAddress;
                                row.bookingCreator = booking?.createdBy ? (booking?.createdBy?.firstName + " " +booking?.createdBy?.lastName) : ""
                                
                                row.gigFee = (booking.viewOnly)  || (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices)
                                    ? <i class="far fa-eye-slash"></i>
                                    : `${currentProfile?.currencySymbol + ((profileType === "agent" || profileType === "staff") && booking.isApproved
                                        ? currencyHelper.formatMoney(booking.gigFee + booking.extraGigFee, 2) 
                                        : currencyHelper.formatMoney(booking.gigFee, 2))}`;
                                row.agentFee = currentProfile?.currencySymbol + currencyHelper.formatMoney(booking.agentCommissionAmount, 2);
                                // row.actName = booking.actName;
                                const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

                                row.actName = booking.status === BookingStatusType.Resent.ordinal &&
                                (profileType === "agent" || profileType === "staff" || profileType === "venue") ? (
                                    <div>
                                        {booking.bookingRequests?.map(bookingRequest => (
                                            <Fragment>
                                                <Link target={"_blank"} to={`/profile/${bookingRequest?.act?.id}`}>{bookingRequest.act ? bookingRequest?.act?.actName : ""}</Link>
                                                <br />
                                                <small>Contact: {bookingRequest?.act?.contactName}</small><br />
                                                <small>Phone: {booking?.act?.contactPhone ? booking?.act?.contactPhone  : "-"}</small>
                                                {/* <hr style={{ marginTop: "8px !important", marginBottom: "8px !important" }} /> */}
                                            </Fragment>
                                        ))}
                                    </div>
                                ) : (
                                    <div>
                                        <a target={"_blank"} href={`/profile/${isEntertainerBookings ? currentProfile?.id : booking?.act?.id}`}>{isEntertainerBookings ? `${currentProfile?.firstName} ${currentProfile?.lastName}` : booking?.act?.actName}</a>
                                        <br />
                                        {booking.act && <small>Contact: {booking?.act?.contactName}</small>}<br />
                                        {booking.act && <small>Phone: {booking?.act?.contactPhone ? booking?.act?.contactPhone  : "-"}</small>}
                                    </div>
                                );
                                let totalFee = 0;
                                booking.lineups?.forEach(l => {
                                    if (l.status !== LineupStatusType.Removed.ordinal) {
                                        totalFee += parseFloat(l.fee)
                                    }                                    
                                });  
                                let linuep = booking.lineups && booking.lineups?.find(x => x.profileId === currentProfile?.id);
                                // let myFee = linuep?.fee ? linuep.fee : isEntertainerBookings ? booking.gigFee : booking.actFee
                                let actOwner = booking?.act?.userId === currentProfile?.userId;                           

                                row.actFee = this.props.profileType === "musician" && !actOwner ? "" : currentProfile?.currencySymbol + currencyHelper.formatMoney(booking.actFee, 2);
                                // row.myFee = this.props.profileType === 'musician' && currentProfile?.currencySymbol + currencyHelper.formatMoney(myFee, 2);
                                
                                var musicianFee = parseFloat(booking.gigFee) - totalFee

                                var musicianFee = parseFloat(booking.gigFee) - totalFee
                                let adminFeeRoster = 0
                                let totalRosterFeeAdmin = 0
                                booking?.act?.actRoster?.forEach(actRoster => {
                                    let feeCount = 0
                                    if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                                        feeCount = actRoster.dividendAmount /100 * booking.actFee;
                                    }else {
                                        feeCount = actRoster.dividendAmount;
                                    }
                                    totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
                                })
                                adminFeeRoster = booking.actFee - totalRosterFeeAdmin

                                if(currentProfile?.type === ProfileType.Musician.ordinal && !booking.lineups?.length){
                                    let actRoster = booking.act?.actRoster.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
                                    if(actRoster?.length){
                                        if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                                            musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                                        }else {
                                            musicianFee = actRoster[0].dividendAmount;
                                        }
                                        if(this.props.profileType === 'musician'){
                                            if(booking?.act?.actAdminId === currentProfile?.id){
                                                musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                                            }
                                        }
                                    }else{
                                        musicianFee = booking.actFee
                                        booking.act?.actRoster.forEach(item => {
                                            var rosterFee = 0
                                            if(item.dividendType === RosterDividendType.Percentage.ordinal){
                                                rosterFee = item.dividendAmount /100 * booking.actFee;
                                            }else {
                                                rosterFee = item.dividendAmount;
                                            }
                                            musicianFee = musicianFee - rosterFee
                                        });
                                    }
                                }
                                var totalRosterFee = 0

                                booking?.lineups?.forEach(lineUp => {
                                    totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
                                })

                                var adminFee = booking.actFee - totalRosterFee
                                var calculateFee = linuep?.fee ?  linuep?.fee : 0
                                if(this.props.profileType === 'musician'){
                                    if(booking?.act?.actAdminId === currentProfile?.id){
                                        calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                                    }
                                }
                                let myFee = linuep?.fee ? calculateFee : isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;

                                row.myFee = this.props.profileType === 'musician' && currentProfile?.currencySymbol + currencyHelper.formatMoney(myFee, 2);
                                row.agentCommissionAmount = `${currentProfile?.currencySymbol}${currencyHelper.formatMoney(booking.agentCommissionAmount, 2)}`
                                row.agentName = booking.agentName;
                                row.time = booking.timeStart.substring(0, 5);

                                let fbPostedStatus = this.state.sharedBookings.includes(booking.id);
                                let fbButtonDisabled = !this.isConnectedToFb(booking) || !booking.sharePermission || booking?.type === BookingType.Adhoc.ordinal || isEntertainerBookings;
                                let fbButtonStyle = (this.isConnectedToFb(booking) && booking.sharePermission && booking?.type !== BookingType.Adhoc.ordinal && !isEntertainerBookings) ? "fb-share" : "fb-share-disabled";
                                let fbButtonText = (!fbButtonDisabled && booking?.isPostToFacebook) || fbPostedStatus ? "Posted" : "Post to Facebook";
                                let fbHoverText = this.isConnectedToFb(booking) ? "" : "Connect your Facebook page to post this gig to Facebook"
                                
                                const isUpcomings = booking?.status === BookingStatusType.Accepted.ordinal || booking?.status === BookingStatusType.Incomplete.ordinal;
                               

                                if(currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.viewOnly){
                                    row.socialPosts = isUpcomings && !booking?.isCompletedGig && (
                                        <Tooltip title={fbHoverText} >
                                            <Button disabled={fbButtonDisabled} className={fbButtonStyle} onClick={() => this.handleSharePost(booking, currentProfile)}>
                                                <img src={facebook} alt="fb" className="logo-fb" />&nbsp;&nbsp;{fbButtonText}
                                            </Button>
                                        </Tooltip>
                                    )
                                }

                                row.purchaseOrder = booking?.purchaseOrderCode;

                                row.acknowledge = booking?.acknowledge ? (
                                    <Tooltip
                                        title={
                                            <span>
                                                Acknowledge By:{" "}
                                                {`${booking?.acknowledgeBy ? booking?.acknowledgeBy.firstName : ""} ${
                                                    booking?.acknowledgeBy ? booking?.acknowledgeBy.lastName : ""
                                                }`}
                                            </span>
                                        }
                                        placement="bottom"
                                    >
                                        <Checkbox checked={true} disableRipple={true} />
                                    </Tooltip>
                                ) : (
                                    <Checkbox
                                        checked={booking?.acknowledge}
                                        onChange={() => this.handleAcknowledgeBooking(booking)}
                                        disabled={booking.acknowledge || !booking.canAcknowledge}
                                    />
                                );
                                row.action = (
                                    <Button
                                        size="small"
                                        color="primary"
                                        className="action-button booking-details"
                                        component={Link}
                                        to={
                                            booking?.type === BookingType.Adhoc.ordinal
                                                ? `/privateBooking/${booking.id}/edit`
                                                : isEntertainerBookings
                                                    ? `/booking/${booking.id}/edit`
                                                    : currentProfile?.type === ProfileType.Musician.ordinal
                                                        ? `/musician/booking/${booking.id}/details`
                                                        : currentProfile?.type === ProfileType.Agent.ordinal
                                                            ? `/booking/${booking.id}/edit`
                                                            : currentProfile?.type === ProfileType.Staff.ordinal
                                                                ? `/booking/${booking.id}/edit`
                                                                : ""
                                        }
                                    >
                                        Detail
                                    </Button>
                                );

                                row.status = <BookingStatusBadge isPrint={true} status={this.getBookingStatus(booking)}  cancelledBy={ (booking && booking.cancelledBy ? booking.cancelledBy.type : 0)}/>;
                                // row.interested = booking.interested ? booking.interested.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0";
                                // row.going = booking.going ? booking.going.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0";

                                return row;
                            })}
                            footerRow = {
                                currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal || currentProfile?.type === ProfileType.Musician.ordinal ? 
                                    this.renderFooterRow() : undefined
                            }
                        />
                         <Grid item style={{marginTop: 25}} xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                            <Grid container direction="row" justify="center" alignItems="center">
                                {this.props.bookingRequestStore.showMoreAll ? (
                                    <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                        Show More
                                    </Button>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.acknowledgeSnackbarState}
                    onClose={this.closeAcknowledgeSnackbar}
                    autoHideDuration={5000}
                    message={
                        <span id="message-id">{`${
                            this.state.booking ? this.props.bookingRequestStore.getBookingTitle(this.state.booking) : "Selected gigs"
                        } has been acknowledged.`}</span>
                    }
                    action={[
                        <Button color="primary" size="small" onClick={this.handleUndoAcknowledge}>
                            UNDO
                        </Button>,
                        <IconButton onClick={this.closeAcknowledgeSnackbar} color="primary">
                            <ClearIcon />
                        </IconButton>
                    ]}
                />
                {FACEBOOK_ENABLED && <FacebookPostDialog profileType="venue" callback={this.openAcknowledgeSnackbar} />}

                {FACEBOOK_ENABLED && <FacebookSharePostDialog profileType="musician" reloadData={this.updatedSharedBookingState} />}

                <PrintModal 
                    open = { showPrintOption }
                    closeHandler = { this.closePrintOptionModal }
                    bookings = { selectedBookings }
                />
            </Fragment>
        );
    }
}

export default BookingAll;
