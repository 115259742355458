import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import ConfirmationModal from "../modal/ConfirmationModal";
import { BookingStatusType, ProfileType, BookingType } from "../../types/enum";
import { withRouter } from "react-router-dom";

import { Grid, TextField, Button, Typography, Avatar, Tooltip, Hidden, IconButton } from "@material-ui/core";
import Loading from "../loadingSpinner/Loading";
import FacebookPostDialog from "../facebookPostDialog/FacebookPostDialog";
import blankProfile from "../../img/hotgig-loading.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
const FACEBOOK_ENABLED = process.env.REACT_APP_FACEBOOK_ENABLED === "true";

@inject("profileStore", "negotiateStore", "bookingRequestStore", "templateStore", "facebookStore")
@withRouter
@observer
class Negotiate extends Component {
    state = {
        declineBookingModalState: false,
        negotiateMessageId: 0,
        negotiateMessageDeleteModal : false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        // debugger;
        const { booking } = this.props.bookingRequestStore;
        this.props.facebookStore.setBooking(booking);

        if (this.props.bookingId) {
            // skip reload if we already have it from the previous page
            if (!booking || booking.id + "" !== this.props.bookingId) {
                debugger;
                this.props.bookingRequestStore.load(this.props.bookingId).then(() => {
                    const message = `Upcoming gig @ ${booking.venue ? booking.venue.profileName : booking.venueName} on ${moment(
                        new Date(booking.dateStart)
                    ).format("DD-MM-YYYY")} from ${moment(booking.timeStart, "HH:mm:ss").format("hh:mm A")} to ${moment(
                        booking.timeEnd,
                        "HH:mm:ss"
                    ).format("hh:mm A")}.`;
                    this.props.facebookStore.setPostMessage(message);
                });
            }
            this.props.negotiateStore.load(this.props.bookingId);
        } else {
            this.props.organisationStore.resetSelection();
        }
    }

    handleBodyChange = e => this.props.negotiateStore.setBody(e.target.value);
    handleOpenDeclineModal = () => this.setState({ declineBookingModalState: true });
    handleCloseDeclineModal = () => this.setState({ declineBookingModalState: false });

    handleDeclineBooking = e => {
        const { match } = this.props;
        const { profile } = this.props.profileStore;
        if (match.params && match.params.bookingRequestId) {
            this.props.bookingRequestStore.declineChangeActRequest(match.params.bookingRequestId).then(() => {
                this.props.templateStore.openSnackbar("Booking declined!");
                this.props.history.push("/musician/gig");
            });
        } else {
            this.props.bookingRequestStore.saveStatus(profile?.id, BookingStatusType.Declined.ordinal).then(() => {
                this.props.templateStore.openSnackbar("Booking declined!");
                //this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
                this.props.history.push("/musician/gig");
            });
        }
    };

    handleBookingAccepted = e => {
        const { match } = this.props;
        const { booking } = this.props.bookingRequestStore;
        if (match.params && match.params.bookingRequestId) {
            this.props.templateStore.openSnackbar("Booking accepted!");
            this.props.history.replace(`/musician/booking/${booking.id}/lineup`);
            this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
            this.props.templateStore.closeLineupBlock(); // block for unsaved lineup
        } else {
            this.props.templateStore.openSnackbar("Booking accepted!");
            if (booking?.type === BookingType.Adhoc.ordinal) {
                this.props.history.replace(`/privatebooking/${booking.id}/lineup`);
            } else {
                this.props.history.replace(`/musician/booking/${booking.id}/lineup`);
            }
            this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
            this.props.templateStore.closeLineupBlock(); // block for unsaved lineup
        }
    };

    handleAcceptBooking = e => {
        const { booking } = this.props.bookingRequestStore;
        if (booking.act.facebookPageId && FACEBOOK_ENABLED && booking?.type === BookingType.Normal.ordinal) {
            this.props.facebookStore.openFacebookPostDialog(booking.act);
        } else {
            this.handleExecuteAcceptBooking();
        }
    };

    handleExecuteAcceptBooking = e => {
        const { match } = this.props;
        const { profile } = this.props.profileStore;

        if (match.params && match.params.bookingRequestId) {
            this.props.bookingRequestStore.acceptChangeActRequestWithProfileId(match.params.bookingRequestId, profile?.id ).then(() => {
                this.handleBookingAccepted();
            });
        } else {
            this.props.bookingRequestStore.saveStatus(profile?.id, BookingStatusType.Accepted.ordinal).then(() => {
                this.handleBookingAccepted();
            });
        }
    };

    handleSubmitForm = e => {
        e.preventDefault();

        // submit
        this.props.negotiateStore.submit();
    };

    showActionButton = (profile, booking) => {
        return (
            (profile?.type === ProfileType.Musician.ordinal || (profile?.type === ProfileType.Agent.ordinal && booking.isAdmin)) &&
            (booking.status === BookingStatusType.New.ordinal ||
                booking.status === BookingStatusType.Resent.ordinal ||
                booking.status === BookingStatusType.Hold.ordinal) && profile?.id !== booking.createdById
        );
    };

    handleOpenModal = (id) => {
        this.setState({ negotiateMessageId: id, negotiateMessageDeleteModal : true });
    }

    handleCloseModal = () => {
        this.setState({ negotiateMessageId: 0, negotiateMessageDeleteModal : false });
    }

    handleDelete = () => {
        const {negotiateMessages } = this.props.negotiateStore;
        this.props.negotiateStore.deleteNegotiateMessage(this.state.negotiateMessageId).then(() =>{
            var filteredMessages = negotiateMessages.filter(x=>x.id != this.state.negotiateMessageId)
            this.props.negotiateStore.setMessages(filteredMessages);
            this.handleCloseModal()
        })

    }
    render() {
        const { match } = this.props;
        const { booking, isLoading } = this.props.bookingRequestStore;
        const { message, negotiateMessages, isLoadingMessages, isLoadingBooking } = this.props.negotiateStore;
        const { profile } = this.props.profileStore;

        return (
            <Grid container spacing={16} style={{ position: "relative", minHeight: "200px" }}>
                <Loading showed={isLoading || isLoadingMessages || isLoadingBooking} />
                {FACEBOOK_ENABLED && (
                    <FacebookPostDialog
                        profileType="musician"
                        bookingId={match.params.bookingRequestId || match.params.id}
                        callback={this.handleBookingAccepted}
                    />
                )}
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={3}>
                            <div>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Negotiate/Message
                                </Typography>
                                <Typography gutterBottom>
                                    Negotiate with the booking creator to get an agreement over the time, fee, terms and conditions, etc.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Messages
                                    </Typography>
                                </Grid>
                                {negotiateMessages.map(message => {
                                    return (
                                        <Grid item xs={12} key={message.id}>
                                            <div className={message.profile?.id === profile?.id ? "chat me" : "chat"}>
                                                <Avatar src={`${message.profileImageUrl === null || typeof message.profileImageUrl === "undefined" ? blankProfile : message.profileImageUrl}`} className="chat-avatar" />
                                                <div className="chat-message">
                                                    <Tooltip
                                                        title={`Time: ${moment(message.createdAt)
                                                            .local()
                                                            .format("DD-MM-YYYY HH:mm:ss")}`}
                                                    >
                                                        <p className="chat-name">
                                                            {message.profile?.type === ProfileType.Agent.ordinal
                                                                ? message.profile?.profileName
                                                                : message.profile?.firstName}
                                                        </p>
                                                    </Tooltip>
                                                    {message.message}
                                                </div>
                                                {
                                                    message.profileId === profile?.id ? 
                                                    <IconButton
                                                            aria-label="close"
                                                            style={{    position: "absolute",
                                                                color: "#525252",
                                                                right: 9,
                                                                fontSize: 15,
                                                                marginTop: "33px"}}
                                                            onClick={() => this.handleOpenModal(message.id)}
                                                            >
                                                    <FontAwesomeIcon icon={['fas', 'trash-can']} />  
                                                    </IconButton>: null
                                                }
                                            </div>
                                        </Grid>
                                    );
                                })}

                                {booking &&
                                    (booking.status === BookingStatusType.Accepted.ordinal || booking.status === BookingStatusType.Hold.ordinal) ? (
                                    <Grid item xs={12}>
                                        <form>
                                            <hr />
                                            <div>
                                                <TextField
                                                    id="body"
                                                    label="Send Message"
                                                    multiline
                                                    rows="4"
                                                    value={message}
                                                    onChange={this.handleBodyChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </div>
                                            <div>
                                                <Button
                                                    id="btn-send"
                                                    type="submit"
                                                    variant="raised"
                                                    color="primary"
                                                    style={{ float: "right" }}
                                                    onClick={this.handleSubmitForm}
                                                >
                                                    Send
                                                </Button>
                                            </div>
                                        </form>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {this.showActionButton(profile, booking) && (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} lg={3}>
                                <div>
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Accept or Decline
                                    </Typography>
                                    <Typography gutterBottom>
                                        Click "Accept Booking" button to accept the booking. <br />
                                        Click "Decline Booking" button if you wish you decline the booking.
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Hidden smDown>
                                    <Button
                                        variant="raised"
                                        className="pull-right btn-accept-booking"
                                        color="primary"
                                        onClick={this.handleAcceptBooking}
                                    >
                                        Accept Booking
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="pull-right"
                                        onClick={this.handleOpenDeclineModal}
                                        style={{ marginRight: "8px" }}
                                    >
                                        Decline Booking
                                    </Button>
                                </Hidden>
                                <Hidden mdUp>
                                    <Button
                                        variant="raised"
                                        color="primary"
                                        fullWidth={true}
                                        style={{ marginBottom: "12px" }}
                                        onClick={this.handleAcceptBooking}
                                        className="btn-accept-booking"
                                    >
                                        Accept Booking
                                    </Button>

                                    <Button variant="outlined" fullWidth={true} onClick={this.handleOpenDeclineModal}>
                                        Decline Booking
                                    </Button>
                                </Hidden>
                                <ConfirmationModal
                                    open={this.state.declineBookingModalState}
                                    title="Are you sure you want to decline this booking?"
                                    message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                    closeHandler={this.handleCloseDeclineModal}
                                    confirmationHandler={this.handleDeclineBooking}
                                    declineHandler={this.handleCloseDeclineModal}
                                    confirmationLabel="Decline Booking"
                                    declineLabel="Do not Decline"
                                    maxWidth="sm"
                                />
                               
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                  <ConfirmationModal
                                    open={this.state.negotiateMessageDeleteModal}
                                    title="Delete negotiate message"
                                    message={`Are you sure to delete this message? `}
                                    closeHandler={this.handleCloseModal}
                                    confirmationHandler={() => this.handleDelete()}
                                    declineHandler={this.handleCloseModal}
                                    confirmationLabel="Delete"
                                />
            </Grid>
        );
    }
}

export default Negotiate;
