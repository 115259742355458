import { observable, action } from "mobx";
import agent from "../agent";

class xeroStore {
    @observable xeroErrorLogs = []
    // this.xeroListofAccountsOutgoing = mappedAccounts.filter(x=>x.)
    // this.xeroListofAccountsIncoming = mappedAccounts
    
    @action
    resetLogs(){
        this.xeroErrorLogs = []
    }

    @action
    getXeroLogs(profileId) {
        return agent.XeroLogs.getXeroLogById(profileId).then(action(result => {
            this.xeroErrorLogs = result

            return result;
        }));
    };

    @action
    markAsResolved(id) {
        return agent.XeroLogs.markAsResolved(id).then(action(result => {
            this.xeroErrorLogs = this.xeroErrorLogs.map(x => {
                if (x.id === result.id) {
                    x.isResolved = result.isResolved
                }
                return x;
            });

            return result;
        }));
    };
    
    

}

export default new xeroStore();
