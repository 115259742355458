import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";


import { IconButton, Menu, MenuItem, Badge, Popover, Tooltip, List } from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";

import "./Notification.css";

import NotificationItem from "./NotificationItem";
import { NotificationType } from "../../types/enum";
import NotificationLineupReminder from "./NotificationLineupReminder";
import NotificationBookingReminder from "./NotificationBookingReminder";
const styles = theme => ({
    margin: {
        margin: theme.spacing.unit * 2
    },
    customBadge: {
        backgroundColor: "white",
        color: "black"
    }
});
@inject("templateStore", "notificationStore", "profileStore")
@withRouter
@observer
class Notification extends Component {
    state = {
        anchorMenu: null,
        dense: true
    };

    handleClick = event => {
        this.props.templateStore.setNotificationPopoverAnchor(event.currentTarget);
    };

    handleClose = () => {
        this.props.templateStore.setNotificationPopoverAnchor(null);
    };

    handleClickMenu = event => {
        this.setState({ anchorMenu: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorMenu: null });
    };

    handleRead = notification => {
        this.handleClose();
        this.props.notificationStore.read(notification);
    };

    handleMarkAllRead = () => {
        this.handleCloseMenu();
        this.props.notificationStore.markAllAsRead();
    };

    handleDeleteAll = () => {
        this.handleCloseMenu();
        this.props.notificationStore.deleteAll();
    };

    renderNotificationContent(notification, index) {
        if (notification && notification.message) {
            if (typeof(notification.message) !== 'string') {
                notification.message = _.mapKeys(notification.message, (value, key) => _.camelCase(key))
                notification.message = JSON.stringify(notification.message)
            }
            switch (notification.type) {
                case NotificationType.LineupReminder.ordinal:
                    return <NotificationLineupReminder notification={notification} key={index} />;
                case NotificationType.BookingReminder.ordinal:
                    return <NotificationBookingReminder notification={notification} key={index} />;
                default:
                    return <NotificationItem notification={notification} key={index} />;
            }
        } else {
            return <NotificationItem notification={notification} key={index} />;
        }
    }

    render() {
        const { anchorMenu, dense } = this.state;
        const { notificationPopoverAnchor } = this.props.templateStore;
        const { notifications, unread } = this.props.notificationStore;
        const { classes } = this.props;

        var viewNotif = notifications.slice(0, 50);
        return (
            <div className="notification-div">
                <IconButton id="btn-notification" style={{ marginRight: "0px", marginLeft: 7, top: 4 }} color="primary" onClick={this.handleClick}>
                    {unread > 0 && (
                        <Badge badgeContent={unread} color="secondary" classes={{ badge: classes.customBadge }}>
                            <i class="far fa-bell"></i>
                        </Badge>
                    )}
                    {unread <= 0 && <i class="far fa-bell"></i>}
                </IconButton>

                <Popover id="notif-pop" anchorEl={notificationPopoverAnchor} open={Boolean(notificationPopoverAnchor)} onClose={this.handleClose}>
                    {/* <Popover
                    id="notif-pop"
                    anchorPosition={{ top: 100, left: 1500 }}
                    open={true}
                    onClose={this.handleClose}
                > */}
                    <div className="notif-container">
                        <div className="notif-header">
                            <span className="notif-title">Notifications</span>
                            <Tooltip id="tooltip-icon" title="Notification Settings">
                                <IconButton aria-label="Option" id="notif-setting" onClick={this.handleClickMenu}>
                                    <Settings style={{marginRight:5}}/>
                                </IconButton>
                            </Tooltip>
                            <Menu id="notif-menu" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={this.handleCloseMenu}>
                                <MenuItem onClick={this.handleMarkAllRead}>Mark all as read</MenuItem>
                                <MenuItem onClick={this.handleDeleteAll}>Delete all notifications</MenuItem>
                            </Menu>
                        </div>
                        <List dense={dense}>
                            {viewNotif.map((notification, index) => {
                                return this.renderNotificationContent(notification, index);
                            })}
                        </List>
                    </div>
                </Popover>
            </div>
        );
    }
}
Notification.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Notification);
