import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { ProfileType, BookingStatusType } from "../../../types/enum";

import { Grid, TextField } from "@material-ui/core";

import SelectField from "../../fields/SelectField";
import SearchVenueField from "../../adhocBooking/formFields/SearchVenueField";
import moment from "moment";

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore", "venueStore", "venueActStore", "templateStore")
@withRouter
@observer
class BookingEventDetails extends Component {
    agentSuggestions = [];
    venueSuggestions = [];
    constructor(props) {
        super(props);
        this.props.venueStore.resetVenues();
    }

    componentDidMount() {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        var isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal
        var isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal
        //if agent
        if (isAgent) {
            if (currentProfile?.isConsultant) {
                this.props.agentVenueStore.getConsultantVenueRosterSimple(currentProfile?.id);
            } else {
                this.props.agentVenueStore.getAgentVenueSimple();
            }
        }
        //if staff
        else if (isStaff) {
            Promise.all([
                this.props.venueStore.getUserBookableVenues().then(venues => {
                    if (venues.length === 0) {
                        this.props.templateStore.hideLoadingScreen();
                        this.props.viewOnlyIsChangging(true);
                        // this.props.history.push("/staff");
                        // this.props.templateStore.openSnackbar("You don't have any permission to create booking.");
                    }
                })
            ])
        }
    }

    handleProviderChange = value => {
        this.props.bookingRequestStore.setBooking("agentId", value);
    };

    handleVenueChangePrivate = value => {
        this.props.bookingRequestStore.setBooking("venueId", undefined);
        this.props.bookingRequestStore.setBooking("independentVenueId", undefined);
        this.props.bookingRequestStore.setBooking("venueName", value);

        this.setTempTitle();
    };
    handleVenueChange = value => {
        const { currentProfile } = this.props.profileStore;
        var isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal
        var isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal
        var venues = [];
       
        this.props.templateStore.showLoadingScreen();
        this.props.profileStore.getProfileByIdSimple(value).then(result => {
          
            if (result) {
                venues.push(result);
                let venue = result
                this.props.bookingRequestStore.setBooking("venue", venue);
                if (venue.contactName != "" && venue.contactName != null && typeof venue.contactName != "undefined") {
                    this.props.bookingRequestStore.setBooking("venueContact", venue.contactName);
                } else if (venue.members) {
                    var venueContactPerson = venue.members.filter(x => x.booking || x.manage);
                    if (venueContactPerson && venueContactPerson[0]) {
                        this.props.bookingRequestStore.setBooking("venueContact", venueContactPerson[0].name);
                    }
                    else {
                        this.props.bookingRequestStore.setBooking("venueContact", "");
                    }
                }
                else {
                    this.props.bookingRequestStore.setBooking("venueContact", "");
                }
                this.props.bookingRequestStore.setBooking("venueName", venue.profileName);
                this.props.bookingRequestStore.setBooking("venueAddress", `${venue.street ? venue.street +", " : ""}${venue.city ? venue.city+", ": ""}${venue.state ? venue.state: ""}`);
                this.props.bookingRequestStore.setBooking("venuePhone", venue.contactPhone);
                this.props.bookingRequestStore.setBooking("venueContract", venue.termCondition);
    
                this.props.bookingRequestStore.setBooking("notes", venue.notes || "");
                this.props.bookingRequestStore.setBooking("requirements", venue.requirements || "");
            } else {
                this.props.bookingRequestStore.setBooking("venueName", "");
                this.props.bookingRequestStore.setBooking("venueContact", "");
                this.props.bookingRequestStore.setBooking("venueAddress", "");
                this.props.bookingRequestStore.setBooking("venuePhone", "");
                this.props.bookingRequestStore.setBooking("venueContract", undefined);
            }
            this.props.changeVenue(true)
            this.props.changeNote(true)
            this.props.changeProduction(true)
            this.agentSuggestions = [];
            this.props.bookingRequestStore.setBooking("venueId", value);
            this.setTempTitle();
            this.props.templateStore.hideLoadingScreen();
       
        })
       
       
    };

    setBookingToStore = venue => {
        if (venue) {
        }
    };
    handleSelectVenue = item => {
  
       

        this.setTempTitle();
        const { currentProfile } = this.props.profileStore;
        var venues = [];
       
        this.props.templateStore.showLoadingScreen();
        this.props.profileStore.getProfileByIdSimple(item.id).then(result => {
          
            if (result) {
                venues.push(result);
                let venue = result
                this.props.bookingRequestStore.setBooking("venue", venue);
                if (venue.contactName != "" && venue.contactName != null && typeof venue.contactName != "undefined") {
                    this.props.bookingRequestStore.setBooking("venueContact", venue.contactName);
                } else if (venue.members) {
                    var venueContactPerson = venue.members.filter(x => x.booking || x.manage);
                    if (venueContactPerson && venueContactPerson[0]) {
                        this.props.bookingRequestStore.setBooking("venueContact", venueContactPerson[0].name);
                    }
                    else {
                        this.props.bookingRequestStore.setBooking("venueContact", "");
                    }
                }
                else {
                    this.props.bookingRequestStore.setBooking("venueContact", "");
                }
                
                this.props.bookingRequestStore.setBooking("venueName", venue.profileName);
                this.props.bookingRequestStore.setBooking("venueAddress", `${venue.street ? venue.street +", " : ""}${venue.city ? venue.city+", ": ""}${venue.state ? venue.state: ""}`);
                this.props.bookingRequestStore.setBooking("venuePhone", venue.contactPhone);
                this.props.bookingRequestStore.setBooking("venueContract", venue.termCondition);
    
                this.props.bookingRequestStore.setBooking("notes", venue.notes || "");
                this.props.bookingRequestStore.setBooking("requirements", venue.requirements || "");
                
            } else {
                this.props.bookingRequestStore.setBooking("venueName", "");
                this.props.bookingRequestStore.setBooking("venueContact", "");
                this.props.bookingRequestStore.setBooking("venueAddress", "");
                this.props.bookingRequestStore.setBooking("venuePhone", "");
                this.props.bookingRequestStore.setBooking("venueContract", undefined);
            }
            this.props.changeVenue(true)
            this.props.changeNote(true)
            this.props.changeProduction(true)
            if (item.isIndependent) {
                this.props.bookingRequestStore.setBooking("venueId", undefined);
                this.props.bookingRequestStore.setBooking("independentVenueId", item.id);
            } else {
                this.props.bookingRequestStore.setBooking("venueId", item.id);
                this.props.bookingRequestStore.setBooking("independentVenueId", undefined);
            }
            this.setTempTitle();
            this.props.templateStore.hideLoadingScreen();
       
        })
    };
    setTempTitle = () => {
        const { actName, venueId, venueName } = this.props.bookingRequestStore.booking;
        const { currentProfile } = this.props.profileStore;
        var title = "";
        const isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal
        if (isAgent) {
            var agentVenue = this.props.agentVenueStore.agentVenues.filter(agentVenue => agentVenue.venue.id === venueId)[0];
            if (agentVenue) {
                let venue = agentVenue.venue;
                title = actName + "@" + venue.profileName;

            }
        } else if (isStaff) {
            var staffVenue = this.props.venueStore.venues.filter(data => data.id === venueId)[0];

            if (staffVenue) {
                let venue = staffVenue;
                title = actName + "@" + venue.profileName;
            }
        } else {
            title = (currentProfile ? currentProfile?.firstName + " " + currentProfile?.lastName : "") + "@" + venueName;
        }
        if (title && title != "") {
            this.props.bookingRequestStore.setBooking("tempTitle", title);
            this.props.bookingRequestStore.setBooking("title", title);
            this.props.bookingRequestStore.setTempBookingTitle();
        }

    };

    handleTitleChange = e => {
        this.props.bookingRequestStore.setBooking("title", e.target.value);
    };
    handleProviderChange = e => {
        this.props.bookingRequestStore.setBooking("provider", e.target.value);
    };
    handleVenueContactChange = e => {
        this.props.bookingRequestStore.setBooking("venueContact", e.target.value);
    };
    handleVenueAddressChange = e => {
        this.props.bookingRequestStore.setBooking("venueAddress", e.target.value);
    };

    handlePhoneChange = e => {
        this.props.bookingRequestStore.setBooking("venuePhone", e.target.value.replace(/\D/, ''));
    };

    handleTicketUrlChange = e => {
        this.props.bookingRequestStore.setBooking("ticketUrl", e.target.value);
    };

    handleSharePermissionChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("sharePermission", checked);
        if (!checked) {
            this.props.bookingRequestStore.setBooking("shareUpdate", checked);
        }
        this.setState({
            ...this.state
        });
    };

    handleShareUpdateChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("shareUpdate", checked);
        this.setState({
            ...this.state
        });
    };

    handlePurchaseOrderCodeChange = e => {
        this.props.bookingRequestStore.setBooking("purchaseOrderCode", e.target.value);
    };

    mapSuggestions = () => {
        const { currentProfile } = this.props.profileStore;
        var isAgent = false
        var isStaff = false
        var isMusician = true

        if (currentProfile) {
            isAgent = currentProfile?.type === ProfileType.Agent.ordinal
            isStaff = currentProfile?.type === ProfileType.Staff.ordinal
            isMusician = !(currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal)
        }
        if (isAgent) {
            let tempVenues = [];
            for (let i = 0; i < this.props.agentVenueStore.agentVenues.length; i++) {
                let agentVenue = this.props.agentVenueStore.agentVenues[i];
                if (agentVenue.venueId && agentVenue.venue) {
                    tempVenues.push({value: agentVenue.venueId, label: agentVenue.venue?.fullName})
                }
            }
            this.venueSuggestions = tempVenues;
        } else if (isStaff) {
            const { venues } = this.props.venueStore;
            if (venues && venues.length > 0) {
                this.venueSuggestions = venues.map(data => {
                    return {
                        value: data.id,
                        label: data.fullName
                    };
                });
            }
        }
    };

    render() {
        let { error, booking, tempBookingTitle } = this.props.bookingRequestStore;
        let {
            title,
            tempTitle,
            provider,
            venueId,
            venueContact,
            venueAddress,
            venuePhone,
            venue,
            status,
            venueName,
            bookingRequest,
            createdBy,
            ticketUrl,
            createdById,
            dateStart,
            act,
            bookingRequests
        } = booking;

        let { readOnly } = this.props;
        const { currentProfile } = this.props.profileStore;
        var isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        var isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal;
        this.mapSuggestions();
        var isMusician = true
        if (currentProfile) {
            isMusician = !(currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal)
        }
        venueAddress = venueAddress || "";
        venuePhone = venuePhone || "";
        // if (currentProfile && !venue && this.props.bookingRequestStore.venueId && this.props.bookingRequestStore.venueId != undefined && this.props.bookingRequestStore.venueId != "undefined") {
        //     this.handleVenueChange(parseInt(this.props.bookingRequestStore.venueId));
        // }

        let confirmedEditable =
            status !== BookingStatusType.Cancelled.ordinal &&
            status !== BookingStatusType.Completed.ordinal &&
            moment(dateStart).endOf("day") >= moment() &&
            (!createdById || createdById === currentProfile?.id || isStaff);
        
        let onLineup = false;
        if (booking.lineups && booking?.lineups?.length && booking.lineups[0] !== undefined){
            if (booking?.lineups?.find(x => x.profileId == currentProfile?.id)){
                onLineup = true;
            }
        }

        let showProvider = false
        if ((onLineup && booking.createdById !== currentProfile?.id) || (currentProfile?.id == act?.actAdminId) || (bookingRequests && bookingRequests.length > 0 && currentProfile?.id == bookingRequests[bookingRequests.length-1]?.act?.actAdminId)) {
            showProvider = true
        }

        const bookingRequestTitle = bookingRequest && bookingRequest.act ? `${bookingRequest.act.actName}@${venue.profileName}` : tempTitle;
        return (<div>
            {
                isMusician ? (
                    <Grid container spacing={8} >
                        <Grid item xs={12} lg={showProvider ? 0 : 6} className="gridBookingForm">
                            <TextField
                                label="Event Title"
                                value={title !== null && title !== undefined ? title : bookingRequest ? bookingRequestTitle : tempBookingTitle}
                                onChange={this.handleTitleChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                                error={error && error["title"]}
                                helperText={error && error["title"] ? error["title"] : ""}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Booking Creator"
                                value={createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : ``}
                                fullWidth
                                onChange={this.handleProviderChange}
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />
                        </Grid>
                        {showProvider ? 
                            <Grid item xs={12} lg={6} className="gridBookingForm">
                                <TextField
                                    label="Provider / Agency"
                                    value={provider}
                                    fullWidth
                                    onChange={this.handleProviderChange}
                                    InputProps={{
                                        readOnly: readOnly
                                    }}
                                />
                            </Grid>
                        : null}
                        <Grid item xs={12} className="gridBookingForm">
                            {readOnly ? (<TextField
                                label="Venue"
                                value={venue && venue.profileName ? venue.profileName : venueName}
                                onChange={this.handleVenueChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />) : null}

                            {(isMusician) && !readOnly ? (
                                <SearchVenueField
                                    placeholder="Search Venue"
                                    id="private-booking-venue"
                                    value={venueName}
                                    handleValueChange={this.handleVenueChangePrivate}
                                    handleSelect={this.handleSelectVenue}
                                    error={error && error["venue"]}
                                    height={40}
                                    helperText={error && error["venue"] ? error["venue"] : ""}
                                />
                            ) : null}

                            {(isStaff || isAgent) && !readOnly ? (
                                <SelectField
                                    id="bookingVenue"
                                    label="Venue"
                                    suggestions={this.venueSuggestions}
                                    onChange={this.handleVenueChange}
                                    value={venueId}
                                    placeholder="Select Venue"
                                    error={error && error["venue"]}
                                    helperText={error && error["venue"] ? error["venue"] : ""}
                                />
                            ) : null}
                        </Grid>

                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Venue Contact"
                                value={venueContact}
                                onChange={this.handleVenueContactChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Venue Address"
                                value={venueAddress}
                                onChange={this.handleVenueAddressChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />
                        </Grid>
                        {/* {currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal ? (
                    <Grid item xs={12} className="gridBookingForm">
                        <TextField label="Purchase Order" value={purchaseOrderCode} onChange={this.handlePurchaseOrderCodeChange} fullWidth />
                    </Grid>
                ) : null} */}
                    </Grid>) : (
                    <Grid container spacing={8}>
                        <Grid item xs={12} className="gridBookingForm">
                            <TextField
                                label="Event Title"
                                value={title !== null && title !== undefined ? title : bookingRequest ? bookingRequestTitle : tempBookingTitle}
                                onChange={this.handleTitleChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                                error={error && error["title"]}
                                helperText={error && error["title"] ? error["title"] : ""}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Booking Creator"
                                value={createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : ``}
                                fullWidth
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Provider / Agency"
                                value={provider}
                                fullWidth
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className="gridBookingForm">
                            {readOnly ? (<TextField
                                label="Venue"
                                value={venue && venue.profileName}
                                onChange={this.handleVenueChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />) : null}



                            {(isStaff || isAgent) && !readOnly ? (
                                <SelectField
                                    id="bookingVenue"
                                    label="Venue"
                                    suggestions={this.venueSuggestions}
                                    onChange={this.handleVenueChange}
                                    value={venueId}
                                    placeholder="Select Venue"
                                    error={error && error["venue"]}
                                    helperText={error && error["venue"] ? error["venue"] : ""}
                                />
                            ) : null}
                        </Grid>

                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Venue Contact"
                                value={venueContact}
                                onChange={this.handleVenueContactChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Venue Address"
                                value={venueAddress}
                                onChange={this.handleVenueAddressChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Venue Phone"
                                value={venuePhone}
                                onChange={this.handlePhoneChange}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridBookingForm">
                            <TextField
                                label="Ticket Url"
                                value={ticketUrl}
                                onChange={this.handleTicketUrlChange}
                                fullWidth
                                InputProps={{
                                    readOnly: !confirmedEditable
                                }}
                                placeholder="Example: https://www.eventbrite.com/"
                                error={error && error["ticketUrl"]}
                                helperText={error && error["ticketUrl"] ? error["ticketUrl"] : ""}
                            />
                        </Grid>
                        {/* {currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal ? (
                    <Grid item xs={12} className="gridBookingForm">
                        <TextField label="Purchase Order" value={purchaseOrderCode} onChange={this.handlePurchaseOrderCodeChange} fullWidth />
                    </Grid>
                ) : null} */}
                    </Grid>)}

        </div>
        )
    }
}

export default BookingEventDetails;
