import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import ResponsiveTable from "../../table/ResponsiveTable";

import { Button } from "@material-ui/core";
import ConfirmationModal from "../../modal/ConfirmationModal";
import TableRowProfile from "../../util/TableRowProfile";
import ConsultantEditModal from "./ConsultantEditModal";


@inject("agentConsultantStore", "agentVenueStore", "templateStore", "userInvitationStore")
@observer
class ConsultantTable extends Component {
    state = {
        deletedAgentConsultant: undefined,
        deleteConfirmationState: false,
        agentConsultant: undefined,
        deleteInvitationState: false,
        editModalState: false
    };

    columns = [
        { key: "consultant", label: "Consultant", width: "40%" },
        { key: "managedVenue", label: "Managed Venue", width: "20%" },
        { key: "status", label: "Status", width: "10%" },
        { key: "action", label: "", width: "30%" }
    ];

    rows = [];

    pendingRows = [];

    openRemoveConfirmation = agentConsultant =>
        this.setState({ ...this.state, deleteConfirmationState: true, deletedAgentConsultant: agentConsultant });
    closeRemoveConfirmation = () =>
        this.setState({
            ...this.state,
            deleteConfirmationState: false,
            deleteInvitationState: false,
            deletedAgentConsultant: undefined
        });

    openRemoveInvitationConfirmation = agentConsultant =>
        this.setState({ ...this.state, deleteInvitationState: true, deletedAgentConsultant: agentConsultant });

    openEditModal = agentConsultant => this.setState({ ...this.state, editModalState: true, agentConsultant: agentConsultant });
    closeEditModal = () => this.setState({ ...this.state, editModalState: false, agentConsultant: undefined });

    handleRemove = () => {
        this.props.agentConsultantStore.removeConsultant(this.state.deletedAgentConsultant.id).then(response => {
            this.props.templateStore.openSnackbar(
                `Consultant ${
                    this.state.deletedAgentConsultant && this.state.deletedAgentConsultant.consultant
                        ? this.state.deletedAgentConsultant.consultant.firstName+" "+this.state.deletedAgentConsultant.consultant.lastName
                        : "selected consultant"
                } has been removed.`
            );
            this.closeRemoveConfirmation();
        });
    };

    handleRemoveInvitation = () => {
        this.props.userInvitationStore.deleteInvitation(this.state.deletedAgentConsultant).then(response => {
            this.props.templateStore.openSnackbar(
                `Consultant ${this.state.deletedAgentConsultant.invitedFirstName} ${this.state.deletedAgentConsultant.invitedLastName} - ${
                    this.state.deletedAgentConsultant.invitedEmail
                } has been removed.`
            );
            this.closeRemoveConfirmation();
        });
    };

    mapInvitationToPendingRowData = () => {
        this.pendingRows = [];

        const { invitations } = this.props.userInvitationStore;
        const { agentConsultants } = this.props.agentConsultantStore;

        invitations.forEach(invitation => {
            let row = {};

            row.consultant = <TableRowProfile name={`${invitation.invitedFirstName} ${invitation.invitedLastName} - ${invitation.invitedEmail}`} />;

            row.status = "Invited";
            row.style = {
                backgroundColor: "#EEE"
            };

            if (invitation.venues) {
                var managedVenue = invitation.venues.map(venue => {
                    if (venue) {
                        return (
                            <Link to={`/profile/${venue.id}`} style={{ marginRight: 8 }}>
                                {" "}
                                {venue.profileName}{" "}
                            </Link>
                        );
                    }
                    return "";
                });

                row.managedVenue = managedVenue;
            }

            row.action = (
                <Button color="primary" size="small" onClick={() => this.openRemoveInvitationConfirmation(invitation)}>
                    Remove
                </Button>
            );

            if (!agentConsultants.find(x => x?.consultantEmail == invitation?.invitedEmail)){
                this.pendingRows.push(row);
            }

        });
        return Promise.resolve();
    };

    mapPendingAgentConsultantToRowData = () => {
        const { agentConsultants } = this.props.agentConsultantStore;
        agentConsultants.filter(x => !x.accepted).forEach(agentConsultant => {
            
            let rowData = {};

            rowData.consultant = (
                <TableRowProfile
                    profileId = { agentConsultant?.consultant ? agentConsultant.consultant.id : undefined }
                    avatarUrl = {agentConsultant?.consultant?.profileImage!=null?agentConsultant.consultant.profileImage.imageProfile:null}
                    name={agentConsultant?.consultant?.firstName + " " + agentConsultant?.consultant?.lastName+" - "+agentConsultant?.consultantEmail}
                />
            );

            rowData.status = "Pending";

            rowData.style = {
                backgroundColor: "#EEE"
            };

            if (agentConsultant.consultantVenues) {
                var managedVenue = agentConsultant.consultantVenues.map(consultantVenue => {
                    if (consultantVenue) {
                        return (
                            <Link to={`/profile/${consultantVenue.id}`} style={{ marginRight: 8 }}>
                                {" "}
                                {consultantVenue.profileName}{" "}
                            </Link>
                        );
                    }
                    return "";
                });

                rowData.managedVenue = managedVenue;
            }

            rowData.action = (
                <Fragment>
                    <Button color="primary" size="small" onClick={() => this.openEditModal(agentConsultant)}>
                        Edit
                    </Button>
                    <Button color="primary" size="small" onClick={() => this.openRemoveConfirmation(agentConsultant)}>
                        Remove
                    </Button>
                </Fragment>
            );

            this.pendingRows.push(rowData);
        });
    };

    mapAgentConsultantToRowData = () => {
        const { agentConsultants } = this.props.agentConsultantStore;
        this.rows = [];
        agentConsultants.filter(x => x.accepted).forEach(agentConsultant => {
            let rowData = {};

            rowData.consultant = (
                <TableRowProfile
                    profileId = { agentConsultant.consultant ? agentConsultant.consultant.id : undefined }
                    avatarUrl = {agentConsultant?.consultant?.profileImage!==null?agentConsultant?.consultant?.profileImage?.imageProfile:null}
                    name={agentConsultant ? agentConsultant?.consultant?.firstName + " " + agentConsultant?.consultant?.lastName : agentConsultant?.consultantEmail}
                />
            );

            rowData.status = "Accepted";
            
            // console.log(agentConsultant.consultantVenues)

            var managedVenue = agentConsultant.consultantVenues.map(consultantVenue => {
                if (consultantVenue) {
                    return (
                        <Link to={`/profile/${consultantVenue.id}`} style={{ marginRight: 8 }}>
                            {" "}
                            {consultantVenue.profileName}{" "}
                        </Link>
                    );
                }
                return "";
            });

            rowData.managedVenue = managedVenue;

            rowData.action = agentConsultant.Accepted ? (
                <Fragment>
                    <Button color="primary" size="small" onClick={() => this.openEditModal(agentConsultant)}>
                        Edit
                    </Button>
                    <Button color="primary" size="small">
                        Booking History
                    </Button>
                    <Button color="primary" size="small" onClick={() => this.openRemoveConfirmation(agentConsultant)}>
                        Remove
                    </Button>
                </Fragment>
            ) : (
                <Fragment>
                    <Button color="primary" size="small" onClick={() => this.openEditModal(agentConsultant)}>
                        Edit
                    </Button>
                    <Button color="primary" size="small" onClick={() => this.openRemoveConfirmation(agentConsultant)}>
                        Remove
                    </Button>
                </Fragment>
            );

            this.rows.push(rowData);
        });
    };

    render() {
        this.mapInvitationToPendingRowData().then(this.mapPendingAgentConsultantToRowData());
        this.mapAgentConsultantToRowData();
        const msgRemove = (name) => {
            return (
                    `<span style={{display:"inline", fontWeight:"bold"}}>${name}</span>`
            )
        }
        return (
            <Fragment>
                {this.pendingRows.length > 0 ? (
                    <Fragment>
                        <ResponsiveTable columns={this.columns} rows={this.pendingRows} showToolbar={true} title="Pending Consultant" />
                        <br />
                    </Fragment>
                ) : (
                    ""
                )}
                <ResponsiveTable columns={this.columns} rows={this.rows} />
                <ConfirmationModal
                    open={this.state.deleteInvitationState}
                    message={`<p style={{display:"inline"}}>Are you sure you would like to remove ${
                        this.state.deletedAgentConsultant 
                            ? msgRemove(this.state.deletedAgentConsultant.invitedFirstName+" "+this.state.deletedAgentConsultant.invitedLastName)
                            : "selected consultant"
                        } from your agency?</p>`}
                    closeHandler={this.closeRemoveConfirmation}
                    confirmationHandler={this.handleRemoveInvitation}
                    declineHandler={this.closeRemoveConfirmation}
                    confirmationLabel="Remove"
                />
                <ConfirmationModal
                    open={this.state.deleteConfirmationState}
                    message={`<p style={{display:"inline"}}>Are you sure you want to remove ${
                                this.state.deletedAgentConsultant && this.state.deletedAgentConsultant.consultant
                                    ? msgRemove(this.state.deletedAgentConsultant.consultant.firstName+" "+this.state.deletedAgentConsultant.consultant.lastName)
                                    : "selected consultant"
                                } from your agency? This action cannot be undone.</p>`}
                    closeHandler={this.closeRemoveConfirmation}
                    confirmationHandler={this.handleRemove}
                    declineHandler={this.closeRemoveConfirmation}
                    confirmationLabel="Remove"
                />
                <ConsultantEditModal open={this.state.editModalState} onClose={this.closeEditModal} agentConsultant={this.state.agentConsultant} />
            </Fragment>
        );
    }
}

export default ConsultantTable;
