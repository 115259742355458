import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TextField from "@material-ui/core/TextField";
import { inject, observer } from "mobx-react";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { BookingStatusType, LineupStatusType, ProfileType } from "../../types/enum";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { withRouter } from "react-router-dom";
import Loading from "../loadingSpinner/Loading";

import moment from "moment";
import "./FacebookSharePostDialog.css";
import agent from "../../agent";

import * as mobx from "mobx";
import image from "../../helper/image";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

@inject("facebookStore", "profileStore", "bookingRequestStore", "templateStore")
@withRouter
@observer
class FacebookSharePostDialog extends React.Component {
    state = {
        isLoading: false
    };

    handleAcceptBooking = e => {
        const { match } = this.props;
        const { profile } = this.props.profileStore;
        const bookingId = this.props.bookingId;
        this.setState({ isLoading: true });
        if (match.params && match.params.bookingRequestId && bookingId && this.props.profileType === "musician") {
            this.props.bookingRequestStore.acceptChangeActRequestWithProfileId(bookingId, profile.id).then(resp => {
                this.handleClose();
                if (this.props.callback) this.props.callback(resp);
            });
        } else if (bookingId && this.props.profileType === "lineup") {
            this.props.bookingRequestStore.saveLineupStatus(LineupStatusType.Accepted.ordinal).then(resp => {
                this.handleClose();
                if (this.props.lineupCallback) this.props.lineupCallback(resp);
            });
        } else {
            this.props.bookingRequestStore.saveStatus(profile.id, BookingStatusType.Accepted.ordinal, false).then(resp => {
                this.handleClose();
                if (this.props.callback) this.props.callback(resp);
            });
        }
    };

    handleAcknowledgeBooking = booking => {
        this.setState({ isLoading: true });
        return this.props.bookingRequestStore
            .venueStaffAcknowledgeGigs(booking.id)
            .then(resp => {
                if (this.props.callback) this.props.callback(resp);
            })
            .finally(() => {
                this.handleClose();
            });
    };

    handleMessageChange = e => {
        this.props.facebookStore.setPostMessage(e.target.value);
    };

    handleClickOpen = () => {
        this.props.facebookStore.openFacebookSharePostDialog();
    };

    handleClose = () => {
        this.setState({ isLoading: false });
        this.props.facebookStore.closeFacebookSharePostDialog();
    };

    handleShowImage = e => {
        this.props.facebookStore.toggleShowImage();
    };

    handlePostDateChange = date => {
        this.props.facebookStore.handlePostDateChange(date);
    };

    handleSubmit = e => {
        this.setState({ isLoading: true });
        const { currentProfile } = this.props.profileStore;
        const { showImage, facebookPostMessage, datePost, booking, facebookProfile } = this.props.facebookStore;
        const bookingModel = mobx.isObservable(booking) ? mobx.toJS(booking) : booking;
        const callback = this.props.profileType === "venue" ? this.handleAcknowledgeBooking : this.handleAcceptBooking;
        
        const postToFacebook = this.props.profileType === "musician" && booking.act?.actAdminId === currentProfile?.id ? agent.Facebook.postToFacebook : agent.Facebook.playerPostToFacebook;

        bookingModel.includeImage = showImage;
        bookingModel.status = BookingStatusType.Accepted.ordinal;
        bookingModel.facebookPostMessage = facebookPostMessage;
        bookingModel.facebookPostDate = datePost;
        bookingModel.facebookSenderType = this.props.profileType === "venue" ? bookingModel.venue?.type : bookingModel.act?.type;

        postToFacebook(facebookProfile.id, bookingModel).then(resp => {
            if (this.props.reloadData) {
                this.props.reloadData();
            }
            Promise.all([
                callback(bookingModel)]).then( () => {
                this.handleClose();
            });
        }, err => {
            console.log(err);
            this.props.templateStore.openSnackbar("Failed to share booking to Facebook. Please check your page settings.");
            this.handleClose();
        });
    };

    render() {
        const { facebookSharePostState, facebookPostMessage, showImage, datePost, facebookProfile } = this.props.facebookStore;
        const { booking } = this.props.facebookStore;
        const { currentProfile } = this.props.profileStore
        const { isLoading } = this.state;
        let isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

        if ((!booking.act || !booking.venue) && !isEntertainerBookings)  return null;
        const venueProfile = booking.venue;
        const actBackground = image.getBackgroundImage(booking.act);
        const venueBackground = image.getBackgroundImage(booking.venue);

        return (
            <Fragment>
                <Dialog
                    fullScreen={false}
                    open={facebookSharePostState}
                    onClose={this.handleClose}
                    maxWidth="sm"
                    fullWidth
                    aria-labelledby="responsive-dialog-title"
                >
                    <Loading showed={isLoading} />
                    <DialogTitle className="facebook-message-post-title">{"Post upcoming gig to Your Facebook page?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <p />
                            <p>Post your upcoming gig to your Facebook Page:</p>
                            <p>Page Name : {facebookProfile && facebookProfile.facebookPageName} </p>
                            {/* <p>Page Category : {facebookProfile && facebookProfile.facebookPageCategory} </p> */}
                            <p>
                                Include Image Background :
                                <Checkbox checked={showImage} onChange={this.handleShowImage} color="primary" />
                            </p>
                            <p>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        keyboard
                                        value={moment(datePost)}
                                        fullWidth
                                        margin="dense"
                                        onChange={this.handlePostDateChange}
                                        label="Date Post"
                                        minDate={!datePost || datePost >= moment().startOf("day") ? moment().startOf("day") : undefined}
                                        maxDate={moment(booking.dateStart)}
                                        format="DD-MM-YYYY"
                                        keyboardIcon={<EventIcon />}
                                        leftArrowIcon={<KeyboardArrowLeftIcon />}
                                        rightArrowIcon={<KeyboardArrowRightIcon />}
                                        style={{ marginTop: 0 }}
                                    />
                                </MuiPickersUtilsProvider>
                            </p>
                            <TextField
                                label="Message to post"
                                multiline
                                rowsMax="6"
                                fullWidth
                                value={facebookPostMessage}
                                onChange={this.handleMessageChange}
                                margin="normal"
                                className="facebook-message-post"
                            />
                        </DialogContentText>
                        {showImage && (
                            <Grid container spacing={8}>
                                <Grid item md={this.props.profileType === "venue" ? 12 : 6} xs={12} alignItems="center">
                                    <Typography variant="body1" gutterBottom align="center">
                                        Act Profile Background
                                    </Typography>
                                    {actBackground !== null && (
                                        <img src={actBackground} alt="Act Profile" width={270} height={180} className="facebook-back-image" />
                                    )}
                                </Grid>
                                {this.props.profileType !== "venue" && (
                                    <Grid item md={6} xs={12}>
                                        <Typography variant="body1" gutterBottom align="center">
                                            Venue Profile Background
                                        </Typography>
                                        {venueBackground !== null && (
                                            <img src={venueBackground} alt="Venue Profile" width={270} height={180} className="facebook-back-image" />
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary" autoFocus>
                            Share Post
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default withMobileDialog()(FacebookSharePostDialog);
