import React, { Component, Fragment } from "react";

import { Typography, Grid, Card, CardContent } from "@material-ui/core";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

class BookingClaimed extends Component {
    breadcrumb = [
        {
            name: "Dashboard",
            link: "/musician"
        },
        {
            name: "Booking",
            link: "/musician/gig"
        },
        {
            name: "Booking Station"
        }
    ];

    render() {
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title="Booking Station" />
                    <Card>
                        <CardContent>
                            <Grid container spacing={16}>
                                <Grid item xs={12} style={{ marginTop: 30 }}>
                                    <Typography variant="display1" align="center">
                                        Selected gig has been claimed by another act.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </MainContainer>
            </Fragment>
        );
    }
}

export default BookingClaimed;
