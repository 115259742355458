import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import AdminTableHeader from "./AdminTableHeader";
import green from "@material-ui/core/colors/green";
import { inject, observer } from "mobx-react";
import Loading from "../loadingSpinner/Loading";
import "./AdminTable.css";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { Menu, MenuItem } from "@material-ui/core";
import AdminChangeUserEmailModal from "./AdminChangeUserEmailModal";

const styles = theme => ({
    rootToolbar: {
        backgroundColor: "#ff0c9c",
        flexGrow: 1
    },
    title: {
        flex: 0.96
    },
    root: {
        width: "100%",
        boxShadow: "none"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    checked: {
        color: green[500],
        "& + $bar": {
            backgroundColor: green[500]
        }
    },
    pads: {
        paddingRight: theme.spacing.unit,
        minHeight: 48
    },
    bar: {}
});

@inject("userStore", "profileStore", "authStore")
@withRouter
@observer
class AdminTable extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        openDelete: false,
        openAdd: false,
        openRemove: false,
        order: "desc",
        orderBy: "createdAt",
        selected: [],
        page: 0,
        rowsPerPage: 10,
        isLoading: true,
        paginationTokenTemp: "",
        actionAnchor: [],
        selectedUserId: undefined,
        isOpenChangeUserEmailModal: false
    };



    componentDidMount() {
        this.props.userStore.getActiveUser().then(x => {
            this.setState({ isLoading: false });
        });
    }

    handleClickOpen = async (userActive) => {
        this.setState({ isLoading: true, actionAnchor: [] });
        await this.props.userStore.impersonateAccount(userActive).then(x => {
            this.setState({ isLoading: false });
            this.props.profileStore.loadProfiles().then(data => {
                switch (data[0].type) {
                    case 1:
                        this.props.history.push("/musician");
                        break;
                    case 2:
                        this.props.history.push("/agent");
                        break;
                    case 3:
                        this.props.history.push("/staff");
                        break;
                    case 6:
                        this.props.history.push("/production");
                        break;
                    case 7:
                        this.props.history.push("/dj");
                        break;
                    case 8:
                        this.props.history.push("/agent");
                        break;
                }

            })
        });
    };

    handleResendLink = async (userActive) => {
        this.setState({ isLoading: true, actionAnchor: [] });
        this.props.authStore.resendVerificationCode(userActive.email).then(() => this.setState({ isLoading: false }));
    };

    handleSetFreeNotFree = async (userActive, type) => {
        this.setState({ isLoading: true, actionAnchor: [] });
        this.props.authStore.setFreeNotFree(userActive,type).then(() => this.setState({ isLoading: false }));
    };

    handleSetAgent = async (userActive) => {
        this.setState({ isLoading: true, actionAnchor: [] });
        this.props.authStore.handleSetAgent(userActive).then(() => this.setState({ isLoading: false }));
    };
    

    handleSetProPlan = async (userActive, type) => {
        this.setState({ isLoading: true, actionAnchor: [] });
        this.props.authStore.setProPlan(userActive,type).then(() => this.setState({ isLoading: false }));
    };

    handleForgotPassword = async (userActive) => {
        this.setState({ isLoading: true, actionAnchor: [] });
        this.props.authStore.setEmail(userActive.email);
        this.props.authStore.forgotPassword().then(() => this.setState({ isLoading: false }));
    };

    handleOpenChangeUserEmailModal = async (userActive) => {
        this.setState({
            actionAnchor: [],
            selectedUserId: userActive.id,
            isOpenChangeUserEmailModal: true
        });
    };

    handleCloseChangeUserEmailModal = async () => {
        this.setState({
            isOpenChangeUserEmailModal: false
        });
    };

    handleChangeUserEmail = async (newEmail) => {
        this.setState({ isLoading: true, isOpenChangeUserEmailModal: false });
        this.props.authStore.changeUserEmail(this.state.selectedUserId, newEmail).then(() => this.setState({ isLoading: false }));
    };

    setActionAnchor = (element, userId) => {
        var tempAnchor = [];
        tempAnchor[userId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    handleChangePage = (event, page) => {
        this.setState({ isLoading: true });

        if (page > 0) {
            if (page > this.state.page) {
                if (this.state.paginationTokenTemp != "") {
                    this.props.userStore.paginationToken.push(this.state.paginationTokenTemp)
                }
                this.setState({ page });
                this.props.userStore.getPrevNextActiveUser("next").then(data => {
                    this.setState({ isLoading: false, paginationTokenTemp: data.paginationToken });
                });

            } else {
                var removed = this.props.userStore.paginationToken.splice(-1)
                this.setState({ page });
                this.props.userStore.getPrevNextActiveUser("prev").then(data => {
                    this.setState({ isLoading: false, paginationTokenTemp: removed[0] });
                });
            }

        } else {
            this.setState({ page });
            this.props.userStore.getActiveUser().then(x => {
                this.setState({ isLoading: false });
            });
        }

    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
        this.props.userStore.setLimitPage(event.target.value)

        this.props.userStore.getActiveUser().then(x => {
            this.setState({ isLoading: false });
        });
    };

    handleRequestSort = (event, property) => {
        const { activeUsers } = this.props.userStore;
        let data = [];
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        if (orderBy === "email") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.username < a.username ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.username < b.username ? -1 : 1));
        } else if (orderBy === "firstName") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.attributes[3].value < a.attributes[3].value ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.attributes[3].value < b.attributes[3].value ? -1 : 1));
        } else if (orderBy === "lastName") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.attributes[4].value < a.attributes[4].value ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.attributes[4].value < b.attributes[4].value ? -1 : 1));
        } else if (orderBy === "status") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.userStatus < a.userStatus ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.userStatus < b.userStatus ? -1 : 1));
        } else if (orderBy === "type") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.type < a.type ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.type < b.type ? -1 : 1));
        } else if (orderBy === "musician") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.musicianName < a.musicianName ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.musicianName < b.musicianName ? -1 : 1));
        } else if (orderBy === "agent") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.agentName < a.agentName ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.agentName < b.agentName ? -1 : 1));
        } else if (orderBy === "venue") {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b.venueName < a.venueName ? -1 : 1))
                    : activeUsers.sort((a, b) => (a.venueName < b.venueName ? -1 : 1));
        }
        else {
            data =
                order === "desc"
                    ? activeUsers.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                    : activeUsers.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        }

        this.props.userStore.updateTable(data);

        this.setState({ order, orderBy });
    };

    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, isLoading, actionAnchor } = this.state;
        const { activeUsers, totalUsers } = this.props.userStore;

        return (
            <div style={{ position: "relative" }}>
                <Loading showed={isLoading} />

                <div className={classes.rootToolbar}>
                    <Toolbar className={classes.pads}>
                        <div className={classes.title}>
                            <Typography variant="subheading">Users</Typography>
                        </div>
                    </Toolbar>
                </div>

                <div className={classes.tableWrapper}>
                    <Table className="admin-table">
                        <AdminTableHeader
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={activeUsers.length}
                        />
                        <TableBody>
                            {activeUsers.map(data => {
                                return (
                                    <TableRow tabIndex={-1}>
                                        <TableCell>
                                            <Typography >{data.email}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.firstName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.lastName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.userStatus === "FORCE_CHANGE_PASSWORD" || data.userStatus === "UNCONFIRMED" || data.userStatus === "RESET_REQUIRED" ? "NOT CONFRIMED" : data.userStatus}</Typography>
                                        </TableCell>
                                        {/* <TableCell>
                                            <Typography >{data.type}</Typography>
                                        </TableCell> */}
                                        <TableCell>
                                            <Typography >{data.musicianName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.agentName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.venueName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.privateHireName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography >{data.activateEditVenue ? <div style={{textAlign:"center", width:"100%"}}><CheckCircle style={{ texAlign:"center",color: "green"}} /></div> : null}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {/* <Tooltip title="Action"> */}
                                            <Button style={{ fontWeight: "bold" }} size="small" onClick={e => this.setActionAnchor(e.currentTarget, data.id)}>
                                                ACTION
                                            </Button>

                                            <Menu
                                                open={this.state.actionAnchor[data.id] != null}
                                                onClose={() => this.unsetActionAchnor(data.id)}
                                                anchorEl={this.state.actionAnchor[data.id]}
                                            >
                                                {data.userStatus === "CONFIRMED" &&
                                                    <Fragment>
                                                        <MenuItem onClick={() => this.handleClickOpen(data)}>Access</MenuItem>
                                                        <MenuItem onClick={() => this.handleForgotPassword(data)}>Forgot Password</MenuItem>
                                                    </Fragment>
                                                }
                                                {data.userStatus !== "CONFIRMED" &&
                                                    <MenuItem onClick={() => this.handleResendLink(data)}>Resend Link</MenuItem>
                                                }

                                                   {/* userData.musicianFreeUser = musicianFreeUser
                userData.agentFreeUser = agentFreeUser
                userData.venueFreeUser = venueFreeUser
                userData.privateHireFreeUser = privateHireFreeUser */}
                                                 <MenuItem onClick={() => this.handleSetFreeNotFree(data,1)}>{ data.musicianFreeUser ? "Set Musician Basic Plan" : "Set Musician Basic Plan Free"}</MenuItem>
                                                 <MenuItem onClick={() => this.handleSetProPlan(data,1)}>{ data.musicianProPlan ? "Set Musician Pro Plan" : "Set Musician Pro Plan Free"}</MenuItem>
                                                 <MenuItem onClick={() => this.handleSetFreeNotFree(data,2)}>{ data.agentFreeUser ? "Set Agent Basic Plan" : "Set Agent Basic Plan Free"}</MenuItem>
                                                 <MenuItem onClick={() => this.handleSetProPlan(data,2)}>{ data.agentProPlan ? "Set Agent Pro Plan" : "Set Agent Pro Plan Free"}</MenuItem>
                                                 <MenuItem onClick={() => this.handleSetFreeNotFree(data,3)}>{ data.venueFreeUser ? "Set Venue Basic Plan" : "Set Venue Basic Plan Free"}</MenuItem>
                                                 <MenuItem onClick={() => this.handleSetProPlan(data,3)}>{ data.venueProPlan ? "Set Venue Pro Plan" : "Set Venue Pro Plan Free"}</MenuItem>
                                                 <MenuItem onClick={() => this.handleSetFreeNotFree(data,9)}>{ data.privateHireFreeUser ? "Set Parties & Events Basic Plan" : "Set Parties & Events Basic Plan Free"}</MenuItem>
                                                 <MenuItem onClick={() => this.handleSetAgent(data)}>{ data.activateEditVenue ? "Agent Booking Edit Off" : "Agent Booking Edit On"}</MenuItem>
                                                 
                                                 
                                                <MenuItem onClick={() => this.handleOpenChangeUserEmailModal(data)}>Change Email</MenuItem>
                                            </Menu>

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    SelectProps={{ className: "transparent-select" }}
                                    count={totalUsers}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    <AdminChangeUserEmailModal open={this.state.isOpenChangeUserEmailModal} onClose={this.handleCloseChangeUserEmailModal} handleSendRequest={this.handleChangeUserEmail} />
                    {/* <LinearProgress variant="determinate" value={topSongs.length * 8.5} /> */}
                </div>
            </div>
        );
    }

}

export default withStyles(styles)(AdminTable);
