import React, { Component } from "react";
import { inject } from "mobx-react";
import { Grid, Button, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { ProfileType } from "../../types/enum";

@inject("userStore", "profileStore")
@withRouter
class GoToDashboard extends Component {
    handleGoToDashboard = e => {
        e.preventDefault();
        this.props.userStore.completeUser().then(user => {
            const { currentProfile } = this.props.profileStore;
            switch (currentProfile.type) {
                case ProfileType.Musician.ordinal:
                    this.props.history.push("/musician");
                    break;
                case ProfileType.Agent.ordinal:
                    this.props.history.push("/agent");
                    break;
                case ProfileType.Staff.ordinal:
                    this.props.history.push("/staff");
                    break;
                case ProfileType.Venue.ordinal:
                    this.props.history.push("/staff");
                    break;
                case ProfileType.Production.ordinal:
                    this.props.history.push("/production");
                    break;
                case ProfileType.PrivateHire.ordinal:
                    this.props.history.push("/privatehire/gig");
                    break;
                default:
                    this.props.history.push("/account/setting");
                    break;
            }
        });
    };

    render() {
        return (
            <Grid container spacing={24} justify="center">
                <Grid item md={6} xs={12} sm={12} className="type goToDashboard">
                    <Button focusRipple className="button-base" onClick={this.handleGoToDashboard}>
                        <Typography component="span" type="subheading" color="inherit">
                            <span className="title">Your profile is all set!</span>
                            <span className="subtitle">Lets go to your dashboard</span>
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default GoToDashboard;
