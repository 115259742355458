import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import logo from "../../img/hotgiggity-logo.png";

class PageNotFound extends Component {
    render() {
        return (
            <React.Fragment>
                <img src={logo} alt="Logo" style={{ position: "absolute", top: 16, left: 16, width: 150 }} />

                <Hidden smDown>
                    <Grid container className="error-404-container" spacing={0}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="display4" className="error-404-big">
                                404
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="display2">
                                Page Not Found
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="display1">
                                Sorry we can't seem to find the page you're looking for.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="headline">
                                There may be a misspelling in the URL entered, or the page you are looking for may no longer exist.
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid container className="error-404-container" spacing={0}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="display4" className="error-404">
                                404
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="headline">
                                Page Not Found
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="title">
                                Sorry we can't seem to find the page you're looking for.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography gutterBottom component="p" variant="subheading">
                                There may be a misspelling in the URL entered, or the page you are looking for may no longer exist.
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
            </React.Fragment>
        );
    }
}
export default PageNotFound;
