import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";
import { DialogContent, Card, Grid, InputLabel, DialogActions, Button } from "@material-ui/core";
import TableRowProfile from "../../util/TableRowProfile";
import ResponsiveTable from "../../table/ResponsiveTable";

@inject("agentVenueStore", "templateStore", "agentConsultantStore")
@observer
class ConsultantEditModal extends Component {
    agentVenueColumns = [{ key: "venue", label: "Venue" }];

    agentVenueRows = [];

    componentWillReceiveProps(nextProps) {
        const { open, agentConsultant } = nextProps;
        if (open) {
            this.props.templateStore.showLoadingScreen();
            this.props.agentVenueStore.getAgentVenue().then(() => {
                this.props.templateStore.hideLoadingScreen();
            });
            if (agentConsultant) {
                this.props.agentConsultantStore.setManagedVenueIds(
                    agentConsultant.consultantVenues.map(x => {
                        return x.agentVenueId;
                    })
                );
            }
        }
    }

    mapAgentVenueToRowData = () => {
        const { agentVenues } = this.props.agentVenueStore;
        const { agentConsultant } = this.props;
        this.agentVenueRows = [];
        if (agentVenues) {
            agentVenues.forEach(agentVenue => {
                var rowData = {};
                rowData.id = agentVenue.id;
                rowData.venue = (
                    <TableRowProfile
                        avatarUrl = {agentVenue?.venue?.profileImage?agentVenue?.venue?.profileImage?.url200:undefined}
                        profileId = { agentVenue.venueId }
                        name={agentVenue?.venue?.fullName}
                    />
                );
                rowData.data = agentVenue;
                rowData.checked =
                    agentConsultant &&
                    agentConsultant.consultantVenues &&
                    agentConsultant.consultantVenues.filter(x => x.id === agentVenue.venue.id).length > 0;
                this.agentVenueRows.push(Object.assign({}, rowData));
            });
        }
    };

    getCheckedRows = rows => {
        var ids = [];
        rows.forEach(row => {
            if (row && row.data && row.data.id) {
                ids.push(row.data.id);
            }
        });
        this.props.agentConsultantStore.setManagedVenueIds(ids);
    };

    handleEdit = () => {
        this.props.agentConsultantStore.submitEditConsultant(this.props.agentConsultant).then(response => {
            var consultantName = "";
            if (response[0].consultant) {
                consultantName = `${response[0].consultant.firstName} ${response[0].consultant.lastName}`;
            } else {
                consultantName = response[0].ConsultantEmail;
            }

            this.props.templateStore.openSnackbar(`Managed venue for ${consultantName} has been updated.`);
            this.props.onClose();
        });
    };

    render() {
        const { open, onClose, agentConsultant } = this.props;
        var modalTitle = "Edit Consultant";
        if (agentConsultant) {
            var { consultant, consultantEmail } = agentConsultant;
            modalTitle = consultant ? `Edit Consultant - ${consultant.firstName} ${consultant.lastName}` : `Edit Consultant ${consultantEmail}`;
        }
        this.mapAgentVenueToRowData();
        return agentConsultant ? (
            <ModalContainer title={modalTitle} open={open} onClose={onClose} maxWidth="sm">
                <DialogContent>
                    <Grid container spacing={16} direction="column">
                        <Grid item xs={12}>
                            {agentConsultant ? (
                                agentConsultant.consultant ? (
                                    <Card>
                                        <TableRowProfile
                                            profileId = { agentConsultant.consultant.id }
                                            avatarUrl = {agentConsultant.consultant.profileImage?agentConsultant.consultant.profileImage.imageProfile:null}
                                            name={`${agentConsultant.consultant.firstName} ${agentConsultant.consultant.lastName}`}
                                        />
                                    </Card>
                                ) : (
                                    <Card>
                                        <TableRowProfile name={agentConsultant.consultantEmail} />
                                    </Card>
                                )
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Managed Venue</InputLabel>
                            <ResponsiveTable
                                columns={this.agentVenueColumns}
                                rows={this.agentVenueRows}
                                selectable={true}
                                getCheckedRows={this.getCheckedRows}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                    <Button color="primary" disabled={!agentConsultant} onClick={this.handleEdit}>
                        Save
                    </Button>
                </DialogActions>
            </ModalContainer>
        ) : (
            ""
        );
    }
}

export default ConsultantEditModal;
