import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import "./ClientContract.css";
import Parser from 'html-react-parser';

class ClientContract extends Component {
    render() {
        const data = this.props.data;
        return (
            <Grid>
                <table>
                    <tbody><tr>
                        <td className="header-left-col tax-invoice">
                            <b>Client Contract</b>
                        </td>
                        <td className="header-right-col">
                            <table>
                                <tbody><tr>
                                    <td className="logo-container">
                                        <img className="avatar" src={data.profileImageUrl} />
                                    </td>
                                </tr>
                                </tbody></table>
                        </td>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table style={{ marginTop: '30px' }}>
                    <tbody><tr>
                        <td className="header-left-col">This contact is provided to {data.clientFirstName} by {data.bookingCreatorFirstName} for their act, {data.actName}, to perform at the following booking:</td>
                    </tr>
                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Event Details:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Event name: {data.eventName}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Event Date: {data.eventDate}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Event Start Time: {data.eventStartTime}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Event End Time: {data.eventEndTime}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Venue: {data.venueName}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Address: {data.venueAddress}</td>
                        </tr>
                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Fee Details:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">The agreed fee for this performance is as follows:</td>
                        </tr>
                        <tr><td style={{ height: '20px' }}>&nbsp;</td><td /></tr>
                        {data.depositAmount && Number(data.depositAmount.replace(/[^0-9\.]+/g, "")) > 0 && 
                            <tr>
                                <td className="header-left-col">Deposit: {data.depositAmount} which is due on {data.depositDueDate}</td>
                            </tr>
                        } 
                        <tr>
                            <td className="header-left-col">Balance: {data.balanceAmount} which is due on {data.balanceDueDate}</td>
                        </tr>
                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Contact Details:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Phone: {data.bookingCreatorPhone}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Email: {data.bookingCreatorEmail}</td>
                        </tr>
                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Terms and Conditions:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">The terms and conditions governing this booking are as follows:</td>
                        </tr>
                        <tr><td style={{ height: '20px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col">{data.clientContract ? Parser(data.clientContract) : ""}</td>
                        </tr>
                    </tbody></table>
            </Grid>
        );
    }
}

export default ClientContract;
