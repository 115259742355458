import { observable, action } from "mobx";
import moment from "moment";

class StaffFilterStore {
    @observable filterType = "monthly";
    @observable startDate = moment().startOf("month");
    @observable endDate = moment().endOf("month");

    @observable organisationId = undefined;
    @observable stateId = undefined;
    @observable regionId = undefined;
    @observable venueId = undefined;
    @observable creatorId = undefined;
    @observable organisationFilter = 0;
    @observable stateFilter = 0;
    @observable regionFilter = 0;
    @observable venueFilter = 0;
    @observable actFilter = 0;

    @action
    setCreatorFilter(creatorId) {
        this.creatorId = creatorId;
    }
    @action
    resetFilterData() {
        // this.filterType = "month";
        // this.startDate = moment().startOf("month");
        // this.endDate = moment().endOf("month");
        // this.organisationId = undefined;
        // this.stateId = undefined;
        // this.regionId = undefined;
        // this.venueId = undefined;
    }

    @action
    clearDateRangeFilter() {
        this.filterType = "custom";
        this.startDate = undefined;
        this.endDate = undefined;
    }

    @action
    setFilterType(value) {
        this.filterType = value;
        switch (this.filterType) {
            case "all":
                this.startDate = undefined;
                this.endDate = undefined;
                break;
            case "today":
                this.startDate = moment().startOf("day");
                this.endDate = moment().endOf("day");
                break;
            case "lastweek":
                this.startDate = moment().subtract(1, 'weeks').startOf("isoWeek");
                this.endDate = moment().subtract(1, 'weeks').endOf("isoWeek").subtract(1,"days");
                break;
            case "week":
                this.startDate = moment().startOf("isoWeek");
                this.endDate = moment().endOf("isoWeek").subtract(1,"days");
                break;
            case "month":
                this.startDate = moment().startOf("month");
                this.endDate = moment().endOf("month");
                break;
            case "monthly":
                this.startDate = moment().startOf("month");
                this.endDate = moment().endOf("month");
                break;
            case "nextMonth":
                this.startDate = moment()
                    .add(1, "M")
                    .startOf("month");
                this.endDate = moment()
                    .add(1, "M")
                    .endOf("month");
                break;
            default:
                break;
        }
    }

    @action
    setStartDate(value) {
        this.startDate = value;
    }

    @action
    setEndDate(value) {
        this.endDate = value;
    }

    @action
    setOrganisationFilter(orgId) {
        this.organisationFilter = orgId;
    }

    @action
    setStateFilter(stateId) {
        this.stateFilter = stateId; // !== 0 ? stateId : undefined;
    }

    @action
    setRegionFilter(regionId) {
        this.regionFilter = regionId; //!== 0 ? regionId : undefined;
    }

    @action
    setVenueFilter(venueId) {
        this.venueFilter = venueId; // !== 0 ? venueId : undefined;
    }

    @action
    setActFilter(actId) {
        this.actFilter = actId;
    }
}

export default new StaffFilterStore();
