import React, { Component } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { TextField, MenuItem, ListItemText } from "@material-ui/core";

class GooglePlaceAutoComplete extends Component {
    getCountry = addressComponent => {
        var country = addressComponent.filter(x => x.types && x.types.filter(y => y === "country")[0])[0];
        return country ? country.short_name : "";
    };

    getState = addressComponent => {
        var state = addressComponent.filter(x => x.types && x.types.filter(y => y === "administrative_area_level_1")[0])[0];
        return {
            target: {
                value: state ? state.short_name : ""
            }
        };
    };

    getSuburb = addressComponent => {
        var suburb = addressComponent.filter(x => x.types && x.types.filter(y => y === "locality" || y === "administrative_area_level_2")[0])[0];
        return {
            target: {
                value: suburb ? suburb.long_name : ""
            }
        };
    };

    getStreetAddress = addressComponent => {
        var streetNumber = addressComponent.filter(x => x.types && x.types.filter(y => y === "street_number")[0])[0];
        var street = addressComponent.filter(x => x.types && x.types.filter(y => y === "route")[0])[0];
        if (!street) {
            street = addressComponent.filter(x => x.types && x.types.filter(y => y === "subpremise")[0])[0];
        }
        return {
            target: {
                value: `${streetNumber ? `${streetNumber.long_name}, ` : ""} ${street ? street.long_name : ""}`
            }
        };
    };

    getPostal = addressComponent => {
        var postalCode = addressComponent.filter(x => x.types && x.types.filter(y => y === "postal_code")[0])[0];
        return {
            target: {
                value: postalCode ? postalCode.long_name : ""
            }
        };
    };

    handleSelect = address => {
        this.props.handleChange(address);
        geocodeByAddress(address)
            .then(results => {
                return getLatLng(results[0]).then(latlng => {
                    this.props.handleChangeLatLng(latlng);
                    if (results && results[0] && results[0].address_components) {
                        if(this.props.handleChangeStreet){
                            this.props.handleChangeStreet(this.getStreetAddress(results[0].address_components));
                        }
                        if(this.props.handleChangeCity){
                            this.props.handleChangeCity(this.getSuburb(results[0].address_components));
                        }
                        if(this.props.handleChangePostal){
                            this.props.handleChangePostal(this.getPostal(results[0].address_components));
                        }
                        if(this.props.handleChangeState){
                            this.props.handleChangeState(this.getState(results[0].address_components));
                        }
                        if(this.props.handleChangeCountry){
                            this.props.handleChangeCountry(this.getCountry(results[0].address_components));
                        }
                    }
                });
            })
            .catch(error => console.error("Error", error));
    };

    renderSuggestion = (suggestion, index) => {
        return (
            <MenuItem
                key={index}
                selected={suggestion.active}
                component="div"
                style={{
                    zIndex: 9999,
                    backgroundColor: suggestion.active ? "#ff0c9c" : "#ffffff",
                    cursor: "pointer"
                }}
            >
                <ListItemText primary={suggestion.description} />
            </MenuItem>
        );
    };

    state = {
        gmapsLoaded: false,
    }
    
    initMap = () => {
        this.setState({
            gmapsLoaded: true,
        })
    }

    componentDidMount () {
        window.initMap = this.initMap
        const gmapScriptEl = document.createElement(`script`)
        gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB7f0cc3w1EyMBqq0kn_fIvf6XPKzi8VWE&libraries=places&callback=initMap`
        document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
      }

    render() {
        return (
            <div>
                {this.state.gmapsLoaded && (
                 <PlacesAutocomplete value={this.props.value} onChange={this.props.handleChange} onSelect={this.handleSelect}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{position: "relative", width: "100%", ...this.props.style }}>
                        <TextField
                            fullWidth
                            label={this.props.label}
                            inputProps={{
                                ...getInputProps({
                                    placeholder: this.props.placeholder,
                                    className: "location-search-input"
                                })
                            }}
                        />
                        <div
                            style={{
                                position: "absolute",
                                zIndex: "9999",
                                width: "100%",
                                maxHeight: "300px",
                                overflowY: "auto",
                                border: suggestions.length > 0 ? "1px solid" : undefined
                            }}
                        >
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style
                                        })}
                                    >
                                        {this.renderSuggestion(suggestion, index)}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
                )}

            </div>
        );
    }
}

export default GooglePlaceAutoComplete;
