import { observable, action, runInAction } from "mobx";
import _ from "lodash";
import agent from "../agent";

import userStore from "./userStore";
import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";

import blankProfile from "../img/hotgig-loading.png";
import profileStore from "./profileStore";
import authStore from "./authStore"
import { Storage } from "aws-amplify";
import CacheHelper from "../helper/cache";
import { ProfileType } from "../types/enum";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class RosterStore {
    @observable openInviteMusician = false;
    @observable openSnackbar = false;
    @observable snackbarMessage = "";
    @observable errors = undefined;
    @observable type = 0;
    @observable actId = undefined;
    @observable instrumentList = [];

    @observable invitedEmail = "";
    @observable invitedName = "";
    @observable actInstruments = [];
    @observable invitedInstrument = null;
    @observable invitedproduction = null;

    @observable instrumentErrors = undefined;
    @observable instrumentRosterErrors = undefined;

    @action
    resetErrors = () => {
        this.instrumentErrors = undefined;
        this.instrumentRosterErrors = undefined;
    };

    @action
    setActId(actId) {
        this.actId = actId;
    }

    @action
    setInstrumentList(newList) {
        this.instrumentList = newList;
    }

    @action
    reset() {
        this.actInstruments = [];
        this.openInviteMusician = false;
        this.openSnackbar = false;
        this.snackbarMessage = "";
        this.errors = undefined;
        this.type = 0;
        this.actId = undefined;
        this.instrumentList = [];
        this.invitedEmail = "";
        this.invitedName = "";
        this.invitedInstrument = null;
    }

    @action
    getActRoster(actId) {
        return agent.ActRoster.getActRoster(actId).then(result => {
            this.actInstruments = result;
            return result;
        })
    }

    @action
    getRosterByMusicianProfileId(profileId) {
        return agent.ActRoster.getRosterByProfileId(profileId).then(result => {
            return result
        })
    }

    @action
    getInstruments() {
        return agent.Instrument.getAll().then(response => {
            return response;
        });
    }

    @action
    loadInitialData(actId) {
        this.reset();
        const { id, lineUpCount } = generalProfileStore;
        const { currentProfile } = profileStore;
        // templateStore.showLoadingScreen();

        actId = actId ? actId : id;

        if (_.isEmpty(this.instrumentList)) {
            return agent.Instrument.getAll()
                .then(response => {
                    this.setInstrumentList(response);
                })
                .finally(() => {
                    if (actId) {
                        this.actId = actId;
                        return agent.ActRoster.getActRoster(actId).then(
                            action(async responseAct => {
                                if (responseAct.length === 0) {
                                    for (var i = 0; i < lineUpCount; i++) {
                                        if (i === 0) {
                                            if (currentProfile?.type === ProfileType.Musician.ordinal) {
                                                var roster = {
                                                    profile: currentProfile,
                                                    profileId: currentProfile.id
                                                };
                                                this.actInstruments.push({
                                                    name: "",
                                                    rosters: [roster],
                                                    id: null,
                                                    instruments: null,
                                                    key: this.actInstruments.length,
                                                    dividendAmount: 0,
                                                    dividendType: 1
                                                });
                                            } else {
                                                this.actInstruments.push({
                                                    name: "",
                                                    rosters: [],
                                                    id: null,
                                                    instruments: null,
                                                    key: this.actInstruments.length,
                                                    dividendAmount: 0,
                                                    dividendType: 0
                                                });
                                            }
                                        } else {
                                            this.actInstruments.push({
                                                name: "",
                                                rosters: [],
                                                id: null,
                                                instruments: null,
                                                key: this.actInstruments.length,
                                                dividendAmount: 0,
                                                dividendType: currentProfile?.type === ProfileType.Musician.ordinal ? 1 : 0
                                            });
                                        }
                                    }
                                } else {
                                    let existedDividendType = 1;
                                    this.actInstruments=[];
                                    for (var idx = 0; idx < responseAct.length; idx++) {
                                        var data = responseAct[idx];
                                        existedDividendType = data.dividendType;
                                        if (data.instruments.length > 0) {
                                            let insts = data.instruments.split(",");
                                            let instrumentName = "";
                                            insts.forEach(inst => {
                                                var filteredInstrument = this.instrumentList.filter(x => x.id === parseInt(inst, 10));
                                                if (filteredInstrument.length > 0) {
                                                    instrumentName += filteredInstrument[0].name + ", ";
                                                }
                                            });

                                            data.name = instrumentName.substring(0, instrumentName.length - 2);
                                        } else {
                                            data.name = "";
                                        }

                                        for (var i = 0; i < data.rosters.length; i++) {
                                            var roster = data.rosters[i];
                                            if (roster.profile && roster.profile.profileImage) {
                                                roster.profile.profileImage.url200 = await CacheHelper.getImgLink(roster.profile.profileImage.url200);
                                                // roster.profile.profileImage.url = `${API_ROOT}/image/${roster.profile.profileImage.id}`;
                                            } else {
                                                roster.profile = {...roster.profile, profileImage: null}
                                            }

                                            if (roster.profile && roster.profile.backgroundImages && roster.profile.backgroundImages.length > 0) {
                                                for (var j = 0; j < roster.profile.backgroundImages.length; j++) {
                                                    roster.profile.backgroundImages[j].url = await CacheHelper.getImgLink(roster.profile.backgroundImages[j].urlOriginal);
                                                    roster.profile.backgroundImages[j].url1920 = await CacheHelper.getImgLink(roster.profile.backgroundImages[j].url1920);
                                                    roster.profile.backgroundImages[j].url960 = await CacheHelper.getImgLink(roster.profile.backgroundImages[j].url960);
                                                }
                                                // roster.profile.backgroundImages = roster.profile.backgroundImages.slice().map(bg => {
                                                //     return { ...bg, url: `${API_ROOT}/image/background/${bg.id}` };
                                                // });
                                            }
                                        }
                                        data.key = idx;
                                        this.setActInstruments(data);
                                    }


                                    // this.actInstruments = responseAct.map( (data, index) => {
                                    //     existedDividendType = data.dividendType;
                                    //     if (data.instruments.length > 0) {
                                    //         let insts = data.instruments.split(",");
                                    //         let instrumentName = "";
                                    //         insts.forEach(inst => {
                                    //             var filteredInstrument = this.instrumentList.filter(x => x.id === parseInt(inst, 10));
                                    //             if (filteredInstrument.length > 0) {
                                    //                 instrumentName += filteredInstrument[0].name + ", ";
                                    //             }
                                    //         });

                                    //         data.name = instrumentName.substring(0, instrumentName.length - 2);
                                    //     } else {
                                    //         data.name = "";
                                    //     }

                                    //     for(var i = 0; i<data.rosters.length ; i++){
                                    //         var roster = data.rosters[i];
                                    //         console.log(roster);
                                    //         if (roster.profile && roster.profile.profileImage) {
                                    //             roster.profile.profileImage.url = await Storage.get(profile.profileImage.url200);
                                    //             // roster.profile.profileImage.url = `${API_ROOT}/image/${roster.profile.profileImage.id}`;
                                    //         } else {
                                    //             roster.profile = {
                                    //                 ...roster.profile,
                                    //                 profileImage: {
                                    //                     url: blankProfile
                                    //                 }
                                    //             };
                                    //         }

                                    //         if (roster.profile && roster.profile.backgroundImages && roster.profile.backgroundImages.length > 0) {
                                    //             for (var j = 0; j < profile.backgroundImages.length; j++) {
                                    //                 profile.backgroundImages[ji].url = await Storage.get(profile.backgroundImages[j].urlOriginal);
                                    //                 profile.backgroundImages[j].url1920 = await Storage.get(profile.backgroundImages[j].url1920);
                                    //                 profile.backgroundImages[j].url960 = await Storage.get(profile.backgroundImages[j].url960);
                                    //             }
                                    //             // roster.profile.backgroundImages = roster.profile.backgroundImages.slice().map(bg => {
                                    //             //     return { ...bg, url: `${API_ROOT}/image/background/${bg.id}` };
                                    //             // });
                                    //         }

                                    //         return roster;
                                    //     }

                                    //     // data.rosters.map(async roster => {
                                    //     //     console.log(roster);
                                    //     //     if (roster.profile && roster.profile.profileImage) {
                                    //     //         roster.profile.profileImage.url = await Storage.get(profile.profileImage.url200);
                                    //     //         // roster.profile.profileImage.url = `${API_ROOT}/image/${roster.profile.profileImage.id}`;
                                    //     //     } else {
                                    //     //         roster.profile = {
                                    //     //             ...roster.profile,
                                    //     //             profileImage: {
                                    //     //                 url: blankProfile
                                    //     //             }
                                    //     //         };
                                    //     //     }

                                    //     //     if (roster.profile && roster.profile.backgroundImages && roster.profile.backgroundImages.length > 0) {
                                    //     //         roster.profile.backgroundImages = roster.profile.backgroundImages.slice().map(bg => {
                                    //     //             return { ...bg, url: `${API_ROOT}/image/background/${bg.id}` };
                                    //     //         });
                                    //     //     }

                                    //     //     return roster;
                                    //     // });

                                    //     data.key = index;

                                    //     return data;
                                    // });

                                    // if (lineUpCount > responseAct.length) {
                                    //     for (var j = responseAct.length; j < lineUpCount; j++) {
                                    //         this.actInstruments.push({
                                    //             name: "",
                                    //             rosters: [],
                                    //             id: null,
                                    //             instruments: null,
                                    //             key: j,
                                    //             dividendType: existedDividendType,
                                    //             dividendAmount: existedDividendType === 0 ? this.getDefaultRosterPercentage() : 0
                                    //         });
                                    //     }
                                    // }
                                }
                                templateStore.hideLoadingScreen();
                                return this.actInstruments;
                            })
                        );
                    }
                });
        }
    }
    @action.bound
    setActInstruments(data) {
        this.actInstruments.push(data);
    }

    @action
    addActInstrument() {
        this.actInstruments.push({
            name: "",
            rosters: [],
            id: null,
            instruments: null,
            key: this.actInstruments.length,
            dividendType: this.actInstruments[0] ? this.actInstruments[0].dividendType : 1,
            dividendAmount: this.actInstruments[0] && this.actInstruments[0].dividendType === 0 ? this.getDefaultRosterPercentage(true) : 0,
            deleted: false
        });
        var actNotDeleted = this.actInstruments.filter(x => !x.deleted)
        for(let i = 0; i< actNotDeleted.length; i++) {
            actNotDeleted[i].dividendAmount = 100 / actNotDeleted.length
        }
    }

    @action
    setInstrumentForActInstrument(instrument, selectedInstrument) {
        _.map(this.actInstruments, (actInstrument, index) => {
            if (_.isEqual(actInstrument, instrument)) {
                actInstrument.instruments = selectedInstrument;

                let insts = selectedInstrument.split(",");
                let instrumentName = "";
                _.each(insts, inst => {
                    var filteredInstrument = _.filter(this.instrumentList, ["id", _.toInteger(inst)]);
                    if (!_.isEmpty(filteredInstrument)) {
                        instrumentName += filteredInstrument[0].name + ", ";
                    }
                });
                actInstrument.name = instrumentName.substring(0, instrumentName.length - 2);

                if (_.isEmpty(actInstrument.name)) {
                    actInstrument.name = "";
                }
            }
        });
    }

    @action
    setInvitedName(name) {
        this.invitedName = name;
    }

    @action
    setInvitedEmail(email) { 
        this.invitedEmail = email;
        this.errors = {email: ""} 
    }

    @action
    validateEmail(email) {
        if(this.errors === undefined || this.errors === 'undefined') this.errors = {email:""}
        if(this.invitedEmail?.length === 0) return this.errors = {email:""}
        /* eslint-disable no-useless-escape*/
        var email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        /* eslint-enable no-useless-escape*/
        if (!email_regex.test(this.invitedEmail)) {
            this.errors = [];
            this.errors["email"] = "Invalid Email";
        } else {
            this.errors.email = undefined;
        }
    }

    @action
    updateRosterDividendAmount(value, index) {
        if (isNaN(value)) {
            value = 0;
        }

        var maxValue = 100;
        var foundIndex = false;
        var allow = true;
        for (let i = 0; i < this.actInstruments.length && !foundIndex && allow; i++) {
            if (i === index - 1) {
                foundIndex = true;
                if (this.actInstruments[i].dividendType === 0 && !this.actInstruments[i].deleted) {
                    if (maxValue - value >= 0) {
                        this.actInstruments[i].dividendAmount = value;
                        maxValue -= value
                    } else {
                        allow = false;
                    }
                }
                else {
                    this.actInstruments[i].dividendAmount = value;
                }
            } else {
                if (this.actInstruments[i].dividendType === 0) {
                    maxValue -= this.actInstruments[i].dividendAmount;
                }
            }
        }

        if (!allow) {
            return allow;
        }

        var divider = this.actInstruments.filter(act => !act.deleted).length - index;
        for (let i = index; i < this.actInstruments.length; i++) {
            if (this.actInstruments[i].dividendType === 0) {
                let tempValue = maxValue > 0 && divider > 0 ? (maxValue / divider) : 0
                this.actInstruments[i].dividendAmount = isNaN(tempValue) ? 0 : tempValue;
            }
        }

        this.actInstruments = this.actInstruments.map(x => x);

        return allow;
    }

    @action
    updateRosterDividendType(value) {
        for (let i = 0; i < this.actInstruments.length; i++) {
            this.actInstruments[i].dividendType = value;
            this.actInstruments[i].dividendAmount = undefined;
        }
        if (value === 0) {
            for (let i = 0; i < this.actInstruments.length; i++) {
                this.actInstruments[i].dividendAmount = this.getDefaultRosterPercentage();
            }
        }
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    removeInstrument(deletedInstrument) {
        this.actInstruments = this.actInstruments.map(actInstrument => {
            if (_.isEqual(actInstrument, deletedInstrument)) {
                actInstrument.deleted = true;
                actInstrument.rosters = [];
                actInstrument.dividendAmount = 0;
            }
            return actInstrument;
        });
        var actNotDeleted = this.actInstruments.filter(x => !x.deleted)
        for(let i = 0; i< actNotDeleted.length; i++) {
            actNotDeleted[i].dividendAmount = 100 / actNotDeleted.length
        }
    }

    @action
    addInstrumentRoster(newRoster, index) {
        this.actInstruments[index - 1].rosters.push({
            profile: newRoster,
            profileId: newRoster.id,
            deleted: false,
            name: newRoster.firstName + " " + newRoster.lastName,
            email: newRoster.email
        })
        return this.actInstruments;
    }

    @action
    removeInstrumentRoster(deletedRoster, editedInstrument) {
        this.actInstruments = _.map(this.actInstruments, actInstrument => {
            if (_.isEqual(actInstrument.name, editedInstrument)) {
                _.remove(actInstrument.rosters, roster => {
                    return _.isEqual(roster, deletedRoster);
                });
            }
            return actInstrument;
        });
    }

    @action
    sortInstrumentRoster(rosters, idx) {
        this.actInstruments[idx].rosters = rosters;
    }

    @action
    sortActRosters(sortedActRosters) {
        this.actInstruments = sortedActRosters;
    }

    @action
    setSnackbarMessage(message) {
        this.snackbarMessage = message;
    }

    @action
    openSnackbarContainer() {
        this.openSnackbar = true;
    }

    @action
    closeSnackbarContainer() {
        this.openSnackbar = false;
    }

    @action
    openInviteMusicianForm() {
        this.openInviteMusician = true;
        this.invitedEmail = null;
        this.invitedName = null;
        this.errors = undefined;
    }

    @action
    closeInviteMusicianForm() {
        this.openInviteMusician = false;
        this.invitedInstrument = undefined;
    }

    @action
    setInvitedInstrument(instrument) {
        this.invitedInstrument = instrument;
    }

    @action
    async inviteRoster() {
        this.errors = undefined;
        return authStore.checkEmailValidWithEmail(this.invitedEmail).then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                agent.Roster.inviteNew(this.actId, {
                    email: this.invitedEmail,
                    name: this.invitedName,
                    actRosterId: this.invitedInstrument ? this.invitedInstrument.id : null
                })
                    .then(response => {
                        if (this.invitedInstrument) {
                            _.each(this.actInstruments, actInstrument => {
                                if (_.isEqual(actInstrument, this.invitedInstrument)) {
                                    actInstrument.rosters.push({
                                        firstName: this.invitedName,
                                        lastName: "",
                                        email: this.invitedEmail,
                                        token: response.token,
                                        profileImage: {
                                            url: blankProfile
                                        }
                                    });
                                }
                            });
                        }
                        this.closeInviteMusicianForm();
                        return response;
                    })
                    .catch(
                        action(err => {
                            this.errors = err.response && err.response.data && err.response.data.errors;
                            return (err.response.data.errors);
                            
                        })
                    );
                }
            else{
                runInAction(async () => {
                    this.errors = [];
                    this.errors["email"] = "We have detected that you are trying to add a user using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.  Please use a permanent email address to invite this user.";
                
                })
                return Promise.reject("Invalid Email!");
            }
        })
    }

    @action
    getDefaultRosterPercentage = additional => {
        var setActInstrumentRosterPercentage = 0;
        var addLength = 0;

        if (additional) {
            addLength = 1;
        }

        var undeletedActInstrument = this.actInstruments.filter(actInstrument => !actInstrument.deleted && actInstrument.dividendType === 0);

        var undeletedActInstrumentWithRosterPercentage = undeletedActInstrument.filter(
            actInstrument => actInstrument.dividendAmount && !isNaN(parseFloat(actInstrument.dividendAmount))
        );

        undeletedActInstrumentWithRosterPercentage.forEach(actInstrument => {
            setActInstrumentRosterPercentage += actInstrument.dividendAmount;
        });

        var divider = 1;
        if (undeletedActInstrument.length !== undeletedActInstrumentWithRosterPercentage.length) {
            divider = undeletedActInstrument.length + addLength - undeletedActInstrumentWithRosterPercentage.length;
        }

        return (100 - setActInstrumentRosterPercentage) / divider;
    };

    @action
    submit() {
        this.errors = undefined;
        this.instrumentErrors = undefined;
        this.instrumentRosterErrors = undefined;

        const { currentUser } = userStore;

        var emptyInstruments = "";

        let rosters = this.actInstruments.map((actInstrument, index) => {
            if (!actInstrument.instruments && !actInstrument.deleted) {
                emptyInstruments += index + 1 + ", ";
            }
            return {
                id: actInstrument.id,
                instruments: actInstrument.instruments,
                rosters: actInstrument.rosters.map(roster => {
                    return {
                        id: roster.id,
                        profileId: roster.profileId ? roster.profileId : undefined,
                        email: roster.email ? roster.email : roster.invitedEmail,
                        name: roster.firstName ? roster.firstName + " " + roster.lastName : roster.name,
                        token: roster.token
                    };
                }),
                dividendType: actInstrument.dividendType,
                dividendAmount: actInstrument.deleted
                    ? 0
                    : actInstrument.dividendType === 0 ?
                        (
                            (!isNaN(actInstrument.dividendAmount) && actInstrument.dividendAmount)
                                ? actInstrument.dividendAmount
                                : this.getDefaultRosterPercentage()
                        )
                        : actInstrument.dividendAmount,
                deleted: actInstrument.deleted
            };
        });

        if (emptyInstruments !== "") {
            this.instrumentErrors = emptyInstruments.substring(0, emptyInstruments.length - 2);
            return Promise.reject("Empty Instrument.");
        }

        const data = {
            id: this.actId,
            userId: currentUser.id,
            rosters: rosters
        };

        return this.updateRoster(data);
    }

    @action
    updateRoster(data) {
        return agent.ActRoster.update(data).then(() => {
            return null;
        });
    }

    @action
    acceptDeclineRequest(actId, accept) {
        const { currentProfile } = profileStore;

        return agent.Roster.acceptDecline(actId, currentProfile.id, accept).then(response => {
            return response;
        });
    }

    @action
    quitRoster(actId) {
        const { currentProfile } = profileStore;

        return agent.Roster.quit(actId, currentProfile.id).then(response => {
            return response;
        });
    }
}

export default new RosterStore();
