import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Paper } from "@material-ui/core";

import Heading from "../../Heading";
import AgencyRequests from "./AgencyRequests";
import AgencyDetail from "./AgencyDetail";

@inject("templateStore", "agentConsultantStore", "agentActStore", "profileStore")
@observer
class AgencyContainer extends Component {
    state = {
        invitationModalState: false
    };

    constructor(props) {
        super(props);
        this.props.agentConsultantStore.resetData();
        this.props.agentActStore.resetData();
    }

    componentDidMount() {
        this.props.templateStore.showLoadingScreen();
        this.props.agentActStore.resetAgentActs();
        this.props.agentConsultantStore.getAgentAgencies().then(response => {
            if (response.length === 1 && response[0].accepted && response[0].agent) {
                Promise.all([
                    this.props.agentActStore.getAgentActRoster(response[0].agent.id),
                    this.props.profileStore.getAgentActList(response[0].agent.id)
                ])
                .then(() => {
                    this.props.templateStore.hideLoadingScreen();
                });
            } else {
                this.props.templateStore.hideLoadingScreen();
            }
        });
    }

    render() {
        const { agentConsultants } = this.props.agentConsultantStore;
        return (
            <Fragment>
                <Heading title="My Agency" />
                {agentConsultants && agentConsultants.length === 1 && agentConsultants[0].accepted ?
                 (
                    <AgencyDetail agency={agentConsultants[0]} />
                ) : agentConsultants && agentConsultants.length !== 0 ? (
                    <Paper>
                        <AgencyRequests />
                    </Paper>
                ) : (
                    ""
                )}
            </Fragment>
        );
    }
}

export default AgencyContainer;
