import React, { Component } from "react";

class SpotifyWidget extends Component {


    render() {
        const url = new URL(this.props.url);
        
        var completeUrl = ""
        if(url.pathname.includes("embed")){
            completeUrl = `https://open.spotify.com${url.pathname}`
        }else{
            completeUrl = `https://open.spotify.com/embed${url.pathname}`
        }
        return (
            <div
                style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    paddingTop: "30px",
                    height: "0",
                    overflow: "hidden"
                }}
            >
                {this.props.url ? (
                    <iframe
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: `100%`,
                            height: `100%`
                        }}
                        title="Spotify Embed URL"
                        src={completeUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                    />
                ) : null}
            </div>
        );
    }
}

export default SpotifyWidget;
