import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, Card, CardHeader, Avatar } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { withRouter } from "react-router-dom";

import ModalContainer from "../../modal/ModalContainer";
import ResponsiveTable from "../../table/ResponsiveTable";
import TableRowProfile from "../../util/TableRowProfile";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import image from "../../../helper/image";
import { ProfileType } from "../../../types/enum";

@withRouter
@inject("profileStore", "rosterStore", "searchStore")
@observer
class AddMusicianToActRosterModal extends Component {

    actColumns = [
        { key: "act", label: "Act" },
    ];

    rosterColumns = [
        { key: "rosterInstrument", label: "Instruments" },
        { key: "numberOfPlayer", label: "# of Player" }
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedAct: undefined,
            selectedActRoster: undefined,
            actRosters: [],
            instruments: [],
            musicianRoster: []
        }
    }

    componentDidUpdate(prevProps){
        const {profile} = this.props
        const currentProfile = this.props.profileStore.getCurrentProfile()
        if(this.props.open && !prevProps.open){
            this.setState({
                ...this.state,
                selectedAct: undefined,
                selectedActRoster: undefined,
                actRosters: []
            })
            this.props.profileStore.getUserActList(currentProfile.id);
            this.props.rosterStore.getRosterByMusicianProfileId(profile.id).then( result => {
                this.setState({
                    ...this.state,
                    musicianRoster: result
                })
            })
            this.props.rosterStore.getInstruments().then( result => {
                this.setState({
                    ...this.state,
                    instruments: result
                })
            });
        }
    }

    getSelectedAct = rows => {
        let selectedAct = rows[0];
        this.setState({
            actRosters: [],
            selectedActRoster: undefined,
            isLoading: true,
            selectedAct: selectedAct
        })

        if(selectedAct){
            this.props.rosterStore.getActRoster(selectedAct.id).then(result => {
                this.setState({
                    actRosters: result,
                    isLoading: false
                })
            });
        }
        else{
            this.setState({
                isLoading: false
            })
        }
    }

    getSelectedActRoster = rows => {
        this.setState({
            selectedActRoster: rows[0]
        })
    }

    getActRows = () => {
        const { actList, currentProfile } = this.props.profileStore;
        const { selectedAct, musicianRoster } = this.state;
        const {profile, profileName, filterActMusician} = this.props
        const profileId = profile.id
        // return actList.filter(x => x.userId === profile.userId).map(act => {
        function checkCheckedStatus (act) {
            if (selectedAct && selectedAct.id == act.id) return true
            return false
        }
        let filteredActs = filterActMusician ? actList.filter(act => act.myMusician.type == ProfileType.Musician.ordinal) : actList

        return filteredActs.filter(x => x.userId === currentProfile?.userId).map(act => {
            
            return {
                act: (
                    <TableRowProfile profileId = { act.id } name={act.actName} avatarUrl={image.getProfileImage(act)}/>
                ),
                id: act.id,
                checked: checkCheckedStatus(act),
                disableCheckbox: musicianRoster.find(avaibility => avaibility.actId == act.id)
            }
        })
    }

    getActRosterRows = () => {
        const { actRosters, instruments, selectedActRoster } = this.state;

        if(actRosters){
            return actRosters.map( (actRoster, index) => {
                const rosterInstruments = actRoster.instruments.split(",");
                let instrumentName = [];
                rosterInstruments.forEach(rosterInstrument =>{
                    // if(this.props.searchStore.instruments.includes(rosterInstrument)){
                    //     isChecked = true;
                    // }
                    if(instruments.length > 0 && rosterInstrument !== "") {
                        instrumentName.push(
                            instruments.filter(x => x.id === parseInt(rosterInstrument, 10))[0].name
                        );
                    }
                });
                return {
                    id: actRoster.id,
                    rosterInstrument: instrumentName.join(", "),
                    numberOfPlayer: actRoster.rosters && actRoster.rosters.length > 0? actRoster.rosters.length : '-',
                    checked: (selectedActRoster ? actRoster.id === selectedActRoster.id : false),
                    index: index
                };
            })
        }
        else{
            return [];
        }
    }

    handleAddToRoster = () => {
        const { selectedActRoster, selectedAct } = this.state;
        const { profile } = this.props;
        this.setState({
            ...this.state,
            isLoading: true
        })
        this.props.rosterStore.setActId(selectedAct.id);
        this.props.rosterStore.addInstrumentRoster(profile, selectedActRoster.index + 1);
        this.props.rosterStore.submit().then( () => {
            this.setState({
                ...this.state,
                isLoading: false
            })
            this.props.onClose();
        });
    }

    render() {
        const { 
            profileName, profileImage, open
        } = this.props;
        const { selectedActRoster, isLoading } = this.state;
        return (
            <ModalContainer title="Add Musician to Act Roster" open={ open } onClose={this.props.onClose}>
                <LoadingSpinner showed = { isLoading } />
                <DialogContent>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            alt={profileName}
                                            src={ profileImage }
                                            style={{
                                                width: 40,
                                                height: 40
                                            }}
                                        />
                                    }
                                    title={ profileName }
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Act List</InputLabel>
                            <ResponsiveTable
                                hideSelectAll
                                selectable={true}
                                single
                                rows={ this.getActRows() }
                                columns={this.actColumns}
                                getCheckedRows={this.getSelectedAct}
                                pagination={this.getActRows().length > 5}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Act's Roster List</InputLabel>
                            <ResponsiveTable
                                hideSelectAll
                                selectable={true}
                                single
                                rows={ this.getActRosterRows() }
                                columns={this.rosterColumns}
                                getCheckedRows={this.getSelectedActRoster}
                                pagination={ this.getActRosterRows().length > 5 }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Close</Button>
                    <Button disabled = { !selectedActRoster }id="invite-musician-to-gig-submit" color="primary" onClick={this.handleAddToRoster}>
                        Add to Act
                    </Button>
                </DialogActions>
            </ModalContainer> 
        );
    }
}

export default AddMusicianToActRosterModal;
