import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
// import NumberFormat from "react-number-format";
// import moment from "moment";
import { ProfileType, BookingType, BookingStatusType, LineupStatusType, RosterDividendType } from "../../types/enum";
import "./BookingDetails.css";
import { Grid, Typography, Button, Checkbox,Menu, Snackbar, IconButton, Tooltip,MenuItem } from "@material-ui/core";
import ResponsiveTable from "../table/ResponsiveTable";
import BookingStatusBadge from "./BookingStatusBadge";
import FacebookPostDialog from "../facebookPostDialog/FacebookPostDialog";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import PrintModal from "./PrintModal.js/PrintModal";
import PrintIcon from "@material-ui/icons/Print";
import facebook from "../../img/facebook-box.svg";
import ConfirmationModal from "../modal/ConfirmationModal";
import currencyHelper from '../../helper/currency';
import FacebookSharePostDialog from "../facebookSharePostDialog/FacebookSharePostDialog";
import Profile from "../../Profile";

const FACEBOOK_ENABLED = process.env.REACT_APP_FACEBOOK_ENABLED === "true";

const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("profileStore", "bookingRequestStore", "templateStore", "facebookStore", "reportStore")
@withRouter
@observer
class BookingSocial extends Component {
    state = {
        columns: [],
        acknowledgeSnackbarState: false,
        booking: undefined,
        selectedBookings: [],
        showPrintOption: false,
        sharedBookings: [],
        actionAnchor: [],
        duplicateModalState: true,
        duplicateModalState: null,
        openDuplicateSnackbar: false,
        duplicateBooking: undefined,
        page: 1
    };

    rows = [];

    setActionAnchor = (element, bookingId) => {
        var tempAnchor = [];
        tempAnchor[bookingId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };
    
    componentDidMount() {
        const {
            currentProfile
        } = this.props.profileStore;

        const { socials } = this.props.bookingRequestStore;
        const { social } = this.props;
        this.props.bookingRequestStore.resetSocials()
            this.props.bookingRequestStore.showLoadingState();
        
        this.setState({
            ...this.state,
            columns: [
                { key: "date", label: "Gig Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                { key: "actName", label: "Act Name", width: "20%" },
                { key: "venue", label: "Venue", width: "20%" },
                { key: "bookingCreator", label: "Booking Creator", width: "15%" },
                { key: "socialPosts", label: "Status",  width: "15%", align: 'center', style: {whiteSpace: 'nowrap'}},
                { key: "scheduledDate", label: "Scheduled Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
            ]
        });
        // if (this.props.profileType === "agent") {
        //     if(social){
        //         this.setState({
        //             ...this.state,
        //             columns: [
        //                 { key: "date", label: "Gig Date", width: "15%", sortable: true,isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
        //                 // { key: "status", label: "Status", width: "15%" },
        //                 { key: "actName", label: "Act Name", width: "15%" },
        //                 { key: "venue", label: "Venue", width: "15%" },
    
        //                 !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "actFee", label: "Act Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "agentFee", label: "My Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
        //                 { key: "bookingCreator", label: "Booking Creator", width: "10%" },
        //                 { key: "socialPosts", label: "Status",  width: "15%", align: 'center', style: {whiteSpace: 'nowrap'}},
        //             ]
        //         });
        //     }else{
        //         this.setState({
        //             ...this.state,
        //             columns: [
        //                 { key: "date", label: "Gig Date", width: "15%", sortable: true,isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
        //                 // { key: "status", label: "Status", width: "15%" },
        //                 { key: "actName", label: "Act Name", width: "15%" },
        //                 { key: "venue", label: "Venue", width: "15%" },
    
        //                 !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "actFee", label: "Act Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "agentFee", label: "My Fee", sortable: true, width: "10%", isNumber: true, align: 'left', style: { flexDirection: 'row' } },
        //                 { key: "bookingCreator", label: "Booking Creator", width: "10%" },
        //            ]
        //         });
        //     }
           
        // } else if (this.props.profileType === "musician") {
        //     if(social){
        //         this.setState({
        //             ...this.state,
        //             columns: [
        //                 { key: "date", label: "Gig Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
        //                 // { key: "status", label: "Status", width: "15%" },
        //                 { key: "actName", label: "Act Name", width: "15%" },
        //                 { key: "venue", label: "Venue", width: "15%" },
        //                 !social && { key: "gigFee", label: "Venue/Client Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "myFee", label: "My Fee", width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
        //                 { key: "bookingCreator", label: "Booking Creator", width: "10%" },
        //                 { key: "socialPosts", label: "Status",  width: "15%", align: 'center', style: {whiteSpace: 'nowrap'}},
        //                 { key: "action", label: "", width: "15%" }
        //             ]
        //         });
        //     }else{
        //         this.setState({
        //             ...this.state,
        //             columns: [
        //                 { key: "date", label: "Gig Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
        //                 // { key: "status", label: "Status", width: "15%" },
        //                 { key: "actName", label: "Act Name", width: "15%" },
        //                 { key: "venue", label: "Venue", width: "15%" },
        //                 !social && { key: "gigFee", label: "Venue/Client Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
        //                 !social &&   { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "myFee", label: "My Fee", width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
        //                 { key: "bookingCreator", label: "Booking Creator", width: "10%" },
        //                 { key: "action", label: "", width: "15%" }
        //             ]
        //         });
        //     }
            
        // } else if (this.props.profileType === "staff") {
        //     if(social){
        //         this.setState({
        //             ...this.state,
        //             columns: [
        //                 { key: "date", label: "Gig Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
        //                 // { key: "status", label: "Status", width: "15%" },
        //                 { key: "actName", label: "Act Name", width: "15%" },
        //                 { key: "venue", label: "Venue", width: "15%" },
        //                 !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } }, 
        //                 !social && { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
        //                 { key: "bookingCreator", label: "Booking Creator", width: "10%" },
        //                 { key: "socialPosts", label: "Status",  width: "15%", align: 'center', style: {whiteSpace: 'nowrap'}},
        //                 { key: "action", label: "", width: "5%" }
        //             ]
        //         });
        //     }else{
        //         this.setState({
        //             ...this.state,
        //             columns: [
        //                 { key: "date", label: "Gig Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
        //                 // { key: "status", label: "Status", width: "15%" },
        //                 { key: "actName", label: "Act Name", width: "15%" },
        //                 { key: "venue", label: "Venue", width: "15%" },
        //                 !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } }, 
        //                 !social && { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
        //                 !social && { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
        //                 { key: "bookingCreator", label: "Booking Creator", width: "10%" },
        //                 { key: "action", label: "", width: "5%" }
        //             ]
        //         });
        //     }
           
            
        // }else if (this.props.profileType === "PrivateHire") {
        //     this.setState({
        //         ...this.state,
        //         columns: [
        //             { key: "date", label: "Gig Date", width: "20%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
        //             // { key: "status", label: "Status", width: "20%" },
        //             { key: "actName", label: "Act Name", width: "20%" },
        //             { key: "venue", label: "Venue", width: "25%" },
        //             { key: "bookingCreator", label: "Booking Creator", width: "10%" },
        //             !social && { key: "gigFee", label: "Gig Fee", sortable: true, width: "15%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },

        //             { key: "action", label: "", width: "5%" }
        //         ]
        //     });
        // }
        
        this.loadGigs(1)
    }

    loadGigs(page){
        const {
            currentProfile
        } = this.props.profileStore;
        this.props.bookingRequestStore.loadSocial(currentProfile?.id,page).then(() => {
            this.props.bookingRequestStore.hideLoadingState();
            this.setState({
                page: page
            })
        })
    }

    handleShowMore = e => {
        var page = this.state.page + 1;
        this.loadGigs(page)
    };

    updatedSharedBookingState = () => {
        const { booking } = this.props.facebookStore;
        const { sharedBookings } = this.state;
        let postedIds = sharedBookings;
        if (booking) {
            postedIds.push(booking.id);
        }
        this.setState({
            ...this.state,
            sharedBookings: postedIds,
        })
    }

    openAcknowledgeSnackbar = booking => {
        this.setState({
            ...this.state,
            acknowledgeSnackbarState: true,
            booking: booking
        });
    };

    closeAcknowledgeSnackbar = () => {
        this.setState({
            ...this.state,
            acknowledgeSnackbarState: false,
            booking: undefined
        });
    };

    handleAcknowledgeBooking = booking => {
        let message = `Catch ${booking.actName} playing here on ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")} from ${moment(
            booking.timeStart,
            "HH:mm:ss"
        ).format("hh:mm A")} to ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}. `;
        if (booking.ticketUrl !== "") {
            message = message + `Tickets on sale ${booking.ticketUrl}.`;
        }
        this.props.facebookStore.setPostMessage(message);
        this.props.facebookStore.setBooking(booking);
        if (booking.venue?.facebookPageId) {
            this.props.facebookStore.openFacebookPostDialog(booking.venue);
        } else {
            this.props.bookingRequestStore.venueStaffAcknowledgeGigs(booking.id).then(response => {
                this.openAcknowledgeSnackbar(response);
            });
        }
    };

    handleSharePost = async (booking, profile) => {
        const { currentProfile } = this.props.profileStore;
        let formattedVenueName = booking.venueName ? "#" + booking.venueName?.replace(/ /g, "") : booking.venue ? "#" + booking.venue?.profileName?.replace(/ /g, "") : "";
        let formattedActName = booking.actName ? "#" + booking.actName.replace(/ /g, "") : "";
        let formattedActName2 = booking.actName ? booking.actName : booking.act ? booking.act?.actName : ""
        let bookTableUrl = booking.venue && booking.venue.bookTableUrl ? booking.venue.bookTableUrl  : ""
        

        let message = `Hi Folks, Catch ${formattedActName2} playing here on ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")}  from ${moment(
            booking.timeStart,
            "HH:mm:ss" 
        ).format("hh:mm A")} to  ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}.  Come and enjoy some great live entertainment - get in early and book a table! \n \n ${bookTableUrl} \n \n ${formattedActName} ${formattedVenueName} #livegigs #livegigs #livemusic #liveentertainment #hotgiggity`
        if(currentProfile?.type === ProfileType.Musician.ordinal){
            message = `Hi Folks!  I am playing a gig at ${formattedVenueName} on the ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")}  from ${moment(
                booking.timeStart,
                "HH:mm:ss" 
            ).format("hh:mm A")} to  ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}.  See you there!! \n \n #livegigs #livemusic #liveentertainment #hotgiggity `
        }
      
        if (booking.ticketUrl && booking.ticketUrl !== "") {
            message = message + `Tickets on sale ${booking.ticketUrl}.`;
        }
        if(booking.venue){
            booking.venue.backgroundImages = await this.props.profileStore.getBackgroundImage(booking.venue.id);
        }
        if(booking.act){
            booking.act.backgroundImages = await this.props.profileStore.getBackgroundImage(booking.act.id);
        }
        this.props.facebookStore.setPostMessage(message);
        this.props.facebookStore.setBooking(booking);
        if (profile.type === ProfileType.Musician.ordinal) {
            if (booking.act?.facebookPageId && booking.act?.actAdminId === currentProfile?.id) {
                this.props.facebookStore.openFacebookSharePostDialog(booking.act);
            } else {
                this.props.facebookStore.openFacebookSharePostDialog(currentProfile);
            }
        } else if (profile.type === ProfileType.Staff.ordinal) {
            this.props.facebookStore.openFacebookSharePostDialog(booking.venue);
              // this.props.bookingRequestStore.venueStaffAcknowledgeGigs(booking.id).then(response => {
            //     this.openAcknowledgeSnackbar(response);
        } else if (profile.type === ProfileType.Agent.ordinal) {
            this.props.facebookStore.openFacebookSharePostDialog(booking.agent);
        }
    };

    handleUndoAcknowledge = () => {
        if (this.state.booking) {
            this.props.bookingRequestStore.venueStaffUnacknowledgeGigs(this.state.booking.id).then(response => {
                this.closeAcknowledgeSnackbar();
                this.props.templateStore.openSnackbar(`Changes to ${response.title} has been reverted.`);
            });
        }
    };

    getBookingStatus = booking => {
        const { currentProfile } = this.props.profileStore
        if (booking.type === BookingType.Adhoc.ordinal) {
            // if (booking.status === BookingStatusType.Accepted.ordinal && !booking.notifyClient) {
            //     return "Incomplete";
            // }
            return BOOKING_STATUS[booking.status];
        } else {
            if (booking.createdById !== currentProfile?.id && (booking.status === BookingStatusType.AllPlayerConfirmed.ordinal || booking.status === BookingStatusType.PlayerConfirmedAndClientNotified.ordinal)) {
                return "Accepted"
            }
            return BOOKING_STATUS[booking.status];
        }
    };

    renderTableCustomActions = () => {
        const { selectedBookings } = this.state;
        return (
            <Fragment>
                <Button onClick = { this.openPrintOptionModal } disabled = { !selectedBookings || selectedBookings.length === 0 } style={{ color: selectedBookings.length > 0 ? "#fff" : undefined }}>
                    <PrintIcon style={{ color: selectedBookings.length > 0 ? "#fff" : undefined }} />
                    Print
                </Button>
            </Fragment>
        )
    };

    renderFooterRow = () => {
        let { socials } = this.props.bookingRequestStore;
        const { profileType } = this.props;
        const { venueId } = this.props.reportStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        
        if (venueId) {
            socials = socials.filter(x => x.venueId === venueId);            
        }
        var totalFee = 0;
        var totalActFee = 0;
        var totalAgentFee = 0;
        var totalMyFee = 0
        
        socials.forEach(booking => {
            let linuep = booking?.lineups?.find(x => x.profileId === currentProfile?.id);
            const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

                                var musicianFee = parseFloat(booking.gigFee) - totalFee

                                let adminFeeRoster = 0
                                let totalRosterFeeAdmin = 0
                                booking?.act?.actRoster?.forEach(actRoster => {
                                    let feeCount = 0
                                    if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                                        feeCount = actRoster.dividendAmount /100 * booking.actFee;
                                    }else {
                                        feeCount = actRoster.dividendAmount;
                                    }
                                    totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
                                })
                                adminFeeRoster = booking.actFee - totalRosterFeeAdmin
                                if(currentProfile?.type === ProfileType.Musician.ordinal && !booking?.lineups?.length){
                                    let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
                                   
                                    if(actRoster?.length){
                                        if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                                            musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                                        }else {
                                            musicianFee = actRoster[0].dividendAmount;
                                        }
                                        if(this.props.profileType === 'musician'){
                                            if(booking?.act?.actAdminId === currentProfile?.id){
                                                musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                                            }
                                        }
                                    }else{
                                        musicianFee = booking.actFee
                                        booking?.act?.actRoster?.forEach(item => {
                                            var rosterFee = 0
                                            if(item.dividendType === RosterDividendType.Percentage.ordinal){
                                                rosterFee = item.dividendAmount /100 * booking.actFee;
                                            }else {
                                                rosterFee = item.dividendAmount;
                                            }
                                            musicianFee = musicianFee - rosterFee
                                        });
                                    }
                                }
                                 
                                var totalRosterFee = 0

                                booking?.lineups?.forEach(lineUp => {
                                    totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
                                })
                                var adminFee = booking.actFee - totalRosterFee
                                var calculateFee = linuep?.fee ?  linuep?.fee : 0
                                if(this.props.profileType === 'musician'){
                                    if(booking?.act?.actAdminId === currentProfile?.id){
                                        calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                                    }
                                }
                                
                                let myFee = linuep?.fee ? calculateFee: isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;
                              
                                let actOwner = booking.act?.userId === currentProfile?.userId;
            let agentActOwner = currentProfile?.type === ProfileType.Agent.ordinal && booking.createdById !== currentProfile?.id && booking.act.actAdminId === currentProfile?.id;
            let totalLineupFee = 0

            totalFee += (booking.viewOnly) ? 0 : (profileType === "agent" || profileType === "staff") && booking.isApproved ? Number(booking.gigFee) + Number(booking.extraGigFee) : Number(booking.gigFee);
            // totalActFee += this.props.profileType === "musician" && !actOwner ? 0 : Number(booking.actFee);
            totalActFee += agentActOwner ? 0 : this.props.profileType === "musician" && !actOwner ? 0 : Number(booking.actFee);
            totalMyFee += Number(myFee);
            booking?.lineups?.forEach(l => {
                if (l.status !== LineupStatusType.Removed.ordinal) {
                    totalLineupFee += parseFloat(l.fee)
                }                                    
            }); 
            totalAgentFee += agentActOwner ? parseFloat(booking.actFee) - parseFloat(totalLineupFee) :  Number(booking.agentCommissionAmount);
        });
        
        return {
            gigFee: ( (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalFee, 2)}`,
            actFee: ( (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalActFee, 2)}`,
            agentCommissionAmount: ((currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
            agentFee: ((currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
            myFee: ((currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalMyFee, 2)}`,
            style: {
                textAlign: 'left'
            }
        }
    }

    getCheckedRows = rows => {
        this.setState({
            ...this.state,
            selectedBookings: rows
        })
    };

    openPrintOptionModal = () => {
        this.setState({
            ...this.state,
            showPrintOption: true
        })
    }

    closePrintOptionModal = () => {
        this.setState({
            ...this.state,
            showPrintOption: false
        })
    }

    isConnectedToFb = (booking) => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        let status;

        if (this.props.profileType === 'musician') {
            status = currentProfile && currentProfile?.facebookPageId || booking.act?.facebookPageId ? true : false;
        } else if (this.props.profileType === 'staff') {
            status = booking.venue?.facebookPageId ? true : false;
        } else if (this.props.profileType === 'agent') {
            status = currentProfile && currentProfile?.facebookPageId ? true : false
        }

        return status
    };

    handleOpenDuplicateBookingModal = booking => this.setState({ duplicateModalState: true, duplicateBooking: booking, actionAnchor: [] });
    handleCloseDuplicateBookingModal = () => {
        this.setState({
            ...this.state,
            duplicateModalState: false,
            duplicateBooking: null
        });
    };
    handleDupicateRequest = () => {
        const { duplicateBooking } = this.state;
        const {currentProfile} = this.props.profileStore
        this.props.bookingRequestStore.duplicateBooking(duplicateBooking.id,currentProfile?.id ).then(response => {
            this.handleCloseDuplicateBookingModal();
           
            this.setState({ openDuplicateSnackbar: true});
            setTimeout(
                function () {
                    this.props.history.replace(`/booking/${response.id}/edit`);
                }.bind(this),
                2000
            );
            
        });
    };

    handleSnackbarEditClose = (event, reason) => {
        this.setState({ openDuplicateSnackbar: false });
    };
    
    renderSocialTip = () => {
        const { profileType } = this.props;
        let tip = "";
        switch (profileType) {
            case "musician":
                tip = "These are your upcoming gigs. Connect your act profile to your facebook page to push your gigs to Facebook"
                break;
            case "agent":
                tip = "These are your upcoming gigs. Connect your agency profile to your facebook page to push your gigs to Facebook."
                break;
            case "staff":
                tip = "These are your upcoming gigs. Connect each of your venue profiles to your Facebook page to publish your gigs to Facebook"
                break;
        
            default:
                break;
        }

        return tip
    }

    render() {
        let { socials } = this.props.bookingRequestStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const { profileType, social } = this.props;
        const { venueId } = this.props.reportStore;
        const { showPrintOption, selectedBookings } = this.state;
        const { match } = this.props;

        if (venueId) {
            socials = socials.filter(x => x.venueId === venueId);
        }
        const bookingHighlight = match.params.id ? match.params.id : null
        var newColumns = []

        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Post to Socials
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                Let people know about your upcoming gigs!  Use this section to schedule your social posts to Instagram and Facebook. 
                            </Typography>
                      
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12} className="booking-table-wrapper" ref={el => (this.componentRef = el)}>
                        <ResponsiveTable
                            showToolbar={true}
                            customActions={
                                this.renderTableCustomActions()
                            }
                            selectable={true}
                            getCheckedRows={this.getCheckedRows}
                            sortable
                            highLight={bookingHighlight}
                            columns={newColumns.length ? newColumns : this.state.columns}
                            rows={socials.map(booking => {
                                var row = {};
                                var dateStartMoment = moment(booking.dateStart)

                                var dateStart = dateStartMoment.subtract((parseFloat(booking.utcOffset) + dateStartMoment.utcOffset()),'minute')
                                var dstVariable = 120
                                var dstChecking = moment(dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                                if(dstChecking.isAfter(dateStart)){
                                    dateStart = dateStart.subtract(dstVariable,"minute")
                                }
                                // row.date = date.getDate()+" "+this.props.templateStore.getMonth(date.getMonth())+" "+date.getFullYear();
                                row.id = booking.id;
                                var formattedDate = dateStart.format("YYYY-MM-DD")
                               
                                let time = booking.timeStart.length < 8 ? `0${booking.timeStart}` : booking.timeStart;
                                let endTime = booking.timeEnd.length < 8 ? `0${booking.timeEnd}` : booking.timeEnd;
                                var finalDate= moment(formattedDate + ' ' + time)
                                row.date = {newFormat: 
                                    (
                                        <div>
                                            <span>{`${dateStart.format("ddd, DD MMM YYYY")}`}</span>
                                            <br />
                                            <small>{`${moment(time).format('hh.mm A')} - ${moment(endTime).format('hh.mm A')}`}</small>
                                        </div>
                                    ), value: finalDate};

                                if(booking.socialPost && !booking.socialPost.postInstagramId && !booking.socialPost.postFacebookId){
                                    var scheduleTime = booking.socialPost.scheduleTime
                                 

                                    row.scheduledDate = {newFormat: 
                                        (
                                            <div>
                                                <span>{`${moment(scheduleTime).format("ddd, DD MMM YYYY")}`}</span>
                                                <br />
                                                <small style={{textAlign:"center"}}>{`${moment(scheduleTime).format('hh.mm A')}`}</small>
                                            </div>
                                        ), value: scheduleTime};
                                }else {
                                    row.scheduledDate = {newFormat: 
                                        (
                                            <div>
                                               
                                            </div>
                                        ), value: null};
                                }
                               
                                // row.date = {newFormat: `${moment(booking.dateStart).utcOffset(booking.utcOffset).format("ddd, DD MMM YYYY")} ${moment(time).format('hh.mm A')}`, value: finalDate};
                             

                               
                                row.bookingCreator = booking?.createdBy ? (booking?.createdBy?.firstName + " " +booking?.createdBy?.lastName) : ""
                                row.venue = (
                                    <div>
                                        <span>{booking.venue ? booking.venue?.profileName : booking.venueName}</span>
                                        <br />
                                        <small>{booking.venueAddress}</small>
                                    </div>
                                );
                                // row.address = booking.venueAddress;
                                row.styleHighlight = {
                                    border: "solid",
                                    borderColor: "#FF0C9C"
                                }
                                let agentActOwner = currentProfile?.type === ProfileType.Agent.ordinal && booking.createdById !== currentProfile?.id && booking.act.actAdminId === currentProfile?.id;
                                let totalFee = 0;
                                booking?.lineups?.forEach(l => {
                                    if (l.status !== LineupStatusType.Removed.ordinal) {
                                        totalFee += parseFloat(l.fee)
                                    }                                    
                                });  
                                row.gigFee = (booking.viewOnly || (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
                                    ? <i class="far fa-eye-slash"></i>  
                                    : `${currentProfile?.currencySymbol + ((profileType === "agent" || profileType === "staff") && booking.isApproved
                                        ? currencyHelper.formatMoney(booking.gigFee + booking.extraGigFee, 2) 
                                        : agentActOwner ? currencyHelper.formatMoney(booking.actFee, 2)
                                        : currencyHelper.formatMoney(booking.gigFee, 2))}`;
                                row.agentFee = currentProfile?.currencySymbol + currencyHelper.formatMoney(agentActOwner ? parseFloat(booking.actFee) - parseFloat(totalFee) : booking.agentCommissionAmount, 2);
                                // row.actName = booking.actName;
                                row.agentCommissionAmount = `${currentProfile?.currencySymbol}${currencyHelper.formatMoney(booking.agentCommissionAmount, 2)}`
                                const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

                                row.actName = (
                                    <div>
                                        <a target={"_blank"} href={`/profile/${isEntertainerBookings ? currentProfile?.id : booking.act?.id}`}>{isEntertainerBookings ? `${currentProfile?.firstName} ${currentProfile?.lastName}` : booking.act?.actName}</a>
                                        <br />
                                        {booking.act && <small>Contact: {booking.act?.contactName}</small>}
                                        <br />
                                        {booking.act && <small>Phone: {booking.act?.contactPhone}</small>}
                                    </div>
                                );
                                let linuep = booking?.lineups?.find(x => x.profileId === currentProfile?.id);

                                var musicianFee = parseFloat(booking.gigFee) - totalFee

                                let adminFeeRoster = 0
                                let totalRosterFeeAdmin = 0
                                booking?.act?.actRoster?.forEach(actRoster => {
                                    let feeCount = 0
                                    if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                                        feeCount = actRoster.dividendAmount /100 * booking.actFee;
                                    }else {
                                        feeCount = actRoster.dividendAmount;
                                    }
                                    totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
                                })
                                adminFeeRoster = booking.actFee - totalRosterFeeAdmin
                                if(currentProfile?.type === ProfileType.Musician.ordinal && !booking?.lineups?.length){
                                    let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
                                   
                                    if(actRoster?.length){
                                        if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                                            musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                                        }else {
                                            musicianFee = actRoster[0].dividendAmount;
                                        }
                                        if(this.props.profileType === 'musician'){
                                            if(booking?.act?.actAdminId === currentProfile?.id){
                                                musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                                            }
                                        }
                                    }else{
                                        musicianFee = booking.actFee
                                        booking?.act?.actRoster?.forEach(item => {
                                            var rosterFee = 0
                                            if(item.dividendType === RosterDividendType.Percentage.ordinal){
                                                rosterFee = item.dividendAmount /100 * booking.actFee;
                                            }else {
                                                rosterFee = item.dividendAmount;
                                            }
                                            musicianFee = musicianFee - rosterFee
                                        });
                                    }
                                }
                                 
                                var totalRosterFee = 0

                                booking?.lineups?.forEach(lineUp => {
                                    totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
                                })
                                var adminFee = booking.actFee - totalRosterFee
                                var calculateFee = linuep?.fee ?  linuep?.fee : 0
                                if(this.props.profileType === 'musician'){
                                    if(booking?.act?.actAdminId === currentProfile?.id){
                                        calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                                    }
                                }
                                
                                let myFee = linuep?.fee ? calculateFee: isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;
                                row.myFee = this.props.profileType === 'musician' && currentProfile?.currencySymbol + currencyHelper.formatMoney(myFee, 2);
                                let actOwner = booking.act?.userId === currentProfile?.userId;

                                row.actFee = agentActOwner ? 0 : this.props.profileType === "musician" && !actOwner ? "" : currentProfile?.currencySymbol + currencyHelper.formatMoney(booking.actFee, 2);
                               
                                row.agentName = booking.agentName;
                                row.time = booking.timeStart.substring(0, 5);

                                // let fbPostedStatus = this.state.sharedBookings.includes(booking.id);
                                // let fbButtonDisabled = !this.isConnectedToFb(booking) || !booking.sharePermission || booking.type === BookingType.Adhoc.ordinal || isEntertainerBookings;
                                // let fbButtonStyle = (this.isConnectedToFb(booking) && booking.sharePermission && booking.type !== BookingType.Adhoc.ordinal && !isEntertainerBookings) ? "fb-share" : "fb-share-disabled";
                                // let fbButtonText = (!fbButtonDisabled && booking?.isPostToFacebook) || fbPostedStatus ? "Posted" : "Post to Facebook";
                                // let fbHoverText = this.isConnectedToFb(booking) && booking.sharePermission ? "" : "Connect your Facebook page to post this gig to Facebook"                                
                                
                                // if(this.props.social &&(currentProfile && currentProfile?.type !== ProfileType.Staff.ordinal) || (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.viewOnly)){
                                //     row.socialPosts = (
                                //         <Tooltip title={fbHoverText} >
                                //             <Button disabled={fbButtonDisabled} className={fbButtonStyle} onClick={() => this.handleSharePost(booking, currentProfile)}>
                                //                 <img src={facebook} alt="fb" className="logo-fb" />&nbsp;&nbsp;{fbButtonText}
                                //             </Button>
                                //         </Tooltip>
                                //     )
                                // }
                              
                                if(booking.socialPost?.postInstagramId || booking.socialPost?.postFacebookId){
                                    
                                    row.socialPosts = 
                                    <Tooltip title={"This post has already been published and can’t be edited here. You will need to edit this post via your social channels"}>
                                    <div>
                                    <Button
                                        size="small"
                                        color="primary"
                                        className="action-button booking-details"
                                        component={Link}
                                        to={`/post/detail/${booking?.id}`}
                                        disabled={booking.socialPost?.postInstagramId || booking.socialPost?.postFacebookId}
                                    >
                                           { booking.socialPost ? (booking.socialPost.postInstagramId || booking.socialPost.postFacebookId) ?  "Published" : "Scheduled - Edit" : "Schedule Post" }
                                    </Button>
                                    </div>
                                    </Tooltip>
                                }else{
                                    row.socialPosts = 
                                    <Button
                                        size="small"
                                        color="primary"
                                        className="action-button booking-details"
                                        component={Link}
                                        to={`/post/detail/${booking?.id}`}
                                        disabled={booking.socialPost?.postInstagramId || booking.socialPost?.postFacebookId}
                                    >
                                           { booking.socialPost ? (booking.socialPost.postInstagramId || booking.socialPost.postFacebookId) ?  "Published" : "Scheduled - Edit" : "Schedule Post" }
                                    </Button>
                                }
                               
                                return row;
                            })}
                            footerRow = {
                                currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal || currentProfile?.type === ProfileType.Musician.ordinal ? 
                                    this.renderFooterRow() : undefined
                            }
                        />
                    </Grid>

                    <Grid item style={{marginTop: 25}} xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                        <Grid container direction="row" justify="center" alignItems="center">
                            {this.props.bookingRequestStore.showMoreSocial ? (
                                <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                    Show More
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

                
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.acknowledgeSnackbarState}
                    onClose={this.closeAcknowledgeSnackbar}
                    autoHideDuration={5000}
                    message={
                        <span id="message-id">{`${
                            this.state.booking ? this.props.bookingRequestStore.getBookingTitle(this.state.booking) : "Selected gigs"
                        } has been acknowledged.`}</span>
                    }
                    action={[
                        <Button color="primary" size="small" onClick={this.handleUndoAcknowledge}>
                            UNDO
                        </Button>,
                        <IconButton onClick={this.closeAcknowledgeSnackbar} color="primary">
                            <ClearIcon />
                        </IconButton>
                    ]}
                />
                {FACEBOOK_ENABLED && <FacebookPostDialog profileType="venue" callback={this.openAcknowledgeSnackbar} />}

                {FACEBOOK_ENABLED && <FacebookSharePostDialog profileType="musician" reloadData={this.updatedSharedBookingState}/>}

                <ConfirmationModal
                    open={this.state.duplicateModalState}
                    message={`Are you sure you want to duplicate this booking - ${this.state.duplicateBooking?.title}?`}
                    closeHandler={this.handleCloseDuplicateBookingModal}
                    confirmationHandler={this.handleDupicateRequest}
                    declineHandler={this.handleCloseDuplicateBookingModal}
                    confirmationLabel="Duplicate"
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDuplicateSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarEditClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Booking {this.state.duplicateBooking?.title} duplicated</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <PrintModal 
                    open = { showPrintOption }
                    closeHandler = { this.closePrintOptionModal }
                    bookings = { selectedBookings }
                />
            </Fragment>
        );
    }
}

export default BookingSocial;
