import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { Button } from "@material-ui/core";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import Heading from "../../components/Heading";
import AgentRosterTable from "../../components/venue/agentRoster/AgentRosterTable";
import StaffAgentInvitationModal from "../../components/venue/agentRoster/StaffAgentInvitationModal";
import Loading from "../../components/loadingSpinner/Loading";

import { ProfileRelationType } from "../../types/enum";

@inject("templateStore", "agentVenueStore", "userInvitationStore", "profileStore")
@observer
class StaffAgentRoster extends Component {
    state = {
        invitationModalState: false,
        isLoading: true
    };

    constructor(props) {
        super(props);
        this.props.agentVenueStore.resetData();
        this.props.userInvitationStore.resetInvitations();
    }

    componentDidMount() {
        const currentProfile = this.props.profileStore.getCurrentProfile()
        this.setState(
            {
                ...this.state,
                isLoading: true
            },
            () => {
                Promise.all([
                    this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentVenue.ordinal, currentProfile.id),
                    this.props.agentVenueStore.getStaffVenueAgentRequest(),
                    this.props.agentVenueStore.getStaffVenueAgent()
                ]).then(() => {
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                });
            }
        );
    }

    openInvitationModal = () => {
        this.setState(
            {
                ...this.state,
                invitationModalState: true
            },
            () => {
                this.props.agentVenueStore.resetInvitationField();
            }
        );
    };

    closeInvitationModal = () => {
        this.setState({
            ...this.state,
            invitationModalState: false
        });
    };

    render() {
        var currentProfile = this.props.profileStore.getCurrentProfile();
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Heading title="My Agents" bottomAction={false}>
                        <div>
                        {
                            !(currentProfile?.viewOnly) && (currentProfile?.allowBooking)? 
                            (
                                <Button color="primary" variant="contained" onClick={this.openInvitationModal}>
                                Add Agent
                                </Button>
                            )
                            :
                            ""
                        }
                           
                        </div>
                    </Heading>
                    <div style={{ position: "relative" }}>
                        <Loading showed={this.state.isLoading} borderRadius={0} />
                        <AgentRosterTable allowBooking={!(currentProfile?.viewOnly) && (currentProfile?.allowBooking)} />
                    </div>
                    <StaffAgentInvitationModal open={this.state.invitationModalState} onClose={this.closeInvitationModal} />
                </MainContainer>
            </Fragment>
        );
    }
}

export default StaffAgentRoster;
