import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";

import { inject, observer } from "mobx-react";

import {
    Grid,
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    InputAdornment,
    Input,
    FormHelperText,
    Switch,
    FormControlLabel
} from "@material-ui/core";

import NumberFormat from "react-number-format";
import { ProfileType, BookingStatusType } from "../../../types/enum";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";

import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Popover, Typography, Button } from "@material-ui/core";

import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";

import { withStyles } from "@material-ui/core/styles";

const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit
    }
});

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore", "templateStore", "commonStore")
@observer
class PrivateBookingFeeDetails extends Component {
    state = {
        approvalDialogOpen: false,
        checked: false,
        anchorEl: false,
        goToPaymentPage: false
    };

    handleNumberSetsChange = e => {
        this.props.bookingRequestStore.setBooking("setNumber", e.target.value);
    };

    handleExtraGigFeeChange = e => {
        let extraGigFee = e.target.value < 0 ? 0 : e.target.value;
        this.props.bookingRequestStore.setBooking("extraGigFee", extraGigFee);
    };

    handleCheckedChange = e => {
        this.setState({ checked: e.target.checked });
    };

    handleGigFeeChange = e => {
        const { agentCommissionType, agentCommissionFee } = this.props.bookingRequestStore.booking;
        const { currentProfile } = this.props.profileStore;
        let value = e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.')
        value = !value ? '' : (value)
        let actGigFee = 0;
        let agentCommission = 0;
        this.props.bookingRequestStore.setBooking("gigFee", value);
      
        if (currentProfile?.type === ProfileType.Agent.ordinal) {
            var agentFee =  agentCommissionFee? parseFloat(agentCommissionFee) : 0
            if (agentCommissionType === 0) {
                actGigFee = parseFloat(value) - agentFee;
                agentCommission = agentFee;
            } else if (agentCommissionType === 1) {
                agentCommission = (agentFee / 100) * parseFloat(value);
                actGigFee = parseFloat(value) - agentCommission;
            }
            this.props.bookingRequestStore.setBooking("actFee", actGigFee);
            this.props.bookingRequestStore.setBooking("agentCommissionAmount", agentCommission);
        } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
            this.props.bookingRequestStore.setBooking("actFee", value);
        }
    };

    handleActFeeChange = e => {
        this.props.bookingRequestStore.setBooking("actFee", e.target.value);
    };

    handleAgentCommissionAmountChange = e => {
        this.props.bookingRequestStore.setBooking("agentCommissionAmount", e.target.value);
    };

    handleCommissionTypeChange = e => {
        this.props.bookingRequestStore.setBooking("agentCommissionType", e.target.value);
        this.props.bookingRequestStore.setBooking("agentCommissionFee", 0);
        this.props.bookingRequestStore.setBooking("actFee", 0);
        this.props.bookingRequestStore.setBooking("agentCommissionAmount", 0);
    };

    handleAgentCommissionChange = e => {
        const { gigFee, agentCommissionType } = this.props.bookingRequestStore.booking;
        let value = e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.')
        value = !value ? '' : (value)
        let result = 0.0;
        if (agentCommissionType === 0) {
            if (parseInt(value, 10) > parseInt(gigFee, 10)) return false;
            result = (gigFee || 0) - e.target.value;
            this.props.bookingRequestStore.setBooking("agentCommissionAmount", value);
        } else if (agentCommissionType === 1 && gigFee !== 0) {
            if (parseInt(value, 10) > 100 || parseInt(value, 10) < 0) return false;
            let diffFee = (gigFee / 100) * value;
            this.props.bookingRequestStore.setBooking("agentCommissionAmount", parseFloat(diffFee).toFixed(2));
            result = parseFloat(gigFee - diffFee).toFixed(2);
        }
        this.props.bookingRequestStore.setBooking("agentCommissionFee", value);
        this.props.bookingRequestStore.setBooking("actFee", result);
    };

    handleImmediateInvoiceChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("immediateInvoice", checked);
        this.setState({
            ...this.state
        });
    };

    handleDepositDueChange = e => {
        const { dateStart } = this.props.bookingRequestStore.booking;

        this.props.bookingRequestStore.setBooking("depositDue", e.target.value);

        if (e.target.value === 0) {
            this.props.bookingRequestStore.setBooking("depositDueDate", moment());
        } else if (e.target.value === 1) {
            this.props.bookingRequestStore.setBooking("depositDueDate", moment(dateStart).subtract(14, "days"));
        } else if (e.target.value === 2) {
            this.props.bookingRequestStore.setBooking("depositDueDate", moment(dateStart).subtract(1, "months"));
        } else if (e.target.value === 3) {
            this.props.bookingRequestStore.setBooking("depositDueDate", moment());
        }
    };

    handleBalanceDueChange = e => {
        const { dateStart } = this.props.bookingRequestStore.booking;

        this.props.bookingRequestStore.setBooking("balanceDue", e.target.value);

        if (e.target.value === 0) {
            this.props.bookingRequestStore.setBooking("balanceDueDate", moment());
        } else if (e.target.value === 1) {
            this.props.bookingRequestStore.setBooking("balanceDueDate", moment(dateStart).subtract(14, "days"));
        } else if (e.target.value === 2) {
            this.props.bookingRequestStore.setBooking("balanceDueDate", moment(dateStart).subtract(1, "months"));
        } else if (e.target.value === 3) {
            this.props.bookingRequestStore.setBooking("balanceDueDate", moment());
        }
    };

    handleDepositDueDateChange = e => {
        this.props.bookingRequestStore.setBooking("depositDueDate", moment(e).startOf("day"));
    };

    handleBalanceDueDateChange = e => {
        this.props.bookingRequestStore.setBooking("balanceDueDate", moment(e).startOf("day"));
    };

    getBalanceAmount = () => {
        let { gigFee, depositAmount } = this.props.bookingRequestStore.booking;

        gigFee = gigFee ? gigFee : 0;
        depositAmount = depositAmount ? depositAmount : 0;

        var result = gigFee - depositAmount;
        // if(result < 0)  this.props.bookingRequestStore.addErrors(["balance"], "balance amount is under 0")
        if (isNaN(result)) return 0
        else return result.toFixed(2);
    };

    handlePopoverOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: false });
    };

    handleGoToPaymentpage = () => {
        this.setState({ goToPaymentPage: true });
    };

    render() {
        if (this.state.goToPaymentPage) return <Redirect to="../../billing/payment" />;
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        let { booking, error } = this.props.bookingRequestStore;
        let {
            gigFee,
            agentCommissionFee,
            actFee,
            agentCommissionType,
            agentCommissionAmount,
            status,
            immediateInvoice,
            createdById,
            dateStart,
            depositAmount,
            depositDue,
            depositDueDate,
            balanceDue,
            balanceDueDate,
            createInvoice,
            act,
            canUpdate
        } = booking;

        let { readOnly } = this.props;
        const { currentProfile } = this.props.profileStore;
        let currencySymbol = currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$";
        const { token } = this.props.commonStore;
        let hasSubscribe = false;
        let currentType = this.props.profileStore.currentProfile?.typeString === "staff" ? "venue" : this.props.profileStore.currentProfile?.typeString;
        // if (token && currentProfile && jwtDecode(token).scope.filter(x => x === currentType).length > 0) hasSubscribe = true;
        let resentAct = booking.bookingRequests && booking.bookingRequests.length > 0 && booking.bookingRequests[0].act;

        if (token && currentProfile) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }
        let NumberFormatCustom = props => {
            const { inputRef, onChange, ...other } = props;

            return (
                <NumberFormat
                    {...other}
                    ref={inputRef}
                    onValueChange={values => {
                        onChange({
                            target: {
                                value: values.value
                            }
                        });
                    }}
                    thousandSeparator
                    prefix={currencySymbol}
                />
            );
        };

        let modGigFee = parseInt(agentCommissionFee, 10) > parseInt(gigFee, 10);

        let confirmedEditable =
            status !== BookingStatusType.Cancelled.ordinal &&
            status !== BookingStatusType.Completed.ordinal &&
            moment(dateStart).endOf("day") >= moment() &&
            (!createdById || createdById === currentProfile?.id);

        const isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal;
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;

        return (
            <Grid container spacing={8}>
                {currentProfile?.id === createdById || canUpdate || (booking && (!booking.id || booking.id === 0)) ? (
                    <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="gigFee">Gig Fee to Client</InputLabel>
                            <Input
                                id="gigFee"
                                type="string"
                                value={gigFee}
                                onChange={this.handleGigFeeChange}
                                startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                readOnly={readOnly}
                            />
                        </FormControl>
                    </Grid>
                ) : currentProfile?.id !== createdById && !canUpdate && ((act && act.userId === currentProfile?.userId) || (resentAct && resentAct?.userId === currentProfile?.userId)) ? (
                    <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="gigFee">Act Fee</InputLabel>
                            <Input
                                id="actFee"
                                value={actFee}
                                startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>
                ) : currentProfile?.id !== createdById && !canUpdate && act && act.userId !== currentProfile?.userId ? (
                    <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="gigFee">Your Fee</InputLabel>
                            <Input
                                id="actFee"
                                value={actFee}
                                startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>
                ) : (
                    ""
                )}

                {isAgent || isStaff ? (
                    <Fragment>
                        <Grid item xs={12} lg={4} className="gridPrivateBookingForm">
                            <FormControl fullWidth error={error && error["agentCommissionFee"]}>
                                <InputLabel htmlFor="agentFee">{isAgent ? "Agent Commission" : "Booking Fee"}</InputLabel>
                                <Input
                                    id="agentFee"
                                    error={
                                        (agentCommissionType === 0 && modGigFee) ||
                                        (agentCommissionType === 1 && (agentCommissionFee < 0 || agentCommissionFee > 100))
                                    }
                                    value={agentCommissionFee}
                                    onChange={this.handleAgentCommissionChange}
                                    type="string"
                                    readOnly={readOnly}
                                    inputprops={{
                                        inputComponent: NumberFormatCustom
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <TextField
                                                select
                                                value={agentCommissionType}
                                                onChange={this.handleCommissionTypeChange}
                                                disabled={readOnly}
                                                SelectProps={{
                                                    className: "select-adornment",
                                                    inputProps: { className: "select-adornment-input" }
                                                }}
                                            >
                                                <MenuItem key={1} value={0}>
                                                    {currencySymbol}
                                                </MenuItem>
                                                <MenuItem key={2} value={1}>
                                                    %
                                                </MenuItem>
                                            </TextField>
                                        </InputAdornment>
                                    }
                                />
                                {error && error["agentCommissionFee"] ? <FormHelperText>{error["agentCommissionFee"]}</FormHelperText> : ""}
                            </FormControl>
                            <FormHelperText id="name-error-text">Percentage 0 to 100% and commission is not more than Gig Fee</FormHelperText>
                        </Grid>
                        <Grid item xs={12} lg={4} className="gridPrivateBookingForm">
                            <FormControl fullWidth error={error && error["actFee"]}>
                                <InputLabel htmlFor="actFee">Gig Fee Paid To Act</InputLabel>
                                <Input
                                    id="actFeeAmount"
                                    type="number"
                                    value={actFee}
                                    onChange={this.handleActFeeChange}
                                    startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                    readOnly
                                />
                                {error && error["actFee"] ? <FormHelperText>{error["actFee"]}</FormHelperText> : ""}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4} className="gridPrivateBookingForm">
                            <FormControl fullWidth>
                                <InputLabel htmlFor="adornment-amount">{isAgent ? "Commission paid to Agent" : "Booking Fee Amount"}</InputLabel>
                                <Input
                                    id="agentFeeAmount"
                                    type="number"
                                    value={agentCommissionAmount}
                                    startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                    readOnly
                                />
                            </FormControl>
                        </Grid>
                    </Fragment>
                ) : (
                    ""
                )}

                {currentProfile?.id === createdById || canUpdate || (booking && (!booking.id || booking.id === 0)) ? (
                    <Fragment>
                        <Grid item xs={12} lg={3} className="gridPrivateBookingForm">
                            <TextField
                                id="deposit-amount"
                                label="Deposit Amount"
                                value={depositAmount}
                                onChange={e => this.props.bookingRequestStore.setBooking("depositAmount", e.target.value.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.'))}
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                    type: "string",
                                    readOnly: readOnly
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} className="gridPrivateBookingForm">
                            <TextField select label="Deposit Due" value={depositDue} onChange={this.handleDepositDueChange} fullWidth>
                                <MenuItem key={0} value={0}>
                                    Today
                                </MenuItem>
                                <MenuItem key={1} value={1}>
                                    2 Weeks Before
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    1 Month Before
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                    Custom
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    className="booking-date-picker"
                                    keyboard
                                    value={moment(depositDueDate)}
                                    fullWidth
                                    margin="dense"
                                    onChange={this.handleDepositDueDateChange}
                                    label="Deposit Due Date"
                                    minDate={booking.id ? undefined : moment().startOf("days")}
                                    maxDate={moment(dateStart).endOf("days")}
                                    format="DD-MM-YYYY"
                                    keyboardIcon={<EventIcon />}
                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                    disabled={depositDue !== 3}
                                    style={{ marginTop: 0 }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} lg={3} className="gridPrivateBookingForm">
                            <TextField
                                id="balance-amount"
                                label="Balance"
                                value={this.getBalanceAmount()}
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                }}
                                readOnly={true}
                                error={error && error["balance"]}
                                helperText={error && error["balance"] ? error["balance"] : ""}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} className="gridPrivateBookingForm">
                            <TextField select label="Balance Due" value={balanceDue} onChange={this.handleBalanceDueChange} fullWidth>
                                <MenuItem key={0} value={0}>
                                    Today
                                </MenuItem>
                                <MenuItem key={1} value={1}>
                                    2 Weeks Before
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    1 Month Before
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                    Custom
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    className="booking-date-picker"
                                    keyboard
                                    value={moment(balanceDueDate)}
                                    fullWidth
                                    margin="dense"
                                    onChange={this.handleBalanceDueDateChange}
                                    label="Balance Due Date"
                                    minDate={depositDueDate ? moment(depositDueDate).startOf("days") : moment().startOf("days")}
                                    maxDate={moment(dateStart).endOf("days")}
                                    format="DD-MM-YYYY"
                                    keyboardIcon={<EventIcon />}
                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                    disabled={balanceDue !== 3}
                                    style={{ marginTop: 0 }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                            <FormControlLabel
                                onMouseOver={this.handlePopoverOpen}
                                control={
                                    <Switch
                                        color="primary"
                                        onChange={(e, checked) => this.props.bookingRequestStore.setBooking("createInvoice", checked)}
                                        checked={createInvoice && hasSubscribe}
                                        disabled={!confirmedEditable || !hasSubscribe}
                                    />
                                }
                                label="Create Invoices"
                            />
                        </Grid>
                        <Popover
                            id="mouse-over-popover"
                            open={open && !hasSubscribe}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                        >
                            <div onMouseLeave={this.handlePopoverClose}>
                                <Typography className={classes.margin}>Upgrade to enable automated invoicing</Typography>
                                <Grid container xs={12} justify="center" className={classes.margin}>
                                    <Grid item>
                                        <Button align="center" variant="contained" color="primary" onClick={this.handleGoToPaymentpage}>
                                            Upgrade
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Popover>
                    </Fragment>
                ) : (
                    ""
                )}
            </Grid>
        );
    }
}

PrivateBookingFeeDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PrivateBookingFeeDetails);
