import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";
import { DialogContent, DialogActions, Button, TextField, Grid,Hidden,MenuItem } from "@material-ui/core";
import ResponsiveTable from "../../table/ResponsiveTable";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import SelectField from "../../fields/SelectField";
import moment from "moment"
import Typography from "@material-ui/core/Typography";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

const ENABLE_QUICKBOOKS = process.env.REACT_APP_ENABLE_QUICKBOOKS === "true";
const ENABLE_MYOB = process.env.REACT_APP_ENABLE_MYOB === "true";
@inject("profileStore", "invoiceStore", "userStore", "myobStore","templateStore", "billingStore")
@observer
class MYOBCustomerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            tabValue: 0,
            selectedMyobInvoiceId: null,
            isUpdate: false,
            openRemoveContactModalState: false
        };
    }

    componentDidMount() {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.setState({
            isUpdate: false
        });
        if(ENABLE_MYOB && currentProfile && currentProfile?.activateMYOBAccess && currentProfile?.myobCompanyUri){
            Promise.all([
                this.props.myobStore.getMYOBCustomers(currentProfile?.id)
            ]).then(() => { 
      
            });
        }
       
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open) {
            let currentProfile = this.props.profileStore.getCurrentProfile();
            this.props.myobStore.resetMYOBPopUp()
            this.setState({
                isUpdate: false
            });
            const { invoices } = this.props;
            this.props.templateStore.showLoadingScreen()
            if(invoices.length){
                this.props.myobStore.getMappedInvoiceMYOBCustomer(currentProfile?.id,invoices[0].id).then(response => {
                   
                    if(!response){
                        this.props.myobStore.getBookingByInvoiceId(invoices[0].id)
                        this.props.templateStore.hideLoadingScreen()
                    }else{
                        this.props.myobStore.getInvoiceMYOBCustomerById(currentProfile?.id,invoices[0].id)
                        this.setState({
                            isUpdate: true
                        });
                        this.props.templateStore.hideLoadingScreen()
                    }
                })
               
            }
            
        }
    }

    handleReload = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.myobStore.resetMYOBPopUp()
            this.setState({
                isUpdate: false
            });
            const { invoices } = this.props;
            this.props.templateStore.showLoadingScreen()
            if(invoices.length){
                this.props.myobStore.getMappedInvoiceMYOBCustomer(currentProfile?.id,invoices[0].id).then(response => {
                   
                    if(!response){
                        this.props.myobStore.getBookingByInvoiceId(invoices[0].id)
                        this.props.templateStore.hideLoadingScreen()
                    }else{
                        this.props.myobStore.getInvoiceMYOBCustomerById(currentProfile?.id,invoices[0].id)
                        this.setState({
                            isUpdate: true
                        });
                        this.props.templateStore.hideLoadingScreen()
                    }
                })
               
            }
    }
    handleMappedId = (myobUID) => {
        this.props.myobStore.setInvoiceMYOB(myobUID)

    }
    handleUpdateMapped = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        const { invoices } = this.props;
        if(invoices.length){
            this.props.myobStore.updateMapInvoiceMYOBCustomer(currentProfile?.id,invoices[0].id).then(response => {
                // this.handleClose()
                this.handleReload()
            })
           
        }

    }

    handleCreateMapped = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        const { invoices } = this.props;
        if(invoices.length){
            this.props.myobStore.createMapInvoiceMYOBCustomer(currentProfile?.id,invoices[0].id).then(response => {
                this.handleReload()
            })
           
        }

    }

    handleCreateInvoiceMYOB = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        const { invoices } = this.props;
        if(invoices.length){
            this.props.myobStore.createInvoiceMYOBCustomer(currentProfile?.id,invoices[0].id).then(response => {
                this.props.myobStore.getMYOBCustomers(currentProfile?.id).then(response => {
                    this.handleReload()
                })
            })
           
        }

    }
    
    getColumns = () => {
        const { hasQuickBooksAccount } = this.props.userStore;
        const columns = [
            { key: "date", label: "Invoice Date", sortable: true, isDate: true },
            { key: "dueDate", label: "Due Date", sortable: true, isDate: true },
            { key: "no", label: "Invoice No." },
            { key: "status", label: "Status" },
            { key: "total", label: "Total", sortable: true },
            { key: "payeeName", label: "Contact Name", sortable: true },
            { key: "payeePhone", label: "Contact Phone" },
            ...(ENABLE_QUICKBOOKS && hasQuickBooksAccount ? [{ key: "quickBooks", label: "QuickBooks" }] : []),
            { key: "action", label: "" }
        ];
        return columns;
    };

    handlePurchaseOrderCodeChange = e => {
        this.props.invoiceStore.setPurchaseOrderCode(e.target.value);
    };


    handleDeleteContact = () => {
        const { invoices } = this.props;
        if(invoices.length){
            let currentProfile = this.props.profileStore.getCurrentProfile();
            this.props.templateStore.showLoadingScreen()
            this.props.myobStore.deleteMapInvoiceMYOBCustomer(currentProfile?.id,invoices[0].id).then(result =>  {
                this.props.myobStore.resetMYOBPopUp()
                this.setState({
                    isUpdate: false
                });
             this.closeRemoveContactModal();
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
            
            })
        }
        
        
    }

    handleClose = () => {
        this.props.invoiceStore.setPurchaseOrderCode(undefined);
        this.props.closeHandler();
    };
    handleSelectChange = event => {
        this.setState({ tabValue:  event.target.value });
       
    }
    openRemoveContactModal = () => {
        this.setState({
            openRemoveContactModalState: true
        });
    };
    closeRemoveContactModal = () => {
        this.setState({
            openRemoveContactModalState: false
        });
    };

    handleSetMYOBDisplayId = e => this.props.myobStore.setMyobDisplayId(e.target.value)
    handleSetMYOBEmail = e => this.props.myobStore.setMyobEmail(e.target.value);
    handleMYOBLastName = e => this.props.myobStore.setMyobLastName(e.target.value);
    handleMYOBFirstName = e => this.props.myobStore.setMyobFirstName(e.target.value);

    renderFooterRow = invoices => {
        // if (invoices) console.log("match", invoices.slice());
        const { incomingInvoices } = this.props.invoiceStore;
        let total = 0;
        if (incomingInvoices && invoices) {
            const match = incomingInvoices.filter(incoming => {
                return invoices.some(x => x.id === incoming.id);
            });

            if (match) {
                total = match.reduce((a, b) => {
                    return a + b.total;
                }, 0);
            }
        }

        return {
            total: "$" + parseFloat(total).toLocaleString()
        };
    };
    handleTabChange = (event, value) => {
        this.setState({ tabValue: value });
    };
    handleBatchSync = () => {
        const { invoices, type} = this.props
        let currentProfileId = this.props.profileStore.currentProfile?.id;
        this.props.templateStore.showLoadingScreen();
        this.props.myobStore.resetResultIntegration();
        this.props.myobStore.integrateOutgoingInvoice(currentProfileId, [invoices[0].id]).then(result => {
            if(result.length && result[0].type === 1){
                this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfileId).finally(() => {
                    this.handleClose()
                })
            }else{
                this.props.templateStore.openSnackbar("Please define related customer before resync invoice");
            }
        }).finally(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    }
    render() {
        const { isLoading,tabValue, isUpdate} = this.state;
        const { invoices, open } = this.props;
        const { myobCustomersFull,invoiceMYOBId,myobFirstName,myobLastName,myobEmail,myobDisplayId } = this.props.myobStore;
        return (
            <ModalContainer
                open={open}
                title="Map Invoice with MYOB Customer"
                maxWidth="md"
                fullScreen={false}
                onClose={this.handleClose}
                isLoading={isLoading}
            >
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <ResponsiveTable columns={this.getColumns()}
                              rows={invoices.map(invoice => {
                                var row = {};
                                row.id = invoice.id;
                                row.date = moment.utc(invoice.date).format("DD MMM YYYY");
                                row.dueDate = moment.utc(invoice.dueDate).format("DD MMM YYYY");
                                row.no = invoice.no;

                                row.status =
                                    invoice.status === "pending" ? (
                                        invoice.immediateInvoice ? (
                                            <Tooltip
                                                title={
                                                    "This invoice is waiting for gig date at " +
                                                    moment(invoice.date).format("DD MMM YYYY")
                                                }
                                                placement="bottom"
                                            >
                                                <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={
                                                    "This invoice is waiting for payer's billing cycle at " +
                                                    moment(invoice.date).format("DD MMM YYYY")
                                                }
                                                placement="bottom"
                                            >
                                                <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                            </Tooltip>
                                        )
                                    ) : invoice.status === "overdue" ? (
                                        <Tooltip
                                            title={
                                                "This invoice is " +
                                                moment(new Date()).diff(moment(invoice.dueDate), "days") +
                                                " day(s) past due date"
                                            }
                                            placement="bottom"
                                        >
                                            <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                        </Tooltip>
                                    ) : (
                                        <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                    );
                                const currencySymbol = invoice && invoice.payee && invoice.payee.currency === "GBP" ? "£" : "$";
                                row.total = currencySymbol + parseFloat(invoice.total).toLocaleString();
                                row.subTotal = currencySymbol + parseFloat(invoice.subTotal).toLocaleString();
                                row.tax = currencySymbol + parseFloat(invoice.tax).toLocaleString();
                                row.payerName = invoice.payerName;
                                row.payerPhone = invoice.payerPhone;
                                row.syncMYOB = invoice.isMYOBSyncronise ? <CheckCircle style={{ texAlign:"center",color: "green"}} />  : null;
                                row.quickBooks = (
                                    <Button
                                        size="small"
                                        color="primary"
                                        className="btn-sync-quickbooks"
                                        component={Link}
                                        to={`/invoice/${invoice.id}/quickbooks`}
                                    >
                                        Sync
                                    </Button>
                                );
                                row.action = (
                                    <div>
                                        <Button
                                            size="small"
                                            color="primary"
                                            className="btn-invoice-detail"
                                            component={Link}
                                            to={`/invoice/${invoice.id}/detail`}
                                        >
                                            Detail
                                        </Button>
                                    </div>
                                );
                                

                                return row;
                            })}
                             footerRow={this.renderFooterRow(invoices)} />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                                          <Hidden smDown>
                                            {
                                                isUpdate ? (
                                                    <Tabs value={this.state.tabValue} onChange={this.handleTabChange}  indicatorColor="primary" textColor="primary">
                                                        <Tab label={"Map Existing Customer"} classes={{ selected: "tab-selected" }} />
                                                    </Tabs>
                                                    )
                                                    
                                                 : (
                                                    <Tabs value={this.state.tabValue} onChange={this.handleTabChange}  indicatorColor="primary" textColor="primary">
                                                        <Tab label={"Map Existing Customer"} classes={{ selected: "tab-selected" }} />
                                                        <Tab label={"Create New Customer"} classes={{ selected: "tab-selected" }} />
                                                    </Tabs>
                                                 )
                                            }
                                        <br />
                                        </Hidden>
                                        <Hidden mdUp>
                                            <div style={{ padding: 8 }}>
                                                <TextField select value={this.state.tabValue} onChange={this.handleSelectChange} textColor="primary" fullWidth>
                                                    <MenuItem key={0} value={0}>
                                                        Map Existing Customer
                                                    </MenuItem>
                                                    <MenuItem key={1} value={1}>
                                                        Create New Customer
                                                    </MenuItem>
                                                </TextField>
                                            </div>
                                        </Hidden>
                                        <Grid item xs={12}>
                                            <div style={{ padding: 16,marginBottom:30 }}>
                                                { this.state.tabValue === 0 && (
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} md={3} container
                                                        justify="flex-end"
                                                        alignItems="center">
                                                            <Typography  variant="body1">
                                                                Select MYOB existing Customer
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <SelectField
                                                                id="myobCustomers"
                                                                value={invoiceMYOBId}
                                                                suggestions={myobCustomersFull}
                                                                placeholder="Map MYOB Account"
                                                                onChange={this.handleMappedId}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Button onClick={isUpdate ? this.handleUpdateMapped : this.handleCreateMapped }>{isUpdate ? "Update MYOB Customer" : "Set MYOB Customer"}</Button>
                                                            <Button size="small" color="primary" style={{display:isUpdate ? "inline" : "none" }} onClick={this.openRemoveContactModal}>
                                                                Remove
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                   
                                                )

                                                }
                                                { this.state.tabValue === 1 && (
                                                      <Grid container spacing={8}>
                                                      <Grid item xs={12} md={12}>
                                                          <Grid container spacing={8} style={{ paddingTop: 50 }}>
                                                            <Grid item xs={6}>
                                                                First Name
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    id="myobFirstName"
                                                                    type="text"
                                                                    style={{width:"100%"}}
                                                                    value={myobFirstName}
                                                                    onChange={this.handleMYOBFirstName}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                Last Name
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    id="myobLastName"
                                                                    type="text"
                                                                    style={{width:"100%"}}
                                                                    value={myobLastName}
                                                                    onChange={this.handleMYOBLastName}
                                                                />
                                                            </Grid>
                                                              
                                                            
                                                          
                                                            <Grid item xs={6}>
                                                                Email
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                            <TextField
                                                                            id="myobEmail"
                                                                            type="text"
                                                                            style={{width:"100%"}}
                                                                            value={myobEmail}
                                                                            onChange={this.handleSetMYOBEmail}
                                                                        />
                                                            </Grid>
                                                            
                                                          </Grid>
                                                          <Grid item xs={12} md={12} style={{textAlign:"right",marginTop:20}}>
                                                            <Button variant="contained" color="primary" onClick={this.handleCreateInvoiceMYOB}>{isUpdate ? "Update MYOB Customer" : "Create MYOB Customer"}</Button>
                                                        </Grid>
                                                      </Grid>
                                                  </Grid>
                                                )

                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <ConfirmationModal
                                        open={this.state.openRemoveContactModalState}
                                        message="Are you sure you want to remove the existing connection to MYOB contact?"
                                        closeHandler={this.closeRemoveContactModal}
                                        confirmationHandler={this.handleDeleteContact}
                                        declineHandler={this.closeRemoveContactModal}
                                        confirmationLabel="Confirm"
                                    />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.handleBatchSync}>Sync Invoice</Button>
                    <Button onClick={this.handleClose}>Close</Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default MYOBCustomerModal;
