import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import { Typography, Snackbar, Button } from "@material-ui/core";

@inject("songListStore", "setListStore")
@observer
class SetListTable extends Component {
    state = {
        open: false
    };

    handleDeleteSong = song => {
        const { selectedSongs } = this.props.setListStore;
        const updateSelected = selectedSongs.filter(item => {
            return item.id !== song.id;
        });
        this.props.setListStore.deleteFromList(song);
        this.props.setListStore.updateSelectedSongs(updateSelected);
        this.props.setListStore.setTempDeletedSong(song);
        if (this.props.autosubmit) {
            this.props.setListStore.submit().then(() => {
                this.setState({ open: true });
            });
        }
    };

    getSortedSongs = result => {
        let sortedSongs = [];
        const { selectedSongs } = this.props.setListStore;
        result.forEach(song => {
            var tmpSong = selectedSongs.find(el => {
                return el.id === song.id;
            });
            sortedSongs.push(tmpSong);
        });
        this.props.setListStore.updateSelectedSongs(sortedSongs);
    };

    handleClose = () => {
        this.props.setListStore.setTempAddedSong(undefined);
        this.props.setListStore.setTempDeletedSong(undefined);
        this.setState({ open: false });
    };

    handleUndoUpdate = () => {
        this.props.setListStore.undoSetlistAction();
        this.handleClose();
        if (this.props.autosubmit) {
            this.props.setListStore.submit().then(res => {
                //do nothing
            });
        }
    };

    render() {
        let data = [];
        const { selectedSongs } = this.props.setListStore;
        const { readonly } = this.props;
        let columns = [
            { key: "number", label: "", width: 10, hide: true },
            {
                key: "title",
                label: (
                    <Typography variant="subheading" className="head-title">
                        Title
                    </Typography>
                ),
                primary: true
            },
            {
                key: "artist",
                label: (
                    <Typography variant="subheading" className="head-title">
                        Artist
                    </Typography>
                )
            },
            {
                key: "songKey",
                label: (
                    <Typography variant="subheading" className="head-title">
                        Song Key
                    </Typography>
                )
            },
            { key: "action", label: "" }
        ];
        if (readonly) columns.splice(3, 1);

        data = selectedSongs.map((song, index) => {
            var tempData = {};
            tempData.id = song.id;
            tempData.number = index + 1;
            tempData.title = song.title;
            tempData.artist = song.artist;
            tempData.songKey = song.songKey;
            if (!readonly) {
                tempData.action = (
                    <Fragment>
                        <Tooltip title="Delete">
                            <IconButton aria-label="Delete" className="btnDeleteSong" onClick={this.handleDeleteSong.bind(this, song)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Fragment>
                );
            }
            return tempData;
        });

        return (
            <div style={{ overflowX: "auto" }}>
                <ResponsiveTable headerClassName="th-songlist" draggable={true} columns={columns} rows={data} postDragEvent={this.getSortedSongs} />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Set List Updated</span>}
                    action={[
                        <Button key="undo" color="inherit" size="small" onClick={this.handleUndoUpdate}>
                            <span style={{ color: "#ff0c9c" }}>UNDO</span>
                        </Button>,
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}

export default SetListTable;
