import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";

import { Grid, Switch, Button, Paper } from "@material-ui/core";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import ModalContainer from "../../modal/ModalContainer";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import InputLabel from "@material-ui/core/InputLabel";

import "./VenueRequestModal.css";

@inject("agentVenueStore", "profileStore", "templateStore")
@observer
class VenueRequestModal extends Component {
    componentWillReceiveProps(nextProps) {
        if (nextProps.open) {
            var rulesJson = JSON.parse(nextProps.request.rules);
            this.props.agentVenueStore.setDayToBook(rulesJson);
        }
    }

    renderDaySwitch = () => {
        return (
            <Grid container spacing={8}>
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(data => (
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.agentVenueStore.dayToBook[data.toLowerCase()]}
                                    value={data.toLowerCase()}
                                    color="primary"
                                />
                            }
                            label={data}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    handleAccept = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.acceptRequest(this.props.request).then(() => {
            this.props.agentVenueStore.getAgentVenueRequest()    
            this.props.agentVenueStore.getConsultantVenueRoster(currentProfile.id)    
            this.props.templateStore.openSnackbar("Request has been accepted.");
            this.props.onClose();
            this.props.templateStore.hideLoadingScreen();
        })
    };

    handleDecline = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.declineAgentRequest(this.props.request.id).then(() => {
            this.props.agentVenueStore.getAgentVenueRequest().then(() => {
                this.props.templateStore.openSnackbar("Request has been declined.");
                this.props.onClose();
                this.props.templateStore.hideLoadingScreen();
            })
        });
    };

    onClose = () => {
        this.props.onClose();
    };

    render() {
        return (
            <ModalContainer open={this.props.open} onClose={this.onClose} title="Agent Request" maxWidth="sm">
                <DialogContent
                    style={{
                        paddingTop: "8px"
                    }}
                >
                    {this.props.request ? (
                        <Fragment>
                            <InputLabel>Message</InputLabel>
                            <Paper
                                style={{
                                    minHeight: "320px",
                                    padding: "16px",
                                    marginBottom: "8px"
                                }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: this.props.request.requestMessage }} />
                            </Paper>
                        </Fragment>
                    ) : (
                        ""
                    )}

                    <InputLabel>Day to Book</InputLabel>
                    {this.renderDaySwitch()}
                </DialogContent>
                <DialogActions
                    classes={{
                        root: "dialog-action"
                    }}
                >
                    <Button onClick={this.onClose}>Close</Button>
                    <Button color="primary" onClick={this.handleAccept} classes={{ root: "pull-right" }}>
                        Accept Request
                    </Button>
                    <Button color="primary" onClick={this.handleDecline} classes={{ root: "pull-right" }}>
                        Decline Request
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default VenueRequestModal;
