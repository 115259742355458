import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";

import { MenuItem, Avatar, TextField } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";

import AutoComplete from "./AutoComplete";
import image from "../../helper/image";
import blankProfile from "../../img/hotgig-loading.png";
import { ProfileRelationType, ProfileType } from "../../types/enum";

@inject("profileStore","templateStore")
@observer
class ProfileSearchBox extends Component {
    state = {
        value: "",
        open: false,
        loading: false,
        suggestions: []
    };

    requestTimer = null;

    // change image to default blank profile if not loaded 
    addDefaultSrc(ev){
        ev.target.src = blankProfile;
    }

    render() {
        let handleSearch = value => {
            this.setState(
                {
                    loading: true,
                    suggestions: []
                },
                () => {
                    this.props.handleSearch(value).then(response => {
                        var result = response;
                        if (this.props.exclude) {
                            var lookup = _.keyBy(this.props.exclude, o => {
                                if (o) {
                                    if (o.id) {
                                        return o.id;
                                    } else if (o.type === ProfileType.Act.ordinal) {
                                        return o.actId ? o.actId : o.id
                                    } else if (o.relation === ProfileRelationType.AgentConsultant.ordinal) {
                                        return o.relation
                                    }
                                    return o.id;
                                }
                            });
                            result = _.filter(result, u => {
                                return lookup[u.id] === undefined;
                            });

                        }
                     
                        if (result.length === 0 && !this.props.isForVenueRoster) {
                            result = [];
                            if (this.props.disableInvite) {
                                result.push({
                                    fullName: "No User Found",
                                    emptyHandler: false
                                });
                                
                            }else if(this.props.searchFromConsultant){

                                result.push({
                                    fullName: "No User Found",
                                    emptyHandler: true
                                });   
                            
                            } else {
                                result.push({
                                    fullName: "No User Found",
                                    instruments: "Click to Invite",
                                    emptyHandler: true
                                });
                            }
                        } else if (result.length === 0 && this.props.isForVenueRoster) {
                            result.push({
                                fullName: "No User Found",
                                emptyHandler: true
                            });
                        }
                        this.setState({
                            loading: false,
                            suggestions: result
                        });
                    });
                }
            );
        };

        let handleSelect = (value, item) => {
            var tempValue = this.state.value;
            this.setState({
                value: "",
                open: false
            });

            if (item.emptyHandler) {
                if (this.props.handleEmpty) {
                    this.props.handleEmpty(tempValue);
                }
            } else {
                this.props.handleChange(item);
            }
        };

        let handleChange = (event, value) => {
            this.setState({ value, open: false });

            if (value.length > 3) {
                clearTimeout(this.requestTimer);
                this.requestTimer = setTimeout(handleSearch(value), 5000);
                this.setState({ value, open: true });
            }
        };

        let renderMenu = (children, value) => {
            const { menuStyle } = this.props;
            return (
                <div
                    className="menu"
                    style={menuStyle ? menuStyle : { position: "absolute", zIndex: "9999", width: "100%", maxHeight: "300px", overflowY: "auto" }}
                >
                    {children}
                </div>
            );
        };

        let renderInput = props => {
            const { ref, ...rest } = props;
            return (
                <TextField
                    {...rest}
                    fullWidth
                    inputRef={ref}
                    InputProps={{
                        endAdornment: this.state.loading ? (
                            <InputAdornment position="start">
                                {" "}
                                <CircularProgress size={30} />
                            </InputAdornment>
                        ) : (
                            ""
                        )
                    }}
                />
            );
        };

        let renderItem = (item, isHighlighted) => {

            item.fullName = item.type === 4 ? item.actName : item.fullName?item.fullName:item.firstName+" "+item.lastName
            return (
                <MenuItem
                    key={item.id ? item.id : item.fullName}
                    selected={isHighlighted}
                    component="div"
                    style={{
                        zIndex: 9999,
                        backgroundColor: isHighlighted ? "#ff0c9c" : "#ffffff"
                    }}
                >
                    <Avatar imgProps={{onError: this.addDefaultSrc}} alt={item.fullName} src={image.getProfileImage(item)} />
                    <ListItemText primary={item.fullName} secondary={this.props.searchFromConsultant?this.props.templateStore.getLabelType(item.type)?item.isConsultant?"Consultant":this.props.templateStore.getLabelType(item.type):"Click to invite":item.instruments} />
                </MenuItem>
            );
        };

        let getItemValue = item => {
            return item.type === 4 ? item.actName : item.fullName;
        };

        return (
            <AutoComplete
                wrapperHeight={this.props.height}
                id={this.props.id ? this.props.id : "profile-search"}
                items={this.state.suggestions}
                getItemValue={getItemValue}
                open={this.state.open}
                value={this.state.value}
                onChange={handleChange}
                onSelect={handleSelect}
                renderInput={renderInput}
                renderMenu={renderMenu}
                renderItem={renderItem}
                placeholder={this.props.placeholder}
                error = { this.props.error }
                helperText = { this.props.helperText }
            />
        );
    }
}

export default ProfileSearchBox;
