import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import { inject, observer } from "mobx-react";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import { Button, Grid, Tabs, Tab, Paper } from "@material-ui/core";
import PromotionCard from "../../components/promotions/PromotionCard";
import PendingPromotions from "../../components/promotions/PendingPromotions";
import PublishedPromotions from "../../components/promotions/PublishedPromotions";
import PastPromotions from "../../components/promotions/PastPromotions";

@withRouter
@inject("venueStore")
@observer
class Promotion extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/staff" }, { name: "Venue Promotions" }];

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            showSavePromotionModal: false,
            canCreatePromotion: false
        };
    }

    componentDidMount() {
        const { match } = this.props;

        this.props.venueStore.getUserVenuesBasedOnPermission(null, null, null, null, true).then(response => {
            this.setState({
                ...this.state,
                canCreatePromotion: response.length > 0
            });
        });

        switch (match.params.activeTab) {
            case "pending":
                this.setState({
                    ...this.state,
                    tabValue: 0
                });
                break;
            case "published":
                this.setState({
                    ...this.state,
                    tabValue: 1
                });
                break;
            case "past":
                this.setState({
                    ...this.state,
                    tabValue: 2
                });
                break;
            default:
                this.setState({
                    ...this.state,
                    tabValue: 0
                });
                break;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.activeTab !== this.props.match.params.activeTab) {
            switch (this.props.match.params.activeTab) {
                case "pending":
                    this.setState({
                        ...this.state,
                        tabValue: 0
                    });
                    break;
                case "published":
                    this.setState({
                        ...this.state,
                        tabValue: 1
                    });
                    break;
                case "past":
                    this.setState({
                        ...this.state,
                        tabValue: 2
                    });
                    break;
                default:
                    this.setState({
                        ...this.state,
                        tabValue: 0
                    });
                    break;
            }
        }
    }

    renderCurrentTab = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0:
                return <PendingPromotions />;
            case 1:
                return <PublishedPromotions />;
            case 2:
                return <PastPromotions />;
            default:
                return <PendingPromotions />;
        }
    };

    renderPublishedTab = () => (
        <Grid container direction="column">
            <Grid item xs={12} lg={6} xl={4}>
                <PromotionCard title="Published Promotions" />
            </Grid>
        </Grid>
    );

    renderCompletedTab = () => (
        <Grid container direction="column">
            <Grid item xs={12} lg={6} xl={4}>
                <PromotionCard title="Completed Promotions" />
            </Grid>
        </Grid>
    );

    handleTabChange = (event, value) => {
        switch (value) {
            case 0:
                this.props.history.replace("/promotion/draft");
                break;
            case 1:
                this.props.history.replace("/promotion/published");
                break;
            case 2:
                this.props.history.replace("/promotion/past");
                break;
            default:
                this.props.history.replace("/promotion/draft");
                break;
        }
        this.setState({
            tabValue: value
        });
    };

    handleOpenSavePromotionModal = () => {
        this.setState({
            ...this.state,
            showSavePromotionModal: true
        });
    };

    handleCloseSavePromotionModal = () => {
        this.setState({
            ...this.state,
            showSavePromotionModal: false
        });
    };

    handleOpenNewPromotionPage = () => {
        this.props.history.push("/promotion/new");
    };

    render() {
        const { tabValue, canCreatePromotion } = this.state;
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title="Venue Promotions">
                        {canCreatePromotion ? (
                            <Button classes={{ root: "pull-right" }} variant="text" color="primary" onClick={this.handleOpenNewPromotionPage}>
                                Add Promotions
                            </Button>
                        ) : null}
                    </Heading>
                    <Paper>
                        <Tabs value={tabValue} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab label="Draft" />
                            <Tab label="Published" />
                            <Tab label="Past" />
                        </Tabs>
                    </Paper>
                    <br />
                    {this.renderCurrentTab()}
                </MainContainer>
            </Fragment>
        );
    }
}

export default Promotion;
