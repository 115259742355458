import React, { Component } from "react";
import { Avatar, Card, Divider, Typography } from "@material-ui/core";

import Moment from "react-moment";

import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

class AgentInvitationMessage extends Component {
    render() {
        return (
            <Card
                style={{
                    minWidth: "300px"
                }}
            >
                <CardHeader
                    avatar={
                        <Avatar
                            src={this.props.from && this.props.from.profileImage ? this.props.from.profileImage.url : ""}
                            style={{
                                width: 60,
                                height: 60
                            }}
                        />
                    }
                    title={this.props.from ? this.props.from.profileName : ""}
                    subheader={this.props.to ? this.props.to.actName : ""}
                    action={
                        <Typography>
                            <Moment date={this.props.createdAt} format={""} />
                        </Typography>
                    }
                    Z
                />
                <Divider />
                <CardContent>
                    <div dangerouslySetInnerHTML={{ __html: this.props.message }} />
                </CardContent>
            </Card>
        );
    }
}

export default AgentInvitationMessage;
