import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
import SelectField from "../../components/fields/SelectField";

import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { ProfileType } from "../../types/enum";

@inject("userStore", "xeroStore", "profileStore","templateStore")
@observer
class InvoiceMapping extends Component {
    state = {
        XeroActive: false,
        XeroAccount: "",
        hotgiggityInvoice: [
            { value: "OutgoingInvoices", label: "Outgoing Invoices" },
            { value:  "IncomingInvoices", label: "Incoming Invoices" },
        ],
        selectedXeroIncoming: "",
        selectedXeroOutgoing: "",
        showConfirmationDialog: false,
        isEdit: false
    };
    
    componentDidMount = () => {
        this.props.templateStore.showLoadingScreen()
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        if(currentProfile.outgoingInvoiceXero && currentProfile.incomingInvoiceXero){
            this.props.xeroStore.setOutgoingAccounts(currentProfile.outgoingInvoiceXero)
            this.props.xeroStore.setIncomingAccounts(currentProfile.incomingInvoiceXero)
             this.setState({
                isEdit : true
            });
        }
      
        this.props.xeroStore.getXeroListOfAccounts(currentProfile.id).finally(() => {
            this.props.templateStore.hideLoadingScreen()
        })
    };

    handleOutgoingXeroChange = (value) => {
        this.props.xeroStore.setOutgoingAccounts(value)
    }
    handleIncomingXeroChange = (value) => {
        this.props.xeroStore.setIncomingAccounts(value)
    }

    handleCloseConfirmation = () => this.setState({ ...this.state, showConfirmationDialog: false });
    handleOpenConfirmation = () => this.setState({ ...this.state, showConfirmationDialog: true });


    handleSaveData = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        this.props.templateStore.showLoadingScreen();
        this.props.xeroStore.mapAccounts(currentProfile.id).then(result => {
            this.props.profileStore.loadProfiles().finally(() => {
                this.props.templateStore.openSnackbar("Account Successfully Mapped");
                this.props.templateStore.hideLoadingScreen();
                this.handleCloseConfirmation();
            })
          
        })
       
    }
    render() {
        const {isEdit} = this.state
        const {xeroListofAccountsIncoming,xeroListofAccountsOutgoing,outgoingAccounts,incomingAccounts} = this.props.xeroStore
        let currentProfile = this.props.profileStore.getCurrentProfile();
        const { currentUser } = this.props.userStore;
        return (
            <div >
                <Grid item md={12} style={{ marginTop: 25, marginBottom: 200}}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                 Mapping your Hot Giggity incoming and outgoing invoice with your Xero chat of accounts allows the system to correctly assign your invoices to your Xero chart of accounts
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                            <Grid container spacing={2}>
                                            
                                                <Grid item md={12}>
                                                    <Typography gutterBottom>Step 2. Map your Hot Giggity Incoming and Outgoing Invoices to your Xero chart of accounts</Typography>  
                                                </Grid>
                                            </Grid>     
                                            <Grid container spacing={2} style={{marginTop:10}}>
                                            
                                                <Grid item md={3} xs={12} style={{textAlign:"center"}}>
                                                    <Typography style={{color:"grey",fontSize: "0.725rem"}} gutterBottom>Hot Giggity Invoices</Typography>  
                                                </Grid>
                                                <Grid item md={3} xs={12} style={{textAlign:"center"}}>
                                                </Grid>
                                                <Grid item md={3} xs={12} style={{textAlign:"center"}}>
                                                    <Typography style={{color:"grey",fontSize: "0.725rem"}} gutterBottom>Xero Chart of Accounts</Typography>  
                                                </Grid>
                                            </Grid>     
                                            { currentProfile?.type != ProfileType.Staff.ordinal ?
                                            <Grid container spacing={2} style={{marginTop:10}}>
                                            
                                                <Grid item md={3} xs={12} style={{textAlign:"center"}}>
                                                <TextField
                                                    // label="Outgoing Invoices"
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    value={"Outgoing Invoices"}
                                                    fullWidth
                                                />
                                                    
                                                </Grid>
                                                <Grid item md={3} xs={12} style={{textAlign:"center"}}>
                                                    <Typography style={{color:"grey"}} gutterBottom>Mapped to</Typography>  
                                                </Grid>
                                                <Grid item md={5} xs={12} style={{textAlign:"center"}}>
                                                <SelectField
                                                    id="XeroOutgoing"
                                                    value={outgoingAccounts}
                                                    suggestions={xeroListofAccountsOutgoing}
                                                    placeholder="Select Xero Accounts"
                                                    onChange={this.handleOutgoingXeroChange}
                                                />
                                                </Grid>
                                            </Grid>  
                                            : null }
                                            <Grid container spacing={2} style={{marginTop:10}}>
                                            
                                                <Grid item md={3} xs={12} style={{textAlign:"center"}}>
                                                    <TextField
                                                        // label="Outgoing Invoices"
                                                        inputProps={{
                                                            readOnly: true
                                                        }}
                                                        value={"Incoming Invoices"}
                                                        fullWidth
                                                    />
                                                
                                                </Grid>
                                                <Grid item md={3} xs={12} style={{textAlign:"center"}}>
                                                    <Typography style={{color:"grey"}} gutterBottom>Mapped to</Typography>  
                                                </Grid>
                                                <Grid item md={5} xs={12} style={{textAlign:"center"}}>
                                                    <SelectField
                                                        id="XeroIncoming"
                                                        value={incomingAccounts}
                                                        suggestions={xeroListofAccountsIncoming}
                                                        placeholder="Select Xero Accounts"
                                                        onChange={this.handleIncomingXeroChange}
                                                    />
                                                </Grid>
                                            </Grid>    
                                            <Grid container spacing={2} style={{marginTop:10}}>
                                                <Grid item md={5} xs={12} style={{textAlign:"center"}}>
                                                </Grid>
                                                <Grid item md={6} xs={12} style={{textAlign:"right"}}>
                                                    <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleOpenConfirmation} >
                                                        { isEdit ? "Update Mapping" : "Save Mapping"}
                                                    </Button> 
                                                </Grid>
                                            
                                            </Grid>  
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                </Grid>
                <ConfirmationModal
                    open={this.state.showConfirmationDialog}
                    title="Are you sure you want to save the chart of accounts mapping?"
                    message="These mapping settings will ensure that your incoming and outgoing invoices in Hot Giggity will be mapped to the selected chart of accounts in your accounting package."
                    closeHandler={this.handleCloseConfirmation}
                    confirmationHandler={this.handleSaveData}
                    declineHandler={this.handleCloseConfirmation}
                    confirmationLabel= { isEdit ? "Update Mapping" : "Save Mapping"}
                    declineLabel="Cancel"
                    maxWidth="sm"
                />
            </div>
        );
    }
}

export default InvoiceMapping;
