import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import { BookingStatusType, BookingType, LineupStatusType, ProfileType } from "../../types/enum";
import "./BookingDetails.css";
import ConfirmationModal from "../modal/ConfirmationModal";
import ConfirmationModalCheckbox from "../modal/ConfirmationModalCheckbox";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DeclineBookingModal from "../../components/booking/DeclineBookingModal.js"
import { Grid, Typography, Button, InputLabel, Hidden } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BookingEventDetails from "./BookingForm/BookingEventDetails";
import BookingDressInput from "./BookingForm/BookingDressInput";
import BookingNumberSet from "./BookingForm/BookingNumberSet";
import BookingFeeDetails from "./BookingForm/BookingFeeDetails";
import BookingDateDetails from "./BookingForm/BookingDateDetails";
import BookingActDetails from "./BookingForm/BookingActDetails";
import FacebookPostDialog from "../facebookPostDialog/FacebookPostDialog";
import CancelBookingModal from "./CancelBookingModal";
import Loading from "../loadingSpinner/Loading";

import Genre from "../controls/BookingGenres";
import Vibe from "../controls/BookingVibes";

const FACEBOOK_ENABLED = process.env.REACT_APP_FACEBOOK_ENABLED === "true";

@inject("profileStore", "bookingRequestStore", "templateStore", "facebookStore", "userStore", "invoiceStore")
@withRouter
@observer
class BookingDetails extends Component {
    state = {
        cancelBookingModalState: false,
        declineBookingModalState: false,
        cancelLineupModalState: false,
        declineLineupModalState: false,
        IsBookingRequest: false,
        acceptTermsCondition: false,
        facebookType: undefined,
        openDialogInvoiceCreated: false,
        openDialogInvoiceCreatedLineup: false,
        openDialogConfirmationInvoiceCreated: false
    };

    componentDidMount() {
        const { match } = this.props;
        const { currentUser } = this.props.userStore;
        var currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.bookingRequestStore.load(this.props.bookingId, match.params.bookingRequestId).then(() => {
            const { booking } = this.props.bookingRequestStore;
            this.props.facebookStore.setBooking(booking);
            // let formattedVenueName = booking.venueName ? "#" + booking.venueName?.replace(/ /g, "") : booking.venue ? "#" + booking.venue?.profileName?.replace(/ /g, "") : "";
            // let formattedActName = booking.actName ? "#" + booking.actName.replace(/ /g, "") : booking.act ? "#" + booking.act?.actName?.replace(/ /g, "") : "";
            // let message = `Hi folks!  I am playing a gig ${booking.venueName ? booking.venueName : booking.venue ? booking.venue?.profileName : ""} on the ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")} from ${moment(
            //     booking.timeStart,
            //     "HH:mm:ss" 
            // ).format("hh:mm A")} to ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}. Look forward to seeing you there! \n \n \n #hotgiggity ${formattedActName} ${formattedVenueName} #livegigs`;
            let formattedVenueName = booking.venueName ? "#" + booking.venueName?.replace(/ /g, "") : booking.venue ? "#" + booking.venue?.profileName?.replace(/ /g, "") : "";
            let formattedActName = booking.actName ? "#" + booking.actName.replace(/ /g, "") : "";
            let formattedActName2 = booking.actName ? booking.actName : booking.act ? booking.act?.actName : ""
            let bookTableUrl = booking.venue && booking.venue.bookTableUrl ? booking.venue.bookTableUrl  : ""
            let message = `Hi Folks, Catch ${formattedActName2} playing here on ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")}  from ${moment(
                booking.timeStart,
                "HH:mm:ss" 
            ).format("hh:mm A")} to  ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}.  Come and enjoy some great live entertainment - get in early and book a table! \n \n ${bookTableUrl} \n \n ${formattedActName} ${formattedVenueName} #livegigs #livegigs #livemusic #liveentertainment #hotgiggity`
            
            if(currentProfile.type === ProfileType.Musician.ordinal){
                message = `Hi Folks!  I am playing a gig at ${formattedVenueName} on the ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")}  from ${moment(
                    booking.timeStart,
                    "HH:mm:ss" 
                ).format("hh:mm A")} to  ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}.  See you there!! \n \n #livegigs #livemusic #liveentertainment #hotgiggity `
            }
            this.props.facebookStore.setPostMessage(message);

            const IsBookingRequest =
                match.params !== undefined &&
                match.params.bookingRequestId !== undefined &&
                booking.bookingRequests !== undefined &&
                booking.bookingRequests.filter(x => x.id === parseInt(match.params.bookingRequestId, 10) && !x.deleted)[0] !== undefined;

            if (match.params && match.params.bookingRequestId && !IsBookingRequest) {
                if (
                    booking.act.userId === currentUser.sub &&
                    (booking.status === BookingStatusType.Accepted.ordinal || booking.status === BookingStatusType.Ready.ordinal || booking.status === BookingStatusType.Hold.ordinal)
                ) {
                    this.props.history.replace(`/musician/booking/${booking.id}/details`);
                } else {
                    this.props.history.replace("/musician/booking/claimed");
                }
            } else {
                this.setState({
                    ...this.state,
                    IsBookingRequest
                });
            }
        });
    }

    handleBookingAccepted = e => {
        const { booking } = this.props.bookingRequestStore;
        var currentProfile  = this.props.profileStore.getCurrentProfile()
        this.props.templateStore.openSnackbar("Booking accepted!");
        // this.props.history.push(`/musician/booking/${booking.id}/lineup`);
        if(currentProfile.checkboxInvoice){
            this.handleCloseDialogInvoiceCreated()
        }else{
            this.handleOpenDialogInvoiceCreated();
        }
    };

      handleBookingAcceptedLineup = e => {
        const { booking } = this.props.bookingRequestStore;
        this.props.templateStore.openSnackbar("Booking accepted!");
        // this.props.history.push(`/musician/booking/${booking.id}/lineup`);
        
        this.handleOpenDialogInvoiceCreatedLineup();
    };

    handleOpenDialogInvoiceCreated = () => {
        var currentProfile = this.props.profileStore.getCurrentProfile();
        if(currentProfile.checkboxInvoice){
            this.handleCloseDialogInvoiceCreated()
        }else{
            this.setState({ openDialogInvoiceCreated: true });
        }
    }
    handleCloseDialogInvoiceCreated = () => {
           const { booking } = this.props.bookingRequestStore;
        this.setState({ openDialogInvoiceCreated: false });
        if(booking.act?.lineUpCount > 1){
            this.props.history.replace(`/musician/booking/${booking.id}/lineup`);
            this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
            if (booking.isAdmin) {
                this.props.templateStore.closeLineupBlock(); // block for unsaved lineup
            }
        }else{
            this.props.history.replace(`/musician/booking/${booking.id}/promote`);
            this.props.templateStore.setEditMusicianBookingActiveStep(3); // skip negotiate
            if (booking.isAdmin) {
                this.props.templateStore.closeLineupBlock(); // block for unsaved lineup
            }
        }
      
        
    }

    handleCloseDialogConfirmationInvoiceCreated = () => {
        const { booking } = this.props.bookingRequestStore;
     this.setState({ openDialogConfirmationInvoiceCreated: false });
     this.props.history.replace(`/musician/booking/${booking.id}/lineup`);
     this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
     if (booking.isAdmin) {
         this.props.templateStore.closeLineupBlock(); // block for unsaved lineup
     }
     
 }

    handleOpenDialogInvoiceCreatedLineup = () => {
        var currentProfile = this.props.profileStore.getCurrentProfile();
        if(currentProfile.checkboxInvoice){
            this.handleCloseDialogInvoiceCreatedLineup()
        }else{
            this.setState({ openDialogInvoiceCreatedLineup: true });
        }
        
    }
    handleCloseDialogInvoiceCreatedLineup = () => {
           const { booking } = this.props.bookingRequestStore;
        this.setState({ openDialogInvoiceCreatedLineup: false });
        this.props.templateStore.openSnackbar("Booking accepted!");
        this.props.history.replace(`/musician/booking/${booking.id}/promote`);
        this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
        
    }

    handleRedirectToInvoice = () => {
        const { currentProfile } = this.props.profileStore;
        this.props.invoiceStore.getInvoiceByBookingAndProfileId(this.props.bookingId, currentProfile.id).then((result) => {
            this.props.history.replace(`/invoice/${result[0]}/detail`);
        });
    }

    handleRedirectPopup = () => {
        this.setState({ openDialogInvoiceCreated: false });
        this.setState({ openDialogConfirmationInvoiceCreated: true });
    }

    handleRedirectToInvoiceLineUp = () => {
        const { currentProfile } = this.props.profileStore;
        this.props.invoiceStore.getInvoiceByBookingAndProfileId(this.props.bookingId, currentProfile.id).then((result) => {
            this.props.history.replace(`/invoice/${result[0]}/detail`);
        });
    }
    
    handleAcceptBooking = e => {
        const { match } = this.props;
        const { currentProfile } = this.props.profileStore;
        const { booking } = this.props.bookingRequestStore;
        const { IsBookingRequest } = this.state;
        let { status, bookingRequests } = booking;

        const bookingRequest =
            // status === BookingStatusType.Resent.ordinal &&
                bookingRequests !== undefined &&
                bookingRequests !== null &&
                match.params !== undefined &&
                match.params !== null &&
                match.params.bookingRequestId !== undefined &&
                match.params.bookingRequestId !== null
                ? bookingRequests.filter(x => parseInt(x.id) === parseInt(match.params.bookingRequestId, 10))[0]
                : undefined;
        const userProfile = IsBookingRequest ? bookingRequest.act : booking.act;

        this.setState({ facebookType: "musician" });
        // if (((booking.act.facebookPageId && !IsBookingRequest) || (IsBookingRequest && bookingRequest.act.facebookPageId)) && FACEBOOK_ENABLED && booking.sharePermission) {
        //     this.handleSetFacebookPost();
        //     this.props.facebookStore.openFacebookPostDialog(userProfile);
        // } else {
        //     if (match.params && match.params.bookingRequestId) {
        //         this.props.bookingRequestStore.acceptChangeActRequestWithProfileId(match.params.bookingRequestId, currentProfile.id).then(() => {
        //             this.handleBookingAccepted();
        //         });
        //     } else {
        //         this.props.bookingRequestStore.saveStatus(currentProfile.id, BookingStatusType.Accepted.ordinal).then(() => {
        //             this.handleBookingAccepted();
        //         });
        //     }
        // }

        if (match.params && match.params.bookingRequestId) {
            this.props.bookingRequestStore.acceptChangeActRequestWithProfileId(match.params.bookingRequestId, currentProfile.id).then(() => {
                this.handleBookingAccepted();
            });
        } else {
            this.props.bookingRequestStore.saveStatus(currentProfile.id, BookingStatusType.Accepted.ordinal).then(() => {
                this.handleBookingAccepted();
            });
        }
    };

    handleSetFacebookPost = () => {
        const { booking } = this.props.bookingRequestStore;
        var currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.facebookStore.setBooking(booking);
        // let formattedVenueName = booking.venueName ? "#" + booking.venueName?.replace(/ /g, "") : booking.venue ? "#" + booking.venue?.profileName?.replace(/ /g, "") : "";
        // let formattedActName = booking.actName ? "#" + booking.actName.replace(/ /g, "") : booking.act ? "#" + booking.act?.actName?.replace(/ /g, "") : "";
        // let message = `Hi folks!  I am playing a gig ${booking.venueName ? booking.venueName : booking.venue ? booking.venue?.profileName : ""} on the ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")} from ${moment(
        //     booking.timeStart,
        //     "HH:mm:ss" 
        // ).format("hh:mm A")} to ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}. Look forward to seeing you there! \n \n \n #hotgiggity ${formattedActName} ${formattedVenueName} #livegigs`;
        let formattedVenueName = booking.venueName ? "#" + booking.venueName?.replace(/ /g, "") : booking.venue ? "#" + booking.venue?.profileName?.replace(/ /g, "") : "";
        let formattedActName = booking.actName ? "#" + booking.actName.replace(/ /g, "") : "";
        let formattedActName2 = booking.actName ? booking.actName : booking.act ? booking.act?.actName : ""
        let bookTableUrl = booking.venue && booking.venue.bookTableUrl ? booking.venue.bookTableUrl  : ""
        let message = `Hi Folks, Catch ${formattedActName2} playing here on ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")}  from ${moment(
            booking.timeStart,
            "HH:mm:ss" 
        ).format("hh:mm A")} to  ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}.  Come and enjoy some great live entertainment - get in early and book a table! \n \n ${bookTableUrl} \n \n ${formattedActName} ${formattedVenueName} #livegigs #livegigs #livemusic #liveentertainment #hotgiggity`
        if(currentProfile.type === ProfileType.Musician.ordinal){
            message = `Hi Folks!  I am playing a gig at ${formattedVenueName} on the ${moment(new Date(booking.dateStart)).format("DD-MM-YYYY")}  from ${moment(
                booking.timeStart,
                "HH:mm:ss" 
            ).format("hh:mm A")} to  ${moment(booking.timeEnd, "HH:mm:ss").format("hh:mm A")}.  See you there!! \n \n #livegigs #livemusic #liveentertainment #hotgiggity `
        }
        this.props.facebookStore.setPostMessage(message);
    }

    handleHoldBooking = e => {
        const { currentProfile } = this.props.profileStore;
        const { booking } = this.props.bookingRequestStore;
        this.props.bookingRequestStore.saveStatus(currentProfile.id, BookingStatusType.Hold.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking hold!");
            this.props.history.replace(`/musician/booking/${booking.id}/negotiate`);
            this.props.templateStore.setEditMusicianBookingActiveStep(1);
        });
    };

    handleDeclineBooking = e => {
        const { match } = this.props;
        const { currentProfile } = this.props.profileStore;
        if (match.params && match.params.bookingRequestId) {
            this.props.bookingRequestStore.declineChangeActRequest(match.params.bookingRequestId).then(() => {
                this.props.templateStore.openSnackbar("Booking declined!");
                this.props.history.push("/musician/gig");
            });
        } else {
            this.props.bookingRequestStore.saveStatus(currentProfile.id, BookingStatusType.Declined.ordinal).then(() => {
                this.props.bookingRequestStore.setCancellationReason(undefined);
                this.props.templateStore.openSnackbar("Booking declined!");
                //this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
                this.props.history.push("/musician/gig");
            });
        }
    };

    handleAcceptLineup = e => {
        const { currentProfile } = this.props.profileStore;

        this.props.bookingRequestStore.saveLineupStatus(LineupStatusType.Accepted.ordinal).then(() => {
            this.handleBookingAcceptedLineup();
        });
        // this.setState({ facebookType: "lineup" });
        // if (currentProfile.facebookPageId && FACEBOOK_ENABLED) {
        //     this.handleSetFacebookPost();
        //     this.props.facebookStore.openFacebookPostDialog(currentProfile);
        // } else {
        //     this.props.bookingRequestStore.saveLineupStatus(LineupStatusType.Accepted.ordinal).then(() => {
        //         this.handleBookingAcceptedLineup();
        //     });
        // }
    };

    handleLineupAccepted = e => {
        const { booking } = this.props.bookingRequestStore;
        this.props.templateStore.hideLoadingScreen();
        this.props.templateStore.openSnackbar("Booking accepted!");
        this.props.history.replace(`/musician/booking/${booking.id}/promote`);
        this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
    };

    handleDeclineLineup = e => {
        this.props.bookingRequestStore.saveLineupStatus(LineupStatusType.Declined.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking declined!");
            this.props.history.push("/musician/gig");
        });
    };

    handleCancelLineup = e => {
        this.props.bookingRequestStore.saveLineupStatus(LineupStatusType.Cancelled.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking cancelled!");
            this.props.history.push("/musician/gig");
        });
    };

    handleOpenCancelModal = () => this.setState({ cancelBookingModalState: true });
    handleCloseCancelModal = () => this.setState({ cancelBookingModalState: false });

    handleOpenDeclineModal = () => this.setState({ declineBookingModalState: true });
    handleCloseDeclineModal = () => this.setState({ declineBookingModalState: false });

    handleCancelBooking = e => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.saveStatus(currentProfile.id, BookingStatusType.Cancelled.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking cancelled!");
            this.props.history.push("/musician/gig");
        });
    };

    handleOpenCancelLineupModal = () => this.setState({ cancelLineupModalState: true });
    handleCloseCancelLineupModal = () => this.setState({ cancelLineupModalState: false });

    handleOpenDeclineLineupModal = () => this.setState({ declineLineupModalState: true });
    handleCloseDeclineLineupModal = () => this.setState({ declineLineupModalState: false });

    isButtonCancelDisabled = () => {
        // const { currentProfile } = this.props.profileStore;
        // const { dateStart } = this.props.bookingRequestStore.booking;
        // let diffDate = moment(dateStart).diff(moment(new Date()), 'days');
        // return (currentProfile.type === ProfileType.Musician.ordinal && diffDate < 7);
        return false;
    };

    handleChangeTermCondition = event => {
        this.setState({ acceptTermsCondition: event.target.checked });
    };

    render() {
        let {
            id,
            requirements,
            notes,
            isAdmin,
            myLineup,
            venueContract,
            agentContract,
            status,
            dateStart,
            cancelledBy,
            cancellationReason,
            createdBy,
            act,
            lineups,
            type,
            bookingRequests
        } = this.props.bookingRequestStore.booking;

        const tooltipText = this.state.acceptTermsCondition ? "" : `Please accept the terms and conditions before accepting the booking`;

        const { isLoading } = this.props.bookingRequestStore;

        let currentProfile = this.props.profileStore.getCurrentProfile();
        const { match } = this.props;
        const { IsBookingRequest, facebookType } = this.state;

        const showCancellation = status === BookingStatusType.Declined.ordinal || status === BookingStatusType.Cancelled.ordinal || status === BookingStatusType.CancelledByActHistory.ordinal;
        
        //for checking if profile is on lineup booking
        let onLineup = true;
        if (lineups && lineups.length  && lineups[0] !== undefined){
            if (lineups.find(x => x.profileId == currentProfile.id)){
                onLineup = true;
            }
        }
        
        return (
            <main style={{ position: "relative", minHeight: "200px" }}>
                <Loading showed={isLoading} />
                {FACEBOOK_ENABLED && (
                    <FacebookPostDialog
                        profileType={facebookType}
                        bookingId={match.params.bookingRequestId || match.params.id}
                        callback={this.handleBookingAccepted}
                        lineupCallback={this.handleBookingAcceptedLineup}
                    />
                )}
                {showCancellation ? (
                    <Grid container spacing={8} style={{ marginBottom: 60 }}>
                        <Grid item xs={12} lg={3}>
                            <div>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Cancelled By:
                                </Typography>
                                <Typography gutterBottom>
                                    {cancelledBy && cancelledBy.type === ProfileType.Agent.ordinal
                                        ? cancelledBy.profileName
                                        : cancelledBy && cancelledBy.user
                                            ? `${cancelledBy.user.firstName} ${cancelledBy.user.lastName}`
                                            : ""}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <InputLabel shrink>Cancellation Reason</InputLabel>
                            <Typography className="scrollable-div" component="div" dangerouslySetInnerHTML={{ __html: cancellationReason }} />
                        </Grid>
                    </Grid>
                ) : (
                    ""
                )}
                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Date and Time Detail
                            </Typography>
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <BookingDateDetails readOnly={true} />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDate">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Event Detail
                            </Typography>
                           
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <BookingEventDetails readOnly={true} />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDetail">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Act Details
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <BookingActDetails readOnly={true} />
                    </Grid>
                </Grid>

                {/* Don't show social section on musician booking details */}
                {/* <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventSocial">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Social
                            </Typography>
                            <Typography gutterBottom>
                                Share this booking information to social media
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <BookingSocialDetail readOnly={true} />
                    </Grid>
                </Grid> */}

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                {currentProfile.type === ProfileType.Musician.ordinal ? "Fee Detail" : "Fee and Commission Detail"}
                            </Typography>
                           
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <BookingFeeDetails readOnly={true} />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventRequirementDetail">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Gig Requirement
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={8}>
                            <BookingNumberSet readOnly={true} />
                            <BookingDressInput />
                            <Vibe id="vibes" disabled="true"/>
                            <Genre id="genres" disabled="true"/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={8} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} lg={3}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Production Requirements
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} lg={12}>
                                <InputLabel shrink>Production Requirements</InputLabel>
                                <Typography className="scrollable-div" component="div" dangerouslySetInnerHTML={{ __html: requirements }} />
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid container spacing={8} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} lg={3}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Notes
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} lg={12}>
                                <InputLabel shrink>Notes</InputLabel>
                                <Typography className="scrollable-div" component="div" dangerouslySetInnerHTML={{ __html: notes }} />
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>
                {(createdBy?.type == ProfileType.Agent.ordinal || createdBy?.type == ProfileType.Staff.ordinal && currentProfile?.id == act?.actAdminId) || (onLineup && type == BookingType.Normal.ordinal && createdBy?.type == ProfileType.Staff.ordinal ) || (isAdmin && bookingRequests && bookingRequests[bookingRequests.length - 1] && (bookingRequests[bookingRequests.length - 1]?.act?.actAdminId == currentProfile.id) ) ? 
                    <Fragment>
                        <Grid container spacing={8} style={{ marginBottom: 20 }}>
                            <Grid item xs={12} lg={3}>
                                <div>
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Venue Contract
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} lg={12}>
                                        <InputLabel shrink>Venue Contract</InputLabel>
                                        <Typography className="scrollable-div" component="div" dangerouslySetInnerHTML={{ __html: venueContract }} />
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid container spacing={8} style={{ marginBottom: 20 }}>
                            <Grid item xs={12} lg={3}>
                                <div>
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Agent Contract
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} lg={12}>
                                        <InputLabel shrink>Agent Contract</InputLabel>
                                        <Typography className="scrollable-div" component="div" dangerouslySetInnerHTML={{ __html: agentContract }} />
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </Grid>
                    </Fragment> 
                    : null
                }   
                            {IsBookingRequest ? (
                                <Grid item xs={12} className="stepperContent">
                                    <Hidden smDown>
                                        <div className="action">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.acceptTermsCondition}
                                                        onChange={this.handleChangeTermCondition}
                                                        value="AcceptTermsCondition"
                                                    />
                                                }
                                                labelPlacement="start"
                                                label="I agree to the terms and conditions above"
                                            />
                                            <Divider variant="middle" />
                                        </div>
                                        <div className="action">
                                            <Button variant="outlined" style={{ marginRight: 10 }} onClick={this.handleOpenDeclineModal}>
                                                Decline Booking
                                            </Button>

                                            <Button variant="contained" color="primary" onClick={this.handleAcceptBooking} disabled={!this.state.acceptTermsCondition}>
                                                Accept Booking
                                            </Button>
                                        </div>
                                    </Hidden>
                                    <Hidden mdUp>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleAcceptBooking}
                                            fullWidth={true}
                                            style={{ marginBottom: "12px" }}
                                        >
                                            Accept Booking
                                        </Button>
                                        <Button variant="outlined" onClick={this.handleOpenDeclineModal} fullWidth={true}>
                                            Decline Booking
                                        </Button>
                                    </Hidden>
                                    {/* <ConfirmationModal
                                        open={this.state.declineBookingModalState}
                                        title="Are you sure you want to decline this booking?"
                                        message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                        closeHandler={this.handleCloseDeclineModal}
                                        confirmationHandler={this.handleDeclineBooking}
                                        declineHandler={this.handleCloseDeclineModal}
                                        confirmationLabel="Decline Booking"
                                        declineLabel="Do not Decline"
                                        maxWidth="sm"
                                    /> */}
                                     <DeclineBookingModal
                                                open={this.state.declineBookingModalState}
                                                onClose={this.handleCloseDeclineModal}
                                                postCancelAction={this.handleDeclineBooking}
                                            />
                                </Grid>
                            ) : (
                                ""
                            )}
                            {isAdmin &&
                                !IsBookingRequest && (  
                                    <Grid item xs={12} className="stepperContent">
                                        {(status === 0 || status === BookingStatusType.Hold.ordinal || status === BookingStatusType.Resent.ordinal) && (
                                            <Fragment>
                                                <Hidden smDown>
                                                    <div className="action">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.acceptTermsCondition}
                                                                    onChange={this.handleChangeTermCondition}
                                                                    value="AcceptTermsCondition"
                                                                />
                                                            }
                                                            labelPlacement="start"
                                                            label="I agree to the venue/agents terms and conditions above"
                                                        />
                                                        <Divider variant="middle" />
                                                    </div>
                                                    <div className="action">
                                                        <Button variant="outlined" style={{ marginRight: 10 }} onClick={this.handleOpenDeclineModal}>
                                                            Decline Booking
                                                        </Button>
                                                        {status === BookingStatusType.New.ordinal ? (
                                                            <Button
                                                                variant="outlined"
                                                                component={Link}
                                                                to={`/musician/booking/${id}/negotiate`}
                                                                style={{ marginRight: 10 }}
                                                                onClick={this.handleHoldBooking}
                                                            >
                                                                Hold and Negotiate
                                                            </Button>
                                                        ) : null}
                                                        <Tooltip title={tooltipText}>
                                                            <span>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.handleAcceptBooking}
                                                                    disabled={!this.state.acceptTermsCondition}
                                                                >
                                                                    Accept Booking
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Fragment>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.acceptTermsCondition}
                                                                    onChange={this.handleChangeTermCondition}
                                                                    value="AcceptTermsCondition"
                                                                />
                                                            }
                                                            labelPlacement="end"
                                                            label="I agree to the venue/agents terms and conditions above"
                                                        />
                                                        <Divider variant="middle" />
                                                        <Tooltip title={tooltipText}>
                                                            <span>
                                                                <Button
                                                                    variant="contained"
                                                                    fullWidth={true}
                                                                    color="primary"
                                                                    style={{ marginBottom: "12px" }}
                                                                    onClick={this.handleAcceptBooking}
                                                                    disabled={!this.state.acceptTermsCondition}
                                                                >
                                                                    Accept Booking
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                        {status === BookingStatusType.New.ordinal ? (
                                                            <Button
                                                                variant="outlined"
                                                                component={Link}
                                                                fullWidth={true}
                                                                style={{ marginBottom: "12px" }}
                                                                to={`/musician/booking/${id}/negotiate`}
                                                                onClick={this.handleHoldBooking}
                                                            >
                                                                Hold and Negotiate
                                                            </Button>
                                                        ) : null}
                                                        <Button variant="outlined" fullWidth={true} onClick={this.handleOpenDeclineModal}>
                                                            Decline Booking
                                                        </Button>
                                                    </Fragment>
                                                </Hidden>
                                                {/* <ConfirmationModal
                                                    open={this.state.declineBookingModalState}
                                                    title="Are you sure you want to decline this booking?"
                                                    message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                                    closeHandler={this.handleCloseDeclineModal}
                                                    confirmationHandler={this.handleDeclineBooking}
                                                    declineHandler={this.handleCloseDeclineModal}
                                                    confirmationLabel="Decline Booking"
                                                    declineLabel="Do not Decline"
                                                    maxWidth="sm"
                                                /> */}
                                                  <DeclineBookingModal
                                                        open={this.state.declineBookingModalState}
                                                        onClose={this.handleCloseDeclineModal}
                                                        postCancelAction={this.handleDeclineBooking}
                                                    />
                                            </Fragment>
                                        )}
                                        {(status === 3 || status === 6) &&
                                            moment(dateStart) > moment() && (
                                                <Fragment>
                                                    <Hidden smDown>
                                                        <div className="action">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={this.isButtonCancelDisabled()}
                                                                onClick={this.handleOpenCancelModal}
                                                            >
                                                                Cancel Booking
                                                            </Button>
                                                        </div>
                                                    </Hidden>
                                                    <Hidden mdUp>
                                                        <Fragment>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={this.isButtonCancelDisabled()}
                                                                onClick={this.handleOpenCancelModal}
                                                                fullWidth={true}
                                                            >
                                                                Cancel Booking
                                                            </Button>
                                                        </Fragment>
                                                    </Hidden>
                                                    <CancelBookingModal
                                                        open={this.state.cancelBookingModalState}
                                                        onClose={this.handleCloseCancelModal}
                                                    />
                                                </Fragment>
                                            )}
                                    </Grid>
                                )}
                            {!isAdmin &&
                                !IsBookingRequest &&
                                myLineup && (
                                    <Grid item xs={12} className="stepperContent">
                                        {myLineup.status === 0 && (
                                            <Fragment>
                                                <Hidden smDown>
                                                    <div className="action">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.acceptTermsCondition}
                                                                    onChange={this.handleChangeTermCondition}
                                                                    value="AcceptTermsCondition"
                                                                />
                                                            }
                                                            labelPlacement="start"
                                                            label={(onLineup || act?.actAdminId == currentProfile?.id) && type == BookingType.Normal.ordinal && createdBy?.type == ProfileType.Staff.ordinal ? "I agree to the terms and conditions above" : "I agree to the venue/agents terms and conditions above"}
                                                        />
                                                        <Divider variant="middle" />
                                                    </div>
                                                    <div className="action">
                                                        <Button
                                                            variant="outlined"
                                                            onClick={this.handleOpenDeclineLineupModal}
                                                            style={{ marginRight: 10 }}
                                                        >
                                                            Decline Booking
                                                        </Button>
                                                        <Tooltip title={tooltipText}>
                                                            <span>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.handleAcceptLineup}
                                                                    disabled={!this.state.acceptTermsCondition}
                                                                >
                                                                    Accept Booking
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Fragment>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.acceptTermsCondition}
                                                                    onChange={this.handleChangeTermCondition}
                                                                    value="AcceptTermsCondition"
                                                                />
                                                            }
                                                            labelPlacement="end"
                                                            label="I agree to the venue/agents terms and conditions above"
                                                        />
                                                        <Divider variant="middle" />
                                                        <Tooltip title={tooltipText}>
                                                            <span>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.handleAcceptLineup}
                                                                    fullWidth={true}
                                                                    style={{ marginBottom: "12px" }}
                                                                    disabled={!this.state.acceptTermsCondition}
                                                                >
                                                                    Accept Booking
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                        <Button variant="outlined" onClick={this.handleOpenDeclineLineupModal} fullWidth={true}>
                                                            Decline Booking
                                                        </Button>
                                                    </Fragment>
                                                </Hidden>
                                                <ConfirmationModal
                                                    open={this.state.declineLineupModalState}
                                                    title="Are you sure you want to decline this booking?"
                                                    message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                                    closeHandler={this.handleCloseDeclineLineupModal}
                                                    confirmationHandler={this.handleDeclineLineup}
                                                    declineHandler={this.handleCloseDeclineLineupModal}
                                                    confirmationLabel="Decline Booking"
                                                    declineLabel="Do not Decline"
                                                    maxWidth="sm"
                                                />
                                                  <DeclineBookingModal
                                                        open={this.state.declineBookingModalState}
                                                        onClose={this.handleCloseDeclineModal}
                                                        postCancelAction={this.handleDeclineBooking}
                                                    />
                                            </Fragment>
                                        )}
                                        {// Lineup Accepted
                                            myLineup.status === 3 && (
                                                <Fragment>
                                                    <Hidden smDown>
                                                        <div className="action">
                                                            <Button variant="contained" color="primary" onClick={this.handleOpenCancelModal}>
                                                                Cancel Booking
                                                        </Button>
                                                        </div>
                                                    </Hidden>
                                                    <Hidden mdUp>
                                                        <Button variant="contained" color="primary" onClick={this.handleOpenCancelModal} fullWidth={true}>
                                                            Cancel Booking
                                                    </Button>
                                                    </Hidden>
                                                    <ConfirmationModal
                                                        open={this.state.cancelBookingModalState}
                                                        title="Are you sure you want to cancel this booking?"
                                                        message="By cancelling this booking you will remove it from your gig list an will not be able to access it anymore."
                                                        closeHandler={this.handleCloseCancelModal}
                                                        confirmationHandler={this.handleCancelLineup}
                                                        declineHandler={this.handleCloseCancelModal}
                                                        confirmationLabel="Cancel Booking"
                                                        maxWidth="sm"
                                                    />
                                                </Fragment>
                                            )}
             
                                  
                                    </Grid>
                                    
                                )}
                          <ConfirmationModalCheckbox
                                            open={this.state.openDialogInvoiceCreated}
                                            title="Invoice created"
                                            message="The invoice for this booking has been generated."
                                            closeHandler={this.handleCloseDialogInvoiceCreated}
                                            confirmationHandler={this.handleRedirectPopup}
                                            declineHandler={this.handleCloseDialogInvoiceCreated}
                                            confirmationLabel="View Invoice"
                                            declineLabel="Dismiss & select players for this gig"
                                            maxWidth="sm"
                                        />   

                        <ConfirmationModalCheckbox
                                    open={this.state.openDialogInvoiceCreatedLineup}
                                    title="Invoice created"
                                    message="The invoice for this booking has been generated."
                                    closeHandler={this.handleCloseDialogInvoiceCreatedLineup}
                                    confirmationHandler={this.handleRedirectToInvoiceLineUp}
                                    declineHandler={this.handleCloseDialogInvoiceCreatedLineup}
                                    confirmationLabel="View Invoice"
                                    declineLabel="Dismiss"
                                    maxWidth="sm"
                                />   

                        <ConfirmationModalCheckbox
                                open={this.state.openDialogConfirmationInvoiceCreated}
                                title="Warning"
                                message="You have not selected your players for this gig. Would you like to select them now?"
                                closeHandler={this.handleRedirectToInvoice}
                                confirmationHandler={this.handleCloseDialogConfirmationInvoiceCreated}
                                declineHandler={this.handleRedirectToInvoice}
                                confirmationLabel="Select Players Now"
                                declineLabel="No - I will do it later"
                                maxWidth="sm"
                            />   


            </main>
        );
    }
}

export default BookingDetails;
