import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, TextField, MenuItem } from "@material-ui/core";

import Genre from "../../controls/BookingGenres";
import Vibe from "../../controls/BookingVibes";

@inject("bookingRequestStore")
@observer
class PrivateBookingGigRequirement extends Component {
    handleDressChange = e => {
        this.props.bookingRequestStore.setBooking("dress", e.target.value);
    };

    handleSetNumberChange = e => {
        this.props.bookingRequestStore.setBooking("setNumber", e.target.value);
    };

    render() {
        const { booking } = this.props.bookingRequestStore;
        const { dress, setNumber } = booking;
        const { readOnly } = this.props;
       
        
        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        select
                        label="Number of Sets"
                        value={setNumber}
                        onChange={this.handleSetNumberChange}
                        fullWidth
                        margin="dense"
                        inputProps={{
                            readOnly: readOnly
                        }}
                    >
                        <MenuItem key={0} value={0}>
                            0
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                            1
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                            2
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                            3
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                            4
                        </MenuItem>
                        <MenuItem key={5} value={5}>
                            5
                        </MenuItem>
                        <MenuItem key={6} value={6}>
                            6
                        </MenuItem>
                        <MenuItem key={7} value={7}>
                            7
                        </MenuItem>
                        <MenuItem key={8} value={8}>
                            8
                        </MenuItem>
                        <MenuItem key={9} value={9}>
                            9
                        </MenuItem>
                        <MenuItem key={10} value={10}>
                            10
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <Genre id="genres" />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <Vibe id="vibes" />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Dress"
                        value={dress}
                        fullWidth
                        margin="dense"
                        onChange={this.handleDressChange}
                        inputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default PrivateBookingGigRequirement;
