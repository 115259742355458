import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import ModalContainer from "../../modal/ModalContainer";
import { Grid, Button, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import ProfileSearchBox from "../../fields/ProfileSearchBox";


import ResponsiveTable from "../../table/ResponsiveTable";
import TableRowProfile from "../../util/TableRowProfile";

import { ProfileRelationType } from "../../../types/enum";

@inject("profileStore", "agentVenueStore", "templateStore", "agentConsultantStore", "userInvitationStore")
@observer
class ConsultantInvitationModal extends Component {
    state = {
        loading: false
    };

    agentVenueColumns = [{ key: "venue", label: "Venue" }];

    agentVenueRows = [];

    componentDidMount() {
        var currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.getAgentVenue(currentProfile.id).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    }

    mapAgentVenueToRowData = () => {
        const { agentVenues } = this.props.agentVenueStore;
        this.agentVenueRows = [];
        if (agentVenues) {
            agentVenues.forEach(agentVenue => {
                let rowData = {};
                rowData.id = agentVenue?.venue?.id;
                rowData.venue = (
                    <TableRowProfile
                        avatarUrl = {agentVenue?.venue?.profileImage?agentVenue?.venue?.profileImage?.url200:undefined}
                        profileId = { agentVenue?.venue?.id }
                        name={agentVenue?.venue?.fullName}
                    />
                );

                rowData.data = agentVenue;

                this.agentVenueRows.push(rowData);
            });
        }
    };

    getExcludeSearch = () => {
        var excludedProfile = [];

        const { agentConsultants } = this.props.agentConsultantStore;
        const { agentVenues } = this.props.agentVenueStore;
        const { invitations } = this.props.userInvitationStore;

        agentConsultants.forEach(data => {
            if (data && data.consultant) {
                excludedProfile.push(data.consultant);
            }
        });

        agentVenues.forEach(data => {
            if (data && data.venue) {
                excludedProfile.push(data.venue);
            }
        });        

        invitations.forEach(data => {
            if (data) {
                excludedProfile.push(data);
            }
        })

        return excludedProfile;
    };

    getCheckedRows = rows => {
        var ids = [];
        rows.forEach(row => {
            if (row && row.data && row.data.id) {
                ids.push(row.data.id);
            }
        });
        this.props.agentConsultantStore.setManagedVenueIds(ids);
    };

    handleInvite = () => {
        const { invitedConsultantFirstName, invitedConsultantLastName } = this.props.agentConsultantStore;
        var currentProfile = this.props.profileStore.getCurrentProfile();

        this.props.templateStore.showLoadingScreen();
        this.props.agentConsultantStore.submitInviteConsultant().then(
            response => {
                if (!response) {
                    this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentConsultant.ordinal,currentProfile.id).then(() => {
                        this.props.templateStore.openSnackbar(
                            `${invitedConsultantFirstName} ${invitedConsultantLastName} has been invited to your agency.`
                        );
                        this.props.templateStore.hideLoadingScreen();
                        this.props.onClose();
                    });
                } else {
                    if (response.ConsultantId == null){
                        Promise.all([
                            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentConsultant.ordinal,currentProfile.id),
                            this.props.agentConsultantStore.getAgentConsultants()
                        ]).then(() => {
                            this.props.templateStore.openSnackbar(
                                `${response.firstName} ${response.lastName} has been invited to your agencys.`
                            );
                            this.props.templateStore.hideLoadingScreen();
                            this.props.onClose();
                        })
                    }else if ( response.isAlreadyAdd!==undefined && response.isAlreadyAdd ){
                        this.props.templateStore.hideLoadingScreen();
                        this.props.templateStore.openSnackbar(
                            `this email (${
                                response.ConsultantEmail
                            }) is already added before`
                        );
                        this.props.onClose();
                    }else {
                        this.props.templateStore.hideLoadingScreen();
                        this.props.templateStore.openSnackbar(
                            `${
                                response && response.firstName && response.lastName? `${response.firstName} ${response.lastName}` : response.ConsultantEmail
                            } has been invited to your agency.`
                        );
                        this.props.onClose();
                    }
                }
            },
            err => {
                const { errors } = this.props.agentConsultantStore;
                if (errors) {
                    if(err && err?.message){
                        this.props.templateStore.openSnackbar(err.message);
                    }else{
                        this.props.templateStore.openSnackbar("Required fields are empty!");
                    }
                   
                } else {
                    this.props.templateStore.openSnackbar(err);
                }

                this.props.templateStore.hideLoadingScreen();
            }
        );
    };

    anyError(field) {
        if (!this.props.agentConsultantStore.errors) return false;
        if (!this.props.agentConsultantStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.agentConsultantStore.errors) return null;
        if (!this.props.agentConsultantStore.errors[field]) return null;
        return this.props.agentConsultantStore.errors[field];
    }

    handleValidate(value, type) {
        switch (type) {
            case "firstName":
                this.props.agentConsultantStore.validateInvitedConsultantFirstName(value);
                break;
            case "lastName":
                this.props.agentConsultantStore.validateInvitedConsultantLastName(value);
                break;
            case "email":
                this.props.agentConsultantStore.validateInvitedConsultantEmail(value);
                break;

            default:
                break;
        }
    }

    render() {
        const { invitedConsultantEmail, invitedConsultantFirstName, invitedConsultantLastName } = this.props.agentConsultantStore;

        this.mapAgentVenueToRowData();
        return (
            <ModalContainer title="Consultant Invitation" open={this.props.open} onClose={this.props.onClose} maxWidth="sm">
                <DialogContent>
                    <Grid container spacing={16} direction="column">
                        <Grid item xs={12}>
                            <ProfileSearchBox
                                height="auto"
                                label="Consultant Email"
                                id="search-profile"
                                searchFromConsultant
                                placeholder="Search for a user"
                                handleSearch={value => this.props.profileStore.searchConsultant(value)}
                                handleChange={item => this.props.agentConsultantStore.handleSearchConsultant(item)}
                                handleEmpty={item => {this.emailAddress.focus(); this.props.agentConsultantStore.handleEmptySearch(item)}}
                                exclude={this.getExcludeSearch()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                inputRef={ref => this.emailAddress = ref}
                                id="consultant-email"
                                label="Consultant Email"
                                value={invitedConsultantEmail}
                                onBlur={e => this.handleValidate(e.target.value, "email")}
                                onChange={e => this.props.agentConsultantStore.setInvitedConsultantEmail(e.target.value)}
                                fullWidth
                                error={this.anyError("consultantEmail")}
                                helperText={this.errorText("consultantEmail")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="consultant-firstname"
                                label="Consultant First Name"
                                value={invitedConsultantFirstName}
                                onBlur={e => this.handleValidate(e.target.value, "firstName")}
                                onChange={e => this.props.agentConsultantStore.setInvitedConsultantFirstName(e.target.value)}
                                fullWidth
                                error={this.anyError("consultantFirstName")}
                                helperText={this.errorText("consultantFirstName")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="consultant-lastname"
                                label="Consultant Last Name"
                                value={invitedConsultantLastName}
                                onBlur={e => this.handleValidate(e.target.value, "lastName")}
                                onChange={e => this.props.agentConsultantStore.setInvitedConsultantLastName(e.target.value)}
                                fullWidth
                                error={this.anyError("consultantLastName")}
                                helperText={this.errorText("consultantLastName")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Managed Venue</InputLabel>
                            <ResponsiveTable
                                columns={this.agentVenueColumns}
                                rows={this.agentVenueRows}
                                selectable={true}
                                getCheckedRows={this.getCheckedRows}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {this.props.onClose(); this.props.agentConsultantStore.resetErrors()}}>Close</Button>
                    {!this.anyError("consultantLastName") && !this.anyError("consultantFirstName") && !this.anyError("consultantEmail") && this.props.agentConsultantStore.invitedConsultantEmail && this.props.agentConsultantStore.invitedConsultantFirstName && this.props.agentConsultantStore.invitedConsultantLastName && 
                        <Button id="invite-consultant-submit" color="primary" onClick={this.handleInvite}>
                            Add Consultant
                        </Button>
                    }
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default ConsultantInvitationModal;
