import React, { Component } from "react";

import { Grid, Paper } from "@material-ui/core";
import classNames from "classnames";

import "./DashboardBigInformationWidget.css";

class DashboardBigInformationWidgetSingle extends Component {
    render() {
        return (
            // <Card
            //     classes={{
            //         root: "dashboard-widget-summary-card-container widget"
            //     }}
            // >
            //     <CardContent
            //         classes={{
            //             root: this.props.primaryColor ? "card-header-primary" : "card-header-secondary"
            //         }}
            //         color="secondary"
            //     >
            //         <Typography align="center" variant="display1" noWrap={true}>
            //             {this.props.headerContent}
            //             <Typography align="center" variant="caption" className="caption">
            //                 {this.props.headerCaption}
            //             </Typography>
            //         </Typography>
            //     </CardContent>
            //     <CardContent
            //         classes={{
            //             root: "card-summary-content"
            //         }}
            //     >
            //         <Grid container spacing={8}>
            //             <Grid item md={6} xs={6}>
            //                 <Button fullWidth>
            //                     <Typography align="center" variant="subheading" color="primary" noWrap={true}>
            //                         {this.props.content1}
            //                         <Typography align="center" variant="caption">
            //                             {this.props.caption1}
            //                         </Typography>
            //                     </Typography>
            //                 </Button>
            //             </Grid>
            //             <Grid item md={6} xs={6}>
            //                 <Button fullWidth>
            //                     <Typography align="center" variant="subheading" color="primary" noWrap={true}>
            //                         {this.props.content2}
            //                         <Typography align="center" variant="caption">
            //                             {this.props.caption2}
            //                         </Typography>
            //                     </Typography>
            //                 </Button>
            //             </Grid>
            //         </Grid>
            //     </CardContent>
            // </Card>
            <Grid container className="dashboard-detail-widget" id={this.props.id}>
                 <Grid item xs={12}>
                    <Paper>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={classNames("dashboard-detail-widget-main", this.props.primaryColor ? "primary" : "secondary")}
                            >
                                <Grid container className="dashboard-detail-widget-main-container">
                                    <Grid item xs={12} className="main-caption" style={{ fontSize: 20 }}>
                                        {this.props.headerCaption}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="dashboard-detail-widget-detail">
                                <Grid container style={{ height: "100%", width: '100%' }}>
                                    <Grid item xs={12}>
                                        <Grid container className="dashboard-detail-widget-detail-container">
                                            <Grid item xs={12} className={this.props.highlightContent2 ? "detail-number red" : "detail-number"}>
                                                {this.props.headerContent}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default DashboardBigInformationWidgetSingle;
