import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { Link, withRouter} from "react-router-dom";
import "./BookingDetails.css";
import { Grid, Typography, Button, Menu, Snackbar, IconButton, Tooltip,MenuItem} from "@material-ui/core";
import ResponsiveTable from "../table/ResponsiveTable";
import BookingStatusBadge from "./BookingStatusBadge";
import moment from "moment";
import ConfirmationModal from "../modal/ConfirmationModal";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import { ProfileType, BookingType, BookingStatusType,RosterDividendType, LineupStatusType } from "../../types/enum";

import currencyHelper from '../../helper/currency';
import { Fragment } from "react";


//Booking Status cancelled only has canelled status
const BOOKING_STATUS = [
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled By Act",
    "Cancelled By Act",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled",
    "Cancelled"
];

@inject("profileStore", "bookingRequestStore", "templateStore", "reportStore")
@withRouter
@observer
class BookingCancelled extends Component {
    state = {
        columns: [],
        actionAnchor: [],
        duplicateModalState: true,
        duplicateModalState: null,
        openDuplicateSnackbar: false,
        duplicateBooking: undefined,
        page: 1
    };

    rows = [];

    componentDidMount() {
        this.props.bookingRequestStore.resetCancelledGigs()
        const { cancelled } = this.props.bookingRequestStore;
            this.props.bookingRequestStore.showLoadingState();
        if (this.props.profileType === "agent") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "10%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "10%" },
                    { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "actFee", label: "Act Fee", sortable: true, isNumber: true,width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "agentFee", label: "My Fee", sortable: true, isNumber: true,width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        } else if (this.props.profileType === "staff") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } }, 
                    { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } }, 
                    // { key: "acknowledge", label: "", width: "10%" },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        } else if (this.props.profileType === "musician") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Venue/Client Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "myFee", label: "My Fee", width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
                    // { key: "acknowledge", label: "", width: "10%" },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        } else if (this.props.profileType === "PrivateHire") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "actFee", label: "Gig Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    // { key: "acknowledge", label: "", width: "10%" },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        }
       this.loadCancelledGigs(1)
    }

    setActionAnchor = (element, bookingId) => {
        var tempAnchor = [];
        tempAnchor[bookingId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    loadCancelledGigs = (page) => {
        const {currentProfile} = this.props.profileStore
        this.props.bookingRequestStore.getCancelledGigs(currentProfile?.id, page).then(() => {
            this.props.bookingRequestStore.hideLoadingState();
            this.props.bookingRequestStore.setCancelledPage(page)
        })
    }

    handleShowMore = e => {
        this.props.bookingRequestStore.showLoadingState();
        var page = this.props.bookingRequestStore.cancelledPage + 1;
        this.loadCancelledGigs(page)
    };
    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };
    
    handleOpenDuplicateBookingModal = booking => this.setState({ duplicateModalState: true, duplicateBooking: booking, actionAnchor: [] });
    handleCloseDuplicateBookingModal = () => {
        this.setState({
            ...this.state,
            duplicateModalState: false,
            duplicateBooking: null
        });
    };
    handleDupicateRequest = () => {
        const { duplicateBooking } = this.state;
        const {currentProfile} = this.props.profileStore
        this.props.bookingRequestStore.duplicateBooking(duplicateBooking.id,currentProfile.id ).then(response => {
            this.handleCloseDuplicateBookingModal();
           
            this.setState({ openDuplicateSnackbar: true});
            setTimeout(
                function () {
                    this.props.history.replace(`/booking/${response.id}/edit`);
                }.bind(this),
                2000
            );
            
        });
    };

    handleSnackbarEditClose = (event, reason) => {
        this.setState({ openDuplicateSnackbar: false });
    };

    getBookingStatus = (booking) => {
        if (moment(booking.dateStart).endOf("day") < moment().startOf("day")) {
            if (
                booking.status === BookingStatusType.New.ordinal ||
                booking.status === BookingStatusType.Hold.ordinal ||
                booking.status === BookingStatusType.Cancelled.ordinal ||
                booking.status === BookingStatusType.Draft.ordinal
            ) {
                return "Cancelled";
            } else {
                return BOOKING_STATUS[booking ? booking.status : 0];
            }
        } else {
            return BOOKING_STATUS[booking ? booking.status : 0];
        }
    };

    render() {
        let { cancelled } = this.props.bookingRequestStore;
        let { currentProfile } = this.props.profileStore;
        const { profileType } = this.props;
        const { venueId } = this.props.reportStore;
        if (venueId) {
            cancelled = cancelled.filter(x => x.venueId === venueId);
            this.props.bookingRequestStore.setBookingsCount("cancelled", cancelled);
        }

        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Cancelled gigs
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                {profileType === "musician" ? "This is the list of your cancelled gigs." : "This is the list of cancelled gigs"}
                            </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} className="booking-table-wrapper">
                        <ResponsiveTable
                            sortable={true}
                            columns={this.state.columns}
                            rows={cancelled.map(booking => {
                                var row = {};
                                let date = new Date(booking.dateStart);
                                // row.date = date.getDate()+" "+this.props.templateStore.getMonth(date.getMonth())+" "+date.getFullYear();
                                var formattedDate = moment(booking.dateStart).format("YYYY-MM-DD")
                                
                                
                                let time = booking.timeStart.length < 8 ? `0${booking.timeStart}` : booking.timeStart;
                                let endTime = booking.timeEnd.length < 8 ? `0${booking.timeEnd}` : booking.timeEnd;
                                var finalDate= moment(formattedDate + ' ' + time)
                                row.date = {newFormat: 
                                    (
                                        <div>
                                            <span>{`${booking.dateStart.format("ddd, DD MMM YYYY")}`}</span>
                                            <br />
                                            <small>{`${moment(time).format('hh.mm A')} - ${moment(endTime).format('hh.mm A')}`}</small>
                                        </div>
                                    ), value: finalDate};
                                row.venue = (
                                    <div>
                                        <span>{booking.venue ? booking.venue.profileName : booking.venueName}</span>
                                        <br />
                                        <small>{booking.venueAddress}</small>
                                    </div>
                                );
                                // row.address = booking.venueAddress;
                                row.agentName = booking.agentName;
                                row.time = moment(booking.timeStart, "HH:mm:ss").format(this.props.templateStore.getFormat12Hour(booking.timeStart));
                                row.gigFee = (booking.viewOnly)  || (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices)
                                    ? <i class="far fa-eye-slash"></i>
                                    : `${currentProfile?.currencySymbol + ((profileType === "agent" || profileType === "staff") && booking.isApproved
                                        ? currencyHelper.formatMoney((Number(booking.gigFee) + Number(booking.extraGigFee)).toFixed(2), 2) 
                                        : currencyHelper.formatMoney(Number(booking.gigFee).toFixed(2), 2))}`;
                                row.agentFee = currentProfile?.currencySymbol + Number(booking.agentCommissionAmount).toFixed(2);
                                // row.actName = booking.actName;
                                const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

                                row.actName = (
                                    <div>
                                        <Link target={"_blank"} to={`/profile/${isEntertainerBookings ? currentProfile?.id : booking?.act?.id}`}>{isEntertainerBookings ? `${currentProfile?.firstName} ${currentProfile?.lastName}` : booking.act ? booking?.act?.actName : ""}</Link>
                                        <br />
                                        {booking.act && <small>Contact: {booking?.act?.contactName}</small>}<br />
                                        {booking.act && <small>Phone: {booking?.act?.contactPhone ? booking?.act?.contactPhone  : "-"}</small>}
                                    </div>
                                );
                                row.actFee = currentProfile?.currencySymbol + parseFloat(booking.actFee);
                               
                                let linuep = booking?.lineups?.find(x => x.profileId === currentProfile?.id);
                                
                                let totalFee = 0;
                                booking?.lineups?.forEach(l => {
                                    if (l.status !== LineupStatusType.Removed.ordinal) {
                                        totalFee += parseFloat(l.fee)
                                    }                                    
                                });  
                                var musicianFee = parseFloat(booking.gigFee) - totalFee

                                let adminFeeRoster = 0
                                let totalRosterFeeAdmin = 0
                                booking?.act?.actRoster?.forEach(actRoster => {
                                    let feeCount = 0
                                    if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                                        feeCount = actRoster.dividendAmount /100 * booking.actFee;
                                    }else {
                                        feeCount = actRoster.dividendAmount;
                                    }
                                    totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
                                })
                                adminFeeRoster = booking.actFee - totalRosterFeeAdmin
                                if(currentProfile?.type === ProfileType.Musician.ordinal && !booking?.lineups?.length){
                                    let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
                                    if(actRoster?.length){
                                        if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                                            musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                                        }else {
                                            musicianFee = actRoster[0].dividendAmount;
                                        }
                                        if(this.props.profileType === 'musician'){
                                            if(booking?.act?.actAdminId === currentProfile?.id){
                                                musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                                            }
                                        }
                                    }else{
                                        musicianFee = booking.actFee
                                        booking?.act?.actRoster?.forEach(item => {
                                            var rosterFee = 0
                                            if(item.dividendType === RosterDividendType.Percentage.ordinal){
                                                rosterFee = item.dividendAmount /100 * booking.actFee;
                                            }else {
                                                rosterFee = item.dividendAmount;
                                            }
                                            musicianFee = musicianFee - rosterFee
                                        });
                                    }
                                }
                                var totalRosterFee = 0

                                  booking?.lineups?.forEach(lineUp => {
                                    totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
                                })
                                var adminFee = booking.actFee - totalRosterFee
                                var calculateFee = linuep?.fee ?  linuep?.fee : 0
                                if(this.props.profileType === 'musician'){
                                    if(booking?.act?.actAdminId === currentProfile?.id){
                                        calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                                    }
                                }
                                let myFee = linuep?.fee ? calculateFee : isEntertainerBookings ? booking.gigFee : (!linuep) ? musicianFee : booking.actFee;
                                row.myFee = this.props.profileType === 'musician' && currentProfile?.currencySymbol + currencyHelper.formatMoney(myFee, 2);
                                row.agentCommissionAmount = `${currentProfile?.currencySymbol}${currencyHelper.formatMoney(booking.agentCommissionAmount, 2)}`
                                row.bookingCreator = booking?.createdBy ? (booking?.createdBy?.firstName + " " +booking?.createdBy?.lastName) : ""
                                
                                row.action = (
                                    (  <Fragment>
                                        <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, booking.id)}>
                                            Actions
                                        </Button>
                                        {booking.id && (
                                            <Menu
                                                open={this.state.actionAnchor[booking.id] != null}
                                                onClose={() => this.unsetActionAchnor(booking.id)}
                                                anchorEl={this.state.actionAnchor[booking.id]}
                                            >
                                                <MenuItem
                                                    button
                                                    className="booking-details"
                                                    onClick={() => {
                                                        let redirectUrl =
                                                            booking.type === BookingType.Adhoc.ordinal
                                                            ? `/privateBooking/${booking.id}/edit`
                                                            : currentProfile?.type === ProfileType.Musician.ordinal
                                                                ? `/musician/booking/${booking.id}/details`
                                                                : currentProfile?.type === ProfileType.Agent.ordinal
                                                                    ? `/booking/${booking.id}/edit`
                                                                    : currentProfile?.type === ProfileType.Staff.ordinal
                                                                        ? `/booking/${booking.id}/edit`
                                                                        : "";
                                                        this.props.history.push(redirectUrl);
                                                    }}
                                                >
                                                    View
                                                </MenuItem>
                                                {(booking.createdById === currentProfile?.id || booking.isBookingVenueManagemenet || currentProfile?.type === ProfileType.Agent.ordinal)? <MenuItem onClick={() => this.handleOpenDuplicateBookingModal(booking)}>Duplicate Booking</MenuItem> : null}
                                            </Menu>
                                        )}
                                    </Fragment>)
                                );
                                let entertainerBookingCancelled = isEntertainerBookings && booking.cancelledBy?.type === ProfileType.Musician.ordinal;
                                row.status = (
                                    <BookingStatusBadge 
                                    cancelledBy={ (booking && booking.cancelledBy ? booking.cancelledBy.type : 0)}
                                    status={this.getBookingStatus(booking)} />
                                );
                                return row;
                            })}
                        />
                    </Grid>

                    <Grid item style={{marginTop: 25}} xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                        <Grid container direction="row" justify="center" alignItems="center">
                            {this.props.bookingRequestStore.showMoreCancelled ? (
                                <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                    Show More
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

                <ConfirmationModal
                    open={this.state.duplicateModalState}
                    message={`Are you sure you want to duplicate this booking - ${this.state.duplicateBooking?.title}?`}
                    closeHandler={this.handleCloseDuplicateBookingModal}
                    confirmationHandler={this.handleDupicateRequest}
                    declineHandler={this.handleCloseDuplicateBookingModal}
                    confirmationLabel="Duplicate"
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDuplicateSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarEditClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Booking {this.state.duplicateBooking?.title} duplicated</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
            </Fragment>
        );
    }
}

export default BookingCancelled;
