import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import AutoComplete from "../../fields/AutoComplete";

import { TextField, InputAdornment, CircularProgress, MenuItem, Avatar, ListItemText } from "@material-ui/core";
import blankVenueProfile from "../../../img/venue.svg";
import image from "../../../helper/image";

@inject("venueStore")
@observer
class SearchVenueField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            open: false,
            loading: false
        };
    }

    renderMenu = children => {
        const { menuStyle } = this.props;
        return (
            <div
                className="menu"
                style={menuStyle ? menuStyle : { position: "absolute", zIndex: "9999", width: "100%", maxHeight: "300px", overflowY: "auto" }}
            >
                {children}
            </div>
        );
    };

    renderInput = props => {
        const { ref, ...rest } = props;

        return (
            <TextField
                {...rest}
                fullWidth
                inputRef={ref}
                InputProps={{
                    endAdornment: this.state.loading ? (
                        <InputAdornment position="start">
                            {" "}
                            <CircularProgress size={30} />
                        </InputAdornment>
                    ) : (
                        ""
                    )
                }}
            />
        );
    };

    renderItem = (item, isHighlighted) => {
        return (
            <MenuItem
                key={item.id ? item.id : item.fullName}
                selected={isHighlighted}
                component="div"
                style={{
                    zIndex: 9999,
                    backgroundColor: isHighlighted ? "#ff0c9c" : "#ffffff"
                }}
            >
                <Avatar alt={item.venueName} src={image.getProfileImage(item)} />
                <ListItemText primary={item.venueName} />
            </MenuItem>
        );
    };

    getItemValue = item => {
        return item.venueName;
    };

    handleSearch = value => {
        this.setState(
            {
                ...this.state,
                loading: true,
                suggestions: []
            },
            () => {
                this.props.venueStore.searchVenueAndIndependentVenue(value).then(response => {
                    var result = response;
                    this.setState({
                        ...this.state,
                        loading: false,
                        suggestions: result
                    });
                });
            }
        );
    };

    handleChange = (e, value) => {
        this.setState({ ...this.state, open: false });

        this.props.handleValueChange(value);

        if (value.length > 3) {
            clearTimeout(this.requestTimer);
            this.requestTimer = setTimeout(this.handleSearch(value), 5000);
            this.setState({ value, open: true });
        }
    };

    handleSelect = (value, item) => {
        this.setState({
            open: false
        });

        this.props.handleSelect(item);
    };

    render() {
        const { suggestions, open } = this.state;
        const { value, height, id, placeholder, error, helperText } = this.props;
        return (
            <AutoComplete
                wrapperHeight={height}
                placeholder={placeholder}
                id={id ? id : "venue-search"}
                error={error}
                helperText={helperText}
                open={open}
                value={value}
                items={suggestions}
                getItemValue={this.getItemValue}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                renderInput={this.renderInput}
                renderMenu={this.renderMenu}
                renderItem={this.renderItem}
            />
            
        );
    }
}

export default SearchVenueField;
