import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import SetListContent from "../../components/setList/SetListContent";
import Paper from "@material-ui/core/Paper";

@inject("songListStore", "profileStore", "setListStore")
@observer
class SetList extends Component {
    state = {
        openAdd: false,
        openRemove: false
    };

    componentDidMount() {
        // const { currentProfile } = this.props.profileStore;
        // this.props.setListStore.setProfileId(currentProfile.id);
    }

    handleSnackbarAddClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({ openAdd: false });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.props.songListStore.executeDeleteSongs();
        this.setState({ openDelete: false });
    };

    render() {
        const breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Manage", link: "/" }, { name: "My Set Lists" }];
        const { currentProfile } = this.props.profileStore;

        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="My Set List" />
                    <Paper style={{ padding: 16 }}>{currentProfile && <SetListContent type="musician" profileId={currentProfile.id} />}</Paper>
                </MainContainer>
            </main>
        );
    }
}

export default SetList;
