import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import userProfile from "./profileStore";
import commonStore from "./commonStore";
import templateStore from "./templateStore";

import moment from "moment";

class FacebookStore {
    @observable facebookProfile = undefined;
    @observable accessToken = undefined;
    @observable email = undefined;
    @observable name = undefined;
    @observable fbUserId = undefined;
    @observable picWidth = 0;
    @observable picHeight = 0;
    @observable picUrl = undefined;
    @observable pages = [];
    @observable booking = {};

    @observable showImage = false;
    @observable facebookPostState = false;
    @observable facebookSharePostState = false;
    @observable facebookPostMessage = "";

    @observable venueName = "";
    @observable venueAddress = "";
    @observable dateStart = "";
    @observable datePost = moment().toDate();

    errors = undefined;

    @action
    setBooking(booking) {
        this.booking = booking;
    }

    @action
    toggleShowImage() {
        this.showImage = !this.showImage;
    }

    @action
    setFacebookBooking(booking) {
        this.venueName = booking.venue ? booking.venue.profileName : booking.venueName;
        this.venueAddress = booking.venueAddress;
        this.dateStart = booking.dateStart;
    }

    @action
    openFacebookSharePostDialog(profile) {
        this.facebookProfile = profile;
        this.facebookSharePostState = true;
    }

    @action
    closeFacebookSharePostDialog(profile) {
        this.facebookProfile = profile;
        this.facebookSharePostState = false;
        this.showImage = false;
        this.facebookPostMessage = "";
        this.venueName = "";
        this.venueAddress = "";
        this.dateStart = "";
        this.datePost = moment().toDate();
    }

    @action
    openFacebookPostDialog(profile) {
        this.facebookProfile = profile;
        this.facebookPostState = true;
    }

    @action
    closeFacebookPostDialog() {
        this.showImage = false;
        this.facebookPostMessage = "";
        this.venueName = "";
        this.venueAddress = "";
        this.dateStart = "";
        this.datePost = moment().toDate();
        this.facebookPostState = false;
    }

    @action
    setPostMessage(message) {
        this.facebookPostMessage = message;
    }

    @action
    setPages(response) {
        let pages = response.data.map(res => {
            const { access_token, category, name, id } = res;
            return { access_token, category, name, id };
        });
        this.pages = pages;
    }

    @action
    setData(response) {
        this.accessToken = response.accessToken;
        this.email = response.email;
        this.name = response.name;
        this.picWidth = response.picture.data.width;
        this.picHeight = response.picture.data.height;
        this.picUrl = response.picture.data.url;
        this.fbUserId = response.userID;
    }

    login(accessToken) {
        return agent.Facebook.login(accessToken)
            .then(({ authToken }) => {
                commonStore.setToken(authToken);
                templateStore.setActiveSidebarKey("dashboard");
            })
            .then(() => userStore.pullUser())
            .then(() => userProfile.loadProfiles())
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    getPages(userId, token, callback) {
        return agent.Facebook.getPages(userId, token);
    }

    @action
    handlePostDateChange(date) {
        this.datePost = date;
    }

    setFacebookPage(profileId, facebookPageId, facebookAccessToken, facebookPageName, facebookPageCategory) {
        let data = { profileId, facebookPageId, facebookAccessToken, facebookPageName, facebookPageCategory };
        return agent.Facebook.setPage(data);
    }

    setRefreshPage(profileId) {
        let data = { profileId};
        return agent.Facebook.refreshPage(data);
    }

    setInstagramPage(profileId, facebookPageId, facebookAccessToken, facebookPageName) {
        let data = { profileId, facebookPageInstagramId : facebookPageId, facebookAccessToken, facebookInstagramName : facebookPageName };
        return agent.Facebook.setInstagramPage(data);

    }
    
    disconnectFacebookPage(profileId) {
        let data = { profileId };
        return agent.Facebook.disconnectPage(data);
    }
}

export default new FacebookStore();
