import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { Grid, Paper, Typography, Button } from "@material-ui/core";

class DashboardSubscriptionInformation extends Component {
    state = {
        goToPaymentPage: false
    }

    handlePaymentPage = () => {
        this.setState({
            goToPaymentPage: true
        });
    }

    render() {
        if (this.state.goToPaymentPage)
            return <Redirect to='../../billing/payment'/>;
        return (
            <Grid container className="dashboard-detail-widget" id={this.props.id}>
                <Paper>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            style={{ height: "94px",
                                lineHeight: "94px",
                                textAlign: "center"}}
                        >
                            <Typography variant="display1" align="center">
                                Upgrade to see your income reporting
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{ height: "94px",
                                display: "flex",
                                alignItems: "center"}}
                        >
                            <Grid container justify="center">
                                <Grid item>
                                    <Button variant="contained" 
                                        color="primary" 
                                        fullWidth
                                        onClick={this.handlePaymentPage}
                                        style={{
                                            fontSize: "15px"
                                        }}
                                    >
                                        Upgrade
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    }
}

export default DashboardSubscriptionInformation;
