import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import ResponsiveTable from "../../table/ResponsiveTable";
import { Button, Typography } from "@material-ui/core";
import TableRowProfile from "../../util/TableRowProfile";
import ConfirmationModal from "../../modal/ConfirmationModal";

import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

@inject("agentConsultantStore")
@observer
class AgencyRequests extends Component {
    state = {
        acceptConfirmationState: false,
        declineConfirmationState: false,
        request: undefined
    };

    columns = [{ key: "agency", label: "Agency Name" }, { key: "agencyOwner", label: "Agency Owner" }, { key: "action", label: "" }];

    rows = [];

    openAcceptConfirmation = request => this.setState({ ...this.state, acceptConfirmationState: true, request: request });
    openDeclineConfirmation = request => this.setState({ ...this.state, declineConfirmationState: true, request: request });
    closeConfirmation = request =>
        this.setState({ ...this.state, acceptConfirmationState: false, declineConfirmationState: false, request: undefined });

    handleAcceptRequest = () => {
        this.props.agentConsultantStore.acceptRequest(this.state.request.id).then(() => {
            this.closeConfirmation();
            window.location.reload()
        });
    };

    handleDeclineRequest = () => {
        this.props.agentConsultantStore.declineRequest(this.state.request.id).then(() => {
            this.closeConfirmation();
        });
    };

    mapAgencyRequestToRowData = () => {
        const { agentConsultants } = this.props.agentConsultantStore;
        this.rows = [];
        if (agentConsultants) {
            agentConsultants.forEach(agentConsultant => {
                if(!agentConsultant.accepted) {
                    var rowData = {};
                    var agent = agentConsultant.agent;
                    rowData.agency = <TableRowProfile profileId = { agent.id } name={agent.profileName} avatarUrl={agent.profileImage?agent.profileImage.imageProfile:null}/>;
                    rowData.agencyOwner = `${agent.firstName} ${agent.lastName}`;
    
                    rowData.action = (
                        <div className="pull-right">
                            <Button size="small" color="primary" onClick={() => this.openAcceptConfirmation(agentConsultant)}>
                                <DoneIcon /> Accept
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.openDeclineConfirmation(agentConsultant)}>
                                <ClearIcon /> Decline
                            </Button>
                        </div>
                    );
    
                    this.rows.push(rowData);
                }  
            });
        }
    };

    render() {
        this.mapAgencyRequestToRowData();
        const agentName = this.state.request?.agent?.profileName;
        return (
            <Fragment>
                <ResponsiveTable columns={this.columns} rows={this.rows} showToolbar={true} title="Agencies Requests" />
                <ConfirmationModal
                    open={this.state.acceptConfirmationState}
                    title="Accept Agency Request"
                    closeHandler={this.closeConfirmation}
                    message={
                        this.state.request ? (
                            <Fragment>
                                <div>{`Are you sure to accept request from ${agentName}?`}</div> <br />
                                <Typography variant="caption">
                                    Other requests will be automatically declined and notification will be given to respective agency.
                                </Typography>
                            </Fragment>
                        ) : (
                            ""
                        )
                    }
                    confirmationLabel="Accept"
                    confirmationHandler={this.handleAcceptRequest}
                />
                <ConfirmationModal
                    open={this.state.declineConfirmationState}
                    title="Decline Agency Request"
                    closeHandler={this.closeConfirmation}
                    message={this.state.request ? `Are you sure to decline request from ${this.state.request.agent.fullName}?` : ""}
                    confirmationLabel="Decline"
                    confirmationHandler={this.handleDeclineRequest}
                />
            </Fragment>
        );
    }
}

export default AgencyRequests;
