import React, { Component } from "react";
import agent from "../../agent";

class SoundCloudWidget extends Component {
    state = {
        embed: ""
    };

    componentDidMount = () => {
        if (this.props.url) {
            this.fetchData();
        }
    };

    fetchData = () => {
        let setting = {
            url: this.props.url,
            format: "json"
        };

        agent.SoundCloud.getEmbed(setting).then(result => {
            this.setState({ embed: result.html });
        });
    };

    render() {
        if (this.props.type === "venue-profile") {
            return (
                <div
                    className="soundcould-widget-venue-profile"
                    style={{ height: this.props.height }}
                    dangerouslySetInnerHTML={{ __html: this.state.embed }}
                />
            );
        } else {
            return (
                 <div
                    style={{
                        position: "relative",
                        paddingBottom: "56%",
                        paddingTop: "30px",
                        height: "0",
                        overflow: "hidden"
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: `100%`,
                            height: `100%`
                        }}
                        className="soundcould-widget"
                        dangerouslySetInnerHTML={{ __html: this.state.embed }}
                    />
                </div>
            );
        }
    }
}

export default SoundCloudWidget;
