import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Button, Grid, Typography } from "@material-ui/core";

import ActTable from "../venue/ActTable";

@inject("memberProfileStore", "templateStore", "authStore", "organisationMemberStore", "profileStore")
@withRouter
@observer
class VenueActRoster extends Component {
    componentDidMount() {
        this.props.profileStore.getProfile(this.props.profileId)
        .then(profile => {
            if (!this.props.profileStore.isCreateVenue) {
                this.props.profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
            }
        })
    }
    
    handleSubmitForm = e => {
        e.preventDefault();
        const { profile } = this.props.profileStore;
        if (this.props.type === "venue") {
            this.props.history.push(`/venues/${profile.id}/edit/menu`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
    };

    handleBack = () => {
        const { profile } = this.props.profileStore;
        if (this.props.type === "venue") {
            this.props.history.push(`/venues/${profile.id}/edit/member`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    render() {
        const { editProfileActiveStep } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        return (
            <form onSubmit={this.handleSubmitForm} className="form-relative">
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={3}>
                        <div className="sticky-information">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Favourite Act
                            </Typography>
                            <Typography gutterBottom>Manage your venues's favourite acts.</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <ActTable venueId={this.props.profileId} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="action">
                            <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                Back
                            </Button>
                            <Button variant="contained" color="primary" type="submit">
                                {editProfileActiveStep === steps.length - 1 ? "Save/Finish" : "Save/Next"}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default VenueActRoster;
