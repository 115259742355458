import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { BookingType, ProfileType } from "../../../types/enum";
import ValidationHelper from "../../../helper/validation.js";

import { Grid, TextField, InputLabel } from "@material-ui/core";

import SearchVenueField from "../formFields/SearchVenueField";

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore", "venueStore", "venueActStore", "templateStore")
@withRouter
@observer
class PrivateBookingDetails extends Component {
    agentSuggestions = [];
    venueSuggestions = [];

    constructor(props) {
        super(props);
        this.props.venueStore.resetVenues();
    }

    componentDidMount() {
        const { currentProfile } = this.props.profileStore;
        //if agent
        if (currentProfile?.type === ProfileType.Agent.ordinal) {
            if (currentProfile?.isConsultant) {
                this.props.agentVenueStore.getConsultantVenueRosterSimple();
            } else {
                this.props.agentVenueStore.getAgentVenueSimple();
            }
        }
        //if staff
        else if (currentProfile?.type === ProfileType.Staff.ordinal) {
            this.props.templateStore.showLoadingScreen();
            Promise.all([
                this.props.venueStore.getUserBookableVenues().then(venues => {
                    if (venues.length === 0) {
                        this.props.templateStore.hideLoadingScreen();
                        // this.props.history.push("/staff");
                        // this.props.templateStore.openSnackbar("You don't have any permission to create booking.");
                    }
                })
            ]).then(() => {
                this.props.templateStore.hideLoadingScreen();
            });
        }

       
    }

    handleProviderChange = value => {
        this.props.bookingRequestStore.setBooking("agentId", value);
    };

    setTempTitle = () => {
        const { booking } = this.props.bookingRequestStore;
        const { actName, venue, venueName } = booking;
        var title = "";

        title = `${actName} @ ${venue ? venue.profileName : venueName}`;

        this.props.bookingRequestStore.setBooking("tempTitle", title);
        this.props.bookingRequestStore.setTempBookingTitle();
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };

    handleTitleChange = e => {
        this.props.bookingRequestStore.setBooking("title", e.target.value);
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };

    handleVenueContactChange = e => {
        this.props.bookingRequestStore.setBooking("venueContact", e.target.value);
    };

    handleVenueAddressChange = e => {
        this.props.bookingRequestStore.setBooking("venueAddress", e.target.value);
    };

    handleVenueEmailChange = e => {
        this.props.bookingRequestStore.setBooking("venueEmail", e.target.value);
    };

    handlePhoneChange = e => {
        this.props.bookingRequestStore.setBooking("venuePhone", e.target.value.replace(/\D/, ''));
    };

    handleTicketUrlChange = e => {
        this.props.bookingRequestStore.setBooking("ticketUrl", e.target.value);
    };

    handleSharePermissionChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("sharePermission", checked);
        if (!checked) {
            this.props.bookingRequestStore.setBooking("shareUpdate", checked);
        }
        this.setState({
            ...this.state
        });
    };

    handleShareUpdateChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("shareUpdate", checked);
        this.setState({
            ...this.state
        });
    };

    mapSuggestions = () => {
        const { currentProfile } = this.props.profileStore;

        if (currentProfile?.type === ProfileType.Agent.ordinal) {
            this.venueSuggestions = this.props.agentVenueStore.agentVenues.filter(x => x.venue !== null).map(data => {
                if (data.venue !== null) {
                    return {
                        value: data.venueId,
                        label: data.venue.fullName
                    };
                }
            });
        } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
            const { venues } = this.props.venueStore;
            if (venues && venues.length > 0) {
                this.venueSuggestions = venues.filter(x => x.venue !== null).map(data => {
                    return {
                        value: data.id,
                        label: data.fullName
                    };
                });
            }
        }
    };

    handleVenueChange = value => {
        this.props.bookingRequestStore.setBooking("venueId", undefined);
        this.props.bookingRequestStore.setBooking("independentVenueId", undefined);
        this.props.bookingRequestStore.setBooking("venueName", value);

        this.setTempTitle();
    };

    handleSelectVenue = item => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.setBooking("venueName", item.venueName);
        this.props.bookingRequestStore.setBooking("venueContact", item.venueContact);
        this.props.bookingRequestStore.setBooking("venuePhone", item.venuePhone);
        this.props.bookingRequestStore.setBooking("venueAddress", item.venueAddress);
        this.props.bookingRequestStore.setBooking("venueEmail", item.venueEmail);
        this.props.bookingRequestStore.setBooking("venueContract", item.termCondition);
        
        // not changing client contract value when craete private booking on agent profile
        if(currentProfile?.type !== ProfileType.Agent.ordinal && this.props.bookingRequestStore?.booking?.type !== BookingType.Adhoc.ordinal && this.props.bookingRequestStore?.booking?.createdBy?.id !== currentProfile?.id) {
            this.props.bookingRequestStore.setBooking("clientContract", item.clientContract);
        }

        if (item.isIndependent) {
            this.props.bookingRequestStore.setBooking("venueId", undefined);
            this.props.bookingRequestStore.setBooking("independentVenueId", item.id);
        } else {
            this.props.bookingRequestStore.setBooking("venueId", item.id);
            this.props.bookingRequestStore.setBooking("independentVenueId", undefined);
        }

        this.setTempTitle();
    };

    checkVenueEmail = email => {
        if (!ValidationHelper.emailValidation(email)) {
            this.props.bookingRequestStore.addError("venueEmail", "Invalid email");
        }
        else {
            this.props.bookingRequestStore.removeError("venueEmail");
        }
    }

    render() {
        let { error, booking, tempBookingTitle } = this.props.bookingRequestStore;
        let {
            title,
            tempTitle,
            provider,
            venueContact,
            venueAddress,
            venuePhone,
            venue,
            createdBy,
            ticketUrl,
            venueName,
            venueEmail,
            bookingRequest
        } = booking;

        let { readOnly } = this.props;
        const { currentProfile } = this.props.profileStore;
        this.mapSuggestions();

        /*let confirmedEditable = ( status !== BookingStatusType.Cancelled.ordinal && status !== BookingStatusType.Completed.ordinal && moment(dateStart).endOf('day') >= moment() ) &&
                                ( !createdById || createdById === currentProfile?.id );*/

        const bookingRequestTitle = bookingRequest && bookingRequest.act ? `${bookingRequest.act.actName}@${venue.profileName}` : tempTitle;

        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        id="event-title"
                        label="Event Title"
                        value={title !== null && title !== undefined ? title : bookingRequest ? bookingRequestTitle : tempBookingTitle}
                        onChange={this.handleTitleChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                        error={error && error["title"]}
                        helperText={error && error["title"] ? error["title"] : ""}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    {currentProfile &&
                        (currentProfile?.type === ProfileType.Staff.ordinal ||
                            (currentProfile?.type === ProfileType.Musician.ordinal && createdBy && createdBy.type === ProfileType.Staff.ordinal)) ? (
                        <TextField
                            label="Booking Creator"
                            value={createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : ``}
                            fullWidth
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    ) : (
                        <TextField
                            label="Provider"
                            value={provider}
                            fullWidth
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                    {readOnly ? (
                        <TextField
                            label="Venue"
                            value={venueName}
                            fullWidth
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    ) : (
                        <Fragment>
                            <InputLabel shrink style={{ margin: 0 }}>
                                Venue
                            </InputLabel>
                            <SearchVenueField
                                placeholder="Search Venue"
                                id="private-booking-venue"
                                value={venueName}
                                handleValueChange={this.handleVenueChange}
                                handleSelect={this.handleSelectVenue}
                                error={error && error["venue"]}
                                height={40}
                                helperText={error && error["venue"] ? error["venue"] : ""}
                            />
                        </Fragment>
                    )}
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Venue Contact"
                        value={venueContact}
                        onChange={this.handleVenueContactChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Venue Address"
                        value={venueAddress}
                        onChange={this.handleVenueAddressChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Venue Phone"
                        value={venuePhone}
                        onChange={this.handlePhoneChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <TextField
                        label="Venue Email"
                        value={venueEmail}
                        type="email"
                        onChange={this.handleVenueEmailChange}
                        fullWidth
                        onBlur={e => this.checkVenueEmail(e.target.value)}
                        InputProps={{
                            readOnly: readOnly
                        }}
                        error={error && error["venueEmail"]}
                        helperText={error && error["venueEmail"] ? error["venueEmail"] : ""}
                    />
                </Grid>
                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                    <TextField
                        label="Ticket Url"
                        value={ticketUrl}
                        onChange={this.handleTicketUrlChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly
                        }}
                        placeholder="Example: https://www.eventbrite.com/"
                        error={error && error["ticketUrl"]}
                        helperText={error && error["ticketUrl"] ? error["ticketUrl"] : ""}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default PrivateBookingDetails;
