import { observable, action } from "mobx";
import agent from "../agent";
// import notificationStore from "./notificationStore";
import profileStore from "./profileStore";
import invoiceStore from "./invoiceStore";
import templateStore from "./templateStore";
import { Promise } from "bluebird";

class QuickBooksStore {
    @observable currentUser;
    @observable loadingCustomer;
    @observable loadingVendor;
    @observable loadingItems;
    @observable loadingTaxCodes;
    @observable loadingExpenses;
    @observable updatingUser;
    @observable updatingUserErrors;

    @observable isSigningIn = false;
    @observable isSigningUp = false;
    @observable inProgress = false;

    @observable error = undefined;

    @observable quickBooksCustomers = [];
    @observable vendors = [];
    @observable items = [];
    @observable taxCodes = [];
    @observable expenses = [];
    @observable quickBooksInvoice = undefined;
    @observable selectedCustomerId = undefined;
    @observable selectedVendorId = undefined;
    @observable selectedItemId = undefined;
    @observable selectedTaxCodeId = undefined;
    @observable selectedExpenseId = undefined;
    @observable selectedCustomer = undefined;
    @observable quickBooksCompany = "";
    @observable hasQuickBooksAccount = false;
    @observable loadingCalendar = true;
    @observable loadingQuickBooks = true;
    @observable isCreatingCustomer = false;

    @observable isCreatingCustomer = false;
    @observable newCustomerFirstName = "";
    @observable newCustomerLastName = "";
    @observable newCustomerCompany = "";
    @observable newCustomerEmail = "";
    @observable newCustomerAddress = "";
    @observable newCustomerCity = "";
    @observable newCustomerState = "";
    @observable newCustomerPostCode = "";

    @action
    reset() {
        this.error = undefined;
        this.selectedCustomerId = undefined;
        this.selectedItemId = undefined;
        this.selectedVendorId = undefined;
        this.selectedExpenseId = undefined;
        this.quickBooksCompany = "";
    }

    @action
    getCustomers(invoiceId) {
        this.loadingCustomer = true;
        const { currentProfile } = profileStore;
        return agent.Profile.getQuickBooksCustomers(currentProfile.id, invoiceId)
            .then(
                action(customers => {
                    this.quickBooksCustomers = customers;
                    customers.map(x => {
                        if (x.selectedItem) {
                            this.selectedCustomerId = x.id;
                            this.selectedCustomer = x;
                        }
                        return x;
                    });
                    return this.quickBooksCustomers;
                })
            )
            .finally(
                action(() => {
                    this.loadingCustomer = false;
                })
            );
    }

    @action
    getVendors(invoiceId) {
        this.loadingVendor = true;
        const { currentProfile } = profileStore;
        return agent.Profile.getQuickBooksVendors(currentProfile.id, invoiceId)
            .then(
                action(vendors => {
                    this.vendors = vendors;
                    vendors.map(x => {
                        if (x.selectedItem) {
                            this.selectedVendorId = x.id;
                        }
                        return x;
                    });
                    return this.vendors;
                })
            )
            .finally(
                action(() => {
                    this.loadingVendor = false;
                })
            );
    }

    @action
    getServices() {
        this.loadingItems = true;
        const { currentProfile } = profileStore;
        return agent.Profile.getQuickBooksItems(currentProfile.id)
            .then(
                action(items => {
                    this.items = items;
                    items.map(x => {
                        if (x.selectedItem) {
                            this.selectedItemId = x.id;
                        }
                        return x;
                    });
                    return this.items;
                })
            )
            .finally(
                action(() => {
                    this.loadingItems = false;
                })
            );
    }

    @action
    getTaxCodes() {
        this.loadingTaxCodes = true;
        const { currentProfile } = profileStore;
        return agent.Profile.getQuickBooksTaxCodes(currentProfile.id)
            .then(
                action(taxcodes => {
                    this.taxCodes = taxcodes;
                    taxcodes.map(x => {
                        if (x.selectedItem) {
                            this.selectedTaxCodeId = x.id;
                        }
                        return x;
                    });
                    return this.taxCodes;
                })
            )
            .finally(
                action(() => {
                    this.loadingTaxCodes = false;
                })
            );
    }

    @action
    getQuickbooksInvoice(invoiceId) {
        this.loadingItems = true;
        const { currentProfile } = profileStore;
        return agent.Profile.getQuickBooksInvoice(currentProfile.id, invoiceId)
            .then(
                action(quickBooksInvoice => {
                    this.quickBooksInvoice = quickBooksInvoice;
                    return this.quickBooksInvoice;
                })
            )
            .finally(
                action(() => {
                    this.loadingItems = false;
                })
            );
    }

    @action
    getExpenses() {
        this.loadingExpenses = true;
        const { currentProfile } = profileStore;
        return agent.Profile.getQuickBooksExpenses(currentProfile.id)
            .then(
                action(expenses => {
                    this.expenses = expenses;
                    expenses.map(x => {
                        if (x.selectedItem) {
                            this.selectedExpenseId = x.id;
                        }
                        return x;
                    });
                    return this.expenses;
                })
            )
            .finally(
                action(() => {
                    this.loadingExpenses = false;
                })
            );
    }

    @action
    setSelectedCustomers(customer) {
        this.selectedCustomerId = customer;
        this.quickBooksCompany = "";
        this.selectedCustomer = this.quickBooksCustomers.filter(x => x.id === customer)[0];
    }

    @action
    setSelectedVendors(vendor) {
        this.selectedVendorId = vendor;
        this.selectedVendor = this.vendors.filter(x => x.id === vendor)[0];
    }

    @action
    setSelectedItem(item) {
        this.selectedItemId = item;
    }

    @action
    setSelectedTaxCode(taxCode) {
        this.selectedTaxCodeId = taxCode;
    }

    @action
    setSelectedExpense(expense) {
        this.selectedExpenseId = expense;
    }

    @action
    setIsCreatingCustomer(isCreatingCustomer) {
        this.isCreatingCustomer = isCreatingCustomer;
    }

    @action
    setNewCustomerFirstName(newCustomerFirstName) {
        this.newCustomerFirstName = newCustomerFirstName;
    }

    @action
    setNewCustomerLastName(newCustomerLastName) {
        this.newCustomerLastName = newCustomerLastName;
    }

    @action
    setNewCustomerCompany(newCustomerCompany) {
        this.newCustomerCompany = newCustomerCompany;
    }

    @action
    setNewCustomerEmail(newCustomerEmail) {
        this.newCustomerEmail = newCustomerEmail;
    }

    @action
    setNewCustomerAddress(newCustomerAddress) {
        this.newCustomerAddress = newCustomerAddress;
    }

    @action
    setNewCustomerCity(newCustomerCity) {
        this.newCustomerCity = newCustomerCity;
    }

    @action
    setNewCustomerState(newCustomerState) {
        this.newCustomerState = newCustomerState;
    }

    @action
    setNewCustomerPostCode(newCustomerPostCode) {
        this.newCustomerPostCode = newCustomerPostCode;
    }

    @action
    addError(fieldKey, errorMessage) {
        if (!this.error) {
            this.error = [];
        }
        this.error[fieldKey] = errorMessage;
    }

    @action
    addErrors(fieldKeys, errorMessages) {
        fieldKeys.forEach((fieldKey, index) => {
            this.addError(fieldKey, errorMessages);
        });
    }

    @action
    validateInvoice() {
        let isValid = true;
        if (!this.selectedCustomerId) {
            this.addError("customer", "Please select the customer.");
            isValid = false;
        }

        if (!this.selectedItemId) {
            this.addError("item", "Please select the product.");
            isValid = false;
        }

        if (!this.selectedTaxCodeId) {
            this.addError("taxCodes", "Please select the Tax Code.");
            isValid = false;
        }

        const { invoice } = invoiceStore;
        if (!invoice) {
            this.addError("invoice", "Please wait for the invoice to load");
            isValid = false;
        }
        console.log("validation", this.error);
        return isValid;
    }

    @action
    validateBill() {
        let isValid = true;
        if (!this.selectedVendorId) {
            this.addError("customer", "Please select the vendor.");
            isValid = false;
        }

        if (!this.selectedExpenseId) {
            this.addError("item", "Please select the expense.");
            isValid = false;
        }

        const { invoice } = invoiceStore;
        if (!invoice) {
            this.addError("invoice", "Please wait for the invoice to load");
            isValid = false;
        }
        console.log("validation", this.error);
        return isValid;
    }

    @action
    submitInvoice() {
        this.inProgress = true;
        const { currentProfile } = profileStore;
        const { invoice } = invoiceStore;
        const invoiceId = invoice ? invoice.id : undefined;
        const payerId = invoice ? invoice.payerId : undefined;
        const payeeId = invoice ? invoice.payeeId : undefined;

        if (!this.validateInvoice()) return Promise.resolve({ error: true });

        if (!currentProfile) {
            templateStore.openSnackbar();
            return;
        }

        const data = {
            invoiceId: invoiceId,
            profileId: currentProfile.id,
            customerId: this.selectedCustomerId,
            vendorId: this.selectedVendorId,
            payerId: payerId,
            payeeId: payeeId,
            itemId: this.selectedItemId,
            taxCodeId: this.selectedTaxCodeId,
            expenseId: this.selectedExpenseId
        };

        console.log("Post Data", data);

        return agent.Invoice.syncQuickBooks(invoiceId, data)
            .then(
                action(items => {
                    // this.items = items;
                    // return this.items;
                    templateStore.openSnackbar("Invoice synced");
                })
            )
            .catch(err => {
                if (err && err.response && err.response.body) {
                    templateStore.openSnackbar(`Error syncing to QuickBooks: ${err.response.body.message}`);
                } else {
                    templateStore.openSnackbar("Error syncing to QuickBooks");
                }
            })
            .finally(
                action(() => {
                    this.inProgress = false;
                    this.error = undefined;
                })
            );
    }

    @action
    submitBill() {
        this.inProgress = true;
        const { currentProfile } = profileStore;
        const { invoice } = invoiceStore;
        const invoiceId = invoice ? invoice.id : undefined;
        const payerId = invoice ? invoice.payerId : undefined;
        const payeeId = invoice ? invoice.payeeId : undefined;

        if (!this.validateBill()) return Promise.resolve({ error: true });

        if (!currentProfile) {
            templateStore.openSnackbar();
            return;
        }

        const data = {
            invoiceId: invoiceId,
            profileId: currentProfile.id,
            customerId: this.selectedCustomerId,
            vendorId: this.selectedVendorId,
            payerId: payerId,
            payeeId: payeeId,
            itemId: this.selectedItemId,
            expenseId: this.selectedExpenseId
        };

        console.log("Post Data", data);

        return agent.Invoice.syncQuickBooks(invoiceId, data)
            .then(
                action(items => {
                    // this.items = items;
                    // return this.items;
                    templateStore.openSnackbar("Invoice synced");
                })
            )
            .catch(err => {
                if (err && err.response && err.response.body) {
                    templateStore.openSnackbar(`Error syncing to QuickBooks: ${err.response.body.message}`);
                } else {
                    templateStore.openSnackbar("Error syncing to QuickBooks");
                }
            })
            .finally(
                action(() => {
                    this.inProgress = false;
                    this.error = undefined;
                })
            );
    }

    @action
    saveNewCustomer() {
        this.isCreatingCustomer = true;
        const { invoice } = invoiceStore;
        const invoiceId = invoice ? invoice.id : undefined;
        const data = {
            firstName: this.newCustomerFirstName,
            lastName: this.newCustomerLastName,
            company: this.newCustomerCompany,
            email: this.newCustomerEmail,
            address: this.newCustomerAddress,
            city: this.newCustomerCity,
            state: this.newCustomerState,
            postCode: this.newCustomerPostCode
        };

        const { currentProfile } = profileStore;
        if (!currentProfile) {
            templateStore.openSnackbar("Error: not logged in");
            return Promise.resolve({ error: true });
        }

        return agent.Invoice.createQuickBooksCustomer(currentProfile.id, invoiceId, data)
            .then(
                action(items => {
                    templateStore.openSnackbar("Customer created");
                    this.isCreatingCustomer = false;
                    return this.getCustomers(invoiceId);
                })
            )
            .catch(err => {
                if (err && err.response && err.response.body) {
                    templateStore.openSnackbar(`Error creating customer: ${err.response.body.message}`);
                } else {
                    templateStore.openSnackbar("Error creating customer");
                }
            })
            .finally(
                action(() => {
                    this.error = undefined;
                })
            );
    }
}

export default new QuickBooksStore();
