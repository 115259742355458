import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { 
    Card, 
    CardHeader, 
    Avatar, 
    CardContent, 
    Grid, 
    Paper, 
    Typography 
    // IconButton, 
    // Tooltip
 } from "@material-ui/core";
import ResponsiveTable from "../../table/ResponsiveTable";
import TableRowProfile from "../../util/TableRowProfile";

import Countries from "../../../lookup/Country";
import image from "../../../helper/image";

// import EventIcon from "@material-ui/icons/Event";
// import HistoryIcon from "@material-ui/icons/History";
// import TimelineIcon from "@material-ui/icons/Timeline";

@inject("agentActStore", "agentConsultantStore", "templateStore", "profileStore")
@withRouter
@observer
class AgencyDetail extends Component {
    state = {
        quitConfirmationModalState: false
    };

    actColumns = [{ key: "actName", label: "Act Name" }, { key: "actOwner", label: "Act Owner" }, { key: "actContact", label: "Act Contact" }];

    managedVenuesColumns = [{ key: "venueName", label: "Venue Name" }, { key: "venueContact", label: "Venue Contact" }, { key: "action", label: "" }];

    actRows = [];

    managedVenuesRows = [];

    mapAgentActRoster = () => {
        this.actRows = [];
        const { agency } = this.props;
        const { actList } = this.props.profileStore;
        const { agentActs } = this.props.agentActStore;
        
        agentActs.forEach(data => {
            var act = data.act;
            var agent = data.agent;

            var row = {};
            if (!act) {
                return row;
            }
            row.actName = <TableRowProfile profileId = { act.id } name={act.fullName} avatarUrl={image.getProfileImage(act)}/>;

            row.actOwner = (
                <TableRowProfile
                    profileId = { act?.myMusician?.id ? act?.myMusician?.id : agent?.id}
                    name={act?.myMusician?.fullName ? act.myMusician?.fullName : agent?.profileName}
                    avatarUrl={image.getProfileImage(act?.myMusician)}
                />
            );

            row.actContact = (act.contactName == null ? "" : act.contactName) + " - " + (act.contactPhone == null ? "" : act.contactPhone);
            this.actRows.push(row);
        });

        actList.forEach(data => {
            var act = data;
            var agent = data.myMusician;

            var row = {};
            if (!act) {
                return row;
            }
            row.actName = <TableRowProfile profileId = { act.id } name={act.fullName} avatarUrl={image.getProfileImage(act)}/>;

            row.actOwner = (
                <TableRowProfile
                    profileId = { act?.myMusician?.id ? act?.myMusician?.id : agent?.id}
                    name={act?.myMusician?.fullName ? act.myMusician?.fullName : agent?.profileName}
                    avatarUrl={image.getProfileImage(act?.myMusician)}
                />
            );

            row.actContact = (act.contactName == null ? "" : act.contactName) + " - " + (act.contactPhone == null ? "" : act.contactPhone);
            if(!agentActs.find(a => a.act.id == act.id)) this.actRows.push(row);
        });
    };

    mapManagedVenueRoster = async () => {
        const { agency } = this.props;
        if (agency) {
            const { consultantVenues } = agency;
            if (consultantVenues) {
                this.managedVenuesRows = [];
                consultantVenues.forEach(consultantVenue => {
                    var venue = consultantVenue.venue;

                        if (venue) {
                            var rowData = {};

                            rowData.venueName = (
                                <TableRowProfile profileId = { venue.id } name={venue.profileName} avatarUrl={image.getProfileImage(venue)}/>
                            );

                            if (venue.members) {
                                var venueContactPerson = venue.members.filter(x => x.booking || x.manage);
                                if (venueContactPerson && venueContactPerson[0]) {
                                    rowData.venueContact = <Typography noWrap>{(venueContactPerson[0].name == null ? "" : venueContactPerson[0].name) + " - " + ( venue.contactPhone == null ? "" : venue.contactPhone)}</Typography>;
                                } else {
                                    rowData.venueContact = <Typography noWrap>{(venue.contactName == null ? "" : venue.contactName) + " - " + ( venue.contactPhone == null ? "" : venue.contactPhone)}</Typography>;
                                }
                            } else {
                                rowData.venueContact = <Typography noWrap>{(venue.contactName == null ? "" : venue.contactName) + " - " +( venue.contactPhone == null ? "" : venue.contactPhone)}</Typography>;
                            }

                            // rowData.action = (
                            //     <Fragment>
                            //         <Tooltip title="Calendar">
                            //             <IconButton color="primary">
                            //                 <EventIcon />
                            //             </IconButton>
                            //         </Tooltip>
                            //         <Tooltip title="Upcoming Booking">
                            //             <IconButton color="primary">
                            //                 <TimelineIcon />
                            //             </IconButton>
                            //         </Tooltip>
                            //         <Tooltip title="History">
                            //             <IconButton color="primary">
                            //                 <HistoryIcon />
                            //             </IconButton>
                            //         </Tooltip>
                            //     </Fragment>
                            // );

                            this.managedVenuesRows.push(rowData);
                        }
                    
                });
            }
        }
    };

    getCountryName = countryCode => {
        var country = Countries.filter(x => x.code === countryCode);

        return country && country[0] && country[0].name ? country[0].name : "";
    };

    render() {
        const { agency } = this.props;
        let avatarUrl = image.getProfileImage(agency.agent);
        this.mapAgentActRoster();
        this.mapManagedVenueRoster();
        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                        <Avatar
                                            alt={agency.agent.profileName}
                                            src={avatarUrl}
                                            style={{
                                                width: 40,
                                                height: 40
                                            }}
                                        />
                                    }
                                title={<Typography variant="title" style={{color:"rgba(0, 0, 0, 0.87)"}}>{agency.agent.profileName}</Typography>}
                            />
                            <CardContent>
                                <Typography variant="body1">
                                    {agency.agent.street} {agency.agent.city} {agency.agent.state} {agency.agent.postal}
                                </Typography>
                                <Typography variant="body1">{this.getCountryName(agency.agent.countryCode)}</Typography>
                                <Typography variant="body1">
                                    {agency.agent.contactName} {agency.agent.contactPhone}
                                </Typography>
                                <Typography variant="body1">{agency.agent.email}</Typography>
                                <Typography variant="body1">Currency: {agency.agent.currency === "GBP" ? `£ (${agency.agent.currency})` : `$ (${agency.agent.currency})`}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper>
                            <ResponsiveTable
                                columns={this.actColumns}
                                rows={this.actRows}
                                showToolbar={true}
                                title="Agency Act Roster"
                                pagination={this.actRows.length > 5}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper>
                            <ResponsiveTable
                                columns={this.managedVenuesColumns}
                                rows={this.managedVenuesRows}
                                showToolbar={true}
                                title="Your Managed Venues"
                                pagination={this.managedVenuesRows.length > 5}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default AgencyDetail;
