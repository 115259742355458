import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Card, IconButton, Typography, Avatar } from "@material-ui/core";

import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import image from "../../../helper/image";
import blankProfile from "../../../img/hotgig-loading.png"

import ResponsiveTable from "../../table/ResponsiveTable";

import ClearIcon from "@material-ui/icons/Clear";
import { ProfileType } from "../../../types/enum";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

@inject("agentActStore", "venueActStore", "profileStore")
@observer
class InvitedActPreview extends Component {
    columns = [{ key: "act", label: "Act Name" }, { key: "lineUpCount", label: "Line Up" }];

    rowData = [];

    componentDidMount() {
        //set checked first data selecteed to invitedChecked
        const { invitedActs } = this.props;
        const currentUser = this.props.profileStore.getCurrentProfile()
        if (invitedActs && invitedActs.length > 0) {
            let tempAct = [];
            invitedActs.forEach(act => {
                if (currentUser.type === ProfileType.Agent.ordinal) {
                    if(this.props.agentActStore.invitedAct?.id == act.id) tempAct.push(act)
                } else if (currentUser.type === ProfileType.Staff.ordinal) {
                    if(this.props.venueActStore.invitedAct?.id == act.id) tempAct.push(act)
                }
            })
            this.props.handleCheckRow(tempAct)
        }
    }

    addDefaultSrc(ev){
        ev.target.src = blankProfile;
    }

    mapToRowData = rows => {
        this.rowData = [];
        const currentUser = this.props.profileStore.getCurrentProfile()
        rows.forEach(row => {
            let data = {};

            data.id = row.id;
           if (this.props.forSearch){
               data.checked = row.id === row.selectedActId;
            } else {
            // data.checked = this.props.invitedActs.filter(act => act.id === row.id).length === 1;
                if (currentUser.type === ProfileType.Agent.ordinal) {
                    data.checked = this.props.agentActStore.invitedAct?.id == row.id;
                } else if (currentUser.type === ProfileType.Staff.ordinal) {
                    data.checked = this.props.venueActStore.invitedAct?.id == row.id;
                }
           }

            data.disableCheckbox = this.props.exclude ? this.props.exclude.filter(act => act.id === row.id).length === 1 : false;

            data.act = (
                <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={5}>
                        <Avatar
                            imgProps={{onError: this.addDefaultSrc}}
                            alt={row.fullName}
                            src={image.getProfileImage(row)}
                            style={{
                                width: 40,
                                height: 40
                            }}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="subheading">{row.fullName ?? row.actName}</Typography>
                    </Grid>
                </Grid>
            );

            data.lineUpCount = this.props.agentActStore.getLineUpCounts(row.lineUpCount);

            data.actData = row;

            this.rowData.push(data);
        });
    };

    getCheckedRows = rows => {
        var invitedActs = [];
        rows.forEach(row => {
            invitedActs.push(row.actData);
        });

        this.props.handleCheckRow(invitedActs);
    };

    render() {
        const { musician, handleClear, invitedActs } = this.props;
        const name = musician?.firstName ? musician?.firstName + " " + musician?.lastName : musician?.fullName;
        const currentUser = this.props.profileStore.getCurrentProfile()
        if (invitedActs && invitedActs.length > 0) {
            if (currentUser.type === ProfileType.Staff.ordinal && invitedActs[0] && invitedActs[0].myMusician && invitedActs[0].myMusician.myActs) {
                this.mapToRowData(invitedActs[0]?.myMusician?.myActs);
            } else {
                this.mapToRowData(invitedActs);
            }
        }
        return (
            <Card>
                <CardHeader
                    avatar={<Avatar alt={name} imgProps={{onError: this.addDefaultSrc}} src={image.getProfileImage(musician)} />}
                    title={name}
                    action={
                        handleClear ? (
                            <IconButton onClick={handleClear}>
                                <ClearIcon />
                            </IconButton>
                        ) : (
                            ""
                        )
                    }
                    style={{
                        paddingBottom: invitedActs && invitedActs.length > 0 ? 0 : 8
                    }}
                />
                {invitedActs && invitedActs.length > 0 ? (
                    <CardContent>
                        <p>
                            {musician.fullName} is the admin/owner of the act that you have selected. You can add any of {musician.fullName}'s act below.
                        </p>
                        <ResponsiveTable columns={this.columns} rows={this.rowData} selectable={true} getCheckedRows={this.getCheckedRows} agentAct={true} />
                    </CardContent>
                ) : (
                    ""
                )}
            </Card>
        );
    }
}

export default InvitedActPreview;
