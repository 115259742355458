import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import _ from "lodash";
import profileStore from "./profileStore";
import { ProfileType, ProfileRelationType } from "../types/enum";
import CacheHelper from '../helper/cache.js'
import { string } from "prop-types";

class AgentVenueStore {
    @observable invitationMessage = "";
    @observable manageSocialPost = false;
    @observable invitedId = undefined;
    @observable inviteeId = undefined;
    @observable invitedProfile = undefined;
    @observable manage = false

    @observable invitedVenues = [];

    @observable tableData = [];

    @observable agentVenueSocials = []
    @observable pendingAgentVenues = [];
    @observable agentVenues = [];

    @observable groupedPendingAgentVenues = [];
    @observable groupedAgentVenues = [];
    @observable isMoreAgentVenues = false;
    @observable agentVenuePage = 1;
    @action
    resetData() {
        this.tableData = [];
        this.pendingAgentVenues = [];
        this.agentVenues = [];
        this.groupedPendingAgentVenues = [];
        this.groupedAgentVenues = [];
        this.isMoreAgentVenues = false
        this.agentVenuePage = 1
    }

    @action
    resetAgentVenues() {
        this.agentVenues = [];
        this.isMoreAgentVenues = false
        this.agentVenuePage = 1
    }

    @action
    pushTableData(row) {
        this.tableData.push(row);
    }

    @observable
    dayToBook = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    };

    @observable
    dayToBookVenueInvitation = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
    };

    @observable editedAgentVenue = undefined;

    @action
    setEditedAgentVenue(agentVenue) {
        if (agentVenue) {
            this.editedAgentVenue = Object.assign({}, agentVenue);
        } else {
            this.editedAgentVenue = undefined;
        }
    }

    @action
    toggleEditedAgentVenueBooking(value) {
        this.editedAgentVenue[value] = !this.editedAgentVenue[value];
    }

    @action
    setDayToBook(rules) {
        this.dayToBook = rules;
    }

    @action
    setManage(value) {
        this.manage = value;
    }

    @action
    setSocialPost(value){
        this.manageSocialPost = value
    }

    @action
    submit(agentVenues) {
        if (!agentVenues) {
            agentVenues = this.agentVenues;
        }

        var data = agentVenues.map(agentVenue => {
            return {
                id: agentVenue.id,
                manage: agentVenue.manage,
                monday: agentVenue.monday,
                tuesday: agentVenue.tuesday,
                wednesday: agentVenue.wednesday,
                thursday: agentVenue.thursday,
                friday: agentVenue.friday,
                saturday: agentVenue.saturday,
                sunday: agentVenue.sunday
            };
        });

        return agent.AgentVenues.edit(data).then(
            action(response => {
                response.forEach(res => {
                    this.agentVenues = this.agentVenues.map(data => {
                        if (data.id === res.id) {
                            data.monday = res.monday;
                            data.tuesday = res.tuesday;
                            data.wednesday = res.wednesday;
                            data.thursday = res.thursday;
                            data.friday = res.friday;
                            data.saturday = res.saturday;
                            data.sunday = res.sunday;
                            data.manage = res.manage;
                            data.accepted = true;
                        }
                        return data;
                    });
                });

                this.groupedPendingAgentVenues = _.chain(this.agentVenues)
                    .groupBy("venueId")
                    .toPairs()
                    .map(function (pair) {
                        return _.zipObject(["venue", "data"], pair);
                    })
                    .value();

                return this.agentVenues;
            })
        );
    }

    @action
    changeManagePermision(value, id) {
        this.agentVenues = this.agentVenues.map(agentVenue => {
            if (agentVenue.id === id) {
                agentVenue.manage = value;
            }
            return agentVenue;
        });
    }

    @action
    updateManagePermision(value, id) {
        var agentVenue = undefined;

        this.agentVenues.forEach(data => {
            if (data.id === id && !agentVenue) {
                agentVenue = data;
            }
        });

        var requestData = [
            {
                id: agentVenue.id,
                manage: value,
                manageSocialPost: agentVenue.manageSocialPost,
                monday: agentVenue.monday,
                tuesday: agentVenue.tuesday,
                wednesday: agentVenue.wednesday,
                thursday: agentVenue.thursday,
                friday: agentVenue.friday,
                saturday: agentVenue.saturday,
                sunday: agentVenue.sunday
            }
        ];

        return agent.AgentVenues.edit(requestData).then(
            action(response => {
                this.agentVenues = this.agentVenues.map(data => {
                    if (data.id === id) {
                        data.manage = value;
                    }
                    return data;
                });
                this.groupedPendingAgentVenues = _.chain(this.agentVenues)
                    .groupBy("venueId")
                    .toPairs()
                    .map(function (pair) {
                        return _.zipObject(["venue", "data"], pair);
                    })
                    .value();
                return this.agentVenues;
            })
        );
    }

    @action
    handleToggleManageSocialPost(value, id) {
        var agentVenue = undefined;

        this.agentVenues.forEach(data => {
            if (data.id === id && !agentVenue) {
                agentVenue = data;
            }
        });

        var requestData = [
            {
                id: agentVenue.id,
                manage: agentVenue.monday,
                manageSocialPost: value,
                monday: agentVenue.monday,
                tuesday: agentVenue.tuesday,
                wednesday: agentVenue.wednesday,
                thursday: agentVenue.thursday,
                friday: agentVenue.friday,
                saturday: agentVenue.saturday,
                sunday: agentVenue.sunday
            }
        ];

        return agent.AgentVenues.edit(requestData).then(
            action(response => {
                this.agentVenues = this.agentVenues.map(data => {
                    if (data.id === id) {
                        data.manageSocialPost = value;
                    }
                    return data;
                });
                this.groupedPendingAgentVenues = _.chain(this.agentVenues)
                    .groupBy("venueId")
                    .toPairs()
                    .map(function (pair) {
                        return _.zipObject(["venue", "data"], pair);
                    })
                    .value();
                return this.agentVenues;
            })
        );
    }
    //get agent's venue

    @action
    getById(id) {
        return agent.AgentVenues.getById(id).then(response => {
            profileStore.mapAdditionalProfileData(response.agent);
            profileStore.mapAdditionalProfileData(response.venue);
            return response;
        });
    }

    @action
    getAgentVenueRequest() {
        return agent.ProfileRelationship.getAgentVenueRequets().then(
            action(async response => {
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    this.pendingAgentVenues = response.length > 0 ? response : [];
                    for (let i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].primaryProfile);
                        await profileStore.mapAdditionalProfileDataAsync(response[i].secondaryProfile);
                        runInAction(() => {
                            this.pendingAgentVenues[i] = response[i]
                        })
                    }
                }
                return this.pendingAgentVenues;
            })
        );
    }
    

    @action
    getAgentVenueSimple(agentId) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!agentId) {
            if (currentProfile != undefined) {
                agentId = currentProfile.id;
            }
        }
        return agent.AgentVenues.getMyVenueRosterSimple(agentId).then(
            action(async response => {
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    let mappedData = []
                    for (var i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].venue)
                        mappedData.push(response[i])
                        runInAction(() => {
                            this.agentVenues = response
                            return this.agentVenues
                        })
                    }
                } else this.agentVenues = []
            })
        );
    }
    

    @action
    getMyVenueRosterSocial(agentId) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!agentId) {
            if (currentProfile != undefined) {
                agentId = currentProfile.id;
            }else{
                return
            }
            
        }
        return agent.AgentVenues.getMyVenueRosterSocial(agentId).then(
            action(async response => {
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    let mappedData = []
                    for (var i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].venue)
                        mappedData.push(response[i])
                        
                    }
                    runInAction(() => {
                        this.agentVenueSocials = mappedData
                        return this.agentVenueSocials
                    })
                } else this.agentVenueSocials = []
            })
        );
    }
    
    @action
    getMyVenueRosterV2(agentId,limit,page) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!agentId) {
            if (currentProfile != undefined) {
                agentId = currentProfile.id;
            }else{
                return
            }
            
        }
        return agent.AgentVenues.getMyVenueRosterV2(agentId,limit,page).then(
            action(async result => {
                var response = result.data
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    let mappedData = []
                    for (var i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].venue)
                        mappedData.push(response[i])
                        
                    }
                    runInAction(() => {
                        this.agentVenues = this.agentVenues.concat(mappedData)
                        this.isMoreAgentVenues = result.isMore
                        return this.agentVenues
                    })
                } else this.agentVenues = []
            })
        );
    }

    @action
    setAgentVenuePage(page){
        this.agentVenuePage = page
    }

    @action
    getAgentVenue(agentId) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!agentId) {
            if (currentProfile != undefined) {
                agentId = currentProfile.id;
            }else{
                return
            }
            
        }
        return agent.AgentVenues.getMyVenueRoster(agentId).then(
            action(async response => {
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    let mappedData = []
                    for (var i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].venue)
                        mappedData.push(response[i])
                        runInAction(() => {
                            this.agentVenues = response
                            return this.agentVenues
                        })
                    }
                } else this.agentVenues = []
            })
        );
    }

    @action
    getAgentVenueForMyGigs(agentId) {
        const currentProfile = profileStore.getCurrentProfile();

        if (!agentId) {
            if (currentProfile != undefined) {
                agentId = currentProfile.id;
            }
        }
        return agent.AgentVenues.getMyVenueRoster(agentId).then(
            action(async response => {
                let result = [];
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    this.agentVenues = response ? response : []
                    for (var i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].venue);
                        runInAction(() => {                           
                            this.agentVenues[i] = response[i]
                        })

                    }

                }
                return response;

            })
        );
    }

    //get venue's agent

    @action
    getConsultantVenueRoster(consultantId) {
        if (!consultantId) {
            const profile = profileStore.getCurrentProfile();
            
            consultantId = profile.id;
        }
        return agent.AgentVenues.getConsultantVenueRoster(consultantId).then(
            action(async response => {
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    this.agentVenues = response ? response : []
                    for (var i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].venue)
                        await profileStore.mapAdditionalProfileDataAsync(response[i].agent)
                        runInAction(() => {
                            this.agentVenues[i] = response[i]
                        })
                    }
                }
            })
        );
    }

    @action
    getConsultantVenueRosterV2(consultantId, limit, page) {
        if (!consultantId) {
            const profile = profileStore.getCurrentProfile();
            
            consultantId = profile.id;
        }
        return agent.AgentVenues.getConsultantVenueRosterV2(consultantId, limit, page).then(
            action(async result => {
                var response = result.data
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    var mappedData = []
                    for (var i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].venue)
                        await profileStore.mapAdditionalProfileDataAsync(response[i].agent)
                        mappedData.push(response[i])
                    }
                    runInAction(() => {
                        this.agentVenues = this.agentVenues.concat(mappedData)
                        this.isMoreAgentVenues = result.isMore
                    })
                }
            })
        );
    }

    

    getConsultantVenueRosterSimple(consultantId) {
        if (!consultantId) {
            const profile = profileStore.getCurrentProfile();
            
            consultantId = profile.id;
        }
        return agent.AgentVenues.getConsultantVenueRosterSimple(consultantId).then(
            action(async response => {
                if (response !== 'undefined' && response !== undefined && typeof (response) !== string) {
                    this.agentVenues = response ? response : []
                    for (var i = 0; i < response.length; i++) {
                        await profileStore.mapAdditionalProfileDataAsync(response[i].venue)
                        await profileStore.mapAdditionalProfileDataAsync(response[i].agent)
                        runInAction(() => {
                            this.agentVenues[i] = response[i]
                        })
                    }
                }
            })
        );
    }

    @action
    getVenueAgent(venueId) {
        return Promise.all([
            agent.ProfileRelationship.getVenueAgentRequest(venueId).then(
                action(async response => {
                    var mappedPending = [];
                    for (var i = 0; i < response.length; i++) {
                        var data = response[i];
                        await profileStore.mapAdditionalProfileDataAsync(data.primaryProfile);
                        await profileStore.mapAdditionalProfileDataAsync(data.secondaryProfile);
                        data.accepted = false;
                        mappedPending.push(data)
                    }
                    runInAction(() => {
                        this.pendingAgentVenues = mappedPending
                        return mappedPending;
                    })
                })
            ),
            agent.AgentVenues.getMyVenuesAgent(venueId).then(
                action(async response => {
                    var agentVen = []
                    for (var i = 0; i < response.length; i++) {
                        var data = response[i];
                        await profileStore.mapAdditionalProfileDataAsync(data.agent);
                        await profileStore.mapAdditionalProfileDataAsync(data.venue);
                        data.accepted = true;
                        agentVen.push(data);
                    }
                    runInAction(() => {
                        this.agentVenues = agentVen
                        return agentVen;
                    })
                })
            )
        ]);
    }

    @action
    getActiveVenueAgent(venueId) {
        return agent.AgentVenues.getMyVenuesAgent(venueId).then(
            action(response => {
                this.agentVenues = response.map(data => {
                    profileStore.mapAdditionalProfileData(data.agent);
                    profileStore.mapAdditionalProfileData(data.venue);
                    data.accepted = true;
                    return data;
                });
                return this.agentVenues;
            })
        );
    }

    @action
    getStaffVenueAgentRequest(staffId) {
        const currentProfile = profileStore.getCurrentProfile();

        if (!staffId) {
            staffId = currentProfile.id;
        }

        return agent.ProfileRelationship.getStaffVenuesAgentRequests(staffId).then(
            action(async response => {
                if (response && response.length > 0 && response !== undefined) {
                    var mapped = [];
                    for (var i = 0; i < response.length; i++) {
                        var data = response[i]
                        await profileStore.mapAdditionalProfileDataAsync(data.primaryProfile);
                        await profileStore.mapAdditionalProfileDataAsync(data.secondaryProfile);
                        data.accepted = true;
                        mapped.push(data)
                    }
                    runInAction(() => {
                        this.pendingAgentVenues = mapped
                        return mapped;
                    })
                } else {
                    this.pendingAgentVenues = []
                    return mapped;
                }


            })

        );
    }

    @action
    getStaffVenueAgent(staffId) {
        const currentProfile = profileStore.getCurrentProfile();

        if (!staffId) {
            staffId = currentProfile.id;
        }

        return agent.AgentVenues.getStaffVenuesAgent(staffId).then(
            action(async response => {
                var mapped = await Promise.all(_.map(response, async data => {
                    await profileStore.mapAdditionalProfileDataAsync(data.agent);
                    await profileStore.mapAdditionalProfileDataAsync(data.venue);
                    data.accepted = true;
                    return data;
                }));
                runInAction(() => {
                    this.agentVenues = mapped
                    this.groupedPendingAgentVenues = _.chain(mapped)
                        .groupBy("venueId")
                        .toPairs()
                        .map(function (pair) {
                            return _.zipObject(["venue", "data"], pair);
                        })
                        .value();

                    return this.agentVenues;
                })


            })
        );
    }

    @action
    deleteAgentVenues(ids) {
        let { profile } = profileStore;
        return agent.AgentVenues.deleteAgentVenues(ids, profile.id).then(
            action(response => {
                response.forEach(data => {
                    _.remove(this.agentVenues, agentVenue => {
                        return agentVenue.id === data.id;
                    });
                    return data;
                });
                return response;
            })
        );
    }

    @action
    deleteAgentVenue(id) {
        let { profile } = profileStore;
        return agent.AgentVenues.deleteAgentVenue(id, profile.id).then(
            action(response => {
                this.agentVenues = this.agentVenues.filter(x => x.id !== id);
                this.groupedPendingAgentVenues = _.chain(this.agentVenues)
                    .groupBy("venueId")
                    .toPairs()
                    .map(function (pair) {
                        return _.zipObject(["venue", "data"], pair);
                    })
                    .value();
                return response;
            })
        );
    }

    //invitation
    @action
    acceptRequest(request) {
        return agent.ProfileRelationship.accept(request).then(
            action(response => {
                if (this.pendingAgentVenues.length <= 1) this.pendingAgentVenues = []
                if (this.agentVenues.length <= 1) this.agentVenues = []
                return response;
            })
        );
    }

    @action
    venueAcceptAgentRequest(request) {
        request.rules = JSON.stringify(this.dayToBook);
        return agent.ProfileRelationship.accept(request).then(
            action(response => {
                this.getVenueAgent(response.secondaryProfileId);
                return response;
            })
        );
    }

    @action
    staffAcceptAgentRequest(request) {
        request.rules = JSON.stringify(this.dayToBook);
        return agent.ProfileRelationship.accept(request).then(
            action(response => {
                return Promise.all([this.getStaffVenueAgentRequest(), this.getStaffVenueAgent()]).then(() => {
                    return response;
                });
            })
        );
    }

    @action
    deleteRequestByStaff(request) {
        if (request.primaryProfile.type === ProfileType.Venue.ordinal) {
            return agent.ProfileRelationship.remove(request.id).then(
                action(response => {
                    this.pendingAgentVenues = this.pendingAgentVenues.filter(x => x.id !== request.id);
                    return this.pendingAgentVenues;
                })
            );
        } else {
            return this.declineAgentRequest(request.id);
        }
    }

    @action
    declineAgentRequest(requestId) {
        return agent.ProfileRelationship.decline(requestId).then(
            action(response => {
                this.pendingAgentVenues = this.pendingAgentVenues.filter(x => x.id !== response.id);
                return response;
            })
        );
    }

    @action
    declineAgentRequests(ids) {
        return agent.ProfileRelationship.declineMany(ids).then(
            action(response => {
                response.forEach(data => {
                    this.pendingAgentVenues = this.pendingAgentVenues.filter(x => x.id !== data.id);
                    return data;
                });
                return response;
            })
        );
    }

    @action
    removeAgentRequests(ids) {
        return agent.ProfileRelationship.removeMany(ids).then(
            action(response => {
                response.forEach(data => {
                    this.pendingAgentVenues = this.pendingAgentVenues.filter(x => x.id !== data.id);
                    return data;
                });
                return response;
            })
        );
    }

    @action
    resetInvitationField() {
        this.invitedId = undefined;
        this.invitationMessage = "";
        this.invitedProfile = undefined;
        this.invitedVenues = [];
        this.dayToBook = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
        };
        this.dayToBookVenueInvitation = {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true
        };
    }

    @action
    setInvitedProfile(profile) {
        if (profile) {
            this.invitedId = profile.id;
        }
        this.invitedProfile = profile;
    }

    @action
    setInvitationMessage(message) {
        this.invitationMessage = message;
    }

    @action
    setInvitedId(id) {
        this.invitedId = id;
    }

    @action
    setInvitedVenues(venues) {
        this.invitedVenues = venues;
    }

    @action
    toggleDayToBook(value) {
        this.dayToBook[value] = !this.dayToBook[value];
    }

    @action
    toggleDayToBookVenueInvitational(value) {
        this.dayToBookVenueInvitation[value] = !this.dayToBookVenueInvitation[value];
    }

    @action
    inviteAgentByVenue(venueId) {
        const { profile } = profileStore;

        let requestDetail = {
            primaryProfileId: venueId,
            secondaryProfileId: this.invitedId,
            type: ProfileRelationType.AgentVenue.ordinal,
            requestMessage: this.invitationMessage,
            rules: JSON.stringify(this.dayToBook),
            createdById: profile.id,
            manage: this.manage,
            manageSocialPost: this.manageSocialPost
        };

        return agent.ProfileRelationship.invite(requestDetail).then(
            action(response => {
                return response;
            })
        );
    }

    @action
    inviteAgentByStaff() {
        const { currentProfile } = profileStore;
        let requests = [];

        this.invitedVenues.forEach(venue => {
            let requestDetail = {
                primaryProfileId: venue.id,
                secondaryProfileId: this.invitedProfile.id,
                type: ProfileRelationType.AgentVenue.ordinal,
                requestMessage: this.invitationMessage,
                rules: JSON.stringify(this.dayToBook),
                createdById: currentProfile.id,
                manage: this.manage,
                manageSocialPost: this.manageSocialPost
            };
            requests.push(requestDetail);
        });

        return agent.ProfileRelationship.inviteMany(requests).then(
            action(response => {
                response.forEach(data => {
                    profileStore.mapAdditionalProfileData(data.primaryProfile);
                    profileStore.mapAdditionalProfileData(data.secondaryProfile);
                    data.accepted = false;
                    this.pendingAgentVenues.push(data);
                });
                return this.pendingAgentVenues;
            })
        );
    }

    @action
    inviteVenueByAgent() {
        const { currentProfile } = profileStore;

        if (currentProfile.type !== ProfileType.Agent.ordinal) {
            return "Not Agent";
        }

        let requestDetail = {
            primaryProfileId: currentProfile.id,
            secondaryProfileId: this.invitedId,
            type: ProfileRelationType.AgentVenue.ordinal,
            requestMessage: this.invitationMessage,
            rules: JSON.stringify(this.dayToBook),
            createdById: currentProfile.id,
            manage: this.manage,
            manageSocialPost: this.manageSocialPost
        };

        return agent.ProfileRelationship.invite(requestDetail).then(
            action(response => {
                profileStore.mapAdditionalProfileData(response.primaryProfile);
                profileStore.mapAdditionalProfileData(response.secondaryProfile);
                response.accepted = false;
                this.pendingAgentVenues.push(response);
                return response;
            })
        );
    }

    @action
    inviteVenueByAgentV2() {
        const { currentProfile } = profileStore;

        if (currentProfile.type !== ProfileType.Agent.ordinal) {
            return "Not Agent";
        }

        let requestDetail = {
            primaryProfileId: currentProfile.id,
            secondaryProfileId: this.invitedId,
            type: ProfileRelationType.AgentVenue.ordinal,
            requestMessage: this.invitationMessage,
            rules: JSON.stringify(this.dayToBookVenueInvitation),
            createdById: currentProfile.id,
            manage: this.manage,
            manageSocialPost: this.manageSocialPost
        };

        return agent.ProfileRelationship.invite2(requestDetail).then(
            action(response => {
                profileStore.mapAdditionalProfileData(response.primaryProfile);
                profileStore.mapAdditionalProfileData(response.secondaryProfile);
                response.accepted = false;
                this.pendingAgentVenues.push(response);
                return response;
            })
        );
    }

    @action
    removeAgentVenueRequest(request) {
        return agent.ProfileRelationship.remove(request).then(
            action(response => {
                this.pendingAgentVenues = this.pendingAgentVenues.filter(x => x.id !== response.id);
                return response;
            })
        );
    }
}

export default new AgentVenueStore();
