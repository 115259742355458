import React, { Component } from "react";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
// import WidgetFormContainer from "../../components/widget/WidgetFormContainer";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import ActList from "../../components/acts/ActList";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import { inject } from "mobx-react";
import { ProfileType } from "../../types/enum";
@inject("agentActStore", "profileStore")
class Acts extends Component {
    constructor() {
        super();
    
        this.style = {
          color: 'white',
        };
      }
    
 

    render() {
         const profiles = this.props.profileStore.getCurrentProfile();
         var breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Manage", link: "/" }, { name: "My Acts" }];
         if(profiles?.type === ProfileType.PrivateHire.ordinal){
            breadcrumb =  [ { name: "My Acts" }];
         }
        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    { 
                            profiles.type !== ProfileType.PrivateHire.ordinal ? (
                                <Heading title="My Acts">
                                    <Button classes={{ root: "pull-right" }} color="primary" variant="contained" onClick={() => this.props.agentActStore.setIsAgentAct(false)} style={Object.assign({}, this.style, {color: 'white' })}  component={Link} to="/myact/create">
                                        Create Act
                                    </Button>
                                </Heading>
                            ) :  <Heading title="My Favourite Acts">
                                </Heading>
                        }
                    <ActList />
                </MainContainer>
            </main>
        );
    }
}

export default Acts;
