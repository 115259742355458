import React, { Component, Fragment } from "react";

import "./CustomEvent.css";
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";

class CustomAgendaEvent extends Component {
    render() {
        const { data } = this.props;

        const { event } = data;

        let eventTooltipTitle = (
            <div className="event-tooltip-open">
                <span>{event.title}</span>
                <br />
                {event.venueName && (
                    <Fragment>
                        <span>{event.venueName}</span>
                        <br />
                    </Fragment>
                )}
                <span>
                    {event.type === "availability" || event.type === "google-calendar"
                        ? event.allDay
                            ? moment(event.start).format("DD-MM-YYYY") === moment(event.end).format("DD-MM-YYYY")
                                ? moment(event.start).format("DD-MM-YYYY")
                                : moment(event.start).format("DD-MM-YYYY") + " - " + moment(event.end).format("DD-MM-YYYY")
                            : moment(event.start).format("DD-MM-YYYY HH:mm") === moment(event.end).format("DD-MM-YYYY HH:mm")
                                ? moment(event.start).format("DD-MM-YYYY HH:mm")
                                : moment(event.start).format("DD-MM-YYYY HH:mm") + " - " + moment(event.end).format("DD-MM-YYYY HH:mm")
                        : moment(event.start).format("DD-MM-YYYY") === moment(event.end).format("DD-MM-YYYY")
                            ? moment(event.start).format("DD-MM-YYYY")
                            : moment(event.start).format("DD-MM-YYYY") + " - " + moment(event.end).format("DD-MM-YYYY")}
                </span>
                {event.timeStart &&
                    event.timeEnd && (
                        <Fragment>
                            <br />
                            <span>
                                {event.timeStart === event.timeEnd
                                    ? moment(event.timeStart, "HH:mm").format("HH:mm")
                                    : moment(event.timeStart, "HH:mm").format("HH:mm") + " - " + moment(event.timeEnd, "HH:mm").format("HH:mm")}
                            </span>
                            <br />
                        </Fragment>
                    )}
            </div>
        );

        return (
            <Tooltip
                title={eventTooltipTitle}
                placement="bottom-start"
                classes={{
                    tooltip: "event-tooltip",
                    open: "event-tooltip-open"
                }}
            >
                <div
                    onClick={() => this.props.clickHandler()}
                    title=""
                    // className={
                    //     this.props.data.event.type +
                    //     (this.props.data.event.status ? "-" + this.props.data.event.status : "-PlayerNotified") +
                    //     "-event event agenda-event"
                    // }
                    className={this.props.data.event.type + (this.props.data.event.statusClass ? "-" + this.props.data.event.statusClass : "") + "-event event"}
                >
                    {this.props.data.event.title}
                </div>
            </Tooltip> 
        );
    }
}
export default CustomAgendaEvent;
