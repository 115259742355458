import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button } from "@material-ui/core";

import Heading from "../../Heading";
import ConsultantTable from "./ConsultantTable";
import ConsultantInvitationModal from "./ConsultantInvitationModal";

import { ProfileRelationType } from "../../../types/enum";

@inject("templateStore", "agentConsultantStore", "userInvitationStore","profileStore")
@observer
class ConsultantContainer extends Component {
    state = {
        invitationModalState: false
    };

    openInvitationModal = () => {
        this.props.agentConsultantStore.resetInvitationData();
        this.setState({ invitationModalState: true });
    };
    closeInvitationModal = () => this.setState({ invitationModalState: false });

    constructor(props) {
        super(props);
        this.props.agentConsultantStore.resetData();
        this.props.userInvitationStore.resetInvitations();
    }

    componentDidMount() {
        var currentProfile = this.props.profileStore.getCurrentProfile();

        this.props.templateStore.showLoadingScreen();
        Promise.all([
            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentConsultant.ordinal,currentProfile.id),
            this.props.agentConsultantStore.getAgentConsultants()
        ]).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    }

    render() {
        return (
            <Fragment>
                <Heading title="My Consultants">
                    <Button color="primary" variant='contained' className="pull-right" onClick={this.openInvitationModal}>
                        Add Consultant
                    </Button>
                </Heading>
                <ConsultantTable />
                <ConsultantInvitationModal open={this.state.invitationModalState} onClose={this.closeInvitationModal} />
            </Fragment>
        );
    }
}

export default ConsultantContainer;
