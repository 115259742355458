import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import CustomEmbed from "./CustomEmbedEntertainmentWidget";


const BASE_URL = process.env.REACT_APP_BASE_URL;

@withRouter
@inject("templateStore", "profileStore", "embedGigStore")
@observer
class EntertainmentGigWidget extends Component {
    state = {
        open: false,
        copyState: false
    };

    handleCopy = () => {
        var copyText = document.getElementById("link");
        copyText.select();
        document.execCommand("copy");
        this.setState({ copyState: true });
        this.props.templateStore.openSnackbar("Code Copied");
        setTimeout(
            function() {
                this.setState({ copyState: false });
            }.bind(this),
            2000
        );
    };

    handleClickOpen = () => this.setState({ open: true });

    handleClose = () => this.setState({ open: false });

    generateParameters = (...par) => {
        let args = par.map(ar => {
            return typeof ar === "string" ? ar.replace("#", "") : ar;
        });
        // const params =
        //     "?headerBgColor=" +
        //     args[0] +
        //     "&headerFtColor=" +
        //     args[1] +
        //     "&tableBgColor=" +
        //     args[2] +
        //     "&tableFtColor=" +
        //     args[3] +
        //     "&dateBgColor=" +
        //     args[4] +
        //     "&dateFtColor=" +
        //     args[5] +
        //     "&hideToolbar=" +
        //     args[6] + 
        //     "&hideOrderUber=" + 
        //     args[7] + 
        //     "&hideShareButton=" + 
        //     args[8];
        const params = 
            "?tableBgColor=" + 
            args[2] + 
            "&tableFtColor=" + 
            args[3] + 
            "&hideToolbar=" + 
            args[6];
        return params;
    };

    componentDidMount() {
        const { profile } = this.props.profileStore;
        if(profile){
            this.props.embedGigStore.loadEmbedCode( profile.id );
        }
        else{
            this.props.embedGigStore.loadEmbedCode( 0 );
        }
        
    }

    render() {
        const { profile } = this.props.profileStore;
        const ENV = process.env.REACT_APP_ENTERTAINER_BASE_URL
        const link = `${ENV}nextevent.html?profileId=${profile?.id}`
        return (
            <Fragment>
                <TextField
                    id="link"
                    label="Next Event (1 event)"
                    multiline
                    rows="1"
                    value={link}
                    margin="normal"
                    fullWidth
                />

                <CustomEmbed link={link} profileId={ (profile ? profile.id : 0) } />
                <Button onClick={this.handleCopy} color="primary" autoFocus style={{ float: "right" }}>
                    {this.state.copyState ? "Copied!" : "Copy"}
                </Button>
            </Fragment>
        );
    }
}

export default EntertainmentGigWidget;
