import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import ComingSoon from "../pages/ComingSoon";
import jwtDecode from "jwt-decode";
import CacheHelper from "../helper/cache";
import agent from "../agent";
import PrivateHireGig from "../pages/booking/PrivateHireGig";
import { ProfileType } from "../types/enum";

const VERIFICATION_REQUIRED = process.env.REACT_APP_EMAIL_CONFIRMATION_REQUIRED === "true";
const SHOW_ON_DEV_FEATURE = process.env.REACT_APP_SHOW_PRODUCTION_CREW === "true";
const ENABLE_SUBSCRIPTION = true;//process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";

@inject("userStore", "commonStore", "templateStore", "profileStore", "billingStore")
@observer
class PrivateRoute extends React.Component {
    _forbiddenAccess = false;
    constructor(props){
        super(props);
        this.state = {forbiddenAccess : false};
    }
    render =  () => {
        this.props.billingStore.loadAccess();
        const { userStore, templateStore, profileStore, billingStore, requiredSubscription, sidebarKey, path, stillOnDev, onDevTitle, ...restProps } = this.props;

        if( typeof userStore.currentUser === "undefined"){
            userStore.saveUserFromCache(CacheHelper.getUser());
        }
        // check email confirmation status
        if (
            userStore &&
            userStore.currentUser &&
            !userStore.isUserVerified() &&
            path !== "/confirmation" &&
            path !== "/resendconfirmationtoken" &&
            VERIFICATION_REQUIRED
        ) {
            return <Redirect to="/confirmation" />;
        }
        templateStore.setShowSidebar(false);
        if (sidebarKey) {
            templateStore.setActiveSidebarKey(sidebarKey);
        }

        let currentProfile = profileStore.getCurrentProfile();
        if(currentProfile != null || currentProfile != undefined){
            let type = profileStore.getCurrentProfile().typeString === "staff" ? "venue" : profileStore.getCurrentProfile().typeString;
            if(parseInt(userStore.currentUser.status) == 50){
                if ((((requiredSubscription && requiredSubscription.includes(type)) || type === 'musician')  && path !== '/account/setting/:tab?')) {
                    if(billingStore.billingAccess && !billingStore.temporaryAccess){
                    
                        if((billingStore.billingAccess.isSubscriptionRequired || billingStore.billingAccess.isNeedToUpdatePayment) && !billingStore.billingAccess.isBasicPlan && process.env.REACT_APP_DISABLE_BILLING_MOBILE === "true"){
                            return <Redirect to="/account/setting/contactadministrator" />;
                        }
                        else if((billingStore.billingAccess.isSubscriptionRequired || billingStore.billingAccess.isNeedToUpdatePayment) && !billingStore.billingAccess.isBasicPlan) {
                            return <Redirect to="/account/setting/lock" />;
                        }
                    }
                }else if(currentProfile.type == ProfileType.PrivateHire.ordinal  && path !== '/account/setting/:tab?'){
                    if((billingStore.billingAccess.isSubscriptionRequired || billingStore.billingAccess.isNeedToUpdatePayment) && process.env.REACT_APP_DISABLE_BILLING_MOBILE === "true"){
                        return <Redirect to="/account/setting/contactadministrator" />;
                    }
                    else if(billingStore.billingAccess.isSubscriptionRequired || billingStore.billingAccess.isNeedToUpdatePayment) {
                        return <Redirect to="/account/setting/privatehirelock" />;
                    }
                
                
                }
            }
        }
        if(billingStore.temporaryAcess){
            billingStore.setTemporaryAccess(false)
        }
        if ((stillOnDev && SHOW_ON_DEV_FEATURE) || !stillOnDev) {
            if (userStore.currentUser) return <Route {...restProps} />;
        } else if (stillOnDev && !SHOW_ON_DEV_FEATURE) {
            if (userStore.currentUser) return <Route {...restProps} component={() => <ComingSoon title={onDevTitle} />} />;
        }

        return <Redirect to="/sign-in" />;
    }
    
}

export default PrivateRoute;
