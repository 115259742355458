import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import RichTextEditor from "../../richTextEditor/RichTextEditor";
import ModalContainer from "../../modal/ModalContainer";
import InvitedActPreview from "../../util/ActInvitation/InvitedActPreview";
import ProfileSearchBox from "../../fields/ProfileSearchBox";
import TableRowProfile from "../../util/TableRowProfile";
import ResponsiveTable from "../../table/ResponsiveTable";

import SelectField2 from "../../fields/SelectField2";

import Loading from "../../loadingSpinner/Loading";
import image from "../../../helper/image";
import { ProfileType } from "../../../types/enum";

@inject("profileStore", "venueActStore", "actTypeStore","venueStore", "templateStore", "userInvitationStore", "agentActStore")
@observer
class StaffActRosterInvitationModal extends Component {
    state = {
        isLoading: false,
        inviteNewUser: false
    };

    venueColumns = [{ key: "venue", label: "Venue" }];

    venueRows = [];

    constructor(props) {
        super(props);
        this.props.venueStore.resetVenues();
        this.props.venueActStore.clearInviteData();
    }

    componentDidMount(){
        this.props.actTypeStore.loadActTypes()
    }

    componentDidUpdate(prevProps){
        if(this.props.open && !prevProps.open){
            this.venueRows = [];
            this.setState(
                {
                    ...this.state,
                    isLoading: true
                },
                () => {
                    this.props.venueStore.load().then(() => {
                        this.setState({
                            ...this.state,
                            isLoading: false
                        });
                    });
                }
            );
        }
    }

    mapVenuesToRowData = () => {
        const { inviteNewUser } = this.state;
        const { venues } = this.props.venueStore;
        const { pendingRequest, venueActRoster, invitedActs, invitedActsVenues } = this.props.venueActStore;
        if (venues) {
            this.venueRows = [];
            venues.forEach(data => {
                var row = {};
                row.id = data.id;
                row.disableCheckbox =
                    pendingRequest.filter(x => x.primaryProfileId === data.id && invitedActs.filter(y => y.id === x.secondaryProfileId).length > 0)
                        .length > 0 ||
                    venueActRoster.filter(x => x.venueId === data.id && invitedActs.filter(y => y.id === x.actId).length > 0).length > 0 ||
                    (!this.getInvitedAct() && !inviteNewUser);
                row.checked = !row.disableCheckbox ? invitedActsVenues.filter(x => x.id === data.id).length > 0 : false;
                row.venue = <TableRowProfile name={data.fullName} avatarUrl={image.getProfileImage(data)} profileId={data.id}/>;
                row.venueData = data;
                this.venueRows.push(Object.assign({}, row));
            });
        }
    };

    handleInvite = () => {
        const { inviteNewUser } = this.state;
        this.props.templateStore.showLoadingScreen();
        if (inviteNewUser) {
            this.props.userInvitationStore.inviteActOwnerByStaff().then(
                () => {
                    this.props.templateStore.hideLoadingScreen();
                    this.handleCloseModal();
                    this.setState({...this.state, inviteNewUser: false})
                },
                err => {
                    this.props.templateStore.hideLoadingScreen();
                    this.props.templateStore.openSnackbar(this.handleErrorMessage(err));
                }
            );
        } else {
            this.props.venueActStore.inviteManyActToManyVenues().then(() => {
                this.props.templateStore.hideLoadingScreen();
                this.handleCloseModal();
                this.setState({...this.state, inviteNewUser: false})
            });
        }
    };

    handleSelect = item => {
        this.props.venueActStore.setInvitedAct(item);
        this.setState({ ...this.state });
    };

    handleInvitationMessageChange = value => {
        this.props.venueActStore.setInvitationMessage(value);
    };

    getInvitedAct = () => {
        if(this.props.profileType === "agent") {
            return this.props.agentActStore?.invitedAct
        } else {
            return this.props.venueActStore?.invitedAct;
        }
    };

    getInvitedActs = () => {
        if(this.props.profileType === "agent") {
            return this.props.agentActStore?.invitedAct?.myActs
        } else {
            if (this.props.venueActStore?.invitedAct?.myMusician?.myActs) {
                return this.props.venueActStore?.invitedAct?.myMusician?.myActs
            }
            return this.props.venueActStore?.invitedAct?.myActs;
        }
        // return this.props.venueActStore.invitedActs;
    };

    getInvitedMusician = () => {
        if(this.props.profileType === "agent") {
            return this.props.agentActStore.invitedAct.myMusician;
        } else {
            return this.props.venueActStore.invitedAct.myMusician;;
        }
        // return this.props.venueActStore.invitedAct.myMusician;
    };

    getInvitationMessage = () => {
        return this.props.venueActStore.invitationMessage;
    };

    handleClear = () => {
        this.props.venueActStore.clearInviteData();
    };

    handleCheckRow = invitedActs => {
        this.props.venueActStore.setInvitedActs(invitedActs);
    };

    handleClear = () => {
        this.props.venueActStore.clearInviteData();
    };

    getCheckedVenues = rows => {
        var selectedVenues = [];
        rows.forEach(row => {
            if (!row.disableCheckbox) {
                selectedVenues.push(row.venueData);
            }
        });
        this.props.venueActStore.setInvitedActsVenues(selectedVenues);
    };

    handleNoActFound = value => {
        this.props.userInvitationStore.resetInvitedData();
        this.setState(
            {
                ...this.state,
                inviteNewUser: true
            },
            () => {
                this.props.userInvitationStore.setInvitedActName(value);
            }
        );
    };

    isInviteButtonDisabled = () => {
        const { inviteNewUser } = this.state;
        const { errors, invitedUserFirstName, invitedUserLastName, invitedActName } = this.props.userInvitationStore;
        const { invitedActs, invitedActsVenues } = this.props.venueActStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        if(this.props.forSearch) {
            if(this.props.profileType == "agent") {
                return !(invitedActs.length > 0 );
            } else {
                return !(invitedActs.length > 0 && invitedActsVenues.length > 0)
            }
        }
        if(currentProfile.type === ProfileType.Staff.ordinal) {
            if (inviteNewUser) {
                const isError = errors?.firstName || errors?.lastName || errors?.actName || errors?.email
                return invitedActsVenues.length === 0 || isError || !invitedUserFirstName || !invitedUserLastName || !invitedActName;
            } else {
                return !(invitedActs.length > 0 && invitedActsVenues.length > 0);
            }
        } else {
            return  !(invitedActs.length > 0 );
        }
    };    

    handleErrorMessage = (err) => {
        try {
            return Object.values(JSON.parse(err))   
        } catch (error) {
            return err
        }
    }

    handleValidate(value, type) {
        switch (type) {
            case "firstName":
                this.props.userInvitationStore.validateInvitedUserFirstName(value);
                break;
            case "lastName":
                this.props.userInvitationStore.validateInvitedUserLastName(value);
                break;
            case "email":
                this.props.userInvitationStore.validateInvitedUserEmail(value);
                break;
            case "actName":
                this.props.userInvitationStore.validateInvitedActName(value);
                break;
        
            default:
                break;
        }
    }

    handleCloseModal() {
        this.handleClear();
        this.props.userInvitationStore.resetInvitedData();
        this.props.onClose();
        this.setState({...this.state, inviteNewUser: false})
    }

    mapActTypeSuggestions = () => {
        const { actTypes } = this.props.actTypeStore;
        return actTypes.map(actType => {
            return {
                value: actType.id,
                label: actType.name
            };
        });
    };
    mapActTypeSuggestions = () => {
        const { actTypes } = this.props.actTypeStore;
        return actTypes.map(actType => {
            return {
                value: actType.id,
                label: actType.name
            };
        });
    };
    getLineupsType = () => {
        let actTypes = [
            { name: "Solo", instrument: 1 },
            { name: "Duo", instrument: 2 },
            { name: "Trio", instrument: 3 },
            { name: "Quartet / 4 Piece", instrument: 4 },
            { name: "Quintet / 5 Piece", instrument: 5 },
            { name: "Sextet / 6 Piece", instrument: 6 }
        ];

        for (var i = 0; i < 93; i++) {
            actTypes.push({ name: i + 7 + " Piece", instrument: i + 7 });
        }
        return actTypes.map(actType => {
            return {
                value: actType.instrument,
                label: actType.name
            };
        });
    };

    handleActTypeChange = value => {
        this.props.userInvitationStore.setActType(value);
    };

    handleActLineupChange = value => {
        this.props.userInvitationStore.setActLineup(value);
    };
    render() {
        const { errors, invitedActName, actLineup, actType,invitedUserFirstName, invitedUserLastName, invitedUserEmail } = this.props.userInvitationStore;
        const { inviteNewUser } = this.state;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.mapVenuesToRowData();
        return (
            <ModalContainer title="Add Act" open={this.props.open} onClose={this.props.onClose}>
                <DialogContent>
                    <div style={{ position: "relative", minHeight:400 }}>
                        <Loading showed={this.state.isLoading} borderRadius={0} />
                        <Grid container spacing={8}>
                            <Grid item xs={12} lg={6}>
                                {this.getInvitedAct() && !inviteNewUser ? (
                                    <Fragment>
                                        <InputLabel>Invited Act's Owner</InputLabel>
                                        <InvitedActPreview
                                            musician={this.getInvitedMusician()}
                                            invitedActs={this.getInvitedActs()}
                                            handleCheckRow={this.handleCheckRow}
                                            handleClear={!this.props.forSearch ? this.handleClear : null}
                                            forSearch={this.props.forSearch}
                                        />
                                    </Fragment>
                                ) : !inviteNewUser ? (
                                    <Fragment>
                                        <InputLabel>Act</InputLabel>
                                        <ProfileSearchBox
                                            placeholder="Search Act"
                                            id="invite-act-name"
                                            handleSearch={value => this.props.profileStore.searchAct(value)}
                                            handleChange={item => this.handleSelect(item)}
                                            handleEmpty={this.handleNoActFound}
                                            menuStyle={{
                                                position: "absolute",
                                                maxHeight: "300px",
                                                overflowY: "auto",
                                                width: "100%"
                                            }}
                                        />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Act Name"
                                                    value={invitedActName}
                                                    fullWidth
                                                    onBlur={e => this.handleValidate(e.target.value, "actName")}
                                                    onChange={e => this.props.userInvitationStore.setInvitedActName(e.target.value)}
                                                    error={errors && errors["actName"] ? true : false}
                                                    helperText={errors && errors["actName"] ? errors["actName"] : ""}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                                                <SelectField2
                                                    id="bookingActType"
                                                    label="Act Type"
                                                    value={actType}
                                                    suggestions={this.mapActTypeSuggestions()}
                                                    placeholder="Select Act Type"
                                                    onChange={this.handleActTypeChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                                                <SelectField2
                                                    id="bookingActLineupType"
                                                    label="Lineup"
                                                    value={actLineup}
                                                    suggestions={this.getLineupsType()}
                                                    placeholder="Select Lineup Numbers"
                                                    onChange={this.handleActLineupChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Act Owner First Name"
                                                    value={invitedUserFirstName}
                                                    fullWidth
                                                    onBlur={e => this.handleValidate(e.target.value, "firstName")}
                                                    onChange={e => this.props.userInvitationStore.setInvitedUserFirstName(e.target.value)}
                                                    error={errors && errors["firstName"] ? true : false}
                                                    helperText={errors && errors["firstName"] ? this.handleErrorMessage(errors["firstName"]) : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Act Owner Last Name"
                                                    value={invitedUserLastName}
                                                    fullWidth
                                                    onBlur={e => this.handleValidate(e.target.value, "lastName")}
                                                    onChange={e => this.props.userInvitationStore.setInvitedUserLastName(e.target.value)}
                                                    error={errors && errors["lastName"] ? true : false}
                                                    helperText={errors && errors["lastName"] ? this.handleErrorMessage(errors["lastName"]) : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Act Owner Email"
                                                    value={invitedUserEmail}
                                                    fullWidth
                                                    onBlur={e => this.handleValidate(e.target.value, "email")}
                                                    onChange={e => this.props.userInvitationStore.setInvitedUserEmail(e.target.value)}
                                                    error={errors && errors["email"] ? true : false}
                                                    helperText={errors && errors["email"] ? this.handleErrorMessage(errors["email"]) : ""}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                            {currentProfile.type === ProfileType.Staff.ordinal ? 
                                <Grid item xs={12} lg={6}>
                                    <InputLabel>Venues</InputLabel>
                                    <ResponsiveTable
                                        selectable={true}
                                        rows={this.venueRows}
                                        columns={this.venueColumns}
                                        getCheckedRows={this.getCheckedVenues}
                                    />
                                </Grid> :
                                <Grid item xs={12} lg={6}>
                                    <InputLabel>Invitation Message</InputLabel>
                                    <RichTextEditor
                                        label="Invitation Message"
                                        value={this.getInvitationMessage()}
                                        onChange={this.handleInvitationMessageChange}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => this.handleCloseModal()}>
                        Close
                    </Button>
                    <Button id="invite-act-submit" color="primary" onClick={this.handleInvite} disabled={this.isInviteButtonDisabled()}>
                        Invite
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default StaffActRosterInvitationModal;
