import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Button, Grid, Typography, TextField } from "@material-ui/core";
import ResponsiveTable from "../table/ResponsiveTable";
import AddIcon from "@material-ui/icons/Add";
import FileCopy from "@material-ui/icons/FileCopy";
import NewDrinkModal from "./menuModal/NewDrinkModal";
import NewFoodModal from "./menuModal/NewFoodModal";
import Loading from "../loadingSpinner/Loading";
import ImportFoodModal from "./menuModal/ImportFoodModal";
import ImportDrinkModal from "./menuModal/ImportDrinkModal";
import CurrencyFormat from "react-currency-format";
import currencyHelper from "../../helper/currency";

@inject("profileStore", "venueMenuStore", "templateStore")
@withRouter
@observer
class VenueMenu extends React.Component {
    constructor(props) {
        super(props);

        this.props.venueMenuStore.reset();

        this.state = {
            isLoading: true,
            showNewDrinkModal: false,
            showNewFoodModal: false,
            editFood: false,
            editDrink: false,
            showImportFoodModal: false,
            showImportDrinkModal: false,
            currency: undefined
        };

        this.foodColumn = [
            { key: "name", label: "Food Name", width: "30%" },
            { key: "section", label: "Food Section", width: "25%" },
            { key: "price", label: "Price", width: "25%" },
            { key: "action", label: "", width: "20%" }
        ];

        this.drinkColumns = [
            { key: "name", label: "Drink Name", width: "30%" },
            { key: "section", label: "Drink Section", width: "25%" },
            { key: "price", label: "Price", width: "25%" },
            { key: "action", label: "", width: "20%" }
        ];
    }

    componentDidMount() {
        this.props.venueMenuStore.setType(this.props.templateStore.getType(this.props.type));
        const { currentProfile } = this.props.profileStore;
        this.setState({
            ...this.state,
            currency: currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$"
        });
        this.props.venueMenuStore
            .loadInitialData(this.props.profileId)
            .then(() => {})
            .finally(() => {
                this.setState({ isLoading: false });
                window.scrollTo(0, 0);
            });
    }

    componentWillUnmount() {}

    handleBack = () => {
        if (this.props.type === "venue") {
            this.props.history.push(`/venues/${this.props.profileId}/edit/favourite`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.setState({ isLoading: true });

        this.props.venueMenuStore
            .submit()
            .then(response => {
                if (this.props.type === "venue") {
                    this.props.history.push(`/venues/${this.props.profileId}/edit/contract`);
                }
                const { editProfileActiveStep } = this.props.templateStore;
                this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    handleBookTableUrlChange = e => {
        this.props.venueMenuStore.setBookTableUrl(e.target.value)
    };

    validateBookTableUrl = e => this.props.venueMenuStore.validateBookTableUrl(e.target.value)

    handleShowDrinkModal = (drink, e) => {
        if (typeof drink !== "undefined") this.props.venueMenuStore.setDrinkValue(drink);
        this.setState({
            ...this.state,
            showNewDrinkModal: true,
            editDrink: typeof drink !== "undefined" ? true : false
        });
    };

    handleHideDrinkModal = () => {
        this.props.venueMenuStore.resetDrinkAttribute();
        this.setState({
            ...this.state,
            showNewDrinkModal: false,
            editDrink: false
        });
    };

    deleteDrinkData = id => {
        this.props.venueMenuStore.deleteDrinkMenu(id);
    };

    handleShowImportDrinkModal = () => {
        this.setState({
            ...this.state,
            showImportDrinkModal: true
        });
    };

    hanldeHideImportDrinkModal = () => {
        this.setState({
            ...this.state,
            showImportDrinkModal: false
        });
    };

    handleShowFoodModal = (food, e) => {
        if (typeof food !== "undefined") this.props.venueMenuStore.setFoodValue(food);
        this.setState({
            ...this.state,
            showNewFoodModal: true,
            editFood: typeof food !== "undefined" ? true : false
        });
    };

    handleHideFoodModal = () => {
        this.props.venueMenuStore.resetFoodAttribute();
        this.setState({
            ...this.state,
            showNewFoodModal: false,
            editFood: false
        });
    };

    deleteFoodData = id => {
        this.props.venueMenuStore.deleteFoodMenu(id);
    };

    handleShowImportFoodModal = () => {
        this.setState({
            ...this.state,
            showImportFoodModal: true
        });
    };

    handleHideImportFoodModal = () => {
        this.setState({
            ...this.state,
            showImportFoodModal: false
        });
    };

    anyError(field) {
        if (!this.props.venueMenuStore.errors) return false;
        if (!this.props.venueMenuStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.venueMenuStore.errors) return null;
        if (!this.props.venueMenuStore.errors[field]) return null;
        return this.props.venueMenuStore.errors[field];
    }

    renderFoodCustomActions = () => (
        <Fragment>
            <Button id="copy-food" onClick={this.handleShowImportFoodModal}>
                <FileCopy style={{color:"white"}}/>
                <span style={{ paddingTop: 3, color:"white" }}>{this.props.addLabel ? this.props.addLabel : "Copy From Other Venue"}</span>
            </Button>
            <Button onClick={this.handleShowFoodModal.bind(this, undefined)}>
                <AddIcon style={{color:"white"}}/>
                <span style={{ paddingTop: 3, color:"white" }}>{this.props.addLabel ? this.props.addLabel : "Add Food"}</span>
            </Button>
        </Fragment>
    );

    renderDrinkCustomActions = () => (
        <Fragment>
            <Button id="copy-drink" onClick={this.handleShowImportDrinkModal}>
                <FileCopy style={{color:"white"}}/>
                <span style={{ paddingTop: 3, color:"white" }}>{this.props.addLabel ? this.props.addLabel : "Copy From Other Venue"}</span>
            </Button>
            <Button onClick={this.handleShowDrinkModal.bind(this, undefined)}>
                <AddIcon style={{color:"white"}}/>
                <span style={{ paddingTop: 3, color:"white" }}>{this.props.addLabel ? this.props.addLabel : "Add Drink"}</span>
            </Button>
        </Fragment>
    );

    mapFoodToRowData = () => {
        const { foodList } = this.props.venueMenuStore;
        const { currency } = this.state;

        return foodList.map(food => {
            var row = {};
            row.id = food.id;
            row.name = food.name;
            row.price = <CurrencyFormat value={currencyHelper.formatMoney(food.price,2)} displayType={"text"} thousandSeparator={true} prefix={currency} />;
            row.section = this.props.venueMenuStore.getFoodSectionLabel(food.section);
            row.action = (
                <Fragment>
                    <Button color="primary" onClick={this.handleShowFoodModal.bind(this, food)}>Edit</Button>
                    <Button color="primary" onClick={() => this.deleteFoodData(food.id)}>Remove</Button>
                </Fragment>
            );
            return row;
        });
    };

    mapDrinkToRowData = () => {
        const { drinkList } = this.props.venueMenuStore;
        const { currency } = this.state;
        return drinkList.map(drink => {
            var row = {};
            row.id = drink.id;
            row.name = drink.name;
            row.price = <CurrencyFormat value={currencyHelper.formatMoney(drink.price,2)} displayType={"text"} thousandSeparator={true} prefix={currency} />;
            row.section = this.props.venueMenuStore.getDrinkSectionLabel(drink.section);
            row.action = (
                <Fragment>
                    <Button color="primary" onClick={this.handleShowDrinkModal.bind(this, drink)}>Edit</Button>
                    <Button color="primary" onClick={() => this.deleteDrinkData(drink.id)}>Remove</Button>
                </Fragment>
            );
            return row;
        });
    };

    render() {
        const { editProfileActiveStep } = this.props.templateStore;
        const { bookTableUrl } = this.props.venueMenuStore;
        const { showNewFoodModal, showNewDrinkModal, isLoading, showImportFoodModal, showImportDrinkModal, currency } = this.state;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);

        return (
            <div className="Profile-Privacy">
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Food Menu
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Add your venue’s food menu to display it in the Hot Giggity Live gig guide app.
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={9}>
                            <ResponsiveTable
                                columns={this.foodColumn}
                                rows={this.mapFoodToRowData()}
                                showToolbar={true}
                                pagination={true}
                                customActions={this.renderFoodCustomActions()}
                            />
                            <NewFoodModal
                                open={showNewFoodModal}
                                closeHandler={this.handleHideFoodModal}
                                editFood={this.state.editFood}
                                currency={currency}
                            />
                            <ImportFoodModal
                                open={showImportFoodModal}
                                closeHandler={this.handleHideImportFoodModal}
                                profileId={this.props.profileId}
                                currency={currency}
                            />
                        </Grid>

                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Drink Menu
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                Use this section to set your venue's drink menu to display it in the Hot Giggity Live gig guide app.
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={9}>
                            <ResponsiveTable
                                columns={this.drinkColumns}
                                rows={this.mapDrinkToRowData()}
                                showToolbar={true}
                                pagination={true}
                                customActions={this.renderDrinkCustomActions()}
                            />
                            <NewDrinkModal
                                open={showNewDrinkModal}
                                closeHandler={this.handleHideDrinkModal}
                                editDrink={this.state.editDrink}
                                currency={currency}
                            />
                            <ImportDrinkModal
                                open={showImportDrinkModal}
                                closeHandler={this.hanldeHideImportDrinkModal}
                                profileId={this.props.profileId}
                                currency={currency}
                            />
                        </Grid>

                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Book a table URL
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Use this section to set your venue's drink menu to display it in the Hot Giggity Live gig guide app.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="bookTableUrl"
                                    label="Book a table URL"
                                    margin="dense"
                                    fullWidth
                                    value={bookTableUrl}
                                    onChange={this.handleBookTableUrlChange}
                                    onBlur={this.validateBookTableUrl}
                                    error={this.anyError("bookTableUrl")}
                                    helperText={this.errorText("bookTableUrl")}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="action">
                                <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                    Back
                                </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    {editProfileActiveStep === steps.length - 1 ? "Save/Finish" : "Save/Next"}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default VenueMenu;
