import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ClearIcon from "@material-ui/icons/Clear";
import Chip from "@material-ui/core/Chip";

import { TextField, Button } from "@material-ui/core";

import Select from "react-select";
import "react-select/dist/react-select.css";

import "./MultiSelect.css";

class Option extends React.Component {
    handleClick = event => {
        this.props.onSelect(this.props.option, event);
    };

    render() {
        const { children, isFocused, isSelected, onFocus, option } = this.props;
        return (
            <MenuItem
                onFocus={onFocus}
                selected={isFocused}
                onClick={this.handleClick}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                    zIndex: 9999
                }}
                divider={option.divider}
                className={option.divider ? "divider" : ""}
            >
                {children}
            </MenuItem>
        );
    }
}

function SelectWrapped(props) {
    const { classes, emptyHandler, disableRemove, emptyText, color, ...other } = props;
    return (
        <Select
            optionComponent={Option}
            noResultsText={
                emptyHandler ? (
                    <Button onClick={emptyHandler}>
                        <Typography>{emptyText ? emptyText : "No results found"}</Typography>
                    </Button>
                ) : (
                    <Typography>{emptyText ? emptyText : "No results found"}</Typography>
                )
            }
            arrowRenderer={arrowProps => {
                return arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
            }}
            clearRenderer={() => <ClearIcon color={color} style={{color}}/>}
            valueComponent={valueProps => {
                const { value, children, onRemove } = valueProps;

                if (onRemove) {
                    return (
                        <Chip
                            tabIndex={-1}
                            label={children}
                            className="multi-select-chip "
                            onDelete={
                                !disableRemove
                                    ? event => {
                                          event.preventDefault();
                                          event.stopPropagation();
                                          onRemove(value);
                                      }
                                    : undefined
                            }
                        />
                    );
                }

                return <div className="Select-value">{children}</div>;
            }}
            {...other}
            {...classes}
        />
    );
}

const ITEM_HEIGHT = 48;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: "auto",
        width: "100%",
        margintop: 10
    },
    chip: {
        margin: theme.spacing.unit / 4,
        position: "relative",
        backgroundColor: "#ff0c9c",
        borderRadius: "4px",
    },
    // We had to use a lot of global selectors in order to style react-select.
    // We are waiting on https://github.com/JedWatson/react-select/issues/1679
    // to provide a better implementation.
    // Also, we had to reset the default style injected by the library.
    "@global": {
        ".Select--multi": {
            height: "auto !important",
            paddingTop: "10px !important"
        },
        ".Select-control": {
            display: "flex",
            alignItems: "center",
            border: 0,
            height: "auto",
            background: "transparent",
            "&:hover": {
                boxShadow: "none"
            }
        },
        ".Select-multi-value-wrapper": {
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap"
        },
        ".Select--multi .Select-input": {
            margin: 0
        },
        ".dark-select .Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0,
            color: "#A9A9A9 !important"
        },
        ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0,
            color: "#000000 !important"
        },
        ".Select-noresults": {
            padding: theme.spacing.unit * 2
        },
        ".dark-select .Select-input":{
            padding: 0,
            color: "#A9A9A9 !important"
        },
        ".Select-input": {
            display: "inline-flex !important",
            padding: 0,
            height: "auto"
        },
        ".Select-input input": {
            background: "transparent",
            border: 0,
            padding: 0,
            cursor: "default",
            display: "inline-block",
            fontFamily: "inherit",
            fontSize: "inherit",
            margin: 0,
            outline: 0
        },
        ".Select-placeholder, .Select--single .Select-value": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            padding: 0
        },
        ".dark-select .Select-placeholder": {
            opacity: 0.42,
            color: "#A9A9A9 !important"
        }, 
        ".Select-placeholder": {
            opacity: 0.42,
            color: theme.palette.common.black
        },
        ".Select-menu-outer": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            position: "absolute",
            left: 0,
            top: `calc(100% + ${theme.spacing.unit}px)`,
            width: "100%",
            zIndex: "99999 !important",
            maxHeight: ITEM_HEIGHT * 4.5
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            boxShadow: "none"
        },
        ".Select-menu": {
            maxHeight: ITEM_HEIGHT * 4.5,
            overflowY: "auto"
        },
        ".Select-menu .divider": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.5);"
        },
        ".Select-menu div": {
            boxSizing: "content-box"
        },
        ".dark-select .Select-arrow-zone": {
            color: "#A9A9A9 !important",
            cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            color: theme.palette.action.active,
            cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1
        },
        ".Select-clear-zone svg": {
            fontSize: 21
        },
        ".Select-arrow-zone svg": {
            position: "relative",
            bottom: 2
        },
        // Only for screen readers. We can't use display none.
        ".Select-aria-only": {
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: 1,
            width: 1,
            margin: -1
        },
        ".Select--single": {
            height: "19px !important",
            maxHeight: "19px !important",
            overflow: "none"
        }
    }
});

class IntegrationReactSelect extends React.Component {
    state = {
        single: null,
        multi: null
    };

    handleChangeSingle = single => {
        this.props.onChange(single);
    };

    handleChangeMulti = multi => {
        this.props.onChange(multi);
    };

    render() {
        const { classes, color } = this.props;
        return (
            <div className={classes.root} id={this.props.id}>
                <TextField
                    margin={this.props.margin ? this.props.margin : "none"}
                    label={this.props.label}
                    classes={ classes }
                    fullWidth
                    value={this.props.value}
                    onChange={this.handleChangeSingle}
                    placeholder={this.props.placeholder}
                    error={this.props.error}
                    helperText={this.props.helperText}
                    disabled={this.props.disabled}
                    readOnly={this.props.readOnly}
                    InputProps={{
                        inputComponent: SelectWrapped,
                        inputProps: {
                            classes,
                            color,
                            multi: this.props.multi,
                            instanceId: this.props.multi ? "react-select-chip" : "react-select-single",
                            id: this.props.multi ? "react-select-chip" : "react-select-single",
                            name: this.props.multi ? "react-select-chip" : "react-select-single",
                            simpleValue: true,
                            options: this.props.suggestions,
                            emptyHandler: this.props.emptyHandler,
                            disableRemove: this.props.disabled || this.props.readOnly,
                            emptyText: this.props.emptyText,
                        }
                    }}
                />
            </div>
        );
    }
}

IntegrationReactSelect.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IntegrationReactSelect);
