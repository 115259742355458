import React, { Component } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import AgentVenueTable from "../../components/agent/venueRoster/AgentVenueTable";
import Breadcrumb from "../../components/Breadcrumb";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
@inject("venueActStore")
@withRouter
@observer
class AgentVenue extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/agent" }, { name: "My Venue Roster" }];

    componentDidMount() {
        this.props.venueActStore.resetList();
    }

    createBooking = (venueId) => {
        let redirectUrl = `/booking/new?actId=undefined&venueId=${venueId}&dateStart=${moment(new Date()).format("DD-MM-YYYY")}`
                                                
        this.props.history.push(redirectUrl)
    }

    redirectVenueFavourite = (data) => {
        let redirectUrl = `/agent/actfavorite/venues/${data.venueId}/${data.venue.fullName}`               
        this.props.history.push(redirectUrl)
    }

    render() {
        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <AgentVenueTable redirectVenueFavourite={(data) => this.redirectVenueFavourite(data)} createBooking={(venueId) => this.createBooking(venueId)}/>
                </MainContainer>
            </div>
        );
    }
}

export default AgentVenue;
