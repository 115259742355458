import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import MusicianProfile from "./MusicianProfile";
import AgentProfile from "./AgentProfile";
import VenueProfile from "./VenueProfile";
import ActProfile from "./ActProfile";
import PageNotFound from "./PageNotFound";
import Splash from "../Splash";

@inject("profileStore")
@observer
class PublicProfile extends Component {
    state = {
        profile: null,
        finishLoading: false
    };

    // load profile initially
    componentDidMount = () => {
        this.loadProfile();
    };

    // check if we get an update, usually by choosing the musician profile in the user popup
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadProfile();
        }
    }

    loadProfile = () => {
        const param = this.props.match.params.id;
        let searchById = this.props.location.pathname.startsWith("/profile/");

        if (searchById) {
            this.props.profileStore.getPublicProfileById(param).then(result => {
                this.setState({
                    profile: result,
                    finishLoading: true
                });
                this.props.profileStore.logProfileViews(param)
            });
        } else {
            if (param !== "null") {
                this.props.profileStore.getPublicProfileByVanityUrl(param).then(result => {
                    this.setState({
                        profile: result,
                        finishLoading: true
                    });
                    this.props.profileStore.logProfileViews(result.id)
                });
            }
        }
    };

    getContent = profile => {
        switch (profile.type) {
            case 1:
                return <MusicianProfile profile={profile} />;
            case 8: 
            case 2:
                return <AgentProfile profile={profile} />;
            case 3:
                return <VenueProfile profile={profile} />;
            case 4:
                return <ActProfile profile={profile} />;
            case 5:
                return <VenueProfile profile={profile} />;
            default:
                return <PageNotFound />;
        }
    };

    render() {
        if (this.props.match.params.id === "null") {
            return <PageNotFound />
        }
        return (
            <React.Fragment>
                {this.state.profile ? this.getContent(this.state.profile) : this.state.finishLoading ? <PageNotFound /> : <Splash/>}
            </React.Fragment>
        );
    }
}
export default PublicProfile;
