import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";
import { DialogContent, DialogActions, Button, Grid, TextField, FormHelperText,MenuItem } from "@material-ui/core";
// import SelectField from '../../fields/SelectField';
import { withRouter } from "react-router-dom";
import RichTextEditor from "../../richTextEditor/RichTextEditor";
import InputLabel from "@material-ui/core/InputLabel";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { ProfileType } from "../../../types/enum";
import HubTimepicker from "../../timepicker/HubTimepicker";
import GooglePlaceAutoComplete from "../../maps/GooglePlaceAutocomplete";
import TimePicker from 'react-time-picker';
import "./messageModal.css";
const isLetters = (str) => /^[^0-9]*$/.test(str);


@inject("userFestivalStore", "templateStore", "agentVenueStore", "userStore", "agentActStore", "venueActStore", "conversationStore", "profileStore")
@withRouter
@observer
class MessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            festivals: undefined,
            isLoading: false,
            venueId: undefined,
            shortedDescription: "",
            hideDescription: false,
            resetEditor: false
        };
    }
    
    handleChangeSearchAddress = value => {
        
        this.props.conversationStore.setAddress(value);
    };

    componentDidMount = () => {
        this.myRef = React.createRef();
        if (this.props.profile) {
            const isAct = this.props.profile && this.props.profile.type === ProfileType.Act.ordinal;
            // const isVenue = currentProfile && currentProfile.type === ProfileType.Venue.ordinal;
            if (isAct) {
                if (this.props.profile.actAdmin) {
                    this.props.conversationStore.setProfileId(this.props.profile.actAdmin.id);
                    this.props.conversationStore.setActId(this.props.profile.id);
                    this.props.conversationStore.setActName(this.props.profile.actName);
                }
            } else {
                this.props.conversationStore.setProfileId(this.props.profile.id);
            }
        }
    };

    handleSkip = () => {
        this.props.closeHandler();
    };

    handleSubmit = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.conversationStore
            .createContactActConversation(this.props.profile)
            .then(() => {
                this.props.closeHandler();
                this.props.conversationStore.setMessage("<p></p>");
                // this.myRef.current.reset();
                this.setState({...this.state, resetEditor: true})
                this.props.templateStore.openSnackbar("Quote Request Sent.");
            })
            .finally(() => {
                this.props.templateStore.hideLoadingScreen();
            });
    };

    getInvitationMessage = () => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            return this.props.agentActStore.invitationMessage;
        } else if (profileType === "venue") {
            return this.props.venueActStore.invitationMessage;
        }
    };

    anyError(field) {
        // if (!this.props.generalProfileStore.errors) return false;
        // if (!this.props.generalProfileStore.errors[field]) return false;
        return false;
    }

    errorText(field) {
        // if (!this.props.generalProfileStore.errors) return null;
        // if (!this.props.generalProfileStore.errors[field]) return null;
        // return this.props.generalProfileStore.errors[field];
        return "";
    }

    handleFirstNameChange = e => this.props.conversationStore.setFirstName(e.target.value);
    handleLastNameChange = e => this.props.conversationStore.setLastName(e.target.value);
    handleEmailChange = e => this.props.conversationStore.setEmail(e.target.value);
    handlePhoneChange = e => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            this.props.conversationStore.setPhone(e.target.value);
        }
   
    }
    handleEventTypeChange = e => this.props.conversationStore.setEventType(e.target.value);
    handleEventDateChange = e => this.props.conversationStore.setEventDate(moment(e).startOf("day"));
    // handleStreetChange = e => this.props.conversationStore.setStreet(e.target.value);
    // handleCityChange = e => this.props.conversationStore.setCity(e.target.value);
    // handlePostalChange = e => this.props.conversationStore.setPostal(e.target.value);
    // handleStateChange = e => this.props.conversationStore.setState(e.target.value);
    // handlePhoneChange = e => this.props.conversationStore.setPhone(e.target.value);
    // handleAccountType = e => this.props.conversationStore.setAccountType(e.target.value);
    handleMessageChange = value => {
        this.props.conversationStore.setMessage(value)
        this.setState({...this.state, resetEditor: false})
    }
        

    handleTimeStartChange = e => {
        this.props.conversationStore.setTimeStartString(e);
    };

    handleTimeEndChange = e => {
        this.props.conversationStore.setTimeEndString(e);
    };

    render() {
        const { open } = this.props;
       // const { searchedAddress } = this.state 
        const { firstName, lastName, email,searchedAddress, body, phone, eventType, eventDate, timeStartString, timeEndString, errors } = this.props.conversationStore;
        var titleModal = `Request A Quote from ${this.props.profile?.actName}`
        return (
            <ModalContainer open={open} title={titleModal}>
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={12}>
                            <Grid container spacing={8}>

                                <Grid item xs={12}>
                                    <GooglePlaceAutoComplete
                                        value={searchedAddress === 'null' ? "" : searchedAddress}
                                        label="Event Address"
                                        placeholder="Type venue name or address"
                                        handleChange={this.handleChangeSearchAddress}
                                        // handleChangeStreet={this.handleStreetChange}
                                        // handleChangeCity={this.handleCityChange}
                                        // handleChangePostal={this.handlePostalChange}
                                        // handleChangeState={this.handleStateChange}
                                        // handleChangeCountry={this.handleCountryChange}
                                        // handleChangeLatLng={this.handleChangeLongitudeLatitude}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="phone"
                                        label="Phone"
                                        margin="dense"
                                        value={phone}
                                        fullWidth
                                        onChange={this.handlePhoneChange}
                                        error={this.anyError("phone")}
                                        helperText={this.errorText("phone")}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            id="booking-date"
                                            className="booking-date-picker"
                                            keyboard
                                            value={eventDate}
                                            fullWidth
                                            margin="dense"
                                            onChange={this.handleEventDateChange}
                                            minDate={moment().startOf("days")}
                                            label="Date Start"
                                            format="DD-MM-YYYY"
                                            keyboardIcon={<EventIcon />}
                                            leftArrowIcon={<KeyboardArrowLeftIcon />}
                                            rightArrowIcon={<KeyboardArrowRightIcon />}
                                            style={{ marginTop: 0 }}
                                            shouldDisableDate={this.shouldDisabledDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <HubTimepicker
                                        id="time-start"
                                        margin="none"
                                        label="Time Start"
                                        value={moment(timeStart, "HH:mm")}
                                        onChange={this.handleTimeStartChange}
                                        error={this.anyError("timeStart")}
                                        helperText={this.errorText("timeStart")}
                                    /> */}
                                     <div>
                                        <p  style={{
                                                color: "rgba(0, 0, 0, 0.54)",
                                                padding: 0,
                                                fontSize: "0.9rem",
                                                fontFamily: "OpenSans, sans-serif",
                                                lineHeight: 1
                                        }}>Time Start</p>
                                        <TimePicker className="time-picker-body" name="Time Start" onChange={this.handleTimeStartChange} value={moment(timeStartString).format("HH:mm")} openClockOnFocus={false} clockIcon={null} clearIcon={null}/>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <HubTimepicker
                                        id="time-end"
                                        margin="none"
                                        label="Time End"
                                        value={moment(timeEnd, "HH:mm")}
                                        start={moment(timeStart, "HH:mm")}
                                        onChange={this.handleTimeEndChange}
                                        error={this.anyError("timeEnd")}
                                        helperText={this.errorText("timeEnd")}
                                    /> */}
                                     <div>
                                        <p  style={{
                                                color: "rgba(0, 0, 0, 0.54)",
                                                padding: 0,
                                                fontSize: "0.9rem",
                                                fontFamily: "OpenSans, sans-serif",
                                                lineHeight: 1
                                        }}>Time End</p>
                                        <TimePicker className="time-picker-body" name="Time End" onChange={this.handleTimeEndChange} value={moment(timeEndString).format("HH:mm")} minTime={moment(timeStartString).format("HH:mm")} openClockOnFocus={false} clockIcon={null} clearIcon={null}/>
                                    </div>
                                   
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <TextField
                                        ref={this.myRef}
                                        id="eventType"
                                        label="Event Type"
                                        margin="dense"
                                        value={eventType}
                                        placeholder="Wedding, Corporate Functions"
                                        fullWidth
                                        onChange={this.handleEventTypeChange}
                                        error={this.anyError("eventType")}
                                        helperText={this.errorText("eventType")}
                                    />
                                    Private Party, Wedding, Corporate Event, Office Party, Public Event]  */}

                                     <TextField
                                         id="eventType"
                                         label="Event Type"
                                         margin="dense"
                                         placeholder="Wedding, Corporate Functions"
                                        select
                                        fullWidth
                                        value={eventType}
                                        onChange={this.handleEventTypeChange}
                                        error={this.anyError("eventType")}
                                        helperText={this.errorText("eventType")}
                                    >
                                        <MenuItem value="Private Party">Private Party</MenuItem>
                                        <MenuItem value="Wedding">Wedding</MenuItem>
                                        <MenuItem value="Corporate Event">Corporate Event</MenuItem>
                                        <MenuItem value="Office Party">Office Party</MenuItem>
                                        <MenuItem value="Public Event">Public Event</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} />

                                <Grid item xs={12} />
                            </Grid>

                            {/* <TextField
                                id="eventDate"
                                label="Event Date"
                                margin="dense"
                                value={eventDate}
                                fullWidth
                                onChange={this.handleEventDateChange}
                                error={this.anyError("eventDate")}
                                helperText={this.errorText("eventDate")}
                            /> */}
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <InputLabel>Message</InputLabel>
                            <RichTextEditor
                                // ref={this.myRef}
                                label="Message"
                                placeholder="Type a message here"
                                value={body}
                                onChange={this.handleMessageChange}
                                resetEditor={this.state.resetEditor}
                            />
                            <FormHelperText error>{errors}</FormHelperText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSkip}>Cancel</Button>
                    <Button variant="raised" color="primary" className="actionButton" onClick={this.handleSubmit}>
                        Send Message
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default MessageModal;
