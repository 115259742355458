import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import profileStore from "./profileStore";
import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";
import validate from "../helper/validation.js"

class SocialProfileStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable userId = 0;
    @observable type = 0; // 1 == musician

    @observable webSite = "";
    @observable facebook = "";
    @observable twitter = "";
    @observable bandcamp = "";
    @observable soundcloud = "";
    @observable mixcloud = "";
    @observable youtube = "";
    @observable instagram = "";
    @observable googlePlus = "";
    @observable linkedIn = "";
    @observable iTunes = "";
    @observable spotify = "";
    @observable profile = undefined;


    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        const { id, actNameTitle, type } = generalProfileStore;

        // const currentUserData = profileStore.getCurrentProfile()

        // if(!profileId) {
        //     if(id) profileId = id
        //     else profileId = currentUserData.id
        // }

        profileId = profileId ? profileId : id;
        if (currentUser) {
            this.inProgress = true;

            // templateStore.showLoadingScreen();
            if ((this.type === ProfileType.Act.ordinal || this.type === ProfileType.Venue.ordinal) && profileId) {
                return profileStore
                    .getProfile(profileId)
                    .then(
                        action(profile => {
                            if (!profile) throw new Error("Can't load profile");
                            this.profile = profile
                            this.mapProfileToVariable(profile);
                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            } else if (this.type !== ProfileType.Act.ordinal && this.type !== ProfileType.Venue.ordinal) {
                return profileStore
                    .loadProfile(currentUser.sub, this.type, true)
                    .then(
                        action(profile => {
                            if (!profile) {
                                this.reset();
                                throw new Error("Can't load profile");
                            }
                            this.profile = profile
                            this.mapProfileToVariable(profile);
                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            }
        } 
        else {
            return Promise.resolve();
        }
    }

    @action
    reset() {
        // this.webSite = "";
        // this.facebook = "";
        // this.twitter = "";
        // this.bandcamp = "";
        // this.soundcloud = "";
        // this.mixcloud = "";
        // this.youtube = "";
        // this.instagram = "";
        // this.googlePlus = "";
        // this.linkedIn = "";
        // this.iTunes = "";
        // this.spotify = "";
        this.profile = undefined;
    }

    @action
    resetErrors() {
        // this.errors = {
        //     webSite : "",
        //     facebook : "",
        //     twitter : "",
        //     bandcamp : "",
        //     soundcloud : "",
        //     mixcloud : "",
        //     youtube : "",
        //     instagram : "",
        //     googlePlus : "",
        //     linkedIn : "",
        //     iTunes : "",
        //     spotify : "",
        // }
    }

   

    @action
    setType(type) {
        this.type = type;
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const profile = {
            id: this.id,
            userId: user.id,
           
            type: this.type
        };

        return Promise.resolve(profile)
        
          //for now this part commented because there is no function on submit social
        // return (this.id ? this.updateProfile(profile) : this.createProfile(profile))
        //     .catch(
        //         action(err => { 
        //             this.errors = err.response && err.response.data.errors;
        //             throw err;
        //         })
        //     )
        //     .finally(
        //         action(() => {
        //             this.inProgress = false;
        //         })
        //     );
    }

    @action
    createProfile(data) {
        //using general profile for create
        switch (this.type) {
            case 1:
                return agent.MusicianGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 2:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 3:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 4:
                return agent.ActGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case ProfileType.Venue.ordinal:
                return agent.VenueSocialProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Production.ordinal:
                return agent.ProductionSocialProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Dj.ordinal:
                return agent.DjGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            default:
                break;
        }
    }

    @action
    mapProfileToVariable(profile) {
        this.webSite = profile.webSite || "";
        this.facebook = profile.facebook || "";
        this.twitter = profile.twitter || "";
        this.bandcamp = profile.bandCamp || "";
        this.soundcloud = profile.soundCloud || "";
        this.mixcloud = profile.mixCloud || "";
        this.youtube = profile.youtube || "";
        this.instagram = profile.instagram || "";
        this.googlePlus = profile.googlePlus || "";
        this.linkedIn = profile.linkedIn || "";
        this.iTunes = profile.itunes || "";
        this.spotify = profile.spotify || "";
        this.id = profile.id;
        this.type = profile.type;
    }


    @action
    updateProfile(data) {
        switch (this.type) {
            case 1:
                return agent.MusicianSocialProfile.update(data).then(profile => {
                    return profile;
                });
            case 2:
                return agent.AgentSocialProfile.update(data).then(profile => {
                    return profile;
                });
            case 3:
                return agent.VenueSocialProfile.update(data).then(profile => {
                    return profile;
                });
            case 4:
                return agent.ActSocialProfile.update(data).then(profile => {
                    return profile;
                });
            case ProfileType.Venue.ordinal:
                return agent.VenueSocialProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            case ProfileType.Production.ordinal:
                return agent.ProductionSocialProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            case ProfileType.Dj.ordinal:
                return agent.DjSocialProfile.update(data).then(
                    action(profile => {
                        this.id = profile.id;
                        return profile;
                    })
                );
            default:
                break;
        }
    }
}


export default new SocialProfileStore();
