import React, { Component } from "react";

//component
import classNames from "classnames";

import LoadingSpinnerLess from "./Loading.css";

import "./Loading.css";
import loader from "../../img/hotgig-loading.png";

let loadingSpinnerLess = classNames.bind(LoadingSpinnerLess);

class Loading extends Component {
    render() {
        let loadingMaskStyle = loadingSpinnerLess({
            displayNone: !this.props.showed,
            loadingMask2: true,
            minHeight: 150,
            position: 'absolute'
        });

        let loadingPanelStyle = loadingSpinnerLess({
            "panel-cube2": true
        });

        return (
            <div
                className={loadingMaskStyle}
                style={{
                    borderRadius: this.props.borderRadius,
                    height: `calc(100% - ${this.props.padding}px)`,
                    width: `calc(100% - ${this.props.padding}px)`
                }}
            >
                <div className={loadingPanelStyle}>
                    <section className="spin-container2">
                        <div id="card2" className="loadingSpinner2">
                            <img className="front spin-img" src={loader} alt="Hotgiggity-front" />
                            <img className="back spin-img" src={loader} alt="Hotgiggity-back" />
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Loading;
