import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AutoComplete from "../../components/fields/AutoComplete";
import ListItemText from "@material-ui/core/ListItemText";
import { Grid, Paper, MenuItem, Avatar, Card, Snackbar } from "@material-ui/core";
import SetListTable from "./SetListTable";
import Music from "./../../img/music.svg";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "./SetListForm.css";
import _ from "lodash";
import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import Hidden from "@material-ui/core/Hidden";
import { withRouter } from "react-router-dom";
import Loading from "../loadingSpinner/Loading";

@inject("songListStore", "setListStore", "profileStore", "templateStore")
@withRouter
@observer
class SetListNew extends Component {
    state = {
        open: false,
        isLoading: true
    };

    constructor(props) {
        super(props);
        switch (this.props.type) {
            case "musician":
                this.props.setListStore.setType(0);
                this.props.setListStore.setProfileId(this.props.profileId).then(() => {
                    this.props.setListStore.loadInitialSongItems().then(() => {
                        this.setState({ isLoading: false });
                    });
                });
                break;
            case "act":
                this.props.setListStore.setType(1);
                this.props.setListStore.setProfileId(this.props.profileId).then(() => {
                    this.props.setListStore.loadInitialSongItems().then(() => {
                        this.setState({ isLoading: false });
                    });
                });
                break;
            case "booking":
                this.props.setListStore.setType(2);
                this.props.setListStore.setBookingId(this.props.bookingId);
                this.props.setListStore.setProfileId(this.props.actId);
                this.props.setListStore.loadInitialSongItems().then(() => {
                    this.setState({ isLoading: false });
                });
                break;
            default:
                break;
        }

        if (this.props.type && this.props.type === "booking") {
            if (!this.props.id) {
                this.props.setListStore.setTitle("Main Setlist");
            }
        }
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.setListStore.load(this.props.id).then(() => {
                this.setState({ isLoading: false });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.props.setListStore.load(this.props.id).then(() => {
                this.setState({ isLoading: false });
            });
        }
    }

    handleSelectSong = song => {
        this.props.setListStore.addSelectedSong(song);
        this.props.setListStore.setTempAddedSong(song);
        if (this.props.type === "act") {
            this.handleSubmit();
        }
    };

    handleTitleChange = event => {
        this.props.setListStore.setTitle(event.target.value);
    };

    handleTitleBlur = event => {
        if (this.props.type === "act") {
            this.props.setListStore.submit().then(() => {
                //do nothing
            });
        }
    };

    anyError(field) {
        if (!this.props.setListStore.errors) return false;
        if (!this.props.setListStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.setListStore.errors) return null;
        if (!this.props.setListStore.errors[field]) return null;
        return this.props.setListStore.errors[field];
    }

    handleSubmit = () => {
        let self = this;
        this.setState({ isLoading: true });
        this.props.setListStore.submit().then(() => {
            if (self.props.type !== "act") {
                const message = this.props.id ? "Set List Updated" : "Set List Created";
                this.props.templateStore.openSnackbar(message);
                this.props.history.push("/musician/setlist");
            } else {
                this.setState({ open: true });
            }
            this.setState({ isLoading: false });
        });
    };

    handleClose = () => {
        this.props.setListStore.setTempAddedSong(undefined);
        this.props.setListStore.setTempDeletedSong(undefined);
        this.setState({ open: false });
    };

    handleUndoUpdate = () => {
        this.props.setListStore.undoSetlistAction();
        this.handleClose();
        if (this.props.type === "act") {
            this.props.setListStore.submit().then(res => {
                //do nothing
            });
        }
    };

    render() {
        let renderMenu = (children, value) => {
            if (_.isEmpty(children)) {
                return (
                    <Card className="menu" style={{ position: "absolute", zIndex: "9999", backgroundColor: "#FFFFFF", width: "100%" }}>
                        <MenuItem button>
                            <ListItemText primary="No Song Found" />
                        </MenuItem>
                    </Card>
                );
            }
            return (
                <div className="menu" style={{ position: "absolute", zIndex: "9999" }}>
                    {children}
                </div>
            );
        };

        let handleSearch = value => {
            const { initialSongs, selectedSongs } = this.props.setListStore;
            const selectedIds = selectedSongs.map(x => x.id);
            var updatedList = initialSongs.filter(function (item) {
                return item.title.toLowerCase().search(value.toLowerCase()) !== -1 && selectedIds.indexOf(item.id) < 0;
            });
            this.props.setListStore.filterSongs(updatedList);
        };

        let renderItem = (item, isHighlighted) => {
            return (
                <MenuItem
                    key={item.id}
                    selected={isHighlighted}
                    component="div"
                    style={{
                        zIndex: 9999,
                        backgroundColor: isHighlighted ? "#ff0c9c" : "#ffffff"
                    }}
                >
                    <Avatar alt={item.firstName} src={Music} />
                    <ListItemText primary={item.title} secondary={item.artist} />
                </MenuItem>
            );
        };

        let getItemValue = item => {
            return "";
        };

        const { filteredSongs, title } = this.props.setListStore;
        const { type, readonly } = this.props;
        const { isLoading } = this.state;

        return (
            <div style={{ position: "relative" }}>
                <Loading showed={isLoading} />
                <Grid container spacing={24}>
                    {!readonly && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="settitle"
                                label="Set List Title"
                                margin="dense"
                                onChange={this.handleTitleChange}
                                onBlur={this.handleTitleBlur}
                                value={title}
                                error={this.anyError("title")}
                                helperText={this.errorText("title")}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                    {!readonly && (
                        <Grid item xs={12} md={6} id="selectSong">
                            <AutoComplete
                                id="searchSong"
                                items={filteredSongs.slice()}
                                getItemValue={getItemValue}
                                handleSelect={item => this.handleSelectSong(item)}
                                handleSearch={handleSearch}
                                renderMenu={renderMenu}
                                renderItem={renderItem}
                                placeholder="Search for the song"
                                minimumLength={0}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Paper>
                            <SetListTable readonly={this.props.readonly} autosubmit={this.props.type === "act"} />
                        </Paper>
                    </Grid>
                    {type === "musician" && (
                        <Grid item xs={12} style={{ marginTop: 20, textAlign: "right" }}>
                            <Hidden only={["md", "xs", "sm"]}>
                                {!_.isUndefined(this.props.id) ? (
                                    <Button variant="flat" color="primary" type="submit" disabled={title === ""} onClick={this.handleSubmit}>
                                        Update
                                    </Button>
                                ) : (
                                        <Button variant="flat" color="primary" type="submit" disabled={title === ""} onClick={this.handleSubmit}>
                                            Save
                                        </Button>
                                    )}
                            </Hidden>
                            <Hidden only={["lg", "xl"]}>
                                {!_.isUndefined(this.props.id) ? (
                                    <Button
                                        fullWidth
                                        variant="flat"
                                        color="primary"
                                        type="submit"
                                        disabled={title === ""}
                                        onClick={this.handleSubmit}
                                    >
                                        Update
                                    </Button>
                                ) : (
                                        <Button
                                            fullWidth
                                            variant="flat"
                                            color="primary"
                                            type="submit"
                                            disabled={title === ""}
                                            onClick={this.handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    )}
                            </Hidden>
                        </Grid>
                    )}
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    ContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">{this.props.id ? "Set List Updated" : "Set List Created"}</span>}
                    action={[
                        <Button key="undo" color="inherit" size="small" onClick={this.handleUndoUpdate}>
                            <span style={{ color: "#ff0c9c" }}>UNDO</span>
                        </Button>,
                        <IconButton key="close" color="inherit" aria-label="Close" onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}

export default compose(withWidth())(SetListNew);
