import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import { Grid, Button, IconButton, Snackbar, Card, CardActions, CardContent, Avatar, Typography, Divider, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import image from "../../helper/image";


@inject("profileStore", "templateStore", "venueStore")
@observer
class Venues extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/staff" }, { name: "Venues" }];
    state = {
        expanded: false,
        openDeleteConfirmationModal: false,
        deletedActId: undefined,
        deletedActName: "",
        openSnackbar: false,
        snackbarMessage: "",
        filterText: "",
        venueCount: 10
    };

    constructor(props) {
        super(props);
        this.props.venueStore.resetVenues();
    }

    componentDidMount() {
      this.loadGigs(1)
    }

    loadGigs(page){
        this.props.templateStore.showLoadingScreen();
        this.props.venueStore.getUserVenues(page).then(() => {
            this.props.venueStore.setPage(page)
            this.props.templateStore.hideLoadingScreen();
        });
    }

    handleShowMore = e => {
        var page = this.props.venueStore.page + 1;
        this.loadGigs(page)
    };
    handleRemove = () => {
        this.props.venueStore.delete(this.state.deletedActId).then(response => {
            this.handleCancelDelete();
            // this.setState({
            //     snackbarMessage: `Venue ${response.profileName} has been deleted.`,
            //     openSnackbar: true
            // });
        });
    };

    handleFilterTextChange = e => {
        this.setState({ filterText: e.target.value });
    };

    // handleShowMore = e => {
    //     var nextCount = this.state.venueCount + 10;
    //     this.setState({ venueCount: nextCount });
    // };

    handleCancelDelete = () => {
        this.setState({
            openDeleteConfirmationModal: false,
            deletedActId: undefined
        });
    };

    handleOpenDeleteConfirmation = id => {
        const venue = this.props.venueStore.venues.slice().find(x => x.id === id);
        this.setState({
            deletedActName: venue ? venue.profileName : "",
            openDeleteConfirmationModal: true,
            deletedActId: id
        });
    };

    closeSnackbarContainer = () => {
        this.setState({
            snackbarMessage: "",
            openSnackbar: false
        });
    };

    handleButtonAdd = () => {
        this.props.profileStore.setStatusCreateVenue(true)
        this.props.profileStore.setVenueNameTitle("Create Venue")
    }

    render() {
        const { venues } = this.props.venueStore;
        const { fullScreen } = false;

        let currentProfile = this.props.profileStore.getCurrentProfile();
        let filteredVenues = [];
        if (this.state.filterText.length > 0) {
            filteredVenues = venues
                .filter(x => x.profileName.toUpperCase().indexOf(this.state.filterText.toUpperCase()) > -1)
                // .slice(0, this.state.venueCount);
            console.log(
                "filter",
                this.state.filterText.toUpperCase(),
                this.state.filterText.length > 0,
                venues.filter(x => x.profileName.toUpperCase().indexOf(this.state.filterText.toUpperCase() > -1))
            );
        } else {
            filteredVenues = venues
        }

        filteredVenues = filteredVenues.sort((a, b) => (b.profileName > a.profileName ? -1 : 1))

        let renderVenue = venue => {
            return (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={venue.id}>
                    <Card className="manage-act" raised={false} style={{ boxShadow: "0 1px 1px rgba(0, 0, 0, 0.05)" }}>
                        <div
                            className="act-background-image"
                            style={{
                                backgroundImage: `url(${image.getBackgroundImage(venue)})`
                            }}
                        >
                            &nbsp;
                            <Avatar className="act-avatar" aria-label={venue.profileName} src={image.getProfileImage(venue)} />
                        </div>
                        <CardContent className="act-details">
                            <Typography variant="headline" component="h2">
                                <Link className="act-profile-link" to={"/profile/" + venue.id} target={"_blank"}>
                                    {venue.profileName}
                                </Link>
                            </Typography>
                            <Typography gutterBottom variant="caption">
                                {`${venue.stateEntity ? venue.stateEntity.name + " / " : ""}${venue.region ? venue.region.name : ""}`}
                            </Typography>
                            <Typography className="act-bio" component="div" dangerouslySetInnerHTML={{ __html: venue.bio }} />
                        </CardContent>
                        <Divider />
                        <CardActions className="act-action">
                            {venue.canEdit ? (
                                <Button color="default" component={Link} to={`/venues/${venue.id}/edit`} size="small">
                                    Edit
                                </Button>
                            ) : (
                                ""
                            )}
                            <Button
                                color="default"
                                component={Link}
                                to={`/calendar/${venue.id}/venue`}
                                size="small"
                                // target="_blank"
                            >
                                Calendar
                            </Button>
                            {venue.canEdit ? (
                                <Button
                                    color="default"
                                    size="small"
                                    onClick={this.handleOpenDeleteConfirmation.bind(this, venue.id)}
                                    disabled={!venue.canRemove}
                                >
                                    Remove
                                </Button>
                            ) : (
                                ""
                            )}
                        </CardActions>
                    </Card>
                </Grid>
            );
        };

        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title="Venues">
                        <TextField
                            className="dark-datepicker"
                            placeholder="Find Venue"
                            onChange={this.handleFilterTextChange}
                            value={this.state.filterText}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />

                        {
                            (!currentProfile?.hasOrganisation) ? 
                            (
                                (venues.length == 0 || venues.length  < currentProfile?.maxVenueLimit) ?
                                (
                                    <Button classes={{ root: "pull-right" }} style={{marginLeft: 20, color: 'white'}} color="primary" variant="contained" component={Link} to="/venues/new" onClick={this.handleButtonAdd}>
                                        Add Venue
                                    </Button>
                                )
                                :
                                (
                                    <Button disabled classes={{ root: "pull-right" }} style={{marginLeft: 20, color: 'grey'}} color="primary" variant="contained" component={Link} to="/venues/new" onClick={this.handleButtonAdd}>
                                        Add Venue
                                    </Button>
                                )
                            )
                            :
                            (
                                
                                    !(currentProfile?.viewOnly) && (currentProfile?.allowBooking) && (currentProfile?.editVenue) ? 
                                    (
                                        <Button classes={{ root: "pull-right" }} style={{marginLeft: 20, color: 'white'}} color="primary" variant="contained" component={Link} to="/venues/new" onClick={this.handleButtonAdd}>
                                            Add Venue
                                        </Button>
                                    )
                                    :
                                    ""
                                
                            )
                        }


                        
                       
                    </Heading>
                    <Grid container direction="column" spacing={8}>
                        <Grid item xs={12}>
                            <Grid container spacing={8}>
                                {filteredVenues.map(venue => renderVenue(venue))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                            <Grid container direction="row" justify="center" alignItems="center">
                            {this.props.venueStore.isMore  ? (
                                    <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                        Show More
                                    </Button>
                                ) : null}
                            </Grid>
                        </Grid>

                        <Dialog
                            fullScreen={fullScreen}
                            open={this.state.openDeleteConfirmationModal}
                            onClose={this.handleCancelDelete}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Delete Venue</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure to delete <strong>{this.state.deletedActName}</strong>?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCancelDelete} color="primary">
                                    Close
                                </Button>
                                <Button onClick={this.handleRemove} color="primary" autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Snackbar
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            open={this.state.openSnackbar}
                            onClose={this.closeSnackbarContainer}
                            autoHideDuration={6000}
                            SnackbarContentProps={{
                                "aria-describedby": "message-id"
                            }}
                            message={<span id="message-id">{this.state.snackbarMessage}</span>}
                            action={[
                                <IconButton key="close" aria-label="Close" color="inherit" onClick={() => this.closeSnackbarContainer()}>
                                    <ClearIcon />
                                </IconButton>
                            ]}
                        />
                    </Grid>
                </MainContainer>
            </main>
        );
    }
}

export default Venues;
