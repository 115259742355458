import React, { Component } from "react";
import { Avatar, CardHeader } from "@material-ui/core";
import { Link } from "react-router-dom";
import blankProfile from "../../img/hotgig-loading.png"
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class TableRowProfile extends Component {
    extractIntialFromName = name => {
        if (!name) {
            return false;
        }
        var split = name.split(" ");
        if (split) {
            var initial = "";
            if (split[0]) {
                initial += split[0].substring(0, 1);
            }
            if (split[1]) {
                initial += split[1].substring(0, 1);
            }
            return initial.toUpperCase();
        }
    };

    newRoute = () => {
        let _route
        if (this.props.actId) {
            _route = `/profile/${this.props.actId}`
        } else {
            _route = `/profile/${this.props.profileId}`
        }
        return _route
    }

    addDefaultSrc(ev){
        ev.target.src = blankProfile;
    }

    render() {
        const { name, action, profileId, avatarUrl } = this.props;

        return (
            <CardHeader
                avatar={
                    avatarUrl ? (
                        <Avatar
                            imgProps={{onError: this.addDefaultSrc}}
                            alt={name}
                            src={avatarUrl}
                            style={{
                                width: 40,
                                height: 40
                            }}
                        />
                    ) : (
                        <Avatar
                            style={{
                                width: 40,
                                height: 40
                            }}
                            src={blankProfile}
                        />
                            
                    )
                }
                title={
                    <div>
                        { this.props.actId || this.props.profileId 
                            ? <Link target={"_blank"} to={this.newRoute()} >{name}</Link>
                            : <p>{name}</p>  
                        }
                    </div>
                }
                style={{
                    paddingRight: 0,
                    paddingLeft: 0,
                    paddingTop: 8,
                    paddingBottom: 8
                }}
                action={action}
            />
        );
    }
}

export default TableRowProfile;
