import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import "./ClientContract.css";
import Parser from 'html-react-parser';

class EntertainerClientBookingContract extends Component {
    render() {
        const clientContract = this.props.data.clientContract;
        const clientEmail = this.props.data.clientEmail;
        const clientInvoice = this.props.data.clientInvoice[0];
        const data = this.props.data;
        return (
            <Grid>
                <table>
                    <tbody><tr>
                        <td className="header-left-col tax-invoice">
                            <b>Entertainer Booking Contract</b>
                        </td>
                        <td className="header-right-col">
                            <table>
                                <tbody><tr>
                                    <td className="logo-container">
                                        <img className="avatar" src={clientEmail.profileImageUrl} />
                                    </td>
                                </tr>
                                </tbody></table>
                        </td>
                    </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
                <table style={{ marginTop: '30px' }}>
                    <tbody><tr>
                        <td className="header-left-col">This contact is provided by {clientEmail.bookingCreatorFirstName} for them to perform at the following event:</td>
                    </tr>
                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Client Details:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Client: {clientContract.clientCompanyName}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Contact: {clientInvoice.recipientName}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Phone: {clientInvoice.recipientPhoneNumber}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Email: {clientInvoice.recipientEmail}</td>
                        </tr>
                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Event Details:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Event name: {clientEmail.eventTitle}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Event Date: {clientEmail.eventDate}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Event Start Time: {clientEmail.eventStartTime}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Event End Time: {clientEmail.eventEndTime}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Venue: {clientEmail.venueName}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Address: {clientContract.venueAddress}</td>
                        </tr>
                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Fee Details:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">The agreed fee for this performance is as follows:</td>
                        </tr>
                        <tr><td style={{ height: '20px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col">Fee: {clientInvoice.invoiceTotal} which is due on {clientInvoice.paymentDueDate}</td>
                        </tr>
                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Musician Contact Details:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Phone: {clientEmail.bookingCreatorPhone}</td>
                        </tr>
                        <tr>
                            <td className="header-left-col">Email: {clientEmail.bookingCreatorEmail}</td>
                        </tr>

                        <tr><td style={{ height: '30px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col"><b>Terms and Conditions:</b></td>
                        </tr>
                        <tr>
                            <td className="header-left-col">The terms and conditions governing this booking are as follows:</td>
                        </tr>
                        <tr><td style={{ height: '20px' }}>&nbsp;</td><td /></tr>
                        <tr>
                            <td className="header-left-col">{Parser(clientContract.clientContract)}</td>
                        </tr>
                    </tbody></table>
            </Grid>
        );
    }
}

export default EntertainerClientBookingContract;
