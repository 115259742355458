import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import ModalContainer from "../../modal/ModalContainer";
import InvitedActPreview from "../../util/ActInvitation/InvitedActPreview";
import ProfileSearchBox from "../../fields/ProfileSearchBox";

@inject("profileStore", "venueActStore", "agentActStore")
@observer
class AgentActInvitationModal extends Component {
    state = {
        loading: false,
        suggestedActs: []
    };

    handleInvite = () => {
        this.props.venueActStore.addMany(this.props.venue).then(() => {
            this.props.onClose();
        });
    };

    getExcludeSearch = () => {
        const { venueActRoster } = this.props.venueActStore;

        var excludedProfile = [];

        venueActRoster.forEach(data => {
            if (data && data.act) {
                excludedProfile.push(data.act);
            }
        });

        return excludedProfile;
    };

    render() {
        return (
            <ModalContainer title="Add Act" open={this.props.open} onClose={this.props.onClose} maxWidth="sm" fullScreen={false}>
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={12}>
                            {this.props.venueActStore.invitedAct ? (
                                <Fragment>
                                    <InputLabel>Invited Act</InputLabel>
                                    <InvitedActPreview
                                        musician={this.props.venueActStore.invitedAct}
                                        invitedActs={this.props.venueActStore.invitedActs}
                                        handleClear={() => this.props.venueActStore.clearInviteData()}
                                        exclude={this.getExcludeSearch()}
                                    />
                                </Fragment>
                            ) : (
                                <ProfileSearchBox
                                    placeholder="Search Act from your act roster"
                                    id="invite-act-venue-name"
                                    handleSearch={value => this.props.agentActStore.searchAgentActByActName(value)}
                                    handleChange={item => this.props.venueActStore.setInvitedAct(item)}
                                    exclude={this.getExcludeSearch()}
                                    menuStyle={{
                                        position: "relative",
                                        maxHeight: "300px",
                                        overflowY: "auto"
                                    }}
                                    isForVenueRoster={true}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.onClose}>
                        Close
                    </Button>
                    <Button color="primary" disabled={!this.props.venue} onClick={this.handleInvite}>
                        Add
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default AgentActInvitationModal;
