import React, { Component } from "react";
import Chip from "@material-ui/core/Chip";
import { ProfileType } from "../../types/enum";

import "./BookingStatusBadge.css";
import { Fragment } from "react";
class BookingStatusBadge extends Component {
    render() {
        let { status, cancelledBy, isPrint } = this.props;
        let statusPrefixed = "";
        if (status === "Cancelled") {
            statusPrefixed =
                cancelledBy === ProfileType.Act.ordinal
                    ? "Act Cancelled"
                    : cancelledBy === ProfileType.Agent.ordinal
                        ? "Agent Cancelled"
                        : cancelledBy === ProfileType.Staff.ordinal
                            ? "Venue Cancelled"
                            : status;
        } else if (status === "Declined") {
            statusPrefixed = "Declined";
            status = "Declined";
        } else if (status === "Cancelled By Act") {
            statusPrefixed =
                cancelledBy === ProfileType.Act.ordinal
                    ? "Act Cancelled"
                    : cancelledBy === ProfileType.Agent.ordinal
                        ? "Agent Cancelled"
                        : cancelledBy === ProfileType.Staff.ordinal
                            ? "Venue Cancelled"
                            : status;
            status = "Cancelled";
        }
        else if (status === "SentToAct") {
            statusPrefixed = "Sent To Act";
            status = "SentToAct";
        }
        else if (status === "PlayerNotified") {
            statusPrefixed = "Player(s) Notified";
            status = "PlayerNotified";
        }
        else if (status === "PlayerDeclined") {
            statusPrefixed = "Player(s) Declined";
            status = "PlayerDeclined";
        }
        else if (status === "AllPlayerConfirmed") {
            statusPrefixed = "All Player(s) Confirmed";
            status = "AllPlayerConfirmed";
        }
        else if (status === "ClientNotified") {
            statusPrefixed = "Client Notified";
            status = "ClientNotified";
        }
        else if (status === "PlayerInvitedAndClientNotified") {
            statusPrefixed = "Player(s) and Client Notified";
            status = "PlayerInvitedAndClientNotified";
        }
        else if (status === "PlayerConfirmedAndClientNotified") {
            statusPrefixed = "Player(s) Confirmed and Client Notified";
            status = "PlayerConfirmedAndClientNotified";
        }
        else if (status === "Ready") {
            statusPrefixed = "Confirmed"
            status = "Confirmed"
        }
        else {
            statusPrefixed = status;
        }        
        

        let autoWidth = status === "PlayerConfirmedAndClientNotified" || status === "AllPlayerConfirmed";
        let autoHeight = status === "PlayerConfirmedAndClientNotified" || status === "PlayerInvitedAndClientNotified" || status === "PlayerNotified" || status === "PlayerDeclined";
        let playerConfirmedStatus = status === "AllPlayerConfirmed";

        autoWidth = statusPrefixed === "Venue Cancelled" || statusPrefixed === "Agent Cancelled";
        return (
            <Fragment>
                {isPrint ?
                    <div className={`booking-badge booking-${status}-badge`} style={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: autoWidth ? "70%" : "90px", height: autoHeight ? 'auto' : null, padding: "1px 5px 2px 5px", whiteSpace: autoWidth && playerConfirmedStatus ? 'nowrap' : null }}>{statusPrefixed}</div> :
                    <Chip
                        label={statusPrefixed}
                        classes={{ root: `booking-badge booking-${status}-badge` }}
                    />
                }
            </Fragment>
        )
    }

}

export default BookingStatusBadge;
