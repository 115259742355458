import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";

import "./Testimonial.css";

class Testimonial extends Component {
    render() {
        return (
            <div className="profile-testimonial">
                <Card classes={{ root: "profile-testimonial-paper" }}>
                    <CardContent className="profile-testimonial-card-content">
                        <div className={this.props.even ? "profile-testimonial-container-even" : "profile-testimonial-container-odd"}>
                            <FormatQuoteIcon classes={{ root: "profile-quote-icon" }} />
                            <Typography component="p" className="profile-testimonial-content">
                                {this.props.testimonial.content}
                            </Typography>
                            <br />
                            <Typography component="p" className="profile-testimonial-from">
                                {this.props.testimonial.name}
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default Testimonial;
