import { inject, observer } from "mobx-react";
import React from "react";
import PropTypes from "prop-types";

import { Typography, Chip, Input } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import agent from "../../agent";
import Select from "react-select";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ClearIcon from "@material-ui/icons/Clear";

import { withStyles } from "@material-ui/core/styles";
import "react-select/dist/react-select.css";
import "./MultiSelect.css";

class Option extends React.Component {
    handleClick = event => {
        this.props.onSelect(this.props.option, event);
    };

    render() {
        const { children, isFocused, isSelected, onFocus } = this.props;

        return (
            <MenuItem
                onFocus={onFocus}
                selected={isFocused}
                onClick={this.handleClick}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400
                }}
            >
                {children}
            </MenuItem>
        );
    }
}

function SelectWrapped(props) {
    const { classes, ...other } = props;

    return (
        <Select
            optionComponent={Option}
            noResultsText={<Typography>{"No results found"}</Typography>}
            arrowRenderer={arrowProps => {
                return arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
            }}
            clearRenderer={() => <ClearIcon />}
            valueComponent={valueProps => {
                const { value, children, onRemove } = valueProps;

                if (onRemove) {
                    return (
                        <Chip
                            tabIndex={-1}
                            label={children}
                            className="multi-select-chip"
                            onDelete={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                onRemove(value);
                            }}
                        />
                    );
                }

                return <div className="Select-value">{children}</div>;
            }}
            {...other}
        />
    );
}

const ITEM_HEIGHT = 48;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: "auto",
        width: "100%",
        margintop: 10
    },
    chip: {
        margin: theme.spacing.unit / 4,
        backgroundColor: "#ff0c9c"
    },
    // We had to use a lot of global selectors in order to style react-select.
    // We are waiting on https://github.com/JedWatson/react-select/issues/1679
    // to provide a better implementation.
    // Also, we had to reset the default style injected by the library.
    "@global": {
        ".Select--multi": {
            height: "auto !important",
            paddingBottom: "5px !important"
        },
        ".Select-control": {
            display: "flex",
            alignItems: "center",
            border: 0,
            height: "auto",
            background: "transparent",
            "&:hover": {
                boxShadow: "none"
            }
        },
        ".Select-multi-value-wrapper": {
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap"
        },
        ".Select--multi .Select-input": {
            margin: 0
        },
        ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0
        },
        ".Select-noresults": {
            padding: theme.spacing.unit * 2
        },
        ".Select-input": {
            display: "inline-flex !important",
            padding: 0,
            height: "auto"
        },
        ".Select-input input": {
            background: "transparent",
            border: 0,
            padding: 0,
            cursor: "default",
            display: "inline-block",
            fontFamily: "inherit",
            fontSize: "inherit",
            margin: 0,
            outline: 0
        },
        ".Select-placeholder, .Select--single .Select-value": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            padding: 0,
            color: "#000 !important"
        },
        ".Select-placeholder": {
            opacity: 0.42,
            color: theme.palette.common.black
        },
        ".Select-menu-outer": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            position: "absolute",
            left: 0,
            top: `calc(100% + ${theme.spacing.unit}px)`,
            width: "100%",
            zIndex: "99999 !important",
            maxHeight: ITEM_HEIGHT * 4.5
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            boxShadow: "none"
        },
        ".Select-menu": {
            maxHeight: ITEM_HEIGHT * 4.5,
            overflowY: "auto",
            zIndex: 100000000000
        },
        ".Select-menu div": {
            boxSizing: "content-box"
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            color: theme.palette.action.active,
            cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1
        },
        // Only for screen readers. We can't use display none.
        ".Select-aria-only": {
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: 1,
            width: 1,
            margin: -1
        }
    }
});

@inject("generalProfileStore")
@observer
class ProductionType extends React.Component {
    state = {
        types: []
    };

    componentDidMount = () => {
        agent.Production.getAll().then(result => {
            this.setState({
                types: result.map(instrument => ({
                    value: instrument.id,
                    label: instrument.name
                }))
            });
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <FormControl fullWidth>
                    {this.props.label && this.props.label !== "" ? (
                        <InputLabel htmlFor="productionTypes" shrink={this.props.value !== ""}>
                            {this.props.label}
                        </InputLabel>
                    ) : (
                        ""
                    )}
                    <Input
                        id="productionTypes"
                        className="instrument-input"
                        fullWidth
                        inputComponent={SelectWrapped}
                        inputProps={{
                            classes,
                            value: this.props.value,
                            multi: false,
                            onChange: this.props.onChange,
                            placeholder: this.props.placeholder,
                            instanceId: "react-select-chip",
                            id: "react-select-chip",
                            name: "react-select-chip",
                            simpleValue: true,
                            options: this.state.types
                        }}
                    />
                    {/* <FormHelperText id="name-helper-text" /> */}
                </FormControl>
            </div>
        );
    }
}

ProductionType.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductionType);
