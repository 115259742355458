import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import "./CustomEmbedGigWidget";

const BASE_URL = process.env.REACT_APP_BASE_URL;

@inject("embedGigStore")
@observer
class CustomEmbedEntertainmentWidget extends Component {
    state = {};

    hancleClick = () => {
        this.props.link
    };



    render() {

        const StyledButton = withStyles({
            root: {
              color: 'primary',
            '&:hover': {
                color: '#ff0c9c'
            }
            },
          })(Button);

        return (
            <Fragment>
                <StyledButton href={this.props.link} target="_blank" color='primary' autoFocus style={{ float: "right" }}>
                    View in new window  
                </StyledButton>

            </Fragment>
        );
    }
}

export default CustomEmbedEntertainmentWidget;
