import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";

import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

import moment from "moment";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

const BASE_URL = process.env.REACT_APP_BASE_URL;

const IOS_BG = `${BASE_URL}ios.png`;

const style = {
    container: {
        backgroundImage: `url(${IOS_BG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        margin: "0 auto 0 auto",
        paddingTop: "105px",
        paddingLeft: "37px",
        width: "292px",
        height: "600px",
        position: "relative",
        fontSize: "10px"
    },
    contentContainer: {
        width: "214px",
        height: "345px",
        overflowX: "hidden",
        overfloxY: "scroll"
    },
    header: {
        height: "35px",
        width: "214px",
        backgroundColor: "#ff0c9c",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "8px"
    },
    promotionImage: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        maxWidth: "214px",
        maxHeight: "380px",
        width: "214px",
        height: "120px"
    }
};

const PromotionPreview = props => {
    const { promotion } = props;
    return (
        <Grid container>
            <Grid item xs={12} lg={4}>
                <Typography variant="headline" component="h3" gutterBottom>
                    Promotion Preview
                </Typography>
                <Typography gutterBottom className="side-helper-text">
                    [ Promotion Preview Description ]
                </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
                <div style={style.container}>
                    <div style={style.header}>
                        <ArrowBackIos />
                        <div>{promotion.title}</div>
                    </div>
                    <div style={style.contentContainer} className="noScrollbar">
                        <div
                            style={{
                                ...style.promotionImage,
                                backgroundImage: `url(${promotion.imageUrl})`
                            }}
                        >
                            &nbsp;
                        </div>
                        <div style={{ padding: "8px" }}>
                            <div>Valid From: {moment(promotion.startDate).format("DD-MM-YYYY")}</div>
                            <div>to: {moment(promotion.endDate).format("DD-MM-YYYY")}</div>
                            <div dangerouslySetInnerHTML={{ __html: promotion.description }} />
                            <Button
                                disableRipple
                                disabled={promotion.redeeemCount === promotion.redeemLimit}
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Redeem
                            </Button>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default PromotionPreview;
