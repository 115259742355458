import React, { Component } from "react";

//component
import classNames from "classnames";

import LoadingSpinnerLess from "./LoadingSpinner.css";

import "./LoadingSpinner.css";
import loader from "../../img/hotgig-loading.png";

let loadingSpinnerLess = classNames.bind(LoadingSpinnerLess);

class LoadingSpinner extends Component {
    render() {
        let loadingMaskStyle = loadingSpinnerLess({
            displayNone: !this.props.showed,
            loadingMask: true
        });

        let loadingPanelStyle = loadingSpinnerLess({
            "panel-cube": true
        });

        return (
            <div className={loadingMaskStyle}>
                <div className={loadingPanelStyle}>
                    <section className="spin-container">
                        <div id="card" className="loadingSpinner">
                            <img className="front spin-img" src={loader} alt="Hotgiggity-front" />
                            <img className="back spin-img" src={loader} alt="Hotgiggity-back" />
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default LoadingSpinner;
