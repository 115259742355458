import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Checkbox, Tooltip, Button } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";

import RegionTableToolbar from "./RegionTableToolbar";

const columnData = [
    { columnTitle: "name", numeric: false, disablePadding: false, label: "Name" },
    { columnTitle: "address", numeric: false, disablePadding: false, label: "Address" },
    { columnTitle: "edit", numeric: false, disablePadding: false, label: "" }
];

@inject("designProfileStore", "stateStore", "organisationStore", "regionStore")
@observer
class RegionTable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            rowsPerPage: 5
        };
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleClearSelected = () => {
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleSelectAllClick = (event, checked) => {
       
        const { regions } = this.props.regionStore;

        if (checked) {
            this.setState({ selected: regions.map(n => n.name) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleSort = (columnName) => {
        const { regions } = this.props.regionStore;
        var newSortDir =  this.state.orderBy === columnName ? this.invertDirection(this.state.order) : "asc";
        var temp=  _.orderBy(regions, columnName,newSortDir); // Use Lodash to sort array by 'name'
        this.props.regionStore.setRegions(temp);
        this.setState({ ...this.state,
             orderBy: columnName,
             order:  newSortDir
             })
            
    }

    invertDirection = (direction) =>{
        if (direction === "asc") {
            return "desc";
        } else {
            return "asc"
        }
    }

    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page } = this.state;
        const { regions } = this.props.regionStore;
        const { canManage } = this.props.stateStore;
        const { level2Label, level3Label, id } = this.props.organisationStore;

        return (
            <Paper className={classes.root}>
                <RegionTableToolbar selected={selected} handleClearSelected={this.handleClearSelected} />
                <div className={classes.tableWrapper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {canManage ? (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            disabled={!canManage}
                                            indeterminate={selected.length > 0 && selected.length < regions.length}
                                            checked={selected.length === regions.length}
                                            onChange={this.handleSelectAllClick}
                                        />
                                    </TableCell>
                                ) : (
                                    ""
                                )}
                                {columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.columnTitle}
                                            numeric={column.numeric}
                                            padding={column.disablePadding ? "none" : "default"}
                                            sortDirection={orderBy === column.columnTitle ? order : false}
                                        >
                                            <Tooltip title="Sort" placement={column.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                                                <TableSortLabel active={orderBy === column.columnTitle} direction={order}>
                                                   <div onClick={() => this.handleSort(column.columnTitle)}>{column.label}</div>
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    );
                                }, this)}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {regions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                const isSelected = this.isSelected(n.name);
                                return (
                                    <TableRow hover role="checkbox" aria-checked={isSelected} tabIndex={-1} key={n.name} selected={isSelected}>
                                        {canManage ? (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    disabled={!canManage}
                                                    checked={isSelected}
                                                    onClick={event => this.handleClick(event, n.name)}
                                                />
                                            </TableCell>
                                        ) : (
                                            ""
                                        )}
                                        <TableCell>{n.name}</TableCell>
                                        <TableCell>{n.address}</TableCell>
                                        <TableCell>
                                            {!n.id && (
                                                <Tooltip title={`Save your ${level2Label} to edit this ${level3Label}`} enterDelay={300}>
                                                    <div>
                                                        <Button
                                                            color="primary"
                                                            component={Link}
                                                            to={`/organisation/${id}/state/${n.id}/edit`}
                                                            disabled={true}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {n.id && (
                                                <Button
                                                    color="primary"
                                                    component={Link}
                                                    to={`/organisation/${id}/state/${n.stateId}/region/${n.id}/edit`}
                                                    className="region-edit"
                                                >
                                                    {canManage ? "Edit" : "View"}
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    SelectProps={{ className: "transparent-select" }}
                                    rowsPerPage={5}
                                    page={page}
                                    count={regions.length}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
        );
    }
}

RegionTable.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto"
    }
});

export default withStyles(styles)(RegionTable);
