import React, { Component } from "react";

class YoutubeWidget extends Component {
    getEmbedId = url => {
        var ID = "";
        url = url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_-]/i);
            ID = ID[0];
        } else {
            ID = url;
        }
        return ID;
    };

    render() {
        return (
            <div
                style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    paddingTop: "30px",
                    height: "0",
                    overflow: "hidden"
                }}
            >
                {this.props.url ? (
                    <iframe
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: `100%`,
                            height: `100%`
                        }}
                        title="Youtube Embed Video"
                        src={"https://www.youtube.com/embed/" + this.getEmbedId(this.props.url || "")}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                    />
                ) : null}
            </div>
        );
    }
}

export default YoutubeWidget;
