import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

import { withStyles } from "@material-ui/core/styles";

import { Paper, Tooltip, Grid, MenuItem, Button, TextField, Typography, Toolbar } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import AddIcon from "@material-ui/icons/Add";

const columnData = [
    { columnTitle: "day", numeric: false, padding: "default", label: "Day" },
    { columnTitle: "hour", numeric: false, padding: "default", label: "Hour" }
];

const predefinedDayName = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Holiday"];

@inject("designProfileStore")
@observer
class TradingHourTable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            openDialog: false,
            orderBy: "id",
            selected: [],
            page: 0,
            rowsPerPage: 5,
            day: "",
            hour: "",
            customDay: "",
            id: 0
        };
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleOpenDialog = () => {
        this.setState({ openDialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    };

    handleDayChange = e => {
        this.setState({ day: e.target.value });
    };

    handleHourChange = e => {
        this.setState({ hour: e.target.value });
    };

    handleCustomDayChange = e => {
        this.setState({ customDay: e.target.value });
    };

    deleteTradingHours = id => () => {
        this.props.designProfileStore.deleteTradingHour(id);
    };

    clearPopupForm = () => {
        this.setState({
            day: "",
            hour: "",
            customDay: "",
            id: 0
        });
    };

    openAddTradingHours = () => {
        this.clearPopupForm();
        this.handleOpenDialog();
    };

    openEditTradingHours = id => () => {
        this.clearPopupForm();

        let tradingHour = this.props.designProfileStore.getTradingHour(id);

        if (predefinedDayName.indexOf(tradingHour.day) < 0) {
            this.setState({
                day: "-CUSTOM-",
                hour: tradingHour.hour,
                customDay: tradingHour.day,
                id: tradingHour.id
            });
        } else {
            this.setState({
                day: tradingHour.day,
                hour: tradingHour.hour,
                customDay: "",
                id: tradingHour.id
            });
        }

        this.handleOpenDialog();
    };

    submitTradingHour = () => {
        this.props.designProfileStore.submitTradingHour({
            id: this.state.id,
            day: this.state.day === "-CUSTOM-" ? this.state.customDay : this.state.day,
            hour: this.state.hour
        });
        this.handleCloseDialog();
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes, fullScreen } = this.props;
        const { rowsPerPage, page } = this.state;
        const { tradingHours } = this.props.designProfileStore;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, tradingHours.length - page * rowsPerPage);

        return (
            <Paper className={classes.root}>
                <Toolbar className={classes.toolbarRoot}>
                    <div className={classes.title}>
                        <Typography variant="subheading">Trading Hours</Typography>
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.actions}>
                        <Tooltip title="Add">
                            <Button id="add-tradinghour" aria-label="Add" onClick={this.openAddTradingHours}>
                                <AddIcon style={{color:"white"}}/>
                                <span style={{ paddingTop: 3, color:"white" }}>Add</span>
                            </Button>
                        </Tooltip>
                    </div>
                </Toolbar>

                <div className={classes.tableWrapper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnData.map(column => {
                                    return (
                                        <TableCell key={column.columnTitle} numeric={column.numeric} padding={column.padding}>
                                            {column.label}
                                        </TableCell>
                                    );
                                }, this)}
                                <TableCell padding="checkbox" numeric={true} />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {tradingHours.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                return (
                                    <TableRow hover tabIndex={-1} key={n.id}>
                                        <TableCell>{n.day}</TableCell>
                                        <TableCell>{n.hour}</TableCell>
                                        <TableCell numeric={true}>
                                            <Tooltip title="Edit">
                                                <Button color="primary" onClick={this.openEditTradingHours(n.id)}>Edit</Button>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <Button color="primary" onClick={this.deleteTradingHours(n.id)}>Delete</Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    SelectProps={{ className: "transparent-select" }}
                                    count={tradingHours.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                    transition={this.transition}
                    className="dialog-div"
                >
                    <DialogTitle className="see-more-dialog-title">Trading Hour</DialogTitle>
                    <DialogContent className="see-more-dialog-content">
                        <div style={{ padding: 16 }}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <DialogContentText className={classes.blackTextColor}>
                                        Enter the venue's trading hours. This will be displayed on your profile.
                                    </DialogContentText>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        id="day"
                                        placeholder="Day"
                                        label="Day"
                                        margin="none"
                                        fullWidth
                                        value={this.state.day}
                                        onChange={this.handleDayChange}
                                        select
                                    >
                                        {predefinedDayName.map((day, i) => (
                                            <MenuItem key={i} value={day}>
                                                {day}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value="-CUSTOM-">Custom Label</MenuItem>
                                    </TextField>
                                </Grid>

                                {this.state.day === "-CUSTOM-" && (
                                    <Grid item xs={12}>
                                        <TextField
                                            autoFocus
                                            id="customDay"
                                            placeholder="Day"
                                            label="Day"
                                            margin="none"
                                            fullWidth
                                            value={this.state.customDay}
                                            onChange={this.handleCustomDayChange}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <TextField
                                        id="hour"
                                        placeholder="Hour"
                                        label="Hour"
                                        margin="none"
                                        fullWidth
                                        value={this.state.hour}
                                        onChange={this.handleHourChange}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Close
                        </Button>
                        <Button id="submit-tradinghour" onClick={this.submitTradingHour} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        );
    }
}

TradingHourTable.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    toolbarRoot: {
        paddingRight: theme.spacing.unit,
        backgroundColor: "#ff0c9c"
    },
    title: {
        flex: "0 0 auto"
    },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    }
});

export default withMobileDialog()(withStyles(styles)(TradingHourTable));
