import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Grid, Paper } from "@material-ui/core";
import Heading from "../../components/Heading";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import Header from "../../components/Header";

import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import SetListView from "../../components/setList/SetListView";

import "../songList/SongListView.css";

@inject("profileStore", "songListStore", "setListStore")
@observer
class SetListViewPage extends Component {
    constructor(props) {
        super(props);
        if (this.props.match.params.id !== undefined) {
            this.props.setListStore.load(this.props.match.params.id).then(() => {
                const { selectedSongs } = this.props.setListStore;
                this.props.songListStore.filterSongs(selectedSongs);
            });
        }
    }

    render() {
        const breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Manage", link: "/" }, { name: "My Set Lists" }];

        return (
            <main>
                <div className="no-print">
                    <Header />
                    <Sidebar />
                </div>
                <MainContainer>
                    <div className="no-print">
                        <Breadcrumb links={breadcrumb} />
                        <Heading title="My Set List" />
                    </div>
                    <Paper style={{ padding: 16 }}>
                        <Grid container justify="center" spacing={0}>
                            <Grid item sm={12} md={12}>
                                <SetListView roles="musician" />
                            </Grid>
                        </Grid>
                    </Paper>
                </MainContainer>
            </main>
        );
    }
}

export default compose(withWidth())(SetListViewPage);
