import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import Dropzone from "react-dropzone";

import { Grid, TextField, GridList, GridListTile, GridListTileBar, InputLabel, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import SearchActField from "../formFields/SearchActField"

import MultiSelectGenre from "../../fields/MultiSelectGenre";
import RichTextEditor from "../../richTextEditor/RichTextEditor";

const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
const FILE_MAXSIZE = IS_MOBILE ? 8497152 : 2097152;
const ACCEPTED_FILE = "image/*";
const MAX_IMAGES = 6;

@inject("gigPromotionStore", "templateStore")
@withRouter
@observer
class GigPromotionActDetails extends Component {

    handleActChange = value => {
        this.props.gigPromotionStore.setGigPromotion("act", undefined);
        this.props.gigPromotionStore.setGigPromotion("actId", undefined);
        this.props.gigPromotionStore.setGigPromotion("actName", value);
        this.setTempTitle();
    }

    handleSelectAct = act => {
        this.props.gigPromotionStore.setGigPromotion("actName", act.actName);
        this.props.gigPromotionStore.setGigPromotion("act", act);
        this.props.gigPromotionStore.setGigPromotion("actId", act.id);
        this.setTempTitle();
    };

    handleGenreChange = value => {
        this.props.gigPromotionStore.setGigPromotion("actGenres", value);
    }

    handleActVideoUrlChange = e => {
        this.props.gigPromotionStore.setGigPromotion("actVideoUrl", e.target.value);
    }

    handleActDescriptionChange = value => {
        this.props.gigPromotionStore.setGigPromotion("actDescription", value);
    }

    handleAddActImage = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            let message = "";
            rejectedFiles.forEach(file => {
                message += file.name + ", ";
            });
            message = message.substr(0, message.length - 2);
            message += " couldn't be uploaded. Please check size and format.";
            this.props.templateStore.openSnackbar(message);
        }

        this.props.gigPromotionStore.addActImages(files);
    };

    handleDeleteActImage = image => {
        this.props.gigPromotionStore.deleteActImages(image);
    }

    setTempTitle = () => {
        const { gigPromotion } = this.props.gigPromotionStore;
        const { actName, venue, venueName } = gigPromotion;
        var title = "";

        title = `${actName} @ ${venue ? venue.profileName : venueName}`;

        this.props.gigPromotionStore.setGigPromotion("tempTitle", title);
    };

    render() {
        const { error, gigPromotion, actImages } = this.props.gigPromotionStore;
        const { actGenres, actName, actVideoUrl, actDescription } = gigPromotion;

        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                    <InputLabel shrink style={{ margin: 0 }}>
                        Act
                    </InputLabel>
                    <SearchActField
                        placeholder="Search Act"
                        id="private-booking-venue"
                        value={actName}
                        handleValueChange={this.handleActChange}
                        handleSelect={this.handleSelectAct}
                        error={error && error["act"]}
                        height={40}
                        helperText={error && error["act"] ? error["act"] : ""}
                    />
                    
                </Grid>
                <Grid item xs={12}>
                    <MultiSelectGenre 
                        id="search-genres" 
                        onChange={this.handleGenreChange} 
                        value={actGenres}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="videoUrl"
                        label="YouTube or Vimeo Url"
                        margin="dense"
                        placeholder="Add YouTube or Vimeo url"
                        fullWidth
                        value={actVideoUrl}
                        onChange={this.handleActVideoUrlChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Act's Images</InputLabel>
                    <div className="act-images">
                        {(actImages === undefined || (actImages && actImages.length === 0)) && (
                            <Dropzone
                                accept={ACCEPTED_FILE}
                                maxSize={FILE_MAXSIZE}
                                onDrop={this.handleAddActImage}
                                className="dropzone"
                            >
                                <p>
                                    Upload your images here. Maximum size 2 MB for each file. Full HD resolution for
                                    best fit.
                                </p>
                            </Dropzone>
                        )}
                        {actImages &&
                            actImages.length > 0 && (
                                <div
                                    style={
                                        {
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-around',
                                            overflow: 'hidden',
                                        }
                                    }
                                >
                                    <GridList className="gridlist" cols={6}>
                                        {actImages.map(image => (
                                            <GridListTile key={image.url}>
                                                <img src={image.url} alt={image.name || image.fileName} />
                                                <GridListTileBar
                                                    title={image.name || image.fileName}
                                                    className="tilebar"
                                                    actionIcon={
                                                        <IconButton
                                                            className="del"
                                                            onClick={ () => this.handleDeleteActImage(image)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </GridListTile>
                                        ))}
                                        {actImages.length < MAX_IMAGES && (
                                            <GridListTile>
                                                <Dropzone
                                                    accept={ACCEPTED_FILE}
                                                    maxSize={FILE_MAXSIZE}
                                                    onDrop={this.handleAddActImage}
                                                    className="dropzone-gridlist"
                                                >
                                                    <p>
                                                        Upload your images here. Maximum size 2 MB for each file. Full HD resolution for
                                                        best fit.
                                                    </p>
                                                </Dropzone>
                                            </GridListTile>
                                        )}
                                    </GridList>
                                </div>
                            )}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink={true}>About Act</InputLabel>
                    <RichTextEditor 
                        value={actDescription} 
                        onChange={this.handleActDescriptionChange}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default GigPromotionActDetails;
