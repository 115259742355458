import React, { Component } from "react";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import ConsultantContainer from "../../components/agent/consultant/ConsultantContainer";

class AgentConsultant extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/agent" }, { name: "My Consultants" }];
    render() {
        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <ConsultantContainer />
                </MainContainer>
            </main>
        );
    }
}

export default AgentConsultant;
