import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Grid, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "./Confirmation.css";
import _ from "lodash";

import Typography from "@material-ui/core/Typography";

@inject("userStore", "authStore")
@withRouter
@observer
class ResendConfirmation extends Component {
    state = {
        activeResend: true
    }
    componentDidMount() {
        this.props.authStore.reset();
        const { currentUser } = this.props.userStore;
        if (this.props.location && this.props.location.search) {
            this.params = _.chain(this.props.location.search)
                .replace("?", "")
                .split("&")
                .map(_.ary(_.partial(_.split, _, "="), 1))
                .fromPairs()
                .value();

            this.props.authStore.setEmail(this.params.email);

            // this.props.authStore.resendVerificationCode(currentUser.email).then(response => {
            //     console.log(response);
            // });

            // this.props.authStore.resendVerificationCode(this.params?this.params.email:'').then(response => {
            //     setTimeout(() => {
            //         this.setState({activeResend: true})
            //     }, 30000);
            // });

        }
    }

    handleResendEmail = () => {
        this.props.authStore.resendVerificationCode(this.params?this.params.email:'')
        this.setState({activeResend: false})
        setTimeout(() => {
            this.setState({activeResend: true})
        }, 30000);
    }

    render() {
        const { currentUser } = this.props.userStore;
        return (
            <div className="Confirmation">
                <Grid container className="main-grid" justify="center">
                    <Grid container spacing={24} justify="center">
                        <Grid item xs={12} sm={8} md={6}>
                            <div className="intro">
                                <h2 id="h-confirmation">You have successfully resend your verification email</h2>
                                <Typography component="p" className="sub-heading">
                                    Please check your Inbox or Spam folder
                                </Typography>
                                <Button disabled={this.state.activeResend ? false : true} className="btnSubmit" variant="contained" color="primary" id="signin-btn" onClick={this.state.activeResend?this.handleResendEmail:()=>null} >
                                    Resend Email Confirmation
                                </Button>
                                {this.state.activeResend ? null : <p className="wait-msg">Please wait for resending another email confirmation</p>}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ResendConfirmation;
