import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import ResponsiveTable from "../table/ResponsiveTable";

import { Grid, Avatar, IconButton, Tooltip, Typography, ExpansionPanel, Paper, Button } from "@material-ui/core";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmationModal from "../modal/ConfirmationModal";
import ModalContainer from "../modal/ModalContainer";

import AgentInvitationMessage from "../agentList/AgentInvitationMessage";
import TableRowProfile from "../util/TableRowProfile";

import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import MessageIcon from "@material-ui/icons/Message";
import InfoIcon from "@material-ui/icons/Info";
import Loading from "../../components/loadingSpinner/Loading";
import image from "../../helper/image";
import { Link } from "react-router-dom";
import { ProfileRelationType, ProfileType } from "../../types/enum";
import blankProfile from "../../img/hotgig-loading.png"

@inject("venueActStore", "templateStore", "userInvitationStore", "profileStore")
@observer
class MusicianVenueTable extends Component {
    state = {
        openAcceptModal: false,
        openDeclineModal: false,
        openAcceptAllModal: false,
        openDeclineAllModal: false,
        openRemoveAgentModal: false,
        openRemoveAgentActModal: false,
        openInvitationMessageModal: false,
        request: undefined,
        isLoading: true,
        openRemoveVenueActModal: false,
        request: null,
        act: null,
        venue: null
    };

    columns = [
        { key: "venue", label: "Venue", width: "20%" }, 
        { key: "actName", label: "Acts", width: "30%" }, 
        { key: "status", label: "Status", width: "20%" }
    ];

    rowData = [];

    pendingColumns = [
        { key: "venue", label: "Venue", width: "20%" },
        { key: "acts", label: "Act", width:"30%" },
        { key: "status", label: "Status", width:"20%" },
    ];

    pendingRows = [];

    constructor(props) {
        super(props);
        this.props.venueActStore.resetList();
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const currentProfile = this.props.profileStore.getCurrentProfile()
        // this.props.templateStore.showLoadingScreen();
        Promise.all([
                    // this.props.venueActStore.getMusicianVenueActRosterPending(), 
                    this.props.venueActStore.getMusicianVenueActRoster(),
                    this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.VenueAct.ordinal, currentProfile.id)
                ]).then(() => {
            this.setState({ isLoading: false });
            this.props.templateStore.hideLoadingScreen();
        });
    }

    mapPendingInvitation = () => {
        this.pendingRows = [];
        if (this.props.userInvitationStore.invitations.length > 0) {
            this.props.userInvitationStore.invitations.forEach(data => {
                var row = {};
                row.venue = data.venue ? <TableRowProfile profileId = { data.venue.id } name={data.venue.fullName} avatarUrl={image.getProfileImage(data.venue)} /> : null;
    
                row.acts = <TableRowProfile name={`${data.invitedAct}`} avatarUrl={data.actImage ? data.actImage : blankProfile} profileId={data.additionalData}/>;
    
                row.status = "Pending";
                this.pendingRows.push(row);
            })
        }
        return Promise.resolve();
    }

    handleOpenRemoveVenueActModal = request => {
        this.setState({
            ...this.state,
            openRemoveVenueActModal: true,
            request: request,
        });
    };
    mapPending = () => {
        this.rowData = [];
        this.props.venueActStore.pendingRequest.forEach(request => {
            var row = {};

            var venue =
                request && request.request && request.request[0] && request.request[0].primaryProfile ? request.request[0].primaryProfile : {};

            var acts = "";

            request.request.forEach(data => {
                acts += data.secondaryProfile.fullName + ", ";
            });

            acts = acts.substr(0, acts.length - 2);

            row.style = {
                backgroundColor: "#EEE"
            };

            row.venue = <TableRowProfile profileId = { venue.id } name={venue.fullName} />;

            row.actName = (
                <ExpansionPanel
                    style={{
                        backgroundColor: "#EEE",
                        boxShadow: "none"
                    }}
                >
                    <ExpansionPanelSummary style={row.style} expandIcon={<ExpandMoreIcon />}>
                        <Typography noWrap> {acts} </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={row.style}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Act Name</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {request.request.map(data => (
                                    <TableRow key={data.id}>
                                        <TableCell>
                                            <Grid container alignContent="center" alignItems="center">
                                                <Grid item xs={5}>
                                                    <Avatar
                                                        alt={data.secondaryProfile.fullName}
                                                        src={data.secondaryProfile.profileImage ? data.secondaryProfile.profileImage.url : ""}
                                                        style={{
                                                            width: 40,
                                                            height: 40
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography variant="subheading">{data.secondaryProfile.fullName}</Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="View Message">
                                                <IconButton onClick={() => this.handleOpenInvitationMessageModal(data)}>
                                                    <MessageIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Accept Request">
                                                <IconButton onClick={() => this.handleOpenAcceptModal(data)}>
                                                    <DoneIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Decline Request">
                                                <IconButton onClick={() => this.handleOpenDeclineModal(data)}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );

            row.status = "Pending";

            row.action = (
                <Fragment>
                    <Tooltip title="Accept All Request">
                        <IconButton onClick={() => this.handleOpenAcceptAllModal(request.request)}>
                            <DoneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Decline All Request">
                        <IconButton onClick={() => this.handleOpenDeclineAllModal(request.request)}>
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </Fragment>
            );

            this.rowData.push(row);
        });

        return Promise.resolve();
    };

    mapAccepted = () => {
        this.props.venueActStore.venueActRoster.forEach(act => {
            var row = {};

            var venue = act?.data[0]?.venue;

            var acts = "";

            act.data.forEach(data => {
                acts += data?.act?.fullName + ", ";
            });

            acts = acts.substr(0, acts.length - 2);

            row.venue = <TableRowProfile avatarUrl={image.getProfileImage(venue)} profileId = { venue?.id } name={venue?.fullName} />;

            row.actName = (
                <ExpansionPanel
                    style={{
                        boxShadow: "none"
                    }}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}><div style={{width:"95%"}}>{acts}</div></ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Act Name</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {act.data.map(data => (
                                    <TableRow key={data.id}>
                                        <TableCell>
                                            <Grid container alignContent="center" alignItems="center">
                                                <Grid item xs={5}>
                                                    <Avatar
                                                        alt={data.act.fullName}
                                                        src={image.getProfileImage(data.act)}
                                                        style={{
                                                            width: 40,
                                                            height: 40
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography variant="subheading"><Link style={{fontSize:"0.8125rem"}} to={`/profile/${data.act.id}`} target={"_blank"}>{data.act.fullName}</Link> </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Remove">
                                            <Button color="primary" size="small" onClick={() => this.handleOpenRemoveVenueActModal(data)}>
                                                Remove
                                            </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );

            row.status = "Accepted";

            row.action = "";

            this.rowData.push(row);
        });
    };

    handleOpenAcceptModal = request => {
        this.setState({
            ...this.state,
            openAcceptModal: true,
            request: request
        });
    };

    handleOpenAcceptAllModal = requests => {
        this.setState({
            ...this.state,
            openAcceptAllModal: true,
            request: requests
        });
    };

    handleOpenDeclineAllModal = requests => {
        this.setState({
            ...this.state,
            openDeclineAllModal: true,
            request: requests
        });
    };

    handleOpenDeclineModal = request => {
        this.setState({
            ...this.state,
            openDeclineModal: true,
            request: request
        });
    };

    handleCloseConfirmationModal = () => {
        this.setState({
            openAcceptModal: false,
            openDeclineModal: false,
            openAcceptAllModal: false,
            openDeclineAllModal: false,
            openRemoveAgentModal: false,
            openRemoveAgentActModal: false,
            openInvitationMessageModal: false,
            openRemoveVenueActModal: false,
            request: undefined
        });
    };

    handleDeleteVenueAct = () => {
        this.props.venueActStore.deleteVenueActMusician(this.state.request.id).then(() => {
            this.handleCloseConfirmationModal();
            this.props.templateStore.openSnackbar("Venue act has been deleted.");
        });
    };

    handleAcceptInvitation = () => {
        this.props.venueActStore.acceptRequest(this.state.request).then(() => {
            this.handleCloseConfirmationModal();
            this.props.templateStore.openSnackbar("Venue invitation has been accepted.");
        });
    };

    handleDeclineInvitation = () => {
        this.props.venueActStore.declineRequest(this.state.request.id).then(() => {
            this.handleCloseConfirmationModal();
            this.props.templateStore.openSnackbar("Venue invitation has been declined.");
        });
    };

    handleAcceptAllInvitation = () => {
        this.props.venueActStore.acceptManyRequest(this.state.request).then(() => {
            this.handleCloseConfirmationModal();
            this.props.templateStore.openSnackbar("Venue invitation has been accepted.");
        });
    };

    handleDeclineAllInvitation = () => {
        this.props.venueActStore.declineManyRequest(this.state.request).then(() => {
            this.handleCloseConfirmationModal();
            this.props.templateStore.openSnackbar("Venue invitation has been declined.");
        });
    };

    handleDeleteAgent = () => {
        this.props.agentActStore.removeAgentActs(this.state.request).then(() => {
            this.props.templateStore.openSnackbar("Venue has been removed.");
            this.handleCloseInvitationMessageModal();
        });
    };

    handleOpenInvitationMessageModal = request => {
        this.setState({
            ...this.state,
            openInvitationMessageModal: true,
            request: request
        });
    };

    render() {
        this.mapPending().then(this.mapAccepted());
        this.mapPendingInvitation();
        const { isLoading } = this.state;
        const {invitations} = this.props.userInvitationStore;
        return (
            <Fragment>
                <Loading showed={isLoading} borderRadius="0" padding="24" />
                {invitations.length > 0 && 
                    <Paper>
                        <ResponsiveTable columns={this.pendingColumns} rows={this.pendingRows} />
                    </Paper>
                }
                <Paper>
                    <ResponsiveTable columns={this.columns} rows={this.rowData} />
                </Paper>

                <ConfirmationModal
                    open={this.state.openAcceptAllModal}
                    message="Are you sure to accept all of request?"
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleAcceptAllInvitation}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Accept"
                />

                <ConfirmationModal
                    open={this.state.openRemoveVenueActModal}
                    title={"Remove Venue Confirmation"}
                    message={`Are you sure that you want to remove ${this.state.request?.act?.actName} from ${this.state.request?.venue?.profileName}'s act roster? This means they will no longer be able to book you for gigs.  Any existing bookings will not be effected.`}
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleDeleteVenueAct}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Confirm"
                />      
                <ConfirmationModal
                    open={this.state.openAcceptModal}
                    message="Are you sure to accept this request?"
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleAcceptInvitation}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Accept"
                />

                <ConfirmationModal
                    open={this.state.openDeclineAllModal}
                    message="Are you sure to decline all invitation?"
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleDeclineAllInvitation}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Decline"
                />

                <ConfirmationModal
                    open={this.state.openDeclineModal}
                    message="Are you sure to decline this invitation?"
                    closeHandler={this.handleCloseConfirmationModal}
                    confirmationHandler={this.handleDeclineInvitation}
                    declineHandler={this.handleCloseConfirmationModal}
                    confirmationLabel="Decline"
                />

                <ModalContainer open={this.state.openInvitationMessageModal} title="Invitation Message" onClose={this.handleCloseConfirmationModal}>
                    <DialogContent>
                        <AgentInvitationMessage
                            from={this.state.request ? this.state.request.primaryProfile : undefined}
                            to={this.state.request ? this.state.request.secondaryProfile : undefined}
                            message={this.state.request ? this.state.request.requestMessage : ""}
                            createdAt={this.state.request ? this.state.request.createdAt : ""}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleCloseConfirmationModal}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handleDeclineInvitation}>
                            Decline
                        </Button>
                        <Button color="primary" onClick={this.handleAcceptInvitation}>
                            Accept
                        </Button>
                    </DialogActions>
                </ModalContainer>
            </Fragment>
        );
    }
}

export default MusicianVenueTable;
