import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Typography, Grid, IconButton, Paper } from "@material-ui/core";
import WidgetContainer from "../widget/WidgetContainer";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import moment from "moment";
import { ProfileType } from "../../types/enum";
import "./GigsWidget.css";
import search from "../../img/search_icon.svg"

@inject("templateStore", "profileStore")
@observer
class GigsWidget extends Component {
    state = {
        displayIndex: 0
    };

    onNextClick = () => {
        if (this.props.todaysGigs && this.props.todaysGigs.length > 0) {
            if (this.state.displayIndex + 1 === this.props.todaysGigs.length) {
                this.setState({ displayIndex: 0 });
            } else {
                this.setState({ displayIndex: this.state.displayIndex + 1 });
            }
        }
    };

    onPreviousClick = () => {
        if (this.props.todaysGigs && this.props.todaysGigs.length > 0) {
            if (this.state.displayIndex - 1 < 0) {
                this.setState({ displayIndex: this.props.todaysGigs.length - 1 });
            } else {
                this.setState({ displayIndex: this.state.displayIndex - 1 });
            }
        }
    };

    render() {
        let renderInformation = data => {
            const { currentProfile } = this.props.profileStore;
            const startTime = moment(data.timeStart, "HH:mm:ss").format(this.props.templateStore.getFormat12Hour(data.timeStart));
            const endTime = moment(data.timeEnd, "HH:mm:ss").format(this.props.templateStore.getFormat12Hour(data.timeEnd));
            let linuep = data.lineups.find(x => x.profileId === currentProfile?.id);
            let myFee = linuep?.fee ? linuep?.fee : parseFloat(data.actFee) ? data.actFee : data.gigFee
            const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && data.createdById === currentProfile?.id && !data.act?.actName;
            let gigsInformation = [
                {
                    title: "Act",
                    information:  isEntertainerBookings ? `${currentProfile?.firstName} ${currentProfile?.lastName}` : data.act?.actName
                },
                {
                    title: "Booking Creator",
                    information: data.createdBy.firstName + " " + data.createdBy.lastName,
                    subinformation: data.agentPhone
                },
                {
                    title: "Playing Times",
                    information: startTime + " - " + endTime
                },
                {
                    title: "Venue",
                    information: data?.venueName,
                    subinformation: data?.venueAddress
                },
                {
                    title: "Venue Contact",
                    information: data?.venue?.contactName,
                    subinformation: data?.venue?.contactPhone
                },
                {
                    title: "Fee",
                    information: ((this.props.profileStore.currentProfile && this.props.profileStore.currentProfile.currencySymbol) ? this.props.profileStore.currentProfile.currencySymbol : "$") + parseFloat(myFee).toLocaleString()
                }
            ];

            // if (data.createdBy.type !== ProfileType.Agent.ordinal) {
            //     gigsInformation.splice(1, 1);
            // }

            let result = gigsInformation.map((info, index) => {
                return (
                    <Grid container spacing={16} key={index}>
                        <Grid item md={6} xs={6}>
                            <Typography type="body1" align="right">
                                {info.title}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography type="body1" align="left">
                                {info.information}
                            </Typography>
                            {info.subinformation ? (
                                <Typography type="body1" align="left">
                                    {info.subinformation}
                                </Typography>
                            ) : (
                                ""
                            )}
                        </Grid>
                    </Grid>
                );
            });

            return result;
        };

        return (
            <Paper>
                <WidgetContainer
                    title="Today's Gigs"
                    showBadge={this.props.todaysGigs && this.props.todaysGigs.length > 0 ? true : false}
                    badgeValue={(this.props.todaysGigs && this.props.todaysGigs.length) || 0}
                >
                    <Grid container spacing={0} justify="center">
                        <Grid item md={12} xs={12} sm={12}>
                            {this.props.todaysGigs && this.props.todaysGigs.length > 0 ? (
                                renderInformation(this.props.todaysGigs[this.state.displayIndex])
                            ) : (
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={12} className="todays-gig-icon">
                                        <img src={search} alt="Search" />
                                    </Grid>

                                    <Grid item xs={12} className="todays-gig-caption-primary">
                                        No gigs for today.
                                    </Grid>

                                    <Grid item xs={12} className="todays-gig-caption-secondary">
                                        Use the search functionality to connect with agents and venues
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    {this.props.todaysGigs &&
                        this.props.todaysGigs.length > 1 && (
                            <Grid container justify="flex-end" spacing={0}>
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <IconButton onClick={this.onPreviousClick}>
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>

                                    <IconButton onClick={this.onNextClick}>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                </WidgetContainer>
            </Paper>
        );
    }
}

export default GigsWidget;
