import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
// import profileStore from "./profileStore";
import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";
import { Storage } from "aws-amplify";

class InsuranceProfileStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable userId = 0;
    @observable type = 0; // 1 == musician

    @observable insurancePolicyHolder = "";
    @observable insuranceCompany = "";
    @observable insurancePolicyName = "";
    @observable insurancePolicyNumber = "";
    @observable insuranceFileGuid = undefined;
    @observable insuranceFileName = undefined;
    @observable insuranceFileContent = undefined;
    @observable insuranceExpiryDate = undefined;
    @observable insuranceFileContentUrl = undefined;
    @observable documentUploadInProgress = false;
    @observable guid = false;

    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        const { id } = generalProfileStore;

        profileId = profileId ? profileId : id;
        if (currentUser) {
            this.inProgress = true;
            // templateStore.showLoadingScreen();
            if ((this.type === ProfileType.Act.ordinal || this.type === ProfileType.Venue.ordinal) && profileId) {
                return agent.MusicianInsuranceProfile.load(profileId)
                    .then(
                        action(profile => {
                            if (!profile) throw new Error("Can't load profile");
                            this.insurancePolicyHolder = profile.insurancePolicyHolder || "";
                            this.insuranceCompany = profile.insuranceCompany || "";
                            this.insurancePolicyName = profile.insurancePolicyName || "";
                            this.insurancePolicyNumber = profile.insurancePolicyNumber || "";
                            this.insuranceExpiryDate = profile.insuranceExpiryDate;
                            this.insuranceFileGuid = profile.insuranceFileGuid;
                            this.insuranceFileName = profile.insuranceFileName;
                            this.insuranceFileContent = profile.insuranceFileContent;
                            this.insuranceFileContentUrl = profile.insuranceFileContentUrl;
                            this.id = profile.id;
                            this.type = profile.type;
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            } else if (this.type !== ProfileType.Act.ordinal && this.type !== ProfileType.Venue.ordinal) {
                return agent.MusicianInsuranceProfile.load(profileId)
                    .then(
                        action(profile => {
                            if (!profile) {
                                this.reset();
                                throw new Error("Can't load profile");
                            }
                            this.insurancePolicyHolder = profile.insurancePolicyHolder || "";
                            this.insuranceCompany = profile.insuranceCompany || "";
                            this.insurancePolicyName = profile.insurancePolicyName || "";
                            this.insurancePolicyNumber = profile.insurancePolicyNumber || "";
                            this.insuranceExpiryDate = profile.insuranceExpiryDate || "";
                            this.insuranceFileGuid = profile.insuranceFileGuid;
                            this.insuranceFileName = profile.insuranceFileName;
                            this.insuranceFileContent = profile.insuranceFileContent;
                            this.insuranceFileContentUrl = profile.insuranceFileContentUrl;
                            this.id = profile.id;
                            this.type = profile.type;
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            }
        } else {
            return Promise.resolve();
        }
    }

    @action
    reset() {
        this.insurancePolicyHolder = "";
        this.insuranceCompany = "";
        this.insurancePolicyName = "";
        this.insurancePolicyNumber = "";
        this.insuranceExpiryDate = undefined;
    }

    @action
    setInsurancePolicyHolder(insurancePolicyHolder) {
        this.insurancePolicyHolder = insurancePolicyHolder;
    }

    @action
    setInsuranceCompany(insuranceCompany) {
        this.insuranceCompany = insuranceCompany;
    }

    @action
    setInsurancePolicyName(insurancePolicyName) {
        this.insurancePolicyName = insurancePolicyName;
    }

    @action
    setInsurancePolicyNumber(insurancePolicyNumber) {
        this.insurancePolicyNumber = insurancePolicyNumber;
    }

    @action
    setInsuranceExpiryDate(insuranceExpiryDate) {
        this.insuranceExpiryDate = insuranceExpiryDate;
    }

    @action
    async setPolicyDocument(policyDocument) {
        policyDocument.forEach(x => {
            // this.profileImage = x;
            // this.profileImage.url = x.preview;
        });
        var uploadedDocuments = [];

        //upload the files here
        this.documentInProgress = true;

        for (var i = 0; i < policyDocument.length; i++) {
            var document = policyDocument[i];
            var result = await this.uploadInsuranceDocument(this.id, document);
            var doc = {
                fileContent: result.key,
                fileName: document.name,
                mime: document.type
            };
            uploadedDocuments.push(doc);
        }

        console.log(uploadedDocuments);
        var result = await agent.MusicianInsuranceProfile.uploadDocument(this.id, uploadedDocuments)
            .then(
                action(result => {
                    console.log(result);
                    console.log(result.documents[0].fileName);
                    this.insuranceFileGuid = result.documents[0].guid;
                    this.insuranceFileName = result.documents[0].fileName;
                    this.insuranceFileContent = result.documents[0].fileContent;
                })
            )
            .finally(
                action(() => {
                    this.documentInProgress = false;
                })
            )
            .catch(
                action(err => {
                    this.insuranceFileGuid = undefined;
                    return "error";
                })
            );
    }
    async uploadInsuranceDocument(id, document) {
        try {
            const result = await Storage.put(
                `document/insurance/${id}/${document.name}`,
                document
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }

    @action
    downloadInsuranceDocument() {
        return agent.MusicianInsuranceProfile.downloadDocument(this.insuranceFileGuid).then(response => {
            return response;
        });
    }

    @action
    async deleteInsuranceDocument() {
        await Storage.remove(this.insuranceFileContent);
        var result = await agent.MusicianInsuranceProfile.deleteDocument(this.insuranceFileGuid).then(
            action(response => {
                this.insuranceFileGuid = undefined;
                this.insuranceFileName = "";
                return response;
            })
        );
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const profile = {
            id: this.id,
            userId: user.id,
            insurancePolicyHolder: this.insurancePolicyHolder,
            insuranceCompany: this.insuranceCompany,
            insurancePolicyName: this.insurancePolicyName,
            insurancePolicyNumber: this.insurancePolicyNumber,
            insuranceExpiryDate: this.insuranceExpiryDate,
            type: this.type
        };

        return (this.id ? this.updateProfile(profile) : this.createProfile(profile))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.data.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    createProfile(data) {
        //using general profile for create
        switch (this.type) {
            case 1:
                return agent.MusicianGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 2:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 3:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 6:
                return agent.ProductionGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 7:
                return agent.DjGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 4:
            default:
                return agent.ActGeneralProfile.create(data).then(profile => {
                    return profile;
                });
        }
    }

    @action
    updateProfile(data) {
        switch (this.type) {
            case 1:
                return agent.MusicianInsuranceProfile.update(data).then(profile => {
                    return profile;
                });
            case 2:
                return agent.AgentInsuranceProfile.update(data).then(profile => {
                    return profile;
                });
            default:
                return agent.MusicianInsuranceProfile.update(data).then(profile => {
                    return profile;
                });
        }
    }
}

export default new InsuranceProfileStore();
