import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import moment from "moment"
import { Promise } from "bluebird";
import { Storage } from "aws-amplify";
import CacheHelper from '../helper/cache';

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class instagramStore{

    @observable instagramCode = "";
    @observable instagramEvent = [];

    @observable instagramEventVenue = [];
    @action
    resetinstagramEvent(){
        this.instagramEvent = []
        this.instagramEventVenue = []
    }
    @action
    setInstagramCode(code){
        this.instagramCode = code;
    }

    @action
    exchangeToken(data){
        return agent.InstagramIntegration.exchangeToken(data).then(action(
            response => {
                return response
            }
        ))
    }

    @action
    disconnectAccount(profileId){
        let data = { profileId };
        return agent.InstagramIntegration.disconnectInstagram(data);
    }
}
   

export default new instagramStore();