import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";
import { DialogContent, DialogActions, Button, TextField, Grid } from "@material-ui/core";
import ResponsiveTable from "../../table/ResponsiveTable";
const ENABLE_QUICKBOOKS = process.env.REACT_APP_ENABLE_QUICKBOOKS === "true";

@inject("profileStore", "invoiceStore", "userStore")
@observer
class POModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open) {
            const { invoices } = this.props;
            console.log(invoices);
            let poCode = undefined;
            invoices.forEach(invoice => {
                poCode = invoice.purchaseOrderCode;
            });
            this.props.invoiceStore.setPurchaseOrderCode(poCode);
        }
    }

    getColumns = () => {
        const { hasQuickBooksAccount } = this.props.userStore;
        const columns = [
            { key: "date", label: "Invoice Date", sortable: true, isDate: true },
            { key: "dueDate", label: "Due Date", sortable: true, isDate: true },
            { key: "no", label: "Invoice No." },
            { key: "status", label: "Status" },
            { key: "total", label: "Total", sortable: true },
            { key: "payeeName", label: "Contact Name", sortable: true },
            { key: "payeePhone", label: "Contact Phone" },
            ...(ENABLE_QUICKBOOKS && hasQuickBooksAccount ? [{ key: "quickBooks", label: "QuickBooks" }] : []),
            { key: "action", label: "" }
        ];
        return columns;
    };

    handlePurchaseOrderCodeChange = e => {
        this.props.invoiceStore.setPurchaseOrderCode(e.target.value);
    };

    handleClose = () => {
        this.props.invoiceStore.setPurchaseOrderCode(undefined);
        this.props.closeHandler();
    };

    handleSetPurchaseOrder = () => {
        const { invoices } = this.props;
        const { purchaseOrderCode } = this.props.invoiceStore;

        if (invoices.length > 0 && purchaseOrderCode) {
            var invoiceIds = invoices.map(invoice => invoice.id);

            this.setState(
                {
                    ...this.state,
                    isLoading: true
                },
                () => {
                    this.props.invoiceStore.updateInvoicesPurchaseOrder(invoiceIds).then(() => {
                        this.setState({
                            ...this.state,
                            isLoading: false
                        });
                        this.handleClose();
                    });
                }
            );
        }
    };

    renderFooterRow = invoices => {
        // if (invoices) console.log("match", invoices.slice());
        const { incomingInvoices } = this.props.invoiceStore;
        let total = 0;
        if (incomingInvoices && invoices) {
            const match = incomingInvoices.filter(incoming => {
                return invoices.some(x => x.id === incoming.id);
            });

            if (match) {
                total = match.reduce((a, b) => {
                    return a + b.total;
                }, 0);
            }
        }

        return {
            total: "$" + parseFloat(total).toLocaleString()
        };
    };

    render() {
        const { isLoading } = this.state;
        const { invoices, open } = this.props;
        const { purchaseOrderCode } = this.props.invoiceStore;
        return (
            <ModalContainer
                open={open}
                title="Send To Accounts"
                maxWidth="md"
                fullScreen={false}
                onClose={this.handleClose}
                isLoading={isLoading}
            >
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <ResponsiveTable columns={this.getColumns()} rows={invoices} footerRow={this.renderFooterRow(invoices)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Purchase Order" value={purchaseOrderCode} onChange={this.handlePurchaseOrderCodeChange} fullWidth />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>Close</Button>
                    <Button color="primary" onClick={this.handleSetPurchaseOrder}>
                    Send to Accounts
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default POModal;
