import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import agent from "../../agent";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import DashboardFilter from "../../components/dashboard/DashboardFilter";
import Heading from "../../components/Heading";
import DashboardBigInformationWidget from "../../components/dashboard/DashboardBigInformationWidget";
import DashboardSmallInformationWidget from "../../components/dashboard/DashboardSmallInformationWidget";
import UpcomingBookingStaff from "../../components/dashboard/UpcomingBookingStaff";
import { ProfileType } from "../../types/enum"
import Grid from "@material-ui/core/Grid";
import Loading from "../../components/loadingSpinner/Loading";
import moment from "moment"
@inject("userStore", "templateStore", "profileStore")
@observer
class Staff extends Component {
    state = {
        data: {},
        startDate: null,
        endDate: null,
        todaysGigs: [],
        organisationFilter: 0,
        stateFilter: 0,
        regionFilter: 0,
        venueFilter: 0,
        organisation: [],
        state: [],
        region: [],
        venue: [],
        firstDay: null,
        lastDay: null,
        isLoading: true
    };
    currentUserId = 0;
    offset = moment(new Date()).utcOffset()
    loadData = () => {
        // this.props.templateStore.showLoadingScreen();
        this.setState({ isLoading: true });
        agent.Profile.getStaffDashboard(
            this.currentUserId,
            this.state.firstDay,
            this.state.lastDay,
            this.state.organisationFilter,
            this.state.stateFilter,
            this.state.regionFilter,
            this.state.venueFilter
        ).then(result => {
            result?.upcomingBookings?.forEach((gig) => {
                var dateStart = moment(gig.dateStart)
                var dateEnd = moment(gig.dateEnd)
                gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
            
                var dstVariable= 120
                var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                if(dstChecking.isAfter(gig.dateStart)){
                    gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                    gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                }
            })
            this.setState({ data: result });
            this.props.templateStore.hideLoadingScreen();
            this.setState({ isLoading: false });
        });
    };

    onOrganisationChange = value => {
        this.setState(
            {
                organisationFilter: value,
                stateFilter: 0,
                regionFilter: 0,
                venueFilter: 0
            },
            () => {
                this.loadData();
            }
        );
    };

    onStateChange = value => {
        this.setState(
            {
                stateFilter: value,
                regionFilter: 0,
                venueFilter: 0
            },
            () => {
                this.loadData();
            }
        );
    };

    onRegionChange = value => {
        this.setState(
            {
                regionFilter: value,
                venueFilter: 0
            },
            () => {
                this.loadData();
            }
        );
    };

    onVenueChange = value => {
        this.setState({ venueFilter: value }, () => {
            this.loadData();
        });
    };

    formatDate = date => {
        let d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    gotoDashboard(profileType) {
        switch (profileType) {
            case 1:
                this.props.history.replace("/musician");
                break;
            case 2:
                this.props.history.replace("/agent");
                break;
            case 3:
                this.props.history.replace("/staff");
                break;
            case 6:
                this.props.history.replace("/production");
                break;
            case 7:
                this.props.history.replace("/dj");
                break;
            case 8:
                this.props.history.replace("/agent");
                break;
            case 9:
                this.props.history.replace("/privatehire/gig");
                break;
    }
    }
    componentDidMount = () => {
        const currentUser = this.props.profileStore.getCurrentProfile();
        this.currentUserId = currentUser.id;
        if(currentUser.type !== ProfileType.Staff.ordinal){
            this.gotoDashboard(currentUser.type)
        }
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        firstDay = this.formatDate(firstDay) + " 00:00:00";
        lastDay = this.formatDate(lastDay) + " 23:59:59.999";

        this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
            this.loadData();
        });
    };

    onCustomMonthChange = (startDate, endDate) => {
        this.setState({ startDate: startDate, endDate: endDate });

        let firstDay = this.formatDate(startDate) + " 00:00:00";
        let lastDay = this.formatDate(endDate) + " 23:59:59.999";

        this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
            this.loadData();
        });
    };

    onCustomStartDateChange = value => {
        this.setState({ startDate: value });

        if (this.state.endDate) {
            let firstDay = this.formatDate(value) + " 00:00:00";
            let lastDay = this.formatDate(this.state.endDate) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        }
    };

    onCustomEndDateChange = value => {
        this.setState({ endDate: value });

        if (this.state.startDate) {
            let firstDay = this.formatDate(this.state.startDate) + " 00:00:00";
            let lastDay = this.formatDate(value) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        }
    };

    handleFilterChange = value => {
        if (value === "today") {
            let today = this.formatDate(new Date());

            let firstDay = today + " 00:00:00";
            let lastDay = today + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        } else if (value === "week") {
            let curr = new Date(); // get current date
            var calculateLast = new Date(); // get current date
            let first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
            let last = first + 6; // last day is the first day + 6

            let firstDay = new Date(curr.setDate(first));
            let lastDay = new Date(calculateLast.setDate(last));

            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        } else if (value === "month") {
            let date = new Date();

            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        } else if (value === "nextMonth") {
            var date = new Date();

            var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        }
    };

    render() {
        const { isLoading } = this.state;
        const { currentProfile } = this.props.profileStore;

        let currency = currentProfile && currentProfile.currency ? currentProfile.currency : "USD";
        if (currency === "AUD") {
            currency = "USD";
        }

        let remainingBudget = 0;
        if (this.state.data.monthlyBudget && this.state.data.totalGigFees) {
            remainingBudget = this.state.data.monthlyBudget - this.state.data.totalGigFees;
        }

        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Heading title="Dashboard">
                        <DashboardFilter
                            onChange={this.handleFilterChange}
                            onCustomStartDateChange={this.onCustomStartDateChange}
                            onCustomEndDateChange={this.onCustomEndDateChange}
                            onCustomMonthChange={this.onCustomMonthChange}
                            onOrganisationChange={this.onOrganisationChange}
                            organisations={this.state.data.organisations}
                            onStateChange={this.onStateChange}
                            states={this.state.data.states}
                            onRegionChange={this.onRegionChange}
                            regions={this.state.data.regions}
                            onVenueChange={this.onVenueChange}
                            venues={this.state.data.venues}
                            level1Label={this.state.data.level1Label}
                            level2Label={this.state.data.level2Label}
                            level3Label={this.state.data.level3Label}
                        />
                    </Heading>

                    <Grid container spacing={16} style={{ position: "relative" }}>
                        <Loading showed={isLoading} />
                        <Grid item xs={12}>
                            <Grid container spacing={16}>
                                <Grid item md={4} xs={12}>
                                    <DashboardBigInformationWidget
                                        headerContent={this.state.data.totalGigs === undefined ? "Loading..." : this.state.data.totalGigs}
                                        headerCaption="Total Gigs"
                                        content1={this.state.data.upcomingGigs === undefined ? "Loading..." : this.state.data.upcomingGigs}
                                        caption1="Upcoming Gigs"
                                        content2={this.state.data.completedGigs === undefined ? "Loading..." : this.state.data.completedGigs}
                                        caption2="Completed Gigs"
                                        primaryColor={true}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <DashboardBigInformationWidget
                                        headerContent={
                                            (currentProfile && currentProfile.allowInvoices ?
                                                (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                                (this.state.data.totalGigFees === undefined
                                                    ? "Loading..."
                                                    : this.state.data.totalGigFees.toLocaleString())
                                                : <i className={"icon-eye-blocked"} />
                                            )
                                                
                                        }
                                        headerCaption="Committed Fees (ex Tax)"
                                        content1={
                                            this.state.data.monthlyBudget === undefined
                                                ? "Loading..."
                                                : currentProfile && currentProfile.allowInvoices ?  this.state.data.monthlyBudget.toLocaleString("en", {
                                                    style: "currency",
                                                    currency: currency,
                                                    currencyDisplay: "symbol",
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }) : <i className={"icon-eye-blocked"} />
                                               
                                        }
                                        caption1="Monthly Budget"
                                        content2={
                                            this.state.data.monthlyBudget === undefined
                                                ? "Loading..."
                                                : currentProfile && currentProfile.allowInvoices ? remainingBudget.toLocaleString("en", {
                                                      style: "currency",
                                                      currency: currency,
                                                      currencyDisplay: "symbol",
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0
                                                  }) : <i className={"icon-eye-blocked"} />
                                        }
                                        caption2="Remaining Budget"
                                        primaryColor={false}
                                        highlightContent2={remainingBudget < 0}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <DashboardSmallInformationWidget
                                                mhIcon="mh-microphone"
                                                information={
                                                    this.state.data.outstandingGigRequests === undefined
                                                        ? "Loading..."
                                                        : this.state.data.outstandingGigRequests
                                                }
                                                informationDetail="Booking Requests Sent"
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <DashboardSmallInformationWidget
                                                mhIcon="fa fa-building"
                                                information={
                                                    this.state.data.venuesOnYourRoster === undefined
                                                        ? "Loading..."
                                                        : this.state.data.venuesOnYourRoster
                                                }
                                                informationDetail="Venues on Your Roster"
                                            />
                                        </Grid> */}
                                           <Grid item xs={12}>
                                                <DashboardSmallInformationWidget
                                                    mhIcon="fa fa-eye"
                                                    information={
                                                        this.state.data.profileViews === undefined
                                                            ? "0"
                                                            : this.state.data.profileViews
                                                    }
                                                    informationDetail="Profile Views"
                                                />
                                            </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={16}>
                                <Grid item xs={12}>
                                    <UpcomingBookingStaff tableData={this.state.data.upcomingBookings} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainContainer>
            </div>
        );
    }
}

export default Staff;
