import React, { Component, Fragment } from 'react'
import googlePlayStoreButton from "../../img/google-play-badge.png"
import appleAppStoreButton from "../../img/app-store-button-white.png"
import hotgiggityLiveLogo from "../../img/Hot Giggity Live.png"
import { Grid, Paper } from '@material-ui/core';

class HotgiggityLive extends Component{
    render(){
        return(
            <Fragment>
                <Paper style={{ backgroundColor: 'black', height: '100vh' }}>
                    <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginBottom: 50, marginTop: 50 }}>
                            <img src={hotgiggityLiveLogo} alt="Hot Giggity Live" width="300px" />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ textAlign: 'center' }}>
                            {/* Please update playstore link and re-enable again*/}
                            {/* <a href="http://play.google.com/store/apps/details?id=com.hubdit.live.prod" rel="noopener noreferrer" target="_blank"> */}
                                <img src={ googlePlayStoreButton } alt="Get It On Google Playstore" width="300px"/>
                            {/* </a> */}
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ textAlign: 'center'}}>
                            <a href="https://apps.apple.com/app/hot-giggity-live-gig-guide/id1614390607" rel="noopener noreferrer" target="_blank">
                                <img src={ appleAppStoreButton } alt="Get It On Google Playstore" width="270px" style={{border:"1px solid white", outlineOffset:"-22px", borderRadius:10}}/>
                            </a>
                        </Grid>
                    </Grid>
                </Paper>
            </Fragment>
        )
    }
}

export default HotgiggityLive;