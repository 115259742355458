import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloseIcon from "@material-ui/icons/Close";

import { Button, TextField, Grid, Typography, IconButton, Snackbar } from "@material-ui/core";
import "./Embed.css";

@inject("embedProfileStore", "templateStore", "authStore", "agentActStore")
@withRouter
@observer
class Embed extends React.Component {
    state = {
        open: false
    };

    componentDidMount() {
        // set type
        this.props.embedProfileStore.setType(this.props.templateStore.getType(this.props.type));
        this.props.embedProfileStore.loadInitialData(this.props.profileId);
    }

    handleEmbedChange = e => this.props.embedProfileStore.setEmbed(e.target.value);

    handleBack = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    renderEmbed = () => {
        return this.props.embedProfileStore.embed;
    };

    handleClickCopy = () => {
        this.setState({ open: true });
    };

    handleCloseSnackbar = () => {
        this.setState({ open: false });
    };

    handleMouseDown = event => {
        event.preventDefault();
    };

    handleSubmitForm = e => {
        e.preventDefault();
        const {isAgentAct} = this.props.agentActStore;
        // this.props.embedProfileStore.submit().then(() => {
        switch (this.props.type) {
            case "agent":
                this.props.history.push("/agent");
                break;
            case "musician":
                this.props.history.push("/musician");
                break;
            case "venue":
                this.props.history.push("/venue");
                break;
            case "act":
                if (isAgentAct) {
                    this.props.history.push("/agent/actroster/myact")
                    this.props.agentActStore.setIsAgentAct(false);
                    break
                }
                this.props.history.push("/myact");
                break;
            default:
                this.props.history.push("/welcome");
                break;
        }
        // });
    };

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    render() {
        const { embed } = this.props.embedProfileStore;
        const { editProfileActiveStep } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);

        return (
            <div className="Profile-Embed">
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.open}
                    onClose={this.handleCloseSnackbar}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Embed code copied to clipboard</span>}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleCloseSnackbar}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />

                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography type="headline" component="h3">
                                Embed
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography type="caption" className="form-label">
                                Preview
                            </Typography>
                            <div class="intrinsic-container intrinsic-container-16x9">
                                <iframe title="embed-preview" src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0" allowFullScreen />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="embed"
                                label="Embed Code"
                                margin="normal"
                                fullWidth
                                disabled
                                readonly
                                classes={{ root: "text-field-readonly" }}
                                value={embed}
                                onChange={this.handleEmbedChange}
                                error={this.anyError("embed")}
                                helperText={this.errorText("embed")}
                                multiline
                                rows="4"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.handleClickCopy} onMouseDown={this.handleMouseDown}>
                                                <FileCopyIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} className="action">
                            <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                Back
                            </Button>
                            <Button variant="contained" color="primary" type="submit">
                                {editProfileActiveStep === steps.length - 1 ? "Finish" : "Next"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default Embed;
