import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import organisationMemberStore from "./organisationMemberStore";
import regionStore from "./regionStore";

class StateStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable organisation = undefined;
    @observable isLoading = true;

    // Organisation fields
    @observable name = "";
    @observable address = "";
    @observable contactNumber = "";
    @observable states = [];
    @observable organisationId = undefined;

    @observable canManage = false;

    @action
    setStates(states) {
        this.states = states;
    }

    @action
    setName(name) {
        this.name = name;
    }

    @action
    setAddress(address) {
        this.address = address;
    }

    @action
    setContactNumber(contactNumber) {
        this.contactNumber = contactNumber;
    }

    @action
    loadState(stateId) {
        this.inProgress = true;

        return agent.State.load(stateId)
            .then(
                action(state => {
                    if (state) {
                        this.id = state.id;
                        this.name = state.name;
                        this.address = state.address;
                        this.contactNumber = state.contactNumber;
                        this.organisationId = state.organisationId;
                        this.canManage = state.canManage;
                    }

                    return state;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    removeState(name) {
        let state = this.states.filter(x => x.name === name);
        if (state && state.length > 0) this.states.remove(state[0]);
    }

    @action
    reset() {
        this.id = undefined;
        this.name = "";
        this.address = "";
        this.contactNumber = "";
        this.states = [];
        this.canManage = false;
    }

    @action
    resetAddForm() {
        this.id = undefined;
        this.name = "";
        this.address = "";
        this.contactNumber = "";
    }

    @action
    create() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const existingState = this.states.filter(x => x.name === this.name);
        if (existingState.length > 0) return false;

        const state = {
            id: this.id,
            createdById: user.id,
            name: this.name,
            address: this.address,
            contactNumber: this.contactNumber
        };
        this.states.push(state);
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const members = organisationMemberStore.members.slice();
        // const states = stateStore.states.slice();
        const regions = regionStore.regions.slice();

        const state = {
            id: this.id,
            createdById: user.id,
            name: this.name,
            address: this.address,
            contactNumber: this.contactNumber,
            organisationId: this.organisationId,
            members: members,
            regions: regions
        };

        return (this.id ? this.updateState(state) : this.createState(state))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    createState(data) {
        data.id = 0;
        return agent.State.create(data).then(state => {
            this.id = state.id;
            organisationMemberStore.setMembers(state.members);
            regionStore.setRegions(state.regions);
            return state;
        });
    }

    @action
    updateState(data) {
        return agent.State.update(data).then(state => {
            this.id = state.id;
            organisationMemberStore.setMembers(state.members);
            regionStore.setRegions(state.regions);
            return state;
        });
    }
}

export default new StateStore();
