import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Hidden from "@material-ui/core/Hidden";

import LoadingSpinner from "./loadingSpinner/LoadingSpinner";
import SimpleSnackbar from "./snackBar/snackbar";

import "./MainContainer.css";

@inject("templateStore")
@observer
class MainContainer extends Component {
    render() {
        return (
            <Fragment>
                <Hidden smDown>
                    <main className="main-container">{this.props.children}</main>
                </Hidden>
                <Hidden mdUp>
                    <main className="main-container-small">{this.props.children}</main>
                </Hidden>
                <LoadingSpinner showed={this.props.templateStore.loadingScreenState} />
                <SimpleSnackbar />
            </Fragment>
        );
    }
}

export default MainContainer;
