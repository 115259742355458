import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { TextField, Button, Grid, Card, Typography, IconButton, Hidden, Snackbar, Tooltip, InputAdornment } from "@material-ui/core";
import Loading from "../loadingSpinner/Loading";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import NumberFormat from "react-number-format";

import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import ModalContainer from "../modal/ModalContainer";

import ProductionType from "../fields/MultiSelectProductionType";

import ProfileSearchBox from "../fields/ProfileSearchBox";

import DragDrop from "../dnd/DragDrop";

import { DragDropContext } from "react-beautiful-dnd";

import BigRosterCard from "../roster/rosterBigCard";
import "./Roster.css";
import Badge from "@material-ui/core/Badge";

import ClearIcon from "@material-ui/icons/Clear";
import ReorderIcon from "@material-ui/icons/Reorder";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

@inject("rosterProductionStore", "templateStore", "generalProfileStore", "profileStore")
@withRouter
@observer
class ProductionRoster extends Component {
    state = {
        selectedProduction: [],
        newProduction: null,
        openInviteProduction: false,
        activeProduction: null,
        openDeleteConfirmation: false,
        deletedActRoster: undefined,
        isLoading: true
    };

    searchBox = [];

    componentDidMount() {
        // set type
        window.scrollTo(0, 0);
        this.props.rosterProductionStore.setType(this.props.templateStore.getType(this.props.type));
        this.props.rosterProductionStore
            .loadInitialData(this.props.profileId)
            .then(response => {
                this.setState({
                    ...this.state
                });
            })
            .finally(() => {
                this.setState({ isLoading: false });
                window.scrollTo(0, 0);
            });
    }

    handleAddProductionRoster = (roster, index) => {
        this.props.rosterProductionStore.addProductionRoster(roster, index);
        this.setState({...this.state});
    };

    handleRemoveProductionRoster = (roster, instrument) => {
        this.props.rosterProductionStore.removeProductionRoster(roster, instrument);
    };

    handleAddNewProduction = productionName => {
        this.props.rosterProductionStore.addActProduction();
        this.setState(
            {
                ...this.state
            },
            () => {
                window.scrollTo(0, document.body.scrollHeight);
            }
        );
    };

    handleOpenInvitationDialog = (production, value) => {
        this.setState(
            {
                activeProduction: production
            },
            () => {
                this.props.rosterProductionStore.setinvitedProduction(production);
                this.props.rosterProductionStore.openInviteProductionForm();
                this.props.rosterProductionStore.setInvitedName(value);
            }
        );
    };

    handleCloseInvitationDialog = () => {
        this.setState(
            {
                activeProduction: null
            },
            () => {
                this.props.rosterProductionStore.closeInviteProductionForm();
            }
        );
    };

    handleInviteNewProduction = () => this.props.rosterProductionStore.inviteRoster();

    handlerosterCommissionChange = (production, value) => {
        this.props.rosterProductionStore.updateRosterCommission(production, parseFloat(value));
    };

    anyError(field) {
        if (!this.props.rosterProductionStore.errors) return false;
        if (!this.props.rosterProductionStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.rosterProductionStore.errors) return null;
        if (!this.props.rosterProductionStore.errors[field]) return null;
        return this.props.rosterProductionStore.errors[field];
    }

    handleBack = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.setState({ isLoading: true });
        this.props.rosterProductionStore
            .submit()
            .then(() => {
                if(this.props.type !== "act" && this.props.type !== "venue"){
                    const { editProfileActiveStep } = this.props.templateStore;
                    this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
                }
                this.props.history.push(`/myact/edit/${this.props.profileId}/songlist`);
                // this.props.templateStore.hideLoadingScreen();
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    handleOpenDeleteConfirmationDialog = deletedProduction => {
        this.setState({
            ...this.state,
            deletedActRoster: deletedProduction,
            openDeleteConfirmation: true
        });
    };

    handleCloseDeleteConfirmationDialog = () => {
        this.setState({
            ...this.state,
            deletedActRoster: undefined,
            openDeleteConfirmation: false
        });
    };

    handleDeleteActProduction = () => {
        this.props.rosterProductionStore.removeProduction(this.state.deletedActRoster);
        this.handleCloseDeleteConfirmationDialog();
    };

    renderInvitationModal = () => (
        <ModalContainer
            open={this.props.rosterProductionStore.openInviteProduction}
            onClose={this.handleCloseInvitationDialog}
            title="Invite Production Crew"
        >
            <DialogContent>
                <TextField
                    label="Production Name"
                    margin="normal"
                    fullWidth
                    value={this.props.rosterProductionStore.invitedName ? this.props.rosterProductionStore.invitedName : ""}
                    type="email"
                    onChange={e => {
                        this.props.rosterProductionStore.setInvitedName(e.target.value);
                    }}
                    id="invitedProductionName"
                />
                <TextField
                    label="Production Email"
                    margin="normal"
                    fullWidth
                    value={this.props.rosterProductionStore.invitedEmail ? this.props.rosterProductionStore.invitedEmail : ""}
                    type="email"
                    onChange={e => {
                        this.props.rosterProductionStore.setInvitedEmail(e.target.value);
                    }}
                    error={this.anyError("email")}
                    helperText={this.errorText("email")}
                    id="invitedProductionEmail"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseInvitationDialog}>Close</Button>
                <Button
                    color="primary"
                    disabled={!this.props.rosterProductionStore.invitedEmail || this.anyError("email")}
                    onClick={this.handleInviteNewProduction}
                >
                    Send Invitation
                </Button>
            </DialogActions>
        </ModalContainer>
    );

    renderDeleteConfirmationModal = () => (
        <ModalContainer
            open={this.state.openDeleteConfirmation}
            fullScreen={false}
            maxWidth="sm"
            onClose={this.handleCloseDeleteConfirmationDialog}
            title="Delete Production"
        >
            <DialogContent>
                <DialogContentText>Are you sure to delete this Act Production including it's rosters?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseDeleteConfirmationDialog}>Close</Button>
                <Button color="primary" disabled={!this.state.deletedActRoster} onClick={() => this.handleDeleteActProduction()}>
                    Delete
                </Button>
            </DialogActions>
        </ModalContainer>
    );

    renderSnackBar = () => (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={this.props.rosterProductionStore.openSnackbar}
            onClose={() => this.props.rosterProductionStore.closeSnackbarContainer()}
            autoHideDuration={6000}
            SnackbarContentProps={{
                "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.props.rosterProductionStore.snackbarMessage}</span>}
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={() => this.props.rosterProductionStore.closeSnackbarContainer()}>
                    <ClearIcon />
                </IconButton>
            ]}
        />
    );

    numberFormatPercentage = props => {
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                ref={inputRef}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                allowNegative={false}
            />
        );
    };

    render() {
        const { editProfileActiveStep } = this.props.templateStore;
        const steps = this.props.templateStore.getSteps("act");
        const rosterItems = this.props.rosterProductionStore.actProductions.filter(actProduction => !actProduction.deleted);
        const { isLoading } = this.state;

        let reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        let onDragEnd = result => {
            if (!result.destination) {
                return;
            }

            var cleanType = result.type.split("@")[0];

            if (cleanType === "roster") {
                var listIdx = _.toInteger(result.draggableId.split("@")[1]) - 1;
                const items = reorder(
                    this.props.rosterProductionStore.actProductions[listIdx].rosters,
                    result.source.index,
                    result.destination.index
                );
                this.props.rosterProductionStore.sortProductionRoster(items, listIdx);
            } else if (cleanType === "actRoster") {
                const items = reorder(this.props.rosterProductionStore.actProductions, result.source.index, result.destination.index);
                this.props.rosterProductionStore.sortActRosters(items);
            }

            this.setState({
                ...this.state
            });
        };

        let getRosterStatus = (status, profileId) => {
            if (!profileId) {
                return "Invited";
            } else {
                if (status) {
                    return "Accepted";
                } else {
                    return "Pending";
                }
            }
        };

        let renderSmallRosterList = (users, instrumentName, index) => {
            let renderContent = (user, index) => {
                let musicianName =
                    user.profile && user.profile.firstName
                        ? _.toString(user.profile.firstName) + " " + _.toString(user.profile.lastName)
                        : user.name
                            ? user.name
                            : _.toString(user.firstName) + " " + _.toString(user.lastName);
                return (
                    <ListItem button style={{ position: "relative" }}>
                        <Badge badgeContent={index} color="primary">
                            &nbsp;
                        </Badge>
                        <Avatar
                            alt={user.profile ? user.profile.firstName : user.firstName + " " + user.profile ? user.profile.lastName : user.lastName}
                            src={user.profile ? user.profile.profileImage.url : user.profileImage.url}
                        />
                        <ListItemText primary={musicianName} secondary={user.productionTypes} />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.handleRemoveProductionRoster(user, instrumentName)} color="primary">
                                <ClearIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            };

            let afterDrag = newRoster => {
                this.props.rosterProductionStore.sortProductionRoster(newRoster, instrumentName);
            };

            return (
                <Grid container spacing={8}>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={12}>
                        <List>
                            <DragDrop
                                droppableId={"roster@" + index}
                                type={"roster@" + index}
                                items={users}
                                renderContent={renderContent}
                                horizontal={false}
                                afterDrag={afterDrag}
                            />
                        </List>
                    </Grid>
                </Grid>
            );
        };

        let renderBigRosterList = (users, productionName, index) => {
            let renderContent = (user, index) => {
                let musicianName =
                    user.profile && user.profile.firstName
                        ? _.toString(user.profile.firstName) + " " + _.toString(user.profile.lastName)
                        : user.name
                            ? user.name
                            : _.toString(user.firstName) + " " + _.toString(user.lastName);
                return (
                    <BigRosterCard
                        key={index}
                        removeHandle={() => this.handleRemoveProductionRoster(user, productionName)}
                        status={getRosterStatus(user.accepted, user.profileId)}
                        name={musicianName}
                        profileId={user.profile ? user.profile.id : undefined}
                        index={index}
                    />
                );
            };

            return (
                <DragDrop droppableId={"roster@" + index} type={"roster@" + index} items={users} renderContent={renderContent} horizontal={true} />
            );
        };

        let renderSelectProduction = actProduction => {
            return (
                <ProductionType
                    onChange={value => {
                        this.props.rosterProductionStore.setProductionForActProduction(actProduction, value);
                        this.setState({
                            ...this.state
                        });
                    }}
                    value={actProduction.productionTypes}
                    placeholder="Select Production"
                    label="Production"
                />
            );
        };

        let getExcludedProfile = () => {
            let excludedProfile = [];
            this.props.rosterProductionStore.actProductions.map(actProduction => {
                actProduction.productionRosters.map(roster => {
                    excludedProfile.push(roster.profile);
                    return roster;
                });
                return actProduction;
            });

            return excludedProfile;
        };

        let renderProductionsGrid = (production, index) => {
            const { currentProfile } = this.props.profileStore;
            let currencySymbol = ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$");
            return (
                <Grid container id={`actProduction${index}`}>
                    <Grid item md={12} xs={12} sm={12}>
                        <Tooltip id="tooltip-top-end" title="Hold to reorder" placement="top">
                            <Card className="roster-wrapper">
                                <CardHeader
                                    title={production.name === "" ? `Production Crew ${index}` : production.name}
                                    action={[
                                        <IconButton key={0} style={{ cursor: "-webkit-grab" }}>
                                            <ReorderIcon />
                                        </IconButton>,
                                        <IconButton key={1} onClick={() => this.handleOpenDeleteConfirmationDialog(production)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    ]}
                                    style={{ borderBottom: "solid 1px #EDEDED" }}
                                />
                                <CardContent style={{ paddingTop: 24, paddingBottom: 24 }}>
                                    <Grid container alignItems="flex-end" spacing={16}>
                                        <Grid item xs={12} md={8}>
                                            {renderSelectProduction(production)}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id={`feeDistribution${index}`}
                                                fullWidth
                                                label="Gigs Fee"
                                                placeholder="Roster Commission"
                                                value={
                                                    !isNaN(production.dividendAmount)
                                                        ? production.dividendAmount === 0
                                                            ? undefined
                                                            : production.dividendAmount
                                                        : undefined
                                                }
                                                onChange={e => this.handlerosterCommissionChange(production, e.target.value)}
                                                InputProps={{
                                                    inputComponent: this.numberFormatPercentage,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            { currencySymbol }
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <ProfileSearchBox
                                                height="auto"
                                                placeholder="Search Production Crew"
                                                handleSearch={value => this.props.profileStore.searchProduction(value)}
                                                handleChange={item => this.handleAddProductionRoster(_.cloneDeep(item), index)}
                                                handleEmpty={value => this.handleOpenInvitationDialog(production, value)}
                                                exclude={getExcludedProfile()}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions style={{ borderTop: "solid 1px #EDEDED" }}>
                                    <Grid container spacing={16}>
                                        <Grid item md={12} xs={12} sm={12}>
                                            <Hidden mdUp>{renderSmallRosterList(production.productionRosters, production.name, index)}</Hidden>
                                            <Hidden smDown>{renderBigRosterList(production.productionRosters, production.name, index)}</Hidden>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Tooltip>
                    </Grid>
                </Grid>
            );
        };

        return (
            <form onSubmit={this.handleSubmitForm} className="form-relative">
                <Loading showed={isLoading} />
                <Grid container direction="column" spacing={8}>
                    <Grid item>
                        <Grid container justify="center">
                            <Grid item md={12} xs={12} sm={12}>
                                <Grid container spacing={8}>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Production Roster
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Hidden smDown>
                                            <Button className="pull-right" color="primary" variant="contained" onClick={this.handleAddNewProduction}>
                                                <AddIcon /> Add Production
                                            </Button>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                                <div className="clearfix">&nbsp;</div>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information">
                                            <Typography gutterBottom>Complete your Production Roster details.</Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                Use this section to create your sound and lighting crew roster. You can add as many production crew as
                                                you require. Input their production fee which will automatically be subtracted from the full gig fee.
                                                Search for existing Hot Giggity members or invite new production crew members to the platform using the
                                                production crew invite.
                                            </Typography>
                                            <Typography>
                                                <b>Tip:</b>
                                            </Typography>
                                            <Typography className="side-helper-text">
                                                You can drag and drop the order of the production crews to create a production crew order preference,
                                                or drag and drop the crew member / production company to reorder how the line up appears on screen.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Hidden mdUp>
                                        <Grid item xs={12} lg={9}>
                                            <Button className="pull-right" color="primary" variant="contained" onClick={this.handleAddNewProduction}>
                                                <AddIcon /> Add Production
                                            </Button>
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12} lg={9}>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <DragDrop
                                                items={rosterItems}
                                                droppableId="actRoster"
                                                type="actRoster"
                                                renderContent={renderProductionsGrid}
                                                horizontal={false}
                                                classes={{
                                                    wrapper: "drag-wrapper"
                                                }}
                                            />
                                        </DragDropContext>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.renderInvitationModal()}
                        {this.renderSnackBar()}
                        {this.renderDeleteConfirmationModal()}
                    </Grid>
                    <Grid item>
                        <div className="action">
                            <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                Back
                            </Button>
                            <Button variant="contained" color="primary" type="submit">
                                {editProfileActiveStep === steps.length - 1 ? "Finish" : "Save/Next"}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default ProductionRoster;
