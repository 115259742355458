import { observable, action, runInAction } from "mobx";
import agent from "../agent";
// import templateStore from "./templateStore";
import userStore from "./userStore";
import profileStore from "./profileStore";
import CacheHelper from "../helper/cache.js";
// import moment from "moment";

/*class Booking {
    @observable id = 0;
    @observable title = "";
    @observable agentId = 0;
    @observable actId = 0;
    @observable venueId = 0;
    @observable provider = "d";
    @observable venue = "d";
    @observable actName = "d";
    @observable venueContact = "d";
    @observable venueAddress = "d";
    @observable venuePhone = "";
    @observable setNumber = 1;
    @observable dress = "";
    @observable gigFee = undefined;
    @observable repeatType = "weekly";
    @observable repeatCount = 0;
    @observable agentCommissionFee = undefined;
    @observable agentCommissionAmount = 0;
    @observable requrements = "";
    @observable notes = "";
    @observable actFee = undefined;
    @observable commissionType = "$";
    @observable bookingDate = new Date("2018-03-21");
    @observable agentCommissionType = "$";
    @observable dateStart = new Date();
    @observable dateEnd = new Date();
    @observable timeStart = new Date();
    @observable timeEnd = new Date();
}*/

class NegotiateStore {
    // @observable booking = new Booking();
    @observable message = "";
    @observable id = undefined;
    @observable bookingId = undefined;
    @observable negotiateMessages = [];
    @observable isLoadingMessages = false;
    @observable isLoadingBooking = false;

    @action
    load(id) {
        this.isLoadingMessages = true;
        this.bookingId = id;
        // this.isLoadingBooking = true;
        // agent.Booking.getDetail(id)
        //     .then(
        //         action(booking => {
        //             this.booking = booking;
        //             return this.booking;
        //         })
        //     )
        //     .finally(
        //         action(() => {
        //             this.inProgress = false;
        //             this.isLoadingBooking = false;
        //             // templateStore.hideLoadingScreen();
        //         })
        //     );

        return agent.NegotiateMessage.loadByBookingId(id)
            .then(
                action(async response => {
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].profileImageUrl !== null)
                            response[i].profileImageUrl = await CacheHelper.getImgLink(response[i].profileImageUrl)
                    }
                    runInAction(() => {
                        this.negotiateMessages = response;
                    })

                    return this.negotiateMessages;
                })
                // action(negotiateMessages => {
                //     this.negotiateMessages = negotiateMessages;
                //     return this.negotiateMessages;
                // })
            )
            .finally(
                action(() => {
                    this.isLoadingMessages = false;
                    // templateStore.hideLoadingScreen();
                })
            );
    }

    @action
    setBody(body) {
        this.message = body;
    }

    @action
    addMessage(negotiateMessage) {
        this.negotiateMessages.push(negotiateMessage);
    }

    @action
    setMessages(messages){
        this.negotiateMessages = messages
    }

    @action
    deleteNegotiateMessage(id){
        return agent.NegotiateMessage.deleteNegotiateMessage(id).then(
            action(response => {
                return response;
            })
        );
    }

    
    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const profile = profileStore.profile;

        const negotiateMessage = {
            id: 0,
            createdById: user.id,
            profileId: profile.id,
            message: this.message,
            bookingRequestId: this.bookingId
        };

        return (this.id ? this.update(negotiateMessage) : this.create(negotiateMessage))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    create(data) {
        data.id = 0;
        return agent.NegotiateMessage.create(data).then(
            action(negotiateMessage => {
                // negotiateMessage.profile = profileStore.profile;
                // this.addMessage(negotiateMessage);
                this.message = "";
                return negotiateMessage;
            })
        );
    }

    @action
    update(data) {
        return agent.NegotiateMessage.update(data).then(
            action(region => {
                this.message = "";
                return region;
            })
        );
    }
}

export default new NegotiateStore();
