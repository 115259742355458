import { Storage, Cache, Auth } from "aws-amplify";
import moment from "moment";

class CacheHelper {
    getImgLink = async key => {
        if (key === undefined || key === null) return ""
        var lc = key.toLowerCase();
        var isMatch = lc.substr(0, 8) == 'https://' || lc.substr(0, 7) == 'http://';

        var isLegacyLink = lc.indexOf("image.hubdit.com") > 0
        
        if (isMatch) {
            if(isLegacyLink){
                return key.replace("image.hubdit.com", "image.hotgiggity.com");
            }else {
                return key
            }
        }
        const cachedImage = Cache.getItem(key)
        let currentUser = await this.checkCurrentAuthenticatedUser();

        if (currentUser) {
            const currentSession = currentUser.signInUserSession;
            // for checking current session token
            // request time also available on accessToken.payload.auth_time
            const tokenIsExpire = moment(new Date()).unix() > currentSession.accessToken?.payload?.exp;
            if (!tokenIsExpire && cachedImage) {
                return cachedImage
            }
        }

        const oneWeekInSec = 604800
        const fourtyFiveMinInMs = 2700000
        const url = await Storage.get(`${key}`, { expires: oneWeekInSec })

        const expiration = new Date().getTime() + fourtyFiveMinInMs;
        Cache.setItem(key, url, { expires: expiration })
        return url
    }
    checkCurrentAuthenticatedUser = () => {
        Auth.currentAuthenticatedUser().then((res) => {
            return res
        })
            .catch(err => {
                console.log(err)
                return null
            })
    }
    setUser = async user => {
        Cache.setItem("User", user)
    }
    getUser = () => {
        return Cache.getItem("User")
    }
    setCurrentProfile = async profile => {
        Cache.setItem("CurrentProfile", profile)
    }
    getCurrentProfile = () => {
        return Cache.getItem("CurrentProfile")
    }
    clearImageLinkCache = () => {
        Cache.clear();
    }

    setLicense = async license => {
        Cache.setItem("license", license)
    }
    getLicense = () => {
        return Cache.getItem("license")
    }
      
}

export default new CacheHelper();