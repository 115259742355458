import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Grid,
    Tooltip,
    Divider,
    Button,
    Chip,
    DialogContent,
    DialogActions,
    Typography
} from "@material-ui/core";
import moment from "moment";

import templateStore from "../../stores/templateStore";
import ConfirmationModal from "../modal/ConfirmationModal";
import PromotionPreview from "./PromotionPreview";
import ModalContainer from "../modal/ModalContainer";

import "./PromotionCard.css";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

const style = {
    promotionStatus: {
        minWidth: 50,
        color: "#ffffff"
    },
    status: {
        draft: {
            backgroundColor: "#757575"
        },
        pending: {
            backgroundColor: "#1565c0"
        },
        published: {
            backgroundColor: "#43a047"
        },
        completed: {
            backgroundColor: "#bdbdbd"
        }
    },
    promotionHeader: {
        height: "60px"
    },
    promotionImage: {
        height: "250px",
        width: "100%"
    },
    buttonContainer: {
        height: "52px",
        width: "100%"
    },
    previewButton: {
        marginLeft: "auto",
        marginRight: 0
    },
    promotionTitleTooltipContainer: {
        width: "300px",
        padding: 8
    },
    promotionTitleTooltip: {
        fontSize: "16px",
        color: "#ffffff"
    }
};

@withRouter
@inject("promotionStore", "templateStore")
@observer
class PromotionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeletePromotionConfirmationModal: false,
            showPreviewModal: false
        };
    }

    getPromotionStatus = promotion => {
        if (moment().endOf("day") > moment(promotion.endDate).endOf("day") && promotion.published) {
            return "Completed";
        } else if (promotion.published) {
            return "Published";
        } else if (promotion.isDraft) {
            return "Draft";
        } else {
            return "Pending";
        }
    };

    getPromotionDates = promotion => {
        let startdate = new Date(promotion.startDate); 
        let startdate_utc =  new Date(startdate.getTime() + startdate.getTimezoneOffset() * 60000);

        let endDate = new Date(promotion.endDate); 
        let endDate_utc =  new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);

        if (moment(promotion.startDate).format("DD-MM-YYYY") === moment(promotion.endDate).format("DD-MM-YYYY")) {
            return moment(startdate_utc).format("DD-MM-YYYY");
        } else {
            return `${moment(startdate_utc).format("DD-MM-YYYY")} - ${moment(endDate_utc).format("DD-MM-YYYY")}`;
            // return `${moment(promotion.startDate).format("DD-MM-YYYY")} - ${moment(promotion.endDate).format("DD-MM-YYYY")}`;
        }
    };

    renderVenue = venuePromotion => {
        return (
            <Fragment>
                <div style={{ fontSize: "16px", marginBottom: 8 }}>{venuePromotion.venue.profileName}</div>
            </Fragment>
        );
    };

    getPromotionTitle = promotion => {
        if (promotion.title && promotion.title.length > 30) {
            return (
                <Tooltip
                    title={
                        <div style={style.promotionTitleTooltipContainer}>
                            <Typography paragraph style={style.promotionTitleTooltip}>
                                {promotion.title}
                            </Typography>
                        </div>
                    }
                    placement="bottom-start"
                    classes={{
                        tooltip: "promotion-title-tooltip"
                    }}
                    style={{
                        cursor: "pointer"
                    }}
                >
                    <span>{promotion.title.substr(0, 27) + "..."}</span>
                </Tooltip>
            );
        }
        return promotion.title;
    };

    getVenuesName = promotion => {
        var venuesName = "";
        // for (var i = 0; i < promotion.promotionVenues.length; i++) {
        //     if (i < 2) {
        //         venuesName += promotion.promotionVenues[0].venue.profileName + ", ";
        //     }
        // }
        // venuesName = venuesName.substr(0, venuesName.length - 2);
        // if (promotion.promotionVenues.length > 2) {
        //     venuesName += "...";
        // }
        if (promotion.promotionVenues.length > 0) {
            venuesName = promotion.promotionVenues.map(p => p.venue?.profileName).join(", ")
        }
        return venuesName;
    };

    handleShowDeletePromotionConfirmationModal = () => {
        this.setState({
            showDeletePromotionConfirmationModal: true
        });
    };

    handleHideDeletePromotionConfirmationModal = () => {
        this.setState({
            showDeletePromotionConfirmationModal: false
        });
    };

    handleShowPreviewModal = () => {
        this.setState({
            showPreviewModal: true
        });
    };

    handleHidePreviewModal = () => {
        this.setState({
            showPreviewModal: false
        });
    };

    handleEditPromotion = promotion => () => {
        if (templateStore && templateStore.history) {
            templateStore.history.push(`/promotion/${promotion.id}/edit`);
        }
    };

    handleRemovePromotion = () => {
        const { promotion } = this.props;
        this.props.templateStore.showLoadingScreen();
        this.props.promotionStore.removePromotion(promotion.id).then(
            response => {
                this.props.templateStore.openSnackbar(`Promotion ${response.title} has been removed.`);
                this.props.templateStore.hideLoadingScreen();
                this.handleHideDeletePromotionConfirmationModal();
            },
            () => {
                this.props.templateStore.openSnackbar(`Promotion ${promotion.title} can not be removed.`);
                this.props.templateStore.hideLoadingScreen();
                this.handleHideDeletePromotionConfirmationModal();
            }
        );
    };

    handleReRunPromotion = () => {
        const { promotion } = this.props;

        this.props.promotionStore.rerunPromotion(promotion).then(() => {
            this.props.history.push("/promotion/new");
        });
    };

    render() {
        const { promotion } = this.props;
        const { showDeletePromotionConfirmationModal, showPreviewModal } = this.state;

        return promotion ? (
            <Fragment>
                <Card>
                    <CardHeader
                        title={this.getPromotionTitle(promotion)}
                        subheader={this.getPromotionDates(promotion)}
                        action={
                            <Chip
                                label={this.getPromotionStatus(promotion)}
                                style={{ ...style.promotionStatus, ...style.status[this.getPromotionStatus(promotion).toLowerCase()] }}
                            />
                        }
                        style={style.promotionHeader}
                    />
                    <Divider />
                    <CardMedia image={promotion.imageUrl} style={style.promotionImage} />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} style={{ height: "40px" }}>
                                <Grid container spacing={16} direction="row" justify="flex-end" alignItems="center">
                                    <Grid item xs={6}>
                                        <i className="fa fa-building" /> &nbsp;
                                        <Tooltip
                                            title={promotion.promotionVenues.map(venue => this.renderVenue(venue))}
                                            placement="bottom-start"
                                            classes={{
                                                tooltip: "promotion-venues-tooltip"
                                            }}
                                            style={{
                                                cursor: "pointer"
                                            }}
                                        >
                                            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '75%', display: 'inline-block', verticalAlign: 'middle' }}>{this.getVenuesName(promotion)}</span>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <i className="fa fa-eye" /> {promotion.viewCount}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <i className="fa fa-ticket-alt" />{" "}
                                        {promotion.redeemLimit > 0 ? `${promotion.redeemCount} / ${promotion.redeemLimit}` : promotion.redeemCount}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ paddingTop: "16px", minHeight: "150px", maxHeight: "150px", overflow: "hidden" }}>
                                    <div dangerouslySetInnerHTML={{ __html: promotion.description }} />
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions style={style.buttonContainer}>
                        {moment(promotion.endDate).endOf("day") < moment().endOf("day") && promotion.canRerun  ? (
                            <Button variant="text" color="primary" onClick={this.handleReRunPromotion}>
                                Re-Run
                            </Button>
                        ) : null}
                        {promotion.canEdit ? (
                            <Button disabled={!promotion.canEdit} variant="text" color="primary" onClick={this.handleEditPromotion(promotion)}>
                                Edit
                            </Button>
                        ) : null}
                        {promotion.canRemove ? (
                            <Button
                                disabled={!promotion.canRemove}
                                variant="text"
                                color="primary"
                                onClick={this.handleShowDeletePromotionConfirmationModal}
                            >
                                Remove
                            </Button>
                        ) : null}
                        {promotion.canView ? (
                            <Button
                                disabled={!promotion.canView}
                                className="pull-right"
                                style={style.previewButton}
                                variant="text"
                                color="primary"
                                onClick={this.handleShowPreviewModal}
                            >
                                Preview
                            </Button>
                        ) : null}
                    </CardActions>
                </Card>
                <ConfirmationModal
                    open={showDeletePromotionConfirmationModal}
                    title="Remove Promotions"
                    message={`Are you sure to remove ${promotion.title}?`}
                    closeHandler={this.handleHideDeletePromotionConfirmationModal}
                    confirmationHandler={this.handleRemovePromotion}
                    declineHandler={this.handleHideDeletePromotionConfirmationModal}
                    confirmationLabel="Remove"
                />
                <ModalContainer open={showPreviewModal} onClose={this.handleHidePreviewModal} maxWidth="md">
                    <DialogContent>
                        <PromotionPreview promotion={promotion} />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button classes={{ root: "pull-right" }} onClick={this.handleHidePreviewModal}>
                            Close
                        </Button>
                    </DialogActions>
                </ModalContainer>
            </Fragment>
        ) : null;
    }
}

export default PromotionCard;
