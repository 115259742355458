import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";

import { Grid, InputLabel, Dialog, AppBar, Toolbar, Typography, Button, FormControlLabel, Switch } from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

import RichTextEditor from "../../richTextEditor/RichTextEditor";

import { BookingStatusType, ProfileType } from "../../../types/enum";

import moment from "moment";

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore")
@observer
class BookingRequirementDetails extends Component {
    state = {
        contractFullState: false
    };

    handleRequirementsChange = e => {
        this.props.bookingRequestStore.setBooking("requirements", e);
    };

    handleOpenContract = () => {
        this.setState({
            ...this.state,
            contractFullState: true
        });
    };

    handleCloseContract = () => {
        this.setState({
            ...this.state,
            contractFullState: false
        });
    };

    handleSaveRequirementsTemplateChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("saveRequirementsAsTemplate", checked);
        this.setState({
            ...this.state
        });
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    render() {
        const { booking } = this.props.bookingRequestStore;
        const { requirements, status, createdById, dateStart, saveRequirementsAsTemplate } = booking;

        const { currentProfile } = this.props.profileStore;
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        const isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal;
        const {isProductionChange, productionIsChangging, readOnly} = this.props
        let confirmedEditable =
            status !== BookingStatusType.Cancelled.ordinal &&
            status !== BookingStatusType.Completed.ordinal &&
            moment(dateStart).endOf("day") >= moment() &&
            (!createdById || createdById === currentProfile?.id || isStaff);

        return (
            <Fragment>
                <Grid container spacing={8} className="">
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            className=""
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <InputLabel>Production Requirements</InputLabel>
                            {!readOnly && 
                                <Button color="primary" size="small" onClick={this.handleOpenContract} className="button-small fullscreen-bio">
                                    <FullscreenIcon />
                                    <Typography>Fullscreen</Typography>
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            {readOnly ? 
                                <Typography style={{border:"2px solid #E0E0E0", padding:5, borderRadius:5}}><div dangerouslySetInnerHTML={{__html: requirements}} style={{minHeight:50}}></div> </Typography> :
                                <RichTextEditor
                                    id="booking-requirement-rte"
                                    value={requirements}
                                    onChange={this.handleRequirementsChange}
                                    readOnly={!confirmedEditable}
                                    isVenueChange={isProductionChange}
                                    resetEditor={this.state.contractFullState} onChangeEditor={productionIsChangging}
                                />
                            }
                        </Grid>
                    </Grid>
                    {(isStaff || isAgent) && (
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={saveRequirementsAsTemplate}
                                    onChange={this.handleSaveRequirementsTemplateChange}
                                    disabled={!confirmedEditable || this.props.readOnly}
                                />
                            }
                            label="Save as Production Requirements Template"
                        />
                    )}
                </Grid>
                <Dialog fullScreen open={this.state.contractFullState} onClose={this.handleCloseContract} transition={this.transition}>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                Production Requirements
                            </Typography>
                            <Button color="inherit" onClick={this.handleCloseContract}>
                                Exit Fullscreen
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="fullscreen-rich-text">
                        {readOnly ? 
                            <Typography ><div dangerouslySetInnerHTML={{__html: requirements}} ></div> </Typography> :   
                            <RichTextEditor value={requirements} onChange={this.handleRequirementsChange} readOnly={!confirmedEditable} resetEditor={isProductionChange} onChangeEditor={productionIsChangging} />
                        }
                    </div>
                </Dialog>
            </Fragment>
        );
    }
}

export default BookingRequirementDetails;
