import { observable, action } from "mobx";
import agent from "../agent";
import _ from "lodash";
import profileStore from "./profileStore";
import { ProfileType } from "../types/enum";

class AgentMusicianStore {
    @observable invitedActId = undefined;
    @observable invitedAct = undefined;
    @observable actInvitationMessage = "";

    @observable invitedMusicianId = undefined;
    @observable invitedMusician = undefined;
    @observable musicianInvitationMessage = "";

    @observable pendingAgentMusicianList = [];
    @observable agentMusicianList = [];

    @observable actList = [];

    @action
    getLineUpCounts(instrument) {
        if (!instrument || instrument === 0) {
            return "";
        }

        let lineUpCounts = [
            { name: "Solo", instrument: 1 },
            { name: "Duo", instrument: 2 },
            { name: "Trio", instrument: 3 },
            { name: "Quartet / 4 Piece", instrument: 4 },
            { name: "Quintet / 5 Piece", instrument: 5 },
            { name: "Sextet / 6 Piece", instrument: 6 }
        ];

        for (var i = 0; i < 93; i++) {
            lineUpCounts.push({ name: i + 7 + " Piece", instrument: i + 7 });
        }

        return _.filter(lineUpCounts, ["instrument", instrument])[0].name;
    }

    @action
    getMyMusicianRoster() {
        const { profile } = profileStore;

        this.getAgentMusicianRoster(profile.id);
    }

    @action
    getMyMusicianAgents() {
        const { profile } = profileStore;

        this.getMusicianAgents(profile.id);
    }

    @action
    getMyMusicianActRoster() {
        const { profile } = profileStore;

        return this.getAgentMusicianActRoster(profile.id);
    }

    @action
    getAgentMusicianRequest() {
        agent.ProfileRelationship.getAgentMusicianRequest().then(
            action(response => {
                this.pendingAgentMusicianList = response.map(data => {
                    profileStore.mapAdditionalProfileData(data.primaryProfile);
                    profileStore.mapAdditionalProfileData(data.secondaryProfile);

                    if (data.primaryProfile.type === ProfileType.Musician.ordinal && data.primaryProfile.myActs) {
                        data.primaryProfile.myActs = data.primaryProfile.myActs.map(act => {
                            profileStore.mapAdditionalProfileData(act);
                            return act;
                        });
                    } else if (data.secondaryProfile.type === ProfileType.Musician.ordinal && data.secondaryProfile.myActs) {
                        data.secondaryProfile.myActs = data.secondaryProfile.myActs.map(act => {
                            profileStore.mapAdditionalProfileData(act);
                            return act;
                        });
                    }

                    data.accepted = false;
                    return data;
                });
            })
        );
    }

    @action
    getMusicianAgentRequest() {
        agent.ProfileRelationship.getMusicianAgentRequest().then(
            action(response => {
                this.pendingAgentMusicianList = response.map(data => {
                    profileStore.mapAdditionalProfileData(data.primaryProfile);
                    profileStore.mapAdditionalProfileData(data.secondaryProfile);
                    data.accepted = false;
                    return data;
                });
            })
        );
    }

    @action
    getAgentMusicianRoster(agentId) {
        agent.AgentMusician.getAgentMusicianRoster(agentId).then(
            action(response => {
                this.agentMusicianList = response.map(data => {
                    profileStore.mapAdditionalProfileData(data.agent);
                    profileStore.mapAdditionalProfileData(data.musician);
                    if (data.musician.myActs) {
                        data.musician.myActs = data.musician.myActs.map(act => {
                            profileStore.mapAdditionalProfileData(act);
                            return act;
                        });
                    }
                    data.accepted = true;
                    return data;
                });
            })
        );
    }

    @action
    getActAgents(actId) {
        agent.AgentAct.getActAgents(actId).then(
            action(response => {
                this.agentMusicianList = response.map(data => {
                    profileStore.mapAdditionalProfileData(data.agent);
                    profileStore.mapAdditionalProfileData(data.act);
                    data.accepted = true;
                    return data;
                });
            })
        );
    }

    @action
    getMusicianAgents(musicianId) {
        agent.AgentMusician.getMusicianAgents(musicianId).then(
            action(response => {
                this.agentMusicianList = response.map(data => {
                    profileStore.mapAdditionalProfileData(data.agent);
                    profileStore.mapAdditionalProfileData(data.musician);
                    data.accepted = true;
                    return data;
                });
            })
        );
    }

    @action
    getAgentMusicianActRoster(agentId) {
        return agent.AgentMusician.getAgentMusicianActRoster(agentId).then(
            action(response => {
                this.actList = response.map(data => {
                    profileStore.mapAdditionalProfileData(data);

                    return data;
                });
            })
        );
    }

    @action
    setInvitedAct(value) {
        profileStore.mapAdditionalProfileData(value.myMusician);
        if (value.myMusician && value.myMusician.myActs) {
            value.myMusician.myActs = value.myMusician.myActs.map(data => {
                profileStore.mapAdditionalProfileData(data);
                return data;
            });
        }
        this.invitedMusician = value.myMusician;
        this.invitedMusicianId = value.myMusician.id;
    }

    @action
    setInvitedMusician(value) {
        this.invitedMusician = value;
    }

    @action
    setInvitedMusicianId(value) {
        this.invitedMusicianId = value;
    }

    @action
    setMusicianInvitationMessage(value) {
        this.musicianInvitationMessage = value;
    }

    @action
    clearInvitedMusician() {
        this.invitedMusician = undefined;
        this.invitedMusicianId = undefined;
        this.musicianInvitationMessage = "";
    }

    @action
    acceptInvitation(agentActRosterId) {
        if (agentActRosterId) {
            return agent.ProfileRelationship.accept(agentActRosterId).then(response => {
                this.getMusicianAgentRequest();
                this.getMyMusicianAgents();
                return response;
            });
        }
    }

    @action
    declineInvitation(agentActRosterId) {
        if (agentActRosterId) {
            return agent.ProfileRelationship.decline(agentActRosterId).then(
                action(response => {
                    _.remove(this.pendingAgentMusicianList, ["id", response.id]);
                    return response;
                })
            );
        }
    }

    @action
    inviteAgentMusician() {
        const { profile } = profileStore;

        if (this.invitedMusicianId) {
            const requestDetail = {
                primaryProfileId: profile.id,
                secondaryProfileId: this.invitedMusicianId,
                requestMessage: this.musicianInvitationMessage,
                type: 0,
                createdById: profile.id
            };

            return agent.ProfileRelationship.invite(requestDetail).then(
                action(response => {
                    profileStore.mapAdditionalProfileData(response.primaryProfile);
                    profileStore.mapAdditionalProfileData(response.secondaryProfile);
                    this.pendingAgentMusicianList.push(response);
                    return response;
                })
            );
        }
    }
}

export default new AgentMusicianStore();
