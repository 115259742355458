import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Prompt } from "react-router-dom";
import { LineupStatusType, ProfileType, BookingStatusType, BookingType } from "../../types/enum";

// import NumberFormat from "react-number-format";
import {
    Grid,
    Typography,
    Button,
    Card,
    Avatar,
    IconButton,
    CardMedia,
    CardContent,
    CardActions,
    Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Checkbox,
    TextField,
    InputAdornment,
    Tooltip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import "./BookingLineup.css";
import LineupMessage from "./Lineup/LineupMessage";

import moment from "moment";
import ResponsiveTable from "../table/ResponsiveTable";
import NewLineupModal from "./Lineup/NewLineupModal";
import RedirectLineUpModal from "./Lineup/redirectLineUpModal"
import ConfirmationModal from "../modal/ConfirmationModal";
import image from "../../helper/image";
import Loading from "../loadingSpinner/Loading";

const SHOW_PRODUCTION_CREW = process.env.REACT_APP_SHOW_PRODUCTION_CREW === "true";

@inject("lineupStore", "bookingRequestStore", "templateStore", "profileStore")
@observer
class BookingLineup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelBookingModalState: false,
            declineBookingModalState: false,
            showNewLineupModal: false,
            showRemoveLineupConfirmation: false,
            deletedLineupIndex: undefined,
            showRedirectModal: false,
            unsaveFees: false,
            showRedirectPromote: false
        };
    }

    componentDidMount() {
        // this.props.templateStore.showLoadingScreen();
        Promise.all([this.props.lineupStore.load(this.props.bookingId), this.props.bookingRequestStore.load(this.props.bookingId)]).then(res => {
        });

    }

    handleExpandClick = instrument => {
        this.props.lineupStore.setExpand(instrument);
    };

    handleSelectPlayer = (instrument, player) => {
        this.props.lineupStore.setPlayer(instrument, player);
    };

    handleSetManualRosterFee = (fee, index) => {
        if (!fee) {
            fee = 0;
        }
        fee = fee == 0 ? 0 : fee.replace(/[^0-9.,]+/g,'').replace(/[,]/,'.')
        this.props.lineupStore.setManualLineupFee(fee, index);
        this.setState({
            ...this.state
        });
    };

    handleSubmitForm = e => {
        const { booking } = this.props.bookingRequestStore;

        this.setState({ ...this.state, unsaveFees: false });
        const { type, isBookingCreator} = this.props.bookingRequestStore.booking;
        e.preventDefault();
        this.props.templateStore.showLoadingScreen();
        this.props.templateStore.openLineupBlock();
        // submit
        this.props.lineupStore.submit().then(
            () => {
                this.props.templateStore.openSnackbar("Lineup Saved");
                this.props.templateStore.hideLoadingScreen();
                if(type == BookingType.Adhoc.ordinal && isBookingCreator){
                    this.showRedirectLineUpModal()
                }else{
                    this.showRedirectPromoteModal()
                }
                
                // this.props.templateStore.setEditMusicianBookingActiveStep(3);
            },
            err => {
                this.props.templateStore.openSnackbar(err);
            }
        );
    };

    statusText = (lineup, id, oldLineup) => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        var value = lineup.status
        var isInvited = id ? true : false;
        switch (value) {
            case LineupStatusType.New.ordinal: {
                if (isInvited && oldLineup) {
                    return "Invited";
                }
                else {
                    if(currentProfile?.id === lineup.profileId){
                        return "Accepted";
                    }else{
                        return "Not Yet Invited";
                    }
                  
                }
            }
            case LineupStatusType.Declined.ordinal:
                return "Declined";
            case LineupStatusType.Hold.ordinal:
                return "Hold";
            case LineupStatusType.Accepted.ordinal:
                return "Accepted";
            case LineupStatusType.Confirmed.ordinal:
                return "Confirmed";
            case LineupStatusType.Cancelled.ordinal:
            case LineupStatusType.Removed.ordinal:
                return "Cancelled";
            default:
                {
                    if (isInvited) {
                        return "Invited";
                    }
                    else {
                        return "Not Yet Invited";
                    }
                }
        }
    };

    isLineupSaveButtonDisabled = () => {
        const { actFee } = this.props.bookingRequestStore.booking;
        const { lineups } = this.props.lineupStore;

        let sumFee = 0;

        lineups.forEach(lineup => {
            if (lineup.status !== LineupStatusType.Removed.ordinal) {
                sumFee += Number(lineup.manualFee) === 0 ? Number(lineup.manualFee) : Number(lineup.manualFee) ? Number(lineup.manualFee) : Number(lineup.fee);
            }
        });

        if (sumFee > Number(actFee)) {
            return true;
        } else {
            return false;
        }
    };

    changeLineupEditMode = index => {
        this.props.lineupStore.toggleLineupEditMode(index);
        this.setState({ ...this.state});
    };

    doneLineupFeeEdit = index => {
        this.props.templateStore.closeLineupBlock();
        this.props.lineupStore.doneEditLineupFeeChanges(index);
        this.setState({ ...this.state, unsaveFees: true });
    };

    doneLineupFeeEditCompleteGigs = index => {
        this.props.templateStore.closeLineupBlock();
        this.props.lineupStore.doneEditLineupFeeChanges(index);
       
        this.setState({ ...this.state, unsaveFees: true });
        if(this.props.lineupStore.lastEditedLineup && this.props.lineupStore.lastEditedLineup.feeEdited){
            this.setState({ ...this.state, unsaveFees: false });
            this.props.templateStore.showLoadingScreen();
            this.props.templateStore.openLineupBlock();
            // submit
            this.props.lineupStore.submitFeeChanged(this.props.lineupStore.lastEditedLineup).then(
                () => {
                    this.props.templateStore.openSnackbar("Update Fee Saved");
                    this.props.templateStore.hideLoadingScreen();
                },
            ).catch(
                (err) => {
                    this.props.templateStore.openSnackbar(err);
                    this.props.templateStore.hideLoadingScreen();
                }
            )
            
        }
    };
    
    getLineupExtraGigFee = () => {
        const { lineups } = this.props.lineupStore;

        const { booking } = this.props.bookingRequestStore;

        return booking.extraGigFeeApprovalById && booking.extraGigFee && booking.extraGigFee > 0
            ? parseFloat(booking.extraGigFee / lineups.length).toFixed()
            : 0;
    };

    getLineupColumns = () => {
        const { booking } = this.props.bookingRequestStore;
        let lineupColumns = [
            { key: "instruments", label: "Instruments", width: "30%" },
            { key: "player", label: "Player Name", width: "20%" },
            { key: "fee", label: booking.extraGigFee && booking.extraGigFee > 0 ? `Fee (+ Extra Gig Fee)` : "Fee", width: "20%" },
            { key: "editFeeButton", label: "", width: "30%" }
        ];

        return lineupColumns;
    };

    getLineupRows = () => {
        const { lineups } = this.props.lineupStore;
        const { currentProfile } = this.props.profileStore;
        let rows = [];
        const { status } = this.props.bookingRequestStore.booking;
        const { booking } = this.props.bookingRequestStore;
        let totalFee = 0;

        lineups.forEach(lineup => {
            if (lineup.status !== LineupStatusType.Removed.ordinal) {
                totalFee += lineup.manualFee === 0 ? lineup.manualFee : lineup.manualFee ? Number(lineup.manualFee) : Number(lineup.fee);
            }
        });

        lineups.forEach((lineup, index) => {
            const playerExtraGigFee = this.getLineupExtraGigFee(index);
            const playerExtraGigFeeString =
                playerExtraGigFee > 0
                    ? `    (+ ${currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$"} ${playerExtraGigFee})`
                    : "";
            if (lineup.status !== LineupStatusType.Removed.ordinal) {
                rows.push({
                    instruments: lineup.name,
                    player: lineup.profile ? `${lineup.profile.firstName} ${lineup.profile.lastName}` : "",
                    fee: lineup.editMode ? (
                        <TextField
                            value={lineup.manualFee === 0 ? lineup.manualFee : lineup.manualFee ? (lineup.manualFee) : (lineup.fee)}
                            fullWidth
                            onChange={e => this.handleSetManualRosterFee(e.target.value, index)}
                            type="string"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {`${currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$"}`}
                                    </InputAdornment>
                                )
                            }}
                        />
                    ) : (
                        `${currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$"} ${parseFloat(lineup.fee).toFixed(2)
                        }${playerExtraGigFeeString}`
                    ),
                    editFeeButton: lineup.editMode ? (
                        <Fragment>
                            <Button style={{ marginRight: "8px" }} variant="raised" color="primary" onClick={ (status === BookingStatusType.Completed.ordinal) ? () => this.doneLineupFeeEditCompleteGigs(index) : () => this.doneLineupFeeEdit(index)}>
                                Done
                            </Button>
                            <Button variant="outlined" onClick={() => this.changeLineupEditMode(index)}>
                                Cancel
                            </Button>
                        </Fragment>
                    ) : (
                        <Button variant="raised" color="primary" onClick={() => this.changeLineupEditMode(index)}>
                            Edit
                        </Button>
                    )
                });
            }
        });

        rows.unshift({
            instruments: "-",
            player: `${currentProfile?.firstName}  ${currentProfile?.lastName} (Act Admin)`,
            fee: `${currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$"} ${isNaN(totalFee) ? booking.actFee : parseFloat(booking.actFee - totalFee).toFixed(2)}`
        });

        return rows;
    };

    getLineupFooterRows = () => {
        const { booking } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        let extraGigFee =
            booking.extraGigFee && booking.extraGigFee > 0
                ? `    (+ ${currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$"} ${booking.extraGigFee})`
                : "";
        return {
            player: "Gig Fee",
            fee: `${currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$"} ${booking.actFee}${extraGigFee}`,
            style: {
                backgroundColor: "#EEEEEE",
                fontWeight: "bold"
            }
        };
    };

    handleRemoveLineup = index => {
        if (index || index === 0) {
            this.props.lineupStore.removeLineup(index);
            this.hideRemoveLineupConfirmation();
        }
    };

    renderPlayerLineup = (lineup, index) => {
        const { isAdmin, status, dateStart, lineups: bookingLineups } = this.props.bookingRequestStore.booking;
        const { currentProfile } = this.props.profileStore;
        const { lineups } = this.props.lineupStore;
        const lineupsChanged = bookingLineups?.find(x => x.profileId === lineup.profileId);        
        return (
            <Grid item xs={12} lg={3} key={lineup.name}>
                <Card className="lineup-card" elevation={1}>
                    <CardMedia
                        image={lineup && lineup.profile ? image.getBackgroundImage(lineup.profile) : ""}
                        title="Background Image"
                        className="card-avatar"
                    >
                        {lineup &&
                            lineup.profile && (
                                <Avatar className="avatar" aria-label={lineup.profile.firstName} src={image.getProfileImage(lineup.profile)} />
                            )}
                    </CardMedia>
                    <CardContent className="detail">
                        {lineup &&
                            lineup.profile && (
                                <Typography variant="title" component="h2">
                                    {lineup.profile.firstName} {lineup.profile.lastName}
                                </Typography>
                            )}
                        {lineup && <Typography variant="caption">{lineup.name}</Typography>}
                        {isAdmin &&
                            lineup && (
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container spacing={16} justify="flex-start">
                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                Fee Amount
                                            </Grid>
                                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                                {`${currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$"
                                                    } ${parseFloat(lineup.fee) + parseFloat(this.getLineupExtraGigFee(index))}`}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={16}>
                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                Status
                                            </Grid>
                                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                                {this.statusText(lineup, lineup.id, lineupsChanged)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                    </CardContent>
                    {isAdmin &&
                        lineup && (
                            <CardActions disableActionSpacing className="action">
                                <IconButton
                                    className={lineup.expanded ? "expanderOpen" : "expander"}
                                    onClick={this.handleExpandClick.bind(this, lineup)}
                                    aria-expanded={lineup.expanded}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                                
                                <IconButton disabled={status === BookingStatusType.Cancelled.ordinal ||
                                                                status === BookingStatusType.New.ordinal} style={{ marginLeft: "auto", marginRight: 0 }} onClick={() => this.showRemoveLineupConfirmation(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </CardActions>
                        )}
                    {isAdmin &&
                        lineup &&
                        lineup.actRoster &&
                        lineup.actRoster.rosters && (
                            <Collapse in={lineup.expanded} timeout="auto">
                                <CardContent className="list">
                                    <Typography gutterBottom>Select player:</Typography>
                                    <List>
                                        {lineup &&
                                            lineup.actRoster &&
                                            lineup.actRoster.rosters.map(
                                                p =>
                                                    p && p.profile ? (
                                                        <ListItem
                                                            key={p.id}
                                                            dense
                                                            button
                                                            className="player-listitem"
                                                            onClick={this.handleSelectPlayer.bind(this, lineup, p)}
                                                            disabled={
                                                                status === BookingStatusType.Cancelled.ordinal ||
                                                                status === BookingStatusType.New.ordinal ||
                                                                !p.available ||
                                                                lineups.filter(x => x.profileId === p.profile.id).length > 0
                                                            }
                                                        >
                                                            {p.profile && (
                                                                <Avatar aria-label={p.profile.firstName} src={image.getProfileImage(p.profile)} />
                                                            )}

                                                            {p.profile && (
                                                                <ListItemText
                                                                    primary={`${p.profile.firstName} ${p.profile.lastName}`}
                                                                    secondary={p.available ? "Available" : "Unavailable"}
                                                                />
                                                            )}
                                                            <ListItemSecondaryAction>
                                                                <Checkbox
                                                                    onChange={this.handleSelectPlayer.bind(this, lineup, p)}
                                                                    checked={p.profileId === lineup.profileId}
                                                                    disabled={
                                                                        status === BookingStatusType.Cancelled.ordinal ||
                                                                        status === BookingStatusType.New.ordinal ||
                                                                        !p.available ||
                                                                        lineups.filter(x => x.profileId === p.profile.id).length > 0
                                                                    }
                                                                />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    ) : (
                                                        ""
                                                    )
                                            )}
                                    </List>
                                </CardContent>
                            </Collapse>
                        )}
                </Card>
            </Grid>
        );
    };

    renderProductionLineup = (lineup, index) => {
        const { isAdmin, status, dateStart } = this.props.bookingRequestStore.booking;
        const { currentProfile } = this.props.profileStore;
        return (
            <Grid item xs={12} lg={3} key={lineup.name}>
                <Card className="lineup-card" elevation={1}>
                    <CardMedia
                        image={lineup && lineup.profile ? image.getBackgroundImage(lineup.profile) : ""}
                        title="Background Image"
                        className="card-avatar"
                    >
                        {lineup &&
                            lineup.profile && (
                                <Avatar className="avatar" aria-label={lineup.profile.firstName} src={image.getBackgroundImage(lineup.profile)} />
                            )}
                    </CardMedia>
                    <CardContent className="detail">
                        {lineup &&
                            lineup.profile && (
                                <Typography variant="title" component="h2">
                                    {lineup.profile.firstName} {lineup.profile.lastName}
                                </Typography>
                            )}
                        {lineup && <Typography variant="caption">{lineup.name}</Typography>}
                        {isAdmin &&
                            lineup && (
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container spacing={16} justify="flex-start">
                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                Fee Amount
                                            </Grid>
                                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                                {`${currentProfile && currentProfile?.currencySymbol ? currentProfile?.currencySymbol : "$"
                                                    } ${parseFloat(lineup.fee) + parseFloat(this.getLineupExtraGigFee(index))}`}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={16}>
                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                Status
                                            </Grid>
                                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                                {this.statusText(lineup)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                    </CardContent>
                    {isAdmin &&
                        lineup && (
                            <CardActions disableActionSpacing className="action">
                                <IconButton
                                    className={lineup.expanded ? "expanderOpen" : "expander"}
                                    onClick={this.handleExpandClick.bind(this, lineup)}
                                    aria-expanded={lineup.expanded}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </CardActions>
                        )}
                    {isAdmin &&
                        lineup &&
                        lineup.actRoster &&
                        lineup.actRoster.productionRosters && (
                            <Collapse in={lineup.expanded} timeout="auto">
                                <CardContent className="list">
                                    <Typography gutterBottom>Select player:</Typography>
                                    <List>
                                        {lineup &&
                                            lineup.actRoster &&
                                            lineup.actRoster.productionRosters.map(
                                                p =>
                                                    p && p.profile ? (
                                                        <ListItem
                                                            key={p.id}
                                                            dense
                                                            button
                                                            className="player-listitem"
                                                            onClick={this.handleSelectPlayer.bind(this, lineup, p)}
                                                            disabled={
                                                                status === BookingStatusType.Cancelled.ordinal ||
                                                                status === BookingStatusType.New.ordinal ||
                                                                !p.available
                                                            }
                                                        >
                                                            {p.profile && (
                                                                <Avatar aria-label={p.profile.firstName} src={image.getProfileImage(p.profile)} />
                                                            )}

                                                            {p.profile && (
                                                                <ListItemText
                                                                    primary={`${p.profile.firstName} ${p.profile.lastName}`}
                                                                    secondary={p.available ? "Available" : "Unavailable"}
                                                                />
                                                            )}
                                                            <ListItemSecondaryAction>
                                                                <Checkbox
                                                                    onChange={this.handleSelectPlayer.bind(this, lineup, p)}
                                                                    checked={p.profileId === lineup.profileId}
                                                                    disabled={
                                                                        status === BookingStatusType.Cancelled.ordinal ||
                                                                        status === BookingStatusType.New.ordinal ||
                                                                        !p.available
                                                                    }
                                                                />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    ) : (
                                                        ""
                                                    )
                                            )}
                                    </List>
                                </CardContent>
                            </Collapse>
                        )}
                </Card>
            </Grid>
        );
    };

    // {showRedirectLineUpModal} onClose={this.hideRedirectLineUpModal
    showRedirectLineUpModal = () => {
        this.setState({
            ...this.state,
            showRedirectModal: true
        });
    };

    hideRedirectLineUpModal = () => {
        this.setState({
            ...this.state,
            showRedirectModal: false
        });
    };

    showRedirectPromoteModal = () => {
        this.setState({
            ...this.state,
            showRedirectPromote: true
        });
    };

    hideRedirectPromoteModal = () => {
        this.setState({
            ...this.state,
            showRedirectPromote: false
        });
    };
    

    redirectNotifyClinet = () => {
        this.setState({
            ...this.state,
            showRedirectModal: false
        });
        this.props.redirectAction()
    }

    redirectPromote = () => {
        this.setState({
            ...this.state,
            showRedirectPromote: false
        });
        this.props.redirectActionPromote()
        
    }

    showNewLineupModal = () => {
        this.setState({
            ...this.state,
            showNewLineupModal: true
        });
    };

    hideNewLineupModal = () => {
        this.setState({
            ...this.state,
            showNewLineupModal: false
        });
    };

 

    renderAddLineupCard = () => {
        const { showNewLineupModal } = this.state;
         const {status,dateStart} = this.props.bookingRequestStore.booking;
         
        return (
            <Grid item xs={12} lg={3}>
               
               {
                 !(
                 status === BookingStatusType.Cancelled.ordinal) &&
                 <Tooltip id="tooltip-bottom" title="Add New Performer" placement="bottom">
                    <Card className="add-lineup-card" onClick={this.showNewLineupModal}>
                        <AddIcon />
                        <Typography color="primary" variant="headline" style={{ paddingTop: 3 }}>
                            Add New Performer
                        </Typography>
                    </Card>
                </Tooltip>
               }
              
                <NewLineupModal open={showNewLineupModal} onClose={this.hideNewLineupModal} />
            </Grid>
        );
    };

    
    
    showRemoveLineupConfirmation = index => {
        this.setState({
            ...this.state,
            showRemoveLineupConfirmation: true,
            deletedLineupIndex: index
        });
    };

    hideRemoveLineupConfirmation = () => {
        this.setState({
            ...this.state,
            showRemoveLineupConfirmation: false,
            deletedLineupIndex: undefined
        });
    };

    render() {
      
        // let NumberFormatCustom = props => {
        //     const { inputRef, onChange, ...other } = props;

        //     return (
        //         <NumberFormat
        //             {...other}
        //             ref={inputRef}
        //             onValueChange={values => {
        //                 onChange({
        //                     target: {
        //                         value: values.value
        //                     }
        //                 });
        //             }}
        //             thousandSeparator
        //             prefix="$"
        //         />
        //     );
        // };

        const { lineups, isLoadingLineup } = this.props.lineupStore;
        const { isAdmin, status, dateStart, act } = this.props.bookingRequestStore.booking;
        const { currentProfile } = this.props.profileStore;
        const { lineUpBlock } = this.props.templateStore;
        const { showRemoveLineupConfirmation, deletedLineupIndex, showRedirectModal, showRedirectPromote } = this.state;

        var lineUps = lineups.filter(x => typeof x.id === "undefined")
        var isNotInvited = false
        if(lineups?.length > 1){
            isNotInvited = lineUps.length > 0;
        }else{
           
            if(lineUps.length && lineUps[0].profileId === currentProfile?.id){
                isNotInvited = false
            }else{
                isNotInvited = true
            }
        }
       

        if (isNotInvited && lineUps.length) {
            this.props.templateStore.closeLineupBlock();
        }
        else {
            this.props.templateStore.openLineupBlock();
        }
        return (
            <Grid container spacing={16} style={{ position: "relative", minHeight: "200px" }}>
                <Prompt when={lineUpBlock} message={location => `You have unsaved Line up, do you still want to leave this section?`} />
                <Prompt when={this.state.unsaveFees} message={location => `You have not saved your updated fees. Do you want to continue?`} />
                
                <Loading showed={isLoadingLineup} />
                {isAdmin ? (
                    <Grid container style={{ paddingBottom: 60, backgroundColor: "#fff" }}>
                        <Grid item xs={12} lg={3}>
                            <div>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Gig Fee Summary
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Use this section to distribute gig fees.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <ResponsiveTable columns={this.getLineupColumns()} rows={this.getLineupRows()} footerRow={this.getLineupFooterRows()} />
                        </Grid>
                    </Grid>
                ) : null}

                <Grid container spacing={8} style={{ paddingBottom: 60, backgroundColor: "#fff" }}>
                    <Grid item xs={12} lg={3}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Players Line Up
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                Select and invite your players to this gig.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={8}>
                            {lineups
                                .filter(x => x.type === 0 && x.status !== LineupStatusType.Removed.ordinal)
                                .map((lineup, index) => this.renderPlayerLineup(lineup, index))}
                            {isAdmin ? this.renderAddLineupCard() : null}
                        </Grid>
                    </Grid>
                </Grid>

                {SHOW_PRODUCTION_CREW ? (
                    <Grid container spacing={8} style={{ paddingBottom: 60, backgroundColor: "#fff" }}>
                        <Grid item xs={12} lg={3}>
                            <div>
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Production Crew
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Add support cast for your gig. Sound engineer, lighting engineer, MC, DJ, etc.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                {lineups.filter(x => x.type === 1).map((lineup, index) => this.renderProductionLineup(lineup, index))}
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                <Grid container style={{ paddingBottom: 60, backgroundColor: "#fff" }}>
                    <Grid item xs={12} lg={3}>
                        &nbsp;
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} />
                            <Grid item xs={12} lg={6} />
                            <Grid item xs={12} lg={12}>
                                <div className="action">
                                    {!(
                                        status === BookingStatusType.Cancelled.ordinal ||
                                        status === BookingStatusType.New.ordinal ||
                                        status === BookingStatusType.Hold.ordinal
                                    ) && isAdmin ? (
                                        <Fragment>
                                            <Tooltip
                                                disableFocusListener={!this.isLineupSaveButtonDisabled()}
                                                disableTouchListener={!this.isLineupSaveButtonDisabled()}
                                                disableHoverListener={!this.isLineupSaveButtonDisabled()}
                                                title="Roster's Fees is greater than Gig Fee"
                                                placement="bottom-end"
                                            >

                                                <span>
                                                    <Button
                                                        disabled={this.isLineupSaveButtonDisabled()}
                                                        variant="raised"
                                                        color="primary"
                                                        onClick={this.handleSubmitForm}
                                                    >
                                                        Save & Invite Players
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </Fragment>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <RedirectLineUpModal open={showRedirectModal} onClose={this.hideRedirectLineUpModal} /> */}
                <ConfirmationModal
                    open={showRedirectModal}
                    title="Line Up Invited!"
                    message={`Your players have been invited. Would you like to notify the client?`}
                    closeHandler={this.hideRedirectPromoteModal}
                    confirmationHandler={this.redirectNotifyClinet}
                    declineHandler={this.hideRedirectLineUpModal}
                    confirmationLabel="Continue"
                />
                <ConfirmationModal
                    open={showRedirectPromote}
                    title="Post To Socials"
                    message={`Let people know about your gig by posting to your socials`}
                    closeHandler={this.hideRedirectPromoteModal}
                    confirmationHandler={this.redirectPromote}
                    declineHandler={this.hideRedirectPromoteModal}
                    confirmationLabel="Create Post"
                    declineLabel="Not now"
                />
                <ConfirmationModal
                    open={showRemoveLineupConfirmation}
                    title="Remove Lineup"
                    message={`Are you sure to remove lineup? `}
                    closeHandler={this.hideRemoveLineupConfirmation}
                    confirmationHandler={() => this.handleRemoveLineup(deletedLineupIndex)}
                    declineHandler={this.hideRemoveLineupConfirmation}
                    confirmationLabel="Remove"
                />
                {currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.id == act?.actAdminId ? <LineupMessage bookingId={this.props.bookingId} /> : ""}
            </Grid>
        );
    }
}

export default BookingLineup;
