import React, { Component } from "react";
import ModalContainer from "../../modal/ModalContainer";
import { inject } from "mobx-react";
import moment from 'moment'

import { withRouter } from "react-router-dom";

import { Grid, DialogContent, Button, Typography } from "@material-ui/core";

import event from "../../../img/event.svg";

import "./BookingOptionModal.css";

@withRouter
@inject("searchStore")
class BookingOptionModal extends Component {

    handleNormalBooking = () => {
        const { requiredDate } = this.props.searchStore;
        const { actId } = this.props;
        let date = moment();
        if(requiredDate)
        {
            date = requiredDate;
        }


        this.props.history.push(`/booking/new?dateStart=${moment(date).format("DD-MM-YYYY")}&dateEnd=${moment(date).format("DD-MM-YYYY")}&actId=${actId}&forceAct=true`);
    }

    handleAdhocBooking = () => {
        const { requiredDate } = this.props.searchStore;
        const { actId } = this.props;
        let date = moment();
        if(requiredDate)
        {
            date = requiredDate;
        }

        this.props.history.push(`/privateBooking?date=${moment(date).format("DD-MM-YYYY")}&act=${actId}&forceAct=true`);
    }

    render() {
        const { unShowPrivate } = this.props;

        return (
            <ModalContainer open={this.props.open} onClose={this.props.onClose} fullScreen={false} maxWidth="sm">
                <DialogContent>
                    <Grid container justify="center" alignItems="center">
                        {!unShowPrivate && 
                            <Grid item xs={12} lg={6}>
                                <div className="private-booking-type">
                                    <Button onClick = { this.handleAdhocBooking }>
                                        <Typography component="div" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={event} alt="Private Booking" />
                                            </div>
                                            <span className="title">Private/Corporate Event</span>
                                        </Typography>
                                    </Button>
                                </div>
                            </Grid>
                        }

                        <Grid item xs={12} lg={6}>
                            <div className="private-booking-type">
                                <Button onClick = { this.handleNormalBooking }>
                                    <Typography component="div" type="subheading" color="inherit">
                                        <div className="type-image">
                                            <img src={event} alt="Venue Booking" />
                                        </div>
                                        <span className="title">Venue Booking</span>
                                    </Typography>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </ModalContainer>
        );
    }
}

export default BookingOptionModal;
