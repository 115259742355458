import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { Link, withRouter} from "react-router-dom";
// import NumberFormat from "react-number-format";
// import moment from "moment";
import { BookingStatusType, BookingType } from "../../types/enum";
import "./BookingDetails.css";
import { Grid, Typography, Button, Tooltip, Checkbox,Menu, Snackbar, IconButton,MenuItem } from "@material-ui/core";
import ResponsiveTable from "../table/ResponsiveTable";
import BookingStatusBadge from "./BookingStatusBadge";
import moment from "moment";
import ConfirmationModal from "../modal/ConfirmationModal";
import CloseIcon from "@material-ui/icons/Close";

import { ProfileType, RosterDividendType, LineupStatusType } from "../../types/enum";

import currencyHelper from '../../helper/currency';
import { Fragment } from "react";

const BOOKING_STATUS = ["Completed", "Cancelled"];

@inject("profileStore", "bookingRequestStore", "templateStore", "reportStore")
@withRouter
@observer
class BookingCompleted extends Component {
    state = {
        cancelBookingModalState: false,
        declineBookingModalState: false,
        columns: [],
        booking: undefined,
        duplicateModalState: true,
        duplicateModalState: null,
        openDuplicateSnackbar: false,
        duplicateBooking: undefined,
        actionAnchor: [],
        isLoading: false
    };

    rows = [];

    componentDidMount() {
        const { completed } = this.props.bookingRequestStore;
      
        this.props.bookingRequestStore.resetCompletedGigs()
        if (this.props.profileType === "agent") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate:true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "agentFee", label: "My Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        } else if (this.props.profileType === "musician") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate:true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Venue/Client Fee", sortable: true, width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
                    { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "myFee", label: "My Fee", width: "10%", isNumber:true, align: 'left', style: { flexDirection: 'row' } },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        } else if (this.props.profileType === "staff") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } }, 
                    { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },  
                    // { key: "acknowledge", label: "Acknowledged", width: "15%", style: { textAlign: "center" } },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        } else if (this.props.profileType === "PrivateHire") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } }, 
                    // { key: "acknowledge", label: "Acknowledged", width: "15%", style: { textAlign: "center" } },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        }
        this.loadCompletedGigs(1)
    }

    loadCompletedGigs = (page) => {
        this.props.bookingRequestStore.showLoadingState()
        const {
            currentProfile
        } = this.props.profileStore;
        this.props.bookingRequestStore.getCompletedGigs(currentProfile?.id, page).then(() => {
           
        }).finally(() => {
            this.props.bookingRequestStore.setCompletedPage(page)
            this.props.bookingRequestStore.hideLoadingState()
        });
        
    }

    handleShowMore = e => {
        var page = this.props.bookingRequestStore.completedPage + 1
        this.loadCompletedGigs(page)
    };

    setActionAnchor = (element, bookingId) => {
        var tempAnchor = [];
        tempAnchor[bookingId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    handleOpenDuplicateBookingModal = booking => this.setState({ duplicateModalState: true, duplicateBooking: booking, actionAnchor: [] });
    handleCloseDuplicateBookingModal = () => {
        this.setState({
            ...this.state,
            duplicateModalState: false,
            duplicateBooking: null
        });
    };
    handleDupicateRequest = () => {
        const { duplicateBooking } = this.state;
        const {currentProfile} = this.props.profileStore
        this.props.bookingRequestStore.duplicateBooking(duplicateBooking.id,currentProfile.id ).then(response => {
            this.handleCloseDuplicateBookingModal();
           
            this.setState({ openDuplicateSnackbar: true});
            setTimeout(
                function () {
                    this.props.history.replace(`/booking/${response.id}/edit`);
                }.bind(this),
                2000
            );
            
        });
    };

    handleSnackbarEditClose = (event, reason) => {
        this.setState({ openDuplicateSnackbar: false });
    };
    renderFooterRow = () => {
        let { completed } = this.props.bookingRequestStore;
        const { profileType } = this.props;
        const { venueId } = this.props.reportStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        if (venueId) {
            completed = completed.filter(x => x.venueId === venueId);
        }
        var totalFee = 0;
        var totalActFee = 0;
        var totalAgentFee = 0;
        var totalMyFee = 0

        completed.forEach( booking => {
            let linuep = booking?.lineups?.find(x => x.profileId === currentProfile?.id);
            var musicianFee = parseFloat(booking.gigFee) - totalFee
            const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

            let adminFeeRoster = 0
            let totalRosterFeeAdmin = 0
            booking?.act?.actRoster?.forEach(actRoster => {
                let feeCount = 0
                if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                    feeCount = actRoster.dividendAmount /100 * booking.actFee;
                }else {
                    feeCount = actRoster.dividendAmount;
                }
                totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
            })
            adminFeeRoster = booking.actFee - totalRosterFeeAdmin
            if(currentProfile?.type === ProfileType.Musician.ordinal && !booking?.lineups?.length){
                let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
               
                if(actRoster?.length){
                    if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                        musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                    }else {
                        musicianFee = actRoster[0].dividendAmount;
                    }
                    if(this.props.profileType === 'musician'){
                        if(booking?.act?.actAdminId === currentProfile?.id){
                            musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                        }
                    }
                }else{
                    musicianFee = booking.actFee
                    booking?.act?.actRoster?.forEach(item => {
                        var rosterFee = 0
                        if(item.dividendType === RosterDividendType.Percentage.ordinal){
                            rosterFee = item.dividendAmount /100 * booking.actFee;
                        }else {
                            rosterFee = item.dividendAmount;
                        }
                        musicianFee = musicianFee - rosterFee
                    });
                }
            }
             
            var totalRosterFee = 0

            booking?.lineups?.forEach(lineUp => {
                totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
            })
            var adminFee = booking.actFee - totalRosterFee
            var calculateFee = linuep?.fee ?  linuep?.fee : 0
            if(this.props.profileType === 'musician'){
                if(booking?.act?.actAdminId === currentProfile?.id){
                    calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                }
            }
            
            let myFee = linuep?.fee ? calculateFee: isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;
          
            let actOwner = booking.act?.userId === currentProfile?.userId; 

            totalFee += (booking.viewOnly) ? 0 : (profileType === "agent" || profileType === "staff") && booking.isApproved ? Number(booking.gigFee) + Number(booking.extraGigFee) : Number(booking.gigFee);
            totalActFee += profileType === "musician" && !actOwner ? 0 : Number(booking.actFee);
            totalAgentFee += Number(booking.agentCommissionAmount);
            totalMyFee += Number(myFee);
        });

        return {
            gigFee: ( (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalFee, 2)}`,
            actFee: ( (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalActFee, 2)}`,
            agentCommissionAmount: ((currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
            agentFee: ((currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
            myFee: ((currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalMyFee, 2)}`,
            style: {
                textAlign: 'left'
            }
        }
    }

    render() {
        let { completed } = this.props.bookingRequestStore;
        let { currentProfile } = this.props.profileStore;
        const { profileType } = this.props;
        const { venueId } = this.props.reportStore;
        if (venueId) {
            completed = completed.filter(x => x.venueId === venueId);
            this.props.bookingRequestStore.setBookingsCount("completed", completed);
        }
        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Completed gigs
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                {profileType === "musician" ? "This is the list of your completed gigs." : "This is the list of completed gigs"}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12} className="booking-table-wrapper">
                        <ResponsiveTable
                            sortable={true}
                            columns={this.state.columns}
                            rows={completed.map(booking => {
                                var row = {};
                                // row.date = date.getDate()+" "+this.props.templateStore.getMonth(date.getMonth())+" "+date.getFullYear();
                                var formattedDate = moment(booking.dateStart).format("YYYY-MM-DD")
                                
                                
                                let time = booking.timeStart.length < 8 ? `0${booking.timeStart}` : booking.timeStart;
                                let endTime = booking.timeEnd.length < 8 ? `0${booking.timeEnd}` : booking.timeEnd;
                                var finalDate= moment(formattedDate + ' ' + time)
                                row.date = {newFormat: 
                                    (
                                        <div>
                                            <span>{`${booking.dateStart.format("ddd, DD MMM YYYY")}`}</span>
                                            <br />
                                            <small>{`${moment(time).format('hh.mm A')} - ${moment(endTime).format('hh.mm A')}`}</small>
                                        </div>
                                    ), value: finalDate};
                                row.venue = (
                                    <div>
                                        <span>{booking.venue ? booking.venue.profileName : booking.venueName}</span>
                                        <br />
                                        <small>{booking.venueAddress}</small>
                                    </div>
                                );
                                row.bookingCreator = booking?.createdBy ? (booking?.createdBy?.firstName + " " +booking?.createdBy?.lastName) : ""
                                // row.address = booking.venueAddress;
                                row.agentName = booking.agentName;
                                row.time = booking.timeStart.substring(0, 5);
                                row.gigFee = (booking.viewOnly) || (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices)
                                    ? <i class="far fa-eye-slash"></i>
                                    : `${currentProfile?.currencySymbol + ((profileType === "agent" || profileType === "staff") && booking.isApproved
                                        ? currencyHelper?.formatMoney(booking.gigFee + booking.extraGigFee, 2) 
                                            : currencyHelper?.formatMoney(booking.gigFee, 2))}`;
                                row.agentFee = currentProfile?.currencySymbol + currencyHelper.formatMoney(booking.agentCommissionAmount, 2);
                                row.agentCommissionAmount = `${currentProfile?.currencySymbol}${currencyHelper.formatMoney(booking.agentCommissionAmount, 2)}`
                                // row.actName = booking.actName;
                                const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

                                row.actName = (
                                    <div>
                                        <Link target={"_blank"} to={`/profile/${isEntertainerBookings ? currentProfile?.id : booking.act?.id}`}>{isEntertainerBookings ? `${currentProfile?.firstName} ${currentProfile?.lastName}` : booking.act?.actName}</Link>
                                        <br />
                                        {booking.act && <small>Contact: {booking.act?.contactName}</small>}
                                    </div>
                                );

                                
                                
                                let linuep = booking?.lineups?.find(x => x.profileId === currentProfile?.id);
                                // let myFee = linuep?.fee ? linuep.fee : isEntertainerBookings ? booking.gigFee : booking.actFee
                                let actOwner = booking.act?.userId === currentProfile?.userId; 

                                row.actFee = this.props.profileType === "musician" && !actOwner ? "" : currentProfile?.currencySymbol + currencyHelper.formatMoney(booking.actFee, 2);


                                let totalFee = 0;
                                booking?.lineups?.forEach(l => {
                                    if (l.status !== LineupStatusType.Removed.ordinal) {
                                        totalFee += parseFloat(l.fee)
                                    }                                    
                                });  

                                var musicianFee = parseFloat(booking.gigFee) - totalFee
                                let adminFeeRoster = 0
                                let totalRosterFeeAdmin = 0
                                booking?.act?.actRoster?.forEach(actRoster => {
                                    let feeCount = 0
                                    if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                                        feeCount = actRoster.dividendAmount /100 * booking.actFee;
                                    }else {
                                        feeCount = actRoster.dividendAmount;
                                    }
                                    totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
                                })
                                adminFeeRoster = booking.actFee - totalRosterFeeAdmin

                                if(currentProfile?.type === ProfileType.Musician.ordinal && !booking?.lineups?.length){
                                    let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
                                    if(actRoster?.length){
                                        if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                                            musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                                        }else {
                                            musicianFee = actRoster[0].dividendAmount;
                                        }
                                        if(this.props.profileType === 'musician'){
                                            if(booking?.act?.actAdminId === currentProfile?.id){
                                                musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                                            }
                                        }
                                    }else{
                                        musicianFee = booking.actFee
                                        booking?.act?.actRoster?.forEach(item => {
                                            var rosterFee = 0
                                            if(item.dividendType === RosterDividendType.Percentage.ordinal){
                                                rosterFee = item.dividendAmount /100 * booking.actFee;
                                            }else {
                                                rosterFee = item.dividendAmount;
                                            }
                                            musicianFee = musicianFee - rosterFee
                                        });
                                    }
                                }
                                 
                                var totalRosterFee = 0

                                  booking?.lineups?.forEach(lineUp => {
                                    totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
                                })
                                var adminFee = booking.actFee - totalRosterFee
                                var calculateFee = linuep?.fee ?  linuep?.fee : 0
                                if(this.props.profileType === 'musician'){
                                    if(booking?.act?.actAdminId === currentProfile?.id){
                                        calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                                    }
                                }
                                let myFee = linuep?.fee ? calculateFee : isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;

                                row.myFee = this.props.profileType === 'musician' && currentProfile?.currencySymbol + currencyHelper.formatMoney(myFee, 2);
                                row.bookingCreator = booking?.createdBy ? (booking?.createdBy?.firstName + " " +booking?.createdBy?.lastName) : ""
                                
                                row.acknowledge = booking.acknowledge ? (
                                    <Tooltip
                                        title={
                                            <span>
                                                Acknowledge By:{" "}
                                                {`${booking.acknowledgeBy ? booking.acknowledgeBy.firstName : ""} ${
                                                    booking.acknowledgeBy ? booking.acknowledgeBy.lastName : ""
                                                }`}
                                            </span>
                                        }
                                        placement="bottom"
                                    >
                                        <Checkbox checked={true} disableRipple={true} />
                                    </Tooltip>
                                ) : (
                                    <Checkbox checked={booking.acknowledge} disabled={true} />
                                );

                                row.action = (
                                    (  <Fragment>
                                        <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, booking.id)}>
                                            Actions
                                        </Button>
                                        {booking.id && (
                                            <Menu
                                                open={this.state.actionAnchor[booking.id] != null}
                                                onClose={() => this.unsetActionAchnor(booking.id)}
                                                anchorEl={this.state.actionAnchor[booking.id]}
                                            >
                                                <MenuItem
                                                    button
                                                    className="booking-details"
                                                    onClick={() => {
                                                        let redirectUrl =
                                                            booking.type === BookingType.Adhoc.ordinal
                                                            ? `/privateBooking/${booking.id}/edit`
                                                            : currentProfile?.type === ProfileType.Musician.ordinal
                                                                ? `/musician/booking/${booking.id}/details`
                                                                : currentProfile?.type === ProfileType.Agent.ordinal
                                                                    ? `/booking/${booking.id}/edit`
                                                                    : currentProfile?.type === ProfileType.Staff.ordinal
                                                                        ? `/booking/${booking.id}/edit`
                                                                        : "";
                                                        this.props.history.push(redirectUrl);
                                                    }}
                                                >
                                                    View
                                                </MenuItem>
                                                {(booking.createdById === currentProfile?.id || booking.isBookingVenueManagemenet || currentProfile?.type === ProfileType.Agent.ordinal)? <MenuItem onClick={() => this.handleOpenDuplicateBookingModal(booking)}>Duplicate Booking</MenuItem> : null}
                                            </Menu>
                                        )}
                                    </Fragment>)
                                );
                                row.status = (
                                    <BookingStatusBadge status={BOOKING_STATUS[booking.status === BookingStatusType.Cancelled.ordinal ? 1 : 0]} />
                                );
                                return row;
                            })}
                            footerRow={this.renderFooterRow()}
                        />
                    </Grid>
                    <Grid item style={{marginTop: 25}} xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                            <Grid container direction="row" justify="center" alignItems="center">
                                {this.props.bookingRequestStore.showMoreCompleted ? (
                                    <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                        Show More
                                    </Button>
                                ) : null}
                            </Grid>
                        </Grid>
                </Grid>
                <ConfirmationModal
                    open={this.state.duplicateModalState}
                    message={`Are you sure you want to duplicate this booking - ${this.state.duplicateBooking?.title}?`}
                    closeHandler={this.handleCloseDuplicateBookingModal}
                    confirmationHandler={this.handleDupicateRequest}
                    declineHandler={this.handleCloseDuplicateBookingModal}
                    confirmationLabel="Duplicate"
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDuplicateSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarEditClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Booking {this.state.duplicateBooking?.title} duplicated</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
            </Fragment>
        );
    }
}

export default BookingCompleted;
