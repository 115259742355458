import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withRouter } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import WidgetTitleBar from "../../components/widget/WidgetTitleBar";
import WidgetContainer from "../../components/widget/WidgetContainer";
import SocialButton from "../../components/widget/SocialButton";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import classNames from "classnames";
import YoutubeWidget from "../../components/widget/YoutubeWidget";
import VimeoWidget from "../../components/widget/VimeoWidget";
import SoundCloudWidget from "../../components/widget/SoundCloudWidget";
import Testimonials from "../../components/widget/Testimonials";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import ShareIcon from "@material-ui/icons/Share";
import Tab from "@material-ui/core/Tab";
import MessageModal from "../../components/profile/messageModal/MessageModal";

import "./Profile.css";

import { Typography, Hidden, Paper, Grid, Tabs, Avatar, Tooltip, Button } from "@material-ui/core";
import { ProfileType } from "../../types/enum";
import VenueInvitationModal from "../../components/agent/venueRoster/VenueInvitationModal";
import { Promise } from "bluebird";
import image from "../../helper/image";

const youtubeReg = /^(http:\/\/|https:\/\/)(youtu.be|www.youtube.com)\/([\w]+)/;
const vimeoReg = /^(http:\/\/|https:\/\/)(vimeo.com)\/([\w]+)/;
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

import { GoogleMap, Marker, withScriptjs, withGoogleMap } from "react-google-maps";
import GooglePlaceAutoComplete from "../../components/maps/GooglePlaceAutocomplete";

const GoogleMaps = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={16}
            defaultCenter={{ lat: props.latitude, lng: props.longitude }}
            center={{
                lat: props.latitude,
                lng: props.longitude
            }}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    ))
);
@inject("profileStore", "templateStore", "bookingRequestStore", "reportStore", "agentVenueStore")
@withRouter
@observer
class VenueProfile extends Component {
    timeoutValue = null;

    constructor(props) {
        super(props);
        this.props.reportStore.clearDateRangeFilter();
        this.state = {
            value: 0,
            backgroundImageIndex: 0,
            upcomings: [],
            showVenueInvitationModal: false,
            canInviteVenue: false,
            isCurrentUser: false,
            showMessageModal: false
        };
    }

    componentDidMount = () => {
        const profile = this.props.profile || this.props.profileStore.profile;
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if(currentProfile && currentProfile.userId === profile.userId) {
            this.setState({isCurrentUser: true})
        }

        if (profile && profile.backgroundImages) {
            for (let i = 0; i < profile.backgroundImages.length; i++) {
                // caches images, avoiding white flash between background replacements
                new Image().src = profile.backgroundImages[i].url1920;
            }
            this.changeBackground(0);
        }
        this.props.templateStore.showLoadingScreen();
        Promise.all([
            this.props.profileStore.canAgentInviteVenue(profile.id),
            this.props.bookingRequestStore.loadEntertainmentUpcomingGigsWithoutAdhoc(profile.id, true, true)
        ]).then(response => {
            this.props.templateStore.hideLoadingScreen();
            this.setState({
                canInviteVenue: response[0],
                upcomings: response[1]
            });
        });
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    changeBackground = index => {
        const profile = this.props.profile || this.props.profileStore.profile;
        this.setState({ backgroundImageIndex: index });

        this.timeoutValue = setTimeout(() => {
            let newIndex = this.state.backgroundImageIndex + 1 < profile.backgroundImages.length ? this.state.backgroundImageIndex + 1 : 0;
            this.changeBackground(newIndex);
        }, 5000);
    };

    manualChangeBackground = index => () => {
        clearTimeout(this.timeoutValue);
        this.changeBackground(index);
    };

    formatUberUrl = () => {
        const profile = this.props.profile || this.props.profileStore.profile;

        var url = "https://m.uber.com/ul/?action=setPickup&client_id=TAoJz2nXLpTYo-BQbGKRLh6ZslaD9rfk&pickup=my_location";

        if (profile && profile.fullName) {
            url += "&dropoff[nickname]=" + profile.fullName;
        }
        if (profile && profile.street) {
            url += "&dropoff[formatted_address]=" + profile.street;
        }
        if (profile && profile.latitude) {
            url += "&dropoff[latitude]=" + profile.latitude;
        }
        if (profile && profile.longitude) {
            url += "&dropoff[longitude]=" + profile.longitude;
        }
        return url;
    };

    showMessageModal = () => {
        this.setState({
            showMessageModal: true
        });
    };

    hideMessageModal = () => {
        this.setState({
            showMessageModal: false
        });
    };

    handleButtonMessage = (e) => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const profile = this.props.profile || this.props.profileStore.profile;
        if(this.state.isCurrentUser) {
            e.preventDefault();
            this.props.history.push('/messages/new');
        } else {
            if (currentProfile) {
                e.preventDefault();
                this.props.history.push({
                    pathname: '/messages/new',
                    state: { profile: profile.venueAdmin }
                })
            } else {
                this.setState({showMessageModal: true})
            }
        }
    }

    renderGigs = (data, index) => {
        return (
            <Grid item xs={12} key={index}>
                <Grid container spacing={0}>
                    <Grid item xs={6} lg={2} className="perform-list-calendar-container-big">
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography className="perform-list-calendar-month-big">{data.month}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="perform-list-calendar-date-big">{data.date}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="perform-list-calendar-day-big">{data.day}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} lg={4} className="perform-list-content-big">
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="subheading" component="p" className="perform-list-title-subheading">
                                    Gig Information:
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={1} className="perform-list-icon-container">
                                        <span classes={{ root: "perform-list-icon" }} >
                                            <i className="fa fa-clock" style={{cursor:"auto", textDecoration:"none"}}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography component="p">{data.time}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={1} className="perform-list-icon-container">
                                        <span classes={{ root: "perform-list-icon" }}>
                                            <i className="fa fa-user" style={{cursor:"auto", textDecoration:"none"}}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography component="p">{data.actName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {data.genre && (
                                <Grid item xs={12}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={1} className="perform-list-icon-container">
                                            <span classes={{ root: "perform-list-icon" }}>
                                                <i className="fa fa-music" style={{cursor:"auto", textDecoration:"none"}}/>
                                            </span>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Tooltip title={data.genre} placement="bottom">
                                                <Typography component="p" noWrap>
                                                    {data.genre.length > 60 ? data.genre.substring(0, 59) + "..." : data.genre}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={6} lg={3}>
                        {data.youtube ? (
                            <Fragment>
                                {data &&
                                    data.youtube.match(youtubeReg) && (
                                        <div style={{ height: '100%', width: '100%', backgroundColor: '#333' }}>
                                            <div style= {{ marginTop: "auto", marginBottom: "auto", width: '100%' }}>
                                                <YoutubeWidget url={data && data.youtube} height={"100%"} />
                                            </div>
                                        </div>
                                    )}
                                {data &&
                                    data.youtube.match(vimeoReg) && (
                                        <div style={{ height: '100%', width: '100%', backgroundColor: '#333' }}>
                                            <div style= {{ verticalAlign: 'center', width: '100%' }}>
                                                <VimeoWidget url={data && data.youtube} height={"100%"}/>
                                            </div>
                                        </div>
                                    )}
                            </Fragment>
                        ) : data.soundcloud ? (
                            <SoundCloudWidget url={data.soundcloud} type="venue-profile" />
                        ) : (
                            <div
                                style={{ backgroundImage: `url(${data.backgroundImage})` }}
                                className="venue-profile-gig-image"
                            >
                                <Avatar style={{ width: 80, height: 80 }} src={data.profileImage} alt={data.actName} />
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={6} lg={3} className="perform-list-content-big">
                        <Grid container spacing={8} style={{ alignContent: "stretch", padding: 3 }}>
                            <Grid item xs={12}>
                                <Typography variant="subheading" component="p" className="perform-list-title-subheading">
                                    RSVP:
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={6} className="text-xs-center">
                                        <Tooltip title="Share Gig" placement="bottom">
                                            <ShareIcon style={{ fontSize: 50 }} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                                        <Tooltip title="Order Uber to This Venue" placement="bottom">
                                            <a href={this.formatUberUrl()}>
                                                <span style={{ fontSize: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <i className="fab fa-uber" />
                                                </span>
                                            </a>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    processUpcomingGigs = upcomingGigs => {
        let result = [];
        if (upcomingGigs && upcomingGigs.length > 0) {
            for (let i = 0; i < upcomingGigs.length; i++) {
                let dateStart = moment(upcomingGigs[i].dateStart);
                let data = {
                    month: dateStart.format("MMMM"),
                    date: dateStart.format("D"),
                    day: dateStart.format("dddd"),
                    venue: upcomingGigs[i].venueName,
                    address: upcomingGigs[i].venueAddress,
                    time:
                        moment(upcomingGigs[i].timeStart, "HH:mm:ss.SSS").format("h.mma") +
                        " - " +
                        moment(upcomingGigs[i].timeEnd, "HH:mm:ss.SSS").format("h.mma"),
                    actName: upcomingGigs[i].act.actName,
                    actUrl: "/" + upcomingGigs[i].act.vanityUrl || "/profile/" + upcomingGigs[i].actId,
                    actId: upcomingGigs[i].act.id,
                    youtube: upcomingGigs[i].act.videoUrl,
                    soundcloud: upcomingGigs[i].act.musicUrl,
                    profileImage: image.getProfileImage(upcomingGigs[i].act),
                    backgroundImage: image.getBackgroundImage(upcomingGigs[i].act),
                    genre:
                        upcomingGigs[i].act.profileGenres &&
                        upcomingGigs[i].act.profileGenres.length > 0 &&
                        upcomingGigs[i].act.profileGenres
                            .map(x => {
                                return x.name;
                            })
                            .join(", ")
                };
                result.push(data);
            }
        }
        return result;
    };

    showVenueInvitationModal = () => {
        const { profile } = this.props;
        this.props.agentVenueStore.setInvitedProfile(profile);
        this.setState({
            ...this.state,
            showVenueInvitationModal: true
        });
    };

    hideVenueInvitationModal = () => {
        this.setState({
            ...this.state,
            showVenueInvitationModal: false
        });
    };

    render() {
        const profile = this.props.profile || this.props.profileStore.profile;
        const { currentProfile } = this.props.profileStore;
        const { upcomings, showVenueInvitationModal, canInviteVenue } = this.state;

        let performData = this.processUpcomingGigs(upcomings);

        let locationQueryString = "";
        let tradingHours = [];
        if (profile) {
            locationQueryString =
                encodeURIComponent(profile.fullName) +
                "," +
                encodeURIComponent(profile.street) +
                "," +
                encodeURIComponent(profile.city) +
                "," +
                encodeURIComponent(profile.state);
            tradingHours = JSON.parse(profile.tradingHours);
        }

        let profileContent = (
            <div className="profile-content">
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <WidgetTitleBar title={profile && profile.fullName + "'s Live Gigs"} showBadge={false} />
                            </Grid>
                            {performData.map((data, index) => {
                                return this.renderGigs(data, index);
                            })}
                        </Grid>
                    </Grid>

                    {profile && profile.testimonials && profile.testimonials.length > 0 ? (
                        <Grid item xs={12}>
                            <div className="widget-title-bar-yellow standalone-widget-title">
                                <WidgetTitleBar title="Testimonials" showBadge={false} />
                            </div>

                            <Testimonials testimonials={profile && profile.testimonials} />
                        </Grid>
                    ) : null}
                </Grid>
            </div>
        );

        let lowerSidebarContent = (
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    { profile.showContactPhone || profile.showEmail ? 
                        <Grid item xs={12}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <div className="border-bottom-grid">
                                        <Typography type="headline" component="h4" className="profile-sidebar-title">
                                            Contact
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className="profile-sidebar-contact">
                                    <Grid container spacing={8}>
                                        {profile && profile.showContactPhone &&
                                            profile.contactPhone && (
                                                <Grid item xs={12}>
                                                    <Typography component="div">
                                                        <PhoneIcon />
                                                        {profile && profile.contactPhone}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        {profile && profile.showEmail &&
                                            profile.email && (
                                                <Grid item xs={12}>
                                                    <Typography component="div">
                                                        <EmailIcon />
                                                        {profile && profile.email}
                                                    </Typography>
                                                </Grid>
                                            )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> : null
                    }

                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <div className="border-bottom-grid">
                                    <Typography type="headline" component="h4" className="profile-sidebar-title">
                                        Trading Hours
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="profile-sidebar-trading-hours">
                                <Grid container spacing={8}>
                                    {tradingHours && tradingHours.map((tradingHour, index) => (
                                        <Grid item xs={12} key={index}>
                                            <Grid container spacing={0}>
                                                <Grid item xs className="text-xs-left">
                                                    <Typography component="div">{tradingHour.day}</Typography>
                                                </Grid>
                                                <Grid item xs className="text-xs-right">
                                                    <Typography component="div">{tradingHour.hour}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <div className="border-bottom-grid">
                                    <Typography type="headline" component="h4" className="profile-sidebar-title">
                                        Location
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {profile.longitude && profile.latitude ? (
                                        <Grid item xs={12} style={{ height: 500 }}>
                                            <GoogleMaps
                                                isMarkerShown
                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB7f0cc3w1EyMBqq0kn_fIvf6XPKzi8VWE&libraries=places"
                                                loadingElement={<div style={{ height: `100%` }} />}
                                                containerElement={<div style={{ height: `400px` }} />}
                                                mapElement={<div style={{ height: `100%` }} />}
                                                longitude={Number(profile.longitude)}
                                                latitude={Number(profile.latitude)}
                                            />
                                        </Grid>
                                    ) : (
                                        ""
                                    )}
                                {/* <iframe
                                    title="location"
                                    src={
                                        "https://www.google.com/maps/embed/v1/place?q=" +
                                        locationQueryString +
                                        "&zoom=17&key=AIzaSyDyEur187Yk2fqRIUWl_Dl-43YrljrJbew"
                                    }
                                    style={{ width: "100%", height: 400 }}
                                /> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );

        return (
            <div>
                <LoadingSpinner showed={this.props.templateStore.loadingScreenState} />
                <Header forceToggler />
                <Sidebar forceMobile />

                <div className="profile-header">
                    <div className="profile-header-information-container">
                        {profile &&
                            profile.id &&
                            profile.showAvatar && (
                                <div className="profile-header-inner">
                                    <Avatar classes={{ root: "profile-avatar" }} src={image.getProfileImage(profile)} alt={profile.fullName} />
                                </div>
                            )}
                        <div className="profile-header-text profile-header-inner">
                            <Typography type="headline" component="h3">
                                {profile && profile.fullName}
                            </Typography>
                            <Typography component="p">Venue</Typography>
                            <br />
                            {profile && (profile.city || profile.state) ? (
                                <Typography component="p">
                                    <LocationOnIcon classes={{ root: "profile-header-icon" }} />
                                    {profile.city && profile.state
                                        ? profile.city + ", " + profile.state
                                        : profile.city
                                            ? profile.city
                                            : profile.state
                                                ? profile.state
                                                : null}
                                </Typography>
                            ) : null}
                        </div>
                        <div className="profile-header-inner">
                            <SocialButton variant="webSite" urlData={profile && profile.webSite} />
                            <SocialButton variant="facebook" urlData={profile && profile.facebook} />
                            <SocialButton variant="twitter" urlData={profile && profile.twitter} />
                            <SocialButton variant="bandcamp" urlData={profile && profile.bandcamp} />
                            <SocialButton variant="soundcloud" urlData={profile && profile.soundcloud} />
                            <SocialButton variant="mixcloud" urlData={profile && profile.mixcloud} />
                            <SocialButton variant="youtube" urlData={profile && profile.youtube} />
                            <SocialButton variant="instagram" urlData={profile && profile.instagram} />
                            <SocialButton variant="googlePlus" urlData={profile && profile.googlePlus} />
                            <SocialButton variant="linkedIn" urlData={profile && profile.linkedIn} />
                            <SocialButton variant="iTunes" urlData={profile && profile.iTunes} />
                            <SocialButton variant="spotify" urlData={profile && profile.spotify} />
                        </div>
                        <div className="profile-header-inner">
                            {currentProfile && currentProfile.type === ProfileType.Agent.ordinal && profile.type === ProfileType.Venue.ordinal ? (
                                <Fragment>
                                    {canInviteVenue ? (
                                        <Fragment>
                                            <Button variant="contained" color="primary" className="actionButton" onClick={this.showVenueInvitationModal}>
                                                Invite Venue
                                            </Button>
                                            <VenueInvitationModal open={showVenueInvitationModal} onClose={this.hideVenueInvitationModal} />
                                        </Fragment>
                                    ) : null}
                                </Fragment>
                            ) : null}
                             {currentProfile ?
                                <Button variant="raised" color="primary" className="actionButton" onClick={(e) => {
                                        this.handleButtonMessage(e)
                                        }} >
                                    Message
                                </Button>
                                : ""
                            }
                            <MessageModal profile={profile} open={this.state.showMessageModal} closeHandler={this.hideMessageModal} />
                        </div>
                        {profile &&
                            profile.backgroundImages &&
                            profile.backgroundImages.length > 1 && (
                                <div className="profile-header-inner-slider">
                                    {profile.backgroundImages.map((backgroundImage, i) => {
                                        return (
                                            <div
                                                index={i}
                                                key={i}
                                                className={classNames(
                                                    "background-image-control",
                                                    this.state.backgroundImageIndex === i ? "active" : "inactive"
                                                )}
                                                onClick={this.manualChangeBackground(i)}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                    </div>
                    {profile &&
                        profile.backgroundImages && (
                            <React.Fragment>
                                {profile.backgroundImages.map((backgroundImage, i) => {
                                    return (
                                        <div
                                            className={classNames(
                                                "profile-header-background-image",
                                                this.state.backgroundImageIndex === i ? "active" : "inactive"
                                            )}
                                            style={{ backgroundImage: "url('" + backgroundImage.url1920 + "')" }}
                                            key={i}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        )}
                    {(!profile || !profile.backgroundImages || profile.backgroundImages.length === 0) && (
                        <React.Fragment>
                            <div
                                className={classNames("profile-header-background-image", "active")}
                                style={{ backgroundImage: "url('/hero2.jpg')" }}
                                key={0}
                            />
                        </React.Fragment>
                    )}
                </div>

                <div className="profile-body">
                    <Hidden smDown>
                        <div className="profile-sidebar">
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <div className="border-bottom-grid">
                                                <Typography type="headline" component="h4" className="profile-sidebar-title">
                                                    About {profile && profile.fullName}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} className="profile-bio-content">
                                            <Typography component="div">
                                                <div className="bio-section" dangerouslySetInnerHTML={{ __html: profile && profile.bio }} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {lowerSidebarContent}
                            </Grid>
                        </div>

                        {profileContent}
                    </Hidden>

                    <Hidden mdUp>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Paper style={{ width: "100%", backgroundColor: "#ff0c9c" }}>
                                    <Tabs
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        centered
                                        fullWidth
                                    >
                                        <Tab icon={<QueueMusicIcon />} label="Live Gigs" />
                                        <Tab icon={<ImportContactsIcon />} label="Bio" />
                                        <Tab icon={<PersonIcon />} label="Contact Us" />
                                    </Tabs>
                                </Paper>
                            </Grid>

                            {this.state.value === 0 && (
                                <Grid item xs={12}>
                                    {profileContent}
                                </Grid>
                            )}
                            {this.state.value === 1 && (
                                <Grid item xs={12}>
                                    <div className="profile-content">
                                        <WidgetContainer title={profile && profile.fullName + "'s Bio"} showBadge={false} style={{backgroundColor:"#333"}}>
                                            <Typography component="div">
                                                <div className="bio-section" dangerouslySetInnerHTML={{ __html: profile && profile.bio }} />{" "}
                                            </Typography>
                                        </WidgetContainer>
                                    </div>
                                </Grid>
                            )}
                            {this.state.value === 2 && (
                                <Grid item xs={12}>
                                    <div className="profile-content">
                                        <WidgetContainer title={profile && profile.fullName + "'s Contact"} showBadge={false}>
                                            {lowerSidebarContent}
                                        </WidgetContainer>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Hidden>
                </div>
            </div>
        );
    }
}

export default VenueProfile;
