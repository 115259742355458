import React, { Component } from "react";
import ModalContainer from "../modal/ModalContainer";

import { withRouter } from "react-router-dom";

import { Grid, DialogContent, Button, Typography } from "@material-ui/core";

import event from "../../img/event.svg";
import traveler from "../../img/traveler-with-a-suitcase.svg";

import "./PrivateBookingOptionModal.css";
import { ProfileType } from "../../types/enum";

@withRouter
class PrivateBookingOptionModal extends Component {
    render() {
        return (
            <ModalContainer open={this.props.open} onClose={this.props.onClose} fullScreen={false} maxWidth="sm">
                <DialogContent>
                    <Grid container justify="center" alignItems="center">
                    {!this.props.hidePrivateBooking && (this.props.type === ProfileType.Staff.ordinal || this.props.type === ProfileType.Agent.ordinal) ? (
                            <Grid item xs={12} lg={6}>
                                <div className="private-booking-type">
                                    <Button onClick={this.props.handleClickVenueBooking}>
                                        <Typography component="div" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={event} alt="Private Booking" />
                                            </div>
                                            <span className="title">Venue Booking</span>
                                        </Typography>
                                    </Button>
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )}
                        
                        {!this.props.hidePrivateBooking && !this.props.hideActBooking && this.props.type === ProfileType.Musician.ordinal ? (
                            <Grid item xs={12} lg={6}>
                                <div className="private-booking-type">
                                    <Button onClick={this.props.privateBookingHandler}>
                                        <Typography component="div" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={event} alt="Private Booking" />
                                            </div>
                                            <span className="title">Act Booking & Invoice</span>
                                        </Typography>
                                    </Button>
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )}

                        {!this.props.hidePrivateBooking && this.props.type === ProfileType.Agent.ordinal ? (
                            <Grid item xs={12} lg={6}>
                                <div className="private-booking-type">
                                    <Button onClick={this.props.privateBookingHandler}>
                                        <Typography component="div" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={event} alt="Private Booking" />
                                            </div>
                                            <span className="title">Private Booking</span>
                                        </Typography>
                                    </Button>
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )}

                        {!this.props.hidePrivateBooking && this.props.type === ProfileType.Musician.ordinal ? (
                            <Grid item xs={12} lg={6}>
                                <div className="private-booking-type">
                                    <Button onClick={this.props.handleClickVenueBooking}>
                                        <Typography component="div" type="subheading" color="inherit">
                                            <div className="type-image">
                                                <img src={event} alt="Private Booking" />
                                            </div>
                                            <span className="title" style={{width:"20rem"}}>Entertainer Booking & Invoice</span>
                                        </Typography>
                                    </Button>
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )}

                    {!this.props.hideManageAvailability && (
                        <Grid item xs={12} lg={6}>
                            <div className="private-booking-type">
                                <Button onClick={this.props.privateEventHandler}>
                                    <Typography component="div" type="subheading" color="inherit">
                                        <div className="type-image">
                                            <img src={traveler} alt="Manage Availability" />
                                        </div>
                                        <span className="title">Manage Availability</span>
                                    </Typography>
                                </Button>
                            </div>
                        </Grid>
                    )
                    }
                    </Grid>
                </DialogContent>
            </ModalContainer>
        );
    }
}

export default PrivateBookingOptionModal;
