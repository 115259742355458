import React, { Component,Fragment } from "react";
import { inject, observer } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";
import { DialogContent, DialogActions, Button, TextField, Grid,Hidden,MenuItem } from "@material-ui/core";
import ResponsiveTable from "../../table/ResponsiveTable";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import SelectField from "../../fields/SelectField";
import moment from "moment"
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import classNames from "classnames";
import CheckCircle from "@material-ui/icons/CheckCircle";

import ConfirmationModal from "../../modal/ConfirmationModal";

const ENABLE_QUICKBOOKS = process.env.REACT_APP_ENABLE_QUICKBOOKS === "true";
const ENABLE_XERO = process.env.REACT_APP_ENABLE_XERO === "true";
@inject("profileStore", "invoiceStore", "userStore", "xeroStore","templateStore","xeroLogStore")
@observer

class XeroMappedContactModal extends Component {
    state = {
        xeroActive: false,
        xeroAccount: "",
        editedId: "",
        updateProcess: false,
        xeroUID: "",
        mappedId: "",
        newPayerId:"",
        openRemoveContactModalState: false,
        removedId : null,
        editedXeroId: ""
    };
    customerMappingColumn = [
        { key: "Contacts", label: "Hot Giggity Contacts" },
        { key: "xeroContacts", label: "xero Contacts" },
        { key: "status", label: "Status" },
        { key: "action", label: "" }
    ];
    
    
    contactMappingRows = [];
    componentDidMount = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        
        if(currentProfile?.organisationOwner && currentProfile?.organisationOwner.id){
            currentProfile = currentProfile?.organisationOwner;
        }

        if(ENABLE_XERO && currentProfile && currentProfile.activateXeroAccess && currentProfile.xeroTenantId){
            Promise.all([
                this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                this.props.xeroStore.getXeroContacts(currentProfile.id)
            ]).then(() => { 
      
            });
        }
     
    };

    openRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: true,
            removedId : id
        });
    };
    closeRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: false,
            removedId : null
        });
    };
    handleMappedId = (contactId,xeroId) => {
        this.props.xeroStore.setEditedXero(contactId,xeroId)

    }
    setEditedContact = value =>  {
        const { editedXeroId } = this.state
        if(this.state.editedId && !editedXeroId){
            this.props.xeroStore.cancelEditedXero(this.state.editedId)
        }
        if(editedXeroId){
            this.props.xeroStore.cancelUpdatedXero(this.state.editedId)
        }
        this.setState({
            editedId: value,
            updateProcess: false,
            editedXeroId: ""
        });
    }

    setUpdatedContact = (data) =>  {
        const { editedXeroId } = this.state
        if(this.state.editedId && !editedXeroId){
            this.props.xeroStore.cancelEditedXero(this.state.editedId)
        }
        if(editedXeroId){
            this.props.xeroStore.cancelUpdatedXero(this.state.editedId)
        }
        this.props.xeroStore.setUpdatedXero(data.contactId)
        // this.props.xeroStore.cancelEditedXero(existingUID)
        this.setState({
            editedId: data.contactId,
            editedXeroId: data.xeroId,
            updateProcess: true
        });
    }

    cancelEditedContact = (contactId) =>{
       
        const { editedXeroId } = this.state
        if(editedXeroId){
            this.props.xeroStore.cancelUpdatedXero(contactId)
        }else{
            this.props.xeroStore.cancelEditedXero(contactId)
        }
        this.setState({
            editedId: "",
            editedXeroId: "",
            updateProcess:false
        });
        
        
    }
    

    handleDeleteContact = () => {
        const { removedId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(currentProfile?.organisationOwner && currentProfile?.organisationOwner.id){
            currentProfile = currentProfile?.organisationOwner;
        }
        this.props.templateStore.showLoadingScreen()
        this.props.xeroStore.deleteXeroMapping(removedId).then(result =>  {
            Promise.all([
                this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                this.props.xeroStore.getXeroContacts(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    openRemoveContactModalState: false,
                    removedId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
        
    }

    handleOpenConfirmCreateNewContact = id => {
    
        this.setState({
            confirmNewContactModalState: true,
            newcontactId: id
        });
    };

    handleCloseConfirmNewContactInvitationModal = () => {
        this.setState({
            ...this.state,
            confirmNewContactModalState: false,
            newcontactId: undefined
        });
    };

    saveEditedContact = (mappedId, contactId,xeroId) =>{
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(currentProfile?.organisationOwner && currentProfile?.organisationOwner.id){
            currentProfile = currentProfile?.organisationOwner;
        }
        this.props.templateStore.showLoadingScreen()
        if(this.state.updateProcess){
            this.props.xeroStore.updateContactXero(mappedId, xeroId).then(result =>  {
                Promise.all([
                    this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                    this.props.xeroStore.getXeroContacts(currentProfile.id)
                ]).then(() => { 
                    // this.props.xeroStore.cancelEditedXero(contactId)
                    this.setState({
                        editedId: "",
                        editedXeroId: ""
                    });
                });
              
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
               
            })
        }
        else{
            this.props.xeroStore.mapExistingXeroContact(currentProfile.id, contactId, xeroId).then(result =>  {
                Promise.all([
                    this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                    this.props.xeroStore.getXeroContacts(currentProfile.id)
                ]).then(() => { 
                    // this.props.xeroStore.cancelEditedXero(contactId)
                    this.setState({
                        editedId: ""
                    });
                });
              
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
               
            })
        }
       
        
    }
    handleCreateNewContact = () => {
        const { newcontactId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
         if(currentProfile?.organisationOwner && currentProfile?.organisationOwner.id){
            currentProfile = currentProfile?.organisationOwner;
        }
        this.props.templateStore.showLoadingScreen()
        this.props.xeroStore.createXeroContacts(currentProfile.id, newcontactId).then(result =>  {
            Promise.all([
                this.props.xeroStore.getListXeroMappedContacts(currentProfile.id),
                this.props.xeroStore.getXeroContacts(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    confirmNewContactModalState: false,
                    newcontactId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
       
    }

    handleClose = () => {

        this.props.invoiceStore.setPurchaseOrderCode(undefined);
        this.props.closeHandler();
        if(this.props.logId){
            this.props.xeroLogStore.markAsResolved(this.props.logId)
        }
      
    };

    getColumns = () => {
        const { hasQuickBooksAccount } = this.props.userStore;
        const columns = [
            { key: "date", label: "Invoice Date", sortable: true, isDate: true },
            { key: "dueDate", label: "Due Date", sortable: true, isDate: true },
            { key: "no", label: "Invoice No." },
            { key: "status", label: "Status" },
            { key: "total", label: "Total", sortable: true },
            { key: "payeeName", label: "Contact Name", sortable: true },
            { key: "payeePhone", label: "Contact Phone" },
            ...(ENABLE_QUICKBOOKS && hasQuickBooksAccount ? [{ key: "quickBooks", label: "QuickBooks" }] : []),
            { key: "action", label: "" }
        ];
        return columns;
    };
    renderFooterRow = invoices => {
        // if (invoices) console.log("match", invoices.slice());
        const { incomingInvoices } = this.props.invoiceStore;
        let total = 0;
        if (incomingInvoices && invoices) {
            const match = incomingInvoices.filter(incoming => {
                return invoices.some(x => x.id === incoming.id);
            });

            if (match) {
                total = match.reduce((a, b) => {
                    return a + b.total;
                }, 0);
            }
        }

        return {
            total: "$" + parseFloat(total).toLocaleString()
        };
    };

     mapContactsToRowData = () => {
        const { listXeroMappedContacts,xeroContacts,xeroContactsFull } = this.props.xeroStore;
        
        const {editedId} = this.state
        this.contactMappingRows = [];
        var selectedXeroMappedCustomers = listXeroMappedContacts.filter(x=> parseInt(x.contactId) === parseInt(this.props.selectedPayerId))
        selectedXeroMappedCustomers.forEach(data => {

            var row = {};

            row.Contacts = data.profileName ? data.profileName : data.firstName + " " + data.lastName

            if(data.xeroId && !data.isEdited && !data.isUpdated){
                var existingUID = xeroContactsFull.filter(x=>x.value === data.xeroId)
                if(existingUID.length){
                    row.xeroContacts = <TextField
                    // label="Xero Contact Name"
                    inputProps={{
                        readOnly: true
                    }}
                    value={existingUID[0].label}
                    fullWidth
                />
                }
               
            }else if(data.contactId != editedId){
                row.xeroContacts = <TextField
                // label="Xero Contact Name"
                inputProps={{
                    readOnly: true
                }}
                value="Map Xero Account"
                fullWidth
            />
            }else{
                row.xeroContacts =  <SelectField
                id="xeroContacts"
                value={data.xeroId}
                suggestions={xeroContacts}
                placeholder="Map Xero Account"
                disabled={data.contactId != editedId}
                onChange={(value) => this.handleMappedId(data.contactId,value)}
            />
            }
            
           
            
           
            row.status = data.xeroId ? ((data.isEdited || data.isUpdated) ? "Confirm?" : "Mapped" ) : "Not Mapped";

            if(data.xeroId && !data.isEdited && !data.isUpdated){
                row.action = (
                    <Fragment>
                         <Button size="small" color="primary" onClick={() => this.setUpdatedContact(data)}>
                            Update
                        </Button>
                        <Button size="small" color="primary" onClick={() => this.openRemoveContactModal(data.mappedId)}>
                            Remove
                        </Button>
                    </Fragment>
                );
    
            }else{
                if(data.isEdited || data.isUpdated){
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.saveEditedContact(data.mappedId,data.contactId,data.xeroId)}>
                                Save
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.cancelEditedContact(data.contactId)}>
                                Cancel
                            </Button>
                        </Fragment>
                        
                    );
                   
                }else{
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.setEditedContact(data.contactId)}>
                                Map Existing
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.handleOpenConfirmCreateNewContact(data.contactId)}>
                                Create New
                            </Button>
                        </Fragment>
                        
                    );
                }
                
            }
           
            // row.style = {
            //     backgroundColor: "#FFC0CB"
            // };

            this.contactMappingRows.push(row);
        });
        return Promise.resolve();
    };

    handleBatchSync = () => {
        const { invoices, type} = this.props
        var currentProfile = this.props.profileStore.currentProfile;
        let currentProfileId = this.props.profileStore.currentProfile?.id;
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfileId = currentProfile?.organisationOwner.id
        }

        var invoiceId = null
        if(invoices?.length){
            invoiceId = [invoices[0].id]
        }else{
            invoiceId = [this.props.relatedInvoiceId]
        }
        if (type === 0) {
            this.props.templateStore.showLoadingScreen();
            this.props.xeroStore.resetResultIntegration();
            this.props.xeroStore.integrateIncomingInvoice(currentProfileId, invoiceId).then(result => {
                if(result.length && result[0].type === 1){
                    this.props.invoiceStore.loadByPayerIdAndFilter(currentProfileId).finally(() => {

                        this.handleClose()
                    })
                }else{
                    this.props.templateStore.openSnackbar("Please define related supplier before resync invoice");
                }
            }).finally(() => {
                this.props.templateStore.hideLoadingScreen();
            });
        }
        if (type === 1) {
            this.props.templateStore.showLoadingScreen();
            this.props.xeroStore.resetResultIntegration();
            this.props.xeroStore.integrateOutgoingInvoice(currentProfileId, invoiceId).then(result => {
                if(result.length && result[0].type === 1){
                    this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfileId).finally(() => {
                        this.handleClose()
                    })
                }else{
                    this.props.templateStore.openSnackbar("Please define related customer before resync invoice");
                }
            }).finally(() => {
                this.props.templateStore.hideLoadingScreen();
            });
        }
    }
    render() {
        const { isLoading,tabValue, isUpdate} = this.state;
        const { invoices, open } = this.props;
        const { xeroContactsFull,invoicexeroId,xeroFirstName,xeroLastName,xeroEmail,xeroDisplayId } = this.props.xeroStore;
        this.mapContactsToRowData()
        return (
            <ModalContainer
                open={open}
                title={"Map Invoice with Xero Contact"}
                maxWidth="md"
                fullScreen={false}
                onClose={this.handleClose}
                isLoading={isLoading}
            >
                <DialogContent>
                    <Grid container spacing={8}>
                        { invoices?.length ?  <Grid item xs={12}>
                            <ResponsiveTable columns={this.getColumns()}
                              rows={invoices.map(invoice => {
                                var row = {};
                                row.id = invoice.id;
                                row.date = moment.utc(invoice.date).format("DD MMM YYYY");
                                row.dueDate = moment.utc(invoice.dueDate).format("DD MMM YYYY");
                                row.no = invoice.no;

                                row.status =
                                    invoice.status === "pending" ? (
                                        invoice.immediateInvoice ? (
                                            <Tooltip
                                                title={
                                                    "This invoice is waiting for gig date at " +
                                                    moment(invoice.date).format("DD MMM YYYY")
                                                }
                                                placement="bottom"
                                            >
                                                <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={
                                                    "This invoice is waiting for payer's billing cycle at " +
                                                    moment(invoice.date).format("DD MMM YYYY")
                                                }
                                                placement="bottom"
                                            >
                                                <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                            </Tooltip>
                                        )
                                    ) : invoice.status === "overdue" ? (
                                        <Tooltip
                                            title={
                                                "This invoice is " +
                                                moment(new Date()).diff(moment(invoice.dueDate), "days") +
                                                " day(s) past due date"
                                            }
                                            placement="bottom"
                                        >
                                            <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                        </Tooltip>
                                    ) : (
                                        <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                    );
                                const currencySymbol = invoice && invoice.payee && invoice.payee.currency === "GBP" ? "£" : "$";
                                row.total = currencySymbol + parseFloat(invoice.total).toLocaleString();
                                row.subTotal = currencySymbol + parseFloat(invoice.subTotal).toLocaleString();
                                row.tax = currencySymbol + parseFloat(invoice.tax).toLocaleString();
                                row.payerName = invoice.payerName;
                                row.payerPhone = invoice.payerPhone;
                                row.syncxero = invoice.isxeroSyncronise ? <CheckCircle style={{ texAlign:"center",color: "green"}} />  : null;
                                row.quickBooks = (
                                    <Button
                                        size="small"
                                        color="primary"
                                        className="btn-sync-quickbooks"
                                        component={Link}
                                        to={`/invoice/${invoice.id}/quickbooks`}
                                    >
                                        Sync
                                    </Button>
                                );
                                row.action = (
                                    <div>
                                        <Button
                                            size="small"
                                            color="primary"
                                            className="btn-invoice-detail"
                                            component={Link}
                                            to={`/invoice/${invoice.id}/detail`}
                                        >
                                            Detail
                                        </Button>
                                    </div>
                                );
                                

                                return row;
                            })}
                             footerRow={this.renderFooterRow(invoices)} />
                        </Grid> : null}
                       
                        <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={12} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item md={12}>
                                                        <ResponsiveTable
                                                            columns={this.customerMappingColumn}
                                                            rows={this.contactMappingRows}
                                                            showToolbar={true}
                                                            title="Customer Mapping"
                                                            pagination={this.contactMappingRows.length > 5}
                                                            addSpace={true}
                                                        />

                                                    </Grid>  
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>
                <ConfirmationModal
                    open={this.state.confirmNewContactModalState}
                    message="Are you sure you want to create a new xero contact for this contact?"
                    closeHandler={this.handleCloseConfirmNewContactInvitationModal}
                    confirmationHandler={this.handleCreateNewContact}
                    declineHandler={this.handleCloseConfirmNewContactInvitationModal}
                    confirmationLabel="Confirm"
                />
                 <ConfirmationModal
                    open={this.state.openRemoveContactModalState}
                    message="Are you sure you want to remove the existing connection to xero contact?"
                    closeHandler={this.closeRemoveContactModal}
                    confirmationHandler={this.handleDeleteContact}
                    declineHandler={this.closeRemoveContactModal}
                    confirmationLabel="Confirm"
                />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.handleBatchSync}>Sync Invoice</Button>
                    <Button onClick={this.handleClose}>Close</Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default XeroMappedContactModal;
