import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Grid,
    Divider,
    Button,
    Chip,
} from "@material-ui/core";
import moment from "moment";

import "./GigPromotionCard.css";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

const style = {
    promotionStatus: {
        minWidth: 50,
        color: "#ffffff"
    },
    status: {
        draft: {
            backgroundColor: "#757575"
        },
        pending: {
            backgroundColor: "#1565c0"
        },
        published: {
            backgroundColor: "#43a047"
        },
        completed: {
            backgroundColor: "#bdbdbd"
        }
    },
    promotionHeader: {
        height: "150px"
    },
    promotionImage: {
        height: "250px",
        width: "100%"
    },
    buttonContainer: {
        height: "52px",
        width: "100%"
    },
    previewButton: {
        marginLeft: "auto",
        marginRight: 0
    },
    promotionTitleTooltipContainer: {
        width: "300px",
        padding: 8
    },
    promotionTitleTooltip: {
        fontSize: "16px",
        color: "#ffffff"
    }
};

@withRouter
@inject("gigPromotionStore", "templateStore")
@observer
class GigPromotionCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            cancelModalState: false,
        }
    }

    openCancelModal = () => this.setState({ ...this.state, cancelModalState: true });
    closeCancelModal = () => this.setState({ ...this.state, cancelModalState: false });

    getPromotionStatus = promotion => {
        if (moment().endOf("day") > moment(promotion.dateStart).endOf("day") && promotion.published) {
            return "Completed";
        } else if (promotion.published) {
            return "Published";
        } else if (promotion.isDraft) {
            return "Draft";
        } else {
            return "Pending";
        }
    };

    getGigPromotionTitle = gigPromotion => {
        if(gigPromotion.title){
            return gigPromotion.title;
        }
        else{
            return `${gigPromotion.actName ? gigPromotion.actName : ""}@${gigPromotion?.venue?.profileName ? gigPromotion.venue.profileName : ""}`;
        }
    }

    getGigPromotionDateTime = gigPromotion => {
        const date = new Date(gigPromotion.dateStart);
        let date_utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        
        var timeStart = moment(gigPromotion.timeStart, "HH:mm").format('hh:mm A');
        var timeEnd = moment(gigPromotion.timeEnd, "HH:mm").format('hh:mm A');

        return `${moment(date_utc).format('DD-MM-YYYY')} / ${timeStart} - ${timeEnd}`
    }

    handleEditGigPromotion = () => {
        const { gigPromotion } = this.props;
        this.props.templateStore.history.push(`/gig/promotion/${gigPromotion.id}/edit`);
    };

    handleCancelGigPromotion = () => {
        this.props.cancelGigPromotionHandler();
        this.closeCancelModal();
    }

    render() {
        const { gigPromotion, hideActionsButton } = this.props;
        const { cancelModalState } = this.state;
        return gigPromotion ? (
            <Fragment>
                <Card>
                    <CardHeader
                        title={ this.getGigPromotionTitle(gigPromotion) }
                        subheader={ this.getGigPromotionDateTime(gigPromotion) }
                        action={
                            <Chip
                                label={this.getPromotionStatus(gigPromotion)}
                                style={{ ...style.promotionStatus, ...style.status[this.getPromotionStatus(gigPromotion).toLowerCase()] }}
                            />
                        }
                        style={style.promotionHeader}
                    />
                    <Divider />
                    <CardMedia image={gigPromotion.imageUrl} style={style.promotionImage} />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={6}>
                                <i className="fa fa-building" />  {gigPromotion.venue?.profileName}
                            </Grid>
                            <Grid item xs={6}>
                                <i className="mh-band" /> {gigPromotion.actName}
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ paddingTop: "16px", minHeight: "150px", maxHeight: "150px", overflow: "hidden" }}>
                                    About Act: 
                                    <div dangerouslySetInnerHTML={{ __html: gigPromotion.actDescription }} />
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions style={style.buttonContainer}>
                        {
                            !hideActionsButton ? (
                                <Fragment>
                                    <Button variant="text" color="primary" onClick={ this.handleEditGigPromotion }>
                                        Edit
                                    </Button>
                                    <Button onClick = { this.openCancelModal }>
                                        Cancel
                                    </Button>
                                </Fragment>
                            ) : null
                        }
                    </CardActions>
                </Card>
                <ConfirmationModal 
                    open = { cancelModalState }
                    title = "Gig Promotion Warning"
                    closeHandler = { this.closeCancelModal }
                    message = "Are you sure to cancel this gig promotion?"
                    confirmationLabel = "Cancel"
                    confirmationHandler = { this.handleCancelGigPromotion }
                />
            </Fragment>
        ) : null;
    }
}

export default GigPromotionCard;
