import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import BookingRequests from "../../components/booking/BookingRequests";
import BookingAccepted from "../../components/booking/BookingAccepted";
import BookingCompleted from "../../components/booking/BookingCompleted";
import BookingCancelled from "../../components/booking/BookingCancelled";
import { Tabs, Tab, Badge, Hidden, TextField, MenuItem } from "@material-ui/core";
import Loading from "../../components/loadingSpinner/Loading";

import ReportDateFilterGigs from "../../components/reports/ReportDateFilterGigs";

import "./MusicianBookings.css";
import FacebookSharePostDialog from "../../components/facebookSharePostDialog/FacebookSharePostDialog";
import BookingDrafts from "../../components/booking/BookingDrafts";
import BookingAll from "../../components/booking/BookingAll";

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("templateStore", "profileStore", "bookingRequestStore", "reportStore")
@withRouter
@observer
class MusicianBookings extends Component {
    state = {
        tabValue: 0
    };

    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0
        };
        this.props.bookingRequestStore.resetRequestGigs()
        this.props.bookingRequestStore.resetRequestsUpcomingCompleted();
        this.props.reportStore.resetFilterData();
    }

    componentWillUnmount() {
        this.props.reportStore.resetFilterData();
    }

    loadGigs = () => {
        const { currentProfile } = this.props.profileStore;
        // this.setState({
        //     ...this.state,
        //     isLoading: true
        // });
        this.props.bookingRequestStore.showLoadingState();
        this.props.bookingRequestStore.resetRequestGigs()
        Promise.all([
            this.props.bookingRequestStore.loadGigsRequests(currentProfile?.id,1),
        ]).then(() => {
            this.setState({
                ...this.state,
                isLoading: false
            });
            this.props.bookingRequestStore.hideLoadingState();
        });
        if(this.state.tabValue == 3){
            this.props.bookingRequestStore.resetCompletedGigs()
            Promise.all([
                this.props.bookingRequestStore.getCompletedGigs(currentProfile?.id,1),
            ]).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.bookingRequestStore.hideLoadingState();
            });
        }else if(this.state.tabValue == 0 || this.state.tabValue == 1){
           
        }else if(this.state.tabValue == 2){
            this.props.bookingRequestStore.resetUpcomingGigs()
            Promise.all([
                this.props.bookingRequestStore.loadUpcomingGigs(currentProfile?.id,1),
            ]).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.bookingRequestStore.hideLoadingState();
            });
        }else if(this.state.tabValue == 4){
            this.props.bookingRequestStore.resetCancelledGigs()
            Promise.all([
                this.props.bookingRequestStore.getCancelledGigs(currentProfile?.id,1)
            ]).then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                this.props.bookingRequestStore.hideLoadingState();
            });
        } else if(this.state.tabValue == 5){
            this.props.bookingRequestStore.resetAllGigs()
            Promise.all([
                this.props.bookingRequestStore.loadAllGigs(currentProfile?.id,1)
            ]).then((result) => {
                this.setState({
                    ...this.state,
                    isLoading: false,
                });
                this.props.bookingRequestStore.hideLoadingState();
            });
        }
    };

    componentDidMount = () => {
        const {
            currentProfile
        } = this.props.profileStore;
        
        this.props.bookingRequestStore.showLoadingState();
        

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.tab) {
            if (this.props.match.params.tab === "drafts") {
                this.setState({
                    tabValue: 0
                });
            } else if (this.props.match.params.tab === "request") {
                this.setState({
                    tabValue: 1
                });
            } else if (this.props.match.params.tab === "upcoming") {
                this.setState({
                    tabValue: 2
                });
            } else if (this.props.match.params.tab === "cancelled") {
                this.setState({
                    tabValue: 4
                });
            } else if (this.props.match.params.tab === "all") {
                this.setState({
                    tabValue: 5
                });
            }
            if (this.props.match.params.tab === "completed") {
                this.setState({
                    tabValue: 3
                });
            }
        }
        this.props.bookingRequestStore.loadGigsRequests(currentProfile?.id,1).then(() => {
            this.props.bookingRequestStore.hideLoadingState();
        })
    };

    handleTabChange = (event, value) => {
        switch (value) {
            case 0:
                this.props.history.replace("/musician/gig/drafts");
                break;
            case 1:
                this.props.history.replace("/musician/gig/request");
                break;
            case 2:
                this.props.history.replace("/musician/gig/upcoming");
                break;
            case 3:
                this.props.history.replace("/musician/gig/completed");
                break;
            case 4:
                this.props.history.replace("/musician/gig/cancelled");
                break;
            case 5:
                this.props.history.replace("/musician/gig/all");  
                break;  
            default:
                this.props.history.replace("/musician/gig/drafts");
                break;
        }

        this.setState({
            tabValue: value
        });
    };

    handleSelectChange = event => {
        switch (event.target.value) {
            case 0:
                this.props.history.replace("/musician/gig/drafts");
                break;
            case 1:
                this.props.history.replace("/musician/gig/request");
                break;
            case 2:
                this.props.history.replace("/musician/gig/upcoming");
                break;
            case 3:
                this.props.history.replace("/musician/gig/completed");
                break;
            case 4:
                this.props.history.replace("/musician/gig/cancelled");
                break;
            case 5:
                this.props.history.replace("/musician/gig/all");
                break;
            default:
                this.props.history.replace("/musician/gig/drafts");
                break;
        }

        this.setState({
            tabValue: event.target.value
        });
    };

    renderTabComponent = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0:
                return <BookingDrafts profileType="musician" />;
            case 1:
                return <BookingRequests profileType="musician" />;
            case 2:
                return <BookingAccepted profileType="musician" />;
            case 3:
                return <BookingCompleted profileType="musician" />;
            case 4:
                return <BookingCancelled profileType="musician" />;
            case 5:
                return <BookingAll profileType="musician" />;
            default:
                return "";
        }
    };

    renderTabTitle = (title, list) => {
        return list && list.length > 0 ? (
            <Badge color="primary" style={{ paddingRight: "16px" }} badgeContent={list ? list.length : 0}>
                {title}
            </Badge>
        ) : (
            title
        );
    };

    renderTabTitleSmall = (title, list) => {
        let content = <span className="test">{list ? list.length : 0}</span>;
        return list && list.length > 0 ? (
            <Badge color="primary" classes={{ badge: "itembadge" }} badgeContent={content}>
                {title}
            </Badge>
        ) : (
            title
        );
    };

    render() {
        const breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "My Gigs" }];
        let { requests, upcomings, cancelled, completed, drafts, newRequests, isLoading: isLoadingGigs, allGigs } = this.props.bookingRequestStore;
        const { isLoading } = this.state;

        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="My Gigs">
                        {
                            this.state.tabValue > 1 ? <ReportDateFilterGigs postChangeEvent={this.loadGigs} /> : null
                        }
                       
                    </Heading>
                    <Paper style={{ position: "relative" }} className="booking-paper-form">
                        <Loading showed={isLoadingGigs} />
                        <div style={{ padding: 16 }}>
                            <Hidden smDown>
                                <Tabs
                                    value={this.state.tabValue}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    scrollable
                                    scrollButtons="off"
                                >
                                    <Tab label={this.renderTabTitle("Draft Bookings", drafts)} />
                                    <Tab label={this.renderTabTitle("Booking Requests", newRequests)} />
                                    <Tab label={this.renderTabTitle("Upcoming Gigs", upcomings)} />
                                    <Tab label={this.renderTabTitle("Completed Gigs", completed)} />
                                    <Tab label={this.renderTabTitle("Cancelled Gigs", cancelled)} />
                                    <Tab label={this.renderTabTitle("All Gigs", allGigs)} />
                                </Tabs>
                                <br />
                            </Hidden>
                            <Hidden mdUp>
                                <TextField
                                    select
                                    value={this.state.tabValue}
                                    onChange={this.handleSelectChange}
                                    textColor="primary"
                                    style={{ marginBottom: 20 }}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>
                                        {this.renderTabTitleSmall("Draft Bookings", drafts)}
                                    </MenuItem>
                                    <MenuItem key={1} value={1}>
                                        {this.renderTabTitleSmall("Booking Requests", requests)}
                                    </MenuItem>
                                    <MenuItem key={2} value={2}>
                                        {this.renderTabTitleSmall("Upcoming Gigs", upcomings)}
                                    </MenuItem>
                                    <MenuItem key={3} value={3}>
                                        {this.renderTabTitleSmall("Completed Gigs", completed)}
                                    </MenuItem>
                                    <MenuItem key={4} value={4}>
                                        {this.renderTabTitleSmall("Cancelled Gigs", cancelled)}
                                    </MenuItem>
                                    <MenuItem key={5} value={5}>
                                        {this.renderTabTitleSmall("All Gigs", allGigs)}
                                    </MenuItem>
                                </TextField>
                            </Hidden>
                            {this.renderTabComponent()}
                        </div>
                    </Paper>
                </MainContainer>
            </div>
        );
    }
}

MusicianBookings.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(MusicianBookings);
