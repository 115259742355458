import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import profileStore from "./profileStore";
import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";
import validate from "../helper/validation.js"
import moment from "moment"
class FinancialProfileStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable userId = 0;
    @observable type = 0; // 1 == musician

    @observable bankName = "";
    @observable bsbNumber = "";
    @observable accountNumber = "";
    @observable accountDescription = "";
    @observable abn1 = "";
    @observable abn2 = "";
    @observable abn3 = "";
    @observable abn4 = "";
    @observable abn = "";
    @observable currency = "";
    @observable tax = "";
    @observable taxValue = 0;
    @observable monthlyBudget = 0;
    @observable paymentTerms = "";
    @observable outgoingPaymentTerms = "";
    @observable paymentOptions = "";
    @observable nextInvoiceNumber = "";
    @observable pushNotification = false;
    @observable emailNotification = false;
    @observable monthlyBudgetType = false;
    @observable autoSend = false
    @observable autoSendTime = "24:00:00";
    @observable autoSendDay = "";
    @observable cCInvoiceEmailAddress = "";
    @observable nextInvoiceNumberInitial = "";

    
    @observable streetIncoming = "";
    @observable cityIncoming = "";
    @observable postalIncoming = "";
    @observable stateIncoming = "";
    @observable countryCodeIncoming = "";
    @observable longitudeIncoming = 0;
    @observable latitudeIncoming = 0;
    @observable streetOutgoing = "";
    @observable cityOutgoing = "";
    @observable postalOutgoing = "";
    @observable stateOutgoing = "";
    @observable countryCodeOutgoing = "";
    @observable longitudeOutgoing = 0;
    @observable latitudeOutgoing = 0;
    // @observable insuranceName = '';
    // @observable insuranceCompany = '';
    // @observable policyNumber = '';
    // @observable insuranceExpiryDate = ''

    @observable januaryBudget = 0;
    @observable februaryBudget = 0;
    @observable marchBudget = 0;
    @observable aprilBudget = 0;
    @observable mayBudget = 0;
    @observable juneBudget = 0;
    @observable julyBudget = 0;
    @observable augustBudget = 0;
    @observable septemberBudget = 0;
    @observable octoberBudget = 0;
    @observable novemberBudget = 0;
    @observable decemberBudget = 0;
    @observable incomingToggleAddress = false;
    @observable outgoingToggleAddress = false
   
    @observable searchedAddressIncoming = ""
    @observable incomingInvoiceEmail = ""
    @observable incomingEntityName = ""
    @observable outgoingEntityName = ""
    @observable outgoingInvoiceEmail = ""
    @observable searchedAddressOutgoing = ""
   


    @action
    setSearchedAddressIncoming(value){
        this.searchedAddressIncoming = value
    }
    @action
    setIncomingInvoiceEmail(value){
        this.incomingInvoiceEmail = value
    }
    @action
    setIncomingEntityName(value){
        this.incomingEntityName = value
    }
    @action
    setOutgoingEntityName(value){
        this.outgoingEntityName = value
    }
    @action
    setOutgoingInvoiceEmail(value){
        this.outgoingInvoiceEmail = value
    }
    @action
    setSearchedAddressOutgoing(value){
        this.searchedAddressOutgoing = value
    }
    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        const { id } = generalProfileStore;

        profileId = profileId ? profileId : id;
        if (currentUser) {
            this.inProgress = true;
            // templateStore.showLoadingScreen();
            if ((this.type === ProfileType.Act.ordinal || this.type === ProfileType.Venue.ordinal) && profileId) {
                return profileStore
                    .getProfile(profileId)
                    .then(
                        action(profile => {
                            console.log(profile)
                            if (!profile) throw new Error("Can't load profile");
                            this.bankName = profile.bankName || "";
                            this.bsbNumber = profile.bsbNumber || "";
                            this.accountNumber = profile.accountNumber || "";
                            this.accountDescription = profile.accountDescription || "";

                            var abn = profile.abn
                            this.abn = abn
                            this.abn1 = abn?.slice(0, 2) || "";
                            this.abn2 = abn?.slice(2, 5) || "";
                            this.abn3 = abn?.slice(5, 8) || "";
                            this.abn4 =  abn?.slice(8, 11) || "";
                            // this.abn = profile.abn || "";
                            this.currency = profile.currency || "";
                            this.tax = profile.tax || "";
                            this.taxValue = profile.taxValue || 0;
                            this.monthlyBudget = !isNaN(profile.monthlyBudget) ? Number(profile.monthlyBudget).toFixed(2) : "";
                            this.paymentTerms = profile.paymentTerms || "";
                            this.outgoingPaymentTerms = profile.outgoingPaymentTerms || "";
                            this.paymentOptions = profile.paymentOptions || "";
                            this.nextInvoiceNumber = profile.nextInvoiceNumber || "1";
                            this.nextInvoiceNumberInitial = profile.nextInvoiceNumber || "1";
                            this.autoSend = profile.autoSend;
                            this.incomingToggleAddress = profile.incomingToggleAddress
                            this.incomingInvoiceEmail = profile.incomingInvoiceEmail
                            this.incomingEntityName = profile.incomingEntityName
                          
                            this.outgoingToggleAddress = profile.outgoingToggleAddress
                            this.outgoingEntityName = profile.outgoingEntityName
                            this.outgoingInvoiceEmail = profile.outgoingInvoiceEmail

                            this.streetIncoming = profile.streetIncoming;
                            this.cityIncoming = profile.cityIncoming;
                            this.postalIncoming = profile.postalIncoming;
                            this.stateIncoming = profile.stateIncoming;
                            this.countryCodeIncoming = profile.countryCodeIncoming;
                            this.longitudeIncoming = profile.longitudeIncoming;
                            this.latitudeIncoming = profile.latitudeIncoming;
                            this.streetOutgoing = profile.streetOutgoing;
                            this.cityOutgoing = profile.cityOutgoing;
                            this.postalOutgoing = profile.postalOutgoing;
                            this.stateOutgoing = profile.stateOutgoing;
                            this.countryCodeOutgoing = profile.countryCodeOutgoing;
                            this.longitudeOutgoing = profile.longitudeOutgoing;
                            this.latitudeOutgoing = profile.latitudeOutgoing;

                            if(profile.autoSendTime){
                                this.autoSendTime = profile.autoSendTime;
                            }
                            this.autoSendDay = profile.autoSendDay;
                            this.pushNotification = profile.pushNotification;
                            this.emailNotification = profile.emailNotification;
                            this.id = profile.id;
                            this.type = profile.type;
                            if (profile.monthlyBudgetType === 1){
                                this.monthlyBudgetType = true
                            } else {
                                this.monthlyBudgetType = false
                            }
                            this.cCInvoiceEmailAddress = profile.ccInvoiceEmailAddress;
                            this.januaryBudget= !isNaN(profile.januaryBudget) ? Number(profile.januaryBudget).toFixed(2) : "";
                            this.februaryBudget= !isNaN(profile.februaryBudget) ? Number(profile.februaryBudget).toFixed(2) : "";
                            this.marchBudget= !isNaN(profile.marchBudget) ? Number(profile.marchBudget).toFixed(2) : "";
                            this.aprilBudget= !isNaN(profile.aprilBudget) ? Number(profile.aprilBudget).toFixed(2) : "";
                            this.mayBudget= !isNaN(profile.mayBudget) ? Number(profile.mayBudget).toFixed(2) : "";
                            this.juneBudget= !isNaN(profile.juneBudget) ? Number(profile.juneBudget).toFixed(2) : "";
                            this.julyBudget= !isNaN(profile.julyBudget) ? Number(profile.julyBudget).toFixed(2) : "";
                            this.augustBudget= !isNaN(profile.augustBudget) ? Number(profile.augustBudget).toFixed(2) : "";
                            this.septemberBudget= !isNaN(profile.septemberBudget) ? Number(profile.septemberBudget).toFixed(2) : "";
                            this.octoberBudget= !isNaN(profile.octoberBudget) ? Number(profile.octoberBudget).toFixed(2) : "";
                            this.novemberBudget= !isNaN(profile.novemberBudget) ? Number(profile.novemberBudget).toFixed(2) : "";
                            this.decemberBudget= !isNaN(profile.decemberBudget) ? Number(profile.decemberBudget).toFixed(2) : "";
                            // this.insuranceName = profile.insuranceName;
                            // this.insuranceCompany = profile.insuranceCompany;
                            // this.policyNumber = profile.policyNumber;
                            // this.insuranceExpiryDate = profile.insuranceExpiryDate
                            // this.searchedAddressOutgoing = `${this.streetOutgoing}, ${this.cityOutgoing}, ${this.postalOutgoing}, ${this.stateOutgoing}, ${this.countryCodeOutgoing}`
                            // this.searchedAddressIncoming = `${this.streetIncoming}, ${this.cityIncoming}, ${this.postalIncoming}, ${this.stateIncoming}, ${this.countryCodeIncoming}`
                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            } else if (this.type !== ProfileType.Act.ordinal && this.type !== ProfileType.Venue.ordinal) {
                return profileStore
                    .loadProfile(currentUser.sub, this.type, true)
                    .then(
                        action(profile => {
                            if (!profile) {
                                this.reset();
                                throw new Error("Can't load profile");
                            }
                            this.bankName = profile.bankName || "";
                            this.bsbNumber = profile.bsbNumber || "";
                            this.accountNumber = profile.accountNumber || "";
                            this.accountDescription = profile.accountDescription || "";
                            var abn = profile.abn
                            this.abn = abn
                            this.abn1 = abn?.slice(0, 2) || "";
                            this.abn2 = abn?.slice(2, 5) || "";
                            this.abn3 = abn?.slice(5, 8) || "";
                            this.abn4 =  abn?.slice(8, 11) || "";
                            this.currency = profile.currency || "";
                            this.tax = profile.tax || "";
                            this.taxValue = profile.taxValue || 0;
                            this.monthlyBudget = !isNaN(profile.monthlyBudget) ? Number(profile.monthlyBudget).toFixed(2) : "";
                            this.paymentTerms = profile.paymentTerms || "";
                            this.outgoingPaymentTerms = profile.outgoingPaymentTerms || "";
                            this.paymentOptions = profile.paymentOptions || "";
                            this.nextInvoiceNumber = profile.nextInvoiceNumber || "1";
                            this.nextInvoiceNumberInitial = profile.nextInvoiceNumber || "1";
                            this.pushNotification = profile.pushNotification;
                            this.emailNotification = profile.emailNotification;
                            this.id = profile.id;
                            this.type = profile.type;
                            this.cCInvoiceEmailAddress = profile.ccInvoiceEmailAddress;

                            this.incomingToggleAddress = profile.incomingToggleAddress
                            this.incomingInvoiceEmail = profile.incomingInvoiceEmail
                            this.incomingEntityName = profile.incomingEntityName

                            this.outgoingToggleAddress = profile.outgoingToggleAddress
                            this.outgoingEntityName = profile.outgoingEntityName
                            this.outgoingInvoiceEmail = profile.outgoingInvoiceEmail

                            this.streetIncoming = profile.streetIncoming;
                            this.cityIncoming = profile.cityIncoming;
                            this.postalIncoming = profile.postalIncoming;
                            this.stateIncoming = profile.stateIncoming;
                            this.countryCodeIncoming = profile.countryCodeIncoming;
                            this.longitudeIncoming = profile.longitudeIncoming;
                            this.latitudeIncoming = profile.latitudeIncoming;
                            this.streetOutgoing = profile.streetOutgoing;
                            this.cityOutgoing = profile.cityOutgoing;
                            this.postalOutgoing = profile.postalOutgoing;
                            this.stateOutgoing = profile.stateOutgoing;
                            this.countryCodeOutgoing = profile.countryCodeOutgoing;
                            this.longitudeOutgoing = profile.longitudeOutgoing;
                            this.latitudeOutgoing = profile.latitudeOutgoing;
                            // this.insuranceName = profile.insuranceName;
                            // this.insuranceCompany = profile.insuranceCompany;
                            // this.policyNumber = profile.policyNumber;
                            // this.insuranceExpiryDate = profile.insuranceExpiryDate
                            // this.searchedAddressOutgoing = `${this.streetOutgoing}, ${this.cityOutgoing}, ${this.postalOutgoing}, ${this.stateOutgoing}, ${this.countryCodeOutgoing}`
                            // this.searchedAddressIncoming = `${this.streetIncoming}, ${this.cityIncoming}, ${this.postalIncoming}, ${this.stateIncoming}, ${this.countryCodeIncoming}`
                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            }
        } else {
            return Promise.resolve();
        }
    }

    @action
    reset() {
        this.incomingToggleAddress = false;
        this.outgoingToggleAddress = false;
        this.bankName = "";
        this.bsbNumber = "";
        this.accountNumber = "";
        this.accountDescription = "";
        this.abn = "";
        this.abn1 = "";
        this.abn2 = "";
        this.abn3 = "";
        this.abn4 = "";
        this.currency = "";
        this.tax = "";
        this.taxValue = 0;
        this.monthlyBudget = "";
        this.paymentTerms = "";
        this.outgoingPaymentTerms = "";
        this.paymentOptions = "";
        this.nextInvoiceNumber = "";
        this.pushNotification = false;
        this.emailNotification = false;
        this.cCInvoiceEmailAddress = "";
        this.monthlyBudgetType = false
        this.januaryBudget = 0;
        this.februaryBudget = 0;
        this.marchBudget = 0;
        this.aprilBudget = 0;
        this.mayBudget = 0;
        this.juneBudget = 0;
        this.julyBudget = 0;
        this.augustBudget = 0;
        this.septemberBudget = 0;
        this.octoberBudget = 0;
        this.novemberBudget = 0;
        this.decemberBudget = 0;
        // this.insuranceName = '';
        // this.insuranceCompany = '';
        // this.policyNumber = '';
        // this.insuranceExpiryDate = ''
    }

    @action
    setIncomingToggleAddress(){
        this.incomingToggleAddress = !this.incomingToggleAddress
    }

    @action
    setOutgoingToggleAddress(){
        this.outgoingToggleAddress = !this.outgoingToggleAddress
    }

    @action
    setBankName(bankName) {
        this.bankName = bankName;
    }

    @action
    setBsbNumber(bsbNumber) {
        this.bsbNumber = bsbNumber;
    }

    @action
    setAccountNumber(accountNumber) {
       
        this.accountNumber = accountNumber;
    }

    @action
    setAccountDescription(accountDescription) {
        this.accountDescription = accountDescription;
    }

    @action
    setAbn(abn) {
        this.abn = abn;
    }

    @action
    setStreetOutgoing(value) {
        this.streetOutgoing = value;
    }
    @action
    setCityOutgoing(value) {
        this.cityOutgoing = value;
    }
    @action
    setPostalOutgoing(value) {
        this.postalOutgoing = value;
    }
    @action
    setStateOutgoing(value) {
        this.stateOutgoing = value;
    }
    @action
    setCountryCodeOutgoing(value) {
        this.countryCodeOutgoing = value;
    }
    @action
    setLongitudeLatitudeOutgoing = value => {
        this.longitudeOutgoing = value.lng;
        this.latitudeOutgoing = value.lat;
    };
    
    @action
    setStreetIncoming(value) {
        this.streetIncoming = value;
    }
    @action
    setCityIncoming(value) {
        this.cityIncoming = value;
    }
    @action
    setPostalIncoming(value) {
        this.postalIncoming = value;
    }
    @action
    setStateIncoming(value) {
        this.stateIncoming = value;
    }
    @action
    setCountryCodeIncoming(value) {
        this.countryCodeIncoming = value;
    }
    @action
    setLongitudeLatitudeIncoming = value => {
        this.longitudeIncoming = value.lng;
        this.latitudeIncoming = value.lat;
    };

    @action
    setAbn1(abn) {
        this.abn1 = abn;
    }

    @action
    setAutoSend() {
        this.autoSend = !this.autoSend;
    }
    
    @action
    setAutoSendTime(value) {
        this.autoSendTime = value;
    }

    @action
    setAutoSendDay(value) {
        this.autoSendDay = value;
    }

    @action
    setAbn2(abn) {
        this.abn2 = abn;
    }

    @action
    setAbn3(abn) {
        this.abn3 = abn;
    }

    @action
    setAbn4(abn) {
        this.abn4 = abn;
    }

    

    @action
    setCurrency(currency) {
        this.currency = currency;
    }

    @action
    setTax(tax) {
        this.tax = tax;
    }

    // @action
    // setInsuranceName(insuranceName) {
    //     this.insuranceName = insuranceName
    // }

    // @action
    // setInsuranceCompany(insuranceCompany) {
    //     this.insuranceCompany = insuranceCompany
    // }

    // @action
    // setPolicyNumber(policyNumber) {
    //     this.policyNumber = policyNumber
    // }

    // @action
    // setDateStart(insuranceExpiryDate) {
    //     this.insuranceExpiryDate = insuranceExpiryDate
    // }

    @action
    setEachMonthlyBudgetType(month, value) 
    {
        if (month === "January"){
            this.januaryBudget = value;
        } else if (month === "February"){
            this.februaryBudget = value;
        } else if (month === "March"){
            this.marchBudget = value;
        } else if (month === "April"){
            this.aprilBudget = value;
        } else if (month === "May"){
            this.mayBudget = value;
        } else if (month === "June"){
            this.juneBudget = value;
        } else if (month === "July"){
            this.julyBudget = value;
        } else if (month === "August"){
            this.augustBudget = value;
        } else if (month === "September"){
            this.septemberBudget = value;
        } else if (month === "October"){
            this.octoberBudget = value;
        } else if (month === "November"){
            this.novemberBudget = value;
        } else if (month === "December"){
            this.decemberBudget = value;
        }
        
    }
    
    @action
    setTaxValue(taxValue) {
        this.taxValue = taxValue;
    }

    @action
    setPaymentTerms(paymentTerms) {
        this.paymentTerms = paymentTerms;
    }

    @action
    setOutgoingPaymentTerms(outgoingPaymentTerms) {
        this.outgoingPaymentTerms = outgoingPaymentTerms;
    }

    @action
    setPaymentOptions(paymentOptions) {
        this.paymentOptions = paymentOptions;
    }

    @action
    setNextInvoiceNumber(nextInvoiceNumber) {
        this.nextInvoiceNumber = nextInvoiceNumber;
    }

    @action
    setPushNotification(pushNotification) {
        this.pushNotification = pushNotification;
    }
    @action
    setEmailNotification(emailNotification) {
        this.emailNotification = emailNotification;
    }

    @action
    setType(type) {
        this.type = type;
    }
    @action
    setMonthlyBudgetType(monthlyBudget) {
        this.monthlyBudgetType = monthlyBudget;
    }

    @action
    setMonthlyBudget(monthlyBudget) {
        if (!isNaN(monthlyBudget)) {
            this.monthlyBudget = parseFloat(monthlyBudget);
        }
    }

    @action
    setcCInvoiceEmailAddress(email){
        this.errors = undefined
        this.cCInvoiceEmailAddress = email;
    }

    @action
    validateCCInvoiceEmail(email) {
        if (this.errors === undefined || this.errors === 'undefined') this.errors = {cCInvoiceEmailAddress: ""}
        if (!this.cCInvoiceEmailAddress && !email) {
            return this.errors = undefined
        } else {
            if(!validate.emailValidation(this.cCInvoiceEmailAddress)){
                this.errors = {cCInvoiceEmailAddress: "Invalid email"}
            } else {
                this.errors = undefined
            }
        }
    }

    @action
    submit() {
        if(parseInt(this.nextInvoiceNumber) < parseInt(this.nextInvoiceNumberInitial)){
            this.errors = {nextInvoiceNumber: "next invoice number need to be bigger than previous number"}
            return Promise.reject("next invoice number need to be bigger than previous number")
        }
       
        var australianABN = this.abn1+this.abn2+this.abn3+this.abn4
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const profile = {
            id: this.id,
            userId: user.id,
            bankName: this.bankName,
            bsbNumber: this.bsbNumber,
            accountNumber: this.accountNumber,
            accountDescription: this.accountDescription,
            abn: this.currency === "AUD"? australianABN : this.abn,
            currency: this.currency,
            tax: this.tax,
            taxValue: this.taxValue || 0,
            monthlyBudget: this.monthlyBudget,
            paymentTerms: this.paymentTerms,
            outgoingPaymentTerms: this.outgoingPaymentTerms,
            paymentOptions: this.paymentOptions,
            nextInvoiceNumber: this.nextInvoiceNumber,
            pushNotification: this.pushNotification,
            emailNotification: this.emailNotification,
            type: this.type,
            monthlyBudgetType: this.monthlyBudgetType,
            januaryBudget: this.januaryBudget,
            februaryBudget: this.februaryBudget,
            marchBudget: this.marchBudget,
            aprilBudget: this.aprilBudget,
            mayBudget: this.mayBudget,
            juneBudget: this.juneBudget,
            julyBudget: this.julyBudget,
            augustBudget: this.augustBudget,
            septemberBudget: this.septemberBudget,
            octoberBudget: this.octoberBudget,
            novemberBudget: this.novemberBudget,
            decemberBudget: this.decemberBudget,
            cCInvoiceEmailAddress: this.cCInvoiceEmailAddress,
            autoSend : this.autoSend,
            autoSendTime : moment(this.autoSendTime, "HH:mm")
            .local()
            .format("HH:mm"),
            autoSendDay : this.autoSendDay,
            utcOffset: new Date().getTimezoneOffset(),
            incomingInvoiceEmail : this.incomingInvoiceEmail,
            incomingEntityName : this.incomingEntityName,
            outgoingEntityName : this.outgoingEntityName, 
            outgoingInvoiceEmail : this.outgoingInvoiceEmail,
            outgoingToggleAddress: this.outgoingToggleAddress,
            incomingToggleAddress: this.incomingToggleAddress,
            streetIncoming: this.streetIncoming,
            cityIncoming: this.cityIncoming,
            postalIncoming: this.postalIncoming,
            stateIncoming: this.stateIncoming,
            countryCodeIncoming: this.countryCodeIncoming,
            longitudeIncoming: this.longitudeIncoming,
            latitudeIncoming: this.latitudeIncoming,
            streetOutgoing: this.streetOutgoing,
            cityOutgoing: this.cityOutgoing,
            postalOutgoing: this.postalOutgoing,
            stateOutgoing: this.stateOutgoing,
            countryCodeOutgoing: this.countryCodeOutgoing,
            longitudeOutgoing: this.longitudeOutgoing,
            latitudeOutgoing: this.latitudeOutgoing,
        };

        profileStore.setCurrentProfileCurrency(this.currency);
        profileStore.setCurrentProfileTax(this.tax);
        return (this.id ? this.updateProfile(profile) : this.createProfile(profile))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.data.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    createProfile(data) {
        //using general profile for create
        switch (this.type) {
            case 1:
                return agent.MusicianGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 2:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 3:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    return profile;
                });
            case 6:
                return agent.ProductionGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 7:
                return agent.DjGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 4:
            default:
                return agent.ActGeneralProfile.create(data).then(profile => {
                    return profile;
                });
        }
    }

    @action
    updateProfile(data) {
        switch (this.type) {
            case 1:
                return agent.MusicianFinancialProfile.update(data).then(profile => {
                    return profile;
                });
            case 2:
                return agent.AgentFinancialProfile.update(data).then(profile => {
                    return profile;
                });
            case 5:
                return agent.VenueFinancialProfile.update(data).then(profile => {
                    return profile;
                });
            case 6:
                return agent.ProductionFinancialProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 7:
                return agent.DjFinancialProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case 4:
            default:
                return agent.ActFinancialProfile.update(data).then(profile => {
                    return profile;
                });
        }
    }
}

export default new FinancialProfileStore();
