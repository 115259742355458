import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import "./BookingLineup.css";
import SetListForm from "../../components/setList/SetListForm";
import { Button, Grid, Typography, AppBar, Tabs, Tab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ConfirmationModal from "../modal/ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";

@inject("setListStore", "bookingRequestStore", "templateStore", "profileStore")
@observer
class BookingSetlist extends Component {
    state = {
        confirmCopyFromAct: false,
        confirmDelete: false,
        tab: 0,
        loading: false,
        success: false
    };

    componentDidMount() {
        this.props.templateStore.showLoadingScreen();
        Promise.all([
            this.props.bookingRequestStore.load(this.props.bookingId),
            this.props.setListStore.reset(),
            this.props.setListStore.setType(2),
            this.props.setListStore.setBookingId(this.props.bookingId),
            this.props.setListStore.loadSetlists()
        ]).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    }

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    };

    handleCreateSetlist = () => {
        this.props.setListStore.saveDraft().then(() => {
            //this.props.templateStore.openSnackbar("Setlist Saved");
            this.props.setListStore.addNewSetlist();
            this.setState({ tab: this.props.setListStore.setLists.length });
        });

    };

    handleDelete = () => {
        this.props.setListStore.deleteCurrentSetlist().then(() => {
            this.setState({ tab: 0, confirmDelete: false });
        });
    };

    handleSubmitForm = e => {
        e.preventDefault();

        // submit
        this.props.setListStore.submit().then(() => {
            this.props.templateStore.openSnackbar("Setlist Saved");
        });
    };

    handleCopyFromAct = actId => {
        this.props.setListStore.copyFromAct(actId);
        this.setState({
            confirmCopyFromAct: false
        });
    };

    handleOpenCopyFromProfile = () => this.setState({ confirmCopyFromAct: true });
    handleCloseCopyFromAct = () => this.setState({ confirmCopyFromAct: false });
    handleOpenDelete = () => this.setState({ confirmDelete: true });
    handleCloseDelete = () => this.setState({ confirmDelete: false });

    render() {
        const { actId, isAdmin, act } = this.props.bookingRequestStore.booking;
        const { tab, loading } = this.state;
        const { setLists, title } = this.props.setListStore;
        const { readOnly } = this.props;
        const currentProfile = this.props.profileStore.getCurrentProfile()
        let showTooltipSetList = act?.actAdminId == currentProfile?.id
        return (
           
                 <div className="Profile-Privacy">
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={3}>
                        <div className="sticky-information">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Set List
                            </Typography>
                            {showTooltipSetList ? 
                                <Typography gutterBottom>
                                    {/* Select your set list for the gig. You can copy the set list from your existing set list as a starting point. */}
                                    Set up the setlists for this gig. You can copy your existing set lists from your act profile.
                                </Typography> : ""
                            }
                        </div>
                    </Grid>
                    
                    <Grid item xs={12} lg={9}>
                        {isAdmin && (
                            <div>
                                <Grid container spacing={8}>
                                    <Grid item sm={12} md={12} lg={12} style={{ textAlign: "right" }}>
                                        <Tooltip title="Delete the current setlist">
                                            <Button variant="flat" color="default" aria-label="import" onClick={this.handleOpenDelete}>
                                                {this.state.success ? <CheckIcon /> : <DeleteIcon />} Delete
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Click to copy all the songs from your musician profile">
                                            <Button variant="flat" color="default" aria-label="import" onClick={this.handleOpenCopyFromProfile}>
                                                {this.state.success ? <CheckIcon /> : <FileCopyIcon />} Copy From Act
                                            </Button>
                                        </Tooltip>
                                        <Button variant="raised" color="primary" aria-label="add" onClick={this.handleCreateSetlist}>
                                            {this.state.success ? <CheckIcon /> : <AddIcon />} Create Setlist
                                        </Button>
                                        {loading && <CircularProgress size={53} />}
                                    </Grid>
                                </Grid>
                                <hr />
                            </div>
                        )}
                        {setLists && (setLists[0] !== undefined) ?
                            <AppBar position="static">
                                <Tabs value={tab} onChange={this.handleTabChange} scrollable scrollButtons="auto">
                                    {setLists.map(setlist => {
                                        return <Tab label={setlist.setList.title} key={setlist.setList.id} />;
                                    })}
                                </Tabs>
                            </AppBar> : null
                            }
                        {actId &&
                            setLists.map((setlist, index) => {
                                return (
                                    <div key={index}>
                                        {tab === index && (
                                            <SetListForm
                                                type="booking"
                                                id={setlist.setList.id}
                                                bookingId={this.props.bookingId}
                                                actId={actId}
                                                readonly={!isAdmin}
                                            />
                                        )}
                                    </div>
                                );
                            })}

                        {(!setLists || setLists.length === 0) &&  (
                            <div style={{ margin: 24, textAlign: "center" }}>
                                {/* <h4>You don't have any setlist yet. {!readOnly && " Click create setlist to create one or import from your act profile"}.</h4> */}
                            </div>
                        )}
                        
                        <ConfirmationModal
                            open={this.state.confirmCopyFromAct}
                            title="Copy set lists from your act profile?"
                            message="This will copy all of the set lists from your act profile. You can then delete or update each set list for this gig."
                            maxWidth="sm"
                            closeHandler={this.handleCloseCopyFromAct}
                            confirmationHandler={this.handleCopyFromAct.bind(this, actId)}
                            declineHandler={this.handleCloseCopyFromAct}
                            confirmationLabel="Copy"
                        />

                        <ConfirmationModal
                            open={this.state.confirmDelete}
                            title={`Delete Set List`}
                            message={`Are you sure to delete ${title}?`}
                            closeHandler={this.handleCloseDelete}
                            confirmationHandler={this.handleDelete}
                            declineHandler={this.handleCloseDelete}
                            confirmationLabel="Delete"
                        />
                    </Grid>
                </Grid>
                { isAdmin && ( <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <div className="action">
                            <Button variant="raised" color="primary" onClick={this.handleSubmitForm}>
                                Save
                            </Button>
                        </div>
                    </Grid>
                </Grid>)

                }
               
                </div>
        
        );
    }
}

export default BookingSetlist;
