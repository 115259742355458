import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { TextField } from "@material-ui/core";

import Autocomplete from "react-autocomplete";

import "./AutoComplete.css";

@inject("profileStore")
@observer
class AutoCompleteContainer extends Component {
    state = {
        value: "",
        open: false
    };

    requestTimer = null;

    render() {
        let onSelect = (value, item) => {
            if (this.props.onSelect) {
                this.props.onSelect(value, item);
            } else {
                this.setState({
                    value: value,
                    open: false
                });
                this.props.handleSelect(item);
            }
        };

        let onChange = (event, value) => {
            if (this.props.onChange) {
                this.props.onChange(event, value);
            } else {
                this.setState({ value, open: false });
                if (value.length > this.props.minimumLength) {
                    clearTimeout(this.requestTimer);
                    this.requestTimer = setTimeout(this.props.handleSearch(value), 5000);
                    this.setState({ value, open: true });
                }
            }
        };

        let renderInput = props => {
            if (this.props.renderInput) {
                return this.props.renderInput(props);
            } else {
                const { ref, ...rest } = props;
                return <TextField {...rest} fullWidth inputRef={ref} />;
            }
        };

        let wrapperHeight = this.props.wrapperHeight || "35px";

        return (
            <Autocomplete
                inputProps={{
                    id: this.props.id || "states-autocomplete",
                    placeholder: this.props.placeholder,
                    className: "autocomplete-inputtext",
                    error: this.props.error,
                    helperText: this.props.helperText
                }}
                wrapperStyle={{ position: "relative", display: "inline-block", width: "100%", minHeight: wrapperHeight }}
                open={this.props.open ? this.props.open : this.state.open}
                value={this.props.value ? this.props.value : this.state.value}
                items={this.props.items}
                getItemValue={this.props.getItemValue}
                onSelect={onSelect}
                onChange={onChange}
                renderInput={renderInput}
                renderMenu={(children, value) => this.props.renderMenu(children, value)}
                renderItem={(item, isHighlighted) => this.props.renderItem(item, isHighlighted)}
            />
        );
    }
}

export default AutoCompleteContainer;
