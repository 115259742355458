import { observable, action, computed } from "mobx";
import agent from "../agent";
import moment from "moment";
import templateStore from "./templateStore";
import profileStore from "./profileStore";
import qs from "qs";

// const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class SearchStore {
    @observable results = [];
    @observable searchTimeout = undefined;
    @observable isLoading = false;

    @observable query = "";
    @observable type = 4;
    @observable showResult = 10;

    @observable song = "";
    @observable instruments;
    @observable genres;
    @observable gigTypes;
    @observable actTypeId;
    @observable lineUpCount = undefined;

    @observable availability = "";
    @observable hasNext = false;
    @observable searchedAddress = "";
    @observable locationLongitudeLatitude = undefined;
    @observable distanceRadius = 50;

    @observable setRequiredDateTime = false;
    @observable requiredDate = undefined;
    @observable requiredTimeStart = moment().startOf("hour");
    @observable
    requiredTimeEnd = moment()
        .startOf("hour")
        .add(2, "h");
    @observable isApplyToPlay = false;
    @observable isApplyToActRoster = false;
    @observable selectedVibes;
    @observable isApplyToBook = false;
    @observable page = 1;
    @observable take = 6;

    @action
    setSearch(search) {
        const parsed = qs.parse(search, { ignoreQueryPrefix: true });
        if (parsed) {
            if (parsed.query) {
                this.query = parsed.query;
            }
            if (parsed.song) {
                this.song = parsed.song;
            }
            if (parsed.instruments) {
                this.instruments = parsed.instruments;
            }
            if (parsed.genres) {
                this.genres = parsed.genres;
            }
            if (parsed.gigTypes) {
                this.gigTypes = parsed.gigTypes;
            }
            if (parsed.actTypeId) {
                this.actTypeId = parsed.actTypeId;
            }
            if(parsed.selectedVibes){
                this.selectedVibes = parsed.selectedVibes;
            }
        }
    }

    @action
    setFavouriteOnSearch(id, value){
        var changeIsFavourite = this.results.filter(x => x.id === id)
        if(changeIsFavourite && changeIsFavourite.length > 0){
            changeIsFavourite[0].isFavourite = value
        }
    }
    @action
    submitSearch() {
        this.search(this.queryString);
    }

    @action
    setShowResult(showResult) {
        this.showResult = showResult;
    }

    @action
    setQuery(query) {
        this.query = query;
        this.refreshSearch();
    }

    @action
    setPage(page) {
        this.page = page;
        this.refreshSearch();
    }

    @action
    setSong(song) {
        this.song = song;
        this.refreshSearch();
    }

    @action
    setType(type) {
        this.results = [];
        this.type = type;

        this.lineUpCount = undefined;
        this.gigTypes = undefined;
        this.actTypeId = undefined;
        this.genres = undefined;
        this.song = undefined;
        this.setRequiredDateTime = false;
        this.vibes = undefined;
        this.requiredDate = undefined;
        this.requiredTimeStart = moment().startOf("hour");
        this.requiredTimeEnd = moment()
            .startOf("hour")
            .add(2, "h");
        this.isApplyToActRoster = false;
        this.isApplyToPlay = false;
        this.page = 1;
        this.isApplyToBook = false;
    }

    @action
    setLineUpCount(lineUpCount) {
        this.lineUpCount = lineUpCount;
    }

    @action
    setInstruments(instruments) {
        this.instruments = instruments;
    }

    @action
    setGenres(genres) {
        this.genres = genres;
    }

    @action
    setGigTypes(gigTypes) {
        this.gigTypes = gigTypes;
    }

    @action
    setActTypeId(actTypeId) {
        this.actTypeId = actTypeId;
    }

    @action
    setSearchedAddress(address) {
        this.searchedAddress = address;
    }

    @action
    setLocationLongitudeLatitude(longlat) {
        this.locationLongitudeLatitude = longlat;
    }

    @action
    setDistanceRadius(radius) {
        this.distanceRadius = radius;
    }

    @action
    setRequiredDate(date) {
        this.requiredDate = date;
    }

    @action
    setRequiredDateTimeOption(value) {
        this.setRequiredDateTime = value;
    }

    @action
    setRequiredTimeStart(value) {
        this.requiredTimeStart = value;
    }

    @action
    setRequiredTimeEnd(value) {
        this.requiredTimeEnd = value;
    }

    @action
    setIsApplyToPlay(value){
        this.isApplyToPlay = value;
    }

    @action
    setIsApplyToBook(value){
        this.isApplyToBook = value;
    }

    @action
    setIsApplyToActRoster(value){
        this.isApplyToActRoster = value;
    }

    @action
    setSelectedVibes(selectedVibes){
        this.selectedVibes = selectedVibes;
    }

    @computed
    get queryString() {
        const { currentProfile } = profileStore;
        var param = {
            profileId: currentProfile.id,
            type: this.type,
            lineUpCount: this.lineUpCount,
            query: this.query,
            song: this.song,
            instruments: this.instruments,
            genres: this.genres,
            gigTypes: this.gigTypes,
            actTypes: this.actTypeId,

            availability: this.availability,
            longitude: this.locationLongitudeLatitude ? this.locationLongitudeLatitude.lng : null,
            latitude: this.locationLongitudeLatitude ? this.locationLongitudeLatitude.lat : null,
            distanceRadius: this.distanceRadius,

            requiredDate: this.setRequiredDateTime ? moment(this.requiredDate).format("MM-DD-YYYY") : null,
            requiredStartTime: this.setRequiredDateTime && this.requiredTimeStart ? moment(this.requiredTimeStart).format("HH:mm") : null,
            requiredEndTime: this.setRequiredDateTime && this.requiredTimeEnd ? moment(this.requiredTimeEnd).format("HH:mm") : null,
            isApplyToPlay: this.isApplyToPlay,
            isApplyToActRoster: this.isApplyToActRoster,
            selectedVibes: this.selectedVibes,
            isApplyToBook: this.isApplyToBook,
            take: this.take,
            page: this.page

        };
        // console.log("query", param);
        return qs.stringify(param);
    }

    @action
    refreshSearch() {
        // if (this.searchTimeout) {
        //     clearTimeout(this.searchTimeout);
        // }
        // const self = this.search;
        // this.searchTimeout = setTimeout(self, 1000);
    }

    @action
    search() {
        this.isLoading = true;
        this.page = 1;
        return agent.Profile.search(this.queryString)
            .then(
                action(async response => {
                    if (response) {
                        await profileStore.mapSearchProfiles(response).then(action(result => {
                            if(result.length > 5){
                                this.hasNext = true
                            }else{
                                this.hasNext = false
                            }
                            this.results = result;
                        }));
                    } else {
                        this.results = [];
                    }
                    return this.results;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    searchNext() {
        this.isLoading = true;
        return agent.Profile.search(this.queryString)
            .then(
                action(async response => {
                    if (response) {
                        await profileStore.mapSearchProfiles(response).then(action(result => {
                            if(result.length > 5){
                                this.hasNext = true
                            }else{
                                this.hasNext = false
                            }
                            this.results = this.results.concat(result); ;
                        }));
                    }
                    return this.results;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }


    mapAdditionalProfileData = profile => {
        if (profile) {
            profile.fullName = templateStore.getFullName(profile);
            profile.typeString = templateStore.getTypeString(profile.type);
            profile.labelType = templateStore.getLabelType(profile.type);
            if (profile.labelType === "Agent" && profile.isConsultant) {
                profile.labelType = "consultant";
                profile.isConsultant = true;
            } else if (
                profile.labelType === "Agent" &&
                !profile.isConsultant &&
                profile.myAgencyConsultant &&
                profile.myAgencyConsultant.length > 0
            ) {
                profile.labelType = "agency";
                profile.isAgency = true;
            }
        }
    };
}

export default new SearchStore();
