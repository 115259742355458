import React, { Component } from "react";

import { observer, inject } from "mobx-react";

import { Select, MenuItem, Grid } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import { ProfileType } from "../../types/enum";

import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import BookingCalendarFilter from "../../components/booking/bookingCalendarFilter";

const dateFormat = "DD-MM-YYYY";
const monthFormat = "MMM YYYY";

@inject("reportStore", "profileStore", "agentVenueStore", "venueStore")
@observer
class ReportDateFilter extends Component {
    constructor(props) {
        super(props);
        this.props.reportStore.resetFilterData();
    }

    componentDidMount() {
        this.getVenueList();
    }

    onChange = e => {
        this.props.reportStore.setFilterType(e.target.value);
        if (this.props.postChangeEvent) {
            this.props.postChangeEvent();
        }
    };

    onChangeStartDate = value => {
        const { endDate } = this.props.reportStore;
        this.props.reportStore.setStartDate(value);
        if (moment(value).endOf("day") > moment(endDate).endOf("day")) {
            this.props.reportStore.setEndDate(value);
        }
        if (this.props.postChangeEvent) {
            this.props.postChangeEvent();
        }
    };

    onChangeEndDate = value => {
        const { startDate } = this.props.reportStore;
        this.props.reportStore.setEndDate(value);
        if (moment(value).endOf("day") < moment(startDate).endOf("day")) {
            this.props.reportStore.setStartDate(value);
        }
        if (this.props.postChangeEvent) {
            this.props.postChangeEvent();
        }
    };

    onChangeMonth = value => {
        const startDate = moment(value).date(1);
        this.props.reportStore.setStartDate(startDate);

        const endDate = moment(value).date(moment(value).daysInMonth());
        this.props.reportStore.setEndDate(endDate);

        if (this.props.postChangeEvent) {
            this.props.postChangeEvent();
        }
    };

    getVenueList = () => {
        const { currentProfile } = this.props.profileStore;

        if (currentProfile?.type === ProfileType.Agent.ordinal) {
            return this.props.agentVenueStore.getAgentVenue();
        } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
            this.props.reportStore.setVenueFilter(undefined);
        } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
            return this.props.venueStore.getUserVenues();
        }
        return Promise.resolve();
    };

    mapVenueSuggestions = () => {
        const { currentProfile } = this.props.profileStore;
        var venueSuggestions = [];
        if (currentProfile?.type === ProfileType.Staff.ordinal) {
            const { venues } = this.props.venueStore;
            venueSuggestions = venues.map(venue => {
                return {
                    value: venue.id,
                    label: venue.profileName
                };
            });
        } else if (currentProfile?.type === ProfileType.Agent.ordinal) {
            const { agentVenues } = this.props.agentVenueStore;

            venueSuggestions = agentVenues.map(agentVenue => {
                return {
                    value: agentVenue.venue.id,
                    label: agentVenue.venue.profileName
                };
            });
        }

        if (venueSuggestions.length > 0) {
            venueSuggestions.unshift({
                value: 0,
                label: "All Venues"
            });
        }

        return venueSuggestions;
    };

    handleSelectVenue = value => {
        // const { currentProfile } = this.props.profileStore;
        // this.setState({
        //     ...this.state,
        //     selectedVenue: value,
        //     isLoading: true
        // });

        // if (!value || value === 0) {
        //     this.getActList().then(() => {
        //         this.setState({ isLoading: false });
        //     });
        // } else {
        //     if (currentProfile.type === ProfileType.Staff.ordinal) {
        //         this.props.venueActStore.getVenueActRoster(value).then(() => {
        //             this.setState({ isLoading: false });
        //         });
        //     } else {
        //         this.setState({ isLoading: false });
        //     }
        // }

        this.props.reportStore.setVenueFilter(value);
    };

    render() {
        const { filterType, startDate, endDate, venueId } = this.props.reportStore;
        const { currentProfile } = this.props.profileStore;
        const { isSocialPost } = this.props;

        const isAgent = currentProfile?.type === ProfileType.Agent.ordinal;
        return (
            <Grid container spacing={8} justify="flex-end" alignItems="center">
                {isAgent && !isSocialPost && (
                    <Grid item>
                        <BookingCalendarFilter
                            id="bookingVenue"
                            suggestions={this.mapVenueSuggestions()}
                            onChange={this.handleSelectVenue}
                            value={venueId}
                            placeholder="All Venues"
                            fullWidth={this.props.width === "xs" || this.props.width === "sm"}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Select
                        label="Filter"
                        className="dark-select pull-right slim-dark-input slim-select-input"
                        value={filterType}
                        onChange={this.onChange}
                        inputProps={{
                            color: "primary"
                        }}
                        classes={{
                            root: "text-white",
                            icon: "text-white"
                        }}
                    >   
                        {/* {this.props.hideAllTime ? null : <MenuItem value="all">All-Time</MenuItem>}  */}
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="week">This Week</MenuItem>
                        <MenuItem value="lastweek">Last Week</MenuItem>
                        {/* <MenuItem value="month">This Month</MenuItem>
                        <MenuItem value="nextMonth">Next Month</MenuItem> */}
                        <MenuItem value="monthly">Month</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                </Grid>

                {filterType === "custom" && (
                    <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                emptyLabel="From"
                                margin="none"
                                className="dark-datepicker slim-month-input"
                                value={startDate}
                                onChange={this.onChangeStartDate}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                format={dateFormat}
                                fullWidth
                                InputProps={{
                                    classes: {
                                        root: "text-white pull-right"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {filterType === "custom" && (
                    <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                emptyLabel="to"
                                margin="none"
                                className="dark-datepicker slim-month-input"
                                value={endDate}
                                onChange={this.onChangeEndDate}
                                format={dateFormat}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                fullWidth
                                InputProps={{
                                    classes: {
                                        root: "text-white pull-right"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {filterType === "monthly" && (
                    <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                views={["year", "month"]}
                                emptyLabel="to"
                                margin="none"
                                className="dark-datepicker slim-month-input"
                                value={startDate}
                                onChange={this.onChangeMonth}
                                format={monthFormat}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                fullWidth
                                autoOk
                                InputProps={{
                                    classes: {
                                        root: "text-white pull-right"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default ReportDateFilter;
