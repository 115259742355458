import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import ModalContainer from "../modal/ModalContainer";
import { DialogContent, DialogActions, Button, Grid, InputLabel, DialogContentText } from "@material-ui/core";

import RichTextEditor from "../richTextEditor/RichTextEditor";
import Loading from "../loadingSpinner/Loading";
import { ProfileType } from "../../types/enum";

@inject("bookingRequestStore", "templateStore", "profileStore")
@withRouter
@observer
class CancelBookingModal extends Component {
    handleCancelBooking = () => {
        const { currentProfile } = this.props.profileStore;

        this.props.bookingRequestStore.cancelBooking().then(() => {
            this.props.templateStore.openSnackbar("Booking has been cancelled.");
            this.props.bookingRequestStore.setCancellationReason(undefined);
            if (this.props.postCancelAction) {
                this.props.postCancelAction();
            } else {
                if (this.props.history.length > 1) {
                    this.props.history.goBack();
                } else {
                    if (currentProfile.type === ProfileType.Agent.ordinal) {
                        this.props.history.push("/agent/gig");
                    } else if (currentProfile.type === ProfileType.Staff.ordinal) {
                        this.props.history.push("/staff/gig");
                    } else if (currentProfile.type === ProfileType.Musician.ordinal) {
                        this.props.history.push("/musician/gig");
                    }
                }
                this.props.onClose();
            }
        });
    };

    handleCloseModal = () => {
        this.props.bookingRequestStore.setCancellationReason(undefined);
        this.props.onClose();
    }

    render() {
        const { cancellationReason, isLoading } = this.props.bookingRequestStore;

        return (
            <ModalContainer open={this.props.open} onClose={this.handleCloseModal} title="Cancel Booking Confirmation">
                <Loading showed={isLoading} />
                <DialogContent>
                    <Grid container spacing={8}>
                        {/* <Grid item xs={12}>
                            <DialogContentText>Are you sure to cancel this booking?</DialogContentText>
                        </Grid> */}
                        <Grid item xs={12} className="grid-cancel-modal">
                            <RichTextEditor
                                value={cancellationReason}
                                placeholder="Enter your cancellation reason"
                                onChange={value => this.props.bookingRequestStore.setCancellationReason(value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={this.handleCloseModal}>
                        Close
                    </Button>
                    <Button id="btn-confirm-cancel-booking" variant="contained" color="primary" onClick={this.handleCancelBooking}>
                        Cancel Booking
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default CancelBookingModal;
