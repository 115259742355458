import { observable, action } from "mobx";
import agent from "../agent";
import templateStore from "./templateStore";
import profileStore from "./profileStore";

import reportStore from "./reportStore";

import moment from "moment";

class InvoiceStore {
    @observable inProgress = false;
    @observable errors = undefined;

    @observable id = undefined;

    @observable incomingInvoices = [];

    @observable outgoingInvoices = [];

    @observable invoiceReports = [];

    @observable invoiceReportDateStart = undefined;
    @observable invoiceReportDateEnd = undefined;
    @observable invoice = undefined;

    @observable selectedIncomingInvoice = undefined;

    @observable selectedOutgoingPdfBatch = undefined;

    @observable purchaseOrderCode = undefined;

    @observable filter = {
        contactName: undefined
    }

    @action
    filterOutgoingInvoiceBaseOnFilter(invoices){
        let outgoingInvoices = [...this.outgoingInvoices];
        if(invoices){
            outgoingInvoices = [...invoices];
        }
        if(this.filter){
            if(this.filter.contactName){
                outgoingInvoices = this.outgoingInvoices.filter(x => x.payerName === this.filter.contactName);
            }
            //..add others filter here
        }
        return outgoingInvoices;
    }

    @action
    filterIncomingInvoiceBaseOnFilter(invoices){
        let incomingInvoices = [...this.incomingInvoices];
        if(invoices){
            incomingInvoices = [...invoices];
        }
        if(this.filter){
            if(this.filter.contactName){
                incomingInvoices = incomingInvoices.filter(x => x.payeeName === this.filter.contactName);
            }
            //..add others filter here
        }
        return incomingInvoices;
    }

    @action
    resetFilter(){
        this.filter = {
            contactName: undefined
        }
    }

    @action
    setFilterAttr(key, value){
        this.filter[key] = value;
    }

    @action
    setPurchaseOrderCode(poCode){
        this.purchaseOrderCode = poCode;
    }

    @action
    setSelectedIncomingInvoice(value){
        this.selectedIncomingInvoice = value;
    }

    @action
    setSelectedOutgoingPdfBatch(value){
        this.selectedOutgoingPdfBatch = value;
    }

    @action
    load() {
        this.inProgress = true;
        templateStore.showLoadingScreen();
        return agent.Invoice.getAll()
            .then(
                action(invoices => {
                    this.invoices = invoices;
                    return this.invoices;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
                this.inProgress = false;
            });
    }

    @action
    clearIncomingOutgoingInvoices = () => {
        this.incomingInvoices = [];
        this.outgoingInvoices = [];
    };

    @action
    setInvoices(invoices) {
        this.invoices = invoices;
    }

    @action
    unsyncSendToAccountIncoming(invoiceId) {
        return agent.Invoice.unsyncSendToAccount(invoiceId)
        .then(
            action(invoice => {
                this.incomingInvoices.forEach(item => {
                    if(item.id === invoiceId) {
                        item.synchedSendAccount = false
                    }
                });
                return invoice;
            })
        )
    }

    @action
    unsyncSendToAccountOutgoing(invoiceId) {
        return agent.Invoice.unsyncSendToAccount(invoiceId)
        .then(
            action(invoice => {
                this.outgoingInvoices.forEach(item => {
                    if(item.id === invoiceId) {
                        item.synchedSendAccount = false
                    }
                });
                return invoice;
            })
        )
    }

    @action


    @action
    unsyncXeroInvoiceIncoming(profileId, invoiceId) {
        return agent.Xero.deleteXeroInvoice(profileId, invoiceId)
        .then(
            action(invoice => {
                this.incomingInvoices.forEach(item => {
                    if(item.id === invoiceId) {
                        item.isXeroSyncronise = false
                    }
                });
                return invoice;
            })
        ).catch(
            action(err => {
                return Promise.reject(err);
            })
        )
    }

    @action
    unsyncXeroInvoice(profileId, invoiceId) {
        return agent.Xero.deleteXeroInvoice(profileId, invoiceId)
        .then(
            action(invoice => {
                this.outgoingInvoices.forEach(item => {
                    if(item.id === invoiceId) {
                        item.isXeroSyncronise = false
                    }
                });
                return invoice;
            })
        ).catch(
            action(err => {
                return Promise.reject(err);
            })
        )
    }
    @action
    unsyncSendToAccount(invoiceId) {
        return agent.Invoice.unsyncSendToAccount(invoiceId)
        .then(
            action(invoice => {
                this.incomingInvoices.forEach(item => {
                    if(item.id === invoiceId) {
                        item.synchedSendAccount = false
                    }
                });
                return invoice;
            })
        )
    }

    @action
    loadByPayeeId(payeeId) {
        this.inProgress = true;
        templateStore.showLoadingScreen();
        return agent.Invoice.getByPayeeId(payeeId)
            .then(
                action(invoices => {
                    this.outgoingInvoices = invoices;
                    return this.invoices;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
                this.inProgress = false;
            });
    }

    @action
    getBookingCreatorByInvoiceId(invoiceId){
        return agent.Invoice.getBookingCreatorByInvoiceId(invoiceId)
        .then(
            action(bookingCreators => {
                return bookingCreators
            })
        )
    }

    @action
    loadByPayerId(payerId) {
        this.inProgress = true;
        templateStore.showLoadingScreen();
        return agent.Invoice.getByPayerId(payerId)
            .then(
                action(invoices => {
                    this.incomingInvoices = invoices;
                    return this.invoices;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
                this.inProgress = false;
            });
    }

    @action
    getInvoiceByBookingAndProfileId(bookingId, profileId) {
        return agent.Invoice.getInvoiceByBookingAndProfileId(bookingId, profileId).then(
            action(invoices => {
                return invoices;
            })
        );
        
    }

    @action
    getByIdSimple(invoiceId) {
        return agent.Invoice.getByIdSimple(invoiceId).then(
            action(invoice => {
                return invoice;
            })
        );
        
    }
    
    @action
    loadByPayeeIdAndFilter(payeeId) {
        const { startDate, endDate, organisationId, stateId, regionId, venueId, invoiceStatusFilter, actId } = reportStore;

        const startDateFormated = startDate ? moment(startDate).format("MM-DD-YYYY HH:mm:ss.SSS") : undefined;
        const endDateFormated = endDate ? moment(endDate).format("MM-DD-YYYY HH:mm:ss.SSS") : undefined;
        const statusFilter = invoiceStatusFilter.join().toLowerCase();

        return agent.Invoice.getByPayeeIdAndFilter(payeeId, startDateFormated, endDateFormated, organisationId, stateId, regionId, venueId, statusFilter, actId).then(
            action(invoices => {
                this.outgoingInvoices = invoices;
                return this.invoices;
            })
        );
    }

    @action
    loadByPayerIdAndFilter(payerId) {
        const { startDate, endDate, organisationId, stateId, regionId, venueId, invoiceStatusFilter, actId } = reportStore;

        const startDateFormated = startDate ? moment(startDate).format("MM-DD-YYYY HH:mm:ss.SSS") : undefined;
        const endDateFormated = endDate ? moment(endDate).format("MM-DD-YYYY HH:mm:ss.SSS") : undefined;
        const statusFilter = invoiceStatusFilter.join().toLowerCase();

        return agent.Invoice.getByPayerIdAndFilter(payerId, startDateFormated, endDateFormated, organisationId, stateId, regionId, venueId, statusFilter, actId).then(
            action(invoices => {
                this.incomingInvoices = invoices;
                return this.invoices;
            })
        );
    }
   
    @action
    getById(invoiceId) {
        this.inProgress = true;
        return agent.Invoice.get(invoiceId)
            .then(
                action(response => {
                    this.invoice = response;
                    return response;
                })
            )
            .finally(() => {
                this.inProgress = false;
            });
    }

    @action
    getByIdProfileId(invoiceId,profileId) {
        this.inProgress = true;
        return agent.Invoice.getProfileId(invoiceId,profileId)
            .then(
                action(response => {
                    this.invoice = response;
                    return response;
                })
            )
            .finally(() => {
                this.inProgress = false;
            });
    }

    @action
    delete(id) {
        return agent.Profile.delete(id).then(
            action(response => {
                let invoice = this.invoices.find(x => x.id === id);
                if (invoice) this.invoices.remove(invoice);
                return response;
            })
        );
    }

    @action
    generate(id) {
        return agent.Invoice.generate(id)
            .then(
                action(response => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }
    
    @action
    unPayInvoice(invoiceId, data) {
        this.inProgress = true;
        templateStore.showLoadingScreen();

        return agent.Invoice.unPayInvoice(invoiceId, data)
            .then(
                action(response => {
                    return response;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
                this.inProgress = false;
            });
    }

    @action
    updatePaidDate(invoiceId, data) {
        this.inProgress = true;
        templateStore.showLoadingScreen();

        return agent.Invoice.updatePaidDate(invoiceId, data)
            .then(
                action(response => {
                    return response;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
                this.inProgress = false;
            });
    }
    @action
    payInvoice(invoiceId, data) {
        this.inProgress = true;
        templateStore.showLoadingScreen();

        return agent.Invoice.payInvoice(invoiceId, data)
            .then(
                action(response => {
                    return response;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
                this.inProgress = false;
            });
    }

    @action
    declineInvoice(invoiceId) {
        this.inProgress = true;
        templateStore.showLoadingScreen();

        return agent.Invoice.declineInvoice(invoiceId)
            .then(
                action(response => {
                    return response;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
                this.inProgress = false;
            });
    }

    @action
    downloadInvoicePdf(id) {
        return agent.Invoice.downloadPdf(id).then(response => {
            return response;
        });
    }

    @action
    downloadBatchInvoicePdf(ids){
        return agent.Invoice.downloadBatchPdf(ids).then(response => {
            return response;
        })
    }

    @action
    resetInvoiceReports() {
        this.invoiceReports = [];
    }

    @action
    generateInvoicesReport() {
        const { currentProfile } = profileStore;
        const { startDate, endDate, organisationId, stateId, regionId, venueId, statusFilter } = reportStore;

        return agent.Invoice.generateReport(
            currentProfile.type,
            moment(startDate).format("MM-DD-YYYY HH:mm:ss.SSS"),
            moment(endDate).format("MM-DD-YYYY HH:mm:ss.SSS"),
            organisationId,
            stateId,
            regionId,
            venueId,
            statusFilter !== "all" ? statusFilter : ""
        ).then(
            action(response => {
                this.invoiceReports = response;
                return this.invoiceReports;
            })
        );
    }

    @action
    updateInvoicesPurchaseOrder(invoiceIds){
        if(!invoiceIds && this.selectedIncomingInvoice){
            invoiceIds = this.selectedIncomingInvoice.map(x => x.id);
        }
        var data = {
            invoiceIds: invoiceIds,
            purchaseOrderCode: this.purchaseOrderCode
        };
        return agent.Invoice.updateInvoicesPurchaseOrder(data).then(action(
            response => {
                response.forEach(invoice => {
                    this.incomingInvoices = this.incomingInvoices.map(x => {
                        if (x.id === invoice.id) {
                            x.purchaseOrderCode = invoice.purchaseOrderCode;
                            x.synchedSendAccount = invoice.synchedSendAccount;
                        }
                        return x;
                    });
                })
                return response;
            }
        ))
    }
}

export default new InvoiceStore();
