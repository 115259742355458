import React from "react";
import { inject, observer } from "mobx-react";
import { Button, TextField, Grid, Paper, Typography, Checkbox, CircularProgress,InputAdornment, IconButton } from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";

import logo from "../img/hotgig-logo.png";
import facebook from "../img/facebook-box.svg";
import FacebookLogin from "react-facebook-login";
import FooterLogin from "./footer/FooterLogin";
import agent from "../agent.js"
import { ProfileType } from "../types/enum";
import WarningModal from "../components/modal/WarningModal";
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const EMAIL_CONFIRMATION_REQUIRED = process.env.REACT_APP_EMAIL_CONFIRMATION_REQUIRED === "true";

@inject("authStore", "facebookStore", "generalProfileStore", "userInvitationStore", "profileStore")
@withRouter
@observer
class Register extends React.Component {
    params = undefined;

    constructor(props) {
        super(props);
        this.state = {
            defaultPasswordHelp: "The Password field need to have at least one number, one capital letter, and at least have 8 characters",
            policy: (
                <span>
                    By signing up, you agree to the <Link to="/tos">Terms of Service</Link> and <Link to="/policy">Privacy Policy</Link>
                </span>
            ),
            source: undefined,
            params: undefined,
            email: "",
            password: "",
            checked: false,
            subscribeEmail: '',
            as: undefined,
            isLoading: false,
            showPassword:false ,
            isEmailInvalid: false
        };
    }

    componentDidMount() {
        this.props.authStore.reset();
        if (this.props.location && this.props.location.search) {
            this.params = _.chain(this.props.location.search)
                .replace("?", "") // a=b454&c=dhjjh&f=g6hksdfjlksd
                .split("&") // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
                .map(_.ary(_.partial(_.split, _, "="), 1)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
                .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
                .value();

            if (this.params.email) {
                this.props.authStore.setEmail(this.params.email);
            }

            if (this.params.firstName) {
                this.props.authStore.setFirstname(this.params.firstName.replace("%20", " "));
            }

            if (this.params.lastName) {
                this.props.authStore.setLastname(this.params.lastName.replace("%20", " "))
            }

            if (this.params.source) {
                this.setState({
                    ...this.state,
                    source: this.params.source
                });
            }
            if (this.params.as) {
                this.setState({
                    ...this.state,
                    as: this.params.as
                });
            }
        }
    }

    responseFacebook(response) {
        this.props.facebookStore.login(response.accessToken).then(user => {
            if (user && user.status === 50) {
                this.props.history.replace("/staff/edit/new");
            } else if (this.params) {
                //auto create musician profile
                if (this.params.as) {
                    this.props.history.replace(`/welcome/${this.params.as}/${user.profileId}`);
                } else {
                    this.props.history.replace("/welcome/musician");
                }
            } else {
                this.props.history.replace("/welcome");
            }
        });
    }

    componentWillUnmount() {
        this.props.authStore.reset();
    }
    handleClickShowPassword = () => {
        const {showPassword} = this.state 
     
        this.setState({showPassword: !showPassword})
    }
    handleEmailChange = e => this.props.authStore.setEmail(e.target.value);
    handleValidateEmail = e => this.props.authStore.validateEmail(e.target.value);
    handleFirstnameChange = e => this.props.authStore.setFirstname(e.target.value);
    handleValidateFirstName = e => this.props.authStore.validateFirstName(e.target.value);
    handleLastnameChange = e => this.props.authStore.setLastname(e.target.value);
    handleValidateLastName = e => this.props.authStore.validateLastName(e.target.value);
    handlePasswordChange = e => this.props.authStore.setPassword(e.target.value)
    handleValidatePassword = e => this.props.authStore.validatePassword(e.target.value)
    handleAccountType = e => this.props.authStore.setAccountType(e.target.value);

    handleConfirmPasswordChange = (e) => this.props.authStore.setConfirmPassword(e.target.value)
    handleValidateConfirmPassword = (e) => this.props.authStore.validateConfirmPasswor(e.target.value)


    handleSubmitForm = async e => {
        e.preventDefault();
        const { source, as } = this.state;
        this.setState({isLoading: true})
        this.props.authStore.checkEmailValid().then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
                this.props.authStore.register(this.params ? this.params.token : undefined, source, as).then(async user => {
                    this.setState({isLoading: false})
                    if (this.params && this.params.as && this.params.as === "changeEmail") {
                        const profile = this.props.profileStore.getCurrentProfile();
                        this.gotoDashboard(profile.type)
                    }
                    else if (EMAIL_CONFIRMATION_REQUIRED && this.params?.token === undefined) {
                        localStorage.setItem("email-confirmation", this.props.authStore.values.email)
                        this.props.history.push(`/confirmation`);
                    } else if (user.status === 50 && (this.params.as === "staff" || this.params.as === "AgentVenues")) {
                        if(this.params.as === "AgentVenues"){
                            let candidateCustomer = { 
                                userId: user.sub, 
                                email: this.params.email, 
                                given_name: this.params.firstName, 
                                family_name: this.params.lastName, 
                                userType: 3
                            };
                            await agent.Billing.createCustomer(candidateCustomer);
                           
                        }
                        Promise.all([
                            this.props.profileStore.loadProfilesAfterLogin(),
                        ]).then(() => { 
                            this.props.history.push("/staff/edit/" + user.profileId);
                        });
                        
                    } else if (user.status === 50 && this.params.as === "VenuesAgent") {
                        let candidateCustomer = { 
                            userId: user.sub, 
                            email: this.params.email, 
                            given_name: this.params.firstName, 
                            family_name: this.params.lastName, 
                            userType: 2
                        };
                        await agent.Billing.createCustomer(candidateCustomer);
                        Promise.all([
                            this.props.profileStore.loadProfilesAfterLogin(),
                        ]).then(() => { 
                            this.props.history.push("/agent/edit/" + user.profileId);
                        });
                        
                    } else if (user.status === 50 && this.params.as === "consultant") {
                        this.props.history.push("/agent/edit");
                    } else if (user.status === 50) {
                        this.props.history.push("/welcome");
                    } else if (user && typeof user.status !== undefined && this.params.as === "staff") {
                        this.props.history.push("/staff/edit/new");
                    } else if (user && typeof user.status !== undefined && this.params.as.toLowerCase().includes('act')) {
                        let candidateCustomer = { 
                            userId: user.sub, 
                            email: this.params.email, 
                            given_name: this.params.firstName, 
                            family_name: this.params.lastName, 
                            userType: 1
                        };
                        await agent.Billing.createCustomer(candidateCustomer);
                        Promise.all([
                            this.props.profileStore.loadProfilesAfterLogin(),
                        ]).then(() => { 
                            this.props.profileStore.setProfileType(ProfileType.Musician.ordinal);
                            this.props.history.push("/musician/edit/new");
                        });
                      
                        // } else if (user && typeof user.status !== undefined && this.params.as === "consultant") {
                        //     this.props.generalProfileStore.setCreateNewConsultant(true);
                        //     this.props.history.push(`/welcome/${this.params.as}/${user.profileId}`);
                    } else if (this.params && user.isInvited) {
        
                        //auto create musician profile
                        if (this.params.as) {
                            this.props.history.replace(`/welcome/${this.params.as}/${user.profileId}`);
                        } else {
                            this.props.history.replace("/welcome/musician");
                        }
        
                    } else if (source) {
                        this.props.history.replace("/welcome/musician");
                    } else {
                        this.props.history.push("/welcome");
        
                    }
                })
                .catch(err => {
                    console.log("error", err)
                    this.setState({isLoading: false})
                })
            }else{
                this.setState({isLoading: false})
                this.openModal()
            }
        })
        

  
        // if (user && typeof user.status!==undefined && user.status === 50) {
        //     this.props.history.push("/staff/edit/new");
        // } else if (this.params && user.isInvited) {
        //     //auto create musician profile
        //     if (this.params.as) {
        //         this.props.history.replace(`/welcome/${this.params.as}/${user.profileId}`);
        //     } else {
        //         this.props.history.replace("/welcome/musician");
        //     }
        // } else if (source) {
        //     this.props.history.replace("/welcome/musician");
        // } else {
        //     if (EMAIL_CONFIRMATION_REQUIRED) {
        //         this.props.history.push("/confirmation");
        //     } else {
        //         this.props.history.push("/welcome");
        //     }
        // }
    };

    gotoDashboard(profileType) {
        switch (profileType) {
            case 1:
                this.props.history.push("/musician");
                break;
            case 2:
                this.props.history.push("/agent");
                break;
            case 3:
                this.props.history.push("/staff");
                break;
            case 6:
                this.props.history.push("/production");
                break;
            case 7:
                this.props.history.push("/dj");
                break;
            case 8:
                this.props.history.push("/agent");
                break;
        }
    }

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        if (field === "password" && this.state.defaultPasswordHelp) {
            this.setState({ defaultPasswordHelp: "" });
        }
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field].replace(/([a-z](?=[A-Z]))/g, "$1 ");
    }

    handleCheckBox = (e) => {
        this.setState({ checked: e.target.checked })
    }

    openModal = () => {
        this.setState({ isEmailInvalid: true })
        
    }
    closeModal = () => {
        this.setState({ isEmailInvalid: false })
        
    }
    handleTextCheckBox = () => {
        this.setState({ checked: !this.state.checked })
    }

    onChangeSubsCribe = (e) => {
        this.setState({ subscribeEmail: e.target.value })
    }

    handleSubscribe = (val) => {
        console.log('Subscribe Email:', this.state.subscribeEmail)
    }

    render() {
        const { values, errors } = this.props.authStore;
        const {showPassword} = this.state;
        const params = Object(this.params); // Get Value from params
        // const signUpLink = this.state.source ? `/register?source=${this.state.source}` : "/register";
        // const signInLink = this.state.source ? `/login?source=${this.state.source}` : "/login";
        let isChangeEmail = false;

        let signUpText = 'Just add in your details below and we will send you a hot link to log in and set up your profile.'
        if (this.state.as === "changeEmail") {
            signUpText = 'Insert your new password and we will set up your profile.'
            isChangeEmail = true;
        }

        const agreementText = () => (
            <span>
                I agree to the <Link className="link-tos" to="/policy" target={"_blank"}>Privacy Policy</Link> and <Link className="link-tos" to="/tos" target={"_blank"}>Terms {'&'} Conditions</Link>
            </span>
        )

        return (
            <div style={{ display: "flex", flex: 1, flexDirection: 'column', zIndex: 40 }}>
                <div className="Login">
                    <div className='image-background' />
                    <img src={logo} alt="HotGiggity" className='header-logo' />
                    <Grid container spacing={24} className="Login-Grid">
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid container direction='row' className="logo-login" alignItems='center' justify='center' alignContent='center' >
                                <h2 className="logo-login-title">Sign Up to</h2>
                                <img style={{ width: 'auto' }} src={logo} alt="HotGiggity" />
                            </Grid>
                        </Grid>

                        <Paper className="Form">
                            <form onSubmit={this.handleSubmitForm} className="Submit-form">
                                {/* // Facebook Sign up component
                                <FacebookLogin
                                    appId={FACEBOOK_APP_ID.toString()}
                                    callback={this.responseFacebook.bind(this)}
                                    cssClass="fb-login fb-btn"
                                    textButton="&nbsp;&nbsp; Continue with Facebook"
                                    icon={<img src={facebook} alt="fb" className="logo-fb" />}
                                />
                                <div className="or-container">
                                    <Typography component="p" className="or">
                                        <span>or</span>
                                    </Typography>
                                </div> */}

                                <p className="login-text">
                                    {signUpText}
                                </p>
                                {
                                    this.anyError("general") &&
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            color: "#f44336",
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            // paddingLeft: 5,
                                            // paddingBottom: 10,
                                            // paddingTop: 10,
                                        }}>{this.errorText("general")}</div>
                                }

                                <TextField
                                    inputProps={{
                                        readOnly: this.params && this.params.email
                                    }}
                                    id="email"
                                    margin="normal"
                                    placeholder="Email"
                                    className="inputTextContainer"
                                    InputProps={{ className: "inputTextField" }}
                                    fullWidth
                                    value={values.email}
                                    onChange={this.handleEmailChange}
                                    onBlur={this.handleValidateEmail}
                                    error={this.anyError("email") && errors["email"]}
                                    // helperText={errors && errors["email"] ? errors["email"] : ""}
                                    helperText={this.errorText("email")}
                                    type="email"
                                />
                                <TextField
                                    id="firstName"
                                    margin="normal"
                                    placeholder="First Name"
                                    className="inputTextContainer"
                                    InputProps={{ className: "inputTextField" }}
                                    fullWidth
                                    value={values.firstName}
                                    onChange={this.handleFirstnameChange}
                                    onBlur={this.handleValidateFirstName}
                                    error={this.anyError("firstName")}
                                    helperText={this.errorText("firstName")}
                                    inputProps={{
                                        readOnly: isChangeEmail
                                    }}
                                />
                                <TextField
                                    id="lastName"
                                    placeholder="Last Name"
                                    margin="normal"
                                    className="inputTextContainer"
                                    InputProps={{ className: "inputTextField" }}
                                    fullWidth
                                    value={values.lastName ?? params?.lastName}
                                    onChange={this.handleLastnameChange}
                                    onBlur={this.handleValidateLastName}
                                    error={this.anyError("lastName")}
                                    helperText={this.errorText("lastName")}
                                    inputProps={{
                                        readOnly: isChangeEmail
                                    }}
                                />
                                <TextField
                                    id="password"
                                    placeholder={isChangeEmail ? "Password" : "Create a Password"}
                                    autoComplete="current-password"
                                    margin="normal"
                                    className="inputTextContainer"
                                    InputProps={{
                                        className: "inputTextField",
                                        endAdornment:(
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                              >
                                                {showPassword ?  <i class="fa fa-eye whiteColor" /> :  <i class="icon-eye-blocked whiteColor"/>}
                                              </IconButton>
                                            </InputAdornment>
                                        )
                                      }}
                                    type={showPassword ? 'text' : 'password'}
                                    fullWidth
                                    value={values.password}
                                    onChange={this.handlePasswordChange}
                                    onBlur={this.handleValidatePassword}
                                    error={this.anyError("password")}
                                    helperText={this.errorText("password")}
                                />
                                <TextField
                                    id="confirmPassword"
                                    placeholder="Confirm Password"
                                    autoComplete="current-password"
                                    margin="normal"
                                    className="inputTextContainer"
                                    InputProps={{
                                        className: "inputTextField",
                                        endAdornment:(
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                              >
                                                {showPassword ?  <i class="fa fa-eye whiteColor" /> :  <i class="icon-eye-blocked whiteColor"/>}
                                              </IconButton>
                                            </InputAdornment>
                                        )
                                      }}
                                    type={showPassword ? 'text' : 'password'}
                                    fullWidth
                                    value={values.confirmPassword}
                                    onChange={this.handleConfirmPasswordChange}
                                    onBlur={this.handleValidateConfirmPassword}
                                    error={this.anyError("confirmPassword")}
                                    helperText={this.errorText("confirmPassword")}
                                />

                                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: -10, }}>
                                    <Checkbox checked={this.state.checked} onChange={this.handleCheckBox} style={{ color: 'white', alignSelf: 'center', }} />
                                    <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }}>
                                        <p onClick={this.handleTextCheckBox} className='agreement-text'>{agreementText()}</p>
                                    </div>
                                </div>
                                <div style={{ alignItems: 'center', justifyContent: 'center', display: "flex", marginTop: 30 }}>
                                    {this.state.isLoading ? 
                                        <CircularProgress /> :
                                        <Button disabled={this.state.checked ? false : true} className="btnSubmit" size="large" variant="contained" color="primary" id="signup-btn" type="submit">
                                            {isChangeEmail ? "SAVE" : "SIGN UP"}
                                            {/* Sign Up */}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </Paper>
                        {/* // Forgot password Component 
                        <LinkContainer to="/forgotpassword">
                            <a className="btnForget">Forgot Password?</a>
                        </LinkContainer> */}
                    </Grid>
                </div>
                <div style={{ display: 'flex', position: 'relative', width: '100vw', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    <FooterLogin subscribe={this.state.subscribeEmail} onChangeSubscribe={this.onChangeSubsCribe} onSubscribe={this.handleSubscribe} />
                </div>
                <WarningModal
                        title={"Warning: Temporary Email Address Detected"}
                        open={this.state.isEmailInvalid}
                        message={`<p>We have detected that you are trying to sign up using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.&#160; Please use a permanent email address to create your account.</p>

                        <p>If you believe this is an error, or if you need assistance, please contact our support team at support@hotgiggity.com.</p>`}
                        closeHandler={this.closeModal}
                        confirmationLabel="Ok"
                />
            </div>
            
        );
    }
}

export default Register;
