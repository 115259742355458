import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { TextField, Button, Grid, Avatar, Card, Typography, IconButton, Hidden, Tooltip, MenuItem, InputAdornment } from "@material-ui/core";
import { findDOMNode } from "react-dom";
import { Prompt } from "react-router-dom";
import _ from "lodash";

import NumberFormat from "react-number-format";

import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import ModalContainer from "../modal/ModalContainer";

import Instruments from "../fields/MultiSelectInstrument";

import ProfileSearchBox from "../fields/ProfileSearchBox";

import DragDrop from "../dnd/DragDrop";

import { DragDropContext } from "react-beautiful-dnd";

import blankProfile from "../../img/blank-profile.png";
// import blankBackground from "../../img/hero.png";

import BigRosterCard from "../roster/rosterBigCard";
import "./Roster.css";
import Badge from "@material-ui/core/Badge";
import ConfirmationModal from "../modal/ConfirmationModal";

import ClearIcon from "@material-ui/icons/Clear";
import ReorderIcon from "@material-ui/icons/Reorder";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Loading from "../loadingSpinner/Loading";
import image from "../../helper/image";
import { ProfileType } from "../../types/enum";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
const SHOW_PRODUCTION_CREW = process.env.REACT_APP_SHOW_PRODUCTION_CREW === "true";

@inject("rosterStore", "templateStore", "generalProfileStore", "profileStore")
@withRouter
@observer
class ActRoster extends Component {
    state = {
        selectedMusician: [],
        newInstrument: null,
        openInviteMusician: false,
        activeInstrument: null,
        openDeleteConfirmation: false,
        deletedActRoster: undefined,
        isLoading: true,
        isEdited: false
    };

    searchBox = [];

    constructor(props) {
        super(props);
        this.actInstrumentsRef = [];
    }

    componentDidMount() {
        // set type
        window.scrollTo(0, 0);
        this.props.rosterStore.setType(this.props.templateStore.getType(this.props.type));
        this.props.rosterStore
            .loadInitialData(this.props.profileId)
            .then(response => {
                this.setState({
                    ...this.state
                });
            })
            .finally(() => {
                this.setState({ isLoading: false });
                window.scrollTo(0, 0);
            });
    }

    handleAddInstrumentRoster = (roster, instrument, inputRef) => {
        this.setState({
            ...this.state,
            isEdited: true
        });
        this.props.rosterStore.addInstrumentRoster(roster, instrument);
        if (inputRef.downshift) {
            inputRef.downshift.state.inputValue = "";
        }
        _.set(this.state.selectedMusician, instrument, undefined);
    };

    handleRemoveInstrumentRoster = (roster, instrument) => {
        this.setState({
            ...this.state,
            isEdited: true
        }, () => {
            this.props.rosterStore.removeInstrumentRoster(roster, instrument);
        });

    };

    handleAddNewInstrument = instrumentName => {
        this.props.rosterStore.addActInstrument();
        this.setState(
            {
                ...this.state,
                isEdited: true
            },
            () => {
                window.scrollTo(0, document.body.scrollHeight);
            }
        );
    };

    handleOpenInvitationDialog = (instrument, value) => {
        this.setState(
            {
                activeInstrument: instrument
            },
            () => {
                this.props.rosterStore.setInvitedInstrument(instrument);
                this.props.rosterStore.openInviteMusicianForm();
                this.props.rosterStore.setInvitedName(value);
            }
        );
    };

    handleCloseInvitationDialog = () => {
        this.setState(
            {
                activeInstrument: null
            },
            () => {
                this.props.rosterStore.closeInviteMusicianForm();
            }
        );
    };

    handleInviteNewMusician = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.rosterStore.inviteRoster().then(
            (res) => {
                this.setState({
                    ...this.state,
                    isEdited: true
                });
                if(typeof res === "string") this.props.templateStore.openSnackbar(res);
                else this.props.templateStore.openSnackbar("Invitation has been sent");
            }
        ).catch(err => {
            this.props.templateStore.openSnackbar(err);
        }).finally(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    };

    handleRosterDividendAmount = (value, index) => {
        this.setState({
            ...this.state,
            isEdited: true
        });
        if (!this.props.rosterStore.updateRosterDividendAmount(parseFloat(value), index)) {
            this.props.templateStore.openSnackbar("Total roster's percentage is more than 100%.");
        }
    };

    handleChangeDividendType = (e) => {
        this.props.rosterStore.updateRosterDividendType(e.target.value);
        this.setState({ ...this.state, isEdited: true })
    }

    anyError(field) {
        if (!this.props.rosterStore.errors) return false;
        if (!this.props.rosterStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.rosterStore.errors) return null;
        if (!this.props.rosterStore.errors[field]) return null;
        return this.props.rosterStore.errors[field];
    }

    handleBack = () => {
        if (this.props.type === "act") {
            this.props.history.replace(`/myact/edit/${this.props.match.params.id}/social`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.setState({ isLoading: true });
        this.props.rosterStore
            .submit()
            .then(
                () => {
                    if (this.props.type !== "act" && this.props.type !== "venue") {
                        const { editProfileActiveStep } = this.props.templateStore;
                        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
                    }
                    this.setState({ isLoading: false, isEdited: false });
                    if (SHOW_PRODUCTION_CREW) {
                        this.props.history.push(`/myact/edit/${this.props.profileId}/crew`);
                    }
                    else {
                        this.props.history.push(`/myact/edit/${this.props.profileId}/songlist`);
                    }
                },
                err => {
                    const { instrumentErrors, instrumentRosterErrors } = this.props.rosterStore;
                    if (err === "Empty Instrument." && instrumentErrors) {
                        let firstInstrumentError = instrumentErrors.split(",")[0];
                        let node = findDOMNode(this.actInstrumentsRef[parseInt(firstInstrumentError, 10)]);
                        if (node) {
                            window.scrollTo(0, node.offsetTop - 100);
                        }
                    } else if (err === "Empty Roster." && instrumentRosterErrors) {
                        let firstInstrumentRosterError = instrumentRosterErrors.split(",")[0];
                        let node = findDOMNode(this.actInstrumentsRef[parseInt(firstInstrumentRosterError, 10)]);
                        if (node) {
                            window.scrollTo(0, node.offsetTop - 100);
                        }
                    }
                    this.props.templateStore.openSnackbar(err);
                    this.setState({ isLoading: false, isEdited: true });
                }
            );
    };

    handleOpenDeleteConfirmationDialog = deletedInstrument => {
        this.setState({
            ...this.state,
            deletedActRoster: deletedInstrument,
            openDeleteConfirmation: true
        });
    };

    handleCloseDeleteConfirmationDialog = () => {
        this.setState({
            ...this.state,
            deletedActRoster: undefined,
            openDeleteConfirmation: false
        });
    };

    handleDeleteActInstrument = () => {
        this.props.rosterStore.removeInstrument(this.state.deletedActRoster);
        this.setState({
            ...this.state,
            isEdited: true,
            deletedActRoster: undefined,
            openDeleteConfirmation: false
        });
    };

    renderInvitationModal = () => (
        <ModalContainer open={this.props.rosterStore.openInviteMusician} onClose={this.handleCloseInvitationDialog} title="Invite Musician / Performer">
            <DialogContent>
                <TextField
                    label="Musician/Performer Name"
                    margin="normal"
                    fullWidth
                    value={this.props.rosterStore.invitedName ? this.props.rosterStore.invitedName : ""}
                    onChange={e => {
                        this.props.rosterStore.setInvitedName(e.target.value);
                    }}
                    id="invitedMusicianName"
                />
                <TextField
                    label="Musician/Performer Email"
                    margin="normal"
                    fullWidth
                    value={this.props.rosterStore.invitedEmail ? this.props.rosterStore.invitedEmail : ""}
                    type="email"
                    onChange={e => {
                        this.props.rosterStore.setInvitedEmail(e.target.value);
                    }}
                    onBlur={e => this.props.rosterStore.validateEmail(e.target.value)}
                    error={this.anyError("email")}
                    helperText={this.errorText("email")}
                    id="invitedMusicianEmail"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseInvitationDialog}>Close</Button>
                <Button
                    id="send-invite"
                    color="primary"
                    disabled={!this.props.rosterStore.invitedEmail || this.anyError("email")}
                    onClick={this.handleInviteNewMusician}
                >
                    Send Invitation
                </Button>
            </DialogActions>
        </ModalContainer>
    );

    renderDeleteConfirmationModal = () => (
        <ConfirmationModal
            open={this.state.openDeleteConfirmation}
            title="Delete Instrument"
            message="Are you sure you want to delete this Act Instrument including it's rosters?"
            closeHandler={this.handleCloseDeleteConfirmationDialog}
            confirmationHandler={this.handleDeleteActInstrument}
            declineHandler={this.handleCloseDeleteConfirmationDialog}
            confirmationLabel="Delete"
            declineLabel="Close"
        />
    );

    allowRosterPercentage = value => {
        value = parseFloat(value);
        if (value > 100 || value < 0) {
            return false;
        }

        return true;
    };

    numberFormatPercentage = props => {
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                ref={inputRef}
                decimalScale={2}
                fixedDecimalScale={true}
                isAllowed={values => this.allowRosterPercentage(values.value)}
                onValueChange={values => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                allowNegative={false}
                suffix="%"
            />
        );
    };

    numberFormatDollar = props => {
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                ref={inputRef}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                allowNegative={false}
            />
        );
    };

    render() {
        const { editProfileActiveStep } = this.props.templateStore;
        const steps = this.props.templateStore.getSteps("act");
        const { isLoading, isEdited } = this.state;

        let reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        let onDragEnd = result => {
            if (!result.destination) {
                return;
            }

            this.props.rosterStore.resetErrors();

            var cleanType = result.type.split("@")[0];

            if (cleanType === "roster") {
                var listIdx = _.toInteger(result.draggableId.split("@")[1]) - 1;
                const items = reorder(this.props.rosterStore.actInstruments[listIdx].rosters, result.source.index, result.destination.index);
                this.props.rosterStore.sortInstrumentRoster(items, listIdx);
            } else if (cleanType === "actRoster") {
                const items = reorder(this.props.rosterStore.actInstruments, result.source.index, result.destination.index);
                this.props.rosterStore.sortActRosters(items);
            }

            this.setState({
                ...this.state,
                isEdited: true
            });
        };

        let getRosterStatus = (status, profileId) => {
            if (!profileId) {
                return "Invited";
            } else {
                if (status) {
                    return "Accepted";
                } else {
                    return "Pending";
                }
            }
        };

        let renderSmallRosterList = (users, instrumentName, index) => {
            let renderContent = (user, index) => {
                let musicianName =
                    user.profile && user.profile.firstName
                        ? _.toString(user.profile.firstName) + " " + _.toString(user.profile.lastName)
                        : user.name
                            ? user.name
                            : _.toString(user.firstName) + " " + _.toString(user.lastName);
                let profileImage = image.getProfileImage(user.profile);
                return (
                    <ListItem button style={{ position: "relative" }}>
                        <Badge badgeContent={index} color="primary">
                            &nbsp;
                        </Badge>
                        <Avatar alt={musicianName} src={profileImage} />
                        <ListItemText primary={musicianName ? musicianName : ""} secondary={user.instruments} />
                        <ListItemSecondaryAction>
                            <IconButton
                                onClick={() => {
                                    this.handleRemoveInstrumentRoster(user, instrumentName);
                                }}
                                color="primary"
                            >
                                <ClearIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            };

            let afterDrag = newRoster => {
                this.props.rosterStore.sortInstrumentRoster(newRoster, instrumentName);
            };

            return (
                <Grid container spacing={8}>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={12}>
                        <List>
                            <DragDrop
                                droppableId={"roster@" + index}
                                type={"roster@" + index}
                                items={users}
                                renderContent={renderContent}
                                horizontal={false}
                                afterDrag={afterDrag}
                            />
                        </List>
                    </Grid>
                </Grid>
            );
        };

        let renderBigRosterList = (users, instrumentName, index) => {
            let renderContent = (user, index) => {
                let musicianName =
                    user.profile && user.profile.firstName
                        ? _.toString(user.profile.firstName) + " " + _.toString(user.profile.lastName)
                        : user.name
                            ? user.name
                            : _.toString(user.firstName) + " " + _.toString(user.lastName);
                return (
                    
                        <BigRosterCard
                            key={index}
                            removeHandle={() => this.handleRemoveInstrumentRoster(user, instrumentName)}
                            status={getRosterStatus(user.accepted, user.profileId)}
                            name={musicianName ? musicianName : ""}
                            profileId={user.profile ? user.profile.id : undefined}
                            index={index}
                            profileImage={user.profile && user.profile.profileImage !== null ? user.profile.profileImage : undefined}
                            backgroundImage={user.profile && user.profile.backgroundImages && user.profile.backgroundImages.length > 0 ? user.profile.backgroundImages[0] : undefined}
                        />
                );
            };

            return (
                <DragDrop droppableId={"roster@" + index} type={"roster@" + index} items={users} renderContent={renderContent} horizontal={true} />
            );
        };

        let handleChange = (selected, actInstrumentIdx) => {
            this.props.rosterStore.addInstrumentRoster(selected, actInstrumentIdx);
            this.setState({
                ...this.state,
                isEdited: true
            });
        };

        let renderSelectInstrument = (actInstrument, error) => {
            return (
                <Instruments
                    id="instruments"
                    onChange={value => {
                        this.props.rosterStore.setInstrumentForActInstrument(actInstrument, value);
                        this.setState({
                            ...this.state,
                            isEdited: true
                        });
                    }}
                    error={error}
                    errorMessage="Error"
                    value={actInstrument.instruments}
                    placeholder="Select Instruments/Skill Sets"
                    label="Instruments/Skill Sets *"
                />
            );
        };

        let getExcludedProfile = () => {
            let excludedProfile = [];
            const actInstrumentFilter = this.props.rosterStore.actInstruments.filter(act => !act.deleted)
            actInstrumentFilter.forEach(actInstrument => {
                actInstrument.rosters.forEach(roster => {
                    if (roster.profile) {
                        excludedProfile.push(roster.profile);
                    }
                });
            });
            return excludedProfile;
        };

        let renderInstrumentsGrid = (instrument, index) => {
            const { instrumentErrors, instrumentRosterErrors } = this.props.rosterStore;
            let instrumentError = instrumentErrors && instrumentErrors.includes(index);

            let instrumentRosterError = instrumentRosterErrors && instrumentRosterErrors.includes(index);
            const { currentProfile, isSoloAct } = this.props.profileStore;
            let currencySymbol = ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$");
            return (
                <Fragment>
                    {instrument.deleted ? null :
                    <Fragment>
                        <Grid
                            container
                            id={`actInstrument${index}`}
                            ref={node => {
                                this.actInstrumentsRef[index] = node;
                            }}
                        >
                            <Grid item md={12} xs={12} sm={12}>
                                <Tooltip id="tooltip-top-end" title="Hold to reorder" placement="top">
                                    <Card className="roster-wrapper">
                                        <CardHeader
                                            title={instrument.name === "" ? `Performer ${index}` : instrument.name}
                                            action={isSoloAct?[
                                                null
                                            ]:[
                                                // <IconButton key={1} style={{ cursor: "-webkit-grab grab" }}>
                                                //     <ReorderIcon />
                                                // </IconButton>,
                                                <IconButton key={2} onClick={() => this.handleOpenDeleteConfirmationDialog(instrument)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            ]}
                                            style={{ borderBottom: "solid 1px #EDEDED" }}
                                        />
                                        <CardContent style={{ paddingTop: 24, paddingBottom: 24 }}>
                                            <Grid container alignItems="flex-end" spacing={16}>
                                                <Grid item xs={12} md={8}>
                                                    <div>{renderSelectInstrument(instrument, instrumentError)}</div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id={`feeDistribution${index}`}
                                                        fullWidth
                                                        label="Gigs Fee Distribution"
                                                        placeholder="Roster Dividend"
                                                        value={
                                                            instrument.dividendType === 0 ? (
                                                                !isNaN(instrument.dividendAmount)
                                                                    ? instrument.dividendAmount === 0
                                                                        ? this.props.rosterStore.getDefaultRosterPercentage()
                                                                        : instrument.dividendAmount
                                                                    : this.props.rosterStore.getDefaultRosterPercentage()
                                                            ) : instrument.dividendAmount
                                                        }
                                                        onChange={e => this.handleRosterDividendAmount(e.target.value, index)}
                                                        InputProps={{
                                                            inputComponent: instrument.dividendType === 0 ? this.numberFormatPercentage : this.numberFormatDollar,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <TextField
                                                                        select
                                                                        value={instrument.dividendType}
                                                                        onChange={value => this.handleChangeDividendType(value)}
                                                                        SelectProps={{
                                                                            className: "select-adornment",
                                                                            inputProps: { className: "select-adornment-input" }
                                                                        }}
                                                                    >
                                                                        <MenuItem key={2} value={1}>
                                                                            {currencySymbol}
                                                                        </MenuItem>
                                                                        <MenuItem key={1} value={0}>
                                                                            %
                                                                        </MenuItem>
                                                                    </TextField>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <ProfileSearchBox
                                                        height="auto"
                                                        id={`searchMusician${index}`}
                                                        placeholder="Search Musician / Performer"
                                                        handleSearch={value => this.props.profileStore.searchMusician(value)}
                                                        handleChange={item => handleChange(item, index)}
                                                        handleEmpty={value => this.handleOpenInvitationDialog(instrument, value)}
                                                        exclude={getExcludedProfile()}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions style={{ borderTop: "solid 1px #EDEDED" }}>
                                            <Grid container spacing={16}>
                                                <Grid item md={12} xs={12} sm={12} style={{ backgroundColor: instrumentRosterError ? "#FFBABA" : undefined }}>
                                                    <Hidden mdUp>{renderSmallRosterList(instrument.rosters, instrument.name, index)}</Hidden>
                                                    <Hidden smDown>{renderBigRosterList(instrument.rosters, instrument.name, index)}</Hidden>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Tooltip>
                            </Grid>
                        </Grid>

                    </Fragment>
                    }
                </Fragment>
            );
        };
        return (
            <Fragment>
                <Prompt
                    when={isEdited}
                    message={() => `You have a not saved your player line up. Do you want to continue?`}
                />
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container direction="column" spacing={8}>
                        <Grid item>
                            <Grid container justify="center">
                                <Grid item md={12} xs={12} sm={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={6} xs={6} sm={6}>
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Lineup
                                            </Typography>
                                        </Grid>
                                        {!this.props.profileStore.isSoloAct &&
                                            <Grid item md={6} xs={6} sm={6}>
                                                <Hidden smDown>
                                                    <Button
                                                        id="add-instrument"
                                                        className="pull-right"
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={this.handleAddNewInstrument}
                                                    >
                                                        <AddIcon /> Add Performer
                                                    </Button>
                                                </Hidden>
                                            </Grid>
                                        }

                                    </Grid>
                                    <div className="clearfix">&nbsp;</div>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12} lg={3}>
                                            <div className="sticky-information">
                                                <Typography gutterBottom>Complete your Act's Lineup details.</Typography>
                                                <Typography gutterBottom className="side-helper-text">
                                                    Use this section to set up the line up for your act, assign performers to instruments/skill sets and determine
                                                    gig fee splits. You can add as many performers to each instruments/skill sets as you require. Search for existing
                                                    Hot Giggity members or invite you act members to the platform using the musician/performer invite.
                                                </Typography>
                                                <Typography>
                                                    <b>Tip:</b>
                                                </Typography>
                                                <Typography className="side-helper-text">
                                                    You can drag and drop the order of the performers to create a player order preference, or drag and
                                                    drop the instruments/skill sets to reorder how the line up appears on screen.
                                                </Typography>
                                            </div>
                                        </Grid>
                                        {!this.props.profileStore.isSoloAct &&
                                            <Hidden mdUp>
                                                <Grid item xs={12} lg={9}>
                                                    <Button
                                                        id="add-instrument-small"
                                                        className="pull-right"
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={this.handleAddNewInstrument}
                                                    >
                                                        <AddIcon /> Add Performer
                                                    </Button>
                                                </Grid>
                                            </Hidden>
                                        }
                                        <Grid item xs={12} lg={9}>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <DragDrop
                                                    items={this.props.rosterStore.actInstruments}
                                                    droppableId="actRoster"
                                                    type="actRoster"
                                                    renderContent={renderInstrumentsGrid}
                                                    horizontal={false}
                                                    classes={{
                                                        wrapper: "drag-wrapper"
                                                    }}
                                                />
                                            </DragDropContext>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <div className="action">
                                <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                    Back
                                </Button>
                                <Button id="submit-roster" variant="contained" color="primary" type="submit">
                                    {editProfileActiveStep === steps.length - 1 ? "Finish" : "Save / Next"}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
                {this.renderInvitationModal()}
                {this.renderDeleteConfirmationModal()}
            </Fragment>
        );
    }
}

export default ActRoster;
