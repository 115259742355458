import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import { Promise } from "bluebird";
import profileStore from "./profileStore";
import CacheHelper from "../helper/cache";
import authStore from "./authStore";

class AgentConsultantStore {
    @observable invitedConsultantEmail = "";
    @observable invitedConsultantFirstName = "";
    @observable invitedConsultantLastName = "";

    @observable managedVenueIds = [];

    @observable agentConsultants = [];

    @observable errors = undefined;

    @action
    resetData = () => {
        this.agentConsultants = [];
        this.resetInvitationData();
    };

    @action
    changeCurrentAgent = (id,agentId,venue) =>{
        var venueObj = {
            venue : venue
        }

        return agent.AgentConsultant.updateChangeAgent(id, agentId,venueObj).then(
            action(response => {
                return response;
            })
        );
    }

    @action
    getAgentConsultants = () => {
        return agent.AgentConsultant.getAgentConsultants().then(
            action(async response => {
                if(response !== 'undefined' && response !== undefined) {
                    this.agentConsultants = response ? response : []
                    for(var i=0; i< response.length; i++){
                        var profileImage = null
                        if(response[i]?.consultant?.profileImage!==null){
                            profileImage = await CacheHelper.getImgLink(response[i]?.consultant?.profileImage?.url200);
                        }

                        runInAction(()=>{
                            
                            if(response[i]?.consultant?.profileImage){
                                response[i].consultant.profileImage.imageProfile = profileImage
                            }
                            this.agentConsultants[i] = response[i];
                        })

                    }


                }
            })
        );
    };

    @action
    getAgentAgencies = () => {
        return agent.AgentConsultant.getAgentAgency().then(
            action(async response => {
                if(response !== 'undefined' && response !== undefined) {
                    this.agentConsultants = response ? response : []
                    let mapped = []
                    for(var i = 0; i < response.length; i++) {
                        if(response[i].consultantVenues && response[i].consultantVenues.length > 0) {
                            for(var j = 0; j < response[i].consultantVenues.length; j ++){
                                var data = response[i].consultantVenues[j]
                                if(data.venue) {
                                    await profileStore.mapAdditionalProfileDataAsync(data.venue)
                                    mapped.push(data)
                                }
                            }
                            var profileImage = null
                            response[i].consultantVenues = mapped
                        }
                        if (response[i] && response[i].agent && response[i].agent.profileImage !== null){
                            await profileStore.mapAdditionalProfileDataAsync(response[i].agent)
                            if(response[i].agent && response[i].agent.profileImage && response[i].agent.profileImage.url200){
                                profileImage = await CacheHelper.getImgLink(response[i].agent.profileImage.url200);
                                response[i].agent.profileImage.imageProfile = profileImage
                            }
                            
                           
                        }
                    }
                    runInAction(() => {
                        this.agentConsultants = response
                    })
                }
                return this.agentConsultants;
            })
        );
    };

    @action
    acceptRequest = id => {
        return agent.AgentConsultant.updateStatus(id, true).then(
            action(response => {
                this.getAgentAgencies()

                return response;
            })
        );
    };

    @action
    declineRequest = id => {
        return agent.AgentConsultant.updateStatus(id, false).then(
            action(response => {
                this.getAgentAgencies()
                return response;
            })
        );
    };

    @action
    removeConsultant = id => {
        const { profile } = profileStore;
        return agent.AgentConsultant.delete(id, profile.id).then(
            action(response => {
                this.getAgentConsultants()
            })
        );
    };

    @action
    quitCurrentAgency = () => {
        const { profile } = profileStore;
        if (profile.myAgency && profile.myAgency.id) {
            return agent.AgentConsultant.delete(profile.myAgency.id, profile.id).then(
                action(response => {
                    return response;
                })
            );
        } else {
            return Promise.reject();
        }
    };

    @action
    setInvitedConsultantEmail(email) {
        let currentErrorState = {...this.errors};
        currentErrorState["consultantEmail"] = undefined;
        this.invitedConsultantEmail = email;
        this.errors = currentErrorState;
    }

    @action
    setInvitedConsultantFirstName(value) {
        this.invitedConsultantFirstName = value;
    }

    @action
    setInvitedConsultantLastName(value) {
        this.invitedConsultantLastName = value;
    }

    @action
    resetErrors() {
        this.errors = undefined
    }

    @action
    validateInvitedConsultantFirstName(value) {
        const nameRegex = /^[a-z ,.'-]+$/i

        let currentErrorState = {...this.errors};

        if (!value) {
            currentErrorState["consultantFirstName"] = 'This field is required'
        } else if (!nameRegex.test(value)) {
            currentErrorState["consultantFirstName"] = 'Invalid first name';
        } else {
            currentErrorState["consultantFirstName"] = undefined;
        }
        this.errors = currentErrorState;
    }

    @action
    validateInvitedConsultantLastName(value) {
        const nameRegex = /^[a-z ,.'-]+$/i

        let currentErrorState = {...this.errors};

        if (!value) {
            currentErrorState["consultantLastName"] = 'This field is required'
        } else if (!nameRegex.test(value)) {
            currentErrorState["consultantLastName"] = 'Invalid last name';
        } else {
            currentErrorState["consultantLastName"] = undefined;
        }
        this.errors = currentErrorState;
    }

    @action
    validateInvitedConsultantEmail(value) {
        /* eslint-disable no-useless-escape*/
        let currentErrorState = {...this.errors};
        if(value.length === 0) return currentErrorState["consultantEmail"] = undefined;
        var email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        /* eslint-enable no-useless-escape*/

        if (!email_regex.test(value)) {
            currentErrorState["consultantEmail"] = "Invalid Email";
        } else {
            currentErrorState["consultantEmail"] = undefined;
        }   
        this.errors = currentErrorState;
    }

    @action
    setManagedVenueIds(ids) {
        this.managedVenueIds = ids;
    }

    @action
    resetInvitationData() {
        this.invitedConsultantEmail = "";
        this.invitedConsultantFirstName = "";
        this.invitedConsultantLastName = "";
        this.managedVenueIds = [];
    }

    @action
    submitEditConsultant(agentConsultant) {
        let consultantVenues = this.managedVenueIds.map(id => {
            return {
                agentVenueId: id
            };
        });

        let details = {
            id: agentConsultant.id,
            consultantVenues: consultantVenues
        };

        return agent.AgentConsultant.update(details).then(
            action(response => {
                // profileStore.mapAdditionalProfileData(response.agent);
                // profileStore.mapAdditionalProfileData(response.consultant);
                
                this.getAgentConsultants()

                this.agentConsultants.sort((a, b) => b.accepted - a.accepted);
                return response;
            })
        );
    }

    @action
    handleSearchConsultant(value){
        this.invitedConsultantEmail = value.email
        this.invitedConsultantFirstName = value.firstName
        this.invitedConsultantLastName = value.lastName
    }

    @action
    handleEmptySearch(value) {
        const name = value.split(" ")
        this.invitedConsultantFirstName = name[0]
        this.invitedConsultantLastName = name[1]
    }

    @action
    async submitInviteConsultant() {
        this.errors = undefined;
        var currentProfile = profileStore.getCurrentProfile();

        await authStore.checkEmailValidWithEmail(this.invitedConsultantEmail).then(async (isValid) => {
            if(isValid || process.env.REACT_APP_DISABLE_DISPOSABLE_EMAIL === "false"){
            let consultantVenues = this.managedVenueIds.map(id => {
                return {
                    agentVenueId: id
                };
            });

            let details = {
                agentId: currentProfile.id,
                consultantEmail: this.invitedConsultantEmail,
                consultantVenues: consultantVenues,
                consultantFirstName: this.invitedConsultantFirstName,
                consultantLastName: this.invitedConsultantLastName
            };

            return agent.AgentConsultant.create(details)
                .then(
                    action( response => {
                        if (response) {
                            // profileStore.mapAdditionalProfileData(response.agent);
                            // profileStore.mapAdditionalProfileData(response.consultant);
                            if(response.consultant!=undefined){
                                var isSame = false;
                                for(let agent of this.agentConsultants){
                                    if(agent.ConsultantEmail == response.ConsultantEmail){
                                        isSame = true
                                        break;
                                    }
                                }

                                if(!isSame){
                                    this.getAgentConsultants()
                                }else{
                                    response.isAlreadyAdd = true
                                }
                                
                                this.agentConsultants.sort((a, b) => b.accepted - a.accepted);
                            }
                        }
                        return response;
                    })
                )
                .catch( 
                    action(err => {
                        this.errors = err.response && err.response.body && err.response.body.errors;
                        return Promise.reject(err.response.data.errors);
                    })
                );
            }else{
                runInAction(async () => {
                    this.errors = [];
                    this.errors["consultantEmail"] = "We have detected that you are trying to add a user using a temporary email address. Our platform requires a verifiable email address for registration to ensure the security and reliability of our services.  Please use a permanent email address to invite this user.";
                
                })
                return Promise.reject({message:"Invalid Email!"});
            }
        })
    }
    
}

export default new AgentConsultantStore();
