import React, { Component } from "react";
import { inject } from "mobx-react";
import Header from "../../components/Header";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        float: "left",
        position: "relative",
        left: "50%"
    },
    input: {
        display: "none"
    }
});

const tos = `
   <h1>
      TERM OF SERVICE
   </h1>
   <p>
      THIS HOT GIGGITY CUSTOMER ACCOUNT AGREEMENT is made between
   </p>
   <p>
      Hot Giggity Pty Ltd of 23 Esther Street, Tarragindi, QLD, Australia. (&ldquo;Supplier&rdquo;)
   </p>
   <p>
      -and-
   </p>
   <p>
      The Party described above or in the Account Set up Procedure as the Customer (&ldquo;Customer&rdquo;)
   </p>
   <h2>
      RECITALS
   </h2>
   <ol>
      <li>
         The Supplier conducts a business of providing secure, cloud based, private platforms as a service.
      </li>
      <li>
         The Supplier has created the Designated Platform.
      </li>
      <li>
         The Supplier has agreed to grant access to the Designated Platform to the Customer and Authorised Account Users on the terms and conditions set out in this Agreement
      </li>
   </ol>
   <h2>
      OPERATIVE PROVISIONS
   </h2>
   <h3>
      1. DEFINITIONS AND INTERPRETATION
   </h3>
   <p>
      1.1 In this Agreement, unless the context otherwise requires:
   </p>
   <ol>
      <li>
         &ldquo;Account&rdquo; means the account of the Customer as described in clause 3.1.
      </li>
      <li>
         &ldquo;Account Administrator&rdquo; means the person or persons described in clause 3.
      </li>
      <li>
         &ldquo;Account Data&rdquo; means the Data stored on or via the Designated Platform through the use of the Account and being the Customer Data and the Authorised Account User Data;
      </li>
      <li>
         &ldquo;Account Set Up Procedure&rdquo; means the procedure used by the Supplier to identify the Customer and create the Account and for the Customer to choose the Designated Platform;
      </li>
      <li>
         &ldquo;Access Rights&rdquo;, in relation to a person, means the rights granted to that person to access and use the designated Platform via the Account, including any rights to upload, store, copy, manipulate or alter any Account Data.
      </li>
      <li>
         &ldquo;Agreement&rdquo; means this agreement and includes all matters included in the Account Set Up Procedure and any Subsequent Account Set Up Procedure.
      </li>
      <li>
         &ldquo;Authorised Account User&rdquo; means the Account Administrator and any person who satisfies the conditions in clause 3.
      </li>
      <li>
         &ldquo;Authorised Account User Data&rdquo; means Data belonging to any of the Authorised Account Users;
      </li>
      <li>
         &ldquo;Associate&rdquo; means in relation to a person, the employees, officers, directors, representatives, agents and contractors (including sub-contractors) of that person and of any Related Entity of that person (if any).
      </li>
      <li>
         &ldquo;Background IP&rdquo; means Intellectual Property (and subsequent enhancements) belonging to a Party prior to Commencement Date or acquired or created by a Party independently of this Agreement, which are used in the provision of the Services;
      </li>
      <li>
         &ldquo;Business Day&rdquo; means a day which is not a Saturday, Sunday or government declared public holiday in Melbourne, Victoria, Australia.
      </li>
      <li>
         &ldquo;Commencement Date&rdquo; means the date the Customer agrees to the terms of this Agreement during the Account Set Up Procedure.
      </li>
      <li>
         &ldquo;Confidential Information&rdquo; means all information of a confidential nature belonging to or in the possession of a Party, consisting of business, financial and other commercially valuable information in whatever form including know how, trade secrets, unpatented inventions,processes, business and marketing policies and strategies, employee and client details, formulae, computer software, code, graphs, drawings, designs, samples, devices, models and other materials provided that the followingwill constitute an exception to such information:
      </li>
      <li>
         information which is already in the public domain;
      </li>
      <li>
         information which hereafter becomes part of the public domain otherwise than as a result of an unauthorised disclosure by the recipient Party or its Associates;
      </li>
      <li>
         information which is or becomes available to the recipient Party from a third party lawfully in possession thereof and who has the lawful power to disclose such information to the recipient Party; and
      </li>
      <li>
         information which is rightfully known by the recipient Party (as shown by its written record) prior to the date of the disclosure to it or otherwise independently developed by an employee of the recipient Party who has no knowledge of the disclosure to it hereunder.
      </li>
      <br>
         <p>
            Confidential Information includes confidential information owned by the Supplier or in relation to which the Supplier holds Intellectual Property Rights and includes the Licensed Software, Documentation and the Source Code relating to the Software.
         </p>
      </br/>
      <li>
         &ldquo;Corporations Act&rdquo; means the Corporations Act 2001 (Cth).
      </li>
      <li>
         &ldquo;Customer Data&rdquo; means the Data belonging to or controlled by the Customer;
      </li>
      <li>
         &ldquo;Data&rdquo; means all information, images, sound, video or data whether in oral or written form (including electronic) created, used, amended, uploaded or stored in the course of using the Services and includes all information relating to the Access Rights of the Customer and Authorised Account Users and all information including email content, headers and attachments;
      </li>
      <li>
         &ldquo;Designated Platform&rdquo; means the platform Hot Giggity described in the Account Set Up Procedure or any Subsequent Account Set Up Procedure.
      </li>
      <li>
         &ldquo;Documentation&rdquo; means:
      </li>
      <li>
         the operating manuals, technical specifications, user manuals, and other printed or electronic literature made available to the Customer by the Supplier to aid the use and application of the Services; and
      </li>
      <li>
         all materials published or otherwise made available by the Supplier that relate to the functional, operational and/or performance capabilities of the Services.
      </li>
      <li>
         "Downtime" means any period of time of any duration that the Services are not made available by Supplier to the Customer or Authorised Customer User for any reason, including scheduled maintenance or enhancements to the Licensed Software.
      </li>
      <li>
         &ldquo;End User&rdquo; means a person who accesses the Designated Platform, other than the Customer and the Authorised Account Users;
      </li>
      <li>
         &ldquo;Fees&rdquo; means the fees and charges payable by the Customer to the Supplier as agreed during the Account Set Up Procedure or any Subsequent Account Set Up Procedure and without limiting the foregoing include fees for any Maintenance and Technical Support requested by the Customer.
      </li>
      <li>
         &ldquo;Force Majeure&rdquo; means any event beyond the reasonable control of the Parties which prevents or materially prejudices or renders materially more expensive, a Party performing one or of its obligations (other than an obligation to pay money) and includes but is not limited to:
      </li>
      <li>
         acts of God, natural disasters, fire, flood, lightning strikes, earthquakes, storms, explosions, transport delays, telephone and data connection interruption;
      </li>
      <li>
         acts of war, terrorism, riot, civil disturbance, third party criminal activity; or
      </li>
      <li>
         industrial action including pickets, work to rule, work bans and strikes.
      </li>
      <li>
         &ldquo;GST&rdquo; has the meaning given in section 195-1 of the GST Act.
      </li>
      <li>
         &ldquo;GST Act&rdquo; means the A New Tax System (Goods and Services Tax) Act 1999 (Cth).
      </li>
      <li>
         &ldquo;Intellectual Property Rights&rdquo; means statutory and other property rights in respect of trademarks, patents, circuit layouts, copyrights, confidential information and all other intellectual property rights as defined in Article 2 of the Convention establishing The World Intellectual Property Organisation of July 1967.
      </li>
      <li>
         &ldquo;Insolvent&rdquo;, in relation to a person, means if that person:
      </li>
      <li>
         is (or states that it is) an insolvent under administration or insolvent (each as defined in the Corporations Act);
      </li>
      <li>
         is in liquidation, in provisional liquidation, under administration or wound up or has had a receiver appointed to any part of its property;
      </li>
      <li>
         is subject to any arrangement, assignment, moratorium or composition, protected from creditors under any statute or dissolved (in each case, other than to carry out a reconstruction or amalgamation while solvent on terms approved by the other Party to this agreement);
      </li>
      <li>
         is the subject of an application or order (and in the case of an application, it is not stayed, withdrawn or dismissed within twenty (20) Business Days), resolution passed, which is preparatory to or could result in any of (i), (ii) or (iii) above;
      </li>
      <li>
         has failed to comply with a statutory demand under section 459F(1) of the Corporations Act or has not had the statutory demand set aside pursuant to section 459G of the Corporations Act;
      </li>
      <li>
         is the subject of an event described in section 459C(2)(b) of the Corporations Act (or it makes a statement from which the other Party to this agreement reasonably deduces it is so subject);
      </li>
      <li>
         has something happen in connection with it that has a substantially similar effect to (i) to (vi) above under the law of any jurisdiction; or
      </li>
      <li>
         is bankrupt or commits an act of bankruptcy.
      </li>
      <li>
         &ldquo;Liability&rdquo; means any liability and includes Losses.
      </li>
      <li>
         &ldquo;Liability Cap Amount&rdquo; means the amount paid to the Supplier by the Customer (less all commissions payable to third parties in relation to the Account) in the 12 month period prior to the event resulting in the relevant Liability.
      </li>
      <li>
         &ldquo;Licensed Software&rdquo; means the software offered by the Supplier and chosen by the Customer either during the Account Set Up Procedure or any Subsequent Account Set Up Procedure (but does not include any Source Code);
      </li>
      <li>
         &ldquo;Log On Procedures&rdquo; means procedures including username and password protocols used by or required to be used by the Supplier to access the Account or obtain Access Rights to the Account.
      </li>
      <li>
         &ldquo;Losses&rdquo; means claims, losses, liabilities, damages, costs and expenses of any kind, including those which are prospective or contingent and those the amount of which is not ascertainedor ascertainable.
      </li>
      <li>
         &ldquo;Maintenance and Technical Support&rdquo; means the services described and chosen by the Customer during the Account Set Up Procedure or any Subsequent Account Set Up Procedure;
      </li>
      <li>
         &ldquo;Party&rdquo; means a party to this Agreement and &ldquo;Parties&rdquo; means both parties to this Agreement;
      </li>
      <li>
         &ldquo;RCTI&rdquo; means a recipient created tax invoice within the meaning of the GST Act or VAT Act;
      </li>
      <li>
         &ldquo;Related Entity&rdquo; has the meaning given to it in the Corporations Act;
      </li>
      <li>
         &ldquo;Remote Access&rdquo; means functionality whereby the computer equipment of the Customer or the Authorised Account Users (as the case may be) has or have a permanent and secure data connection to the Supplier or its equipment enabling Maintenance and services to be carried out remotely;
      </li>
      <li>
         &ldquo;Services&rdquo; means the customised services, products or facilities described in the Account Set Up Procedure or any Subsequent Account Set Up Procedure;
      </li>
      <li>
         &ldquo;Source Code&rdquo; means the complete high level language computer programs in human readable alphanumeric characters, which when compiled, generate the object and executable program that constitutes a usable software product and any relevant documentation including the description of the development environment for the relevant software;
      </li>
      <li>
         &ldquo;Subsequent Account Set Up Procedure&rdquo; means any amendment or change to the particulars agreed by the parties in the Account Set Up Procedure;
      </li>
      <li>
         &ldquo;Statute&rdquo; means any act of any parliament or other legislative body or any regulations or other subordinate instrument made under or pursuant to such an act;
      </li>
      <li>
         &ldquo;Term&rdquo; means the term of this Agreement commencing on the date this Agreement is made or agreed during the Account Set Up Procedure and ending on termination or expiration of this Agreement.
      </li>
      <li>
         &ldquo;Third Party Software&rdquo; means the any software or Intellectual Property Rights belonging to or owned by a third party which are used by the Customer or any Authorised Account User in relation to the Services (but excluding the Software and Licensed Software).
      </li>
      <li>
         &ldquo;Valid Tax Invoice&rdquo; means a tax invoice that complies with the relevant GST Act or VAT Act.
      </li>
      <li>
         &ldquo;VAT&rdquo; means any type of consumption tax on a product or service whenever value is added at a stage of production or final sale of such product or sale and includes any tax which is similar in effect to the GST;
      </li>
      <li>
         &ldquo;VAT Act&rdquo; means any statute that imposes VAT in any jurisdiction in the world other than Australia;
      </li>
      <li>
         &ldquo;Valid Tax Invoice&rdquo; means a tax invoice that complies with:
      </li>
      <li>
         the GST Act; or
      </li>
      <li>
         the relevant legislation imposing VAT.
      </li>
      <li>
         &ldquo;Viruses&rdquo; means any viruses, time-bombs, back doors, trojan horses and any other form of malevolent or defective code or similar items.
      </li>
      <li>
         &ldquo;User&rdquo; means each Authorised Account User and End User;
      </li>
      <li>
         &ldquo;User Agreement&rdquo; means the agreement made between the Supplier and each User;
      </li>
      <li>
         &ldquo;Website&rdquo; means the website www.hotgiggity.com or such other web address notified by the Supplier from time to time and includes all domains within such website.
      </li>
   </ol>
   <p>
      1.2 In this Agreement, headings are inserted for convenience only and will not affect interpretation. Unless expressly provided otherwise:
   </p>
   <ol>
      <li>
         words importing the singular include the plural and vice versa;
      </li>
      <li>
         a word importing a gender includes the other genders;
      </li>
      <li>
         other grammatical forms of defined words or expressions have corresponding meanings;
      </li>
      <li>
         a reference to a clause, paragraph, Schedule or Annexure is a reference to a clause of, paragraph of, schedule of, or annexure to, this Agreement;
      </li>
      <li>
         a reference to this Agreement includes all schedules and annexures to it;
      </li>
      <li>
         a reference to dollars or $ means Australian dollars;
      </li>
      <li>
         a reference to a document or agreement includes a reference to that document or agreement as novated, altered or replaced from time to time;
      </li>
      <li>
         a reference to a Party or a person includes the Party&rsquo;s or the person&rsquo;s executors, legal personal representatives, successors, permitted transferees and assigns; and
      </li>
      <li>
         a reference to a specific time for the performance of an obligation is a reference to that time in the place, in the case of a notice, where the notice is to be received and otherwise, where the obligation is to be performed.
      </li>
   </ol>
   <p>
      1.3 If the doing of any act, matter or thing under this Agreement is dependent on the consent or approval of a Party or is within the discretion of a Party, the consent or approval may be given or the discretion may be exercised conditionally or unconditionally or withheld by the Party in its absolute discretion, unless this Agreement expressly provides otherwise.
   </p>
   <p>
      1.4 Where any conflict occurs between the provision contained in two or more of the documents forming this Agreement, the document lower in the order of precedence will where possible be read down to resolve such conflict. If the conflict remains incapable of resolution by reading down, the conflict provisions will be severed from the document lower in the order of precedence without otherwise diminishing the enforceability of the remaining provisions of that document.
   </p>
   <h3>
      2. RIGHT TO ACCESS AND USE DESIGNATED PLATFORM
   </h3>
   <p>
      2.1 On the terms and conditions set out in this Agreement, the Supplier agrees to allow the Customer and Authorised Account Users to access and use the Designated Platform using the Account.
   </p>
   <h3>
      3. ACCOUNT AND ACCOUNT ADMINISTRATOR
   </h3>
   <p>
      3.1 Subject to clause 3.2, the Supplier shall supply a facility (the Account) to allow the Customer and Authorised Account Users electronic access to the Designated Platform and the Services.
   </p>
   <p>
      3.2 To allow the Supplier to create the Account, the Customer must:
   </p>
   <ol>
      <li>
         appoint an individual person or persons to act on behalf of the Customer as the administrator of the Account (Account Administrator) in accordance with this Agreement, with the first Account Administrator being the person(s) described during Account Set Up Procedure; and
      </li>
      <li>
         advise the Supplier of the details of any change to such appointment during a Subsequent Account Set Up Procedure.
      </li>
   </ol>
   <p>
      3.3 Subject to the rights of the Supplier, the Account Administrator shall be responsible for the operation of the Account and without limiting the foregoing the Account Administrator:
   </p>
   <ol>
      <li>
         shall invite Authorised Account Users to use the Designated Platform via the Account;
      </li>
      <li>
         must determine the Access Rights of Authorised Account Users and advise the Supplier of such Access Rights;
      </li>
      <li>
         may alter or terminate the Access Rights of Authorised Account Users at its discretion; and
      </li>
      <li>
         is responsible for and agrees to monitor all Account Data.
      </li>
   </ol>
   <h3>
      4. AUTHORISED ACCOUNT USERS
   </h3>
   <p>
      4.1 A person is an Authorised Account User at a particular time if:
   </p>
   <ol>
      <li>
         the person is an employee, officer or consultant of the Customer;
      </li>
      <li>
         the Account Administrator has invited the person to use the Account as an Authorised Account User;
      </li>
      <li>
         the person has made a User Agreement with the Supplier relating to the use of the Account and the Designated Platform; and
      </li>
      <li>
         the person has not:
      </li>
      <li>
         been removed as an Authorised Account User or had his, her or its rights as an Authorised Account User terminated by the Customer; and
      </li>
      <li>
         the User Agreement has not been terminated or expired.
      </li>
   </ol>
   <h3>
      5. SUPPLIES TO AUTHORISED ACCOUNT USERS
   </h3>
   <p>
      5.1 Subject to clause 6.2, the Supplier and the Customer agree that the Supplier will supply to the Customer and Authorised Account Users:
   </p>
   <ol>
      <li>
         Access Rights to the Designated Platform;
      </li>
      <li>
         a licence to use of the Licensed Software in accordance with clause 6.
      </li>
   </ol>
   <p>
      5.2 The Supplier and the Customer agree that:
   </p>
   <ol>
      <li>
         the Supplier will require all Authorised Account Users to enter into a separate User Agreement with the Supplier in relation to rights to use the Designated Platform via the Account;
      </li>
      <li>
         the Customer will be responsible for:
      </li>
      <li>
         the payment of any Fees or other amounts paid or payable by the Authorised Account Users to the Supplier; and
      </li>
      <li>
         the acts and omissions of the Authorised Account Users; that relate to use of the Designated Platform and the Licensed Software via the Account by the Authorised Account Users, including Maintenance and Technical Services supplied to the Authorised Account Users.
      </li>
   </ol>
   <h3>
      6.&nbsp;SOFTWARE LICENSE
   </h3>
   <p>
      6.1 Subject to clauses 5 and 6.2, the Supplier grants to the Customer and the Authorised Account Users a non-exclusive, non-transferable license to use the Licensed Software:
   </p>
   <ol>
      <li>
         during the Term;
      </li>
      <li>
         for the sole purpose of accessing the Services and/or the Designated Platform.
      </li>
   </ol>
   <p>
      6.2 The Supplier and the Customer agree that:
   </p>
   <ol>
      <li>
         the Customer and each Authorised Account User and Authorised Account User will be required to agree to enter into software license agreements for each relevant software program constituting the Licensed Software;
      </li>
      <li>
         the Supplier may change the terms upon which the Licensed Software is licensed to the Customer and Authorised Account Users (both in respect of the Licensed Software and any other software licensed during the Term and the Fees;
      </li>
      <li>
         any right to use Third Party Software will be supplied by the Supplier or Third Party on terms separately agreed between the Customer and the supplier of the Third Party Software; and
      </li>
   </ol>
   <h3>
      7. THIRD PARTY SOFTWARE
   </h3>
   <p>
      7.1 The Supplier will assist the Customer and Authorised Account Users to obtain a licence from third parties to use Third Party Software during the Term to the extent that it is necessary to obtain the benefit of the Services. Any such licence will be on the terms agreed between the Customer or Authorised Account Users (as the case may be) and the relevant third party and the Supplier makes no warranty or representation in relation to the supply or use of Third Party Software.
   </p>
   <p>
      7.2 The Customer acknowledges that the use of Third Party Software may be necessary in order to obtain or use the Services. If it is necessary to use such Third Party Software, it is a condition of provision of the Services that the Customer and Authorised Account Users agree and comply with the terms upon which any Third Party Software is licensed to them.
   </p>
   <h3>
      8. INTELLECTUAL PROPERTY
   </h3>
   <p>
      8.1 Other than rights expressly granted under this Agreement, no right, title or interest is granted to the Customer or any Authorised Account User in the Licensed Software, and the Customer expressly disclaims any such right, title or interest and will not during or after this Agreement challenge or dispute the Supplier&rsquo;s ownership of or rights to the Licensed Software.
   </p>
   <p>
      8.2 Subject to clause 8.3, the Supplier retains ownership of and rights to all relevant Intellectual Property Rights in and relating to the Designated Platform and the Licensed Software and the Customer will not, during the Term or after this Agreement has terminated, challenge or dispute such ownership or rights.
   </p>
   <p>
      8.3 Despite any other provision of this Agreement, as between the Customer and the Supplier:
   </p>
   <ol>
      <li>
         the Customer will own the Intellectual Property Rights which reside solely in the Customer Data and Authorised Account User Data (even if processed or generated in part using the Licensed Software);
      </li>
      <li>
         the Customer will own the Intellectual Property Rights relating to its brands, trademarks, logos, business names, styling, design rights (together with any associated copyright);
      </li>
      <li>
         any information, reports, documents generated by the Customer using the Licensed Software will not be owned by the Supplier;
      </li>
      <li>
         the Supplier solely and exclusively owns:
      </li>
      <li>
         the Licensed Software;
      </li>
      <li>
         all Background IP incorporated into or used in association with the Software and Services;
      </li>
      <li>
         the Supplier&rsquo;s Confidential Information;
      </li>
      <li>
         all derivative works based on the Licensed Software or the Supplier&rsquo;s Confidential Information;
      </li>
      <li>
         all derivative works, ideas, concepts, know-how, and techniques that the Supplier may use, conceive of or first reduce to practice in connection with the Licensed Software.
      </li>
   </ol>
   <p>
      8.4 Nothing in this clause or this Agreement transfers ownership of any Source Code that produces or generates Data, information, reports or documents.
   </p>
   <h3>
      9. CUSTOMER OBLIGATIONS
   </h3>
   <p>
      9.1 The Customer will not and will ensure that all Authorised Account Users do not:
   </p>
   <ol>
      <li>
         reproduce, disseminate or otherwise disclose the content of any Licensed Software except as expressly set out in this Agreement;
      </li>
      <li>
         electronically transmit any Licensed Software over a network except as necessary for the Customer's or Authorised Account User&rsquo;s licence of the Licensed Software;
      </li>
      <li>
         modify, disassemble, decompile, or reverse engineer any Licensed Software or the Software except to the extent permitted by law; and
      </li>
      <li>
         use the Services or the Licensed Software in any way not expressly provided for by this Agreement.
      </li>
   </ol>
   <p>
      9.2 The Customer is responsible for acquiring and maintaining all licences and permissions necessary in respect of any Third Party Software and data that belongs to third parties it may use in connection with the Services. The Customer confirms that any Customer Information or other materials provided by the Customer to the Supplier or supplied by the Customer in relation to the Services will not infringe any Intellectual Property Rights of any third party, will not be obscene or defamatory, and will not violate the laws or regulations of any state which may have jurisdiction over such activity.
   </p>
   <p>
      9.3 The Customer acknowledges that it is responsible for its input (including all Account Data) in relation to the Service and for any use that it or its Authorised Account Users make of such input and Data and that the Supplier has no responsibility for such input or Data or its use.
   </p>
   <p>
      9.4 The Customer acknowledges that it is its sole responsibility to determine that the Services and the Licensed Software meet the needs of its business, and to satisfy itself that the Licensed Software are ready for operational use in its business before it is so used.
   </p>
   <p>
      9.5 The Customer licenses the Supplier the right to incorporate items of the Customer's corporate branding, such as a trade mark or name or logo, for the purpose of customising its pages and input as requested by the Customer.
   </p>
   <p>
      9.6 If the Customer learns about or is informed of any of the components, processes or methods of operating any Licensed Software comprised in any of the Services it will treat that knowledge or information as the Supplier&rsquo;s trade secret, and not use it to the benefit of any party other than the Supplier or convey it in any way to any third party or allow any third party to acquire it.
   </p>
   <p>
      9.7 If the Supplier suffers any Loss or Liability as a result of:
   </p>
   <ol>
      <li>
         any unauthorised access to, or use or misuse of, the Services or the Licensed Software by the Customer or any Authorised Account User; or
      </li>
      <li>
         any unauthorised access to, or use or misuse of, the Services or the Licensed Software, by any third party if such access, use or misuse was permitted or facilitated by the Customer or Authorised Account User, the Customer will fully indemnify the Supplier in respect of such Loss or Liability.
      </li>
   </ol>
   <h3>
      10. MAINTENANCE AND TECHNICAL SUPPORT SERVICES
   </h3>
   <p>
      10.1 The Supplier agrees to supply the Maintenance and Technical Support Services to the designated platform.
   </p>
   <h3>
      11. CUSTOMER AND AUTHORISED ACCOUNT USER DATA
   </h3>
   <p>
      11.1 The Customer warrants that it is either the sole and exclusive owner or an authorised licensee or user of all Intellectual Property Rights and all other rights in the Customer Data including, but not limited to, any trade marks or logos, and that it is authorised to allow the use of such rights as necessary for the use of and provision of the Services, and warrants that the Account Data does not infringe the copyright or other Intellectual Property Rights of any other person.
   </p>
   <p>
      11.2 The Customer is solely responsible for any breach of the Intellectual Property Rights or privacy rights of any person arising as a result of the provision or use of Authorised Account User Data through the use of the Services or the Licensed Software.
   </p>
   <p>
      11.3 The Customer is responsible for checking, validation, error correction, backup and reconstruction of any Customer Data and Authorised Account User Data at all times, before and after it is entered into this Agreement and during the Term.
   </p>
   <p>
      11.4 The Customer acknowledges and agrees that it is entirely responsible for the Customer Data, Authorised Account User Data and other input to the Designated Platform via the Account including its accuracy and integrity, and for any use that it, or any third party may make of it for any purposes. Without limiting the foregoing, to the full extent permitted by law, the Supplier gives no warranty that Customer Data, Authorised Account User Data will not be altered or destroyed through the application of the Licensed Software or a result of it being stored, retrieved, used, altered or managed on the Designated Platform or through the Account.
   </p>
   <p>
      11.5 Without prejudice to the generality of the previous sub-clause, the Customer undertakes that neither it nor the Authorised Account Users will provide any item of Data or upload, transmit or download any Data, message or material that:
   </p>
   <ol>
      <li>
         is defamatory, racist or sexist, threatening or menacing to any person or group of people, or otherwise illegal, or which in the Supplier's reasonable opinion is likely to cause annoyance or distress to any person;
      </li>
      <li>
         infringes the copyright or other Intellectual Property Rights of any other person, company or partnership, anywhere in the world;
      </li>
      <li>
         impersonates another person without their explicit written consent;
      </li>
      <li>
         contains any Viruses or any other harmful or destructive files;
      </li>
      <li>
         constitutes or facilitates any conduct which is unlawful or criminal; or
      </li>
      <li>
         constitutes, directly or via the Supplier, the sending of any unsolicited e-mail.
      </li>
   </ol>
   <p>
      11.6 The Customer acknowledges and asserts that it is the best judge of the value and importance of the Customer Data and Authorised Account User Data and that it will be solely responsible for instituting and operating all necessary back-up procedures, for its own benefit, to ensure that the integrity of the data can be maintained in the event of loss for any reason.
   </p>
   <p>
      11.7 If, for the purpose of providing the Services, it is necessary or desirable for the Supplier to access or use any Customer Data, Authorised Account User Data or equipment, facilities or services of the Customer or Authorised Account User, the Customer will make these available to the Supplier for access free of charge to enable the Supplier to perform its obligations under this Agreement, and the Customer grants to the Supplier a non-exclusive, royalty-free licence for such purposes.
   </p>
   <p>
      11.8 The Customer agrees that it will not and will ensure that the Authorised Account Users do not:
   </p>
   <ol>
      <li>
         access, amend or any data or information of any other person which it is not authorised to do under this Agreement; or
      </li>
      <li>
         disrupt or attempt to disrupt or damage the Website, the content of the Website, servers, platforms or networks of the Supplier.
      </li>
   </ol>
   <h3>
      12. PRIVACY
   </h3>
   <p>
      12.1 The Customer will comply with and ensure that all the Authorised Account Users:
   </p>
   <ol>
      <li>
         comply with the Supplier&rsquo;s privacy policy from time to time; and
      </li>
      <li>
         comply with all laws relating to privacy.
      </li>
   </ol>
   <p>
      12.2 Without limiting clause 12.1, the Customer acknowledges that the Website and the Services may allow the Customer and Authorised Account Users to communicate with third parties and agree to exercise care and discretion in disclosing Customer Data and Authorised Account User Data to third parties.
   </p>
   <p>
      12.3 The Customer acknowledges that it is a condition of the provision of the Maintenance and Technical Support services described in this Agreement that the Customer and the Authorised Account Users comply with the Log On Procedures.
   </p>
   <p>
      12.4 To the maximum extent allowed by law, the Supplier will have no Liability under this Agreement to the Customer or any Authorised Account User if any loss or damage or expense suffered or incurred by the Customer or any Authorised Account User resulted in part or whole from a breach of this Agreement by the Customer or an Authorised Account User or any User or any failure by a Customer or Authorised Account User to comply with Log On Procedures or the reasonable instructions or directions of the Supplier.
   </p>
   <p>
      12.5 The Customer will keep an up to date list of the Authorised Account Users and will provide it to the Supplier on request.
   </p>
   <h3>
      13. DATA SECURITY
   </h3>
   <p>
      13.1 The Supplier will effect and maintain at all times standard industry security measures, in order to safeguard the Customer Data and Authorised Account User Data from unauthorised access and use, and to minimise the risk of unauthorised access to the Account, Customer Data and Authorised Account User Data. Subject to the foregoing, the Supplier provides no warranty that Customer Data and Authorised Account User Data will be secure from attack, Viruses, destruction, theft, alteration or sterilisation as a result of unauthorised access by third parties (including hackers) and regardless of how such access occurred.
   </p>
   <p>
      13.2 Using appropriate and reliable storage media, the Supplier will regularly backup Customer Data and Authorised Account User Data and retain such backup copies for a minimum of 90 days.
   </p>
   <p>
      13.3 The Supplier will retain logs associated with Customer and Authorised Account User activities relating to the Services for a period of 90 days after the Term has ended.
   </p>
   <p>
      13.4 Standard Encryption techniques will be used for protecting data on input and transmission.
   </p>
   <p>
      13.5 The Supplier will promptly notify the Customer of any security attack which it learns of or suspects, which appears to be directed towards the Designated Platform.
   </p>
   <h3>
      14. DATA TRANSFER UPON TERMINATION OR EXPIRATION
   </h3>
   <p>
      14.1 On the termination or expiration of this Agreement:
   </p>
   <ol>
      <li>
         the Customer is obliged to ensure that all Customer Data and Authorised Customer Data is transferred or exported to the Customer (or its equipment or storage devices) at its own expense within 60 days of such termination or expiration; and
      </li>
      <li>
         the Supplier will provide all reasonable assistance to Customer to ensure the transfer or export of all Customer Data and Authorised Customer Data to the Customer provided however that the Supplier may charge reasonable fees for this services and the Customer must (in any case) use and request a method and facilities that are compatible with the Supplier&rsquo;s system and at the Supplier&rsquo;s option the Supplier may provide (solely) a back-up copy of such Customer Data and Authorised Customer Data.
      </li>
   </ol>
   <p>
      14.2 If the Customer does not comply with its obligations under this clause the Supplier will not be under any obligation to retain Customer Data and Authorised Account User Data for more than 90 days after the termination or expiration of this Agreement and without limiting the foregoing:
   </p>
   <ol>
      <li>
         the Supplier may destroy Customer Data and Authorised Account User Data after such period; and/or
      </li>
      <li>
         the Supplier may retain such Customer Data and Authorised Account User Data and charge the Customer for the costs of such retention if required by law to do so or if it is reasonable to do so.
      </li>
   </ol>
   <p>
      14.3 Notwithstanding any other provision in this clause:
   </p>
   <ol>
      <li>
         the Supplier will be entitled to retain and disclose any Customer Data and Authorised Account User Data in order to comply with any legal or taxation obligation to do so;
      </li>
      <li>
         as between the Supplier and the Customer, the Customer is the owner of the Customer Data and Authorised Account User Data and retains fully property rights in such data;
      </li>
      <li>
         except for any rights expressly granted to the Supplier, the Agreement does not give the Supplier any rights to Customer Data and Authorised Account User Data; and
      </li>
      <li>
         the Supplier will only use or access Customer Data and Authorised Account User Data for the purposes of carrying out its obligations under this Agreement; and
      </li>
      <li>
         the Supplier does not warrant that:
      </li>
      <li>
         it will be technically possible to transfer or export all the Customer Data and Authorised Customer Data to the Customer; or
      </li>
      <li>
         that the cost and conditions for the transfer or export of all the Customer Data and Authorised Customer Data will be on terms acceptable or satisfactory to the Customer.
      </li>
   </ol>
   <h3>
      15. AVAILABILITY OF SERVICES
   </h3>
   <p>
      15.1 Subject to the provisions set out in this clause, the Supplier will make the Services and the Licensed Software available to the Customer and Authorised Account Users during the Term.
   </p>
   <p>
      15.2 The Services and the Licensed Software may be suspended for so long as is reasonably necessary as Downtime subject to prior agreement with the Customer, such agreement not to be unreasonably withheld or delayed:
   </p>
   <ol>
      <li>
         to enable either party to comply with an order or request from a governmental, or other competent regulatory body or administrative authority, or
      </li>
      <li>
         to enable the Supplier to carry out work which is necessary in its reasonable opinion to maintain or improve the Services, or
      </li>
      <li>
         to carry out standard maintenance and support; provided that the Supplier will use all reasonable endeavours to schedule such Downtime during hours of low usage of the Services in order to minimise impact on the Services, and to ensure that there is no permanent material degradation of the Services.
      </li>
   </ol>
   <p>
      15.3 Without limiting clause 15.2, the Supplier gives no warranty:
   </p>
   <ol>
      <li>
         that the Customer or the Authorised Account Users will be able to access Customer Data and Authorised Account User Data;
      </li>
      <li>
         relating to the speed at which the Customer or the Authorised Account Users will be able to access Customer Data and Authorised Account User Data or store, retrieve, alter such Customer Data and Authorised Account User Data, provided always that that the Services and the Licensed Software must be reasonably suitable for usage by the Customer and Authorised Account Users in the manner intended by this Agreement and provided to a standard no less than the general industry standard in Australia for similar types of services and licensed software.
      </li>
   </ol>
   <p>
      15.4 If Licensed Software requires immediate correction to enable it to run effectively or for immediate compliance with a governmental or regulatory requirement, the Supplier may suspend that the Licensed Software without advance warning for as long as reasonably necessary to implement the correction or to ensure compliance provided that the Supplier will use all reasonable endeavours to schedule such Downtime during hours of low usage of the Services in order to minimise impact on the Services, and to ensure that there is no permanent material degradation of the Services.
   </p>
   <p>
      15.5 The Supplier is entitled in its sole discretion to make changes or upgrades to the Services or Licensed Software or accessibility provided that such changes or upgrades do not cause any material reduction in functionality. The Supplier will endeavour to give at least 7 days' written notice of any such changes. Licensed Software may be suspended for so long as is reasonably necessary, but the Supplier will use all reasonable endeavours to minimise the Downtime that may be caused by such change or upgrade and will use all reasonable endeavours to schedule such Downtime during hours of low usage of the Services in order to minimise impact on the Services.
   </p>
   <p>
      15.6 The Supplier shall not be Liable to any person for interruptions or delays in provision of the Services resulting from:
   </p>
   <ol>
      <li>
         the acts or omissions of the Customer or Authorised Account Users that impair the ability of the Supplier to provide the Services or prevent the Supplier from providing the Services ;
      </li>
      <li>
         disruptions to or lack of access to the Services caused by a lack of internet connection or wi-fi connection (or disruptions thereto) that are beyond the reasonable control of the Supplier; or
      </li>
      <li>
         any act or omission beyond the reasonable control of the Supplier, including any acts or omissions of suppliers of services to the Supplier which are beyond the reasonable control of the Supplier.
      </li>
   </ol>
   <h3>
      16. WARRANTIES AND DISCLAIMERS
   </h3>
   <p>
      16.1 The Supplier warrants that it is either the owner or an authorised licensee or user of all Intellectual Property Rights needed to comply with its obligations in relation to the Services under this Agreement.
   </p>
   <p>
      16.2 The Supplier warrants that it will provide the Services using all reasonable skill and care in accordance with the terms of this Agreement.
   </p>
   <p>
      16.3 The Supplier will at all times use all reasonable endeavours to provide the Services free of Viruses and without interruption. However, the Supplier strongly recommends that the Customer has its own effective anti-Virus programs.
   </p>
   <p>
      16.4 The Supplier does not and cannot control the Designated Platform or the flow of Data (including Customer Data and Authorised Customer Data) to or from its Platform. Such flow depends on the performance of services provided or controlled by third parties. At times, actions or omissions of such third parties can impair or disrupt connections. Although the Supplier will use all commercially reasonable efforts to avoid such events and take all actions it deems appropriate to remedy such events, the Supplier cannot guarantee that such events will not occur. Accordingly, without limiting clause 15, the Supplier cannot and does not warrant that the Services will be uninterrupted, error-free or entirely secure, and will not be liable for any delay in response time in accessing the internet and disclaims any and all liability resulting from or related to such events. The Customer accepts that the Supplier cannot guarantee availability or response times and may have to suspend the Services at its sole discretion from time to time.
   </p>
   <p>
      16.5 The Customer acknowledges that the Supplier does not provide any back-up software or processing facilities covering equipment, data, operating systems or application software, and the Customer agrees that the Supplier will not be responsible or liable if, for any reason concerning any of these, the Services cannot be provided.
   </p>
   <p>
      16.6 The Supplier does not make any other warranties, guarantees or representations concerning the operation or performance of the Services or the Licensed Software. The Customer is entirely responsible for deciding to select the Services for its own business purposes, and the Supplier accepts no liability for any use to which the Customer puts the Services.
   </p>
   <p>
      16.7 The Supplier does not accept any responsibility or any liability for enabling the Customer to make any link to the contents of any other website, whether one from which the Customer may have been linked to, or to which the Customer may link from.
   </p>
   <h3>
      17. FEES RELATING TO SERVICES
   </h3>
   <p>
      17.1 The Customer will pay the Fees and any applicable GST and VAT on the terms and in the amount and within the time provided for in any Valid Tax Invoice sent to Customer by Supplier.
   </p>
   <p>
      17.2 The Customer will pay the Fees and any applicable GST or VAT in full and without deduction or abatement other than those required by law.
   </p>
   <p>
      17.3 The Customer will pay interest on any sums unpaid to the Supplier after becoming due and payable and at the rate from time to time set out in the Penalty Interest Rates Act (Victoria)1983.
   </p>
   <p>
      17.4 Payments must be made by the Customer by electronic funds transfer to the account the Supplier nominated to the Customer from time to time or by such other method nominated by the Supplier.
   </p>
   <p>
      17.5 The Supplier reserves the right to increase or vary the Fees at any time with reasonable notice to the Customer.
   </p>
   <p>
      17.6 The customer will be billed every month in arrears after joining and electing to subscribe to the relevant applications as managed by the customers nominated account administrator/s.
   </p>
   <p>
      17.7 The customer is not entitled to any refunds as any subscription payments are paid in arrears for services provided by the platform and the supplier. The customers Appointed account administrator/s are entirely responsible for managing the customer&rsquo;s account and use of the platform or services rendered by or through the supplier.
   </p>
   <h3>
      18. CONFIDENTIALITY
   </h3>
   <p>
      18.1 In relation to the Confidential Information of a Party, the recipient Party will:
   </p>
   <ol>
      <li>
         use the Confidential Information only for the purposes for which it has been disclosed;
      </li>
      <li>
         keep that Confidential Information confidential and not disclose it or allow it to be disclosed to any third party except:
      </li>
      <li>
         with the consent of the owner of the Confidential Information;
      </li>
      <li>
         to Associates to the extent that each has a need to know for the purposes contemplated by this Agreement and who are aware that the Confidential Information will be kept confidential;
      </li>
      <li>
         to its legal advisors and auditors and other consultants who require it for the purposes contemplated by this agreement or for the purpose of providing legal or commercial advice in relation to this agreement;
      </li>
      <li>
         if required to do so by law or a stock exchange; or
      </li>
      <li>
         if required to do so in connection with legal proceedings relating to this Agreement;
      </li>
      <li>
         take or cause to be taken reasonable precautions necessary to maintain the secrecy and confidentiality of the Confidential Information; and
      </li>
      <li>
         continue to keep confidential all Confidential Information of the other Party.
      </li>
   </ol>
   <p>
      18.2 With the exception of information which the Supplier needs to retain for its own business records, upon request of the Customer, the Supplier must return to the Customer at the expense of the Customer that Confidential Information of the Customer or that the Customer requests that the Supplier returns or if the Customer requests that it be destroyed, certify that it has been destroyed.
   </p>
   <p>
      18.3 With the exception of information which the Customer needs to retain for its own business records; upon the request of the Supplier, the Customer must return to the Supplier the Confidential Information of the Supplier.
   </p>
   <p>
      18.4 The rights and obligations of the Parties set out in this Agreement with respect to Confidential Information will survive termination of this Agreement.
   </p>
   <p>
      18.5 The Supplier is entitled to inform third parties of the fact that the Customer has a license of the Licensed Software.
   </p>
   <p>
      18.6 The Customer is entitled to inform third parties of the fact that the Customer has a license of the Licensed Software from the Supplier.
   </p>
   <h3>
      19. IMPLIED TERMS
   </h3>
   <p>
      19.1 Subject to clause 19.2 the Supplier provides no representation or warranty in relation to the Licensed Software other than the express limited warranties set out in this Agreement.
   </p>
   <p>
      19.2 Where any legislation implies in this Agreement any condition or warranty and that legislation avoids or prohibits provisions in a contract excluding or modifying the application or exercise of, or any liability under, such condition or warranty, the condition or warranty will be deemed to be included in this Agreement. However, the liability of the Supplier for any breach of such condition or warranty will be limited, at the Supplier&rsquo;s option, to one or more of the following:
   </p>
   <ol>
      <li>
         if the breach relates to goods:
      </li>
      <li>
         the replacement of the goods with equivalent goods or the supply of equivalent goods;
      </li>
      <li>
         the repair of such goods;
      </li>
      <li>
         the payment of the cost of replacing the goods or of acquiring equivalent goods; or
      </li>
      <li>
         the payment of the cost of having the goods repaired; and
      </li>
      <li>
         if the breach relates to services:
      </li>
      <li>
         the supplying of the services again; or
      </li>
      <li>
         the payment of the cost of having the services supplied again.
      </li>
   </ol>
   <h3>
      20. LIMITATION OF LIABILITY
   </h3>
   <p>
      20.1 Subject to clause 20.7, but otherwise notwithstanding anything to the contrary in this Agreement, the Supplier&rsquo;s total liability for damages or other forms of monetary relief for matters related to, connected with or arising out of this Agreement regardless of the cause of action, whether in contract, tort (including, without limitation, negligence) or breach of any Statute or any other legal or equitable obligation is limited to the Liability Cap Amount.
   </p>
   <p>
      20.2 Where any Statute implies in this Agreement any term, and that Statute avoids or prohibits provisions in a contract excluding, restoring or modifying the application of, or the exercise of, or liability under such term, such implied terms as are not excludable will be deemed to be included in this Agreement. However, the liability of the Supplier for any breach of such term will be limited, at the option of the Supplier, to any one or more of the following:
   </p>
   <ol>
      <li>
         if the breach related to goods: the replacement of the goods or the supply of equivalent goods; the repair of such goods; the payment of the cost of replacing the goods or of acquiring equivalent goods; or the payment of the cost of having the goods repaired; and
      </li>
      <li>
         if the breach relates to services the supplying of the services again; or the payment of the cost of having the services supplied again.
      </li>
   </ol>
   <p>
      20.3 In no event will the Supplier be liable to another for any incidental, special, indirect, consequential Losses or damage of any character, (including without limitation, loss of profit, revenue, goodwill or anticipated savings, loss of data, work stoppage or computer failure).
   </p>
   <p>
      20.4 Without limitation to the generality of clause 20.3, under no circumstances will the Supplier be liable for direct, indirect or consequential Losses, personal injury or death arising out of or in connection with:
   </p>
   <ol>
      <li>
         the Customer&rsquo;s computer equipment or the equipment of Authorised Account Users failing to operate in accordance with its specification or otherwise;
      </li>
      <li>
         any element of the Customer&rsquo;s business not functioning correctly; or
      </li>
      <li>
         any act or omission of any person acting as the Customer&rsquo;s representative or agent or Authorised Account Users.
      </li>
   </ol>
   <p>
      20.5 The Customer warrants that it has not relied on any representation made by the Supplier which has not been stated expressly in this Agreement or upon any descriptions, illustrations or specifications contained in any document or publicity material produced by the Supplier and that, but for providing such warranty, the Supplier would have entered into this Agreement.
   </p>
   <p>
      20.6 The Customer acknowledges that to the extent the Supplier has made any representation which is not otherwise expressly stated in this Agreement, the Customer has been provided with an opportunity to independently verify the accuracy of that representation.
   </p>
   <p>
      20.7 The Customer indemnifies the Supplier and each of its Associates (the &ldquo;Indemnified&rdquo;) against any Losses incurred by the Indemnified, arising directly out of any of the following:
   </p>
   <ol>
      <li>
         any fraud or wilful misconduct of the Customer or its Associates or Authorised Account Users under or in connection with this Agreement causing Liability;
      </li>
      <li>
         any infringement or alleged infringement of the Supplier&rsquo;s or a third party&rsquo;s Intellectual Property Rights by the Customer or any of its Associates or Authorised Account Users;
      </li>
      <li>
         personal injury, death or loss of or damage to real or tangible personal property (excluding data) caused by the Customer or any of its Associates or Authorised Account Users;
      </li>
      <li>
         breach by the Customer or Authorised Account Users of any obligation of confidentiality owed to the Supplier;
      </li>
      <li>
         any claim, action or proceeding by a third party against any of the Indemnified to the extent that it relates to a breach of this Agreement by the Customer or Authorised Account Users; or
      </li>
      <li>
         a breach of the warranty in clause 20.5
      </li>
   </ol>
   <p>
      20.8 Where any Statute provides a guarantee in relation to the supply of any good or service under this Agreement and that Statute avoids or prohibits provisions in a contract excluding, restoring or modifying the application of, or the exercise of, or liability under such guarantee, such guarantees as are not excludable, will be deemed to be included in this Agreement. However, to the maximum extent permitted under the Statute, the liability of the Supplier for any failure to comply with the guarantee will be limited, at the option of the Supplier, to any one or more of the following:
   </p>
   <ol>
      <li>
         if the failure relates to goods:
      </li>
      <li>
         the replacement of the goods or the supply of equivalent goods;
      </li>
      <li>
         the repair of such goods;
      </li>
      <li>
         the payment of the cost of replacing the goods or of acquiring equivalent goods; or
      </li>
      <li>
         the payment of the cost of having the goods repaired, and
      </li>
      <li>
         if the failure relates to services:
      </li>
      <li>
         the supplying of the services again; or
      </li>
      <li>
         the payment of the cost of having the services supplied again.
      </li>
   </ol>
   <h3>
      21. SUSPENSION AND TERMINATION
   </h3>
   <p>
      21.1 The Supplier may suspend this Agreement and any licence relating to the Licensed Software if the Customer fails to pay any Fees when due, and after notice from the Supplier to the Customer notifying the Customer of such failure, the Customer fails to make payment of such Fees within five (5) Business Days of receipt of such notice.
   </p>
   <p>
      21.2 The Supplier may terminate the Agreement, the Account and any license relating to the Licensed Software:
   </p>
   <ol>
      <li>
         if the Customer fails to pay any Fees (or any other fees owing to the Supplier by the Customer whether pursuant to this Agreement or otherwise) when due, and after written notice from the Supplier to the Customer notifying the Customer of such failure, the Customer fails to make payment of such Fees within five (5) Business Days of receipt of such notice;
      </li>
      <li>
         if the Customer or any Authorised Account User breach this Agreement and fail to rectify such breach within five Business Days of receipt of a notice setting out the details of such breach;
      </li>
      <li>
         if the Customer or any Authorised Account User uses any of the Licensed Software in an unauthorised manner or allows unauthorised third parties to use the Licensed Software, and after written notice from the Supplier to the Customer notifying the Customer of unauthorised use, the Customer fails to prevent (with the reasonable assistance of the Supplier) such unauthorised use within ten (10) Business Days of receipt of such notice;
      </li>
      <li>
         immediately, and without notice to the Customer, if the Customer becomes Insolvent; or
      </li>
      <li>
         if an event of Force Majeure continues for a period of more than two months.
      </li>
   </ol>
   <p>
      21.3 Either party may terminate this Agreement, the Account and any licence relating to the Licensed Software by 30 days&rsquo; written notice to the other party.
   </p>
   <p>
      21.4 Upon termination of this Agreement under clause 21.2 by the Supplier or by either party under clause 21.3, all rights of the Customer in relation to the Licensed Software and the Account will cease immediately and the Customer and will return all copies of any Licensed Software to the Supplier immediately.
   </p>
   <p>
      21.5 Termination of this Agreement pursuant to this clause will not otherwise affect the accrued rights of any Party.
   </p>
   <p>
      21.6 The Customer may terminate this Agreement at any time by immediate notice in writing to the Supplier if the Supplier becomes Insolvent. Upon termination of this Agreement under this clause 21.6 any unused portion of the monthly fee payable by the Customer to the Supplier will be immediately refunded by the Supplier to the Customer on a pro-rata basis
   </p>
   <h3>
      22. GST AND TAXES
   </h3>
   <p>
      22.1 Amounts payable under this Agreement to a Party are exclusive of any applicable GST, VAT or similar Tax chargeable in relation to the supply of any good, service or thing under or pursuant to this Agreement unless clearly and expressly stated to the contrary. The GST, VAT and any similar taxation treatment of any supply will be determined pursuant to the GST Act or VAT Act or equivalent statute of the jurisdiction where a taxable transaction for GST purposes (or equivalent VAT tax purposes) is deemed to take place.
   </p>
   <p>
      22.2 If GST or VAT is properly chargeable on any such supply or supplies, the Customer will pay to the Supplier an amount equal to the GST or VAT, if any, chargeable in the relevant jurisdiction; provided, however, that such amount will only be required to be paid once the Supplier provides to the Customer with a Valid Tax invoice in relation to that amount.
   </p>
   <p>
      22.3 Each Party will, to the extent permitted by law, provide the other with any Valid Tax Invoices (or equivalents) as required for the purposes of this Agreement and, to the extent required by law, will correctly account for any GST or VAT properly due in its jurisdiction.
   </p>
   <p>
      22.4 Subject to each Party&rsquo;s obligations relating to GST and VAT, each Party will cause all royalty tax, taxes, duties and other sums (including any royalty withholding tax, stamp duty, other documentary taxes, environmental tax or levy) legally payable by that Party arising in connection with this Agreement to be paid.
   </p>
   <p>
      22.5 If the Supplier is required by law to pay or deduct any royalty tax, taxes, duties and other sums (&ldquo;Other Taxes&rdquo;) which are properly for the account of the Customer, the Customer will promptly indemnify or reimburse the Supplier in respect of such Other Taxes provided always that the Supplier has provided the Customer with a copy of all documentation reasonably required by the Customer in order to allow the Customer to recoup any such Other Taxes to the extent that they are able to be recouped.
   </p>
   <h3>
      23. FURTHER ASSURANCES&nbsp;
   </h3>
   <p>
      23.1 Each Party will sign, execute, deliver and do and will procure that each of its officers, employees and agents signs, executes, delivers and does, all deeds, documents, instruments and acts reasonably required of it or them by notice from another Party to effectively carry out and give full effect to this Agreement and the rights and obligations of the Parties under it.
   </p>
   <h3>
      24. NO MERGER
   </h3>
   <p>
      24.1 The rights and obligations of the Parties in respect of agreements, indemnities, covenants and warranties contained in this Agreement will remain in full force and effect, be continuing agreements, indemnities, covenants and warranties and not be merged or extinguished by or upon termination or completion of any obligations under, this Agreement.
   </p>
   <h3>
      25. NO PARTNERSHIP
   </h3>
   <p>
      25.1 Nothing contained or implied in this Agreement will create or constitute, or be deemed to create or constitute, a partnership between the Parties for the purposes of any law or Statute of any jurisdiction.
   </p>
   <p>
      25.2 A Party will not act, represent or hold itself out as having authority to act as the agent of or in any way bind or commit the other Party to any obligation.
   </p>
   <h3>
      26. ENTIRE AGREEMENT
   </h3>
   <p>
      26.1 This Agreement constitutes the entire agreement between the Parties with respect to its subject matter and supersedes all previous communications, representations, inducements, undertakings, agreements or arrangements between the Parties or their respective officers, employees or agents and all such other agreements will (other than in relation to Confidential information) be replaced by this Agreement.
   </p>
   <h3>
      27. WAIVER
   </h3>
   <p>
      27.1 The failure, delay, relaxation or indulgence on the part of a Party in exercising any power, right or remedy conferred upon that Party by this Agreement will not operate as a waiver of that power, right or remedy, nor will the exercise or any single or partial exercise of any power, right or remedy preclude any other or further exercise of such power, right or remedy or the exercise of any other power, right or remedy under this Agreement.
   </p>
   <p>
      27.2 Any waiver of a breach of this Agreement will be in writing signed by the Party granting the waiver and will be effective only to the extent expressly set out in such waiver.
   </p>
   <h3>
      28. APPLICATION OF LAW
   </h3>
   <p>
      28.1 This Agreement will be deemed to have been made in Australia and the construction validity and performance of this Agreement will be governed in all respects by the law for the time being in force in Australia
   </p>
   <p>
      28.2 The Parties hereby submit themselves to the exclusive jurisdiction of the Supreme Court of Australia in respect of any dispute, proceeding or matter relating to this Agreement.
   </p>
   <h3>
      29. SEVERABILITY
   </h3>
   <p>
      29.1 If any provision of this Agreement is invalid or not enforceable in accordance with its terms in any jurisdiction, it is to be read down for the purposes of that jurisdiction, if possible, so as to be valid and enforceable and will otherwise be capable of being severed to the extent of the invalidity or unenforceability without affecting the remaining provisions of this Agreement or affecting the validity or enforceability of that provision in any other jurisdiction.
   </p>
   <h3>
      30. ASSIGNMENT AND SUB CONTRACTING
   </h3>
   <p>
      30.1 The End User may not assign this Agreement or any of the rights granted pursuant to this Agreement other than with the written consent of the Supplier and in accordance with the terms of this Agreement.
   </p>
   <p>
      30.2 The Supplier may assign or sub-contract this Agreement to a third party upon giving the End User twenty (20) Business Days prior written notice of the assignment or proposed sub-contract.
   </p>
   <h3>
      31. SURVIVAL OF TERMS
   </h3>
   <p>
      31.1 Notwithstanding termination or expiration of this Agreement, the following clauses are deemed to survive such termination or expiration: clauses 1, 8, 9, 11, 12, 14, 16, 18, 20, 28 and this clause.
   </p>
   <p>
      31.2 Nothing in this clause will affect the accrued rights of a Party that exist at termination.
   </p>

`

@withRouter
@inject("templateStore", "userStore")
class TermOfServiceMobile extends Component {
    backStock() {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Paper className="policy-container-mobile">
                    <div dangerouslySetInnerHTML={{ __html: tos }} />
                </Paper>
                {/* {this.props.userStore.currentUser && 
                  <Button onClick={e => this.backStock()} variant="contained" color="primary" className={classes.button}>
                     B a c k
                  </Button>
               } */}
            </div>
        );
    }
}

TermOfServiceMobile.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(TermOfServiceMobile);
