import { Enum } from "enumify";

/**
 * Enum Class representing profileType.
 * to use it : ProfileType.Musician.ordinal = 1
 * @extends Enum
 */
class ProfileType extends Enum {}
class NotificationType extends Enum {}
class BookingStatusType extends Enum {}
class LineupStatusType extends Enum {}
class ProfileRelationType extends Enum {}
class OrganisationType extends Enum {}
class BookingType extends Enum {}
class InvoiceType extends Enum {}
class VenueMenuType extends Enum {}
class EmbedType extends Enum {}
class XeroErrorType extends Enum {}
class RosterDividendType extends Enum {}



/**
 * Enum Class representing media type.
 * to use it : NotificationMediaType.Web.ordinal = 1
 * @extends Enum
 */
class NotificationMediaType extends Enum {}

ProfileType.initEnum(["None", "Musician", "Agent", "Staff", "Act", "Venue", "Production", "Dj","Admin", "PrivateHire"]);


EmbedType.initEnum(["Large", "Table", "Grid"])


XeroErrorType.initEnum([
    "General",
"ContactNotFoundBill",
"ContactNotFoundInvoice",
"CustomerNotFound",
"SupplierNotFound",
"CustomerPrivateNotFound",
"InvoiceNotFound",
"UploadAttachmentFailed",
"InvoiceAccountCodeNotMapped"
])


NotificationType.initEnum([
    "ActRosterInvited",
    "ActRosterAccepted",
    "ActRosterRejected",
    "ActRosterRemoved",
    "PasswordChanged",
    "AgentActRosterInvitation",
    "AgentActRosterAccepted",
    "AgentActRosterRejected",
    "AgentActRosterRemoved",
    "AgentVenueRosterInvited",
    "AgentVenueRosterAccepted",
    "AgentVenueRosterRejected",
    "AgentVenueRosterRemoved",
    "VenueActRosterInvited",
    "VenueActRosterAccepted",
    "VenueActRosterRejected",
    "VenueActRosterRemoved",
    "LineupAdded",
    "LineupAccepted",
    "LineupConfirmed",
    "LineupRejected",
    "LineupCancelled",
    "LineupRemoved",
    "LineupReminder",
    "LineupReminder2",
    "BookingCreated",
    "BookingUpdated",
    "BookingAccepted",
    "BookingConfirmed",
    "BookingDeclined",
    "BookingCancelled",
    "BookingReminder",
    "BookingReminder2",
    "BookingRequestCreated",
    "BookingRequestAccepted",
    "BookingRequestDeclined",
    "BookingRequestRemoved",
    "AgentConsultantInvitation",
    "AgentConsultantAccepted",
    "AgentConsultantDeclined",
    "AgentConsultantUpdated",
    "AgentRemoveConsultant",
    "ConsultantQuitAgency",
    "BookingHolded",
    "ActRemoveAgent",
    "MusicianQuitAct",
    "InvoiceIncoming",
    "InvoicePaid",
    "AgentVenueRosterUpdated",
    "NewBookingLineupMessage",
    "ExtraGigFeeRequested",
    "ExtraGigFeeApproved",
    "ExtraGigFeeDeclined",
    "NewBookingNegotiationMessage",
    "NewVenueBookingCreated",
    "BookingRequestHasBeenClaimed",
    "UserGoingUpdated",
    "UserGoingReminder",
    "UserInterestedUpdated",
    "UserInterestedReminder",
    "FavouriteActGigAlert",
    "FavouriteVenueGigAlert",
    "LineupFeeUpdated",
    //Festival
    "FestivalRegistrationCreated",
    "FestivalRegistrationAccepted",
    "FestivalRegistrationDeclined",
    "FestivalRegistrationRemoved",
    "NewMessageReceived",
    //End Of Festival
    //venue agent request
    "VenueAgentRosterInvited",
    "VenueAgentRosterAccepted",
    "VenueAgentRosterRejected",
    "VenueAgentRosterRemoved",
    //consultantChangeAgent
    "ConsultantChangeAgent",
    "VenueActRosterActInvited",
    "VenueActRosterActRemoved",
    "VenueMemberSwitch",
    "VenueMemberRemove",
    "ConsultantRemoveAgency",
    "VenueAcceptedAgentInvitation",
    "VenueAcceptPermissionManageAct",
    "VenueRemovePermissionManageAct",
    "AgentVenueRosterVenueRejected",
    "MusicianInviteAgent",
    "AddActRoster",
    "MusicianApplyVenue",
    "VenueDeclineMusicianInvitation",
    "AddStaffMemberVenue",
    "UpdateOrganisationMember",
    "PrivateHireQuote",
    "PrivateHireBookingNotification",
    "AutoSendNotificationSuccess", 
    "AutoSendNotificationFailed",
    "BookingReminderNotification",
    "AutoXeroNotificationFailedCount",
    "AutoXeroNotificationSuccessCount",
    "BookingDeleted",
    "BookingFeeChanged",
    "AgentOwnedLineUpAdded",
    "InvoiceUpdatedOnDetails",
    "InvoiceDeleted",
    "PublishSocialInstagramFailed",
    "PublishSocialFacebookFailed",
    "SocialPostBookingChanged",
    "SocialPostBookingCancelAfterPublished",
    "SocialPostBookingChangedAfterPublished"
]);
BookingStatusType.initEnum([
    "New",
    "Declined",
    "Hold",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "CancelledByAct",
    "CancelledByActHistory",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
]); // the booking status type enum

LineupStatusType.initEnum(["New", "Declined", "Hold", "Accepted", "Confirmed", "Cancelled", "Removed"]);

ProfileRelationType.initEnum(["AgentAct", "AgentVenue", "VenueAct", "AgentConsultant", "StaffAct"]);

OrganisationType.initEnum(["Venue", "Agency"]);

NotificationMediaType.initEnum(["Web", "Phone", "Email"]);

BookingType.initEnum(["Normal", "Adhoc"]);

InvoiceType.initEnum(["Normal", "ExtraGigFee", "AdhocDeposit", "AdhocBalance"]);

VenueMenuType.initEnum(["Food", "Drink"]);

RosterDividendType.initEnum(["Percentage", "Dollar"]);


export {
    ProfileType,
    NotificationType,
    BookingStatusType,
    LineupStatusType,
    ProfileRelationType,
    OrganisationType,
    NotificationMediaType,
    BookingType,
    InvoiceType,
    VenueMenuType,
    EmbedType,
    XeroErrorType,
    RosterDividendType
};
