import { Button, Divider, Grid, Icon, Link, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import logo from '../../img/hotgig-logo.png';
import imageBackground from '../../img/footer.png'
import './FooterLogin.css';

export default class FooterLogin extends Component {
    render() {

        const phoneNumber = '+61 419 507 507'
        const emailAddr = 'support@hotgiggity.com'
        const addr = 'Brisbane, Qld, Australia.'
        const allRights = '© 2021 Hot Giggity Pty Ltd. All rights reserved.'

        return (
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', maxHeight: '100%', width: '100vw', height: '30vh', zIndex: 20, overflow: 'hidden'}}>
                <Divider style={{width: '100%', height: 10, backgroundColor: '#ff0c9c'}} />
                <div className='Footer'>
                    <div className='image-container'>
                        <div className='image-bg'/>
                    </div>   
                    <div className='container'>
                        <div className='container-content'>
                            <img src={logo} style={{ width: '200px', height: 'auto', marginBottom: 20, marginTop: 40}} />
                            {/* <p>{phoneNumber}</p> */}
                            <p>{emailAddr}</p>
                            <p>{addr}</p>
                            <Grid className='social-container' container direction='row'>
                                <Link target={"_blank"} to='/' style={{marginRight: 20, cursor:"pointer"}} >
                                    <Icon className='fab fa-facebook' fontSize='large' />
                                </Link>
                                <Link target={"_blank"} to='/' style={{marginRight: 20, cursor:"pointer"}}>
                                    <Icon className='fab fa-linkedin' fontSize='large' />
                                </Link>
                                <Link target={"_blank"} to='/' target='_blank' style={{marginRight: 20, cursor:"pointer"}}>
                                    <Icon className='fab fa-instagram' fontSize='large' />
                                </Link>
                            </Grid>
                        </div>
                        <div className='container-content-with-field'>
                            {/* <h1>
                                Stay in the loop
                            </h1>
                            <p style={{textAlign: 'right', marginBottom: 20}}>Subscribe to the Hot Giggity Loop to keep up to date with all things Hot Giggity, tips, tricks and updates! </p>
                            <div style={{display: 'flex', flexDirection: 'row'}} > 
                                <TextField 
                                id='email-subscribe'
                                placeholder='Email'
                                value={this.props.subscribe}
                                onChange={this.props.onChangeSubscribe}
                                fullWidth
                                type="email"
                                />
                                <Button onClick={this.props.onSubscribe} style={{borderRadius: 0}} size='large' variant='contained' color='primary' type='submit'>
                                    SUBSCRIBE
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='arr-text' >
                    <p style={{color: 'white'}}>{allRights}</p>
                </div>
            </div>
        )
    }
}
