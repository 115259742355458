import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import organisationMemberStore from "./organisationMemberStore";
import stateStore from "./stateStore";

class OrganisationStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable organisations = [];
    @observable organisation = undefined;
    @observable isLoading = true;
    @observable profileSearchResult = [];
    // Organisation fields
    @observable name = "";
    @observable address = "";
    @observable contactNumber = "";
    @observable currency = undefined;
    @observable level1Label = "Organisation";
    @observable level2Label = "State";
    @observable level3Label = "Region";
    @observable type = undefined;
    @observable members = [];
    @observable regions = [];

    @observable canManage = false;
    @observable created = false

    @action
    loadOrganisations(userId) {
        this.inProgress = true;
        return agent.Organisation.loadOrganisations()
            .then(
                action(organisations => {
                    this.organisations = organisations;
                    if (organisations.length > 0) {
                        const organisation = organisations[0];
                        this.level1Label = organisation.level1Label;
                        this.level2Label = organisation.level2Label;
                        this.level3Label = organisation.level3Label;
                        this.created = true;
                    }
                    
                    return this.organisations;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action setCreated(value){
        this.created = value
    }
    @action
    setName(name) {
        this.name = name;
    }

    @action
    setAddress(address) {
        this.address = address;
    }

    @action
    setContactNumber(contactNumber) {
        this.contactNumber = contactNumber;
    }

    @action
    setCurrency(currency){
        this.currency = currency;
    }

    @action
    setLevel1Label(level1Label) {
        this.level1Label = level1Label;
    }

    @action
    setLevel2Label(level2Label) {
        this.level2Label = level2Label;
    }

    @action
    setLevel3Label(level3Label) {
        this.level3Label = level3Label;
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    load(id) {
        return agent.Organisation.load(id)
            .then(
                action(org => {
                    if (org) {
                        this.id = org.id;
                        this.name = org.name;
                        this.address = org.address;
                        this.contactNumber = org.contactNumber;
                        this.level1Label = org.level1Label;
                        this.level2Label = org.level2Label;
                        this.level3Label = org.level3Label;
                        this.type = org.type;
                        this.canManage = org.canManage;
                        this.currency = org.currency;
                        // organisationMemberStore.setMembers(org.members);
                        // organisationMemberStore.setOrganisationId(org.id);
                        // organisationMemberStore.setLevel(1);
                        // stateStore.setStates(org.states);
                    }

                    return org;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    setupNew() {
        this.canManage = true;
        return Promise.resolve();
    }

    @action
    loadRegions() {
        this.regions = [];
        return agent.Organisation.loadRegions()
            .then(
                action(regions => {
                    if (regions) {
                        this.regions = regions;
                    }
                    return regions;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    resetSelection(id) {
        this.id = undefined;
        this.name = "";
        this.address = "";
        this.contactNumber = "";
        this.level1Label = "Organisation";
        this.level2Label = "State";
        this.level3Label = "Region";
        this.type = undefined;
        this.canManage = false;
        this.currency = undefined;
        organisationMemberStore.reset();
        stateStore.reset();
    }

    @action
    submit() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const members = organisationMemberStore.members.slice();
        const states = stateStore.states.slice();

        const organisation = {
            id: this.id,
            createdById: user.id,
            name: this.name,
            address: this.address,
            contactNumber: this.contactNumber,
            level1Label: this.level1Label,
            level2Label: this.level2Label,
            level3Label: this.level3Label,
            type: this.type,
            members: members,
            states: states,
            currency: this.currency
        };
        return (this.id ? this.updateOrganisation(organisation) : this.createOrganisation(organisation))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    submitSecondProfile() {
        this.inProgress = true;
        this.errors = undefined;
        const user = userStore.currentUser;
        const members = organisationMemberStore.members.slice();
        const states = stateStore.states.slice();

        const organisation = {
            id: this.id,
            createdById: user.id,
            name: this.name,
            address: this.address,
            contactNumber: this.contactNumber,
            level1Label: this.level1Label,
            level2Label: this.level2Label,
            level3Label: this.level3Label,
            type: this.type,
            members: members,
            states: states,
            currency: this.currency
        };
        return (this.id ? this.inProgress = false : this.createOrganisation(organisation))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }


    @action
    createOrganisation(data) {
        data.id = 0;
        return agent.Organisation.create(data).then(
            action(org => {
                if (org) {
                    this.id = org.id;
                    this.name = org.name;
                    this.address = org.address;
                    this.contactNumber = org.contactNumber;
                    this.level1Label = org.level1Label;
                    this.level2Label = org.level2Label;
                    this.level3Label = org.level3Label;
                    this.type = org.type;
                    this.canManage = org.canManage;
                    this.currency = org.currency;
                    organisationMemberStore.setMembers(org.members);
                    stateStore.setStates(org.state);
                }
               
                return org;
            })
        );
    }

    @action
    updateOrganisation(data) {
        return agent.Organisation.update(data).then(
            action(org => {
                if (org) {
                    this.id = org.id;
                    this.name = org.name;
                    this.address = org.address;
                    this.contactNumber = org.contactNumber;
                    this.level1Label = org.level1Label;
                    this.level2Label = org.level2Label;
                    this.level3Label = org.level3Label;
                    this.type = org.type;
                    this.canManage = org.canManage;
                    this.currency = org.currency;
                    organisationMemberStore.setMembers(org.members);
                    stateStore.setStates(org.state);
                }
               
                return org;
            })
        );
    }
}

export default new OrganisationStore();
