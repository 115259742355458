import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import templateStore from "./templateStore";
import CacheHelper from "../helper/cache";
import profileStore from "./profileStore";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class VenueStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable venues = [];
    @observable organisation = undefined;
    @observable isLoading = true;
    @observable profileSearchResult = [];
    @observable page = 1;
    @observable isMore = false;

    @action
    searchVenueAndIndependentVenue = name => {
        return agent.Venue.searchVenueAndIndependentVenue(name).then(venues => {
            venues = venues.map(venue => {
                profileStore.mapAdditionalProfileDataAsync(venue);
                return venue;
            });

            return venues;
        });
    };

    @action
    setPage(page) {
        this.page = page;
    }
    @action
    resetVenues() {
        this.venues = [];
        this.page = 1;
        this.isMore = false
    }

    
    @action
    load() {
        this.inProgress = true;
        return agent.Venue.load().then(
            action(venues => {
                this.venues = [];
                venues.map(async venue => {
                    await this.mapAdditionalProfileDataVenues(venue);
                    runInAction(() => {
                        this.venues.push(venue)
                    })
                });
                this.inProgress = false;
                return this.venues;
            })
        );
    }
    
    @action
    getUserVenues(page) {
        return agent.Profile.getMyVenues(page).then(
            action(async venues => {
                // this.venues = venues?.bookings? venues.bookings : [];
                var result = [];
                await Promise.all(venues.bookings.map(async venue => {
                    await profileStore.mapAdditionalProfileDataAsync(venue);
                    result.push(venue);
                }))
               
               
                runInAction(() => {
                    this.venues = this.venues.concat(result);
                    this.isMore = venues.isMore
                })
                return this.venues;
            })
        );
    }

    @action
    getUserVenuesWithNoImage() {
        return agent.Profile.getMyVenuesNoImage().then(
            action(async venues => {
                this.venues = venues ? venues : [];
                var result = [];
                venues.map(venue => {
                    profileStore.mapAdditionalProfileDataNoImage(venue);
                    result.push(venue);
                })
                this.venues = result;
                return this.venues;
            })
        );
    }

    @action
    getUserBookableVenues() {
        return agent.Venue.loadBookableVenues().then(
            action(venues => {
                this.venues = venues.map(venue => {
                    this.mapAdditionalProfileData(venue);
                    return venue;
                });
                this.inProgress = false;
                return this.venues;
            })
        );
    }

    @action
    getUserVenuesBasedOnPermission(edit, manage, financial, booking, promote) {
        return agent.Venue.getUserVenuesBasedOnPermission(edit, manage, financial, booking, promote).then(
            action(venues => {
                return venues.map(venue => {
                    this.mapAdditionalProfileData(venue);
                    return venue;
                });
            })
        );
    }
    
    @action
    getUserVenuesBasedOnPermissionSimple(edit, manage, financial, booking, promote) {
        return agent.Venue.getUserVenuesBasedOnPermissionSimple(edit, manage, financial, booking, promote).then(
            action(venues => {
                return venues.map(venue => {
                    this.mapAdditionalProfileData(venue);
                    return venue;
                });
            })
        );
    }
    
    // @action
    // loadByRegionId(id) {
    //     this.inProgress = true;
    //     agent.Venue.load()
    //         .then(
    //             action(venues => {
    //                 this.venues = venues.map(venue => {
    //                     this.mapAdditionalProfileData(venue);
    //                     return venue;
    //                 });
    //                 console.log("venues loaded", this.venues);
    //             })
    //         )
    //         .finally(
    //             action(() => {
    //                 this.inProgress = false;
    //             })
    //         );
    // }

    @action
    setVenues(venues) {
        this.venues = venues;
    }

    @action
    delete(id) {
        return agent.Profile.delete(id)
            .then(
                action(response => {
                    let venue = this.venues.find(x => x.id === id);
                    if (venue) this.venues.remove(venue);

                    templateStore.openSnackbar(`Venue ${venue.profileName} has been deleted.`);
                    return venue;
                })
            )
            .catch((error, code) => {
                console.log("error removing venue", error, code);
                templateStore.openSnackbar(`Unable to remove venue with active bookings.`);
            });
    }

    @action
    async mapAdditionalProfileDataVenues(profile) {
        await profileStore.mapAdditionalProfileImageAndBackgroundImages(profile);
        profileStore.mapAdditionalProfileNames(profile);
    }

    mapAdditionalProfileData(profile) {
        if (profile) {
            if (profile.profileImage) {
                profile.profileImage.url = `${API_ROOT}/image/${profile.profileImage.id}`;
            }

            if (profile.backgroundImages && profile.backgroundImages.length > 0) {
                profile.backgroundImages.forEach(b => {
                    b.url = `${API_ROOT}/image/background/${b.id}`;
                });
            } else {
                profile.backgroundImages = undefined;
            }
            profile.fullName = templateStore.getFullName(profile);
            profile.typeString = templateStore.getTypeString(profile.type);
        }
    }
}

export default new VenueStore();
