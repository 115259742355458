import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import LazyLoad from 'react-lazyload';

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import WidgetTitleBar from "../../components/widget/WidgetTitleBar";
import PerformList from "../../components/widget/PerformList";
import Testimonials from "../../components/widget/Testimonials";
import WidgetSmallTextLink from "../../components/widget/WidgetSmallTextLink";
import WidgetContainer from "../../components/widget/WidgetContainer";
import YoutubeWidget from "../../components/widget/YoutubeWidget";
import VimeoWidget from "../../components/widget/VimeoWidget";
import SoundCloudWidget from "../../components/widget/SoundCloudWidget";
import SpotifyWidget from "../../components/widget/SpotifyWidget";
import SocialButton from "../../components/widget/SocialButton";
import SongList from "../../components/songListTable/SongListProfile";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import classNames from "classnames";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

import Tab from "@material-ui/core/Tab";

import "./Profile.css";

import { Typography, Hidden, Paper, Grid, Tabs, Avatar, Button, Tooltip } from "@material-ui/core";
import { ProfileType } from "../../types/enum";
import BookingOptionModal from "../../components/searchPage/modal/BookingOptionModal";
import { default as AgentActInvitationModal } from "../../components/util/ActInvitation/ActInvitationModal";
import StaffActRosterInvitationModal from "../../components/venue/staffRoster/StaffActRosterInvitationModal";
import MessageModal from "../../components/profile/messageModal/MessageModal";
import { Promise } from "bluebird";
import image from "../../helper/image";
import qs from "qs";
import profileStore from "../../stores/profileStore";

const youtubeReg = /^(http:\/\/|https:\/\/)(youtu.be|www.youtube.com)\/([\w]+)/;
const soundcloudReg = /^(http:\/\/|https:\/\/)(soundcloud.com|w.soundcloud.com)\/([\w]+)/;
const spotifyReg = /^(http:\/\/|https:\/\/)(open.spotify.com)\/([\w]+)/;
const vimeoReg = /^(http:\/\/|https:\/\/)(vimeo.com)\/([\w]+)/;

@inject("profileStore", "templateStore", "bookingRequestStore", "reportStore", "agentActStore", "venueActStore")
@withRouter
@observer
class ActProfile extends Component {
    timeoutValue = null;
    actProfile = null;

    constructor(props) {
        super(props);
        this.props.reportStore.clearDateRangeFilter();
        this.state = {
            value: 0,
            backgroundImageIndex: 0,
            showBookingOptionModal: false,
            showActInvitationModal: false,
            canInviteAct: false,
            showMessageModal: false,
            performData: [],
            instrumentData: [],
            isCurrentUser: false,
            showMessageModal: false
        };
    }

    componentDidMount = () => {
        const profile = this.props.profile || this.props.profileStore.profile;
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if(currentProfile && currentProfile.userId === profile.userId) {
            this.setState({isCurrentUser: true})
        }

        if (this.actProfile && this.actProfile?.backgroundImages) {
            for (let i = 0; i < this.actProfile?.backgroundImages.length; i++) {
                // caches images, avoiding white flash between background replacements
                new Image().src = this.actProfile?.backgroundImages[i].url1920;
            }
            this.changeBackground(0);
        }
        if (this.actProfile) {
            this.props.templateStore.showLoadingScreen();
            Promise.all([
                this.props.profileStore.canAgentInviteAct(this.actProfile?.id),
                this.props.bookingRequestStore.loadUpcomingGigsWithoutAdhoc(this.actProfile?.id)
            ]).then(response => {
                let performData = this.processUpcomingGigs();
                this.setState({
                    ...this.state,
                    canInviteAct: response[0],
                    performData
                });

                this.props.templateStore.hideLoadingScreen();
                const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
                if (parsed) {
                    if (parsed.contact) {
                        this.showMessageModal();
                    }
                }
            });
        }

        if (this.actProfile && this.actProfile?.profileInstruments) {

            profileStore.getInstrumentNameByInstrumentProfile(this.actProfile?.profileInstruments).then(result=>{

                this.setState({
                    ...this.state,
                    instrumentData: result
                });

            })

        }
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    changeBackground = index => {
        this.setState({ backgroundImageIndex: index });

        this.timeoutValue = setTimeout(() => {
            let newIndex = this.state.backgroundImageIndex + 1 < this.actProfile?.backgroundImages.length ? this.state.backgroundImageIndex + 1 : 0;
            this.changeBackground(newIndex);
        }, 5000);
    };

    manualChangeBackground = index => () => {
        clearTimeout(this.timeoutValue);
        this.changeBackground(index);
    };

    processUpcomingGigs = () => {
        let { upcomings } = this.props.bookingRequestStore;
        let result = [];
        upcomings = upcomings.sort((a,b) => (a.dateStart > b.dateStart) ? 1 : ((b.dateStart > a.dateStart) ? -1 : 0))
        for (let i = 0; i < upcomings.length; i++) {
            let dateStart = moment(upcomings[i].dateStart);
            let data = {
                month: dateStart.format("MMMM"),
                date: dateStart.format("D"),
                day: dateStart.format("dddd"),
                venue: upcomings[i].venueName,
                address: upcomings[i].venueAddress,
                time:
                    moment(upcomings[i].timeStart, "HH:mm:ss.SSS").format("h.mma") +
                    " - " +
                    moment(upcomings[i].timeEnd, "HH:mm:ss.SSS").format("h.mma"),
                actName: upcomings[i].actName,
                actUrl: "/profile/" + upcomings[i].actId,
                venueUrl: "/profile/" + upcomings[i].venueId,
                actProfileImageUrl:
                    upcomings[i].act.profileImage &&
                    upcomings[i].act.profileImage.id &&
                    this.props.profileStore.getImageUrl(upcomings[i].act.profileImage && upcomings[i].act.profileImage.id),
                actBackgroundImageUrl:
                    upcomings[i].act.backgroundImages &&
                    upcomings[i].act.backgroundImages[0] &&
                    upcomings[i].act.backgroundImages[0].id &&
                    this.props.profileStore.getBackgroundImageUrl(
                        upcomings[i].act.backgroundImages && upcomings[i].act.backgroundImages[0] && upcomings[i].act.backgroundImages[0].id
                    ),
                youtube: upcomings[i].act.videoUrl,
                soundcloud: upcomings[i].act.musicUrl,
                genre:
                    upcomings[i].act.profileGenres &&
                    upcomings[i].act.profileGenres
                        .map(x => {
                            return x.genre.name;
                        })
                        .join(", ")
            };
            result.push(data);
        }
        return result;
    };

    showBookingOptionModal = () => {
        this.setState({
            ...this.state,
            showBookingOptionModal: true
        });
    };

    hideBookingOptionModal = () => {
        this.setState({
            ...this.state,
            showBookingOptionModal: false
        });
    };

    showActInvitationModal = () => {
        const { currentProfile } = this.props.profileStore;
        if (currentProfile.type === ProfileType.Agent.ordinal) {
            this.props.agentActStore.setInvitedAct(this.actProfile).then(() => {
                this.setState({
                    ...this.state,
                    showActInvitationModal: true
                })
            })
        } else if (currentProfile.type === ProfileType.Staff.ordinal) {
            this.props.venueActStore.setInvitedAct(this.actProfile).then(() => {
                this.setState({
                    ...this.state,
                    showActInvitationModal: true
                })
            })
        }
    };

    hideActInvitationModal = () => {
        this.setState({
            ...this.state,
            showActInvitationModal: false
        });
    };
    showMessageModal = () => {
        this.setState({
            showMessageModal: true
        });
    };

    hideMessageModal = () => {
        this.setState({
            showMessageModal: false
        });
    };

    handleButtonMessage = (e) => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        if(this.state.isCurrentUser) {
            e.preventDefault();
            this.props.history.push('/messages/new');
        } else {
            if (currentProfile) {
                e.preventDefault();
                this.props.history.push({
                    pathname: '/messages/new',
                    state: { profile: this.actProfile?.actAdmin }
                })
            } else {
                this.setState({showMessageModal: true})
            }
        }
    }

    render() {
        const { showBookingOptionModal, showActInvitationModal, canInviteAct, performData } = this.state;
        const { profiles, currentProfile } = this.props.profileStore;

        this.actProfile = this.props.profile || null;
        if (!this.actProfile && profiles) {
            let actId = this.props.match.params.id;
            for (let i = 0; i < profiles.length; i++) {
                if (profiles[i].id === actId) {
                    this.actProfile = profiles[i];
                    break;
                }
            }
        }

        let widgetSmallTextLinksPlayedAt = {
            title: "Played At",
            datas: []
        };

        if (this.actProfile && this.actProfile?.playedAts) {
            widgetSmallTextLinksPlayedAt.datas = this.actProfile?.playedAts.map(playedAt => ({
                text: playedAt.name,
                secondaryText: playedAt.suburb + ", " + playedAt.state,
                link: "#"
            }));
        }

        let widgetSmallTextLinksPlayedWith = {
            title: "Played With",
            datas: []
        };

        if (this.actProfile && this.actProfile?.playedWiths && this.actProfile?.showPlayedWith) {
            widgetSmallTextLinksPlayedWith.datas = this.actProfile?.playedWiths.map(playedWith => ({
                text: playedWith.name,
                secondaryText: playedWith.notes,
                link: "#"
            }));
        }

        let widgetSmallTextLinksSkillSet = {
            title: "Skill Set",
            datas: this.state.instrumentData
        };

        
        let widgetSmallTextLinksCount =
            (widgetSmallTextLinksPlayedAt.datas.length > 0 ? 1 : 0) +
            (widgetSmallTextLinksPlayedWith.datas.length > 0 ? 1 : 0) +
            (widgetSmallTextLinksSkillSet.datas.length > 0 ? 1 : 0);

        let profileContent = (
            <div className="profile-content">
                <Grid container spacing={8}>
                    {widgetSmallTextLinksPlayedAt.datas.length ? (
                        <Grid item xs={12} lg={12 / widgetSmallTextLinksCount}>
                            <WidgetSmallTextLink data={widgetSmallTextLinksPlayedAt} />
                        </Grid>
                    ) : null}

                    {widgetSmallTextLinksPlayedWith.datas.length ? (
                        <Grid item xs={12} lg={12 / widgetSmallTextLinksCount}>
                            <WidgetSmallTextLink data={widgetSmallTextLinksPlayedWith} />
                        </Grid>
                    ) : null}

                    {widgetSmallTextLinksSkillSet.datas.length ? (
                        <Grid item xs={12} lg={12 / widgetSmallTextLinksCount}>
                            <WidgetSmallTextLink data={widgetSmallTextLinksSkillSet} />
                        </Grid>
                    ) : null}

                    {this.actProfile && this.actProfile?.videoUrl ? (
                        <Grid item xs={12} lg={this.actProfile?.musicUrl ? 6 : 12}>
                            <div className="standalone-widget-title">
                                <WidgetTitleBar title={this.actProfile && this.actProfile?.fullName + "'s Video"} showBadge={false} />
                            </div>
                            {this.actProfile &&
                                this.actProfile?.videoUrl &&
                                this.actProfile?.videoUrl.match(youtubeReg) && <YoutubeWidget url={this.actProfile && this.actProfile?.videoUrl} />}
                            {this.actProfile &&
                                this.actProfile?.videoUrl &&
                                this.actProfile?.videoUrl.match(vimeoReg) && (
                                    <VimeoWidget url={this.actProfile && this.actProfile?.videoUrl} height="400px" />
                                )}
                        </Grid>
                    ) : null}

                    {this.actProfile && this.actProfile?.musicUrl ? (
                        <Grid item xs={12} lg={this.actProfile?.videoUrl ? 6 : 12}>
                            <div className="standalone-widget-title">
                                <WidgetTitleBar title={this.actProfile && this.actProfile?.fullName + "'s Sound"} showBadge={false} />
                            </div>
                            {this.actProfile && this.actProfile?.musicUrl && this.actProfile && this.actProfile?.musicUrl.match(soundcloudReg) && <SoundCloudWidget url={this.actProfile && this.actProfile?.musicUrl} />}
                            {this.actProfile && this.actProfile?.musicUrl && this.actProfile?.musicUrl.match(spotifyReg) && <SpotifyWidget url={this.actProfile && this.actProfile?.musicUrl} />}
                            
                        </Grid>
                    ) : null}

                    {this.actProfile && this.actProfile?.songLists && this.actProfile?.songLists.length > 0 ? (
                        <Grid item xs={12} lg={12} className="songlist">
                            <WidgetContainer title={this.actProfile && this.actProfile?.fullName + "'s Song List"} showBadge={false}>
                                <SongList
                                    songs={this.actProfile && this.actProfile?.songLists}
                                    musician={this.actProfile && this.actProfile?.fullName}
                                />
                            </WidgetContainer>
                        </Grid>
                    ) : null}

                    {this.actProfile && this.actProfile?.testimonials && this.actProfile?.testimonials.length > 0 ? (
                        <Grid item xs={12}>
                            <div className="widget-title-bar-yellow standalone-widget-title">
                                <WidgetTitleBar title="Testimonials" showBadge={false} />
                            </div>

                            <Testimonials testimonials={this.actProfile && this.actProfile?.testimonials} />
                        </Grid>
                    ) : null}
                </Grid>
            </div>
        );

        return (
            <div>
                <LoadingSpinner showed={this.props.templateStore.loadingScreenState} />
                <Header forceToggler />
                <Sidebar forceMobile />

                <div className="profile-header">
                    <div className="profile-header-information-container">
                        {this.actProfile &&
                            this.actProfile?.profileImage &&
                            this.actProfile?.showAvatar && (
                                <div className="profile-header-inner">
                                    <LazyLoad>
                                        <Avatar
                                            classes={{ root: "profile-avatar" }}
                                            src={image.getProfileImage(this.actProfile)}
                                            alt={this.actProfile?.fullName}
                                        />
                                    </LazyLoad>
                                </div>
                            )}
                        <div className="profile-header-text profile-header-inner">
                            <Typography type="headline" component="h3">
                                {this.actProfile && this.actProfile?.fullName}
                            </Typography>
                            <br />
                            {this.actProfile && (this.actProfile?.city || this.actProfile?.state) ? (
                                <Typography component="p">
                                    <LocationOnIcon classes={{ root: "profile-header-icon" }} />
                                    {this.actProfile?.city && this.actProfile?.state
                                        ? this.actProfile?.city + ", " + this.actProfile?.state
                                        : this.actProfile?.city
                                            ? this.actProfile?.city
                                            : this.actProfile?.state
                                                ? this.actProfile?.state
                                                : null}
                                </Typography>
                            ) : null}
                        </div>
                        <div className="profile-header-inner">
                            <SocialButton variant="webSite" urlData={this.actProfile && this.actProfile?.webSite} />
                            <SocialButton variant="facebook" urlData={this.actProfile && this.actProfile?.facebook} />
                            <SocialButton variant="twitter" urlData={this.actProfile && this.actProfile?.twitter} />
                            <SocialButton variant="bandcamp" urlData={this.actProfile && this.actProfile?.bandcamp} />
                            <SocialButton variant="soundcloud" urlData={this.actProfile && this.actProfile?.soundcloud} />
                            <SocialButton variant="mixcloud" urlData={this.actProfile && this.actProfile?.mixcloud} />
                            <SocialButton variant="youtube" urlData={this.actProfile && this.actProfile?.youtube} />
                            <SocialButton variant="instagram" urlData={this.actProfile && this.actProfile?.instagram} />
                            <SocialButton variant="googlePlus" urlData={this.actProfile && this.actProfile?.googlePlus} />
                            <SocialButton variant="linkedIn" urlData={this.actProfile && this.actProfile?.linkedIn} />
                            <SocialButton variant="iTunes" urlData={this.actProfile && this.actProfile?.iTunes} />
                            <SocialButton variant="spotify" urlData={this.actProfile && this.actProfile?.spotify} />
                        </div>

                        <div className="profile-header-inner">
                            {currentProfile &&
                                (currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Staff.ordinal) && (
                                    <Fragment>
                                        {canInviteAct && (
                                            <Tooltip
                                                disableHoverListener={canInviteAct}
                                                title={canInviteAct ? "" : "Already on your act roster."}
                                                placement="bottom"
                                            >
                                                <span>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className="actionButton"
                                                        disabled={!canInviteAct}
                                                        onClick={this.showActInvitationModal}
                                                    >
                                                        Add Act
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        )}

                                        <Button variant="contained" color="primary" className="actionButton" onClick={this.showBookingOptionModal}>
                                            Book Act
                                        </Button>
                                        {currentProfile.type === ProfileType.Staff.ordinal || currentProfile.type === ProfileType.Agent.ordinal ? (
                                            <StaffActRosterInvitationModal open={showActInvitationModal} onClose={this.hideActInvitationModal} profileType={currentProfile.type === ProfileType.Agent.ordinal ? "agent" : "staff"}/>
                                        ) : null}
                                        <BookingOptionModal
                                            open={showBookingOptionModal}
                                            onClose={this.hideBookingOptionModal}
                                            actId={this.actProfile?.id}
                                        />
                                    </Fragment>
                                )}
                              {currentProfile ?
                                <Button variant="raised" color="primary" className="actionButton" onClick={(e) => {
                                        this.handleButtonMessage(e)
                                        }} >
                                    Message
                                </Button>
                                : ""
                            }
                            <MessageModal profile={profiles} open={this.state.showMessageModal} closeHandler={this.hideMessageModal} profile={this.actProfile} />
                        </div>

                        {this.actProfile &&
                            this.actProfile?.backgroundImages &&
                            this.actProfile?.backgroundImages.length > 1 && (
                                <div className="profile-header-inner-slider">
                                    {this.actProfile?.backgroundImages.map((backgroundImage, i) => {
                                        return (
                                            <LazyLoad>
                                                <div
                                                    index={i}
                                                    className={classNames(
                                                        "background-image-control",
                                                        this.state.backgroundImageIndex === i ? "active" : "inactive"
                                                    )}
                                                    onClick={this.manualChangeBackground(i)}
                                                />
                                            </LazyLoad>
                                        );
                                    })}
                                </div>
                            )}
                    </div>
                    {this.actProfile &&
                        this.actProfile?.backgroundImages && (
                            <React.Fragment>
                                {this.actProfile?.backgroundImages.map((backgroundImage, i) => {
                                    return (
                                        <LazyLoad>
                                            <div
                                                key={i}
                                                className={classNames(
                                                    "profile-header-background-image",
                                                    this.state.backgroundImageIndex === i ? "active" : "inactive"
                                                )}
                                                style={{ backgroundImage: "url('" + backgroundImage.url1920 + "')" }}
                                            />
                                        </LazyLoad>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    {(!this.actProfile || !this.actProfile?.backgroundImages || this.actProfile?.backgroundImages.length === 0) && (
                        <React.Fragment>
                            <LazyLoad>
                                <div
                                    className={classNames("profile-header-background-image", "active")}
                                    style={{ backgroundImage: "url('/hero2.jpg')" }}
                                    key={0}
                                />
                            </LazyLoad>
                        </React.Fragment>
                    )}
                </div>

                <div className="profile-body">
                    <Hidden smDown>
                        <div className="profile-sidebar">
                            <Grid container spacing={16}>
                                {performData &&
                                    performData.length > 0 && (
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <div className="border-bottom-grid">
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography type="headline" component="h4" className="profile-sidebar-title">
                                                                {this.actProfile && this.actProfile?.fullName}'s Live Gigs
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginBottom: "50px" }}>
                                                <PerformList performData={performData} />
                                            </Grid>
                                        </React.Fragment>
                                    )}

                                <Grid item xs={12}>
                                    <div className="border-bottom-grid">
                                        <Typography type="headline" component="h4" className="profile-sidebar-title">
                                            {this.actProfile && this.actProfile?.fullName}'s Bio
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={12} className="profile-bio-content">
                                    <Typography component="div">
                                        <div className="bio-section" dangerouslySetInnerHTML={{ __html: this.actProfile && this.actProfile?.bio }} />{" "}
                                    </Typography>
                                </Grid>

                                {
                                    this.actProfile?.showContactPhone || this.actProfile?.showEmail
                                        ? <Grid item xs={12}>
                                            <div className="border-bottom-grid">
                                                <Typography type="headline" component="h4" className="profile-sidebar-title">
                                                    Contact
                                                </Typography>
                                            </div>
                                        </Grid>
                                        : null
                                }
                                {
                                    this.actProfile?.showContactPhone || this.actProfile?.showEmail
                                        ? <Grid item xs={12} className="profile-sidebar-contact">
                                            <Grid container spacing={8}>
                                                {
                                                    this.actProfile?.showContactPhone
                                                        ? <Grid item xs={12}>
                                                            <Typography component="div">
                                                                <PhoneIcon />
                                                                {this.actProfile?.showContactPhone && this.actProfile?.contactPhone !== '' ? this.actProfile?.contactPhone : '-'}
                                                            </Typography>
                                                        </Grid>
                                                        : null
                                                }
                                                {
                                                    this.actProfile?.showEmail
                                                        ? <Grid item xs={12}>
                                                            <Typography component="div">
                                                                <EmailIcon />
                                                                {this.actProfile?.showEmail && this.actProfile?.email !== '' ? this.actProfile?.email : '-'}
                                                            </Typography>
                                                        </Grid>
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                        </div>

                        {profileContent}
                    </Hidden>

                    <Hidden mdUp>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Paper style={{ width: "100%", backgroundColor: "#ff0c9c" }}>
                                    <Tabs
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        centered
                                        variant="fullWidth"
                                    >
                                        <Tab icon={<PersonIcon />} label="Profile" />
                                        <Tab icon={<QueueMusicIcon />} label="Live Gigs" />
                                        <Tab icon={<ImportContactsIcon />} label="Bio" />
                                    </Tabs>
                                </Paper>
                            </Grid>

                            {this.state.value === 0 && (
                                <Grid item xs={12}>
                                    {profileContent}
                                </Grid>
                            )}
                            {this.state.value === 1 && (
                                <Grid item xs={12}>
                                    <div className="profile-content">
                                        <WidgetContainer title={this.actProfile && this.actProfile?.fullName + "'s Live Gigs"} showBadge={false}>
                                            <PerformList performData={performData} />
                                        </WidgetContainer>
                                    </div>
                                </Grid>
                            )}
                            {this.state.value === 2 && (
                                <Grid item xs={12}>
                                    <div className="profile-content">
                                        <WidgetContainer title={this.actProfile && this.actProfile?.fullName + "'s Bio"} showBadge={false} style={{backgroundColor:"#333"}}>
                                            <Typography component="p">
                                                <div
                                                    className="bio-section"
                                                    dangerouslySetInnerHTML={{ __html: this.actProfile && this.actProfile?.bio }}
                                                />{" "}
                                            </Typography>
                                        </WidgetContainer>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Hidden>
                </div>
            </div>
        );
    }
}

export default ActProfile;
