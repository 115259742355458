class ValidationHelper {
    emailValidation = (email) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
         return pattern.test(email)     
    };

    urlValidation = (url) => {
        var regex_space = /^\S*$/;
        var regex_point = /^[^.]+$/;
        if(regex_space.test(url)) {
            if(regex_point.test(url)) {
                return false
            } else{
                return true
            }
        }else {
            return false
        }
    }

    validateWhiteSpace = (string) => {
        var regex_space = /^\S*$/;
        if(regex_space.test(string)) {
            return true
        } else {
            return false
        }
    }

    linkify = (inputText) => {
        var replacedText, replacePattern1, replacePattern2, replacePattern3;
    
        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '$1');
    
        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, 'https://$2');
    
        if(!inputText.includes('www') && !inputText.includes('http')) return `https://www.${inputText}`
    
        return replacedText;
    }
}

export default new ValidationHelper();