import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from "@material-ui/icons/Print";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/CloseRounded";
import ModalContainer from "../../components/modal/ModalContainer";
import MYOBCustomerModal from "../../components/invoice/Modal/MYOBCustomerModal";
import XeroCustomerModal from "../../components/invoice/Modal/XeroCustomerModal";
import MYOBMappedCustomerModal from "../../components/invoice/Modal/MYOBMappedCustomerModal";
import XeroMappedContactModal from "../../components/invoice/Modal/XeroMappedContactModal";
import currencyHelper from '../../helper/currency';

import { DialogContent, DialogActions, Menu, MenuItem  } from "@material-ui/core";
import PdfViewer from "../../components/pdf/PdfViewer";
import List from '@material-ui/core//List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ResponsiveTable from "../../components/table/ResponsiveTable";
import { ProfileType } from "../../types/enum";
import ConfirmationModal from "../../components/modal/ConfirmationModal";

const ENABLE_QUICKBOOKS = process.env.REACT_APP_ENABLE_QUICKBOOKS === "true";
const ENABLE_MYOB = process.env.REACT_APP_ENABLE_MYOB === "true";
const ENABLE_XERO = process.env.REACT_APP_ENABLE_XERO === "true";
@inject("profileStore", "conversationStore", "invoiceStore", "templateStore", "reportStore", "userStore", "myobStore", "billingStore", "xeroStore")
@withRouter
@observer
class OutgoingInvoice extends Component {
    constructor(props) {
        super(props);
        this.props.invoiceStore.setInvoices([]);
        this.state = {
            invoiceDetailDialogOpen: false,
            selectedInvoice: { invoiceDetails: [] },
            batchPrintModalState: false,
            pdfFile: undefined,
            openCustomerMYOBModal: false,
            openCustomerMappedMYOBModal: false,
            openCustomerMappedXeroModal: false,
            openCustomerXeroModal: false,
            customerRow: [],
            selectedPayerId: "",
            activateMYOBAccess : false,
            enableMYOB: false,
            activateXeroAccess: false,
            actionAnchor: [],
            unsyncInvoiceModal: false,
            invoice: null,
            unsyncInvoiceXeroModal: false
        };
    }

    componentDidMount() {
        this.props.myobStore.resetResultIntegration()
        this.props.xeroStore.resetResultIntegration()
        let currentProfile = this.props.profileStore.getCurrentProfile()
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
            var activeMYOB = false
            var activeXero = false
            if(currentProfile?.activateMYOBAccess && currentProfile?.myobCompanyUri){
                activeMYOB = true 
            }
            if(currentProfile?.activateXeroAccess && currentProfile?.xeroTenantId){
                activeXero = true
            }
            this.setState({
                activateMYOBAccess : activeMYOB,
                activateXeroAccess : activeXero
            });
        }else{
            var activeMYOB = false
            var activeXero = false
            if(currentProfile?.organisationOwner?.activateMYOBAccess && currentProfile?.organisationOwner?.myobCompanyUri){
                activeMYOB = true
            }
            if(currentProfile?.organisationOwner?.activateXeroAccess && currentProfile?.organisationOwner?.xeroTenantId){
                activeXero = true
            }
            this.setState({
                activateMYOBAccess : activeMYOB,
                activateXeroAccess : activeXero
            });
        }
        // this.props.userStore.getQuickBooks();
    }

    /*refreshInvoices = () => {
        let currentProfileId = this.props.profileStore.currentProfile?.id;
        
        return this.props.invoiceStore.generate(currentProfileId)
            .then((response) => {
                this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfileId);
            })
            .catch((err) => {
                console.log(err);
            });
    }*/
    loadOutgoingInvoice = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        // return this.props.invoiceStore.generate(currentProfile?.id).then(() => {
        //     return this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfile?.id);
        // });
        if(currentProfile.myAgencies && currentProfile.myAgencies.length){
            return this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfile.myAgencies[0].agentId);
        }else{
            return this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfile?.id);
        }
       
    };
   
    showCustomerMYOBModal = (invoice) => {
        this.setState({
            ...this.state,
            openCustomerMYOBModal: true,
            customerRow : [invoice]
        });
    };

    hideCustomerMYOBModal = () => {
        this.setState({
            ...this.state,
            openCustomerMYOBModal: false,
            customerRow : []
        });
    };

    showCustomerXeroModal = (invoice) => {
        this.setState({
            ...this.state,
            openCustomerXeroModal: true,
            customerRow : [invoice]
        });
    };

    hideCustomerXeroModal = () => {
        this.setState({
            ...this.state,
            openCustomerXeroModal: false,
            customerRow : []
        });
    };

    // handleAddParticipants = (participant) => {
    //     if (participant.type == ProfileType.Agent.ordinal) {
    //         participant.fullName = participant.profileName
    //     } 
    //     if(this.props.match.params.conversationId  > 0){
    //         this.props.conversationStore.addParticipant(this.props.match.params.conversationId,participant)
    //     }
    // };
    
    showCustomerMappedXeroModal = (invoice) => {
        this.setState({
            ...this.state,
            openCustomerMappedXeroModal: true,
            selectedPayerId: invoice.payerId,
            customerRow : [invoice]
        });
    };

    handleSendMessageNotification = (invoice) => {
        var currentProfile = this.props.profileStore.getCurrentProfile()
        this.props.profileStore.getByIdSimple(invoice.payerId).then(result => {
            if(result.length){
                if(result[0].type === ProfileType.Venue.ordinal || result[0].type === ProfileType.Staff.ordinal){
                    this.props.invoiceStore.getBookingCreatorByInvoiceId(invoice.id).then(bookingCreators => {
                        var bookingCreator = result[0].profileName ? result[0].profileName : result[0].firstName + " " + result[0].lastName
                    var invoiceNumber = "HG-"+invoice.no
                    var title = `Invoice ${invoiceNumber} reminder from ${currentProfile?.firstName} ${currentProfile?.lastName}`
        
                    var body = `<p>Hi ${bookingCreator},</br></p><p>Just a reminder that my invoice ${invoiceNumber} has not been paid.</p><p>Your prompt payment would be much appreciated.</p><p>Kind regards,</p></br><p>${currentProfile.firstName} ${currentProfile.lastName}</p>`
                    this.props.conversationStore.setTitle(title);
                    this.props.conversationStore.setMessage(body);
                    this.props.conversationStore.setParticipant(bookingCreators);
                    // this.handleAddParticipants(result)
                    this.props.history.push("/messages/invoice");
                    })
                    
                }else{
                    var bookingCreator = result[0].profileName ? result[0].profileName : result[0].firstName + " " + result[0].lastName
                    var invoiceNumber = "HG-"+invoice.no
                    var title = `Invoice ${invoiceNumber} reminder from ${currentProfile?.firstName} ${currentProfile?.lastName}  Participants - ${bookingCreator}`
        
                    var body = `<p>Hi ${bookingCreator},</br></p><p>Just a reminder that my invoice ${invoiceNumber} has not been paid.</p><p>Your prompt payment would be much appreciated.</p><p>Kind regards,</p></br><p>${currentProfile.firstName} ${currentProfile.lastName}</p>`
                    this.props.conversationStore.setTitle(title);
                    this.props.conversationStore.setMessage(body);
                    this.props.conversationStore.setParticipant(result);
                    // this.handleAddParticipants(result)
                    this.props.history.push("/messages/invoice");
                }
               
            }
          
                // this.props.conversationStore.submitInvoice(title,body).then(response => {
                //     this.props.templateStore.openSnackbar("Message sent!");
                //     this.unsetActionAchnor()
                // });
        })

    
        // this.props.conversationStore.handleSendMessageNotification(invoice)
       
    }

    hideCustomerMappedXeroModal = () => {
        this.setState({
            ...this.state,
            openCustomerMappedXeroModal: false,
            customerRow : []
        });
    };
    
    
    showCustomerMappedMYOBModal = (invoice) => {
        this.setState({
            ...this.state,
            openCustomerMappedMYOBModal: true,
            selectedPayerId: invoice.payerId,
            customerRow : [invoice]
        });
    };

    hideCustomerMappedMYOBModal = () => {
        this.setState({
            ...this.state,
            openCustomerMappedMYOBModal: false,
            customerRow : []
        });
    };
    
    handleViewInvoiceDetailClick = invoice => () => {
        this.setState({
            selectedInvoice: invoice,
            invoiceDetailDialogOpen: true
        });
    };

    solveCustomerPrivateBooking = invoiceId =>{
        let { outgoingInvoices } = this.props.invoiceStore;
        let invoices = outgoingInvoices.filter(x=>x.id === invoiceId)
        if(invoices.length){
            this.showCustomerMYOBModal(invoices[0])
        }
        
    }

    solveCustomerXeroPrivateBooking = invoiceId =>{
        let { outgoingInvoices } = this.props.invoiceStore;
        let invoices = outgoingInvoices.filter(x=>x.id === invoiceId)
        if(invoices.length){
            this.showCustomerXeroModal(invoices[0])
        }
        
    }
    
    
    solveCustomerNotFound = invoiceId =>{
        let { outgoingInvoices } = this.props.invoiceStore;
        let invoices = outgoingInvoices.filter(x=>x.id === invoiceId)
        this.showCustomerMappedMYOBModal(invoices[0])
    }
    solveCustomerXeroNotFound = invoiceId =>{
        let { outgoingInvoices } = this.props.invoiceStore;
        let invoices = outgoingInvoices.filter(x=>x.id === invoiceId)
        this.showCustomerMappedXeroModal(invoices[0])
    }

    handleCloseInvoiceDetailDialog = () => {
        this.setState({
            selectedInvoice: { invoiceDetails: [] },
            invoiceDetailDialogOpen: false
        });
    };

    getCheckedRows = rows => {
        var ids = [];
        rows.forEach(row => {
            if (row && row.id) {
                ids.push(row.id);
            }
        });
        this.props.invoiceStore.setSelectedOutgoingPdfBatch(ids);
    };

    handleBatchPrint = () => {
        const { selectedOutgoingPdfBatch } = this.props.invoiceStore;
        if (selectedOutgoingPdfBatch) {
            this.props.templateStore.showLoadingScreen();
            this.props.invoiceStore.downloadBatchInvoicePdf(selectedOutgoingPdfBatch).then(responseFile => {
                let pdfFile = `data:application/pdf;base64,${responseFile}`;
                this.setState(
                    {
                        ...this.state,
                        batchPrintModalState: true,
                        pdfFile: pdfFile
                    },
                    () => {
                        this.props.templateStore.hideLoadingScreen();
                    }
                );
            });
        }
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            batchPrintModalState: false
        });
    };

    getColumns = hasQuickBooksAccount => {
        var currentProfile = this.props.profileStore.getCurrentProfile()
        const columns = [
            { key: "date", label: "Invoice Date", sortable: true, isDate: true },
            { key: "dueDate", label: "Due Date", sortable: true, isDate: true },
            { key: "no", label: "Invoice No." },
            { key: "status", label: "Status" },
            { key: "subTotal", label: "Gig Fee", sortable: true, isNumber: true },
            { key: "tax", label: "Tax", sortable: true, isNumber: true },
            { key: "total", label: "Total", sortable: true, isNumber: true },
            {
                key: "payerName",
                label: this.props.profileStore.currentProfile && this.props.profileStore.currentProfile?.type === ProfileType.Agent.ordinal ? "Venue" : "Contact Name",
                sortable: true
            },
            { key: "payerPhone", label: "Contact Phone" },
            ...(ENABLE_MYOB && this.props.billingStore.billingAccess?.isProPlan && this.state.activateMYOBAccess ? [ { key: "syncMYOB", label: "Synced to MYOB" }] : []),
            ...(ENABLE_XERO && ((this.props.billingStore.billingAccess?.isProPlan && this.state.activateXeroAccess) || (currentProfile?.type === ProfileType.Staff.ordinal && currentProfile?.organisationOwner && currentProfile?.organisationOwner.activateXeroAccess)) ? [ { key: "syncXero", label: "Synced to Xero" }] : []),
           
            // ...(ENABLE_QUICKBOOKS && hasQuickBooksAccount ? [{ key: "quickBooks", label: "QuickBooks" }] : []),
            { key: "action", label: "" }
        ];
        return columns;
    };
    handleBatchSync = () => {
        const { selectedOutgoingPdfBatch } = this.props.invoiceStore;
        let currentProfileId = this.props.profileStore.currentProfile?.id;
        this.props.myobStore.resetResultIntegration()
        if (selectedOutgoingPdfBatch.length && selectedOutgoingPdfBatch.length < 11) {
            this.props.templateStore.showLoadingScreen();
            this.props.myobStore.resetResultIntegration();
            this.props.xeroStore.resetResultIntegration();
            this.props.myobStore.integrateOutgoingInvoice(currentProfileId, selectedOutgoingPdfBatch).then(result => {
                this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfileId).finally(() => {
                    this.props.templateStore.hideLoadingScreen();
                })
            });
        }else {
            this.props.templateStore.openSnackbar("Integration only support 10 invoice at a time");
        }
    }

     handleBatchSyncXero = () => {
        const { selectedOutgoingPdfBatch } = this.props.invoiceStore;
        let currentProfileId = this.props.profileStore.currentProfile?.id;
        this.props.xeroStore.resetResultIntegration()
        if (selectedOutgoingPdfBatch.length && selectedOutgoingPdfBatch.length < 11) {
            this.props.templateStore.showLoadingScreen();
            this.props.xeroStore.resetResultIntegration();
            this.props.myobStore.resetResultIntegration();
            this.props.xeroStore.integrateOutgoingInvoice(currentProfileId, selectedOutgoingPdfBatch).then(result => {
                this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfileId).finally(() => {
                    this.props.templateStore.hideLoadingScreen();
                })
            });
        }else {
            this.props.templateStore.openSnackbar("Integration only support 10 invoice at a time");
        }
    }

    renderFooterRow = invoices => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        let summary = invoices.reduce(
            (a, b) => {
                return { total: parseFloat(a.total) + parseFloat(b.total), subTotal: parseFloat(a.subTotal) + parseFloat(b.subTotal), tax: parseFloat(a.tax) + parseFloat(b.tax) };
            },
            { total: 0, subTotal: 0, tax: 0 }
        );
        let currencySymbol = "$"
        if((currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.type === ProfileType.Agent.ordinal) && currentProfile?.currency){
            currencySymbol = currentProfile?.currency === "GBP" ? "£" : "$"
        }else if(currentProfile?.type === ProfileType.Staff.ordinal ){
            if(currentProfile.hasOrganisation){
                currencySymbol = currentProfile.organisation[0].currency === "GBP" ? "£" : "$"
            }else{
                currencySymbol = currentProfile.venue.currency === "GBP" ? "£" : "$"
            }
        }
        return {
            date: `Total of ${invoices.length} invoices`,
            total: currencySymbol +  currencyHelper.formatMoney(parseFloat(summary.total), 2),
            subTotal: currencySymbol + currencyHelper.formatMoney(parseFloat(summary.subTotal), 2),
            tax: currencySymbol + currencyHelper.formatMoney(parseFloat(summary.tax), 2)
        };
    };

    handleOpenUnsyncInvoiceXeroModal = invoice => this.setState({ unsyncInvoiceXeroModal: true, invoice: invoice, actionAnchor: [] });
    handleCloseUnsyncInvoiceXeroModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceXeroModal: false,
            invoice: null
        });
    };

    handleConfirmMarkAsUnsyncXero = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.invoiceStore.unsyncXeroInvoice(currentProfile.id, this.state.invoice.id).then(response => {
            this.handleCloseUnsyncInvoiceXeroModal()
        }).catch((err) => {
            this.handleCloseUnsyncInvoiceXeroModal()
            this.props.templateStore.openSnackbar("This invoice has already been paid and cannot be unsynced. You will need to manually edit the invoice in XERO.");
        });
    }

    renderTableCustomActions = () => {
        const { selectedOutgoingPdfBatch } = this.props.invoiceStore;
        const { currentProfile } = this.props.profileStore;
        return (
            <Fragment>
                { ENABLE_MYOB && this.props.billingStore.billingAccess?.isProPlan && this.state.activateMYOBAccess ? (
                    <Button disabled={selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] == undefined || !selectedOutgoingPdfBatch ? true : false} onClick={this.handleBatchSync}>
                        <RefreshIcon style={{ color: selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] !== undefined ? "#fff" : undefined }} />
                        <span style={{ paddingTop: 3, color: selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] !== undefined ? "#fff" : undefined }}>SYNC WITH MYOB</span>
                    </Button>
                ) : null}
                 { ENABLE_XERO && ((this.props.billingStore.billingAccess?.isProPlan && this.state.activateXeroAccess) || (currentProfile?.type === ProfileType.Staff.ordinal && currentProfile?.organisationOwner && currentProfile?.organisationOwner.activateXeroAccess))? (
                    <Button disabled={selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] == undefined || !selectedOutgoingPdfBatch ? true : false} onClick={this.handleBatchSyncXero}>
                        <RefreshIcon style={{ color: selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] !== undefined ? "#fff" : undefined }} />
                        <span style={{ paddingTop: 3, color: selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] !== undefined ? "#fff" : undefined }}>SYNC WITH XERO</span>
                    </Button>
                ) : null}
                    <Button disabled={selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] == undefined || !selectedOutgoingPdfBatch ? true : false} onClick={this.handleBatchPrint}>
                        <PrintIcon style={{ color: selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] !== undefined ? "#fff" : undefined }} />
                        <span style={{ paddingTop: 3, color: selectedOutgoingPdfBatch && selectedOutgoingPdfBatch[0] !== undefined ? "#fff" : undefined }}>Print</span>
                    </Button>
            </Fragment>
        );
    };

    setActionAnchor = (element, bookingId) => {
        var tempAnchor = [];
        tempAnchor[bookingId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    render() {
        let { outgoingInvoices } = this.props.invoiceStore;
        const { selectedOutgoingPdfBatch } = this.props.invoiceStore;
        const { profile } = this.props.profileStore;
        const { pdfFile, batchPrintModalState,customerRow, openCustomerMYOBModal, openCustomerMappedMYOBModal,openCustomerMappedXeroModal,openCustomerXeroModal, selectedPayerId} = this.state;
        const { venueId } = this.props.reportStore;
        const { hasQuickBooksAccount } = this.props.userStore;
        var invoiceNumberArray = [];
        if(selectedOutgoingPdfBatch && selectedOutgoingPdfBatch.length > 0){
            selectedOutgoingPdfBatch.forEach(invoiceId => {
                var selectedInvoice = outgoingInvoices.filter(x=>x.id === invoiceId)[0]
                if(selectedInvoice){
                    invoiceNumberArray.push("HG " + selectedInvoice.no)
                }
               
            });
        }
        
        if (venueId) {
            outgoingInvoices = outgoingInvoices.filter(x => x.payerId === venueId);
        }

        const columns = this.getColumns(hasQuickBooksAccount);
        const invoices = this.props.invoiceStore.filterOutgoingInvoiceBaseOnFilter(outgoingInvoices);

        return (
            <Fragment>
                {
                    ENABLE_MYOB && (this.props.billingStore.billingAccess?.isProPlan && this.state.activateMYOBAccess)?
                    <Fragment>
                        <MYOBCustomerModal open={openCustomerMYOBModal} invoices={customerRow} closeHandler={this.hideCustomerMYOBModal} />
                        <MYOBMappedCustomerModal type={1}  selectedPayerId={selectedPayerId}  open={openCustomerMappedMYOBModal} invoices={customerRow} closeHandler={this.hideCustomerMappedMYOBModal} />

                </Fragment> : null
                }
                
                {
                    ENABLE_XERO && (this.props.billingStore.billingAccess?.isProPlan && this.state.activateXeroAccess) ?
                    <Fragment>
                        <XeroCustomerModal open={openCustomerXeroModal} invoices={customerRow} closeHandler={this.hideCustomerXeroModal} />
                        <XeroMappedContactModal type={1}  selectedPayerId={selectedPayerId}  open={openCustomerMappedXeroModal} invoices={customerRow} closeHandler={this.hideCustomerMappedXeroModal} />
                    </Fragment> : null
                }
                 
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Outgoing Invoices
                            </Typography>
                            {profile && profile?.type === ProfileType.Agent.ordinal ? (
                                <Typography gutterBottom className="side-helper-text">
                                    These are the invoices that are sent to venues - clients whom you have booked an act on their behalf from your act
                                    roster through your agency. Your invoices automatically appear here immediately after your act accepts the gig
                                    offer. Hot Giggity will then automatically dispatch the invoice according to the batching terms that the venue has
                                    stipulated in their batching and payment cycle.
                                </Typography>
                            ) : (
                                <Typography gutterBottom className="side-helper-text">
                                    These are the invoices that are sent to anyone who has booked you or acts you manage. Your invoices automatically
                                    appear here immediately after you accept the gig offer. Hot Giggity will then automatically dispatch the invoice
                                    according to the dispatching terms that the venue - agent have stipulated in their batching terms
                                </Typography>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={16}>
                            {/* <Grid item xs={12} className="text-xs-right">
                                <Button variant="contained" color="primary" onClick={this.refreshInvoices}>Refresh Invoice</Button>
                            </Grid> */}
                            <Grid container spacing={16}>
                                <Grid item xs={12}>
                                    <Paper>
                                        {
                                            this.props.myobStore.integrateReturnResult.length ? 
                                            ( 
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                    {this.props.myobStore.integrateReturnResult.map((value) => (
                                                        <ListItem
                                                        key={value.id}
                                                        disableGutters
                                                        >
                                                        <CloseIcon style={{ texAlign:"center",display:value.type == 0 ? "absolute" : "none",color: "red"}}  /> 
                                                        <CheckCircle style={{ texAlign:"center",display:value.type == 1 ? "absolute" : "none",color: "green"}}  /> 
                                                        <ListItemText primary={`${value.message}`} />
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            style={{margin:20,display:value.type == 0 && (value.code === 1 || value.code === 4)  ? "absolute" : "none"}}
                                                            onClick={value.code === 1 ? () => this.solveCustomerPrivateBooking(value.id) : () => this.solveCustomerNotFound(value.id) }
                                                        >
                                                            Quick Fix
                                                        </Button>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            ) : null
                                        }
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        {
                                            this.props.xeroStore.integrateReturnResult.length ? 
                                            ( 
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                    {this.props.xeroStore.integrateReturnResult.map((value) => (
                                                        <ListItem
                                                        key={value.id}
                                                        disableGutters
                                                        >
                                                        <CloseIcon style={{ texAlign:"center",display:value.type == 0 ? "absolute" : "none",color: "red"}}  /> 
                                                        <CheckCircle style={{ texAlign:"center",display:value.type == 1 ? "absolute" : "none",color: "green"}}  /> 
                                                        <ListItemText primary={`${value.message}`} />
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            style={{margin:20,display:value.type == 0 && (value.code === 1 || value.code === 4)  ? "absolute" : "none"}}
                                                            onClick={value.code === 1 ? () => this.solveCustomerXeroPrivateBooking(value.id) : () => this.solveCustomerXeroNotFound(value.id) }
                                                        >
                                                            Quick Fix
                                                        </Button>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            ) : null
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <div style={{ overflowX: "auto" }}>
                                        <ResponsiveTable
                                            showToolbar={true}
                                            customActions={this.renderTableCustomActions()}
                                            selectable={true}
                                            getCheckedRows={this.getCheckedRows}
                                            columns={columns}
                                            rows={invoices.map(invoice => {
                                                var row = {};
                                                row.id = invoice.id;
                                                row.date = moment.utc(invoice.date).format("DD MMM YYYY");
                                                row.dueDate = moment.utc(invoice.dueDate).format("DD MMM YYYY");
                                                row.no = invoice.no;

                                                row.status =
                                                    invoice.status === "pending" ? (
                                                        invoice.immediateInvoice ? (
                                                            <Tooltip
                                                                title={
                                                                    "This invoice is waiting for gig date at " +
                                                                    moment(invoice.date).format("DD MMM YYYY")
                                                                }
                                                                placement="bottom"
                                                            >
                                                                <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip
                                                                title={
                                                                    "This invoice is waiting for payer's billing cycle at " +
                                                                    moment(invoice.date).format("DD MMM YYYY")
                                                                }
                                                                placement="bottom"
                                                            >
                                                                <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                                            </Tooltip>
                                                        )
                                                    ) : invoice.status === "overdue" ? (
                                                        <Tooltip
                                                            title={
                                                                "This invoice is " +
                                                                moment(new Date()).diff(moment(invoice.dueDate), "days") +
                                                                " day(s) past due date"
                                                            }
                                                            placement="bottom"
                                                        >
                                                            <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                                        </Tooltip>
                                                    ) : (
                                                        <Chip className={classNames("status-chip", invoice.status)} label={invoice.status} />
                                                    );
                                                const currencySymbol = invoice && invoice.payee && invoice.payee.currency === "GBP" ? "£" : "$";
                                                row.total =  currencySymbol + currencyHelper.formatMoney(invoice.total, 2)
                                                row.subTotal =  currencySymbol + currencyHelper.formatMoney(invoice.subTotal, 2)
                                                row.tax = currencySymbol + currencyHelper.formatMoney(invoice.tax, 2)
                                                var payerName = invoice.payerName
                                                if(invoice.payer){
                                                    payerName = invoice.payer.profileName ? invoice.payer.profileName : invoice.payer.firstName + " " + invoice.payer.lastName
                                                }
                                                row.payerName = payerName;
                                                row.payerPhone = invoice.payerPhone;
                                                row.syncMYOB = invoice.isMYOBSyncronise ? <div style={{textAlign:"center", width:"100%"}}><CheckCircle style={{ texAlign:"center",color: "green"}} /></div>  : null;
                                                row.syncXero = invoice.isXeroSyncronise ? <div style={{textAlign:"center", width:"100%"}}><CheckCircle style={{ texAlign:"center",color: "green"}} /></div>  : null;
                                                row.quickBooks = (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        className="btn-sync-quickbooks"
                                                        component={Link}
                                                        to={`/invoice/${invoice.id}/quickbooks`}
                                                    >
                                                        Sync
                                                    </Button>
                                                );
                                                
                                                if((profile?.type === ProfileType.Musician.ordinal && invoice.status != "paid") || (this.state.activateMYOBAccess && !invoice.payerId ) || this.state.activateXeroAccess && !invoice.payerId  ){
                                                    row.action = (
                                                        <Fragment>
                                                            <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, invoice.id)}>
                                                                Actions
                                                            </Button>
                                                            <Menu
                                                                open={this.state.actionAnchor[invoice.id] != null}
                                                                onClose={() => this.unsetActionAchnor(invoice.id)}
                                                                anchorEl={this.state.actionAnchor[invoice.id]}
                                                                    >
                                                                <MenuItem
                                                                    button
                                                                    className="booking-details"
                                                                    onClick={() => {
                                                                        var redirectUrl = `/invoice/${invoice.id}/detail`
                                                                        this.props.history.push(redirectUrl);
                                                                    }}
                                                                >
                                                                    View
                                                                </MenuItem>
                                                                {
                                                                    profile?.type === ProfileType.Musician.ordinal && invoice.status != "paid" ? 
                                                                    <MenuItem
                                                                        button
                                                                        className="booking-details"
                                                                        onClick={() => this.handleSendMessageNotification(invoice)}
                                                                        >
                                                                           Send invoice reminder
                                                                    </MenuItem> : null
                                                                }
                                                                
                                                                { this.state.activateMYOBAccess && !invoice.payerId ?
                                                                     <MenuItem
                                                                        button
                                                                        className="booking-details"
                                                                        onClick={() => this.showCustomerMYOBModal(invoice)}
                                                                        >
                                                                            Set MYOB Customer
                                                                    </MenuItem> : null
                                                                }
    
                                                                {
                                                                    this.state.activateXeroAccess && !invoice.payerId ? 
                                                                    <MenuItem
                                                                        button
                                                                        className="booking-details"
                                                                        onClick={() => this.showCustomerXeroModal(invoice)}
                                                                        >
                                                                            Set Xero Customer
                                                                    </MenuItem> : null
                                                                }
                                                                 {invoice.isXeroSyncronise? <MenuItem onClick={() => this.handleOpenUnsyncInvoiceXeroModal(invoice)}>Unsync Xero Invoice </MenuItem> : null}
                                                                    
                                                            </Menu>
                                                        </Fragment>
                                                    );
                                                }else{
                                                    // row.action = (
                                                    //     <Button
                                                    //     size="small"
                                                    //     color="primary"
                                                    //     className="btn-invoice-detail"
                                                    //     component={Link}
                                                    //     style={{textAlign:"left",fontSize: "0.7rem"}}
                                                    //     to={`/invoice/${invoice.id}/detail`}
                                                    //     >
                                                    //         Detail
                                                    //     </Button>)

                                                    row.action =  
                                                    (  <Fragment>
                                                            <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, invoice.id)}>
                                                                Actions
                                                            </Button>
                                                            {invoice.id && (
                                                                <Menu
                                                                    open={this.state.actionAnchor[invoice.id] != null}
                                                                    onClose={() => this.unsetActionAchnor(invoice.id)}
                                                                    anchorEl={this.state.actionAnchor[invoice.id]}
                                                                >
                                                                    <MenuItem
                                                                        button
                                                                        className="btn-invoice-detail"
                                                                        onClick={() => {
                                                                            var redirectUrl = `/invoice/${invoice.id}/detail`
                                                                            this.props.history.push(redirectUrl);
                                                                        }}
                                                                    >
                                                                        View
                                                                    </MenuItem>
                                                                    {invoice.isXeroSyncronise? <MenuItem onClick={() => this.handleOpenUnsyncInvoiceXeroModal(invoice)}>Unsync Xero Invoice </MenuItem> : null}                
                                                                </Menu>
                                                            )}
                                                        </Fragment>
                                                    ) 
                                                        
                                                }
                                               

                                               
                                            //    if(this.state.activateMYOBAccess && !invoice.payerId){
                                            //         row.action = (
                                            //             <paper>
                                            //                 <Button
                                            //                     size="small"
                                            //                     color="primary"
                                            //                     className="btn-invoice-detail"
                                            //                     component={Link}
                                            //                     style={{textAlign:"left",fontSize: "0.7rem"}}
                                            //                     to={`/invoice/${invoice.id}/detail`}
                                            //                 >
                                            //                     Detail
                                            //                 </Button>
                                            //                 <Button
                                            //                     size="small"
                                            //                     color="primary"
                                            //                     className="btn-invoice-detail"
                                            //                     style={{textAlign:"left",fontSize: "0.7rem"}}
                                            //                     onClick={() => this.showCustomerMYOBModal(invoice)}
                                            //                 >
                                            //                     Set MYOB Customer
                                            //                 </Button>
                                            //             </paper>
                                            //         );
                                            //     }else if(this.state.activateXeroAccess && !invoice.payerId){
                                            //         row.action = (
                                            //             <paper>
                                            //                 <Button
                                            //                     size="small"
                                            //                     color="primary"
                                            //                     className="btn-invoice-detail"
                                            //                     component={Link}
                                            //                     style={{textAlign:"left",fontSize: "0.7rem"}}
                                            //                     to={`/invoice/${invoice.id}/detail`}
                                            //                 >
                                            //                     Detail
                                            //                 </Button>
                                            //                 <Button
                                            //                     size="small"
                                            //                     color="primary"
                                            //                     className="btn-invoice-detail"
                                            //                     style={{textAlign:"left",fontSize: "0.7rem"}}
                                            //                     onClick={() => this.showCustomerXeroModal(invoice)}
                                            //                 >
                                            //                     Set Xero Customer
                                            //                 </Button>
                                            //             </paper>
                                            //         );
                                            //     }else {
                                            //         row.action = (
                                            //             <div>
                                            //                 <Button
                                            //                     size="small"
                                            //                     color="primary"
                                            //                     className="btn-invoice-detail"
                                            //                     component={Link}
                                            //                     style={{textAlign:"left",fontSize: "0.7rem"}}
                                            //                     to={`/invoice/${invoice.id}/detail`}
                                            //                 >
                                            //                     Detail
                                            //                 </Button>
                                                           
                                            //             </div>
                                            //         );
                                            //     }
                                                

                                                return row;
                                            })}
                                            sortable={true}
                                            footerRow={this.renderFooterRow(invoices)}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
 

                <ConfirmationModal
                    open={this.state.unsyncInvoiceXeroModal}
                    title={"Unsync Confirmation"}
                    message={`This action will void the invoice in XERO. You will need to resync the invoice again. Are you sure you want to continue?`}
                    closeHandler={this.handleCloseUnsyncInvoiceXeroModal}
                    confirmationHandler={this.handleConfirmMarkAsUnsyncXero}
                    declineHandler={this.handleCloseUnsyncInvoiceXeroModal}
                    confirmationLabel="Confirm"
                />
                <ModalContainer open={batchPrintModalState} onClose={this.handleClose} title="Batch Invoice Pdf" coloredDialogTitle={true}>
                    <DialogContent>
                        <PdfViewer file={pdfFile} batch={true} invoiceIds={selectedOutgoingPdfBatch} receiptNumber={invoiceNumberArray.join()} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>Close</Button>
                    </DialogActions>
                </ModalContainer>
            </Fragment>
        );
    }
}
export default OutgoingInvoice;
