import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import RichTextEditor from "../../richTextEditor/RichTextEditor";
import ModalContainer from "../../modal/ModalContainer";
import InvitedActPreview from "./InvitedActPreview";
import ProfileSearchBox from "../../fields/ProfileSearchBox";
import { ProfileType } from "../../../types/enum";
import Loading from "../../loadingSpinner/Loading";

@inject("profileStore", "agentActStore", "venueActStore")
@observer
class ActInvitationModal extends Component {
    state = {
        loading: false
    };

    handleInvite = () => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            this.props.agentActStore.inviteMany(true).then(() => {
                this.props.onClose();
            });
            this.handleClear();
        } else if (profileType === "venue") {
            this.props.venueActStore.inviteMany(true).then(() => {
                this.props.onClose();
            });
            this.handleClear();
        }
    };

    getExcludeSearch = () => {
        const { profileType } = this.props;
        var excludedProfile = [];

        if (profileType === "agent") {
            const { pendingAgentActs, agentActs } = this.props.agentActStore;
            pendingAgentActs.forEach(data => {
                var act = data.primaryProfile.type === ProfileType.Act.ordinal ? data.primaryProfile : data.secondaryProfile;
                if (act) {
                    excludedProfile.push(act);
                }
            });
            agentActs.forEach(data => {
                if (data && data.act) {
                    excludedProfile.push(data.act);
                }
            });
        } else if (profileType === "venue") {
            const { pendingRequest, venueActRoster } = this.props.venueActStore;
            pendingRequest.forEach(data => {
                var act =
                    data.primaryProfile && data.primaryProfile.type
                        ? data.primaryProfile.type === ProfileType.Act.ordinal
                            ? data.primaryProfile
                            : data.secondaryProfile
                        : {};
                if (act) {
                    excludedProfile.push(act);
                }
            });
            venueActRoster.forEach(data => {
                if (data && data.act) {
                    excludedProfile.push(data.act);
                }
            });
        } else if (profileType === "staff") {
        }

        return excludedProfile;
    };

    handleSelect = item => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            this.props.agentActStore.setInvitedAct(item);
        } else if (profileType === "venue") {
            this.props.venueActStore.setInvitedAct(item);
        }
    };

    handleInvitationMessageChange = value => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            this.props.agentActStore.setInvitationMessage(value);
        } else if (profileType === "venue") {
            this.props.venueActStore.setInvitationMessage(value);
        }
    };

    getInvitedAct = () => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            return this.props.agentActStore.invitedAct;
        } else if (profileType === "venue") {
            return this.props.venueActStore.invitedAct;
        }
        return undefined;
    };

    getInvitedActs = () => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            return this.props.agentActStore.invitedActs;
        } else if (profileType === "venue") {
            return this.props.venueActStore.invitedActs;
        }
    };

    getInvitedMusician = () => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            return this.props.agentActStore.invitedAct.myMusician;
        } else if (profileType === "venue") {
            return this.props.venueActStore.invitedAct.myMusician;
        }
    };

    getInvitationMessage = () => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            return this.props.agentActStore.invitationMessage;
        } else if (profileType === "venue") {
            return this.props.venueActStore.invitationMessage;
        }
    };

    handleClear = () => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            this.props.agentActStore.clearInviteData();
        } else if (profileType === "venue") {
            this.props.venueActStore.clearInviteData();
        }
    };

    handleCheckRow = invitedActs => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            this.props.agentActStore.setInvitedChecked(invitedActs);
        } else if (profileType === "venue") {
            this.props.venueActStore.setInvitedChecked(invitedActs);
        }
    };

    handleClear = () => {
        const { profileType } = this.props;
        if (profileType === "agent") {
            this.props.agentActStore.clearInviteData();
        } else if (profileType === "venue") {
            this.props.venueActStore.clearInviteData();
        }
    };

    render() {
        const { isLoading } = this.props.agentActStore;
        return (
            <Fragment>
                <ModalContainer title="Add Act" open={this.props.open} onClose={this.props.onClose}>
                    <DialogContent>
                        <Grid container spacing={8}>
                            <Grid item xs={12} lg={12} style={{ position: "relative",  minHeight:300 }}>
                                <Loading showed={isLoading} padding="8" borderRadius="4px" />
                                {this.getInvitedAct() ? (
                                    <Fragment>
                                        <InputLabel>Invited Act Owner</InputLabel>
                                        <InvitedActPreview
                                            musician={this.getInvitedMusician()}
                                            invitedActs={this.getInvitedActs()}
                                            handleCheckRow={this.handleCheckRow}
                                            handleClear={this.handleClear}
                                            exclude={this.getExcludeSearch()}
                                            handleEmpty={() => this.openInviteActOwnerModal().bind(this)}
                                        />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <InputLabel>Act</InputLabel>
                                        <ProfileSearchBox
                                            placeholder="Search Act"
                                            id="invite-act-name"
                                            handleSearch={value => this.props.profileStore.searchAct(value)}
                                            handleChange={item => this.handleSelect(item)}
                                            exclude={this.getExcludeSearch()}
                                            menuStyle={{
                                                position: "relative",
                                                maxHeight: "300px",
                                                overflowY: "auto"
                                            }}
                                            handleEmpty={this.props.handleEmpty}
                                        />
                                    </Fragment>
                                )}
                            </Grid>
                            {/* // Hide invitation message field
                            <Grid item xs={12} lg={6}>
                                <InputLabel>Invitation Message</InputLabel>
                                <RichTextEditor
                                    label="Invitation Message"
                                    value={this.getInvitationMessage()}
                                    onChange={this.handleInvitationMessageChange}
                                />
                            </Grid> */}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.props.onClose}>
                            Close
                        </Button>
                        <Button id="invite-act-submit" color="primary" onClick={this.handleInvite}>
                            Invite
                        </Button>
                    </DialogActions>
                </ModalContainer>
            </Fragment>
        );
    }
}

export default ActInvitationModal;
