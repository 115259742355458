import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import "./Modal.css";
import Loading from "../loadingSpinner/Loading";

class ModalContainer extends Component {
    render() {
        const { fullScreen } = this.props;
        return (
            <Dialog
                disableEnforceFocus={true}
                open={this.props.open}
                onClose={this.props.onClose}
                onEntered={this.props.onEntered}
                fullScreen={fullScreen}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "md"}
                fullWidth
                disableRestoreFocus
                className="dialog-div"
                disableBackdropClick={this.props.disableBackdropClick}
                disableEscapeKeyDown={this.props.disableEscapeKeyDown}
            >
                <DialogTitle
                    // style={{
                    //     paddingBottom: "8px"
                    // }}
                    className={this.props.coloredDialogTitle ? "see-more-dialog-title" : ""}
                >
                    {this.props.title || ""}
                </DialogTitle>
                {/* <Divider 
                    style={
                        {
                            marginBottom: "16px",
                        }
                    }
                /> */}
                <div style={{ position: "relative" }}>
                    <Loading showed={this.props.isLoading} />
                    {this.props.children}
                </div>
            </Dialog>
        );
    }
}
export default withMobileDialog()(ModalContainer);
