import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
import ResponsiveTable from "../../components/table/ResponsiveTable";
import SelectField from "../../components/fields/SelectField";
import ConfirmationModal from "../../components/modal/ConfirmationModal";

@inject("authStore", "templateStore", "userStore","profileStore","myobStore")
@observer
class SupplierMapping extends Component {
    state = {
        myobActive: false,
        myobAccount: "",
        editedId: "",
        updateProcess: false,
        myobUID: "",
        mappedId: "",
        newpayeeId:"",
        openRemoveContactModalState: false,
        removedId : null,
        editedMYOBId: ""
    };
    supplierMappingColumn = [
        { key: "suppliers", label: "Hot Giggity Suppliers" },
        { key: "myobSuppliers", label: "MYOB Suppliers" },
        { key: "status", label: "Status" },
        { key: "action", label: "" }
    ];
    
    
    supplierMappingRows = [];
    componentDidMount = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        Promise.all([
            this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
            this.props.myobStore.getMYOBSuppliers(currentProfile.id)
        ]).then(() => { 
  
        });
    };

    handleMappedId = (payeeId,myobUID) => {
        this.props.myobStore.setEditedSupplierMYOB(payeeId,myobUID)
        this.setState({
            editedId: payeeId
        });
    }
    setEditedSupplier = value =>  {
        const { editedMYOBId } = this.state
        if(this.state.editedId && !editedMYOBId){
            this.props.myobStore.cancelEditedSupplierMYOB(this.state.editedId,false)
        }
        if(editedMYOBId){
            this.props.myobStore.cancelUpdatedMYOBSupplier(this.state.editedId,false)
        }
        this.setState({
            editedId: value,
            updateProcess: false,
            editedMYOBId: ""
        });
    }

    setUpdatedSupplier = (data) =>  {
        const { editedMYOBId } = this.state
        if(this.state.editedId && !editedMYOBId){
            this.props.myobStore.cancelEditedSupplierMYOB(this.state.editedId,false)
        }
        if(editedMYOBId){
            this.props.myobStore.cancelUpdatedMYOBSupplier(this.state.editedId,false)
        }
        this.props.myobStore.setUpdatedMYOBSupplier(data.payeeId)
        // this.props.myobStore.cancelEditedMYOB(existingUID)
        this.setState({
            editedId: data.payeeId,
            editedMYOBId: data.myobUID,
            updateProcess: true
        });
    }

    handleOpenConfirmCreateNewSupplier = id => {
        this.setState({
            confirmNewSupplierModalState: true,
            newpayeeId: id
        });
    };

    openRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: true,
            removedId : id
        });
    };
    closeRemoveContactModal = id => {
        this.setState({
            openRemoveContactModalState: true,
            removedId : null
        });
    };
    
    handleCloseConfirmNewSupplierInvitationModal = () => {
        this.setState({
            ...this.state,
            confirmNewSupplierModalState: false,
            newpayeeId: undefined
        });
    };

    saveEditedSupplier = (mappedId,payeeId,myobUID) =>{
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        this.props.templateStore.showLoadingScreen()
        if(this.state.updateProcess){
            this.props.myobStore.updateContactMYOB(mappedId, myobUID).then(result =>  {
                Promise.all([
                    this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
                    this.props.myobStore.getMYOBSuppliers(currentProfile.id)
                ]).then(() => { 
                    // this.props.myobStore.cancelEditedMYOB(payerId)
                    this.setState({
                        editedId: "",
                        editedMYOBId: ""
                    });
                });
              
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
               
            })
        }else{
            this.props.myobStore.mapExistingMYOBSupplier(currentProfile.id, payeeId, myobUID).then(result =>  {
                Promise.all([
                    this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
                    this.props.myobStore.getMYOBSuppliers(currentProfile.id)
                ]).then(() => { 
                    this.setState({
                        editedId: ""
                    });
                });
            
            }).finally(()=> {
                this.props.templateStore.hideLoadingScreen()
            
            })
        }
    }

    handleDeleteContact = () => {
        const { removedId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        this.props.templateStore.showLoadingScreen()
        this.props.myobStore.deleteMYOBMapping(removedId).then(result =>  {
            Promise.all([
                this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
                this.props.myobStore.getMYOBSuppliers(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    openRemoveContactModalState: false,
                    removedId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
        
    }
    handleCreateNewContact = () => {
        const { newpayeeId } = this.state
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
        
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        this.props.templateStore.showLoadingScreen()
        this.props.myobStore.createMYOBSuppliers(currentProfile.id, newpayeeId).then(result =>  {
            Promise.all([
                this.props.myobStore.getListMYOBMappedSuppliers(currentProfile.id),
                this.props.myobStore.getMYOBSuppliers(currentProfile.id)
            ]).then(() => { 
                this.setState({
                    ...this.state,
                    confirmNewSupplierModalState: false,
                    newpayeeId: undefined
                });
            });
          
        }).finally(()=> {
            this.props.templateStore.hideLoadingScreen()
           
        })
       
    }
    cancelEditedSupplier = (payeeId) =>{
        if(this.state.updateProcess){
            this.props.myobStore.cancelEditedSupplierMYOB(payeeId,true)
                this.setState({
                    editedId: "",
                    editedMYOBId: "",
                    updateProcess: false
                });
        }else{

            this.props.myobStore.cancelEditedSupplierMYOB(payeeId,false)
            this.setState({
                editedId: ""
            });
        }
    }
    
    mapSuppliersToRowData = () => {
        const { listMYOBMappedSuppliers,myobSuppliers,myobSuppliersFull } = this.props.myobStore;
        
        const {editedId} = this.state
        this.supplierMappingRows = [];
        listMYOBMappedSuppliers.forEach(data => {
            var row = {};

            row.suppliers = data.profileName ? data.profileName : data.firstName + " " + data.lastName

            if(data.myobUID && !data.isEdited && !data.isUpdated){
                var existingUID = myobSuppliersFull.filter(x=>x.value === data.myobUID)
                if(existingUID.length){
                    row.myobSuppliers = <TextField
                    // label="MYOB Supplier Name"
                    inputProps={{
                        readOnly: true
                    }}
                    value={existingUID[0].label}
                    fullWidth
                />
                }
               
            }else if(data.payeeId != editedId){
                row.myobSuppliers = <TextField
                // label="MYOB Supplier Name"
                inputProps={{
                    readOnly: true
                }}
                value="Map MYOB Account"
                fullWidth
            />
            }else{
                row.myobSuppliers =  <SelectField
                id="myobSuppliers"
                value={data.myobUID}
                suggestions={myobSuppliers}
                placeholder="Map MYOB Account"
                disabled={data.payeeId != editedId}
                onChange={(value) => this.handleMappedId(data.payeeId,value)}
            />
            }
            
           
            
           
            row.status = data.myobUID ? ((data.isEdited || data.isUpdated) ? "Confirm?" : "Mapped" ) : "Not Mapped";

            if(data.myobUID && !data.isEdited && !data.isUpdated){
                row.action = (
                    <Fragment>
                         <Button size="small" color="primary" onClick={() => this.setUpdatedSupplier(data)}>
                            Update
                        </Button>
                        <Button size="small" color="primary" onClick={() => this.openRemoveContactModal(data.mappedId)}>
                            Remove
                        </Button>
                    </Fragment>
                );
    
            }else{
                if(data.isEdited || data.isUpdated){
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.saveEditedSupplier(data.mappedId,data.payeeId,data.myobUID)}>
                                Save
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.cancelEditedSupplier(data.payeeId)}>
                                Cancel
                            </Button>
                        </Fragment>
                        
                    );
                   
                }else{
                    row.action = (
                        <Fragment>
                             <Button size="small" color="primary" onClick={() => this.setEditedSupplier(data.payeeId)}>
                                Map Existing
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.handleOpenConfirmCreateNewSupplier(data.payeeId)}>
                                Create New
                            </Button>
                        </Fragment>
                        
                    );
                }
                
            }
           
            // row.style = {
            //     backgroundColor: "#FFC0CB"
            // };

            this.supplierMappingRows.push(row);
        });
        
        return Promise.resolve();
    };

    render() {
        this.mapSuppliersToRowData()
        return (
            <div>
                <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                Your suppliers are the contacts that are listed in your incoming invoices section.
                                            </Typography>
                                            <Typography gutterBottom>
                                                Mapping your Hot Giggity suppliers to your MYOB Suppliers allowsthe system to sync invoices to the correct contact in MYOB.
                                            </Typography>
                                            <Typography gutterBottom>
                                            You will only have to do this once. New Hot Giggity suppliers added after the initial mapping  will automatically be added to your MYOB account
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item md={12}>
                                                        <Typography gutterBottom>Step 4. Map your Hot Giggity suppliers your existing MYOB suppliers. </Typography>

                                                        
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <ResponsiveTable
                                                            columns={this.supplierMappingColumn}
                                                            rows={this.supplierMappingRows}
                                                            showToolbar={true}
                                                            title="Supplier Mapping"
                                                            pagination={this.supplierMappingRows.length > 5}
                                                        />

                                                    </Grid>  
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>

                <ConfirmationModal
                    open={this.state.confirmNewSupplierModalState}
                    message="Are you sure you want to create a new MYOB contact for this contact?"
                    closeHandler={this.handleCloseConfirmNewSupplierInvitationModal}
                    confirmationHandler={this.handleCreateNewContact}
                    declineHandler={this.handleCloseConfirmNewSupplierInvitationModal}
                    confirmationLabel="Confirm"
                />

                <ConfirmationModal
                    open={this.state.openRemoveContactModalState}
                    message="Are you sure you want to remove the existing connection to MYOB contact?"
                    closeHandler={this.closeRemoveContactModal}
                    confirmationHandler={this.handleDeleteContact}
                    declineHandler={this.closeRemoveContactModal}
                    confirmationLabel="Confirm"
                />

            </div>
            
        );
    }
}

export default SupplierMapping;
