import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import ModalContainer from "../../modal/ModalContainer.js";
import ClientInvoice from "./ClientInvoice.js";
import ClientContract from "./ClientContract.js";
import EntertainerClientContract from "./EntertainerClientContract.js";

class DocumentModal extends Component {
    render() {
        return (
            <ModalContainer
                open={this.props.open}
                title={this.props.title ? this.props.title : "Document"}
                onClose={this.props.closeHandler}
                fullScreen={false}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "xs"}
            >
                <DialogContent
                // style={{ paddingTop: "16px" }}
                >
                    {this.props.clientInvoice ?
                        <ClientInvoice data={this.props.data} /> :
                            this.props.isEnterTainer ? 
                                <EntertainerClientContract data={this.props.dataClientEmail}/> :
                                    <ClientContract data={this.props.data}/>
                    }

                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.closeHandler}>
                        Close
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default DocumentModal;
