import React, { Component } from "react";
import { inject } from "mobx-react";
import { Grid, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import venue from "../../img/venue.svg";
import groupvenue from "../../img/venue-group.svg";

import "./ChooseProfile.css";

@inject("profileStore", "userStore")
@withRouter
class CreateVenue extends Component {
    handleCreateVenueGroup = e => {
        e.preventDefault();
        this.props.history.replace(`/organisation`);
    };

    handleCreateVenue = e => {
        e.preventDefault();
        this.props.history.replace(`/venue/edit`);
    };

    handleGoToDashboard = e => {
        e.preventDefault();
        const { currentUser } = this.props.userStore;
        const status = currentUser.status;
        this.props.userStore.completeUser().then(user => {
            switch (status) {
                case 1:
                    this.props.history.push("/musician");
                    break;
                case 11:
                    this.props.history.push("/agent");
                    break;
                case 21:
                    this.props.history.push("/staff");
                    break;
                default:
                    this.props.history.push("/account/setting");
                    break;
            }
        });
    };
    render() {
        return (
            <Grid container spacing={24} justify="center">
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <div className="type">
                            <Button id="create-venue-group" onClick={this.handleCreateVenueGroup}>
                                <Typography component="div" type="subheading" color="inherit">
                                    <div className="type-image">
                                        <img src={groupvenue} alt="Create venue group" />
                                    </div>
                                    <span className="title">Venue Group</span>
                                    <span className="subtitle">Create your organisation structure to manage permission for members</span>
                                </Typography>
                            </Button>
                        </div>
                        <div className="type">
                            <Button id="create-venue" onClick={this.handleCreateVenue}>
                                <Typography component="div" type="subheading" color="inherit">
                                    <div className="type-image">
                                        <img src={venue} alt="Create single venue" />
                                    </div>
                                    <span className="title">Single Venue</span>
                                    <span className="subtitle">Start creating your venues</span>
                                </Typography>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        {/* <Button variant="contained" color="primary" onClick={this.handleGoToDashboard}>
                            Skip this step and take me to the dashboard.
                        </Button> */}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default CreateVenue;
