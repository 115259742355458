import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./TableWidget.css";

import EditIcon from "@material-ui/icons/Edit";

class TableWidget extends Component {
    renderTableHeader = () => {
        let headerData = [];
        for (let i = 0; i < this.props.tableData.title.length; i++) {
            headerData.push(<TableCell>{this.props.tableData.title[i]}</TableCell>);
        }
        return headerData;
    };

    renderTableData = () => {
        let rows = [];
        for (let i = 0; i < this.props.tableData.data.length; i++) {
            rows.push(
                <TableRow key={this.props.tableData.data[i].id}>
                    <TableCell>{this.props.tableData.data[i].date}</TableCell>
                    <TableCell>{this.props.tableData.data[i].venue}</TableCell>
                    <TableCell>{this.props.tableData.data[i].address}</TableCell>
                    <TableCell>{this.props.tableData.data[i].artist}</TableCell>
                    <TableCell>{this.props.tableData.data[i].time}</TableCell>
                    <TableCell>{this.props.tableData.data[i].consultant}</TableCell>
                    <TableCell>
                        <IconButton aria-label="Edit">
                            <EditIcon className="table-icon" />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }
        return rows;
    };

    render() {
        return (
            <Paper classes={{ root: "table-paper" }}>
                <Table>
                    <TableHead>
                        <TableRow> {this.renderTableHeader()} </TableRow>
                    </TableHead>
                    <TableBody>{this.renderTableData()}</TableBody>
                </Table>
            </Paper>
        );
    }
}

export default TableWidget;
