import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";

import { Grid, Switch, Button, Card, Avatar, IconButton, Typography, InputLabel, TextField } from "@material-ui/core";

import CardHeader from "@material-ui/core/CardHeader";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ModalContainer from "../../modal/ModalContainer";
import ProfileSearchBox from "../../fields/ProfileSearchBox";
import RichTextEditor from "../../richTextEditor/RichTextEditor";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ProfileType } from "../../../types/enum";

import ClearIcon from "@material-ui/icons/Clear";
import image from "../../../helper/image";

@inject("agentVenueStore", "profileStore", "templateStore", "userInvitationStore")
@observer
class AgentInvitationModal extends Component {
    state = {
        inviteNewUser: false
    };

    constructor(props) {
        super(props);
        this.state = {
            inviteNewUser: false,
            isLoading: false
        };
    }

    componentDidMount() {
        this.props.agentVenueStore.resetInvitationField();
    }

    onClose = () => {
        this.setState(
            {
                inviteNewUser: false
            },
            () => {
                this.props.agentVenueStore.resetInvitationField();
                this.props.onClose();
            }
        );
    };

    renderDaySwitch = () => {
        return (
            <Grid container spacing={0}>
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(data => (
                    <Grid item xs={3} md={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.agentVenueStore.dayToBook[data.toLowerCase()]}
                                    onChange={() => this.props.agentVenueStore.toggleDayToBook(data.toLowerCase())}
                                    value={data.toLowerCase()}
                                    color="primary"
                                />
                            }
                            label={data}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    handleInvite = () => {
        const { inviteNewUser } = this.state;

        this.setState({
            ...this.state,
            isLoading: true
        });

        if (inviteNewUser) {
            this.props.userInvitationStore.inviteVenueAgent(this.props.venueId,this.props.agentVenueStore.manage,this.props.agentVenueStore.manageSocialPost).then(
                () => {
                    this.props.templateStore.openSnackbar("Agent has been invited.");
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                    this.onClose();
                },
                err => {
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                    this.props.templateStore.openSnackbar(err);
                }
            );
        } else {
            this.props.agentVenueStore.inviteAgentByVenue(this.props.venueId).then(response => {
                this.props.agentVenueStore.getVenueAgent(this.props.venueId).then(() => {
                    this.props.templateStore.openSnackbar("Agent has been invited.");
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                    this.onClose();
                });
            });
        }
    };

    getExcludeSearch = () => {
        const { pendingAgentVenues, agentVenues } = this.props.agentVenueStore;
        var excludedProfile = [];
        pendingAgentVenues.forEach(data => {
            var agent = data.primaryProfile.type === ProfileType.Agent.ordinal ? data.primaryProfile : data.secondaryProfile;
            let agentId = data.primaryProfile.type === ProfileType.Agent.ordinal ? data.primaryProfileId : data.secondaryProfileId;
            if (agent) {
                excludedProfile.push({...agent, id: agentId});
            }
        });
        agentVenues.forEach(data => {
            if (data && data.agent) {
                excludedProfile.push(data.agent);
            }
        });

        return excludedProfile;
    };

    handleEmpty = value => {
        this.props.userInvitationStore.resetInvitedData();
        this.setState(
            {
                ...this.state,
                inviteNewUser: true
            },
            () => {
                var names = value.match(/\S+/g) || [];

                if (names && names[0]) {
                    this.props.userInvitationStore.setInvitedUserFirstName(names[0]);
                }
                if (names && names[1]) {
                    this.props.userInvitationStore.setInvitedUserLastName(names[names.length - 1]);
                }
            }
        );
    };

    isInviteButtonDisabled = () => {
        const { inviteNewUser } = this.state;
        const { errors, invitedUserFirstName, invitedUserLastName, invitedUserEmail } = this.props.userInvitationStore;
        const { invitedProfile } = this.props.agentVenueStore;
        if (inviteNewUser) {
            const isError = errors?.firstName || errors?.lastName || errors?.actName || errors?.email
            return isError || !invitedUserFirstName || !invitedUserLastName || !invitedUserEmail;
        } else {
            return !invitedProfile;
        }
    };

    syncManage = () => {
        this.props.agentVenueStore.setManage(!this.props.agentVenueStore.manage)
        
    };

    syncSocialPost = () => {
        this.props.agentVenueStore.setSocialPost(!this.props.agentVenueStore.manageSocialPost)
        
    };
    
    handleValidate(value, type) {
        switch (type) {
            case "firstName":
                this.props.userInvitationStore.validateInvitedUserFirstName(value);
                break;
            case "lastName":
                this.props.userInvitationStore.validateInvitedUserLastName(value);
                break;
            case "email":
                this.props.userInvitationStore.validateInvitedUserEmail(value);
                break;;
        
            default:
                break;
        }
    }

    render() {
        const { inviteNewUser, isLoading } = this.state;
        const { errors, invitedUserEmail, invitedUserFirstName, invitedUserLastName } = this.props.userInvitationStore;

        return (
            <ModalContainer open={this.props.open} onClose={this.onClose} title="Invite Agent" isLoading={isLoading}>
                <DialogContent
                    style={{
                        paddingTop: "8px"
                    }}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={10}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    {this.props.agentVenueStore.invitedProfile && !inviteNewUser ? (
                                        <Fragment>
                                            <InputLabel>Agent</InputLabel>
                                            <Card>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar
                                                            alt={this.props.agentVenueStore.invitedProfile.fullName}
                                                            src={image.getProfileImage(this.props.agentVenueStore.invitedProfile)}
                                                            style={{
                                                                width: 40,
                                                                height: 40
                                                            }}
                                                        />
                                                    }
                                                    action={
                                                        <IconButton onClick={() => this.props.agentVenueStore.setInvitedProfile(undefined)}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    }
                                                    title={this.props.agentVenueStore.invitedProfile.fullName}
                                                />
                                            </Card>
                                        </Fragment>
                                    ) : !inviteNewUser ? (
                                        <Fragment>
                                            <InputLabel>Agent</InputLabel>
                                            <ProfileSearchBox
                                                placeholder="Search Agent"
                                                handleSearch={value => this.props.profileStore.searchAgencyProfile(value)}
                                                handleChange={item => this.props.agentVenueStore.setInvitedProfile(item)}
                                                handleEmpty={this.handleEmpty}
                                                exclude={this.getExcludeSearch()}
                                                menuStyle={{
                                                    position: "absolute",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    width: "100%"
                                                }}
                                            />
                                        </Fragment>
                                    ) : inviteNewUser ? (
                                        <Fragment>
                                            <Grid container spacing={8}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Agent First Name"
                                                        value={invitedUserFirstName}
                                                        fullWidth
                                                        onBlur={e => this.handleValidate(e.target.value, "firstName")}
                                                        onChange={e => this.props.userInvitationStore.setInvitedUserFirstName(e.target.value)}
                                                        error={errors && errors["firstName"] ? true : false}
                                                        helperText={errors && errors["firstName"] ? errors["firstName"] : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Agent Last Name"
                                                        value={invitedUserLastName}
                                                        fullWidth
                                                        onBlur={e => this.handleValidate(e.target.value, "lastName")}
                                                        onChange={e => this.props.userInvitationStore.setInvitedUserLastName(e.target.value)}
                                                        error={errors && errors["lastName"] ? true : false}
                                                        helperText={errors && errors["lastName"] ? errors["lastName"] : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Agent Email"
                                                        value={invitedUserEmail}
                                                        fullWidth
                                                        onBlur={e => this.handleValidate(e.target.value, "email")}
                                                        onChange={e => this.props.userInvitationStore.setInvitedUserEmail(e.target.value)}
                                                        error={errors && errors["email"]}
                                                        helperText={errors && errors["email"] ? errors["email"] : ""}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Invitation Message</InputLabel>
                                    <RichTextEditor
                                        value={this.props.agentVenueStore.invitationMessage}
                                        onChange={value => this.props.agentVenueStore.setInvitationMessage(value)}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <p style={{fontSize:14, fontWeight:900}}> <Switch checked={this.props.agentVenueStore.manage} onChange={this.syncManage} value="hideToolbar" />  Manage Favourite Act</p>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <p style={{fontSize:14, fontWeight:900}}> <Switch checked={this.props.agentVenueStore.manageSocialPost} onChange={this.syncSocialPost} value="hideToolbar" />  Manage Social Post</p>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={2}>
                            <InputLabel>Booking Days</InputLabel>
                            {this.renderDaySwitch()}
                            <Typography variant="caption">Unselected days will restrict agent to create gig on those days.</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose}>Close</Button>
                    <Button color="primary" onClick={this.handleInvite} disabled={this.isInviteButtonDisabled()}>
                        Invite
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default AgentInvitationModal;
