import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import AgentTable from "../../components/agentList/AgentTable";

@inject("profileStore")
@observer
class Invitations extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "My Agents" }];

    render() {
        const { currentProfile } = this.props.profileStore;
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    {currentProfile && <AgentTable />}
                </MainContainer>
            </Fragment>
        );
    }
}

export default Invitations;
