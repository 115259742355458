import React, { Component } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import SummaryCard from "../../components/dashboard/SummaryCard";
import UpcomingBooking from "../../components/dashboard/UpcomingBooking";
import InformationCard from "../../components/dashboard/InformationCard";
import MainContainer from "../../components/MainContainer";

import DashboardFilter from "../../components/dashboard/DashboardFilter";

import Grid from "@material-ui/core/Grid";
import Heading from "../../components/Heading";

class Venue extends Component {
    handleFilterChange = value => {};

    render() {
        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Heading title="Dashboard">
                        <DashboardFilter onChange={this.handleFilterChange} />
                    </Heading>
                    <Grid container spacing={8} style={{ padding: 15 }}>
                        <Grid item md={3} xs={12} sm={12}>
                            <SummaryCard
                                headerContent="99"
                                headerCaption="Total Gig Requests"
                                content1="45"
                                caption1="Upcoming"
                                content2="54"
                                caption2="Completed"
                                primaryColor={true}
                            />
                        </Grid>
                        <Grid item md={3} xs={12} sm={12}>
                            <SummaryCard
                                headerContent="$39,969"
                                headerCaption="Total Gig Fees"
                                content1="$18,696"
                                caption1="Due to Pay"
                                content2="$21,373"
                                caption2="Paid"
                                primaryColor={false}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} sm={12}>
                            <Grid container spacing={8}>
                                <Grid item md={6} xs={12} sm={12}>
                                    <InformationCard mhIcon="mh-microphone" information="8" informationDetail="Outstanding gig requests" />
                                </Grid>
                                <Grid item md={6} xs={12} sm={12}>
                                    <InformationCard mhIcon="fa fa-building" information="87" informationDetail="Venues on your roster" />
                                </Grid>
                                <Grid item md={6} xs={12} sm={12}>
                                    <InformationCard mhIcon="mh-band" information="45" informationDetail="Acts on your roster" />
                                </Grid>
                                <Grid item md={6} xs={12} sm={12}>
                                    <InformationCard mhIcon="fa fa-envelope" information="9" informationDetail="New Messages" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <UpcomingBooking />
                        </Grid>
                    </Grid>
                </MainContainer>
            </div>
        );
    }
}

export default Venue;
