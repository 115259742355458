import React, { Component } from "react";
import "./Landing.css";
import withTheme from "@material-ui/core/styles/withTheme";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

@inject("userStore", "commonStore")
@withRouter
@observer
class Splash extends Component {
    render() {
        return <div className="Landing" />;
    }
}

export default withTheme()(Splash);
