import React, { Component, Children, cloneElement } from "react";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Grid, Typography, Hidden, withWidth } from "@material-ui/core";
import { inject, observer } from "mobx-react";

import CustomCalendarToolbar from "../../components/calendar/custom-toolbar";
import CustomEvent from "../../components/calendar/CustomEvent";
import CustomAgendaEvent from "../../components/calendar/CustomAgendaEvent";

import { Calendar, momentLocalizer  } from 'react-big-calendar' 
import "react-big-calendar/lib/css/react-big-calendar.css";

import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import BookingCalendarFilter from "../../components/booking/bookingCalendarFilter";
import BookingDialog from "../../components/booking/bookingDialog";
import EventDialog from "../../components/adhocBooking/EventDialog";
import Loading from "../../components/loadingSpinner/Loading";

import compose from "recompose/compose";

import { ProfileType } from "../../types/enum";

import moment from "moment";

import "./MyCalendar.css";
import PrivateBookingOptionModal from "../../components/adhocBooking/PrivateBookingOptionModal";

moment.locale("ko", {
    week: {
        dow: 1,
        doy: 1
    }
});

const localizer = momentLocalizer(moment)


// let allViews = Object.keys(Calendar.Views).map(k => Calendar.Views[k]);

const formatDate = 'YYYY-MM-DD';

const dayFormat =  {
    agendaHeaderFormat: ({
        start,
        end
      }, culture, local) =>
      local.format(start, 'DD/MM/YYYY', culture) + '-' +
      local.format(end, 'DD/MM/YYYY', culture)

  }
@inject(
    "generalProfileStore",
    "templateStore",
    "authStore",
    "bookingRequestStore",
    "profileStore",
    "eventStore",
    "userStore",
    "agentActStore",
    "venueActStore",
    "venueStore",
    "agentVenueStore",
    "googleCalendarStore"
)
@observer
class MyCalendar extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Calendar" }];

    constructor(props) {
        super(props);
        this.state = {
            openOptionModal: false,
            open: false,
            selectedAct: undefined,
            selectedVenue: undefined,
            events: [],
            openEventDialog: false,
            isEditEvent: false,
            isLoading: true,
            slotInformation: undefined,
            currentDate: new Date(),
            bookingDataEvent : [],
            isLoadingActVenue : false,
            startDate: moment().startOf('month').format(formatDate),
            endDate: moment().endOf('month').format(formatDate),
            selectedBookingStatus: 0
        };
        this.props.agentActStore.resetData();
        this.props.profileStore.resetAgentActList();
        this.props.venueStore.resetVenues();
        this.props.eventStore.resetEvents();
    }

    componentDidMount() {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.setState({ isLoading: true, isLoadingActVenue: true});
        this.props.agentActStore.resetAgentActs();   
        this.props.googleCalendarStore.resetGoogleCalendarEvent();     
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;
        Promise.all([this.props.bookingRequestStore.getMyCalendarBookings(null, startDate, endDate), this.getActList(), this.getVenueList()]).then(() => {
            this.setState({ isLoading: false });

            if (currentProfile.type === ProfileType.Musician.ordinal) {
                const { agentActList, currentProfile } = this.props.profileStore;
                let actIds = agentActList.map(act => act.id);
                actIds.push(currentProfile.id);
                this.props.eventStore.loadEventsByProfileIds(actIds).then(() => {});
                if(currentProfile.activateGoogleCalendar && currentProfile.googleRefreshToken){
                    this.props.googleCalendarStore.loadCalendarProfile(currentProfile.id,startDate, endDate)
                }
               
            } else if (currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Staff.ordinal) {
                const profile = this.props.profileStore.getCurrentProfile();
                const { agentActs } = this.props.agentActStore;
                let actIds = agentActs.map(act => act.id);
                actIds.push(profile.id);

                this.props.eventStore.loadEventsByProfileIds(actIds).then(() => {});
                if(currentProfile.activateGoogleCalendar && currentProfile.googleRefreshToken){
                    this.props.googleCalendarStore.loadCalendarProfile(currentProfile.id,startDate, endDate)
                }
                this.updateBreadCumbPriveledge("/");
            }
        });
    }

    updateBreadCumbPriveledge(linPriveledge){
        this.breadcrumb = [{ name: "Dashboard", link: linPriveledge }, { name: "Calendar" }];
    }

    componentWillUnmount() {
        this.props.bookingRequestStore.resetMyCalendarActBooking();
    }

    loadData(startDate,endDate, currentProfile){
        const selectedVenue = this.state.selectedVenue;
        if(selectedVenue && selectedVenue !== 0){
            Promise.all([this.props.bookingRequestStore.getMyCalendarBookings(null, startDate, endDate), this.props.eventStore.loadEventsByProfileId(selectedVenue)]).then(
                () => {
                    if(currentProfile.activateGoogleCalendar && currentProfile.googleRefreshToken){
                        this.props.googleCalendarStore.loadCalendarProfile(currentProfile.id,startDate, endDate)
                    }
                    this.setState({ isLoading: false });
                    this.handleSelectVenue(selectedVenue)
                }
            );
        } else {
            this.props.bookingRequestStore.getMyCalendarBookings(null, startDate, endDate).then(response => {
                if(currentProfile.type === ProfileType.Musician.ordinal){
                    const { agentActList, profile } = this.props.profileStore;
                    let actIds = agentActList.map(act => act.id);
                    actIds.push(profile.id);
    
                    if(currentProfile.activateGoogleCalendar && currentProfile.googleRefreshToken){
                        this.props.googleCalendarStore.loadCalendarProfile(currentProfile.id,startDate, endDate)
                    }
                    this.props.eventStore.loadEventsByProfileIds(actIds).then(() => {
                        this.setState({ isLoading: false });
                    });
                }
                else{
                    this.setState({ isLoading: false });
                    if(currentProfile.activateGoogleCalendar && currentProfile.googleRefreshToken){
                        this.props.googleCalendarStore.loadCalendarProfile(currentProfile.id,startDate, endDate)
                    }
                   
                   
                }
            });
        }
   
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClickEvent = event => {
        if (event.type === "booking") {
            this.props.bookingRequestStore.getDetailBookingCalendar(event.id, event.bookingRequestId).finally(() => {
                this.setState({ open: true, selectedBookingStatus: event.statusNumber });
            });
        } else if (event.type === "availability") {
            this.props.eventStore.reset();
            this.props.eventStore.loadEventById(event.id);
            this.handleOpenEventDialog();
        }
    };

    handleClickSlot = slotInformation => {
        const { currentProfile } = this.props.profileStore;
        if (currentProfile && currentProfile.type === ProfileType.Musician.ordinal) {
            this.props.profileStore.getUserTotalActList(currentProfile?.id)
        }

        if (
            this.props.profileStore.currentProfile.type === ProfileType.Musician.ordinal ||
            this.props.profileStore.currentProfile.type === ProfileType.Agent.ordinal ||
            this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal
        ) {
            this.setState({
                ...this.state,
                openOptionModal: true,
                slotInformation
            });
        }
    };

    handleClickPrivateBooking = () => {
        const { slotInformation, selectedAct, selectedVenue } = this.state;
        if (slotInformation && this.props.profileStore.currentProfile.type === ProfileType.Musician.ordinal || this.props.profileStore.currentProfile.type === ProfileType.Agent.ordinal) {
            this.props.history.push(
                `/privateBooking?date=${moment(slotInformation.start).format("DD-MM-YYYY")}&act=${selectedAct ? selectedAct : ""}`
            );
        } else if (slotInformation && this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal || this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal) {
            this.props.history.push(`/booking/new?actId=${selectedAct}&venueId=${selectedVenue}&dateStart=${moment(slotInformation.start).format("DD-MM-YYYY")}`);
        } else {
            this.props.history.push(`/privateBooking`);
        }
    };
    handleClickVenueBooking = () => {
        const { slotInformation, selectedAct, selectedVenue } = this.state;
        if (slotInformation && this.props.profileStore.currentProfile.type === ProfileType.Musician.ordinal ||
            this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal ||
            this.props.profileStore.currentProfile.type === ProfileType.Agent.ordinal) {
            this.props.history.push(`/booking/new?actId=${selectedAct}&venueId=${selectedVenue}&dateStart=${moment(slotInformation.start).format("DD-MM-YYYY")}`);
        } else {
            this.props.history.push(`/privateBooking`);
        }
    };

    handleClickPrivateEvent = () => {
        const { slotInformation } = this.state;
        if (slotInformation) {
            this.props.eventStore.reset();

            let start = slotInformation.start;
            let end = slotInformation.end;
            if (start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 0 && end.getMinutes() === 0) {
                this.props.eventStore.setAllDay(true);
            } else {
                this.props.eventStore.setAllDay(false);
            }

            this.props.eventStore.setFrom(slotInformation.start);
            this.props.eventStore.setTo(slotInformation.end);
            this.props.eventStore.setProfileId(this.props.profileStore.currentProfile.id);
            if(this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal && this.state.selectedVenue){
                this.props.eventStore.setProfileId(this.state.selectedVenue);
            }
            
            this.setState({
                ...this.state,
                openEventDialog: true,
                isEditEvent: false,
                openOptionModal: false,
                slotInformation: undefined
            });
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    renderLegends = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        let legendArrays = currentProfile.type === ProfileType.Musician.ordinal  ? [
            { label: "Draft", color: "#64b5f6" },
            { label: "New", color: "#1565c0" },
            { label: "Negotiate", color: "#ff0c9c" },
            { label: "In Progress", color: "#1b5e20" },
            { label: "Accepted", color: "#43a047" },
            { label: "Cancelled", color: "#000000" },
            { label: "Unavailable", color: "#696969" }
        ] : [
            { label: "Draft", color: "#64b5f6" },
            { label: "Sent", color: "#1565c0" },
            { label: "Negotiate", color: "#ff0c9c" },
            { label: "In Progress", color: "#1b5e20" },
            { label: "Accepted", color: "#43a047" },
            { label: "Cancelled", color: "#000000" },
            { label: "Unavailable", color: "#696969" }
        ]

        if (
            currentProfile.type === ProfileType.Agent.ordinal ||
            currentProfile.type === ProfileType.Staff.ordinal ||
            currentProfile.type === ProfileType.Venue.ordinal
        ) {
            legendArrays.push({ label: "Declined", color: "#b71c1c" });
            legendArrays.push({ label: "Resent", color: "#b19cd9" });
        }

        if (
            currentProfile.type === ProfileType.Musician.ordinal || 
            currentProfile.type === ProfileType.Staff.ordinal ||
            currentProfile.type === ProfileType.Agent.ordinal
        ) {
            legendArrays.push({ label: "Completed", color: "#bdbdbd" })
        }

        return legendArrays.map(x => (
            <Grid key={x.label} item xs={3} lg={1}>
                <Typography variant="caption">
                    <span style={{ backgroundColor: x.color, width: "15px", height: "15px", display: "inline-block", borderRadius: "10px" }}>&nbsp;</span> <span style={{ color: "white" }}> {x.label}</span>
                </Typography>
            </Grid>
        ));
    };

    getActList = async () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if (currentProfile.type === ProfileType.Musician.ordinal) {
            await this.props.profileStore.loadAgentActList();
            return 
        } else if (currentProfile.type === ProfileType.Agent.ordinal && !currentProfile.isConsultant) {
            await this.props.agentActStore.getAgentActRosterSimple(currentProfile.id);
            await this.props.agentActStore.getAgentMyActs(currentProfile.id);
            return 
        } else if (currentProfile.type === ProfileType.Agent.ordinal && currentProfile.isConsultant) {
            await this.props.agentActStore.getAgentActRoster(currentProfile.id);
            return 
        } else if (currentProfile.type === ProfileType.Staff.ordinal) {
            await this.props.venueActStore.getStaffActRosterInvoice(null, true);
            return 
        }

        return Promise.resolve();
    };

    getVenueList = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if (currentProfile.type === ProfileType.Agent.ordinal && !currentProfile.isConsultant) {
            return this.props.agentVenueStore.getAgentVenueSimple(currentProfile.id);
        } else if (currentProfile.type === ProfileType.Agent.ordinal && currentProfile.isConsultant) {
            return this.props.agentVenueStore.getConsultantVenueRoster(currentProfile.id);
        } else if (currentProfile.type === ProfileType.Staff.ordinal) {
            return this.props.venueStore.getUserVenuesWithNoImage();
        }
        return Promise.resolve();
    };

    handleSelectVenue = value => {
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;

        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.setState({
            ...this.state,
            selectedVenue: value,
            // isLoading: true
        });
        if(currentProfile?.type === ProfileType.Staff.ordinal){
            if(value > 0){
                this.props.googleCalendarStore.loadCalendarProfileVenue(value,startDate, endDate).then(response => {

                })
                this.props.eventStore.loadEventsByProfileIds([value]).then(() => {
                });
            }else{
                this.props.googleCalendarStore.resetGoogleCalendarEvent()
                this.props.eventStore.loadEventsByProfileIds(currentProfile.id).then(() => {});
            }
           
        }
        // if (!value || value === 0) {
        //     this.getActList().then(() => {
        //         this.setState({ isLoading: false });
        //     });
        // } else {
        //     if (currentProfile.type === ProfileType.Staff.ordinal) {
        //         this.props.venueActStore.getVenueActRoster(value).then(() => {
        //             this.setState({ isLoading: false });
        //         });
        //     } else {
        //         this.setState({ isLoading: false });
        //     }
        // }

        // if(value && value !== 0){
        //     Promise.all([this.props.bookingRequestStore.getMyCalendarBookings(value, startDate, endDate), this.props.eventStore.loadEventsByProfileId(value)]).then(
        //         () => {
        //             this.setState({ isLoading: false });
        //         }
        //     );
        // } else {
        //     this.props.bookingRequestStore.getMyCalendarBookings(null, startDate, endDate).then(response => {
        //         if(currentProfile.type === ProfileType.Musician.ordinal){
        //             const { agentActList, profile } = this.props.profileStore;
        //             let actIds = agentActList.map(act => act.id);
        //             actIds.push(profile.id);
    
        //             this.props.eventStore.loadEventsByProfileIds(actIds).then(() => {
        //                 this.setState({ isLoading: false });
        //             });
        //         }
        //         else{
        //             this.setState({ isLoading: false });
        //         }
        //     });
        // }
    };

    handleSelectAct = value => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const { startDate, endDate } = this.state;
        this.setState({
            ...this.state,
            selectedAct: value
        });

        if(currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Staff.ordinal){
            this.props.bookingRequestStore.loadMyBookingRelatedAct(value,startDate, endDate)
        }
    };

    handleOpenEventDialog = () => {
        this.setState({ openEventDialog: true, isEditEvent: true });
    };

    handleCloseEventDialog = () => {
        this.setState({ openEventDialog: false });
    };

    mapVenueSuggestions = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        var venueSuggestions = [];
        if (currentProfile.type === ProfileType.Staff.ordinal) {
            const { venues } = this.props.venueStore;
            venueSuggestions = venues.filter(x=>x.venue!==null).map(venue => {
                return {
                    value: venue.id,
                    label: venue.profileName
                };
            });
        } else if (currentProfile.type === ProfileType.Agent.ordinal && !currentProfile.isConsultant) {
            const { agentVenues } = this.props.agentVenueStore;

            venueSuggestions = agentVenues.filter(x=>x.venue!==null).map(agentVenue => {
                return {
                    value: agentVenue.venue.id,
                    label: agentVenue.venue.profileName
                };
            });
        } else if (currentProfile.type === ProfileType.Agent.ordinal && currentProfile.isConsultant) {
            const { agentVenues } = this.props.agentVenueStore;

            venueSuggestions = agentVenues.filter(x=>x.venue!==null).map(agentVenue => {
                return {
                    value: agentVenue.venue.id,
                    label: agentVenue.venue.profileName
                };
            });
        }

        if (venueSuggestions.length > 0) {
            venueSuggestions.unshift({
                value: 0,
                label: "All Venues"
            });
        }

        return venueSuggestions;
    };

    mapActSuggestions = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        let suggestAct = [];
        if (currentProfile.type === ProfileType.Staff.ordinal) {
            const { venueActRoster } = this.props.venueActStore;
            suggestAct = venueActRoster.filter(x => x.act.isCompleted).map(e => {
                return {
                    value: e.act.id,
                    label: e.act.actName
                };
            });
        } else if (currentProfile.type === ProfileType.Agent.ordinal) {
            const { agentActs } = this.props.agentActStore;
            suggestAct = agentActs.filter(x => x.act.isCompleted).map(e => {
                return {
                    value: e.act.id,
                    label: e.act.actName
                };
            });
        } else if (currentProfile.type === ProfileType.Musician.ordinal) {
            const { agentActList } = this.props.profileStore;
            suggestAct = agentActList.filter(x => x.accepted && x.isCompleted).map(e => {
                return {
                    value: e.id,
                    label: e.actName
                };
            });
        }

        suggestAct = _.uniqBy(suggestAct, "value")

        suggestAct.unshift({
            value: 0,
            label: "All Acts"
        });

        suggestAct = _.uniqBy(suggestAct, "value")

        return suggestAct;
    };

    handleCloseOptionModal = () => {
        this.setState({
            ...this.state,
            openOptionModal: false,
            slotInformation: undefined
        });
    };

    getBookingData = (startDate,endDate) => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.loadData(startDate,endDate,currentProfile)
    }

    customDayPropGetter = date => {
        if (this.checkDisabledDate(date)) {
            return {
                className: "disabled-date"
            };
        } else {
            return {};
        }
    };
    handleChangeView(){
        this.setState({defaultView: 'agenda'})
    }
    checkDisabledDate = date => {
        let { selectedVenue } = this.state;

        let days = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        if (selectedVenue) {
            var agentVenue = this.props.agentVenueStore.agentVenues.filter(agentVenue => agentVenue.venueId === selectedVenue)[0];
            if (agentVenue && !agentVenue[days[moment(date).isoWeekday()]]) {
                return true;
            }
        }

        if (moment(date) < moment().startOf("day")) {
            return true;
        }
        return false;
    };
    
    handleNavigate = (newDate, view, action) => {
        const typeView = view !== 'agenda' ? view  : 'month';
        const dateMoment = moment(newDate);
        const currentMoment = moment()
        var startDate = dateMoment.startOf(typeView).format(formatDate);
        var endDate   = dateMoment.endOf(typeView).format(formatDate);
        if(view === "agenda"){
            var month = dateMoment.format('M');
            var year = dateMoment.format('Y');
            currentMoment.set('month', month-1);
            currentMoment.set('year', year);
            currentMoment.set('date', 1);
            startDate = currentMoment.format(formatDate);
            endDate   = currentMoment.add(2, "month").endOf("day").format(formatDate); 
        }
        this.setState({
            ...this.state,
            currentDate: newDate,
            startDate: dateMoment.startOf(typeView).format(formatDate),
            endDate: dateMoment.endOf(typeView).format(formatDate),
            isLoading: true
        });

        // get agenda data
        this.getBookingData(startDate,endDate);
    }

    handleView = (view) => {
        // only handle agenda view
        
        const typeView = view !== 'agenda' ? view  : 'month';
        // agenda view showing all agenda in current date of month
        const dateMoment = moment(this.state.currentDate);
        let startDate = dateMoment.startOf(typeView).format(formatDate);
        let endDate   = dateMoment.endOf(typeView).format(formatDate);
        
        if(view === "agenda"){
            startDate = moment().format(formatDate);
            endDate   = moment().add(1, "month").endOf("day").format(formatDate); 
        }
        this.setState({
            ...this.state,
            startDate: startDate,
            endDate: endDate,
            isLoading: true
        });
        // get agenda data
        this.getBookingData(startDate, endDate);
    }
    render() {
   
        let components = {
            toolbar: toolbar => <CustomCalendarToolbar toolbar={toolbar} />,
            event: event => <CustomEvent data={event} />,
            agenda: {
                event: event => <CustomAgendaEvent data={event} clickHandler={() => this.handleClickEvent(event.event)} />
            }
        };
        const { selectedAct, selectedVenue, slotInformation } = this.state;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const {actListTotal} = this.props.profileStore;
        const type = currentProfile && currentProfile.type ? currentProfile.type : 1;
        const { myCalendarActBooking,relatedBookings} = this.props.bookingRequestStore;
        const { events } = this.props.eventStore;
        const { googleCalendarEvent,googleCalendarEventVenue } = this.props.googleCalendarStore

        let bookingEvents = this.props.bookingRequestStore.generateBookings(
            myCalendarActBooking.filter(x => {
                if (selectedAct && selectedAct !== 0 && selectedVenue && selectedVenue !== 0) {
                    return x.actId === selectedAct && x.venueId === selectedVenue;
                } else if (selectedAct && selectedAct !== 0) {
                    return x.actId === selectedAct;
                } else if (selectedVenue && selectedVenue !== 0) {
                    return x.venueId === selectedVenue;
                }
                return true;
            })
        );

        let filteredEvents = events.filter(x => {
            return true;
        });
        for (let i = 0; i < filteredEvents.length; i++) {
            bookingEvents.push(events[i]);
        }
        for (let i = 0; i < googleCalendarEvent.length; i++) {
            bookingEvents.push(googleCalendarEvent[i]);
        }
        
        for (let i = 0; i < relatedBookings.length; i++) {
            bookingEvents.push(relatedBookings[i]);
        }

        for (let i = 0; i < googleCalendarEventVenue.length; i++) {
            bookingEvents.push(googleCalendarEventVenue[i]);
        }
        const { isLoading } = this.state;

        return (
            <main>
                <Header userRole="agent" />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title="Calendar" bottomAction={this.props.width === "xs" || this.props.width === "sm"}>
                        <div
                            style={{
                                minWidth: "150px",
                                color: "#FFFFFF !important",
                                width: this.props.width === "xs" || this.props.width === "sm" ? "100%" : undefined,
                                float: 'right'
                            }}
                        >
                            {currentProfile.type === ProfileType.Staff.ordinal || currentProfile.type === ProfileType.Agent.ordinal ? (
                                <Grid container>
                                    <Grid item xs={6}>
                                        <BookingCalendarFilter
                                            id="bookingVenue"
                                            suggestions={this.mapVenueSuggestions()}
                                            onChange={this.handleSelectVenue}
                                            value={selectedVenue}
                                            placeholder="All Venues"
                                            fullWidth={this.props.width === "xs" || this.props.width === "sm"}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <BookingCalendarFilter
                                            id="bookingAct"
                                            suggestions={this.mapActSuggestions()}
                                            onChange={this.handleSelectAct}
                                            value={selectedAct}
                                            placeholder="All Acts"
                                            fullWidth={this.props.width === "xs" || this.props.width === "sm"}
                                        />
                                    </Grid>
                                </Grid>
                            ) : currentProfile.type === ProfileType.Musician.ordinal ? (
                                <BookingCalendarFilter
                                    id="bookingAct"
                                    suggestions={this.mapActSuggestions()}
                                    onChange={this.handleSelectAct}
                                    value={selectedAct}
                                    placeholder="All Acts"
                                    fullWidth={this.props.width === "xs" || this.props.width === "sm"}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </Heading>
                    <Grid container spacing={8} style={{ position: "relative", height: "100% !important" }}>
                        <Loading showed={isLoading} />

                        <Grid item xs={12}>
                            <Hidden mdUp>
                                <Grid container alignItems="center" style={{ paddingLeft: 4 }}>
                                    {this.renderLegends()}
                                </Grid>
                            </Hidden>
                            <Hidden smDown>
                                <Grid container justify="flex-end" alignItems="center">
                                    {this.renderLegends()}
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} lg={12} style={{ position: "relative", height: "100% !important" }}>
                            <Calendar
                                formats={dayFormat}
                                localizer={localizer}
                                selectable={true}
                                events={bookingEvents && bookingEvents.length > 0 ? bookingEvents : []}
                                // views={allViews}
                                // views={["month, week, day, agenda"]}
                                step={30}
                                defaultDate={new Date()}
                                className="my-calendar"
                                components={components}
                                longPressThreshold={10}
                                onSelectEvent={this.handleClickEvent}
                                onSelectSlot={this.handleClickSlot}
                                showMultiDayTimes
                                popup
                                style={{ minHeight: 800 }}
                                onNavigate={this.handleNavigate}
                                onView={this.handleView}
                                dayPropGetter={this.customDayPropGetter}
                            />
                        </Grid>
                    </Grid>
                </MainContainer>
                <BookingDialog open={this.state.open} handleClose={this.handleClose} hidepanel={true} statusBooking={this.state.selectedBookingStatus}/>
                <EventDialog openDialog={this.state.openEventDialog} handleCloseDialog={this.handleCloseEventDialog} isEdit={true} />
                <PrivateBookingOptionModal
                    open={this.state.openOptionModal}
                    onClose={this.handleCloseOptionModal}
                    privateEventHandler={()=> this.handleClickPrivateEvent()}
                    privateBookingHandler={()=> this.handleClickPrivateBooking()}
                    handleClickVenueBooking={() => this.handleClickVenueBooking()}
                    type={type}
                    hidePrivateBooking={(slotInformation && moment(slotInformation.start).endOf("day") < moment().startOf("day")) || !slotInformation}
                    hideActBooking={actListTotal > 0 ? false : true}
                />
            </main>
        );
    }
}
export default compose(withWidth())(MyCalendar);
