import React, { Component } from "react";
import ComingSoon from "./ComingSoon";

class Applesitehosting extends Component {
    render() {
        return <ComingSoon title="Apple Hosting" />;
    }
}

export default Applesitehosting;
