import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ClearIcon from "@material-ui/icons/Clear";
import Chip from "@material-ui/core/Chip";
import Select from "react-select";
import "react-select/dist/react-select.css";

class Option extends React.Component {
    handleClick = event => {
        this.props.onSelect(this.props.option, event);
    };

    render() {
        const { children, isFocused, isSelected, onFocus } = this.props;

        return (
            <MenuItem
                onFocus={onFocus}
                selected={isFocused}
                onClick={this.handleClick}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400
                }}
            >
                {children}
            </MenuItem>
        );
    }
}

function SelectWrapped(props) {
    const { classes, ...other } = props;

    return (
        <Select
            optionComponent={Option}
            noResultsText={<Typography>{"No results found"}</Typography>}
            arrowRenderer={arrowProps => {
                return arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
            }}
            clearRenderer={() => <ClearIcon />}
            valueComponent={valueProps => {
                const { value, children, onRemove } = valueProps;

                const onDelete = event => {
                    event.preventDefault();
                    event.stopPropagation();
                    onRemove(value);
                };

                if (onRemove) {
                    return (
                        <Chip
                            tabIndex={-1}
                            label={children}
                            className={classes.chip}
                            deleteIcon={<CancelIcon onTouchEnd={onDelete} />}
                            onDelete={onDelete}
                        />
                    );
                }

                return (
                    <div className="Select-value">
                        <Typography style={{color:"white"}}  variant="body1" noWrap>
                            {" "}
                            {children}{" "}
                        </Typography>
                    </div>
                );
            }}
            {...other}
        />
    );
}

const ITEM_HEIGHT = 48;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: "auto",
        // margintop: 10,
        // paddingRight: "8px",
        // paddingLeft: "8px",
        // paddingBottom: "2px",
        display: "flex"
    },
    chip: {
        margin: theme.spacing.unit / 4,
        position: "relative",
        backgroundColor: "#ff0c9c",
        borderRadius: "4px"
    },
    // We had to use a lot of global selectors in order to style react-select.
    // We are waiting on https://github.com/JedWatson/react-select/issues/1679
    // to provide a better implementation.
    // Also, we had to reset the default style injected by the library.
    "@global": {
        ".Select--multi": {
            height: "auto !important",
            paddingTop: "4px !important",
            width: "auto !important"
        },
        ".Select-control": {
            display: "flex",
            alignItems: "center",
            border: 0,
            height: "auto",
            background: "transparent",
            "&:hover": {
                boxShadow: "none"
            },
            flexGrow: 100,
            width: "auto !important"
        },
        ".MuiTypography-colorPrimary-277": {
            color: "white !important"
        },
        ".Select-multi-value-wrapper": {
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
            width: "auto !important",
            marginRight: "48px"
        },
        ".Select--multi .Select-input": {
            margin: 0,
            width: "auto !important"
        },
        ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0,
            color: "#ff0c9c !important",
            width: "auto !important",
            maxWidth: "220px"
        },
        ".Select-noresults": {
            padding: theme.spacing.unit * 2,
            width: "auto !important"
        },
        ".Select-input": {
            display: "inline-flex !important",
            padding: 0,
            height: "auto",
            color: "white",
            width: "auto !important"
        },
        ".Select-input input": {
            background: "transparent",
            border: 0,
            padding: 0,
            cursor: "default",
            display: "inline-block",
            fontFamily: "inherit",
            fontSize: "inherit",
            margin: 0,
            outline: 0,
            width: "auto !important",
            maxWidth: "auto !important"
        },
        ".Select-placeholder, .Select--single .Select-value": {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            padding: 0,
            color: "#ff0c9c !important",
            width: "auto !important",
            maxWidth: "auto !important"
        },
        ".Select-placeholder": {
            // opacity: 0.7,
            color: "white !important",
            width: "auto !important"
        },
        ".Select-menu-outer": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            position: "absolute",
            left: 0,
            top: `calc(100% + ${theme.spacing.unit}px)`,
            width: "100%",
            zIndex: "99999 !important",
            maxHeight: ITEM_HEIGHT * 4.5
        },
        ".Select.is-focused > .Select-control": {
            boxShadow: "none",
            background: "transparent !important"
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            boxShadow: "none",
            background: "transparent !important"
        },
        ".Select-menu": {
            maxHeight: ITEM_HEIGHT * 4.5,
            overflowY: "auto"
        },
        ".Select-menu div": {
            boxSizing: "content-box"
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            color: "white",
            cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1
        },
        // Only for screen readers. We can't use display none.
        ".Select-aria-only": {
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: 1,
            width: 1,
            margin: -1
        },
        ".Select--single": {
            height: "19px !important",
            maxHeight: "19px !important",
            overflow: "none",
            width: "auto !important",
            backgroundColor: "#ff0c9c"
        },
        ".input-underline:before": {
            left: 0,
            right: 0,
            bottom: 0,
            height: "1px",
            content: "",
            position: "absolute",
            transition: "background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            pointerEvents: "none",
            backgroundColor: "#ff0c9c"
        }
    }
});

class BookingCalendarFilter extends Component {
    render() {
        const { classes, placeholder, value, suggestions } = this.props;
        return (
            <div className={classes.root} id={this.props.id}>
                <Input
                    className="slim-dark-input"
                    inputComponent={SelectWrapped}                    
                    value={value}
                    onChange={this.props.onChange}
                    placeholder={placeholder}
                    id={this.props.inputId || "react-select-single"}
                    margin="none"
                    classes={{
                        underline: "input-underline"
                    }}
                    style={{color:"white"}}
                    inputProps={{
                        classes,
                        name: "react-select-single",
                        instanceId: "react-select-single",
                        simpleValue: true,
                        options: suggestions
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(BookingCalendarFilter);
