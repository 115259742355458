import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { ProfileType } from "../types/enum";
import { Typography, Grid, Card, CardContent } from "@material-ui/core";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import MainContainer from "../components/MainContainer";

import Breadcrumb from "../components/Breadcrumb";
import Heading from "../components/Heading";

@inject("profileStore")
@observer
class ComingSoon extends Component {
    breadcrumb = [
        {
            name: "Dashboard",
            link:
                this.props.profileStore.currentProfile.type === ProfileType.Agent.ordinal
                    ? "/agent"
                    : this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal
                        ? "/staff"
                        : this.props.profileStore.currentProfile.type === ProfileType.Musician.ordinal
                            ? "/musician"
                            : this.props.profileStore.currentProfile.type === ProfileType.Dj.ordinal
                                ? "/dj"
                                : ""
        },
        { name: this.props.title }
    ];

    render() {
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title={this.props.title} />
                    <Card>
                        <CardContent>
                            <Grid container spacing={16}>
                                <Grid item xs={12} style={{ marginTop: 30 }}>
                                    <Typography variant="display1" align="center">
                                        Coming Soon!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </MainContainer>
            </Fragment>
        );
    }
}

export default ComingSoon;
