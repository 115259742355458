import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import {
    Grid,
    Button,
    Card,
    CardHeader,
    CardContent,
    Avatar
} from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";


import ModalContainer from "../../modal/ModalContainer";
import ResponsiveTable from "../../table/ResponsiveTable";
import TableRowProfile from "../../util/TableRowProfile";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import image from "../../../helper/image";
import RichTextEditor from "../../richTextEditor/RichTextEditor";
import { ProfileRelationType, ProfileType } from "../../../types/enum";

const styles = theme => ({
    mt15: {
        marginTop: 15
    }
});

@withRouter
@inject("profileStore", "venueActStore", "templateStore", "agentActStore", "userInvitationStore")
@observer
class ApplyToPlayModal extends Component {

    actColumns = [
        { key: "act", label: "Act Name" },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedActs: undefined,
            invitationMessage: null,
            isLoading: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open) {
            this.setState({...this.state, isLoading: true})
            const { currentProfile } = this.props.profileStore;
            const { profile } = this.props;
            const relation = profile.type === ProfileType.Venue.ordinal ? ProfileRelationType.VenueAct.ordinal : ProfileRelationType.AgentAct.ordinal;
            Promise.all([
                this.props.profileStore.getUserActList(currentProfile.id),
                this.props.agentActStore.getMusicianAgents(currentProfile.id),
                this.props.venueActStore.getMusicianVenueActRoster(),
                this.props.userInvitationStore.getMyUserInvitation(relation, currentProfile.id)
            ]).then(() => {
                this.setState({...this.state, isLoading: false})
            })
        }
    }

    handleInvitationMessageChange = value => this.setState({ ...this.state, invitationMessage: value });

    getActRows = () => {
        const { actList, currentProfile } = this.props.profileStore;
        const { selectedActs } = this.state;
        const { agentActs } = this.props.agentActStore;
        const { venueActRoster } = this.props.venueActStore;
        const { profile } = this.props;
        const { invitations } = this.props.userInvitationStore;
        let actRoster = []
        if(agentActs && agentActs.length > 0 && profile.type === ProfileType.Agent.ordinal) {
            for(let i = 0; i < agentActs.length; i++) {
                if(agentActs[i].agent == profile.id) {
                    if(agentActs[i].data && agentActs[i].data.length > 0) {
                        for(let j = 0; j < agentActs[i].data.length; j ++) {
                            actRoster.push(agentActs[i].data[j])
                        }
                    }
                }
            }
        }

        if(venueActRoster && venueActRoster.length > 0 && profile.type === ProfileType.Venue.ordinal) {
            for(let i = 0; i < venueActRoster.length; i++) {
                if(venueActRoster[i].venue == profile.id){
                    for(let j = 0; j < venueActRoster[i].data.length; j ++) {
                        actRoster.push(venueActRoster[i].data[j])
                    }
                }
            }
        }

        function checkDisable (act) {
            if (actRoster?.length > 0) {
                if (actRoster.find(item => item.actId == act.id)) return true
            }
            if (profile.type === ProfileType.Venue.ordinal) {
                if (invitations.length > 0) {
                    let venueInvitaions = invitations.filter(inv => inv.invitedVenue == profile.id)
                    if (venueInvitaions.find(item => item.additionalData == act.id)) return true
                }
            } else {
                if (invitations.length > 0) {
                    let agentInvitations = invitations.filter(inv => inv.invitedAgency == profile.id)
                    if (agentInvitations.find(item => item.additionalData == act.id)) return true
                }
            }
            return false
        }

        return actList.filter(item => item.userId === currentProfile.userId).map(act => {
            return {
                act: (
                    <TableRowProfile
                        profileId={act.id}
                        avatarUrl={image.getProfileImage(act)}
                        name={act.actName} />
                ),
                id: act.id,
                checked: (selectedActs) ? selectedActs.find(item => item.id === act.id) : false,
                disableCheckbox: checkDisable(act)
            }
        })
    }

    getInvitedActs = rows => this.setState({ ...this.state, selectedActs: rows });

    handleInvite = () => {
        const { selectedActs, invitationMessage } = this.state;
        const { profile } = this.props;

        this.props.venueActStore.setInvitationMessage(invitationMessage)
        this.props.venueActStore.setInvitedActs(selectedActs)
        this.props.venueActStore.setActiveVenueId(profile.id);
        this.props.venueActStore.setDestinationProfile(profile);
        this.setState({ ...this.state, isLoading: true });
        this.props.venueActStore.inviteMany().then(() => {
            this.setState({ ...this.state, 
                isLoading: false
            });

            this.props.templateStore.openSnackbar(`${profile.type === ProfileType.Venue.ordinal ? "Venue" : "Agent"} Invited`);
            this.props.onClose();
        });
        this.handleClear();
    };

    handleClear = () => this.props.venueActStore.clearInviteData();

    

    render() {
        const {
            profileName, profileImage, open, profile
        } = this.props;
        const { currentProfile } = this.props.profileStore;
        const { selectedActs, invitationMessage, isLoading } = this.state;
        const { classes } = this.props;
        const title = `Apply to ${profile.type === ProfileType.Agent.ordinal ? "Act Roster" : "Play"}`

        return (
            <ModalContainer title={title} open={open} onClose={this.props.onClose}>
                <LoadingSpinner showed={isLoading} />
                <DialogContent>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <InputLabel>Invited {profile.type === ProfileType.Venue.ordinal ? "Venue" : "Agent"}</InputLabel>
                            <Card>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            alt={profileName}
                                            src={profileImage}
                                            style={{
                                                width: 40,
                                                height: 40
                                            }}
                                        />
                                    }
                                    title={profileName}
                                />
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputLabel>Invite Act Owner</InputLabel>
                            <Card>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            alt={currentProfile.fullName}
                                            src={image.getProfileImage(currentProfile)}
                                            style={{
                                                width: 40,
                                                height: 40
                                            }}
                                        />
                                    }
                                    title={currentProfile.fullName}
                                />
                                <CardContent>
                                    <p>Please select any of your available act.</p>
                                    <ResponsiveTable
                                        selectable={true}
                                        rows={this.getActRows()}
                                        columns={this.actColumns}
                                        getCheckedRows={this.getInvitedActs}
                                        pagination={this.getActRows().length > 5}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.mt15}>
                            <InputLabel>{currentProfile.type === ProfileType.Musician.ordinal ? "" : "Invitation "}Message</InputLabel>
                            <RichTextEditor value={invitationMessage} onChange={this.handleInvitationMessageChange} />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={this.props.onClose}>Close</Button>
                        <Button disabled={!selectedActs} id="invite-venue-to-my-venue-submit" color="primary" onClick={this.handleInvite}>
                            {currentProfile.type === ProfileType.Musician.ordinal ? "Apply" : "Invite"}
                        </Button>
                    </DialogActions>
                </DialogContent>
                
            </ModalContainer > 
        )
    }
}

export default withStyles(styles)(ApplyToPlayModal);