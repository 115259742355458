import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import ModalContainer from "../../modal/ModalContainer";
import { Button, Grid, Avatar, Typography } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ResponsiveTable from "../../table/ResponsiveTable";
import image from "../../../helper/image";

import { ProfileType, BookingStatusType } from "../../../types/enum";
import moment from "moment";
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

@inject("agentActStore", "bookingRequestStore", "templateStore", "venueActStore", "profileStore", "agentMusicianStore")
@observer
class BookingChangeActModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            selectedRow: [],
            booking: undefined,
            isLoading: true,
            availableActs: undefined
        };
    }

    componentDidMount() {
        const { currentProfile } = this.props.profileStore;

        if (this.props.booking) {
            if (currentProfile.type === ProfileType.Agent.ordinal) {
                this.props.agentActStore.getAgentAvailableActs(this.props.booking).then(response => {
                    this.mapToRowData(response);
                    this.setState({
                        ...this.state,
                        booking: this.props.booking,
                        isLoading: false,
                        availableActs: response
                    });
                });
            } else if (currentProfile.type === ProfileType.Staff.ordinal) {
                this.props.venueActStore.getStaffAvailableActs(this.props.booking).then(response => {
                    this.mapToRowData(response);
                    this.setState({
                        ...this.state,
                        booking: this.props.booking,
                        isLoading: false,
                        availableActs: response
                    });
                });
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { currentProfile } = this.props.profileStore;
        if (this.props.booking !== nextProps.booking) {
            this.props.agentActStore.resetData();
            this.props.venueActStore.resetList();

            this.setState(
                {
                    ...this.state,
                    booking: nextProps.booking,
                    isLoading: true,
                    selectedRow: []
                },
                () => {
                    if (currentProfile.type === ProfileType.Agent.ordinal) {
                        this.props.agentActStore.getAgentAvailableActs(nextProps.booking).then(response => {
                            this.mapToRowData(response);
                            this.setState({
                                ...this.state,
                                isLoading: false,
                                availableActs: response
                            });
                        });
                    } else if (currentProfile.type === ProfileType.Staff.ordinal) {
                        this.props.venueActStore.getStaffAvailableActs(nextProps.booking).then(response => {
                            this.mapToRowData(response);
                            this.setState({
                                ...this.state,
                                isLoading: false,
                                availableActs: response
                            });
                        });
                    }
                }
            );
        }
    }

    columns = [{ key: "act", label: "Act Name" }, { key: "lineUpCount", label: "Act Type" }];

    mapToRowData = rows => {
        var rowData = [];
        const { booking } = this.props;
        const { selectedRow } = this.state;
        rows.forEach(row => {
            let data = {};
            data.actData = row;

            data.id = row.actId;

            // data.disableCheckbox =
            //     booking.status === BookingStatusType.Declined.ordinal &&
            //     !booking.cancelledBy &&
            //     selectedRow.length > 0 &&
            //     selectedRow[0].actData.id !== row.id;

            // data.disableCheckbox =
            //     // booking.status === BookingStatusType.Declined.ordinal &&
            //     selectedRow.length > 0 &&
            //     selectedRow[0].actData.actId !== row.actId;

            data.act = (
                <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Avatar
                            alt={row.act.fullName}
                            src={image.getProfileImage(row.act)}
                            style={{
                                width: 40,
                                height: 40
                            }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subheading" noWrap>
                            {row.act.fullName}
                        </Typography>
                    </Grid>
                </Grid>
            );

            data.lineUpCount = this.props.agentActStore.getLineUpCounts(row.act.lineUpCount);

            rowData.push(data);
        });

        this.setState({
            ...this.state,
            rowData: rowData
        });
    };

    getCheckedRows = rows => {
        const { availableActs } = this.state;
        this.setState(
            {
                ...this.state,
                selectedRow: rows
            },
            () => {
                this.mapToRowData(availableActs);
            }
        );
    };

    handleSendRequest = () => {
        var acts = this.state.selectedRow.map(row => {
            return row.actData.act;
        });

        const { booking } = this.props;

        this.props.templateStore.showLoadingScreen();

        var bookingTitle = ""
        var titleArray = booking.title.split("@");
        if(titleArray.length == 2){
            bookingTitle = `@${titleArray[1]}`;
            this.props.bookingRequestStore.setBooking("title", bookingTitle);
            // this.state.booking.title = bookingTitle;
        }
        if (this.props.booking && this.props.booking.status === BookingStatusType.Declined.ordinal && !booking.cancelledBy) {
            this.props.bookingRequestStore.initializeEditBooking(this.props.booking.id).then(() => {
                this.props.bookingRequestStore.setBooking("actId", acts[0].id);
                this.props.bookingRequestStore.saveBooking().then(() => {
                    var bookingTitle = "";

                    if (this.state.booking.title && this.state.booking.title !== "") {
                        bookingTitle = this.state.booking.title;
                    } else {
                        bookingTitle = `${this.state.booking.act.actName}@${this.state.booking.venue.profileName}`;
                    }
                    this.props.templateStore.hideLoadingScreen();
                    this.props.templateStore.openSnackbar(`Change act request for ${bookingTitle} has been sent.`);

                    this.setState(
                        {
                            ...this.state,
                            booking: undefined
                        },
                        () => {
                            this.props.onClose();
                        }
                    );
                });
            });
        } else {
            this.props.bookingRequestStore.sendChangeActRequest(acts, this.state.booking,bookingTitle).then(res => {
                var bookingTitle = "";

                if (this.state.booking.title && this.state.booking.title !== "") {
                    bookingTitle = this.state.booking.title;
                } else {
                    bookingTitle = `${this.state.booking.act.actName}@${this.state.booking.venue.profileName}`;
                }
                this.props.templateStore.hideLoadingScreen();
                this.props.templateStore.openSnackbar(`Change act request for ${bookingTitle} has been sent.`);

                this.setState(
                    {
                        ...this.state,
                        booking: undefined
                    },
                    () => {
                        this.props.onClose();
                    }
                );
            });
        }
    };

    getBookingTitle = () => {
        const { booking } = this.props;

        let title = "Resend Booking Request";

        if (booking) {
            title += " -";
            title += ` ${moment(booking.dateStart).format("DD-MM-YYYY")}`;
            title += ` ${moment(booking.timeStart, "HH:mm").format("HH:mm")}`;
            title += " -";
            title += ` ${moment(booking.timeEnd, "HH:mm").format("HH:mm")}`;
        }

        return title;
    };

    handleClose = () => {
        this.setState(
            {
                rowData: [],
                selectedRow: [],
                booking: undefined,
                isLoading: true,
                availableActs: undefined
            },
            () => {
                this.props.onCloseModel();
            }
        );
    };

    render() {
        const { booking } = this.props;
        return (
            <ModalContainer
                title={this.getBookingTitle()}
                open={this.props.open}
                onClose={this.handleClose}
                maxWidth="md"
                fullScreen={false}
                isLoading={this.state.isLoading}
            >
                <DialogContent>
                    <ResponsiveTable
                        columns={this.columns}
                        rows={this.state.rowData}
                        selectable={true}
                        getCheckedRows={this.getCheckedRows}
                        showToolbar={true}
                        title="Available Act"
                        // hideSelectAll={this.props.booking && this.props.booking.status === BookingStatusType.Declined.ordinal}
                        hideSelectAll={false}
                    />
                    <br />
                    <Typography variant="caption">
                        {booking && booking.act
                            ? `Note: Acts on the table above is selected from your act roster with same line up count as previous act ( ${this.props.agentMusicianStore.getLineUpCounts(
                                booking.act.lineUpCount
                            )} ) and available to perform at ${moment(booking.dateStart).format("DD-MM-YYYY")}, ${moment(
                                booking.timeStart,
                                "HH:mm"
                            ).format("HH:mm")} - ${moment(booking.timeEnd, "HH:mm").format("HH:mm")}.`
                            : `Note: Acts on the table above is selected from your act roster with same line up count as previous act and available to perform on the gigs.`}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>Close</Button>
                    <Button
                        id="confirm-send-request"
                        color="primary"
                        disabled={this.state.selectedRow && this.state.selectedRow.length === 0}
                        onClick={this.handleSendRequest}
                    >
                        Send Request
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default BookingChangeActModal;
