import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import profileStore from "./profileStore";

import _ from "lodash";
import { Promise } from "bluebird";

import { ProfileType, ProfileRelationType } from "../types/enum";

class VenueActStore {
    @observable pendingRequest = [];
    @observable venueActRoster = [];

    @observable groupedPendingRequest = [];
    @observable groupedVenueActRoster = [];

    @observable invitedAct = undefined;
    @observable invitationMessage = "";

    @observable invitedActs = [];
    @observable invitedActsVenues = [];

    @observable venueId = undefined;

    @observable destinationProfile = undefined;
    @observable invitedChecked = [];
    @observable isMoreFavouriteAct = false
    @observable agentActFavouritePage = 1
    @action
    setInvitedChecked(acts) {
        this.invitedChecked = acts;
    }

    @action
    setActiveVenueId(venueId) {
        this.venueId = venueId;
    }

    @action
    setDestinationProfile(profile) {
        this.destinationProfile = profile;
    }

    @action
    resetPage() {
        this.agentActFavouritePage = 1;
        this.isMoreFavouriteAct = false;
        this.venueActRoster = [];
    }
    @action
    resetList() {
        this.pendingRequest = undefined;
        this.venueActRoster = undefined;

        this.pendingRequest = [];
        this.venueActRoster = [];
        this.groupedPendingRequest = [];
        this.groupedVenueActRoster = [];
    }

    @action
    getVenueActRosterPending = venueId => {
        this.pendingRequest = [];
        agent.ProfileRelationship.getVenueActRoster(venueId).then(
            action(response => {
                if (response.length > 0) {
                    this.pendingRequest = response.map(data => {
                        profileStore.mapAdditionalProfileData(data.primaryProfile);
                        profileStore.mapAdditionalProfileData(data.secondaryProfile);
    
                        if (data.primaryProfile.type === ProfileType.Act.ordinal && data.primaryProfile.myMusician) {
                            profileStore.mapAdditionalProfileData(data.primaryProfile.myMusician);
                        } else if (data.secondaryProfile.type === ProfileType.Act.ordinal && data.secondaryProfile.myMusician) {
                            profileStore.mapAdditionalProfileData(data.secondaryProfile.myMusician);
                        }
                        return data;
                    });
                }
            })
        );
    };
    @action
    getVenueActRosterSimple = venueId => { 
        this.venueActRoster = [];
        return agent.VenueAct.getVenueActRosterSimpleData(venueId).then(
            action(async response => {
                let result = [];
                if (response.length) {
                    // profileStore.mapAdditionalProfileNames(data);
                    response.forEach(data => {
                        profileStore.mapAdditionalProfileNames(data.act);
                        profileStore.mapAdditionalProfileNames(data.venue);
                        if (data.act.myMusician) {
                           profileStore.mapAdditionalProfileNames(data.act.myMusician) 
                        }
                        result.push(data);
                        this.venueActRoster.push(data)
                    });
                } else {
                    this.venueActRoster = [];
                }
                return result;
            })
        );
    }
    @action
    getVenueActRoster = venueId => {
        this.venueActRoster = [];
        return agent.VenueAct.getVenueActRoster(venueId).then(
            action(async response => {
                let result = [];
                if (response.length) {
                    await Promise.all(response.map(async data => {
                        await this.mapAdditionalProfileData(data.act);
                        await this.mapAdditionalProfileData(data.venue);
                        if (data.act.myMusician) {
                            await this.mapAdditionalProfileData(data.act.myMusician);
                        }
                        runInAction(() => {
                            result.push(data);
                            this.venueActRoster.push(data)
                        }); // use to async/await and push mapadditionaldata in mobx 
                    }))
                    // for(var i = 0; i < response.length;i++){
                    //     var data = response[i]
                    //     await this.mapAdditionalProfileData(data.act);
                    //     await this.mapAdditionalProfileData(data.venue);
                    //     if (data.act.myMusician) {
                    //         await this.mapAdditionalProfileData(data.act.myMusician);
                    //     }
                    //     runInAction(() => {
                    //         result.push(data);
                    //         this.venueActRoster.push(data)
                    //     }); // use to async/await and push mapadditionaldata in mobx 
                    // }
                } else {
                    this.venueActRoster = [];
                }
                return result;
            })
        );
    };

    @action
    getVenueActRosterWithPagination = (venueId,limit,page) => {
        return agent.VenueAct.getVenueActRosterWithPagination(venueId, limit, page).then(
            action(async res => {
                var response = res.data
                let result = [];
                if (response.length) {
                    await Promise.all(response.map(async data => {
                        await this.mapAdditionalProfileData(data.act);
                        await this.mapAdditionalProfileData(data.venue);
                        if (data.act.myMusician) {
                            await this.mapAdditionalProfileData(data.act.myMusician);
                        }
                        runInAction(() => {
                            result.push(data);
                            this.venueActRoster.push(data)
                            this.isMoreFavouriteAct = res.isMore
                        }); // use to async/await and push mapadditionaldata in mobx 
                    }))
                    // for(var i = 0; i < response.length;i++){
                    //     var data = response[i]
                    //     await this.mapAdditionalProfileData(data.act);
                    //     await this.mapAdditionalProfileData(data.venue);
                    //     if (data.act.myMusician) {
                    //         await this.mapAdditionalProfileData(data.act.myMusician);
                    //     }
                    //     runInAction(() => {
                    //         result.push(data);
                    //         this.venueActRoster.push(data)
                    //     }); // use to async/await and push mapadditionaldata in mobx 
                    // }
                } else {
                    // this.venueActRoster = [];
                }
                return result;
            })
        );
    };

    
    @action
    getStaffPendingActRoster = staffId => {
        const { profile } = profileStore;
        if (!staffId) {
            staffId = profile.id;
        }
        this.pendingRequest = [];
        return agent.ProfileRelationship.getStaffActRoster(staffId).then(
            action(response => {
                if (!response || response.length === 0) return this.pendingRequest = [];
                this.pendingRequest = response.map(async data => {
                    await this.mapAdditionalProfileData(data.primaryProfile);
                    profileStore.mapAdditionalProfileData(data.secondaryProfile);

                    if (data.primaryProfile.type === ProfileType.Act.ordinal && data.primaryProfile.myMusician) {
                        profileStore.mapAdditionalProfileData(data.primaryProfile.myMusician);
                    } else if (data.secondaryProfile.type === ProfileType.Act.ordinal && data.secondaryProfile.myMusician) {
                        profileStore.mapAdditionalProfileData(data.secondaryProfile.myMusician);
                    }
                    return data;
                });

                this.groupedPendingRequest = _.chain(this.pendingRequest)
                    .groupBy("primaryProfileId")
                    .toPairs()
                    .map(function(pair) {
                        return _.zipObject(["venueId", "requests"], pair);
                    })
                    .value();
                return this.pendingRequest;
            })
        );
    };

    @action
    getStaffActRoster = (staffId, isUnique = false) => {
        const { profile } = profileStore;
        if (!staffId && profile) {
            staffId = profile.id;
        } 
        this.venueActRoster = [];
        const requestVenueActRoster = isUnique ? agent.VenueAct.getStaffActRosterUnique : agent.VenueAct.getStaffActRoster;
        return requestVenueActRoster(staffId).then(
            action(async response => {
                if (!response) return this.venueActRoster = [];
                this.venueActRoster = []
                var actIds = [];
                var venueIds = []
                var myMusicianIds = []
                var actData = {}
                var venueData = {}
                var myMusicianData = {}
                await Promise.all(response.map(async data => {  
                    if(data.act && data.act.id && actIds.filter(x=>x == data.act.id).length == 0){
                        actIds.push(data.act.id)
                        await this.mapAdditionalProfileData(data.act);
                        actData[data.act.id] = data.act
                    }  
                    if(data.venue && data.venue.id && venueIds.filter(x=>x == data.venue.id).length == 0){
                        venueIds.push(data.venue.id)
                        await this.mapAdditionalProfileData(data.venue);
                        venueData[data.venue.id] = data.venue
                    }  
                    
                   
                    
                    if (data.act && data.act.myMusician) {
                        if(data.act.myMusician.id && myMusicianIds.filter(x=>x == data.act.myMusician.id).length == 0){
                            myMusicianIds.push(data.act.myMusician.id)
                            await this.mapAdditionalProfileData(data.act.myMusician);
                            myMusicianData[data.act.myMusician.id] = data.act.myMusician
                        }  
                        
                    }
                    
                }));
                var venueActData = [];
                response.forEach(data => {
                    if(data.act && data.act.id && data.venue && data.venue.id){
                        if(data.act && data.act.id){
                            data.act = actData[data.act.id]
                        }else{
                            deletedData.push(data.id)
                        }
                        if(data.venue && data.venue.id){
                            data.venue = venueData[data.venue.id]
                        }else{
                            deletedData.push(data.id)
                        }
                        if (data.act && data.act.myMusician) {
                            data.act.myMusician = myMusicianData[data.act.myMusician.id]
                        }
                        venueActData.push(data);
                    }
                    
                       
                });
                
                runInAction(() => {
                    this.venueActRoster = _.orderBy(venueActData, x => x.act.actNameSort, ['asc']);
                    this.groupedVenueActRoster = _.chain(this.venueActRoster)
                    .groupBy("venueId")
                    .toPairs()
                    .map(function(pair) {
                        return _.zipObject(["venueId", "rosters"], pair);
                    })
                    .value()
                })
            })
        );
    };

    @action
    getStaffActRosterInvoice = (staffId, isUnique = false) => {
        const { profile } = profileStore;
        if (!staffId && profile) {
            staffId = profile.id;
        } 
        this.venueActRoster = [];
        const requestVenueActRoster = isUnique ? agent.VenueAct.getStaffActRosterUnique : agent.VenueAct.getStaffActRosterSimple;
        return requestVenueActRoster(staffId).then(
            action(async response => {
                if (!response) return this.venueActRoster = [];
                this.venueActRoster = []
                await response.forEach(async data => {      
                    await this.mapAdditionalProfileData(data.act);
                    await this.mapAdditionalProfileData(data.venue);
                    if (data.act.myMusician) {
                        await this.mapAdditionalProfileData(data.act.myMusician);
                    }
                    runInAction(() => {
                        this.venueActRoster.push(data);
                        this.groupedVenueActRoster = _.chain(this.venueActRoster)
                        .groupBy("venueId")
                        .toPairs()
                        .map(function(pair) {
                            return _.zipObject(["venueId", "rosters"], pair);
                        })
                        .value()
                    })
                });
            })
        );
    };

    @action
    getStaffActRosterMyGigs = (staffId, isUnique = false) => {
        const { profile } = profileStore;
        if (!staffId && profile) {
            staffId = profile.id;
        } 
        this.venueActRoster = [];
        const requestVenueActRoster = isUnique ? agent.VenueAct.getStaffActRosterUnique : agent.VenueAct.getStaffActRosterSimple;
        return requestVenueActRoster(staffId).then(
            action(async response => {
                if (!response) return this.venueActRoster = [];
                let result = []
                for (const data of response) {     
                    await this.mapAdditionalProfileData(data.act);
                    await this.mapAdditionalProfileData(data.venue);
                    if (data.act.myMusician) {
                        await this.mapAdditionalProfileData(data.act.myMusician);
                    }
                    runInAction(() => {
                        result.push(data);
                        this.venueActRoster = result
                        this.groupedVenueActRoster = _.chain(this.venueActRoster)
                        .groupBy("venueId")
                        .toPairs()
                        .map(function(pair) {
                            return _.zipObject(["venueId", "rosters"], pair);
                        })
                        .value()
                    })
                }
                return result
            
            })
        );
    };

    @action
    getStaffAvailableActs = (bookingDetail, staffId) => {
        const { currentProfile } = profileStore;

        if (!staffId) {
            staffId = currentProfile.id;
        }

        return agent.VenueAct.getStaffAvailableActs(staffId, bookingDetail).then(
            action(async response => {
                var result = [];
                for(var i = 0; i< response.length; i++){
                    var data = response[i];
                    await profileStore.mapAdditionalProfileDataAsync(data.act);
                    await profileStore.mapAdditionalProfileDataAsync(data.venue);
                    // profileStore.mapAdditionalProfileData(data.act);
                    // profileStore.mapAdditionalProfileData(data.venue);
                    if (data.act.myMusician) {
                        await profileStore.mapAdditionalProfileDataAsync(data.act.myMusician);
                        // profileStore.mapAdditionalProfileData(data.act.myMusician);
                    }
                    result.push(data);
                }
                this.venueActRoster = result;
                return this.venueActRoster;
            })
        );
    };

    @action
    getMusicianVenueActRosterPending = () => {
        this.pendingRequest = [];
        return agent.ProfileRelationship.getMusicianActVenueRoster().then(
            action(response => {
                this.pendingRequest = response.map(data => {
                    profileStore.mapAdditionalProfileData(data.primaryProfile);
                    profileStore.mapAdditionalProfileData(data.secondaryProfile);

                    if (data.primaryProfile.type === ProfileType.Act.ordinal && data.primaryProfile.myMusician) {
                        profileStore.mapAdditionalProfileData(data.primaryProfile.myMusician);
                    } else if (data.secondaryProfile.type === ProfileType.Act.ordinal && data.secondaryProfile.myMusician) {
                        profileStore.mapAdditionalProfileData(data.secondaryProfile.myMusician);
                    }
                    return data;
                });

                this.pendingRequest = _.chain(this.pendingRequest)
                    .groupBy("primaryProfileId")
                    .toPairs()
                    .map(function(pair) {
                        return _.zipObject(["venue", "request"], pair);
                    })
                    .value();
                return this.pendingRequest;
            })
        );
    };

    @action
    getMusicianVenueActRoster = () => {
        this.venueActRoster = [];
        const profile = profileStore.getCurrentProfile();
        return agent.VenueAct.getMusicianActVenue(profile.id).then(
            action(async response => {
                if(response && response.length > 0 && response !== undefined) {
                    var mapped = [];
                    for(var i = 0; i < response.length; i++){
                        var data = response[i]
                        await profileStore.mapAdditionalProfileDataAsync(data.venue);
                        await profileStore.mapAdditionalProfileDataAsync(data.act);
                        mapped.push(data)
                    }
                    runInAction(() => {
                        this.venueActRoster = mapped
                        this.venueActRoster = _.chain(this.venueActRoster)
                        .groupBy("venueId")
                        .toPairs()
                        .map(function(pair) {
                            return _.zipObject(["venue", "data"], pair);
                        })
                        .value();
                        return this.venueActRoster;
                     })
                } else {
                    this.venueActRoster = []
                    return mapped;
                }
            })
        );
    };

    
    @action
    deleteVenueActMusician(id) {

        return agent.VenueAct.deleteMusician(id).then(
            action(response => {
                var result = this.venueActRoster
                result.forEach(item => {
                    item.data = item.data.filter(x=>parseInt(x.id) != parseInt(id))
                });
                result = result.filter(x=>x.data.length)
                this.venueActRoster = result
                this.groupedVenueActRoster = _.chain(this.venueActRoster)
                    .groupBy("venueId")
                    .toPairs()
                    .map(function(pair) {
                        return _.zipObject(["venueId", "rosters"], pair);
                    })
                    .value();
                profileStore.mapAdditionalProfileData(response.act);
                profileStore.mapAdditionalProfileData(response.venue);

                return response;
            })
        );
    }
    @action
    acceptInvitation(invitation) {
        const { profile } = profileStore;
        const requestDeatail = {
            primaryProfileId: Number(invitation.invitedVenue),
            secondaryProfileId: Number(invitation.additionalData),
            requestMessage: invitation.invitationMessage,
            type: ProfileRelationType.VenueAct.ordinal,
            createdById: profile.id ,
            invitationId: invitation.id
        }
        return agent.ProfileRelationship.invite(requestDeatail).then(
            action(response => {
            })
        )
    }

    @action
    inviteMany(checkedOnly) {
        const { profile } = profileStore;
        let type
        if (this.destinationProfile && this.destinationProfile.type === ProfileType.Agent.ordinal){
            type = ProfileRelationType.AgentAct.ordinal
        } else {
            type = ProfileRelationType.VenueAct.ordinal
        }

        if (this.invitedActs && this.invitedActs.length > 0) {
            var requestDetails = [];
            var listActs = checkedOnly ? this.invitedChecked : this.invitedActs;
            listActs.forEach(invitedAct => {
                requestDetails.push({
                    primaryProfileId: this.venueId,
                    secondaryProfileId: invitedAct.id,
                    requestMessage: this.invitationMessage,
                    type: type,
                    createdById: profile.id
                });
            });

            return agent.ProfileRelationship.inviteMany(requestDetails).then(
                action(response => {
                    this.getVenueActRoster(this.venueId);
                    this.getVenueActRosterPending(this.venueId);
                    return this.pendingRequest;
                })
            );
        }

        return Promise.resolve();
    }

    @action
    inviteManyActToManyVenues() {
        const { profile } = profileStore;

        if (this.invitedActs && this.invitedActs.length > 0) {
            var requestDetails = [];

            this.invitedActs.forEach(invitedAct => {
                if(this.invitedActsVenues.length === 0) {
                    requestDetails.push({
                        primaryProfileId: profile.id,
                        secondaryProfileId: invitedAct.id,
                        requestMessage: this.invitationMessage,
                        type: ProfileRelationType.AgentAct.ordinal,
                        createdById: profile.id
                    });
                } else {
                    this.invitedActsVenues.forEach(invitedActVenue => {
                        requestDetails.push({
                            primaryProfileId: invitedActVenue.id,
                            secondaryProfileId: invitedAct.id,
                            requestMessage: this.invitationMessage,
                            type: ProfileRelationType.VenueAct.ordinal,
                            createdById: profile.id
                        });
                    });
                }
            });

            return agent.ProfileRelationship.inviteMany(requestDetails).then(
                action(() => {
                    return this.getStaffPendingActRoster().then(() => {
                        return this.getStaffActRoster().then(() => {
                            return this.pendingRequest;
                        });
                    });
                })
            );
        }

        return Promise.resolve();
    }

    @action
    setAgentActFavouritePage(page){
        this.agentActFavouritePage = page
    }
    @action
    addMany(venue) {
        const { profile } = profileStore;

        if (this.invitedActs && this.invitedActs.length > 0 && venue) {
            var requestDetails = [];

            this.invitedActs.forEach(invitedAct => {
                requestDetails.push({
                    primaryProfileId: venue.id,
                    primaryProfile: {type: venue.type},
                    secondaryProfileId: invitedAct.id,
                    secondaryProfile: {type: invitedAct.type},
                    type: ProfileRelationType.VenueAct.ordinal,
                    createdById: profile.id
                });
            });

            return agent.VenueAct.createMany(requestDetails).then(
                action(response => {
                    return response;
                })
            );
        }else if(this.invitedAct){
            var requestDetails = [];
                requestDetails.push({
                    primaryProfileId: venue.id,
                    primaryProfile: {type: venue.type},
                    secondaryProfileId: this.invitedAct.id,
                    secondaryProfile: {type: this.invitedAct.type},
                    type: ProfileRelationType.VenueAct.ordinal,
                    createdById: profile.id
                });
            return agent.VenueAct.createMany(requestDetails).then(
                action(response => {
                    return response;
                })
            );
        }

        return Promise.resolve();
    }

    @action
    setInvitedActs(acts) {
        this.invitedActs = acts;
    }

    @action
    setInvitedActsVenues(venues) {
        this.invitedActsVenues = venues;
    }

    @action
    setInvitationMessage(value) {
        this.invitationMessage = value;
    }

    @action
    setInvitedAct(act) {
        profileStore.mapAdditionalProfileData(act.myMusician);

        var checkPending = this.pendingRequest.filter(
            x => x.secondaryProfileId === act.id && this.invitedActsVenues.filter(y => y.id === x.primaryProfileId).length > 0
        );

        var checkAccepted = this.venueActRoster.filter(x => x.actId === act.id && this.invitedActsVenues.filter(y => y.id === x.venueId).length > 0);

        if (checkPending.length > 0) {
            checkPending.forEach(data => {
                this.invitedActsVenues = this.invitedActsVenues.filter(x => x.id !== data.primaryProfileId);
            });
        }

        if (checkAccepted.length > 0) {
            checkAccepted.forEach(data => {
                this.invitedActsVenues = this.invitedActsVenues.filter(x => x.id !== data.venueId);
            });
        }

        if (act.myMusician && act.myMusician.myActs) {
            act.myMusician.myActs = act.myMusician.myActs.map(data => {
                profileStore.mapAdditionalProfileData(data);
                return data;
            });
            this.invitedAct = act;
            if (!this.invitedActs) {
                this.invitedActs = [];
            }
            this.invitedActs.push(act);
        }
        else{
            this.invitedAct = [];
            return agent.Profile.loadActs(act.userId)
                .then(
                    action(async musician => {
                        await profileStore.mapAdditionalProfileDataAsync(musician.myMusician);
                        if(musician.myActs.length > 0 && Array.isArray(musician.myActs)){
                            for(let i = 0; i < musician.myActs.length; i++) {
                                await profileStore.mapAdditionalProfileDataAsync(musician.myActs[i])
                                musician.myActs[i].selectedActId = act.id
                            }
                        }
                        act.myMusician = musician.myMusician
                        act.myActs = musician.myActs
                        runInAction(() => {
                            this.invitedAct = act;
                   
                            return this.invitedAct
                          
                        })
                    })
                );
        }
    }

    @action
    clearInviteData() {
        this.invitationMessage = "";
        this.invitedAct = undefined;
        this.invitedActs = [];
        this.invitedActsVenues = [];
    }

    //end of invitation

    @action
    acceptRequest(request) {
        return agent.ProfileRelationship.accept(request).then(
            action(response => {
                //TODO: delete and add based on response
                this.getMusicianVenueActRosterPending();
                this.getMusicianVenueActRoster();
                return response;
            })
        );
    }

    @action
    acceptManyRequest(requests) {
        var data = [];
        requests.forEach(request => {
            data.push(request);
        });
        return agent.ProfileRelationship.acceptMany(data).then(
            action(response => {
                //TODO: delete and add based on response
                this.getMusicianVenueActRosterPending();
                this.getMusicianVenueActRoster();
                return response;
            })
        );
    }

    @action
    removeRequest(request) {
        return agent.ProfileRelationship.remove(request).then(
            action(response => {
                this.pendingRequest = this.pendingRequest.filter(x => x.id !== response.id);
                this.groupedPendingRequest = _.chain(this.pendingRequest)
                    .groupBy("primaryProfileId")
                    .toPairs()
                    .map(function(pair) {
                        return _.zipObject(["venueId", "requests"], pair);
                    })
                    .value();
                profileStore.mapAdditionalProfileData(response.primaryProfile);
                profileStore.mapAdditionalProfileData(response.secondaryProfile);
                return response;
            })
        );
    }

    @action
    removeManyRequest(requests) {
        var data = [];
        requests.forEach(request => {
            data.push(request.id);
        });
        if (data.length > 0) {
            return agent.ProfileRelationship.removeMany(data).then(
                action(response => {
                    response.forEach(data => {
                        this.pendingRequest = this.pendingRequest.filter(x => x.id !== data.id);
                    });
                    this.groupedPendingRequest = _.chain(this.pendingRequest)
                        .groupBy("primaryProfileId")
                        .toPairs()
                        .map(function(pair) {
                            return _.zipObject(["venueId", "requests"], pair);
                        })
                        .value();
                    var result = response[0];
                    profileStore.mapAdditionalProfileData(result.primaryProfile);
                    profileStore.mapAdditionalProfileData(result.secondaryProfile);
                    return result;
                })
            );
        } else {
            return Promise.resolve(data);
        }
    }

    @action
    declineRequest(request) {
        return agent.ProfileRelationship.decline(request).then(
            action(response => {
                _.remove(this.pendingRequest, ["id", response.id]);
                return response;
            })
        );
    }

    @action
    declineManyRequest(requests) {
        var data = [];
        requests.forEach(request => {
            data.push(request.id);
        });
        return agent.ProfileRelationship.declineMany(data).then(
            action(response => {
                response.forEach(data => {
                    _.remove(this.pendingRequest, ["id", data.id]);
                });
                return response;
            })
        );
    }

    @action
    deleteVenueAct(request, cancelBookings) {
        return agent.VenueAct.delete(request, cancelBookings).then(
            action(response => {
                this.venueActRoster = this.venueActRoster.filter(x => x.id !== response.id);
                this.groupedVenueActRoster = _.chain(this.venueActRoster)
                    .groupBy("venueId")
                    .toPairs()
                    .map(function(pair) {
                        return _.zipObject(["venueId", "rosters"], pair);
                    })
                    .value();
                profileStore.mapAdditionalProfileData(response.act);
                profileStore.mapAdditionalProfileData(response.venue);

                return response;
            })
        );
    }

    @action
    deleteManyVenueAct(requests) {
        var data = [];
        requests.forEach(request => {
            data.push(request.id);
        });
        if (data.length > 0) {
            return agent.VenueAct.deleteMany(data).then(
                action(response => {
                    response.forEach(data => {
                        this.venueActRoster = this.venueActRoster.filter(x => x.id !== data.id);
                    });
                    this.groupedVenueActRoster = _.chain(this.venueActRoster)
                        .groupBy("venueId")
                        .toPairs()
                        .map(function(pair) {
                            return _.zipObject(["venueId", "rosters"], pair);
                        })
                        .value();
                    var result = response[0];
                    profileStore.mapAdditionalProfileData(result.act);
                    profileStore.mapAdditionalProfileData(result.venue);
                    return result;
                })
            );
        } else {
            return Promise.resolve(data);
        }
    }

    @action
    mapAdditionalProfileData = async (data) => {
        await profileStore.mapAdditionalProfileImageAndBackgroundImages(data);
        profileStore.mapAdditionalProfileNames(data);
    }
}

export default new VenueActStore();
