import React, { Component, Fragment } from "react";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Grid } from "@material-ui/core";
import { Paper, Typography, Button } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import "./Organization.css";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import ResponsiveTable from "../../components/table/ResponsiveTable";
import { ProfileType, OrganisationType } from "../../types/enum";

import Loading from "../../components/loadingSpinner/Loading";

@inject("profileStore", "templateStore", "organisationStore")
@observer
class Organisation extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/staff" }, { name: "Organisation" }];

    state = {
        isLoading: false,
        organisations: []
    };

    componentDidMount() {
        this.setState(
            {
                ...this.state,
                isLoading: true
            },
            () => {
                this.props.organisationStore.loadOrganisations().then(() => {
                    
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                   
                });
            }
        );
    }

    handleNameChange = e => this.props.organisationStore.setName(e.target.value);

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    renderOrganisations(currentProfile, organisations) {
        let orgs = [];
        if (currentProfile && currentProfile.type){
            switch (currentProfile.type) {
                
                case ProfileType.Staff.ordinal:
                    orgs = organisations.filter(x => x.type === OrganisationType.Venue.ordinal);
                    break;
                case ProfileType.Agent.ordinal:
                    orgs = organisations.filter(x => x.type === OrganisationType.Agency.ordinal);
                    break;
                default:
                    break;
            }
        }
       
        let regionColumns = [
            { key: "name", label: orgs && orgs[0] && orgs[0].level3Label ? orgs[0].level3Label : "Region", width: "30%" },
            { key: "address", label: "Address", width: "30%" },
            { key: "contactNumber", label: "Contact No.", width: "30%" },
            { key: "editButton", label: "", width: "10%" }
        ];

        if (orgs && orgs.length > 0) {
            orgs.forEach((o, i) => {
                if (o.state && o.state.length > 0) {
                    o.state.forEach((s, j) => {
                        if (s.regions && s.regions.length > 0) {
                            s.regions.map((r, k) => {
                                r.editButton = r.canManage ? (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className="btn-edit-region"
                                        component={Link}
                                        to={`/organisation/${o.id}/state/${s.id}/region/${r.id}/edit`}
                                    >
                                        Edit
                                    </Button>
                                ) : (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className="btn-edit-region"
                                        component={Link}
                                        to={`/organisation/${o.id}/state/${s.id}/region/${r.id}/edit`}
                                    >
                                        View
                                    </Button>
                                );

                                return true;
                            });
                        }
                    });
                }
            });
        }

        return (
            <Fragment>
                {currentProfile && (
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            {orgs.length === 0 && (
                                <Paper elevation={1} className="paper-tutorial">
                                    <Typography variant="h5" component="h3">
                                        Manage your organisation
                                    </Typography>
                                    <Typography component="p">
                                        You're not in any organisation yet. If you are a member of an organisation, you can setup it up here. Start by
                                        creating your organisation and then create the hierarchy to manage the permission for your members.
                                    </Typography>
                                    <div className="action">
                                        <Button variant="contained" color="default" component={Link} to="/organisation/new">
                                            Show Me
                                        </Button>
                                        &nbsp;
                                        <Button variant="contained" color="primary" component={Link} to="/organisation/new">
                                            Got it
                                        </Button>
                                    </div>
                                </Paper>
                            )}
                            {orgs.length > 0 &&
                                orgs.map((organisation, index) => {
                                    return (
                                        <Fragment>
                                            {index > 0 ? <hr className="org-divider" /> : ""}
                                            <Grid container spacing={16} key={organisation.id} justify="center">
                                                {/*
                                                * Organization 
                                                */}
                                                <Grid item xs={12} md={4}>
                                                    <Paper className="org">
                                                        <Grid container className="orgHead">
                                                            <Grid item xs={8}>
                                                                <Typography variant="h5" component="h3">
                                                                    {organisation.name}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={4} className="text-right">
                                                                {organisation.canManage ? (
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        component={Link}
                                                                        to={`/organisation/${organisation.id}/edit`}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    component={Link}
                                                                    to={`/organisation/${organisation.id}/edit`}
                                                                    >
                                                                    View
                                                                    </Button>
                                                                )}
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography component="p">
                                                                    {organisation.address}
                                                                    <br />
                                                                    {organisation.contactNumber}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>

                                                {/*
                                                * State
                                                */}
                                                {organisation.state &&
                                                    organisation.state.length > 0 && (
                                                        <Grid item xs={12} md={8}>
                                                            {organisation.state.map((state, index) => {
                                                                return (
                                                                    <Paper className="org state">
                                                                        <Grid container className="orgHead">
                                                                            <Grid item xs={8}>
                                                                                <Typography variant="h5" component="h3">
                                                                                    {state.name}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={4} className="text-right">
                                                                                {state.canManage ? (
                                                                                    <Button
                                                                                        size="small"
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        component={Link}
                                                                                        to={`/organisation/${organisation.id}/state/${state.id}/edit`}
                                                                                    >
                                                                                        Edit
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Button
                                                                                        size="small"
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        component={Link}
                                                                                        to={`/organisation/${organisation.id}/state/${state.id}/edit`}
                                                                                    >
                                                                                        View
                                                                                    </Button>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid container spacing={16}>
                                                                            <Grid item xs={12}>
                                                                                <Typography component="p">
                                                                                    {state.address}
                                                                                    <br />
                                                                                    {state.contactNumber}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <ResponsiveTable
                                                                                    columns={regionColumns}
                                                                                    rows={state.regions}
                                                                                    showToolbar={true}
                                                                                    title={`${state.name}'s ${organisation.level3Label}`}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Paper>
                                                                );
                                                            })}
                                                        </Grid>
                                                    )}
                                            </Grid>
                                        </Fragment>
                                    );
                                })}
                        </Grid>
                    </Grid>
                )}
            </Fragment>
        );
    }

    render() {
        const { currentProfile } = this.props.profileStore;
        const { organisations } = this.props.organisationStore;
        return (
            <main>
                <Header userRole="agent" />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title="Organisation" />
                    <div style={{ position: "relative" }}>
                        <Loading showed={this.state.isLoading} />
                        {this.renderOrganisations(currentProfile, organisations)}
                    </div>
                </MainContainer>
            </main>
        );
    }
}
export default Organisation;
