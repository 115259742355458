import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Paper, TextField, FormControl, InputLabel, Grid, FormControlLabel, Checkbox, Button, Switch } from "@material-ui/core";
import Slider from "@material-ui/lab/Slider";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";

import moment from "moment";

import Instruments from "../fields/MultiSelectInstrument";

import Genre from "../fields/MultiSelectGenre";
import GigType from "../fields/MultiSelectGigTypes";

import SelectField from "../fields/SelectField";
import GooglePlaceAutoComplete from "../maps/GooglePlaceAutocomplete";

import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ProfileType } from "../../types/enum";
import HubTimepicker from "../timepicker/HubTimepicker";

import agent from "../../agent";

@inject("searchStore", "profileStore", "actTypeStore")
@withRouter
@observer
class SearchFilter extends Component {
    state = {
        searchTimeout: undefined,
        vibes: []
    };

    componentDidMount() {
        this.props.searchStore.setPage(1);
        this.props.actTypeStore.loadActTypes();
        if (this.props.location.search) {
            this.props.searchStore.setSearch(this.props.location.search);
        }

        agent.Vibe.getAll().then(result => {
            this.setState({
                vibes: result.map(vibes => ({
                    value: vibes.id,
                    label: vibes.name
                }))
            });
        });
    }
    refreshSearch() {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        //this.state.searchTimeout = setTimeout(this.props.searchStore.search, 1000);
    }

    handleTypeChange = value => {
        this.props.searchStore.setType(value);
        this.setState({ ...this.state });
    };

    handleQueryChange = e => {
        this.props.searchStore.setQuery(e.target.value);
        // this.refreshSearch();
    };
    handleSongChange = e => this.props.searchStore.setSong(e.target.value);
    handleInstrumentChange = value => this.props.searchStore.setInstruments(value);
    handleGenreChange = value => this.props.searchStore.setGenres(value);
    handleGigTypeChange = value => this.props.searchStore.setGigTypes(value);
    handleCityChange = e => this.props.searchStore.setCity(e.target.value);
    handleStateChange = e => this.props.searchStore.setState(e.target.value);
    handleLineUpCountChange = value => this.props.searchStore.setLineUpCount(value);
    handleChangeSearchAddress = value => {
        this.props.searchStore.setSearchedAddress(value);
    };
    handleChangeLongitudeLatitude = value => this.props.searchStore.setLocationLongitudeLatitude(value);
    handleChangeDistanceRadius = (event, value) => this.props.searchStore.setDistanceRadius(value);
    handleRequiredDate = value => this.props.searchStore.setRequiredDate(value);
    handleSetRequiredDateTimeOption = event => {
        this.props.searchStore.setRequiredDateTimeOption(event.target.checked);
    };
    handleSetRequiredTimeStart = value => this.props.searchStore.setRequiredTimeStart(value);
    handleSetRequiredTimeEnd = value => this.props.searchStore.setRequiredTimeEnd(value);
    handleActTypeChange = value => this.props.searchStore.setActTypeId(value);

    handleSubmitSearch = e => {
        e.preventDefault();
        this.props.searchStore.submitSearch();
    };

    handleApplyToPlay = e => this.props.searchStore.setIsApplyToPlay(e.target.checked);
    handleApplyToBook = e => this.props.searchStore.setIsApplyToBook(e.target.checked);

    handleApplyActRoster = e => this.props.searchStore.setIsApplyToActRoster(e.target.checked);
    handleVibeChange = value => this.props.searchStore.setSelectedVibes(value);

    lineUpCountSuggestion = () => {
        let lineUpCounts = [
            { name: "Solo", instrument: 1 },
            { name: "Duo", instrument: 2 },
            { name: "Trio", instrument: 3 },
            { name: "Quartet / 4 Piece", instrument: 4 },
            { name: "Quintet / 5 Piece", instrument: 5 },
            { name: "Sextet / 6 Piece", instrument: 6 }
        ];

        for (var i = 0; i < 93; i++) {
            lineUpCounts.push({ name: i + 7 + " Piece", instrument: i + 7 });
        }
        return lineUpCounts.map(lineUpCount => {
            return {
                value: lineUpCount.instrument,
                label: lineUpCount.name
            };
        });
    };

    vibeSuggestion = () => {
        const { vibes } = this.state;
        return vibes.map(vibe => {
            
            return vibe;
        });
    }

    getProfileTypeSuggestions = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile()

        if (currentProfile?.type === ProfileType.Musician.ordinal ||
            currentProfile?.type === ProfileType.Agent.ordinal 
        ) {
            return [
                { value: 4, label: "Act / Band" },
                { value: 1, label: "Musician / DJ / Entertainer" },
                { value: 5, label: "Venue" },
                { value: 2, label: "Agent" }
            ];
        } else if (currentProfile?.type === ProfileType.PrivateHire.ordinal){
            return [{ value: 4, label: "Act / Band" }]
        }else {
            return [{ value: 4, label: "Act / Band" }, { value: 5, label: "Venue" }, { value: 2, label: "Agent" }];
        }
    };

    render() {
        const {
            query,
            song,
            // type,
            lineUpCount,
            instruments,
            genres,
            gigTypes,
            searchedAddress,
            locationLongitudeLatitude,
            distanceRadius,
            setRequiredDateTime,
            requiredDate,
            requiredTimeStart,
            requiredTimeEnd,
            actTypeId,
            isApplyToPlay,
            isApplyToActRoster,
            selectedVibes,
            isApplyToBook
        } = this.props.searchStore;
        const type = this.props.searchStore.type
        const { actTypes } = this.props.actTypeStore;

        let actTypeSuggestion = () => {
            return actTypes.map(actType => {
                return {
                    value: actType.id,
                    label: actType.name
                };
            });
        };

        const currentProfile = this.props.profileStore.getCurrentProfile();

        return (
            <Paper style={{ padding: 14 }}>
                <form onSubmit={this.handleSubmitSearch}>
                    <Typography variant="headline" component="h3" gutterBottom>
                        Filter
                    </Typography>
                    <hr />
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TextField id="query" label="Name" margin="dense" fullWidth value={query} onChange={this.handleQueryChange} />
                                </Grid>
                                <Grid item>
                                    <SelectField
                                        multi={false}
                                        suggestions={this.getProfileTypeSuggestions()}
                                        onChange={this.handleTypeChange}
                                        value={type && type}
                                        placeholder=" "
                                        label="Profile Type"
                                        margin="dense"
                                    />
                                </Grid>
                                {type && type === 4 ? (
                                    <>
                                    <Grid item>
                                        <SelectField
                                            id="lineUpCount"
                                            multi={false}
                                            suggestions={this.lineUpCountSuggestion()}
                                            onChange={this.handleLineUpCountChange}
                                            value={lineUpCount}
                                            placeholder=" "
                                            label="Line Up"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <SelectField
                                            id="vibes"
                                            multi={true}
                                            suggestions={this.vibeSuggestion()}
                                            onChange={this.handleVibeChange}
                                            value={selectedVibes}
                                            placeholder=" "
                                            label="Vibes"
                                            margin="dense"
                                        />
                                    </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        </Grid>
                        {type && type !== undefined && (type === 1 || type === 4) ? (
                            <Grid item xs={12} md={3}>
                                <Grid container direction="column">
                                    {type && (type === 1 || type === 4) ? (
                                        <Grid item>
                                            <GigType id="search-gigTypes" onChange={this.handleGigTypeChange} value={gigTypes} />
                                        </Grid>
                                    ) : null}
                                    {type && type === 4 ? (
                                        <Grid item>
                                            <SelectField
                                                id="actTypeId"
                                                multi={true}
                                                suggestions={actTypeSuggestion()}
                                                onChange={this.handleActTypeChange}
                                                value={actTypeId}
                                                placeholder=" "
                                                label="Act Type"
                                                margin="dense"
                                            />
                                        </Grid>
                                    ) : null}
                                    {type && (type === 1 || type === 4) ? (
                                        <Grid item>
                                            <Genre id="search-genres"
                                                onChange={this.handleGenreChange}
                                                value={genres} />
                                        </Grid>
                                    ) : null}
                                    {type && (type === 1 || type === 4) ? (
                                        <Grid item>
                                            <TextField
                                                id="song"
                                                label="Song title"
                                                margin="dense"
                                                fullWidth
                                                value={song}
                                                onChange={this.handleSongChange}
                                            />
                                        </Grid>
                                    ) : null}
                                    {type && type === 1 ? (
                                        <Grid item>
                                            <Instruments
                                                id="search-instruments"
                                                onChange={this.handleInstrumentChange}
                                                value={instruments}
                                                placeholder="Select Instruments/Skill Sets"
                                                label="Instruments/Skill Sets"
                                            />
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                        ) : null}
                        {(type && type === 4 && (currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal)) ||
                            (type && (type === 1 && currentProfile?.type === ProfileType.Musician.ordinal) || (type === ProfileType.Act.ordinal && currentProfile?.isConsultant)) ? (
                            <Grid item xs={12} md={3}>
                                <Grid container direction="column">
                                    {(type && type === ProfileType.Act.ordinal || type === ProfileType.Musician.ordinal) &&
                                        (currentProfile?.type === ProfileType.Agent.ordinal ||
                                            currentProfile?.type === ProfileType.Staff.ordinal ||
                                            currentProfile?.type === ProfileType.Musician.ordinal
                                            ) ? (
                                        <Grid item>
                                            <FormControl variant="outlined" fullWidth className="save-checkbox">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            className="checkbox-dense"
                                                            checked={setRequiredDateTime}
                                                            onChange={this.handleSetRequiredDateTimeOption}
                                                        />
                                                    }
                                                    label="Set Required Date/Time"
                                                    style={{ height: 72 }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    ) : null}
                                    {setRequiredDateTime &&
                                        (type && type === 4 || type === 1) &&
                                        (currentProfile?.type === ProfileType.Agent.ordinal ||
                                            currentProfile?.type === ProfileType.Staff.ordinal ||
                                            currentProfile?.type === ProfileType.Musician.ordinal) ? (
                                        <Grid item>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DatePicker
                                                    id="search-required-date"
                                                    className="search-required-date"
                                                    keyboard
                                                    value={requiredDate}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={this.handleRequiredDate}
                                                    label="Required Date"
                                                    minDate={moment().startOf("day")}
                                                    format="DD-MM-YYYY"
                                                    keyboardIcon={<EventIcon />}
                                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                                    style={{ marginTop: 8 }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    ) : null}
                                    {setRequiredDateTime &&
                                        (type && type === 4 || type === 1) &&
                                        (currentProfile?.type === ProfileType.Agent.ordinal ||
                                            currentProfile?.type === ProfileType.Staff.ordinal ||
                                            currentProfile?.type === ProfileType.Musician.ordinal) ? (
                                        <Grid item>
                                            <Grid container spacing={8}>
                                                <Grid item xs={12} md={6}>
                                                    <HubTimepicker
                                                        id="time-start"
                                                        margin="dense"
                                                        label="Time Start"
                                                        value={moment(requiredTimeStart, "HH:mm")}
                                                        onChange={this.handleSetRequiredTimeStart}
                                                        wrapperStyle={{
                                                            marginTop: 10
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <HubTimepicker
                                                        id="time-end"
                                                        margin="dense"
                                                        label="Time End"
                                                        value={moment(requiredTimeEnd, "HH:mm")}
                                                        onChange={this.handleSetRequiredTimeEnd}
                                                        wrapperStyle={{
                                                            marginTop: 10
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} md={3}>
                            <Grid container direction="column" justify="space-between" className="last-search-filter-container">
                                <Grid item>
                                    <GooglePlaceAutoComplete
                                        value={searchedAddress}
                                        label="Search Address"
                                        placeholder="Type venue name or address"
                                        handleChange={this.handleChangeSearchAddress}
                                        handleChangeCity={this.handleCityChange}
                                        handleChangeState={this.handleStateChange}
                                        handleChangeLatLng={this.handleChangeLongitudeLatitude}
                                        style={{
                                            marginTop: "8px"
                                        }}
                                    />
                                </Grid>
                                {locationLongitudeLatitude ? (
                                    <Grid item>
                                        <FormControl fullWidth style={{ marginTop: 16 }}>
                                            <InputLabel shrink>
                                                Distance From Searched Address {distanceRadius ? `: ${distanceRadius} Km` : ""}
                                            </InputLabel>
                                            <div style={{ height: 30, marginTop: 48, paddingLeft: 4 }}>
                                                <Slider
                                                    value={distanceRadius}
                                                    min={0}
                                                    max={500}
                                                    step={1}
                                                    onChange={this.handleChangeDistanceRadius}
                                                />
                                            </div>
                                        </FormControl>
                                    </Grid>
                                ) : null}
                                <Grid item className="text-right">
                                    <Button
                                        variant="raised"
                                        color="primary"
                                        style={{ marginTop: 16 }}
                                        onClick={this.handleSubmitSearch}
                                        type="submit"
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                          type &&  type === 5 &&  currentProfile?.type === ProfileType.Musician.ordinal && (
                                <Grid item xs={12} md={3}>
                                    <Grid item>
                                        <div>
                                            <Switch checked={isApplyToPlay} onChange={this.handleApplyToPlay} color="primary" />{" "}
                                            Apply to play
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                           type && type === ProfileType.Venue.ordinal &&  (currentProfile?.type === ProfileType.Agent.ordinal) && (
                                <Grid item xs={12} md={3}>
                                    <Grid item>
                                        <div>
                                            <Switch checked={isApplyToBook} onChange={this.handleApplyToBook} color="primary" />{" "}
                                            Apply to book
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                          type &&  type === ProfileType.Agent.ordinal  && currentProfile?.type === ProfileType.Musician.ordinal && (
                                <Grid item xs={12} md={3}>
                                    <Grid item>
                                        <div>
                                            <Switch checked={isApplyToActRoster} onChange={this.handleApplyActRoster} color="primary" />{" "}
                                            Apply to Act Roster
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        }

                    </Grid>
                </form>
            </Paper>
        );
    }
}

export default SearchFilter;
