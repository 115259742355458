import React, { Component, Fragment } from "react";
import ResponsiveTable from "../../table/ResponsiveTable";

import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";

import { Paper, Checkbox, Button } from "@material-ui/core";

import { ProfileType } from "../../../types/enum";

import AgentInvitationModal from "./AgentInvitationModal";
import AcceptRequestModal from "./AcceptRequestModal";
import ConfirmationModal from "../../modal/ConfirmationModal";
import EditAgentModal from "./EditAgentModal";
import TableRowProfile from "../../util/TableRowProfile";
import image from "../../../helper/image";

@inject("agentVenueStore", "templateStore", "userInvitationStore", "profileStore")
@withRouter
@observer
class AgentTable extends Component {
    state = {
        invitationModalState: false,
        acceptModalState: false,
        deleteModalState: false,
        editModalState: false,
        venueId: undefined,
        request: undefined,
        editedAgentVenue: undefined,
        deletedInvitation: undefined
    };

    rowData = [];

    columns = [
        { key: "agent", label: "Agent" },
        { key: "status", label: "Status" },
        { key: "bookingDay", label: "Booking Day" },
        { key: "allowManage", label: "Manage Favourite Acts" },
        { key: "allowManageSocial", label: "Manage Social Posts" },
        { key: "action", label: "" }
    ];

    constructor(props) {
        super(props);
        this.props.agentVenueStore.resetData();
        this.props.userInvitationStore.resetInvitations();
    }

    componentDidMount() {
        const venueId = this.props.match.params.id;

        this.setState(
            {
                ...this.state,
                venueId: venueId
            },
            () => {
                this.rowData = [];
            }
        );
    }

    mapBookingDay = data => {
        var bookingDay = "";

        if (data?.monday) {
            bookingDay += "Mon, ";
        }
        if (data?.tuesday) {
            bookingDay += "Tue, ";
        }
        if (data?.wednesday) {
            bookingDay += "Wed, ";
        }
        if (data?.thursday) {
            bookingDay += "Thu, ";
        }
        if (data?.friday) {
            bookingDay += "Fri, ";
        }
        if (data?.saturday) {
            bookingDay += "Sat, ";
        }
        if (data?.sunday) {
            bookingDay += "Sun, ";
        }

        return bookingDay.substr(0, bookingDay.length - 2);
    };

    mapInvitationToRowData = () => {
        this.props.userInvitationStore.invitations.forEach(data => {
            var row = {};
            row.style = {
                backgroundColor: "#EEE"
            };

            row.data = data;

            row.id = "i" + data.id;

            var jsonBookingDay = JSON.parse(data.additionalData);

            row.bookingDay = this.mapBookingDay(jsonBookingDay);

            row.agent = <TableRowProfile name={`${data.invitedFirstName} ${data.invitedLastName} - ${data.invitedEmail}`} />;

            row.status = "Invited";

            this.rowData.push(row);
        });

        return Promise.resolve();
    };

    mapPendingToRowData = () => {
        this.props.agentVenueStore.pendingAgentVenues.forEach(data => {
            var row = {};

            row.id = "p" + data.id;
            row.data = data;
            row.style = {
                backgroundColor: "#EEE"
            };

            var jsonBookingDay = JSON.parse(data.rules);
            var agent = (data.primaryProfile.type === ProfileType.Agent.ordinal) ? data.primaryProfile : data.secondaryProfile;
            row.bookingDay = this.mapBookingDay(jsonBookingDay);

            row.agent = <TableRowProfile profileId = {(data.primaryProfile.type === ProfileType.Agent.ordinal) ? data.primaryProfileId : data.secondaryProfileId} name={agent.profileName} avatarUrl={image.getProfileImage(agent)}/>;
            row.status = "Pending";
            row.action =
                data.primaryProfile.type === ProfileType.Agent.ordinal ? (
                    <Fragment>
                        <Button size="small" color="primary" className="btn-venue-request" onClick={() => this.openAcceptModal(data)}>
                            View Request
                        </Button>
                    </Fragment>
                ) : (
                    ""
                );

            this.rowData.push(row);
        });

        return Promise.resolve();
    };

    mapAcceptedToRowData = () => {
        this.props.agentVenueStore.agentVenues.forEach(data => {
            var row = {};
            var agent = data.agent;

            row.id = "a" + data.id;

            row.data = data;

            row.bookingDay = this.mapBookingDay(data);
            var fullname = ""
            if (agent && agent.profileName){
                fullname = agent.profileName
            } else if(agent && agent.firstName){
                fullname = agent.firstName + " " + agent.lastName
            }
            row.agent = <TableRowProfile profileId = { agent.id } name={fullname} avatarUrl={agent.profileImage?agent.profileImage.url:null} />;
            row.status = "Accepted";

            row.allowManage = (
                <Checkbox
                    checked={data.manage}
                    onChange={(e, checked) => this.handleToggleManage(checked, data)}
                    value={data.manage}
                />
                // <Checkbox checked={data.manage} onChange={(e, checked) => this.handleToggleManage(checked, data)} value={data.manage} />
            );

            row.allowManageSocial = (
                <Checkbox
                    checked={data.manageSocialPost}
                    onChange={(e, checked) => this.handleToggleManageSocialPost(checked, data)}
                    value={data.manageSocialPost}
                />
                // <Checkbox checked={data.manage} onChange={(e, checked) => this.handleToggleManage(checked, data)} value={data.manage} />
            );

            
            row.action = (
                <Fragment>
                    <Button color="primary" size="small" onClick={() => this.openEditModal(data)}>
                        <span style={{ paddingTop: "3px" }}>Edit</span>
                    </Button>
                </Fragment>
            );

            this.rowData.push(row);
        });
    };

    //event

    openInvitationModal = () => {
        this.setState({
            ...this.state,
            invitationModalState: true
        });
    };

    closeInvitationModal = () => {
        this.setState({
            ...this.state,
            invitationModalState: false
        });
    };

    openAcceptModal = request => {
        this.setState({
            ...this.state,
            acceptModalState: true,
            request: request
        });
    };

    closeAcceptModal = () => {
        this.setState({
            ...this.state,
            acceptModalState: false
        });
    };

    openDeleteModal = deletedRow => {
        var deletedInvitation = deletedRow.filter(data => data.status === "Invited");
        var deletedRequest = deletedRow.filter(data => data.status === "Pending");
        var deletedRelation = deletedRow.filter(data => data.status === "Accepted");
        this.setState({
            ...this.state,
            deleteModalState: true,
            deletedRelation: deletedRelation,
            deletedRequest: deletedRequest,
            deletedInvitation: deletedInvitation
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            deleteModalState: false,
            deletedRelation: undefined,
            deletedRequest: undefined
        });
    };

    openEditModal = agentVenue => {
        this.setState(
            {
                ...this.state,
                editModalState: true
            },
            () => {
                this.props.agentVenueStore.setEditedAgentVenue(agentVenue);
            }
        );
    };

    closeEditModal = () => {
        this.setState(
            {
                ...this.state,
                editModalState: false
            },
            () => {
                this.props.agentVenueStore.setEditedAgentVenue(undefined);
            }
        );
    };

    handleDelete = () => {
        const { profile } = this.props.profileStore;

        Promise.all([
            this.props.agentVenueStore.removeAgentRequests(
                this.state.deletedRequest.filter(x => x.data.primaryProfileId === profile.id).map(row => row.data.id)
            ),
            this.props.agentVenueStore.declineAgentRequests(
                this.state.deletedRequest.filter(x => x.data.primaryProfileId !== profile.id).map(row => row.data.id)
            ),
            this.props.agentVenueStore.deleteAgentVenues(this.state.deletedRelation.map(row => row.data.id)),
           
            this.props.userInvitationStore.deleteInvitations(this.state.deletedInvitation.map(row => row.data))
        ]).then(() => {
            this.props.templateStore.openSnackbar("Agents have been deleted.");
            this.closeDeleteModal();
        });
    };

    handleToggleManage = (checked, data) => {
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.updateManagePermision(checked, data.id).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    };

    handleToggleManageSocialPost = (checked, data) => {
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.handleToggleManageSocialPost(checked, data.id).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    };

    render() {
        this.rowData = [];
        this.mapInvitationToRowData().then(this.mapPendingToRowData().then(this.mapAcceptedToRowData()));

        return (
            <Fragment>
                <Paper>
                    <ResponsiveTable
                        selectable={true}
                        showToolbar={true}
                        title="Agents"
                        handleAdd={this.openInvitationModal}
                        handleDelete={this.openDeleteModal}
                        columns={this.columns}
                        rows={this.rowData}
                    />
                </Paper>
                <AgentInvitationModal open={this.state.invitationModalState} onClose={this.closeInvitationModal} venueId={this.state.venueId} />

                <EditAgentModal open={this.state.editModalState} onClose={this.closeEditModal} />

                <AcceptRequestModal open={this.state.acceptModalState} onClose={this.closeAcceptModal} request={this.state.request} />
                <ConfirmationModal
                    title="Delete Agent"
                    open={this.state.deleteModalState}
                    message="Are you sure you want to remove booking permission for this agent?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDelete}
                    confirmationLabel="Delete"
                />
            </Fragment>
        );
    }
}

export default AgentTable;
