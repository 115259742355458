import { observable, action } from "mobx";
import agent from "../agent";

class xeroStore {
    @observable xeroToken = "";
    @observable xeroListofAccountsOutgoing = [];
    @observable xeroListofAccountsIncoming = [];
    @observable outgoingAccounts = "";
    @observable incomingAccounts = "";

    @observable listXeroMappedContacts = [];
    @observable xeroContacts = [];
    @observable xeroContactsFull = [];
    @observable invoiceXeroId= "";
    @observable editedContactID= "";
    @observable editedxeroID= "";

    @observable xeroFirstName= ""
    @observable xeroLastName= ""
    @observable xeroEmail= ""
    @observable integrateReturnResult = [];
    
    @observable tenantList = []
    // this.xeroListofAccountsOutgoing = mappedAccounts.filter(x=>x.)
    // this.xeroListofAccountsIncoming = mappedAccounts
    
    @action
    resetXeroPopUp(){
        this.invoiceXeroId= ""
        this.xeroFirstName= ""
        this.xeroLastName= ""
        this.xeroEmail= ""
    }
    @action
    createMapInvoiceXeroContact(profileId,invoiceId){
        var data = {
            profileId: profileId,
            invoiceId: invoiceId,
            xeroCustomersId: this.invoiceXeroId
        }
        return agent.Xero.createMappedInvoiceXeroContact(data)
        .then(
            action(response => {
                return response
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }

    @action
    updateMapInvoiceXeroContact(profileId,invoiceId){
        var data = {
            profileId: profileId,
            invoiceId: invoiceId,
            xeroCustomersId: this.invoiceXeroId
        }
        return agent.Xero.updateMapInvoiceXeroContact(data)
        .then(
            action(response => {
                return response
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }

    @action
    deleteMapInvoiceXeroContact(profileId,invoiceId){
        var data = {
            profileId: profileId,
            invoiceId: invoiceId,
        }
        return agent.Xero.deleteMapInvoiceXeroContact(data)
        .then(
            action(response => {
                return response
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }

    @action
    createInvoiceXeroContact(profileId,invoiceId){
        var data = {
            firstName: this.xeroFirstName,
            lastName: this.xeroLastName,
            email: this.xeroEmail,
            invoiceId :invoiceId
        }
        return agent.Xero.createInvoiceXeroContact(profileId,data)
        .then(
            action(response => {
                return response
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }
    @action
    getInvoiceXeroContactById(profileId,invoiceId){
        return agent.Xero.getInvoiceXeroContactById(profileId,invoiceId)
        .then(
            action(response => {
                this.xeroFirstName = response.lastName
                this.xeroLastName = response.firstName
                this.xeroEmail = response.email
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }
    @action
    getMappedInvoiceXeroContact(profileId,invoiceId){
        return agent.Xero.getMappedInvoiceXeroContact(profileId,invoiceId)
        .then(
            action(response => {
                if(response){
                    this.invoiceXeroId = response.xeroCustomersId
                    return response;
                }else{
                    return null
                }
              
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }
    @action
    getBookingByInvoiceId(invoiceId){
        return agent.Invoice.getBookingByInvoiceId(invoiceId)
        .then(
            action(response => {
                this.xeroFirstName = response.clientFirstName
                this.xeroLastName = response.clientLastName
                this.xeroEmail = response.clientEmail
                
                return response;
            })
        )
        .finally(() => {
            this.inProgress = false;
        });
    }


    @action 
    setXeroFirstName(value){
        this.xeroFirstName = value
    }
    @action 
    setXeroLastName(value){
        this.xeroLastName = value
    }
    @action 
    setXeroEmail(value){
        this.xeroEmail = value
    }
    @action 
    setXeroDisplayId (value){
        this.xeroDisplayId = value
    }
   
    @action 
    setInvoiceXero(xeroId){
        this.invoiceXeroId = xeroId
    }

    @action
    setEditedXero(editedContactID,editedxeroID){
        this.listXeroMappedContacts.forEach(item => {
            if(item.contactId === editedContactID) {
                item.xeroId = editedxeroID
                item.isEdited = true
            }
        });
    }

    @action
    setUpdatedXero(editedContactID){
        this.listXeroMappedContacts.forEach(item => {
            if(item.contactId === editedContactID) {
                item.isUpdated = true
                var filtered = this.xeroContactsFull.filter(x=>x.value === item.xeroId)
                if(filtered.length){
                    this.xeroContacts.push(filtered[0])
                }
               
            }
        });
    }

    @action
    cancelUpdatedXero(editedContactID){
        this.listXeroMappedContacts.forEach(item => {
            if(item.contactId === editedContactID) {
                item.isUpdated = false
                this.xeroContacts = this.xeroContacts.filter(x=>x.value != item.xeroId)
                
            }
        });
    }
    @action
    cancelEditedXero(editedContactID){
        this.listXeroMappedContacts.forEach(item => {
            if(item.contactId === editedContactID) {
                item.xeroId = ""
                item.isEdited = false
            }
        });
    }
    @action
    setIncomingAccounts(value){
        this.incomingAccounts= value;
    }
    @action
    setOutgoingAccounts(value){
        this.outgoingAccounts= value;
    }
    @action
    setXeroCode(code){
        this.xeroToken = code
    }
    @action
    connectXero(profileId, data){
        return agent.Xero.connectXero(profileId,data).then(action(
            result => {
                return result;
            }
        ));
    }



    @action
    mapExistingXeroContact(profileId, contactId, xeroId){
        var body = {
            profileId: profileId,
            contactId: contactId,
            xeroId: xeroId
        }
        return agent.Xero.mapExistingXeroContact(body).then(action(
            result => {
                return result;
            }
        ));
    }
    
    @action
    createXeroContacts(profileId, contactId){
        return agent.Xero.createXeroContacts(profileId,contactId).then(action(
            result => {
                return result;
            }
        ));
    }
   
    @action
    deleteXeroMapping(id){
        return agent.Xero.deleteContactXero(id).then(action(
            result => {
                return result;
            }
        ));
    }
    
    @action
    updateContactXero(id,xeroID){
        var data = {
            xeroId : xeroID
        }
        return agent.Xero.updateContactXero(id,data).then(action(
            result => {
                return result;
            }
        ));
    }
    


    @action
    mapAccounts(profileId){
        var data = {
            incomingInvoiceXero: this.incomingAccounts,
            outgoingInvoiceXero: this.outgoingAccounts,
        }
        return agent.Xero.mapAccounts(profileId,data).then(action(
            result => {
                return result;
            }
        ));
    }
    @action
    getXeroListOfAccounts(profileId){
        return agent.Xero.getListOfAccounts(profileId).then(action(
            result => {
                var mappedAccountsOutgoing = []
                var mappedAccountsIncoming = []
                result.Accounts.forEach(item => {
                    if(item.Class === "REVENUE"){
                        mappedAccountsOutgoing.push({ value: item.Code, label: item.Code + " - "+item.Name})
                    }else if(item.Class === "EXPENSE" || item.Class === "DIRECTCOSTS"){
                        mappedAccountsIncoming.push({ value: item.Code, label: item.Code + " - "+item.Name})
                    }
                   
                });
                this.xeroListofAccountsOutgoing = mappedAccountsOutgoing
                this.xeroListofAccountsIncoming = mappedAccountsIncoming
                return result;
            }
        ));
        
    }

    @action
    getListXeroMappedContacts(profileId){
        return agent.Xero.getListXeroMappedContacts(profileId).then(action(
            result => {
                this.listXeroMappedContacts = _.orderBy(result, "contactId",'asc');
                return result;
            }
        ));
        
    }

    @action
    resetResultIntegration(){
        this.integrateReturnResult = []
    }
 
    @action
    integrateIncomingInvoice(profileId, selectedIncomingInvoice){
        var body = {
            invoiceIds: selectedIncomingInvoice
        }
        return agent.Xero.integrateIncomingInvoice(profileId, body).then(action(
            result => {
                result.failed.forEach(item => {
                    this.integrateReturnResult.push({id: item.id,invoiceNumber: item.invoiceId, type: 0, code: item.code, message:`sync failed on Invoice no ${item.invoiceId}. Reason : ${item.messages}`})
                });
                result.success.forEach(item => {
                    this.integrateReturnResult.push({id: item.invoiceId,type: 1, message:`invoice number ${item.invoiceId} successfully synced`})
                });
                return this.integrateReturnResult;
            }
        )).catch(action(err => {
           console.log(err)
        }))
    }

    @action
    integrateOutgoingInvoice(profileId, selectedOutgoingInvoice){
        var body = {
            invoiceIds: selectedOutgoingInvoice
        }
        return agent.Xero.integrateOutgoingInvoice(profileId, body).then(action(
            result => {
                result.failed.forEach(item => {
                    this.integrateReturnResult.push({id: item.id,invoiceNumber: item.invoiceId, type: 0, code: item.code, message:`sync failed on Invoice no ${item.invoiceId}. Reason : ${item.messages}`})
                });
                result.success.forEach(item => {
                    this.integrateReturnResult.push({id: item.invoiceId, type: 1, message:`invoice number ${item.invoiceId} successfully synced`})
                });
                return this.integrateReturnResult;
            }
        ));
    }

    @action
    handleSelectTenant(body){
        return agent.Xero.mapXeroTenant(body).then(action(
            result => {
               
                return result;
            }
        ));
    }
    @action
    getXeroTenantId(profileId){
        return agent.Xero.getXeroTenantId(profileId).then(action(
            result => {
                
                var options = []
                result.forEach(item => {
                    options.push({ value: item.tenantId, label: item.tenantName });
                });
                this.tenantList = options
                return options;
            }
        ));
    }

    @action
    disconnect(profileId){
        return agent.Xero.disconnect(profileId).then(action(result => {
            return result
        }))
    }
    @action
    getXeroContacts(profileId){
        return agent.Xero.getXeroContacts(profileId).then(action(
            result => {
                var xeroContacts = []
                var xeroContactsFull = []
                result.forEach(item => {
                    var name = item.Name ? item.Name : item.FirstName + " "+ item.LastName
                    if(!item.isMapped){
                        
                        xeroContacts.push({ value: item.ContactID, label: name})
                    }
                    xeroContactsFull.push({ value: item.ContactID, label: name})
                });
                this.xeroContacts = xeroContacts
                this.xeroContactsFull = xeroContactsFull
                return result;
            }
        ));
        
    }

    

}

export default new xeroStore();
