const BASE_URL = process.env.REACT_APP_BASE_URL;
import blankProfile from "../img/hotgig-loading.png";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
const blankBackground = "/hero2.jpg";

class ImageHelper {
    getProfileImage = (profile, getLargeVersion) => {
        if (profile && profile.profileImage && profile.profileImage.url200) {
            if (getLargeVersion) {
                return profile.profileImage.url400;
            }
            return profile.profileImage.url200;
        }

        // return default avatar
        return blankProfile;
    };

    getBackgroundImage = (profile, getLargeVersion) => {
        if (profile && profile.backgroundImages && profile.backgroundImages.length > 0) {
            if (getLargeVersion) {
                return profile.backgroundImages[0].url1920;
            }

            return profile.backgroundImages[0].url960;
        }
        return blankBackground;
    };

    readFileAsync = async (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        })
    }
    loadImgAsync = async (imgSrc) => {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => {
                resolve(img);
            };
            img.onerror = reject;
            img.src = imgSrc;
        })
    }
    imgToBlobAsync = async (img, canvas) => {
        return new Promise((resolve, reject) => {
            const ctxMain = canvas.getContext('2d');
            ctxMain.drawImage(img, 0, 0, canvas.width, canvas.height);
            ctxMain.canvas.toBlob(async (blob) => {
                resolve(blob)
            }, 'image/*');
        })
    }
    createCanvas(img, maxSize) {
        const canvas = document.createElement('canvas');
        if (img.width > img.height) {
            const widthMain = maxSize;
            const scaleFactorMain = widthMain / img.width;
            canvas.width = widthMain;
            canvas.height = img.height * scaleFactorMain;
        } else {
            const heightMain = maxSize;
            const scaleFactorMain = heightMain / img.height;
            canvas.width = img.width * scaleFactorMain;
            canvas.height = heightMain;
        }
        return canvas;
    }

    resizeImage = async (image, size) => {
        const imgSrc = await this.readFileAsync(image);
        const img = await this.loadImgAsync(imgSrc)
        // From the image and a canvas (for the resize), 
        // generate a blob to be uploaded
        const canvas = this.createCanvas(img, size);
        const imgBlob = await this.imgToBlobAsync(img, canvas)

        // Create a file from the blob
        const fileMain = new File([imgBlob], `${ticks}.${fileExtension}`, {
            type: 'image/*',
            lastModified: Date.now()
        });
        return fileMain;
    }
}

export default new ImageHelper();
