import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { Grid, Button, TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

@inject("authStore", "profileStore", "templateStore", "userStore")
@withRouter
@observer
class AccountEmail extends Component {
    state = {
        email: "",
        password: "",
        isDirty: false,
    };

    componentDidMount = () => {
        this.props.authStore.reset();
    };

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }
    handleChange = name => event => {
        const { currentUser } = this.props.userStore;

        if (event.target.value.length == 0 || (name == "email" && event.target.value === currentUser?.email)) {
            this.setState({ isDirty: false });
        } else {
            this.setState({ isDirty: true });
        }
        this.setState({
            [name]: event.target.value
        });
    };

    handleCancel = () => {
        this.setState({
            email: "",
            password: "",
        });
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.props.templateStore.showLoadingScreen();
        this.props.authStore.changePersonalEmail(this.state.email).then(() => {
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.openSnackbar("Account has been updated. You will be logged out automatically.");
            setTimeout(() => {
                this.props.authStore.logout().then(() => {
                    this.props.history.push("/sign-in");
                })
            }, 5000);

        }).catch(e => {
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.openSnackbar("Account update is failed, check your credentials.");
        });
    };

    handlePasswordChange = e => {
        this.props.authStore.setPassword(e.target.value);
        if (e.target.value.length == 0) {
            this.setState({ isDirty: false });
        } else {
            this.setState({ isDirty: true });
        }
    };

    handleClickShowPassword = name => () => {
        this.setState({ [name]: !this.state[name] });
    };

    render() {
        const { values } = this.props.authStore;
        const { currentUser } = this.props.userStore;

        return (
            <div>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <TextField
                            id="email"
                            label="Current Email"
                            value={currentUser?.email}
                            fullWidth
                            disabled={true}
                            classes={{ root: "text-field-readonly" }}
                            margin="normal"
                            error={this.anyError("email")}
                            helperText={this.errorText("email")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="email"
                            label="New Email"
                            value={this.state.email}
                            onChange={this.handleChange("email")}
                            fullWidth
                            classes={{ root: "text-field-readonly" }}
                            margin="normal"
                            error={this.anyError("email")}
                            helperText={this.errorText("email")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            label="Confirm Your Password"
                            value={values.password}
                            onChange={this.handlePasswordChange}
                            fullWidth
                            type={this.state.showPassword ? "text" : "password"}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={this.handleClickShowPassword("showPassword")}
                                            onMouseDown={this.handleMouseDownPassword}
                                        >
                                            {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={this.anyError("password")}
                            helperText={this.errorText("password")}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ fontSize: 14 }}>
                        Please note: This action will change your login credentials.  For security, you will be logged out automatically.  You will need to verify your new email address and re-submit your password. Please check your inbox and spam folders for the verification email.
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} className="text-xs-right">
                                <Button onClick={this.handleCancel} disabled={!this.state.isDirty}>Close</Button>
                                <Button
                                    variant="contained"
                                    onClick={this.handleSubmitForm}
                                    style={{ marginLeft: 10 }}
                                    color="primary"
                                    disabled={!this.state.isDirty}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default AccountEmail;
