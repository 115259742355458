import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

import { withStyles } from "@material-ui/core/styles";

import { Paper, Checkbox, Tooltip } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";

import UserTableToolbar from "./UserTableToolbar";

import { withRouter } from "react-router";
import MemberStore from "../../stores/organisationMemberStore.js";

@inject("organisationStore", "organisationMemberStore", "stateStore", "regionStore", "templateStore","memberProfileStore")
@withRouter
@observer
class UserTable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            rowsPerPage: 5
        };
    }

    componentDidMount() {
        const orgId = this.props.match.params.id;
        const level = this.props.level;

        this.props.organisationMemberStore.loadMembers(level, orgId);
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePermission = (id, permission, value) => {};

    handlePermissionChange = (e, organisationMember, type) => {
        this.props.organisationMemberStore.setMemberPermission(organisationMember, type, e.target.checked);
        this.props.templateStore.closeOrganisationBlock();
        this.props.templateStore.closeStateBlock();
        this.props.templateStore.closeRegionBlock();
        this.props.templateStore.closeMemberBlock();
    };

    invertDirection = (direction) =>{
        if (direction === "asc") {
            return "desc";
        } else {
            return "asc"
        }
    }
    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleClearSelected = () => {
        this.setState({ selected: [] });
    };

    handleSelectAllClick = (event, checked) => {
        const { members } = this.props.organisationMemberStore;

        if (checked) {
            this.setState({ selected: members.map(n => n.email) });
            return;
        }
        this.setState({ selected: [] });
    };
    handleSort = (columnName) => {
        const { members } = this.props.organisationMemberStore;
        var newSortDir =  this.state.orderBy === columnName ? this.invertDirection(this.state.order) : "asc";
        var temp=  _.orderBy(members, columnName,newSortDir); // Use Lodash to sort array by 'name'
        MemberStore.setMembers(temp);
        this.setState({ ...this.state,
            order: newSortDir,
             orderBy: columnName
             })
    }

    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page } = this.state;
        const { members } = this.props.organisationMemberStore;

        var canManage = false;
        var editLabel = "";
        if (this.props.level === 1) {
            canManage = this.props.organisationStore.canManage || this.props.organisationStore.id === undefined;
            editLabel = `Edit ${this.props.organisationStore.level1Label}`;
        } else if (this.props.level === 2) {
            canManage = this.props.stateStore.canManage;
            editLabel = `Edit ${this.props.organisationStore.level2Label}`;
        } else if (this.props.level === 3) {
            canManage = this.props.regionStore.canManage;
            editLabel = `Edit ${this.props.organisationStore.level3Label}`;
        } else if (this.props.level === 4) {
            canManage = this.props.memberProfileStore.canManage;
            editLabel = `Manage Staff`;
        }

        var columnData = [
            { columnTitle: "name", numeric: false, disablePadding: false, label: "Name", width: "20%" },
            { columnTitle: "email", numeric: false, disablePadding: false, label: "Email", width: "20%" },
            { columnTitle: "edit", numeric: false, disablePadding: false, label: "Edit Venues", width: "8%" },
            { columnTitle: "financial", numeric: false, disablePadding: false, label: "Pay Invoices", width: "8%" },
            { columnTitle: "booking", numeric: false, disablePadding: false, label: "Manage Bookings", width: "8%" },
            { columnTitle: "manage", numeric: false, disablePadding: false, label: `${editLabel}`, width: "8%" },
            { columnTitle: "promote", numeric: false, disablePadding: false, label: `Manage Promotions`, width: "8%" },
            { columnTitle: "viewOnly", numeric: false, disablePadding: false, label: `View Only`, width: "8%" }, 
            { columnTitle: "title", numeric: false, disablePadding: false, label: "Title", width: "12%" },
        ];

        return (
            <Paper className={classes.root}>
                <UserTableToolbar level={this.props.level} selected={selected} handleClearSelected={this.handleClearSelected} />
                <div className={classes.tableWrapper}>
                    <Table>
                        <TableHead>
                            <TableRow className="userTable-row">
                                {canManage ? (
                                    <TableCell padding="checkbox" style={{ width: "16px" }}>
                                        <Checkbox
                                            indeterminate={selected.length > 0 && selected.length < members.length}
                                            checked={selected.length === members.length}
                                            onChange={this.handleSelectAllClick}
                                        />
                                    </TableCell>
                                ) : (
                                    ""
                                )}
                                {columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.columnTitle}
                                            padding={column.disablePadding ? "none" : "default"}
                                            sortDirection={orderBy === column.columnTitle ? order : false}
                                            style={{ width: column.width }}
                                        >
                                            <Tooltip title="Sort" placement={column.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                                                <TableSortLabel active={orderBy === column.columnTitle} direction={order}>
                                                    <div onClick={() => this.handleSort(column.columnTitle)}>{column.label}</div>
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    );
                                }, this)}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                const isSelected = this.isSelected(n.email);
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={n.email}
                                        selected={isSelected}
                                        className="userTable-row"
                                    >
                                        {canManage ? (
                                            <TableCell padding="checkbox" style={{ width: "16px" }}>
                                                <Checkbox checked={isSelected} onClick={event => this.handleClick(event, n.email)} />
                                            </TableCell>
                                        ) : (
                                            ""
                                        )}
                                        <TableCell>
                                            {!n.userId && (
                                                <Tooltip title="User invited" enterDelay={300}>
                                                    <strong>{n.name} {n.lastName}</strong>
                                                </Tooltip>
                                            )}
                                            {n.userId && <span>{n.name} {n.lastName}</span>}
                                        </TableCell>
                                        <TableCell>{n.email}</TableCell>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                disabled={!canManage}
                                                checked={n.edit}
                                                onChange={e => this.handlePermissionChange(e, n, "edit")}
                                            />
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                disabled={!canManage}
                                                checked={n.financial}
                                                onChange={e => this.handlePermissionChange(e, n, "financial")}
                                            />
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                disabled={!canManage}
                                                checked={n.booking}
                                                onChange={e => this.handlePermissionChange(e, n, "booking")}
                                            />
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                disabled={!canManage}
                                                checked={n.manage}
                                                onChange={e => this.handlePermissionChange(e, n, "manage")}
                                            />
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                disabled={!canManage}
                                                checked={n.promote}
                                                onChange={e => this.handlePermissionChange(e, n, "promote")}
                                            />
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                disabled={!canManage}
                                                checked={n.viewOnly ? n.viewOnly : false}
                                                onChange={e => this.handlePermissionChange(e, n, "viewOnly")}
                                            />
                                        </TableCell>
                                        <TableCell>{n.title}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={8}
                                    rowsPerPage={rowsPerPage}
                                    SelectProps={{ className: "transparent-select" }}
                                    page={page}
                                    count={members.length}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
        );
    }
}

UserTable.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto"
    }
});

export default withStyles(styles)(UserTable);
