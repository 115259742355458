import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { DialogActions, DialogContent, InputLabel, Paper, Grid, Button, Chip } from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";

import ResponsiveTable from '../../table/ResponsiveTable';
import ModalContainer from '../../modal/ModalContainer';
import SelectField from '../../fields/SelectField';
import { Promise } from "bluebird";
import CurrencyFormat from 'react-currency-format';
import { VenueMenuType } from '../../../types/enum';

@inject("venueStore", "venueMenuStore", "templateStore")
@observer
class ImportFoodModal extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            selectedVenue: undefined, 
            selectedRows: undefined, 
            rows: []
        };
    }

    componentDidMount() {
        this.props.venueStore.getUserVenues();
    }

    getCheckedRows = rows => {
        this.manipulateRowsValue(rows).then(newRows => {
            this.setState({ ...this.state, selectedRows: newRows });
        });
    }

    manipulateRowsValue = rows => {
        return new Promise.resolve(rows.map(row => {
            return {
                ...row, 
                id: undefined, 
                price: row.price.props.value, 
                tags: row.tags !== '' && row.tags.length > 0 ? row.tags.map(tag => {
                    return tag.props.label
                }) : ''
            }
        }));
    }

    getColumns = () => {
        const foodColumn = [
            { key: "name", label: "Food Name", width: '25%' }, 
            { key: "section", label: "Food Section", width: '10%' }, 
            { key: "price", label: "Price", width: '10%' }, 
            { key: "tags", label: "Tags", width: '25%' }, 
            { key: "viewDescription", label: "Description", width: '30%' }
        ];
        return foodColumn;
    }

    renderTableCustomActions = () => {
        const {selectedRows} = this.state;
        return (
            <Fragment>
                {
                    typeof selectedRows !== 'undefined' && selectedRows.length > 0 
                    ?   <Button onClick={this.copyFoodMenu}>
                            <FileCopy style={{ color: '#ffffff' }} />
                            <span style={{ paddingTop: 3, color: '#ffffff' }}>Copy Menu</span>
                        </Button> 
                    : ''
                }
            </Fragment>
        )
    }

    copyFoodMenu = () => {
        const {selectedRows} = this.state;
        this.props.venueMenuStore.copySelectedMenu(selectedRows, 0);
        this.props.templateStore.openSnackbar("Successfully copy food menu.");
    }

    closeImportFoodModal = () => {
        const { closeHandler } = this.props;
        this.setState(this.getInitialState());
        closeHandler();
    }

    availableVenue = () => {
        const {venues} = this.props.venueStore;
        return venues.filter(venue => venue.id !== Number(this.props.profileId)).map(venue => {
            return {
                label: venue.profileName, 
                value: venue.id
            }
        });
    }

    onVenueChange = value => {
        this.props.venueMenuStore.getMenuListFromSelectedVenue(value).then(response => {
            if(response) {
                return response.menus.filter(menu => menu.type === VenueMenuType.Food.ordinal).map((menu, i) => {
                    return {
                        id: menu.id, 
                        name: menu.name, 
                        price: <CurrencyFormat value={menu.price} displayType={'text'} thousandSeparator={true} prefix={this.props.currency} />, 
                        section: this.props.venueMenuStore.getFoodSectionLabel(menu.section), 
                        description: menu.description, 
                        viewDescription: <div dangerouslySetInnerHTML={{ __html: menu.description }}></div>, 
                        tags: menu.tags.length > 0 ? menu.tags.split(',').map((tag, j) => <Chip key={j} label={tag} />) : ''
                    }
                })
            }
        }).then(foods => {
            this.setState({ 
                ...this.state, 
                selectedVenue: value, 
                rows: foods
            });
        });
    }

    render() {
        const {open} = this.props;
        const {selectedVenue, rows} = this.state;

        return (
            <ModalContainer open={ open } title="Copy Food Menu">
                <DialogContent style={{minHeight: 300}}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <InputLabel>Select Venue</InputLabel>
                            <SelectField 
                                placeholder="Select Venue" 
                                suggestions={this.availableVenue()}
                                onChange={this.onVenueChange}
                                value={selectedVenue}
                            />
                        </Grid>
                        {
                            !selectedVenue
                            ? null
                            :   <Grid item xs={12} style={{paddingTop: 15}}>
                                    <Fragment>
                                        <Paper>
                                            <ResponsiveTable
                                                customActions={this.renderTableCustomActions()}
                                                getCheckedRows={this.getCheckedRows}
                                                selectable={true}
                                                showToolbar={true}
                                                title="Food Menu"
                                                columns={this.getColumns()}
                                                rows={rows}
                                            />
                                        </Paper>
                                    </Fragment>
                                </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ this.closeImportFoodModal }>
                        Close
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default ImportFoodModal;
