import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import SetListForm from "../../components/setList/SetListForm";
import { Grid, Typography, Paper } from "@material-ui/core";

@inject("songListStore", "setListStore", "profileStore")
@observer
class SetListEdit extends Component {
    render() {
        const { title } = this.props.setListStore;
        const { currentProfile } = this.props.profileStore;

        const breadcrumb = [
            { name: "Dashboard", link: "/" },
            { name: "Manage", link: "/" },
            { name: "My Set Lists", link: "/musician/setlist" },
            { name: title ? title : "Edit" }
        ];

        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title={"Set List"} />
                    <Paper className="org-paper-form">
                        <Grid container spacing={8}>
                            <Grid item xs={12} lg={3}>
                                <div className="sticky-information">
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Set List
                                    </Typography>
                                    <Typography gutterBottom className="side-helper-text">
                                        Create new Set list, a collection of your songs. Pick your songs and put to the set list
                                    </Typography>
                                    <Typography gutterBottom className="side-helper-text">
                                        We highly recommend you complete your profile on Hot Giggity, which will help others get to know you and help you
                                        stand out from the crowd.
                                    </Typography>
                                    <Typography>
                                        <b>Tip:</b>
                                    </Typography>
                                    <Typography className="side-helper-text">You can choose the songs as many as you want.</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                {currentProfile && <SetListForm id={this.props.match.params.id} type="musician" profileId={currentProfile.id} />}
                            </Grid>
                        </Grid>
                    </Paper>
                </MainContainer>
            </main>
        );
    }
}

export default SetListEdit;
