import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Card , Typography, CardContent, Grid, Button } from '@material-ui/core';

import ConfirmationStripeModal from '../modal/ConfirmationStripeModal';

@inject("userStore", "billingStore")
@observer
class BillingInformation extends Component {
    state = { 
        isLoading: true,
        isRegistered: this.props.card != null ? true : false,
        openCreditCardDialog: false,
        billingStartDate: "",
        billingStatus: "",
        billingCycle: "",
        subsctiptionPlan: "",
        creditCardInformation: "",
    }

    handleCloseDialog = () => {
        this.setState({
            openCreditCardDialog: false,
        })
    };

    handleOnSuccessDialog = () => {
        this.props.updateCard();
        this.setState({
            openCreditCardDialog: false,
        })
    }

    handleOpenDialog = () => {
        this.setState({
            openCreditCardDialog: true,
        });
    };

    render() { 
        const title = "Update Credit Card Detail";
        return ( 
            <Card>
                <CardContent>
                    <Typography variant="h5">
                        Billing Information
                    </Typography>
                    <br />
                    <Grid container>
                        <Grid item xs={4}>
                            Billing Start Date
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.billingInfoStartDate}
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={4}>
                            Billing Status
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.billingInfoStatus}
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={4}>
                            Billing Cycle
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.billingInfoCycle}
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={4}>
                            Current Plan
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.billingInfoSubsctiptionPlan}
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={4}>
                            Credit Card
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.billingInfoCreditCardInformation}
                        </Grid>
                    </Grid>
                    <br />
                    <Button variant="contained" color="primary" onClick={this.handleOpenDialog}>
                        {this.props.billingInfoIsRegistered ? "Update Credit Card" : "Add Credit Card"} 
                    </Button>
                    <ConfirmationStripeModal 
                        customer={this.props.customer}
                        open={this.state.openCreditCardDialog}
                        onSuccess={this.handleOnSuccessDialog}
                        onClose={this.handleCloseDialog}
                        fullWidth={true}
                        isSubscribe={false}
                        title={title}
                        confirmationHandler={this.handleOnSuccessDialog}
                        declineHandler={this.handleCloseDialog}
                        confirmationLabel={"Update"}
                     />
                </CardContent>
            </Card>
        );
    }
}
 
export default BillingInformation;