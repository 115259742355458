import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, TextField, MenuItem } from "@material-ui/core";

@inject("bookingRequestStore")
@observer
class BookingNumberSet extends Component {
    handleNumberSetsChange = e => {
        this.props.bookingRequestStore.setBooking("setNumber", e.target.value);
    };

    render() {
        let { booking } = this.props.bookingRequestStore;
        let { setNumber } = booking;
        let { readOnly } = this.props;

        return (
            <Grid item xs={12} lg={6}>
                <TextField select label="Number of Sets" value={setNumber} onChange={this.handleNumberSetsChange} fullWidth disabled={readOnly}>
                    <MenuItem key={0} value={0}>
                        0
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                        1
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                        2
                    </MenuItem>
                    <MenuItem key={3} value={3}>
                        3
                    </MenuItem>
                    <MenuItem key={4} value={4}>
                        4
                    </MenuItem>
                    <MenuItem key={5} value={5}>
                        5
                    </MenuItem>
                    <MenuItem key={6} value={6}>
                        6
                    </MenuItem>
                    <MenuItem key={7} value={7}>
                        7
                    </MenuItem>
                    <MenuItem key={8} value={8}>
                        8
                    </MenuItem>
                    <MenuItem key={9} value={9}>
                        9
                    </MenuItem>
                    <MenuItem key={10} value={10}>
                        10
                    </MenuItem>
                </TextField>
            </Grid>
        );
    }
}

export default BookingNumberSet;
