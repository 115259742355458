import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { BookingStatusType, BookingType, ProfileType } from "../../../types/enum";

import { Grid, Switch, FormControlLabel } from "@material-ui/core";

import moment from "moment";

@inject("bookingRequestStore", "profileStore")
@withRouter
@observer
class BookingSocialDetails extends Component {
    componentDidMount() {
        let { booking } = this.props.bookingRequestStore;
        let { sharePermission, shareUpdate } = booking;
        if (sharePermission === undefined) this.props.bookingRequestStore.setBooking("sharePermission", true);
        if (shareUpdate === undefined) this.props.bookingRequestStore.setBooking("shareUpdate", true);
    }

    handleSharePermissionChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("sharePermission", checked);
        if (!checked) {
            this.props.bookingRequestStore.setBooking("shareUpdate", checked);
        }
        this.setState({
            ...this.state
        });
    };

    handleShareUpdateChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("shareUpdate", checked);
        this.setState({
            ...this.state
        });
    };

    handleDisplayGigChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("displayGig", checked);
        this.setState({
            ...this.state
        });
    };

    render() {
        let { booking } = this.props.bookingRequestStore;
        let { status, sharePermission, shareUpdate, displayGig, createdById, dateStart, type, createdBy } = booking;

        const { currentProfile } = this.props.profileStore;
        const isStaff = currentProfile && currentProfile.type === ProfileType.Staff.ordinal;
        const { readOnly } = this.props;
        let confirmedEditable =
            status !== BookingStatusType.Cancelled.ordinal &&
            status !== BookingStatusType.Completed.ordinal &&
            moment(dateStart).endOf("day") >= moment() &&
            (!createdById || createdById === currentProfile.id || isStaff);

        return (
            <Grid container spacing={8}>
                {/* <Grid item xs={12} md={6} className="gridBookingForm">
                    <FormControlLabel
                        control={
                            sharePermission || sharePermission == undefined ? (
                                <Switch color="primary" disabled={readOnly || !confirmedEditable} checked={true} onChange={this.handleSharePermissionChange} />
                            ) : (
                                <Switch color="primary" disabled={readOnly || !confirmedEditable} checked={false} onChange={this.handleSharePermissionChange} />
                            )
                        }
                        label="Post booking to Facebook"
                    />
                </Grid> */}
                {type === BookingType.Normal.ordinal && createdBy?.type == ProfileType.Musician.ordinal &&
                    <Grid item xs={12} md={12} className="gridBookingForm">
                        <FormControlLabel
                            control={
                                shareUpdate || shareUpdate == undefined ? (
                                    <Switch
                                        color="primary"
                                        checked={true}
                                        onChange={this.handleShareUpdateChange}
                                        disabled={readOnly || !sharePermission || !confirmedEditable}
                                    />
                                ) : (
                                    <Switch
                                        color="primary"
                                        checked={false}
                                        onChange={this.handleShareUpdateChange}
                                        disabled={readOnly || !sharePermission || !confirmedEditable}
                                    />
                                )
                            }
                            label="Post booking update to Facebook"
                        />
                    </Grid>
                }
                {/* {type === BookingType.Adhoc.ordinal && (
                    <Grid item xs={12} md={6} className="gridBookingForm">
                        <FormControlLabel
                            control={
                                displayGig ? (
                                    <Switch color="primary" checked={true} onChange={this.handleDisplayGigChange} />
                                ) : (
                                    <Switch color="primary" checked={false} onChange={this.handleDisplayGigChange} />
                                )
                            }
                            label="Display gig in public profile"
                        />
                    </Grid>
                )} */}
                <Grid item xs={12} md={6} className="gridBookingForm">
                    <FormControlLabel
                        control={
                            displayGig || displayGig == undefined ? (
                                <Switch color="primary"  disabled={readOnly} checked={true} onChange={this.handleDisplayGigChange} />
                            ) : (
                                <Switch color="primary"  disabled={readOnly} checked={false} onChange={this.handleDisplayGigChange} />
                            )
                        }
                        label="Display gig in public profile"
                    />
                </Grid>
            </Grid>
        );
    }
}

export default BookingSocialDetails;
