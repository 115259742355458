import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, IconButton, Card, CardHeader, Avatar } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ClearIcon from "@material-ui/icons/Clear";

import { withRouter } from "react-router-dom";

import ModalContainer from "../../modal/ModalContainer";
import ProfileSearchBox from "../../fields/ProfileSearchBox";
import Instruments from "../../fields/MultiSelectInstrument";
import image from "../../../helper/image";

@withRouter
@inject("profileStore", "lineupStore")
@observer
class NewLineupModal extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open) {
            this.props.lineupStore.resetNewLineupData();
        }
    }

    handleSelectMusician = musician => {
        this.props.lineupStore.setNewPlayerLineup(musician);
    };

    handleChangeInstruments = instruments => {
        this.props.lineupStore.setNewPlayerInstruments(instruments);
    };

    handleAddToLineup = () => {
        this.props.lineupStore.addNewPlayerToLineup(this.props.match.params.id).then(() => {
            this.props.onClose();
        });
    };

    getExcludedProfile = () => {
        const { lineups } = this.props.lineupStore;

        return lineups.map(lineup => {
            return lineup.profile;
        });
    };

    render() {
        const { open } = this.props;

        const { newPlayerLineup, newPlayerInstruments, errors } = this.props.lineupStore;

        return (
            <ModalContainer title="New Performer" open={open} onClose={this.props.onClose}>
                <DialogContent style={{ height: "300px" }}>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            {newPlayerLineup ? (
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                alt={newPlayerLineup.fullName}
                                                src={image.getProfileImage(newPlayerLineup)}
                                                style={{
                                                    width: 40,
                                                    height: 40
                                                }}
                                            />
                                        }
                                        action={
                                            <IconButton onClick={() => this.handleSelectMusician(undefined)}>
                                                <ClearIcon />
                                            </IconButton>
                                        }
                                        title={newPlayerLineup.fullName}
                                    />
                                </Card>
                            ) : (
                                <ProfileSearchBox
                                    height="auto"
                                    id={`searchMusicianField`}
                                    placeholder="Search Musician"
                                    handleSearch={value => this.props.profileStore.searchMusician(value)}
                                    handleChange={this.handleSelectMusician}
                                    exclude={this.getExcludedProfile()}
                                    disableInvite
                                    error={errors && errors["performer"]}
                                    helperText={errors && errors["performer"] ? errors["performer"] : ""}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Instruments
                                id="instruments"
                                onChange={this.handleChangeInstruments}
                                value={newPlayerInstruments}
                                placeholder="Select Instrument"
                                label="Instruments/Skill Sets"
                                error={errors && errors["instrument"]}
                                helperText={errors && errors["instrument"] ? errors["instrument"] : ""}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Close</Button>
                    <Button color="primary" onClick={this.handleAddToLineup}>
                        Add to Lineup
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default NewLineupModal;
