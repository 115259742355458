import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import moment from "moment"
import { Promise } from "bluebird";
import { Storage } from "aws-amplify";
import CacheHelper from '../helper/cache';

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class GigPromotionStore{

    @observable pendingGigPromotions = [];
    @observable publishedGigPromotions = [];
    @observable completedGigPromotions = [];

    @observable actImages = [];
    @observable gigPromotion = {
        title: undefined,
        tempTitle: "",
        actId: undefined,
        actName: "",
        venueId: undefined,
        venueName: "",
        venue: undefined,
        dateStart: new Date(),
        timeStart: new Date(),
        timeEnd: new Date(),
        ticketUrl: "",
        actGenres: undefined,
        actVideoUrl: undefined,
        actDescription: undefined,
        gigImage: undefined,
        isDraft: false,
        published: false,
        venueContact: "",
        venueAddress: "",
        venuePhone: "",
        venueEmail: ""
    }

    @action
    resetGigPromotion(){
        this.actImages = [];
        this.gigPromotion = {
            title: undefined,
            tempTitle: "",
            actId: undefined,
            actName: "",
            venueId: undefined,
            venueName: "",
            venue: undefined,
            dateStart: new Date(),
            timeStart: new Date(),
            timeEnd: new Date(),
            ticketUrl: "",
            actGenres: undefined,
            actVideoUrl: undefined,
            actDescription: undefined,
            gigImage: undefined,
            isDraft: false,
            published: false,
            venueContact: "",
            venueAddress: "",
            venuePhone: "",
            venueEmail: "",
            actGenres: undefined,
            actVideoUrl: undefined,
        }
    }

    @action
    getGigPromotionDetail(id){
        this.actImages = [];
        return agent.GigPromotion.getById(id).then(action(
            async response => {
                this.gigPromotion = response;
    
                this.gigPromotion.tempTitle = response.title//`${response.actName}@${response.venue?.profileName}`
                this.gigPromotion.venueContact = this.gigPromotion.venue?.contactName;
                this.gigPromotion.venueAddress = "";
                this.gigPromotion.venuePhone = this.gigPromotion.venue?.contactPhone;
                this.gigPromotion.venueEmail = this.gigPromotion.venue?.email;  
       
                let imageUrl = await CacheHelper.getImgLink(this.gigPromotion.imageUrl);
       
                runInAction(() => {
                    if(this.gigPromotion.gigImageId){
                        this.gigPromotion.gigImage = {
                            id: this.gigPromotion.gigImageId,
                            url: imageUrl
                        }
                    }
                })
           
                if(response.gigActImageIds){
                    // var actImageIds = response.gigActImageIds.split(",");
                    response.gigActImages.forEach(async actImage => {
                        let actImageUrl = await CacheHelper.getImgLink(actImage.url)
                        runInAction(() => {
                            this.actImages.push({
                                id: parseInt(actImage.id, 10),
                                url: actImageUrl
                            })
                        })
                    })
                }
            
               
            }
        ))
    }

    @action
    setGigPromotion(key, value)
    {
        this.gigPromotion[key] = value;
    }
    
    @action
    addActImages(images){
        const MAX_IMAGES = 6;
        if( (images.length + this.actImages.length) <= MAX_IMAGES){
            images.forEach(image => {
                image.url = image.preview;
                this.actImages.push(image);
            })
        }
    }

    @action
    deleteActImages(image){
        this.actImages = this.actImages.filter(x => x.url !== image.url)
        if(image.id){
            return agent.GigPromotion.deleteGigPromotionImage(image.id).then( () => {
                return this.actImages;
            });
        }
        else{
            return this.actImages;
        }
    }

    @action
    removeGigPromotionImage(imageId){
        if(imageId){
            return agent.GigPromotion.deleteGigPromotionImage(imageId).then( () => {
                this.gigPromotion.gigImage = undefined;
                return Promise.resolve();
            })
        }
        else{
            return Promise.resolve();
        }
    }
    
    @action
    resetActImages(){
        this.actImages = [];
    }

    @action
    saveGigPromotion(){
        const data = {
            isDraft: this.gigPromotion.isDraft,
            published: this.gigPromotion.published,
            ticketUrl: this.gigPromotion.ticketUrl,
            actDescription: this.gigPromotion.actDescription,
            timeStart: moment(this.gigPromotion.timeStart, "HH:mm")
                        .local()
                        .format("HH:mm"),
            timeEnd: moment(this.gigPromotion.timeEnd, "HH:mm")
                        .local()
                        .format("HH:mm"),
            dateStart: moment(this.gigPromotion.dateStart).endOf("day"),
            venueId: this.gigPromotion.venueId,
            actId: this.gigPromotion.actId,
            actName: this.gigPromotion.actName,
            actVideoUrl: this.gigPromotion.actVideoUrl,
            actGenres: this.gigPromotion.actGenres,
            id: this.gigPromotion.id,
            title: this.gigPromotion.title
        }
        
        return agent.GigPromotion.saveGigPromotion(data).then( response => {
            if(this.gigPromotion.gigImage || this.actImages){
                return Promise.all([
                    this.gigPromotion.gigImage ? this.setGigPromotionImage(response.id) : undefined,
                    this.actImages ? this.setGigPromotionActImage(response.id) : undefined
                ]).then((response) => {
                    return Promise.resolve();
                }, () => {
                    return Promise.resolve();
                })
            }
        })
    }

    @action
    async setGigPromotionImage(gigPromotionId) {
        var image = this.gigPromotion.gigImage;
        console.log(image)
        const fileExtension = image.name.split('.').pop();
        var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
        var name = `${ticks}.${fileExtension}`;
        var result = await this.uploadGigPromotionImage(gigPromotionId, image, name);
        var gigImage = {
            url: result.key,
            filename: name,
            mime: image.type
        }
     
        var uploadedImage = await agent.GigPromotion.uploadGigPromotionImage(gigPromotionId, gigImage);
        return uploadedImage
        
    }

    @action
    async setGigPromotionActImage(gigPromotionId) {

        var images = this.actImages;
        let gigImages = [];
        for (const image of images) {
            const fileExtension = image.name.split('.').pop();
            var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
            var name = `${ticks}.${fileExtension}`;
            var result = await this.uploadGigPromotionActImage(gigPromotionId, image, name);
            var gigImage = {
                url: result.key,
                filename: name,
                mime: image.type
            }
            gigImages.push(gigImage);
        }

        var uploadedImage = gigImages.length > 0 ? await agent.GigPromotion.uploadGigPromotionActImage(gigPromotionId, gigImages) : null;
        return uploadedImage
        
    }

    async uploadGigPromotionActImage(id, profileImage, name) {
        try {
            const result = await Storage.put(
                `promotion/gig/act/image/${id}/${name}`,
                profileImage
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }

    async uploadGigPromotionImage(id, profileImage, name) {
        try {
            const result = await Storage.put(
                `promotion/gig/image/${id}/${name}`,
                profileImage
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }

    @action
    removeGigPromotion(gigPromotionId){
        return agent.GigPromotion.removeGigPromotion(gigPromotionId).then(action(
            response => {
                console.log(response);
                this.pendingGigPromotions = this.pendingGigPromotions.filter(x => x.id !== response.id);
                this.publishedGigPromotions = this.publishedGigPromotions.filter(x => x.id !== response.id);
                this.completedGigPromotions = this.completedGigPromotions.filter(x => x.id !== response.id);
                return Promise.resolve();
            }
        ))
    }

    @action
    getPendingGigPromotion(){
        return agent.GigPromotion.getPending().then(action(
            async response => {
                for (const gigPromotion of response) {
                    gigPromotion.imageUrl = await CacheHelper.getImgLink(gigPromotion.imageUrl)
                }
                runInAction(() => {
                    this.pendingGigPromotions = response;
                })
                return this.pendingGigPromotions;
            }
        ))
    }

    @action
    getPublishedGigPromotion(){
        return agent.GigPromotion.getPublished().then(action(
            async response => {
                for (const gigPromotion of response) {
                    gigPromotion.imageUrl = await CacheHelper.getImgLink(gigPromotion.imageUrl)
                }
                runInAction(() => {
                    this.publishedGigPromotions = response;
                })
                return this.publishedGigPromotions ;
            }
        ))
    }

    @action
    getCompletedGigPromotion(){
        return agent.GigPromotion.getCompleted().then(action(
            async response => {
                for (const gigPromotion of response) {
                    gigPromotion.imageUrl = await CacheHelper.getImgLink(gigPromotion.imageUrl)
                }
                runInAction(() => {
                    this.completedGigPromotions = response;
                })
                return this.completedGigPromotions;
            }
        ))
    }
}

export default new GigPromotionStore();