import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Grid, Button, Switch } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Grow from "@material-ui/core/Grow";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import SearchCard from "../searchPage/SearchCard";
import SearchCardAct from "../searchPage/SearchCardAct";

import ModalContainer from "../modal/ModalContainer";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import RichTextEditor from "../richTextEditor/RichTextEditor";

import { ProfileType } from "../../types/enum";
import { default as AgentActInvitationModal } from "../util/ActInvitation/ActInvitationModal";
import StaffActRosterInvitationModal from "../venue/staffRoster/StaffActRosterInvitationModal";

@inject("profileStore", "templateStore", "agentMusicianStore", "agentVenueStore", "agentActStore", "searchStore", "venueActStore", "userStore")
@withRouter
@observer
class SearchResult extends Component {
    state = {
        mainSearchValue: undefined,
        venueInvitationModalState: false,
        actInvitationModalState: false
    };

    openVenueInvitationModal = () => {
        this.setState({
            ...this.state,
            venueInvitationModalState: true
        });
    };

    closeInvitationModal = () => {
        this.setState(
            {
                ...this.state,
                actInvitationModalState: false,
                venueInvitationModalState: false
            },
            () => {
                //reset venue invited id and message
                this.props.agentVenueStore.setInvitedId(undefined);
                this.props.agentVenueStore.setInvitationMessage("");
            }
        );
    };

    handleInviteVenue = () => {
        this.props.agentVenueStore.inviteVenueByAgent().then(response => {
            this.closeInvitationModal();
            this.props.templateStore.openSnackbar("Venue has been invited.");
        });
    };

    handleInviteEvent = (value, type) => {
        if (this.props.profileStore.profile) {
            var profileType = this.props.profileStore.profile?.type;
            if (type === "musician") {
            } else if (type === "venue") {
                if (profileType === ProfileType.Agent.ordinal ) {
                    this.props.agentVenueStore.setInvitedId(value);
                    this.openVenueInvitationModal();
                }
            }
        }
    };

    handleAddRemoveFavourite = (act) => {
        const { showResult } = this.props.searchStore;
        if(act.isFavourite){
            this.props.userStore.removeActAsUserFavourite(act.id).then(result => {
                this.props.searchStore.setFavouriteOnSearch(act.id, !act.isFavourite)
          
            });
        }else{
            this.props.userStore.addActAsUserFavourite(act.id).then(result => {
                this.props.searchStore.setFavouriteOnSearch(act.id, !act.isFavourite)
            });
        }
       
    };
    
    venueInvitationMessageHandler = value => this.props.agentVenueStore.setInvitationMessage(value);

    renderDaySwitch = () => {
        return (
            <Grid container spacing={0}>
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(data => (
                    <Grid item xs={3} md={12} key={data}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.agentVenueStore.dayToBook[data.toLowerCase()]}
                                    onChange={() => this.props.agentVenueStore.toggleDayToBook(data.toLowerCase())}
                                    value={data.toLowerCase()}
                                    color="primary"
                                />
                            }
                            label={data}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    renderInvitationModal = (onChangeHandler, value, inviteHandler, open, type) => (
        <ModalContainer open={open} title="Invitation Detail" onClose={this.closeInvitationModal}>
            <DialogContent>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={type === "venue" ? 10 : 12}>
                        <InputLabel>Message</InputLabel>
                        <RichTextEditor onChange={onChangeHandler} value={value} />
                    </Grid>
                    {type === "venue" ? (
                        <Grid item xs={12} lg={2}>
                            <InputLabel>Day to Book</InputLabel>
                            {this.renderDaySwitch()}
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.closeInvitationModal}>Close</Button>
                <Button color="primary" onClick={inviteHandler} style={{ marginRight: "8px" }}>
                    Invite
                </Button>
            </DialogActions>
        </ModalContainer>
    );

    getProfileUrl = profile => {
        return profile.vanityUrl && profile.vanityUrl.length > 3 ? `/${profile.vanityUrl}` : `/profile/${profile.id}`;
    };

    handleOpenActInvitationModal = actProfile => {
        const { currentProfile } = this.props.profileStore;
        this.props.venueActStore.setInvitedActs([actProfile]);
        if (currentProfile?.type === ProfileType.Agent.ordinal) {
            this.props.agentActStore.setInvitedAct(actProfile);
        } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
            this.props.venueActStore.setInvitedAct(actProfile);
        }
        this.setState({
            ...this.state,
            actInvitationModalState: true
        });
    };

    renderActSearchResult = () => {
        const { results, showResult } = this.props.searchStore;
        const shownResult = results

        return shownResult.map(act => {
            return (
                <Grow in={true} key={act.id}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SearchCardAct
                            profileId={act.id}
                            profileName={act.actName}
                            profileBio={act.bio}
                            profile={act}
                            type="act"
                            userRole={this.props.profileStore.profile?.type}
                            profileUrl={this.getProfileUrl(act)}
                            isFavourite={act.isFavourite}
                            handleInvite={() => this.handleOpenActInvitationModal(act)}
                            handleAddRemoveFavourite={() => this.handleAddRemoveFavourite(act)}
                        />
                    </Grid>
                </Grow>
            );
        });
    };

    renderMusicianSearchResult = () => {
        const { results, showResult } = this.props.searchStore;
        const shownResult = results
        return shownResult.map(musician => {
            return (
                <Grow in={true}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SearchCard
                            profileName={musician.firstName + " " + musician.lastName}
                            profileBio={musician.bio}
                            profileId={musician.id}
                            profile={musician}
                            type="musician"
                            userRole={this.props.profileStore.profile?.type}
                            profileUrl={this.getProfileUrl(musician)}
                        />
                    </Grid>
                </Grow>
            );
        });
    };

    renderVenueSearchResult = () => {
        const { results, showResult } = this.props.searchStore;
        const shownResult = results

        return shownResult.map(venue => (
            <Grow in={true}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SearchCard
                        profileName={venue.profileName}
                        profileBio={venue.bio}
                        profileId={venue.id}
                        profile={venue}
                        type="venue"
                        userRole={this.props.profileStore.profile?.type}
                        handleInvite={() => this.handleInviteEvent(venue.id, "venue")}
                        profileUrl={this.getProfileUrl(venue)}
                    />
                </Grid>
            </Grow>
        ));
    };

    renderAgentSearchResult = () => {
        const { results, showResult } = this.props.searchStore;
        const shownResult = results
        return shownResult.map(agent => (
            <Grow in={true}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SearchCard
                        profileName={agent.profileName}
                        profileBio={agent.bio}
                        profileId={agent.id}
                        profile={agent}
                        type="agent"
                        userRole={this.props.profileStore.profile?.type}
                        handleInvite={() => this.handleInviteEvent(agent.id, "agent")}
                        profileUrl={this.getProfileUrl(agent)}
                    />
                </Grid>
            </Grow>
        ));
    };

    renderAllTypeSearchResult = () =>{
        const { results, showResult } = this.props.searchStore;
        const shownResult = results
        return shownResult.map(profile => {

            let additionalProps = {};

            console.log(profile);
            if(profile?.type=== 4){
                additionalProps = {
                    type: "act",
                    handleInvite: () => this.handleOpenActInvitationModal(profile)
                };
            }
            else if(profile?.type === 1){
                additionalProps = {
                    type: "musician"
                };
            }
            else if(profile?.type === 5){
                additionalProps = {
                    type: "venue",
                    handleInvite: () => this.handleInviteEvent(profile.id, "venue")
                }; 
            }
            else if(profile?.type === 2){
                additionalProps = {
                    type: "agent",
                    handleInvite: () => this.handleInviteEvent(profile.id, "agent")
                }; 
            }

            return(<Grow in={true}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SearchCard
                        profileName={profile.fullName}
                        profileBio={profile.bio}
                        profileId={profile.id}
                        profile={profile}
                        userRole={this.props.profileStore.profile?.type}
                        profileUrl={this.getProfileUrl(profile)}
                        {...additionalProps}
                    />
                </Grid>
            </Grow>)
        });
    }

    handleShowMore = e => {
        const { page } = this.props.searchStore;
        this.props.searchStore.setPage(page+1);
        this.props.searchStore.searchNext();
    };

    render() {
        const { results, type, showResult, hasNext } = this.props.searchStore;
        const { currentProfile } = this.props.profileStore;

        return (
            <Fragment>
                {results.length > 0 ? (
                    <Fragment>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    {type === null && this.renderAllTypeSearchResult()}
                                    {type === 4 && this.renderActSearchResult()}
                                    {type === 1 && this.renderMusicianSearchResult()}
                                    {type === 5 && this.renderVenueSearchResult()}
                                    {type === 2 && this.renderAgentSearchResult()}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                                <Grid container direction="row" justify="center" alignItems="center">
                                    {hasNext ? (
                                         <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                            Show More
                                        </Button>
                                    ) : null}
                                   
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
                ) : (
                    ""
                )}

                {this.renderInvitationModal(
                    this.venueInvitationMessageHandler,
                    this.props.agentVenueStore.invitationMessage,
                    this.handleInviteVenue,
                    this.state.venueInvitationModalState,
                    "venue"
                )}

                {currentProfile && (currentProfile?.type === ProfileType.Agent.ordinal ) ? (
                    // <AgentActInvitationModal open={this.state.actInvitationModalState} onClose={this.closeInvitationModal} profileType="agent" />
                    <StaffActRosterInvitationModal open={this.state.actInvitationModalState} onClose={this.closeInvitationModal} forSearch={true} profileType="agent"/>
                ) : currentProfile && currentProfile?.type === ProfileType.Staff.ordinal ? (
                    <StaffActRosterInvitationModal open={this.state.actInvitationModalState} onClose={this.closeInvitationModal} forSearch={true} profileType="staff"/>
                ) : null}
            </Fragment>
        );
    }
}

export default SearchResult;
