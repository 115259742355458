import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";

import ResponsiveTable from "../table/ResponsiveTable";

import { IconButton, Chip } from "@material-ui/core";

import classNames from "classnames";

import moment from "moment";

import "../../pages/invoice/Invoice.css";
import { ProfileType } from "../../types/enum";

@inject("invoiceStore", "profileStore")
@observer
class ReportTable extends Component {
    columns = [];

    componentDidMount() {
        const { currentProfile } = this.props.profileStore;
        let taxLabel =
            currentProfile && currentProfile.tax && currentProfile.tax !== "NOT" && currentProfile.tax !== "CUSTOM" ? currentProfile.tax : "Tax";

        if (currentProfile.type === ProfileType.Staff.ordinal) {
            this.columns = [
                { label: "Date", key: "date", sortable: true, isDate: true },
                { label: "Inv #", key: "invoiceNumber", sortable: true },
                { label: "Status", key: "status", sortable: true, useRawData: true },
                { label: "Client", key: "client", sortable: true },
                { label: "Invoiced Amount", key: "invoicedAmount", sortable: true, isNumber: true, type: "currency" },
                { label: "Outgoing Payments", key: "outgoingPayments", sortable: true, isNumber: true, type: "currency" },
                { label: taxLabel + " Paid Out", key: "gstPaidOut", sortable: true, isNumber: true, type: "currency" }
            ];
        } else {
            this.columns = [
                { label: "Date", key: "date", sortable: true, isDate: true },
                { label: "Inv #", key: "invoiceNumber", sortable: true },
                { label: "Status", key: "status", sortable: true, useRawData: true },
                { label: "Client", key: "client", sortable: true },
                { label: "Invoiced Amount", key: "invoicedAmount", sortable: true, isNumber: true, type: "currency" },
                { label: "Outgoing Payments", key: "outgoingPayments", sortable: true, isNumber: true, type: "currency" },
                { label: "Net Earnings", key: "netEarnings", sortable: true, isNumber: true, type: "currency" },
                { label: taxLabel + " Collected", key: "gstCollected", sortable: true, isNumber: true, type: "currency" },
                { label: taxLabel + " Paid Out", key: "gstPaidOut", sortable: true, isNumber: true, type: "currency" },
                { label: taxLabel + " Balance", key: "gstBalance", sortable: true, isNumber: true, type: "currency" }
            ];
        }
    }

    rows = [];

    footerRow = {};

    renderInvoiceReportCustomActions = () => (
        <Fragment>
            <IconButton>
                <i class="far fa-file-csv" />
            </IconButton>
            <IconButton>
                <i class="far fa-file-pdf" />
            </IconButton>
        </Fragment>
    );

    mapInvoiceReportToRowData = () => {
        const { invoiceReports } = this.props.invoiceStore;
        this.rows = [];
        this.footerRow = {};

        let invoicedTotalAmount = 0,
            outgoingInvoiceTotal = 0,
            netEarningsTotal = 0,
            gstCollectedTotal = 0,
            gstPaidTotal = 0,
            gstBalanceTotal = 0;

        invoiceReports.forEach(report => {
            var row = {};

            invoicedTotalAmount += report.invoicedAmount;
            outgoingInvoiceTotal += report.outgoingPayments;
            netEarningsTotal += report.netEarnings;
            gstCollectedTotal += report.gstCollected;
            gstPaidTotal += report.gstPaidOut;
            gstBalanceTotal += report.gstBalance;

            row.date = moment(report.invoiceDate).format("DD-MM-YYYY");
            row.invoiceNumber = report.invoiceNo;
            row.client = report.clientName;
            row.invoicedAmount = report.invoicedAmount;
            row.outgoingPayments = report.outgoingPayments;
            row.netEarnings = report.netEarnings;
            row.gstCollected = report.gstCollected;
            row.gstPaidOut = report.gstPaidOut;
            row.gstBalance = report.gstBalance;

            row.status = report.status;

            row.status = <Chip className={classNames("status-chip", report.status)} label={report.status} />;

            row.rawData = report;

            this.rows.push(row);
        });

        this.footerRow.date = "Totals";
        this.footerRow.invoiceNumber = "";
        this.footerRow.client = "";
        this.footerRow.invoicedAmount = invoicedTotalAmount;
        this.footerRow.outgoingPayments = outgoingInvoiceTotal;
        this.footerRow.netEarnings = netEarningsTotal;
        this.footerRow.gstCollected = gstCollectedTotal;
        this.footerRow.gstPaidOut = gstPaidTotal;
        this.footerRow.gstBalance = gstBalanceTotal;
        this.footerRow.style = {
            backgroundColor: "#EEEEEE",

            fontWeight: "bold"
        };
    };

    render() {
        this.mapInvoiceReportToRowData();
        return (
            <ResponsiveTable
                columns={this.columns}
                rows={this.rows}
                showToolbar={true}
                title="Invoice Reports"
                footerRow={this.footerRow}
                sortable={true}
            />
        );
    }
}

export default ReportTable;
