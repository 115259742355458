import React, { Component } from "react";
import { Paper, Grid } from "@material-ui/core";

import "./DashboardSmallInformationWidget.css";

class DashboardSmallInformationWidget extends Component {
    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="information-card-widget">
                    <Paper className="information-card-widget-paper">
                        <Grid container style={{ height: "100%" }}>
                            <Grid item xs={12} className="information-card-widget-container">
                                <Grid container style={{ height: "100%" }}>
                                    <Grid item xs={2} className="information-card-icon-container">
                                        <i className={this.props.mhIcon} />
                                    </Grid>

                                    <Grid item xs={10} className="information-card-content-wrapper">
                                        <Grid container className="information-card-content-container">
                                            <Grid item xs={12} className="content-number">
                                                {this.props.information}
                                            </Grid>
                                            <Grid item xs={12} className="content-caption">
                                                {this.props.informationDetail}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default DashboardSmallInformationWidget;
