import { observable, action } from "mobx";
import _ from "lodash";
import agent from "../agent";

import userStore from "./userStore";
import templateStore from "./templateStore";
import generalProfileStore from "./generalProfileStore";

import blankProfile from "../img/blank-profile.png";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class RosterStore {
    @observable openInviteProduction = false;
    @observable openSnackbar = false;
    @observable snackbarMessage = "";
    @observable errors = undefined;
    @observable type = 0;
    @observable actId = undefined;
    @observable productionList = [];

    @observable invitedEmail = "";
    @observable invitedName = "";
    @observable actProductions = [];
    @observable invitedProduction = null;
    @observable invitedproduction = null;

    @action
    setProductionList(newList) {
        this.productionList = newList;
    }

    @action
    reset() {
        this.actProductions = [];
        this.openInviteProduction = false;
        this.openSnackbar = false;
        this.snackbarMessage = "";
        this.errors = undefined;
        this.type = 0;
        this.actId = undefined;
        this.productionList = [];
        this.invitedEmail = "";
        this.invitedName = "";
        this.invitedProduction = null;
    }

    @action
    loadInitialData(actId) {
        this.reset();
        const { id } = generalProfileStore;
        // templateStore.showLoadingScreen();

        actId = actId ? actId : id;

        if (_.isEmpty(this.productionList)) {
            return agent.Production.getAll()
                .then(response => {
                    this.setProductionList(response);
                })
                .finally(() => {
                    if (actId) {
                        this.actId = actId;
                        return agent.ActRoster.getActProductionRoster(actId).then(
                            action(responseAct => {
                                if (!_.isEmpty(responseAct)) {
                                    this.actProductions = responseAct.map((data, index) => {
                                        if (!_.isEmpty(data.productionTypes)) {
                                            let insts = data.productionTypes.split(",");
                                            let productionName = "";
                                            _.each(insts, inst => {
                                                var filteredProduction = _.filter(this.productionList, ["id", _.toInteger(inst)]);
                                                if (!_.isEmpty(filteredProduction)) {
                                                    productionName += filteredProduction[0].name + ", ";
                                                }
                                            });
                                            data.name = productionName.substring(0, productionName.length - 2);
                                        } else {
                                            data.name = "";
                                        }

                                        data.productionRosters.map(roster => {
                                            if (roster.profile && roster.profile.profileImage) {
                                                roster.profile.profileImage.url = `${API_ROOT}/image/${roster.profile.profileImage.id}`;
                                            }
                                            if (roster.profile && roster.profile.backgroundImages && roster.profile.backgroundImages.length > 0) {
                                                roster.profile.backgroundImages = roster.profile.backgroundImages.slice().map(bg => {
                                                    return { ...bg, url: `${API_ROOT}/image/background/${bg.id}` };
                                                });
                                            } else {
                                                roster.profile = {
                                                    ...roster.profile,
                                                    profileImage: {
                                                        url: blankProfile
                                                    }
                                                };
                                            }
                                            return roster;
                                        });

                                        data.key = index;

                                        return data;
                                    });
                                }
                                templateStore.hideLoadingScreen();
                                return this.actProductions;
                            })
                        );
                    }
                });
        }
    }

    @action
    addActProduction() {
        this.actProductions.push({ name: "", productionRosters: [], id: null, productions: null, key: this.actProductions.length });
    }

    @action
    removeProduction(deletedProduction) {
        this.actProductions = this.actProductions.map(actProduction => {
            if (_.isEqual(actProduction, deletedProduction)) {
                actProduction.deleted = true;
            }
            return actProduction;
        });
    }

    @action
    setProductionForActProduction(production, selectedProduction) {
        _.map(this.actProductions, (actProduction, index) => {
            if (_.isEqual(actProduction, production)) {
                actProduction.productionTypes = selectedProduction;

                // let insts = selectedProduction.split(',');
                let insts = selectedProduction;
                let productionName = "";
                _.each(insts, inst => {
                    var filteredProduction = _.filter(this.productionList, ["id", _.toInteger(inst)]);
                    if (!_.isEmpty(filteredProduction)) {
                        productionName += filteredProduction[0].name + ", ";
                    }
                });
                actProduction.name = productionName.substring(0, productionName.length - 2);

                if (_.isEmpty(actProduction.name)) {
                    actProduction.name = "";
                }
            }
        });
    }

    @action
    setInvitedName(name) {
        this.invitedName = name;
    }

    @action
    setInvitedEmail(email) {
        this.invitedEmail = email;
        this.errors = undefined;
    }

    @action
    updateRosterCommission(production, value) {
        this.actProductions = this.actProductions.map(actProduction => {
            if (_.isEqual(actProduction, production)) {
                actProduction.dividendAmount = value;
            }
            return actProduction;
        });
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    addProductionRoster(newRoster, index) {
        this.actProductions[index - 1].productionRosters.push({
            profile: newRoster,
            profileId: newRoster.id
        });
        return this.actProductions;
    }

    @action
    removeProductionRoster(deletedRoster, editedProduction) {
        this.actProductions = _.map(this.actProductions, actProduction => {
            if (_.isEqual(actProduction.name, editedProduction)) {
                _.remove(actProduction.productionRosters, roster => {
                    return _.isEqual(roster, deletedRoster);
                });
            }
            return actProduction;
        });
    }

    @action
    sortProductionRoster(rosters, idx) {
        this.actProductions[idx].productionRosters = rosters;
    }

    @action
    sortActRosters(sortedActRosters) {
        this.actProductions = sortedActRosters;
    }

    @action
    setSnackbarMessage(message) {
        this.snackbarMessage = message;
    }

    @action
    openSnackbarContainer() {
        this.openSnackbar = true;
    }

    @action
    closeSnackbarContainer() {
        this.openSnackbar = false;
    }

    @action
    openInviteProductionForm() {
        this.openInviteProduction = true;
        this.invitedEmail = null;
        this.invitedName = null;
        this.errors = undefined;
    }

    @action
    closeInviteProductionForm() {
        this.openInviteProduction = false;
        this.invitedProduction = undefined;
    }

    @action
    setinvitedProduction(instrument) {
        this.invitedProduction = instrument;
    }

    @action
    inviteRoster() {
        this.errors = undefined;
        return agent.Roster.inviteProduction(this.actId, {
            Email: this.invitedEmail,
            Name: this.invitedName,
            ActRosterId: this.invitedProduction ? this.invitedProduction.id : null
        })
            .then(response => {
                this.setSnackbarMessage("Invitation has been sent");
                this.openSnackbarContainer();
                if (this.invitedProduction) {
                    _.each(this.actProductions, actProduction => {
                        if (_.isEqual(actProduction, this.invitedProduction)) {
                            actProduction.productionRosters.push({
                                firstName: this.invitedName,
                                lastName: "",
                                email: this.invitedEmail,
                                token: response.token,
                                profileImage: {
                                    url: blankProfile
                                }
                            });
                        }
                    });
                }
                this.closeInviteProductionForm();
            })
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action
    getDefaultrosterCommission = () => {
        var setActInstrumentrosterCommission = 0;

        var undeletedActInstrument = this.actInstruments.filter(actInstrument => !actInstrument.deleted);

        var undeletedActInstrumentWithrosterCommission = undeletedActInstrument.filter(actInstrument => !isNaN(actInstrument.rosterCommission));

        undeletedActInstrumentWithrosterCommission.forEach(actInstrument => {
            setActInstrumentrosterCommission += actInstrument.dividendAmount;
        });

        return (100 - setActInstrumentrosterCommission) / (undeletedActInstrument.length - undeletedActInstrumentWithrosterCommission.length);
    };

    @action
    submit() {
        this.errors = undefined;

        const { currentUser } = userStore;
        let productionRosters = this.actProductions.map(actProduction => {
            return {
                id: actProduction.id,
                productionTypes: actProduction.productionTypes,
                productionRosters: actProduction.productionRosters.map(roster => {
                    return {
                        id: roster.id,
                        profileId: roster.profileId ? roster.profileId : undefined,
                        email: roster.email ? roster.email : roster.invitedEmail,
                        name: roster.firstName ? roster.firstName + " " + roster.lastName : roster.name,
                        token: roster.token
                    };
                }),
                rosterCommission: !isNaN(actProduction.dividendAmount) ? actProduction.dividendAmount : 0,
                dividendAmount: !isNaN(actProduction.dividendAmount) ? actProduction.dividendAmount : 0,
                dividendType: 1,
                deleted: actProduction.deleted
            };
        });

        const data = {
            id: this.actId,
            userId: currentUser.id,
            productionRosters
        };

        return this.updateRoster(data);
    }

    @action
    updateRoster(data) {
        return agent.ActRoster.productionUpdate(data).then(() => {
            return null;
        });
    }
}

export default new RosterStore();
