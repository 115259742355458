import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import agent from "../../agent";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import DashboardFilter from "../../components/dashboard/DashboardFilter";
import Heading from "../../components/Heading";
import DashboardBigInformationWidget from "../../components/dashboard/DashboardBigInformationWidget";
import DashboardBigInformationWidgetSingle from "../../components/dashboard/DashboardBigInformationWidgetSingle";
import DashboardSmallInformationWidget from "../../components/dashboard/DashboardSmallInformationWidget";
import UpcomingBookingAgent from "../../components/dashboard/UpcomingBookingAgent";
import Loading from "../../components/loadingSpinner/Loading";
import { ProfileType } from "../../types/enum"
import moment from "moment"
import Grid from "@material-ui/core/Grid";

@inject("profileStore", "templateStore", "profileStore")
@observer
class Agent extends Component {
    state = {
        data: {},
        startDate: null,
        endDate: null,
        todaysGigs: [],
        consultantFilter: 0,
        venueFilter: 0,
        consultant: [],
        venue: [],
        firstDay: null,
        lastDay: null,
        isLoading: true
    };
    currentProfileId = 0;
    offset = moment(new Date()).utcOffset()
    loadData = () => {
        this.setState({ isLoading: true });
        Promise.all([
            agent.Profile.getAgentDashboardMain(
                this.currentProfileId,
                this.state.firstDay,
                this.state.lastDay,
                this.state.venueFilter,
                this.state.consultantFilter
            ),
            agent.Profile.getAgentBookingRequestDashboard(
                this.currentProfileId,
                this.state.firstDay,
                this.state.lastDay,
                this.state.venueFilter,
                this.state.consultantFilter
            ),
            agent.Profile.getAgentDashboardVenue(
                this.currentProfileId,
                this.state.firstDay,
                this.state.lastDay,
                this.state.venueFilter,
                this.state.consultantFilter
            )
        ])
        .then(result => {
            var data = result[0]
            data.venues = result[2]
            data.pendingGigRequest = result[1]

            data?.upcomingBookings?.forEach((gig) => {
                var dateStart = moment(gig.dateStart)
                var dateEnd = moment(gig.dateEnd)
                gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                var dstVariable= 120
                var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                if(dstChecking.isAfter(gig.dateStart)){
                    gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                    gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                }
             
            })

            this.setState({ data: data });
            this.setState({ isLoading: false });
        });
    };


    onConsultantChange = value => {
        this.setState({ consultantFilter: value }, () => {
            this.loadData();
        });
    };

    onVenueChange = value => {
        this.setState({ venueFilter: value }, () => {
            this.loadData();
        });
    };

    formatDate = date => {
        let d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    gotoDashboard(profileType) {
        switch (profileType) {
            case 1:
                this.props.history.replace("/musician");
                break;
            case 2:
                this.props.history.replace("/agent");
                break;
            case 3:
                this.props.history.replace("/staff");
                break;
            case 6:
                this.props.history.replace("/production");
                break;
            case 7:
                this.props.history.replace("/dj");
                break;
            case 8:
                this.props.history.replace("/agent");
                break;
            case 9:
                this.props.history.replace("/privatehire/gig");
                break;
    }
    }

    componentDidMount = () => {
        var currentProfile = this.props.profileStore.getCurrentProfile();

        this.currentProfileId = currentProfile.id;
        if(currentProfile.type !== ProfileType.Staff.ordinal){
            this.gotoDashboard(currentProfile.type)
        }
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        firstDay = this.formatDate(firstDay) + " 00:00:00";
        lastDay = this.formatDate(lastDay) + " 23:59:59.999";

        this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
            this.loadData();
        });
    };

    onCustomMonthChange = (startDate, endDate) => {
        this.setState({ startDate: startDate, endDate: endDate });

        let firstDay = this.formatDate(startDate) + " 00:00:00";
        let lastDay = this.formatDate(endDate) + " 23:59:59.999";

        this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
            this.loadData();
        });
    };

    onCustomStartDateChange = value => {
        this.setState({ startDate: value });

        if (this.state.endDate) {
            let firstDay = this.formatDate(value) + " 00:00:00";
            let lastDay = this.formatDate(this.state.endDate) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        }
    };

    onCustomEndDateChange = value => {
        this.setState({ endDate: value });

        if (this.state.startDate) {
            let firstDay = this.formatDate(this.state.startDate) + " 00:00:00";
            let lastDay = this.formatDate(value) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        }
    };

    handleFilterChange = value => {
        if (value === "today") {
            let today = this.formatDate(new Date());

            let firstDay = today + " 00:00:00";
            let lastDay = today + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        } else if (value === "week") {
            let curr = new Date(); // get current date
            var calculateLast = new Date(); // get current date
            let first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
            let last = first + 6; // last day is the first day + 6

            let firstDay = new Date(curr.setDate(first));
            let lastDay = new Date(calculateLast.setDate(last));

            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        } else if (value === "month") {
            let date = new Date();

            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        } else if (value === "nextMonth") {
            var date = new Date();

            var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
                this.loadData();
            });
        }
    };

    render() {
        const { isLoading } = this.state;
        const { currentProfile } = this.props.profileStore;
        const taxCode = currentProfile && currentProfile.tax && currentProfile.tax != "CUSTOM" ? currentProfile.tax : "Tax";

        const taxText =  currentProfile?.taxValue && parseInt(currentProfile?.taxValue) > 0 ? ` (ex ${taxCode})` : "";

        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Heading title="Dashboard">
                        <DashboardFilter
                            onChange={this.handleFilterChange}
                            onCustomStartDateChange={this.onCustomStartDateChange}
                            onCustomEndDateChange={this.onCustomEndDateChange}
                            onCustomMonthChange={this.onCustomMonthChange}
                            onConsultantChange={this.onConsultantChange}
                            consultants={this.state.data.consultants}
                            onVenueChange={this.onVenueChange}
                            venues={this.state.data.venues}
                        />
                    </Heading>

                    <Grid container spacing={16} style={{ position: "relative" }}>
                        <Loading showed={isLoading} />
                        <Grid item xs={12}>
                            <Grid container spacing={16}>
                                <Grid item md={4} xs={12}>
                                    <DashboardBigInformationWidgetSingle
                                        id="agent-total-gig-fee"
                                        headerContent={
                                            (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                            (this.state.data.totalGigFees === undefined
                                                ? "Loading..."
                                                : this.state.data.totalGigFees.toLocaleString())
                                        }
                                        headerCaption="Total Gig Fees"
                                        // content1={
                                        //     (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                        //     (this.state.data.outstandingGigFees === undefined
                                        //         ? "Loading..."
                                        //         : this.state.data.outstandingGigFees.toLocaleString())
                                        // }
                                        // caption1="Outstanding Gig Fees"
                                        // content2={
                                        //     (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                        //     (this.state.data.paidGigFees === undefined ? "Loading..." : this.state.data.paidGigFees.toLocaleString())
                                        // }
                                        // caption2="Paid Gig Fees"
                                        primaryColor={true}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <DashboardBigInformationWidgetSingle
                                        id="agent-total-commission-fee"
                                        headerContent={
                                            (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                            (this.state.data.totalCommissionEarned === undefined
                                                ? "Loading..."
                                                : this.state.data.totalCommissionEarned.toLocaleString())
                                        }
                                        headerCaption={`Total Commission Earned${taxText}`}
                                       // headerCaption={"Total Commission Earned (ex " + taxCode + ")"}
                                        primaryColor={false}
                                        // content1={
                                        //     (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                        //     (this.state.data.outstandingCommission === undefined
                                        //         ? "Loading..."
                                        //         : this.state.data.outstandingCommission.toLocaleString())
                                        // }
                                        // caption1="Outstanding Commission"
                                        // content2={
                                        //     (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                        //     (this.state.data.paidCommission === undefined
                                        //         ? "Loading..."
                                        //         : this.state.data.paidCommission.toLocaleString())
                                        // }
                                        // caption2="Paid Commision"
                                    />
                                </Grid>
                                {/* <Grid item md={6} xs={12}>
                                    <Grid container spacing={16}>
                                        <Grid item md={6} xs={12}>
                                            <DashboardSmallInformationWidget
                                                mhIcon="mh-microphone"
                                                information={this.state.data.outstandingGigRequests}
                                                informationDetail="Outstanding Gig Requests"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <DashboardSmallInformationWidget
                                                mhIcon="fa fa-building"
                                                information={this.state.data.venuesOnYourRoster}
                                                informationDetail="Venues on Your Roster"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <DashboardSmallInformationWidget
                                                mhIcon="mh-band"
                                                information={this.state.data.actOnYourRoster}
                                                informationDetail="Acts on Your Roster"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <DashboardSmallInformationWidget
                                                mhIcon="fa fa-envelope"
                                                information={this.state.data.newMessages}
                                                informationDetail="New Messages"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item md={4} xs={12}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <DashboardSmallInformationWidget
                                                mhIcon="fa fa-hourglass-start"
                                                information={
                                                    this.state.data.pendingGigRequest === undefined ? "Loading..." : this.state.data.pendingGigRequest
                                                }
                                                informationDetail="Pending Gig Request"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DashboardSmallInformationWidget
                                                mhIcon="fa fa-calendar-times"
                                                information={
                                                    this.state.data.declinedGigRequest === undefined
                                                        ? "Loading..."
                                                        : this.state.data.declinedGigRequest
                                                }
                                                informationDetail="Declined Gig Request"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={16}>
                                <Grid item xs={12}>
                                    <UpcomingBookingAgent tableData={this.state.data.upcomingBookings} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainContainer>
            </div>
        );
    }
}
export default Agent;
