import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { Select, MenuItem, Checkbox, ListItemText, Input } from '@material-ui/core'

import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import IncomingInvoice from "./IncomingInvoice";
import OutgoingInvoice from "./OutgoingInvoice";

import ReportDateFilter from "../../components/reports/ReportDateFilter";

import "./Invoice.css";
import Loading from "../../components/loadingSpinner/Loading";
import DisablePage from "../../components/loadingSpinner/DisablePage";
import StaffFilter from "../../components/filters/StaffFilter";
import { ProfileType } from "../../types/enum";

import jwtDecode from "jwt-decode";
import BookingCalendarFilter from "../../components/booking/bookingCalendarFilter";

const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";

@inject("templateStore", "profileStore", "invoiceStore", "reportStore", "commonStore", "agentActStore", "venueActStore","staffFilterStore")
@withRouter
@observer
class Invoice extends Component {
    
    loadOutgoingInvoice = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        // return this.props.invoiceStore.generate(currentProfile.id).then(() => {
        //     return this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfile.id);
        // });
        return this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfile.id);
    };

    constructor(props) {
        super(props);
        this.props.reportStore.resetFilterData();
        this.props.invoiceStore.clearIncomingOutgoingInvoices();
        this.state = {
            tabValue: 0,
            isLoading: true,
            isLoaded:false,
            firstLoaded:false
        };
    }

    componentDidMount = () => {
         const currentProfile = this.props.profileStore.getCurrentProfile();
        const { token } = this.props.commonStore;
        this.props.reportStore.initialFilter();
        let currentType = ""
        if (this.props.profileStore.currentProfile && this.props.profileStore.currentProfile.typeString){
            currentType = this.props.profileStore.currentProfile.typeString === "staff" ? "venue" : this.props.profileStore.currentProfile.typeString;

        }
       
        if (!ENABLE_SUBSCRIPTION || (token && currentProfile && jwtDecode(token).scope.filter(x => x === currentType).length > 0)) {
            
            if (currentProfile){
                this.setState({
                    ...this.state,
                    isLoading: true,
                    firstLoaded: true,
                });
                if (this.props.match.params.stateId && this.props.match.params.stateId === "lastweek") {
                    this.props.staffFilterStore.setFilterType("lastweek");
                    this.props.reportStore.setFilterType("lastweek");
                 }
                Promise.all(
                    [
                        this.props.invoiceStore.loadByPayerIdAndFilter(currentProfile.id), 
                        this.loadOutgoingInvoice(),
                        currentProfile.type === ProfileType.Agent.ordinal ? this.props.agentActStore.getConsultantActRoster(currentProfile.id) : undefined,
                        currentProfile.type === ProfileType.Staff.ordinal ? this.props.venueActStore.getStaffActRosterInvoice(currentProfile.id, true) : undefined
                    ]
                ).then(() => {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        isLoaded: true,
                        firstLoaded: true
                    });
                });
            } else {
                const currentUser = this.props.profileStore.getCurrentProfile();
                this.currentUserId = currentUser.id;
                this.setState({
                    ...this.state,
                    isLoading: false,
                    isLoaded:false
                });
            }
           
        }

    };

    

  
    handleTabChange = (event, value) => {
        const { tabValue } = this.state

        
        if(tabValue !== value){
            this.props.invoiceStore.resetFilter();
            this.props.invoiceStore.setSelectedIncomingInvoice(undefined);
            this.props.invoiceStore.setSelectedOutgoingPdfBatch(undefined);
            this.setState({
                tabValue: value,
                isLoaded: false
            });
        }
       
    };
    

    renderTabComponent = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0:
                return <OutgoingInvoice />;
            case 1:
                return <IncomingInvoice />;
            default:
                return "";
        }
    };


    renderTabStaffComponent = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0:
                return <IncomingInvoice />;
            default:
                return "";
        }
    };
    renderTabTitle = (title, list) => {
        return list && list.length > 0 ? (
            <Badge color="primary" style={{ paddingRight: "16px" }} badgeContent={list ? list.length : 0}>
                {title}
            </Badge>
        ) : (
            title
        );
    };

    reloadInvoice = filterData => {
        const { currentProfile } = this.props.profileStore;
        this.setState({
            ...this.state,
            isLoading: true
        });
        if (filterData) {
            this.props.reportStore.setOrganisationFilter(filterData.organisation);
            this.props.reportStore.setStateFilter(filterData.state);
            this.props.reportStore.setRegionFilter(filterData.region);
            this.props.reportStore.setVenueFilter(filterData.venue);

            this.props.reportStore.setStartDate(filterData.startDate);
            this.props.reportStore.setEndDate(filterData.endDate);
        }

        Promise.all([
            this.props.invoiceStore.loadByPayerIdAndFilter(currentProfile.id),
            //currentProfile.type !== ProfileType.Staff.ordinal ?
            this.props.invoiceStore.loadByPayeeIdAndFilter(currentProfile.id)
            //: undefined
        ]).then(() => {
            this.props.invoiceStore.setSelectedIncomingInvoice(undefined);
            this.props.invoiceStore.setSelectedOutgoingPdfBatch(undefined);
            this.setState({
                ...this.state,
                isLoading: false
            });
        });
    };

    handleStatusFilterChange = e => {
        this.props.reportStore.setInvoiceStatusFilter(e.target.value);
        this.reloadInvoice();
    }
    
    mapActSuggestions = () => {
        const { currentProfile } = this.props.profileStore;
        let suggestions = [];
        if(currentProfile && currentProfile.type === ProfileType.Agent.ordinal){
            const { agentActs } = this.props.agentActStore;

            agentActs.forEach( agentAct => {
                if(!suggestions.find(x => x.value === agentAct.act.id)){
                    suggestions.push(
                        {
                            value: agentAct.act.id,
                            label: agentAct.act.actName
                        } 
                    )
                }
            })
        }
        else if(currentProfile && currentProfile.type === ProfileType.Staff.ordinal){
            const { venueActRoster } = this.props.venueActStore;
            venueActRoster.forEach( venueAct => {
                if(!suggestions.find(x => x.value === venueAct.act.id)){
                    suggestions.push(
                        {
                            value: venueAct.act.id,
                            label: venueAct.act.actName
                        } 
                    )
                }
            })
        }

        if (suggestions.length > 0) {
            suggestions.sort((a, b) => (a.label < b.label ? -1 : 1))
            suggestions.unshift({
                value: 0,
                label: "All Acts"
            });
            
        }

        return suggestions;
    }

    handleSelectAct = value => {
        this.props.reportStore.setActFilter(value);
        this.reloadInvoice();
    }

    mapContactSuggestions = (profileType) => {
        const { incomingInvoices, outgoingInvoices } = this.props.invoiceStore;
        const { tabValue } = this.state;
        var suggestions = [];
        if(profileType === ProfileType.Staff.ordinal){
            incomingInvoices.forEach(x => {
                if(!suggestions.find(suggestion => suggestion.value === x.payeeName)){
                    suggestions.push({
                        value: x.payeeName,
                        label: x.payeeName
                    })
                }
            });
        }else{
            if(tabValue === 0){
                outgoingInvoices.forEach(x => {
                    if(!suggestions.find(suggestion => suggestion.value === x.payerName)){
                        suggestions.push({
                            value: x.payerName,
                            label: x.payerName
                        })
                    }
                });
            }
            else if(tabValue === 1 ){
                incomingInvoices.forEach(x => {
                    if(!suggestions.find(suggestion => suggestion.value === x.payeeName)){
                        suggestions.push({
                            value: x.payeeName,
                            label: x.payeeName
                        })
                    }
                });
            }
        }
   
        return suggestions;
    }

    handleSelectContactFilter = value => {
        this.props.invoiceStore.setFilterAttr('contactName', value);
        this.reloadInvoice();
    }

    render() {
        const { currentProfile } = this.props.profileStore;
        const { isLoading, isLoaded,firstLoaded } = this.state;
        const { invoiceStatusFilter } = this.props.reportStore; 
        const { filter } = this.props.invoiceStore;

        const breadcrumb = [];
        if (currentProfile && currentProfile.type){
            [{ name: "Dashboard", link: "/" + this.props.templateStore.getTypeString(currentProfile.type) }, { name: "Invoices" }];
        }
        const { token } = this.props.commonStore;
        let hasSubscribe = false;
        let currentType = ""

        // if(currentProfile && !firstLoaded ){
        //     this.loadInitialData();
        // }
        if (this.props.profileStore.currentProfile && this.props.profileStore.currentProfile.typeString) {
            currentType = this.props.profileStore.currentProfile.typeString === "staff" ? "venue" : this.props.profileStore.currentProfile.typeString;
        }
        
        // if (token && currentProfile && jwtDecode(token).scope.filter(x => x === currentType).length > 0) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }

        return (
            <div>
               
                <Header />
                <Sidebar />
                <MainContainer>
                    <Fragment>
                        <Breadcrumb links={breadcrumb} />
                        { currentProfile ? (
                                <Heading title="Invoices">
                                <Grid container spacing={8} justify="flex-end" alignItems="center">
                                    <Grid item>
                                        <Select
                                            multiple
                                            label="Filter"
                                            className="dark-select"
                                            value={ invoiceStatusFilter.slice() }
                                            onChange={this.handleStatusFilterChange}
                                            input={<Input id="select-multiple-checkbox" />}
                                            renderValue={selected => selected.join(', ')}
                                            fullWidth
                                            inputProps={{
                                                color: "primary"
                                            }}
                                            classes={{
                                                root: "text-white",
                                                icon: "text-white"
                                            }}
                                        >
                                            <MenuItem value="Pending">
                                                <Checkbox checked={invoiceStatusFilter.slice().indexOf("Pending") > -1} />
                                                <ListItemText primary = "Pending"/>
                                            </MenuItem>
                                            <MenuItem value="Outstanding">
                                                <Checkbox checked={invoiceStatusFilter.slice().indexOf("Outstanding") > -1} />
                                                <ListItemText primary = "Outstanding"/>
                                            </MenuItem>
                                            <MenuItem value="Paid">
                                                <Checkbox checked={invoiceStatusFilter.slice().indexOf("Paid") > -1} />
                                                <ListItemText primary = "Paid"/>
                                            </MenuItem>
                                            <MenuItem value="Overdue">
                                                <Checkbox checked={invoiceStatusFilter.slice().indexOf("Overdue") > -1} />
                                                <ListItemText primary = "Overdue"/>
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    {
                                        currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Staff.ordinal ? (
                                            <Grid item>
                                                <BookingCalendarFilter
                                                    id="invoiceContactFilter"
                                                    suggestions={this.mapContactSuggestions(currentProfile.type)}
                                                    onChange={this.handleSelectContactFilter}
                                                    value={ filter.contactName }
                                                    placeholder="All Contacts"
                                                    fullWidth={this.props.width === "xs" || this.props.width === "sm"}
                                                />
                                            </Grid>
                                        ) : null
                                    }
                                    <Grid item>
                                        {currentProfile.type === ProfileType.Staff.ordinal ? (
                                            <StaffFilter postChangeEvent={this.reloadInvoice} hideAllTime={true}/>
                                        ) : (
                                            <ReportDateFilter postChangeEvent={this.reloadInvoice} hideAllTime={true}/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Heading>
    
                        ): null}
                    
                        <Grid container spacing={16} style={{ position: "relative" }}>
                            <DisablePage showed={!hasSubscribe} title="Upgrade to enable invoicing" />
                            <Grid item>
                                <AppBar position="static" elevation={2}>
                                    {currentProfile && (currentProfile.type === ProfileType.Staff.ordinal || currentProfile.type === ProfileType.PrivateHire.ordinal) ?  <Tabs
                                        value={this.state.tabValue}
                                        onChange={this.handleTabChange}
                                        indicatorColor="primary"
                                        textColor="secondary"
                                        scrollable
                                        scrollButtons="off"
                                        classes={{
                                            root: "tabs-root",
                                            indicator: "tabs-indicator"
                                        }}
                                    >
                                        <Tab label={this.renderTabTitle("Incoming Invoices", [])} classes={{ selected: "tab-selected" }} />
                                    </Tabs> :  <Tabs
                                        value={this.state.tabValue}
                                        onChange={this.handleTabChange}
                                        indicatorColor="primary"
                                        textColor="secondary"
                                        scrollable
                                        scrollButtons="off"
                                        classes={{
                                            root: "tabs-root",
                                            indicator: "tabs-indicator"
                                        }}
                                    >
                                        <Tab label={this.renderTabTitle("Outgoing Invoices", [])} classes={{ selected: "tab-selected" }} />
                                        <Tab label={this.renderTabTitle("Incoming Invoices", [])} classes={{ selected: "tab-selected" }} />
                                    </Tabs>}
                                   
                                </AppBar>
                                <Paper className="org-paper-form" style={{ borderRadius: "0px 0px 2px 2px", position: "relative" }}>
                                    <Loading showed={isLoading && hasSubscribe} />

                                    {currentProfile && (currentProfile.type === ProfileType.Staff.ordinal || currentProfile.type === ProfileType.PrivateHire.ordinal) ? this.renderTabStaffComponent() : this.renderTabComponent() }
                                    
                                    {/* {this.props.profileStore.currentProfile.type === ProfileType.Staff.ordinal ? <IncomingInvoice /> : this.renderTabComponent()} */}
                                   
                                </Paper>
                            </Grid>
                        </Grid>
                    </Fragment>
                </MainContainer>
            </div>
        );
    }
}

export default Invoice;
