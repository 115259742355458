import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
// import TableHead from "@material-ui/core/TableHead";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const maxText = 500;
const blankBackground = "/hero2.jpg";
import Grid from '@material-ui/core/Grid';
import "./EmbedGigGridDetails.css";
import Typography from "@material-ui/core/Typography";
import { Button,IconButton } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ProfileType } from "../../../types/enum";
class EmbedGigGridDetails extends Component {
    state = {
        clicked:false
    };
    handleClose = () => {
        this.setState({
            clicked:false
        })
        this.props.onClose();
    };

    handleShare = (text) => {
        this.setState({
            clicked:true
        })
        navigator.clipboard.writeText(text);
        setTimeout(() => {
            this.setState({
                clicked:false
            })
        }, 5000);
        
    }
    render(){
        const { data,profile,bookingGenresString, tableBgColor,tableFtColor, enabledTransparent, tableWidgetBgColor, actGenreString} = this.props;
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        var bookingUrl = data?.bookTableUrl?.includes("https://") ? data?.bookTableUrl: "https://"+data?.bookTableUrl;
        var ticketUrl = data?.ticketUrl?.includes("https://") ? data?.ticketUrl: "https://"+data?.ticketUrl;
        const currentIframeHref = new URL(document.location.href);
       
        return (
            <Dialog
            disableEnforceFocus={true}
            open={this.props.open}
            onClose={this.props.onClose}
            onEntered={this.props.onEntered}
            PaperProps={{
                style: {
                  backgroundColor: "#fff",
                },
              }}
            maxWidth="800px"
            disableRestoreFocus
            // className="dialog-div"
            disableBackdropClick={this.props.disableBackdropClick}
            disableEscapeKeyDown={this.props.disableEscapeKeyDown}
        >
           <DialogTitle>
           <IconButton
                aria-label="close"
                onClick={this.handleClose}
               style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
               }}
                >
          <CloseIcon />
        </IconButton>
           </DialogTitle>
            <div>
            <Grid style={{marginTop: -30}} container justifyContent="space-between" m={2} spacing={2}>
                <Grid item xs={12} md={6}  className="gridLayout" >
                    <Card className="imageItemDetail">
                        <CardActionArea>
                            <img className="imageStyleDetail" src={profile?.type === ProfileType.Musician.ordinal ? data?.venueCover : data?.profileImageCover ? data?.profileImageCover : blankBackground} alt={profile?.type === ProfileType.Musician.ordinal ? data?.venueCoverAlt : data?.profileImageCoverAlt}></img>
                            <div className="topcorner" style={{backgroundColor: tableWidgetBgColor}}>
                                <div style={{fontSize:"19px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor }}>{`${data?.day?.toUpperCase()} ${data?.date}`}</div>
                                <div style={{fontSize:"15px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor }}>{data?.month}</div>
                                <div style={{fontSize:"11px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor }}>{data?.startTimeString}</div>
                            </div>
                            <div className="bottomSection"></div>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className="gridInfo">
                <a className="clickLink" onClick={() => window.open(`${BASE_URL}profile/${data?.actId}`)} style={{fontSize:"30px", fontFamily:"Raleway", fontWeight:"bold",  textDecoration: "underline",color:"black"}}>
                    {profile?.type === ProfileType.Musician.ordinal ? data?.venueName : data?.actName}
                </a>
                <div className="genreContainer" style={{marginLeft:-5,marginBottom:10}}>
                    {
                        actGenreString && actGenreString.length ? actGenreString.map((data, index) => {
                            return <span className="genreWrapper" style={{backgroundColor: tableWidgetBgColor, color: tableFtColor }}>
                                {data.name}
                            </span>
                        }) : null
                    }
                </div>
                <Typography component="div" style={{maxWidth: 500,fontSize:"15px", fontFamily:"Open Sans"}} dangerouslySetInnerHTML={{ 
                        __html: profile?.type === ProfileType.Musician.ordinal ? data?.shortVenueBio : data?.shortActBio
                    }} 
                />
                <Grid style={{textAlign:"center",marginTop:30}} container justifyContent="space-between" alignContent="center" m={1} spacing={1}>
                    
                    {
                        data?.bookTableUrl && !data?.ticketUrl ? 
                        <Grid item xs={12} md={12}>
                            <Button className="buttonStyle" style={{backgroundColor: tableWidgetBgColor, color: tableFtColor, float:"left"}} onClick={() => window.open(bookingUrl, "_system")}>Book A Table</Button>
                        </Grid> : null
                    }
                     {
                        data?.bookTableUrl && data?.ticketUrl ? 
                        <Grid item xs={12} md={6}>
                            <Button className="buttonStyle" style={{backgroundColor: tableWidgetBgColor, color: tableFtColor, float:"left" }} onClick={() => window.open(bookingUrl, "_system")}>Book A Table</Button>
                        </Grid> : null
                    }
                    {
                         data?.bookTableUrl && data?.ticketUrl ? 
                        <Grid item xs={12} md={6}>
                            <Button className="buttonStyle" style={{backgroundColor: tableWidgetBgColor, color: tableFtColor, float:"left" }} onClick={() => window.open(ticketUrl, "_system")}>Buy Tickets</Button>
                        </Grid> : null
                    }
                     {
                         !data?.bookTableUrl && data?.ticketUrl ? 
                        <Grid item xs={12} md={12}>
                            <Button className="buttonStyle" style={{backgroundColor: tableWidgetBgColor, color: tableFtColor, float:"left" }} onClick={() => window.open(ticketUrl, "_system")}>Buy Tickets</Button>
                        </Grid> : null
                    }
                    
                    {/* <Grid item xs={12} md={4}>
                        <Button className={this.state.clicked? "buttonStyleShare" : "buttonStyle" } style={{backgroundColor: tableWidgetBgColor, color: tableFtColor }} onClick={() => this.handleShare(currentIframeHref)}>{this.state.clicked ? "Copied" : "Share"}</Button>
                    </Grid> */}
                </Grid>
                </Grid>
            </Grid>
            </div>
        </Dialog>
        )
    }
}

export default EmbedGigGridDetails;
