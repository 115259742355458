import React, { Component } from "react";

import { inject, observer } from "mobx-react";

import { Grid, Paper } from "@material-ui/core";

import ResponsiveTable from "../table/ResponsiveTable";
import moment from "moment";
import WidgetContainer from "../widget/WidgetContainer.js";
import { Link } from "react-router-dom";
import "./UpcomingBooking.css";
import { BookingType, ProfileType } from "../../types/enum";
import currencyHelper from '../../helper/currency';

@inject("profileStore", "bookingRequestStore", "templateStore")
@observer
class UpcomingBookingStaff extends Component {
    state = {
        venue: "null",
        consultant: "null",
        columns: [
            { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, addComp: true, style: {whiteSpace: 'nowrap'} },
            { key: "venueName", label: "Venue", width: "20%" },
            { key: "actName", label: "Artist", width: "20%" },
            { key: "gigFee", label: "Gig Fee", sortable: true, width: "10%", isNumber: true, sortable: true, align: 'left', style: { flexDirection: 'row' } },
            { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
            { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
            { key: "consultant", label: "Booking Creator", width: "15%", align: 'left', }
        ],
        columnsNonFee: [
            { key: "date", label: "Date", width: "20%", sortable: true, isDate: true, addComp: true, style: {whiteSpace: 'nowrap'} },
            { key: "venueName", label: "Venue", width: "30%" },
            { key: "actName", label: "Artist", width: "25%" },
            { key: "consultant", label: "Booking Creator", width: "20%", align: 'left', }
        ]
    };

    tableData = {
        title: ["Date", "Venue", "Address", "Artist", "Time", "Consultant"]
    };

    handleVenueChange = event => {
        this.setState({ venue: event.target.value });
    };

    handleConsultantChange = event => {
        this.setState({ consultant: event.target.value });
    };

    mapBookingDataToRowData = () => {
        const { tableData } = this.props
        const { currentProfile } = this.props.profileStore;
        
        if (tableData) {
            return tableData.map(booking => {
                var row = {};
                var formattedDate = moment(booking.dateStart).format("YYYY-MM-DD")
                                
                                
                let time = booking.timeStart.length < 8 ? `0${booking.timeStart}` : booking.timeStart;
                let endTime = booking.timeEnd.length < 8 ? `0${booking.timeEnd}` : booking.timeEnd;
                var finalDate= moment(formattedDate + ' ' + time)
                row.date = {value:finalDate, newFormat: <Link to={this.bookingRedirectPath(booking)} >{booking.dateStart.format("DD MMM YYYY")} {moment(time).format('hh.mm A')}</Link>};
                row.venueName = (
                    <div>
                        <span>{booking.venueId ? booking.venue.profileName : booking.venueName}</span>
                        <br />
                        <small>{booking.venueAddress}</small>
                    </div>
                );
                row.actName =  <Link to={`/profile/${booking.act.id}`} target={"_blank"}>{booking.act.actName}</Link>
                row.gigFee = (booking.viewOnly) || (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices)
                    ? <i class="far fa-eye-slash"></i>
                    : ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$") + parseFloat(booking.gigFee).toLocaleString();
                row.consultant = booking.createdBy.firstName + " " + booking.createdBy.lastName;
                row.agentCommissionAmount = `${currentProfile?.currencySymbol}${currencyHelper.formatMoney(booking.agentCommissionAmount, 2)}`
                row.actFee = (booking.viewOnly) || (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices)
                ? <i class="far fa-eye-slash"></i>
                : ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$") + parseFloat(booking.actFee).toLocaleString();

                return row;
            })
        }
        else {
            return [];
        }
    };

    
    bookingRedirectPath = (booking) => {
        let path = `/booking/${booking.id}/edit`
        if (booking.type === BookingType.Adhoc.ordinal) path = `/privateBooking/${booking.id}/edit`;
        return path;
    };

    render() {
        const { currentProfile } = this.props.profileStore;
        
        return (
            <Paper>
                <WidgetContainer
                    title="Upcoming Gigs"
                    showBadge={this.props.tableData && this.props.tableData.length ? true : false}
                    badgeValue={this.props.tableData && this.props.tableData.length}
                >
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <ResponsiveTable
                                sortable={true}
                                columns={currentProfile && currentProfile.allowInvoices ? this.state.columns : this.state.columnsNonFee}
                                rows={this.mapBookingDataToRowData()}
                            />
                        </Grid>
                    </Grid>
                </WidgetContainer>
            </Paper>
        );
    }
}

export default UpcomingBookingStaff;
