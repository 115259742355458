import React, { Fragment } from "react";
import { inject } from "mobx-react";
import { Tooltip } from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import InboxIcon from "@material-ui/icons/Inbox";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withRouter, Redirect } from "react-router-dom";
import facebook from "../../img/facebook-box.svg";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import IntegrateInstagramForm from "../../components/modal/IntegrateInstagramForm";

import jwtDecode from "jwt-decode";
import { ProfileType } from "../../types/enum";

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";

const footerHtmlText = `<p><strong style="text-align: start;color: rgb(23, 43, 77);background-color: rgb(255, 255, 255);font-size: 14px;">NOTE:&nbsp;</strong><span style="text-align: start;color: rgb(23, 43, 77);background-color: rgb(255, 255, 255);font-size: 14px;">Please remember that the Facebook page must be connected to your Instagram account.&nbsp;</span><a href="https://www.facebook.com/business/help/502981923235522" title="https://www.facebook.com/business/help/502981923235522" style="text-align: start;color: var(--ds-link, #0052CC);background-color: rgb(255, 255, 255);font-size: 14px;">Here are instructions</a><span style="text-align: start;color: rgb(23, 43, 77);background-color: rgb(255, 255, 255);font-size: 14px;">&nbsp;for converting your Instagram account to a Professional Account.</span></p>`
@inject("facebookStore", "profileStore", "templateStore", "commonStore", "instagramStore")
class FacebookPageDialog2 extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    state = {
        open: false,
        openAdd: false,
        openConfirmModal: false,
        redirectToPaymentPage: false
    };

    handleClick2 = event => {
        this.closeInfoPopup()
        this.myRef.current.props.onClick();
    };
    handleFacebookShare = response => {
        if (response.status !== "unknown") {
            this.props.templateStore.showLoadingScreen();
            this.props.facebookStore.getPages(response.id, response.accessToken).then(res => {
                this.props.templateStore.hideLoadingScreen();
                this.props.facebookStore.setPages(res);
                this.setState({ open: true });
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleOpenModal = () => this.setState({ openConfirmModal: true });

    handleCloseModal = () => this.setState({ openConfirmModal: false });

    handleConfirmDisconnect = () => {
        const { profile } = this.props.profileStore;
        this.props.templateStore.showLoadingScreen();
        this.props.instagramStore.disconnectAccount(profile.id).then(resp => {
            this.props.templateStore.hideLoadingScreen();
            this.props.profileStore.resetInstagram();
            this.handleCloseModal();
            this.props.templateStore.openSnackbar("This profile has been disconnected to your Instagram page");
        });
    };

    showInfoPopup = () => this.setState({ showInfoPopup: true });

    closeInfoPopup = () => this.setState({ showInfoPopup: false });
    

    handleListItemClick2 = page => {
        const { profile } = this.props.profileStore;
        this.props.templateStore.showLoadingScreen();
        this.props.facebookStore.setInstagramPage(profile.id, page.id, page.access_token, page.name).then(resp => {
            this.props.templateStore.hideLoadingScreen();
            
            this.props.profileStore.setInstagram(resp);
            if(profile.type === ProfileType.Agent.ordinal || profile.type === ProfileType.Musician.ordinal){
                this.props.profileStore.loadProfiles();
            }else if(profile.type === ProfileType.Venue.ordinal){
                this.props.refresh()
            }
           
            this.handleClose();
            this.props.templateStore.openSnackbar("This profile has been connected to your Instagram page");
        });
    };

    handlePayment = () => {
        this.setState({
            redirectToPaymentPage: true
        });
    };

    render() {
        if (this.state.redirectToPaymentPage) return <Redirect to="../../../billing/payment" />;
        const { pages } = this.props.facebookStore;
        const { profile, currentProfile } = this.props.profileStore;
        const { token } = this.props.commonStore;
        let hasSubscribe = false;
        if (token && currentProfile && jwtDecode(token).scope.includes(x => x === currentProfile.typeString).length > 0) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }
        return (
            <Fragment>
                <Tooltip title={hasSubscribe ? "Share to your Instagram Page" : "Upgrade to enable Instagram integration"}>
                    {profile && profile.instagramProfile ? (
                         <Button className="instagram-button-login" onClick={this.handleOpenModal}>  <span style={{color:"white", marginTop:-5}}><Icon style={{marginTop:10,marginRight:10}}>
                                <i className={"fab fa-instagram"} />
                            </Icon> {profile.instagramAccessToken ? `Connected to ${profile.instagramProfile}` : "Reconnect your Instagram Account"}</span>
                        </Button>
                       
                    ) : (
                        <Button className="instagram-button-login" onClick={this.showInfoPopup}>  <span style={{color:"white", marginTop:-5}}><Icon style={{marginTop:10,marginRight:10}}>
                                <i className={"fab fa-instagram"} />
                            </Icon> {`Connect to Instagram`}</span>
                        </Button>
                       
                    )}
                </Tooltip>
                <FacebookLogin
                    appId={FACEBOOK_APP_ID.toString()}
                    callback={this.handleFacebookShare.bind(this)}
                    scope="pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement,business_management"
                    // cssClass="instagram-button-login"
                    style={{ display: "none !important" }}
                    // textButton="&nbsp;&nbsp; Connect Instagram to HG"
                    render={renderProps => (
                        <Button className="instagram-button-login" style={{display:"none"}} ref={this.myRef}  onClick={renderProps.onClick}>  <span style={{color:"white", marginTop:-5}}><Icon style={{marginTop:10,marginRight:10}}>
                        <i className={"fab fa-instagram"} />
                            </Icon> {`Connect Instagram to HG`}</span>
                        </Button>
                      )}
                      disableMobileRedirect={true}
                    // icon={<Icon><i className={"fab fa-instagram logo-fb"} /></Icon>}
                />
                <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open && !this.props.isMYOBRedirect} className="dialog-div" color="primary">
                    <DialogTitle className="facebook-dialog-title"><span className="facebook-dialog-text-title">Connecting to Instagram</span></DialogTitle>
                    <DialogContentText style={{padding:10}}  className="blackText">Please select the page connected to your Instagram account:</DialogContentText>
                    <div style={{marginTop:40, marginBottom:40}}>
                        {
                            pages?.length? 
                            <List>
                            {pages.map(page => (
                                <ListItem button onClick={() => this.handleListItemClick2(page)} key={page.id}>
                                    <ListItemIcon>
                                        <InboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={page.name} secondary={page.category} />
                                </ListItem>
                            ))}
                        </List>
                        :
                        <DialogContentText style={{textAlign:"center"}} className="blackText">No fans page available</DialogContentText>
                   
                        }
                      
                    </div>
                    <DialogContentText style={{padding:10}} className="blackText"><div dangerouslySetInnerHTML={{ __html: footerHtmlText }} /></DialogContentText>
                    

                </Dialog>

                <ConfirmationModal
                    open={this.state.openConfirmModal}
                    message="Disconnect this Instagram page account?"
                    closeHandler={this.handleCloseModal}
                    confirmationHandler={this.handleConfirmDisconnect}
                    declineHandler={this.handleCloseModal}
                    confirmationLabel="Disconnect Instagram"
                    maxWidth="sm"
                />

<IntegrateInstagramForm
                    open={this.state.showInfoPopup}
                    message="Disconnect this Instagram page account?"
                    closeHandler={this.closeInfoPopup}
                    confirmationHandler={this.handleClick2}
                    declineHandler={this.closeInfoPopup}
                    confirmationLabel="Connect"
                    maxWidth="sm"
                />

            </Fragment>
        );
    }
}

export default withMobileDialog()(withRouter(FacebookPageDialog2));
