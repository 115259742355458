import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { Grid } from "@material-ui/core";

import MomentUtils from "@date-io/moment";

import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";

import moment from "moment";

import HubTimepicker from "../../timepicker/HubTimepicker";

import { BookingStatusType } from "../../../types/enum";

import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "./PrivateBookingDateDetails.css";

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore")
@observer
class PrivateBookingDateDetails extends Component {
    handleRepeatTypeChange = e => {
        this.props.bookingRequestStore.setBooking("repeatType", e.target.value);
        this.props.bookingRequestStore.setBooking("repeatCount", 1);
    };
    handleRepeatCountChange = e => {
        if (e.target.value === "0") {
            this.props.bookingRequestStore.setBooking("repeatCount", 1);
        } else {
            this.props.bookingRequestStore.setBooking("repeatCount", parseInt(e.target.value, 10));
        }
    };
    handleDateStartChange = e => {
        this.props.bookingRequestStore.setBooking("dateStart", moment(e).startOf("day"));
        this.props.bookingRequestStore.setPrivateBookingHeading();
        this.props.bookingRequestStore.getBookableActs();

    };
    handleTimeStartChange = e => {
        /*let {
            timeEnd
        } = this.props.bookingRequestStore.booking;

        if( moment(e, 'HH:mm').isAfter( moment(timeEnd, 'HH:mm') ) ){
            this.props.bookingRequestStore.setBooking("timeEnd", e);
        }*/
        this.props.bookingRequestStore.setBooking("timeStart", e);
        this.props.bookingRequestStore.getBookableActs();
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };
    handleTimeEndChange = e => {
        this.props.bookingRequestStore.setBooking("timeEnd", e);
        this.props.bookingRequestStore.getBookableActs();
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };

    handleLoadInStartChange = e => {
        this.props.bookingRequestStore.setBooking("loadInStart", e);
        this.props.bookingRequestStore.getBookableActs();
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };

    handleLoadInEndChange = e => {
        this.props.bookingRequestStore.setBooking("loadInEnd", e);
        this.props.bookingRequestStore.getBookableActs();
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };

    handleSoundcheckStartChange = e => {
        this.props.bookingRequestStore.setBooking("soundCheckStart", e);
        this.props.bookingRequestStore.getBookableActs();
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };

    handleSoundcheckEndChange = e => {
        this.props.bookingRequestStore.setBooking("soundCheckEnd", e);
        this.props.bookingRequestStore.getBookableActs();
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };

    shouldDisabledDate = date => {
        let { venueId } = this.props.bookingRequestStore.booking;
        let days = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        if (venueId) {
            var agentVenue = this.props.agentVenueStore.agentVenues.filter(agentVenue => agentVenue.venueId === venueId)[0];
            if (agentVenue && !agentVenue[days[moment(date).isoWeekday()]]) {
                return true;
            }
        }
        return false;
    };

    render() {
        let { booking, error } = this.props.bookingRequestStore;
        let { dateStart, timeStart, timeEnd, loadInStart, loadInEnd, soundCheckStart, soundCheckEnd, status, createdById, canUpdate } = booking;

        let { readOnly } = this.props;
        let { currentProfile } = this.props.profileStore;

        let confirmedEditable =
            status !== BookingStatusType.Cancelled.ordinal &&
            status !== BookingStatusType.Completed.ordinal &&
            moment(dateStart).endOf("day") >= moment() &&
            (!createdById || createdById === currentProfile.id || canUpdate);

        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            id="booking-date"
                            className="booking-date-picker"
                            keyboard
                            value={dateStart}
                            fullWidth
                            margin="dense"
                            onChange={this.handleDateStartChange}
                            label="Date Start"
                            minDate={
                                status !== BookingStatusType.Cancelled.ordinal &&
                                status !== BookingStatusType.CancelledByAct.ordinal &&
                                status !== BookingStatusType.CancelledByActHistory.ordinal &&
                                status !== BookingStatusType.Completed.ordinal
                                    ? moment().startOf("day")
                                    : undefined
                            }
                            format="ddd, DD-MM-YYYY"
                            keyboardIcon={<EventIcon />}
                            leftArrowIcon={<KeyboardArrowLeftIcon />}
                            rightArrowIcon={<KeyboardArrowRightIcon />}
                            disabled={readOnly}
                            style={{ marginTop: 0 }}
                            shouldDisableDate={this.shouldDisabledDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        margin="none"
                        label="Start Time"
                        value={moment(timeStart, "HH:mm")}
                        onChange={this.handleTimeStartChange}
                        // readOnly={!confirmedEditable}
                        error={error && error["timeStart"]}
                        helperText={error && error["timeStart"] ? error["timeStart"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        margin="none"
                        label="End Time"
                        value={moment(timeEnd, "HH:mm")}
                        start={moment(timeStart, "HH:mm")}
                        onChange={this.handleTimeEndChange}
                        // readOnly={!confirmedEditable}
                        error={error && error["timeEnd"]}
                        helperText={error && error["timeEnd"] ? error["timeEnd"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="loadin-start"
                        margin="none"
                        label="Load In Start"
                        value={loadInStart ? moment(loadInStart, "HH:mm") : undefined}
                        onChange={this.handleLoadInStartChange}
                        readOnly={!confirmedEditable}
                        error={error && error["loadInStart"]}
                        helperText={error && error["loadInStart"] ? error["loadInStart"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="loadin-end"
                        margin="none"
                        label="Load In End"
                        value={loadInEnd ? moment(loadInEnd, "HH:mm") : undefined}
                        onChange={this.handleLoadInEndChange}
                        readOnly={!confirmedEditable}
                        error={error && error["loadInEnd"]}
                        helperText={error && error["loadInEnd"] ? error["loadInEnd"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="time-start"
                        margin="none"
                        label="Soundcheck Start"
                        value={soundCheckStart ? moment(soundCheckStart, "HH:mm") : undefined}
                        onChange={this.handleSoundcheckStartChange}
                        readOnly={!confirmedEditable}
                        error={error && error["soundCheckStart"]}
                        helperText={error && error["soundCheckStart"] ? error["soundCheckStart"] : "Venue's Local Time"}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <HubTimepicker
                        id="time-end"
                        margin="none"
                        label="Soundcheck End"
                        value={soundCheckEnd ? moment(soundCheckEnd, "HH:mm") : undefined}
                        onChange={this.handleSoundcheckEndChange}
                        readOnly={!confirmedEditable}
                        error={error && error["soundCheckEnd"]}
                        helperText={error && error["soundCheckEnd"] ? error["soundCheckEnd"] : "Venue's Local Time"}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default PrivateBookingDateDetails;
