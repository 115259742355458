import { observable, action } from "mobx";
import agent from "../agent";
import { NotificationType } from "../types/enum";

class NotificationSettingStore {
    @observable notificationSetting = undefined;

    @action
    load() {
        agent.NotificationSetting.load().then(
            action(notificationSetting => {
                this.notificationSetting = notificationSetting;
            })
        );
    }

    @action
    change(notificationType, mediaType, checked) {
        switch (notificationType) {
            case NotificationType.ActRosterInvited.ordinal:
                this.notificationSetting.actRosterInvited = checked
                    ? this.notificationSetting.actRosterInvited | mediaType
                    : this.notificationSetting.actRosterInvited & ~mediaType;
                break;
            case NotificationType.ActRosterAccepted.ordinal:
                this.notificationSetting.actRosterAccepted = checked
                    ? this.notificationSetting.actRosterAccepted | mediaType
                    : this.notificationSetting.actRosterAccepted & ~mediaType;
                break;
            case NotificationType.ActRosterRejected.ordinal:
                this.notificationSetting.actRosterRejected = checked
                    ? this.notificationSetting.actRosterRejected | mediaType
                    : this.notificationSetting.actRosterRejected & ~mediaType;
                break;
            case NotificationType.ActRosterRemoved.ordinal:
                this.notificationSetting.actRosterRemoved = checked
                    ? this.notificationSetting.actRosterRemoved | mediaType
                    : this.notificationSetting.actRosterRemoved & ~mediaType;
                break;

            case NotificationType.PasswordChanged.ordinal:
                this.notificationSetting.passwordChanged = checked
                    ? this.notificationSetting.passwordChanged | mediaType
                    : this.notificationSetting.passwordChanged & ~mediaType;
                break;
            case NotificationType.AgentActRosterInvitation.ordinal:
                this.notificationSetting.agentActRosterInvitation = checked
                    ? this.notificationSetting.agentActRosterInvitation | mediaType
                    : this.notificationSetting.agentActRosterInvitation & ~mediaType;
                break;
            case NotificationType.VenueAcceptedAgentInvitation.ordinal:
                this.notificationSetting.venueAcceptedAgentInvitation = checked
                    ? this.notificationSetting.venueAcceptedAgentInvitation | mediaType
                    : this.notificationSetting.venueAcceptedAgentInvitation & ~mediaType;
                break;
            case NotificationType.VenueMemberRemove.ordinal:
                this.notificationSetting.VenueMemberRemove = checked
                    ? this.notificationSetting.VenueMemberRemove | mediaType
                    : this.notificationSetting.VenueMemberRemove & ~mediaType;
                break;
            case NotificationType.VenueAcceptPermissionManageAct.ordinal:
                this.notificationSetting.VenueAcceptPermissionManageAct = checked
                    ? this.notificationSetting.VenueAcceptPermissionManageAct | mediaType
                    : this.notificationSetting.VenueAcceptPermissionManageAct & ~mediaType;
                break;
                    
            case NotificationType.AgentActRosterRemoved.ordinal:
                this.notificationSetting.agentActRosterRemoved = checked
                    ? this.notificationSetting.agentActRosterRemoved | mediaType
                    : this.notificationSetting.agentActRosterRemoved & ~mediaType;
                break;
            case NotificationType.AgentActRosterAccepted.ordinal:
                this.notificationSetting.agentActRosterAccepted = checked
                    ? this.notificationSetting.agentActRosterAccepted | mediaType
                    : this.notificationSetting.agentActRosterAccepted & ~mediaType;
                break;
            case NotificationType.AgentActRosterRejected.ordinal:
                this.notificationSetting.agentActRosterRejected = checked
                    ? this.notificationSetting.agentActRosterRejected | mediaType
                    : this.notificationSetting.agentActRosterRejected & ~mediaType;
                break;
            case NotificationType.ActRemoveAgent.ordinal:
                this.notificationSetting.actRemoveAgent = checked
                    ? this.notificationSetting.actRemoveAgent | mediaType
                    : this.notificationSetting.actRemoveAgent & ~mediaType;
                break;

            case NotificationType.AgentVenueRosterInvited.ordinal:
                this.notificationSetting.agentVenueRosterInvited = checked
                    ? this.notificationSetting.agentVenueRosterInvited | mediaType
                    : this.notificationSetting.agentVenueRosterInvited & ~mediaType;
                break;
            case NotificationType.AgentVenueRosterAccepted.ordinal:
                this.notificationSetting.agentVenueRosterAccepted = checked
                    ? this.notificationSetting.agentVenueRosterAccepted | mediaType
                    : this.notificationSetting.agentVenueRosterAccepted & ~mediaType;
                break;
            case NotificationType.AgentVenueRosterRejected.ordinal:
                this.notificationSetting.agentVenueRosterRejected = checked
                    ? this.notificationSetting.agentVenueRosterRejected | mediaType
                    : this.notificationSetting.agentVenueRosterRejected & ~mediaType;
                break;
            case NotificationType.AgentVenueRosterRemoved.ordinal:
                this.notificationSetting.agentVenueRosterRemoved = checked
                    ? this.notificationSetting.agentVenueRosterRemoved | mediaType
                    : this.notificationSetting.agentVenueRosterRemoved & ~mediaType;
                break;

            case NotificationType.VenueActRosterInvited.ordinal:
                this.notificationSetting.venueActRosterInvited = checked
                    ? this.notificationSetting.venueActRosterInvited | mediaType
                    : this.notificationSetting.venueActRosterInvited & ~mediaType;
                break;
            case NotificationType.VenueActRosterRemoved.ordinal:
                this.notificationSetting.venueActRosterRemoved = checked
                    ? this.notificationSetting.venueActRosterRemoved | mediaType
                    : this.notificationSetting.venueActRosterRemoved & ~mediaType;
                break;
            case NotificationType.VenueActRosterAccepted.ordinal:
                this.notificationSetting.venueActRosterAccepted = checked
                    ? this.notificationSetting.venueActRosterAccepted | mediaType
                    : this.notificationSetting.venueActRosterAccepted & ~mediaType;
                break;
            case NotificationType.VenueActRosterRejected.ordinal:
                this.notificationSetting.venueActRosterRejected = checked
                    ? this.notificationSetting.venueActRosterRejected | mediaType
                    : this.notificationSetting.venueActRosterRejected & ~mediaType;
                break;

            case NotificationType.LineupAdded.ordinal:
                this.notificationSetting.lineupAdded = checked
                    ? this.notificationSetting.lineupAdded | mediaType
                    : this.notificationSetting.lineupAdded & ~mediaType;
                break;
            case NotificationType.LineupAccepted.ordinal:
                this.notificationSetting.lineupAccepted = checked
                    ? this.notificationSetting.lineupAccepted | mediaType
                    : this.notificationSetting.lineupAccepted & ~mediaType;
                break;
            case NotificationType.LineupRejected.ordinal:
                this.notificationSetting.lineupRejected = checked
                    ? this.notificationSetting.lineupRejected | mediaType
                    : this.notificationSetting.lineupRejected & ~mediaType;
                break;
            case NotificationType.LineupConfirmed.ordinal:
                this.notificationSetting.lineupConfirmed = checked
                    ? this.notificationSetting.lineupConfirmed | mediaType
                    : this.notificationSetting.lineupConfirmed & ~mediaType;
                break;
            case NotificationType.LineupReminder.ordinal:
                this.notificationSetting.lineupReminder = checked
                    ? this.notificationSetting.lineupReminder | mediaType
                    : this.notificationSetting.lineupReminder & ~mediaType;
                break;
            case NotificationType.LineupReminder2.ordinal:
                this.notificationSetting.lineupReminder2 = checked
                    ? this.notificationSetting.lineupReminder2 | mediaType
                    : this.notificationSetting.lineupReminder2 & ~mediaType;
                break;
            case NotificationType.LineupCancelled.ordinal:
                this.notificationSetting.lineupCancelled = checked
                    ? this.notificationSetting.lineupCancelled | mediaType
                    : this.notificationSetting.lineupCancelled & ~mediaType;
                break;
            case NotificationType.LineupRemoved.ordinal:
                this.notificationSetting.lineupRemoved = checked
                    ? this.notificationSetting.lineupRemoved | mediaType
                    : this.notificationSetting.lineupRemoved & ~mediaType;
                break;

            case NotificationType.BookingCreated.ordinal:
                this.notificationSetting.bookingCreated = checked
                    ? this.notificationSetting.bookingCreated | mediaType
                    : this.notificationSetting.bookingCreated & ~mediaType;
                break;
            case NotificationType.BookingUpdated.ordinal:
                this.notificationSetting.bookingUpdated = checked
                    ? this.notificationSetting.bookingUpdated | mediaType
                    : this.notificationSetting.bookingUpdated & ~mediaType;
                break;

            case NotificationType.BookingAccepted.ordinal:
                this.notificationSetting.bookingAccepted = checked
                    ? this.notificationSetting.bookingAccepted | mediaType
                    : this.notificationSetting.bookingAccepted & ~mediaType;
                break;
            case NotificationType.BookingDeclined.ordinal:
                this.notificationSetting.bookingDeclined = checked
                    ? this.notificationSetting.bookingDeclined | mediaType
                    : this.notificationSetting.bookingDeclined & ~mediaType;
                break;
            case NotificationType.BookingHolded.ordinal:
                this.notificationSetting.bookingHolded = checked
                    ? this.notificationSetting.bookingHolded | mediaType
                    : this.notificationSetting.bookingHolded & ~mediaType;
                break;
            case NotificationType.BookingReminder.ordinal:
                this.notificationSetting.bookingReminder = checked
                    ? this.notificationSetting.bookingReminder | mediaType
                    : this.notificationSetting.bookingReminder & ~mediaType;
                break;

            case NotificationType.BookingCancelled.ordinal:
                this.notificationSetting.bookingCancelled = checked
                    ? this.notificationSetting.bookingCancelled | mediaType
                    : this.notificationSetting.bookingCancelled & ~mediaType;
                break;

            case NotificationType.BookingRequestCreated.ordinal:
                this.notificationSetting.bookingRequestCreated = checked
                    ? this.notificationSetting.bookingRequestCreated | mediaType
                    : this.notificationSetting.bookingRequestCreated & ~mediaType;
                break;
            case NotificationType.BookingRequestAccepted.ordinal:
                this.notificationSetting.bookingRequestAccepted = checked
                    ? this.notificationSetting.bookingRequestAccepted | mediaType
                    : this.notificationSetting.bookingRequestAccepted & ~mediaType;
                break;
            case NotificationType.BookingRequestDeclined.ordinal:
                this.notificationSetting.bookingRequestDeclined = checked
                    ? this.notificationSetting.bookingRequestDeclined | mediaType
                    : this.notificationSetting.bookingRequestDeclined & ~mediaType;
                break;
            case NotificationType.BookingRequestRemoved.ordinal:
                this.notificationSetting.bookingRequestRemoved = checked
                    ? this.notificationSetting.bookingRequestRemoved | mediaType
                    : this.notificationSetting.bookingRequestRemoved & ~mediaType;
                break;

            case NotificationType.AgentConsultantInvitation.ordinal:
                this.notificationSetting.agentConsultantInvitation = checked
                    ? this.notificationSetting.agentConsultantInvitation | mediaType
                    : this.notificationSetting.agentConsultantInvitation & ~mediaType;
                break;
            case NotificationType.AgentConsultantUpdated.ordinal:
                this.notificationSetting.agentConsultantUpdated = checked
                    ? this.notificationSetting.agentConsultantUpdated | mediaType
                    : this.notificationSetting.agentConsultantUpdated & ~mediaType;
                break;

            case NotificationType.AgentConsultantAccepted.ordinal:
                this.notificationSetting.agentConsultantAccepted = checked
                    ? this.notificationSetting.agentConsultantAccepted | mediaType
                    : this.notificationSetting.agentConsultantAccepted & ~mediaType;
                break;
            case NotificationType.AgentConsultantDeclined.ordinal:
                this.notificationSetting.agentConsultantDeclined = checked
                    ? this.notificationSetting.agentConsultantDeclined | mediaType
                    : this.notificationSetting.agentConsultantDeclined & ~mediaType;
                break;
            case NotificationType.ConsultantQuitAgency.ordinal:
                this.notificationSetting.consultantQuitAgency = checked
                    ? this.notificationSetting.consultantQuitAgency | mediaType
                    : this.notificationSetting.consultantQuitAgency & ~mediaType;
                break;
            case NotificationType.AgentRemoveConsultant.ordinal:
                this.notificationSetting.agentRemoveConsultant = checked
                    ? this.notificationSetting.agentRemoveConsultant | mediaType
                    : this.notificationSetting.agentRemoveConsultant & ~mediaType;
                break;

            default:
                break;
        }
    }

    @action
    submit() {
        if (this.notificationSetting) {
            return agent.NotificationSetting.update(this.notificationSetting);
        }
    }
}

export default new NotificationSettingStore();
