import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { Stepper, Step, StepLabel, StepContent, Hidden } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "./Welcome.css";

import Header from "../components/Header";
import Typography from "@material-ui/core/Typography";
import ChooseProfile from "../components/welcome/ChooseProfile";
import CreateAct from "../components/welcome/CreateAct";
import CreateVenue from "../components/welcome/CreateVenue";
import GoToDashboard from "../components/welcome/GoToDashboard";

import { ProfileType } from "../types/enum";

function getSteps(step, profileType) {
    switch (step) {
        case 0:
        case 10:
        case 1:
        case 2:
            if (profileType === ProfileType.Agent.ordinal) {
                return ["Select your profile type", "View your public profile"];
            } else {
                return ["Select your profile type", "Do you need to create a bookable act?", "View your public profile"];
            }
        case 3:
            return ["Select your profile type", "Do you need to invite an act to your rooster?", "View your public profile"];
        case 20:
        case 21:
            return ["Select your profile type", "Are you a group or a solo venue vendor?", "View your public profile"];
        default:
            if (profileType === ProfileType.Agent.ordinal) {
                return ["Select your profile type", "View your public profile"];
            } else {
                return ["Select your profile type", "Do you need to create a bookable act?", "View your public profile"];
            }
    }
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return <ChooseProfile />;
        case 10:
            return <ChooseProfile type="musician" />;
        case 20:
            return <ChooseProfile type="venue" />;
        case 1:
            return <CreateAct />;
        case 21:
            return <CreateVenue />;
        case 2:
            return <GoToDashboard />;
        default:
            return <GoToDashboard />;
    }
}

function getTitleWelcome(step){
    switch(step){
        case 1:
            return "You're nearly done..."
        default : 
            return "Welcome to Hot Giggity!"
    }
}

function getSubtitleWelcome(step){
    switch(step){
        case 1:
            return "Now that you have set up your personal profile, you now need to create an act so that you can be booked for gigs!"
        default : 
            return "Choose your profile type to get started."
    }
}

@inject("templateStore", "profileStore", "userStore")
@withRouter
@observer
class Welcome extends Component {
    params = undefined;

    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.role) {
            if (this.props.match.params.role === "musician" || this.props.match.params.role === "act") {
                this.props.history.push("/musician/edit/new");
            } else if (this.props.match.params.role === "agent" || this.props.match.params.role === "consultant") {
                if(this.props.match.params.role==="agent"){
                    this.props.history.push(`/agent/edit/new`);
                }else{
                    this.props.history.push(`/agent/edit/new/${this.props.match.params.role}/create`);
                }
            } else if (this.props.match.params.role === "venue") {
                /*else if(this.props.match.params.role === 'act'){
                if(this.props.match.params.profileId && this.props.match.params.profileId !== 0){
                    this.props.history.push(`/myact/edit/${this.props.match.params.profileId}`);
                }
                else{
                    this.props.history.push("/myact");
                }
            }*/
                if (this.props.match.params.profileId && this.props.match.params.profileId !== 0) {
                    this.props.history.push(`/venues/${this.props.match.params.profileId}/edit`);
                } else {
                    this.props.history.push("/venues");
                }
            } else if (this.props.match.params.role === "staff") {
                this.props.history.push("/staff/edit/new");
            }
        }
        this.props.profileStore.loadProfiles().then(profiles => {
            profiles.forEach(profile => {
                if (profile.type === 1) {
                    this.props.templateStore.setWelcomeStep(1);
                }
            });
        });
    }

    render() {
        const { currentUser } = this.props.userStore;
        const { currentProfile } = this.props.profileStore;

        const steps = getSteps(currentUser?.status, currentProfile ? currentProfile.type : undefined);
        return (
            <div className="Welcome">
                <Header hideToggler handleDrawerToggle={this.handleDrawerToggle} />
                <Grid container className="main-grid" justify="center">
                    <Grid container spacing={24} justify="center">
                        <Grid item xs={12} sm={8} md={6}>
                            <div className="intro">
                                <h2 id="h-welcome">{getTitleWelcome(currentUser?.status)}</h2>
                                {currentUser?.status !== 2 && (
                                    <Typography component="p" className="sub-heading">
                                        {getSubtitleWelcome(currentUser?.status)}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24} justify="center">
                        <Grid item xs={12} md={12}>
                            <Hidden mdUp>
                                <Stepper activeStep={currentUser?.status % 10} orientation="vertical" className="stepperHub">
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={label}>
                                                <StepLabel
                                                    classes={{
                                                        root: "stepLabel",
                                                        label: "label",
                                                        active: "active"
                                                    }}
                                                    StepIconProps={{
                                                        classes: {
                                                            root: "stepIcon",
                                                            active: "active"
                                                        }
                                                    }}
                                                >
                                                    {label}
                                                </StepLabel>
                                                <StepContent>{getStepContent(currentUser?.status)}</StepContent>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Hidden>
                            <Hidden smDown>
                                <Grid className="stepContent">{getStepContent(currentUser?.status)}</Grid>
                                <Stepper activeStep={currentUser?.status % 10} orientation="horizontal" alternativeLabel className="stepperHub">
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={label}>
                                                <StepLabel
                                                    classes={{
                                                        root: "stepLabel",
                                                        label: "label",
                                                        active: "active"
                                                    }}
                                                    StepIconProps={{
                                                        classes: {
                                                            root: "stepIcon",
                                                            active: "active"
                                                        }
                                                    }}
                                                >
                                                    {label}
                                                </StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Welcome;
