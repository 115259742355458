import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";
import UserTable from "../../pages/organization/UserTable";
import AgentTable from "../venue/AgentTable/AgentTable";
import { ProfileRelationType } from "../../types/enum";
import Loading from "../loadingSpinner/Loading";
import { Prompt } from "react-router-dom";

@inject("memberProfileStore", "templateStore", "authStore", "organisationMemberStore", "agentVenueStore", "userInvitationStore", "profileStore")
@withRouter
@observer
class Member extends React.Component {
    state = {
        isLoading: true
    };

    componentDidMount() {
        // this.props.templateStore.showLoadingScreen();
        // set type
        this.props.memberProfileStore.setType(this.props.templateStore.getType(this.props.type));
        this.props.organisationMemberStore.reset();

        Promise.all([
            this.props.memberProfileStore.loadInitialData(this.props.profileId).then(profile => {
                if (profile && profile.members) {
                    this.props.organisationMemberStore.setMembers(profile.members);
                    this.props.organisationMemberStore.setVenueId(profile.id);
                    this.props.organisationMemberStore.setLevel(4);
                }
            }),
            this.props.agentVenueStore.getVenueAgent(this.props.profileId),
            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentVenue.ordinal, this.props.profileId)
        ]).then(() => {
            this.props.templateStore.hideLoadingScreen();
            this.setState({ isLoading: false });
        });
    }

    componentWillUnmount() {
        this.props.templateStore.openMemberBlock();
    }

    handleBack = () => {
        if (this.props.type === "venue") {
            const { profile } = this.props.profileStore;
            this.props.history.push(`/venues/${profile.id}/edit/social`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.props.templateStore.openMemberBlock();
        this.props.memberProfileStore.submit().then(resp => {
            // this.props.agentVenueStore.submit().then(() => {
            //     if (this.props.type === "venue") {
            //         this.props.history.push(`/venues/${resp.id}/edit/favourite`);
            //     }
            //     const { editProfileActiveStep } = this.props.templateStore;
            //     this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
            // });
            const { profile } = this.props.profileStore;
            if (this.props.type === "venue") {
                this.props.history.push(`/venues/${profile.id}/edit/favourite`);
            }
        });
    };

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    render() {
        const { editProfileActiveStep } = this.props.templateStore;
        const { memberBlock } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        const { isLoading } = this.state;

        return (
            <div className="Profile-Privacy">
                <Prompt when={memberBlock} message={location => `You have unsaved Member data, do you still want to leave this section?`} />
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Member
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Use this section to invite your staff to manage your Hot Giggity profile and assign them different permissions. You can
                                    also use this section to invite your booking agent and specific which days of the week they will be able to book
                                    entertainment for your venue.
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <UserTable level={4} />
                                </Grid>

                                <Grid item xs={12}>
                                    <AgentTable />
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="action">
                                        <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                            Back
                                        </Button>
                                        <Button variant="contained" color="primary" type="submit">
                                            {editProfileActiveStep === steps.length - 1 ? "Save/Finish" : "Save/Next"}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default Member;
