import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";

import { Grid, InputLabel, Dialog, AppBar, Toolbar, Typography, Button } from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

import RichTextEditor from "../../richTextEditor/RichTextEditor";

// import { BookingStatusType } from "../../../types/enum";

// import moment from "moment";

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore")
@observer
class BookingAgentContract extends Component {
    state = {
        contractFullState: false
    };

    handleContractChange = e => {
        this.props.bookingRequestStore.setBooking("agentContract", e);
    };

    handleOpenContract = () => {
        this.setState({
            ...this.state,
            contractFullState: true
        });
    };

    handleCloseContract = () => {
        this.setState({
            ...this.state,
            contractFullState: false
        });
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    render() {
        let { agentContract } = this.props.bookingRequestStore.booking;
        if (agentContract == null || agentContract == "null") agentContract = "";
        const {isActChange, actIsChanging} = this.props

        //const { currentProfile } = this.props.profileStore;

        // let confirmedEditable =
        //     status !== BookingStatusType.Cancelled.ordinal &&
        //     status !== BookingStatusType.Completed.ordinal &&
        //     moment(dateStart).endOf("day") >= moment() &&
        //     (!createdById || createdById === currentProfile.id);

        const readonly = this.props.readOnly;

        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={0}>
                            <Grid
                                item
                                xs={12}
                                className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <InputLabel>{this.props.isAct ? "Act " : "Agent "} Contract</InputLabel>
                                {!readonly &&
                                    <Button color="primary" size="small" onClick={this.handleOpenContract} className="button-small fullscreen-bio">
                                        <FullscreenIcon />
                                        <Typography>Fullscreen</Typography>
                                    </Button>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {readonly ? 
                                    <Typography style={{border:"2px solid #E0E0E0", padding:5, borderRadius:5}}><div dangerouslySetInnerHTML={{__html: agentContract}} style={{minHeight:50}}></div> </Typography> :
                                    <RichTextEditor
                                        id="booking-contract-rte"
                                        value={agentContract}
                                        onChange={this.handleContractChange}
                                        readOnly={readonly}
                                        resetEditor={isActChange || this.state.contractFullState} 
                                        onChangeEditor={actIsChanging}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog fullScreen open={this.state.contractFullState} onClose={this.handleCloseContract} transition={this.transition}>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                Agent Contract
                            </Typography>
                            <Button color="inherit" onClick={this.handleCloseContract}>
                                Exit Fullscreen
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="fullscreen-rich-text">
                        {readonly ? 
                            <Typography ><div dangerouslySetInnerHTML={{__html: agentContract}}></div> </Typography> :
                            <RichTextEditor value={agentContract} onChange={this.handleContractChange} readOnly={readonly} />
                        }
                    </div>
                </Dialog>
            </Fragment>
        );
    }
}

export default BookingAgentContract;
