import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import TableRow from "@material-ui/core/TableRow";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import moment from "moment";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Link,withRouter } from "react-router-dom";
import { ProfileType, BookingStatusType, BookingType } from "../../types/enum";

import ModalContainer from "../modal/ModalContainer";

@inject("bookingRequestStore", "profileStore", "templateStore")
@withRouter
@observer
class BookingDialog extends Component {
    handleDupicateRequest = (id) => {
        const {currentProfile} = this.props.profileStore
        this.props.bookingRequestStore.duplicateBooking(id,currentProfile.id ).then(response => {
            setTimeout(
                function () {
                    this.props.history.replace(`/booking/${response.id}/edit`);
                }.bind(this),
                2000
            );
            
        });
    };

    render() {
        const { bookingDetail } = this.props.bookingRequestStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const statusBooking = this.props.statusBooking ? this.props.statusBooking : bookingDetail.status
        let actName = bookingDetail?.act?.actName;
        const link = bookingDetail.bookingRequests.length && bookingDetail.bookingRequests.filter(x=> !x.deleted)[0] ? `/musician/booking/${bookingDetail.id}/details/${bookingDetail.bookingRequests.filter(x=> !x.deleted)[0].id}` : `/musician/booking/${bookingDetail.id}/details`
        if (bookingDetail && bookingDetail.bookingRequests && bookingDetail.bookingRequests[0] !== undefined) {
            actName = bookingDetail.bookingRequests.filter(x => !x.deleted)[0]?.act?.actName
        }
        return (
            <ModalContainer open={this.props.open} onClose={this.props.handleClose} title="Booking Detail" coloredDialogTitle={true}>
                <DialogContent className="see-more-dialog-content">
                    <div id="alert-dialog-description">
                        <Table className="table-booking-detail">
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ width: "40%" }}>
                                        <Typography>Title</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {bookingDetail.title && bookingDetail.title !== "" ? bookingDetail.title : bookingDetail.tempTitle}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "40%" }}>
                                        <Typography>Provider</Typography>
                                    </TableCell>
                                    <TableCell>{bookingDetail.agent && bookingDetail.agent.profileName ? bookingDetail.agent.profileName : bookingDetail.provider}{!bookingDetail.agent && bookingDetail.providerName}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{ width: "40%" }}>
                                        <Typography>Booking Creator</Typography>
                                    </TableCell>
                                    <TableCell>{bookingDetail?.createdBy ? (bookingDetail?.createdBy?.firstName + " " +bookingDetail?.createdBy?.lastName) : ""}</TableCell>
                                </TableRow>

                                {
                                    !(bookingDetail.type === BookingType.Normal.ordinal && bookingDetail.createdBy && bookingDetail.createdBy.type === ProfileType.Musician.ordinal) &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography>Act</Typography>
                                        </TableCell>
                                        <TableCell>{actName}</TableCell>
                                    </TableRow>

                                }
                                <TableRow>
                                    <TableCell>
                                        <Typography>Venue Name</Typography>
                                    </TableCell>
                                    <TableCell>{bookingDetail.venueName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Venue Contact</Typography>
                                    </TableCell>
                                    <TableCell>{bookingDetail.venueContact}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Venue Address</Typography>
                                    </TableCell>
                                    <TableCell>{bookingDetail.venueAddress}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Date/Time</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {bookingDetail.dateStart === bookingDetail.dateEnd
                                            ? bookingDetail.dateStart
                                            : bookingDetail.dateStart +
                                            " - " +
                                            bookingDetail.dateEnd}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>
                                    {bookingDetail.timeStart === bookingDetail.timeEnd
                                            ? moment(bookingDetail.timeStart, "HH:mm").format(this.props.templateStore.getFormat12Hour(bookingDetail.timeStart))
                                            : moment(bookingDetail.timeStart, "HH:mm").format(this.props.templateStore.getFormat12Hour(bookingDetail.timeStart)) +
                                            " - " +
                                            moment(bookingDetail.timeEnd, "HH:mm").format(this.props.templateStore.getFormat12Hour(bookingDetail.timeEnd))}
                                    </TableCell>
                                </TableRow>

                                    <Fragment>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>Number of Sets</Typography>
                                            </TableCell>
                                            <TableCell>{bookingDetail.setNumber}</TableCell>
                                        </TableRow>
                                        {bookingDetail.createdBy && (bookingDetail.createdBy.type === ProfileType.Agent.ordinal || bookingDetail.createdBy.type === ProfileType.Musician.ordinal) && bookingDetail.createdById === currentProfile.id && 
                                            (<TableRow>
                                                <TableCell>
                                                    <Typography>Gig Fee to Client</Typography>
                                                </TableCell>{ }
                                                <TableCell>{Number(bookingDetail.gigFee).toFixed(2)}</TableCell>
                                            </TableRow>
                                            )
                                        }

                                        { currentProfile.type === ProfileType.Staff.ordinal && (bookingDetail.createdById === currentProfile.id || bookingDetail.isBookingManagement) && 
                                            (<TableRow>
                                                <TableCell>
                                                    <Typography>Gig Fee to Client</Typography>
                                                </TableCell>{ }
                                                <TableCell>{Number(bookingDetail.gigFee).toFixed(2)}</TableCell>
                                            </TableRow>
                                            )
                                        }
                                        
                                        
                                        {/* {bookingDetail.type === BookingType.Normal.ordinal && bookingDetail.createdBy && bookingDetail.createdBy.type === ProfileType.Musician.ordinal ?
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>Gig Fee to Client</Typography>
                                                </TableCell>{ }
                                                <TableCell>{Number(bookingDetail.gigFee).toFixed(2)}</TableCell>
                                            </TableRow> :
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>Gig fee paid to Act</Typography>
                                                </TableCell>{ }
                                                <TableCell>{!isNaN(parseFloat(bookingDetail.actFee)) ? bookingDetail.actFee : Number(bookingDetail.gigFee).toFixed(2)}</TableCell>
                                            </TableRow>
                                        } */}
                                        {
                                            !bookingDetail.privateHireId ?
                                            
                                                <TableRow>
                                                    <TableCell style={{ verticalAlign: "top", paddingTop: 10 }}>
                                                        <Typography>PA Requirements</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                            // className="bio-section"
                                                            dangerouslySetInnerHTML={{
                                                                __html: bookingDetail && bookingDetail.requirements
                                                            }}
                                                            style={{ color: "black" }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                             : ""
                                        }
                                          {
                                            !bookingDetail.privateHireId ?
                                                <TableRow>
                                                    <TableCell style={{ verticalAlign: "top", paddingTop: 10 }}>
                                                        <Typography>Notes</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                            // className="bio-section"
                                                            dangerouslySetInnerHTML={{ __html: bookingDetail && bookingDetail.notes }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                             : ""
                                        }
                                       
                                    </Fragment>
                            
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose}>Close</Button>
                    {(currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Staff.ordinal || currentProfile.type === ProfileType.Musician.ordinal) &&
                        (currentProfile.id === bookingDetail.createdById || (currentProfile.type == ProfileType.Staff.ordinal && (bookingDetail.venue?.userId == currentProfile.userId || bookingDetail.isBookingManagement)) || (currentProfile.type === ProfileType.Staff.ordinal && bookingDetail.canUpdate)) &&
                        bookingDetail.type === BookingType.Normal.ordinal ? (
                        <Button component={Link} to={`/booking/${bookingDetail.id}/edit`} color="primary" autoFocus>
                            {statusBooking !== BookingStatusType.Completed.ordinal &&
                                statusBooking !== BookingStatusType.Cancelled.ordinal &&
                                moment(bookingDetail.originalDateStart) >= moment()
                                ? "Edit"
                                : "View"}
                        </Button>
                    ) : (
                        ""
                    )}

                    {(currentProfile.type === ProfileType.Agent.ordinal) &&
                        currentProfile.id === bookingDetail.createdById &&
                        bookingDetail.type === BookingType.Adhoc.ordinal ? (
                        <Button component={Link} to={`/privateBooking/${bookingDetail.id}/edit`} color="primary" autoFocus>
                            {statusBooking !== BookingStatusType.Completed.ordinal &&
                                statusBooking !== BookingStatusType.Cancelled.ordinal &&
                                moment(bookingDetail.originalDateStart) >= moment()
                                ? "Edit"
                                : "View"}
                        </Button>
                    ) : (
                        ""
                    )}
                    {currentProfile.type === ProfileType.Musician.ordinal
                        && currentProfile.id !== bookingDetail.createdById &&
                        bookingDetail.type === BookingType.Normal.ordinal ? (
                        <Button component={Link} to={link} color="primary">
                            View
                        </Button>
                    ) : (
                        ""
                    )}

                    {currentProfile.type === ProfileType.Agent.ordinal
                        && currentProfile.id !== bookingDetail.createdById && bookingDetail.isAdmin &&
                        bookingDetail.type === BookingType.Normal.ordinal ? (
                        <Button component={Link} to={link} color="primary">
                            View
                        </Button>
                    ) : (
                        ""
                    )}

                    {currentProfile.type === ProfileType.Agent.ordinal
                        && currentProfile.id !== bookingDetail.createdById && bookingDetail.isAdmin &&
                        bookingDetail.type === BookingType.Adhoc.ordinal ? (
                        <Button component={Link} to={`/privateBooking/${bookingDetail.id}/edit`} color="primary">
                            View
                        </Button>
                    ) : (
                        ""
                    )}

                    {(currentProfile.type === ProfileType.Musician.ordinal || currentProfile.type === ProfileType.Staff.ordinal) &&
                        bookingDetail.type === BookingType.Adhoc.ordinal ? (
                        <Button component={Link} to={`/privateBooking/${bookingDetail.id}/edit`} color="primary">
                            View
                        </Button>
                    ) : (
                        ""
                    )}
                    {
                        (currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Staff.ordinal || currentProfile.type === ProfileType.Musician.ordinal) &&
                        (currentProfile.id === bookingDetail.createdById || (currentProfile.type == ProfileType.Staff.ordinal && (bookingDetail.venue?.userId == currentProfile.userId || bookingDetail.isBookingManagement))) &&
                        <Button onClick={() => this.handleDupicateRequest(bookingDetail.id)} color="primary" autoFocus>
                            Duplicate booking
                        </Button>
                    }
                </DialogActions>
                {/*(currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Staff.ordinal) && currentProfile.id === bookingDetail.createdById ? 
                    (
                        <DialogActions>
                            <Button onClick={this.props.handleClose}>Close</Button>
                            {
                                currentProfile.id === bookingDetail.createdById ? (
                                    <Button component={Link} to={`/booking/${bookingDetail.id}/edit`} color="primary" autoFocus>
                                        {
                                            bookingDetail.status !== BookingStatusType.Completed.ordinal &&
                                            bookingDetail.status !== BookingStatusType.Cancelled.ordinal &&
                                            moment(bookingDetail.dateStart) >= moment()
                                            ?
                                                "Edit": "View"
                                        }
                                    </Button>
                                ) : ""
                            }
                        
                        </DialogActions>
                    ) :
                    currentProfile.type === ProfileType.Musician.ordinal && bookingDetail.isAdmin && 
                        (bookingDetail.status === BookingStatusType.New.ordinal || bookingDetail.status === BookingStatusType.Resent.ordinal) ?
                    (
                        <DialogActions>
                            <Button onClick={this.props.handleClose}>Close</Button>
                            <Button onClick={this.props.handleClose} color="primary">
                                Decline
                            </Button>
                            <Button component={Link} to={`/musician/booking/${bookingDetail.id}/negotiate`} color="primary">
                                Hold &amp; Negotiate
                            </Button>
                            <Button
                                component={Link}
                                to={`/musician/booking/${bookingDetail.id}/details`}
                                color="primary"
                                autoFocus
                            >
                                Accept
                            </Button>
                        </DialogActions>
                    ) :
                    currentProfile.type === ProfileType.Musician.ordinal && bookingDetail.isAdmin && 
                        (   bookingDetail.status !== BookingStatusType.New.ordinal && 
                            bookingDetail.status !== BookingStatusType.Resent.ordinal && 
                            bookingDetail.status !== BookingStatusType.Completed.ordinal &&
                            bookingDetail.status !== BookingStatusType.Cancelled.ordinal &&
                            bookingDetail.status !== BookingStatusType.Declined.ordinal
                        ) ? 
                    (
                        <DialogActions>
                            <Button onClick={this.props.handleClose}>Close</Button>
                            <Button component={Link} to={`/musician/booking/${bookingDetail.id}/details`} color="primary">
                                View
                            </Button>
                            <Button component={Link} to={`/musician/booking/${bookingDetail.id}/lineup`} color="primary">
                                Edit Lineup
                            </Button>
                            <Button component={Link} to={`/musician/booking/${bookingDetail.id}/setlist`} color="primary">
                                Edit Setlist
                            </Button>
                        </DialogActions>
                    ) : 
                    currentProfile.type === ProfileType.Musician.ordinal && !bookingDetail.isAdmin ? (
                        <DialogActions>
                            <Button onClick={this.props.handleClose}>Close</Button>
                            <Button component={Link} to={`/musician/booking/${bookingDetail.id}/details`} color="primary">
                                View
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <Button onClick={this.props.handleClose}>Close</Button>
                        </DialogActions>
                    )*/}
            </ModalContainer>
        );
    }
}
export default withMobileDialog()(BookingDialog);
