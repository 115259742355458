import React, { Component } from "react";

import { Card, Grid, Typography, Button } from "@material-ui/core";

import CardContent from "@material-ui/core/CardContent";

import "./SummaryCard.css";

class SummaryCard extends Component {
    render() {
        return (
            <Card
                classes={{
                    root: "dashboard-widget-summary-card-container widget"
                }}
            >
                <CardContent
                    classes={{
                        root: this.props.primaryColor ? "card-header-primary" : "card-header-secondary"
                    }}
                    color="secondary"
                >
                    <Typography align="center" variant="display1" noWrap={true}>
                        {this.props.headerContent}
                        <Typography align="center" variant="caption" className="caption">
                            {this.props.headerCaption}
                        </Typography>
                    </Typography>
                </CardContent>
                <CardContent
                    classes={{
                        root: "card-summary-content"
                    }}
                >
                    <Grid container spacing={8}>
                        <Grid item md={6} xs={6}>
                            <Button fullWidth>
                                <Typography align="center" variant="subheading" color="primary" noWrap={true}>
                                    {this.props.content1}
                                    <Typography align="center" variant="caption">
                                        {this.props.caption1}
                                    </Typography>
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Button fullWidth>
                                <Typography align="center" variant="subheading" color="primary" noWrap={true}>
                                    {this.props.content2}
                                    <Typography align="center" variant="caption">
                                        {this.props.caption2}
                                    </Typography>
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export default SummaryCard;
