import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import image from "../../helper/image";

import PersonIcon from "@material-ui/icons/Person";
import { ProfileType } from "../../types/enum";

@inject("templateStore", "authStore", "profileStore")
@observer
class SidebarUser extends Component {
    render() {
        const { profiles, currentProfileType } = this.props.profileStore;

        var currentProfile = this.props.profileStore.currentProfile

        let profile = undefined;
        if (profiles && profiles.length > 0) {
            for (let i = 0; i < profiles.length; i++) {
                if (profiles[i].type === currentProfileType) {
                    profile = profiles[i];
                    break;
                }
            }
        }

        let profileName = ""
        if(currentProfile) {
            if(currentProfile.isConsultant && currentProfile.type === ProfileType.Agent.ordinal) {
                profileName = `${currentProfile.firstName} ${[currentProfile.lastName]}`
            } else if(currentProfile.type === ProfileType.Agent.ordinal) {
                profileName = currentProfile.profileName
            } else if(currentProfile.type === ProfileType.PrivateHire.ordinal) {
                profileName = currentProfile.firstName + " " + currentProfile.lastName
            } else {
                profileName = currentProfile.fullName
            }
        }

        return (
            <ListItem button divider={true} dense={true} style={{ height: 65, paddingLeft: 16, paddingRight: 16 }}>
                {currentProfile ? (
                    <Avatar src={image.getProfileImage(currentProfile)} />
                ) : (
                    <Avatar>
                        <PersonIcon />
                    </Avatar>
                )}
                <ListItemText
                    className="sidebar-user-text"
                    primary={profileName}
                    secondary={currentProfile && currentProfile.labelType}
                />
            </ListItem>
        );
    }
}

export default SidebarUser;
