import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Grid, Button, TextField ,Typography} from "@material-ui/core";
const MYOB_KEY = process.env.REACT_APP_MYOB_KEY;
const MYOB_REDIRECT_URL = process.env.REACT_APP_MYOB_REDIRECT_URL;
import ConfirmationModal from "../../components/modal/ConfirmationModal.js";

import { withRouter } from "react-router-dom";

@inject("authStore","myobStore", "templateStore", "userStore","profileStore")
@withRouter
@observer
class ConnectedAccount extends Component {
    state = {
        myobActive: false,
        myobAccount: "",
        showMyobDisconnectModal: false
    };

    componentDidMount = () => {
        this.props.authStore.reset();

        this.props.userStore.getUserCognito()

        let currentProfile = this.props.profileStore.getCurrentProfile();

        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
            this.setState({
                myobActive: currentProfile?.activateMYOBAccess,
                myobAccount: currentProfile?.myobCompanyName
            });
        }else{
            this.setState({
                myobActive: currentProfile?.organisationOwner?.activateMYOBAccess,
                myobAccount: currentProfile?.organisationOwner?.myobCompanyName
            });
        }
        
    };
    handleCloseMYOBModal = () => this.setState({ ...this.state, showMyobDisconnectModal: false });
    handleOpenMYOBModal = () => this.setState({ ...this.state, showMyobDisconnectModal: true });

    handleDisconnectMYOB = e => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.templateStore.showLoadingScreen()
        if(!currentProfile.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
        }
        this.props.myobStore.disconnect(currentProfile.id).finally(() => {
            this.props.profileStore.loadProfiles().finally(() => {
                this.props.templateStore.hideLoadingScreen()
                this.props.history.push("/account/setting/myob")
            })
         
        });
    }

    render() {
        const {myobActive,myobAccount} = this.state
        const { currentUser } = this.props.userStore;
        var stringText = `By disconnecting your account, you will no longer be able to sync your customer invoices and supplier bills between Hot Giggity and MYOB. </br>
       The synchronisation data in Hot Giggity will also be deleted.</br>
       Do you want to continue?`
        return (
            <div>
                <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                                <Grid item md={12}>
                                    <Grid container spacing={8}>
                                        <Grid item md={3} xs={12}  spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                            
                                            <Typography gutterBottom>
                                                Connect your Hotgiggity Account to your MYOB account
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}  container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item md={6}>
                                                        <Typography gutterBottom>Step 1. Connect Hot Giggity to your MYOB account</Typography>
                                                        {
                                                                    myobActive ?  <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: Successfully connected to {myobAccount}</Typography> :
                                                                    <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: MYOB not connected</Typography>
                                                        }
                                                        
                                                    </Grid>
                                                    <Grid item md={6} style={{textAlign:"center"}}>
                                                        {
                                                            myobActive ? 
                                                            <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleOpenMYOBModal} >
                                                                disconnect
                                                            </Button> :
                                                            <Button
                                                                variant="outlined"
                                                                // component={Link}
                                                                target="_blank"
                                                                href={`https://secure.myob.com/oauth2/account/authorize?client_id=${MYOB_KEY}&redirect_uri=${MYOB_REDIRECT_URL}&response_type=code&scope=CompanyFile la.global&state=${currentUser.id
                                                                    }`}
                                                            >
                                                                Connect
                                                            </Button>
                                                        }
                                                    </Grid>    
                                                </Grid>     
                                            </Grid>
                                    </Grid>

                                               
                                </Grid>
                            </Grid>
                            
                        </Grid>
                <ConfirmationModal
                    open={this.state.showMyobDisconnectModal}
                    title="Are you sure you want to disconnect your MYOB account?"
                    message={stringText}
                    closeHandler={this.handleCloseMYOBModal}
                    confirmationHandler={this.handleDisconnectMYOB}
                    declineHandler={this.handleCloseMYOBModal}
                    confirmationLabel="Disconnect"
                    declineLabel="Cancel"
                    maxWidth="sm"
                />
            </div>
        );
    }
}

export default ConnectedAccount;
