import React from 'react';
import Img from 'react-cool-img';
import hgLogo from "../../img/hotgig-loading.png";

export const ImageLazy = (props) => {
    const { src, placeholder, error, alt} = props;

    return (
        <Img
            {...props}
            placeholder={placeholder ? placeholder : hgLogo }
            src={src}
            error={error ? error : hgLogo}
            alt={alt}
        />
    )
}
