import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { inject, observer } from "mobx-react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

@inject("songListStore")
@observer
class SongListEdit extends React.Component {
    state = {
        open: false,
        openSnackbar: false,
        mediaUrl: "",
        artist: "",
        title: "",
        songKey: "",
        mediaError: false,
        disabled: true
    };

    componentDidMount() {
        const { id, title, artist, mediaUrl, songKey } = this.props.song;
        this.setState({ id, mediaUrl, title, artist, songKey, disabled: true });
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false, disabled: true });
    };

    handleMediaChange = e => {
        const url = e.target.value;
        this.setState({ mediaUrl: url, mediaError: false, disabled: false });
    };

    handleTitleChange = e => {
        this.setState({ title: e.target.value, disabled: false });
    };
    handleArtistChange = e => {
        this.setState({ artist: e.target.value, disabled: false });
    };
    handleKeyChange = e => {
        this.setState({ songKey: e.target.value, disabled: false });
    };

    handleSubmit = () => {
        if (this.state.mediaError) return false;
        const { song } = this.props;
        const { mediaUrl, title, artist, songKey } = this.state;
        let songToUpdate = {
            id: song.id,
            mediaUrl,
            title,
            artist,
            songKey
        };
        this.props.songListStore.update(songToUpdate).then(response => {
            this.props.songListStore.replaceSelectedSong(response);
            this.handleClose();
            this.setState({ openSnackbar: true });
        });
    };

    handleSongRemove = () => {
        this.props.onListRemove([this.state.id]);
        this.props.onListReset();
    };

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({ openSnackbar: false });
    };

    render() {
        return (
            <Fragment>
                <Tooltip title="Edit">
                    <Button color="primary" size="small" onClick={this.handleClickOpen}>
                        Edit
                    </Button>
                </Tooltip>
                <Tooltip title="Delete">
                    <Button color="primary" size="small" onClick={this.handleSongRemove.bind(this)}>
                        Delete
                    </Button>
                </Tooltip>

                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" className="dialog-div">
                    <DialogTitle id="form-dialog-title">Edit Song</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Edit media url for your song.</DialogContentText>
                        <TextField
                            autoFocus
                            value={this.state.title}
                            onChange={this.handleTitleChange}
                            margin="dense"
                            id="title"
                            label="Song Title"
                            fullWidth
                        />
                        <TextField value={this.state.artist} onChange={this.handleArtistChange} margin="dense" id="artist" label="Artist" fullWidth />
                        <TextField
                            value={this.state.mediaUrl}
                            onChange={this.handleMediaChange}
                            margin="dense"
                            error={this.state.mediaError}
                            id="mediaUrl"
                            label="URL"
                            type="url"
                            fullWidth
                        />
                        <TextField
                            value={this.state.songKey}
                            onChange={this.handleKeyChange}
                            margin="dense"
                            id="songKey"
                            label="Song Key"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                        <Button onClick={this.handleSubmit} disabled={this.state.mediaError || this.state.disabled} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Song List Updated</span>}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </Fragment>
        );
    }
}

export default withMobileDialog()(SongListEdit);
