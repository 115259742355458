import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import moment from "moment";
import { Promise } from "bluebird";
import { Storage } from "aws-amplify";
import CacheHelper from '../helper/cache';

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class PromotionStore {
    @observable
    promotion = {
        id: undefined,
        title: "",
        dateStart: moment(),
        dateEnd: moment(),
        venues: "",
        redeemLimit: 0,
        description: "",
        isDraft: true,
        published: false,
        imageUrl: undefined,
        canRemove: false,
        rerunId: undefined,
        isFollowAndRedeem: false,
    };

    @observable promotionImage = undefined;

    @observable pendingPromotions = [];
    @observable publishedPromotions = [];
    @observable completedPromotions = [];
    @observable error = undefined;

    @action
    getPromotionDetail(promotionId) {
        return agent.Promotion.getById(promotionId).then(
            action(async response => {
                let newImageUrl = await CacheHelper.getImgLink(response.imageUrl)
                runInAction(() => {
                    this.promotionImage = undefined;
                    this.promotion = {
                        id: response.id,
                        imageUrl: response.imageUrl ? newImageUrl : null,
                        title: response.title,
                        dateStart: moment(response.startDate),
                        dateEnd: moment(response.endDate),
                        venues: response.promotionVenues.map(x => x.venueId).join(),
                        redeemLimit: response.redeemLimit,
                        description: response.description,
                        isDraft: response.isDraft,
                        published: response.published,
                        canRemove: response.canRemove,
                        isFollowAndRedeem: response.isFollowAndRedeem
                    };
                })
            })
        );
    }

    @action
    loadPendingPromotions() {
        return agent.Promotion.getPending().then(
            action(async response => {
                for (const promotion of response) {
                    promotion.imageUrl = await CacheHelper.getImgLink(promotion.imageUrl)
                }
                runInAction(() => {
                    this.pendingPromotions = response;
                })
                return response;
            })
        );
    }

    @action
    loadPublishedPromotions() {
        return agent.Promotion.getPublished().then(
            action(async response => {
                for (const promotion of response) {
                    promotion.imageUrl = await CacheHelper.getImgLink(promotion.imageUrl)
                }
                runInAction(() => {
                    this.publishedPromotions = response;
                })
                return response;
            })
        );
    }

    @action
    loadCompletedPromotions() {
        return agent.Promotion.getCompleted().then(
            action(async response => {
                for (const promotion of response) {
                    promotion.imageUrl = await CacheHelper.getImgLink(promotion.imageUrl)
                }
                runInAction(() => {
                    this.completedPromotions = response;
                })
                return response;
            })
        );
    }

    @action
    rerunPromotion(promotion) {
        console.log(promotion);
        this.promotion = {
            ...promotion,
            id: undefined,
            dateStart: moment(),
            dateEnd: moment(),
            imageUrl: `${API_ROOT}/promotion/${promotion.id}/image`,
            venues: promotion.promotionVenues ? promotion.promotionVenues.map(x => x.venueId).join() : "",
            rerunId: promotion.id,
            published: false,
            isDraft: true,
            canRemove: false
        };
        return Promise.resolve();
    }

    // save promotion section

    @action
    resetPromotionData() {
        this.promotion = {
            id: undefined,
            image: undefined,
            title: "",
            dateStart: moment(),
            dateEnd: moment(),
            venues: "",
            redeemLimit: 0,
            description: "",
            isDraft: true,
            published: false,
            imageUrl: undefined,
            canRemove: false,
            rerunId: undefined,
            isFollowAndRedeem: false,
        };

        this.error = undefined;
        this.promotionImage = undefined;
    }

    @observable errors = [];

    @action
    setPromotionValue(key, value) {
        if (key === "image") {
            this.promotionImage = value;
        } else {
            this.promotion[key] = value;
        }
    }

    @action
    savePromotion() {
        // validation

        if (this.promotion.venues.length === 0) {
            this.addError("venue", "Venue is required.");
            return Promise.resolve({ error: true });
        }
        var data = {
            id: this.promotion.id ? this.promotion.id : 0,
            title: this.promotion.title,
            dateStart: moment(this.promotion.dateStart).endOf("day"),
            dateEnd: moment(this.promotion.dateEnd).endOf("day"),
            venuesId: this.promotion.venues.split(",").map(venueId => parseInt(venueId, 10)),
            redeemLimit: this.promotion.redeemLimit,
            description: this.promotion.description,
            published: this.promotion.published,
            isDraft: this.promotion.isDraft,
            rerunId: this.promotion.rerunId,
            isFollowAndRedeem: this.promotion.isFollowAndRedeem

        };

        return agent.Promotion.savePromotion(data).then(async response => {
            if (this.promotionImage) {
                let promoImg = await this.setPromotionImage(response.id, this.promotionImage);
                return agent.Promotion.uploadPromotionImage(response.id, promoImg[0]).then(() => {
                    return response;
                });
            } else {
                return response;
            }
        });
    }

    @action
    async setPromotionImage(promotionId) {
        var promotionImages = [];

        var image = this.promotionImage[0];
        const fileExtension = image.name.split('.').pop();
        var ticks = (((new Date()).getTime() * 10000) + 621355968000000000);
        var name = `${ticks}.${fileExtension}`;
        var result = await this.uploadPromotionImage(promotionId, image, name);
        var promotionImage = {
            url: result.key,
            filename: name,
            mime: image.type
        }
        promotionImages.push(promotionImage)
     
        return promotionImages
    }

    async uploadPromotionImage(id, profileImage, name) {
        try {
            const result = await Storage.put(
                `promotion/image/${id}/${name}`,
                profileImage
            );
            return result;
        } catch (exception) {
            // console.log(exception);
        }
    }

    @action
    removePromotion(promotionId) {
        return agent.Promotion.removePromotion(promotionId).then(
            action(response => {
                this.pendingPromotions = this.pendingPromotions.filter(x => x.id !== response.id);
                this.publishedPromotions = this.publishedPromotions.filter(x => x.id !== response.id);
                this.completedPromotions = this.completedPromotions.filter(x => x.id !== response.id);
                return response;
            })
        );
    }

    @action
    addError(fieldKey, errorMessage) {
        if (!this.error) {
            this.error = [];
        }
        this.error[fieldKey] = errorMessage;
    }

    @action
    addErrors(fieldKeys, errorMessages) {
        fieldKeys.forEach((fieldKey, index) => {
            this.addError(fieldKey, errorMessages);
        });
    }
}

export default new PromotionStore();
