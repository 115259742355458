import React, { Component, Fragment } from "react";

import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { ProfileType, BookingStatusType } from "../../../types/enum";

import SelectField from "../../fields/SelectField";

import { Grid, TextField } from "@material-ui/core";
import Countries from "../../../lookup/Country";
import moment from "moment";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { Storage } from "aws-amplify";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;
@inject("bookingRequestStore", "profileStore", "templateStore", "actTypeStore")
@withRouter
@observer
class PrivateBookingActDetails extends Component {
    getLineupsType = () => {
        let actTypes = [
            { name: "Solo", instrument: 1 },
            { name: "Duo", instrument: 2 },
            { name: "Trio", instrument: 3 },
            { name: "Quartet / 4 Piece", instrument: 4 },
            { name: "Quintet / 5 Piece", instrument: 5 },
            { name: "Sextet / 6 Piece", instrument: 6 }
        ];

        for (var i = 0; i < 93; i++) {
            actTypes.push({ name: i + 7 + " Piece", instrument: i + 7 });
        }
        return actTypes.map(actType => {
            return {
                value: actType.instrument,
                label: actType.name
            };
        });
    };

    componentDidMount() {
        // this.props.templateStore.showLoadingScreen();

        this.props.actTypeStore.loadActTypes().then(() => {
            // this.props.templateStore.hideLoadingScreen();
        });
    }

    handleActChange = (value, firstLoad) => {
        const { bookableActs } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        const { booking } = this.props.bookingRequestStore;
        if (!this.props.readOnly) {
            this.props.actChange(true)
        }
        this.props.bookingRequestStore.setBooking("actId", value);
        this.setTempTitle(firstLoad);

        var act = bookableActs.filter(x => x.id === value)[0];
        const isMusician = currentProfile && currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.type === ProfileType.Agent.ordinal;
        const useActsClientContract = isMusician;
        if (act) {
            this.props.bookingRequestStore.setBooking("act", act);
            this.props.bookingRequestStore.setBooking("actType", act.actTypeId);
            this.props.bookingRequestStore.setBooking("actLineup", act.lineUpCount ? act.lineUpCount : act.lineupCount ? act.lineupCount : "");
        }else if(!value){
            this.props.bookingRequestStore.setBooking("actType", undefined);
            this.props.bookingRequestStore.setBooking("actLineup", undefined);
            this.props.bookingRequestStore.setBooking("act", null);
            this.props.bookingRequestStore.setBooking("actId", 0);
        }

        if (useActsClientContract) {
            if (act) {
                this.props.bookingRequestStore.setBooking("actType", act.actTypeId);
                this.props.bookingRequestStore.setBooking("actLineup",  act.lineUpCount ? act.lineUpCount : act.lineupCount ? act.lineupCount : "");
                if (currentProfile?.type !== ProfileType.Agent.ordinal) {
                    this.props.bookingRequestStore.setBooking("clientContract", act.clientContract);
                }
            } else {
                if (!booking.actType) this.props.bookingRequestStore.setBooking("actType", "");
                if (!booking.actLineup) this.props.bookingRequestStore.setBooking("actLineup", "");
                if (!booking.clientContract) this.props.bookingRequestStore.setBooking("clientContract", "");
            }
        }
    };

    handleActTypeChange = value => {
        this.props.bookingRequestStore.setBooking("actType", value);
        this.props.bookingRequestStore.getBookableActs();
    };

    handleActLineupChange = value => {
        this.props.bookingRequestStore.setBooking("actLineup", value);
        this.props.bookingRequestStore.getBookableActs();
    };

    handleDownloadInsuranceDocument = () => {
        const act = this.props.bookingRequestStore.booking.act
        Storage.get(act.insuranceFileContentUrl)
            .then(result => window.open(result))
            .catch(err => console.log(err));
    }

    mapActSuggestions = () => {
        const { bookableActs } = this.props.bookingRequestStore;

        return bookableActs.map(act => {
            return {
                value: act.id,
                label: act.actName
            };
        });
    };

    setTempTitle = (firstLoad) => {
        const { bookableActs, booking } = this.props.bookingRequestStore;
        const { actId, venue, venueName } = booking;
        var title = "";

        var act = bookableActs.filter(x => x.id === actId)[0];
        title = `${act ? act.actName : ""} @ ${venue ? venue.profileName : venueName}`;

        this.props.bookingRequestStore.setBooking("tempTitle", title);
        if(!firstLoad) this.props.bookingRequestStore.setBooking("title", title);
        this.props.bookingRequestStore.setBooking("actName", act ? act.actName : "");
        this.props.bookingRequestStore.setTempBookingTitle();
        this.props.bookingRequestStore.setPrivateBookingHeading();
    };

    mapActTypeSuggestions = () => {
        const { actTypes } = this.props.actTypeStore;
        return actTypes.map(actType => {
            return {
                value: actType.id,
                label: actType.name
            };
        });
    };
    anyError(field) {
        return false;
    }

    errorText(field) {
        // if (!this.props.generalProfileStore.errors) return null;
        // if (!this.props.generalProfileStore.errors[field]) return null;
        // return this.props.generalProfileStore.errors[field];
    }
    render() {
        const { error, booking, bookableActs, isActLoaded } = this.props.bookingRequestStore;
        const { actId, act, status, bookingRequests, createdBy, bookingRequest, actType, actLineup, forceAct } = booking;

        const { readOnly } = this.props;
        const { currentProfile } = this.props.profileStore;
        const isMusician = currentProfile && currentProfile?.type === ProfileType.Musician.ordinal;
        let countryName = "";
        let resentAct = booking.bookingRequests && booking.bookingRequests.length > 0 && booking.bookingRequests[0].act;

        if (actId) {
            this.handleActChange(parseInt(actId), true)
        }
        var selectedAct = act;
        if (resentAct) {
            selectedAct = resentAct;
        }
        if (selectedAct) {
            let country = Countries.filter(x => x.code === selectedAct.countryCode);
            if (country.length > 0) {
                countryName = country[0].name;
            }
        }
        let insurancePdfLink = "";
        if (selectedAct) {
            insurancePdfLink = `${API_ROOT}/document/${selectedAct.insuranceFileGuid}/${selectedAct.insuranceFileName}`;
        }

        return (
            <Grid container spacing={8}>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <SelectField
                        id="bookingActType"
                        label="Act Type"
                        value={actType}
                        suggestions={this.mapActTypeSuggestions()}
                        placeholder="Select Act Type"
                        onChange={this.handleActTypeChange}
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="gridPrivateBookingForm">
                    <SelectField
                        id="bookingActLineupType"
                        label="Lineup"
                        value={actLineup}
                        suggestions={this.getLineupsType()}
                        placeholder="Select Lineup Numbers"
                        onChange={this.handleActLineupChange}
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item xs={12} lg={12} className="gridPrivateBookingForm">
                    {(currentProfile?.type === ProfileType.Agent.ordinal ||
                        currentProfile?.type === ProfileType.Staff.ordinal ||
                        currentProfile?.type === ProfileType.Musician.ordinal) &&
                        (status === BookingStatusType.Resent.ordinal || !readOnly) &&
                        !bookingRequest && !forceAct &&
                        (actId && bookableActs.length > 0 ? this.mapActSuggestions().filter(x => x.value === actId).length > 0 : true) ? (
                        status === BookingStatusType.Resent.ordinal && currentProfile?.id === createdBy?.id ? (
                            <SelectField
                                id="bookingAct"
                                label="Acts"
                                multi={true}
                                suggestions={this.mapActSuggestions()}
                                value={bookingRequests ? bookingRequests.filter(x => !x.deleted).map(y => y.actId) : 0}
                                placeholder={isActLoaded ? "Select Act" : "Hang tight... we're checking for available acts for this date and time"}
                                disabled={true}
                                emptyText="No act available on selected times"
                            />
                        ) : (
                            <SelectField
                                id="bookingAct"
                                label="Act"
                                suggestions={this.mapActSuggestions()}
                                onChange={this.handleActChange}
                                value={actId}
                                placeholder={isActLoaded ? "Select Act" : "Hang tight... we're checking for available acts for this date and time"}
                                error={error && error["act"]}
                                helperText={error && error["act"] ? error["act"] : ""}
                                emptyText="No act available on selected times"
                            />
                        )
                    ) : (currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.type === ProfileType.Agent.ordinal) && (bookingRequest && bookingRequest.length > 0) ? (
                        <TextField
                            label="Act Name"
                            inputProps={{
                                readOnly: true
                            }}
                            value={bookingRequest && bookingRequest.act.actName}
                            fullWidth
                        />
                    ) : (currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.type === ProfileType.Agent.ordinal) && resentAct ? (
                        <TextField
                            label="Act Name"
                            inputProps={{
                                readOnly: true
                            }}
                            value={resentAct.actName}
                            fullWidth
                        />
                    ) : (
                        <TextField
                            label="Act Name"
                            inputProps={{
                                readOnly: true
                            }}
                            value={act ? act.actName : ""}
                            onChange={this.handleActNameChange}
                            fullWidth
                        />
                    )}
                </Grid>
                {act &&
                    !isMusician && (
                        <Fragment>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.email}
                                    readOnly
                                    error={this.anyError("email")}
                                    helperText={this.errorText("email")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="contactPhone"
                                    label="Phone"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.contactPhone}
                                    readOnly
                                    error={this.anyError("contactPhone")}
                                    helperText={this.errorText("contactPhone")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="street"
                                    label="Street Address"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.street}
                                    readOnly
                                    error={this.anyError("street")}
                                    helperText={this.errorText("street")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="city"
                                    label="Suburb"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.city}
                                    onChange={this.handleCityChange}
                                    error={this.anyError("city")}
                                    helperText={this.errorText("city")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="postal"
                                    label="Postal/Zip Code"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.postal}
                                    readOnly
                                    error={this.anyError("postal")}
                                    helperText={this.errorText("postal")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="state"
                                    label="State"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.state}
                                    readOnly
                                    error={this.anyError("state")}
                                    helperText={this.errorText("state")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="countries"
                                    label="Country"
                                    margin="dense"
                                    fullWidth
                                    value={countryName}
                                    readOnly
                                    error={this.anyError("country")}
                                    helperText={this.errorText("country")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id="abn"
                                    label="ABN"
                                    margin="dense"
                                    fullWidth
                                    value={selectedAct.abn}
                                    readOnly
                                    error={this.anyError("abn")}
                                    helperText={this.errorText("abn")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="insurancePolicyHolder"
                                    label="Policy Holder Name"
                                    margin="dense"
                                    fullWidth
                                    readOnly
                                    value={selectedAct.insurancePolicyHolder}
                                    error={this.anyError("insurancePolicyHolder")}
                                    helperText={this.errorText("insurancePolicyHolder")}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="insuranceCompany"
                                    label="Insurance Company"
                                    margin="dense"
                                    fullWidth
                                    readOnly
                                    value={selectedAct.insuranceCompany}
                                    error={this.anyError("insuranceCompany")}
                                    helperText={this.errorText("insuranceCompany")}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="insurancePolicyNumber"
                                    label="Insurance Policy Number"
                                    margin="dense"
                                    fullWidth
                                    readOnly
                                    value={selectedAct.insurancePolicyNumber}
                                    error={this.anyError("insurancePolicyNumber")}
                                    helperText={this.errorText("insurancePolicyNumber")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="insuranceExpiryDate"
                                    label="Insurance Expiry Date"
                                    margin="dense"
                                    fullWidth
                                    readOnly
                                    value={selectedAct.insuranceExpiryDate ? moment(selectedAct.insuranceExpiryDate).format("DD-MM-YYYY") : ""}
                                    error={this.anyError("insuranceExpiryDate")}
                                    helperText={this.errorText("insuranceExpiryDate")}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                {act.insuranceFileGuid && (
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="insuranceFileName">Insurance File</InputLabel>
                                        <Input
                                            id="insuranceFileName"
                                            value={selectedAct.insuranceFileName}
                                            startAdornment={
                                                <InputAdornment
                                                    onClick={this.handleDownloadInsuranceDocument}
                                                    position="start"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <a target="_blank">
                                                        <CloudDownload />
                                                    </a>
                                                </InputAdornment>
                                            }
                                            readOnly={true}
                                        />
                                    </FormControl>
                                )}
                            </Grid>
                        </Fragment>
                    )}
            </Grid>
        );
    }
}

export default PrivateBookingActDetails;
