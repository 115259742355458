import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Button, TextField, Typography } from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";

import "./Login.css";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";

import logo from "../img/hotgig-logo.png";

@inject("userStore", "authStore")
@observer
class ForgotPassword extends Component {
    state = {
        requestSuccess: false
    };

    componentDidMount = () => {
        this.props.authStore.reset();
    };
    handleValidateEmail = e => this.props.authStore.validateEmail(e.target.value);
    handleEmailChange = e => this.props.authStore.setEmail(e.target.value);
    handlePasswordChange = e => this.props.authStore.setPassword(e.target.value);

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    handleSubmitForm = e => {
        e.preventDefault();
        if(this.props.authStore.values.email.length > 0) {
            if(this.props.authStore.errors.email) {
                return
            } else {
                this.props.authStore.forgotPassword().then(() => {
                    this.setState({ requestSuccess: true });
                });
            }
        } else {
            return
        }
    };

    render() {
        const { values } = this.props.authStore;

        return (
            <div style={{display: "flex", flex: 1, flexDirection: 'column', zIndex: 40}}>
                <div className="Login">
                    <div className='image-background' />
                    <img src={logo} alt="HotGiggity" className='header-logo'/>
                    <Grid container spacing={24} className="Login-Grid">
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Grid item xs={12} sm={6} md={4} lg={3} space={0}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <div className="logo-login">
                                        <img src={logo} alt="Hot Giggity" />
                                    </div>
                                </Grid>
                                {/* 
                                // adjust to new design
                                // commented for future used
                                <Grid container direction="row" className="page-indicator">
                                        <a>CAN'T SIGN IN?</a>
                                </Grid>
                                 */}
                            </Grid>
                            <Paper className="Form">
                                <form onSubmit={this.handleSubmitForm} className="Submit-form">
                                    <Typography style={{color:"white"}} component="p">We'll send a recovery link to you</Typography>
                                    {!this.state.requestSuccess ? (
                                        <Fragment>
                                            <TextField
                                                id="email"
                                                margin="normal"
                                                placeholder="Email"
                                                className="inputTextContainer"
                                                InputProps={{className: "inputTextField"}}
                                                fullWidth
                                                value={values.email}
                                                onChange={this.handleEmailChange}
                                                onBlur={this.handleValidateEmail}
                                                error={this.anyError("email")}
                                                helperText={this.errorText("email")}
                                                style={{ marginBottom: 40 }}
                                            />
                                            <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: "flex", marginTop: 30}}>
                                                <Button className="" size="large" variant="contained" color="primary" id="signup-btn" type="submit">
                                                    Send Reset Email
                                                </Button>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <span>
                                            <br />
                                            <Typography component="p" style={{color:"white"}}>
                                                Don't worry, we all forget sometimes. Please check your Inbox for further action to reset your password.
                                            </Typography>
                                        </span>
                                    )}
                                </form>
                            </Paper>
                            {/* <LinkContainer to="/sign-in">
                                <a className="btnForget">Back</a>
                            </LinkContainer> */}
                            <Button className="btnResetPass" size="large" variant="contained" color="primary" id="signup-btn" onClick={() => this.props.history.push("/sign-in")}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
