import React, { Component, Fragment } from "react";
import { observer, inject } from 'mobx-react'
import { withRouter } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";

import { Button, Tabs, Tab, Paper } from "@material-ui/core";

import PendingGigPromotions from "./Tabs/PendingGigPromotions";
import PublishedGigPromotions from "./Tabs/PublishedGigPromotions";
import CompletedGigPromotions from "./Tabs/CompletedGigPromotions";

@withRouter
@inject("venueStore")
@observer
class GigPromotionList extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/staff" }, { name: "Gig Promotion" }];

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            canCreateGigPromotion: false
        };
    }

    componentDidMount() {
        const { match } = this.props;

        this.props.venueStore.getUserVenuesBasedOnPermission(null, null, null, null, true).then(response => {
            this.setState({
                ...this.state,
                canCreateGigPromotion: response.length > 0
            });
        });

        switch (match.params.activeTab) {
            case "draft":
                this.setState({
                    ...this.state,
                    tabValue: 0
                });
                break;
            case "published":
                this.setState({
                    ...this.state,
                    tabValue: 1
                });
                break;
            case "past":
                this.setState({
                    ...this.state,
                    tabValue: 2
                });
                break;
            default:
                this.setState({
                    ...this.state,
                    tabValue: 0
                });
                break;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.activeTab !== this.props.match.params.activeTab) {
            switch (this.props.match.params.activeTab) {
                case "draft":
                    this.setState({
                        ...this.state,
                        tabValue: 0
                    });
                    break;
                case "published":
                    this.setState({
                        ...this.state,
                        tabValue: 1
                    });
                    break;
                case "past":
                    this.setState({
                        ...this.state,
                        tabValue: 2
                    });
                    break;
                default:
                    this.setState({
                        ...this.state,
                        tabValue: 0
                    });
                    break;
            }
        }
    }

    renderCurrentTab = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0:
                return <PendingGigPromotions />;
            case 1:
                return <PublishedGigPromotions />;
            case 2:
                return <CompletedGigPromotions />;
            default:
                return <PendingGigPromotions />;
        }
    };

    handleTabChange = (event, value) => {
        switch (value) {
            case 0:
                this.props.history.replace("/gig/promotion/draft");
                break;
            case 1:
                this.props.history.replace("/gig/promotion/published");
                break;
            case 2:
                this.props.history.replace("/gig/promotion/past");
                break;
            default:
                this.props.history.replace("/gig/promotion/draft");
                break;
        }
        this.setState({
            tabValue: value
        });
    };

    handleOpenNewGigPromotionPage = () => {
        this.props.history.push("/gig/promotion/new");
    };

    render() {
        const { tabValue, canCreateGigPromotion } = this.state;
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title="Gig Promotion">
                        {canCreateGigPromotion ? (
                            <Button classes={{ root: "pull-right" }} variant="text" color="primary" onClick = { this.handleOpenNewGigPromotionPage }>
                                Add Gig Promotion
                            </Button>
                        ) : null}
                    </Heading>
                    <Paper>
                        <Tabs value={tabValue} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab label="Draft" />
                            <Tab label="Published" />
                            <Tab label="Past" />
                        </Tabs>
                    </Paper>
                    <br />
                    {this.renderCurrentTab()}
                </MainContainer>
            </Fragment>
        );
    }
}

export default GigPromotionList;
