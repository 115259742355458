import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { findDOMNode } from "react-dom";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button, Hidden, InputLabel } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import queryString from "query-string";
import { ProfileType, BookingStatusType, LineupStatusType, BookingType } from "../../types/enum";

import Loading from "../../components/loadingSpinner/Loading";
import BookingSocialDetail from "../booking/BookingForm/BookingSocialDetail";
import BookingNotes from "../booking/BookingForm/BookingNotes";
import BookingContract from "../booking/BookingForm/BookingContract";
import BookingClientContract from "../booking/BookingForm/BookingClientContract";
import BookingRequirementDetails from "../booking/BookingForm/BookingRequirementDetails";
import PrivateBookingDetails from "./formComponent/PrivateBookingDetails";
import PrivateBookingFeeDetails from "./formComponent/PrivateBookingFeeDetails";
import PrivateBookingDateDetails from "./formComponent/PrivateBookingDateDetails";
import PrivateBookingClientDetails from "./formComponent/PrivateBookingClientDetails";
import PrivateBookingGigRequirement from "./formComponent/PrivateBookingGigRequirement";

import "../../pages/booking/Booking.css";
import PrivateBookingSummaryModal from "./formComponent/PrivateBookingSummaryModal";
import CancelBookingModal from "../booking/CancelBookingModal";
import ConfirmationModal from "../modal/ConfirmationModal";
import BookingChangeActModal from "../booking/BookingModal/BookingChangeActModal";
import PrivateBookingActDetails from "./formComponent/PrivateBookingActDetails";
import BookingAgentContract from "../booking/BookingForm/BookingAgentContract";

@inject("profileStore", "bookingRequestStore", "templateStore", "userStore", "agentVenueStore", "invoiceStore", "conversationStore")
@withRouter
@observer
class PrivateBookingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summaryModalState: false,
            cancelBookingModalState: false,
            declineBookingModalState: false,
            declineLineupModalState: false,
            cancelLineupModalState: false,
            changeActModalState: false,
            confirmDoubleBookingModalState: false,
            acceptTermsCondition: false,
            errorMessage: "",
            notifyStartEndTime: false,
            isVenueChange: false,
            isActChange: false,
            isLoadingLocal: false,
            unsyncInvoiceModal : false,
            unsyncInvoiceXeroModal : false,
            invoiceid : null,
            unsyncInvoiceModalPayee: false,
            invoice: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { match, bookingRequestStore, userStore } = this.props;
        const { currentUser } = userStore;
        this.setState({
            ...this.state,
            isLoadingLocal: true
        });
        if (match.params.id) {
            // bookingRequestStore.getBookableActs();
            bookingRequestStore.initializeEditBooking(match.params.id, match.params.bookingRequestId).then(() => {
                const { booking } = bookingRequestStore;

                const IsBookingRequest = match.params && match.params.bookingRequestId && booking.bookingRequests && booking.bookingRequests.length > 0;

                if (match.params && match.params.bookingRequestId && !IsBookingRequest) {
                    if (
                        (booking.act.userId === currentUser.sub || booking.status === BookingStatusType.New.ordinal) &&
                        (booking.status === BookingStatusType.Accepted.ordinal || booking.status === BookingStatusType.Ready.ordinal || booking.status === BookingStatusType.New.ordinal)
                    ) {
                        this.props.history.replace(`/privateBooking/${booking.id}/edit`);
                    } else {
                        this.props.history.replace("/musician/booking/claimed");
                    }
                } else {
                    this.setState({
                        ...this.state,
                        IsBookingRequest
                    });
                }
            }).finally(() => {
                this.setState({
                    ...this.state,
                    isLoadingLocal: false
                });
            });
        } else {
            var currentProfile = this.props.profileStore.getCurrentProfile();
            const params = queryString.parse(window.location.search);
            let currentProfileId = currentProfile.type === ProfileType.Agent.ordinal ? currentProfile.id : params.act;
            if (currentProfile.isConsultant) {
                if (currentProfile.myAgencies && currentProfile.myAgencies[0]) {
                    currentProfileId = currentProfile.myAgencies[0]?.agentId;
                }
            }
            if (currentProfileId) {
                this.props.profileStore.loadTermCondition(currentProfileId).then(term => {
                    var agentContractResult = term.agentContract || term.termCondition;
                    this.props.bookingRequestStore.setBooking("agentContract", agentContractResult);
                    this.props.bookingRequestStore.setBooking("clientContract", term.clientContract);
                    this.props.bookingRequestStore.setBooking("venueContract", term.termCondition);
                }).finally(() => {
                    this.setState({
                        ...this.state,
                        isLoadingLocal: false
                    });
                });;
            } else {
                this.setState({
                    ...this.state,
                    isLoadingLocal: false
                });
            }
        }
    }


    handleRedirectToInvoice = (invoiceId) => {
        const { booking } = this.props.bookingRequestStore;
        this.props.history.replace(`/invoice/${invoiceId}/detail`);
    }

    handleOpenUnsyncInvoicePayeeModal = invoiceId => {
        this.props.invoiceStore.getByIdSimple(invoiceId).then(result => {
            this.setState({ unsyncInvoiceModalPayee: true, invoice: result, actionAnchor: [] });
        })
    }
    handleCloseUnsyncInvoicePayeeModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceModalPayee: false,
            invoiceid: null
        });
        
    };
    handleSendMessageNotification = (invoiceId) => {
        var currentProfile = this.props.profileStore.getCurrentProfile()
        var result = this.state.invoice
        if(result){
            var payeeName = result.payeeName
            var invoiceNumber = "HG-"+result.no
            var title = `Please unsync ${invoiceNumber} to enable changes`;
            var body = `<p>Hi ${payeeName},</br></p><p>Please unsync invoice ${invoiceNumber} from your accounting system so that the booking can be updated.</p><p>Thanks,</p></br><p>${currentProfile.firstName} ${currentProfile.lastName}</p>`
            this.props.conversationStore.setTitle(title);
            this.props.conversationStore.setMessage(body);
            this.props.conversationStore.setParticipant(result.payee);
            this.props.history.push("/messages/invoice");
        }

    
        // this.props.conversationStore.handleSendMessageNotification(invoice)
       
    }

    handleRedirectUnsyncInvoice = () => {
        const { booking } = this.props.bookingRequestStore;
            if(booking.profilePayer.synchedStatus === 1){
                this.handleOpenUnsyncInvoiceModal(booking.profilePayer.invoiceId)
            }else if(booking.profilePayer.synchedPayer){
                this.handleOpenUnsyncInvoiceXeroModal(booking.profilePayer.invoiceId)
            }else if(booking.profilePayer.synchedPayee){
                this.handleOpenUnsyncInvoicePayeeModal(booking.profilePayer.invoiceId)
            }
       
        
    }

    handleOpenUnsyncInvoiceXeroModal = invoice => this.setState({ unsyncInvoiceXeroModal: true, invoiceid: invoice, actionAnchor: [] });
    handleCloseUnsyncInvoiceXeroModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceXeroModal: false,
            invoiceid: null
        });
    };

    handleOpenUnsyncInvoiceModal = invoice => this.setState({ unsyncInvoiceModal: true, invoiceid: invoice, actionAnchor: [] });
    handleCloseUnsyncInvoiceModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceModal: false,
            invoiceid: null
        });
    };

    handleConfirmMarkAsUnsync = () => {
        this.props.invoiceStore.unsyncSendToAccount(this.state.invoiceid).then(responseFile => {
            this.handleCloseUnsyncInvoiceModal()
            this.componentDidMount();
        })
    }
    handleConfirmMarkAsUnsyncXero = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.invoiceStore.unsyncXeroInvoice(currentProfile.id, this.state.invoiceid).then(response => {
            this.handleCloseUnsyncInvoiceXeroModal()
            this.componentDidMount();
        }).catch((err) => {
            this.handleCloseUnsyncInvoiceXeroModal()
            this.componentDidMount();
            this.props.templateStore.openSnackbar("This invoice has already been paid and cannot be unsynced. You will need to manually edit the invoice in XERO.");
        });
    }

    handleSavePrivateBooking = finalize => {
        const { match } = this.props;
        const { currentProfile } = this.props.profileStore;
        const { booking } = this.props.bookingRequestStore;
        this.props.templateStore.showLoadingScreen();
        const isOwner = booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile.id
        this.props.bookingRequestStore.savePrivateBooking(finalize, isOwner).then(
            response => {
                this.props.templateStore.hideLoadingScreen();
                if (!match.params.id) {
                    this.props.templateStore.openSnackbar("Booking has been created.");
                    if (currentProfile.type === ProfileType.Musician.ordinal) {
                        if(booking.act?.lineUpCount > 1){
                            this.props.history.replace(`/privateBooking/${response.id}/lineup`);
                        }else{
                            this.props.history.replace(`/privateBooking/${response.id}/notifyclient`);
                        }
                      

                    } else if (currentProfile.type === ProfileType.Agent.ordinal) {
                        if (isOwner) {
                            if(response.saveAsDraft){
                                this.props.history.push(`/agent/calendar`);
                            }else{
                                if (response.type == BookingType.Adhoc.ordinal && response.createdById == currentProfile.id) {
                                    this.props.history.replace(`/privateBooking/${response.id}/lineup`);
                                } else {
                                    this.props.history.replace(`/privateBooking/${response.id}/notifyclient`);
                                }
                            }
                           
                        } else {
                            if (response.saveAsDraft && booking.type !== BookingType.Adhoc.ordinal) {
                                return this.closeSummaryModal();
                            } else if (response.notifyAct) {
                                this.props.history.replace(`/privateBooking/${response.id}/notifyclient`);
                            } else {
                                this.props.history.push(`/agent/calendar`);
                            }
                        }

                    } else {
                        this.props.history.push("/booking");
                    }
                } else {
                    this.props.templateStore.openSnackbar("Booking details has been updated.");
                    if (currentProfile.type === ProfileType.Musician.ordinal && response.type === BookingType.Adhoc.ordinal) {
                        if(booking.act?.lineUpCount > 1){
                            this.props.history.replace(`/privateBooking/${response.id}/lineup`);
                        }else{
                            this.props.history.replace(`/privateBooking/${response.id}/notifyclient`);
                        }
                    }
                    else {
                        this.props.templateStore.setEditBookingStep(this.props.templateStore.editBookingStep + 1);
                        if (currentProfile.type === ProfileType.Musician.ordinal) {
                            this.props.history.replace(`/privateBooking/${response.id}/lineup`);
                        } else if (currentProfile.type === ProfileType.Agent.ordinal) {
                            if (response.notifyAct) {
                                if (response.type == BookingType.Adhoc.ordinal && response.createdById == currentProfile.id && response.act?.actAdminId === currentProfile.id) {
                                    this.props.history.replace(`/privateBooking/${response.id}/lineup`);
                                } else {
                                    this.props.history.replace(`/privateBooking/${response.id}/notifyclient`);
                                }
                            } else {
                                this.props.history.replace(`/agent/gig`);
                            }
                        } else if (currentProfile.type === ProfileType.Staff.ordinal) {
                            this.props.history.replace(`/staff/gig`);
                        }
                    }
                    // this.props.templateStore.setEditBookingStep(this.props.templateStore.editBookingStep + 1);

                }

                this.closeSummaryModal();
            },
            reason => {
                this.props.templateStore.hideLoadingScreen();
                if (reason === "Invalid") {
                    const { error } = this.props.bookingRequestStore;
                    if (error) {
                        let node = undefined;
                        if (error["title"] || error["venue"] || error["act"] || error["agent"] || error["ticketUrl"]) {
                            node = findDOMNode(this.refs.eventDetail);
                        } else if (
                            error["clientFirstName"] ||
                            error["clientLastName"] ||
                            error["clientAddress"] ||
                            error["clientPhone"] ||
                            error["clientEmail"]
                        ) {
                            node = findDOMNode(this.refs.clientDetail);
                        } else if (error["gigFee"] || error["agentCommissionFee"] || error["actFee"] || error["balance"]) {
                            node = findDOMNode(this.refs.eventFee);
                        }
                        if (node) {
                            window.scrollTo(0, node.offsetTop - 100);
                        }
                    }
                    this.props.templateStore.openSnackbar("Incomplete Booking Field(s)");
                } else {
                    this.handleOpenConfirmDoubleBookingModal(reason);
                }
                this.closeSummaryModal();
            }
        );
    };

    handleAcceptBooking = e => {
        const { match, profileStore, bookingRequestStore, templateStore, history } = this.props;
        const currentProfile = profileStore.getCurrentProfile();
        const { booking } = bookingRequestStore;
        const { IsBookingRequest } = this.state;

        templateStore.showLoadingScreen();

        if (IsBookingRequest) {
            bookingRequestStore.acceptChangeActRequestWithProfileId(match.params.bookingRequestId, currentProfile.id).then(() => {
                templateStore.hideLoadingScreen();
                this.handleBookingAccepted();
            });
        } else {
            bookingRequestStore.saveStatus(currentProfile.id, BookingStatusType.Accepted.ordinal).then(() => {
                templateStore.openSnackbar("Booking accepted!");
                if (currentProfile.type === ProfileType.Musician.ordinal) {
                    if(booking.act?.lineUpCount > 1){
                        this.props.history.replace(`/privateBooking/${booking.id}/lineup`);
                    }else{
                        this.props.history.replace(`/privateBooking/${booking.id}/promote`);
                    }
                } else {
                    history.replace(`/privateBooking/${booking.id}/lineup`);
                }
                templateStore.hideLoadingScreen();
                templateStore.closeLineupBlock(); // block for unsaved lineup
            });
        }
    };

    handleHoldBooking = e => {
        const currentProfile = this.props.profileStore.getCurrentProfile();;
        const { booking } = this.props.bookingRequestStore;

        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore.saveStatus(currentProfile.id, BookingStatusType.Hold.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking Hold!");
            this.props.history.replace(`/privateBooking/${booking.id}/negotiate`);
            this.props.templateStore.setEditMusicianBookingActiveStep(1);
            this.props.templateStore.hideLoadingScreen();
        });
    };

    handleDeclineBooking = e => {
        const { match, profileStore, bookingRequestStore, templateStore, history } = this.props;
        const currentProfile = profileStore.getCurrentProfile();;
        const { IsBookingRequest } = this.state;

        templateStore.showLoadingScreen();

        if (IsBookingRequest) {
            this.props.bookingRequestStore.declineChangeActRequest(match.params.bookingRequestId).then(() => {
                this.props.templateStore.openSnackbar("Booking declined!");
                templateStore.hideLoadingScreen();
                this.props.history.push("/musician/gig");
            });
        } else {
            bookingRequestStore.saveStatus(currentProfile.id, BookingStatusType.Declined.ordinal).then(() => {
                templateStore.openSnackbar("Booking declined!");
                templateStore.hideLoadingScreen();
                history.push("/musician/gig");
            });
        }
    };

    handleAcceptLineup = e => {
        const { booking } = this.props.bookingRequestStore;
        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore.saveLineupStatus(LineupStatusType.Accepted.ordinal).then(() => {
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.openSnackbar("Booking accepted!");
            this.props.history.replace(`/privateBooking/${booking.id}/lineup`);
            this.props.templateStore.setEditMusicianBookingActiveStep(1); // skip negotiate
        });
    };

    handleDeclineLineup = e => {
        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore.saveLineupStatus(LineupStatusType.Declined.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking declined!");
            this.props.templateStore.hideLoadingScreen();
            this.props.history.push("/musician/gig");
        });
    };

    handleCancelLineup = e => {
        this.props.bookingRequestStore.saveLineupStatus(LineupStatusType.Cancelled.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking cancelled!");
            this.props.history.push("/musician/gig");
        });
    };

    handleBookingAccepted = e => {
        const { booking } = this.props.bookingRequestStore;
        this.props.templateStore.openSnackbar("Booking accepted!");
        this.props.history.push(`/privateBooking/${booking.id}/lineup`);
        this.props.history.replace(`/privateBooking/${booking.id}/lineup`);
        this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
        if (booking.isAdmin) {
            this.props.templateStore.closeLineupBlock(); // block for unsaved lineup
        }
    };

    handleConfirmDoubleBookingBooking = e => {
        this.handleCloseConfirmDoubleBookingModal();
        const { match } = this.props;
        const currentProfile = this.props.profileStore.getCurrentProfile();;
        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore.saveBookingWithoutCheckingAvailability().then(response => {
            this.props.templateStore.hideLoadingScreen();
            if (!match.params.id) {
                this.props.templateStore.openSnackbar("Booking has been created.");
                this.props.history.push("/booking");
                if (currentProfile.type === ProfileType.Musician.ordinal) {
                    this.props.history.replace(`/privateBooking/${response.id}/lineup`);
                } else if (currentProfile.type === ProfileType.Agent.ordinal) {
                    this.props.history.push(`/agent/calendar`);
                }
            } else {
                this.props.templateStore.openSnackbar("Booking details has been updated.");
                this.props.templateStore.setEditBookingStep(this.props.templateStore.editBookingStep + 1);
                if (currentProfile.type === ProfileType.Musician.ordinal) {
                    this.props.history.replace(`/privateBooking/${response.id}/lineup`);
                } else if (currentProfile.type === ProfileType.Agent.ordinal) {
                    this.props.history.replace(`/agent/gig`);
                } else if (currentProfile.type === ProfileType.Staff.ordinal) {
                    this.props.history.replace(`/staff/gig`);
                }
            }

            this.closeSummaryModal();
        });
    };

    handleCancelDoubleBookingBooking = e => {
        let node = findDOMNode(this.refs.eventDate);
        if (node) {
            window.scrollTo(0, node.offsetTop - 100);
        }
        // this.props.templateStore.openSnackbar(reason);
        this.handleCloseConfirmDoubleBookingModal();
    };

    actIsChanging = (value) => {
        this.setState({ isActChange: value });
    }

    checkStartEndTime = () => {
        const { booking } = this.props.bookingRequestStore;
        if (moment(booking.timeStart, "hh:mm:ss").format("hh:mm a") == moment(booking.timeEnd, "hh:mm:ss").format("hh:mm a")) {
            this.setState({ ...this.state, notifyStartEndTime: true })
        } else {
            const currentProfile = this.props.profileStore.getCurrentProfile();;

            if (currentProfile.type === ProfileType.Musician.ordinal) {
                this.handleSavePrivateBooking(false);
            }
            else {
                if (!booking.id && !booking.notifyAct) {                
                    // Checked saveAsDraft by default
                    // if it's newly created booking by agent, const, or staff
                    this.props.bookingRequestStore.setBooking("saveAsDraft", true);
                }
                this.setState({ ...this.state, summaryModalState: true })
            }
        }
    }

    handleCancelWarningStartEndTime = () => {
        this.setState({ ...this.state, notifyStartEndTime: false })
    }

    openSummaryModal = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if (currentProfile.type === ProfileType.Musician.ordinal) {
            this.handleSavePrivateBooking(false);
        }
        else {
            this.setState({ ...this.state, summaryModalState: true })
        }
    };
    closeSummaryModal = () => this.setState({ ...this.state, summaryModalState: false });

    openCancelBookingModal = () => this.setState({ ...this.state, cancelBookingModalState: true });
    closeCancelBookingModal = () => this.setState({ ...this.state, cancelBookingModalState: false });

    handleOpenDeclineModal = () => this.setState({ ...this.state, declineBookingModalState: true });
    handleCloseDeclineModal = () => this.setState({ ...this.state, declineBookingModalState: false });

    handleOpenDeclineLineupModal = () => this.setState({ ...this.state, declineLineupModalState: true });
    handleCloseDeclineLineupModal = () => this.setState({ ...this.state, declineLineupModalState: false });

    handleOpenCancelLineupModal = () => this.setState({ ...this.state, cancelLineupModalState: true });
    handleCloseCancelLineupModal = () => this.setState({ ...this.state, cancelLineupModalState: false });

    handleOpenConfirmDoubleBookingModal = errorMessage =>
        this.setState({ ...this.state, confirmDoubleBookingModalState: true, errorMessage: errorMessage });
    handleCloseConfirmDoubleBookingModal = () => this.setState({ ...this.state, confirmDoubleBookingModalState: false });

    isButtonCancelDisabled = () => {
        // const { dateStart } = this.props.bookingRequestStore.booking;
        // let diffDate = moment(dateStart).diff(moment(new Date()), "days");
        // return diffDate < 7;
        return false;
    };

    handleOpenChangeActModal = () => {
        this.setState({
            ...this.state,
            changeActModalState: true
        });
    };

    handleCloseChangeActModal = () => {
        const { match } = this.props;
        this.setState(
            {
                ...this.state,
                changeActModalState: false
            },
            () => {
                if (match.params.id) {
                    this.props.bookingRequestStore.initializeEditBooking(match.params.id, match.params.bookingRequestId);
                }
            }
        );
    };
    

    handlePostCancelBooking = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();;
        if (currentProfile.type === ProfileType.Agent.ordinal) {
            this.props.history.push("/agent/calendar");
        } else if (currentProfile.type === ProfileType.Staff.ordinal) {
            this.props.history.push("/staff/calendar");
        } else if (currentProfile.type === ProfileType.Musician.ordinal) {
            this.props.history.push("/musician/calendar");
        } else if (currentProfile.type === ProfileType.PrivateHire.ordinal) {
            this.props.history.push("/privatehire/gig/upcoming");
        }
    };

    handleChangeTermCondition = event => {
        this.setState({ acceptTermsCondition: event.target.checked });
    };

    render() {
        const currentProfile = this.props.profileStore.getCurrentProfile();;
        const { booking,isPrivateHire, isLoading, bookableActs } = this.props.bookingRequestStore;
        const { summaryModalState, cancelBookingModalState, IsBookingRequest, changeActModalState, notifyStartEndTime } = this.state;
        const { readOnly } = this.props;
        const tooltipText = this.state.acceptTermsCondition ? "" : `Please accept the terms and conditions before accepting the booking`;
        const { isLoadingLocal } = this.state
        const isAgent = currentProfile.type === ProfileType.Agent.ordinal;
        const isStaff = currentProfile.type === ProfileType.Staff.ordinal;
        const bookingCreatedByStaff = booking.createdBy?.type === ProfileType.Staff.ordinal;
        const isMusician = currentProfile.type === ProfileType.Musician.ordinal;
        const isPrivateHireProfile = currentProfile?.type === ProfileType.PrivateHire.ordinal;
        let showClientContract = isAgent || isStaff || isPrivateHireProfile || (isMusician && !readOnly); // client contract shown to musician only if they are creating the booking
        let showActContract = isAgent || isStaff;

        var synchedStatus = booking.profilePayer?.synchedStatus === 1 
        var synchedPayee = booking.profilePayer?.synchedPayee
        var synchedPayer = booking.profilePayer?.synchedPayer
        var invoiceIdPayer = booking.profilePayer?.invoiceId

        var synchedStatusPayee = booking.profilePayee?.synchedStatus === 1 
        var synchedPayeePayee = booking.profilePayee?.synchedPayee
        var synchedPayerPayee = booking.profilePayee?.synchedPayer
        var invoiceIdPayee = booking.profilePayee?.invoiceId

        var showPayerSection = synchedStatus || synchedPayee || synchedPayer
        var showPayeeSection = synchedStatusPayee || synchedPayeePayee || synchedPayerPayee

        const readOnlyInvoice = showPayerSection || showPayeeSection
        //for checking if profile is on lineup booking
        let onLineup = false;
        if (booking.lineups && booking?.lineups?.length && booking.lineups[0] !== undefined){
            if (booking?.lineups?.find(x => x.profileId == currentProfile.id)){
                onLineup = true;
            }
        }

        //for act owner or musician on lineups in private booking created by agent/consultant, exclude booking creator
        if ((booking?.id && booking?.createdById !== currentProfile?.id) && booking?.createdBy?.type == ProfileType.Agent.ordinal && booking?.type == BookingType.Adhoc.ordinal && (booking.act?.actAdminId == currentProfile?.id || booking?.actAdminId == currentProfile?.id || onLineup)){
            showActContract = true;
            showClientContract = false;
        }
        return (
            <div style={{ position: "relative", minHeight: "200px" }}>
                <Loading showed={isLoading} />
                {(booking.status === BookingStatusType.Cancelled.ordinal || booking.status === BookingStatusType.CancelledByAct.ordinal) &&
                    booking.cancelledBy && booking.createdById === currentProfile?.id ? (
                    <Grid container style={{ marginBottom: 60, position: "relative" }}>
                        <Grid item xs={12} lg={3}>
                            <div ref="eventDetail">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Cancellation
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <InputLabel shrink>Cancelled By</InputLabel>
                                    <Typography className="scrollable-div" variant="subheading">
                                        {booking.cancelledBy && booking.cancelledBy.type === ProfileType.Act.ordinal
                                            ? booking.cancelledBy.actName
                                            : booking.cancelledBy && booking.cancelledBy.type === ProfileType.Agent.ordinal
                                                ? booking.cancelledBy.profileName
                                                : booking.cancelledBy && booking.cancelledBy.type === ProfileType.Staff.ordinal ||  booking.cancelledBy.type === ProfileType.Musician.ordinal ||  booking.cancelledBy.type === ProfileType.PrivateHire.ordinal
                                                    ? `${booking.cancelledBy.user.firstName} ${booking.cancelledBy.user.lastName}`
                                                    : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel shrink>Cancellation Reason</InputLabel>
                                    <Typography
                                        className="scrollable-div"
                                        component="div"
                                        dangerouslySetInnerHTML={{ __html: booking.cancellationReason }}
                                    />
                                </Grid>
                                {(booking.status === BookingStatusType.Declined.ordinal || booking.status === BookingStatusType.Cancelled.ordinal || booking.status === BookingStatusType.CancelledByAct.ordinal) &&
                                    booking.cancelledBy &&
                                    booking.cancelledBy.type === ProfileType.Act.ordinal ? (
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" onClick={this.handleOpenChangeActModal}>
                                                Select Another Act
                                            </Button>
                                        </Grid>
                                        <BookingChangeActModal
                                            open={changeActModalState}
                                            onCloseModel={this.handleCloseChangeActModal}
                                            onClose={this.handleCloseChangeActModal}
                                            booking={booking}
                                        />
                                    </Fragment>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    ""
                )}
                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDate">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Date and Time Detail
                            </Typography>
                            {!readOnly &&
                                <Typography gutterBottom className="side-helper-text">
                                    {/* Enter the date and time details for this booking. Date, Start Time and End times are mandatory fields. */}
                                    Manually input the date, start and finish times, load in and sound check times.
                                </Typography>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <PrivateBookingDateDetails readOnly={readOnly || readOnlyInvoice} />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="clientDetail">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Client Details
                            </Typography>
                            {!readOnly &&
                                <Typography gutterBottom className="side-helper-text">
                                    {/* Enter the details for your client. Be sure to include the client's email address so that they receive your invoices and contract. */}
                                    Use this section to enter all of the details of the person or company you are doing the gig for. The email address is required in order to automatically email your invoice to the client.
                                </Typography>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <PrivateBookingClientDetails readOnly={readOnly || readOnlyInvoice} />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDetail">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Event / Venue Details
                            </Typography>
                            {!readOnly &&
                                <Typography gutterBottom className="side-helper-text">
                                    {/* Enter the name of the event along with venue details. If this is a ticketed event, enter a link to the event provided by your ticketing provider. */}
                                    Enter the event title and venue details. The event title will appear in the line item description on your invoice. If the event is a ticketed event, enter the Ticket URL in the field allocated to create a link on the venue’s profile page to the ticketing site.
                                </Typography>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <PrivateBookingDetails readOnly={readOnly || readOnlyInvoice} />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }} spacing={8}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDetail">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Act Details
                            </Typography>
                            {!readOnly &&
                                <Typography gutterBottom className="side-helper-text">
                                    Use this section to select the act for this booking. Only acts that are available on the booking date and time will be
                                    shown.
                                    {/* Select your act that will be performing the gig. */}
                                </Typography>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <PrivateBookingActDetails actChange={this.actIsChanging} readOnly={booking.status === BookingStatusType.Declined.ordinal ? false : readOnly} />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventSocial">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Publishing
                            </Typography>
                            {!readOnly &&
                                <Typography gutterBottom className="side-helper-text">
                                    Share this booking information
                                </Typography>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <BookingSocialDetail />
                    </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventFee">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Fee - Deposit Details
                            </Typography>
                            {!readOnly &&
                                <Fragment>
                                    <Typography gutterBottom className="side-helper-text">
                                        Manually input the: gig fee - Deposit fee - Deposit due etc.. System will automatically calculate the balance left
                                        from deposit paid and balance due date.
                                    </Typography>
                                    <Typography gutterBottom className="side-helper-text">
                                        You also have the option to create an invoice for this gig by clicking the create invoice button. If you don’t wish to
                                        create an invoice for this gig simply ensure the button is switched off
                                    </Typography>
                                </Fragment>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <PrivateBookingFeeDetails readOnly={readOnly || readOnlyInvoice} />
                    </Grid>
                </Grid>

                {
                    !isLoadingLocal ? (
                        <>
                            <Grid container spacing={8} style={{ marginBottom: 60 }}>
                                <Grid item xs={12} lg={3}>
                                    <div ref="eventRequirementDetail">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Gig Requirement
                                        </Typography>
                                        {!readOnly &&
                                            <Typography gutterBottom className="side-helper-text">
                                                Let the artist know the details of the gig, number of sets, vibe, suggested genres and what to wear.
                                            </Typography>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    {!isLoadingLocal &&
                                        <PrivateBookingGigRequirement readOnly={readOnly || readOnlyInvoice} />
                                    }
                                </Grid>
                            </Grid>
                            {
                                !bookingCreatedByStaff && (!isPrivateHire && !booking.privateHireId) &&
                                <Grid container spacing={8} style={{ marginBottom: 60 }}>
                                    <Grid item xs={12} lg={3}>
                                        <div ref="eventRequirementDetail">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Production Requirements Detail
                                            </Typography>
                                            {!readOnly &&
                                                <Typography gutterBottom className="side-helper-text">
                                                    {/* List any production requirements that the act will need to provide for the gig. Also use this field to advise the act
                                    if production is being supplied by the venue or the client. */}
                                                    Use this field to record the production requirements for this gig.
                                                </Typography>
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        {!isLoadingLocal &&
                                            <BookingRequirementDetails readOnly={readOnly || readOnlyInvoice} />
                                        }
                                    </Grid>
                                </Grid>
                            }

                            {
                                !bookingCreatedByStaff &&  (!isPrivateHire && !booking.privateHireId)  &&
                                <Grid container spacing={8} style={{ marginBottom: 60 }}>
                                    <Grid item xs={12} lg={3}>
                                        <div ref="notes">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Notes
                                            </Typography>
                                            {!readOnly &&
                                                <Typography gutterBottom className="side-helper-text">
                                                    {/* Use this field to advise the act on any other details such as load in instructions, db limits, car parking etc. */}
                                                    Use this field to record any notes which will be distributed to your players - db limits, car parking etc.
                                                </Typography>
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        {!isLoadingLocal &&
                                            <BookingNotes readOnly={readOnly || readOnlyInvoice} />
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {showActContract && !bookingCreatedByStaff && (
                                <Grid container spacing={8} style={{ marginBottom: 60 }}>
                                    <Grid item xs={12} lg={3}>
                                        <div ref="notes">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Act Contract
                                            </Typography>
                                            {!readOnly &&
                                                <Typography gutterBottom className="side-helper-text">
                                                    We have supplied a template contract for your convenience. This contract can be manually adjusted by you to your
                                                    requirements and saved at any time.
                                                </Typography>
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9} className="space-top-10">
                                        {!isLoadingLocal &&
                                            <BookingAgentContract isAct={true} readOnly={readOnly || readOnlyInvoice} />
                                        }

                                    </Grid>
                                </Grid>
                            )}
                            {showClientContract && (
                                <Grid container spacing={8} style={{ marginBottom: 60 }}>
                                    <Grid item xs={12} lg={3}>
                                        <div ref="notes">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Client Contract
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                {/* The client contract should contain the terms and conditions for this booking.  This contract can be sent to the client in the next step of the booking process. */}
                                                Use this section to create a client contract which will be emailed with your invoices. The template for this can be set up in the Terms & Conditions section when editing your act.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9} className="space-top-10">
                                        {!isLoadingLocal &&
                                            <BookingClientContract booking={booking} isActChange={currentProfile.type == ProfileType.Agent.ordinal ? false : this.state.isActChange} actIsChanging={this.actIsChanging} />
                                        }

                                    </Grid>
                                </Grid>
                            )}
                        </>
                    ) : (
                        <p>Hang tight... We're fetching contracts...</p>
                    )
                }
                {
                    !readOnlyInvoice &&
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={3} />
                        <Grid item xs={12} lg={9}>
                            {IsBookingRequest ? (
                                <Fragment>
                                    <Hidden smDown>
                                        <div className="action">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.acceptTermsCondition}
                                                        onChange={this.handleChangeTermCondition}
                                                        value="AcceptTermsCondition"
                                                    />
                                                }
                                                labelPlacement="start"
                                                label="I agree to the terms and conditions of this booking"
                                            />
                                            <Divider variant="middle" />
                                        </div>
                                        <div className="action">
                                            <Button variant="outlined" style={{ marginRight: 10 }} onClick={this.handleOpenDeclineModal}>
                                                Decline Booking
                                            </Button>

                                            <Button variant="contained" disabled={!this.state.acceptTermsCondition} color="primary" onClick={this.handleAcceptBooking}>
                                                Accept Booking
                                            </Button>
                                        </div>
                                    </Hidden>
                                    <Hidden mdUp>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleAcceptBooking}
                                            fullWidth={true}
                                            style={{ marginBottom: "12px" }}
                                        >
                                            Accept Booking
                                        </Button>
                                        <Button variant="outlined" onClick={this.handleOpenDeclineModal} fullWidth={true}>
                                            Decline Booking
                                        </Button>
                                    </Hidden>
                                    <ConfirmationModal
                                        open={this.state.declineBookingModalState}
                                        title="Are you sure you want to decline this booking?"
                                        message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                        closeHandler={this.handleCloseDeclineModal}
                                        confirmationHandler={this.handleDeclineBooking}
                                        declineHandler={this.handleCloseDeclineModal}
                                        confirmationLabel="Decline Booking"
                                        declineLabel="Do not Decline"
                                        maxWidth="sm"
                                    />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Hidden smDown>
                                        <div className="action">
                                            
                                            {//New Booking
                                                (booking && booking.id && booking.id === 0) || (booking && !booking.id) ? (
                                                    <Button variant="contained" color="primary" onClick={this.checkStartEndTime}>
                                                        Save & Next
                                                    </Button>
                                                ) : (
                                                    ""
                                                )
                                            }
                                            {// if user is booking creator
                                                (booking &&
                                                    currentProfile &&
                                                    booking.id &&
                                                    booking.id !== 0 &&
                                                    (booking.createdById === currentProfile.id || booking.canUpdate) &&
                                                    moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
                                                    booking.status !== BookingStatusType.Cancelled.ordinal) && !((isPrivateHire || isPrivateHireProfile)) ? (
                                                    <Fragment>
                                                        <Button
                                                            variant="outlined"
                                                            style={{ marginRight: 10 }}
                                                            onClick={this.openCancelBookingModal}
                                                            disabled={this.isButtonCancelDisabled()}
                                                        >
                                                            Cancel Booking
                                                        </Button>
                                                        <Button variant="contained" color="primary" onClick={this.checkStartEndTime}>
                                                            Save & Next
                                                        </Button>
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}
                                                {// if user is booking creator
                                                booking &&
                                                currentProfile &&
                                                booking.id &&
                                                booking.id !== 0 &&
                                                ((isPrivateHire || isPrivateHireProfile)) &&
                                                moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
                                                booking.status !== BookingStatusType.Cancelled.ordinal ? (
                                                <Fragment>
                                                    <Button
                                                        variant="outlined"
                                                        style={{ marginRight: 10 }}
                                                        onClick={this.openCancelBookingModal}
                                                        disabled={this.isButtonCancelDisabled()}
                                                    >
                                                        Cancel Booking
                                                    </Button>
                                                    {
                                                        currentProfile.type === ProfileType.PrivateHire.ordinal ? "" :
                                                        ( <Button variant="contained" color="primary" onClick={this.checkStartEndTime}>
                                                            Save & Next
                                                        </Button>)
                                                    }
                                                
                                                </Fragment>
                                            ) : (
                                                ""
                                            )}
                                                {/* {// if user is booking creator
                                                booking &&
                                                currentProfile &&
                                                booking.id &&
                                                booking.id !== 0 &&
                                                moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
                                                booking.status !== BookingStatusType.Cancelled.ordinal ? (
                                                <Fragment>
                                                    <Button
                                                        variant="outlined"
                                                        style={{ marginRight: 10 }}
                                                        onClick={this.openCancelBookingModal}
                                                        disabled={this.isButtonCancelDisabled()}
                                                    >
                                                        Cancel Booking
                                                    </Button>
                                                </Fragment>
                                            ) : (
                                                ""
                                            )} */}
                                            {//if new booking is created by agent and user is musician and act admin
                                                booking &&
                                                    (booking.createdById !== currentProfile.id && !booking.canUpdate) &&
                                                    (isMusician || isAgent) &&
                                                    booking.isAdmin &&
                                                    (booking.status === BookingStatusType.New.ordinal || booking.status === BookingStatusType.Hold.ordinal || booking.status === BookingStatusType.SentToAct.ordinal) ? (
                                                    <Fragment>
                                                        <div className="action">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.acceptTermsCondition}
                                                                        onChange={this.handleChangeTermCondition}
                                                                        value="AcceptTermsCondition"
                                                                    />
                                                                }
                                                                labelPlacement="start"
                                                                label="I agree to the terms and conditions of this booking"
                                                            />
                                                            <Divider variant="middle" />
                                                        </div>
                                                        <div className="action">
                                                            <Button variant="outlined" style={{ marginRight: 10 }} onClick={this.handleOpenDeclineModal}>
                                                                Decline Booking
                                                            </Button>
                                                            {booking.status === BookingStatusType.New.ordinal || booking.status === BookingStatusType.SentToAct.ordinal ? (
                                                                <Button variant="outlined" style={{ marginRight: 10 }} onClick={this.handleHoldBooking}>
                                                                    Hold and Negotiate
                                                                </Button>
                                                            ) : null}
                                                            <Tooltip title={tooltipText}>
                                                                <span>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={this.handleAcceptBooking}
                                                                        disabled={!this.state.acceptTermsCondition}
                                                                    >
                                                                        Accept Booking
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                        <ConfirmationModal
                                                            open={this.state.declineBookingModalState}
                                                            title="Are you sure you want to decline this booking?"
                                                            message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                                            closeHandler={this.handleCloseDeclineModal}
                                                            confirmationHandler={this.handleDeclineBooking}
                                                            declineHandler={this.handleCloseDeclineModal}
                                                            confirmationLabel="Decline Booking"
                                                            declineLabel="Do not Decline"
                                                            maxWidth="sm"
                                                        />
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}

                                            {//if accepted booking is created by agent and user is musician and act admin
                                                booking &&
                                                    booking.createdById !== currentProfile.id &&
                                                    (isMusician || isAgent) &&
                                                    moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
                                                    booking.isAdmin &&
                                                    booking.status === BookingStatusType.Accepted.ordinal ? (
                                                    <Fragment>
                                                        <Button
                                                            variant="contained"
                                                            onClick={this.openCancelBookingModal}
                                                            color="primary"
                                                            disabled={this.isButtonCancelDisabled()}
                                                        >
                                                            Cancel Booking
                                                        </Button>
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}

                                            {/*
                                                    if new booking is created by agent,
                                                    user is musician and player
                                                    lineup status = new
                                                    */
                                                booking &&
                                                    booking.createdById !== currentProfile.id &&
                                                    isMusician &&
                                                    !booking.isAdmin &&
                                                    booking.myLineup &&
                                                    booking.myLineup.status === 0 ? (
                                                    <Fragment>
                                                        <div className="action">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.acceptTermsCondition}
                                                                        onChange={this.handleChangeTermCondition}
                                                                        value="AcceptTermsCondition"
                                                                    />
                                                                }
                                                                labelPlacement="start"
                                                                label={readOnly ? "I agree to the terms and conditions above" : "I agree to the venue's terms and condition above"}
                                                            />
                                                            <Divider variant="middle" />
                                                        </div>
                                                        <div className="action">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ marginRight: 10 }}
                                                                onClick={this.handleOpenDeclineLineupModal}
                                                            >
                                                                Decline Booking
                                                            </Button>
                                                            <Tooltip title={tooltipText}>
                                                                <span>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={this.handleAcceptLineup}
                                                                        disabled={!this.state.acceptTermsCondition}
                                                                    >
                                                                        Accept Booking
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                            <ConfirmationModal
                                                                open={this.state.declineLineupModalState}
                                                                title="Are you sure you want to decline this booking?"
                                                                message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                                                closeHandler={this.handleCloseDeclineLineupModal}
                                                                confirmationHandler={this.handleDeclineLineup}
                                                                declineHandler={this.handleCloseDeclineLineupModal}
                                                                confirmationLabel="Decline Booking"
                                                                declineLabel="Do not Decline"
                                                                maxWidth="sm"
                                                            />{" "}
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}

                                            {/*
                                                    if booking is created by agent,
                                                    user is musician and player
                                                    lineup status = accepted
                                                    */
                                                booking &&
                                                    booking.createdById !== currentProfile.id &&
                                                    isMusician &&
                                                    !booking.isAdmin &&
                                                    booking.myLineup &&
                                                    booking.myLineup.status === 3 ? (
                                                    <Fragment>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={this.handleOpenCancelLineupModal}
                                                            disabled={this.isButtonCancelDisabled()}
                                                        >
                                                            Cancel Booking
                                                        </Button>
                                                        <ConfirmationModal
                                                            open={this.state.cancelLineupModalState}
                                                            title="Are you sure you want to cancel this booking?"
                                                            message="By cancelling this booking you will remove it from your gig list an will not be able to access it anymore."
                                                            closeHandler={this.handleCloseCancelLineupModal}
                                                            confirmationHandler={this.handleCancelLineup}
                                                            declineHandler={this.handleCloseCancelLineupModal}
                                                            confirmationLabel="Cancel Booking"
                                                            maxWidth="sm"
                                                        />
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}
                                                
                                        </div>
                                    
                                    </Hidden>
                                    <Hidden mdUp>
                                        <div>
                                            {//New Booking
                                                (booking && booking.id && booking.id === 0) || (booking && !booking.id) ? (
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        style={{ marginBottom: "12px" }}
                                                        color="primary"
                                                        onClick={this.checkStartEndTime}
                                                    >
                                                        Save & Next
                                                    </Button>
                                                ) : (
                                                    ""
                                                )}

                                            {// if user is booking creator
                                                booking &&
                                                    currentProfile &&
                                                    booking.id &&
                                                    booking.id !== 0 &&
                                                    (booking.createdById === currentProfile.id || booking.canUpdate) &&
                                                    moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
                                                    booking.status !== BookingStatusType.Cancelled.ordinal ? (
                                                    <Fragment>
                                                        <Button
                                                            variant="contained"
                                                            fullWidth
                                                            style={{ marginBottom: 12 }}
                                                            color="primary"
                                                            onClick={this.checkStartEndTime}
                                                        >
                                                            Save & Next
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            fullWidth
                                                            style={{ marginBottom: 12 }}
                                                            onClick={this.openCancelBookingModal}
                                                            disabled={this.isButtonCancelDisabled()}
                                                        >
                                                            Cancel Booking
                                                        </Button>
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}
        
        {// if user is booking creator
                                                booking &&
                                                currentProfile &&
                                                booking.id &&
                                                booking.id !== 0 &&
                                                (isPrivateHire || isPrivateHireProfile) &&
                                                moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
                                                booking.status !== BookingStatusType.Cancelled.ordinal ? (
                                                <Fragment>
                                                    <Button
                                                        variant="outlined"
                                                        fullWidth
                                                        style={{ marginBottom: 12 }}
                                                        onClick={this.openCancelBookingModal}
                                                        disabled={this.isButtonCancelDisabled()}
                                                    >
                                                        Cancel Booking
                                                    </Button>
                                                </Fragment>
                                            ) : (
                                                ""
                                            )}

                                            {//if new booking is created by agent and user is musician and act admin
                                                booking &&
                                                    booking.createdById !== currentProfile.id &&
                                                    isMusician &&
                                                    booking.isAdmin &&
                                                    (booking.status === BookingStatusType.New.ordinal || booking.status === BookingStatusType.Hold.ordinal) ? (
                                                    <Fragment>
                                                        <Button
                                                            variant="contained"
                                                            fullWidth
                                                            style={{ marginBottom: 12 }}
                                                            color="primary"
                                                            onClick={this.handleAcceptBooking}
                                                        >
                                                            Accept Booking
                                                        </Button>
                                                        {booking.status === BookingStatusType.New.ordinal ? (
                                                            <Button
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{ marginBottom: 12 }}
                                                                onClick={this.handleHoldBooking}
                                                            >
                                                                Hold and Negotiate
                                                            </Button>
                                                        ) : null}
                                                        <Button
                                                            variant="outlined"
                                                            fullWidth
                                                            style={{ marginBottom: 12 }}
                                                            onClick={this.handleOpenDeclineModal}
                                                        >
                                                            Decline Booking
                                                        </Button>
                                                        <ConfirmationModal
                                                            open={this.state.declineBookingModalState}
                                                            title="Are you sure you want to decline this booking?"
                                                            message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                                            closeHandler={this.handleCloseDeclineModal}
                                                            confirmationHandler={this.handleDeclineBooking}
                                                            declineHandler={this.handleCloseDeclineModal}
                                                            confirmationLabel="Decline Booking"
                                                            declineLabel="Do not Decline"
                                                            maxWidth="sm"
                                                        />
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}

                                            {//if accepted booking is created by agent and user is musician and act admin
                                                booking &&
                                                    booking.createdById !== currentProfile.id &&
                                                    isMusician &&
                                                    moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
                                                    booking.isAdmin &&
                                                    booking.status === BookingStatusType.Accepted.ordinal ? (
                                                    <Fragment>
                                                        <Button
                                                            variant="contained"
                                                            onClick={this.openCancelBookingModal}
                                                            fullWidth
                                                            style={{ marginBottom: 12 }}
                                                            color="primary"
                                                            disabled={this.isButtonCancelDisabled()}
                                                        >
                                                            Cancel Booking
                                                        </Button>
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}

                                            {/*
                                                    if new booking is created by agent,
                                                    user is musician and player
                                                    lineup status = new
                                                    */
                                                booking &&
                                                    booking.createdById !== currentProfile.id &&
                                                    isMusician &&
                                                    !booking.isAdmin &&
                                                    booking.myLineup &&
                                                    booking.myLineup.status === 0 ? (
                                                    <Fragment>
                                                        <Button
                                                            variant="contained"
                                                            fullWidth
                                                            style={{ marginBottom: 12 }}
                                                            color="primary"
                                                            onClick={this.handleAcceptLineup}
                                                        >
                                                            Accept Booking
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            fullWidth
                                                            style={{ marginBottom: 10 }}
                                                            onClick={this.handleOpenDeclineLineupModal}
                                                        >
                                                            Decline Booking
                                                        </Button>

                                                        <ConfirmationModal
                                                            open={this.state.declineLineupModalState}
                                                            title="Are you sure you want to decline this booking?"
                                                            message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                                                            closeHandler={this.handleCloseDeclineLineupModal}
                                                            confirmationHandler={this.handleDeclineLineup}
                                                            declineHandler={this.handleCloseDeclineLineupModal}
                                                            confirmationLabel="Decline Booking"
                                                            declineLabel="Do not Decline"
                                                            maxWidth="sm"
                                                        />
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}

                                            {/*
                                                    if booking is created by agent,
                                                    user is musician and player
                                                    lineup status = accepted
                                                    */
                                                booking &&
                                                    booking.createdById !== currentProfile.id &&
                                                    isMusician &&
                                                    !booking.isAdmin &&
                                                    booking.myLineup &&
                                                    booking.myLineup.status === 3 ? (
                                                    <Fragment>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={this.handleOpenCancelLineupModal}
                                                            disabled={this.isButtonCancelDisabled()}
                                                            fullWidth
                                                            style={{ marginBottom: 12 }}
                                                        >
                                                            Cancel Booking
                                                        </Button>
                                                        <ConfirmationModal
                                                            open={this.state.cancelLineupModalState}
                                                            title="Are you sure you want to cancel this booking?"
                                                            message="By cancelling this booking you will remove it from your gig list an will not be able to access it anymore."
                                                            closeHandler={this.handleCloseCancelLineupModal}
                                                            confirmationHandler={this.handleCancelLineup}
                                                            declineHandler={this.handleCloseCancelLineupModal}
                                                            confirmationLabel="Cancel Booking"
                                                            maxWidth="sm"
                                                        />
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}
                                            <Button variant="outlined" fullWidth onClick={() => this.props.history.goBack()}>
                                                Back
                                            </Button>
                                        </div>
                                    </Hidden>
                                </Fragment>
                            )}
                        </Grid>

                
                    </Grid>
                }

                {readOnlyInvoice && (
                     <Grid container spacing={8}>
                        <Grid item xs={12} lg={3} />
                        <Grid item xs={12} lg={9} className="stepperContent">
                            <div className="action pull-right">
                                <Fragment>
                                        <Button
                                            variant="outlined"
                                            style={{ marginRight: 10 }}
                                            onClick={this.openCancelBookingModal}
                                            disabled={this.isButtonCancelDisabled()}
                                        >
                                            Cancel Booking
                                        </Button>
                                        <Tooltip title="The invoice for this booking has already been sent to your accounting system. You need to Unsync the invoice before you can update the booking">
                                        <Button
                                            id="btn-process"
                                            style={{backgroundColor:"black",color:"white",opacity:"0.6"}}
                                        >
                                            {this.props.processLabel ? this.props.processLabel : "Process Booking"}
                                        </Button>
                                    </Tooltip>
                                </Fragment>
                            </div>
                        </Grid>
                            
                     </Grid>
                                                    
                                                  
                                                )}

                {/* {   booking &&
                        currentProfile &&
                        booking.id &&
                        booking.id !== 0 &&
                        (booking.createdById === currentProfile.id || booking.canUpdate) &&
                        moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
                        booking.status !== BookingStatusType.Cancelled.ordinal ? (
                        <Grid container style={{marginBottom: 60, marginTop:50}}>
                            <Grid item xs={12} lg={3}>
                                <div ref="eventFee">
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Invoice Payer Section
                                    </Typography>
                                </div>
                            </Grid>

                      
                            <Grid item xs={6} lg={3}>
                                <Button className="viewInvoiceForm" style={{width:"120px", height:"40px"}} variant="contained" color="primary" onClick={() => this.handleRedirectToInvoice(invoiceIdPayer)}>
                                    View Invoice
                                </Button>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <Button className="viewInvoiceForm" style={{width:"120px", height:"40px"}} variant="contained" color="primary" onClick={this.handleRedirectUnsyncInvoice}>
                                    Unsync Invoice
                                </Button>
                            </Grid>
                        </Grid>) :  null
                    } */}



             


                <PrivateBookingSummaryModal
                    open={summaryModalState}
                    // open={true}
                    onClose={this.closeSummaryModal}
                    processBooking={finalize => this.handleSavePrivateBooking(finalize)}
                />

                <CancelBookingModal
                    open={cancelBookingModalState}
                    onClose={this.closeCancelBookingModal}
                    postCancelAction={this.handlePostCancelBooking}
                />

                <ConfirmationModal
                    open={this.state.confirmDoubleBookingModalState}
                    title="Booking Warning"
                    message={this.state.errorMessage + " Are you sure to save this gig?"}
                    closeHandler={this.handleCloseConfirmDoubleBookingModal}
                    confirmationHandler={this.handleConfirmDoubleBookingBooking}
                    declineHandler={this.handleCancelDoubleBookingBooking}
                    confirmationLabel="Confirm"
                    declineLabel="Cancel"
                    maxWidth="sm"
                />

                <ConfirmationModal
                    open={this.state.notifyStartEndTime}
                    title="Booking Warning"
                    message={"The start and end times are the same - are you sure you want to save this booking?"}
                    closeHandler={this.handleCancelWarningStartEndTime}
                    confirmationHandler={this.openSummaryModal}
                    declineHandler={this.handleCancelWarningStartEndTime}
                    confirmationLabel="Confirm"
                    declineLabel="Cancel"
                />

<ConfirmationModal
                    open={this.state.confirmDoubleBookingModalState}
                    title="Booking Warning"
                    message={this.state.errorMessage + " Are you sure to save this gig?"}
                    closeHandler={this.handleCloseConfirmDoubleBookingModal}
                    confirmationHandler={this.handleConfirmDoubleBookingBooking}
                    declineHandler={this.handleCancelDoubleBookingBooking}
                    confirmationLabel="Confirm"
                    declineLabel="Cancel"
                    maxWidth="sm"
                />
                 <ConfirmationModal
                    open={this.state.unsyncInvoiceModal}
                    title={"Unsync Confirmation"}
                    message={`This action will not affected the invoice that already sent to accounts. It will only mark this hotgiggity invoice to unsync. Are you sure you want to continue??`}
                    closeHandler={this.handleCloseUnsyncInvoiceModal}
                    confirmationHandler={this.handleConfirmMarkAsUnsync}
                    declineHandler={this.handleCloseUnsyncInvoiceModal}
                    confirmationLabel="Confirm"
                />

                <ConfirmationModal
                    open={this.state.unsyncInvoiceXeroModal}
                    title={"Unsync Confirmation"}
                    message={`This action will void the invoice in XERO. You will need to resync the invoice again. Are you sure you want to continue?`}
                    closeHandler={this.handleCloseUnsyncInvoiceXeroModal}
                    confirmationHandler={this.handleConfirmMarkAsUnsyncXero}
                    declineHandler={this.handleCloseUnsyncInvoiceXeroModal}
                    confirmationLabel="Confirm"
                />

                <ConfirmationModal
                    open={this.state.unsyncInvoiceModalPayee}
                    title={"Unsync Confirmation"}
                    message={`${this.state.invoice?.payeeName} has already synced this invoice to their accounting system so this booking cannot be unsynced`}
                    closeHandler={this.handleCloseUnsyncInvoicePayeeModal}
                    confirmationHandler={this.handleSendMessageNotification}
                    declineHandler={this.handleCloseUnsyncInvoicePayeeModal}
                    confirmationLabel={`Message ${this.state.invoice?.payeeName}`}
                />
            </div>
        );
    }
}

export default PrivateBookingForm;
