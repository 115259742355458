import React, { Component, Fragment } from "react";

import { observer, inject } from "mobx-react";

import Heading from "../../components/Heading";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
// import { ProfileType } from "../../types/enum";

import MessagesTable from "../../components/messages/MessagesTable";
import Breadcrumb from "../../components/Breadcrumb";
import { ProfileType } from "../../types/enum";

@inject("invoiceStore", "templateStore", "reportStore", "profileStore", "commonStore", "conversationStore")
@observer
class Messages extends Component {

    handlePaymentPage = () => {
        this.setState({
            goToPaymentPage: true
        });
    };

    loadConversations = () => {
        this.props.conversationStore.load();
    };

    componentDidMount() {
        this.loadConversations();
    }

    handleStatusFilterChange = e => {
        this.props.reportStore.setStatusFilter(e.target.value);
        this.loadConversations();
    };

    render() {
        const profiles = this.props.profileStore.getCurrentProfile();
        var breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Messages" }];
        if(profiles?.type === ProfileType.PrivateHire.ordinal){
            breadcrumb = [{ name: "Messages" }];
        }
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Fragment>
                        <Heading title="Messages" />
                        <MessagesTable />
                    </Fragment>
                </MainContainer>
            </Fragment>
        );
    }
}

export default Messages;
