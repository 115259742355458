import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import SongListHeader from "./SongListHeader";
import SongListToolbar from "./SongListToolbar";
import green from "@material-ui/core/colors/green";
import { inject, observer } from "mobx-react";
import SongListEdit from "./SongListEdit";
import SongListMedia from "./SongListMedia";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../loadingSpinner/Loading";
import "./SongListTable.css";

const styles = theme => ({
    root: {
        width: "100%",
        boxShadow: "none"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    checked: {
        color: green[500],
        "& + $bar": {
            backgroundColor: green[500]
        }
    },
    bar: {}
});

@inject("songListStore", "setListStore")
@observer
class SongListTable extends Component {
    state = {
        openAdd: false,
        openRemove: false,
        order: "asc",
        orderBy: "title",
        selected: [],
        page: 0,
        rowsPerPage: 10,
        isLoading: true
    };

    componentDidMount() {
        if (this.props.profileId) {
            this.props.songListStore.reset();
            this.props.songListStore.setType(this.props.type);
            this.props.songListStore.setProfileId(this.props.profileId);
            this.props.songListStore.loadInitialSongs().finally(() => {
                this.setState({ isLoading: false });
            });
        }
    }

    handleRequestSort = (event, property) => {
        let { songList } = this.props.songListStore;
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        const data =
            order === "desc"
                ? songList.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                : songList.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        this.props.songListStore.updateSongList(data);

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        let { songList } = this.props.songListStore;
        if (checked) {
            this.setState({ selected: songList.map(n => n.id) });
            this.props.setListStore.updateSelectedSongs(songList.map(n => n));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        let { songList } = this.props.songListStore;
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        const songToSetList = songList.filter(item => {
            return newSelected.indexOf(item.id) !== -1;
        });

        this.props.setListStore.updateSelectedSongs(songToSetList);
        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleSelectTopSong = song => {
        this.props.songListStore.selectTopSong(song);
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    onListReset = () => {
        this.setState({ selected: [] });
    };

    handleAddSongList = (title, artist, mediaUrl, songKey) => {
        this.setState({ isLoading: true });
        let song = { title, artist, mediaUrl, songKey, profileId: this.props.profileId };
        return this.props.songListStore.addSong(song).then(() => {
            this.setState({ openAdd: true, isLoading: false });
        });
    };

    handleUndoAddSongList = () => {
        this.props.songListStore.undoAddSong();
        this.setState({ openAdd: false });
    };

    handleRemoveSong = selectedIds => {
        // let songs = this.props.songListStore.songList;
        this.setState({ isLoading: true });
        this.props.songListStore.deleteSongs(selectedIds).then(() => {
            this.setState({ isLoading: false });
        });
        this.setState({ openDelete: true });
    };

    handleUndoRemoveSong = () => {
        this.setState({ isLoading: true });
        this.props.songListStore.undoDeleteSongs().then(() => {
            this.setState({ isLoading: false });
        });
        this.setState({ openDelete: false });
    };

    handleSortSongList = songs => {
        this.props.songListStore.handleSortSongList(songs);
    };

    handleSnackbarAddClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({ openAdd: false });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.props.songListStore.executeDeleteSongs();
        this.setState({ openDelete: false });
    };

    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, isLoading } = this.state;
        const { songList } = this.props.songListStore;

        return (
            <div style={{ position: "relative" }}>
                <Loading showed={isLoading} />
                <SongListToolbar
                    numSelected={this.state.selected.length}
                    onListAdd={this.handleAddSongList}
                    onListRemove={this.handleRemoveSong}
                    onListReset={this.onListReset}
                    selectedSongs={this.state.selected}
                    type={this.props.type}
                />

                <div className={classes.tableWrapper}>
                    <Table className="song-table">
                        <SongListHeader
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={songList.length}
                        />
                        <TableBody>
                            {songList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                const isSelected = this.isSelected(n.id);
                                return (
                                    <TableRow hover role="checkbox" aria-checked={isSelected} tabIndex={-1} key={n.id} selected={isSelected}>
                                        <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id)} className="nopad">
                                            <Checkbox className="songCheckbox" checked={isSelected} />
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{n.title}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{n.artist}</Typography>
                                        </TableCell>
                                        <TableCell className="nopad">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={n.topProfile}
                                                        onChange={this.handleSelectTopSong.bind(this, n)}
                                                        classes={{
                                                            checked: classes.checked,
                                                            bar: classes.bar
                                                        }}
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell className="nopad">
                                            <SongListMedia media={n.mediaUrl} />
                                        </TableCell>
                                        <TableCell className="nopad">
                                            <Typography>{n.songKey}</Typography>
                                        </TableCell>
                                        <TableCell className="nopad action">
                                            <SongListEdit onListReset={this.onListReset} onListRemove={this.handleRemoveSong} song={n} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    SelectProps={{ className: "transparent-select" }}
                                    count={songList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    {/* <LinearProgress variant="determinate" value={topSongs.length * 8.5} /> */}
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openAdd}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarAddClose}
                    snackbarcontentprops={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Song Added</span>}
                    action={[
                        <Button key="undo" color="primary" size="small" onClick={this.handleUndoAddSongList}>
                            UNDO
                        </Button>,
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarAddClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDelete}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarDeleteClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Song Deleted</span>}
                    action={[
                        <Button key="undo" color="primary" size="small" onClick={this.handleUndoRemoveSong}>
                            UNDO
                        </Button>,
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarDeleteClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}

SongListTable.propTypes = {
    classes: PropTypes.object.isRequired
    // onListAdd: PropTypes.func.isRequired,
    // onListRemove: PropTypes.func.isRequired,
    // onListSort: PropTypes.func.isRequired,
    // profileId: PropTypes.number.isRequired
};

export default withStyles(styles)(SongListTable);
