import React, { Component } from "react";
import { Grid, Paper } from "@material-ui/core";

import WidgetTitleBar from "./WidgetTitleBar.js";

class WidgetContainer extends Component {
    render() {
        const {style} = this.props
        return (
            <Paper style={{ width: "100%", boxShadow: "0 1px 1px rgba(0, 0, 0, 0.05)", ...style }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <WidgetTitleBar
                            title={this.props.title}
                            action={this.props.action}
                            showBadge={this.props.showBadge}
                            badgeValue={this.props.badgeValue}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0} style={{ padding: this.props.padding || 16 }}>
                    <Grid item xs={12}>
                        {this.props.children}
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default WidgetContainer;
