import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";

import { observer, inject } from "mobx-react";

import Heading from "../../components/Heading";
import InvoiceReportTable from "../../components/reports/InvoiceReportTable";
import ReportDateFilter from "../../components/reports/ReportDateFilter";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import StaffFilter from "../../components/filters/StaffFilter";
import { ProfileType } from "../../types/enum";
import { Button, Grid, Select, MenuItem } from "@material-ui/core";

import jwtDecode from "jwt-decode";
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";

@inject("invoiceStore", "templateStore", "reportStore", "profileStore", "commonStore")
@observer
class Reports extends Component {
    constructor(props) {
        super(props);
        this.props.reportStore.resetFilterData();
        this.props.invoiceStore.resetInvoiceReports();
    }

    state = {
        goToPaymentPage: false
    };

    handlePaymentPage = () => {
        this.setState({
            goToPaymentPage: true
        });
    };

    loadReports = filterData => {
        if (filterData) {
            this.props.reportStore.setOrganisationFilter(filterData.organisation);
            this.props.reportStore.setStateFilter(filterData.state);
            this.props.reportStore.setRegionFilter(filterData.region);
            this.props.reportStore.setVenueFilter(filterData.venue);

            this.props.reportStore.setStartDate(filterData.startDate);
            this.props.reportStore.setEndDate(filterData.endDate);
        }

        this.props.templateStore.showLoadingScreen();
        this.props.invoiceStore.generateInvoicesReport().then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    };

    componentDidMount() {
        this.loadReports();
    }

    handleStatusFilterChange = e => {
        this.props.reportStore.setStatusFilter(e.target.value);
        this.loadReports();
    };

    render() {
        if (this.state.goToPaymentPage) return <Redirect to="../../billing/payment" />;
        const { currentProfile } = this.props.profileStore;
        const { token } = this.props.commonStore;
        const { statusFilter } = this.props.reportStore;
        let hasSubscribe = false;

        if (ENABLE_SUBSCRIPTION) {
            let currentType =
                this.props.profileStore.currentProfile.typeString === "staff" ? "venue" : this.props.profileStore.currentProfile.typeString;
            if (token && currentProfile && jwtDecode(token).scope.filter(x => x === currentType).length > 0) hasSubscribe = true;
        } else {
            hasSubscribe = true;
        }

        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    {hasSubscribe ? (
                        <Fragment>
                            <Heading title="Reports">
                                <Grid container spacing={8} justify="flex-end" alignItems="center">
                                    <Grid item>
                                        <Select
                                            label="Filter"
                                            className="dark-select"
                                            value={statusFilter}
                                            onChange={this.handleStatusFilterChange}
                                            fullWidth
                                            inputProps={{
                                                color: "primary"
                                            }}
                                            classes={{
                                                root: "text-white",
                                                icon: "text-white"
                                            }}
                                        >
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="pending">Pending</MenuItem>
                                            <MenuItem value="outstanding">Outstanding</MenuItem>
                                            {/* <MenuItem value="paid">Paid</MenuItem>
                                            <MenuItem value="declined">Declined</MenuItem> */}
                                            <MenuItem value="overdue">Overdue</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item>
                                        {currentProfile.type === ProfileType.Staff.ordinal ? (
                                            <StaffFilter postChangeEvent={this.loadReports} />
                                        ) : (
                                            <ReportDateFilter postChangeEvent={this.loadReports} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Heading>
                            <InvoiceReportTable />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <br />
                            <br />
                            <Grid container justify="center">
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={this.handlePaymentPage}
                                        style={{
                                            fontSize: "20px"
                                        }}
                                    >
                                        Upgrade to see your financial reporting
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )}
                </MainContainer>
            </Fragment>
        );
    }
}

export default Reports;
