import React, { Component, Fragment } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";

import StaffActRosterContainer from "../../components/venue/staffRoster/StaffActRosterContainer";

class StaffActRoster extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <Sidebar />
                <MainContainer>
                    <StaffActRosterContainer />
                </MainContainer>
            </Fragment>
        );
    }
}

export default StaffActRoster;
