import React, { Fragment } from "react";
import { inject } from "mobx-react";
import { Snackbar, Tooltip } from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import InboxIcon from "@material-ui/icons/Inbox";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withRouter } from "react-router-dom";
import "./SetListDialog.css";

@inject("songListStore", "setListStore", "templateStore", "profileStore")
class SetListDialog extends React.Component {
    state = {
        open: false,
        openAdd: false
    };

    constructor(props) {
        super(props);
        switch (this.props.type) {
            case "musician":
                const { currentProfile } = this.props.profileStore;
                
                this.props.setListStore.setType(0);
                this.props.setListStore.setProfileId(this.props.profileId);
                this.props.setListStore.loadSetlists();
                break;
            case "act":
                const { profile } = this.props.profileStore;
               
                this.props.setListStore.setType(1);
                this.props.setListStore.setProfileId(this.props.profileId);
                this.props.setListStore.loadSetlists();
                break;
            case "booking":
                break;
            default:
                break;
        }
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleNewSetlist = () => {
        if (this.props.type === "act") {
            this.props.setListStore.setNewActSetlist(true);
            const { editProfileActiveStep } = this.props.templateStore;
            this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
        } else {
            this.props.history.push("/musician/setlist/new");
        }
    };

    handleListItemClick = set => {
        this.setState({
            openAdd: true,
            open: false
        });
        this.props.setListStore.addToExistingSet(set);
    };

    handleSnackbarAddClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({ openAdd: false });
    };

    render() {
        const { setLists } = this.props.setListStore;
        return (
            <Fragment>
                <Tooltip title="Add to Setlist">
                    <IconButton aria-label="Add to Setlist" style={{ color: "#FFF", width: "120px" }} onClick={this.handleClickOpen}>
                        <AddIcon />
                        <Typography variant="body2" style={{ color: "#FFF" }}>
                            Add to Set list
                        </Typography>
                    </IconButton>
                </Tooltip>

                <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open} className="dialog-div">
                    <DialogTitle className="setlist-dialog-title"><p style={{color:"white"}}>Add to Set List</p></DialogTitle>
                    <div>
                        <List>
                            {setLists.map(set => (
                                <ListItem button onClick={() => this.handleListItemClick(set.setList)} key={set.setList.id}>
                                    <ListItemIcon>
                                        <InboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={set.setList.title} />
                                </ListItem>
                            ))}
                            <ListItem button onClick={() => this.handleNewSetlist()}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AddIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Add New Set list" />
                            </ListItem>
                        </List>
                    </div>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openAdd}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarAddClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Songs Added To Set List</span>}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarAddClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </Fragment>
        );
    }
}

export default withMobileDialog()(withRouter(SetListDialog));
