import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";

import { Toolbar, Typography, Tooltip, Button } from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Link } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";

import green from "@material-ui/core/colors/green";
import Slide from "@material-ui/core/Slide";
import ConfirmationModal from "../../components/modal/ConfirmationModal";

@inject("designProfileStore", "regionStore", "venueStore", "templateStore")
@observer
class VenuesTableToolbar extends React.Component {
    state = {
        newName: "",
        newSuburb: "",
        newState: "",
        openAdd: false,
        openDelete: false,
        openDialog: false,
        openDeleteConfirmation: false,
        success: false
    };

    lastData = [];

    handleNameChange = e => {
        this.setState({ newName: e.target.value });
    };

    handleSuburbChange = e => {
        this.setState({ newSuburb: e.target.value });
    };

    handleStateChange = e => {
        this.setState({ newState: e.target.value });
    };

    handleSubmitClick = () => {
        this.props.designProfileStore
            .addPlayedAt([
                {
                    name: this.state.newName,
                    suburb: this.state.newSuburb,
                    state: this.state.newState
                }
            ])
            .then(results => {
                this.lastData = results;
                this.setState({ openAdd: true });
            });

        this.setState({
            newName: "",
            newSuburb: "",
            newState: ""
        });

        this.handleCloseDialog();
    };

    handleConfirmDeleteClick = () => {
        this.props.selected.forEach(selectedId => {
            this.props.venueStore.delete(selectedId).then(venue => {
                if (venue) {
                    this.props.templateStore.openSnackbar(`${venue.profileName} has been removed.`);
                }
            });
        });

        // this.props.designProfileStore.deletePlayedAt(deletedPlayedAt).then(results => {
        //     this.lastData = results;
        //     this.props.handleClearSelected();
        //     this.setState({ openDelete: true });
        // });
        this.setState({ openDeleteConfirmation: false });
    };

    handleDeleteClick = () => {
        this.setState({ openDeleteConfirmation: true });
    };

    handleCloseDeleteModal = () => {
        this.setState({ openDeleteConfirmation: false });
    };

    handleSnackbarAddOpen = () => {
        this.setState({ openAdd: true });
    };

    handleSnackbarAddClose = (event, reason) => {
        this.setState({ openAdd: false });
        if (reason === "undo") {
            //execute undo add
            this.props.designProfileStore.deletePlayedAt(this.lastData);
        }
    };

    handleSnackbarDeleteOpen = () => {
        this.setState({ openDelete: true });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        this.setState({ openDelete: false });
        if (reason === "undo") {
            this.props.designProfileStore.addPlayedAt(this.lastData);
        }
    };

    handleUndoAddPlayedAt = event => {
        this.handleSnackbarAddClose(event, "undo");
    };

    handleUndoRemovePlayedAt = event => {
        this.handleSnackbarDeleteClose(event, "undo");
    };

    handleOpenDialog = () => {
        this.setState({ openDialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    render() {
        const { classes, selected } = this.props;
        const { playedAtSuccess } = false;
        const { canEdit } = this.props.regionStore;

        let numSelected = selected.length;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0
                })}
            >
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography variant="subtitle1" style={{ color: "#FFF" }}>
                            {numSelected} selected
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1">Venues</Typography>
                    )}
                </div>
                <div className={classes.spacer} />
                {canEdit ? (
                    <div className={classes.actions}>
                        {numSelected > 0 ? (
                            <Tooltip title="Delete">
                                <Button id="btn-delete-venue" aria-label="Delete" style={{ color: "#FFF" }} onClick={this.handleDeleteClick}>
                                    <DeleteIcon />
                                    <span className={classes.textSideIcon}>Delete </span>
                                </Button>
                            </Tooltip>
                        ) : (
                            <Link to={"/venues/new/" + this.props.regionStore.id}>
                                <Tooltip title="Add">
                                    <Button id="add-venue" aria-label="Add" style={playedAtSuccess ? { color: green[800] } : null}>
                                        {playedAtSuccess ? (
                                            <CheckIcon />
                                        ) : (
                                            <React.Fragment>
                                                <AddIcon style={{color:"white"}}/>
                                                <span className={classes.textSideIcon} style={{color:"white"}}>Add</span>
                                            </React.Fragment>
                                        )}
                                    </Button>
                                </Tooltip>
                            </Link>
                        )}
                    </div>
                ) : (
                    ""
                )}
                {canEdit ? (
                    <ConfirmationModal
                        open={this.state.openDeleteConfirmation}
                        message="Are you sure to remove the selected venue(s)?"
                        closeHandler={this.handleCloseDeleteModal}
                        confirmationHandler={this.handleConfirmDeleteClick}
                        declineHandler={this.handleCloseDeleteModal}
                        confirmationLabel="Remove"
                        maxWidth="sm"
                    />
                ) : (
                    ""
                )}
            </Toolbar>
        );
    }
}

VenuesTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        backgroundColor: "#ff0c9c"
    },
    highlight: {
        backgroundColor: "#333333"
    },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
    blackTextColor: {
        color: "#000"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: 2,
        right: 2,
        zIndex: 1
    },
    textSideIcon: {
        paddingTop: 3
    }
});

export default withMobileDialog()(withStyles(toolbarStyles)(VenuesTableToolbar));
